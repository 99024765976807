<h2 mat-dialog-title>Add Device</h2>

<mat-dialog-content>
  <div style="height: 100%;">

    <div style="display: flex;">
      <div style="flex: 1;" (click)="setView('OPOS')">OPOS Devices</div>
      <div style="flex: 1;" (click)="setView('LAN')">LAN Devices</div>
    </div>

    <div *ngIf="currentView == 'OPOS'">
      <mat-action-list>
        <div *ngFor="let oposOption of oposOptions" style="display: flex; align-items: center;">
          <button mat-list-item (click)="selectOption(oposOption)">{{ oposOption.display }}</button>
        </div>
      </mat-action-list>
    </div>

    <div *ngIf="currentView == 'LAN'">
      <mat-action-list>
        <div *ngFor="let lanOption of lanOptions" style="display: flex; align-items: center;">
          <button mat-list-item (click)="selectOption(lanOption)">{{ lanOption.display }}</button>
        </div>
      </mat-action-list>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <span style="flex: 1 1 auto;"></span>
  <button mat-stroked-button color="warn" [mat-dialog-close]>Cancel</button>
  <button mat-stroked-button (click)="close()">OK</button>
</mat-dialog-actions>
