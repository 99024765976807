<div [formGroup]="form">
  <div>
    <mat-form-field style="width: 100%;">
      <input matInput formControlName="value" style="width: 100%;" placeholder="{{ data.title }}">
    </mat-form-field>
  </div>

</div>

<mat-dialog-actions>

  <span style="flex: 1 1 auto;"></span>

  <app-pos-button *ngIf="data.showDevCardSwipe" (click)="simulateCardSwipe()">Sim Card Swipe</app-pos-button>
  <app-pos-button color="warn" [mat-dialog-close]>Cancel</app-pos-button>
  <app-pos-button color="accent" style="margin-left: 1em;" (click)="confirm()">Confirm</app-pos-button>

</mat-dialog-actions>
