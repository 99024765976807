import { Component, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Employee } from '../../models/employee';
import { Position } from '../../models/position';
import { EmployeeShift } from '../../models/employee-shift';
import { getDuration, TimeSpan } from 'core';
import { WaitModalProvider } from 'core';
import { SelectListModalProvider } from 'core';
import { DateTimeModalData, DateTimeModalProvider } from 'core';
import { SecurityPinModalProvider } from '../security-pin-modal/security-pin-modal.provider';
import { EmployeeShiftService } from '../../services/employee-shift.service';
import { PositionService } from '../../services/position.service';
import { EmployeeService } from '../../services/employee.service';
import { ActiveStatusKeys, EmployeeShiftStatusKeys, EmployeeStatusKeys } from '../../keys';

@Component({
  selector: 'app-clock-in-out-modal',
  templateUrl: './clock-in-out-modal.component.html',
  styleUrls: ['./clock-in-out-modal.component.scss'],
})
export class ClockInOutModalComponent implements OnInit {

  public selectedEmployee = new BehaviorSubject<Employee>(null);
  public selectedPosition = new BehaviorSubject<Position>(null);
  public clockInDateTime = new BehaviorSubject<Date>(null);
  public clockOutDateTime = new BehaviorSubject<Date>(null);
  public employeeOpenShift: EmployeeShift;
  public canSelectEmployee = new BehaviorSubject<boolean>(false);
  public canSelectPosition = new BehaviorSubject<boolean>(false);
  public canClockIn = new BehaviorSubject<boolean>(false);
  public canClockOut = new BehaviorSubject<boolean>(false);
  public canSave = new BehaviorSubject<boolean>(false);
  public employeePositions: Position[];
  public duration = new BehaviorSubject<TimeSpan>(null);
  public saveButtonText: string = 'Clock in';
  public maxHoursWarningThreshold = 8;
  public now: Date;

  private identityToken: string;
  private destroyed$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ClockInOutModalData,
    private dialogRef: MatDialogRef<ClockInOutModalComponent>,
    private waitModalProvider: WaitModalProvider,
    private selectListModalProider: SelectListModalProvider,
    private dateTimeModalProvider: DateTimeModalProvider,
    private securityPinModalProvider: SecurityPinModalProvider,
    private employeeShiftService: EmployeeShiftService,
    private positionService: PositionService,
    private employeeService: EmployeeService
  ) {
    this.identityToken = data.identityToken;

    this.selectedEmployee.subscribe(employee => {
      this.employeeOpenShift = null;

      if (employee) {
        this.positionService.getByUids(employee.positions.filter(x => x.activeStatusUid.toLowerCase() == ActiveStatusKeys.Active).map(x => x.uid), null, this.identityToken).subscribe(positions => {
          this.employeePositions = positions;
          this.selectedPosition.next(this.employeePositions.length == 1 ? this.employeePositions[0] : null);

          if (this.data.loadExisting) {
            let dialogRef = this.waitModalProvider.open('Loading...');
            dialogRef.afterOpened().subscribe(() => {
              this.employeeShiftService.getOpen(employee.uid, EmployeeShiftService.EmployeeShiftFullView, this.identityToken).subscribe(employeeShift => {
                this.employeeOpenShift = employeeShift;

                if (employeeShift) {
                  this.selectedPosition.next(this.employeePositions.find(y => y.uid == employeeShift.positionUid));
                  this.clockInDateTime.next(employeeShift.clockInDateTimeUtc);
                  this.clockOutDateTime.next(employeeShift.clockOutDateTimeUtc);
                } else {
                  this.clockInDateTime.next(this.now);
                  this.clockOutDateTime.next(null);
                }

                this.updateEnablement();

                dialogRef.close();
              });
            });
          } else {
            this.updateEnablement();
          }
        });
      }
    });

    this.updateEnablement();
  }

  ngOnInit() {

    this.updateNow();

    if (this.data.employeeShiftUid) {
      this.employeeShiftService.getByUid(this.data.employeeShiftUid, EmployeeShiftService.EmployeeShiftIndexView, this.identityToken).subscribe(employeeShift => {
        if (employeeShift) {
          let dialogRef = this.waitModalProvider.open('Loading...');
          dialogRef.afterOpened().subscribe(() => {

            this.employeeService.getByUid(employeeShift.employeeUid, EmployeeService.EmployeeFullView, this.identityToken).subscribe(employee => {
              this.selectedEmployee.next(employee);

              this.positionService.getByUids(employee.positions.map(x => x.uid), this.identityToken).subscribe(positions => {
                this.employeePositions = positions;

                this.selectedPosition.next(positions.find(y => y.uid.toUpperCase() == employeeShift.positionUid.toUpperCase()));
                this.clockInDateTime.next(employeeShift.clockInDateTimeUtc);

                if (employeeShift.clockOutDateTimeUtc) {
                  this.clockOutDateTime.next(employeeShift.clockOutDateTimeUtc);
                }

                this.employeeOpenShift = employeeShift;

                this.updateEnablement();
                this.duration.next(getDuration(this.clockInDateTime.value, this.clockOutDateTime.value));
                dialogRef.close();
              });
            });
          });
        }
      });
    } else if (this.data.employeeUid) {
      this.employeeService.getByUid(this.data.employeeUid, EmployeeService.EmployeeFullView, this.identityToken).subscribe(employee => {
        this.selectedEmployee.next(employee);
      });
    }

    setInterval(() => {
      this.updateNow();
    }, 60000);
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  updateNow() {

    this.now = this.round(new Date(), 15 * 60 * 1000);
  }

  selectEmployee() {

    if (this.data.employeeSelectMode == 'pin') {
      this.securityPinModalProvider.open({
        title: 'Enter your security PIN',
        getAuthTokenCallback: this.data.getAuthTokenCallback
      }).afterClosed().subscribe(([identityToken, employee]) => {
        if (identityToken) {
          this.identityToken = identityToken;
          this.selectedEmployee.next(employee);
          this.updateEnablement();
        }
      });
    } else {
      this.employeeService.search(null, [EmployeeStatusKeys.Active], null, EmployeeService.EmployeeFullView).subscribe(employeesPage => {
        this.selectListModalProider.open({
          title: 'Select Employee',
          options: employeesPage.edges.map(x => x.node),
          displayFunc: item => item.getFullName()
        }).afterClosed().subscribe(employee => {
          if (employee) {
            this.selectedEmployee.next(<Employee>employee);
          }
        });
      });
    }
  }

  selectPosition() {

    let dialogRef = this.selectListModalProider.open({
      title: 'Select Position',
      options: this.employeePositions,
      displayFunc: (item: Position) => item.name
    });

    dialogRef.afterClosed().subscribe(selection => {
      if (selection) {
        this.selectedPosition.next(<Position>selection);
        this.updateEnablement();
      }
    });
  }

  selectClockInDateTime() {

    let dialogRef = this.dateTimeModalProvider.open(<DateTimeModalData>{
      initialDate: this.clockInDateTime.value,
      maximumDateTime: this.clockInDateTime.value,
      selectionMode: 'preferred'
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.clockInDateTime.next(value);
        this.updateEnablement();
      }
    });
  }

  selectClockOutDateTime() {

    let dialogRef = this.dateTimeModalProvider.open(<DateTimeModalData>{
      initialDate: this.clockOutDateTime.value,
      minimumDateTime: this.clockInDateTime.value,
      selectionMode: 'preferred'
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.clockOutDateTime.next(value);
        this.updateEnablement();
      }
    });
  }

  save() {

    let saveDialogRef = this.waitModalProvider.open('Saving...');
    saveDialogRef.afterOpened().subscribe(() => {
      let clockInDateTime = this.clockInDateTime.value;
      let clockOutDateTime = this.clockOutDateTime.value;

      if (this.employeeOpenShift) {
        let employeeShiftUid = this.employeeOpenShift.uid;

        this.employeeShiftService.getByUid(employeeShiftUid, EmployeeShiftService.EmployeeShiftFullView, this.identityToken).subscribe(employeeShift => {
          if (employeeShift) {

            this.employeeShiftService.editEmployeeShift(employeeShiftUid, this.selectedEmployee.value.uid, this.selectedPosition.value.uid, clockInDateTime, clockOutDateTime, EmployeeShiftService.EmployeeShiftFullView, this.identityToken).subscribe(employeeShift => {
              if (employeeShift) {
                if (employeeShift.employeeShiftStatusUid.toLocaleUpperCase() == EmployeeShiftStatusKeys.ClockedOut) {
                  this.employeeShiftService.clockOut(employeeShiftUid, clockOutDateTime, EmployeeShiftService.EmployeeShiftFullView, this.identityToken).subscribe(employeeShift => {
                    if (this.data.onClockOut) {
                      this.data.onClockOut(employeeShift, this.identityToken);
                    }
                  });
                }

                saveDialogRef.close();
                this.dialogRef.close(employeeShift);
              } else {
                saveDialogRef.close();
                this.dialogRef.close(employeeShift);
              }
            });
          }
        });
      } else {
        const employeeShiftUid = UUID.UUID();

        this.employeeShiftService.clockIn(employeeShiftUid, this.selectedEmployee.value.uid, this.selectedPosition.value.uid, clockInDateTime, EmployeeShiftService.EmployeeShiftFullView, this.identityToken).subscribe(employeeShift => {
          if (clockOutDateTime) {

            this.employeeShiftService.clockOut(employeeShiftUid, clockOutDateTime, EmployeeShiftService.EmployeeShiftFullView, this.identityToken).subscribe(employeeShift => {
              if (employeeShift && this.data.onClockOut) {
                this.data.onClockOut(employeeShift, this.identityToken);
              }
            });

            saveDialogRef.close();
            this.dialogRef.close(employeeShift);
          } else {
            saveDialogRef.close();
            this.dialogRef.close(employeeShift);
          }
        })
      }
    });
  }

  cancel() {

    this.dialogRef.close();
  }

  private round(date: Date, durationMs: number): Date {

    let dateMs = date.getTime();

    return new Date(Math.ceil((+dateMs) / (+durationMs)) * (+durationMs));
  }

  private updateEnablement() {

    var selectedEmployee = this.selectedEmployee.value;
    var selectedPosition = this.selectedPosition.value;
    var clockInDateTime = this.clockInDateTime.value;
    var clockOutDateTime = this.clockOutDateTime.value;

    this.duration.next(getDuration(clockInDateTime, clockOutDateTime));

    this.canSelectEmployee.next(this.data.canSelectEmployee);
    this.canSelectPosition.next(selectedEmployee != null && this.employeePositions != null && this.employeePositions.length > 1);
    this.canClockIn.next(selectedEmployee != null && selectedPosition != null);
    this.canClockOut.next(selectedEmployee && selectedPosition && clockInDateTime != null);
    this.canSave.next(selectedEmployee && selectedPosition && clockInDateTime != null && (clockOutDateTime == null ? true : clockInDateTime < clockOutDateTime));

    var actions = <string[]>[];
    if (this.employeeOpenShift) {
      // Editing
      if (clockOutDateTime) {
        if (clockInDateTime.getTime() != this.employeeOpenShift.clockInDateTimeUtc.getTime()) {
          actions.push('Update Clock In');
        }
        actions.push('Clock Out');
      } else {
        actions.push('Update Clock In');
      }
    } else {
      // New shift
      if (clockInDateTime) {
        actions.push('Clock In');
        if (clockOutDateTime) {
          actions.push('Clock Out');
        }
      } else {
        actions.push('Clock In');
      }
    }

    this.saveButtonText = actions.join('/');
  }
}

export interface ClockInOutModalData {

  loadExisting: boolean;
  canSelectEmployee: boolean;
  employeeSelectMode: string;
  employeeUid: string;
  employeeShiftUid: string;
  getAuthTokenCallback: (pin: string) => Observable<[string, Employee]>;
  onClockOut: { (employeeShift: EmployeeShift, identityToken: string): void };
  identityToken: string;
}
