<div mat-dialog-title style="text-align: center;"><span>{{ data.title }}</span></div>
<mat-dialog-content>
  <mat-action-list>
    <button mat-list-item *ngFor="let guest of guests" style="height: 8.3333vh; background-color: #333; border-radius: 4px; text-align: center;" (click)="selectOption(guest)">{{ guest.name }}</button>
  </mat-action-list>
</mat-dialog-content>

<mat-dialog-actions>
  <!-- <button mat-flat-button color="warn" [mat-dialog-close]>Cancel</button> -->

  <app-pos-button (click)="newGuest()">New...</app-pos-button>
  <span style="flex: 1 1 auto;"></span>
  <app-pos-button (click)="clear()">None</app-pos-button>
  <app-pos-button (click)="close()" [styles]="{ backgroundColor: 'red' }">Cancel</app-pos-button>

</mat-dialog-actions>