export class PriceAdjustment {

  uid: string;
  name: string;
  description: string;
  amount: number;
  type: string;
  autoApply: boolean;
  scheduleUids: string[];
  departmentUids: string[];
  categoryUids: string[];
  productUids: string[];
  EmployeeUids: string[];
  MemberUids: string[];
  PriceAdjustmentStatusUid: string;
}
