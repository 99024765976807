import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-expansion-panel-title',
  templateUrl: './expansion-panel-title.component.html',
  styleUrls: ['./expansion-panel-title.component.scss']
})
export class ExpansionPanelTitleComponent {

  ngOnInit() {

  }
}
