import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { slideInAnimation, slideOutAnimation } from 'core';
import { CacheService } from 'core';
import { EditableTransactionItem } from 'downtown-transaction';
import { EditableCart } from '../../models/editable-cart';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-cart-editor',
  templateUrl: './cart-editor.component.html',
  styleUrls: ['./cart-editor.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class CartEditorComponent implements OnInit {

  @Input() cart: EditableCart;
  @Input() canEdit: boolean;
  @Output() selectItem = new EventEmitter<EditableTransactionItem>();
  @Output() itemUpdated = new EventEmitter<EditableTransactionItem>();
  @Output() lineItemCancel = new EventEmitter<EditableTransactionItem>();


  constructor(
    private cacheService: CacheService,
    private cartService: CartService
  ) {
  }

  ngOnInit() {
  }

  public ensureActiveLineViewable() {

  }

  public onSelectItem(item: EditableTransactionItem) {

    this.selectItem.next(item);
  }

  remove(cartItem: EditableTransactionItem) {

  }

  // public adjust(transactionLine: TransactionLine) {

  //   transactionLine.adjust();
  // }

  // public cancel(transactionLine: TransactionLine) {

  //   transactionLine.cancel();
  // }

  // public showMessage(message: string) {

  //   this.dialog.open(MessageModalComponent, {
  //     width: '50vw',
  //     disableClose: true,
  //     closeOnNavigation: true,
  //     data: {
  //       message: message
  //     }
  //   });
  // }
}
