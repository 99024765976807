import { Component, Input, EventEmitter, Output } from '@angular/core';
import { slideInAnimation, slideOutAnimation } from 'core';
import { CacheService } from 'core';
import { EditableOrder, EditableOrderItem } from '../../../models/editable-order';

@Component({
  selector: 'app-order-fulfillment-editor',
  templateUrl: './order-fulfillment-editor.component.html',
  styleUrls: ['./order-fulfillment-editor.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class OrderFulfillmentEditorComponent {

  @Input() order: EditableOrder;
  @Input() isReadOnly: boolean;
  @Output() select = new EventEmitter<EditableOrderItem>();
  @Output() fulfillOrderItem = new EventEmitter<EditableOrderItem>();
  @Output() fulfillOrder = new EventEmitter();

  constructor(
    private cacheService: CacheService,
    ) {
  }
}
