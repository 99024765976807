import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from "rxjs/operators";
import { ConfirmModalProvider, IPagedDataSource, MessageModalProvider } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { getDuration, TimeSpan } from "core";
import { EmployeeShiftStatusKeys } from "../../../keys";
import { EmployeeShift } from "../../../models/employee-shift";
import { EmployeeShiftService, EmployeeShiftViewOptions } from "../../../services/employee-shift.service";

@Component({
  selector: 'app-shifts-table',
  templateUrl: './shifts-table.component.html',
  styleUrls: ['./shifts-table.component.scss']
})
export class ShiftsTableComponent {

  @Input() public dataSource: EmployeeShiftDataSource;
  @Input() public masterDataSource: EmployeeShiftDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {

    if (!this.masterDataSource) {
      this.masterDataSource = this.dataSource;
    }
  }
}

export class EmployeeShiftModel {

  item: EmployeeShift;

  canEdit: boolean;
  canDelete: boolean;
  canSubmit: boolean;
  canAccept: boolean;
  canReject: boolean;
  // canSchedulePayment: boolean;
}

export class EmployeeShiftDataSource extends DataSource<EmployeeShiftModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;

  private _selection: SelectionModel<EmployeeShiftModel>;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<EmployeeShiftModel[]>([]);
  private isPreloaded = false;

  private _submittable: EmployeeShiftModel[];
  private _acceptable: EmployeeShiftModel[];
  private _rejectable: EmployeeShiftModel[];
  private _deletable: EmployeeShiftModel[];
  private _canBulkEdit = false;
  private _canBulkSubmit = false;
  private _canBulkAccept = false;
  private _canBulkReject = false;
  private _canBulkDelete = false;

  constructor(
    private employeeShiftService: EmployeeShiftService,
    private messageModalProvider: MessageModalProvider,
    private employeeShiftModelFactory: () => EmployeeShiftModel = () => { return new EmployeeShiftModel(); },
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<EmployeeShiftModel>(multiselect, [], true);
  }

  public get selection(): SelectionModel<EmployeeShiftModel> {
    return this._selection;
  }

  public set selection(selection: SelectionModel<EmployeeShiftModel>) {
    this._selection = selection;
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get submittable(): EmployeeShiftModel[] {
    return this._submittable
  }

  public get acceptable(): EmployeeShiftModel[] {
    return this._acceptable
  }

  public get rejectable(): EmployeeShiftModel[] {
    return this._rejectable
  }

  public get deletable(): EmployeeShiftModel[] {
    return this._deletable
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkSubmit(): boolean {
    return this._canBulkSubmit
  }

  public get canBulkAccept(): boolean {
    return this._canBulkAccept
  }

  public get canBulkReject(): boolean {
    return this._canBulkReject
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  public get currentPage(): EmployeeShiftModel[] {

    return this.dataSubject.value;
  }

  connect(collectionViewer: CollectionViewer): Observable<EmployeeShiftModel[]> {

    return this.isPreloaded ? this.dataSubject : this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.filter(x => this.dataSubject.value.find(y => y.item.uid.toUpperCase() == x.item.uid.toUpperCase())).length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  public loadData(employeeUids: string[], positionUids: string[], paymentMethodUids: string[], payPeriodUids: string[], daysOfWeek: number[], statusUids: string[], paginationInput: PaginationInput, viewOptions: EmployeeShiftViewOptions, identityToken: string = null) {

    this.loadingSubject.next(true);

    this.employeeShiftService.search(employeeUids, positionUids, paymentMethodUids, payPeriodUids, daysOfWeek, statusUids, paginationInput, viewOptions, identityToken).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        const shifts = page.edges.map(x => x.node).map(x => this.evaluateEnablement(this.projectOne(x, this.employeeShiftModelFactory())));

        return <[PageInfo, EmployeeShiftModel[]]>[page.pageInfo, shifts];
      }),
    ).subscribe({
      next: ([pageInfo, data]) => this.assignPageData(pageInfo, data),
      error: err => {
        this.loadingSubject.next(false);
        this.assignPageData(null, []);

        let errorMessage = err.error instanceof ErrorEvent ? `Error: ${err.message}` : `Error Code: ${err.status}\nMessage: ${err.message}`;
        this.messageModalProvider.open({ title: 'Error', message: errorMessage }).afterClosed().subscribe();

        console.log(errorMessage);
      },
      complete: () => {
        this.loadingSubject.next(false);
      }
    });
  }

  private assignPageData(pageInfo: PageInfo, data: EmployeeShiftModel[]) {

    this.selection.clear();
    this.pageInfoSubject.next(pageInfo);
    this.dataSubject.next(data);
  }

  public loadFrom(source: EmployeeShiftModel[]) {

    this.selection.clear();
    this.dataSubject.next(source);
    this.isPreloaded = true;
  }

  public merge(source: EmployeeShift): EmployeeShiftModel {

    var employeeShifts = this.dataSubject.value;
    var target = employeeShifts.find(x => x.item.uid.toUpperCase() == source.uid.toUpperCase());
    if (target) {
      target = this.evaluateEnablement(this.projectOne(source, target));
    }

    return target;
  }

  public submit(employeeShifts: EmployeeShiftModel[]): Observable<EmployeeShiftModel[]> {

    return this.employeeShiftService.submit(employeeShifts.map(x => x.item.uid)).pipe(
      map(updates => updates.map(update => this.merge(update))),
      tap(() => this.evaluateBulkEnablement())
    );
  }

  public accept(employeeShifts: EmployeeShiftModel[]): Observable<EmployeeShiftModel[]> {

    return this.employeeShiftService.accept(employeeShifts.map(x => x.item.uid)).pipe(
      map(updates => updates.map(update => this.merge(update))),
      tap(() => this.evaluateBulkEnablement())
    );
  }

  public reject(employeeShifts: EmployeeShiftModel[]): Observable<EmployeeShiftModel[]> {

    return this.employeeShiftService.reject(employeeShifts.map(x => x.item.uid)).pipe(
      map(updates => updates.map(update => this.merge(update))),
      tap(() => this.evaluateBulkEnablement())
    );
  }

  public delete(rows: EmployeeShiftModel[]): Observable<EmployeeShiftModel[]> {

    return this.employeeShiftService.delete(rows.map(x => x.item.uid)).pipe(
      map(updates => updates.map(update => this.merge(update))),
      tap(() => this.evaluateBulkEnablement())
    );
  }

  public updatePayPeriod(employeeShifts: EmployeeShiftModel[], payPeriodUid: string): Observable<EmployeeShiftModel[]> {

    return this.employeeShiftService.updatePayPeriod(employeeShifts.map(x => x.item.uid), payPeriodUid).pipe(
      map(updates => updates.map(update => this.merge(update))),
      tap(() => this.evaluateBulkEnablement())
    );
  }

  public projectOne(source: EmployeeShift, target: EmployeeShiftModel): EmployeeShiftModel {

    return <EmployeeShiftModel>Object.assign(target, {
      item: source
    });
  }

  public evaluateEnablement(row: EmployeeShiftModel): EmployeeShiftModel {

    let employeeShiftStatusUid = row.item.employeeShiftStatusUid.toUpperCase();

    row.canEdit = ([EmployeeShiftStatusKeys.PaymentMade, EmployeeShiftStatusKeys.Deleted].indexOf(employeeShiftStatusUid) === -1);
    row.canSubmit = this.evaluateCanSubmit(employeeShiftStatusUid);
    row.canAccept = this.evaluateCanAccept(employeeShiftStatusUid);
    // employeeShift.canSchedulePayment = this.evaluateCanSchedule(employeeShiftStatusUid);
    row.canReject = this.evaluateCanReject(employeeShiftStatusUid);
    row.canDelete = this.evaluateCanDelete(employeeShiftStatusUid);

    return row;
  }

  private evaluateCanSubmit(employeeShiftStatusUid: string) {

    return ([EmployeeShiftStatusKeys.ClockedOut, EmployeeShiftStatusKeys.PaymentCancelled].indexOf(employeeShiftStatusUid) > -1);
  }

  private evaluateCanAccept(employeeShiftStatusUid: string) {

    return ([EmployeeShiftStatusKeys.Submitted, EmployeeShiftStatusKeys.Rejected, EmployeeShiftStatusKeys.PaymentCancelled].indexOf(employeeShiftStatusUid) > -1);
  }

  private evaluateCanReject(employeeShiftStatusUid: string) {

    return ([EmployeeShiftStatusKeys.Submitted, EmployeeShiftStatusKeys.Accepted, EmployeeShiftStatusKeys.PaymentCancelled].indexOf(employeeShiftStatusUid) > -1);
  }

  private evaluateCanDelete(employeeShiftStatusUid: string) {

    return ([EmployeeShiftStatusKeys.PaymentMade, EmployeeShiftStatusKeys.Deleted, EmployeeShiftStatusKeys.PaymentCancelled].indexOf(employeeShiftStatusUid) === -1);
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._submittable = selected.filter(x => this.evaluateCanSubmit(x.item.employeeShiftStatusUid.toUpperCase()));
    this._acceptable = selected.filter(x => this.evaluateCanAccept(x.item.employeeShiftStatusUid.toUpperCase()));
    this._rejectable = selected.filter(x => this.evaluateCanReject(x.item.employeeShiftStatusUid.toUpperCase()));
    this._deletable = selected.filter(x => this.evaluateCanDelete(x.item.employeeShiftStatusUid.toUpperCase()));

    this._canBulkEdit = selected.length == 1 && ([EmployeeShiftStatusKeys.PaymentMade, EmployeeShiftStatusKeys.Deleted].indexOf(selected[0].item.employeeShiftStatusUid.toUpperCase()) === -1);
    this._canBulkSubmit = this._submittable.length > 0;
    this._canBulkAccept = this._acceptable.length > 0;
    this._canBulkReject = this._rejectable.length > 0;
    this._canBulkDelete = this._deletable.length > 0;
  }
}
