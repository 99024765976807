import { Injectable } from "@angular/core";
import { concatMap, map, Observable, of, shareReplay } from "rxjs";
import { Lock } from 'core';
import { CacheService } from 'core';
import { TerminalProvider } from "downmain-terminal";

@Injectable()
export class PosCoreLockResolver {

  constructor(
    private cacheService: CacheService,
    private terminalProvider: TerminalProvider
  ) {    
    
  }

  resolve(uid: string): Observable<Lock> {

    if (uid) {
      return this.terminalProvider.active$.pipe(
        concatMap(activeTerminal => {
          return this.cacheService.getOrAdd(uid, () => this.terminalProvider.getOneByUid$(uid).pipe(shareReplay(1))).pipe(
            map(resolvedTerminal => {
              const lock = new Lock();
              lock.uid = resolvedTerminal.uid;
              lock.name = resolvedTerminal.name;
              lock.isLocalDevice = activeTerminal.uid.toLowerCase() == resolvedTerminal.uid.toLowerCase();
              lock.isLocalUser = false;

              return lock;
            })
          )
        })
      );
    }

    return of(null);
  }
}
