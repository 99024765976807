<div style="height: 100%; width: 100%; display: flex; flex-direction: column;">
  <div>
    <div style="display: flex;">
      <h2 mat-dialog-title>Conversation</h2>
      <span style="flex: 1 1 auto;"></span>
      <button mat-flat-button color="warn" [mat-dialog-close]>X</button>
    </div>
  </div>
  <div class="conversation-content" style="display: flex; flex-direction: column-reverse; justify-content: right; overflow-y: auto;">
    <ng-container *ngIf="loading | async">
      <mat-spinner color="accent"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!(loading | async)">
      <div *ngFor="let message of messages">
        <div class="chat theirs" [class.theirs]="message.owner == 'theirs'" [class.mine]="message.owner == 'mine'" color="accent">
          <div style="display: flex; opacity: .5;">
            <h6>{{ message.displayName }}</h6>
            <span style="flex: 1 1 auto;"></span>
            <h6>{{ message.dateTimeUtc | date: 'M-dd-yyyy h:mm a' }}</h6>
          </div>
          <div>
            <h2>{{ message.content }}</h2>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div [formGroup]="form" style="font-size: 16px;">
    <mat-dialog-actions>
      <mat-form-field style="width: 100%;">
        <textarea #messageContent formControlName="messageContent" placeholder="Type message here..." style="box-sizing: content-box;" (keydown.control.enter)="send()" matInput cdkAutosizeMinRows="1" cdkAutosizeMaxRows="4" cdkTextareaAutosize></textarea>
        <button matSuffix mat-flat-button color="accent" (click)="send()">Send</button>
      </mat-form-field>
    </mat-dialog-actions>
  </div>
</div>


<!-- <div [formGroup]="form" class="modal-dialog">
  <div class="modal-content" style="display: flex; flex-direction: column;">
    <div class="modal-body" style="flex: 1; position: relative;">
      <div style="display: flex; height: 100%; flex-direction: column;">
        <div style="position: relative; font-size: 48px; text-align: center; color: black;">
          <span>Conversation</span>
        </div>
        <div style="max-height: 25vh; overflow-y: auto; border: solid 1px black;">
          <div *ngFor="let message of messages" style="border-bottom: solid 1px black; color: black; padding: 5px;">
            <div>
              {{ message.employee.fullName }}&nbsp;[{{ message.dateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}]
            </div>
            <div>
              {{ message.content }}
            </div>
          </div>
        </div>
        <div style="position: relative; font-size: 24px; border: solid 1px black; background-color: #2e466b; color: white;">
          <div style="padding: 5px; position: relative; display: flex;">
            <textarea #messageContent formControlName="messageContent" style=" flex: 4; font-size: 22px; line-height: 24px; height: 1.5em; border: solid 1px gray; color: black;" placeholder="Type message here..."></textarea>
            <div style="flex: 1; padding: 5px; position: relative; font-size: 22px;" (click)="confirm()">Send</div>
          </div>
        </div>
        <div style="flex: 1; position: relative; display: flex; flex-direction: row; margin-top: 10px;">
          <div style="flex: 1;">
            <div>

            </div>
          </div>
          <div style="flex: 1; height: 8.3333vh;">
            <div>
              <div style="flex:1; background-color: red;" (click)="close()">Close</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
