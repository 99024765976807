import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConversationModalComponent } from "./conversation-modal.component";

@Injectable()
export class ConversationModalProvider {

  constructor(
    private dialog: MatDialog
  ) {
  }

  public open(data: ConversationModalData): MatDialogRef<ConversationModalComponent> {

    return this.dialog.open(ConversationModalComponent, {
      height: '50vh',
      width: '25vw',
      minHeight: '50vh',
      maxHeight: '50vh',
      maxWidth: '25vw',
      position: {
        bottom: '0',
        right: '0'
      },
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface ConversationModalData {
  refAggregateUid: string;
}
