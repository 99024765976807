import { Injectable } from "@angular/core";
import { HttpService } from "core";
import { Observable } from 'rxjs';
import { Page } from "core";
import { PriceAdjustment } from "../models/price-adjustment";
import { OltpServiceSettings } from "../oltp-service-settings";
import { PaginationInput } from "core";
import { map, tap } from "rxjs/operators";
import { PosModelFactory } from '../pos-model-factory';

@Injectable()
export class PriceAdjustmentService {

  public static readonly PriceAdjustmentIndexView = <PriceAdjustmentViewOptions>{};
  public static readonly PriceAdjustmentFullView = <PriceAdjustmentViewOptions>{};

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: PriceAdjustmentViewOptions = PriceAdjustmentService.PriceAdjustmentIndexView): Observable<PriceAdjustment> {

    let view = PriceAdjustmentService.buildView(viewOptions);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<PriceAdjustment>(this.oltpServiceSettings.apiUrl, 'api/oltp/priceadjustment', request, 'getByUid').pipe(
      map(x => PosModelFactory.assignPriceAdjustment(x))
    );
  }

  getByUids(uids: string[], viewOptions: PriceAdjustmentViewOptions = PriceAdjustmentService.PriceAdjustmentIndexView): Observable<PriceAdjustment[]> {

    let view = PriceAdjustmentService.buildView(viewOptions);

    var request = {
      query: `query { getByUids (uids: ${JSON.stringify(uids)}) ${view} }`
    }

    return this.httpService.graph<PriceAdjustment[]>(this.oltpServiceSettings.apiUrl, 'api/oltp/priceadjustment', request, 'getByUids').pipe(
      map(x => x.map(y => PosModelFactory.assignPriceAdjustment(y)))
    );
  }

  search(statusUids: string[], paginationInput: PaginationInput, viewOptions: PriceAdjustmentViewOptions = PriceAdjustmentService.PriceAdjustmentIndexView): Observable<Page<PriceAdjustment>> {

    let view = PriceAdjustmentService.buildView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search (statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<PriceAdjustment>>(this.oltpServiceSettings.apiUrl, 'api/oltp/priceadjustment', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = PosModelFactory.assignPriceAdjustment(edge.node)))
    );
  }

  create(uid: string, name: string, description: string, viewOptions: PriceAdjustmentViewOptions = PriceAdjustmentService.PriceAdjustmentIndexView): Observable<PriceAdjustment> {

    let view = PriceAdjustmentService.buildView(viewOptions);

    var priceadjustmentInput = {
      uid: uid,
      name: name,
      description: description
    };

    var request = {
      query: `mutation create($priceadjustment:PriceAdjustmentInput!) { create(priceadjustment: $priceadjustment) ${view} }`,
      variables: { priceadjustment: priceadjustmentInput }
    };

    return this.httpService.graph<PriceAdjustment>(this.oltpServiceSettings.apiUrl, 'api/oltp/priceadjustment', request, 'create').pipe(
      map(x => PosModelFactory.assignPriceAdjustment(x))
    );
  }

  update(uid: string, name: string, description: string, viewOptions: PriceAdjustmentViewOptions = PriceAdjustmentService.PriceAdjustmentIndexView): Observable<PriceAdjustment> {

    let view = PriceAdjustmentService.buildView(viewOptions);

    var priceadjustmentInput = {
      uid: uid,
      name: name,
      description: description
    };

    var request = {
      query: `mutation update($priceadjustment:PriceAdjustmentInput!) { update(priceadjustment: $priceadjustment) ${view} }`,
      variables: { priceadjustment: priceadjustmentInput }
    };

    return this.httpService.graph<PriceAdjustment>(this.oltpServiceSettings.apiUrl, 'api/oltp/priceadjustment', request, 'update').pipe(
      map(x => PosModelFactory.assignPriceAdjustment(x))
    );
  }

  delete(uid: string, viewOptions: PriceAdjustmentViewOptions = PriceAdjustmentService.PriceAdjustmentIndexView): Observable<PriceAdjustment> {

    let view = PriceAdjustmentService.buildView(viewOptions);

    var request = {
      query: `mutation delete { delete(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<PriceAdjustment>(this.oltpServiceSettings.apiUrl, 'api/oltp/priceadjustment', request, 'delete').pipe(
      map(x => PosModelFactory.assignPriceAdjustment(x))
    );
  }

  public static buildView(viewOptions: PriceAdjustmentViewOptions) {

    let view = `uid name description amount type autoApply scheduleUids departmentUids categoryUids productUids employeeUids memberUids priceAdjustmentStatusUid`;

    return '{ ' + view + ' }';
  }
}

export interface PriceAdjustmentViewOptions {

}

