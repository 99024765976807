<span>Page&nbsp;{{ paginator.pageIndex + 1 }}</span>&nbsp;of&nbsp;<span>{{ paginator.maxPageIndex + 1 }}</span>&nbsp;of&nbsp;{{ paginator.totalCount }}

<button mat-flat-button color="primary" (click)="paginator.pageBackward()" style="margin-left: 3px;" [disabled]="paginator.pageIndex == 0">
  <mat-icon style="font-weight: bold;">chevron_left</mat-icon>
</button>

<button mat-flat-button color="primary" (click)="paginator.pageForward()" style="margin-left: 1px;" [disabled]="paginator.pageIndex == paginator.maxPageIndex">
  <mat-icon style="font-weight: bold;">chevron_right</mat-icon>
</button>

<button mat-flat-button color="primary" [matMenuTriggerFor]="filterMenu" style="margin-left: 1px;">
  <div><span>Page Size:</span>&nbsp;<span style="font-weight: bold;">{{ paginator.selectedPageSizeOption.display }}</span></div>
</button>

<mat-menu #filterMenu="matMenu" xPosition="before">
  <button *ngFor="let pageSizeOption of paginator.pageSizeOptions" mat-menu-item (click)="selectPageSize(pageSizeOption)">{{ pageSizeOption.display }}</button>
</mat-menu>
