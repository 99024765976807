import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideInAnimation = trigger('slideIn', [
  transition(':enter', [
    style({ height: '0px' }),
    animate('150ms ease-in-out', style({ height: '*' }))
  ]),
]);

export const slideOutAnimation = trigger('slideOut', [
  transition(':leave', [
    style({ height: '*' }),
    animate('150ms ease-in-out', style({ height: '0px' })),
  ])
]);
