import { Pipe, PipeTransform } from '@angular/core';
import { AuthHeaderProvider } from 'core';
import { CacheService } from 'core';
import { AccountSettingService } from '../services/account-setting.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Pipe({
  name: 'accountSetting'
})
export class AccountSettingPipe implements PipeTransform {

  constructor(
    private accountSettingService: AccountSettingService,
    private authHeaderProvider: AuthHeaderProvider,
    private cacheService: CacheService
  ) {
  }

  transform(accountSettingUid: string): Observable<string> {

    if (accountSettingUid) {
      return this.cacheService.getOrAdd(
        accountSettingUid,
        () => this.accountSettingService.getByUid(accountSettingUid, this.authHeaderProvider,).pipe(shareReplay(1))
      ).pipe(
        map(accountSetting => {
          return accountSetting ? accountSetting.name : null;
        })
      );
    }

    return null;
  }
}
