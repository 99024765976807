import { HostListener, Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { BlobServerSettings } from "core";
import { OltpServiceSettings } from "../oltp-service-settings";

@Injectable()
export abstract class PosRuntimeProvider {

  public readonly oltpApiUrl: string;
  public readonly oltpSocketUrl: string;
  public readonly availabilityHeartbeat: number;

  public readonly currentYear: number;
  public readonly isDesktop: boolean;

  public screenHeight: number;
  public screenWidth: number;
  public rows: number;
  public columns: number;
  public buttonWidth: string;
  public buttonHeight: string;

  protected _assetBaseUrl: string;
  protected _imageHostUrl: string;

  constructor(
    deviceDetectorService: DeviceDetectorService,
    oltpServiceSettings: OltpServiceSettings,
    blobServerSettings: BlobServerSettings,
  ) {
    this.oltpApiUrl = oltpServiceSettings.apiUrl;
    this.oltpSocketUrl = oltpServiceSettings.socketUrl;
    this.availabilityHeartbeat = oltpServiceSettings.availabilityHeartbeat;
    this._assetBaseUrl = './assets/';
    this._imageHostUrl = `${blobServerSettings.apiUrl}/api/blob/${blobServerSettings.apiOwnerUid}`;
    this.currentYear = new Date().getFullYear();
    this.isDesktop = deviceDetectorService.isDesktop();

    this.rows = 12;
    this.columns = 12;

    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    this.calculateInterfaceMeasures();
  }

  private calculateInterfaceMeasures() {

    this.buttonWidth = (this.screenWidth / this.columns) + 'px';
    this.buttonHeight = (this.screenHeight / this.rows) + 'px';
  }

  public get assetBaseUrl(): string {

    return this._assetBaseUrl;
  }

  public get imageHostUrl(): string {

    return this._imageHostUrl;
  }
}
