import { Edge, Metadata, PageInfo } from 'core';
import { CardTransaction } from './card-transaction';
import { TransactionStatusEnum } from '../keys';

export class TransactionPage {

  totalCount: number;
  totalValue: number;
  edges: Edge<Transaction>[];
  pageInfo: PageInfo;
}

export class TransactionSearchPage {

  totalCount: number;
  totalValue: number;
  edges: Edge<string>[];
  pageInfo: PageInfo;
}

export class Transaction {

  uid: string;
  number: string;
  notes: string;
  holdCardReference: string;
  logisticTypeUid: string;
  transactionStatus: TransactionStatusEnum;
  lockUid: string;
  lastGuestUid: string;
  openDateTimeUtc: Date;
  openAuthUid: string;
  closeDateTimeUtc: Date;
  closeAuthUid: string;
  isAdjusted: boolean;
  total: number;
  totalDue: number;

  guests: TransactionGuest[];
  items: TransactionItem[];
  charges: TransactionCharge[];
  adjustments: TransactionAdjustment[];
  payments: TransactionPayment[];
  disbursements: TransactionDisbursement[];

  getChange(): number {
    return this.getSaleItemsTotal() >= this.getSubtractionsTotal() ? null : this.getSubtractionsTotal() - this.getSaleItemsTotal();
  }

  getTotalDue(): number {
    return this.getSaleItemsTotal() >= this.getSubtractionsTotal() ? this.getSaleItemsTotal() - this.getSubtractionsTotal() : 0;
  }

  getTotalSale(): number {
    return this.getSaleItemsTotal();
  }

  private getSaleItemsTotal() {

    const itemsTotal = this.items.map(x => {
      const adjustments = x.adjustments.map(x => x.getTotal()).reduce((sum, total) => sum + total, 0);
      return x.getTotal() - adjustments;
    }).reduce((sum, total) => sum + total, 0);

    const chargesTotal = this.charges.map(x => x.getTotal()).reduce((sum, total) => sum + total, 0);

    return itemsTotal + chargesTotal;
  }

  private getPaymentsTotal() {

    return this.payments.map(x => x.amount).reduce((sum, amount) => sum + amount, 0);
  }

  private getAdjustmentsTotal() {

    return this.adjustments.map(x => x.amount).reduce((sum, amount) => sum + amount, 0);
  }

  private getSubtractionsTotal() {

    return this.getPaymentsTotal() + this.getAdjustmentsTotal();
  }
}

export class TransactionGuest {

  uid: string;
  name: string;
}

export class TransactionItem {

  uid: string;
  departmentUid: string;
  categoryUid: string;
  productUid?: string;
  productVersion?: number;
  guestUid?: string;
  configuration?: TransactionItemConfiguration;
  quantity: number;
  eachAmount: number;
  notes: string[];

  adjustments?: TransactionItemAdjustment[];

  getTotal(): number {
    return this.quantity * this.eachAmount;
  }
}

export class TransactionItemAdjustment {

  uid: string;
  description: string;
  quantity: number;
  eachAmount: number;
  isVoid: boolean;
  isCompensated: boolean;

  getTotal(): number {
    return this.quantity * this.eachAmount;
  }
}

export class TransactionAdjustment {

  uid: string;
  description: string;
  amount: number;

  getTotal(): number {
    return this.amount;
  }
}

export class TransactionPayment {

  uid: string;
  paymentMethodUid: string;
  amount: number;
  referenceUid: string;
  metadata: Metadata[];

  cardTransaction: CardTransaction
}

export class TransactionCharge {

  uid: string;
  chargeTypeUid: string;
  description: string;
  quantity: number;
  eachAmount: number;

  adjustments?: TransactionChargeAdjustment[];

  getTotal(): number {
    return this.quantity * this.eachAmount;
  }
}

export class TransactionChargeAdjustment {

  uid: string;
  description: string;
  quantity: number;
  eachAmount: number;
  isVoid: boolean;
  isCompensated: boolean;

  getTotal(): number {
    return this.quantity * this.eachAmount;
  }
}

export class TransactionDisbursement {

  uid: string;
  drawerUid: string;
  paymentMethodUid: string;
  amount: number;
}

export class TransactionItemConfiguration {

  portion: TransactionItemConfigurationPortion;

  public getConfiguringPortion?(): TransactionItemConfigurationPortion {

    return this.portion;
  }
}

export class TransactionItemConfigurationPortion {

  portionUid: string;
  preparations?: TransactionItemConfigurationPreparation[];
  variations?: TransactionItemConfigurationVariation[];
  inclusionGroups: TransactionItemConfigurationInclusionGroup[];
  addOns?: TransactionItemConfigurationAddOn[];
  notes: string[];

  public getConfiguringInclusionGroup?(inclusionGroupUid: string): TransactionItemConfigurationInclusionGroup {

    return this.inclusionGroups ? this.inclusionGroups.find(x => x.inclusionGroupUid.toUpperCase() == inclusionGroupUid.toUpperCase()) : null;
  }
}

export class TransactionItemConfigurationPreparation {

  preparationUid: string;
  optionUid: string;
}

export class TransactionItemConfigurationVariation {

  variationUid: string;
  optionUid: string;
  productUid: string;
  productVersion: number;
  productPortionUid: string;
}

export class TransactionItemConfigurationInclusionGroup {

  inclusionGroupUid: string;
  options: TransactionItemConfigurationInclusionGroupOption[];
}

export class TransactionItemConfigurationInclusionGroupOption {

  optionUid: string;
  productUid: string;
  productVersion: number;
  productPortionUid: string;
  quantity: number;
  isSubstitution: boolean;
  preparations?: TransactionItemConfigurationPreparation[];
  variations?: TransactionItemConfigurationVariation[];
  notes: string[];
}

export class TransactionItemConfigurationAddOn {

  addOnUid: string;
  item: TransactionItem;
}
