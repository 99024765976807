import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EditableTransactionGuest } from "downtown-transaction";
import { TransactionGuest } from "downtown-transaction";
import { TextEntryModalProvider, SettingProvider } from "core";
import { SelectGuestModalData, SelectGuestModalResult } from "./select-guest-modal.provider";
import { TerminalProvider } from "downmain-terminal";
import { DeviceSettings } from "downmain-device";

@Component({
  selector: 'app-select-guest-modal',
  templateUrl: './select-guest-modal.component.html',
  styleUrls: ['./select-guest-modal.component.scss']
})
export class SelectGuestModalComponent {

  public guests: EditableTransactionGuest[];

  constructor(
    private dialogRef: MatDialogRef<SelectGuestModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectGuestModalData,
    private terminalProvider: TerminalProvider,
    private settingProvider: SettingProvider,
    private textEntryModalProvider: TextEntryModalProvider
  ) {
    this.guests = this.data.guests.map(x => Object.assign({}, x));

    if (this.guests.length == 0) {
      this.newGuest();
    }
  }

  selectOption(option: EditableTransactionGuest) {

    this.dialogRef.close(<SelectGuestModalResult>{ guest: this.data.guests.find(x => x.uid == option.uid) });
  }

  public newGuest() {

    let existingNames = this.guests.map(x => x.name);

    let count = 0;
    let defaultNamePrefix = 'Guest #';

    let defaultName: string = null;
    while (!defaultName) {
      let test = `${defaultNamePrefix}${++count}`;
      if (!existingNames.some(x => x.toLowerCase() == test.toLowerCase())) {
        defaultName = test;
      }
    }

    this.settingProvider.getOneByTypeAndOwner$<DeviceSettings>('DeviceSettings', this.terminalProvider.active.uid).subscribe(deviceSettings => {
      this.textEntryModalProvider.open({ title: 'Guest Name, Location, or Swipe Card', initialValue: defaultName }, deviceSettings.textEntryStyle).afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close(<SelectGuestModalResult>{
            guest: <TransactionGuest>{ name: result.textEntry }
          });
        } else {
          if (this.guests.length == 0) {
            this.close();
          }
        }
      });
    });
  }

  clear() {

    this.dialogRef.close({});
  }

  close() {

    this.dialogRef.close();
  }
}
