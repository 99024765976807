export class CardTransaction2StatusKeys {

  public static readonly Authorized = '27BD56CA-87FB-43C6-BE5F-F1DBB2B7F6C6';
  public static readonly Captured = '2BDDBC0E-021F-4509-9103-CE435C9EDE1D';
  public static readonly Refunded = '86ED8B1E-8DEC-4359-8D0A-9AD4639B5666';
  public static readonly Voided = '4448BDD2-741D-4D50-A879-B8480AE77F26';
}

export class CardTransactionBatchStatusKeys {

  public static readonly Open = 'BD43A727-9741-4D7F-84A4-C936BB746B18';
  public static readonly Closed = '1ACC9FA5-A709-477C-B7A5-947E960936D9';
}

export class CartStatusKeys {

  public static readonly Open = '0E0FCCB4-EBF7-4DF8-9026-5D44B230C124';
  public static readonly Processing = 'CF06148D-79DA-47B9-BA07-699E31810CF6';
  public static readonly Converted = 'E7A8D371-2645-4553-AFF9-65417FD9546F';
  public static readonly Cancelled = '';
}

export class DeliveryType {

  public static readonly Courier = '0CD93E3C-831A-4167-A091-22DAD8C19AB2';
  public static readonly Download = 'FA89B3F9-D72D-446C-8A84-DB10CA06AF17';
  public static readonly Virtual = '326A833B-4B3C-4D6C-881B-F4408ADA3B7D';
}

export class LocationStatusKeys {

  public static readonly Registering = '3A6D87D1-30E2-4F3B-A075-A9AF9C81C8AE';
  public static readonly Active = 'E4E3B44A-437E-4A7B-A467-29BFA7E6DFA6';
  public static readonly Inactive = '2F07FDE5-3BC5-4FCA-83DC-BB3E13BF267E';
  public static readonly Deleted = '5C0401AD-9C64-4E9B-9E99-993803B1786B';
}

export class LogisticType {

  public static readonly DineIn = '776F2000-E4D3-4163-8124-6A2C6514D48C';
  public static readonly CarryOut = '627AB494-4230-4A90-9E34-62F99E74D387';
  public static readonly Curbside = '222557F4-5949-4266-AEB5-14945FABE5C7';
  public static readonly Delivery = '3F46E3F1-EA40-4012-8260-CD22B5CC054C';
}

export class LogisticPaymentMethod {

  public static readonly CreditCard = 'CFDCABDE-FDBE-421D-82C5-3E2ECEAB50CF';
  public static readonly Cash = 'EBBA4BB2-053F-4C18-89BA-7D16E82B253D';
}

export class LogisticSchedule {

  public static readonly AsSoonAsPossible = 'EE6E6080-1158-45B8-9B6C-909EE001A648';
  public static readonly Scheduled = '21E063A4-90B1-479B-8801-0B0FE8017F47';
}

export class MemberStatusKeys {

  public static readonly Active = 'A6AF4206-D55E-468B-AAA5-62DFBAEC2AFA';
  public static readonly Inactive = '0323D0E4-81B0-4E5E-8EFF-D8C6B17A6CE1';
  public static readonly Deleted = '4EABCD50-F5E6-4BE1-A063-FB132887E69B';
}

export class MenuPlacement {

  public static readonly Menu = 'B8F62863-6F65-4FD7-9F29-BD8B37610E88';
  public static readonly OffMenu = 'EE202D9D-9F44-492F-8A3F-EDC23A2414AD';
  public static readonly NoSale = 'C35072A2-2B8B-4F08-9762-316544646D5F';
}

export class OrderStatusKeys {

  public static readonly Created = 'EF944C59-379E-4DBA-8D3B-27A27E995405';
  public static readonly Submitted = 'F518E374-DA58-4DC4-A410-2A2977A46FED';
  public static readonly Reviewing = '436DF48D-DD9C-478A-BA6C-042D15BCE76E';
  public static readonly Accepted = '1C58C7DB-3ED3-46D3-B2EF-D88A105C98FC';
  public static readonly Processing = '6AD2F3A8-C56F-4A92-8E8C-C21266C3C651';
  public static readonly Fulfilled = 'E5300DC2-698D-4BCB-AE4B-1871570CF5E0';
  public static readonly Closed = 'B4445AF7-0D14-4D92-80AF-32F197A4589A';
  public static readonly Cancelled = '6FF9A127-5DCD-48B3-BFDE-BFB8FEEC174F';
  public static readonly Committed = '3BB33306-B13A-4215-82EA-1FC4069A446F';
}

export class OrderItemStatusKeys {

  public static readonly Open = '160A162D-5F01-4B85-8157-A225D7143B8E';
  public static readonly Fulfilled = '56A2F6B2-386A-4FCB-833B-B960E15CC543';
  public static readonly Deleted = '855782DD-85CA-493A-932B-5764AE873272';
}

