import { Product, ProductConfigurationInclusionGroupOption, ProductConfigurationPreparation, ProductConfiguration, ProductConfigurationInclusionGroup, ProductProvider, ProductConfigurationVariation } from 'downtown-product';
import { UUID } from 'angular2-uuid';
import { EditableTransactionItem, EditableTransactionItemConfiguration, EditableTransactionItemConfigurationAddOn, EditableTransactionItemConfigurationInclusionGroup, EditableTransactionItemConfigurationInclusionGroupOption, EditableTransactionItemConfigurationPortion, EditableTransactionItemConfigurationPreparation, EditableTransactionItemConfigurationVariation } from '../../models/editable-transaction/editable-transaction';
import { flattenProduct } from 'downtown-product';

export function initTransactionItem(product: Product, portionUid: string, productProvider: ProductProvider, setDefaults: boolean): EditableTransactionItem {

  product = flattenProduct(product);

  var transactionItem = new EditableTransactionItem();
  transactionItem.departmentUid.next(product.departmentUid);
  transactionItem.categoryUid.next(product.categoryUid);
  transactionItem.productUid.next(product?.uid);
  transactionItem.productVersion.next(product?.version);
  transactionItem.configuration.next(initTransactionItemConfiguration(product, portionUid, productProvider, setDefaults));

  return transactionItem;
}

export function initTransactionItemConfiguration(product: Product, portionUid: string, productProvider: ProductProvider, setDefaults: boolean): EditableTransactionItemConfiguration {

  if (!portionUid) {
    portionUid = product?.configuration ? product.configuration.portions[0].uid : null;
  }

  var transactionItemConfiguration = new EditableTransactionItemConfiguration();
  transactionItemConfiguration.portion = initTransactionItemConfigurationPortion(product, portionUid, productProvider, setDefaults);

  return transactionItemConfiguration;
}

export function initTransactionItemConfigurationPortion(product: Product, portionUid: string, productProvider: ProductProvider, setDefaults: boolean): EditableTransactionItemConfigurationPortion {

  let configurablePortion = product.configuration.getPortion(portionUid);

  let portion = new EditableTransactionItemConfigurationPortion();
  portion.portionUid = portionUid;
  portion.preparations = configurablePortion.preparations?.length > 0 ? configurablePortion.preparations.map(x => initTransactionItemConfigurationPreparation(product.configuration, x, setDefaults)) : null;
  portion.inclusionGroups = initTransactionItemConfigurationInclusionGroups(product, portionUid, productProvider, setDefaults);

  return portion;
}

export function initTransactionItemConfigurationInclusionGroups(product: Product, portionUid: string, productProvider: ProductProvider, setDefaults: boolean): EditableTransactionItemConfigurationInclusionGroup[] {

  let configurablePortion = product.configuration.getPortion(portionUid);

  return configurablePortion.inclusionGroups?.length > 0 ? configurablePortion.inclusionGroups.map(x => initTransactionItemConfigurationInclusionGroup(product, x, productProvider, setDefaults)) : [];
}

export function initTransactionItemConfigurationInclusionGroup(product: Product, inclusionGroup: ProductConfigurationInclusionGroup, productProvider: ProductProvider, setDefaults: boolean): EditableTransactionItemConfigurationInclusionGroup {

  const configuringInclusionGroup = new EditableTransactionItemConfigurationInclusionGroup();
  configuringInclusionGroup.inclusionGroupUid = inclusionGroup.uid;
  configuringInclusionGroup.options.push(...initTransactionItemConfigurationInclusionGroupOptions(product, inclusionGroup, productProvider, setDefaults));

  return configuringInclusionGroup;
}

export function initTransactionItemConfigurationInclusionGroupOptions(product: Product, inclusionGroup: ProductConfigurationInclusionGroup, productProvider: ProductProvider, setDefaults: boolean): EditableTransactionItemConfigurationInclusionGroupOption[] {

  let defaultOptions = inclusionGroup.options?.filter(x => x.defaultQuantity > 0);

  return defaultOptions.length > 0 ? defaultOptions.map(x => initTransactionItemConfigurationInclusionGroupOption(product, x, setDefaults, false)) : [];
}

export function initTransactionItemConfigurationInclusionGroupOption(
  product: Product,
  option: ProductConfigurationInclusionGroupOption,
  setDefaults: boolean,
  isSubstitution: boolean
): EditableTransactionItemConfigurationInclusionGroupOption {

  var productReference = option.productReference;

  const configuringOption = new EditableTransactionItemConfigurationInclusionGroupOption();
  configuringOption.optionUid = option.uid;
  configuringOption.productUid = productReference?.uid;
  configuringOption.productVersion = productReference?.version;
  configuringOption.productPortionUid = productReference?.portionUid;
  configuringOption.quantity = setDefaults ? option.defaultQuantity : 0;
  configuringOption.preparations = option.preparations ? option.preparations.map(configurablePreparation => initTransactionItemConfigurationPreparation(product.configuration, configurablePreparation, setDefaults)) : null;
  configuringOption.variations = null;
  configuringOption.isSubstitution = isSubstitution;

  return configuringOption;
}

export function initTransactionItemConfigurationAddOn(product: Product, portionUid: string): EditableTransactionItemConfigurationAddOn {

  const configuringAddOn = new EditableTransactionItemConfigurationAddOn();
  configuringAddOn.addOnUid = UUID.UUID();

  return configuringAddOn;
}

export function initTransactionItemConfigurationPreparation(productConfiguration: ProductConfiguration, configurablePreparation: ProductConfigurationPreparation, setDefaults: boolean): EditableTransactionItemConfigurationPreparation {

  const configuringPreparation = new EditableTransactionItemConfigurationPreparation();
  configuringPreparation.preparationUid = configurablePreparation.uid;

  if (!configurablePreparation.allowNone && !!configurablePreparation.options) {
    configuringPreparation.optionUid = configurablePreparation.options.length > 0 ? configurablePreparation.options[0].uid : null;
  }

  return configuringPreparation;
}

export function initTransactionItemConfigurationVariation(productConfiguration: ProductConfiguration, configurableVariation: ProductConfigurationVariation, setDefaults: boolean): EditableTransactionItemConfigurationVariation {

  const configuringVariation = new EditableTransactionItemConfigurationVariation();
  configuringVariation.variationUid = configurableVariation.uid;
  configuringVariation.optionUid = configurableVariation.options && configurableVariation.options.length > 0 ? configurableVariation.options[0].uid : null;

  return configuringVariation;
}