<div *ngIf="form" [formGroup]="form">

  <div style="display: flex;">
    <div style="flex: 1;">&nbsp;</div>
    <div style="flex: 1;">Product Setting</div>
    <div *ngIf="scope == 'portionAddOn'" style="flex: 1;">Override</div>
  </div>

  <ng-template #not_assigned>Not Assigned</ng-template>
  <ng-template #none>None</ng-template>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Product</span>
    </div>
    <div *ngIf="scope == 'portionAddOn'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="validProductReference(getProductAddOnFormValue(form, 'productReference'));else not_assigned">
        <app-product-portion-label [productUid]="getProductAddOnFormValue(form, 'productReference').uid" [portionUid]="getProductAddOnFormValue(form, 'productReference').portionUid" [version]="getProductAddOnFormValue(form, 'productReference').version"></app-product-portion-label>
      </span>
    </div>
    <div style="flex: 1; margin-left: 5px;">
      <app-product-portion-button [productUid]="form.get('productReference').get('uid').value" [portionUid]="form.get('productReference').get('portionUid').value" [version]="form.get('productReference').get('version').value" (click)="showSelectProductModal(form)"></app-product-portion-button>
    </div>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Alias</span>
    </div>
    <div *ngIf="scope == 'portionAddOn'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="getProductAddOnFormValue(form, 'alias');else not_assigned">
        {{ getProductAddOnFormValue(form, 'alias') }}
      </span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="alias">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Price Override</span>
    </div>
    <div *ngIf="scope == 'portionAddOn'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="getProductAddOnFormValue(form, 'priceOverride');else not_assigned">
        {{ getProductAddOnFormValue(form, 'priceOverride') }}
      </span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="priceOverride">
    </mat-form-field>
  </div>

</div>
