import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { IPagedDataSource, hasEqualUUID, isEqualUUID } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { EmployeeStatusKeys } from "../../../keys";
import { Employee } from "../../../models/employee";
import { PosModelFactory } from "../../../pos-model-factory";
import { EmployeeService, EmployeeViewOptions } from "../../../services/employee.service";

@Component({
  selector: 'app-employees-table',
  templateUrl: './employees-table.component.html',
  styleUrls: ['./employees-table.component.scss']
})
export class EmployeesTableComponent {

  @Input() public dataSource: EmployeeDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }
}

export class EmployeeModel {

  employee: Employee;

  canEdit: boolean;
  canViewShifts: boolean;
  canEnable: boolean;
  canDisable: boolean;
  canDelete: boolean;
  canLinkIdentity: boolean;
  canUnlinkIdentity: boolean;
}

export class EmployeeDataSource extends DataSource<EmployeeModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<EmployeeModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<EmployeeModel[]>([]);

  private _canBulkEditProfile = false;
  private _canBulkEditPositions = false;
  private _canBulkEditPermissions = false;
  private _canBulkViewShifts = false;
  private _canBulkEditPayment = false;
  private _canBulkEnable = false;
  private _canBulkDisable = false;
  private _canBulkLink = false;
  private _canBulkUnlink = false;
  private _canBulkDelete = false;

  constructor(
    private employeeService: EmployeeService,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<EmployeeModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEditProfile(): boolean {
    return this._canBulkEditProfile
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<EmployeeModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(payMethodFilterUids: string[], statusUids: string[], paginationInput: PaginationInput, viewOptions: EmployeeViewOptions) {

    this.loadingSubject.next(true);

    this.employeeService.search(payMethodFilterUids, statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        const employees = page.edges.map(x => x.node).map(x => {
          let employeeModel = Object.assign(new EmployeeModel(), {
            employee: x
          });

          this.evaluateEnablement(employeeModel);

          return employeeModel;
        });

        return <[PageInfo, EmployeeModel[]]>[page.pageInfo, employees];
      }),
      catchError(() => of(<[PageInfo, EmployeeModel[]]>[null, []])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  public merge(employee: Employee) {

    if (employee) {
      var match = this.dataSubject.value.find(x => hasEqualUUID(x.employee, employee));
      if (match) {
        PosModelFactory.mergeEmployee(employee, match.employee);
        this.evaluateEnablement(match);
        this.evaluateBulkEnablement();
      }
    }
  }

  public enable(employee: EmployeeModel, authEmployee: Employee): Observable<EmployeeModel> {

    return this.employeeService.enable(employee.employee.uid, <EmployeeViewOptions>{}).pipe(
      map(x => {
        employee.employee.employeeStatusUid = x.employeeStatusUid;

        return this.evaluateEnablement(employee);
      })
    );
  }

  public disable(employee: EmployeeModel, authEmployee: Employee): Observable<EmployeeModel> {

    return this.employeeService.disable(employee.employee.uid, <EmployeeViewOptions>{}).pipe(
      map(x => {
        employee.employee.employeeStatusUid = x.employeeStatusUid;

        return this.evaluateEnablement(employee);
      })
    );
  }

  public delete(employee: EmployeeModel, authEmployee: Employee): Observable<EmployeeModel> {

    return this.employeeService.delete(employee.employee.uid, <EmployeeViewOptions>{}).pipe(
      map(x => {
        employee.employee.employeeStatusUid = x.employeeStatusUid;

        return this.evaluateEnablement(employee);
      })
    );
  }

  public updateIdentity(employee: EmployeeModel, identityUserId: string, authEmployee: Employee): Observable<EmployeeModel> {

    return this.employeeService.updateIdentity(employee.employee.uid, identityUserId).pipe(
      map(x => {
        employee.employee.identityId = x.identityId;

        return this.evaluateEnablement(employee);
      })
    );
  }

  private evaluateEnablement(employee: EmployeeModel): EmployeeModel {

    let employeeStatusUid = employee.employee.employeeStatusUid.toUpperCase();

    employee.canEdit = employeeStatusUid !== EmployeeStatusKeys.Deleted.toUpperCase();
    employee.canViewShifts = employeeStatusUid !== EmployeeStatusKeys.Deleted.toUpperCase();
    employee.canEnable = employeeStatusUid === EmployeeStatusKeys.Inactive.toUpperCase();
    employee.canDisable = employeeStatusUid === EmployeeStatusKeys.Active.toUpperCase();
    employee.canDelete = employeeStatusUid !== EmployeeStatusKeys.Deleted.toUpperCase();
    employee.canLinkIdentity = employee.employee.identityId == null;
    employee.canUnlinkIdentity = employee.employee.identityId != null;

    return employee;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEditProfile = selected.length == 1;
    this._canBulkEditPositions = selected.length == 1;
    this._canBulkEditPermissions = selected.length == 1;
    this._canBulkViewShifts = selected.length == 1;
    this._canBulkEditPayment = selected.length == 1;
    this._canBulkEnable = selected.length == 1 && selected[0].employee.employeeStatusUid.toUpperCase() === EmployeeStatusKeys.Inactive.toUpperCase();
    this._canBulkDisable = selected.length == 1 && selected[0].employee.employeeStatusUid.toUpperCase() === EmployeeStatusKeys.Active.toUpperCase();
    this._canBulkLink = selected.length == 1 && selected[0].employee.identityId == null;
    this._canBulkUnlink = selected.length == 1 && selected[0].employee.identityId != null;
    this._canBulkDelete = selected.length == 1;
  }
}
