import { Injectable } from '@angular/core';
import { Edge, Page, PaginationInput } from 'core';
import { ObservableCacheProvider } from 'core';
import { Product } from '../models/product';
import { ProductService } from '../services/product.service';
import { BehaviorSubject, Observable, concatMap, first, forkJoin, map, of, tap } from "rxjs";
import { TenantProvider } from 'core';

/**
 * Provider meant to be a cache enabled alternative to calling ProductService directly, with additional members for getting Product in different states (ie: Active).
 */
@Injectable()
export class ProductProvider {

  public currentActive$: Observable<Product[]>;

  private currentActiveSubject = new BehaviorSubject<Product[]>(null);

  constructor(
    private cacheProvider: ObservableCacheProvider,
    private tenantProvider: TenantProvider,
    private productService: ProductService,
  ) {
    this.currentActive$ = this.currentActiveSubject.asObservable();
  }

  public search$(ownerUid: string, departmentUids: string[], categoryUids: string[], statusUids: string[], paginationInput: PaginationInput, viewOptions = ProductService.ProductFullView): Observable<Page<Product>> {

    return this.productService.search(ownerUid, departmentUids, categoryUids, statusUids, paginationInput).pipe(
      concatMap(result => {
        const productIds = result.edges.map(x => x.node);
        const cacheKeys = productIds.map(x => this.getCacheKey(x));
        const missingKeys = this.cacheProvider.missingKeys(cacheKeys);
        const missingUids = missingKeys.map(x => this.getUidFromKey(x));

        return missingUids.length == 0 ? of(result) : this.productService.getByUids(missingUids, viewOptions).pipe(
          tap(results => {
            results.forEach(result => {
              this.cacheProvider.addOrUpdate(this.getCacheKey(result.uid), of(result)); // TODO: Add test if this is latest version
              this.cacheProvider.addOrUpdate(this.getCacheKey(result.uid, result.version), of(result));
            });
          }),
          map(_ => result)
        );
      }),
      first(),
      concatMap(results => {
        if (results.edges.length == 0) {
          return of(<Page<Product>>{ pageInfo: results.pageInfo, totalCount: results.totalCount, edges: [] });
        }

        return forkJoin(
          results.edges.map(x => x.node).map(x => this.cacheProvider.get<Product>(this.getCacheKey(x)))
        ).pipe(
          map(values => {
            return <Page<Product>>(<Page<Product>>{
              pageInfo: results.pageInfo,
              totalCount: results.totalCount,
              edges: values.map(x => <Edge<Product>>{ node: x })
            });
          })
        );
      }),
      first()
    );
  }

  public getOneCached$(uid: string, version: number = null): Observable<Product> {

    return this.cacheProvider.getOrAdd(this.getCacheKey(uid, version), () => this.getOne$(uid, version))
  }

  public getOne$(uid: string, version: number = null): Observable<Product> {

    return this.productService.getByUid(uid, version)
  }

  public getManyCached$(uids: string[]): Observable<Product[]> {

    if (!uids?.length) {
      return of([]);
    }

    const cacheKeys = uids.map(x => this.getCacheKey(x));
    const missingKeys = this.cacheProvider.missingKeys(cacheKeys);
    const missingUids = missingKeys.map(x => this.getUidFromKey(x));

    return (missingUids.length == 0 ? of([]) : this.productService.getByUids(missingUids)).pipe(
      tap(products => {
        products.forEach(product => {
          this.cacheProvider.addOrUpdate(this.getCacheKey(product.uid), of(product)); // TODO: Add test if this is latest version
          this.cacheProvider.addOrUpdate(this.getCacheKey(product.uid, product.version), of(product));
        });
      }),
      concatMap(_ => {
        return forkJoin(
          uids.map(x => {
            return this.cacheProvider.get<Product>(this.getCacheKey(x));
          })
        );
      }),
      first()
    );
  }

  private getCacheKey(uid: string, version: number = null): string {

    return `Product_${uid}${version != null ? `_${version}` : ''}`;
  }

  private getUidFromKey(key: string): string {

    const segments = key.split('_');
    return segments[1];
  }
}
