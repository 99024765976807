<div *ngIf="form" [formGroup]="form">

  <h2>Logistic Settings</h2>

  <div style="display: inline-block; min-width: 500px; max-width: 900px;">

    <div style="display: flex;">

      <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <span style="flex: 1;">Available Methods</span>
      </div>
      <div style="flex: 2;">
        <div><mat-checkbox formControlName="dineIn">Dine In</mat-checkbox></div>
        <div><mat-checkbox formControlName="carryOut">Carry Out</mat-checkbox></div>
        <div><mat-checkbox formControlName="curbsidePickup">Curbside Pickup</mat-checkbox></div>
        <div>
          <mat-checkbox formControlName="delivery">Delivery</mat-checkbox>

          <div style="display: flex;">
            <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
              <label for="delivery-charge">Delivery Charge</label>
            </div>
            <mat-form-field style="flex: 1;">
              <input id="delivery-charge" matInput currencyMask [options]="{ allowNegative: false }" type="text" formControlName="deliveryCharge">
            </mat-form-field>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
