<div style="height: 100%; overflow-y: auto;">

  <p class="lead">UPTOWN DARTS</p>

  <div style="padding-left: 30px; padding-right: 30px; display: flex;">
    <div style="flex: 5;">
      <img src="{{ this.runtimeProvider.assetBaseUrl + 'images/darts.jpg' }}" style="width: 100%;" />
    </div>
    <div style="flex: 7;">
      <p>Uptown Bar sponsors fall dart leagues. Stop in if your team is seeking sponsorship.</p>
    </div>
  </div>

  <div style="display: flex;">
    <div style="flex: 7;">
      <p>Monthly Triplespot tournament. Check back here or in house for scheduling, rules, and payouts.</p>
    </div>
    <div style="flex: 5;">
      <img src="{{ this.runtimeProvider.assetBaseUrl + 'images/dartbrackets.png' }}" style="width: 100%;" />
    </div>
  </div>

</div>
