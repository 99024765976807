import { TransactionStatusEnum } from "../../keys";
import { BehaviorSubject, Subscription } from 'rxjs';
import { EditableTransactionTypes } from './editable-transaction-data-handler';
import { Metadata, isCaseInsensitiveEqual, isEqualUUID } from "core";
import Decimal from "decimal.js";

export interface HasPreparations {

  preparations?: EditableTransactionItemConfigurationPreparation[];
}

export interface HasVariations {

  variations?: EditableTransactionItemConfigurationVariation[];
}

export class EditableTransaction {

  public selectedLineItem = new BehaviorSubject<EditableTransactionTypes>(null);

  public uid: string;
  public number = new BehaviorSubject<string>(null);
  public openDateTimeUtc: Date;
  public openAuthUid: string;
  public closeDateTimeUtc: Date;
  public closeAuthUid: string;
  public lockUid = new BehaviorSubject<string>(null);
  public logisticTypeUid: string;
  public lastGuestUid: string;
  public notes = new BehaviorSubject<string>(null);
  public holdCardReference = new BehaviorSubject<string>(null);
  public total = new BehaviorSubject<number>(null);
  public transactionStatus = new BehaviorSubject<TransactionStatusEnum>(TransactionStatusEnum.Open);
  public totalPayments = new BehaviorSubject<number>(null);
  public change = new BehaviorSubject<number>(null);
  public totalDue = new BehaviorSubject<number>(null);

  public guests = <EditableTransactionGuest[]>[];
  public lineItems = <EditableTransactionItem[]>[];
  public charges = <EditableTransactionCharge[]>[];
  public adjustments = <EditableTransactionAdjustment[]>[];
  public payments = <EditableTransactionPayment[]>[];
  public canEditNotes = false;
  public canEditLocation = false;

  private subscriptionMap: { [index: string]: Subscription } = {};

  constructor() {

    this.transactionStatus.subscribe(() => {
      this.evaluateState();
    });

    this.evaluateState();
  }

  public addTransactionGuest(guest?: EditableTransactionGuest): EditableTransactionGuest {

    guest = guest || new EditableTransactionGuest();
    this.guests.push(guest);

    return guest;
  }

  public addTransactionItem(transactionLineItem?: EditableTransactionItem): EditableTransactionItem {

    transactionLineItem = transactionLineItem || new EditableTransactionItem();
    this.lineItems.push(transactionLineItem);

    this.subscriptionMap[`sale_${this.lineItems.indexOf(transactionLineItem)}`] = transactionLineItem.total.subscribe(() => {
      this.calculateTotals();
    });

    return transactionLineItem;
  }

  public resetTransactionItem(transactionLineItem: EditableTransactionItem) {

    transactionLineItem.restoreSnapshot();
  }

  public removeTransactionItem(transactionLineItem: EditableTransactionItem) {

    let lineItems = this.lineItems;
    let index = lineItems.indexOf(transactionLineItem);

    let subscription = this.subscriptionMap[`sale_${index}`];
    if (subscription) {
      subscription.unsubscribe();
    }

    lineItems.splice(index, 1);

    this.calculateTotals();
  }

  public addTransactionCharge(): EditableTransactionCharge {

    const transactionChargeLineItem = new EditableTransactionCharge();
    this.charges.push(transactionChargeLineItem);

    this.subscriptionMap[`charge_${this.charges.indexOf(transactionChargeLineItem)}`] = transactionChargeLineItem.total.subscribe(() => {
      this.calculateTotals();
    });

    return transactionChargeLineItem;
  }

  public resetTransactionCharge(transactionCharge: EditableTransactionCharge) {

    transactionCharge.restoreSnapshot();
  }

  public removeTransactionCharge(transactionCharge: EditableTransactionCharge) {

    let index = this.charges.indexOf(transactionCharge);

    let subscription = this.subscriptionMap[`charge_${index}`];
    if (subscription) {
      subscription.unsubscribe();
    }

    this.charges.splice(index, 1);

    this.calculateTotals();
  }

  public addTransactionAdjustment(): EditableTransactionAdjustment {

    const transactionAdjustmentLineItem = new EditableTransactionAdjustment();
    this.adjustments.push(transactionAdjustmentLineItem);

    this.subscriptionMap[`adjustment_${this.adjustments.indexOf(transactionAdjustmentLineItem)}`] = transactionAdjustmentLineItem.total.subscribe(() => {
      this.calculateTotals();
    });

    return transactionAdjustmentLineItem;
  }

  public resetTransactionAdjustment(transactionAdjustment: EditableTransactionAdjustment) {

    transactionAdjustment.restoreSnapshot();
  }

  public removeTransactionAdjustment(transactionAdjustmentLineItem: EditableTransactionAdjustment) {

    let index = this.adjustments.indexOf(transactionAdjustmentLineItem);

    let subscription = this.subscriptionMap[`adjustment_${index}`];
    if (subscription) {
      subscription.unsubscribe();
    }

    this.adjustments.splice(index, 1);

    this.calculateTotals();
  }

  public addPayment(): EditableTransactionPayment {

    const paymentLineItem = new EditableTransactionPayment();

    paymentLineItem.total.subscribe(() => {
      this.calculateTotals();
    });

    this.payments.push(paymentLineItem);

    this.subscriptionMap[`payment_${this.payments.indexOf(paymentLineItem)}`] = paymentLineItem.total.subscribe(() => {
      this.calculateTotals();
    });

    return paymentLineItem;
  }

  public resetPayment(paymentLineItem: EditableTransactionPayment) {

    paymentLineItem.restoreSnapshot();
  }

  public removePayment(paymentLineItem: EditableTransactionPayment) {

    let index = this.payments.indexOf(paymentLineItem);

    let subscription = this.subscriptionMap[`payment_${index}`];
    if (subscription) {
      subscription.unsubscribe();
    }

    this.payments.splice(index, 1);

    this.calculateTotals();
  }

  getChange(): number {
    return this.getSaleTotal() >= this.getSubtractionsTotal() ? null : this.getSubtractionsTotal() - this.getSaleTotal();
  }

  getTotalDue(): number {
    return this.getSaleTotal() >= this.getSubtractionsTotal() ? this.getSaleTotal() - this.getSubtractionsTotal() : 0;
  }

  getTotalSale(): number {
    return this.getSaleTotal();
  }

  private getSaleTotal() {

    const itemsTotal = this.lineItems.map(x => {
      const adjustments = x.adjustments.length == 0 ? 0 : x.adjustments.map(x => x.total.value).reduce((sum, total) => sum + total);
      return x.total.value - adjustments;
    }).reduce((sum, total) => sum + total, 0);

    const chargesTotal = this.charges.map(x => x.total.value).reduce((sum, total) => sum + total, 0);

    return itemsTotal + chargesTotal;
  }

  private getPaymentsTotal() {

    return this.payments.length == 0 ? 0 : this.payments.map(x => x.total.value).reduce((sum, amount) => sum + amount);
  }

  private getAdjustmentsTotal() {

    return this.adjustments.length == 0 ? 0 : this.adjustments.map(x => x.total.value).reduce((sum, amount) => sum + amount);
  }

  private getSubtractionsTotal() {

    return this.getPaymentsTotal() + this.getAdjustmentsTotal();
  }

  private calculateTotals() {

    const itemsTotal = this.lineItems.map(x => {
      const adjustments = x.adjustments.map(x => x.total.value).reduce((sum, total) => sum + total, 0);
      return x.total.value - adjustments;
    }).reduce((sum, total) => sum + total, 0);

    const chargesTotal = this.charges.map(x => {
      const adjustments = x.adjustments.map(x => x.total.value).reduce((sum, total) => sum + total, 0);
      return x.total.value - adjustments;
    }).reduce((sum, total) => sum + total, 0);

    const saleTotal = itemsTotal + chargesTotal;

    this.total.next(saleTotal);

    const paymentsTotal = this.payments.map(x => x.getAmount()).reduce((sum, amount) => sum + amount, 0);
    const adjustmentsTotal = this.adjustments.map(x => x.getAmount()).reduce((sum, amount) => sum + amount, 0);

    const subtractionsTotal = paymentsTotal + adjustmentsTotal;

    if (saleTotal >= subtractionsTotal) {
      this.totalDue.next(saleTotal - subtractionsTotal);
      this.change.next(null);
    } else {
      this.totalDue.next(null);
      this.change.next(subtractionsTotal - saleTotal);
    }
  }

  private evaluateState() {

    this.canEditNotes = isCaseInsensitiveEqual(this.transactionStatus.value, TransactionStatusEnum.Open);
    this.canEditLocation = isCaseInsensitiveEqual(this.transactionStatus.value, TransactionStatusEnum.Open);
  }
}

export class EditableTransactionGuest {

  public uid?: string;
  public name: string;
}

export class EditableTransactionItem {

  public uid?: string;
  public alias = new BehaviorSubject<string>(null);
  public departmentUid = new BehaviorSubject<string>(null);
  public categoryUid = new BehaviorSubject<string>(null);
  public productUid = new BehaviorSubject<string>(null);
  public productVersion = new BehaviorSubject<number>(null);

  public guestUid = new BehaviorSubject<string>(null);
  public configuration = new BehaviorSubject<EditableTransactionItemConfiguration>(null);
  public quantity = new BehaviorSubject<number>(1);
  public eachAmountText = new BehaviorSubject<string>(new Decimal(0).toFixed(2));
  public notes = <string[]>[];
  public total = new BehaviorSubject<number>(0);
  public adjustments = <EditableTransactionItemAdjustment[]>[];
  public isValid: boolean;
  public isDirty: boolean;
  public canEdit = false;
  public canVoid = false;
  public canComp = false;
  public canCancel = false;
  public canPrint = false;
  public canGuest = false;

  private snapShot: any;
  private subscriptionMap: { [index: string]: Subscription } = {};

  constructor(

  ) {
    this.takeSnapshot();

    this.departmentUid.subscribe(() => {
      this.evaluateState();
    });
    this.categoryUid.subscribe(() => {
      this.evaluateState();
    });
    this.guestUid.subscribe(() => {
      this.evaluateState();
    });
    this.configuration.subscribe(() => {
      this.evaluateState();
    });
    this.quantity.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });
    this.eachAmountText.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });

    this.calculateTotals();
    this.evaluateState();
  }

  public getQuantity(): number {

    return this.quantity.value;
  }

  public getEachAmount(): number {

    return new Decimal(this.eachAmountText.value).toNumber();
  }

  public addTransactionItemAdjustment(): EditableTransactionItemAdjustment {

    const transactionLineItemAdjustment = new EditableTransactionItemAdjustment();
    transactionLineItemAdjustment.transactionLineItemUid = this.uid;

    this.adjustments.push(transactionLineItemAdjustment);

    this.subscriptionMap[`comp_${this.adjustments.indexOf(transactionLineItemAdjustment)}`] = transactionLineItemAdjustment.total.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });

    return transactionLineItemAdjustment;
  }

  public resetTransactionItemAdjustment(transactionLineItemAdjustment: EditableTransactionItemAdjustment) {

    transactionLineItemAdjustment.restoreSnapshot();
  }

  public removeTransactionItemAdjustment(transactionLineItemAdjustment: EditableTransactionItemAdjustment) {

    let index = this.adjustments.indexOf(transactionLineItemAdjustment);

    let subscription = this.subscriptionMap[`comp_${index}`];
    if (subscription) {
      subscription.unsubscribe();
    }

    this.adjustments.splice(index, 1);

    this.calculateTotals();
    this.evaluateState();
  }

  public takeSnapshot() {

    this.snapShot = {
      departmentUid: this.departmentUid.value,
      categoryUid: this.categoryUid.value,
      guestUid: this.guestUid.value,
      configuration: this.configuration.value,
      quantity: this.quantity.value,
      eachAmountText: this.eachAmountText.value,
    };

    this.evaluateState();
  }

  public restoreSnapshot() {

    this.departmentUid.next(this.snapShot.departmentUid);
    this.categoryUid.next(this.snapShot.categoryUid);
    this.guestUid.next(this.snapShot.guestUid);
    this.configuration.next(this.snapShot.configuration);
    this.quantity.next(this.snapShot.quantity);
    this.eachAmountText.next(this.snapShot.eachAmountText);

    this.evaluateState();
  }

  private calculateTotals() {

    const eachAmount = this.getEachAmount();
    const quantity = this.getQuantity();

    this.total.next(eachAmount * quantity);
  }

  private evaluateState() {

    this.isValid = this.departmentUid.value != null && this.getQuantity() > 0 && this.getEachAmount() > 0;

    this.isDirty = this.departmentUid.value != this.snapShot.departmentUid;
    this.isDirty = this.categoryUid.value != this.snapShot.categoryUid;
    this.isDirty = this.guestUid.value != this.snapShot.guestUid;
    this.isDirty = this.isDirty || this.configuration.value != this.snapShot.configuration;
    this.isDirty = this.isDirty || this.quantity.value != this.snapShot.quantity;
    this.isDirty = this.isDirty || this.eachAmountText.value != this.snapShot.eachAmountText;

    let adjustmentsTotal = Math.abs(this.adjustments.map(x => x.total.value).reduce((sum, value) => sum + value, 0));

    this.canEdit = this.uid != null && this.configuration.value != null; // is not already voided, and not changed if already committed
    this.canVoid = this.uid != null && (adjustmentsTotal == 0); // is not already voided, and not changed if already committed
    this.canComp = this.isValid && (adjustmentsTotal < this.total.value); // was committed, and is not already voided
    this.canCancel = this.isDirty || (this.uid == null && !this.isValid);
    this.canPrint = this.configuration.value && this.isValid && !this.isDirty;
    this.canGuest = this.isValid && !this.isDirty;
  }
}

export class EditableTransactionItemConfiguration {

  public portion: EditableTransactionItemConfigurationPortion;
}

export class EditableTransactionItemConfigurationPortion implements HasPreparations, HasVariations {

  portionUid: string;
  preparations?: EditableTransactionItemConfigurationPreparation[];
  variations?: EditableTransactionItemConfigurationVariation[];
  inclusionGroups: EditableTransactionItemConfigurationInclusionGroup[];
  addOns?: EditableTransactionItemConfigurationAddOn[];
  notes?: string[];

  public getConfiguringInclusionGroup?(inclusionGroupUid: string): EditableTransactionItemConfigurationInclusionGroup {

    return this.inclusionGroups ? this.inclusionGroups.find(x => isEqualUUID(x.inclusionGroupUid, inclusionGroupUid)) : null;
  }
}

export class EditableTransactionItemConfigurationPreparation {

  preparationUid: string;
  optionUid: string;
}

export class EditableTransactionItemConfigurationVariation {

  variationUid: string;
  optionUid: string;
  productUid: string;
  productVersion: number;
  productPortionUid: string;
}

export class EditableTransactionItemConfigurationInclusionGroup {

  private _options: EditableTransactionItemConfigurationInclusionGroupOption[];

  public inclusionGroupUid: string;

  public get options(): EditableTransactionItemConfigurationInclusionGroupOption[] {

    this._options = this._options || [];

    return this._options;
  }

  public set options(value: EditableTransactionItemConfigurationInclusionGroupOption[]) {

    this._options = value || [];
  }
}

export class EditableTransactionItemConfigurationInclusionGroupOption implements HasPreparations, HasVariations {

  optionUid: string;
  productUid: string;
  productVersion: number;
  productPortionUid: string;
  quantity: number;
  isSubstitution: boolean;
  preparations?: EditableTransactionItemConfigurationPreparation[];
  variations?: EditableTransactionItemConfigurationVariation[];
  notes?: string[];

  public getPreparation?(preparationUid: string): EditableTransactionItemConfigurationPreparation {

    return this.preparations ? this.preparations.find(x => isEqualUUID(x.preparationUid, preparationUid)) : null;
  }

  public getVariation?(variationUid: string): EditableTransactionItemConfigurationVariation {

    return this.variations ? this.variations.find(x => isEqualUUID(x.variationUid, variationUid)) : null;
  }
}

export class EditableTransactionItemConfigurationAddOn {

  addOnUid: string;
  item: EditableTransactionItem;
}

export class EditableTransactionItemAdjustment {

  public uid: string;
  public transactionLineItemUid: string;
  public description: string;
  public quantity = new BehaviorSubject<number>(1);
  public eachAmountText = new BehaviorSubject<string>(new Decimal(0).toFixed(2));
  public total = new BehaviorSubject<number>(0);
  public isVoid = new BehaviorSubject<boolean>(false);
  public isCompensated = new BehaviorSubject<boolean>(false);

  public isValid: boolean;
  public isDirty: boolean;
  public canCancel = false;
  public canVoid = false;
  public validationMessage: string;
  private snapShot: any;

  constructor(

  ) {
    this.takeSnapshot();

    this.quantity.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });
    this.eachAmountText.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });

    this.calculateTotals();
    this.evaluateState();
  }

  public getQuantity(): number {

    return this.quantity.value;
  }

  public getEachAmount(): number {

    return new Decimal(this.eachAmountText.value).toNumber();
  }

  public takeSnapshot() {

    this.snapShot = {
      description: this.description,
      quantity: this.quantity.value,
      eachAmountText: this.eachAmountText.value
    };

    this.evaluateState();
  }

  public restoreSnapshot() {

    this.description = this.snapShot.description;
    this.quantity.next(this.snapShot.quantity);
    this.eachAmountText.next(this.snapShot.eachAmountText);

    this.evaluateState();
  }

  private calculateTotals() {

    const eachAmount = this.getEachAmount();
    const quantity = this.getQuantity();

    this.total.next(eachAmount * quantity);
  }

  private evaluateState() {

    this.isValid = (this.description != null || this.description != null) && this.getQuantity() > 0 && this.getEachAmount() > 0;

    this.isDirty = this.description != this.snapShot.description;
    this.isDirty = this.isDirty || this.quantity.value != this.snapShot.quantity;
    this.isDirty = this.isDirty || this.eachAmountText.value != this.snapShot.eachAmountText;

    this.canVoid = this.uid != null;// && this.lineItemAdjustmentsList.All(x => !x.isVoid.value); // is not already voided, and not changed if already committed
    this.canCancel = this.isDirty;
  }
}

export class EditableTransactionCharge {

  public uid: string;
  public chargeTypeUid = new BehaviorSubject<string>(null);
  public description: string;
  public quantity = new BehaviorSubject<number>(1);
  public eachAmountText = new BehaviorSubject<string>(new Decimal(0).toFixed(2));
  public total = new BehaviorSubject<number>(0);
  public adjustments = <EditableTransactionChargeAdjustment[]>[];

  public isValid: boolean;
  public isDirty: boolean;
  public canVoid = false;
  public canComp = false;
  public canCancel = false;
  public validationMessage: string;

  private snapShot: any;
  private subscriptionMap: { [index: string]: Subscription } = {};

  constructor(

  ) {
    this.takeSnapshot();

    this.chargeTypeUid.subscribe(() => {
      this.evaluateState();
    });
    this.quantity.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });
    this.eachAmountText.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });

    this.calculateTotals();
    this.evaluateState();
  }

  public getQuantity(): number {

    return this.quantity.value;
  }

  public getEachAmount(): number {

    return new Decimal(this.eachAmountText.value).toNumber();
  }

  public addTransactionChargeAdjustment(): EditableTransactionChargeAdjustment {

    const transactionChargeAdjustment = new EditableTransactionChargeAdjustment();
    transactionChargeAdjustment.transactionChargeUid = this.uid;

    this.adjustments.push(transactionChargeAdjustment);

    this.subscriptionMap[`comp_${this.adjustments.indexOf(transactionChargeAdjustment)}`] = transactionChargeAdjustment.total.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });

    return transactionChargeAdjustment;
  }

  public resetTransactionChargeAdjustment(transactionChargeAdjustment: EditableTransactionChargeAdjustment) {

    transactionChargeAdjustment.restoreSnapshot();
  }

  public removeTransactionChargeAdjustment(transactionChargeAdjustment: EditableTransactionChargeAdjustment) {

    let index = this.adjustments.indexOf(transactionChargeAdjustment);

    let subscription = this.subscriptionMap[`comp_${index}`];
    if (subscription) {
      subscription.unsubscribe();
    }

    this.adjustments.splice(index, 1);

    this.calculateTotals();
    this.evaluateState();
  }

  public takeSnapshot() {

    this.snapShot = {
      chargeTypeUid: this.chargeTypeUid,
      description: this.description,
      quantity: this.quantity.value,
      eachAmountText: this.eachAmountText.value
    };

    this.evaluateState();
  }

  public restoreSnapshot() {

    this.chargeTypeUid = this.snapShot.chargeTypeUid;
    this.description = this.snapShot.description;
    this.quantity.next(this.snapShot.quantity);
    this.eachAmountText.next(this.snapShot.eachAmountText);

    this.evaluateState();
  }

  private calculateTotals() {

    const eachAmount = this.getEachAmount();
    const quantity = this.getQuantity();

    this.total.next(eachAmount * quantity);
  }

  private evaluateState() {

    this.isValid = this.chargeTypeUid != null && this.description != null && this.getQuantity() > 0 && this.getEachAmount() > 0;

    this.isDirty = this.description != this.snapShot.description;
    this.isDirty = this.isDirty || this.quantity.value != this.snapShot.quantity;
    this.isDirty = this.isDirty || this.eachAmountText.value != this.snapShot.eachAmountText;

    let adjustmentsTotal = Math.abs(this.adjustments.map(x => x.total.value).reduce((sum, value) => sum + value, 0));

    this.canVoid = this.uid != null;// && this.lineItemAdjustmentsList.All(x => !x.isVoid.value); // is not already voided, and not changed if already committed
    this.canComp = this.isValid && (adjustmentsTotal < this.total.value); // was committed, and is not already voided
    this.canCancel = this.uid == null || this.isDirty;
  }
}

export class EditableTransactionChargeAdjustment {

  public uid: string;
  public transactionChargeUid: string;
  public description: string;
  public quantity = new BehaviorSubject<number>(1);
  public eachAmountText = new BehaviorSubject<string>(new Decimal(0).toFixed(2));
  public total = new BehaviorSubject<number>(0);
  public isVoid = new BehaviorSubject<boolean>(false);
  public isCompensated = new BehaviorSubject<boolean>(false);

  public isValid: boolean;
  public isDirty: boolean;
  public canCancel = false;
  public canVoid = false;
  public validationMessage: string;
  private snapShot: any;

  constructor(

  ) {
    this.takeSnapshot();

    this.quantity.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });
    this.eachAmountText.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });

    this.calculateTotals();
    this.evaluateState();
  }

  public getQuantity(): number {

    return this.quantity.value;
  }

  public getEachAmount(): number {

    return new Decimal(this.eachAmountText.value).toNumber();
  }

  public takeSnapshot() {

    this.snapShot = {
      description: this.description,
      quantity: this.quantity.value,
      eachAmountText: this.eachAmountText.value
    };

    this.evaluateState();
  }

  public restoreSnapshot() {

    this.description = this.snapShot.description;
    this.quantity.next(this.snapShot.quantity);
    this.eachAmountText.next(this.snapShot.eachAmountText);

    this.evaluateState();
  }

  private calculateTotals() {

    const eachAmount = this.getEachAmount();
    const quantity = this.getQuantity();

    this.total.next(eachAmount * quantity);
  }

  private evaluateState() {

    this.isValid = (this.description != null || this.description != null) && this.getQuantity() > 0 && this.getEachAmount() > 0;

    this.isDirty = this.description != this.snapShot.description;
    this.isDirty = this.isDirty || this.quantity.value != this.snapShot.quantity;
    this.isDirty = this.isDirty || this.eachAmountText.value != this.snapShot.eachAmountText;

    this.canVoid = this.uid != null;// && this.lineItemAdjustmentsList.All(x => !x.isVoid.value); // is not already voided, and not changed if already committed
    this.canCancel = this.isDirty;
  }
}

export class EditableTransactionAdjustment {

  public uid: string;
  public description = new BehaviorSubject<string>('');
  public amountText = new BehaviorSubject<string>(new Decimal(0).toFixed(2));
  public total = new BehaviorSubject<number>(null);

  public isValid: boolean;
  public isDirty: boolean;
  public canCancel = false;
  public canVoid = false;
  public validationMessage: string;
  private snapShot: any;

  constructor(

  ) {
    this.takeSnapshot();

    this.description.subscribe(() => {
      this.evaluateState();
    });
    this.amountText.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });

    this.calculateTotals();
    this.evaluateState();
  }

  public getAmount(): number {

    return new Decimal(this.amountText.value).toNumber();
  }

  public takeSnapshot() {

    this.snapShot = {
      description: this.description.value,
      amountText: this.amountText.value
    };

    this.evaluateState();
  }

  public restoreSnapshot() {

    this.description.next(this.snapShot.description);
    this.amountText.next(this.snapShot.amountText);

    this.evaluateState();
  }

  private calculateTotals() {

    const amount = this.getAmount();

    this.total.next(amount);
  }

  private evaluateState() {

    this.isValid = (this.description != null || this.description != null) && this.getAmount() > 0;

    this.isDirty = this.description.value != this.snapShot.description;
    this.isDirty = this.isDirty || this.amountText.value != this.snapShot.amountText;

    this.canVoid = this.uid != null;// && this.lineItemAdjustmentsList.All(x => !x.isVoid.value); // is not already voided, and not changed if already committed
    this.canCancel = this.isDirty;
  }
}

export class EditableTransactionPayment {

  public uid: string;
  public paymentMethodUid = new BehaviorSubject<string>(null);
  public referenceUid = new BehaviorSubject<string>(null);
  public amountText = new BehaviorSubject<string>(new Decimal(0).toFixed(2));
  public total = new BehaviorSubject<number>(0);
  public employeeUid = new BehaviorSubject<string>(null);
  public metadata: Metadata[];

  public isValid: boolean;
  public isDirty: boolean;
  public canCancel = false;
  public canVoid = false;
  private snapShot: any;

  // cardTransaction: new FormArray([]),
  constructor(

  ) {
    this.takeSnapshot();

    this.paymentMethodUid.subscribe(() => {
      this.evaluateState();
    });
    this.amountText.subscribe(() => {
      this.calculateLineItemTotal();
      this.evaluateState();
    });

    this.calculateLineItemTotal();
    this.evaluateState();
  }

  public getAmount(): number {

    return new Decimal(this.amountText.value).toNumber();
  }

  public takeSnapshot() {

    this.snapShot = {
      paymentMethodUid: this.paymentMethodUid.value,
      referenceUid: this.referenceUid.value,
      amountText: this.amountText.value,
      employeeUid: this.employeeUid.value,
    };

    this.evaluateState();
  }

  public restoreSnapshot() {

    this.paymentMethodUid.next(this.snapShot.paymentMethodUid);
    this.referenceUid.next(this.snapShot.referenceUid);
    this.amountText.next(this.snapShot.amountText);
    this.employeeUid.next(this.snapShot.employeeUid);

    this.evaluateState();
  }

  public addOrUpdateMetadata(key: string, value: string) {

    this.metadata = this.metadata || [];

    let metadataEntry = this.metadata.find(x => x.key == key);
    if (metadataEntry == null) {
      metadataEntry = { key: key, value: null };
      this.metadata.push(metadataEntry);
    }
    metadataEntry.value = value;
  }

  private calculateLineItemTotal() {

    const amount = this.getAmount();

    this.total.next(amount);
  }

  private evaluateState() {

    this.isValid = (this.paymentMethodUid.value != null || this.getAmount() > 0);

    this.isDirty = this.paymentMethodUid.value != this.snapShot.paymentMethodUid;
    this.isDirty = this.isDirty || this.referenceUid.value != this.snapShot.referenceUid;
    this.isDirty = this.isDirty || this.amountText.value != this.snapShot.amountText;
    this.isDirty = this.isDirty || this.employeeUid.value != this.snapShot.employeeUid;

    this.canVoid = this.uid != null;
    this.canCancel = this.isDirty;
  }
}
