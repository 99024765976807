<div class="top-navigation" style="display: flex;">
  <div class="branding-container" (click)="navigateHome()" style="margin-right: 20px;">
    <h1>UPTOWN BAR</h1>
  </div>

  <div class="normal-menu-container">
    <div class="normal-menu">
      <button mat-button style="margin-right: 20px;" (click)="navigateToMenu()">
        <h3>MENU</h3>
      </button>

      <!-- <button *ngIf="canOrderOnline" mat-button (click)="navigateToIntegratedStore()">
        <h3>ORDER ONLINE NOW (Integrated)</h3>
      </button> -->

      <button *ngIf="canOrderOnline" mat-button (click)="navigateToHostedStore()">
        <h3>ORDER ONLINE NOW</h3>
      </button>
    </div>
  </div>

  <div class="small-menu-container">
    <div class="login-menu">
      <button mat-button *ngIf="!(authIdentity$ | async) && (identityServerAvailable | async)" (click)="navigateToLogin()">
        REGISTER/LOG IN
      </button>

      <div *ngIf="authIdentity$ | async">
        <button mat-button [matMenuTriggerFor]="employeeMenu" *ngIf="isEmployee | async">
          <h4>EMPLOYEE</h4>
        </button>

        <button mat-button [matMenuTriggerFor]="backOfficeMenu" *ngIf="isManager | async">
          <h4>BACK OFFICE</h4>
        </button>

        <button mat-button [matMenuTriggerFor]="adminMenu" *ngIf="isAdmin | async">
          <h4>ADMIN</h4>
        </button>

        <button mat-button [matMenuTriggerFor]="profileMenu">
          <h4 *ngIf="(authMember$ | async) != null">{{ (authMember$ | async).getDisplayableName() }}</h4>
          <h4 *ngIf="(authMember$ | async) == null">{{ username$ | async }}</h4>
        </button>
      </div>
    </div>

    <div class="small-menu">
      <button mat-icon-button [matMenuTriggerFor]="smallMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-menu #profileMenu="matMenu" xPosition="before">
  <button mat-menu-item *ngIf="authMember$ | async" (click)="navigateToMemberProfile()">MEMBER PROFILE</button>
  <button mat-menu-item (click)="navigateToChangePassword()">CHANGE PASSWORD</button>
  <button mat-menu-item (click)="navigateToLogout()">LOG OUT</button>
</mat-menu>

<mat-menu #employeeMenu="matMenu">
  <!-- <button mat-menu-item (click)="navigateToEmployeeDashboard()">DASHBOARD</button> -->
  <button mat-menu-item (click)="navigateToEmployeeShifts()">SHIFTS</button>
  <button mat-menu-item (click)="navigateToEmployeeProfile()">PROFILE</button>
</mat-menu>

<mat-menu #backOfficeMenu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="humanResourceMenu">HR</button>
  <button mat-menu-item [matMenuTriggerFor]="accountingMenu">ACCOUNTING</button>
  <button mat-menu-item [matMenuTriggerFor]="productsMenu">PRODUCTS</button>
  <button mat-menu-item [matMenuTriggerFor]="storeMenu">STORE</button>
</mat-menu>

<mat-menu #humanResourceMenu="matMenu">
  <button mat-menu-item (click)="navigateToPositions()">POSITIONS</button>
  <button mat-menu-item (click)="navigateToEmployees()">EMPLOYEES</button>
  <button mat-menu-item (click)="navigateToBackOfficeEmployeeShifts()">SHIFTS</button>
  <button mat-menu-item (click)="navigateToPayPeriods()">PAY PERIODS</button>
</mat-menu>

<mat-menu #accountingMenu="matMenu">
  <button mat-menu-item (click)="navigateToTransactions()">TRANSACTIONS</button>
  <button mat-menu-item (click)="navigateToRegister()">REGISTER</button>
  <button mat-menu-item (click)="navigateToDrawers()">DRAWERS</button>
  <button mat-menu-item (click)="navigateToAccounts()">ACCOUNTS</button>
  <button mat-menu-item (click)="navigateToReports()">REPORTS</button>
</mat-menu>

<mat-menu #productsMenu="matMenu">
  <button mat-menu-item (click)="navigateToDepartments()">DEPARTMENTS</button>
  <button mat-menu-item (click)="navigateToProducts()">PRODUCTS</button>
</mat-menu>

<mat-menu #storeMenu="matMenu">
  <!-- <button mat-menu-item (click)="navigateToDepartments()">DEPARTMENTS</button>
  <button mat-menu-item (click)="navigateToProducts()">PRODUCTS</button>
  <button mat-menu-item (click)="navigateToOrders()">ORDERS</button>
  <button mat-menu-item (click)="navigateToCardTransactions()">CARD TRANSACTIONS</button>
  <button mat-menu-item (click)="navigateToCardTransactionBatches()">CARD TRANSACTION BATCHES</button> -->
</mat-menu>

<mat-menu #adminMenu="matMenu">
  <button mat-menu-item (click)="navigateToAdminDashboard()">DASHBOARD</button>
  <!-- <button mat-menu-item (click)="navigateToSystemMessages()">MESSAGES</button> -->
  <button mat-menu-item (click)="navigateToAdministrationDam()">DAM</button>
  <button mat-menu-item (click)="navigateToAdministrationMembers()">MEMBERS</button>
  <button mat-menu-item (click)="navigateToSystemSettings()">SETTINGS</button>
  <button mat-menu-item (click)="navigateToAdministrationTerminals()">TERMINALS</button>
  <!-- <button mat-menu-item (click)="navigateTo()">Message Templates</button>
    <button mat-menu-item (click)="navigateTo()">Messages</button>
    <button mat-menu-item (click)="navigateTo()">Send Message</button> -->
</mat-menu>

<mat-menu #smallMenu="matMenu">
  <button mat-menu-item (click)="navigateToMenu()">MENU</button>
  <button *ngIf="canOrderOnline" mat-menu-item (click)="navigateToHostedStore()">ORDER ONLINE NOW</button>
  <button mat-menu-item *ngIf="!(authIdentity$ | async) && (identityServerAvailable | async)" (click)="navigateToLogin()">REGISTER/LOG IN</button>
  <div *ngIf="authEmployee$ | async">
    <button mat-menu-item [matMenuTriggerFor]="employeeMenu" *ngIf="isEmployee | async">EMPLOYEE</button>
    <button mat-menu-item [matMenuTriggerFor]="backOfficeMenu" *ngIf="isManager | async">BACK OFFICE</button>
    <button mat-menu-item [matMenuTriggerFor]="adminMenu" *ngIf="isAdmin | async">ADMIN</button>
    <button mat-menu-item [matMenuTriggerFor]="profileMenu">{{ username$ | async }}</button>
  </div>
</mat-menu>
