import { Injectable } from "@angular/core";
import { Page } from "core";
import { PaginationInput } from "core";
import { HttpService } from "core";
import { OltpServiceSettings } from "../oltp-service-settings";
import { Client } from "../models/client";
import { StoreModelFactory } from "../providers/store-model-factory";
import { ClientViewOptions, ViewFactory } from "../view-factory";
import { Observable, of } from 'rxjs';
import { map, tap } from "rxjs/operators";

@Injectable()
export class ClientService {

  public static readonly ClientIndexView = <ClientViewOptions>{};

  public static readonly FullShape = `{ uid name }`;
  public static readonly IndexShape = `{ uid name }`;

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: ClientViewOptions = ClientService.ClientIndexView): Observable<Client> {

    let view = ViewFactory.buildClientView(viewOptions);

    var request = {
      query: `query { getByUid(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<Client>(this.oltpServiceSettings.apiUrl, 'api/oltp/client', request, 'getByUid').pipe(
      map(x => StoreModelFactory.assignClient(x))
    );
  }

  getByMemberUid(memberUid: string, viewOptions: ClientViewOptions = ClientService.ClientIndexView): Observable<Client[]> {

    let view = ViewFactory.buildClientView(viewOptions);

    var request = {
      query: `query { getByMemberUid(memberUid: "${memberUid}") ${view} }`
    };

    return this.httpService.graph<Client[]>(this.oltpServiceSettings.apiUrl, 'api/oltp/client', request, 'getByMemberUid').pipe(
      map(x => x.map(y => StoreModelFactory.assignClient(y)))
    );
  }

  search(statusUids: string[], paginationInput: PaginationInput, viewOptions: ClientViewOptions = ClientService.ClientIndexView): Observable<Page<Client>> {

    let view = ViewFactory.buildClientView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<Client>>(this.oltpServiceSettings.apiUrl, 'api/oltp/client', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = StoreModelFactory.assignClient(edge.node)))
    );
  }

  create(uid: string, applicationUid: string, name: string, viewOptions: ClientViewOptions = ClientService.ClientIndexView): Observable<Client> {

    let view = ViewFactory.buildClientView(viewOptions);

    var clientInput = {
      uid: uid,
      applicationUid: applicationUid,
      name: name,
    };

    var request = {
      query: 'mutation create($client:ClientInput!) { create(client: $client) ' + ClientService.FullShape + ' }',
      variables: { client: clientInput }
    };

    return this.httpService.graph<Client>(this.oltpServiceSettings.apiUrl, 'api/oltp/client', request, 'create').pipe(
      map(x => StoreModelFactory.assignClient(x))
    );
  }

  update(uid: string, applicationUid: string, name: string, authIdentityUid: string, viewOptions: ClientViewOptions = ClientService.ClientIndexView): Observable<Client> {

    let view = ViewFactory.buildClientView(viewOptions);

    var clientInput = {
      uid: uid,
      applicationUid: applicationUid,
      name: name,
    };

    var request = {
      query: `mutation update($client:ClientInput!) { update(client: $client, authIdentityUid: "${authIdentityUid}") ${view} }`,
      variables: { client: clientInput }
    };

    return this.httpService.graph<Client>(this.oltpServiceSettings.apiUrl, 'api/oltp/client', request, 'update').pipe(
      map(x => StoreModelFactory.assignClient(x))
    );
  }

  enable(uid: string, authIdentityUid: string, viewOptions: ClientViewOptions = ClientService.ClientIndexView): Observable<Client> {

    let view = ViewFactory.buildClientView(viewOptions);

    var request = {
      query: `mutation enable { enable(uid: "${uid}", authIdentityUid: "${authIdentityUid}") ${view} }`
    };

    return this.httpService.graph<Client>(this.oltpServiceSettings.apiUrl, 'api/oltp/client', request, 'enable').pipe(
      map(x => StoreModelFactory.assignClient(x))
    );
  }

  disable(uid: string, authIdentityUid: string, viewOptions: ClientViewOptions = ClientService.ClientIndexView): Observable<Client> {

    let view = ViewFactory.buildClientView(viewOptions);

    var request = {
      query: `mutation disable { disable(uid: "${uid}", authIdentityUid: "${authIdentityUid}") ${view} }`
    };

    return this.httpService.graph<Client>(this.oltpServiceSettings.apiUrl, 'api/oltp/client', request, 'disable').pipe(
      map(x => StoreModelFactory.assignClient(x))
    );
  }

  delete(uid: string, authIdentityUid: string, viewOptions: ClientViewOptions = ClientService.ClientIndexView): Observable<Client> {

    let view = ViewFactory.buildClientView(viewOptions);

    var request = {
      query: `mutation delete { delete(uid: "${uid}", authIdentityUid: "${authIdentityUid}") ${view} }`
    };

    return this.httpService.graph<Client>(this.oltpServiceSettings.apiUrl, 'api/oltp/client', request, 'delete').pipe(
      map(x => StoreModelFactory.assignClient(x))
    );
  }
}
