import { Pipe, PipeTransform } from '@angular/core';
import { PositionStatusKeys } from '../keys';
import { Position } from '../models/position';

@Pipe({
  name: 'positionStatus'
})
export class PositionStatusPipe implements PipeTransform {

  private map = new Map<string, string>([
    [PositionStatusKeys.Active.toUpperCase(), 'Active'],
    [PositionStatusKeys.Inactive.toUpperCase(), 'Inactive'],
    [PositionStatusKeys.Deleted.toUpperCase(), 'Deleted']
  ]);

  constructor(
  ) {
  }

  transform(value: Position | string): string {

    if (typeof (value) === 'string') {
      return value ? this.map.get(value.toLocaleUpperCase()) : null;
    } else {
      return value ? this.map.get(value.positionStatusUid.toLocaleUpperCase()) : null;
    }
  }
}
