import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CaptureTokenEventModel, CardSwipe, CardSwipeEventModel } from "core";
import { CardEntryModalComponent } from "./card-entry-modal.component";

@Injectable()
export abstract class CardEntryModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public abstract open(data: ICardEntryModalData): MatDialogRef<CardEntryModalComponent, CaptureTokenEventModel | CardSwipeEventModel>;
}

export interface ICardEntryModalData {

  title: string,
  mode: CardSwipeMode,
  initialValue: number,
  allowEdit: Boolean
}

export const enum CardSwipeMode {
  CaptureSwipe,
  CaptureToken
}

export interface ICardEntryModalResult {

  textEntry: string;
  cardSwipe: CardSwipe;
}
