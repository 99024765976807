import { Pipe, PipeTransform } from '@angular/core';
import { CardTransactionStatusKeys } from '../keys';
import { CardTransaction } from '../models/card-transaction';

@Pipe({
  name: 'cardTransactionStatus'
})
export class CardTransactionStatusPipe implements PipeTransform {

  private cardTransactionStatusMap = new Map<string, string>([
    [CardTransactionStatusKeys.Created.toLowerCase(), 'Created'],
    [CardTransactionStatusKeys.Authorized.toLowerCase(), 'Authorized'],
    [CardTransactionStatusKeys.AuthorizedFailed.toLowerCase(), 'AuthorizedFailed'],
    [CardTransactionStatusKeys.Captured.toLowerCase(), 'Captured'],
    [CardTransactionStatusKeys.Voided.toLowerCase(), 'Voided']
  ]);

  constructor(
  ) {
  }

  transform(value: CardTransaction | string): string {

    if (typeof(value) === 'string'){
      return value ? this.cardTransactionStatusMap.get(value.toLowerCase()) : null;
    } else {
      return value ? this.cardTransactionStatusMap.get(value.cardTransactionStatusUid.toLowerCase()) : null;
    }
  }
}
