import { ModelFactory } from "core";
import {
  Transaction,
  TransactionGuest,
  TransactionItem,
  TransactionItemAdjustment,
  TransactionItemConfiguration,
  TransactionItemConfigurationPortion,
  TransactionItemConfigurationInclusionGroup,
  TransactionItemConfigurationInclusionGroupOption,
  TransactionItemConfigurationPreparation,
  TransactionItemConfigurationVariation,
  TransactionItemConfigurationAddOn,
  TransactionAdjustment,
  TransactionCharge,
  TransactionPayment,
  TransactionDisbursement,
  TransactionChargeAdjustment,
} from './models/transaction';
import { CardTransaction } from './models/card-transaction';

export class TransactionModelFactory {

  public static assignCardTransaction(input: CardTransaction): CardTransaction {

    if (input) {
      var output = ModelFactory.assign(input, CardTransaction);
      output.modifiedDateTimeUtc = input.modifiedDateTimeUtc ? new Date(input.modifiedDateTimeUtc) : output.modifiedDateTimeUtc;

      return output;
    }

    return null;
  }

  public static assignTransaction(input: Transaction): Transaction {

    if (input) {
      var output = ModelFactory.assign(input, Transaction);
      output.openDateTimeUtc = input.openDateTimeUtc ? new Date(input.openDateTimeUtc) : output.openDateTimeUtc;
      output.closeDateTimeUtc = input.closeDateTimeUtc ? new Date(input.closeDateTimeUtc) : output.closeDateTimeUtc;
      output.items = input.items ? input.items.map(x => TransactionModelFactory.assignTransactionItem(x)) : output.items;
      output.charges = input.charges ? input.charges.map(x => TransactionModelFactory.assignTransactionCharge(x)) : output.charges;
      output.payments = input.payments ? input.payments.map(x => TransactionModelFactory.assignTransactionPayment(x)) : output.payments;
      output.adjustments = input.adjustments ? input.adjustments.map(x => TransactionModelFactory.assignTransactionAdjustment(x)) : output.adjustments;
      output.disbursements = input.disbursements ? input.disbursements.map(x => TransactionModelFactory.assignTransactionDisbursement(x)) : output.disbursements;

      return output;
    }

    return null;
  }

  public static assignTransactionGuest(input: TransactionGuest): TransactionGuest {

    if (input) {
      return ModelFactory.assign(input, TransactionGuest);
    }

    return null;
  }

  public static assignTransactionItem(input: TransactionItem): TransactionItem {

    if (input) {
      var output = ModelFactory.assign(input, TransactionItem);
      output.adjustments = input.adjustments ? input.adjustments.map(x => TransactionModelFactory.assignTransactionItemAdjustment(x)) : output.adjustments;
      output.configuration = input.configuration ? TransactionModelFactory.assignTransactionItemConfiguration(input.configuration) : output.configuration;

      return output;
    }

    return null;
  }

  public static assignTransactionItemConfiguration(input: TransactionItemConfiguration): TransactionItemConfiguration {

    if (input) {
      var output = ModelFactory.assign(input, TransactionItemConfiguration);

      output.portion = input.portion ? TransactionModelFactory.assignTransactionItemConfigurationPortion(input.portion) : output.portion;

      return output;
    }

    return null;

  }

  public static assignTransactionItemConfigurationPortion(input: TransactionItemConfigurationPortion): TransactionItemConfigurationPortion {

    if (input) {
      var output = ModelFactory.assign(input, TransactionItemConfigurationPortion);

      output.portionUid = input.portionUid;
      output.inclusionGroups = input.inclusionGroups ? input.inclusionGroups.map(x => TransactionModelFactory.assignTransactionItemConfigurationInclusionGroup(x)) : output.inclusionGroups;
      output.addOns = input.addOns ? input.addOns.map(x => TransactionModelFactory.assignTransactionItemConfigurationAddOn(x)) : output.addOns;

      return output;
    }

    return null;
  }

  public static assignTransactionItemConfigurationInclusionGroup(input: TransactionItemConfigurationInclusionGroup): TransactionItemConfigurationInclusionGroup {

    if (input) {
      var output = ModelFactory.assign(input, TransactionItemConfigurationInclusionGroup);

      output.inclusionGroupUid = input.inclusionGroupUid;
      output.options = input.options ? input.options.map(x => TransactionModelFactory.assignTransactionItemConfigurationInclusionGroupOption(x)) : output.options;

      return output;
    }

    return null;
  }

  public static assignTransactionItemConfigurationInclusionGroupOption(input: TransactionItemConfigurationInclusionGroupOption): TransactionItemConfigurationInclusionGroupOption {

    if (input) {
      var output = ModelFactory.assign(input, TransactionItemConfigurationInclusionGroupOption);

      output.optionUid = input.optionUid;
      output.quantity = input.quantity;
      output.preparations = input.preparations ? input.preparations.map(x => TransactionModelFactory.assignTransactionItemConfigurationPreparation(x)) : output.preparations;
      output.variations = input.variations ? input.variations.map(x => TransactionModelFactory.assignTransactionItemConfigurationVariation(x)) : output.variations;
      output.isSubstitution = input.isSubstitution;

      return output;
    }

    return null;
  }


  public static assignTransactionItemConfigurationPreparation(input: TransactionItemConfigurationPreparation): TransactionItemConfigurationPreparation {

    if (input) {
      var output = ModelFactory.assign(input, TransactionItemConfigurationPreparation);

      output.preparationUid = input.preparationUid;
      output.optionUid = input.optionUid;

      return output;
    }

    return null;
  }

  public static assignTransactionItemConfigurationVariation(input: TransactionItemConfigurationVariation): TransactionItemConfigurationVariation {

    if (input) {
      var output = ModelFactory.assign(input, TransactionItemConfigurationVariation);

      output.variationUid = input.variationUid;
      output.optionUid = input.optionUid;

      return output;
    }

    return null;
  }

  public static assignTransactionItemConfigurationAddOn(input: TransactionItemConfigurationAddOn): TransactionItemConfigurationAddOn {

    if (input) {
      var output = ModelFactory.assign(input, TransactionItemConfigurationAddOn);

      output.addOnUid = input.addOnUid;
      output.item = TransactionModelFactory.assignTransactionItem(input.item);

      return output;
    }

    return null;
  }

  public static assignTransactionItemAdjustment(input: TransactionItemAdjustment): TransactionItemAdjustment {

    if (input) {
      return ModelFactory.assign(input, TransactionItemAdjustment);
    }

    return null;
  }

  public static assignTransactionCharge(input: TransactionCharge): TransactionCharge {

    if (input) {
      return ModelFactory.assign(input, TransactionCharge);
    }

    return null;
  }

  public static assignTransactionChargeAdjustment(input: TransactionChargeAdjustment): TransactionChargeAdjustment {

    if (input) {
      return ModelFactory.assign(input, TransactionChargeAdjustment);
    }

    return null;
  }

  public static assignTransactionAdjustment(input: TransactionAdjustment): TransactionAdjustment {

    if (input) {
      return ModelFactory.assign(input, TransactionAdjustment);
    }

    return null;
  }
  public static assignTransactionPayment(input: TransactionPayment): TransactionPayment {

    if (input) {
      return ModelFactory.assign(input, TransactionPayment);
    }

    return null;
  }

  public static assignTransactionDisbursement(input: TransactionDisbursement): TransactionDisbursement {

    if (input) {
      return ModelFactory.assign(input, TransactionDisbursement);
    }

    return null;
  }
}
