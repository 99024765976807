import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-scaled-container',
  templateUrl: './scaled-container.component.html',
  styleUrls: ['./scaled-container.component.scss']
})
export class ScaledContainerComponent {

  @Input() scale: string = '12x12';
  @Input() size: string = '1x1';
  @Input() padding: number = 0;

  public heightValue: string;
  public widthValue: string;
  public paddingValue: string;

  ngOnInit() {

    const [scaleWidth, scaleHeight] = this.scale.split('x');
    const [sizeWidth, sizeHeight] = this.size.split('x');

    this.heightValue = sizeHeight == "*" ? 'auto' : (parseFloat(sizeHeight) / parseFloat(scaleHeight) * 100).toFixed(4) + 'vh';
    this.widthValue = sizeWidth == "*" ? 'auto' : (parseFloat(sizeWidth) / parseFloat(scaleWidth) * 100).toFixed(4) + 'vw';
    this.paddingValue = this.padding.toString() + 'px';
  }
}
