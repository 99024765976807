<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef color="accent">Type</th>
    <td mat-cell *matCellDef="let setting">{{ setting.type }}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef color="accent">Name</th>
    <td mat-cell *matCellDef="let setting">{{ setting.name }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>

</table>
