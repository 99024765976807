import { Employee } from "./employee";
import { ConversationStatus } from './conversation-status';
import { IdentityUser } from "core";

export class Conversation {

  uid: string;
  refAggregateUid: string;
  conversationStatusUid: string;
  conversationStatus: ConversationStatus;

  messages: ConversationMessage[]
}

export class ConversationMessage {

  uid: string;
  conversationUid: string;
  identityId: string;
  content: string;
  dateTimeUtc: Date;

  identityUser: IdentityUser
  employee: Employee;
}
