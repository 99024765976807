import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { HttpService } from 'core';
import { Page } from "core";
import { PaginationInput } from "core";
import { CardTransactionBatch } from "../models/card-transaction-batch";
import { StoreModelFactory } from "../providers/store-model-factory";
import { CardTransactionBatchViewOptions, ViewFactory } from "../view-factory";
import { OltpServiceSettings } from "../oltp-service-settings";

@Injectable()
export class CardTransactionBatchService {

  public static readonly CardTransactionBatchIndexView = <CardTransactionBatchViewOptions>{};

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  // getByUid(uid: string): Observable<Cart> {

  //   var request = {
  //     query: 'query { getByUid (uid: \"' + uid + '\") ' + this.getCartFullView() + ' }'
  //   }

  //   return this.httpService.post<Cart>(this.oltpServiceSettings.oltpApiUrl, 'api/oltp/cart', request, 'getByUid', x => this.assign(x, Cart)).pipe(x => x, catchError(x => of(null)));
  // }

  search(ownerUids: string[], storeFrontUids: string[], statusUids: string[], paginationInput: PaginationInput, viewOptions: CardTransactionBatchViewOptions = CardTransactionBatchService.CardTransactionBatchIndexView): Observable<Page<CardTransactionBatch>> {

    let view = ViewFactory.buildCardTransactionBatchView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(ownerUids: ${JSON.stringify(ownerUids)}, storeFrontUids: ${JSON.stringify(storeFrontUids)}, statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<CardTransactionBatch>>(this.oltpServiceSettings.apiUrl, 'api/oltp/cardtransactionbatch', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = StoreModelFactory.assignCardTransactionBatch(edge.node)))
    );
  }

  close(uid: string, viewOptions: CardTransactionBatchViewOptions = CardTransactionBatchService.CardTransactionBatchIndexView): Observable<CardTransactionBatch> {

    let view = ViewFactory.buildCardTransactionBatchView(viewOptions);

    var request = {
      query: `mutation close { close(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<CardTransactionBatch>(this.oltpServiceSettings.apiUrl, 'api/oltp/cardtransactionbatch', request, 'close').pipe(
      map(x => StoreModelFactory.assignCardTransactionBatch(x))
    );
  }
}


