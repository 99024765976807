import { Injectable } from "@angular/core";
import { BlobServerSettings } from '../blob-server-settings';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BlobImage } from "../models/blob-image";

@Injectable()
export class BlobService {

  constructor(
    private http: HttpClient,
    private blobServiceSettings: BlobServerSettings,
  ) {

  }

  getByUid(blobUid: string): Observable<BlobImage> {

    return this.http.get<BlobImage>(this.blobServiceSettings.apiUrl + `/api/blob/${this.blobServiceSettings.apiOwnerUid}/get/${blobUid}`);
  }

  searchImage(): Observable<BlobImage[]> {

    return this.http.post<BlobImage[]>(this.blobServiceSettings.apiUrl + `/api/blob/${this.blobServiceSettings.apiOwnerUid}/search`, null);
  }

  upload(imageUid: string, file: File): Observable<BlobImage> {

    var form = new FormData();
    form.append('file', file);

    return this.http.post<BlobImage>(this.blobServiceSettings.apiUrl + `/api/blob/${this.blobServiceSettings.apiOwnerUid}/${imageUid}`, form);
  }
}
