<div>
  <div class="jumbotron clearfix" style="padding-left: 30px; padding-right: 30px;">
    <div class="col-md-7">
      <p class="lead">** STORE **</p>
    </div>
    <div class="col-md-5">
      <div>CART</div>
      <div>{{ cart?.items.length }}</div>
      <div *ngIf="cart?.items.length > 0">
        <button class="btn btn-xs" (click)="checkOut()">Check Out</button>
      </div>
    </div>
  </div>
</div>

<div>
  <div *ngFor="let product of products" style="display: flex; margin-bottom: 10px;">
    <div style="flex: 4;">
      <div>
        {{ product.name }}&nbsp;[{{ product.sku }}]
      </div>
      <div>
        {{ product.description }}
      </div>
    </div>
    <div style="flex: 1;">
      {{ product.basePrice | currency:'USD':'symbol':'1.2-2' }}
    </div>
    <div style="flex: 1;">
      <button class="btn btn-md btn-primary" (click)="addToCart(product)">Add to Cart</button>
    </div>
  </div>
</div>
