import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Observable, of, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemConfigurationDetailContext } from "../item-configuration-detail-context";
import { CurrencyPipe } from "@angular/common";
import { SettingProvider, TenantProvider, slideInAnimation, slideOutAnimation } from "core";
import { SelectListModalData, SelectListModalProvider } from "core";
import { findInArrayByKey, findInArrayByUid } from "core";
import { EditableTransactionItemConfigurationInclusionGroup, EditableTransactionItemConfiguration, EditableTransactionItemConfigurationVariation, EditableTransactionItemConfigurationPreparation, EditableTransactionItemConfigurationInclusionGroupOption } from "../../../models/editable-transaction/editable-transaction";
import { ProductConfigurationPreparationOption, ProductProvider } from "downtown-product";
import { TransactionSettings } from "../../../models";

@Component({
  selector: 'transaction-item-configuration-detail-inclusion-group',
  templateUrl: './item-configuration-detail-inclusion-group.component.html',
  styleUrls: ['./item-configuration-detail-inclusion-group.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfigurationDetailInclusionGroupComponent {

  @Input() public context: ItemConfigurationDetailContext;
  @Input() public inclusionGroup: EditableTransactionItemConfigurationInclusionGroup;
  @Output() public configurationChanged = new EventEmitter();

  public transactionSettings$: Observable<TransactionSettings>;

  constructor(
    private currencyPipe: CurrencyPipe,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider,
    private selectListModalProvider: SelectListModalProvider
  ) {
    this.transactionSettings$ = this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid);
  }

  public get configuration(): EditableTransactionItemConfiguration {

    return this.context.itemConfiguration;
  }

  public getAssignedPreparations(option: EditableTransactionItemConfigurationInclusionGroupOption): EditableTransactionItemConfigurationPreparation[] {

    return option.preparations?.filter(x => !!x.optionUid) || [];
  }

  public getInclusionGroupSelectDisplay(): string {

    return this.context.localCache.getOrAdd(`${this.inclusionGroup.inclusionGroupUid}_selectdisplay`, () => {
      const inclusionGroup = this.context.configurableProduct.configuration.getPortion(this.configuration.portion.portionUid).getInclusionGroup(this.inclusionGroup.inclusionGroupUid);

      return inclusionGroup ? `Choose a total of ${inclusionGroup.maxTotalOptionQuantityIncluded} in up to ${inclusionGroup.maxDistinctOptionsIncluded} option(s). Minimum ${inclusionGroup.minQuantityForOption} per option. ${inclusionGroup.allowAdditional ? 'Additional quantities extra.' : ''}` : 'Select...';
    });
  }

  public getInclusionGroupDisplay(inclusionGroupUid: string): string {

    return this.context.localCache.getOrAdd(`${inclusionGroupUid}_display`, () => {
      return this.context.configurableProduct.configuration.getPortion(this.configuration.portion.portionUid).getInclusionGroup(inclusionGroupUid)?.name;
    });
  }

  public getInclusionGroupOptionDisplay(inclusionGroupUid: string, optionUid: string, isSubstitution: boolean): Observable<string> {

    return this.context.localCache.getOrAdd(`${inclusionGroupUid}_${optionUid}_display`, () => {
      const inclusionGroup = this.context.configurableProduct.configuration.getPortion(this.configuration.portion.portionUid).getInclusionGroup(inclusionGroupUid);
      const inclusionGroupOption = findInArrayByUid(inclusionGroup?.options, optionUid);

      if (inclusionGroupOption?.alias) {
        return of(inclusionGroupOption.alias);
      }

      const configuredOption = findInArrayByKey(this.inclusionGroup.options, 'optionUid', optionUid);

      return this.context.productProvider.getOneCached$(configuredOption.productUid, configuredOption.productVersion).pipe(
        map(product => {
          return (isSubstitution ? '(Sub) ' : '') + product.name;
        })
      );
    });
  }

  public getInclusionGroupOptionPreparationDisplay(inclusionGroupUid: string, optionUid: string, preparationUid: string): string {

    return this.context.localCache.getOrAdd(`${inclusionGroupUid}_${optionUid}_${preparationUid}_display`, () => {
      const inclusionGroup = this.context.configurableProduct.configuration.getPortion(this.configuration.portion.portionUid).getInclusionGroup(inclusionGroupUid);
      const inclusionGroupOption = findInArrayByUid(inclusionGroup.options, optionUid);
      const preparation = findInArrayByUid(inclusionGroupOption?.preparations, preparationUid);

      return preparation?.name;
    });
  }

  public getInclusionGroupOptionPreparationOptionDisplay(inclusionGroupUid: string, optionUid: string, preparationUid: string, preparationOptionUid: string): string {

    return this.context.localCache.getOrAdd(`${inclusionGroupUid}_${optionUid}_${preparationUid}_${preparationOptionUid}_display`, () => {
      const inclusionGroup = this.context.configurableProduct.configuration.getPortion(this.configuration.portion.portionUid).getInclusionGroup(inclusionGroupUid);
      const inclusionGroupOption = findInArrayByUid(inclusionGroup.options, optionUid);

      if (inclusionGroupOption) {
        let preparation = findInArrayByUid(inclusionGroupOption.preparations, preparationUid);

        if (preparation) {
          return findInArrayByUid(preparation.options, preparationOptionUid)?.name;
        }
      }

      return null;
    });
  }

  public getInclusionGroupOptionVariationDisplay(inclusionGroupUid: string, optionUid: string, variationUid: string): string {

    return this.context.localCache.getOrAdd(`${inclusionGroupUid}_${optionUid}_${variationUid}_display`, () => {
      const inclusionGroup = this.context.configurableProduct.configuration.getPortion(this.configuration.portion.portionUid).getInclusionGroup(inclusionGroupUid);
      const inclusionGroupOption = findInArrayByUid(inclusionGroup.options, optionUid);
      const variation = findInArrayByUid(inclusionGroupOption.variations, variationUid);

      return variation?.name;
    });
  }

  public getInclusionGroupOptionVariationOptionDisplay(inclusionGroupUid: string, optionUid: string, variationUid: string, variationOptionUid: string): Observable<string> {

    return this.context.localCache.getOrAdd(`${inclusionGroupUid}_${optionUid}_${variationUid}_${variationOptionUid}_display`, () => {
      const inclusionGroup = this.context.configurableProduct.configuration.getPortion(this.configuration.portion.portionUid).getInclusionGroup(inclusionGroupUid);
      const inclusionGroupOption = findInArrayByUid(inclusionGroup.options, optionUid);
      const variation = findInArrayByUid(inclusionGroupOption.variations, variationUid);

      const variationOption = findInArrayByUid(variation?.options, variationOptionUid);

      if (variationOption?.alias) {
        return of(variationOption.alias);
      }

      const configuredOption = findInArrayByKey(this.inclusionGroup.options, 'optionUid', optionUid);
      const configuredVariation = findInArrayByKey(configuredOption?.variations, 'variationUid', variationUid);

      return this.context.productProvider.getOneCached$(configuredVariation.productUid, configuredVariation.productVersion).pipe(
        map(x => x ? x.name : null)
      );
    });
  }

  public editPreparation(inclusionGroupUid: string, inclusionGroupOptionUid: string, preparationUid: string, isSubstitution: boolean) {

    const productPortion = this.context.configurableProduct.configuration.getPortion(this.configuration.portion.portionUid);
    const productInclusionGroup = productPortion.getInclusionGroup(inclusionGroupUid);
    const productInclusionGroupOption = findInArrayByUid(productInclusionGroup.options, inclusionGroupOptionUid);
    const productPreparation = findInArrayByUid(productInclusionGroupOption.preparations, preparationUid);

    const productPreparationOptions = [...productPreparation.options || []];
    if (productPreparation.allowNone) {
      productPreparationOptions.splice(0, 0, { uid: null, name: 'No Change', hideInPrintDetail: false, hideInUIDetail: false });
    }

    this.getInclusionGroupOptionDisplay(inclusionGroupUid, inclusionGroupOptionUid, isSubstitution).subscribe(optionDisplay => {
      this.selectListModalProvider.open(<SelectListModalData<ProductConfigurationPreparationOption>>{
        title: `${optionDisplay} - ${productPreparation.name}`,
        options: productPreparationOptions,
        displayFunc: x => `${x.name} ${(x.additionalPrice ? `+ ${this.currencyPipe.transform(x.additionalPrice, 'USD', 'symbol', '1.2-2')} each` : '')}`
      }).afterClosed().subscribe(selection => {
        if (selection) {
          const itemInclusionGroup = this.configuration.portion.getConfiguringInclusionGroup(inclusionGroupUid)
          const itemInclusionGroupOption = findInArrayByKey(itemInclusionGroup.options, 'optionUid', inclusionGroupOptionUid);
          let itemPreparation = findInArrayByKey(itemInclusionGroupOption.preparations, 'preparationUid', preparationUid);

          if (!itemPreparation) {
            itemPreparation = <EditableTransactionItemConfigurationPreparation>{
              preparationUid: preparationUid,
            };

            itemInclusionGroupOption.preparations = itemInclusionGroupOption.preparations || [];
            itemInclusionGroupOption.preparations.push(itemPreparation);
          }

          itemPreparation.optionUid = (<ProductConfigurationPreparationOption>selection).uid;

          this.configurationChanged.emit();
        }
      });
    });
  }

  public editVariation(inclusionGroupUid: string, inclusionGroupOptionUid: string, variationUid: string, isSubstitution: boolean) {

    const productPortion = this.context.configurableProduct.configuration.getPortion(this.configuration.portion.portionUid);
    const productInclusionGroup = productPortion.getInclusionGroup(inclusionGroupUid);
    const productInclusionGroupOption = findInArrayByUid(productInclusionGroup.options, inclusionGroupOptionUid);

    const productVariation = findInArrayByUid(productInclusionGroupOption.variations, variationUid);

    combineLatest(productVariation.options.map(productVariationOption => {
      return this.context.productProvider.getOneCached$(productVariationOption.productReference.uid, productVariationOption.productReference.version).pipe(
        map(x => {
          return {
            uid: productVariationOption.uid,
            display: productVariationOption.alias ? productVariationOption.alias : x.name,
            productUid: productVariationOption.productReference.uid,
            productVersion: productVariationOption.productReference.version,
            productPortionUid: productVariationOption.productReference.portionUid
          };
        })
      );
    })).subscribe(values => {
      values.splice(0, 0, { uid: null, display: 'None', productUid: null, productVersion: null, productPortionUid: null });

      this.getInclusionGroupOptionDisplay(inclusionGroupUid, inclusionGroupOptionUid, isSubstitution).subscribe(optionDisplay => {
        this.selectListModalProvider.open(<SelectListModalData<{ uid: string, display: string, productUid: string, productVersion: number, productPortionUid: string }>>{
          title: `${optionDisplay} - ${productVariation.name}`,
          options: values,
          displayFunc: x => x.display
        }).afterClosed().subscribe(value => {
          const selection = value as { uid: string, display: string, productUid: string, productVersion: number, productPortionUid: string };

          if (selection) {
            const itemInclusionGroup = this.configuration.portion.getConfiguringInclusionGroup(inclusionGroupUid)
            const itemInclusionGroupOption = findInArrayByKey(itemInclusionGroup.options, 'optionUid', inclusionGroupOptionUid);

            itemInclusionGroupOption.variations = itemInclusionGroupOption.variations || [];
            let itemVariation = findInArrayByKey(itemInclusionGroupOption.variations, 'variationUid', variationUid);

            if (selection.uid) {
              if (!itemVariation) {
                itemVariation = <EditableTransactionItemConfigurationVariation>{
                  variationUid: variationUid,
                };
                itemInclusionGroupOption.variations.push(itemVariation);
              }

              itemVariation.optionUid = selection.uid;
              itemVariation.productUid = selection.productUid;
              itemVariation.productVersion = selection.productVersion;
              itemVariation.productPortionUid = selection.productPortionUid;
            } else {
              if (itemVariation) {
                itemInclusionGroupOption.variations.splice(itemInclusionGroupOption.variations.indexOf(itemVariation), 1);
                if (itemInclusionGroupOption.variations.length == 0) {
                  itemInclusionGroupOption.variations == null;
                }
              }
            }

            itemVariation.optionUid = selection.uid;
          }
        });
      });
    });
  }
}
