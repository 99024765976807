import { IdentityUser } from "../models/identity-user";

export class Member {

  uid: string;
  identityId: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  memberStatusUid: string;

  identityUser: IdentityUser;

  getFullName(): string {
    return `${this.firstName ? this.firstName : ''} ${this.lastName ? this.lastName : ''}`.trim();
  }

  getReportName(): string {
    return `${this.lastName ? this.lastName : ''}, ${this.firstName ? this.firstName : ''}`.trim();
  }

  getDisplayableName(): string {
    var displayableName: string = this.displayName != null && displayableName != '' ? this.displayName : this.getFullName();
    return displayableName != null && displayableName != '' ? displayableName : this.email;
  }
}
