import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { slideInAnimation, slideOutAnimation } from 'core';
import { LoggingProvider } from 'core';
import { EditableOrder, EditableOrderItem } from '../../../models/editable-order';
import { EditableOrderDataHandler } from '../../../models/editable-order-data-handler';
import { OrderService } from '../../../services/order.service';
import { ProductProvider } from 'downtown-product';

@Component({
  selector: 'app-order-item-editor',
  templateUrl: './order-item-editor.component.html',
  styleUrls: ['./order-item-editor.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class OrderItemEditorComponent implements OnInit {

  @Input() order: EditableOrder;
  @Input() orderItem: EditableOrderItem;
  @Input() canEdit: boolean;
  @Output() selectItem = new EventEmitter<EditableOrderItem>();
  // @Output() lineItemEdit = new EventEmitter<EditableOrderItem>();
  // @Output() lineItemVoid = new EventEmitter<EditableOrderItem | EditableOrderItemAdjustment | EditableOrderPayment>();
  // @Output() lineItemComp = new EventEmitter<EditableOrderItem>();
  // @Output() lineItemCancel = new EventEmitter<EditableOrderItem>();

  private editableOrderDataHandler: EditableOrderDataHandler;

  constructor(
    private orderService: OrderService,
    private productProvider: ProductProvider
  ) {
    this.editableOrderDataHandler = new EditableOrderDataHandler(new LoggingProvider());
  }

  ngOnInit() {

    // this.editableOrderItem.form.get('quantity').valueChanges.pipe(
    //   debounceTime(2000)
    // ).subscribe(value => {
    //   if (this.editableOrderItem.form.dirty) {
    //     console.log('updating');
    //     this.updateOrderItem();
    //   }
    // });
  }

  getPortionDisplay(): Observable<string> {

    return this.productProvider.getOneCached$(this.orderItem.productUid.value, this.orderItem.productVersion.value).pipe(
      map(product => {
        let portionUid = this.orderItem.configuration.value.portion ? this.orderItem.configuration.value.portion.portionUid : null;

        if (portionUid) {
          let portion = product && product.configuration ? product.configuration.getPortion(portionUid) : null;
          return `${product.name} ${(portion ? ` - ${portion.name}` : null)}`;
        }

        return product.name;
      })
    );
  }
}
