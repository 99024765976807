<div style="display: flex; border-radius: 4px;" [style.background-color]="(editableTransaction.selectedLineItem | async) == payment ? 'gray' : 'transparent'" (click)="select.emit(payment)">
  <span style="width: 80%; text-align: right;">{{ payment.paymentMethodUid | async | paymentMethod | async }} </span>
  <span style="width: 20%; text-align: right;">{{ -(payment.total | async) | currency:'USD':'symbol':'1.2-2' }}</span>
</div>

<div *ngIf="(editableTransaction.selectedLineItem | async) == payment && (payment.canVoid || payment.canCancel)" [@slideIn] [@slideOut] style="display: flex; overflow: hidden;">
  <div style="width: 20%; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
  </div>
  <div style="background-color: gray; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 4px;">
    <button *ngIf="payment.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="void.emit(payment)">Remove</button>
    <button *ngIf="payment.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="changeType.emit(payment)">Change Type</button>
    <button *ngIf="payment.canCancel" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="cancel.emit(payment)">Cancel</button>
  </div>
  <div style="flex: 1 1 auto; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
  </div>
</div>
