import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { IPagedDataSource } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { OrderStatusKeys } from "../../../keys";
import { OrderService } from "../../../services/order.service";
import { OrderViewOptions } from "../../../view-factory";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss']
})
export class OrdersTableComponent {

  @Input() public dataSource: OrderDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {

  }
}

export class OrderModel {

  uid: string;
  number: string;
  ownerUid: string;
  initiatorIdentity: string;
  orderQuantity: number = 0;
  fulfilledQuantity: number = 0;
  subTotalAmount: number = 0;
  shippingAmount: number = 0;
  taxAmount: number = 0;
  totalAmount: number = 0;
  openDateTime: Date;
  modifiedDateTime: Date;
  closeDateTime: Date;
  orderStatusUid: string;

  canEdit: boolean;
  canCancel: boolean;
}

export class OrderDataSource extends DataSource<OrderModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<OrderModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<OrderModel[]>([]);
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private orderService: OrderService,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<OrderModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<OrderModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(storeFrontUid: string, statusUids: string[], paginationInput: PaginationInput, viewOptions: OrderViewOptions) {

    this.loadingSubject.next(true);

    this.orderService.search(null, [storeFrontUid], statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        const orders = page.edges.map(x => x.node).map(x => {
          let order = <OrderModel>Object.assign(new OrderModel(), {
            uid: x.uid,
            number: x.number,
            ownerUid: x.ownerUid,
            orderQuantity: x.orderQuantity,
            fulfilledQuantity: x.fulfilledQuantity,
            subTotalAmount: x.subTotalAmount,
            shippingAmount: x.shippingAmount,
            taxAmount: x.taxAmount,
            totalAmount: x.totalAmount,
            initiatorIdentity: x.initiatorIdentity ? x.initiatorIdentity.getDisplay() : x.identityUid,
            openDateTime: x.openDateTimeUtc,
            modifiedDateTime: x.modifiedDateTimeUtc,
            orderStatusUid: x.orderStatusUid
          });

          this.evaluateEnablement(order);

          return order;
        });

        return <[PageInfo, OrderModel[]]>[page.pageInfo, orders];
      }),
      catchError(() => of(<[PageInfo, OrderModel[]]>[null, []])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  private evaluateEnablement(order: OrderModel): OrderModel {

    let orderStatusUid = order.orderStatusUid;

    order.canEdit = orderStatusUid.toUpperCase() !== OrderStatusKeys.Cancelled.toUpperCase();
    order.canCancel = orderStatusUid.toUpperCase() !== OrderStatusKeys.Cancelled.toUpperCase();

    return order;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEdit = selected.length == 1;
    this._canBulkDelete = selected.length == 1;
  }
}
