import {
    CurrentDateTimeProvider,
    DebugErrorHandlingProvider,
    ElectronProvider,
    EntityEventProvider,
    ErrorHandlingProvider,
    LoggingProvider,
    NavigationProvider,
    ObservableCacheProvider,
    SettingProvider,
    SystemMessageProvider,
    TenantProvider,
    TimeoutProvider
} from ".";

export const providerTypes = [
    CurrentDateTimeProvider,
    DebugErrorHandlingProvider,
    ElectronProvider,
    EntityEventProvider,
    ErrorHandlingProvider,
    LoggingProvider,
    NavigationProvider,
    ObservableCacheProvider,
    SettingProvider,
    SystemMessageProvider,
    TenantProvider,
    TimeoutProvider,
];
