import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { IPagedDataSource } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { MemberStatusKeys } from "../../../keys";
import { MemberService, MemberViewOptions } from "../../../services/member.service";

@Component({
  selector: 'app-members-table',
  templateUrl: './members-table.component.html',
  styleUrls: ['./members-table.component.scss']
})
export class MembersTableComponent {

  @Input() public dataSource: MemberDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }

  linkEmployee(member: MemberModel) {

  }

  unlinkEmployee(member: MemberModel) {

  }
}

export class MemberModel {

  uid: string;
  firstName: string;
  lastName: string;
  pin: string;
  displayName: string;
  email: string;
  identityId: string;
  memberStatusUid: string;
  isEmployee: boolean;
  employeeUid: string;
  employeeFullName: string;
  employeeStatusUid: string;
  roles: string[];

  getFullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  getDisplayableName(): string {
    return this.displayName ? this.displayName : this.getFullName();
  }

  canEdit: boolean;
  canEnable: boolean;
  canDisable: boolean;
  canDelete: boolean;
  canLinkEmployee: boolean;
  canUnlinkEmployee: boolean;
}

export class MemberDataSource extends DataSource<MemberModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<MemberModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<MemberModel[]>([]);

  // private _canBulkEdit = false;
  // private _canBulkEditPositions = false;
  // private _canBulkEditPermissions = false;
  // private _canBulkViewShifts = false;
  // private _canBulkEditPayment = false;
  private _canBulkEnable = false;
  private _canBulkDisable = false;
  private _canBulkLink = false;
  private _canBulkUnlink = false;
  private _canBulkDelete = false;

  constructor(
    private memberService: MemberService,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<MemberModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  // public get canBulkEditProfile(): boolean {
  //   return this._canBulkEdit
  // }

  public get canBulkEnable(): boolean {
    return this._canBulkEnable;
  }

  public get canBulkDisable(): boolean {
    return this._canBulkDisable;
  }

  public get canBulkLink(): boolean {
    return this._canBulkLink;
  }

  public get canBulkUnlink(): boolean {
    return this._canBulkUnlink;
  }
  public get canBulkDelete(): boolean {
    return this._canBulkDelete;
  }

  connect(collectionViewer: CollectionViewer): Observable<MemberModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(statusUids: string[], paginationInput: PaginationInput, viewOptions: MemberViewOptions) {

    this.loadingSubject.next(true);

    this.memberService.search(statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        const employees = page.edges.map(x => x.node).map(x => {
          let employeeModel = Object.assign(new MemberModel(), {
            uid: x.uid,
            firstName: x.firstName,
            lastName: x.lastName,
            displayName: x.displayName,
            email: x.email,
            identityId: x.identityId,
            memberStatusUid: x.memberStatusUid,
            isEmployee: x.isEmployee,
            employeeUid: x.employee ? x.employee.uid : null,
            employeeFullName: x.employee ? x.employee.getFullName() : null,
            employeeStatusUid: x.employee ? x.employee.employeeStatusUid : null,
            roles: x.identityUser ? x.identityUser.claims.filter(x => x.type == 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' && x.value.startsWith('uptownbar')).map(x => x.value.substring(x.value.indexOf('.') + 1)) : []
          });

          this.evaluateEnablement(employeeModel);

          return employeeModel;
        });

        return <[PageInfo, MemberModel[]]>[page.pageInfo, employees];
      }),
      catchError(() => of(<[PageInfo, MemberModel[]]>[null, []])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  public enable(member: MemberModel): Observable<MemberModel> {

    return this.memberService.enable(member.uid).pipe(
      map(x => {
        member.memberStatusUid = x.memberStatusUid;

        return this.evaluateEnablement(member);
      })
    );
  }

  public disable(member: MemberModel): Observable<MemberModel> {

    return this.memberService.disable(member.uid).pipe(
      map(x => {
        member.memberStatusUid = x.memberStatusUid;

        return this.evaluateEnablement(member);
      })
    );
  }

  public delete(member: MemberModel): Observable<MemberModel> {

    return this.memberService.delete(member.uid).pipe(
      map(x => {
        member.memberStatusUid = x.memberStatusUid;

        return this.evaluateEnablement(member);
      })
    );
  }

  public evaluateEnablement(member: MemberModel): MemberModel {

    let memberStatusUid = member.memberStatusUid;

    member.canEdit = memberStatusUid.toUpperCase() !== MemberStatusKeys.Deleted.toUpperCase();
    member.canEnable = memberStatusUid.toUpperCase() === MemberStatusKeys.Inactive.toUpperCase();
    member.canDisable = memberStatusUid.toUpperCase() === MemberStatusKeys.Active.toUpperCase();
    member.canDelete = memberStatusUid.toUpperCase() !== MemberStatusKeys.Deleted.toUpperCase();
    member.canLinkEmployee = !member.isEmployee;
    member.canUnlinkEmployee = member.isEmployee;

    return member;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    // this._canBulkEdit = selected.length == 1;
    // this._canBulkEditPositions = selected.length == 1;
    // this._canBulkEditPermissions = selected.length == 1;
    // this._canBulkViewShifts = selected.length == 1;
    // this._canBulkEditPayment = selected.length == 1;
    this._canBulkEnable = selected.length == 1 && selected[0].memberStatusUid.toUpperCase() === MemberStatusKeys.Inactive.toUpperCase();
    this._canBulkDisable = selected.length == 1 && selected[0].memberStatusUid.toUpperCase() === MemberStatusKeys.Active.toUpperCase();
    this._canBulkLink = selected.length == 1 && selected[0].isEmployee == null;
    this._canBulkUnlink = selected.length == 1 && selected[0].isEmployee != null;
    this._canBulkDelete = selected.length == 1;
  }
}
