<div class="editor" *ngIf="context">

  <transaction-item-configuration-detail [product]="product" [itemConfiguration]="itemConfiguration" (configurationChanged)="handleConfigurationChanged()"></transaction-item-configuration-detail>

  <div style="flex: 1 1 auto;"></div>

  <div style="display: flex; border-top: 1px solid #000;">

    <span class="total-headings" style="width: 80%; text-align: right;">Total</span>
    <span class="total-headings" style="width: 20%; text-align: right;">{{ eachAmount | async | currency:'USD':'symbol':'1.2-2' }}</span>

  </div>

</div>
