<div [formGroup]="context.logisticsForm">
  <div style="display: flex;">
    <div style="flex: 1;">
      <div style="flex: 1; margin-bottom: 5px;">
        How do you want to get your order?
      </div>
      <mat-radio-group formControlName="logisticTypeUid">
        <div style="margin-top: 6px;">
          <mat-radio-button name="logisticTypeUid" value="3F46E3F1-EA40-4012-8260-CD22B5CC054C">Delivery</mat-radio-button>
        </div>
        <div style="margin-top: 6px;">
          <mat-radio-button name="logisticTypeUid" value="627AB494-4230-4A90-9E34-62F99E74D387">Pickup Inside</mat-radio-button>
        </div>
        <div style="margin-top: 6px;">
          <mat-radio-button name="logisticTypeUid" value="222557F4-5949-4266-AEB5-14945FABE5C7">Pickup Curbside</mat-radio-button>
        </div>
        <div style="margin-top: 6px;">
          <mat-radio-button name="logisticTypeUid" value="776F2000-E4D3-4163-8124-6A2C6514D48C">Dine In</mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
    <div style="flex: 1;">
      <div style="flex: 1; margin-bottom: 5px;">
        When do you want to get your order?
      </div>
      <mat-radio-group formControlName="logisticScheduleUid">
        <div style="margin-top: 6px;">
          <mat-radio-button name="logisticScheduleUid" value="EE6E6080-1158-45B8-9B6C-909EE001A648">As Soon As Possible</mat-radio-button>
        </div>
        <div style="margin-top: 6px;">
          <mat-radio-button name="logisticScheduleUid" value="21E063A4-90B1-479B-8801-0B0FE8017F47">Schedule Date and Time</mat-radio-button>
          <div style="margin-top: 6px; margin-left: 28px;" *ngIf="context.logisticsForm.get('logisticScheduleUid').value != 'EE6E6080-1158-45B8-9B6C-909EE001A648'">
            <button mat-flat-button type="button" color="accent" (click)="selectDeliveryDate()">Date<span *ngIf="context.logisticsForm.get('logisticDateTimeUtc').value">: {{ context.logisticsForm.get('logisticDateTimeUtc').value | date: 'M-dd-yyyy' }}</span></button>
            <button mat-flat-button type="button" color="accent" style="margin-left: 4px;" (click)="selectDeliveryTime()">Time<span *ngIf="context.logisticsForm.get('logisticDateTimeUtc').value">: {{ context.logisticsForm.get('logisticDateTimeUtc').value | date: 'h:mm a' }}</span></button>
          </div>
        </div>
      </mat-radio-group>

    </div>

  </div>
  <div style="display: flex; margin-top: 25px;">
    <div style="flex: 1;">
      <div style="flex: 1; margin-bottom: 5px;">
        How do you want to pay for your order?
      </div>
      <mat-radio-group formControlName="logisticPaymentMethodUid">
        <div style="margin-top: 6px;">
          <mat-radio-button name="logisticPaymentMethodUid" value="CFDCABDE-FDBE-421D-82C5-3E2ECEAB50CF">Credit Card</mat-radio-button>
        </div>
        <div style="margin-top: 6px;">
          <mat-radio-button name="logisticPaymentMethodUid" value="EBBA4BB2-053F-4C18-89BA-7D16E82B253D">Cash</mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
    <div style="flex: 1;">


    </div>

  </div>
</div>
