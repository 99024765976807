import { Injectable } from "@angular/core";
import { Observable, tap } from 'rxjs';
import { Setting } from "../models/setting";
import { map } from "rxjs/operators";
import { SettingInput } from '../models/setting-input';
import { CoreLibraryConfig } from '../core-library-config';
import { HttpService } from './http.service';
import { ModelFactory } from '../factories/model-factory';
import { PaginationInput } from '../models/pagination-input';
import { Page } from '../models/page';

@Injectable()
export class SettingService {

  public static readonly SettingFullView = <SettingViewOptions>{};

  constructor(
    private httpService: HttpService,
    private coreLibraryConfig: CoreLibraryConfig,
  ) {
  }

  list(viewOptions: SettingViewOptions = SettingService.SettingFullView): Observable<Setting[]> {

    let view = SettingService.buildView(viewOptions);

    var request = {
      query: `query { list ${view} }`
    }

    return this.httpService.graph<Setting[]>(this.coreLibraryConfig.apiUrl, 'api/oltp/setting', request, 'list').pipe(
      map(x => x.map(y => ModelFactory.assignSetting(y)))
    );
  }

  getByUid(uid: string, viewOptions: SettingViewOptions = SettingService.SettingFullView): Observable<Setting> {

    let view = SettingService.buildView(viewOptions);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<Setting>(this.coreLibraryConfig.apiUrl, 'api/oltp/setting', request, 'getByUid').pipe(
      map(x => ModelFactory.assignSetting(x))
    );
  }

  getByType(type: string, viewOptions: SettingViewOptions = SettingService.SettingFullView): Observable<Setting> {

    let view = SettingService.buildView(viewOptions);

    var request = {
      query: `query { getByType (type: "${type}") ${view} }`
    }

    return this.httpService.graph<Setting>(this.coreLibraryConfig.apiUrl, 'api/oltp/setting', request, 'getByUid').pipe(
      map(x => ModelFactory.assignSetting(x))
    );
  }

  search(ownerUids: string[], paginationInput: PaginationInput, viewOptions: SettingViewOptions = null): Observable<Page<Setting>> {

    let view = SettingService.buildView(viewOptions || SettingService.SettingFullView);

    var request = {
      query: `query search($pagination:PaginationInput) { search(ownerUids: ${JSON.stringify(ownerUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<Setting>>(this.coreLibraryConfig.apiUrl, 'api/oltp/setting', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = ModelFactory.assignSetting(edge.node)))
    );
  }

  updateOne(settingInput: SettingInput, viewOptions: SettingViewOptions = SettingService.SettingFullView): Observable<Setting> {

    let view = SettingService.buildView(viewOptions);

    var request = {
      query: `mutation updateOne($setting:SettingInput!) { updateOne(setting: $setting) ${view} }`,
      variables: { setting: settingInput }
    }

    return this.httpService.graph<Setting>(this.coreLibraryConfig.apiUrl, 'api/oltp/setting', request, 'updateOne').pipe(
      map(x => ModelFactory.assignSetting(x))
    );
  }

  updateMany(settingsInput: SettingInput[], viewOptions: SettingViewOptions = SettingService.SettingFullView): Observable<Setting[]> {

    let view = SettingService.buildView(viewOptions);

    var request = {
      query: `mutation updateMany($settings:[SettingInput!]!) { updateMany(settings: $settings) ${view} }`,
      variables: { settings: settingsInput }
    }

    return this.httpService.graph<Setting[]>(this.coreLibraryConfig.apiUrl, 'api/oltp/setting', request, 'updateMany').pipe(
      map(x => x.map(y => ModelFactory.assignSetting(y)))
    );
  }

  public static buildView(viewOptions: SettingViewOptions) {

    let view = `uid ownerUid type name value`;

    return '{ ' + view + ' }';
  }
}

export interface SettingViewOptions {
}

