import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Observable, first } from 'rxjs';
import { ItemConfiguratorContext } from "../../item-configurator-context";
import { SettingProvider, TenantProvider, TextEntryModalProvider, slideInAnimation, slideOutAnimation } from "core";
import { TransactionSettings } from "../../../../models";
import { DeviceSettings } from "downmain-device";
import { TerminalProvider } from "downmain-terminal";

@Component({
  selector: 'transaction-item-configurator-mods-notes',
  templateUrl: './item-configurator-mods-notes.component.html',
  styleUrls: ['./item-configurator-mods-notes.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfiguratorModsNotesComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public item: { notes?: string[] }
  @Input() public itemDisplay: Observable<string>
  @Output() public configurationChanged = new EventEmitter();

  public transactionSettings$: Observable<TransactionSettings>;
  public display: string;

  constructor(
    private tenantProvider: TenantProvider,
    private terminalProvider: TerminalProvider,
    private settingProvider: SettingProvider,
    private textEntryModalProvider: TextEntryModalProvider
  ) {
    this.transactionSettings$ = this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid);
  }

  addNote() {

    this.settingProvider.getOneByTypeAndOwner$<DeviceSettings>('DeviceSettings', this.terminalProvider.active.uid).pipe(first()).subscribe(deviceSettings => {
      this.itemDisplay.subscribe(display => {
        this.textEntryModalProvider.open({ title: 'Note for: ' + display, initialValue: '', maxLength: 38 }, deviceSettings.textEntryStyle).afterClosed().subscribe(result => {
          if (result?.textEntry?.length > 0) {
            this.item.notes = this.item.notes || [];
            this.item.notes.push(result.textEntry);
          }
        })
      });
    });
  }

  editNote(note: string) {

    this.settingProvider.getOneByTypeAndOwner$<DeviceSettings>('DeviceSettings', this.terminalProvider.active.uid).pipe(first()).subscribe(deviceSettings => {
      this.itemDisplay.subscribe(display => {
        this.textEntryModalProvider.open({ title: 'Note for: ' + display, initialValue: note, maxLength: 38 }, deviceSettings.textEntryStyle).afterClosed().subscribe(result => {
          if (result) {
            this.item.notes.splice(this.item.notes.indexOf(note), 1, result.textEntry);
          }
        })
      });
    });
  }

  deleteNote(note: string) {

    this.item.notes.splice(this.item.notes.indexOf(note), 1);
  }
}
