import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import { IdentityUser } from 'core';
import { CacheService } from 'core';
import { EmployeeService } from '../services/employee.service';

@Injectable()
export class PosCoreIdentityUserResolver {

  constructor(
    private cacheService: CacheService,
    private employeeService: EmployeeService
  ) {
  }

  resolve(uid: string): Observable<IdentityUser> {

    if (uid) {
      return this.cacheService.getOrAdd(uid, () => {
        return this.employeeService.getByUid(uid);
      }).pipe(
        map(employee => {
          var identityUser = new IdentityUser();
          identityUser.id = employee.uid;
          identityUser.username = employee.getFullName();
          identityUser.firstName = employee.firstName;
          identityUser.lastName = employee.lastName;

          return identityUser;
        })
      );
    }

    return of(null);

  }
}
