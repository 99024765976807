<div style="display: flex; flex-direction: column; height: 100%;">

  <app-page-header>

    <ng-container left>
      <button mat-flat-button style="width: 4.1666vw;" (click)="navigateHome()">
        <mat-icon style="font-weight: bold;">home</mat-icon>
      </button>
      <button mat-flat-button style="width: 4.1666vw; margin-left: 4px;" (click)="navigateBack()">
        <mat-icon style="font-weight: bold;">arrow_back</mat-icon>
      </button>
    </ng-container>

    <ng-container middle>{{title}}</ng-container>

    <app-paginator right [dataSource]="dataSource" [paginator]="paginator"></app-paginator>

  </app-page-header>

  <app-action-bar [actionBarConfiguration]="actionBarConfiguration"></app-action-bar>

  <div style="flex: 1; overflow-y: auto;">
    <app-positions-table [dataSource]="dataSource" [columns]="columns"></app-positions-table>
  </div>

</div>

<!-- <mat-toolbar color="primary">
  <span>{{ title }}</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<mat-toolbar class="action-toolbar" color="primary">

  <div class="full">

    <button mat-stroked-button color="accent" class="filter" (click)="new()">
      <mat-icon>create</mat-icon>
      <span>New</span>
    </button>

    <button mat-stroked-button color="accent" class="filter" [disabled]="!canEditSelected" (click)="edit(selection.selected[0])">
      <mat-icon>edit</mat-icon>
      <span>Edit</span>
    </button>

    <button mat-stroked-button color="warn" class="filter" [disabled]="!canDeleteSelected" (click)="delete(selection.selected[0])">
      <mat-icon>delete_outline</mat-icon>
      <span>Delete</span>
    </button>
  </div>

  <button mat-icon-button [matMenuTriggerFor]="smallMenu" class="compact">
    <mat-icon>more_vert</mat-icon>
  </button>

  <span style="flex: 1 1 auto;"></span>

</mat-toolbar>

<div style="position: absolute; top: 138px; left: 5px; right: 5px; bottom: 5px; overflow-y: auto;">

  <table class="themed" style="width: 100%;" mat-table [dataSource]="positions">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox *ngIf="selection.isMultipleSelection()" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef color="accent">Name</th>
      <td mat-cell *matCellDef="let position">{{ position.name }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef color="accent">Description</th>
      <td mat-cell *matCellDef="let position">{{ position.description }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let position">
        <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="position.canEdit" (click)="edit(position)">Edit</button>
        <button mat-stroked-button color="warn" style="margin-right: 5px;" click-stop-propagation *ngIf="position.canDelete" (click)="delete(position)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected]="selection.isSelected(row)"></tr>
  </table>

</div>


<mat-menu #smallMenu="matMenu" xPosition="before">
  <button mat-menu-item color="accent" (click)="new()" class="">
    <mat-icon>create</mat-icon>
    <span>New</span>
  </button>

  <button mat-menu-item color="warn" *ngIf="canEditSelected" (click)="edit(selection.selected[0])">
    <mat-icon>edit</mat-icon>
    <span>Edit</span>
  </button>

  <button mat-menu-item color="warn" *ngIf="canDeleteSelected" (click)="delete(selection.selected[0])">
    <mat-icon>delete_outline</mat-icon>
    <span>Delete</span>
  </button>
</mat-menu> -->
