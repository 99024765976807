import { Injectable } from '@angular/core';
import { EntityEventProvider, isCaseInsensitiveEqual, isEqualUUID, SecurityUser } from 'core';
import { AuthIdentityProvider } from 'core';
import { Employee } from '../models/employee';
import { EmployeeService } from '../services/employee.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { concatMap, filter, takeUntil } from 'rxjs/operators';

@Injectable()
export abstract class AuthEmployeeProvider {

  public static readonly cookieKey = '.authEmployeeUid';

  public authEmployee$: Observable<Employee>;
  public authEmployee: Employee;

  protected authEmployeeSubject = new BehaviorSubject<Employee>(null);
  private destroyed$ = new Subject();

  constructor(
    protected authIdentityProvider: AuthIdentityProvider,
    protected entityEventProvider: EntityEventProvider,
    protected employeeService: EmployeeService,
  ) {
    this.authEmployee$ = this.authEmployeeSubject.asObservable();

    entityEventProvider.event$.pipe(
      filter(event => isCaseInsensitiveEqual(event.type, 'EmployeeContext')),
      filter(event => isEqualUUID(event.uid, this.authEmployeeSubject.value?.uid)),
      concatMap(event => employeeService.getByUid(event.uid)),
      takeUntil(this.destroyed$)
    ).subscribe(employee => {
      this.authEmployeeSubject.next(employee);
      // PosModelFactory.mergeEmployee(employee, this.authEmployeeSubject.value);
    });
  }

  ngOnDestroy() {

    this.destroyed$.next(null);
  }

  setAuthEmployee(authEmployee: Employee) {

    this.authEmployee = authEmployee;

    if (authEmployee) {
      localStorage.setItem(AuthEmployeeProvider.cookieKey, authEmployee.uid);

      if (this.authEmployeeSubject.value?.uid?.toUpperCase() != authEmployee.uid.toUpperCase()) {
        this.authEmployeeSubject.next(authEmployee);
      }

      this.authIdentityProvider.setUserIdentity(<SecurityUser>{ id: authEmployee.identityId, email: authEmployee.email, roles: ['uptownbar.member', 'uptownbar.employee'] });
    } else {
      localStorage.removeItem(AuthEmployeeProvider.cookieKey)

      if (this.authEmployeeSubject.value) {
        this.authEmployeeSubject.next(null);
      }

      this.authIdentityProvider.setUserIdentity(null);
    }

  };

  clear() {

    this.authEmployee = null;

    localStorage.removeItem(AuthEmployeeProvider.cookieKey)
    this.authEmployeeSubject.next(null);

    this.authIdentityProvider.clear();
  }
}
