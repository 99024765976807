import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageModalProvider, MessageModalData } from 'core';
import { SpinnerModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { LoggingProvider } from 'core';
import { AuthEmployeeProvider } from 'pos-core';
import { OrderStatusKeys } from 'store-core';
import { EditableOrder } from 'store-core';
import { EditableOrderDataHandler } from 'store-core';
import { OrderService } from 'store-core';
import { OrderViewOptions } from 'store-core';

@Component({
  selector: 'app-back-office-store-order',
  templateUrl: './back-office-store-order.component.html',
  styleUrls: ['./back-office-store-order.component.scss']
})
export class BackOfficeStoreOrderComponent implements OnInit {

  public order: EditableOrder;

  @Input() canEdit: boolean;
  public canAccept = false;
  public canPrint = false;

  private orderEditDataHandler: EditableOrderDataHandler;
  private orderViewOptions = <OrderViewOptions>{
    includeShippingAddress: true,
    includeBillingAddress: true,
    includeItems: { configurationDepth: 2 },
    includeAdjustments: true,
    includePayments: { includeCardTransaction: true },
    includeDisbursements: true
  };

  public countries: string[] = [
    "United States",
    "Canada",
    "Mexico"
  ];

  constructor(
    private navigationProvider: NavigationProvider,
    private activatedRoute: ActivatedRoute,
    private spinnerModalProvider: SpinnerModalProvider,
    private messageModalProvider: MessageModalProvider,
    private orderService: OrderService,
    private authEmployeeProvider: AuthEmployeeProvider
  ) {
    this.orderEditDataHandler = new EditableOrderDataHandler(new LoggingProvider());
  }

  ngOnInit() {

    this.navigationProvider.updateTitle("Order");

    this.activatedRoute.params.subscribe(params => {
      let orderUid = params['orderUid'];

      if (orderUid) {
        this.fetchOrder(orderUid);
      }
    });
  }

  public accept() {

    this.authEmployeeProvider.authEmployee$.subscribe(authEmployee => {
      this.orderService.accept(this.order.uid, this.orderViewOptions).subscribe(order => {
        if (order) {
          this.navigationProvider.navigate(['/backoffice/transactions/']);
        }
      });
    });
  }

  private fetchOrder(orderUid: string) {

    let spinnerModalRef = this.spinnerModalProvider.open();
    spinnerModalRef.afterOpened().subscribe(() => {
      this.orderService.getByUid(orderUid, this.orderViewOptions).subscribe(order => {
        if (order) {
          this.order = this.orderEditDataHandler.mergeOrder(this.order || new EditableOrder(), order);
          this.updateEnablement();

          spinnerModalRef.close();
        } else {
          spinnerModalRef.close();

          this.messageModalProvider.open(<MessageModalData>{ title: 'Unknown Order', message: 'Cannot find this order.' }).afterClosed().subscribe(() => {
            this.navigationProvider.navigate(['/backoffice/integration/store/orders']);
          });
        }
      });
    });
  }

  private updateEnablement() {

    this.canAccept = [OrderStatusKeys.Submitted, OrderStatusKeys.Reviewing].some(x => x.toUpperCase() == this.order.orderStatusUid.value.toUpperCase());
    // this.canPrint.next(!this.form.dirty && this.form.valid);
  }
}

