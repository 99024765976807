<div style="height: 100%; font-size: 32px; line-height: 32px; padding: 2px;">
  <div style="height: 100%; display: flex; flex-direction: column;">

    <div style="display: flex; justify-content: center;">
      <span style="text-align: center; font-size: 1.2em; line-height: 1.2em;">Open Transactions ({{ (selectableTransactions$ | async)?.length }})</span>
    </div>

    <div style="flex: 1; display: flex; min-height: 0;">

      <div style="flex: 1; flex-wrap: wrap; overflow-y: auto;">
        <div *ngFor="let selectableTransaction of selectableTransactions$ | async" style="display: inline-block; padding: 2px 2px 4px 2px; width: 50%;" (click)="selectTransaction(selectableTransaction)">
          <div style="height: 100%; border-width: 2px; border-style: solid; background-color: rgba(255, 255, 255, 0.1); color: white; padding: 2px;" [style.opacity]="(selectableTransaction.lock | async)?.isRemoteDevice ? '.3' : 'inherit'" [style.border-color]="(selectableTransaction.lock | async)?.isLocalDevice ? 'white' : ((selectableTransaction.lock | async)?.isRemoteDevice ? 'red' : 'rgba(255, 255, 255, 0.12)')">

            <div style="display: flex;">
              <div style="width: 50%;"><span style="color: rgba(255, 255, 255, .9); text-overflow: ellipsis;">{{ selectableTransaction.transaction.notes }}</span></div>
              <div style="width: 25%;">
                <div *ngIf="selectableTransaction.transaction.holdCardReference then hold_card else no_hold_card"> </div>
                <ng-template #hold_card><span style="color: rgba(255, 255, 255, .9);">
                    <mat-icon>credit_card</mat-icon>&nbsp;{{ selectableTransaction.transaction.holdCardReference }}
                  </span></ng-template>
                <ng-template #no_hold_card><span *ngIf="!selectableTransaction.hasDepartmentItems" style="color: rgba(215, 0, 25, .8);">NO HOLD CARD</span></ng-template>
              </div>
              <div style="width: 25%; text-align: right;"><span style="color: rgba(255, 255, 255, .9);">{{ selectableTransaction.transaction.totalDue | currency:'USD':'symbol':'1.2-2' }}</span></div>
            </div>

            <div *ngIf="selectableTransaction.recentOrderedItems?.length > 0" class="recent-ordered-items" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; color: rgba(255, 255, 255, .3)">
              <span *ngFor="let recentOrderedItem of selectableTransaction.recentOrderedItems">{{ recentOrderedItem | async }}</span>
            </div>

            <div style="display: flex;">
              <span style="width: 75%;">
                &nbsp;
                <!-- Keeps div from floating up for some reason -->
                <span *ngIf="selectableTransaction.transaction.lockUid && (selectableTransaction.transaction.lockUid | lockResolver| async)?.isLocalDevice">
                  <mat-icon>lock</mat-icon>&nbsp;LOCKED HERE
                </span>
                <span *ngIf="selectableTransaction.transaction.lockUid && !((selectableTransaction.transaction.lockUid | lockResolver | async)?.isLocalDevice)">
                  <mat-icon>lock</mat-icon>&nbsp;LOCKED ON <span>{{ (selectableTransaction.transaction.lockUid | lockResolver | async)?.getDisplay() }}</span>
                </span>
              </span>
              <span style="width: 25%; text-align: right;">{{ selectableTransaction.transaction.logisticTypeUid | logisticType }}</span>
            </div>

          </div>
        </div>
      </div>

      <!-- <div>
        Commands
      </div> -->

    </div>

    <div style="display: flex;">

      <div style="flex: 1 1 auto;"></div>

      <app-timeout-button [percent]="timeoutPercent" (click)="close()">Close</app-timeout-button>

    </div>

  </div>
</div>