import { Directive, Input, Type, ViewContainerRef, EventEmitter, Output } from '@angular/core';
import { Product } from 'downtown-product';
import { EditableTransactionItemConfiguration } from '../models/editable-transaction/editable-transaction';
import { HtmlElementMap } from './html-element-map';


@Directive({
  selector: '[product-configuration-detail]',
})
export class ProductConfigurationDetailDirective {

  @Input() public product: Product;
  @Input() public itemConfiguration: EditableTransactionItemConfiguration;
  @Input() readOnly: boolean;
  @Output() public configurationChanged = new EventEmitter();

  constructor(
    private container: ViewContainerRef
  ) {
  }

  ngOnInit(): void {

    if (this.product && this.itemConfiguration) {
      let component: Type<any> | null = null;

      HtmlElementMap.filter(x => x.map.indexOf('transaction-product-configuration-detail') > -1).forEach(
        x => {
          if (component === null) component = x.component;
        }
      );

      const componentRef = this.container.createComponent(component);
      componentRef.instance.product = this.product;
      componentRef.instance.itemConfiguration = this.itemConfiguration;
      componentRef.instance.readOnly = this.readOnly;
      componentRef.instance.configurationChanged = this.configurationChanged;
    }
  }
}
