<div mat-dialog-title style="text-align: center;"><span>New System Message</span></div>
<mat-dialog-content>
  <mat-action-list [formGroup]="form">

    <mat-form-field style="width: 100%;">
      <mat-select formControlName="systemMessageTypeUid" placeholder="System Message Type">
        <mat-option *ngFor="let systemMessageType of systemMessageTypes" [value]="systemMessageType.systemMessageTypeUid">{{ systemMessageType.display }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 100%;">
      <mat-select formControlName="terminalUid" placeholder="Terminal">
        <mat-option *ngFor="let terminal of terminals" [value]="terminal.terminalUid">{{ terminal.name }}</mat-option>
      </mat-select>
    </mat-form-field>

  </mat-action-list>
</mat-dialog-content>
<mat-dialog-actions>
  <!-- <button mat-flat-button color="warn" [mat-dialog-close]>Cancel</button> -->

  <!-- <app-pos-button (click)="newGuest()">New...</app-pos-button> -->

  <span style="flex: 1 1 auto;"></span>

  <app-pos-button [disabled]="!form.dirty" color="primary" (click)="create()">OK</app-pos-button>
  <app-pos-button (click)="cancel()" [styles]="{ backgroundColor: 'red' }">Cancel</app-pos-button>

</mat-dialog-actions>