<div [formGroup]="context.shippingForm" style="display: flex;">
  <div style="flex: 1; display: flex;" formGroupName="address">
    <div style="flex: 1;">Shipping Address</div>
    <div style="flex: 3;">
      <div>
        <mat-form-field style="width: 100%;">
          <mat-select formControlName="country" placeholder="Country *" autocomplete="country">
            <mat-option *ngFor="let country of countries" [value]="country">{{ country }}</mat-option>
          </mat-select>
          <mat-error *ngIf="context.shippingForm.get('address').get('country').hasError('required')">Country is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 100%;">
          <input matInput type="text" formControlName="address1" placeholder="Address *" autocomplete="address" />
          <mat-error *ngIf="context.shippingForm.get('address').get('address1').hasError('required')">Address is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 100%;">
          <input matInput type="text" formControlName="address2" placeholder="" autocomplete="address2" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 100%;">
          <input matInput type="text" formControlName="city" placeholder="City *" autocomplete="city" />
          <mat-error *ngIf="context.shippingForm.get('address').get('city').hasError('required')">City is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 100%;">
          <input matInput type="text" formControlName="state" placeholder="State *" autocomplete="state" />
          <mat-error *ngIf="context.shippingForm.get('address').get('state').hasError('required')">State is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 100%;">
          <input matInput type="text" formControlName="postalCode" placeholder="ZIP/Postal Code *" autocomplete="postalCode" />
          <mat-error *ngIf="context.shippingForm.get('address').get('postalCode').hasError('required')">Postal Code is required</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
