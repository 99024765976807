<div *ngIf="form" [formGroup]="form" style="display: flex; flex-direction: column;">

  <div style=" min-width: 500px; max-width: 50vw;">

    <h2>General Device Settings</h2>

    <div style="display: flex;">
      <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <label>Text Entry Style</label>
      </div>
      <mat-form-field style="flex: 2;">
        <mat-select formControlName="textEntryStyle">
          <mat-option *ngFor="let textEntryStyle of textEntryStyles" [value]="textEntryStyle">{{ textEntryStyle }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <hr style="width: 100%; margin-top: 20px;" />

    <h2>Devices&nbsp;<span>({{ form.controls.settings.controls.length }})</span> </h2>

    <div style="display: flex;">

      <div style="flex: 2;">

        <app-expansion-container #expansionContainer style="flex: 1; background-color: rgba(255, 255, 255, 0.06);">

          <app-expansion-panel *ngFor="let device of form.controls.settings.controls">
            <app-expansion-panel-header>
              <app-expansion-panel-title>
                <div style="display: flex; align-items: center;">
                  <div style="flex: 1;" (click)="deviceSettingPanelClick(device)">
                    <span style="font-size: 24px;">{{ device.get('name').value }}</span>
                  </div>
                  <mat-icon (click)="deleteDevice(device)">delete</mat-icon>
                </div>
              </app-expansion-panel-title>
            </app-expansion-panel-header>
          </app-expansion-panel>

          <app-expansion-panel>
            <app-expansion-panel-header (click)="addDevice()">
              <app-expansion-panel-title>
                <span style="font-size: 24px;">+ Add Device</span>
              </app-expansion-panel-title>
            </app-expansion-panel-header>
          </app-expansion-panel>

        </app-expansion-container>

      </div>

      <div style="flex: 3;">

        <ng-container *ngIf="selectedDeviceSettingFormGroup" [formGroup]="selectedDeviceSettingFormGroup">

          <div style="display: flex;">
            <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
              <span style="flex: 1;">Enabled</span>
            </div>
            <div style="flex: 2;">
              <mat-checkbox formControlName="enabled"></mat-checkbox>
            </div>
          </div>

          <div style="min-width: 500px; max-width: 50vw;">
            <div style="display: flex;">
              <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
                <label>UID</label>
              </div>
              <mat-form-field style="flex: 2;">
                <input matInput type="text" [readonly]="true" formControlName="uid" />
              </mat-form-field>
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
              <label>Display Name</label>
            </div>
            <mat-form-field style="flex: 2;">
              <input matInput type="text" formControlName="name" />
            </mat-form-field>
          </div>

          <div style="display: flex;">
            <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
              <label>Device Type</label>
            </div>
            <mat-form-field style="flex: 2;">
              <mat-select formControlName="deviceType">
                <mat-option *ngFor="let deviceType of deviceTypes" [value]="deviceType">{{ deviceType }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div style="display: flex;">
            <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
              <label>Platform</label>
            </div>
            <mat-form-field style="flex: 2;">
              <input matInput type="text" [readonly]="true" formControlName="platform" />
            </mat-form-field>
          </div>

          <ng-container *ngIf="selectedDeviceSettingFormGroup.get('platform').value == 'OPOS'">

            <div [formGroup]="getMetadataFormGroup(selectedDeviceSettingFormGroup.controls, 'deviceName')">
              <div style="display: flex;">
                <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
                  <label>Device Name</label>
                </div>
                <mat-form-field style="flex: 2;">
                  <input matInput type="text" [readonly]="true" formControlName="value" />
                </mat-form-field>
              </div>
            </div>

          </ng-container>

          <ng-container *ngIf="selectedDeviceSettingFormGroup.get('platform').value == 'Network'">

            <div [formGroup]="getMetadataFormGroup(selectedDeviceSettingFormGroup.controls, 'ipAddress')">
              <div style="display: flex;">
                <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
                  <label>IP Address</label>
                </div>
                <mat-form-field style="flex: 2;">
                  <input matInput type="text" formControlName="value" />
                </mat-form-field>
              </div>
            </div>

            <div [formGroup]="getMetadataFormGroup(selectedDeviceSettingFormGroup.controls, 'port')">
              <div style="display: flex;">
                <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
                  <label>Port</label>
                </div>
                <mat-form-field style="flex: 2;">
                  <input matInput type="number" formControlName="value" />
                </mat-form-field>
              </div>
            </div>

            <div style="display: flex;">
              <div style="flex: 1; text-align: right; padding-right: 50px; padding-top: 15.75px;">
              </div>
              <div style="flex: 2;">
                <button mat-button mat-button-flat style="border: 1px solid rgba(255, 255, 255, 0.12);" (click)="printTest(selectedDeviceSettingFormGroup)">Print Test</button>
              </div>
            </div>

            <div>
            </div>

          </ng-container>

        </ng-container>

      </div>

    </div>

  </div>

</div>