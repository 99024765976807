import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AmountFrequencyModalComponent } from "./amount-frequency-modal.component";

@Injectable()
export class AmountFrequencyModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: AmountFrequencyModalData): MatDialogRef<AmountFrequencyModalComponent, AmountFrequencyModalResult> {

    return this.dialog.open(AmountFrequencyModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface AmountFrequencyModalData {
  title: string,
  amount: number,
  workUnitUid: string
}

export interface AmountFrequencyModalResult {
  amount: number,
  workUnitUid: string
}
