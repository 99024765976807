import { Injectable } from '@angular/core';
import { AuthIdentityProvider, EntityEventProvider } from 'core';
import { AuthEmployeeProvider } from 'pos-core';
import { EmployeeService } from 'pos-core';

@Injectable()
export class SiteAuthEmployeeProvider extends AuthEmployeeProvider {

  constructor(
    authIdentityProvider: AuthIdentityProvider,
    entityEventProvider: EntityEventProvider,
    employeeService: EmployeeService
  ) {
    super(authIdentityProvider, entityEventProvider, employeeService);

    authIdentityProvider.userIdentity$.subscribe(authIdentity => {
      if (authIdentity) {
        if (authIdentity.roles.some(x => x == 'uptownbar.employee')) {
          employeeService.getByIdentityId(authIdentity.id).subscribe(employee => {
            this.authEmployee = employee;
            this.authEmployeeSubject.next(employee);
          });
        }
      } else {
        this.authEmployeeSubject.next(null);
      }
    });
  }
}
