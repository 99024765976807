import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, map, concatMap, takeUntil } from 'rxjs/operators';
import { ActionBarConfiguration, ActionConfiguration, FilterConfiguration, FilterOptionConfiguration } from 'core';
import { Paginator } from 'core';
import { PaginationInput } from 'core';
import { CheckListModalProvider, CheckListModalData } from 'core';
import { ConfirmModalProvider } from 'core';
import { SelectListModalProvider, SelectListModalData } from 'core';
import { SpinnerModalComponent } from 'core';
import { SpinnerModalProvider } from 'core';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { MemberDataSource, MemberModel } from 'pos-core';
import { MemberStatusKeys, EmployeeStatusKeys } from 'pos-core';
import { Employee } from 'pos-core';
import { AuthEmployeeProvider } from 'pos-core';
import { EmployeeService } from 'pos-core';
import { IdentityUserService } from 'pos-core';
import { MemberService } from 'pos-core';

@Component({
  selector: 'app-administration-members',
  templateUrl: './administration-members.component.html',
  styleUrls: ['./administration-members.component.scss']
})
export class AdministrationMembersComponent implements OnInit {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: MemberDataSource;
  public paginator: Paginator;

  public title: string;
  public columns: string[];

  private destroyed$ = new Subject();

  constructor(
    private navigationProvider: NavigationProvider,
    private authEmployeeProvider: AuthEmployeeProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private checkListModalProvider: CheckListModalProvider,
    private selectListModalProvider: SelectListModalProvider,
    private memberService: MemberService,
    private employeeService: EmployeeService,
    private identityUserService: IdentityUserService
  ) {
    this.navigationProvider.setWaypoint('Members');
    this.title = 'Members';

    this.dataSource = new MemberDataSource(this.memberService, false);
    this.paginator = new Paginator(this.dataSource, 0, 25, '', 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        // new ActionConfiguration('New', this.new.bind(this)),
        new ActionConfiguration('Profile', () => this.profile(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Roles', () => this.roles(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Enable', () => this.enable(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEnable)),
        new ActionConfiguration('Disable', () => this.disable(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDisable)),
        new ActionConfiguration('Link Employee', () => this.linkEmployee(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canLinkEmployee)),
        new ActionConfiguration('Unlink Employee', () => this.unlinkEmployee(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canUnlinkEmployee)),
        new ActionConfiguration('Delete', () => this.delete(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDelete))
      ],
      [
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('Active', [MemberStatusKeys.Active]),
            new FilterOptionConfiguration('All', [MemberStatusKeys.Active, MemberStatusKeys.Inactive])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'Active')) }
        )
      ]
    );

    // Build initial columns
    this.columns = [ /*'select', 'memberUid',*/ 'member', 'identity', 'employee'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    combineLatest([
      this.actionBarConfiguration.filters.find(x => x.title == 'Status').selected,
      this.paginator.pageRequest$
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([statusFilterOption, paginationInput]) => {
        let statusFilterUids = statusFilterOption.value;

        this.dataSource.loadData(statusFilterUids, paginationInput, MemberService.MemberFullView);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public profile(member: MemberModel) {

    this.navigationProvider.navigate(['/administration/member', member.uid, 'profile']);
  }

  public roles(member: MemberModel) {

    const options = [
      { value: { id: 'member', display: 'Member' }, checked: member.roles.some(x => x == 'member'), disabled: true },
      { value: { id: 'employee', display: 'Employee' }, checked: member.roles.some(x => x == 'employee'), disabled: true },
      { value: { id: 'manager', display: 'Manager' }, checked: member.roles.some(x => x == 'manager'), disabled: false },
      { value: { id: 'admin', display: 'Admin' }, checked: member.roles.some(x => x == 'admin'), disabled: false }
    ];

    this.checkListModalProvider.open(<CheckListModalData<{ id: string, display: string }>>{
      title: 'Select Roles',
      options: options,
      displayFunc: (value: { id: string, display: string }) => value.display
    }).afterClosed().subscribe(confirm => {
      if (confirm) {
        const added = options.filter(x => x.checked && !member.roles.some(y => y == x.value.id)).map(x => x.value.id);
        const removed = options.filter(x => !x.checked && member.roles.some(y => y == x.value.id)).map(x => x.value.id);

        const operations = [
          ...added.map(x => this.identityUserService.addClaim(member.identityId, 'uptownbar.' + x)),
          ...removed.map(x => this.identityUserService.removeClaim(member.identityId, 'uptownbar.' + x))
        ];

        if (operations.length > 0) {
          let waitDialogRef = this.waitModalProvider.open('Updating...');
          waitDialogRef.afterOpened().subscribe(() => {
            combineLatest(operations).subscribe(results => {
              member.roles = options.filter(x => x.checked).map(x => x.value.id);

              this.dataSource.evaluateEnablement(member);

              waitDialogRef.close();
            });
          })
        }
      }
    });
  }

  public enable(member: MemberModel) {

    let waitDialogRef = this.waitModalProvider.open('Enabling...');

    combineLatest([
      this.authEmployeeProvider.authEmployee$,
      waitDialogRef.afterOpened()
    ]).pipe(
      concatMap(([authEmployee]) => this.dataSource.enable(member)),
      catchError(() => of(null))
    ).subscribe(() => {
      waitDialogRef.close();
    });

  }

  public disable(member: MemberModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Disable Employee', 'Are you certain you want to disable this member?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Disabling...');

        combineLatest([
          this.authEmployeeProvider.authEmployee$,
          waitDialogRef.afterOpened()
        ]).pipe(
          concatMap(([authEmployee]) => this.dataSource.disable(member)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public linkEmployee(member: MemberModel) {

    let waitDialogRef = this.waitModalProvider.open('Loading...');
    waitDialogRef.afterOpened().subscribe(() => {
      this.employeeService.search(null, [EmployeeStatusKeys.Active], <PaginationInput>{ pageSize: 25, pageIndex: 0, sortField: 'lastName', sortStrategy: 'asc' }).subscribe(employeesPage => {
        waitDialogRef.close();

        this.selectListModalProvider.open(<SelectListModalData<Employee>>{
          title: 'Select Employee',
          options: employeesPage.edges.map(x => x.node).sort((x, y) => x.getFullName().localeCompare(y.getFullName())),
          displayFunc: employee => employee.getFullName()
        }).afterClosed().subscribe(employee => {
          if (employee) {
            member.isEmployee = true;

            let waitDialogRef2 = this.waitModalProvider.open('Linking Employee...');

            waitDialogRef2.afterOpened().subscribe(() => {
              this.employeeService.updateIdentity((<Employee>employee).uid, member.identityId).subscribe(employee => {
                member.isEmployee = true;
                member.employeeUid = employee.uid;
                member.employeeFullName = employee.getFullName();
                member.roles.push('employee');

                this.dataSource.evaluateEnablement(member);

                waitDialogRef2.close();
              });
            });
          }
        });
      });
    });
  }

  public unlinkEmployee(member: MemberModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Unlink Employee', 'Are you certain you want to unlink this member?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Unlinking...');

        combineLatest([
          this.authEmployeeProvider.authEmployee$,
          waitDialogRef.afterOpened()
        ]).pipe(
          concatMap(([authEmployee, n]) => {
            return this.employeeService.updateIdentity(member.employeeUid, null)
          }),
          catchError(() => of(null))
        ).subscribe(() => {
          member.isEmployee = false;
          member.employeeUid = null;
          member.employeeFullName = null;
          member.roles = member.roles.filter(x => x != 'employee');

          this.dataSource.evaluateEnablement(member);

          waitDialogRef.close();
        });
      }
    });
  }

  public delete(member: MemberModel) {

    this.confirmModalProvider.open('Delete Member', 'Are you certain you want to delete this member?').afterClosed().subscribe(value => {
      let waitDialogRef = this.waitModalProvider.open('Deleting member...');

      waitDialogRef.afterOpened().subscribe(() => {
        this.memberService.delete(member.uid).subscribe(x => {
          member.memberStatusUid = MemberStatusKeys.Deleted;
          waitDialogRef.close();
          // this.refresh();
        });
      });
    });
  }
}
