import { Injectable } from "@angular/core";

import { 
  CharacterPrintCommand, 
  ClearStylesCommand, 
  ESCPOSDocument, 
  PartialCutAfterFeedCommand, 
  PrintBarcodeCommand, 
  PrintCommand, 
  ReverseModeCommand, 
  SetBarcodeHeightCommand, 
  SetBarcodeLabelPostionCommand, 
  SetBarcodeWidthCommand, 
  SetStylesCommand, 
  TextPrintCommand, 
  UpsideDownModeCommand 
} from "../models/esc-pos-document";

@Injectable()
export class DocumentProvider {

  buildTestPrintDocument(): ESCPOSDocument {

    const document = <ESCPOSDocument>{
      commands: []
    };
    document.commands.push(DocumentProvider.CenterAlign());
    document.commands.push(DocumentProvider.PrintLine());
    document.commands.push(DocumentProvider.PrintLine('UPTOWN BAR AND GRILL'));
    document.commands.push(DocumentProvider.PrintLine('416 E. Main St.'));
    document.commands.push(DocumentProvider.PrintLine('Watertown, WI 53094'));
    document.commands.push(DocumentProvider.PrintLine());
    document.commands.push(DocumentProvider.SetBarcodeHeightInDots(80));
    document.commands.push(DocumentProvider.SetBarcodeWidth('Default'));
    document.commands.push(DocumentProvider.SetBarcodeLabelPostion('None'));
    document.commands.push(DocumentProvider.PrintBarcode('ITF', '0123456789'));
    document.commands.push(DocumentProvider.PrintLine());
    document.commands.push(DocumentProvider.PrintLine());
    document.commands.push(DocumentProvider.PrintLine());
    document.commands.push(DocumentProvider.PrintLine());
    document.commands.push(DocumentProvider.PrintLine());
    document.commands.push(DocumentProvider.PartialCutAfterFeed(5));

    return document;
  }

  buildReceipt(): ESCPOSDocument {

    const document = <ESCPOSDocument>{
      commands: []
    };
    document.commands.push(DocumentProvider.CenterAlign());

    return document;
  }

  public static ClearStyles(): PrintCommand {

    return <ClearStylesCommand>{ command: 'clear-styles' };
  }

  public static SetStyles(options: ISetStylesOptions): PrintCommand {

    return <SetStylesCommand>{ ...{ command: 'set-styles' }, ...options };
  }

  public static CenterAlign(): PrintCommand {

    return <CharacterPrintCommand>{ command: 'center-align' };
  }

  public static LeftAlign(): PrintCommand {

    return <CharacterPrintCommand>{ command: 'left-align' };
  }

  public static RightAlign(): PrintCommand {

    return <CharacterPrintCommand>{ command: 'right-align' };
  }

  public static ReverseMode(activate: boolean): PrintCommand {

    return <ReverseModeCommand>{ command: 'reverse-mode', activate: activate };
  }

  public static UpsideDownMode(activate: boolean): PrintCommand {

    return <UpsideDownModeCommand>{ command: 'upside-down-mode', activate: activate };
  }

  public static PrintLine(text?: string): PrintCommand {

    return <TextPrintCommand>{ command: 'print-line', text: text };
  }

  public static PartialCutAfterFeed(lines: number): PrintCommand {

    return <PartialCutAfterFeedCommand>{ command: 'partial-cut-after-feed', lines: lines };
  }

  public static SetBarcodeHeightInDots(dots: number) {

    return <SetBarcodeHeightCommand>{ command: 'set-barcode-height-in-dots', height: dots };
  }

  public static SetBarcodeWidth(width: string): PrintCommand {

    return <SetBarcodeWidthCommand>{ command: 'set-bar-width', width: width };
  }

  public static SetBarcodeLabelPostion(position: string): PrintCommand {

    return <SetBarcodeLabelPostionCommand>{ command: 'set-bar-label-position', position: position };
  }

  public static PrintBarcode(barcodeType: string, barcode: string): PrintCommand {

    return <PrintBarcodeCommand>{ command: 'print-bar-code', barcodeType: barcodeType, barcode: barcode };
  }
}

export interface ISetStylesOptions {

  fontB?: boolean,
  proportional?: boolean,
  condensed?: boolean,
  bold?: boolean,
  doubleHeight?: boolean,
  doubleWidth?: boolean,
  italic?: boolean,
  underline?: boolean
};
