import { Component, Input, EventEmitter, Output } from "@angular/core";
import { slideInAnimation, slideOutAnimation } from "core";
import { CacheService } from "core";
import { EditableOrderItem } from "../../../../models/editable-order";
import { Product } from "downtown-product";
import { ProductService } from "downtown-product";
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-order-item-configuration-editor',
  templateUrl: './order-item-configuration-editor.component.html',
  styleUrls: ['./order-item-configuration-editor.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class OrderItemConfigurationEditorComponent {

  @Input() public orderItem: EditableOrderItem;
  @Input() public total: number;
  @Output() public configurationChanged = new EventEmitter();

  constructor(
    private cacheService: CacheService,
    private productService: ProductService
  ) {
  }

  ngOnInit() {

  }

  getSaleableProductDisplay(): Observable<string> {

    return this.cacheService.getOrAdd(
      this.orderItem.productUid.value,
      () => this.getProduct(this.orderItem.productUid.value, this.orderItem.productVersion.value).pipe(shareReplay(1))
    ).pipe(
      map(product => {
        return product.name;
      })
    );
  }

  getPortionDisplay(portionUid: string): Observable<string> {

    return this.cacheService.getOrAdd(
      `${this.orderItem.productUid}_${portionUid}`,
      () => {
        return this.cacheService.getOrAdd(
          this.orderItem.productUid.value,
          () => this.getProduct(this.orderItem.productUid.value, this.orderItem.productVersion.value).pipe(shareReplay(1))
        ).pipe(
          map(product => {
            let portion = product && product.configuration ? product.configuration.getPortion(portionUid) : null;
            return portion ? portion.name : null;
          })
        );
      }
    );
  }

  public getProduct(productUid: string, version: number): Observable<Product> {

    return this.productService.getByUid(productUid, version);
  }

  public handleConfigurationChanged() {

    this.configurationChanged.emit();
  }
}
