import { MatDialog,MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { TerminalDeviceModalComponent } from './terminal-device-modal.component';
import { DeviceInfo } from '../../models/device-info';

@Injectable()
export class TerminalDeviceModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: TerminalDeviceModalData): MatDialogRef<TerminalDeviceModalComponent, DeviceInfo> {

    return this.dialog.open<TerminalDeviceModalComponent, TerminalDeviceModalData, DeviceInfo>(TerminalDeviceModalComponent, {
      width: '50vw',
      height: '50vh',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface TerminalDeviceModalData {

  oposOptions: DeviceInfo[],
  netowrkOptions: DeviceInfo[],
  displayFunc: (item: DeviceInfo) => {};
}
