<div>
  <app-pos-button (click)="buttonPressed.emit('2.50')" [styles]="{ backgroundColor: '#2e466b', border: 'solid 3px #5e85ba' }">2.50</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('5.00')" [styles]="{ backgroundColor: '#2e466b', border: 'solid 3px #5e85ba' }">5.00</app-pos-button>
</div>
<div>
  <app-pos-button (click)="buttonPressed.emit('3.00')" [styles]="{ backgroundColor: '#2e466b', border: 'solid 3px #5e85ba' }">3.00</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('6.00')" [styles]="{ backgroundColor: '#2e466b', border: 'solid 3px #5e85ba' }">6.00</app-pos-button>
</div>
<div>
  <app-pos-button (click)="buttonPressed.emit('3.50')" [styles]="{ backgroundColor: '#2e466b', border: 'solid 3px #5e85ba' }">3.50</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('7.00')" [styles]="{ backgroundColor: '#2e466b', border: 'solid 3px #5e85ba' }">7.00</app-pos-button>
</div>
<div>
  <app-pos-button (click)="buttonPressed.emit('4.00')" [styles]="{ backgroundColor: '#2e466b', border: 'solid 3px #5e85ba' }">4.00</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('8.00')" [styles]="{ backgroundColor: '#2e466b', border: 'solid 3px #5e85ba' }">8.00</app-pos-button>
</div>
<div>
  <app-pos-button (click)="buttonPressed.emit('4.50')" [styles]="{ backgroundColor: '#2e466b', border: 'solid 3px #5e85ba' }">4.50</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('10.00')" [styles]="{ backgroundColor: '#2e466b', border: 'solid 3px #5e85ba' }">10.00</app-pos-button>
</div>