import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from 'rxjs';
import { CategorySelectModalData, CategorySelectModalResult } from "./category-select-modal.provider";
import { takeUntil } from 'rxjs/operators';
import { Category } from "../../models/category";
import { DepartmentProvider } from "../../providers";
import { Department } from "../../models";

@Component({
  selector: 'app-category-select-modal',
  templateUrl: './category-select-modal.component.html',
  styleUrls: ['./category-select-modal.component.scss']
})
export class CategorySelectModalComponent {

  public title: string;
  public departments: Department[];

  private destroyed$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CategorySelectModalData,
    private dialogRef: MatDialogRef<CategorySelectModalComponent, CategorySelectModalResult>,
    private departmentProvider: DepartmentProvider,
  ) {
    this.title = this.data.title.toUpperCase();
  }

  ngOnInit() {

    this.departmentProvider.getMany$(this.data.departmentUids).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(departments => {
      this.departments = departments;
    });
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public selectCategory(category: Category) {

    this.dialogRef.close(<CategorySelectModalResult>{
      departmentUid: category.departmentUid,
      categoryUid: category.uid
    });
  }

  public cancel() {

    this.dialogRef.close();
  }
}
