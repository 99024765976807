<div style="max-width: 1024px; margin-left: auto; margin-right: auto; height: 100%;">
  <div class="" style="position: relative; padding-left: 30px; padding-right: 30px; display: flex; height: 100%;">

    <mat-drawer-container class="example-container" style="height: 100%; width: 100%;" (backdropClick)="closeCart()">

      <mat-drawer-content>

        <div style="height: 100%; display: flex; flex-direction: column;">

          <div style="display: flex;">
            <div class="">
              <p class="lead"></p>
            </div>
            <span style="flex: 1 1 auto;"></span>
            <button mat-stroked-button *ngIf="cart?.items?.length > 0" class="" style="margin-right: 10px;" (click)="checkOut()">Check Out</button>
            <button mat-stroked-button (click)="openCart()">CART<span *ngIf="cart?.itemQuantity > 0">&nbsp;({{ cart?.itemQuantity }})</span></button>
          </div>

          <div style="flex: 1; overflow-y: auto;" [formGroup]="form">

            <div *ngFor="let category of categories">
              <h2>{{ category.name }}</h2>

              <div style="display: flex; flex-wrap: wrap;">

                <div *ngFor="let product of category.products" style="flex: 1; min-width: 50%; padding: 6px;" (click)="configureProduct(product)">
                  <div style="background-color: rgb(89, 74, 36); border: 1px solid rgb(128, 96, 15); border-radius: 2px; padding: 6px;">

                    <div style="font-size: 16px; font-weight: bold;">{{ product.title }}</div>
                    <div>{{ product.subtitle }}</div>

                  </div>
                </div>
              </div>
            </div>

<!--
            <mat-card *ngFor="let product of products">
              <div style="display: flex;" (click)="configureProduct(product)">
                <div>
                  <mat-card-title>{{ product.name }}</mat-card-title>
                  <mat-card-subtitle>{{ product.sku }}</mat-card-subtitle>
                </div>
                <span style="flex: 1 1 auto;"></span>
                <div>
                  <input matInput numeric-only formControlName="{{ product.uid }}" style="width: 5em;" />&nbsp;@&nbsp;
                  {{ product.eachAmount | currency:'USD':'symbol':'1.2-2' }}
                  <button mat-stroked-button (click)="addToCart(product)">Add to Cart</button>
                </div>
              </div>
              <mat-card-content>{{ product.description }}</mat-card-content>
            </mat-card> -->
          </div>

        </div>

      </mat-drawer-content>

      <mat-drawer #sidenav mode="over" position="end" style="min-width: 35%;">
        <div style="display: flex; flex-direction: column;">
          <div style="flex: 1 1 auto;">
            <!-- <app-site-public-store-cart [cart]="cart" [canEdit]="true"></app-site-public-store-cart> -->
          </div>
          <div *ngIf="cart?.itemQuantity > 0" style="display: flex;">
            <span style="flex: 1 1 auto;"></span>
            <button mat-stroked-button class="" (click)="checkOut()">Check out</button>
          </div>
        </div>
      </mat-drawer>

    </mat-drawer-container>

  </div>

</div>
