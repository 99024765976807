import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, shareReplay } from "rxjs";
import { SecurityUser } from "../models/security-user";

@Injectable()
export abstract class AuthIdentityProvider {

  public userIdentity: SecurityUser;
  public userIdentity$: Observable<SecurityUser>;

  public systemIdentity: SecurityUser;
  public systemIdentity$: Observable<SecurityUser>;

  protected userIdentitySubject = new BehaviorSubject<SecurityUser>(null);
  protected systemIdentitySubject = new BehaviorSubject<SecurityUser>(null);

  constructor(
  ) {
    this.userIdentity$ = this.userIdentitySubject.asObservable();
    this.systemIdentity$ = this.systemIdentitySubject.asObservable();
  }

  protected abstract loadUserIdentity(): void;
  protected abstract loadSystemIdentity(): void;

  setUserIdentity(userIdentity: SecurityUser) {

    this.userIdentity = userIdentity;
    this.userIdentitySubject.next(userIdentity);
  };


  setSystemIdentity(systemIdentity: SecurityUser) {

    this.systemIdentity = systemIdentity;
    this.systemIdentitySubject.next(systemIdentity);
  };

  public abstract setUserIdentityToken(token: string): void;
  public abstract setSystemToken(token: string): void;

  public abstract getUserIdentityToken(): string;
  public abstract getSystemIdentityToken(): string;

  public getIdentityToken(): string {

    if (this.getUserIdentityToken()) {
      return this.getUserIdentityToken();
    }

    return this.getSystemIdentityToken();
  }

  clear() {

    this.userIdentity = null;
    this.userIdentitySubject.next(null);

    this.systemIdentity = null;
    this.systemIdentitySubject.next(null);
  }
}
