import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import { ChartType } from 'angular-google-charts';
import { ActionBarConfiguration, FilterConfiguration, FilterOptionConfiguration } from 'core';
import { DateModalProvider, DateModalData } from 'core';
import { NavigationProvider } from 'core';
import { TransactionService } from 'downtown-transaction';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-back-office-reports-daily-sales-chart',
  templateUrl: './back-office-reports-daily-sales-chart.component.html',
  styleUrls: ['./back-office-reports-daily-sales-chart.component.scss']
})
export class BackOfficeReportsDailySalesChartComponent implements OnInit {

  @ViewChild('chartContainer', { static: true }) chartContainerEl: ElementRef;

  public title: string;
  public actionBarConfiguration: ActionBarConfiguration;

  public chart: any;
  private destroyed$ = new Subject();

  constructor(
    private navigationProvider: NavigationProvider,
    private dateModalProvider: DateModalProvider,
    public transactionService: TransactionService
  ) {
    this.navigationProvider.setWaypoint('Daily Sales');
    this.title = 'Daily Sales';

    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var yesterday = new Date(new Date(today).setDate(today.getDate() - 1));

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        // new ActionConfiguration('View', () => this.view(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canView)),
        // new ActionConfiguration('Edit', () => this.edit(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        // new ActionConfiguration('Reopen', () => this.reopen(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canReopen))
      ],
      [
        new FilterConfiguration('Date',
          [
            new FilterOptionConfiguration('Today', { afterDateTimeUtc: today, beforeDateTimeUtc: today }),
            new FilterOptionConfiguration('Yesterday', { afterDateTimeUtc: yesterday, beforeDateTimeUtc: yesterday }),
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'Today')) }
        ),
        // new FilterConfiguration('Status',
        //   [
        //     new FilterOptionConfiguration('All', null),
        //     new FilterOptionConfiguration('Open', [TransactionStatusKeys.Open, TransactionStatusKeys.Tab]),
        //     new FilterOptionConfiguration('Closed', [TransactionStatusKeys.Closed, TransactionStatusKeys.Committed]),
        //   ],
        //   (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }
        // )
      ]
    );

    const dateFilter = this.actionBarConfiguration.filters.find(x => x.title == 'Date');
    for (var i = 2; i < 7; i++) {
      var date = new Date(new Date(today).setDate(today.getDate() - i));
      var filterOption = new FilterOptionConfiguration(
        new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric', day: 'numeric' }).format(date),
        { afterDateTimeUtc: date, beforeDateTimeUtc: date }
      );
      dateFilter.options.push(filterOption);
    }

    dateFilter.options.push(new FilterOptionConfiguration("Custom", { afterDateTimeUtc: null, beforeDateTimeUtc: null }));
  }

  ngOnInit() {

    // this.chart = {
    //   visible: false,
    //   type: ChartType.ColumnChart,
    //   title: null,
    //   width: this.chartContainerEl.nativeElement.clientWidth,
    //   height: this.chartContainerEl.nativeElement.clientHeight,
    //   options: {
    //     backgroundColor: '#000',
    //     vAxis: {
    //       title: 'Sales $',
    //       textStyle: {
    //         fontSize: 14
    //       }
    //     },
    //     hAxis: {
    //       textStyle: {
    //         fontSize: 14
    //       }
    //     }
    //   }
    // }

    // this.actionBarConfiguration.filters.find(x => x.title == 'Date').selected.pipe(
    //   takeUntil(this.destroyed$),
    // ).subscribe(dateRangeFilterOption => {

    //   if (dateRangeFilterOption.display == 'Custom') {
    //     this.selectDateRangeFilter();
    //   } else {
    //     // Adjust date range
    //     var afterDateTime = new Date(new Date(dateRangeFilterOption.value.afterDateTimeUtc).setHours(dateRangeFilterOption.value.afterDateTimeUtc.getHours() + 8));// 8am
    //     var beforeDateTime = new Date(new Date(dateRangeFilterOption.value.afterDateTimeUtc).setHours(dateRangeFilterOption.value.afterDateTimeUtc.getHours() + 28));//// 4am the next day
    //     beforeDateTime = new Date(beforeDateTime.setMilliseconds(beforeDateTime.getMilliseconds() - 1));

    //     var adjustedDateRangeFilter = <DateRangeFilterInput>{
    //       afterDateTimeUtc: afterDateTime.toISOString(),
    //       beforeDateTimeUtc: beforeDateTime.toISOString()
    //     };

    //     this.transactionService.search(adjustedDateRangeFilter, null, null).subscribe(transactionPage => {

    //       const hourlySales = new Array<{ hour: number, dateTime: Date, saleCount: number, totalSold: number }>();
    //       for (var t = afterDateTime; t <= beforeDateTime; t = new Date(t.setHours(t.getHours() + 1))) {
    //         hourlySales.push({ hour: t.getHours(), dateTime: new Date(t), saleCount: 0, totalSold: 0 });
    //       }

    //       transactionPage.edges.map(x => x.node).sort((l, r) => {
    //         if (l.closeDateTimeUtc == null || r.closeDateTimeUtc == null) {
    //           return l.openDateTimeUtc.getTime() - r.openDateTimeUtc.getTime();
    //         }

    //         return l.closeDateTimeUtc.getTime() - r.closeDateTimeUtc.getTime();
    //       }).forEach(transaction => {
    //         var transactionHour = transaction.closeDateTimeUtc ? transaction.closeDateTimeUtc.getHours() : transaction.openDateTimeUtc.getHours();
    //         var hourlySalesData = hourlySales.find(x => x.hour == transactionHour);

    //         if (hourlySalesData) {
    //           hourlySalesData.saleCount += 1;
    //           hourlySalesData.totalSold += transaction.total;
    //         } else {
    //           console.log(transaction);
    //         }
    //       });

    //       var startDate = afterDateTime.toLocaleTimeString('en-US',
    //         { hour12: true, day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
    //       );
    //       var endDate = beforeDateTime.toLocaleTimeString('en-US',
    //         { hour12: true, day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
    //       );

    //       this.chart.title = `Daily Sales ${startDate} - ${endDate}`;

    //       this.chart.data = hourlySales.map(hourSales => {

    //         return [
    //           hourSales.dateTime.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' }),
    //           { v: hourSales.totalSold, f: hourSales.totalSold }
    //         ];
    //       });

    //       this.loadChart();
    //     });
    //   }
    // });
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  private selectDateRangeFilter() {

    let dialogRef = this.dateModalProvider.open(<DateModalData>{
      initialDate: new Date()
    });

    dialogRef.afterClosed().subscribe(date => {
      if (date) {
        var startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        const filter = this.actionBarConfiguration.filters.find(x => x.title == 'Date');
        var filterOption = new FilterOptionConfiguration(new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric', day: 'numeric' }).format(startDate), { afterDateTimeUtc: startDate, beforeDateTimeUtc: startDate });
        filter.options.splice(filter.options.length - 1, 0, filterOption);
        filter.selected.next(filterOption);
      }
    });
  }

  loadChart() {

    this.chart.visible = false;
    setTimeout(() => {
      this.chart.visible = true;
    }, 1000);
  }

  resizeChart(event: any) {

    //Reset the width and height based on current window size
    // this.chart.visible = false;
    this.chart.width = this.chartContainerEl.nativeElement.clientWidth;
    this.chart.height = this.chartContainerEl.nativeElement.clientHeight;

    this.loadChart();
  }

  onSelect(event: any) {
    console.log(event);
  }
}


