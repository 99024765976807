import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { ProductContext } from "./product-context";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ProductService } from '../../services/product.service';
import { ProductAndPortionModalProvider } from '../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { ConfigurationFormFuncs, ConfigurationFuncs } from '../../functions/configuration.functions';
import { ProductConfigurationProductReference } from '../../models/product';
import { TenantProvider, getDescendentRouteSnapshot } from 'core';
import { ProductProvider } from "../../providers";

export abstract class ConfigurationComponentBase {

  abstract set context(context: ProductContext)
  abstract get context(): ProductContext;
  public scopes: { [scope: string]: string };

  public constructor(
    protected activatedRoute: ActivatedRoute,
    protected tenantProvider: TenantProvider,
    protected productProvider: ProductProvider,
    protected productAndPortionModalProvider: ProductAndPortionModalProvider
  ) {
    this.activatedRoute.params.subscribe(x => {
      this.scopes = this.getScopes(this.activatedRoute);
    });
  }

  protected getScopes(activatedRoute: ActivatedRoute): { [scope: string]: string } {

    let scopes = <{ [scope: string]: string }>{};

    var params = activatedRoute.snapshot.params;

    if (params['portionUid']) {
      scopes['portion'] = params['portionUid'];
    }
    if (params['inclusionGroupUid']) {
      scopes['inclusionGroup'] = params['inclusionGroupUid'];
    }
    if (params['optionUid']) {
      scopes['inclusionGroupOption'] = params['optionUid'];
    }
    if (params['addOnUid']) {
      scopes['addOn'] = params['addOnUid'];
    }

    return scopes;
  }

  protected getScope(activatedRoute: ActivatedRoute): string {

    var params = activatedRoute.snapshot.params;
    var scope = params['portionUid'] ? 'portion' : 'product';

    if (params['inclusionGroupUid']) {
      scope += 'InclusionGroup';
    } else if (params['addOnUid']) {
      scope += 'AddOn';
    }

    return scope;
  }

  protected getBaseProductNavigationSegments(): string[] {

    var segments = ['/backoffice/product'];
    var routeSnapshot = getDescendentRouteSnapshot(this.activatedRoute);

    var productuid = routeSnapshot.paramMap.get('productUid');
    if (productuid) {
      segments.push(productuid);
    }

    return segments;
  }

  protected getBasePortionNavigationSegments(): string[] {

    var segments = this.getBaseProductNavigationSegments();
    var routeSnapshot = getDescendentRouteSnapshot(this.activatedRoute);

    var portionUid = routeSnapshot.paramMap.get('portionUid');
    if (portionUid) {
      segments.push(...['portion', portionUid]);
    }

    return segments;
  }

  public getValueOrUnknown(value: any): any {

    return ConfigurationFormFuncs.configuration.getValueOrUnknown(value);
  }

  public getProductPortionLabel(productUid: string, portionUid: string, alias: string): Observable<string> {

    if (alias) {
      return of(alias);
    } else {
      if (productUid != null && portionUid != null) {
        return this.productProvider.getOneCached$(productUid, null).pipe(
          map(product => {
            if (product && product.configuration) {
              const portion = product.configuration.portions.find(x => x.uid.toUpperCase() == portionUid.toUpperCase());
              if (portion) {
                return `${product.name} - ${portion.name}`;
              }
            }

            return 'Unknown';
          })
        );
      } else {
        return of('Unassigned');
      }
    }
  }

  public showSelectProductModal(form: UntypedFormGroup) {

    this.productProvider.search$(this.tenantProvider.currentUid, null, null, null, null, ProductService.ProductFullView).subscribe(productPage => {
      let products = productPage.edges.map(x => x.node).filter(x => {
        return x.uid.toLowerCase() != this.context.productForm.get('uid').value.toLowerCase() && x.configuration && x.configuration.portions && x.configuration.portions.filter(y => y.availableAsInclusion).length > 0;
      });

      this.productAndPortionModalProvider.open({
        title: 'Select Product and Portion',
        products: products
      }).afterClosed().subscribe(result => {
        if (result) {
          let productReference = form.get('productReference');

          let uidFormControl = productReference.get('uid');
          uidFormControl.setValue(result.productReference.uid);
          uidFormControl.markAsDirty();

          let portionFormControl = productReference.get('portionUid');
          portionFormControl.setValue(result.productReference.portionUid);
          portionFormControl.markAsDirty();

          let versionFormControl = productReference.get('version');
          versionFormControl.setValue(result.productReference.version);
          versionFormControl.markAsDirty();
        }
      });
    });
  }

  public validProductReference(productReference: ProductConfigurationProductReference) {

    return ConfigurationFuncs.validProductReference(productReference);
  }

  public getResolvedProductReference(scope: string, uid: string): ProductConfigurationProductReference {

    var scopes = Object.assign({}, this.scopes)
    scopes[scope] = uid;

    return ConfigurationFormFuncs.resolveProductReference(this.context.configurationForm, scopes);
  }

  public getResolvedInclusionGroupFormValue(inclusionGroupUid: string, key: string): any {

    var scopes = Object.assign({}, this.scopes)
    scopes['inclusionGroup'] = inclusionGroupUid;

    return ConfigurationFormFuncs.resolveFormValue(this.context.configurationForm, null, key, scopes);
  }

  public getResolvedInclusionGroupOptionFormValue(inclusionGroupOptionUid: string, key: string): any {

    var scopes = Object.assign({}, this.scopes)
    scopes['inclusionGroupOption'] = inclusionGroupOptionUid;

    return ConfigurationFormFuncs.resolveFormValue(this.context.configurationForm, null, key, scopes);
  }

  public getResolvedAddOnFormValue(addOnUid: string, key: string) {

    var scopes = Object.assign({}, this.scopes)
    scopes['addOn'] = addOnUid;

    return ConfigurationFormFuncs.resolveFormValue(this.context.configurationForm, null, key, scopes);
  }

  public getResolvedPreparationFormValue(preparationUid: string, key: string) {

    var scopes = Object.assign({}, this.scopes)
    scopes['preparation'] = preparationUid;

    return ConfigurationFormFuncs.resolveFormValue(this.context.configurationForm, null, key, scopes);
  }

  public getResolvedVariationFormValue(variationUid: string, key: string) {

    var scopes = Object.assign({}, this.scopes)
    scopes['variation'] = variationUid;

    return ConfigurationFormFuncs.resolveFormValue(this.context.configurationForm, null, key, scopes);
  }

  public getProductInclusionGroupForm(form: UntypedFormGroup): UntypedFormGroup {

    var inclusionGroupUid = form.get('uid').value;

    return ConfigurationFormFuncs.inclusionGroups.getInclusionGroupForm(this.context.configurationForm, inclusionGroupUid);
  }

  public getLinkableProductInclusionGroupFormsForPortionForm(portionForm: UntypedFormGroup): UntypedFormGroup[] {

    return ConfigurationFormFuncs.inclusionGroups.getLinkableProductInclusionGroupFormsForPortionForm(this.context.configurationForm, portionForm);
  }

  public getProductInclusionGroupFormValue(form: UntypedFormGroup, key: string): any {

    var inclusionGroupUid = form.get('uid').value;

    return ConfigurationFormFuncs.inclusionGroups.getInclusionGroupFormValue(this.context.configurationForm, inclusionGroupUid, key);
  }

  public getLinkableProductInclusionGroupOptionFormsForInclusionGroupForm(inclusionGroupForm: UntypedFormGroup): UntypedFormGroup[] {

    return ConfigurationFormFuncs.inclusionGroupOptions.getLinkableProductInclusionGroupOptionFormsForInclusionGroupForm(this.context.configurationForm, inclusionGroupForm);
  }

  public getProductInclusionGroupOptionForm(form: UntypedFormGroup): UntypedFormGroup {

    var inclusionGroupUid = form.parent.parent.get('uid').value;
    var optionUid = form.get('uid').value;

    return ConfigurationFormFuncs.inclusionGroupOptions.getInclusionGroupOptionForm(this.context.configurationForm, inclusionGroupUid, optionUid);
  }

  public getProductInclusionGroupOptionFormValue(form: UntypedFormGroup, key: string): any {

    var inclusionGroupUid = form.parent.parent.get('uid').value;
    var optionUid = form.get('uid').value;

    return ConfigurationFormFuncs.inclusionGroupOptions.getInclusionGroupOptionFormValue(this.context.configurationForm, inclusionGroupUid, optionUid, key);
  }

  public resolveProductInclusionGroupOptionFormValue(form: UntypedFormGroup, key: string) {

    return ConfigurationFormFuncs.inclusionGroupOptions.resolveProductInclusionGroupOptionFormValue(this.context.configurationForm, form, key);
  }

  public getProductAddOnForm(form: UntypedFormGroup): UntypedFormGroup {

    return ConfigurationFormFuncs.addOns.getAddOnForm(this.context.configurationForm, form.get('uid').value);
  }

  public getLinkableProductAddOnFormsForPortionForm(portionForm: UntypedFormGroup): UntypedFormGroup[] {

    return ConfigurationFormFuncs.addOns.getLinkableProductAddOnFormsForPortionForm(this.context.configurationForm, portionForm);
  }

  public getProductAddOnFormValue(form: UntypedFormGroup, key: string) {

    return ConfigurationFormFuncs.addOns.getAddOnFormValue(this.context.configurationForm, form.get('uid').value, key);
  }

  public resolveProductAddOnFormValue(form: UntypedFormGroup, key: string) {

    return ConfigurationFormFuncs.addOns.resolveProductAddOnFormValue(this.context.configurationForm, form, key);
  }

  public getProductPreparationForm(form: UntypedFormGroup): UntypedFormGroup {

    return ConfigurationFormFuncs.preparations.getPreparationForm(this.context.configurationForm, form.get('uid').value);
  }

  public getLinkableProductPreparationFormsForPortionForm(portionForm: UntypedFormGroup): UntypedFormGroup[] {

    return ConfigurationFormFuncs.preparations.getLinkableProductPreparationFormsForPortionForm(this.context.configurationForm, portionForm);
  }

  public getProductPreparationFormValue(form: UntypedFormGroup, key: string) {

    return ConfigurationFormFuncs.preparations.getPreparationFormValue(this.context.configurationForm, form.get('uid').value, key);
  }

  public resolvePreparationFormValue(form: UntypedFormGroup, key: string, scope: string) {

    return ConfigurationFormFuncs.preparations.resolvePreparationFormValue(this.context.configurationForm, form, key, scope);
  }

  public getProductInclusionGroupOptionPreparationForm(form: UntypedFormGroup): UntypedFormGroup {

    var inclusionGroupUid = form.parent.parent.parent.parent.get('uid').value;
    var optionUid = form.parent.parent.get('uid').value;
    var preparationUid = form.get('uid').value;

    return ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationForm(this.context.configurationForm, inclusionGroupUid, optionUid, preparationUid);
  }

  public getProductVariationForm(form: UntypedFormGroup): UntypedFormGroup {

    var variationUid = form.get('uid').value;

    return ConfigurationFormFuncs.variations.getVariationForm(this.context.configurationForm, variationUid);
  }

  public getLinkableProductVariationFormsForPortionForm(portionForm: UntypedFormGroup): UntypedFormGroup[] {

    return ConfigurationFormFuncs.variations.getLinkableProductVariationFormsForPortionForm(this.context.configurationForm, portionForm);
  }

  public getProductVariationFormValue(form: UntypedFormGroup, key: string) {

    var variationUid = form.get('uid').value;

    return ConfigurationFormFuncs.variations.getVariationFormValue(this.context.configurationForm, variationUid, key);
  }

  public resolveProductVariationFormValue(form: UntypedFormGroup, key: string, scope: string) {

    return ConfigurationFormFuncs.variations.resolveVariationFormValue(this.context.configurationForm, form, key, scope);
  }
}
