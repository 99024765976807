import { ErrorHandlingProvider, isEqualUUID, LoggingProvider, SettingProvider, TenantProvider } from "core";
import { catchError, map, Observable, of, concatMap, tap, take, finalize } from "rxjs";
import { UUID } from "angular2-uuid";
import { TransactionStatusEnum } from '../../keys';
import { fromEditableTransactionAdjustment, fromEditableTransactionCharge, fromEditableTransactionChargeAdjustment, fromEditableTransactionGuest, fromEditableTransactionItem, fromEditableTransactionItemAdjustment, fromEditableTransactionPayment } from '../../functions/transaction-item-configuration/transaction-input-factory';
import { TransactionItem, TransactionItemAdjustment, TransactionCharge, TransactionChargeAdjustment, TransactionAdjustment, TransactionPayment, Transaction, TransactionGuest } from '../../models/transaction';
import { EditableTransaction, EditableTransactionAdjustment, EditableTransactionCharge, EditableTransactionChargeAdjustment, EditableTransactionGuest, EditableTransactionItem, EditableTransactionItemAdjustment, EditableTransactionPayment } from '../../models/editable-transaction/editable-transaction';
import { EditableTransactionDataHandler, EditableTransactionTypes } from '../../models/editable-transaction/editable-transaction-data-handler';
import { TransactionService } from '../../services/transaction.service';
import { TransactionSettings } from "../../models";
import { RemoteLoggingProvider } from "downmain-terminal";
import { TransactionProvider } from "../../providers";

/* This base class handles persisting actions on the transaction with regard for technical and state correctness
but without regard for business rules.  Implementing business rules is expected to be handled in sub classes. */
export abstract class TransactionEditorBase {

  public editableTransaction: EditableTransaction;
  protected lockUid: string

  constructor(
    protected errorHandlingProvider: ErrorHandlingProvider,
    protected tenantProvider: TenantProvider,
    protected settingProvider: SettingProvider,
    protected transactionProvider: TransactionProvider,
    protected remoteLoggingProvider: RemoteLoggingProvider,
  ) {

  }

  protected getTransaction$(uid: string): Observable<EditableTransaction> {

    return this.transactionProvider.getOne$(uid).pipe(
      catchError(error => this.errorHandlingProvider.handleError<Transaction>(error)),
      map(transaction => EditableTransactionDataHandler.mergeTransaction(null, transaction))
    );
  }

  protected openTransaction$(editableTransaction: EditableTransaction, correlationUid: string): Observable<EditableTransaction> {

    if (editableTransaction && !editableTransaction.uid) {

      editableTransaction.uid = UUID.UUID();

      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | openTransaction$ => transactionService.open observe`);

      return this.transactionProvider.open$(
        editableTransaction.uid,
        editableTransaction.number.value,
        editableTransaction.logisticTypeUid
      ).pipe(
        catchError(error => this.errorHandlingProvider.handleError<Transaction>(error)),
        map(transaction => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | openTransaction$ <= transactionService.open`);

          return EditableTransactionDataHandler.mergeTransaction(editableTransaction, transaction);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | openTransaction$ => transactionService.open finalize`);
        })
      );
    }

    return of(editableTransaction);
  }

  protected cancelTransaction$(editableTransaction: EditableTransaction, correlationUid: string): Observable<EditableTransaction> {

    if (editableTransaction?.uid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | cancelTransaction$ => transactionService.cancel observe`);

      return this.transactionProvider.cancel$(editableTransaction.uid).pipe(
        catchError(error => this.errorHandlingProvider.handleError<Transaction>(error)),
        map(x => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | cancelTransaction$ <= transactionService.cancel`);

          return EditableTransactionDataHandler.mergeTransaction(editableTransaction, x);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | cancelTransaction$ => transactionService.cancel finalize`);
        })
      );
    }

    return of(editableTransaction);
  }

  protected updateNotes$(editableTransaction: EditableTransaction, correlationUid: string): Observable<EditableTransaction> {

    if (editableTransaction?.uid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | updateNotes$ => transactionService.updateNotes observe`);

      return this.transactionProvider.updateNotes$(editableTransaction.uid, editableTransaction.notes.value).pipe(
        catchError(error => this.errorHandlingProvider.handleError<Transaction>(error)),
        map(x => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | updateNotes$ <= transactionService.updateNotes`);

          return EditableTransactionDataHandler.mergeTransaction(editableTransaction, x);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | updateNotes$ => transactionService.updateNotes finalize`);
        })
      );
    }

    return of(editableTransaction);
  }

  protected updateHoldCardReference$(editableTransaction: EditableTransaction, correlationUid: string): Observable<EditableTransaction> {

    if (editableTransaction?.uid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | updateHoldCardReference$ => transactionService.updateHoldCardReference observe`);

      return this.transactionProvider.updateHoldCardReference$(editableTransaction.uid, editableTransaction.holdCardReference.value).pipe(
        catchError(error => this.errorHandlingProvider.handleError<Transaction>(error)),
        map(x => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | updateHoldCardReference$ <= transactionService.updateHoldCardReference`);

          return EditableTransactionDataHandler.mergeTransaction(editableTransaction, x);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | updateHoldCardReference$ => transactionService.updateHoldCardReference finalize`);
        })
      );
    }

    return of(editableTransaction);
  }

  protected updateLogisticType$(editableTransaction: EditableTransaction, correlationUid: string): Observable<EditableTransaction> {

    if (editableTransaction?.uid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | updateLogisticType$ => transactionService.updateLogisticType observe`);

      return this.transactionProvider.updateLogisticType$(editableTransaction.uid, editableTransaction.logisticTypeUid).pipe(
        catchError(error => this.errorHandlingProvider.handleError<Transaction>(error)),
        map(x => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | updateLogisticType$ <= transactionService.updateLogisticType`);

          return EditableTransactionDataHandler.mergeTransaction(editableTransaction, x);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | updateLogisticType$ => transactionService.updateLogisticType finalize`);
        })
      );
    }

    return of(editableTransaction);
  }

  protected addViewer$(editableTransaction: EditableTransaction, lockUid: string, force: boolean = false, correlationUid: string): Observable<EditableTransaction> {

    if (editableTransaction != null && (editableTransaction.lockUid.value == null || force) && !isEqualUUID(editableTransaction.lockUid.value, lockUid)) {
      return of(editableTransaction);
      // this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | addLock$ => transactionService.addLock observe`);

      // return this.transactionProvider.addLock$(editableTransaction.uid, lockUid, force).pipe(
      //   catchError(error => this.errorHandlingProvider.handleError<Transaction>(error)),
      //   map(x => {
      //     this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | addLock$ <= transactionService.addLock`);

      //     return EditableTransactionDataHandler.mergeTransaction(editableTransaction, x);
      //   }),
      //   finalize(() => {
      //     this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | addLock$ => transactionService.addLock finalize`);
      //   })
      // );
    }

    return of(null);
  }

  protected removeViewer$(editableTransaction: EditableTransaction, lockUid: string, force: boolean = false, correlationUid: string): Observable<EditableTransaction> {

    if (editableTransaction?.uid) {
      return of(editableTransaction);
      // this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | unlockTransaction$ => transactionService.removeLock observe`);

      // let lockTerminalUid = editableTransaction.lockUid.value;
      // if (lockTerminalUid != null && isEqualUUID(lockTerminalUid, this.lockUid)) {
      //   return this.transactionProvider.removeLock$(editableTransaction.uid, lockUid, force).pipe(
      //     catchError(error => this.errorHandlingProvider.handleError<Transaction>(error)),
      //     map(x => {
      //       this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | unlockTransaction$ <= transactionService.removeLock`);

      //       return EditableTransactionDataHandler.mergeTransaction(editableTransaction, x);
      //     }),
      //     finalize(() => {
      //       this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | unlockTransaction$ => transactionService.removeLock finalize`);
      //     })
      //   );
      // }
    }

    return of(editableTransaction);
  }

  protected addLock$(editableTransaction: EditableTransaction, lockUid: string, force: boolean = false, correlationUid: string): Observable<EditableTransaction> {

    if (editableTransaction != null && (editableTransaction.lockUid.value == null || force) && !isEqualUUID(editableTransaction.lockUid.value, lockUid)) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | addLock$ => transactionService.addLock observe`);

      return this.transactionProvider.addLock$(editableTransaction.uid, lockUid, force).pipe(
        catchError(error => this.errorHandlingProvider.handleError<Transaction>(error)),
        map(x => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | addLock$ <= transactionService.addLock`);

          return EditableTransactionDataHandler.mergeTransaction(editableTransaction, x);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | addLock$ => transactionService.addLock finalize`);
        })
      );
    }

    return of(null);
  }

  protected removeLock$(editableTransaction: EditableTransaction, lockUid: string, force: boolean = false, correlationUid: string): Observable<EditableTransaction> {

    if (editableTransaction?.uid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | unlockTransaction$ => transactionService.removeLock observe`);

      let lockTerminalUid = editableTransaction.lockUid.value;
      if (lockTerminalUid != null && isEqualUUID(lockTerminalUid, this.lockUid)) {
        return this.transactionProvider.removeLock$(editableTransaction.uid, lockUid, force).pipe(
          catchError(error => this.errorHandlingProvider.handleError<Transaction>(error)),
          map(x => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | unlockTransaction$ <= transactionService.removeLock`);

            return EditableTransactionDataHandler.mergeTransaction(editableTransaction, x);
          }),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | unlockTransaction$ => transactionService.removeLock finalize`);
          })
        );
      }
    }

    return of(editableTransaction);
  }

  protected closeTransaction$(editableTransaction: EditableTransaction, correlationUid: string): Observable<EditableTransaction> {

    if (editableTransaction && !isEqualUUID(editableTransaction.transactionStatus.value, TransactionStatusEnum.Closed)) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | closeTransaction$ | Transaction eligible for closing`);

      if (editableTransaction.totalDue.value > 0 || editableTransaction.change.value > 0) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | closeTransaction$ | Transaction has total due and change values > 0`);

        throw 'Cannot close transaction. Transaction has total due and change values > 0';
      }

      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | closeTransaction$ -> transactionService.close observe`);

      return this.transactionProvider.close$(editableTransaction.uid).pipe(
        catchError(error => this.errorHandlingProvider.handleError<Transaction>(error)),
        map(transaction => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | closeTransaction$ <= transactionService.close`);

          return EditableTransactionDataHandler.mergeTransaction(editableTransaction, transaction);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | closeTransaction$ -> transactionService.close finalize`);
        })
      );
    }

    return of(editableTransaction);
  }

  protected commitGuest$(editableTransaction: EditableTransaction, guest: EditableTransactionGuest): Observable<EditableTransactionGuest> {

    if (guest.uid) {
      throw 'Not implemented';
    } else {
      this.remoteLoggingProvider.log(`TransactionEditServiceHandler::commitGuest:commit create`);

      guest.uid = UUID.UUID();

      return this.transactionProvider.addGuest$(
        editableTransaction.uid,
        fromEditableTransactionGuest(guest)
      ).pipe(
        catchError(error => this.errorHandlingProvider.handleError<TransactionGuest>(error)),
        tap(x => EditableTransactionDataHandler.mergeTransactionGuest(guest, x)),
        map(x => guest)
      );
    }
  }

  protected abstract commitItem$(editableTransaction: EditableTransaction, transactionItem: EditableTransactionTypes, correlationUid: string): Observable<EditableTransactionTypes>

  protected commitTransactionItem$(editableTransaction: EditableTransaction, transactionItem: EditableTransactionItem, correlationUid: string): Observable<EditableTransactionItem> {

    if (transactionItem && transactionItem.isValid && transactionItem.isDirty) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | commitTransactionItem$ => settingProvider.getOneByTypeAndOwner observe`);

      return this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid).pipe(
        concatMap(transactionSettings => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | commitTransactionItem$ <= settingProvider.getOneByTypeAndOwner`);

          if (!transactionItem.uid && transactionSettings.combineDuplicateItems) {

            const duplicateChecks = [
              (x: EditableTransactionItem, y: EditableTransactionItem) => isEqualUUID(x.departmentUid.value, y.departmentUid.value),
              (x: EditableTransactionItem, y: EditableTransactionItem) => isEqualUUID(x.categoryUid.value, y.categoryUid.value),
              (x: EditableTransactionItem, y: EditableTransactionItem) => isEqualUUID(x.productUid.value, y.productUid.value),
              (x: EditableTransactionItem, y: EditableTransactionItem) => x.getEachAmount() == y.getEachAmount(),
              (x: EditableTransactionItem, y: EditableTransactionItem) => x.configuration.value == null && y.configuration.value == null
            ];

            var existingMatch = editableTransaction.lineItems.find(lineItem => lineItem != transactionItem && duplicateChecks.every(check => check(lineItem, transactionItem)));

            if (existingMatch) {
              existingMatch.quantity.next(existingMatch.quantity.value + transactionItem.getQuantity());

              editableTransaction.lineItems.splice(editableTransaction.lineItems.indexOf(transactionItem), 1)

              transactionItem = existingMatch;
            }
          }

          let handler: Observable<TransactionItem>;
          const transactionItemInput = fromEditableTransactionItem(transactionItem);

          if (transactionItem.uid) {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionItemInput.uid} | commitTransactionItem$ => transactionService.changeItem observe`);

            handler = this.transactionProvider.changeItem$(
              editableTransaction.uid,
              transactionItemInput
            ).pipe(
              tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionItemInput.uid} | commitTransactionItem$ <= transactionService.changeItem`)),
              finalize(() => {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionItemInput.uid} | commitTransactionItem$ => transactionService.changeItem finalize`);
              })
            );
          } else {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionItemInput.uid} | commitTransactionItem$ => transactionService.addItem observe`);

            handler = this.transactionProvider.addItem$(
              editableTransaction.uid,
              transactionItemInput
            ).pipe(
              tap(_ => {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionItemInput.uid} | commitTransactionItem$ <= transactionService.addItem`);
              }),
              finalize(() => {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionItemInput.uid} | commitTransactionItem$ => transactionService.addItem finalize`);
              })
            );
          }

          return handler.pipe(
            catchError(error => this.errorHandlingProvider.handleError<TransactionItem>(error)),
            map(patch => EditableTransactionDataHandler.mergeTransactionItem(transactionItem, patch))
          );
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | commitTransactionItem$ => settingProvider.getOneByTypeAndOwner finalize`);
        })
      );
    } else {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitTransactionItem$ | No transaction, not dirty or not valid.`);

      return of(null);
    }
  }

  protected commitTransactionItemAdjustment$(editableTransaction: EditableTransaction, itemAdjustment: EditableTransactionItemAdjustment, correlationUid: string): Observable<EditableTransactionItemAdjustment> {

    if (itemAdjustment && itemAdjustment.isValid && itemAdjustment.isDirty) {
      let handler: Observable<TransactionItemAdjustment>;
      const itemAdjustmentInput = fromEditableTransactionItemAdjustment(itemAdjustment);

      if (itemAdjustment.uid) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${itemAdjustmentInput.uid} | commitTransactionItemAdjustment$ => transactionService.changeItemAdjustment observe`);

        handler = this.transactionProvider.changeItemAdjustment$(
          editableTransaction.uid,
          itemAdjustment.transactionLineItemUid,
          itemAdjustmentInput
        ).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${itemAdjustmentInput.uid} | commitTransactionItemAdjustment$ <= transactionService.changeItemAdjustment`)),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${itemAdjustmentInput.uid} | commitTransactionItemAdjustment$ => transactionService.changeItemAdjustment finalize`);
          })
        );
      } else {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${itemAdjustmentInput.uid} | commitTransactionItemAdjustment$ => transactionService.addItemAdjustment observe`);

        handler = this.transactionProvider.addItemAdjustment$(
          editableTransaction.uid,
          itemAdjustment.transactionLineItemUid,
          itemAdjustmentInput
        ).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${itemAdjustmentInput.uid} | commitTransactionItemAdjustment$ <= transactionService.addItemAdjustment`)),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${itemAdjustmentInput.uid} | commitTransactionItemAdjustment$ => transactionService.addItemAdjustment finalize`);
          })
        );
      }

      return handler.pipe(
        catchError(error => this.errorHandlingProvider.handleError<TransactionItemAdjustment>(error)),
        map(patch => EditableTransactionDataHandler.mergeTransactionItemAdjustment(itemAdjustment, patch)),
      );
    }

    return of(null);
  }

  protected commitTransactionCharge$(editableTransaction: EditableTransaction, transactionCharge: EditableTransactionCharge, correlationUid: string): Observable<EditableTransactionCharge> {

    if (transactionCharge && transactionCharge.isValid && transactionCharge.isDirty) {
      let handler: Observable<TransactionCharge>;
      const transactionChargeInput = fromEditableTransactionCharge(transactionCharge);

      if (transactionCharge.uid) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionCharge$ => transactionService.updateCharge observe`);

        handler = this.transactionProvider.updateCharge$(
          editableTransaction.uid,
          transactionChargeInput
        ).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionCharge$ <= transactionService.updateCharge`)),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionCharge$ => transactionService.updateCharge finalize`);
          })
        );
      } else {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionCharge$ => transactionService.addCharge observe`);

        handler = this.transactionProvider.addCharge$(
          editableTransaction.uid,
          transactionChargeInput
        ).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionCharge$ <= transactionService.addCharge`)),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionCharge$ => transactionService.addCharge finalize`);
          })
        );
      }

      return handler.pipe(
        catchError(error => this.errorHandlingProvider.handleError<TransactionCharge>(error)),
        map(patch => EditableTransactionDataHandler.mergeTransactionCharge(transactionCharge, patch)),
      );
    }

    return of(null);
  }

  protected commitTransactionChargeAdjustment$(editableTransaction: EditableTransaction, chargeAdjustment: EditableTransactionChargeAdjustment, correlationUid: string): Observable<EditableTransactionChargeAdjustment> {

    if (chargeAdjustment && chargeAdjustment.isValid && chargeAdjustment.isDirty) {
      let handler: Observable<TransactionChargeAdjustment>;
      const transactionChargeInput = fromEditableTransactionChargeAdjustment(chargeAdjustment);

      if (chargeAdjustment.uid) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionChargeAdjustment$ => transactionService.updateChargeAdjustment observe`);

        handler = this.transactionProvider.updateChargeAdjustment$(
          editableTransaction.uid,
          chargeAdjustment.uid,
          transactionChargeInput
        ).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionChargeAdjustment$ <= transactionService.updateChargeAdjustment`)),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionChargeAdjustment$ => transactionService.updateChargeAdjustment finalize`);
          })
        );
      } else {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionChargeAdjustment$ => transactionService.addChargeAdjustment observe`);

        handler = this.transactionProvider.addChargeAdjustment$(
          editableTransaction.uid,
          chargeAdjustment.uid,
          transactionChargeInput
        ).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionChargeAdjustment$ <= transactionService.addChargeAdjustment`)),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeInput.uid} | commitTransactionChargeAdjustment$ => transactionService.addChargeAdjustment finalize`);
          })
        );
      }

      return handler.pipe(
        catchError(error => this.errorHandlingProvider.handleError<TransactionChargeAdjustment>(error)),
        map(patch => EditableTransactionDataHandler.mergeTransactionChargeAdjustment(chargeAdjustment, patch)),
      );
    } else {
      return of(null);
    }
  }

  protected commitTransactionAdjustment$(editableTransaction: EditableTransaction, transactionAdjustment: EditableTransactionAdjustment, correlationUid: string): Observable<EditableTransactionAdjustment> {

    if (transactionAdjustment && transactionAdjustment.isValid && transactionAdjustment.isDirty) {
      let handler: Observable<TransactionAdjustment>;
      const transactionAdjustmentInput = fromEditableTransactionAdjustment(transactionAdjustment);

      if (transactionAdjustment.uid) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionAdjustment.uid} | commitTransactionAdjustment$ => transactionService.changeAdjustment observe`);

        handler = this.transactionProvider.changeAdjustment$(
          editableTransaction.uid,
          transactionAdjustmentInput
        ).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionAdjustment.uid} | commitTransactionAdjustment$ <= transactionService.changeAdjustment`)),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionAdjustment.uid} | commitTransactionAdjustment$ => transactionService.changeAdjustment finalize`);
          })
        );
      } else {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionAdjustment.uid} | commitTransactionAdjustment$ => transactionService.addAdjustment observe`);

        handler = this.transactionProvider.addAdjustment$(
          editableTransaction.uid,
          transactionAdjustmentInput
        ).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionAdjustment.uid} | commitTransactionAdjustment$ <= transactionService.addAdjustment`)),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionAdjustment.uid} | commitTransactionAdjustment$ => transactionService.addAdjustment finalize`);
          })
        );
      }

      return handler.pipe(
        catchError(error => this.errorHandlingProvider.handleError<TransactionAdjustment>(error)),
        map((patch: TransactionAdjustment) => EditableTransactionDataHandler.mergeTransactionAdjustment(transactionAdjustment, patch)),
      );
    }

    return of(null);
  }

  protected commitTransactionPayment$(editableTransaction: EditableTransaction, payment: EditableTransactionPayment, correlationUid: string): Observable<EditableTransactionPayment> {

    if (payment && payment.isValid && payment.isDirty) {

      let handler: Observable<TransactionPayment>;
      const transactionPaymentInput = fromEditableTransactionPayment(payment);

      if (payment.uid) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionPaymentInput.uid} | commitTransactionPayment$ -> transactionService.changePayment observe`);

        handler = this.transactionProvider.changePayment$(editableTransaction.uid, transactionPaymentInput).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionPaymentInput.uid} | commitTransactionPayment$ <= transactionService.changeAdjustment`)),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionPaymentInput.uid} | commitTransactionPayment$ -> transactionService.changePayment finalize`);
          })
        );
      } else {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionPaymentInput.uid} | commitTransactionPayment$ -> transactionService.addPayment observe`);

        handler = this.transactionProvider.addPayment$(editableTransaction.uid, transactionPaymentInput).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionPaymentInput.uid} | commitTransactionPayment$ <= transactionService.addPayment`)),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionPaymentInput.uid} | commitTransactionPayment$ -> transactionService.addPayment finalize`);
          })
        );
      }

      return handler.pipe(
        catchError(error => this.errorHandlingProvider.handleError<TransactionPayment>(error)),
        map((transactionPayment: TransactionPayment) => EditableTransactionDataHandler.mergePayment(payment, transactionPayment)),
      );
    } else {
      return of(null);
    }
  }

  protected cancelTransactionItemAdjustment$(editableTransaction: EditableTransaction, transactionItemAdjustment: EditableTransactionItemAdjustment, correlationUid: string): Observable<EditableTransactionItemAdjustment> {

    if (transactionItemAdjustment.uid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionItemAdjustment.uid} | cancelTransactionItemAdjustment$ -> transactionService.cancelItemAdjustment observe`);

      return this.transactionProvider.cancelItemAdjustment$(
        editableTransaction.uid,
        transactionItemAdjustment.transactionLineItemUid,
        transactionItemAdjustment.uid
      ).pipe(
        catchError(error => this.errorHandlingProvider.handleError<TransactionItemAdjustment>(error)),
        map(patch => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionItemAdjustment.uid} | cancelTransactionItemAdjustment$ <= transactionService.cancelItemAdjustment`);

          return EditableTransactionDataHandler.mergeTransactionItemAdjustment(transactionItemAdjustment, patch);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionItemAdjustment.uid} | cancelTransactionItemAdjustment$ -> transactionService.cancelItemAdjustment finalize`);
        })
      );
    }

    return of(null);
  }

  protected cancelTransactionCharge$(editableTransaction: EditableTransaction, transactionCharge: EditableTransactionCharge, correlationUid: string): Observable<EditableTransactionCharge> {

    if (transactionCharge.uid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionCharge.uid} | cancelTransactionCharge$ -> transactionService.cancelCharge observe`);

      return this.transactionProvider.cancelCharge$(editableTransaction.uid, transactionCharge.uid).pipe(
        catchError(error => this.errorHandlingProvider.handleError<TransactionCharge>(error)),
        map(patch => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionCharge.uid} | cancelTransactionCharge$ <= transactionService.cancelCharge`);

          return EditableTransactionDataHandler.mergeTransactionCharge(transactionCharge, patch);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionCharge.uid} | cancelTransactionCharge$ -> transactionService.cancelCharge finalize`);
        })
      );
    } else {
      return of(null);
    }
  }

  protected cancelTransactionChargeAdjustment$(editableTransaction: EditableTransaction, transactionChargeAdjustment: EditableTransactionChargeAdjustment, correlationUid: string): Observable<EditableTransactionChargeAdjustment> {

    if (transactionChargeAdjustment.uid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeAdjustment.uid} | cancelTransactionChargeAdjustment$ -> transactionService.cancelChargeAdjustment observe`);

      return this.transactionProvider.cancelChargeAdjustment$(
        editableTransaction.uid,
        transactionChargeAdjustment.transactionChargeUid,
        transactionChargeAdjustment.uid
      ).pipe(
        catchError(error => this.errorHandlingProvider.handleError<TransactionChargeAdjustment>(error)),
        map(patch => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeAdjustment.uid} | cancelTransactionChargeAdjustment$ <= transactionService.cancelChargeAdjustment`);

          return EditableTransactionDataHandler.mergeTransactionChargeAdjustment(transactionChargeAdjustment, patch);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionChargeAdjustment.uid} | cancelTransactionChargeAdjustment$ -> transactionService.cancelChargeAdjustment finalize`);
        })
      );
    } else {
      return of(null);
    }
  }

  protected cancelTransactionAdjustment$(editableTransaction: EditableTransaction, transactionAdjustmentItem: EditableTransactionAdjustment, correlationUid: string): Observable<EditableTransactionAdjustment> {

    if (transactionAdjustmentItem.uid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionAdjustmentItem.uid} | cancelTransactionAdjustment$ -> transactionService.cancelAdjustment observe`);

      return this.transactionProvider.cancelAdjustment$(editableTransaction.uid, transactionAdjustmentItem.uid).pipe(
        catchError(error => this.errorHandlingProvider.handleError<TransactionAdjustment>(error)),
        map(patch => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionAdjustmentItem.uid} | cancelTransactionAdjustment$ <= transactionService.cancelAdjustment`);

          return EditableTransactionDataHandler.mergeTransactionAdjustment(transactionAdjustmentItem, patch);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${transactionAdjustmentItem.uid} | cancelTransactionAdjustment$ -> transactionService.cancelAdjustment finalize`);
        })
      );
    } else {
      return of(null);
    }
  }

  protected cancelTransactionPayment$(editableTransaction: EditableTransaction, payment: EditableTransactionPayment, correlationUid: string) {

    if (payment.uid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${payment.uid} | cancelPaymentLineItem$ -> transactionService.cancelPayment observe`);

      return this.transactionProvider.cancelPayment$(editableTransaction.uid, payment.uid).pipe(
        catchError(error => this.errorHandlingProvider.handleError<TransactionPayment>(error)),
        map(x => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${payment.uid} | cancelPaymentLineItem$ <= transactionService.cancelPayment`);

          return EditableTransactionDataHandler.mergePayment(payment, x);
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | item: ${payment.uid} | cancelPaymentLineItem$ -> transactionService.cancelPayment finalize`);
        })
      );
    } else {
      return of(null);
    }
  }
}
