import { Component } from "@angular/core";
import { combineLatest, of, Subject } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { catchError, first, concatMap } from "rxjs/operators";
import { ActionBarConfiguration, ActionConfiguration } from "core";
import { ConfirmModalProvider } from "core";
import { SelectListModalProvider, SelectListModalData } from "core";
import { SpinnerModalComponent } from "core";
import { SpinnerModalProvider } from "core";
import { NavigationProvider } from "core";
import { EmployeePermissionDataSource, EmployeePermissionModel } from "pos-core";
import { Permission } from "pos-core";
import { EmployeeService } from "pos-core";
import { PermissionService } from "pos-core";

@Component({
  selector: 'app-back-office-employee-permissions',
  templateUrl: './back-office-employee-permissions.component.html',
  styleUrls: ['./back-office-employee-permissions.component.scss']
})
export class BackOfficeEmployeePermissionsComponent {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: EmployeePermissionDataSource;

  public title: string;
  public columns: Array<string>;

  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private selectListModalProvider: SelectListModalProvider,
    private employeeService: EmployeeService,
    private permissionService: PermissionService
  ) {
    this.navigationProvider.setWaypoint('Employee Permissions');
    this.title = 'Employee Permissions';

    this.dataSource = new EmployeePermissionDataSource(this.employeeService, this.permissionService, false);

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('Add', this.add.bind(this)),
        new ActionConfiguration('Delete', () => this.delete(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDelete))
      ]
    );

    // Build initial columns
    this.columns = [ /*'select',*/ 'name'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    this.dataSource.loadData(this.activatedRoute.snapshot.params['employeeUid'], EmployeeService.EmployeeFullView);
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }
  
  public add() {

    combineLatest([
      this.permissionService.list(),
      this.dataSource.connect(null)
    ]).pipe(
      first()
    ).subscribe(([positions, employeePositions]) => {
      let availablePositions = positions.filter(x => !employeePositions.some(y => y.permissionUid.toUpperCase() == x.uid.toUpperCase()));

      if (availablePositions.length > 0) {
        this.selectListModalProvider.open(<SelectListModalData<Permission>>{
          title: 'Select Permission',
          options: availablePositions,
          displayFunc: (item: Permission) => item.name
        }).afterClosed().subscribe(permission => {
          if (permission) {
            let spinnerDialogRef = this.spinnerModalProvider.open();

            spinnerDialogRef.afterOpened().subscribe(() => {
              this.dataSource.add(this.activatedRoute.snapshot.params['employeeUid'], (<Permission>permission).uid).subscribe(() => {
                spinnerDialogRef.close();
              });
            });
          }
        });
      }
    });
  }

  public delete(employeePermission: EmployeePermissionModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Delete Permission', 'Are you certain you want to delete this permission?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let spinnerDialogRef = this.spinnerModalProvider.open();

        spinnerDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.delete(this.activatedRoute.snapshot.params['employeeUid'], employeePermission)),
          catchError(() => of(null)),
        ).subscribe(() => spinnerDialogRef.close());
      }
    });
  }
}
