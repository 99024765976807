export class CardTransactionStatusKeys {

  public static readonly Created = 'B5F2C4D7-44C5-44DC-8F51-A0B843119299';
  public static readonly Authorized = '576EB690-17D7-4938-98FD-6D32D3AAFE59';
  public static readonly AuthorizedFailed = 'BFA9CC02-1626-47EB-AD7F-C19FFA688C03';
  public static readonly Captured = '8C75AE0B-BEA1-4826-8F83-9B0E1DF0DF52';
  public static readonly Voided = '5478F994-A678-458D-99AE-ABABB3C34061';
}

export class ChargeTypeKeys {

  public static readonly DeliveryCharge = '06070850-9c01-46f0-8e10-7b52271e2dc8';
  public static readonly ContainerCharge = '2f99af8f-36fd-475e-b2b1-63182c2c00c9';
  public static readonly MiscellaneousCharge = 'b83ab045-73c1-4cd0-9e30-735bd03e66f4';
}

export class LogisticTypeKeys {

  static readonly DineIn = "776f2000-e4d3-4163-8124-6a2c6514d48c";
  static readonly CarryOut = "627ab494-4230-4a90-9e34-62f99e74d387";
  static readonly CurbsidePickup = "ab424812-3a8f-4679-b38e-76891acc8fd9";
  static readonly Delivery = "3f46e3f1-ea40-4012-8260-cd22b5cc054c";
}

export class MenuPlacement {

  public static readonly Menu = 'B8F62863-6F65-4FD7-9F29-BD8B37610E88';
  public static readonly OffMenu = 'EE202D9D-9F44-492F-8A3F-EDC23A2414AD';
  public static readonly NoSale = 'C35072A2-2B8B-4F08-9762-316544646D5F';
}

// export class TransactionStatusKeys {

//   public static readonly Open = 'BA8DFC14-C279-4283-91A5-69976074F0FA';
//   public static readonly Closed = 'B4445AF7-0D14-4D92-80AF-32F197A4589A';
//   public static readonly Check = '36331146-A3BC-4123-8856-482EB6065FBC';
//   public static readonly Tab = '4F6CF403-5C41-4201-8E00-9AFFBA0DE55C';
//   public static readonly Cancelled = '6FF9A127-5DCD-48B3-BFDE-BFB8FEEC174F';
//   public static readonly Committed = '3BB33306-B13A-4215-82EA-1FC4069A446F';
// }

export enum TransactionStatusEnum {

  Open = 'Open',
  Closed = 'Closed',
  Check = 'Check',
  Tab = 'Tab',
  Cancelled = 'Cancelled',
  Committed = 'Committed'
}