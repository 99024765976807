<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="uid">
    <th mat-header-cell *matHeaderCellDef color="accent"> UID</th>
    <td mat-cell *matCellDef="let row">{{ row.terminal.uid }}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef color="accent">Name</th>
    <td mat-cell *matCellDef="let row">{{ row.terminal.name }}</td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef color="accent">Description</th>
    <td mat-cell *matCellDef="let row">{{ row.terminal.description }}</td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let row">{{ row.terminal.type }}</td>
  </ng-container>

  <ng-container matColumnDef="deviceId">
    <th mat-header-cell *matHeaderCellDef color="accent">Device Id</th>
    <td mat-cell *matCellDef="let row">{{ row.terminal.deviceId }}</td>
  </ng-container>

  <ng-container matColumnDef="defaultDrawerSeedAmount">
    <th mat-header-cell *matHeaderCellDef>Default Drawer Seed $</th>
    <td mat-cell *matCellDef="let row" style="text-align: left;">{{ row.terminal.defaultDrawerSeedAmount | currency:'USD':'symbol':'1.2-2' }}</td>
  </ng-container>

  <ng-container matColumnDef="createDateTimeUtc">
    <th mat-header-cell *matHeaderCellDef>Create Date</th>
    <td mat-cell *matCellDef="let row">{{ row.terminal.createDateTimeUtc | date: 'E M-dd-yyyy h:mm a' }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let row">{{ row.terminal.status | splitTitleCase }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let drawer">
      <!-- <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="drawer.canEdit" (click)="edit(drawer)">Edit</button>
      <button mat-stroked-button color="warn" style="margin-right: 5px;" click-stop-propagation *ngIf="drawer.canDelete" (click)="delete(drawer)">
        <mat-icon>delete_outline</mat-icon>
      </button> -->
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>
</table>