import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentMethod } from "core";
import { PaymentModalComponent } from "./payment-modal.component";

@Injectable()
export class PaymentModalProvider {

  constructor(
    private dialog: MatDialog
  ) {
  }

  public open(data: PaymentModalData): MatDialogRef<PaymentModalComponent, PaymentModalResult> {

    return this.dialog.open(PaymentModalComponent, {
      width: '50vw',
      // height: '65vh',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface PaymentModalData {

  title: string;
  amount: number;
  paymentMethodUid: string;
  paymentMethods: PaymentMethod[];
}

export interface PaymentModalResult {

  amount: number;
  paymentMethod: PaymentMethod;

}
