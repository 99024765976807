<div style="display: flex; flex-direction: column; height: 100%;">

  <app-page-header>

    <ng-container left>
      <button mat-flat-button style="width: 4.1666vw;" (click)="navigateHome()">
        <mat-icon style="font-weight: bold;">home</mat-icon>
      </button>
      <button mat-flat-button style="width: 4.1666vw; margin-left: 4px;" (click)="navigateBack()">
        <mat-icon style="font-weight: bold;">arrow_back</mat-icon>
      </button>
    </ng-container>

    <ng-container middle>{{title}}</ng-container>

    <app-paginator right [dataSource]="dataSource" [paginator]="paginator"></app-paginator>

  </app-page-header>

  <app-action-bar [actionBarConfiguration]="actionBarConfiguration"></app-action-bar>

  <div style="flex: 1; overflow-y: auto;">
    <app-shifts-table [dataSource]="dataSource" [columns]="columns"></app-shifts-table>
  </div>

</div>

<!-- <mat-toolbar color="primary">
  <span>{{ title }}</span>
  <span style="flex: 1 1 auto;"></span>
  <span *ngIf="employeeShifts | async">{{ startItemIndex }} to {{ endItemIndex }} of {{ totalItemCount }}</span>
</mat-toolbar>

<mat-toolbar class="action-toolbar" color="primary">

  <div class="full">

    <button mat-stroked-button color="accent" class="filter" (click)="new()">
      <mat-icon>create</mat-icon>
      <span>New</span>
    </button>

    <button mat-stroked-button color="accent" class="filter" [disabled]="!canBulkEdit" (click)="edit(selection.selected[0])">
      <mat-icon>account_box</mat-icon>
      <span>Edit</span>
    </button>

    <button mat-stroked-button color="accent" class="filter" [disabled]="!canBulkSubmit" (click)="submit(selection.selected)">
      <mat-icon>forward</mat-icon>
      <span>Submit<span *ngIf="submittableCount > 0">&nbsp;({{ submittableCount }})</span></span>
    </button>

    <button mat-stroked-button color="warn" class="filter" [disabled]="!canBulkDelete" (click)="delete(selection.selected)">
      <mat-icon>delete_outline</mat-icon>
      <span>Delete<span *ngIf="deletableCount > 0">&nbsp;({{ deletableCount }})</span></span>
    </button>

  </div>

  <button mat-icon-button [matMenuTriggerFor]="smallMenu" class="compact">
    <mat-icon>more_vert</mat-icon>
  </button>

  <span style="flex: 1 1 auto;"></span>

  <button mat-flat-button color="primary" class="filter" [disabled]="this.pageIndex == 0" (click)="pageBackward()" style="height: 100%;">
    <mat-icon style="font-size: 1.5em; font-weight: bold;">chevron_left</mat-icon>
  </button>
  <button mat-flat-button color="primary" class="filter" [disabled]="this.pageIndex == this.maxPageIndex" (click)="pageForward()" style="height: 100%;">
    <mat-icon style="font-size: 1.5em; font-weight: bold;">chevron_right</mat-icon>
  </button>
  <button mat-flat-button color="primary" class="filter" [matMenuTriggerFor]="positionFilterMenu" style="height: 100%;">
    <div class="mat-caption">Position</div>
    <div style="font-weight: bold;">{{ (positionFilter | async)?.name }}</div>
  </button>
  <button mat-flat-button color="primary" class="filter" [matMenuTriggerFor]="daysOfWeekFilterMenu" style="height: 100%;">
    <div class="mat-caption">Day(s)</div>
    <div style="font-weight: bold;">{{ (daysOfWeekFilter | async)?.name }}</div>
  </button>
  <button mat-flat-button color="primary" class="filter" [matMenuTriggerFor]="statusFilterMenu" style="height: 100%;">
    <div class="mat-caption">Status</div>
    <div style="font-weight: bold;">{{ (statusFilter | async)?.name }}</div>
  </button>
  <button mat-flat-button color="primary" class="filter" [matMenuTriggerFor]="pageSizeFilterMenu" style="height: 100%;">
    <div class="mat-caption">Page Size</div>
    <div style="font-weight: bold;">{{ (pageSizeFilter | async)?.name }}</div>
  </button>

</mat-toolbar>

<div style="position: absolute; top: 138px; left: 5px; right: 5px; bottom: 5px; overflow-y: auto;">

  <table class="themed" style="width: 100%;" mat-table [dataSource]="employeeShifts">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox *ngIf="selection.isMultipleSelection()" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef color="accent">Position</th>
      <td mat-cell *matCellDef="let employeeShift">{{ employeeShift.positionName }}</td>
    </ng-container>

    <ng-container matColumnDef="clockedIn">
      <th mat-header-cell *matHeaderCellDef>Clocked In</th>
      <td mat-cell *matCellDef="let employeeShift">{{ employeeShift.clockInDateTime | date: '(E) M-dd-yyyy h:mm a' }}</td>
    </ng-container>

    <ng-container matColumnDef="clockedOut">
      <th mat-header-cell *matHeaderCellDef>Clocked Out</th>
      <td mat-cell *matCellDef="let employeeShift">{{ employeeShift.clockOutDateTime | date: '(E) M-dd-yyyy h:mm a' }}</td>
    </ng-container>

    <ng-container matColumnDef="hours">
      <th mat-header-cell *matHeaderCellDef>Hours</th>
      <td mat-cell *matCellDef="let employeeShift" style="text-align: left;" [ngStyle]="{ 'color': employeeShift.duration?.toNumber() > 8 ? 'red' : 'inherit' }">{{ employeeShift.duration | timeSpan : 'short' }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let employeeShift">
        <span [ngStyle]="{ 'color': employeeShift.employeeShiftStatusUid.toLocaleUpperCase() === 'BBBABA53-7424-46D0-BB91-991A96AA11A7' ? 'red' : 'inherit' }">{{ employeeShift.employeeShiftStatusUid | employeeShiftStatus }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let employeeShift">
        <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="employeeShift.canEdit" (click)="viewConversations(employeeShift)">
          <mat-icon matBadge="{{ employeeShift.conversationCount }}" matBadgeColor="warn" [matBadgeHidden]="employeeShift.conversationCount == 0">feedback</mat-icon>
        </button>
        <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="employeeShift.canEdit" (click)="edit(employeeShift)">Edit</button>
        <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="employeeShift.canSubmit" (click)="submit(employeeShift)">Submit</button>
        <button mat-stroked-button color="warn" style="margin-right: 5px;" click-stop-propagation *ngIf="employeeShift.canDelete" (click)="delete(employeeShift)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>

<mat-menu #smallMenu="matMenu" xPosition="before">
  <button mat-menu-item color="accent" (click)="new()" class="">
    <mat-icon>create</mat-icon>
    <span>New</span>
  </button>

  <button mat-menu-item color="accent" *ngIf="canBulkEdit" (click)="edit(selection.selected[0])">
    <mat-icon>account_box</mat-icon>
    <span>Edit</span>
  </button>

  <button mat-menu-item color="accent" *ngIf="canBulkSubmit" (click)="submit(selection.selected)">
    <mat-icon>forward</mat-icon>
    <span>Submit<span *ngIf="submittableCount > 0">&nbsp;({{ submittableCount }})</span></span>
  </button>

  <button mat-menu-item color="warn" *ngIf="canBulkDelete" (click)="delete(selection.selected)">
    <mat-icon>delete_outline</mat-icon>
    <span>Delete<span *ngIf="deletableCount > 0">&nbsp;({{ deletableCount }})</span></span>
  </button>

</mat-menu>

<mat-menu #positionFilterMenu="matMenu" xPosition="before">
  <div>
    <button *ngFor="let positionFilter of positionFilters" mat-menu-item (click)="selectPositionFilter(positionFilter)">{{ positionFilter.name }}</button>
  </div>
</mat-menu>

<mat-menu #daysOfWeekFilterMenu="matMenu" xPosition="before">
  <div>
    <button *ngFor="let daysOfWeekFilter of daysOfWeekFilters" mat-menu-item (click)="selectDayOfWeekFilter(daysOfWeekFilter)">{{ daysOfWeekFilter.name }}</button>
  </div>
</mat-menu>

<mat-menu #statusFilterMenu="matMenu" xPosition="before">
  <div>
    <button *ngFor="let statusFilter of statusFilters" mat-menu-item (click)="selectStatusFilter(statusFilter)">{{ statusFilter.name }}</button>
  </div>
</mat-menu>

<mat-menu #pageSizeFilterMenu="matMenu" xPosition="before">
  <div>
    <button *ngFor="let pageSizeFilter of pageSizeFilters" mat-menu-item (click)="selectPageSizeFilter(pageSizeFilter)">{{ pageSizeFilter.name }}</button>
  </div>
</mat-menu> -->
