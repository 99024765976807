import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginationInput } from 'core';
import { NavigationProvider } from 'core';
import { Order } from '../../../models/order';
import { OrderService } from '../../../services/order.service';
import { ProductService } from 'downtown-product';
import { OrderViewOptions } from '../../../view-factory';

@Component({
  selector: 'app-store-checkout-ordered',
  templateUrl: './store-checkout-ordered.component.html',
  styleUrls: ['./store-checkout-ordered.component.scss']
})
export class StoreCheckoutOrderedComponent implements OnInit {

  public pagination = <PaginationInput>{ pageSize: 25, pageIndex: 0, sortField: 'name', sortStrategy: 'asc' };

  public order: Order;

  constructor(
    private navigationProvider: NavigationProvider,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private orderService: OrderService
  ) {
  }

  ngOnInit() {
    this.navigationProvider.updateTitle("Store");

    this.activatedRoute.params.subscribe(params => {
      let venueUid = params['venueUid'];
      let orderUid = params['orderUid'];

      this.orderService.getByUid(orderUid, <OrderViewOptions>{ includeShippingAddress: true, includeBillingAddress: true, incudeOrderItems: true }).subscribe(order => {
        if (order) {
          console.log(order);
          this.order = order;
        }
      });
    });
  }

  checkOut() {

  }
}
