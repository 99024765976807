import { Component } from "@angular/core";
import { of, Subject } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { catchError, concatMap } from "rxjs/operators";
import { ActionBarConfiguration, ActionConfiguration } from "core";
import { ConfirmModalProvider } from "core";
import { SelectListModalProvider, SelectListModalData } from "core";
import { SpinnerModalComponent } from "core";
import { SpinnerModalProvider } from "core";
import { NavigationProvider } from "core";
import { EmployeePositionDataSource, EmployeePositionModel } from "pos-core";
import { WorkUnitKeys } from "pos-core";
import { AmountFrequencyModalProvider } from "pos-core";
import { EmployeeService } from "pos-core";
import { PositionService } from "pos-core";
import { Position } from "pos-core";

@Component({
  selector: 'app-back-office-employee-positions',
  templateUrl: './back-office-employee-positions.component.html',
  styleUrls: ['./back-office-employee-positions.component.scss']
})
export class BackOfficeEmployeePositionsComponent {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: EmployeePositionDataSource;

  public title: string;
  public columns: Array<string>;

  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private selectListModalProvider: SelectListModalProvider,
    private amountFrequencyModalProvider: AmountFrequencyModalProvider,
    private employeeService: EmployeeService,
    private positionService: PositionService
  ) {
    this.navigationProvider.setWaypoint('Employee Positions');
    this.title = 'Employee Positions';

    this.dataSource = new EmployeePositionDataSource(this.employeeService, this.positionService, false);

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('New', this.new.bind(this)),
        new ActionConfiguration('Edit', () => this.edit(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDelete)),
        new ActionConfiguration('Delete', () => this.delete(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDelete))
      ]
    );

    // Build initial columns
    this.columns = [ /*'select',*/ 'name', 'payRate', 'frequency'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    this.dataSource.loadData(this.activatedRoute.snapshot.params['employeeUid'], EmployeeService.EmployeeFullView);
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }
  
  public new() {

    this.positionService.search(null, null).subscribe(positions => {
      this.selectListModalProvider.open(<SelectListModalData<Position>>{
        title: 'Select Position',
        options: positions.edges.map(x => x.node),
        displayFunc: item => item.name
      }).afterClosed().subscribe(position => {
        if (position) {
          this.amountFrequencyModalProvider.open({
            title: 'Enter Starting Wage',
            amount: 0,
            workUnitUid: WorkUnitKeys.Hourly
          }).afterClosed().subscribe(amountFrequencyResult => {
            if (amountFrequencyResult && amountFrequencyResult.amount) {
              let spinnerDialogRef = this.spinnerModalProvider.open();

              spinnerDialogRef.afterOpened().subscribe(() => {
                this.dataSource.add(this.activatedRoute.snapshot.params['employeeUid'], (<Position>position).uid, amountFrequencyResult.amount, amountFrequencyResult.workUnitUid).subscribe(() => {
                  spinnerDialogRef.close();
                });
              });
            }
          });
        }
      });
    });
  }

  public edit(employeePosition: EmployeePositionModel) {

    this.amountFrequencyModalProvider.open({
      title: 'Enter Wage',
      amount: employeePosition.payRate,
      workUnitUid: employeePosition.workUnitUid
    }).afterClosed().subscribe(amountFrequencyResult => {
      if (amountFrequencyResult) {
        let spinnerDialogRef = this.spinnerModalProvider.open();

        spinnerDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.edit(this.activatedRoute.snapshot.params['employeeUid'], employeePosition.positionUid, amountFrequencyResult.amount, amountFrequencyResult.workUnitUid)),
          catchError(() => of(null))
        ).subscribe(() => spinnerDialogRef.close());
      }
    });
  }

  public delete(employeePosition: EmployeePositionModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Delete Position', 'Are you certain you want to delete this position?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let spinnerDialogRef = this.spinnerModalProvider.open();

        spinnerDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.delete(this.activatedRoute.snapshot.params['employeeUid'], employeePosition)),
          catchError(() => of(null)),
        ).subscribe(() => spinnerDialogRef.close());
      }
    });
  }
}
