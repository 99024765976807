import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent {

  @HostBinding('class.expanded') expanded: boolean;
  @HostBinding('class.selected') selected: boolean;

  @Input() value: any;

  ngOnInit() {

    this.expanded = false;
    this.selected = false;
  }
}
