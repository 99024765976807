import { animate, state, style, transition, trigger } from '@angular/animations';

export const clickRippleAnimation = trigger('clickAnimation', [
  state('clicked', style({
    opacity: .75
  })),
  transition(':enter', [style({ opacity: 0 }), animate('250ms', style({ opacity: 1 }))]),
  transition('default => clicked', [animate(100)]),
  transition('clicked => default', [animate(250)])
]);
