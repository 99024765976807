import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { UUID } from "angular2-uuid";
import { LogisticType, LogisticSchedule, LogisticPaymentMethod } from '../../keys';

export class CheckoutContext {

    public logisticsForm: UntypedFormGroup;
    public identityForm: UntypedFormGroup;
    public shippingForm: UntypedFormGroup;
    public billingForm: UntypedFormGroup;

  constructor() {

    let now = new Date();

    var logisticDateTime = new Date(new Date(now).setMinutes(now.getMinutes() + 30));
    logisticDateTime = this.round(logisticDateTime, 15 * 60 * 1000);

    https://smartystreets.com/
    this.logisticsForm = new UntypedFormGroup({
      logisticTypeUid: new UntypedFormControl(LogisticType.Delivery, { validators: [Validators.required] }),
      logisticScheduleUid: new UntypedFormControl(LogisticSchedule.AsSoonAsPossible, { validators: [Validators.required] }),
      logisticDateTimeUtc: new UntypedFormControl(logisticDateTime, { validators: [], updateOn: 'blur' }),
      logisticPaymentMethodUid: new UntypedFormControl(LogisticPaymentMethod.CreditCard, { validators: [Validators.required] }),
    });
    this.identityForm = new UntypedFormGroup({
      uid: new UntypedFormControl(UUID.UUID(), { validators: [Validators.required], updateOn: 'blur' }),
      firstName: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
      lastName: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
      company: new UntypedFormControl(null),
      email: new UntypedFormControl(null, { validators: [Validators.required, Validators.email], updateOn: 'blur' }),
      phoneNumber: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' })
    });
    this.shippingForm = new UntypedFormGroup({
      address: new UntypedFormGroup({
        uid: new UntypedFormControl(UUID.UUID(), { validators: [Validators.required], updateOn: 'blur' }),
        country: new UntypedFormControl('United States', { validators: [Validators.required], updateOn: 'blur' }),
        address1: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
        address2: new UntypedFormControl(null, { validators: [], updateOn: 'blur' }),
        city: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
        state: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
        postalCode: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' })
      })
    });

    let isSameAsShippingValidation = (control: AbstractControl) => !control.parent || control.parent.parent.get('isSameAsShipping').value ? null : Validators.required(control);

    this.billingForm = new UntypedFormGroup({
      isSameAsShipping: new UntypedFormControl(true),
      creditCard: new UntypedFormGroup({
        name: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
        number: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
        expiryMonth: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
        expiryYear: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
        csc: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
      }),
      address: new UntypedFormGroup({
        uid: new UntypedFormControl(UUID.UUID(), { validators: [Validators.required], updateOn: 'blur' }),
        country: new UntypedFormControl('United States', { validators: [], updateOn: 'blur' }),
        address1: new UntypedFormControl(null, { validators: [isSameAsShippingValidation], updateOn: 'blur' }),
        address2: new UntypedFormControl(null, { validators: [], updateOn: 'blur' }),
        city: new UntypedFormControl(null, { validators: [isSameAsShippingValidation], updateOn: 'blur' }),
        state: new UntypedFormControl(null, { validators: [isSameAsShippingValidation], updateOn: 'blur' }),
        postalCode: new UntypedFormControl(null, { validators: [isSameAsShippingValidation], updateOn: 'blur' })
      })
    });
    this.billingForm.get('isSameAsShipping').valueChanges.subscribe(value => {
      this.billingForm.get('address').get('address1').updateValueAndValidity();
      this.billingForm.get('address').get('address2').updateValueAndValidity();
      this.billingForm.get('address').get('city').updateValueAndValidity();
      this.billingForm.get('address').get('state').updateValueAndValidity();
      this.billingForm.get('address').get('postalCode').updateValueAndValidity();
    });

  }

  private round(date: Date, durationMs: number): Date {

    let dateMs = date.getTime();

    return new Date(Math.ceil((+dateMs) / (+durationMs)) * (+durationMs));
  }
}
