import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthHeaderProvider } from "core";
import { AuthIdentityProvider } from "core";
import { IHttpOptions } from "core";

@Injectable()
export class SiteAuthHeaderProvider extends AuthHeaderProvider {

  constructor(
    authIdentityProvider: AuthIdentityProvider
  ) {
    super(authIdentityProvider);
  }

  apply(options: IHttpOptions, authToken: string = null): IHttpOptions {

    authToken = authToken ?? this.authIdentityProvider.getUserIdentityToken();

    if (authToken) {
      options.headers = options.headers || {};
      options.headers['content-type' as keyof HttpHeaders] = 'application/json';
      options.headers['Authorization' as keyof HttpHeaders] = 'Bearer ' + authToken;
    };

    return options;
  }
}
