import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, BehaviorSubject, Observer, asyncScheduler } from 'rxjs';
import { map, observeOn } from 'rxjs/operators';
import { UUID } from 'angular2-uuid';

export interface OperationMessage {

  id: string;
  type: string;
  payload: any;
}

@Injectable()
export class GraphService {

  public messages: Subject<OperationMessage> = new Subject<OperationMessage>();

  constructor(
  ) {

  }

  createEndpoint(endpointUrl: string, endpointId: string = null, reconnectOnClose: boolean = true): GraphEndpoint {

    return new GraphEndpoint(endpointUrl, endpointId, reconnectOnClose);
  }
}

export const enum EndpointState {
  Closed,
  Closing,
  Connecting,
  Ready
}

export class GraphEndpoint {

  public endpointConnection: EndpointConnection;
  public operationMessage$: Subject<OperationMessage>;
  public state = new BehaviorSubject<EndpointState>(EndpointState.Closed);
  private subscription: Subscription;
  private readyStateSubscription: Subscription;
  private interval = 1;

  constructor(
    private endpointUrl: string,
    private endpointId: string,
    private reconnectOnClose: boolean
  ) {
    if (!endpointId) {
      this.endpointId = UUID.UUID();
    }

    setInterval(() => {
      if (this.state.value == EndpointState.Closed && this.reconnectOnClose) {
        this.interval = 1000;
        this.tryConnect();
      }
    }, this.interval);
  }

  private tryConnect() {

    this.reset();

    console.log(`[socket: ${this.endpointId}] Attempting to connect to ${this.endpointUrl}`);

    this.endpointConnection = new EndpointConnection(this.endpointUrl);

    // // Handle initial state
    // this.handleReadyState(this.endpointConnection.readyState.value);

    // Listen for changes
    this.bindReadyState(this.endpointConnection);
  }

  private bindReadyState(endpointConnection: EndpointConnection) {

    if (endpointConnection) {
      this.readyStateSubscription = this.endpointConnection.connectionState$.subscribe(readyState => {
        this.handleConnectionState(readyState);
      })
    }
  }

  private handleConnectionState(readyState: number) {

    switch (readyState) {
      case WebSocket.OPEN:
        console.log(`[socket: ${this.endpointId}] SocketEndpoint.readyState=OPEN`);

        this.interval = 1000;

        this.operationMessage$ = <Subject<OperationMessage>>this.endpointConnection.messageEvent$.pipe(
          map((response: MessageEvent): OperationMessage => {
            let data = JSON.parse(response.data);

            return {
              id: data.id,
              type: data.type,
              payload: data.payload
            }
          })
        );

        this.subscription = this.operationMessage$.subscribe(operationMessage => {

          switch (operationMessage.type) {
            case 'connection_ack':
              console.log(`[socket: ${this.endpointId}] Initialize handshake acknowledged`);
              this.state.next(EndpointState.Ready);
              break;
            case 'data':
              console.log(`[socket: ${this.endpointId}] Graph data payload received`);
              this.operationMessage$.next(operationMessage);
              break;
            default:
              console.log(operationMessage.payload);
              break;
          }
        });

        this.state.next(EndpointState.Connecting);

        console.log(`[socket: ${this.endpointId}] Attempting initialize handshake`);

        this.operationMessage$.next({ type: 'connection_init', id: 'client', payload: '' });
        break;
      case WebSocket.CONNECTING:
        console.log(`[socket: ${this.endpointId}] SocketEndpoint.readyState=CONNECTING`);
        this.state.next(EndpointState.Connecting);
        break;
      case WebSocket.CLOSING:
        console.log(`[socket: ${this.endpointId}] SocketEndpoint.readyState=CLOSING`);
        break;
      case WebSocket.CLOSED:
        console.log(`[socket: ${this.endpointId}] SocketEndpoint.readyState=CLOSED`);
        this.state.next(EndpointState.Closed);
        break;
    }
  }

  // TODO: Fix adding mulitple subscriptions to a single endpoint - last one wins, previous subscriptions get lost
  addSubscription<T>(request: string, member: string, identityToken: string = null): Observable<T> {

    if (this.operationMessage$ == null) {
      console.log(`[socket: ${this.endpointId}]: no operation message subject`);
    }

    this.operationMessage$.next({ type: 'start', id: this.endpointId, payload: { 'query': request } });

    return this.operationMessage$.pipe(
      observeOn(asyncScheduler),
      map((x: OperationMessage) => {
        if (x.payload.errors) {
          x.payload.errors.forEach((error: any) => {
            console.log(`[socket: ${this.endpointId}]: ${error.message}`);
          });

          return null;
        }

        console.log(`[socket: ${this.endpointId}] Payload received`);

        return <T>x.payload.data[member];
      })
    );
  }

  close() {

    this.reconnectOnClose = false;
    this.reset();
  }

  private reset() {

    if (this.endpointConnection) {
      this.state.next(EndpointState.Closing);

      try {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.endpointConnection.close();
      } catch (e) {
        console.log(e);
      };

      this.endpointConnection = null;
      this.subscription = null;
    }
  }
}

export class EndpointConnection {

  public messageEvent$: Subject<MessageEvent>;
  public connectionState$ = new BehaviorSubject<number>(0);
  private webSocket: WebSocket;

  constructor(
    url: string
  ) {
    this.webSocket = new WebSocket(url, 'graphql-ws');
    this.connectionState$.next(this.getReadyState(this.webSocket));

    this.webSocket.onopen = event => {
      this.connectionState$.next(this.getReadyState(this.webSocket));
    }
    this.webSocket.onerror = event => {
      this.connectionState$.next(this.getReadyState(this.webSocket));
    };
    this.webSocket.onclose = event => {
      this.connectionState$.next(WebSocket.CLOSED);
    };

    let observable = new Observable<MessageEvent>((observer: Observer<MessageEvent>) => {
      this.webSocket.onmessage = observer.next.bind(observer);

      return this.webSocket.close.bind(this.webSocket);
    });

    let observer = {
      next: (data: Object) => {
        if (this.getReadyState(this.webSocket) === WebSocket.OPEN) {
          this.webSocket.send(JSON.stringify(data));
        } else {
          console.log('ws.readyState !== WebSocket.OPEN');
        }
      }
    };

    let n = new Subject();
    n.next = (data: Object) => {
      if (this.getReadyState(this.webSocket) === WebSocket.OPEN) {
        this.webSocket.send(JSON.stringify(data));
      } else {
        console.log('ws.readyState !== WebSocket.OPEN');
      }
    };

    this.messageEvent$ = Subject.create(observer, observable);
  }

  private getReadyState(webSocket: WebSocket): number {

    if (!webSocket) {
      return 0;
    }

    return webSocket.readyState;
  }

  close() {

    if (this.webSocket) {
      this.webSocket.close();
      this.webSocket = null;
    }
  }
}
