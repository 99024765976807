import { Component, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ExpansionContainerComponent, getDescendentRouteSnapshot } from 'core';
import { ExpansionPanelComponent } from 'core';
import { NavigationProvider } from 'core';
import { ProductAndPortionModalProvider } from '../../../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { ConfigurationComponentBase } from '../../configuration-component-base';
import { ProductContext } from '../../product-context';
import { TenantProvider } from 'core';
import { ProductProvider } from '../../../../providers';

@Component({
  selector: 'app-back-office-product-configuration-portion',
  templateUrl: './back-office-product-configuration-portion.component.html',
  styleUrls: ['./back-office-product-configuration-portion.component.scss']
})
export class BackOfficeProductConfigurationPortionComponent extends ConfigurationComponentBase {

  @ViewChild('expansionContainer', { static: true }) expansionContainer: ExpansionContainerComponent;
  @ViewChild('inclusionGroupsContainer', { static: true }) inclusionGroupsContainer: ExpansionContainerComponent;
  @ViewChild('addOnsContainer', { static: true }) addOnsContainer: ExpansionContainerComponent;

  @ViewChild('generalPanel', { static: true }) generalPanel: ExpansionPanelComponent;
  @ViewChild('inclusionGroupsPanel', { static: true }) inclusionGroupsPanel: ExpansionPanelComponent;
  @ViewChild('addOnsPanel', { static: true }) addOnsPanel: ExpansionPanelComponent;

  private _context: ProductContext;
  public form: UntypedFormGroup;
  public inclusionsFormArray: UntypedFormArray;
  public inclusionGroupsFormArray: UntypedFormArray;
  public extrasFormArray: UntypedFormArray;
  public addOnsFormArray: UntypedFormArray;

  private destroyed$ = new Subject();

  constructor(
    private navigationProvider: NavigationProvider,
    activatedRoute: ActivatedRoute,
    tenantProvider: TenantProvider,
    productProvider: ProductProvider,
    productAndPortionModalProvider: ProductAndPortionModalProvider,
  ) {
    super(activatedRoute, tenantProvider, productProvider, productAndPortionModalProvider);
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public set context(context: ProductContext) {

    this._context = context;

    this.activatedRoute.url.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(() => {
      this.assignForm();
      this.setNavTarget();
    });
  }

  private assignForm() {

    var route = this.activatedRoute.snapshot;
    var portionUid = route.params['portionUid'];

    let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
    let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
    if (portionsGroupFormArray) {
      this.form = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid.toLowerCase());
      this.inclusionsFormArray = <UntypedFormArray>this.form.get('inclusions');
      this.inclusionGroupsFormArray = <UntypedFormArray>this.form.get('inclusionGroups');
      this.extrasFormArray = <UntypedFormArray>this.form.get('extras');
      this.addOnsFormArray = <UntypedFormArray>this.form.get('addOns');
    }
  }

  public get context(): ProductContext {

    return this._context;
  }

  setNavTarget() {

    setTimeout(() => {
      var route = getDescendentRouteSnapshot(this.activatedRoute);
      var inclusionUid = route.params['inclusionUid'];
      var inclusionGroupUid = route.params['inclusionGroupUid'];
      var extraUid = route.params['extraUid'];
      var addOnUid = route.params['addOnUid'];

      this.expansionContainer.setSelected(null);
      this.inclusionGroupsContainer.setSelected(null);

      if (inclusionGroupUid || route.url.some(x => x.path == 'inclusiongroups')) {
        this.expansionContainer.setExpanded(this.inclusionGroupsPanel);

        if (route.url.some(x => x.path == 'inclusiongroups')) {
          this.expansionContainer.setSelected(this.inclusionGroupsPanel);
        }

        if (inclusionGroupUid) {
          var inclusionGroupPanel = this.inclusionGroupsContainer.panels.find(x => x.value == inclusionGroupUid)
          if (inclusionGroupPanel) {
            this.inclusionGroupsContainer.setSelected(inclusionGroupPanel);
          }
        }
      } else if (addOnUid || route.url.some(x => x.path == 'addons')) {
        this.expansionContainer.setExpanded(this.addOnsPanel);

        if (route.url.some(x => x.path == 'addons')) {
          this.expansionContainer.setSelected(this.addOnsPanel);
        }

        if (addOnUid) {
          var addOnGroupPanel = this.addOnsContainer.panels.find(x => x.value == addOnUid)
          if (addOnGroupPanel) {
            this.addOnsContainer.setSelected(addOnGroupPanel);
          }
        }
      } else {
        this.expansionContainer.setExpanded(this.generalPanel);
        this.expansionContainer.setSelected(this.generalPanel);
      }
    });
  }

  panelClick(target: string) {

    this.navigationProvider.navigate(this.getBasePortionNavigationSegments().concat(target));

    // var route = this.getDescendentRouteSnapshot(this.activatedRoute);
    // var portionUid = route.params['portionUid'];

    // if (target == 'inclusions') {
    //   this.navigationProvider.navigate(['/backoffice/product', this.activatedRoute.snapshot.params['productUid'], 'portion', portionUid, 'inclusions']);
    // } else if (target == 'inclusiongroups') {
    //   this.navigationProvider.navigate(['/backoffice/product', this.activatedRoute.snapshot.params['productUid'], 'portion', portionUid, 'inclusiongroups']);
    // } else if (target == 'extras') {
    //   this.navigationProvider.navigate(['/backoffice/product', this.activatedRoute.snapshot.params['productUid'], 'portion', portionUid, 'extras']);
    // } else if (target == 'addons') {
    //   this.navigationProvider.navigate(['/backoffice/product', this.activatedRoute.snapshot.params['productUid'], 'portion', portionUid, 'addons']);
    // } else {
    //   this.navigationProvider.navigate(['/backoffice/product', this.activatedRoute.snapshot.params['productUid'], 'portion', portionUid]);
    // }
  }

  inclusionGroupClick(target: string) {

    var route = getDescendentRouteSnapshot(this.activatedRoute);

    const routeSegments = this.getBasePortionNavigationSegments().concat(['inclusiongroup', target]);

    if (route.url.some(x => x.path == 'options')) {
      routeSegments.push('options');
    }

    this.navigationProvider.navigate(routeSegments);
  }

  addOnClick(target: string) {

    var route = getDescendentRouteSnapshot(this.activatedRoute);
    var portionUid = route.params['portionUid'];

    this.navigationProvider.navigate(['/backoffice/product', this.activatedRoute.snapshot.params['productUid'], 'portion', portionUid, 'addon', target]);
  }

  onActivate(event: ConfigurationComponentBase) {

    event.context = this.context;
  }

  public resolveInclusionGroupName(inclusionGroupForm: UntypedFormGroup) {

    var name = inclusionGroupForm.get('name').value;

    if (!name) {
      var configurationForm = this.context.productForm.get('configuration');
      var inclusionGroupsArray = <UntypedFormArray>configurationForm.get('inclusionGroups');
      if (inclusionGroupsArray) {
        var parentForm = inclusionGroupsArray.controls.find(x => x.get('uid').value.toLowerCase() == inclusionGroupForm.get('uid').value.toLowerCase());
        if (parentForm) {
          name = parentForm.get('name').value;
        }
      }
    }

    return name;
  }
}
