
import { Injectable } from '@angular/core';
import { Observable, concatMap, of } from 'rxjs';
import { MessageModalProvider } from '../modals';
import { getGraphApiErrorMessageHtml, isGraphError } from '../functions';

@Injectable()
export class ErrorHandlingProvider {

  constructor(
    private messageModalProvider: MessageModalProvider
  ) {
  }

  handleError<T>(error: any, defaultValue: T = null): Observable<T> {

    let title = 'Error';
    let message = error?.toString();

    if (isGraphError(error.error)) {
      message = getGraphApiErrorMessageHtml(error.error);
    }

    if (error.name) {
      title = error.name;
    }

    return this.messageModalProvider.open({ title: title, message: message }).afterClosed().pipe(
      concatMap(_ => of<T>(defaultValue))
    );
  };
}
