import { Product, ProductConfiguration, ProductConfigurationInclusionGroupOption, cloneConfiguration, cloneProduct } from "downtown-product";
import { EditableTransactionItem, EditableTransactionItemConfiguration, TransactionItem } from "../../models";
import { findInArrayByUid } from "core";

export function configureProductWithItem(product: Product, item: TransactionItem): Product {

    var targetProduct = cloneProduct(product);

    var productConfiguration = targetProduct.configuration;
    var itemConfiguration = item.configuration;

    var productPortion = productConfiguration.getPortion(itemConfiguration.getConfiguringPortion().portionUid);

    itemConfiguration.portion.inclusionGroups?.forEach(itemInclusionGroup => {
        var productInclusionGroup = findInArrayByUid(productPortion.inclusionGroups, itemInclusionGroup.inclusionGroupUid);

        if (productInclusionGroup) {
            itemInclusionGroup.options?.forEach(itemInclusionGroupOption => {
                var productInclusionGroupOption = findInArrayByUid(productInclusionGroup.options, itemInclusionGroupOption.optionUid);

                if (!productInclusionGroupOption) {
                    // Substitution
                    productInclusionGroupOption = new ProductConfigurationInclusionGroupOption();
                    productInclusionGroupOption.uid = itemInclusionGroupOption.optionUid;
                    productInclusionGroupOption.productReference = {
                        uid: itemInclusionGroupOption.productUid,
                        portionUid: itemInclusionGroupOption.productPortionUid,
                        version: itemInclusionGroupOption.productVersion
                    };
                    productInclusionGroupOption.preparations = productInclusionGroup.preparations;
                    productInclusionGroupOption.variations = productInclusionGroup.variations;

                    productInclusionGroup.options.push(productInclusionGroupOption);
                }
            });
        }
    });

    return targetProduct;
}

export function configureProductWithEditableItem(product: Product, item: EditableTransactionItem): Product {

    var targetProduct = cloneProduct(product);

    targetProduct.configuration = configureProductConfigurationWithEditableItemConfiguration(targetProduct.configuration, item.configuration.value);

    return targetProduct;
}

export function configureProductConfigurationWithEditableItemConfiguration(
    productConfiguration: ProductConfiguration, 
    itemConfiguration: EditableTransactionItemConfiguration
): ProductConfiguration {

    var targetProductConfiguration = cloneConfiguration(productConfiguration);
    var productPortion = targetProductConfiguration.getPortion(itemConfiguration.portion.portionUid);

    itemConfiguration.portion.inclusionGroups?.forEach(itemInclusionGroup => {
        var productInclusionGroup = findInArrayByUid(productPortion.inclusionGroups, itemInclusionGroup.inclusionGroupUid);

        if (productInclusionGroup) {
            itemInclusionGroup.options?.forEach(itemInclusionGroupOption => {
                var productInclusionGroupOption = findInArrayByUid(productInclusionGroup.options, itemInclusionGroupOption.optionUid);

                if (!productInclusionGroupOption) {
                    // Substitution
                    productInclusionGroupOption = new ProductConfigurationInclusionGroupOption();
                    productInclusionGroupOption.uid = itemInclusionGroupOption.optionUid;
                    productInclusionGroupOption.productReference = {
                        uid: itemInclusionGroupOption.productUid,
                        portionUid: itemInclusionGroupOption.productPortionUid,
                        version: itemInclusionGroupOption.productVersion
                    };
                    productInclusionGroupOption.preparations = productInclusionGroup.preparations;
                    productInclusionGroupOption.variations = productInclusionGroup.variations;

                    productInclusionGroup.options.push(productInclusionGroupOption);
                }
            });
        }
    });

    return targetProductConfiguration;
}