import { OrderStatusKeys, CardTransaction2StatusKeys } from '../keys';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { Address } from "./address";
import { Identity } from "./identity";
import Decimal from 'decimal.js';

export class EditableOrder {

  public selection = new BehaviorSubject<EditableOrderItem | EditableOrderItemAdjustment | EditableOrderPayment | EditableOrderAdjustment>(null);

  public uid: string;
  public ownerUid: string;
  public number: string;
  public identityUid: string;
  public cartUid: string;
  public orderQuantity: number = 0;
  public fulfilledQuantity: number = 0;
  public subTotalAmount: number = 0;
  public shippingAmount: number = 0;
  public taxAmount: number = 0;
  public totalAmount: number = 0;
  public openDateTimeUtc: Date;
  public modifiedDateTimeUtc: Date;
  public closeDateTimeUtc: Date;
  public orderStatusUid = new BehaviorSubject<string>(OrderStatusKeys.Created);

  public notes = new BehaviorSubject<string>(null);

  public initiatorIdentity: Identity;
  public shippingAddress: Address;
  public billingAddress: Address;
  public items = <EditableOrderItem[]>[];
  public adjustments = <EditableOrderAdjustment[]>[];
  public payments = <EditableOrderPayment[]>[];
  public cardTransactions = <EditableCardTransaction[]>[];
  public canEditNotes = false;
  public canEditLocation = false;

  constructor() {

    this.orderStatusUid.subscribe(() => {
      this.evaluateState();
    });

    this.evaluateState();
  }

  public addOrderItem(orderItem?: EditableOrderItem): EditableOrderItem {

    orderItem = orderItem || new EditableOrderItem();
    this.items.push(orderItem);

    return orderItem;
  }

  public resetOrderLineItem(transactionLineItem: EditableOrderItem) {

    transactionLineItem.restoreSnapshot();
  }

  public removeOrderLineItem(transactionLineItem: EditableOrderItem) {

    let lineItems = this.items;
    let index = lineItems.indexOf(transactionLineItem);

    lineItems.splice(index, 1);
  }

  public addOrderAdjustmentLineItem(): EditableOrderAdjustment {

    const transactionAdjustmentLineItem = new EditableOrderAdjustment();
    this.adjustments.push(transactionAdjustmentLineItem);

    return transactionAdjustmentLineItem;
  }

  public resetOrderAdjustmentLineItem(transactionAdjustmentLineItem: EditableOrderAdjustment) {

    transactionAdjustmentLineItem.restoreSnapshot();
  }

  public removeOrderAdjustmentLineItem(transactionAdjustmentLineItem: EditableOrderAdjustment) {

    let index = this.adjustments.indexOf(transactionAdjustmentLineItem);

    this.adjustments.splice(index, 1);
  }

  public addPaymentLineItem(): EditableOrderPayment {

    const paymentLineItem = new EditableOrderPayment();

    this.payments.push(paymentLineItem);

    return paymentLineItem;
  }

  public resetPaymentLineItem(paymentLineItem: EditableOrderPayment) {

    paymentLineItem.restoreSnapshot();
  }

  public removePaymentLineItem(paymentLineItem: EditableOrderPayment) {

    let index = this.payments.indexOf(paymentLineItem);

    this.payments.splice(index, 1);
  }

  public get balance() {

    const paymentsTotal = this.payments.length == 0 ? 0 : this.payments.map(x => x.amount.value).reduce((sum, amount) => sum + amount);
    return this.totalAmount - paymentsTotal;
  }

  public get totalRemainingQuantity() {

    return this.orderQuantity - this.fulfilledQuantity;
  }

  private evaluateState() {

    this.canEditNotes = this.orderStatusUid.value.toUpperCase() == OrderStatusKeys.Created.toUpperCase();
    this.canEditLocation = this.orderStatusUid.value.toUpperCase() == OrderStatusKeys.Created.toUpperCase();
  }
}

export class EditableOrderItem {

  public uid?: string;
  public name = new BehaviorSubject<string>(null);
  public productUid?= new BehaviorSubject<string>(null);
  public productVersion = new BehaviorSubject<number>(null);
  public configuration = new BehaviorSubject<EditableOrderItemConfiguration>(null);
  public orderQuantity = new BehaviorSubject<number>(0);
  public eachAmount = new BehaviorSubject<number>(0);
  public fulfilledQuantity = new BehaviorSubject<number>(0);
  public remainingQuantity = new BehaviorSubject<number>(0);
  public total = new BehaviorSubject<number>(0);

  public adjustments = <EditableOrderItemAdjustment[]>[];
  public isValid: boolean;
  public isDirty: boolean;
  public canVoid = false;
  public canComp = false;
  public canCancel = false;
  private snapShot: any;
  private subscriptionMap: { [index: string]: Subscription } = {};

  constructor(

  ) {
    this.takeSnapshot();

    this.productUid.subscribe(() => {
      this.evaluateState();
    });
    this.configuration.subscribe(() => {
      this.evaluateState();
    });
    this.orderQuantity.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });
    this.eachAmount.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });
    this.fulfilledQuantity.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });

    this.calculateTotals();
    this.evaluateState();
  }

  public getOrderQuantity(): number {

    return this.orderQuantity.value;
  }

  public getEachAmount(): number {

    return this.eachAmount.value;
  }

  public addOrderLineItemAdjustment(): EditableOrderItemAdjustment {

    const transactionLineItemAdjustment = new EditableOrderItemAdjustment();
    transactionLineItemAdjustment.transactionLineItemUid = this.uid;

    this.adjustments.push(transactionLineItemAdjustment);

    this.subscriptionMap[`comp_${this.adjustments.indexOf(transactionLineItemAdjustment)}`] = transactionLineItemAdjustment.total.subscribe(() => {
      this.calculateTotals();
    });

    return transactionLineItemAdjustment;
  }

  public resetOrderLineItemAdjustment(transactionLineItemAdjustment: EditableOrderItemAdjustment) {

    transactionLineItemAdjustment.restoreSnapshot();
  }

  public removeOrderLineItemAdjustment(transactionLineItemAdjustment: EditableOrderItemAdjustment) {

    let index = this.adjustments.indexOf(transactionLineItemAdjustment);

    let subscription = this.subscriptionMap[`comp_${index}`];
    if (subscription) {
      subscription.unsubscribe();
    }

    this.adjustments.splice(index, 1);

    this.calculateTotals();
  }

  public takeSnapshot() {

    this.snapShot = {
      productUid: this.productUid.value,
      configuration: this.configuration.value,
      orderQuantity: this.orderQuantity.value,
      eachAmount: this.eachAmount.value,
      fulfilledQuantity: this.fulfilledQuantity.value,
    };

    this.evaluateState();
  }

  public restoreSnapshot() {

    this.productUid.next(this.snapShot.productUid);
    this.configuration.next(this.snapShot.configuration);
    this.orderQuantity.next(this.snapShot.orderQuantity);
    this.eachAmount.next(this.snapShot.eachAmount);
    this.fulfilledQuantity.next(this.snapShot.fulfilledQuantity);

    this.evaluateState();
  }

  private calculateTotals() {

    const eachAmount = this.getEachAmount();
    const quantity = this.getOrderQuantity();

    this.total.next(eachAmount * quantity);
    this.remainingQuantity.next(this.orderQuantity.value - this.fulfilledQuantity.value);
  }

  private evaluateState() {

    this.isValid = this.productUid.value != null && this.getOrderQuantity() > 0 && this.getEachAmount() > 0;

    this.isDirty = this.productUid.value != this.snapShot.productUid;
    this.isDirty = this.isDirty || this.configuration.value != this.snapShot.configuration;
    this.isDirty = this.isDirty || this.orderQuantity.value != this.snapShot.orderQuantity;
    this.isDirty = this.isDirty || this.eachAmount.value != this.snapShot.eachAmount;
    this.isDirty = this.isDirty || this.fulfilledQuantity.value != this.snapShot.fulfilledQuantity;

    let adjustmentsTotal = this.adjustments.length == 0 ? 0 : Math.abs(this.adjustments.map(x => x.total.value).reduce((sum, value) => sum + value));

    this.canVoid = this.uid != null && (adjustmentsTotal == 0); // is not already voided, and not changed if already committed
    this.canComp = this.isValid && (adjustmentsTotal < this.total.value); // was committed, and is not already voided
    this.canCancel = this.isDirty;
    // this.canCancel.next(isEditing && this.lineItemAdjustmentsList.All(x => !x.isVoid.value)); // In edit state, is not already voided, and not changed if already committed
    // this.canVoid.next(isEditing && this.uid && this.lineItemAdjustmentsList.All(x => !x.isVoid.value)); // In edit state, was committed, and is not already voided
    // this.canAdjust.next(isEditing && Math.abs(this.lineItemAdjustmentsList.Sum(x => x.total.value)) < this.total.value); // In edit state, is not voided, and comp total is less than item total
  }
}

export class EditableOrderItemConfiguration {

  portion: EditableOrderItemConfigurationPortion;

  public getConfiguringPortion?(): EditableOrderItemConfigurationPortion {

    return this.portion;
  }
}

export class EditableOrderItemConfigurationPortion {

  portionUid: string;
  preparations?: EditableOrderItemConfigurationPreparation[];
  variations?: EditableOrderItemConfigurationVariation[];
  inclusionGroups?: EditableOrderItemConfigurationInclusionGroup[];
  inclusions?: EditableOrderItemConfigurationInclusion[];
  extras?: EditableOrderItemConfigurationExtra[];
  addOns?: EditableOrderItemConfigurationAddOn[];

  public getConfiguringInclusionGroup?(inclusionGroupUid: string): EditableOrderItemConfigurationInclusionGroup {

    return this.inclusionGroups ? this.inclusionGroups.find(x => x.inclusionGroupUid.toUpperCase() == inclusionGroupUid.toUpperCase()) : null;
  }

  public getConfiguringInclusion?(inclusionUid: string): EditableOrderItemConfigurationInclusion {

    return this.inclusions ? this.inclusions.find(x => x.inclusionUid.toUpperCase() == inclusionUid.toUpperCase()) : null;
  }

  public getConfiguringExtra?(extraUid: string): EditableOrderItemConfigurationExtra {

    return this.extras ? this.extras.find(x => x.extraUid.toUpperCase() == extraUid.toUpperCase()) : null;
  }
}

export class EditableOrderItemConfigurationPreparation {

  preparationUid: string;
  optionUid: string;
}

export class EditableOrderItemConfigurationVariation {

  variationUid: string;
  optionUid: string;
  productUid: string;
  productVersion: number;
}

export class EditableOrderItemConfigurationInclusionGroup {

  inclusionGroupUid: string;
  options: EditableOrderItemConfigurationInclusionGroupOption[];
}

export class EditableOrderItemConfigurationInclusionGroupOption {

  optionUid: string;
  productUid: string;
  productVersion: number;
  quantity: number;
  preparations?: EditableOrderItemConfigurationPreparation[];
  variations?: EditableOrderItemConfigurationVariation[];
}

export class EditableOrderItemConfigurationInclusion {

  inclusionUid: string;
  productUid: string;
  productVersion: number;
  quantity: number;
  preparations?: EditableOrderItemConfigurationPreparation[];
  variations?: EditableOrderItemConfigurationVariation[];
}

export class EditableOrderItemConfigurationExtra {

  extraUid: string;
  productUid: string;
  productVersion: number;
  quantity: number;
  preparations?: EditableOrderItemConfigurationPreparation[];
  variations?: EditableOrderItemConfigurationVariation[];
}

export class EditableOrderItemConfigurationAddOn {

  addOnUid: string;
  item: EditableOrderItem;
}

export class EditableOrderItemAdjustment {

  public uid: string;
  public transactionLineItemUid: string;
  public description: string;
  public quantity = new BehaviorSubject<number>(1);
  public eachAmountText = new BehaviorSubject<string>(new Decimal(0).toFixed(2));
  public total = new BehaviorSubject<number>(0);
  public isVoid = new BehaviorSubject<boolean>(false);
  public isCompensated = new BehaviorSubject<boolean>(false);

  public isValid: boolean;
  public isDirty: boolean;
  public canCancel = false;
  public canVoid = false;
  public validationMessage: string;
  private snapShot: any;

  constructor(

  ) {
    this.takeSnapshot();

    this.quantity.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });
    this.eachAmountText.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });

    this.calculateTotals();
    this.evaluateState();
  }

  public getQuantity(): number {

    return this.quantity.value;
  }

  public getEachAmount(): number {

    return new Decimal(this.eachAmountText.value).toNumber();
  }

  public takeSnapshot() {

    this.snapShot = {
      description: this.description,
      quantity: this.quantity.value,
      eachAmountText: this.eachAmountText.value
    };

    this.evaluateState();
  }

  public restoreSnapshot() {

    this.description = this.snapShot.description;
    this.quantity.next(this.snapShot.quantity);
    this.eachAmountText.next(this.snapShot.eachAmountText);

    this.evaluateState();
  }

  private calculateTotals() {

    const eachAmount = this.getEachAmount();
    const quantity = this.getQuantity();

    this.total.next(eachAmount * quantity);
  }

  private evaluateState() {

    this.isValid = (this.description != null || this.description != null) && this.getQuantity() > 0 && this.getEachAmount() > 0;

    this.isDirty = this.description != this.snapShot.description;
    this.isDirty = this.isDirty || this.quantity.value != this.snapShot.quantity;
    this.isDirty = this.isDirty || this.eachAmountText.value != this.snapShot.eachAmountText;

    this.canVoid = this.uid != null;// && this.lineItemAdjustmentsList.All(x => !x.isVoid.value); // is not already voided, and not changed if already committed
    this.canCancel = this.isDirty;
  }
}

export class EditableOrderAdjustment {

  public uid: string;
  public description = new BehaviorSubject<string>('');
  public amountText = new BehaviorSubject<string>(new Decimal(0).toFixed(2));
  public total = new BehaviorSubject<number>(null);
  public isVoid = new BehaviorSubject<boolean>(false);
  public isCompensated = new BehaviorSubject<boolean>(false);

  public isValid: boolean;
  public isDirty: boolean;
  public canCancel = false;
  public canVoid = false;
  private snapShot: any;

  constructor(

  ) {
    this.takeSnapshot();

    this.description.subscribe(() => {
      this.evaluateState();
    });
    this.amountText.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });

    this.calculateTotals();
    this.evaluateState();
  }

  public getAmount(): number {

    return new Decimal(this.amountText.value).toNumber();
  }

  public takeSnapshot() {

    this.snapShot = {
      description: this.description.value,
      amountText: this.amountText.value
    };

    this.evaluateState();
  }

  public restoreSnapshot() {

    this.description.next(this.snapShot.description);
    this.amountText.next(this.snapShot.amountText);

    this.evaluateState();
  }

  private calculateTotals() {

    const amount = this.getAmount();

    this.total.next(amount);
  }

  private evaluateState() {

    this.isValid = (this.description != null || this.description != null) && this.getAmount() > 0;

    this.isDirty = this.description.value != this.snapShot.description;
    this.isDirty = this.isDirty || this.amountText.value != this.snapShot.amountText;

    this.canVoid = this.uid != null;// && this.lineItemAdjustmentsList.All(x => !x.isVoid.value); // is not already voided, and not changed if already committed
    this.canCancel = this.isDirty;
  }
}

export class EditableOrderPayment {

  public uid: string;
  public cardTransactionUid = new BehaviorSubject<string>(null);
  public paymentMethodUid = new BehaviorSubject<string>(null);
  public referenceUid = new BehaviorSubject<string>(null);
  public amount = new BehaviorSubject<number>(0);
  public tip = new BehaviorSubject<number>(0);

  public cardTransaction: EditableCardTransaction;

  public isValid: boolean;
  public isDirty: boolean;
  public canCancel = false;
  public canVoid = false;

  private snapShot: any;

  constructor(

  ) {
    this.takeSnapshot();

    this.paymentMethodUid.subscribe(() => {
      this.evaluateState();
    });
    this.amount.subscribe(() => {
      this.evaluateState();
    });

    this.evaluateState();
  }

  public takeSnapshot() {

    this.snapShot = {
      drawerUid: this.cardTransactionUid.value,
      paymentMethodUid: this.paymentMethodUid.value,
      referenceUid: this.referenceUid.value,
      amount: this.amount.value,
    };

    this.evaluateState();
  }

  public restoreSnapshot() {

    this.cardTransactionUid.next(this.snapShot.cardTransactionUid);
    this.paymentMethodUid.next(this.snapShot.paymentMethodUid);
    this.referenceUid.next(this.snapShot.referenceUid);
    this.amount.next(this.snapShot.amount);

    this.evaluateState();
  }

  private evaluateState() {

    this.isValid = (this.paymentMethodUid.value != null || this.amount.value > 0);

    this.isDirty = this.cardTransactionUid.value != this.snapShot.cardTransactionUid;
    this.isDirty = this.isDirty || this.paymentMethodUid.value != this.snapShot.paymentMethodUid;
    this.isDirty = this.isDirty || this.referenceUid.value != this.snapShot.referenceUid;
    this.isDirty = this.isDirty || this.amount.value != this.snapShot.amount;

    this.canVoid = this.uid != null;
    this.canCancel = this.isDirty;
  }
}

export class EditableCardTransaction {

  public uid: string;
  public processorReferenceId: string;
  public name = new BehaviorSubject<string>(null);
  public lastFour = new BehaviorSubject<string>(null);
  public amount = new BehaviorSubject<number>(null);
  public tip = new BehaviorSubject<number>(null);
  public cardTransactionStatusUid = new BehaviorSubject<string>(null);
  public createdDateTimeUtc = new BehaviorSubject<Date>(null);
  public modifiedDateTimeUtc = new BehaviorSubject<Date>(null);

  public isValid: boolean;
  public isDirty: boolean;
  public canTip = false;
  public canVoid = false;
  public canReverse = false;
  public canCapture = false;
  public canRefund = false;

  constructor(

  ) {
    combineLatest([
      this.name,
      this.lastFour,
      this.amount,
      this.tip,
      this.cardTransactionStatusUid
    ]).subscribe(() => {
      this.evaluateState();
    })

    this.evaluateState();
  }

  private evaluateState() {

    if (this.cardTransactionStatusUid.value) {
      let cardTransactionStatusUid = this.cardTransactionStatusUid.value.toUpperCase();

      this.canTip = cardTransactionStatusUid == CardTransaction2StatusKeys.Authorized.toUpperCase();
      this.canVoid = cardTransactionStatusUid == CardTransaction2StatusKeys.Captured.toUpperCase() || cardTransactionStatusUid == CardTransaction2StatusKeys.Refunded.toUpperCase();
      this.canReverse = false; //cardTransactionStatusUid.toUpperCase() == CardTransaction2StatusKeys.UID_Authorized.toUpperCase();
      this.canCapture = cardTransactionStatusUid.toUpperCase() == CardTransaction2StatusKeys.Authorized.toUpperCase();
      this.canRefund = cardTransactionStatusUid.toUpperCase() == CardTransaction2StatusKeys.Captured.toUpperCase();
    }
  }
}
