<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="dataSource.selection.isMultipleSelection()" (change)="$event ? dataSource.masterToggle() : null" [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row) : null" [checked]="dataSource.selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="number">
    <th mat-header-cell *matHeaderCellDef color="accent">Number</th>
    <td mat-cell *matCellDef="let order">
      {{ order.number }}
    </td>
  </ng-container>

  <ng-container matColumnDef="ownerUid">
    <th mat-header-cell *matHeaderCellDef color="accent">Client</th>
    <td mat-cell *matCellDef="let order">
      {{ order.ownerUid }}
    </td>
  </ng-container>

  <ng-container matColumnDef="initiatorIdentity">
    <th mat-header-cell *matHeaderCellDef color="accent">Identity</th>
    <td mat-cell *matCellDef="let order">
      {{ order.initiatorIdentity }}
    </td>
  </ng-container>

  <ng-container matColumnDef="orderQuantity">
    <th mat-header-cell *matHeaderCellDef color="accent">Order Quantity</th>
    <td mat-cell *matCellDef="let order">
      {{ order.orderQuantity }}
    </td>
  </ng-container>

  <ng-container matColumnDef="fulfilledQuantity">
    <th mat-header-cell *matHeaderCellDef color="accent">Fulfilled Quantity</th>
    <td mat-cell *matCellDef="let order">
      {{ order.fulfilledQuantity }}
    </td>
  </ng-container>

  <ng-container matColumnDef="totalAmount">
    <th mat-header-cell *matHeaderCellDef color="accent">Total</th>
    <td mat-cell *matCellDef="let order">
      {{ order.totalAmount | currency:'USD':'symbol':'1.2-2' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="openDateTime">
    <th mat-header-cell *matHeaderCellDef color="accent">Opened</th>
    <td mat-cell *matCellDef="let order">
      {{ order.openDateTime | date: '(E) M-dd-yyyy h:mm a' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="modifiedDateTime">
    <th mat-header-cell *matHeaderCellDef color="accent">Modified</th>
    <td mat-cell *matCellDef="let order">
      {{ order.modifiedDateTime | date: '(E) M-dd-yyyy h:mm a' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="orderStatusUid">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let order">
      {{ order.orderStatusUid | orderStatus }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>

</table>
