<div style="display: flex; height: 100%;">

  <div style="flex: 1; background-color: rgba(255, 255, 255, 0.06); height: 100%;">
    <app-expansion-container #expansionContainer style="height: 100%;">

      <app-expansion-panel *ngFor="let setting of settings">
        <app-expansion-panel-header (click)="panelClick(setting)">
          <app-expansion-panel-title>
            <span style="font-size: 24px;">{{ setting.name }}</span>
          </app-expansion-panel-title>
        </app-expansion-panel-header>
      </app-expansion-panel>

    </app-expansion-container>
  </div>

  <div style="flex: 5; margin-left: 10px; height: 100%; display: flex; flex-direction: column;">

    <div style="flex: 1; overflow-y: hidden;">
      <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"></router-outlet>
    </div>

    <div style="display: flex; justify-content: right; padding-right: 4.1666%; align-items: center;">
      <app-pos-button color="warn" (click)="cancel()">Cancel</app-pos-button>
      <app-pos-button color="primary" (click)="save()">Save</app-pos-button>
    </div>

  </div>

</div>