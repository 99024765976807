import { Component, Input, Output, EventEmitter, Inject } from "@angular/core";
import { UUID } from "angular2-uuid";
import { findInArrayByUid } from "core";
import { of } from "rxjs";
import { ProductConfigurationAddOn } from "downtown-product";
import { IItemConfiguratorModalProvider, ITEM_CONFIGURATOR_MODAL_PROVIDER } from '../../iitem-configurator-modal.provider';
import { type ItemConfiguratorModalData } from "../../item-configurator-modal.provider";
import { flattenProduct } from "downtown-product";
import { initTransactionItem, initTransactionItemConfiguration, initTransactionItemConfigurationAddOn } from "../../../../functions/transaction-item-configuration/initialize";
import { cloneTransactionItem, cloneTransactionItemConfiguration } from "../../../../functions/transaction-item-configuration/clone";
import { MenuPlacement } from 'downtown-product';
import { EditableTransactionItem, EditableTransactionItemConfigurationAddOn } from "../../../../models/editable-transaction/editable-transaction";
import { AddOnSelectionModel } from "../../models";
import { ItemConfiguratorContext } from "../../../../components";

@Component({
  selector: 'transaction-item-configurator-modal-add-on',
  templateUrl: './item-configurator-modal-add-on.component.html',
  styleUrls: ['./item-configurator-modal-add-on.component.scss']
})
export class ItemConfiguratorModalAddOnComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public isNew: boolean;
  @Input() public addOn: ProductConfigurationAddOn;
  @Output() public configurationChanged = new EventEmitter();

  public addOnSelection: AddOnSelectionModel;

  constructor(
    @Inject(ITEM_CONFIGURATOR_MODAL_PROVIDER) private itemConfiguratorModalProvider: IItemConfiguratorModalProvider
  ) {
  }

  ngOnInit(): void {

    this.buildAddOn(this.isNew);
  }

  public add(addOnSelection: AddOnSelectionModel) {

    if (addOnSelection.productUid) {
      this.context.productProvider.getOneCached$(addOnSelection.productUid, addOnSelection.productVersion).subscribe(product => {
        product = flattenProduct(product);

        let productPortion = findInArrayByUid(product.configuration.portions, addOnSelection.portionUid);

        const configuration = initTransactionItemConfiguration(product, productPortion.uid, this.context.productProvider, true);

        if (productPortion.isConfigurable) {
          this.itemConfiguratorModalProvider.open(<ItemConfiguratorModalData>{
            ownerUid: this.context.ownerUid,
            productUid: product.uid,
            productVersion: product.version,
            itemConfiguration: configuration,
            menuPlacementUid: MenuPlacement.Menu,
            isNew: true,
            isAddOn: true
          }).afterClosed().subscribe(result => {
            if (result) {
              const transactionItem = initTransactionItem(product, productPortion.uid, this.context.productProvider, true);
              transactionItem.uid = transactionItem.uid || UUID.UUID();

              const configuringAddOn = initTransactionItemConfigurationAddOn(product, productPortion.uid);

              configuringAddOn.item = transactionItem;
              configuringAddOn.item.configuration.next(cloneTransactionItemConfiguration(result.configuration));
              configuringAddOn.item.eachAmountText.next(result.eachTotal.toFixed(2));

              this.context.itemConfiguration.portion.addOns = this.context.itemConfiguration.portion.addOns || [];
              this.context.itemConfiguration.portion.addOns.push(configuringAddOn);

              this.configurationChanged.next(null);
            }
          });
        } else {
          // Nothing more to configure
          const transactionItem = initTransactionItem(product, productPortion.uid, this.context.productProvider, true);
          transactionItem.uid = transactionItem.uid || UUID.UUID();

          const configuringAddOn = initTransactionItemConfigurationAddOn(product, productPortion.uid);
          configuringAddOn.addOnUid = addOnSelection.addOnUid;
          configuringAddOn.item = cloneTransactionItem(transactionItem);

          this.context.itemConfiguration.portion.addOns = this.context.itemConfiguration.portion.addOns || [];
          this.context.itemConfiguration.portion.addOns.push(configuringAddOn);

          this.configurationChanged.next(null);
        }
      });
    } else {
      const configuringAddOn = new EditableTransactionItemConfigurationAddOn();
      configuringAddOn.addOnUid = UUID.UUID();

      const addOnItem = new EditableTransactionItem();
      addOnItem.alias.next(addOnSelection.display);

      configuringAddOn.item = addOnItem;

      this.context.itemConfiguration.portion.addOns = this.context.itemConfiguration.portion.addOns || [];
      this.context.itemConfiguration.portion.addOns.push(configuringAddOn);
    }
  }

  buildAddOn(setDefaults: boolean) {

    (this.addOn.productReference ? this.context.productProvider.getOneCached$(this.addOn.productReference.uid, this.addOn.productReference.version) : of(null)).subscribe(addOnproduct => {
      addOnproduct = flattenProduct(addOnproduct);

      if (addOnproduct) {
        let addOnConfiguringPortion = addOnproduct.configuration.getPortion(this.addOn.productReference.portionUid);

        let addOnSelection = <AddOnSelectionModel>{
          addOnUid: this.addOn.uid,
          productUid: this.addOn.productReference.uid,
          productVersion: this.addOn.productReference.version,
          portionUid: this.addOn.productReference.portionUid,
          display: this.addOn.alias || addOnproduct.name,
          minimumEachAmount: addOnConfiguringPortion.price
        };

        this.addOnSelection = addOnSelection;
      } else {
        this.addOnSelection = <AddOnSelectionModel>{
          addOnUid: this.addOn.uid,
          productUid: null,
          productVersion: null,
          portionUid: null,
          display: this.addOn.alias,
          minimumEachAmount: this.addOn.priceOverride
        };
      }
    });
  }
}
