import { Product, ProductConfigurationInclusionGroup, ProductConfigurationInclusionGroupOption, ProductConfigurationPortion, ProductConfigurationPreparation, ProductConfigurationPreparationOption, ProductConfigurationVariation, ProductConfigurationVariationOption, ProductProvider } from "downtown-product";
import { CacheService, isEqualUUID } from "core";
import { EditableTransactionItemConfiguration, EditableTransactionItemConfigurationAddOn, EditableTransactionItemConfigurationInclusionGroupOption, EditableTransactionItemConfigurationPortion, EditableTransactionItemConfigurationPreparation, EditableTransactionItemConfigurationVariation } from "../../models/editable-transaction/editable-transaction";
import { findInArrayByUid } from "core";
import { Observable, map, of } from "rxjs";

export class ItemConfiguratorContext {

  public selection: EditableTransactionItemConfigurationPortion | EditableTransactionItemConfigurationInclusionGroupOption | EditableTransactionItemConfigurationAddOn;

  public localCache: CacheService;

  constructor(
    public ownerUid: string,
    public productProvider: ProductProvider,
    public product: Product,
    public itemConfiguration: EditableTransactionItemConfiguration,
    public isReadOnly: boolean
  ) {
    this.localCache = new CacheService();
  }

  public getSelectionIfHasPreparations(): { preparations?: EditableTransactionItemConfigurationPreparation[] } {

    if (this.selection instanceof EditableTransactionItemConfigurationAddOn) {
      return null;
    }

    return this.selection;
  }

  public getSelectionIfHasVariations(): { variations?: EditableTransactionItemConfigurationVariation[] } {

    if (this.selection instanceof EditableTransactionItemConfigurationAddOn) {
      return null;
    }

    return this.selection;
  }

  public getSelectionIfHasNotes(): { notes?: string[] } {

    if (this.selection instanceof EditableTransactionItemConfigurationAddOn) {
      return null;
    }

    return this.selection;
  }

  public select(selection: EditableTransactionItemConfigurationPortion | EditableTransactionItemConfigurationInclusionGroupOption | EditableTransactionItemConfigurationAddOn) {

    if (!this.isReadOnly) {
      if (selection instanceof EditableTransactionItemConfigurationInclusionGroupOption) {
        if (this.selection == selection || selection == null || selection.quantity == 0) {
          this.selection = null;
        } else {
          this.selection = selection;
        }
      } else {
        if (this.selection == selection || selection == null) {
          this.selection = null;
        } else {
          this.selection = selection;
        }
      }

    }
  }

  public getProductPortion(portionUid: string): ProductConfigurationPortion {

    return this.product.configuration.portions.find(x => isEqualUUID(x.uid, portionUid));
  }

  public getProductInclusionGroup(item: { inclusionGroups?: ProductConfigurationInclusionGroup[] }, inclusionGroupUid: string): ProductConfigurationInclusionGroup {

    return item && item.inclusionGroups && item.inclusionGroups.length > 0 ? item.inclusionGroups.find(x => isEqualUUID(x.uid, inclusionGroupUid)) : null;
  }

  public getProductInclusionGroupOption(item: { options?: ProductConfigurationInclusionGroupOption[] }, optionUid: string): ProductConfigurationInclusionGroupOption {

    return item && item.options && item.options.length > 0 ? item.options.find(x => isEqualUUID(x.uid, optionUid)) : null;
  }

  public getProductPreparations(item: { preparations?: ProductConfigurationPreparation[] }): ProductConfigurationPreparation[] {

    return item?.preparations || [];
  }

  public getProductPreparation(item: { preparations?: ProductConfigurationPreparation[] }, preparationUid: string): ProductConfigurationPreparation {

    return item && item.preparations && item.preparations.length > 0 ? item.preparations.find(x => isEqualUUID(x.uid, preparationUid)) : null;
  }

  public getProductVariations(item: { variations?: ProductConfigurationVariation[] }): ProductConfigurationVariation[] {

    return item?.variations || [];
  }

  public getProductVariation(item: { variations?: ProductConfigurationVariation[] }, variationUid: string): ProductConfigurationVariation {

    return item && item.variations && item.variations.length > 0 ? item.variations.find(x => isEqualUUID(x.uid, variationUid)) : null;
  }

  public getItemPortion(): EditableTransactionItemConfigurationPortion {

    return this.itemConfiguration.portion;
  }

  public getAssignedItemPreparations(item: { preparations?: EditableTransactionItemConfigurationPreparation[] }): EditableTransactionItemConfigurationPreparation[] {

    return item.preparations?.filter(x => !!x.optionUid) || [];
  }

  getProductPortionDisplay(portionUid: string): string {

    return this.localCache.getOrAdd(`${this.product.uid}_${portionUid}`, () => {
      let portion = this.product && this.product.configuration ? this.product.configuration.getPortion(portionUid) : null;
      return `${this.product.name} ${(portion ? ` - ${portion.name}` : null)}`;
    });
  }

  public getProductInclusionGroupSelectMessaging(productInclusionGroup: ProductConfigurationInclusionGroup): string {

    return this.localCache.getOrAdd(`${productInclusionGroup.uid}_selectdisplay`, () => {

      let message = '';

      if (productInclusionGroup.maxTotalOptionQuantityIncluded) {
        if (productInclusionGroup.allowAdditional) {
          message += `${productInclusionGroup.maxTotalOptionQuantityIncluded} included`;

          if (productInclusionGroup.options.some(x => x.maxIncludedQuantity)) {
            message += `, additional restrictions may be listed below`;
          }

          message += '.';
        } else {
          if (productInclusionGroup.maxTotalOptionQuantityIncluded == 1 || productInclusionGroup.maxDistinctOptionsIncluded == 1) {
            message += `Choose 1.`
          } else {
            message += `Max quantity of ${productInclusionGroup.maxTotalOptionQuantityIncluded}`

            if (productInclusionGroup.maxDistinctOptionsIncluded && productInclusionGroup.maxDistinctOptionsIncluded > 1) {
              message += `, in up to ${productInclusionGroup.maxDistinctOptionsIncluded} choices`;
            }

            message += '. ';
          }
        }
      } else {
        if (productInclusionGroup.maxDistinctOptionsIncluded > 1) {
          message += `Choose a max of ${productInclusionGroup.maxDistinctOptionsIncluded} choice(s). `;
        }
      }

      if (productInclusionGroup.minQuantityForOption && productInclusionGroup.minQuantityForOption > 1 && productInclusionGroup.maxDistinctOptionsIncluded > 1) {
        message += `Min quantity of ${productInclusionGroup.minQuantityForOption} per choice.`;
      }

      return message;
    });
  }

  public getProductInclusionGroupOptionDisplay(productInclusionGroupOption: ProductConfigurationInclusionGroupOption, isSubstitution: boolean): Observable<string> {

    return this.localCache.getOrAdd(`${productInclusionGroupOption.uid}_display`, () => {
      if (productInclusionGroupOption?.alias) {
        return of(productInclusionGroupOption.alias);
      }

      return this.productProvider.getOneCached$(productInclusionGroupOption.productReference.uid, productInclusionGroupOption.productReference.version).pipe(
        map(product => {
          return (isSubstitution ? '(Sub) ' : '') + product.name;
        })
      );
    });
  }

  public getProductPreparationDisplay(item: { uid: string, preparations?: ProductConfigurationPreparation[] }, preparationUid: string): string {

    return this.localCache.getOrAdd(`${this.product.uid}_${item.uid}_${preparationUid}`, () => {
      const preparation = findInArrayByUid(item.preparations, preparationUid);

      return preparation?.name;
    });
  }

  public getProductVariationOptionDisplay(productVariationOption: ProductConfigurationVariationOption): Observable<string> {

    return this.localCache.getOrAdd(`${this.product.uid}_${productVariationOption.uid}`, () => {
      if (productVariationOption?.alias) {
        return of(productVariationOption.alias);
      }

      return this.productProvider.getOneCached$(productVariationOption.productReference.uid, productVariationOption.productReference.version).pipe(
        map(product => {
          return product.name;
        })
      );
    });
  }

  public getItemInclusionGroupOptionDisplay(itemInclusionGroupOption: EditableTransactionItemConfigurationInclusionGroupOption): Observable<string> {

    return this.localCache.getOrAdd(`${this.product.uid}_${itemInclusionGroupOption.optionUid}`, () => {
      const productPortion = this.product.configuration.getPortion(this.itemConfiguration.portion.portionUid);
      const productInclusionGroup = productPortion.inclusionGroups.find(x => x.options.some(y => isEqualUUID(y.uid, itemInclusionGroupOption.optionUid)));
      const productInclusionGroupOption = productInclusionGroup.getOption(itemInclusionGroupOption.optionUid);

      return this.getProductInclusionGroupOptionDisplay(productInclusionGroupOption, itemInclusionGroupOption.isSubstitution).pipe(
        map(x => itemInclusionGroupOption.isSubstitution ? `(Sub) ${x}` : x)
      );
    });
  }
}
