<div class="container" [formGroup]="form">
  <div style="margin-bottom: 10px;">
    <h2>{{ title }}</h2>
  </div>
  <div>
    <div>
      <mat-form-field style="width: 100%;">
        <mat-select formControlName="paymentMethodUid" placeholder="Payment Method">
          <mat-option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod.uid">{{ paymentMethod.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div style="text-align: right;">
    <button mat-button color="warn" style="margin-left: 1em;" (click)="cancel()">Cancel</button>
    <button mat-stroked-button [disabled]="!this.form.dirty" color="primary" style="margin-left: 1em;" (click)="save()">Save</button>
  </div>

</div>
