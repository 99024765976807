export class Alert {

  uid: string; // Same as SystemMessage uid
  title: string;
  systemMessageTypeUid: string;
  lifespanUid: string;
  metadataJson?: string;
  publishedDateTimeUtc?: Date;
  expiresDateTimeUtc?: Date;
  systemMessageStatusUid: string;
}
