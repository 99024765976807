<div class="container" [formGroup]="form" style="">
  <div style="margin-bottom: 10px;">
    <h2>Member Profile</h2>
  </div>
  <div>
    <div>
      <mat-form-field>
        <input matInput formControlName="firstName" placeholder="First Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="lastName" placeholder="Last Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="displayName" placeholder="Display Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="email" placeholder="Email">
        <mat-error *ngIf="form.get('email').hasError('email')">Email is not valid</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div style="text-align: right;">
    <button mat-button color="warn" style="margin-left: 1em;" (click)="cancel()">Cancel</button>
    <button mat-stroked-button [disabled]="!this.form.dirty" color="primary" style="margin-left: 1em;" (click)="save()">Save</button>
  </div>
</div>
