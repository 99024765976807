<ng-container *ngIf="context">

    <div style="height: 100%; display: flex; flex-direction: column;">

        <div *ngIf="context.selection" style="flex: 1;">

            <div *ngFor="let productPreparation of productPreparations" style="margin-bottom: 20px;">
                <transaction-item-configurator-mods-preparation [context]="context" [item]="context.getSelectionIfHasPreparations()" [itemDisplay]="title" [productPreparation]="productPreparation" [itemPreparation]="getItemPreparation(productPreparation.uid)"></transaction-item-configurator-mods-preparation>
            </div>

            <div *ngFor="let productVariation of productVariations" style="margin-bottom: 20px;">
                <transaction-item-configurator-mods-variation [context]="context" [item]="context.getSelectionIfHasVariations()" [itemDisplay]="title" [productVariation]="productVariation" [itemVariation]="getItemVariation(productVariation.uid)"></transaction-item-configurator-mods-variation>
            </div>

            <transaction-item-configurator-mods-notes [context]="context" [item]="context.getSelectionIfHasNotes()" [itemDisplay]="title"></transaction-item-configurator-mods-notes>

        </div>


    </div>

</ng-container>