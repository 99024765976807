import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UpdateOrderItemFullfilmentModalComponent } from "./update-order-item-fullfilment-modal.component";

@Injectable()
export class UpdateOrderItemFullfilmentModalProvider {

  constructor(
    private dialog: MatDialog
  ) {
  }

  public open(data: UpdateOrderItemFullfilmentModalData): MatDialogRef<UpdateOrderItemFullfilmentModalComponent, UpdateOrderItemFullfilmentModalResult> {

    return this.dialog.open<UpdateOrderItemFullfilmentModalComponent, UpdateOrderItemFullfilmentModalData, UpdateOrderItemFullfilmentModalResult>(UpdateOrderItemFullfilmentModalComponent, {
      width: '40vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface UpdateOrderItemFullfilmentModalData {

  orderUid: string;
  itemUid: string;
  orderQuantity: number;
  fulfilledQuantity: number;
}

export interface UpdateOrderItemFullfilmentModalResult {

  orderUid: string;
  itemUid: string;
  fulfilledQuantity: number;
}
