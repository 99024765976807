export class OrderInput {

  uid: string;
  cartUid: string;
  initiatorIdentityUid: string;
  shippingAddressUid?: string;
  shippingPhoneNumber?: string;
  billingAddressUid?: string;
  billingPhoneNumber?: string;

  identities: IdentityInput[];
  addresses?: AddressInput[];
  creditCards?: CreditCardInput[];
}

export class OrderFulfillmentInput {

  uid: string;
  itemFulfillments: OrderItemFulfillmentInput[]
}

export class OrderItemFulfillmentInput {

  itemUid: string;
  fulfilledQuantity: number;
}

export class IdentityInput {

  uid: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  addressUid: string;
  phoneNumber: string;
}

export class AddressInput {

  uid: string;
  name: string;
  country: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  phoneNumber: string;
}

export class CreditCardInput {

  name: string;
  number: string;
  expiryMonth: string;
  expiryYear: string;
  csc: string;
  amount: number;
}
