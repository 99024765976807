<div style="display: flex; height: 100%;">

  <div style="height: 100%; width: 50%; padding-right: 10px; overflow-y: hidden; overflow-x: hidden;">
    <transaction-item-configurator [context]="context" (configurationChanged)="updateDisplay()"></transaction-item-configurator>
  </div>

  <div style="flex: 1; display: flex; flex-direction: column; height: 100%;">

    <div style="display: flex; margin-bottom: 4px;">
      <app-pos-button *ngFor="let selectionTab of selectionTabs" (click)="selectTab(selectionTab)" [styles]="{ backgroundColor: selectedTab == selectionTab ? 'var(--app-primary-default-color)' : 'var(--app-accent-lighter-color)' }">{{ selectionTab.display }}</app-pos-button>
    </div>

    <div style="flex: 1; padding-top: 4px; padding-bottom: 4px; overflow: hidden; display: flex; background-color: rgb(30, 30, 30); border-radius: 4px;">

      <div style="flex: 1; height: 100%; padding: 2px; overflow-y: auto;">

        <ng-container *ngFor="let inclusionGroupUid of selectedTab?.inclusionGroupUids">
          <transaction-item-configurator-modal-inclusion-group-tab [context]="context" [isNew]="data.isNew" [productInclusionGroup]="getProductInclusionGroup(inclusionGroupUid)" (configurationChanged)="updateDisplay()"></transaction-item-configurator-modal-inclusion-group-tab>
        </ng-container>

        <div *ngIf="selectedTab?.addOnUids?.length > 0">
          <span style="color: white; font-size: 36px; line-height: 36px; font-weight: bold;">Add Ons</span>

          <ng-container *ngFor="let addOnUid of selectedTab?.addOnUids">
            <transaction-item-configurator-modal-add-on-tab [context]="context" [isNew]="data.isNew" [productAddOn]="getProductAddOn(addOnUid)" (configurationChanged)="updateDisplay()"></transaction-item-configurator-modal-add-on-tab>
          </ng-container>
        </div>

      </div>

    </div>

    <div style="display: flex; margin-top: 4px;">

      <span style="flex: 1 1 auto;"></span>

      <app-pos-button (click)="cancel()" [styles]="{ backgroundColor: 'red' }">Cancel</app-pos-button>

      <span style="width: 15px;"></span>

      <app-pos-button *ngIf="!data.isAddOn" (click)="selectOption(false)" [styles]="{ backgroundColor: '#b9a246' }">{{ data.isNew ? 'Add Item' : 'Update Item' }}</app-pos-button>
      <app-pos-button *ngIf="data.isAddOn" (click)="selectOption(false)" [styles]="{ backgroundColor: '#b9a246' }">{{ data.isNew ? 'Include Add On' : 'Update Add On' }}</app-pos-button>
      <app-pos-button *ngIf="!data.isAddOn && this.data.isNew" (click)="selectOption(true)" [styles]="{ backgroundColor: '#b9a246' }">Add And Start Another</app-pos-button>

    </div>

  </div>

</div>