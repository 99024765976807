export function getDateTimeForHourMinuteSecond(baseDate: Date, hourMinuteSecond: string): Date {

  var segments = hourMinuteSecond.split(':');

  var hour = parseInt(segments[0]);
  var minute = segments.length > 1 ? parseInt(segments[1]) : 0;
  var second = segments.length > 2 ? parseInt(segments[2]) : 0;

  return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), hour, minute, second);
}

export function getHourMinuteSecondFromString(hourMinuteSeconds: string) {

  let hourMinuteSegments = hourMinuteSeconds ? hourMinuteSeconds.split(':') : [];
  let hours = hourMinuteSegments.length > 0 ? parseInt(hourMinuteSegments[0]) : 0;
  let minutes = hourMinuteSegments.length > 1 ? parseInt(hourMinuteSegments[1]) : 0;
  let seconds = hourMinuteSegments.length > 2 ? parseInt(hourMinuteSegments[2]) : 0;

  return [hours, minutes, seconds]
}

export function addHourMinuteSecond(baseDate: Date, hourMinuteSecond: string): Date {

  var segments = hourMinuteSecond.split(':');

  var hours = parseInt(segments[0]);
  var minutes = segments.length > 1 ? parseInt(segments[1]) : 0;
  var seconds = segments.length > 2 ? parseInt(segments[2]) : 0;

  return addHours(addMinutes(addSeconds(baseDate, seconds), minutes), hours)
}

export function subtractHourMinuteSecond(baseDate: Date, hourMinuteSecond: string): Date {

  var segments = hourMinuteSecond.split(':');

  var hours = parseInt(segments[0]);
  var minutes = segments.length > 1 ? parseInt(segments[1]) : 0;
  var seconds = segments.length > 2 ? parseInt(segments[2]) : 0;

  return addHours(addMinutes(addSeconds(baseDate, -seconds), -minutes), -hours)
}

/**
 * Adds the days to the baseDate without mutating the original
 * @param baseDate
 * @param days
 * @returns
 */
export function addDays(baseDate: Date, days: number): Date {

  return new Date(new Date(baseDate.getTime()).setDate(baseDate.getDate() + days));
}

/**
 * Adds the hours to the baseDate without mutating the original
 * @param baseDate
 * @param days
 * @returns
 */
export function addHours(baseDate: Date, hours: number): Date {

  return new Date(new Date(baseDate.getTime()).setHours(baseDate.getHours() + hours));
}

/**
 * Adds the minutes to the baseDate without mutating the original
 * @param baseDate
 * @param days
 * @returns
 */
export function addMinutes(baseDate: Date, minutes: number): Date {

  return new Date(new Date(baseDate.getTime()).setMinutes(baseDate.getMinutes() + minutes));
}

/**
 * Adds the seconds to the baseDate without mutating the original
 * @param baseDate
 * @param days
 * @returns
 */
export function addSeconds(baseDate: Date, seconds: number): Date {

  return new Date(new Date(baseDate.getTime()).setSeconds(baseDate.getSeconds() + seconds));
}

/**
 * Adds the milliseconds to the baseDate without mutating the original
 * @param baseDate
 * @param days
 * @returns
 */
export function addMilliseconds(baseDate: Date, milliseconds: number): Date {

  return new Date(new Date(baseDate.getTime()).setMilliseconds(baseDate.getMilliseconds() + milliseconds));
}

// /**
//  * Subtracts the hours to the baseDate without mutating the original
//  * @param baseDate
//  * @param days
//  * @returns
//  */
// export function subtractHours(baseDate: Date, hours: number): Date {

//   return new Date(new Date(baseDate.getTime()).setHours(baseDate.getHours() - hours));
// }

// /**
//  * Subtracts the minutes to the baseDate without mutating the original
//  * @param baseDate
//  * @param days
//  * @returns
//  */
// export function subtractMinutes(baseDate: Date, minutes: number): Date {

//   return new Date(new Date(baseDate.getTime()).setMinutes(baseDate.getMinutes() - minutes));
// }

// /**
//  * Subtracts the seconds to the baseDate without mutating the original
//  * @param baseDate
//  * @param days
//  * @returns
//  */
// export function subtractSeconds(baseDate: Date, seconds: number): Date {

//   return new Date(new Date(baseDate.getTime()).setSeconds(baseDate.getSeconds() - seconds));
// }

// /**
//  * Subtracts the milliseconds to the baseDate without mutating the original
//  * @param baseDate
//  * @param days
//  * @returns
//  */
// export function subtractMilliseconds(baseDate: Date, milliseconds: number): Date {

//   return new Date(new Date(baseDate.getTime()).setMilliseconds(baseDate.getMilliseconds() - milliseconds));
// }

export function isDST(date: Date) {

  let jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

  return Math.max(jan, jul) !== date.getTimezoneOffset();
}

export function getLocaleDate(date: Date | string): Date {

  if (typeof (date) == 'string') {
    var dateTimeSegments = date.split('T');
    var dateSegments = dateTimeSegments[0].split('-');
    date = new Date(parseInt(dateSegments[0]), parseInt(dateSegments[1]) - 1, parseInt(dateSegments[2]));
  }

  let year = date.getUTCFullYear();
  let month = date.getUTCMonth();
  let day = date.getUTCDate();

  return new Date(year, month, day);
}
