import { ProductConfiguration, ProductConfigurationInclusionGroup, ProductConfigurationPreparation, ProductConfigurationVariation, ProductProvider } from "downtown-product";
import { Observable, of, forkJoin } from 'rxjs';
import { map, first, mergeMap } from "rxjs/operators";
import { EditableTransactionItem, EditableTransactionItemConfiguration, EditableTransactionItemConfigurationAddOn, EditableTransactionItemConfigurationInclusionGroup, EditableTransactionItemConfigurationInclusionGroupOption, EditableTransactionItemConfigurationPreparation, EditableTransactionItemConfigurationVariation } from "../../models/editable-transaction/editable-transaction";
import { ProductConfigurationInclusionGroupOption } from "downtown-product";
import { flattenProduct } from "downtown-product";
import { isEqualUUID } from "core";
import { configureProductConfigurationWithEditableItemConfiguration } from "./configure-product-with-item";
import Decimal from "decimal.js";

interface OptionTuple {
  itemOption: EditableTransactionItemConfigurationInclusionGroupOption;
  productOption: ProductConfigurationInclusionGroupOption;
}

interface QuantityPriceTuple {
  quantity: number;
  price: number;
}

export function calculateTotal(transactionItem: EditableTransactionItem, productProvider: ProductProvider): Observable<number> {

  return forkJoin([
    calculateBasePrice(transactionItem.productUid.value, transactionItem.productVersion.value, productProvider),
    calculateConfigurationTotal(transactionItem.productUid.value, transactionItem.productVersion.value, transactionItem.configuration.value, productProvider)
  ]).pipe(
    map(values => {
      return values.reduce((x, y) => x.plus(y), new Decimal(0)).toNumber();
    })
  );
};

export function calculateBasePrice(productUid: string, productVersion: number, productProvider: ProductProvider): Observable<number> {

  return productProvider.getOneCached$(productUid, productVersion).pipe(
    map(product => product.basePrice)
  );
};

export function calculateConfigurationTotal(productUid: string, productVersion: number, itemConfiguration: EditableTransactionItemConfiguration, productProvider: ProductProvider): Observable<number> {

  return productProvider.getOneCached$(productUid, productVersion).pipe(
    mergeMap(product => {
      product = flattenProduct(product);

      const productConfiguration = configureProductConfigurationWithEditableItemConfiguration(product.configuration, itemConfiguration);

      return itemConfiguration ? calculatePortionTotal(productConfiguration, itemConfiguration, productProvider) : of(0);
    }),
    first()
  );
}

export function calculatePortionTotal(productConfiguration: ProductConfiguration, itemConfiguration: EditableTransactionItemConfiguration, productProvider: ProductProvider): Observable<number> {

  let productPortion = productConfiguration.getPortion(itemConfiguration.portion.portionUid);

  let itemPortion = itemConfiguration.portion;

  let tasks = new Array<Observable<number>>();
  (itemPortion.inclusionGroups || []).forEach(itemInclusionGroup => {
    let productInclusionGroup = productConfiguration.getPortion(itemConfiguration.portion.portionUid).getInclusionGroup(itemInclusionGroup.inclusionGroupUid);

    tasks.push(calculateInclusionGroup(itemInclusionGroup, productInclusionGroup, productProvider))
  });
  (itemPortion.addOns || []).forEach(addOn => {
    tasks.push(calculateAddOnTotal(addOn, productProvider));
  });

  return tasks.length == 0 ? of(productPortion.price) : forkJoin(tasks).pipe(
    map(values => {
      return productPortion.price + values.reduce((x, y) => x.plus(y), new Decimal(0)).toNumber();
    })
  );
};

export function calculateInclusionGroup(itemInclusionGroup: EditableTransactionItemConfigurationInclusionGroup, productInclusionGroup: ProductConfigurationInclusionGroup, productProvider: ProductProvider): Observable<number> {

  const inclusionGroupOptionTuples = (itemInclusionGroup.options || [])
    .filter(x => x.quantity > 0)
    .map(x => {
      return <OptionTuple>{
        itemOption: x,
        productOption: productInclusionGroup?.options.find(y => isEqualUUID(y.uid, x.optionUid))
      };
    });

  return inclusionGroupOptionTuples.length == 0 ? of(0) : forkJoin(
    inclusionGroupOptionTuples.map(tuple => {

      return (tuple.productOption ? of(tuple) : productProvider.getOneCached$(tuple.itemOption.productUid, tuple.itemOption.productVersion).pipe(
        map(optionProduct => {
          optionProduct = flattenProduct(optionProduct);
          const optionProductPortion = optionProduct.configuration.getPortion(tuple.itemOption.productPortionUid);

          tuple.productOption = new ProductConfigurationInclusionGroupOption();
          tuple.productOption.uid = tuple.itemOption.optionUid;
          tuple.productOption.productReference = {
            uid: optionProduct.uid,
            portionUid: tuple.itemOption.productPortionUid,
            version: optionProduct.version
          };
          tuple.productOption.priceOverride = optionProductPortion.price;

          return tuple;
        })
      ));
    })
  ).pipe(
    mergeMap(optionTuples => {

      return forkJoin(
        optionTuples.map(optionTuple => {
          const tasks: Observable<QuantityPriceTuple>[] = [];

          if (optionTuple.productOption.priceOverride) {
            tasks.push(of(<QuantityPriceTuple>{ quantity: optionTuple.itemOption.quantity, price: optionTuple.productOption.priceOverride }));
          } else {
            tasks.push(productProvider.getOneCached$(optionTuple.productOption.productReference.uid, optionTuple.productOption.productReference.version).pipe(
              map(optionProduct => {
                optionProduct = flattenProduct(optionProduct);

                return <QuantityPriceTuple>{ quantity: optionTuple.itemOption.quantity, price: optionProduct.configuration.getPortion(optionTuple.productOption.productReference.portionUid)?.price || 0 };
              })
            ));
          }

          if (optionTuple.itemOption.preparations?.length > 0) {
            tasks.push(...optionTuple.itemOption.preparations.map(itemPreparation => {
              const productPreparation = optionTuple.productOption.preparations?.find(x => isEqualUUID(x.uid, itemPreparation.preparationUid));

              if (productPreparation) {
                return calculatePreparation(itemPreparation, productPreparation).pipe(
                  map(preparationPrice => { return <QuantityPriceTuple>{ quantity: 1, price: preparationPrice }; })
                );
              }

              return of(<QuantityPriceTuple>{ quantity: 0, price: 0 });
            }));
          }

          if (optionTuple.itemOption.variations?.length > 0) {
            tasks.push(...optionTuple.itemOption.variations.map(itemVariation => {
              const productVariation = optionTuple.productOption.variations?.find(x => isEqualUUID(x.uid, itemVariation.variationUid));

              if (productVariation) {
                return calculateVariation(itemVariation, productVariation, productProvider).pipe(
                  map(variationPrice => { return <QuantityPriceTuple>{ quantity: 1, price: variationPrice }; })
                );
              }

              return of(<QuantityPriceTuple>{ quantity: 0, price: 0 });
            }));
          }

          return forkJoin(tasks).pipe(
            mergeMap(x => {
              const basePrice = x[0];
              const upcharges = x.slice(1);

              return of(<[OptionTuple, QuantityPriceTuple, QuantityPriceTuple[]]>[optionTuple, basePrice, upcharges]);
            })
          );
        })
      ).pipe(
        map((tuples: ([OptionTuple, QuantityPriceTuple, QuantityPriceTuple[]])[]) => {

          tuples = tuples.sort((x, y) => Math.sign(x[1].price - y[1].price));

          let price = new Decimal(0);
          let quantityIncludedGroupRemaining = productInclusionGroup?.maxTotalOptionQuantityIncluded || Number.MAX_SAFE_INTEGER;
          for (let i = 0; i < tuples.length; i++) {
            const tuple = tuples[i];
            let optionTuple = tuple[0];
            let basePriceTuple = tuple[1];
            let upchargePriceTuples = tuple[2];

            const optionQuantity = basePriceTuple.quantity;
            const optionMaxIncludedQuantity = optionTuple.productOption.maxIncludedQuantity || 0;
            const groupMaxIncludedQuantity = productInclusionGroup?.maxTotalOptionQuantityIncluded || 0;

            if (groupMaxIncludedQuantity > 0) {
              let quantityAllocatedToGroup = 0;

              if (optionMaxIncludedQuantity > 0) {
                quantityAllocatedToGroup = optionQuantity > optionMaxIncludedQuantity ? optionMaxIncludedQuantity : optionQuantity;
              } else {
                quantityAllocatedToGroup = optionQuantity;
              }

              if (optionQuantity > quantityAllocatedToGroup) {
                // Take overrage price here but don't include extras in any group calculations
                const optionOverrageQuantity = optionQuantity - quantityAllocatedToGroup;
                price = price.plus(new Decimal(optionOverrageQuantity).times(basePriceTuple.price));
              }

              let quantityForInclusionGroupOverrage = quantityAllocatedToGroup > quantityIncludedGroupRemaining ? quantityAllocatedToGroup - quantityIncludedGroupRemaining : 0;

              quantityIncludedGroupRemaining = quantityIncludedGroupRemaining - basePriceTuple.quantity;
              if (quantityIncludedGroupRemaining < 0) {
                quantityIncludedGroupRemaining = 0;
              }

              if (quantityForInclusionGroupOverrage > 0) {
                price = price.plus(new Decimal(quantityForInclusionGroupOverrage).times(basePriceTuple.price));
              }
            } else {
              const optionOverrageQuantity = optionQuantity - optionMaxIncludedQuantity;
              if (optionOverrageQuantity > 0) {
                price = price.plus(new Decimal(optionOverrageQuantity).times(basePriceTuple.price));
              }
            }

            const upchargeAmount = upchargePriceTuples?.map(x => new Decimal(x.quantity).times(x.price)).reduce((x, y) => x.plus(y), new Decimal(0)) || new Decimal(0);
            price = price.plus(upchargeAmount);
          }

          return price.toNumber();
        })
      )
    }),
  );
}

export function calculatePreparation(itemPreparation: EditableTransactionItemConfigurationPreparation, productPreparation: ProductConfigurationPreparation): Observable<number> {

  if (productPreparation) {
    const productOption = productPreparation.options.find(x => isEqualUUID(x.uid, itemPreparation.optionUid));
    if (productOption) {
      return of(productOption.additionalPrice || 0);
    }
  }

  return of(0);
}

export function calculateVariation(itemVariation: EditableTransactionItemConfigurationVariation, productVariation: ProductConfigurationVariation, productProvider: ProductProvider): Observable<number> {

  if (productVariation) {
    const productOption = productVariation.options.find(x => isEqualUUID(x.uid, itemVariation.optionUid));

    if (productOption?.priceOverride) {
      return of(productOption.priceOverride || 0);
    }

    return productProvider.getOneCached$(productOption.productReference.uid, productOption.productReference.version).pipe(
      map(optionProduct => {
        optionProduct = flattenProduct(optionProduct);

        return optionProduct.configuration.getPortion(productOption.productReference.portionUid)?.price || 0;
      })
    );
  }

  return of(0);
}

export function calculateAddOnTotal(itemAddOn: EditableTransactionItemConfigurationAddOn, productProvider: ProductProvider): Observable<number> {

  if (itemAddOn.item.getEachAmount()) {
    return of(new Decimal(itemAddOn.item.quantity.value).times(itemAddOn.item.getEachAmount()).toNumber());
  }

  return calculateTotal(itemAddOn.item, productProvider);
};
