import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { UpdateOrderItemFullfilmentModalData, UpdateOrderItemFullfilmentModalResult } from "./update-order-item-fullfilment-modal.provider";

@Component({
  selector: 'app-update-order-item-fullfilment-modal',
  templateUrl: './update-order-item-fullfilment-modal.component.html',
  styleUrls: ['./update-order-item-fullfilment-modal.component.scss']
})
export class UpdateOrderItemFullfilmentModalComponent {

  public form: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<UpdateOrderItemFullfilmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateOrderItemFullfilmentModalData
  ) {
    this.form = new UntypedFormGroup({
      fulfilledQuantity: new UntypedFormControl('', { validators: [Validators.required, Validators.min(0), Validators.max(this.data.orderQuantity)], updateOn: 'blur' })
    });
  }

  fulfillAll() {

    this.form.get('fulfilledQuantity').setValue(this.data.orderQuantity - this.data.fulfilledQuantity);
    this.form.markAsDirty();

    this.commit();
  }

  commit() {

    let value = this.form.dirty ? this.form.get('fulfilledQuantity').value : null;
    this.dialogRef.close(<UpdateOrderItemFullfilmentModalResult>{
      orderUid: this.data.orderUid,
      itemUid: this.data.itemUid,
      fulfilledQuantity: value
    });
  }

  cancel() {

    this.dialogRef.close();
  }
}
