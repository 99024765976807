import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { IPagedDataSource } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { CardTransaction2StatusKeys } from "../../../keys";
import { CardTransaction } from "../../../models/card-transaction";
import { CardTransaction2Service } from "../../../services/card-transaction.service";
import { CardTransactionViewOptions } from "../../../view-factory";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";

@Component({
  selector: 'app-card-transactions-table',
  templateUrl: './card-transactions-table.component.html',
  styleUrls: ['./card-transactions-table.component.scss']
})
export class CardTransactionsTableComponent {

  @Input() public dataSource: CardTransactionDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }
}

export class CardTransactionModel {

  uid: string;
  ownerUid: string;
  orderNumber: string;
  processorReferenceId: string;
  batchId: string;
  name: string;
  lastFour: string;
  amount: number;
  tip: number;
  cardTransactionStatusUid: string;
  createdDateTimeUtc: Date;
  modifiedDateTimeUtc: Date;

  canVoid: boolean;
  canReverse: boolean;
  canCapture: boolean;
  canRefund: boolean;
}

export class CardTransactionDataSource extends DataSource<CardTransactionModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<CardTransactionModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<CardTransactionModel[]>([]);
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private cardTransactionService: CardTransaction2Service,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<CardTransactionModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<CardTransactionModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(storeFrontUid: string, statusUids: string[], paginationInput: PaginationInput, viewOptions: CardTransactionViewOptions) {

    this.loadingSubject.next(true);

    this.cardTransactionService.search(null, [storeFrontUid], null, statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        let cardTransactions = page.edges.map(x => x.node).map(order => this.mergeCardTransactionModel(new CardTransactionModel(), order));


        return <[PageInfo, CardTransactionModel[]]>[page.pageInfo, cardTransactions];
      }),
      catchError(() => of(<[PageInfo, CardTransactionModel[]]>[null, []])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  public void(cardTransaction: CardTransactionModel): Observable<CardTransactionModel> {

    return this.cardTransactionService.void(cardTransaction.uid).pipe(
      map(x => {
        return this.mergeCardTransactionModel(cardTransaction, x);
      })
    );
  }

  public reversal(cardTransaction: CardTransactionModel): Observable<CardTransactionModel> {

    return this.cardTransactionService.reversal(cardTransaction.uid).pipe(
      map(x => {
        return this.mergeCardTransactionModel(cardTransaction, x);
      })
    );
  }

  public capture(cardTransaction: CardTransactionModel): Observable<CardTransactionModel> {

    return this.cardTransactionService.capture(cardTransaction.uid).pipe(
      map(x => {
        return this.mergeCardTransactionModel(cardTransaction, x);
      })
    );
  }

  public refund(cardTransaction: CardTransactionModel): Observable<CardTransactionModel> {

    return this.cardTransactionService.refund(cardTransaction.uid).pipe(
      map(x => {
        return this.mergeCardTransactionModel(cardTransaction, x);
      })
    );
  }

  private mergeCardTransactionModel(model: CardTransactionModel, cardTransaction: CardTransaction) {

    let payment = cardTransaction.order ? cardTransaction.order.payments.find(x => x.cardTransactionUid == cardTransaction.uid) : null;

    model.uid = cardTransaction.uid;
    model.ownerUid = cardTransaction.ownerUid;
    model.orderNumber = cardTransaction.order ? cardTransaction.order.number : null;
    model.processorReferenceId = cardTransaction.processorReferenceId;
    model.batchId = cardTransaction.batchId;
    model.name = cardTransaction.name;
    model.lastFour = cardTransaction.lastFour;
    model.amount = cardTransaction.amount;
    model.tip = payment ? payment.tip : cardTransaction.tip;
    model.createdDateTimeUtc = cardTransaction.createdDateTimeUtc;
    model.modifiedDateTimeUtc = cardTransaction.modifiedDateTimeUtc;
    model.cardTransactionStatusUid = cardTransaction.cardTransactionStatusUid;

    this.evaluateEnablement(model);


    return model;
  }

  private evaluateEnablement(cardTransaction: CardTransactionModel): CardTransactionModel {

    let cardTransactionStatusUid = cardTransaction.cardTransactionStatusUid.toUpperCase();

    cardTransaction.canVoid = cardTransactionStatusUid == CardTransaction2StatusKeys.Captured.toUpperCase();
    cardTransaction.canReverse = cardTransactionStatusUid == CardTransaction2StatusKeys.Authorized.toUpperCase();
    cardTransaction.canCapture = cardTransactionStatusUid == CardTransaction2StatusKeys.Authorized.toUpperCase();
    cardTransaction.canRefund = cardTransactionStatusUid == CardTransaction2StatusKeys.Captured.toUpperCase();

    return cardTransaction;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEdit = selected.length == 1;
    this._canBulkDelete = selected.length == 1;
  }
}
