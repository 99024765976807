
import { Injectable } from '@angular/core';
import { AuthHeaderProvider } from 'core';
import { AuthMemberProvider } from '../providers/auth-member.provider';
import { Client } from '../models/client';
import { ClientService } from '../services/client.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AuthClientProvider {

  public clients$: Observable<Client[]>;
  private authClientsSubject = new BehaviorSubject<Client[]>(null);

  constructor(
    authMemberProvider: AuthMemberProvider,
    authHeaderProvider: AuthHeaderProvider,
    clientService: ClientService
  ) {
    this.clients$ = this.authClientsSubject.asObservable();

    authMemberProvider.authMember$.subscribe(authMember => {
      if (authMember) {
        clientService.getByMemberUid(authMember.uid, authHeaderProvider).subscribe(clients => {
          this.authClientsSubject.next(clients);
        });
      } else {
        this.authClientsSubject.next(null);
      }
    });
  }
}
