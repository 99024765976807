<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="dataSource.selection.isMultipleSelection()" (change)="$event ? dataSource.masterToggle() : null" [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row) : null" [checked]="dataSource.selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="ownerUid">
    <th mat-header-cell *matHeaderCellDef color="accent">Client</th>
    <td mat-cell *matCellDef="let cardTransaction">
      {{ cardTransaction.ownerUid }}
    </td>
  </ng-container>

  <ng-container matColumnDef="orderNumber">
    <th mat-header-cell *matHeaderCellDef color="accent">Order #</th>
    <td mat-cell *matCellDef="let cardTransaction">
      {{ cardTransaction.orderNumber }}
    </td>
  </ng-container>

  <ng-container matColumnDef="processorReferenceId">
    <th mat-header-cell *matHeaderCellDef color="accent">Processor Ref</th>
    <td mat-cell *matCellDef="let cardTransaction">
      {{ cardTransaction.processorReferenceId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="batchId">
    <th mat-header-cell *matHeaderCellDef color="accent">Batch Id</th>
    <td mat-cell *matCellDef="let cardTransaction">
      {{ cardTransaction.batchId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef color="accent">Name</th>
    <td mat-cell *matCellDef="let cardTransaction">
      {{ cardTransaction.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastFour">
    <th mat-header-cell *matHeaderCellDef color="accent">Last Four</th>
    <td mat-cell *matCellDef="let cardTransaction">
      {{ cardTransaction.lastFour }}
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef color="accent">Amount</th>
    <td mat-cell *matCellDef="let cardTransaction">
      {{ cardTransaction.amount | currency:'USD':'symbol':'1.2-2' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="tip">
    <th mat-header-cell *matHeaderCellDef color="accent">Tip</th>
    <td mat-cell *matCellDef="let cardTransaction">
      {{ cardTransaction.tip | currency:'USD':'symbol':'1.2-2' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="createdDateTimeUtc">
    <th mat-header-cell *matHeaderCellDef color="accent">Created</th>
    <td mat-cell *matCellDef="let cardTransaction">
      {{ cardTransaction.createdDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="modifiedDateTimeUtc">
    <th mat-header-cell *matHeaderCellDef color="accent">Modified</th>
    <td mat-cell *matCellDef="let cardTransaction">
      {{ cardTransaction.modifiedDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="cardTransactionStatusUid">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let cardTransaction">
      {{ cardTransaction.cardTransactionStatusUid | cardTransaction2Status }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>

</table>
