import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { isEqualUUID, PaymentMethodKeys } from "core";
import { DrawerProvider } from "../../../providers/drawer.provider";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { Transaction } from "downtown-transaction";
import { DrawerCredit, DrawerDebit } from '../../../models/drawer';

@Component({
  selector: 'app-drawer-exchanges-table',
  templateUrl: './drawer-exchanges-table.component.html',
  styleUrls: ['./drawer-exchanges-table.component.scss']
})
export class DrawerExchangesTableComponent {

  @Input() public dataSource: DrawerExchangesDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }

  viewTransaction(uid: string) {

    console.log(uid);
  }

  updateTip(exchange: DrawerExchangeModel) {

  }
}

export class DrawerExchangeModel {

  exchange: DrawerCredit | DrawerDebit;

  creditAmount: number;
  debitAmount: number;

  canUpdateTip: boolean;
}

export class DrawerExchangesDataSource extends DataSource<DrawerExchangeModel> {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public selection: SelectionModel<DrawerExchangeModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private dataSubject = new BehaviorSubject<DrawerExchangeModel[]>([]);

  constructor(
    private drawerProvider: DrawerProvider,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();

    this.selection = new SelectionModel<DrawerExchangeModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  connect(collectionViewer: CollectionViewer): Observable<DrawerExchangeModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(drawerUid: string) {

    this.loadingSubject.next(true);

    this.drawerProvider.getOne$(drawerUid).pipe(
      map(drawer => {
        this.totalCountSubject.next(drawer.credits.length + drawer.debits.length);

        let creditExchanges = drawer.credits.map(x => {
          return <DrawerExchangeModel>{
            exchange: x,
            creditAmount: x.amount
          };
        })
        let debitExchanges = drawer.debits.map(x => {
          return <DrawerExchangeModel>{
            exchange: x,
            debitAmount: x.amount
          };
        })
        
        let exchanges = creditExchanges.concat(debitExchanges).sort((left, right) => {
          if (left.exchange.dateTimeUtc > right.exchange.dateTimeUtc) {
            return -1;
          } else if (left.exchange.dateTimeUtc < right.exchange.dateTimeUtc) {
            return 1;
          } else {
            return 0;
          }
        });

        exchanges.forEach(x => this.evaluateEnablement(x));

        return <[DrawerExchangeModel[]]>[exchanges];
      }),
      catchError(() => of(<[DrawerExchangeModel[]]>[[]])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([data]) => {
      this.dataSubject.next(data);
    });
  }

  private evaluateEnablement(exchange: DrawerExchangeModel): DrawerExchangeModel {

    let paymentMethodUid = exchange.exchange.paymentMethodUid.toUpperCase();

    exchange.canUpdateTip = isEqualUUID(paymentMethodUid, PaymentMethodKeys.Card);

    return exchange;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

  }
}
