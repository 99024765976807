import { Pipe, PipeTransform } from '@angular/core';
import { MemberStatusKeys } from '../keys';
import { Member } from '../models/member';

@Pipe({
  name: 'memberStatus'
})
export class MemberStatusPipe implements PipeTransform {

  private memberStatusMap = new Map<string, string>([
    [MemberStatusKeys.Active.toUpperCase(), 'Active'],
    [MemberStatusKeys.Inactive.toUpperCase(), 'Inactive'],
    [MemberStatusKeys.Deleted.toUpperCase(), 'Deleted']
  ]);

  constructor(
  ) {
  }

  transform(value: Member | string): string {

    if (typeof (value) === 'string') {
      return value ? this.memberStatusMap.get(value.toUpperCase()) : null;
    } else {
      return value ? this.memberStatusMap.get(value.memberStatusUid.toUpperCase()) : null;
    }
  }
}
