<div *ngIf="!context.readOnly || (context.readOnly && inclusionGroup.options?.length > 0)">
  {{ getInclusionGroupDisplay(inclusionGroup.inclusionGroupUid) }}

  <div *ngIf="inclusionGroup.options == null || inclusionGroup.options.length == 0" style="margin-left: 10%;">
    <!-- Select... -->
    <div style="color: green;">{{ getInclusionGroupSelectDisplay() }}</div>
  </div>

  <div *ngFor="let option of inclusionGroup.options" style="margin-left: 10%;">

    <div class="option" (click)="context.select(option)" [ngClass]="{ 'selected': context.selection == option }">

      <div class="display-line">
        <span *ngIf="option.quantity == 0">** NO {{ getInclusionGroupOptionDisplay(inclusionGroup.inclusionGroupUid, option.optionUid, option.isSubstitution) | async }} **</span>
        <span *ngIf="option.quantity > 0">{{ option.quantity }} - {{ getInclusionGroupOptionDisplay(inclusionGroup.inclusionGroupUid, option.optionUid, option.isSubstitution) | async }}</span>

        <span *ngIf="getAssignedPreparations(option).length > 0">
          &nbsp;(
          <span *ngFor="let preparation of getAssignedPreparations(option); let last = last">
            {{ getInclusionGroupOptionPreparationOptionDisplay(inclusionGroup.inclusionGroupUid, option.optionUid, preparation.preparationUid, preparation.optionUid) }}
            <span *ngIf="!last">/</span>
          </span>
          )
        </span>

        <span *ngIf="option.variations && option.variations.length > 0">
          &nbsp;+
          <span *ngFor="let variation of option.variations; let last = last">
            {{ getInclusionGroupOptionVariationOptionDisplay(inclusionGroup.inclusionGroupUid, option.optionUid, variation.variationUid, variation.optionUid) | async }}
            <span *ngIf="!last">/</span>
          </span>
        </span>

        <button *ngIf="!context.readOnly && (context.getInclusionGroupOptionPreparations(option.optionUid)?.length > 0 || context.getInclusionGroupOptionVariations(option.optionUid)?.length > 0)" mat-flat-button color="primary" style="height: 32px;">
          options
        </button>

      </div>

      <!-- Context Menu -->
      <div *ngIf="!context.readOnly && context.selection == option" [@slideIn] [@slideOut] class="context-menu">
        <div class="left">
          <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
        </div>
        <div class="center">
          <ng-container *ngFor="let preparation of context.getInclusionGroupOptionPreparations(option.optionUid)">
            <button mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="editPreparation(inclusionGroup.inclusionGroupUid, option.optionUid, preparation.uid, option.isSubstitution)" click-stop-propagation>
              {{ getInclusionGroupOptionPreparationDisplay(inclusionGroup.inclusionGroupUid, option.optionUid, preparation.uid) }}
            </button>
          </ng-container>
          <ng-container *ngFor="let variation of context.getInclusionGroupOptionVariations(option.optionUid)">
            <button mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="editVariation(inclusionGroup.inclusionGroupUid, option.optionUid, variation.uid, option.isSubstitution)" click-stop-propagation>
              {{ getInclusionGroupOptionVariationDisplay(inclusionGroup.inclusionGroupUid, option.optionUid, variation.uid) }}
            </button>
          </ng-container>
        </div>
        <div class="right">
          <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
        </div>
      </div>
    </div>
  </div>
</div>