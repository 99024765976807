<div style="height: 100%; display: flex; flex-direction: column; overflow-y: hidden;">

    <app-page-header>
  
      <ng-container left>
        <button mat-flat-button style="width: 4.1666vw;" (click)="navigateHome()">
          <mat-icon style="font-weight: bold;">home</mat-icon>
        </button>
        <button mat-flat-button style="width: 4.1666vw; margin-left: 4px;" (click)="navigateBack()">
          <mat-icon style="font-weight: bold;">arrow_back</mat-icon>
        </button>
      </ng-container>
  
      <ng-container middle>{{title}}</ng-container>
  
    </app-page-header>
  
    <div style="flex: 1; overflow-y: hidden;">
      <core-settings [ownerUids]="ownerUids"></core-settings>
    </div>
  
  </div>