import { Component, Input, Output, EventEmitter } from "@angular/core";
import { EditableTransactionItemConfigurationPortion, EditableTransactionItemConfigurationInclusionGroupOption, EditableTransactionItemConfigurationAddOn } from '../../../models/editable-transaction/editable-transaction';
import { IProductConfiguratorDetail } from './iitem-configurator-detail.provider';
import { ItemConfiguratorContext } from "../item-configurator-context";
import { HtmlComponent } from "../../../directives";

@HtmlComponent({ map: ['transaction-item-configurator-detail'] })
@Component({
  selector: 'transaction-item-configurator-detail',
  templateUrl: './item-configurator-detail.component.html',
  styleUrls: ['./item-configurator-detail.component.scss']
})
export class ItemConfiguratorDetailComponent implements IProductConfiguratorDetail {

  @Input() public context: ItemConfiguratorContext;
  @Output() public configurationChanged = new EventEmitter();

  constructor(
  ) {
  }

  ngOnInit() {

  }

  public updateDisplay() {

    this.context.selection = null;
  }

  handleConfigurationChanged() {

    this.configurationChanged.emit();
  }

  handleSelected(value: EditableTransactionItemConfigurationPortion | EditableTransactionItemConfigurationInclusionGroupOption | EditableTransactionItemConfigurationAddOn) {

    this.context.select(value);
  }
}
