import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { IPagedDataSource } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { DepartmentService, DepartmentViewOptions } from "../../services/department.service";
import { TenantProvider } from 'core';

@Component({
  selector: 'app-departments-table',
  templateUrl: './departments-table.component.html',
  styleUrls: ['./departments-table.component.scss']
})
export class DepartmentsTableComponent {

  @Input() public dataSource: DepartmentDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }
}

export class DepartmentModel {

  uid: string;
  name: string;
  accountSettingUid: string;
  isSalesTaxIncluded: boolean;
  taxAccountSettingUid: string;
  departmentStatusUid: string;

  canEdit: boolean;
  canEnable: boolean;
  canDisable: boolean;
  canDelete: boolean;
}

export class DepartmentDataSource extends DataSource<DepartmentModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<DepartmentModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<DepartmentModel[]>([]);
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private departmentService: DepartmentService,
    private tenantProvider: TenantProvider,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<DepartmentModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<DepartmentModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(statusUids: string[], paginationInput: PaginationInput, viewOptions: DepartmentViewOptions) {

    this.loadingSubject.next(true);

    this.departmentService.search(this.tenantProvider.currentUid, statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        const productDepartments = page.edges
          .map(x => x.node)
          .filter(x => !!x)
          .map(x => {
            let productDepartmentModel = Object.assign(new DepartmentModel(), {
              uid: x.uid,
              name: x.name,
              accountSettingsUid: x.accountSettingUid,
              isSalesTaxIncluded: x.isSalesTaxIncluded,
              taxAccountSettingUid: x.taxAccountSettingUid,
              departmentStatusUid: x.departmentStatusUid,
            });

            this.evaluateEnablement(productDepartmentModel);

            return productDepartmentModel;
          });

        return <[PageInfo, DepartmentModel[]]>[page.pageInfo, productDepartments];
      }),
      catchError(() => of(<[PageInfo, DepartmentModel[]]>[null, []])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  public enable(department: DepartmentModel): Observable<DepartmentModel> {

    return this.departmentService.enable(department.uid).pipe(
      map(x => {
        department.departmentStatusUid = x.departmentStatusUid;

        return this.evaluateEnablement(department);
      })
    );
  }

  public disable(department: DepartmentModel): Observable<DepartmentModel> {

    return this.departmentService.disable(department.uid).pipe(
      map(x => {
        department.departmentStatusUid = x.departmentStatusUid;

        return this.evaluateEnablement(department);
      })
    );
  }

  public delete(department: DepartmentModel): Observable<DepartmentModel> {

    return this.departmentService.delete(department.uid).pipe(
      map(x => {
        department.departmentStatusUid = x.departmentStatusUid;

        return this.evaluateEnablement(department);
      })
    );
  }

  private evaluateEnablement(department: DepartmentModel): DepartmentModel {

    let departmentStatusUid = department.departmentStatusUid.toUpperCase();

    department.canEdit = true;
    department.canDelete = true;

    return department;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEdit = selected.length == 1;
    this._canBulkDelete = selected.length == 1;
  }
}
