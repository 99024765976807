<app-page-header>

  <ng-container left>
    <button mat-flat-button style="width: 4.1666vw;" (click)="navigateHome()">
      <mat-icon style="font-weight: bold;">home</mat-icon>
    </button>
    <button mat-flat-button style="width: 4.1666vw; margin-left: 4px;" (click)="navigateBack()">
      <mat-icon style="font-weight: bold;">arrow_back</mat-icon>
    </button>
  </ng-container>

  <ng-container middle>{{title}}</ng-container>

</app-page-header>

<div class="container">
  <form [formGroup]="form" style="margin-top: 20px;">
    <div>
      <mat-form-field>
        <input matInput formControlName="startDateTimeUtc" [readonly] placeholder="Start Date">
        <button matSuffix mat-flat-button color="primary" (click)="editStartDate()">Select ...</button>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="endDateTimeUtc" [readonly] placeholder="End Date">
        <button matSuffix mat-flat-button color="primary" (click)="editEndDate()">Select ...</button>
      </mat-form-field>
    </div>
  </form>

  <div style="text-align: right;">
    <button mat-flat-button color="warn" style="margin-left: 1em;" (click)="cancel()">Cancel</button>
    <button mat-flat-button [disabled]="!this.form.dirty || !this.valid()" color="primary" style="margin-left: 1em;" (click)="save()">Save</button>
  </div>

</div>
