import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { SelectListModalProvider, getDescendentRouteSnapshot } from 'core';
import { ProductAndPortionModalProvider } from '../../../../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { ProductPreparationModalProvider, ProductPreparationModalData } from '../../../../../modals/product-preparation-modal/product-preparation-modal.provider';
import { ProductVariationModalProvider, ProductVariationModalData } from '../../../../../modals/product-variation-modal/product-variation-modal.provider';
import { ConfigurationComponentBase } from '../../../configuration-component-base';
import { buildProductPreparationForm, buildProductPreparationOptionForm, buildProductVariationForm, buildProductVariationOptionForm } from '../../../../../functions/configuration-form-functions';
import { ProductContext } from '../../../product-context';
import { MenuPlacement, ProductStatusKeys } from '../../../../../keys';
import { TenantProvider } from 'core';
import { ProductProvider } from '../../../../../providers';

@Component({
  selector: 'app-back-office-product-configuration-portion-general',
  templateUrl: './back-office-product-configuration-portion-general.component.html',
  styleUrls: ['./back-office-product-configuration-portion-general.component.scss']
})
export class BackOfficeProductConfigurationPortionGeneralComponent extends ConfigurationComponentBase {

  public form: UntypedFormGroup;
  public scope: string;
  public canLinkPreparations: boolean;
  public canLinkVariations: boolean;
  public productStatuses = [
    {
      uid: ProductStatusKeys.Active,
      name: 'Active'
    },
    {
      uid: ProductStatusKeys.Inactive,
      name: 'Inactive'
    }
  ];
  public menuPlacements = [
    { uid: MenuPlacement.Menu, display: 'Menu Item' },
    { uid: MenuPlacement.OffMenu, display: 'Off Menu Item' },
    { uid: MenuPlacement.NoSale, display: 'No Sale' },
  ];

  private _context: ProductContext;
  private destroyed$ = new Subject();

  constructor(
    private selectListModalProvider: SelectListModalProvider,
    private productPreparationModalProvider: ProductPreparationModalProvider,
    private productVariationModalProvider: ProductVariationModalProvider,
    private router: Router,
    activatedRoute: ActivatedRoute,
    tenantProvider: TenantProvider,
    productProvider: ProductProvider,
    productAndPortionModalProvider: ProductAndPortionModalProvider,
  ) {
    super(activatedRoute, tenantProvider, productProvider, productAndPortionModalProvider);

    this.scope = this.getScope(this.activatedRoute);
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public set context(context: ProductContext) {

    this._context = context;

    this.assignForm();

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      if (x instanceof NavigationEnd) { this.assignForm(); }
    });
  }

  public get preparationsFormGroupArray() : UntypedFormGroup[] {

    return (this.form.get('preparations') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  public get variationsFormGroupArray() : UntypedFormGroup[] {

    return (this.form.get('variations') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  private assignForm() {

    var route = getDescendentRouteSnapshot(this.activatedRoute);
    var portionUid = route.params['portionUid'];

    let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
    let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');

    this.form = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid.toLowerCase());

    this.updateUI();
  }

  public get context(): ProductContext {

    return this._context;
  }

  public newPreparation() {

    const formGroup = buildProductPreparationForm(null);
    const preparations = <UntypedFormArray>this.form.get('preparations');

    preparations.push(formGroup);
    preparations.markAsDirty();

    this.editPreparation(formGroup);
  }

  public linkPreparation() {

    const preparations = super.getLinkableProductPreparationFormsForPortionForm(this.form).map(x => {
      const name = x.get('name').value;

      if (name) {
        return <ProductPreparationSelection>{ display: name, formGroup: x };
      } else {
        return <ProductPreparationSelection>{ display: 'Unknown', formGroup: x };
      }
    });

    this.selectListModalProvider.open({
      title: 'Select Product Preparation',
      options: preparations,
      displayFunc: item => item.display
    }).afterClosed().subscribe(preparationResult => {
      if (preparationResult) {
        const options = (<UntypedFormArray>(<ProductPreparationSelection>preparationResult).formGroup.get('options')).controls.map(x => {
          const name = x.get('name').value;

          if (name) {
            return <ProductPreparationOptionSelection>{ display: name, formGroup: x };
          } else {
            return <ProductPreparationOptionSelection>{ display: 'Unknown', formGroup: x };
          }
        });

        this.selectListModalProvider.open({
          title: 'Select Preparation Options to link',
          options: options,
          allowMultiple: true,
          displayFunc: item => item.display
        }).afterClosed().subscribe(optionsResult => {
          if (optionsResult) {
            var preparationsFormArray = <UntypedFormArray>this.form.get('preparations');

            const preparationFormGroup = buildProductPreparationForm(null);
            preparationFormGroup.get('uid').setValue((<ProductPreparationSelection>preparationResult).formGroup.get('uid').value);

            var optionsFormArray = <UntypedFormArray>preparationFormGroup.get('options');
            (<ProductPreparationOptionSelection[]>optionsResult).forEach(option => {
              const preparationOptionFormGroup = buildProductPreparationOptionForm(null);
              preparationOptionFormGroup.get('uid').setValue(option.formGroup.get('uid').value);

              optionsFormArray.push(preparationOptionFormGroup);
              optionsFormArray.markAsDirty();
            });

            preparationsFormArray.push(preparationFormGroup);
            preparationsFormArray.markAsDirty();

            this.updateUI();
          }
        });
      }
    });
  }

  public editPreparation(form: UntypedFormGroup) {

    this.productPreparationModalProvider.open(<ProductPreparationModalData>{ scope: this.scope, preparationForm: form }).afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
      }
    });
  }

  public dropPreparation(event: CdkDragDrop<string[]>) {

    if (event.previousIndex != event.currentIndex) {
      var preparations = <UntypedFormArray>this.form.get('preparations');
      var item = preparations.controls[event.previousIndex];
      preparations.removeAt(event.previousIndex);

      preparations.insert(event.currentIndex, item);
    }
  }

  public deletePreparation(form: UntypedFormGroup) {

    var preparations = <UntypedFormArray>this.form.get('preparations');
    preparations.removeAt(preparations.controls.indexOf(form));

    this.updateUI();
  }

  public newVariation() {

    const formGroup = buildProductVariationForm(null);
    const variations = <UntypedFormArray>this.form.get('variations');

    variations.push(formGroup);
    variations.markAsDirty();

    this.editVariation(formGroup);
  }

  public linkVariation() {

    const variations = super.getLinkableProductVariationFormsForPortionForm(this.form).map(x => {
      const name = x.get('name').value;

      if (name) {
        return <ProductVariationSelection>{ display: name, formGroup: x };
      } else {
        return <ProductVariationSelection>{ display: 'Unknown', formGroup: x };
      }
    });

    this.selectListModalProvider.open({
      title: 'Select Product Variation',
      options: variations,
      displayFunc: item => item.display
    }).afterClosed().subscribe(variationResult => {
      if (variationResult) {
        forkJoin((<UntypedFormArray>(<ProductVariationSelection>variationResult).formGroup.get('options')).controls.map(x => {
          return this.getProductPortionLabel(x.get('productUid').value, x.get('portionUid').value, x.get('alias').value).pipe(
            map(y => { return <ProductVariationOptionSelection>{ display: y, formGroup: x } })
          );
        })).subscribe(options => {
          this.selectListModalProvider.open({
            title: 'Select Variation Options to link',
            options: options,
            allowMultiple: true,
            displayFunc: item => item.display
          }).afterClosed().subscribe(optionsResult => {
            if (optionsResult) {
              var variationsFormArray = <UntypedFormArray>this.form.get('variations');

              const variationFormGroup = buildProductVariationForm(null);
              variationFormGroup.get('uid').setValue((<ProductVariationSelection>variationResult).formGroup.get('uid').value);

              var optionsFormArray = <UntypedFormArray>variationFormGroup.get('options');
              (<ProductVariationOptionSelection[]>optionsResult).forEach(option => {
                const variationOptionFormGroup = buildProductVariationOptionForm(null);
                variationOptionFormGroup.get('uid').setValue(option.formGroup.get('uid').value);

                optionsFormArray.push(variationOptionFormGroup);
                optionsFormArray.markAsDirty();
              });

              variationsFormArray.push(variationFormGroup);
              variationsFormArray.markAsDirty();

              this.updateUI();
            }
          });
        });
      }
    });
  }

  public editVariation(form: UntypedFormGroup) {

    this.productVariationModalProvider.open(<ProductVariationModalData>{ scope: this.scope, variationForm: form }).afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
      }
    });
  }

  public dropVariation(event: CdkDragDrop<string[]>) {

    if (event.previousIndex != event.currentIndex) {
      var variations = <UntypedFormArray>this.form.get('variations');
      var item = variations.controls[event.previousIndex];
      variations.removeAt(event.previousIndex);

      variations.insert(event.currentIndex, item);
    }
  }

  public deleteVariation(form: UntypedFormGroup) {

    var variations = <UntypedFormArray>this.form.get('variations');
    variations.removeAt(variations.controls.indexOf(form));

    this.updateUI();
  }

  private updateUI() {

    this.canLinkPreparations = super.getLinkableProductPreparationFormsForPortionForm(this.form).length > 0;
    this.canLinkVariations = super.getLinkableProductVariationFormsForPortionForm(this.form).length > 0;
  }
}

interface ProductPreparationSelection {

  display: string;
  formGroup: UntypedFormGroup;
}

interface ProductPreparationOptionSelection {

  display: string;
  formGroup: UntypedFormGroup;
}

interface ProductVariationSelection {

  display: string;
  formGroup: UntypedFormGroup;
}

interface ProductVariationOptionSelection {

  display: string;
  formGroup: UntypedFormGroup;
}
