import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SiteCardEntryModalComponent } from "./site-card-entry-modal.component";
import { CaptureTokenEventModel, CardSwipeEventModel } from "core";
import { CardEntryModalComponent, CardEntryModalProvider, ICardEntryModalData } from "pos-core";

@Injectable()
export class SiteCardEntryModalProvider extends CardEntryModalProvider {

  constructor(
    dialog: MatDialog
  ) {
    super(dialog);
  }

  public open(data: ICardEntryModalData): MatDialogRef<CardEntryModalComponent, CaptureTokenEventModel | CardSwipeEventModel> {

    return this.dialog.open<SiteCardEntryModalComponent, ICardEntryModalData, CaptureTokenEventModel | CardSwipeEventModel>(SiteCardEntryModalComponent, {
      width: '40vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

// export interface CardSwipeModalData {

//   title: string,
//   mode: CardSwipeMode,
//   initialValue: number,
//   allowEdit: Boolean
// }

// export const enum CardSwipeMode {
//   CaptureSwipe,
//   CaptureToken
// }

