import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProductSelectModalComponent } from "./product-select-modal.component";

@Injectable()
export class ProductSelectModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: ProductSelectModalData): MatDialogRef<ProductSelectModalComponent, ProductSelectModalResult> {

    return this.dialog.open<ProductSelectModalComponent, ProductSelectModalData, ProductSelectModalResult>(ProductSelectModalComponent, {
      height: '100vh',
      minWidth: '100vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface ProductSelectModalData {
  title: string;
  departmentUid: string;
  menuPlacement?: string;
}

export interface ProductSelectModalResult {

  productUid: string,
  productVersion: number,
  portionUid: string,
  eachAmount: number
}
