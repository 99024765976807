import { TransactionLine } from './transaction-line';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { TransactionEvent } from './transaction-event';
import { TransactionEventType } from './transaction-event-type';
import { TransactionSaleAdjustmentLine } from './transaction-sale-adjustment-line';
import { TransactionEditModel } from './transaction-edit-model';
import Decimal from 'decimal.js';

export class TransactionSaleLine extends TransactionLine {

  static commitConditions = <((x: TransactionSaleLine) => boolean)[]>[
    x => Math.abs(x.lineItemAdjustmentsList.reduce((a, b) => { return a + b.total.value; }, 0)) <= x.total.value,
    x => !x.previousValues || x.isChangedFrom(x.previousValues, x.getValues())
  ];

  departmentUid = new BehaviorSubject<string>(null);
  quantityText = new BehaviorSubject<string>(null);
  eachAmountText = new BehaviorSubject<string>(null);
  total = new BehaviorSubject<number>(null);

  private lineItemAdjustmentsList = <TransactionSaleAdjustmentLine[]>[];
  private subscriptions = new Map<TransactionSaleAdjustmentLine, Subscription[]>();

  public get adjustments(): TransactionSaleAdjustmentLine[] {
    return this.lineItemAdjustmentsList;
  }

  get typeName() { return 'TransactionSaleLine'; }

  constructor() {
    super();

    combineLatest([
      this.departmentUid,
      this.quantityText,
      this.eachAmountText]
    ).subscribe(x => {
      this.total.next(this.getQuantity() * this.getEachAmount());
      this.isValid.next(this.departmentUid.value != null && this.getQuantity() > 0 && this.getEachAmount() > 0);
    });

    combineLatest([
      this.isEditing$
    ]).subscribe(([isEditing]) => {
      if (isEditing) {
        this.previousValues = this.getValues();
      }

      this.canCancel.next(isEditing && this.lineItemAdjustmentsList.every(x => !x.isVoid.value)); // In edit state, is not already voided, and not changed if already committed
      this.canVoid.next(isEditing && this.uid && this.lineItemAdjustmentsList.every(x => !x.isVoid.value)); // In edit state, was committed, and is not already voided
      this.canAdjust.next(isEditing && Math.abs(this.lineItemAdjustmentsList.reduce((a, b) => { return a + b.total.value; }, 0)) < this.total.value); // In edit state, is not voided, and comp total is less than item total
    });
  }

  private getValues() {

    return {
      ['departmentUid']: this.departmentUid.value,
      ['quantityText']: this.quantityText.value,
      ['eachAmountText']: this.eachAmountText.value
    };
  }

  keyPressed(value: string) {

    let keyValue = value.toLowerCase();

    if (keyValue == "00") {
      this.keyPressed("0");
      this.keyPressed("0");
    } else if (keyValue == ".") {
      let eachAmount = this.eachAmountText.value;
      let decimalIndex = eachAmount.indexOf('.');

      let wholeDigits = eachAmount.substr(0, decimalIndex + 2).replace('.', '');
      let decimalDigits = eachAmount.substr(decimalIndex + 2);

      eachAmount = parseInt(wholeDigits + decimalDigits).toString() + ".";
      this.eachAmountText.next(eachAmount);

    } else if (keyValue == "back") {
      let amount = this.getEachAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        // Shift all digits right
        amount = Math.floor(amount * 10) / 100;

        if (amount == 0 && !this.isValid) {
          this.cancel();
        } else {
          this.eachAmountText.next(amount.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        }
      }
    } else if (keyValue == "enter") {
      let amount = this.getEachAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        this.commit();
      }
    } else if (keyValue == "clear") {
      let amount = this.getEachAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        this.clearAmount();
      }
    } else {
      let digit = parseInt(value);
      if (!Number.isNaN(digit)) {
        let textEachAmount = this.eachAmountText.value.concat(keyValue);

        let validAmount = new Decimal(textEachAmount);
        if (!validAmount.isNaN()) {
          let decimalIndex = textEachAmount.indexOf('.');
          if (decimalIndex > 0 && textEachAmount.length - decimalIndex > 3) {
            // Need to shift the decimal point
            textEachAmount = (new Decimal(textEachAmount.slice(0, decimalIndex + 2).replace('.', '')).toString()) + '.' + (textEachAmount.slice(decimalIndex + 2));
          }

          this.eachAmountText.next(textEachAmount);
        }
      }
    }
  }

  departmentPressed(departmentUid: string) {

    var value = this.departmentUid.value;
    if (value && value === departmentUid) {
      let quantity = new Decimal(this.quantityText.value);

      this.quantityText.next(quantity.plus(1).toString());
    } else {
      this.quantityText.next(new Decimal(1).toString());
    }

    this.departmentUid.next(departmentUid);
  }

  cancel() {

    if (this.previousValues) {
      // Revert to previous values
      this.departmentUid.next(this.previousValues['departmentUid']);
      this.eachAmountText.next(this.previousValues['eachAmountText']);
      this.quantityText.next(this.previousValues['quantityText']);
    }

    this.previousValues = null;
    this.isEditing$.next(false);

    this.onEvent.next(new TransactionEvent(this, TransactionEventType.Cancelled, null));
  }

  void() {

    if (this.previousValues) {
      // Revert to previous values
      this.departmentUid.next(this.previousValues['departmentUid']);
      this.eachAmountText.next(this.previousValues['eachAmountText']);
      this.quantityText.next(this.previousValues['quantityText']);
    }

    this.previousValues = null;
    this.isEditing$.next(false);

    this.onEvent.next(new TransactionEvent(this, TransactionEventType.Voided, null));
  }

  adjust() {
    this.onEvent.next(new TransactionEvent(this, TransactionEventType.Adjusting, null));
  }

  commit() {

    // Set default department when missing
    //TODO: Make this data driven
    if (this.departmentUid.value == null) {
      this.departmentPressed('6F6BD33F-8C14-42FB-B437-1BE40F629041');
    }

    if (TransactionSaleLine.commitConditions.every(x => x(this))) {
      this.isEditing$.next(false);
      this.previousValues = null;

      this.onEvent.next(new TransactionEvent(this, TransactionEventType.Committed, null));
    } else {
      // Uncommittable, cancel instead of commit
      this.cancel();
    }
  }

  clearAmount(): TransactionSaleLine {

    this.eachAmountText.next((0).toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));

    return this;
  }

  public getQuantity(): number {

    return new Decimal(this.quantityText.value).toNumber();
  }

  public getEachAmount(): number {

    return new Decimal(this.eachAmountText.value).toNumber();
  }

  public createAdjustment(isVoid: boolean): TransactionSaleAdjustmentLine {

    let adjustment = new TransactionSaleAdjustmentLine();
    adjustment.saleLine = this;
    adjustment.description = isVoid ? 'Void' : 'Comp';
    adjustment.quantityText.next('1');
    adjustment.eachAmountText.next(new Decimal(0).toFixed(2));
    adjustment.isVoid.next(isVoid);
    adjustment.isCompensated.next(!isVoid);

    return adjustment;
  }

  public addAdjustment(transactionEntry: TransactionEditModel, transactionSaleAdjustmentLine: TransactionSaleAdjustmentLine) {

    // If adjustment is a void, remove all other adjustments
    if (transactionSaleAdjustmentLine.isVoid.value) {
      this.lineItemAdjustmentsList.forEach(adjustment => {
        this.removeAdjustment(adjustment);
      });
    }

    this.lineItemAdjustmentsList.push(transactionSaleAdjustmentLine);

    let subscriptions = new Array<Subscription>();

    subscriptions.push(transactionSaleAdjustmentLine.total.subscribe(x => {
      transactionEntry.updateOrderTotal();
    }));
    // subscriptions.push(transactionSaleAdjustmentLine.isEditing.subscribe(isEditing => {
    //     if (isEditing) {
    //         transactionEntry.setCurrentLineItem(transactionSaleAdjustmentLine);
    //     } else {
    //         transactionEntry.setCurrentLineItem(null);
    //     }
    // }));
    subscriptions.push(transactionSaleAdjustmentLine.onEvent.subscribe((event: TransactionEvent) => {
      if (event) {
        console.log('onEvent TransactionSaleAdjustmentLine');

        // Perform any local handling
        // if (event.eventType == TransactionEventType.Cancelling) {
        //     transactionEntry.setCurrentLineItem(null);
        // }

        // Bubble up
        this.onEvent.next(event);
      }
    }));

    this.subscriptions.set(transactionSaleAdjustmentLine, subscriptions);

    return transactionSaleAdjustmentLine;
  }

  public removeAdjustment(transactionSaleAdjustmentLine: TransactionSaleAdjustmentLine) {

    this.lineItemAdjustmentsList.splice(this.lineItemAdjustmentsList.indexOf(transactionSaleAdjustmentLine), 1);

    let subscriptions = this.subscriptions.get(transactionSaleAdjustmentLine);
    subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
