import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { IPagedDataSource, Page, Setting, SettingService, SettingViewOptions } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";

@Component({
  selector: 'app-settings-table',
  templateUrl: './settings-table.component.html',
  styleUrls: ['./settings-table.component.scss']
})
export class SettingsTableComponent {

  @Input() public dataSource: SettingDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }
}

export class SettingModel {

  uid: string;
  type: string;
  name: string;
  value: string;

  canEdit: boolean;
  canDelete: boolean;
}

export class SettingDataSource extends DataSource<SettingModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<SettingModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<SettingModel[]>([]);
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private settingService: SettingService
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<SettingModel>(false, [], true);
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<SettingModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(viewOptions: SettingViewOptions = SettingService.SettingFullView) {

    this.loadingSubject.next(true);

    this.settingService.list().pipe(
      map(list => {
        const page = <Page<Setting>>{
          totalCount: list.length,
          pageInfo: <PageInfo>{ firstPage: 0, lastPage: 0, thisPage: 0 }
        };

        this.totalCountSubject.next(page.totalCount);

        const settings = list.map(x => {
          let settingModel = Object.assign(new SettingModel(), {
            uid: x.uid,
            type: x.type,
            name: x.name,
            value: x.value
          });

          return settingModel;
        });

        return <[PageInfo, SettingModel[]]>[page.pageInfo, settings];
      }),
      catchError(() => of(<[PageInfo, SettingModel[]]>[null, []])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }
}
