import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditableTransaction } from "../../models/editable-transaction/editable-transaction";
import { Subject } from "rxjs";
import { TransactionViewModalComponent } from "./transaction-view-modal.component";

@Injectable()
export class TransactionViewModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: TransactionViewModalData): MatDialogRef<TransactionViewModalComponent> {

    return this.dialog.open(TransactionViewModalComponent, {
      width: '100%',
      height: '100%',
      minWidth: '100%',
      minHeight: '100%',
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface TransactionViewModalData {

  transactionUids: string[];
  commands: TransactionViewCommand[];
}

export interface TransactionViewCommand {

  display: string;
  action: (editableTransaction: EditableTransaction) => void;
  closeOnAction: boolean;
}
