<div *ngIf="departments" style="height: 100%; display: flex; flex-direction: column;">

  <div>
    <div style="flex: 1 1 auto; display: flex; justify-content: center; align-items: center;">
      <h2 mat-dialog-title style="font-size: 42px; font-weight: bold; line-height: 42px;">{{ title }}</h2>
    </div>

  </div>

  <div style="flex: 1; overflow-y: auto;">
    <div *ngFor="let department of departments" style="margin-top: 5px;">
      <div style="font-size: 2em;">{{ department.name }}</div>

      <div style="display:flex;">
        <app-pos-button *ngFor="let category of department.categories" (click)="selectCategory(category)" [styles]="{ backgroundColor: 'transparent', border: '4px solid #b9a246' }">
          <div>{{ category.name }}</div>
        </app-pos-button>
      </div>

    </div>
  </div>

  <div style="display: flex;">

    <span style="flex: 1 1 auto;"></span>
    <app-pos-button (click)="cancel()" [styles]="{ backgroundColor: 'red' }">Cancel</app-pos-button>

  </div>

</div>

<mat-menu #productFilterMenu="matMenu" xPosition="before">
</mat-menu>