import { Component, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { isCaseInsensitiveEqual } from '../../functions';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent {

  @Input() actionBarConfiguration: ActionBarConfiguration;

  public pageIndex: number;
  public maxPageIndex: number;

  private destroyed$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit() {

    // this.activatedRoute.queryParams.pipe(
    //   takeUntil(this.destroyed$)
    // ).subscribe(params => {

    //   this.actionBarConfiguration.filters.forEach(filter => {
    //     const filterOptionParam = <string>params[filter.urlParamKey];

    //     var currentSelectedOption = filter.selected?.value?.display;  
    //     if (!isCaseInsensitiveEqual(filterOptionParam, currentSelectedOption)) {
    //       var nextFilterOption = filter.options.find(x => isCaseInsensitiveEqual(x.display, filterOptionParam));
    //       if (nextFilterOption) {
    //         filter.selected.next(nextFilterOption);
    //       }
    //     }  
    //   });
    // });
  }

  ngOnDestroy(): void {

    this.destroyed$.next();
  }

  public execute(action: ActionConfiguration) {

    if (action.action) {
      action.action();
    }
  }

  public pageBackward() {

  }

  public pageForward() {

  }

  public filterSelect(filter: FilterConfiguration<any>, option: FilterOptionConfiguration<any>) {

    filter.selected.next(option);
  }
}

export class ActionBarConfiguration {

  private _actions: ActionConfiguration[];
  private _filters: FilterConfiguration<any>[];

  constructor(actions?: ActionConfiguration[], filters?: FilterConfiguration<any>[]) {

    this._actions = actions || [];
    this._filters = filters || [];
  }

  public get actions(): ActionConfiguration[] {
    return this._actions;
  }

  public get filters(): FilterConfiguration<any>[] {
    return this._filters;
  }
}

export class ActionConfiguration {

  private _display: string;
  private _action: () => void;
  private _enabled: () => boolean;

  constructor(display: string, action = () => { }, enabled = () => { return true; }) {

    this._display = display;
    this._action = action;
    this._enabled = enabled;
  }

  public get display(): string {
    return this._display;
  }

  public get action(): () => void {
    return this._action;
  }

  public get enabled(): () => boolean {
    return this._enabled;
  }
}

export class FilterConfiguration<T> {

  private _title: string;
  private _options: FilterOptionConfiguration<T>[];
  private _urlParamKey: string = null;
  private _reset: (filter: FilterConfiguration<T>) => void;
  private _selected = new BehaviorSubject<FilterOptionConfiguration<T>>(null);

  constructor(title: string, options: FilterOptionConfiguration<T>[], reset = (filter: FilterConfiguration<T>) => { }) {

    this._title = title;
    this._options = options ?? [];
    this._urlParamKey = this._title.toLowerCase().replace(' ', '');
    this._reset = reset;

    if (this._reset) {
      this._reset(this);
    }
  }

  public get title(): string {
    return this._title;
  }

  public get options(): FilterOptionConfiguration<T>[] {
    return this._options;
  }

  public get urlParamKey(): string {
    return this._urlParamKey;
  }

  public get selected(): BehaviorSubject<FilterOptionConfiguration<T>> {
    return this._selected;
  }

  public reset() {
    return this._reset ? this._reset(this) : null;
  }
}

export class FilterOptionConfiguration<T> {

  private _display: string;
  private _value: T;

  constructor(display: string, value: T) {

    this._display = display;
    this._value = value;
  }

  public get display(): string {
    return this._display;
  }

  public get value(): T {
    return this._value;
  }
}

export function updateFiltersFromParams(filterConfigurations: FilterConfiguration<any>[], params: Params) {

  filterConfigurations.forEach(filterConfiguration => {
    updateFilterFromParams(filterConfiguration, params);
  });
};

export function updateFilterFromParams(filterConfiguration: FilterConfiguration<any>, params: Params) {

  var currentSelectedOption = filterConfiguration.selected?.value?.display;
  var param = <string>params[filterConfiguration.urlParamKey];

  if (!isCaseInsensitiveEqual(param, currentSelectedOption)) {
    var nextSelectedOption = filterConfiguration.options.find(x => isCaseInsensitiveEqual(x.display, param));
    if (nextSelectedOption) {
      filterConfiguration.selected.next(nextSelectedOption);
    }
  }
}

export function getParamsFromFilters(filterConfigurations: FilterConfiguration<any>[]): Params {

  var params: Params = {};

  filterConfigurations?.forEach(filterConfiguration => {
    params[filterConfiguration.urlParamKey] = filterConfiguration.selected?.value?.display;
  });

  return params;
}
