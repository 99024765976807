import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { CacheService, isCaseInsensitiveEqual } from "core";
import { UidFilterModel } from "core";
import { ProductSelectModalData, ProductSelectModalResult } from "./product-select-modal.provider";
import { shareReplay, takeUntil } from 'rxjs/operators';
import { Category } from "../../models/category";
import { Product } from "../../models/product";
import { DepartmentService } from "../../services/department.service";
import { ProductService } from "../../services/product.service";
import { MenuPlacement, ProductStatusKeys } from "../../keys";
import { TenantProvider } from 'core';
import { DepartmentProvider, ProductProvider } from "../../providers";

@Component({
  selector: 'app-product-select-modal',
  templateUrl: './product-select-modal.component.html',
  styleUrls: ['./product-select-modal.component.scss']
})
export class ProductSelectModalComponent {

  public title: string;
  public categories: Category[];
  private allProducts: Product[];
  private explodedProducts: DisplayableProduct[];
  public displayableProducts: DisplayableProduct[];
  public productFilter = new BehaviorSubject<UidFilterModel>(null);
  public productFilters: UidFilterModel[];

  private destroyed$ = new Subject();

  public headingCategories: { category: Category, displayableProducts: DisplayableProduct[] }[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ProductSelectModalData,
    private dialogRef: MatDialogRef<ProductSelectModalComponent, ProductSelectModalResult>,
    private departmentProvider: DepartmentProvider,
    private productProvider: ProductProvider,
    private tenantProvider: TenantProvider
  ) {
    this.title = this.data.title.toUpperCase();

    this.productFilters = [
      <UidFilterModel>{ name: 'Menu', uids: [MenuPlacement.Menu] },
      <UidFilterModel>{ name: 'Off Menu', uids: [MenuPlacement.OffMenu] },
      <UidFilterModel>{ name: 'All', uids: [MenuPlacement.Menu, MenuPlacement.OffMenu] }
    ];

    this.productFilter.next(this.productFilters.find(x => x.name === (data.menuPlacement || 'Menu')));
  }

  ngOnInit() {

    combineLatest([
      this.departmentProvider.getOneCached$(this.data.departmentUid),
      this.productProvider.search$(this.tenantProvider.currentUid, [this.data.departmentUid], null, [ProductStatusKeys.Active, ProductStatusKeys.Inactive], null),
    ]).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(([department, productsPage]) => {
      this.allProducts = productsPage.edges.map(x => x.node);
      this.categories = department.categories;

      // Explode products by portions
      const displayableStatuses = [ProductStatusKeys.Active, ProductStatusKeys.Inactive];
      this.explodedProducts = this.allProducts.filter(x => displayableStatuses.some(y => isCaseInsensitiveEqual(y, x.productStatusUid))).map(product => {

        if (product.configuration && product.configuration.portions) {
          let availablePortions = product.configuration && product.configuration.portions ? product.configuration.portions.filter(y => !isCaseInsensitiveEqual(y.menuPlacementUid, MenuPlacement.NoSale)) : [];

          return availablePortions.map(portion => <DisplayableProduct>{
            product: product,
            categoryUid: product.categoryUid,
            title: product.name,
            subtitle: availablePortions.length > 1 || !isCaseInsensitiveEqual(portion.menuPlacementUid, MenuPlacement.Menu) ? portion.name : '',
            price: product.basePrice + portion.price,
            portionUid: portion.uid,
            menuPlacementUid: portion.menuPlacementUid,
            selectable: isCaseInsensitiveEqual(product.productStatusUid, ProductStatusKeys.Active) && isCaseInsensitiveEqual(portion.productStatusUid, ProductStatusKeys.Active)
          });
        } else {
          return [<DisplayableProduct>{
            product: product,
            categoryUid: product.categoryUid,
            title: product.name,
            subtitle: '',
            price: product.basePrice,
            menuPlacementUid: MenuPlacement.Menu,
            selectable: isCaseInsensitiveEqual(product.productStatusUid, ProductStatusKeys.Active)
          }];
        }
      }).reduce((a, x) => { return a.concat(x); }, []);

      this.productFilter.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(productFilter => {
        this.refresh(productFilter);
      })
    });
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  private refresh(productFilter: UidFilterModel) {

    const categories = this.categories.filter(category => {
      return this.explodedProducts
        .filter(product => product.categoryUid.toUpperCase() == category.uid.toUpperCase())
        .filter(product => productFilter.uids.some(x => isCaseInsensitiveEqual(x, product.menuPlacementUid)))
      // .some(x => x.menuPlacementUid.toUpperCase() == MenuPlacement.Menu.toUpperCase());
    });
    this.headingCategories = categories.map(category => {
      const products = this.explodedProducts.filter(x => isCaseInsensitiveEqual(x.categoryUid, category.uid));

      return {
        category: category,
        displayableProducts: products.filter(selectableProduct => productFilter.uids.some(x => isCaseInsensitiveEqual(x, selectableProduct.menuPlacementUid)))
      }
    }).filter(x => x.displayableProducts.length > 0);
  }

  public selectProductFilter(filter: UidFilterModel) {

    this.productFilter.next(filter);
  }

  public selectDisplayableProduct(product: DisplayableProduct) {

    this.dialogRef.close(<ProductSelectModalResult>{
      productUid: product.product.uid,
      productVersion: product.product.version,
      portionUid: product.portionUid,
      eachAmount: product.price
    });
  }

  public showAll() {

  }

  public cancel() {

    this.dialogRef.close();
  }
}

interface DisplayableProduct {

  product: Product;
  categoryUid: string;
  title: string;
  subtitle: string;
  price: number;
  portionUid: string;
  menuPlacementUid: string;
  selectable: boolean;
}
