import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { SpinnerModalProvider } from "core";
import { UUID } from "angular2-uuid";
import { NavigationProvider } from 'core';
import { Department } from '../models/department';
import { DepartmentService } from '../services/department.service';
import { TenantProvider } from 'core';

export class DepartmentForm {

  public departmentUid: string;
  public form: UntypedFormGroup;
  public canSave = false;

  constructor(
    protected navigationProvider: NavigationProvider,
    protected spinnerModalProvider: SpinnerModalProvider,
    protected tenantProvider: TenantProvider,
    protected departmentService: DepartmentService
  ) {
    this.form = new UntypedFormGroup({
      ownerUid: new UntypedFormControl(this.tenantProvider.currentUid, [Validators.required]),
      name: new UntypedFormControl('', [Validators.required]),
      accountSettingUid: new UntypedFormControl(null, [Validators.required]),
      isSalesTaxIncluded: new UntypedFormControl(false, [Validators.required]),
      taxAccountSettingUid: new UntypedFormControl(null)
    });

    this.form.valueChanges.subscribe(x => this.updateEnablement());
  }

  public loadForm(department: Department) {

    if (department) {
      this.departmentUid = department.uid;

      this.form.get('ownerUid').setValue(department.ownerUid);
      this.form.get('name').setValue(department.name);
      this.form.get('accountSettingUid').setValue(department.accountSettingUid);
      this.form.get('isSalesTaxIncluded').setValue(department.isSalesTaxIncluded);
      this.form.get('taxAccountSettingUid').setValue(department.taxAccountSettingUid);
    }

    this.form.markAsPristine();
    this.updateEnablement();
  }

  public save() {

    if (this.form.dirty) {
      let saveDialogRef = this.spinnerModalProvider.open();

      saveDialogRef.afterOpened().subscribe(() => {
        if (this.departmentUid) {
          this.departmentService.update(
            this.departmentUid,
            this.form.get('name').value,
            this.form.get('accountSettingUid').value,
            this.form.get('isSalesTaxIncluded').value,
            this.form.get('taxAccountSettingUid').value
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        } else {
          this.departmentUid = UUID.UUID();

          this.departmentService.create(
            this.departmentUid,
            this.form.get('ownerUid').value,
            this.form.get('name').value,
            this.form.get('accountSettingUid').value,
            this.form.get('isSalesTaxIncluded').value,
            this.form.get('taxAccountSettingUid').value
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        }
      });
    } else {
      this.navigationProvider.backOneWaypoint();
    }
  }

  public cancel() {

    this.navigationProvider.backOneWaypoint();
  }

  protected updateEnablement() {

    this.canSave = this.form.dirty && this.form.valid;
  }
}
