import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { TransactionLine } from "../../models/transaction/transaction-line";
import { CacheService } from 'core';
import { EditableTransaction, EditableTransactionGuest, EditableTransactionItem, EditableTransactionItemAdjustment, EditableTransactionPayment, EditableTransactionAdjustment, EditableTransactionCharge } from '../../models/editable-transaction/editable-transaction';
import { EditableTransactionTypes } from '../../models/editable-transaction/editable-transaction-data-handler';

@Component({
  selector: 'transaction-transaction-editor',
  templateUrl: './transaction-editor.component.html',
  styleUrls: ['./transaction-editor.component.scss'],
})
export class TransactionEditorComponent {

  @Input() editableTransaction: EditableTransaction;
  @Input() percent: number;

  @Output() updateNotes = new EventEmitter();
  @Output() updateLocation = new EventEmitter();
  @Output() select = new EventEmitter<EditableTransactionGuest | EditableTransactionTypes>();
  @Output() edit = new EventEmitter<EditableTransactionGuest | EditableTransactionItem>();
  @Output() void = new EventEmitter<EditableTransactionTypes>();
  @Output() comp = new EventEmitter<EditableTransactionItem | EditableTransactionCharge>();
  @Output() changeType = new EventEmitter<EditableTransactionPayment>();
  @Output() cancel = new EventEmitter<EditableTransactionGuest | EditableTransactionTypes>();
  @Output() print = new EventEmitter<EditableTransactionGuest | EditableTransactionItem>();
  @Output() guest = new EventEmitter<EditableTransactionItem>();

  public cacheService: CacheService;

  constructor(
  ) {
    this.cacheService = new CacheService();
    this.cacheService.enabled = true;
  }

  public ensureActiveLineViewable() {

    //https://mail.hdtuto.com/article/angular-scroll-to-bottom-of-div-smooth-scroll-example
  }

  public getTransactionGuests(): EditableTransactionGuest[] {

    return this.editableTransaction.lineItems.map(x => x.guestUid.value).filter((x, y, z) => z.indexOf(x) === y).filter(x => !!x).map(x => this.editableTransaction.guests.find(y => y.uid.toUpperCase() == x.toUpperCase()));
  }

  public getTransactionItems(): EditableTransactionItem[] {

    return this.editableTransaction.lineItems.filter(x => x.guestUid.value == null);
  }

  public addTip(transactionLine: TransactionLine) {

    // let transactionPaymentLine = transactionLine as TransactionPaymentLine;
    // if (transactionPaymentLine && transactionPaymentLine.cardTransaction.value) {
    //   let cardTransactionValue = transactionPaymentLine.cardTransaction.value;

    //   let decimalDialogRef = this.dialog.open(DecimalModalComponent, {
    //     width: '50vw',
    //     disableClose: true,
    //     closeOnNavigation: true,
    //     data: {
    //       title: 'Enter Tip Amount',
    //       initialValue: transactionPaymentLine.cardTransaction.value.tipText.value,
    //     }
    //   });

    //   decimalDialogRef.afterClosed().subscribe(value => {
    //     if (value) {
    //       cardTransactionValue.tipText.next(value ? value.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null);

    //       this.processQueueProvider.add(() => this.cardTransactionService.updateTip(cardTransactionValue.uid, value, this.stateProvider.authorityEmployee.value.uid));
    //       this.transaction.setCurrentLineItem(null);
    //     }
    //   });
    // }
  }

  // public adjust(transactionLine: TransactionLine) {

  //   transactionLine.adjust();
  // }

  // public cancel(transactionLine: TransactionLine) {

  //   transactionLine.cancel();
  // }

  // public showMessage(message: string) {

  //   this.dialog.open(MessageModalComponent, {
  //     width: '50vw',
  //     disableClose: true,
  //     closeOnNavigation: true,
  //     data: {
  //       message: message
  //     }
  //   });
  // }
}
