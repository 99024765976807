import { Injectable } from "@angular/core";
import { Page } from "core";
import { PaginationInput } from "core";
import { HttpService } from "core";
import { OltpServiceSettings } from "../oltp-service-settings";
import { Order } from "../models/order";
import { OrderInput, OrderFulfillmentInput } from "../models/order-input";
import { StoreModelFactory } from "../providers/store-model-factory";
import { OrderViewOptions, ViewFactory } from "../view-factory";
import { Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";

@Injectable()
export class OrderService {

  public static readonly OrderIndexView = <OrderViewOptions>{};

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `query { getByUid(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'getByUid').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  search(ownerUids: string[], storeFrontUids: string[], statusUids: string[], paginationInput: PaginationInput, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Page<Order>> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(ownerUids: ${JSON.stringify(ownerUids)}, storeFrontUids: ${JSON.stringify(storeFrontUids)}, statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<Order>>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = StoreModelFactory.assignOrder(edge.node)))
    );
  }

  createFromCart(orderInput: OrderInput, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation createFromCart($order:OrderInput!) { createFromCart(order: $order) ${view} }`,
      variables: { order: orderInput }
    }

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'createFromCart').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  updateTip(uid: string, paymentUid: string, tip: number, viewOptions: OrderViewOptions = OrderService.OrderIndexView) {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation updateTip { updateTip(uid: "${uid}", paymentUid: "${paymentUid}", tip: "${tip}") ${view} }`
    };

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'updateTip').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  capturePayment(uid: string, paymentUid: string, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation capturePayment { capturePayment(uid: "${uid}", paymentUid: "${paymentUid}") ${view} }`
    };

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'capturePayment').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  refundPayment(uid: string, paymentUid: string, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation refundPayment { refundPayment(uid: "${uid}", paymentUid: "${paymentUid}") ${view} }`
    };

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'refundPayment').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  voidPayment(uid: string, paymentUid: string, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation voidPayment { voidPayment(uid: "${uid}", paymentUid: "${paymentUid}") ${view} }`
    };

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'voidPayment').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  review(uid: string, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation review { review(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'review').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  accept(uid: string, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation accept { accept(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'accept').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  process(uid: string, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation process { process(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'process').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  fulfill(uid: string, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation fulfill { fulfill(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'fulfill').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  close(uid: string, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation close { close(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'close').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  updateOrderItemFulfillment(uid: string, itemUid: string, fulfilledQuantity: number, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation updateOrderItemFulfillment { updateOrderItemFulfillment(uid: "${uid}", itemUid: "${itemUid}", fulfilledQuantity: ${fulfilledQuantity}) ${view} }`
    };

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'updateOrderItemFulfillment').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }

  updateOrderFulfillment(orderFulfillmentInput: OrderFulfillmentInput, viewOptions: OrderViewOptions = OrderService.OrderIndexView): Observable<Order> {

    let view = ViewFactory.buildOrderView(viewOptions);

    var request = {
      query: `mutation updateOrderFulfillment($orderFulfillment:OrderFulfillmentInput!) { updateOrderFulfillment(orderFulfillment: $orderFulfillment) ${view} }`,
      variables: { orderFulfillment: orderFulfillmentInput }
    };

    return this.httpService.graph<Order>(this.oltpServiceSettings.apiUrl, 'api/oltp/order', request, 'updateOrderFulfillment').pipe(
      map(x => StoreModelFactory.assignOrder(x))
    );
  }
}

