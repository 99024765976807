<h1 mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content>

  <div style="display: flex;">
    <!-- LEFT SIDE -->
    <div style="flex: 1; display: flex; flex-direction: column; padding-right: 10px;">

      <div>
        <form style="font-size: 56px; line-height: 56px; color: white; text-align: center;">
          {{ amountText | currency:'USD':'symbol':'1.2-2' }}
        </form>
      </div>

      <mat-radio-group style="display: flex; flex-direction: column;" [(ngModel)]="paymentMethod">
        <mat-radio-button style="margin-top: 5px; padding: 20px; border: 1px solid green;" *ngFor="let paymentMethod of data.paymentMethods" [value]="paymentMethod">
          {{ paymentMethod.name }}
        </mat-radio-button>
      </mat-radio-group>

    </div>

    <!-- RIGHT SIDE -->
    <div style="flex: 1;">
      <mat-grid-list cols="3" rowHeight="8.3333vh" style="color: #fff;">

        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('7')">7</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('8')">8</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('9')">9</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('4')">4</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('5')">5</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('6')">6</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('1')">1</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('2')">2</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('3')">3</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('Back')">Back</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('0')">0</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('00')">00</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('Cancel')">Cancel</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('Clear')">Clear</mat-grid-tile>
        <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('Enter')">Enter</mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>

<!-- <div>
    <mat-grid-list cols="6" rowHeight="8.3333vh" style="color: #fff;">
      <mat-grid-tile [colspan]="4"></mat-grid-tile>
      <mat-grid-tile><app-pos-button (click)="cancel()" [styles]="{ marginRight: '10px' }">Cancel</app-pos-button></mat-grid-tile>
      <mat-grid-tile><app-pos-button (click)="cancel()" [styles]="{ backgroundColor: 'red', marginRight: '10px' }">Accept</app-pos-button></mat-grid-tile>
    </mat-grid-list>
  </div> -->

<mat-dialog-actions [align]="'end'">
  <button mat-button mat- color="alert" style="color: red; height: 8.3333vh; width: 8.3333vw;" (click)="cancel()">Cancel</button>
  <button mat-button color="accent" style="background-color: green; color: white; height: 8.3333vh; width: 8.3333vw;" (click)="update()">Update</button>
</mat-dialog-actions>
