<div [formGroup]="form">
  <div>
    <mat-form-field style="width: 100%; font-size: 200%;">
      <input matInput formControlName="value" style="width: 100%;" placeholder="{{ data.title }}">
    </mat-form-field>
  </div>

</div>

<mat-dialog-actions>

  <span style="flex: 1 1 auto;"></span>

  <button mat-button color="warn" [mat-dialog-close]>Cancel</button>
  <button mat-stroked-button color="accent" style="margin-left: 1em;" (click)="confirm()">Confirm</button>

</mat-dialog-actions>
