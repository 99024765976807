import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-site-shell',
  templateUrl: './site-shell.component.html',
  styleUrls: ['./site-shell.component.scss']
})
export class SiteShellComponent implements OnInit {

  constructor(
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe(['(max-width: 375px)', '(max-width: 768px)', '(max-width: 1024px)']).subscribe(result => {
      if (result.matches) {
        // doSomething();
      } else {
        // if necessary:
        // doSomethingElse();
      }
    });
  }

  ngOnInit() {

  }
}
