<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <mat-action-list>
    <div *ngFor="let option of data.options" style="margin-bottom: 8px;">
      <mat-checkbox (change)="option.checked = !option.checked" [checked]="option.checked" [disabled]="option.disabled">{{ data.displayFunc(option.value) }}</mat-checkbox>
    </div>
  </mat-action-list>
</mat-dialog-content>
<mat-dialog-actions>
  <span style="flex: 1 1 auto;"></span>
  <button mat-flat-button color="warn" [mat-dialog-close]>Cancel</button>
  <button mat-flat-button color="accent" (click)="ok()" style="margin-left: 1em;">OK</button>
</mat-dialog-actions>
