import { Injectable } from "@angular/core";

@Injectable()
export class OltpServiceSettings {

  apiUrl: string;
  socketUrl: string;
  ownerUid: string;
  availabilityHeartbeat: number;
  showDevFeatures: boolean;
}
