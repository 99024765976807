import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Product, ProductProvider } from "downtown-product";
import { TenantProvider, isEqualUUID } from "core";
import { ItemConfigurationDetailContext } from "./item-configuration-detail-context";
import { EditableTransactionItemConfigurationPortion, EditableTransactionItemConfigurationInclusionGroupOption, EditableTransactionItemConfigurationAddOn, EditableTransactionItemConfiguration } from '../../models/editable-transaction/editable-transaction';
import { IItemConfigurationDetail } from './iitem-configuration-detail.provider';
import { HtmlComponent } from "../../directives";

@HtmlComponent({ map: ['transaction-product-configuration-detail'] })
@Component({
  selector: 'transaction-item-configuration-detail',
  templateUrl: './item-configuration-detail.component.html',
  styleUrls: ['./item-configuration-detail.component.scss']
})
export class ItemConfigurationDetailComponent implements IItemConfigurationDetail {

  @Input() public product: Product;
  @Input() public itemConfiguration: EditableTransactionItemConfiguration;
  @Input() public readOnly: boolean;
  @Output() public configurationChanged = new EventEmitter();

  public context: ItemConfigurationDetailContext;

  constructor(
    private tenantProvider: TenantProvider,
    private productProvider: ProductProvider,
  ) {
  }

  ngOnInit() {

    this.context = new ItemConfigurationDetailContext(
      this.tenantProvider.currentUid,
      this.productProvider,
      this.product,
      this.itemConfiguration,
      this.readOnly || false
    );
  }

  public getVisibleInclusionGroups() {

    const productInclusionGroups = this.product.configuration.getPortion(this.context.itemConfiguration?.portion?.portionUid)?.inclusionGroups || [];

    return this.context.itemConfiguration?.portion?.inclusionGroups.filter(x => {
      const productInclusionGroup = productInclusionGroups.find(y => isEqualUUID(x.inclusionGroupUid, y.uid));

      return !productInclusionGroup?.hideInUIDetail;
    });
  }

  public updateDisplay() {

    this.context.selection = null;
  }

  handleConfigurationChanged() {

    this.configurationChanged.emit();
  }

  handleSelected(value: EditableTransactionItemConfigurationPortion | EditableTransactionItemConfigurationInclusionGroupOption | EditableTransactionItemConfigurationAddOn) {

    this.context.select(value);
  }
}
