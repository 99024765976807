import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Component, Inject } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProductAndPortionModalProvider } from '../product-and-portion-modal/product-and-portion-modal.provider';
import { ProductVariationModalData } from "./product-variation-modal.provider";
import { ProductService } from '../../services/product.service';
import { ConfigurationFormFuncs } from '../../functions/configuration.functions';
import { buildProductVariationOptionForm } from '../../functions/configuration-form-functions';
import { TenantProvider } from 'core';
import { ProductProvider } from "../../providers";

@Component({
  selector: 'app-product-variation-modal',
  templateUrl: './product-variation-modal.component.html',
  styleUrls: ['./product-variation-modal.component.scss']
})
export class ProductVariationModalComponent {

  public form: UntypedFormGroup;
  public options: UntypedFormArray;
  public scope: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProductVariationModalData,
    private productAndPortionModalProvider: ProductAndPortionModalProvider,
    private productProvider: ProductProvider,
    private tenantProvider: TenantProvider
  ) {
    this.form = data.variationForm;
    this.options = <UntypedFormArray>data.variationForm.get('options');

    this.scope = data.scope;
  }

  public addOption() {

    const formGroup = buildProductVariationOptionForm(null);

    this.options.push(formGroup);
    this.options.markAsDirty();
  }

  public drop(event: CdkDragDrop<string[]>) {

    if (event.previousIndex != event.currentIndex) {
      var item = this.options.controls[event.previousIndex];
      this.options.removeAt(event.previousIndex);

      this.options.insert(event.currentIndex, item);
    }
  }

  public delete(formGroup: UntypedFormGroup) {

    var index = this.options.controls.indexOf(formGroup);
    this.options.removeAt(index);
  }

  public showSelectProductModal(form: UntypedFormGroup) {

    this.productProvider.search$(this.tenantProvider.currentUid, null, null, null, null, ProductService.ProductFullView).subscribe(productPage => {
      let products = productPage.edges.map(x => x.node).filter(x => {
        return x.uid.toLowerCase() != this.form.get('uid').value.toLowerCase() && x.configuration && x.configuration.portions && x.configuration.portions.filter(y => y.availableAsInclusion).length > 0;
      });

      this.productAndPortionModalProvider.open({
        title: 'Select Product and Portion',
        products: products
      }).afterClosed().subscribe(result => {
        if (result) {
          let productReference = form.get('productReference');

          let uidFormControl = productReference.get('uid');
          uidFormControl.setValue(result.productReference.uid);
          uidFormControl.markAsDirty();

          let portionFormControl = productReference.get('portionUid');
          portionFormControl.setValue(result.productReference.portionUid);
          portionFormControl.markAsDirty();

          let versionFormControl = productReference.get('version');
          versionFormControl.setValue(result.productReference.version);
          versionFormControl.markAsDirty();
        }
      });
    });
  }

  public getProductVariationForm(variationForm: UntypedFormGroup) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(variationForm);

    return ConfigurationFormFuncs.variations.getVariationForm(productForm, variationForm.get('uid').value);
  }

  public getProductVariationFormValue(variationForm: UntypedFormGroup, key: string) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(variationForm);

    return ConfigurationFormFuncs.variations.getVariationFormValue(productForm, variationForm.get('uid').value, key);
  }

  public getProductVariationOptionFormValue(variationForm: UntypedFormGroup, optionForm: UntypedFormGroup, key: string) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(variationForm);

    return ConfigurationFormFuncs.variations.getVariationOptionFormValue(productForm, variationForm.get('uid').value, optionForm.get('uid').value, key);
  }

  public getProductInclusionGroupVariationForm(variationForm: UntypedFormGroup) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(variationForm);

    var inclusionGroupUid = variationForm.parent.parent.parent.parent.get('uid').value;
    var optionUid = variationForm.parent.parent.get('uid').value;
    var variationUid = variationForm.get('uid').value;

    return ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationForm(productForm, inclusionGroupUid, optionUid, variationUid);
  }

  public getProductInclusionGroupVariationFormValue(variationForm: UntypedFormGroup, key: string) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(variationForm);

    var inclusionGroupUid = variationForm.parent.parent.parent.parent.get('uid').value;
    var optionUid = variationForm.parent.parent.get('uid').value;
    var variationUid = variationForm.get('uid').value;

    return ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationFormValue(productForm, inclusionGroupUid, optionUid, variationUid, key);
  }

  public getProductInclusionGroupVariationOptionFormValue(variationForm: UntypedFormGroup, optionForm: UntypedFormGroup, key: string) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(variationForm);

    var inclusionGroupUid = variationForm.parent.parent.parent.parent.get('uid').value;
    var optionUid = variationForm.parent.parent.get('uid').value;
    var variationUid = variationForm.get('uid').value;
    var variationOptionUid = optionForm.get('uid').value;

    return ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationOptionFormValue(productForm, inclusionGroupUid, optionUid, variationUid, variationOptionUid, key);
  }
}
