<div style="display: flex; flex-direction: column; height: 100%;">

  <div style="height: 8vh;">
    <div style="margin-left: 10px;">
      <div style="margin-bottom: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.12);">
        <h2 *ngIf="context.productForm">Product Configuration - {{ context.productForm.get('name').value }} - Version {{ version }}</h2>
      </div>
    </div>
  </div>

  <div style="flex: 1; display: flex;">

    <app-expansion-container #expansionContainer style="flex: 1; background-color: rgba(255, 255, 255, 0.06);">

      <app-expansion-panel #generalPanel>
        <app-expansion-panel-header (click)="panelClick('general')">
          <app-expansion-panel-title>
            <span style="font-size: 24px;">Product Settings</span>
          </app-expansion-panel-title>
        </app-expansion-panel-header>
      </app-expansion-panel>

      <app-expansion-panel #portionsPanel>

        <app-expansion-panel-header (click)="panelClick('portions')">
          <app-expansion-panel-title>
            <span style="font-size: 24px;"> Portions<span *ngIf="context.productForm">&nbsp;({{ portionsFormArray?.length }})</span></span>
          </app-expansion-panel-title>
        </app-expansion-panel-header>

        <app-expansion-panel-content>

          <div style="padding-left: 40px;">

            <app-expansion-container #portionsContainer>
              <app-expansion-panel *ngFor="let portionForm of portionsFormArray?.controls" [value]="portionForm.get('uid').value">
                <app-expansion-panel-header (click)="portionClick(portionForm.get('uid').value)">
                  <app-expansion-panel-title>
                    <span style="font-size: 18px;">{{ portionForm.get('name').value }}<span *ngIf="portionForm.get('productStatusUid').value == ProductStatusKeys.Inactive" style="font-size: 12px;">&nbsp;(Inactive)</span></span>
                  </app-expansion-panel-title>
                </app-expansion-panel-header>
              </app-expansion-panel>

            </app-expansion-container>

          </div>

        </app-expansion-panel-content>

      </app-expansion-panel>

      <app-expansion-panel #inclusionGroupsPanel>

        <app-expansion-panel-header (click)="panelClick('inclusiongroups')">
          <app-expansion-panel-title>
            <div style="font-size: .8em;">Product</div>
            <div style="font-size: 24px;"> Inclusion Groups<span *ngIf="context.productForm">&nbsp;({{ inclusionGroupsFormArray?.length }})</span></div>
          </app-expansion-panel-title>
        </app-expansion-panel-header>

        <app-expansion-panel-content>

          <div style="padding-left: 40px;">

            <app-expansion-container #inclusionGroupsContainer>

              <app-expansion-panel *ngFor="let inclusionGroupForm of inclusionGroupsFormArray?.controls" [value]="inclusionGroupForm.get('uid').value">
                <app-expansion-panel-header (click)="inclusionGroupClick(inclusionGroupForm.get('uid').value)">
                  <app-expansion-panel-title>
                    <div style="font-size: 18px;">{{ getResolvedInclusionGroupFormValue(inclusionGroupForm.get('uid').value, 'name') }}</div>
                  </app-expansion-panel-title>
                </app-expansion-panel-header>
              </app-expansion-panel>

            </app-expansion-container>

          </div>

        </app-expansion-panel-content>

      </app-expansion-panel>

      <app-expansion-panel #addOnsPanel>

        <app-expansion-panel-header (click)="panelClick('addons')">
          <app-expansion-panel-title>
            <div style="font-size: .8em;">Product</div>
            <div style="font-size: 24px;">Add Ons<span *ngIf="context.productForm">&nbsp;({{ addOnsFormArray?.length }})</span></div>
          </app-expansion-panel-title>
        </app-expansion-panel-header>

        <app-expansion-panel-content>

          <div style="padding-left: 40px;">

            <app-expansion-container #addOnsContainer>

              <app-expansion-panel *ngFor="let addOnForm of addOnsFormArray?.controls" [value]="addOnForm.get('uid').value">
                <app-expansion-panel-header (click)="addOnClick(addOnForm.get('uid').value)">
                  <app-expansion-panel-title>

                    <span style="font-size: 18px;">
                      <ng-container *ngIf="getResolvedAddOnFormValue(addOnForm.get('uid').value, 'alias'); else noAddOnAlias;">
                        <span>{{ getResolvedAddOnFormValue(addOnForm.get('uid').value, 'alias') }}</span>
                      </ng-container>

                      <ng-template #noAddOnAlias>
                        <app-product-portion-label [productUid]="getResolvedProductReference('addOn', addOnForm.get('uid').value)?.uid" [portionUid]="getResolvedProductReference('addOn', addOnForm.get('uid').value)?.portionUid" [version]="getResolvedProductReference('addOn', addOnForm.get('uid').value)?.version"></app-product-portion-label>
                      </ng-template>
                    </span>

                  </app-expansion-panel-title>
                </app-expansion-panel-header>
              </app-expansion-panel>

            </app-expansion-container>

          </div>

        </app-expansion-panel-content>

      </app-expansion-panel>

    </app-expansion-container>

    <div style="flex: 5; margin-left: 10px; height: 100%;">

      <ng-container *ngIf="context.productForm">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </ng-container>

    </div>


  </div>

  <div *ngIf="context.productForm" style="height: 8vh; text-align: right;">
    <app-pos-button color="warn" (click)="cancel()">Cancel</app-pos-button>
    <!-- <button mat-stroked-button [disabled]="!context.productForm.dirty || !context.productForm.valid" color="primary" style="margin-left: 1em;" (click)="save()">Save</button> -->
    <app-pos-button [disabled]="!context.productForm.dirty" (click)="save()">Save</app-pos-button>
  </div>

</div>