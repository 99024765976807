<mat-toolbar color="primary" class="action" style="display: flex;">

  <ng-content select="[left]"></ng-content>

  <div style="flex: 1 1 auto; margin-left: 16px; margin-right: 16px;">
    <ng-content select="[middle]"></ng-content>
  </div>

  <!-- <span style="margin-left: 10px;">{{ title }}</span>
  <span style="flex: 1 1 auto; margin-left: 10px; margin-right: 10px;"></span> -->
  <ng-content select="[right]"></ng-content>

</mat-toolbar>