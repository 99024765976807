
export class StoreFrontInput {

  uid: string;
  ownerUid: string;
  name: string;

  configuration: StoreFrontConfigurationInput;
}

export class StoreFrontConfigurationInput {

  logistics: LogisticSettingsInput;
  transactions: TransactionSettingsInput;
}


export class LogisticSettingsInput {

  dineIn: boolean;
  carryOut: boolean;
  curbsidePickup: boolean;
  delivery: boolean;

  deliveryCharge: number;
}

export class TransactionSettingsInput {

}
