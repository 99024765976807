import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigurationComponentBase } from '../../configuration-component-base';
import { ProductContext } from '../../product-context';
import { ProductAndPortionModalProvider } from '../../../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { TenantProvider, getDescendentRouteSnapshot } from 'core';
import { ProductProvider } from '../../../../providers';

@Component({
  selector: 'app-back-office-product-configuration-add-on',
  templateUrl: './back-office-product-configuration-add-on.component.html',
  styleUrls: ['./back-office-product-configuration-add-on.component.scss']
})
export class BackOfficeProductConfigurationAddOnComponent extends ConfigurationComponentBase {

  public form: UntypedFormGroup;
  public scope: string;

  private _context: ProductContext;
  private destroyed$ = new Subject();

  constructor(
    activatedRoute: ActivatedRoute,
    tenantProvider: TenantProvider,
    productProvider: ProductProvider,
    productAndPortionModalProvider: ProductAndPortionModalProvider,
  ) {
    super(activatedRoute, tenantProvider, productProvider, productAndPortionModalProvider);

    this.scope = this.getScope(this.activatedRoute);
  }

  public set context(context: ProductContext) {

    this._context = context;

    this.activatedRoute.url.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(x => {
      var route = getDescendentRouteSnapshot(this.activatedRoute);
      var portionUid = route.params['portionUid'];
      var addOnUid = route.params['addOnUid'];

      let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
      if (portionUid) {
        let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
        if (portionsGroupFormArray) {
          configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid.toLowerCase());
        }
      }

      let addOnsFormArray = <UntypedFormArray>configurationFormGroup.get('addOns');
      configurationFormGroup = <UntypedFormGroup>addOnsFormArray.controls.find(x => x.get('uid').value.toLowerCase() == addOnUid.toLowerCase());

      this.form = configurationFormGroup;
    });
  }

  public get context(): ProductContext {

    return this._context;
  }
}

