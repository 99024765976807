import { Component } from "@angular/core";
import { Subject } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { ActionBarConfiguration, ActionConfiguration } from "core";
import { SpinnerModalComponent } from "core";
import { SpinnerModalProvider } from "core";
import { NavigationProvider } from "core";
import { DrawerExchangesDataSource, DrawerExchangeModel, DrawerProvider } from "pos-core";
import { DrawerService } from "pos-core";

@Component({
  selector: 'app-back-office-drawer',
  templateUrl: './back-office-drawer.component.html',
  styleUrls: ['./back-office-drawer.component.scss']
})
export class BackOfficeDrawerComponent {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: DrawerExchangesDataSource;

  public title: string;
  public columns: Array<string>;

  public canSyncToStore = true;

  private destroyed$ = new Subject();

  constructor(
    private navigationProvider: NavigationProvider,
    private activatedRoute: ActivatedRoute,
    private spinnerModalProvider: SpinnerModalProvider,
    private drawerProvider: DrawerProvider
  ) {
    this.navigationProvider.setWaypoint('Drawer Exchanges');
    this.title = 'Drawer Exchanges';

    this.dataSource = new DrawerExchangesDataSource(this.drawerProvider, false);

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('Update Tip', () => this.updateTip(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canUpdateTip)),
      ]
    );

    // Build initial columns
    this.columns = [ /*'select',*/ 'dateTimeUtc', 'transaction.number', 'credit', 'debit', 'description'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      let drawerUid = params['drawerUid'].toUpperCase();

      this.dataSource.loadData(drawerUid);
    });
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }
  
  updateTip(exchange: DrawerExchangeModel) {

    // this.navigationProvider.navigate(['/transaction', exchange.transactionUid, 'cardtransactions']);
  }
}
