import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IdentityUser } from '../models/identity-user';
import { CacheService } from '../services/cache.service';
import { IdentityUserResolver } from '../resolvers/identity-user-resolver';

@Pipe({
  name: 'identityUserResolver'
})
export class IdentityUserResolverPipe implements PipeTransform {

  constructor(
    private cacheService: CacheService,
    private identityUserResolver: IdentityUserResolver
  ) {
  }

  transform(value: string): Observable<IdentityUser> {

    if (value) {
      var item = this.cacheService.getOrAdd(value, () => this.identityUserResolver.resolve(value));

      return item;
    }

    return of(null);
  }

  ngOnDestroy(): void {

  }
}
