<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="dataSource.selection.isMultipleSelection()" (change)="$event ? dataSource.masterToggle() : null" [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row) : null" [checked]="dataSource.selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef color="accent">Start Date</th>
    <td mat-cell *matCellDef="let payPeriod">{{ payPeriod.startDateTimeUtc | date: 'M-dd-yyyy' }}</td>
  </ng-container>

  <ng-container matColumnDef="endDate">
    <th mat-header-cell *matHeaderCellDef>End Date</th>
    <td mat-cell *matCellDef="let payPeriod">{{ payPeriod.endDateTimeUtc | date: 'M-dd-yyyy' }}</td>
  </ng-container>

  <ng-container matColumnDef="totalSales">
    <th mat-header-cell *matHeaderCellDef>Total Sales</th>
    <td mat-cell *matCellDef="let payPeriod">{{ payPeriod.totalSales | currency:'USD':'symbol':'1.2-2' }}</td>
  </ng-container>

  <ng-container matColumnDef="totalPay">
    <th mat-header-cell *matHeaderCellDef>Employee Pay</th>
    <td mat-cell *matCellDef="let payPeriod">{{ payPeriod.totalPay | currency:'USD':'symbol':'1.2-2' }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let payPeriod">{{ payPeriod.payPeriodStatusUid | payPeriodStatus }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>

</table>
