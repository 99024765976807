import { Metadata } from "core";

export class TransactionPaymentInput {

  uid: string;
  paymentMethodUid: string;
  amount: number;
  referenceUid: string;
  metadata: Metadata[];
}
