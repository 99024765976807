import { Component, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, map, concatMap, takeUntil } from 'rxjs/operators';
import { ActionBarConfiguration, ActionConfiguration, FilterConfiguration, FilterOptionConfiguration, TenantProvider, getParamsFromFilters, getParamsFromPaginator, updateFiltersFromParams, updatePaginatorFromParams } from 'core';
import { Paginator } from 'core';
import { ConfirmModalProvider } from 'core';
import { SpinnerModalComponent } from 'core';
import { SpinnerModalProvider } from 'core';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { DepartmentDataSource, DepartmentModel } from 'downtown-product';
import { DepartmentStatusKeys } from 'downtown-product';
import { DepartmentService, DepartmentViewOptions } from 'downtown-product';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-back-office-departments',
  templateUrl: './back-office-departments.component.html',
  styleUrls: ['./back-office-departments.component.scss']
})
export class BackOfficeDepartmentsComponent implements OnInit {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: DepartmentDataSource;
  public paginator: Paginator;

  public title: string;
  public columns: Array<string>;

  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private departmentService: DepartmentService,
    private tenantProvider: TenantProvider
  ) {
    this.navigationProvider.setWaypoint('Product Departments');
    this.title = 'Product Departments';

    this.dataSource = new DepartmentDataSource(this.departmentService, this.tenantProvider, false);
    this.paginator = new Paginator(this.dataSource, 0, 100, 'clockInDateTimeUtc', 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('New', this.new.bind(this)),
        new ActionConfiguration('Edit', () => this.edit(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Categories', () => this.categories(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Enable', () => this.enable(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEnable)),
        new ActionConfiguration('Disable', () => this.disable(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDisable)),
        new ActionConfiguration('Delete', () => this.delete(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDelete))
      ],
      [
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('Active', [DepartmentStatusKeys.Active]),
            new FilterOptionConfiguration('All', [DepartmentStatusKeys.Active, DepartmentStatusKeys.Inactive])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'Active')) }
        )
      ]
    );

    // Build initial columns
    this.columns = [ /*'select',*/ 'name', 'isSalesTaxIncluded', /* 'taxAccountSettingUid', */ 'departmentStatusUid'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    const statusFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => x.title == 'Status');
    const paginator = this.paginator;

    this.activatedRoute.queryParams.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(params => {
      updateFiltersFromParams([statusFilter], params);
      updatePaginatorFromParams(paginator, params);
    });

    combineLatest([
      this.actionBarConfiguration.filters.find(x => x.title == 'Status').selected,
      this.paginator.pageRequest$
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([statusFilterOption, paginationInput]) => {
        let statusFilterUids = statusFilterOption.value;

        const params = Object.assign(getParamsFromFilters([statusFilter]), getParamsFromPaginator(paginator));
        this.navigationProvider.navigate([], { queryParams: params, queryParamsHandling: 'merge' }).then(_ => this.navigationProvider.updateLastWaypoint());

        this.dataSource.loadData(statusFilterUids, paginationInput, <DepartmentViewOptions>{});
      })
    ).subscribe();
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }

  public new() {

    this.navigationProvider.navigate(['/backoffice/department']);
  }

  public edit(department: DepartmentModel) {

    this.navigationProvider.navigate(['/backoffice/department', department.uid]);
  }

  public categories(department: DepartmentModel) {

    this.navigationProvider.navigate(['/backoffice/department', department.uid, 'categories']);
  }

  public enable(department: DepartmentModel) {

    let waitDialogRef = this.waitModalProvider.open('Enabling...');

    waitDialogRef.afterOpened().pipe(
      concatMap(() => this.dataSource.enable(department)),
      catchError(() => of(null))
    ).subscribe(() => {
      waitDialogRef.close();
    });
  }

  public disable(department: DepartmentModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Disable Department', 'Are you certain you want to disable this department?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Disabling...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.disable(department)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public delete(department: DepartmentModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Delete Department', 'Are you certain you want to delete this department?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Deleting...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.delete(department)),
          catchError(() => of(null)),
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }
}
