export interface TransactionGuestInput {

  uid: string;
  name: string;
};

export interface TransactionItemInput {

  uid: string;
  departmentUid: string;
  productUid?: string;
  productVersion?: number;
  guestUid?: string;
  configuration?: TransactionItemInputConfiguration;
  quantity: number;
  eachAmount: number;
};

export class TransactionItemInputConfiguration {

  portion: TransactionItemInputConfigurationPortion;
}

export class TransactionItemInputConfigurationPortion {

  portionUid: string;
  preparations?: TransactionItemInputConfigurationPreparation[];
  variations?: TransactionItemInputConfigurationVariation[];
  inclusionGroups?: TransactionItemInputConfigurationInclusionGroup[];
  addOns?: TransactionItemInputConfigurationAddOn[];
}

export class TransactionItemInputConfigurationPreparation {

  preparationUid: string;
  optionUid: string;
}

export class TransactionItemInputConfigurationVariation {

  variationUid: string;
  optionUid: string;
}

export class TransactionItemInputConfigurationInclusionGroup {

  inclusionGroupUid: string;
  options: TransactionItemInputConfigurationInclusionGroupOption[];
}

export class TransactionItemInputConfigurationInclusionGroupOption {

  optionUid: string;
  quantity: number;
  preparations?: TransactionItemInputConfigurationPreparation[];
  variations?: TransactionItemInputConfigurationVariation[];
}

export class TransactionItemInputConfigurationAddOn {

  addOnUid: string;
  item: TransactionItemInput;
}
