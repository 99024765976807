import { Component, Inject } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AmountFrequencyModalData, AmountFrequencyModalResult } from "./amount-frequency-modal.provider";
import { WorkUnitKeys } from "../../keys";
import Decimal from "decimal.js";

@Component({
  selector: 'app-amount-frequency-modal',
  templateUrl: './amount-frequency-modal.component.html',
  styleUrls: ['./amount-frequency-modal.component.scss']
})
export class AmountFrequencyModalComponent {

  public amountText = new BehaviorSubject<string>('0.00');
  public workUnitUid = new BehaviorSubject<string>(WorkUnitKeys.Hourly);

  constructor(
    private dialogRef: MatDialogRef<AmountFrequencyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AmountFrequencyModalData
  ) {

  }

  ngOnInit() {

    if (this.data.amount) {
      this.amountText.next(this.data.amount.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: false }));
    }
    if (this.data.workUnitUid) {
      this.workUnitUid.next(this.data.workUnitUid);
    }
  }

  numberPadKeyPressed(value: string) {

    let keyValue = value.toLowerCase();

    if (keyValue == "00") {
      this.numberPadKeyPressed("0");
      this.numberPadKeyPressed("0");
    } else if (keyValue == ".") {
      let amount = this.amountText.value;
      let decimalIndex = amount.indexOf('.');

      let wholeDigits = amount.substr(0, decimalIndex + 2).replace('.', '');
      let decimalDigits = amount.substr(decimalIndex + 2);

      amount = parseInt(wholeDigits + decimalDigits).toString() + ".";
      this.amountText.next(amount);
    } else if (keyValue == "back") {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        // Shift all digits right
        amount = Math.floor(amount * 10) / 100;

        this.amountText.next(amount.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: false }));
      }
    } else if (keyValue == "enter") {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        this.commit();
      }
    } else if (keyValue == "clear") {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        this.clearAmount();
      }
    } else if (keyValue == "cancel") {
      this.cancel();
    } else {
      let digit = parseInt(value);
      if (!Number.isNaN(digit)) {
        let textEachAmount = this.amountText.value.concat(keyValue);

        let validAmount = new Decimal(textEachAmount);
        if (!validAmount.isNaN()) {
          let decimalIndex = textEachAmount.indexOf('.');
          if (decimalIndex > 0 && textEachAmount.length - decimalIndex > 3) {
            // Need to shift the decimal point
            textEachAmount = (new Decimal(textEachAmount.slice(0, decimalIndex + 2).replace('.', '')).toString()) + '.' + (textEachAmount.slice(decimalIndex + 2));
          }

          this.amountText.next(textEachAmount);
        }
      }
    }
  }

  frequencyKeyPressed(value: string) {

    this.workUnitUid.next(value);
  }

  getAmount(): number {

    return new Decimal(this.amountText.value).toNumber();
  }

  clearAmount() {
    
    this.amountText.next((0).toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: false }));
  }

  commit() {

    this.dialogRef.close(<AmountFrequencyModalResult>{ amount: this.getAmount(), workUnitUid: this.workUnitUid.value });
  }

  cancel() {

    this.dialogRef.close();
  }
}
