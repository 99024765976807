<h2 mat-dialog-title>Variation Options</h2>

<mat-dialog-content>

  <div [formGroup]="form">

    <div style="display: flex;">
      <div style="flex: 1;">&nbsp;</div>

      <div *ngIf="getProductVariationForm(form)" style="flex: 1;">Product Setting</div>

      <div *ngIf="scope == 'productInclusion'" style="flex: 1;">Product Inclusion<span *ngIf="getProductVariationForm(form)">&nbsp;Override</span></div>
      <div *ngIf="scope == 'productInclusionGroup'" style="flex: 1;">Product Inclusion Group<span *ngIf="getProductVariationForm(form)">&nbsp;Override</span></div>
      <div *ngIf="scope == 'productExtra'" style="flex: 1;">Product Extra<span *ngIf="getProductVariationForm(form)">&nbsp;Override</span></div>

      <div *ngIf="scope == 'portionInclusionGroup' && getProductInclusionGroupVariationForm(form)" style="flex: 1;">Product Inclusion Group Setting</div>

      <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1;">Portion Inclusion Group<span *ngIf="getProductVariationForm(form)">&nbsp;Override</span></div>
    </div>

    <div style="display: flex;">
      <div style="flex: 1;">
        <span>Name</span>
      </div>
      <div *ngIf="scope != 'product' && getProductVariationForm(form)" style="flex: 1; margin-right: 5px;">
        <span *ngIf="getProductVariationFormValue(form, 'name');else not_assigned">
          {{ getProductVariationFormValue(form, 'name') }}
        </span>
      </div>
      <div *ngIf="scope == 'portionInclusionGroup' && getProductInclusionGroupVariationForm(form)" style="flex: 1; margin-right: 5px;">
        <span *ngIf="getProductInclusionGroupVariationFormValue(form, 'name');else not_assigned">
          {{ getProductInclusionGroupVariationFormValue(form, 'name') }}
        </span>
      </div>
      <mat-form-field style="flex: 1; margin-left: 5px;">
        <input matInput formControlName="name">
      </mat-form-field>
    </div>

  </div>

  <hr />

  <div style="display: flex;">
    <div style="width: 30px;">&nbsp;</div>
    <div style="flex: 1;">&nbsp;</div>

    <div *ngIf="getProductVariationForm(form)" style="flex: 1;">Product Setting</div>

    <div *ngIf="scope == 'productInclusionGroup'" style="flex: 1;">Product Inclusion Group<span *ngIf="getProductVariationForm(form)">&nbsp;Override</span></div>

    <div *ngIf="scope == 'portionInclusionGroup' && getProductInclusionGroupVariationForm(form)" style="flex: 1;">Product Inclusion Group Setting</div>

    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1;">Portion Inclusion Group<span *ngIf="getProductVariationForm(form)">&nbsp;Override</span></div>

    <div style="width: 55px;">&nbsp;</div>
  </div>

  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <div cdkDrag *ngFor="let option of options.controls" [formGroup]="$any(option)" class="item">
      <div style="display: flex; align-items: center;">
        <div style="width: 25px; margin-right: 5px;">
          <mat-icon>drag_indicator</mat-icon>
        </div>
        <div style="flex: 1; ">
          <div style="display: flex;">
            <div style="flex: 1;">
              <span>Product</span>
            </div>
            <div *ngIf="scope != 'product' && getProductVariationForm(form)" style="flex: 1; margin-right: 5px;">
              <span *ngIf="getProductVariationOptionFormValue(form, $any(option), 'saleableProductUid');else not_assigned">
                <app-product-portion-label [productUid]="getProductVariationOptionFormValue(form, $any(option), 'productReference')?.get('uid').value" [portionUid]="getProductVariationOptionFormValue(form, $any(option), 'productReference')?.get('portionUid').value" [version]="getProductVariationOptionFormValue(form, $any(option), 'productReference')?.get('version').value" [includePrice]="true"></app-product-portion-label>
              </span>
            </div>
            <div style="flex: 1;">
              <app-product-portion-button [productUid]="option.get('productReference').get('uid').value" [portionUid]="option.get('productReference').get('portionUid').value" [version]="option.get('productReference').get('version').value" (click)="showSelectProductModal($any(option))" [includePrice]="true"></app-product-portion-button>
            </div>
          </div>

          <div style="display: flex;">
            <div style="flex: 1;">
              <span>Alias</span>
            </div>
            <div *ngIf="scope != 'product' && getProductVariationForm(form)" style="flex: 1; margin-right: 5px;">
              <span *ngIf="getProductVariationOptionFormValue(form, $any(option), 'alias');else not_assigned">
                {{ getProductVariationOptionFormValue(form, $any(option), 'alias') }}
              </span>
            </div>
            <mat-form-field style="flex: 1; margin-left: 5px;">
              <input matInput formControlName="alias">
            </mat-form-field>
          </div>

          <div style="display: flex;">
            <div style="flex: 1;">
              <span>Price Override</span>
            </div>
            <div *ngIf="scope == 'portion'" style="flex: 1; margin-right: 5px;">
              <span *ngIf="getProductVariationOptionFormValue(form, $any(option), 'priceOverride');else not_assigned">
                {{ getProductVariationOptionFormValue(form, $any(option), 'priceOverride') }}
              </span>
            </div>

            <!-- <mat-form-field style="flex: 1; margin-left: 5px;">
              <input matInput formControlName="priceOverride">
            </mat-form-field> -->
          </div>

        </div>

        <div style="width: 50px; margin-left: 5px; text-align: center;">
          <mat-icon (click)="delete($any(option))">delete</mat-icon>
        </div>

      </div>

    </div>

  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button color="accent" (click)="addOption()">Add Option</button>
  <span style="flex: 1 1 auto;"></span>
  <button mat-stroked-button color="warn" [mat-dialog-close]>Close</button>
</mat-dialog-actions>

<ng-template #not_assigned>Not Assigned</ng-template>
