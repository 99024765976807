<div style="border-radius: 4px;" [style.background-color]="(order.selection | async) == orderItem ? 'gray' : 'transparent'" (click)="selectItem.emit(orderItem)" click-stop-propagation>
  <ng-container *ngIf="orderItem.configuration.value; else item">
    <ng-container *ngTemplateOutlet="configuredItem"></ng-container>
  </ng-container>
</div>

<!-- Order Item Context Menu -->
<div *ngIf="(order.selection | async) == orderItem && (orderItem.canVoid || orderItem.canComp || orderItem.canCancel)" [@slideIn] [@slideOut] style="display: flex; overflow: hidden;">
  <div style="width: 10%; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
  </div>
  <div style="background-color: gray; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 4px;">
    <!-- <button *ngIf="orderItem.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemEdit.emit(orderItem)">Edit</button>
    <button *ngIf="orderItem.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemVoid.emit(orderItem)">Void</button>
    <button *ngIf="orderItem.canComp" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemComp.emit(orderItem)">Comp</button>
    <button *ngIf="orderItem.canCancel" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemCancel.emit(orderItem)">Cancel</button> -->
  </div>
  <div style="flex: 1 1 auto; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
  </div>
</div>

<!-- Order Item Adjustments -->
<ng-container *ngFor="let adjustment of orderItem.adjustments">
  <!-- <div style="display: flex; color: red;" (click)="lineItemSelect.emit(adjustment)">
    <div style="width: 10%;"></div>
    <div style="width: 90%; border-radius: 4px;" [style.background-color]="(order.selectedLineItem | async) == adjustment ? 'gray' : 'transparent'">
      <div style="display: flex; width: 100%;">
        <div style="width: 47.7%;">{{ adjustment.description }}</div>
        <div style="width: 11%; text-align: right;">{{ adjustment.quantity | async | number:'1.0-2' }}</div>
        <div style="width: 2.2%; text-align: center;">@</div>
        <div style="width: 16.5%; text-align: right;">{{ adjustment.eachAmountText | async | currency:'USD':'symbol':'1.2-2' }}</div>
        <div style="width: 22%; text-align: right;">-{{ adjustment.total | async | currency:'USD':'symbol':'1.2-2' }}</div>
      </div>

      <div *ngIf="adjustment.validationMessage" class="validation-message" [@slideIn] [@slideOut]>
        <span>{{ adjustment.validationMessage }}</span>
      </div>
    </div>
  </div> -->

  <!-- Order Item Adjustments Context Menu -->
  <!-- <div *ngIf="(order.selectedLineItem | async) == adjustment && (adjustment.canVoid || adjustment.canCancel)" [@slideIn] [@slideOut] style="display: flex; overflow: hidden;">
    <div style="width: 20%; background-color: gray;">
      <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
    </div>
    <div style="background-color: gray; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 4px;">
      <button *ngIf="adjustment.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemVoid.emit(adjustment)">Remove</button>
      <button *ngIf="adjustment.canCancel" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemCancel.emit(adjustment)">Cancel</button>
    </div>
    <div style="flex: 1 1 auto; background-color: gray;">
      <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
    </div>
  </div> -->

</ng-container>

<ng-template #configuredItem>
  <div style="display: flex;">
    <div *ngIf="canEdit" style="flex: 10;"><button mat-flat-button style="font-size: 10px; line-height: 14px; padding: 4px;" (click)="selectItem.emit(orderItem)" click-stop-propagation>Select</button></div>
    <div style="flex: 50; overflow: hidden;">{{ orderItem.productUid.value | product | async }}</div>
    <div style="flex: 10; text-align: right;">{{ orderItem.orderQuantity | async | number:'1.0-2' }}</div>
    <div style="flex: 10; text-align: right;">{{ orderItem.eachAmount | async | currency:'USD':'symbol':'1.2-2' }}</div>
    <div style="flex: 10; text-align: right;">{{ orderItem.total | async | currency:'USD':'symbol':'1.2-2' }}</div>
    <div *ngIf="canEdit" style="flex: 10; text-align: right;"></div>
  </div>

  <div *ngIf="orderItem.configuration" style="margin-left: 10%;">

    <!-- Show detail -->
    <div *ngIf="(order.selection | async) == orderItem" [@slideIn] [@slideOut]>
      <!-- <app-order-item-configuration-detail [orderItem]="orderItem"></app-order-item-configuration-detail> -->
    </div>

    <!-- Show summary -->
    <!-- <div *ngIf="(order.selectedLineItem | async) != transactionLineItem" [@slideIn] [@slideOut]>
      <app-product-configuration-summary [transactionItemConfiguration]="transactionLineItem.configuration.value"></app-product-configuration-summary>
    </div> -->

  </div>

</ng-template>

<ng-template #item>

  <div style="display: flex;">
    <div style="width: 60%;">{{ orderItem.productUid.value | product | async }}</div>
    <div style="width: 10%; text-align: right;">{{ orderItem.orderQuantity | async | number:'1.0-2' }}</div>
    <div style="width: 10%; text-align: right;">{{ orderItem.eachAmount | async | currency:'USD':'symbol':'1.2-2' }}</div>
    <div style="width: 10%; text-align: right;">{{ orderItem.total | async | currency:'USD':'symbol':'1.2-2' }}</div>
    <div style="width: 10%; text-align: right;"><button mat-flat-button style="font-size: 10px; line-height: 14px; padding: 4px;">Select</button></div>
  </div>

</ng-template>
