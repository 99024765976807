import { IdentityUser } from 'core';
import { Employee } from './employee';

export class Member {

  uid: string;
  identityId: string
  firstName: string;
  lastName: string;
  email: string;
  displayName: string;
  memberStatusUid: string;

  isEmployee: boolean;

  identityUser: IdentityUser;
  employee: Employee;

  getFullName(): string {
    return `${this.firstName ? this.firstName : ''} ${this.lastName ? this.lastName : ''}`.trim();
  }

  getReportName(): string {
    return `${this.lastName ? this.lastName : ''}, ${this.firstName ? this.firstName : ''}`.trim();
  }

  getDisplayableName(): string {
    const displayableName = this.displayName != null && this.displayName != '' ? this.displayName : this.getFullName();
    return displayableName != null && displayableName != '' ? displayableName : this.email;
  }
}
