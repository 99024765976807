import { Pipe, PipeTransform } from '@angular/core';
import { DrawerStatusKeys } from '../keys';
import { Drawer } from '../models/drawer';

@Pipe({
  name: 'drawerStatus'
})
export class DrawerStatusPipe implements PipeTransform {

  private drawerStatusMap = new Map<string, string>([
    [DrawerStatusKeys.Started, 'Loaded'],
    [DrawerStatusKeys.Ended, 'Closed'],
    [DrawerStatusKeys.Deleted, 'Deleted'],
    [DrawerStatusKeys.Committed, 'Committed'],
    ['266d61f5-fa71-4696-bce8-73300f247231', 'Loaded'],
    ['f5f00362-70a1-424e-89ed-5857a63bee23', 'Closed'],
    ['9812e5b3-86cc-4c65-962b-aa1d3ff2e20a', 'Deleted'],
    ['d4658de1-546f-4eff-87cb-e4bf90c2da1b', 'Committed']
  ]);

  constructor(
  ) {
  }

  transform(value: Drawer | string): string {

    if (typeof (value) === 'string') {
      return value ? this.drawerStatusMap.get(value.toLocaleUpperCase()) : 'Unavailable';
    } else {
      return value ? this.drawerStatusMap.get(value.drawerStatusUid.toLocaleUpperCase()) : 'Unavailable';
    }
  }
}
