import { Observable, Observer } from 'rxjs';

/**Method to Generate a Name for the Image */
export function generateFilename(extension: string): string {

  const date: number = new Date().valueOf();
  let text: string = "";
  const possibleText: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 5; i++) {
    text += possibleText.charAt(
      Math.floor(Math.random() * possibleText.length)
    );
  }

  // Replace extension according to your media type like this
  return `${date}.${text}.${extension}`;
}

export function base64DataToBlob(base64Data: string, blobType: string): Observable<Blob> {

  return new Observable((observer: Observer<Blob>) => {
    const byteString: string = window.atob(base64Data);
    const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: blobType });

    observer.next(blob);
    observer.complete();
  });
}
