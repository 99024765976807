
export class StoreFront {

  uid: string;
  ownerUid: string;
  name: string;

  configuration: StoreFrontConfiguration;
}

export class StoreFrontConfiguration {

  logistics: LogisticSettings;
  transactions: TransactionSettings;
}

export class LogisticSettings {

  dineIn: boolean;
  carryOut: boolean;
  curbsidePickup: boolean;
  delivery: boolean;

  deliveryCharge: number;
}

export class TransactionSettings {

}
