import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TransactionSelectModalComponent } from "./transaction-select-modal.component";

@Injectable()
export class TransactionSelectModalProvider  {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data?: TransactionSelectModalData): MatDialogRef<TransactionSelectModalComponent> {

    return this.dialog.open(TransactionSelectModalComponent, {
      width: '100%',
      height: '100%',
      minWidth: '100%',
      minHeight: '100%',
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface TransactionSelectModalData {

}
