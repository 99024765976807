<ng-container *ngIf="inclusionGroupSelection">

  <div style="margin-bottom: 2px;" [ngStyle]="{ 'margin-top': inclusionGroupSelection?.name ? '0px' : '10px' }">
    <span *ngIf="productInclusionGroup.name" class="group-heading">{{ inclusionGroupSelection?.name }}</span>

    <span *ngIf="!productInclusionGroup.name && productInclusionGroup.options?.length > 1" class="group-message">Choose from this group</span>

    <span class="group-message" [ngStyle]="{ 'margin-left': inclusionGroupSelection?.name ? '.5em' : '0' }">{{ context.getProductInclusionGroupSelectMessaging(productInclusionGroup) }}</span>
  </div>

  <div style="display: flex; flex-wrap: wrap;">
    <div *ngFor="let option of inclusionGroupSelection?.options;" style="display: flex; align-items: center;" [ngStyle]="{ 'width': inclusionGroupSelection?.options.length > 6 ? '50%' : '100%' }">

      <button mat-flat-button color="accent" class="inclusion-button" style="height: var(--app-register-button-height); width: calc(var(--app-register-button-width) / 2); font-size: 200%; margin-bottom: 4px;" (click)="decrementInclusionGroupOption(option)">-</button>
      <button mat-flat-button color="accent" class="inclusion-button" [ngClass]="{ 'selected': option.selectedQuantity > 0 }" style="height: var(--app-register-button-height); width: calc(var(--app-register-button-width) / 2); font-size: 200%; margin-bottom: 4px; margin-left: 4px;">{{ option.selectedQuantity }}</button>
      <button mat-flat-button color="accent" class="inclusion-button" style="height: var(--app-register-button-height); width: calc(var(--app-register-button-width) / 2); font-size: 200%; margin-bottom: 4px; margin-left: 4px;" (click)="incrementInclusionGroupOption(option)">+</button>

      <div style="flex: 1; margin-left: 1em;">
        <div class="option-heading">{{ option.display }}</div>
        <div class="option-message">{{ getInclusionGroupOptionMessaging(option) }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="inclusionGroupSelection.allowSubstitution" style="display: flex; align-items: center;">
    <button mat-flat-button color="accent" class="inclusion-button" style="height: var(--app-register-button-height); width: calc((var(--app-register-button-width) * 1.5) + 8px); font-family: 'BebasNeue Bold', Raleway, sans-serif; font-size: 200%; margin-bottom: 4px;" (click)="selectSubstitution()">Substitute...</button>
  </div>

</ng-container>