import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CardTransaction } from "../models/card-transaction";
import { HttpService } from 'core';
import { Page } from "core";
import { TransactionModelFactory } from '../transaction-model-factory';
import { TransactionServiceSettings } from '../transaction-service-settings';

@Injectable()
export class CardTransactionService {

  public static readonly CardTransactionIndexView = <CardTransactionViewOptions>{};

  constructor(
    private httpService: HttpService,
    private transactionServiceSettings: TransactionServiceSettings
  ) {
  }

  getByUid(uid: string, viewOptions: CardTransactionViewOptions = CardTransactionService.CardTransactionIndexView): Observable<CardTransaction> {

    let view = this.buildView(viewOptions);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<CardTransaction>(this.transactionServiceSettings.apiUrl, 'api/oltp/cardTransaction', request, 'getByUid').pipe(
      map(x => TransactionModelFactory.assignCardTransaction(x))
    );
  }

  getByUids(cardTransactionUids: string[], viewOptions: CardTransactionViewOptions = CardTransactionService.CardTransactionIndexView): Observable<Page<CardTransaction>> {

    let view = this.buildView(viewOptions);

    var request = {
      query: `query { getByUids (uids: ${JSON.stringify(cardTransactionUids)}) { totalCount edges { node ${view} } } }`
    }

    return this.httpService.graph<Page<CardTransaction>>(this.transactionServiceSettings.apiUrl, 'api/oltp/cardTransaction', request, 'getByUids').pipe(
      tap(x => x.edges.forEach(edge => edge.node = TransactionModelFactory.assignCardTransaction(edge.node)))
    );
  }

  search(cardTransactionStatusUids: string[], viewOptions: CardTransactionViewOptions = CardTransactionService.CardTransactionIndexView): Observable<Page<CardTransaction>> {

    let view = this.buildView(viewOptions);

    var request = {
      query: `query { search (statusUids: ${JSON.stringify(cardTransactionStatusUids)}) { totalCount edges { node ${view} } } }`
    }

    return this.httpService.graph<Page<CardTransaction>>(this.transactionServiceSettings.apiUrl, 'api/oltp/cardTransaction', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = TransactionModelFactory.assignCardTransaction(edge.node)))
    );
  }

  create(cardTransactionUid: string, transactionUid: string, paymentUid: string, amount: number, tip: number, firstName: string, middleInitial: string, surname: string, lastFour: string, token: string, encryption: string, viewOptions: CardTransactionViewOptions = CardTransactionService.CardTransactionIndexView): Observable<CardTransaction> {

    let view = this.buildView(viewOptions);

    var cardTransactionInput = {
      uid: cardTransactionUid,
      saleUid: transactionUid,
      paymentUid: paymentUid,
      amount: amount,
      tip: tip,
      firstName: firstName,
      middleInitial: middleInitial,
      surname: surname,
      lastFour: lastFour,
      token: token,
      encryption: encryption
    };

    var request = {
      query: `mutation createCardTransaction($cardTransaction:CardTransactionInput!) { create(cardTransaction: $cardTransaction) ${view} }`,
      variables: { cardTransaction: cardTransactionInput }
    }

    return this.httpService.graph<CardTransaction>(this.transactionServiceSettings.apiUrl, 'api/oltp/cardTransaction', request, 'create').pipe(
      map(x => TransactionModelFactory.assignCardTransaction(x))
    );
  }

  updateTip(cardTransactionUid: string, tip: number, viewOptions: CardTransactionViewOptions = CardTransactionService.CardTransactionIndexView): Observable<CardTransaction> {

    let view = this.buildView(viewOptions);

    var request = {
      query: `mutation updateTip { updateTip(uid: "${cardTransactionUid}", tip: ${tip}) ${view} }`
    }

    return this.httpService.graph<CardTransaction>(this.transactionServiceSettings.apiUrl, 'api/oltp/cardTransaction', request, 'updateTip').pipe(
      map(x => TransactionModelFactory.assignCardTransaction(x))
    );
  }

  authorize(cardTransactionUid: string, processorReferenceId: string, authorizedAmount: number, viewOptions: CardTransactionViewOptions = CardTransactionService.CardTransactionIndexView): Observable<CardTransaction> {

    let view = this.buildView(viewOptions);

    var request = {
      query: `mutation authorize { authorize(uid: "${cardTransactionUid}", processorReferenceId: "${processorReferenceId}", amount: "${authorizedAmount}") ${view} }`
    }

    return this.httpService.graph<CardTransaction>(this.transactionServiceSettings.apiUrl, 'api/oltp/cardTransaction', request, 'authorize').pipe(
      map(x => TransactionModelFactory.assignCardTransaction(x))
    );
  }

  failAuthorize(cardTransactionUid: string, viewOptions: CardTransactionViewOptions = CardTransactionService.CardTransactionIndexView): Observable<CardTransaction> {

    let view = this.buildView(viewOptions);

    var request = {
      query: `mutation failAuthorize { failAuthorize(uid: '${cardTransactionUid}") ${view} }`
    }

    return this.httpService.graph<CardTransaction>(this.transactionServiceSettings.apiUrl, 'api/oltp/cardTransaction', request, 'failAuthorize').pipe(
      map(x => TransactionModelFactory.assignCardTransaction(x))
    );
  }

  capture(cardTransactionUid: string, processorReferenceId: string, authorizedAmount: number, viewOptions: CardTransactionViewOptions = CardTransactionService.CardTransactionIndexView): Observable<CardTransaction> {

    let view = this.buildView(viewOptions);

    var request = {
      query: `mutation capture { capture(uid: "${cardTransactionUid}}", processorReferenceId: "${processorReferenceId}", amount: "${authorizedAmount}") ${view} }`
    }

    return this.httpService.graph<CardTransaction>(this.transactionServiceSettings.apiUrl, 'api/oltp/cardTransaction', request, 'capture').pipe(
      map(x => TransactionModelFactory.assignCardTransaction(x))
    );
  }

  void(cardTransactionUid: string, viewOptions: CardTransactionViewOptions = CardTransactionService.CardTransactionIndexView): Observable<CardTransaction> {

    let view = this.buildView(viewOptions);

    var request = {
      query: `mutation void { void(uid: "${cardTransactionUid}") ${view} }`
    }

    return this.httpService.graph<CardTransaction>(this.transactionServiceSettings.apiUrl, 'api/oltp/cardTransaction', request, 'void').pipe(
      map(x => TransactionModelFactory.assignCardTransaction(x))
    );
  }

  refund(cardTransactionUid: string, viewOptions: CardTransactionViewOptions = CardTransactionService.CardTransactionIndexView): Observable<CardTransaction> {

    let view = this.buildView(viewOptions);

    var request = {
      query: `mutation refund { refund(uid: "${cardTransactionUid}") ${view} }`
    }

    return this.httpService.graph<CardTransaction>(this.transactionServiceSettings.apiUrl, 'api/oltp/cardTransaction', request, 'refund').pipe(
      map(x => TransactionModelFactory.assignCardTransaction(x))
    );
  }

  public buildView(viewOptions: CardTransactionViewOptions) {

    let view = `uid transactionUid paymentUid amount tip firstName middleInitial surname lastFour token encryption processorReferenceId authorizedAmount cardTransactionStatusUid modifiedDateTimeUtc`;

    return '{' + view + '}';
  }
}

export interface CardTransactionViewOptions {

}
