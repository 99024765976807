import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { SelectSystemMessageTypeModalData, SelectSystemMessageTypeModalResult } from "./select-system-message-type-modal.provider";
import { AuthHeaderProvider } from "core";
import { SystemMessageTypeUids } from "../../keys";
import { TerminalService } from "downmain-terminal";

@Component({
  selector: 'app-select-system-message-type-modal',
  templateUrl: './select-system-message-type-modal.component.html',
  styleUrls: ['./select-system-message-type-modal.component.scss']
})
export class SelectSystemMessageTypeModalComponent {

  public systemMessageTypes: SystemMessageTypeModel[];
  public terminals: TerminalModel[];
  public form: UntypedFormGroup;

  constructor(
    private terminalService: TerminalService,
    private authHeaderProvider: AuthHeaderProvider,
    private dialogRef: MatDialogRef<SelectSystemMessageTypeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectSystemMessageTypeModalData
  ) {
    this.form = new UntypedFormGroup({
      systemMessageTypeUid: new UntypedFormControl(null, [Validators.required]),
      terminalUid: new UntypedFormControl(null),
    });
  }

  ngOnInit(): void {

    this.systemMessageTypes = [
      <SystemMessageTypeModel>{ systemMessageTypeUid: SystemMessageTypeUids.KickCashDrawer, display: 'Kick Cash Drawer' },
      <SystemMessageTypeModel>{ systemMessageTypeUid: SystemMessageTypeUids.OrderSubmitted, display: 'Order Submitted' }
    ];
    this.terminalService.search([], null, this.authHeaderProvider).subscribe(terminalsPage => {
      if (terminalsPage) {
        this.terminals = terminalsPage.edges.map(x => x.node).map(x => <TerminalModel>{ terminalUid: x.uid, name: x.name });
      }
    });
  }

  create() {

    this.dialogRef.close(<SelectSystemMessageTypeModalResult>{ systemMessageTypeUid: this.form.controls['ystemMessageTypeUid'].value, terminalUid: this.form.controls['terminalUid'].value });
  }

  cancel() {

    this.dialogRef.close();
  }
}

interface SystemMessageTypeModel {
  systemMessageTypeUid: string,
  display: string
}

interface TerminalModel {
  terminalUid: string,
  name: string
}
