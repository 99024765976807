import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef, DialogPosition } from '@angular/material/dialog';
import { CardSwipe } from "../../models/card-swipe";
import { Observable } from "rxjs";
import { TextBoxModalComponent } from "./textbox-modal/textbox-modal.component";
import { KeyboardModalComponent } from "./keyboard-modal/keyboard-modal.component";
import { TextEntryModalComponent } from "./text-entry-modal.component";

@Injectable()
export class TextEntryModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public openSoftKeyboard(data: TextEntryModalData): MatDialogRef<KeyboardModalComponent, TextEntryModalResult> {

    return this.dialog.open<KeyboardModalComponent, TextEntryModalData, TextEntryModalResult>(KeyboardModalComponent, {
      position: { bottom: '10px' },
      minWidth: '100vw',
      width: '100vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }

  public openTextBox(data: TextEntryModalData): MatDialogRef<TextBoxModalComponent, TextEntryModalResult> {

    return this.dialog.open<TextBoxModalComponent, TextEntryModalData, TextEntryModalResult>(TextBoxModalComponent, {
      position: { top: '10px' },
      minWidth: '60vw',
      width: '60vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }

  public open(data: TextEntryModalData, style: TextEntryStyleEnum): MatDialogRef<TextEntryModalComponent, TextEntryModalResult> {

    if (style == TextEntryStyleEnum.SoftKeyboard) {
      return this.openSoftKeyboard(data);
    } else {
      return this.openTextBox(data);
    }
  }
}

export enum TextEntryStyleEnum {

  SoftKeyboard = 'SoftKeyboard',
  Textbox = 'Textbox'
}

export interface TextEntryModalData {

  title: string,
  initialValue?: string;
  maxLength?: number;
  cardSwipeEvent$?: Observable<CardSwipe>;
  showDevCardSwipe?: boolean;
}

export interface TextEntryModalResult {

  textEntry: string;
  cardSwipe?: CardSwipe;
}
