<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="memberUid">
    <th mat-header-cell *matHeaderCellDef color="accent">Uid</th>
    <td mat-cell *matCellDef="let member">
      <div>{{ member.uid }}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="member">
    <th mat-header-cell *matHeaderCellDef color="accent">Member</th>
    <td mat-cell *matCellDef="let member">
      <!-- <div>{{ member.uid }}</div> -->
      <div><span>First: </span>{{ member.firstName }}</div>
      <div><span>Last: </span>{{ member.lastName }}</div>
      <div><span>Display: </span>{{ member.displayName }}</div>
      <div><span>Email: </span>{{ member.email }}</div>
      <div><span>Status: </span>{{ member.memberStatusUid | memberStatus }}</div>
      <div>
        <!-- <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canEdit" (click)="profile(member)">
          <mat-icon>edit_outline</mat-icon>
        </button> -->
        <!-- <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canEnable" (click)="enable(member)">
          <mat-icon>toggle_on</mat-icon>
        </button>
        <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canDisable" (click)="disable(member)">
          <mat-icon>toggle_off</mat-icon>
        </button> -->
        <!-- <button mat-icon-button color="warn" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canDelete" (click)="delete(member)">
          <mat-icon>delete_outline</mat-icon>
        </button> -->
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="identity">
    <th mat-header-cell *matHeaderCellDef>Identity/Roles</th>
    <td mat-cell *matCellDef="let member">
      <!-- <div>{{ member.identityId }}</div> -->
      <div style="display: flex;">
        <div style="flex: 1;">
          <div *ngFor="let role of member.roles">
            <span>{{ role }}</span>
            <!-- <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canEdit" (click)="removeRole(member, role)">
              <mat-icon>remove_circle</mat-icon>
            </button> -->
          </div>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="employee">
    <th mat-header-cell *matHeaderCellDef>Employee</th>
    <td mat-cell *matCellDef="let member">
      <!-- <div>{{ member.employeeUid }}</div> -->
      <div>{{ member.employeeFullName }}</div>
      <div>{{ member.employeeStatusUid | employeeStatus }}</div>
      <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canLinkIdentity" (click)="linkEmployee(member)">
        <mat-icon>link</mat-icon>
      </button>
      <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canUnlinkIdentity" (click)="unlinkEmployee(member)">
        <mat-icon>link_off</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>

</table>


