import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";

export function getDescendentRouteSnapshot(activatedRoute: ActivatedRoute): ActivatedRouteSnapshot {

  let route = activatedRoute.snapshot;
  while (route.firstChild) {
    route = route.firstChild;
  }

  return route;
}