import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ActionBarConfiguration, ActionConfiguration, FilterConfiguration, FilterOptionConfiguration } from 'core';
import { Paginator } from 'core';
import { CheckListModalProvider } from 'core';
import { ConfirmModalProvider } from 'core';
import { SelectListModalProvider } from 'core';
import { SpinnerModalComponent } from 'core';
import { SpinnerModalProvider } from 'core';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { TerminalDataSource, TerminalRowModel, TerminalService, TerminalStatusEnum } from 'downmain-terminal';
import { AuthEmployeeProvider } from 'pos-core';
import { EmployeeService } from 'pos-core';
import { IdentityUserService } from 'pos-core';

@Component({
  selector: 'app-terminals',
  templateUrl: './terminals.component.html',
  styleUrls: ['./terminals.component.scss']
})
export class TerminalsComponent implements OnInit {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: TerminalDataSource;
  public paginator: Paginator;

  public title: string;
  public columns: string[];

  private destroyed$ = new Subject();

  constructor(
    private navigationProvider: NavigationProvider,
    private authEmployeeProvider: AuthEmployeeProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private checkListModalProvider: CheckListModalProvider,
    private selectListModalProvider: SelectListModalProvider,
    private terminalService: TerminalService,
    private employeeService: EmployeeService,
    private identityUserService: IdentityUserService
  ) {
    this.navigationProvider.setWaypoint('Terminals');
    this.title = 'Terminals';

    this.dataSource = new TerminalDataSource(this.terminalService, false);
    this.paginator = new Paginator(this.dataSource, 0, 25, '', 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('New', this.new.bind(this)),
        new ActionConfiguration('Edit', () => this.edit(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Accept', () => this.accept(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canAccept)),
        new ActionConfiguration('Reject', () => this.reject(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canReject)),
      ],
      [
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('Active', [TerminalStatusEnum.Online, TerminalStatusEnum.BindTerminalRequested]),
            new FilterOptionConfiguration('All', [TerminalStatusEnum.Online, TerminalStatusEnum.Offline, TerminalStatusEnum.BindTerminalRequested, TerminalStatusEnum.BindTerminalRejected])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'Active')) }
        )
      ]
    );

    // Build initial columns
    this.columns = ['name', 'description', 'type', 'deviceId', 'defaultDrawerSeedAmount', 'createDateTimeUtc', 'status'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    combineLatest([
      this.actionBarConfiguration.filters.find(x => x.title == 'Status').selected,
      this.paginator.pageRequest$
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([statusFilterOption, paginationInput]) => {
        let statusFilterUids = statusFilterOption.value;

        this.dataSource.loadData(statusFilterUids, paginationInput);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }

  new() {

    this.navigationProvider.navigate(['/admin/terminal']);
  }

  edit(terminal: TerminalRowModel) {

    this.navigationProvider.navigate(['/admin/terminal', terminal.terminal.uid]);
  }

  accept(terminal: TerminalRowModel) {

    this.confirmModalProvider.open('Confirm Accept Terminal Bind', 'Are you certain you want to accept and bind this terminal to this account?').afterClosed().subscribe(result => {
      if (result) {
        this.dataSource.accept(terminal).subscribe(terminalRow => {

        });
      }
    })
  }

  reject(terminal: TerminalRowModel) {

    this.confirmModalProvider.open('Confirm Reject Terminal Bind', 'Are you certain you want to reject binding this terminal to this account?').afterClosed().subscribe(result => {
      if (result) {
        this.dataSource.reject(terminal).subscribe(terminalRow => {

        });
      }
    })
  }
}
