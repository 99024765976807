import { TimeoutProvider, MessageModalProvider, NavigationProvider, PaymentMethodKeys, SettingProvider, SystemMessageProvider } from "core";
import { EditableTransaction } from "downtown-transaction";
import { InjectionToken } from "@angular/core";
import { PrintDepartmentSlipModalProvider, TransactionItemPrintModalProvider } from "../modals";
import { Observable } from "rxjs";
import { getPrintLocationHandler$ } from "../handlers/get-print-location-handler";
import { TerminalProvider } from "downmain-terminal";

export let REGISTER_HANDLER_PROVIDER_FACTORY = new InjectionToken<() => RegisterFeatureProvider>('register-handler-provider');

export abstract class RegisterFeatureProvider {

  public destroy() { }

  public abstract navigateHome(navigationProvider: NavigationProvider): void;

  public abstract navigateToBackOffice(navigationProvider: NavigationProvider): void;

  public abstract navigateToTransaction(navigationProvider: NavigationProvider, transactionUid: string): void;

  public abstract printCheck$(transactionUid: string): Observable<boolean>;

  public abstract printReceipt$(transactionUid: string): Observable<boolean>;

  public abstract printDepartmentSlip$(
    editableTransaction: EditableTransaction,
    departmentUid: string,
    printDepartmentSlipModalProvider: PrintDepartmentSlipModalProvider,
    transactionItemPrintModalProvider: TransactionItemPrintModalProvider,
    messageModalProvider: MessageModalProvider,
    timeoutProvider: TimeoutProvider,
    systemMessageProvider: SystemMessageProvider,
    settingProvider: SettingProvider,
    terminalProvider: TerminalProvider
  ): Observable<boolean>;

  public getPrintLocation$ = getPrintLocationHandler$;

  public abstract openCashDrawer$(systemMessageProvider: SystemMessageProvider): Observable<boolean>;
}