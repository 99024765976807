import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Lock } from '../models/lock';
import { LockResolver } from '../resolvers/lock-resolver';

@Pipe({
  name: 'lockResolver'
})
export class LockResolverPipe implements PipeTransform {

  constructor(
    private lockResolver: LockResolver
  ) {
  }

  transform(value: string): Observable<Lock> {

    return value ? this.lockResolver.resolve(value) : of(null);
  }
}
