import { Pipe, PipeTransform } from '@angular/core';
import { CardTransaction2StatusKeys } from '../keys';

@Pipe({
  name: 'cardTransaction2Status'
})
export class CardTransaction2StatusPipe implements PipeTransform {

  private map = new Map<string, string>([
    [CardTransaction2StatusKeys.Authorized.toUpperCase(), 'Authorized'],
    [CardTransaction2StatusKeys.Captured.toUpperCase(), 'Captured'],
    [CardTransaction2StatusKeys.Refunded.toUpperCase(), 'Refunded'],
    [CardTransaction2StatusKeys.Voided.toUpperCase(), 'Voided'],
  ]);

  constructor(
  ) {
  }

  transform(value: string): string {

    if (typeof (value) === 'string') {
      return value ? this.map.get(value.toUpperCase()) : null;
    } else {
      return null;
    }
  }
}
