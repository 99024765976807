import { Component, OnInit } from '@angular/core';
import { Cart } from '../../models/cart';
import { Subject, takeUntil } from 'rxjs';
import { CartProvider } from '../../providers/cart.provider';

@Component({
  selector: 'store-core-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  // @Input() public cart: EditableCart;
  // @Input() canEdit: boolean;

  public cart: Cart;

  private destroyed$ = new Subject();

  constructor(
    private cartProvider: CartProvider
  ) {
  }

  ngOnInit() {

    this.cartProvider.currentCart$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(cart => {
      this.cart = cart;

    })
 }

 ngOnDestroy() {

  this.destroyed$.next(null);
}

  // public selectItem(item: EditableCartItem) {

  //   if (this.cart.selection.value == item) {
  //     // Deselect
  //     this.cart.selection.next(null);
  //   } else {
  //     // Select
  //     this.cart.selection.next(item);
  //   }
  // }

  // remove(cartItem: EditableCartItem) {

  //   this.cartService.removeCartItem(cartItem.uid, this.cart.uid).subscribe(updatedCart => {
  //     if (updatedCart) {
  //       if (!updatedCart.items.find(x => x.uid == cartItem.uid)) {
  //         this.cart.items = this.cart.items.filter(x => x.uid != cartItem.uid);
  //       }
  //     }
  //   });
  // }
}
