import { BehaviorSubject, Subscription } from 'rxjs';
import { TransactionEventType } from './transaction-event-type';
import { TransactionLine } from './transaction-line';
import { TransactionEvent } from './transaction-event';
import { TransactionSaleLine } from './transaction-sale-line';
import { TransactionAdjustmentLine } from './transaction-adjustment-line';
import { TransactionPaymentLine } from './transaction-payment-line';
import { TransactionStatusEnum } from '../../keys';
import { TransactionDisbursementLine } from './transaction-disbursement-line';
import Decimal from 'decimal.js';
import { isCaseInsensitiveEqual } from 'core';

export class TransactionEditModel {

  onEvent = new BehaviorSubject<TransactionEvent>(null);

  uid: string;
  number: string;
  lockTerminalUid: string;
  transactionStatusUid = new BehaviorSubject<string>(null);
  notes: string;
  totalSale = new BehaviorSubject<number>(null);
  totalPayments = new BehaviorSubject<number>(null);
  change = new BehaviorSubject<number>(null);
  totalDue = new BehaviorSubject<number>(null);

  private currentLineItem = new BehaviorSubject<TransactionLine>(null);
  private subscriptions = new Map<TransactionLine, Subscription[]>();

  constructor() {

    // this.currentLineItem.subscribe(x => {
    //     console.log('currentLineItem: ' + (x ? x.typeName : 'null'));
    // });
    this.transactionStatusUid.subscribe(x => {
      if (x && isCaseInsensitiveEqual(x, TransactionStatusEnum.Closed)) {
        this.currentLineItem.next(null);
      }
    });
  }

  private saleLineItemList = <TransactionSaleLine[]>[];
  private saleAdjustmentsList = <TransactionAdjustmentLine[]>[];
  private paymentsList = <TransactionPaymentLine[]>[];
  private disbursementsList = <TransactionDisbursementLine[]>[];

  public get lineItems(): TransactionSaleLine[] {
    return this.saleLineItemList;
  }

  public get adjustments(): TransactionAdjustmentLine[] {
    return this.saleAdjustmentsList;
  }

  public get payments(): TransactionPaymentLine[] {
    return this.paymentsList;
  }

  public getCurrentLineItem(): TransactionLine {
    return this.currentLineItem.value;
  }

  public setCurrentLineItem(transactionLine: TransactionLine) {

    let currentLineItem = this.currentLineItem.value;

    if (transactionLine != currentLineItem) {
      if (currentLineItem != null && currentLineItem.isEditing) {

        currentLineItem.commit();
        this.currentLineItem.next(transactionLine);
      } else {
        this.currentLineItem.next(transactionLine);
      }
    }
  }

  public createSaleLine(): TransactionSaleLine {

    let transactionSaleLine = new TransactionSaleLine();
    transactionSaleLine.quantityText.next('1.000');
    transactionSaleLine.eachAmountText.next(new Decimal(0).toFixed(2));

    return transactionSaleLine;
  }

  public addSaleLine(transactionSaleLine: TransactionSaleLine): TransactionSaleLine {

    this.saleLineItemList.push(transactionSaleLine);

    let subscriptions = new Array<Subscription>();

    subscriptions.push(transactionSaleLine.total.subscribe(x => {
      this.updateOrderTotal();
    }));
    subscriptions.push(transactionSaleLine.onEvent.subscribe((event: TransactionEvent) => {
      if (event) {
        // Perform any local handling
        if (event.eventType == TransactionEventType.Editing) {
          this.currentLineItem.next(event.transactionLine);
        }

        if (event.eventType == TransactionEventType.Cancelled) {
          this.currentLineItem.next(null);
        }

        if (event.eventType == TransactionEventType.Committed) {
          this.currentLineItem.next(null);
        }

        if (event.eventType == TransactionEventType.Voided) {
          this.currentLineItem.next(null);
        }

        // Bubble up
        this.onEvent.next(event);
      }
    }));

    this.subscriptions.set(transactionSaleLine, subscriptions);

    return transactionSaleLine;
  }

  public removeSaleLine(transactionSaleLine: TransactionSaleLine) {

    this.saleLineItemList.splice(this.saleLineItemList.indexOf(transactionSaleLine), 1);

    let subscriptions = this.subscriptions.get(transactionSaleLine);
    subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public createAdjustment(isVoid: boolean): TransactionAdjustmentLine {

    let adjustment = new TransactionAdjustmentLine();
    adjustment.description = isVoid ? 'Void' : 'Comp';
    adjustment.amountText.next(new Decimal(0).toFixed(2));
    adjustment.isVoid.next(isVoid);
    adjustment.isCompensated.next(!isVoid);

    return adjustment;
  }

  public addAdjustment(transactionAdjustmentLine: TransactionAdjustmentLine) {

    // If adjustment is a void, remove all other adjustments
    if (transactionAdjustmentLine.isVoid.value) {
      this.saleAdjustmentsList.forEach(adjustment => {
        this.removeAdjustment(adjustment);
      });
    }

    this.saleAdjustmentsList.push(transactionAdjustmentLine);

    let subscriptions = new Array<Subscription>();

    subscriptions.push(transactionAdjustmentLine.total.subscribe(x => {
      this.updateOrderTotal();
    }));
    subscriptions.push(transactionAdjustmentLine.onEvent.subscribe((event: TransactionEvent) => {
      if (event) {
        // Perform any local handling
        if (event.eventType == TransactionEventType.Editing) {
          this.currentLineItem.next(event.transactionLine);
        }

        if (event.eventType == TransactionEventType.Cancelled) {
          this.currentLineItem.next(null);
        }

        if (event.eventType == TransactionEventType.Committed) {
          this.currentLineItem.next(null);
        }

        if (event.eventType == TransactionEventType.Voided) {
          this.currentLineItem.next(null);
        }

        // Bubble up
        this.onEvent.next(event);
      }
    }));

    this.subscriptions.set(transactionAdjustmentLine, subscriptions);

    return transactionAdjustmentLine;
  }

  public removeAdjustment(transactionAdjustmentLine: TransactionAdjustmentLine) {

    this.saleAdjustmentsList.splice(this.saleAdjustmentsList.indexOf(transactionAdjustmentLine), 1);

    let subscriptions = this.subscriptions.get(transactionAdjustmentLine);
    subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public createPaymentLine(): TransactionPaymentLine {

    let transactionPaymentLine = new TransactionPaymentLine();
    transactionPaymentLine.amountText.next(new Decimal(0).toFixed(2));
    transactionPaymentLine.paymentMethodUid.next(null);

    return transactionPaymentLine;
  }

  public addPaymentLine(transactionPaymentLine: TransactionPaymentLine): TransactionPaymentLine {

    this.paymentsList.push(transactionPaymentLine);

    let subscriptions = new Array<Subscription>();

    subscriptions.push(transactionPaymentLine.amountText.subscribe(x => {
      this.updateOrderTotal();
    }));
    subscriptions.push(transactionPaymentLine.onEvent.subscribe((event: TransactionEvent) => {
      if (event) {
        // Perform any local handling
        if (event.eventType == TransactionEventType.Editing) {
          this.currentLineItem.next(event.transactionLine);
        }

        if (event.eventType == TransactionEventType.Cancelled) {
          this.currentLineItem.next(null);
        }

        if (event.eventType == TransactionEventType.Committed) {
          this.currentLineItem.next(null);
        }

        if (event.eventType == TransactionEventType.Voided) {
          this.currentLineItem.next(null);
        }

        // Bubble up
        this.onEvent.next(event);
      }
    }));

    this.subscriptions.set(transactionPaymentLine, subscriptions);

    return transactionPaymentLine;
  }

  public removePayment(transactionPaymentLine: TransactionPaymentLine) {

    this.paymentsList.splice(this.paymentsList.indexOf(transactionPaymentLine), 1);

    let subscriptions = this.subscriptions.get(transactionPaymentLine);
    subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.updateOrderTotal();
  }

  public createDisbursementLine(): TransactionDisbursementLine {

    let transactionDisbursementLine = new TransactionDisbursementLine();
    transactionDisbursementLine.amountText.next(new Decimal(0).toFixed(2));
    transactionDisbursementLine.paymentMethodUid.next(null);

    return transactionDisbursementLine;
  }

  public addDisbursementLine(transactionDisbursementLine: TransactionDisbursementLine): TransactionDisbursementLine {

    this.disbursementsList.push(transactionDisbursementLine);

    let subscriptions = new Array<Subscription>();

    subscriptions.push(transactionDisbursementLine.amountText.subscribe(x => {
      this.updateOrderTotal();
    }));
    subscriptions.push(transactionDisbursementLine.onEvent.subscribe((event: TransactionEvent) => {
      if (event) {
        // Perform any local handling
        if (event.eventType == TransactionEventType.Editing) {
          this.currentLineItem.next(event.transactionLine);
        }

        if (event.eventType == TransactionEventType.Cancelled) {
          this.currentLineItem.next(null);
        }

        if (event.eventType == TransactionEventType.Committed) {
          this.currentLineItem.next(null);
        }

        if (event.eventType == TransactionEventType.Voided) {
          this.currentLineItem.next(null);
        }

        // Bubble up
        this.onEvent.next(event);
      }
    }));

    this.subscriptions.set(transactionDisbursementLine, subscriptions);

    return transactionDisbursementLine;
  }

  updateOrderTotal() {

    let orderTotal = this.saleLineItemList.reduce((a, b) => { return a + b.total.value + b.adjustments.reduce((c, d) => { return c + d.total.value; }, 0); }, 0);
    this.totalSale.next(orderTotal);

    this.totalPayments.next(this.paymentsList.reduce((a, b) => { return a + b.getAmount(); }, 0));

    var balance = orderTotal + this.saleAdjustmentsList.reduce((a, b) => { return a + b.total.value }, 0) - this.totalPayments.value;

    this.change.next(balance < 0 ? -balance : null);
    this.totalDue.next(balance >= 0 ? balance : null);
  }
}
