import { Component, ViewChild } from "@angular/core";
import { BehaviorSubject, finalize, concatMap, tap } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { NavigationProvider, UidFilterModel } from "core";
import { WaitModalProvider } from "core";
import { ReportService } from "pos-core";
import { PaymentMethodKeys } from "core";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-back-office-pay-period-report',
  templateUrl: './back-office-pay-period-report.component.html',
  styleUrls: ['./back-office-pay-period-report.component.scss']
})
export class BackOfficePayPeriodReportComponent {

  public paymentMethodFilter = new BehaviorSubject<UidFilterModel>(null);
  public paymentMethodFilters: UidFilterModel[];

  // public pagination = <PaginationInput>{ pageSize: 10000, pageIndex: 0, sortField: 'clockInDateTimeUtc', sortStrategy: 'asc' };
  // public employees = new BehaviorSubject<EmployeeModel[]>(null);
  // public title: string;
  // public startDateTime: Date;
  // public endDateTime: Date;
  // public reportTotal: number;

  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand: any;

  public title: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private waitModalProvider: WaitModalProvider,
    private reportService: ReportService,

    private http: HttpClient
  ) {
    this.navigationProvider.setWaypoint('Pay Period Report');
    this.title = 'Pay Period Report';

    this.paymentMethodFilters = [
      <UidFilterModel>{ name: 'Cash', uids: [PaymentMethodKeys.Cash] },
      <UidFilterModel>{ name: 'Check', uids: [PaymentMethodKeys.Check] },
      <UidFilterModel>{ name: 'All', uids: null }
    ];
  }

  ngOnInit() {

    let dialogRef = this.waitModalProvider.open('Generating Report...');
    dialogRef.afterOpened().subscribe(() => {

      const payPeriodUid = this.activatedRoute.snapshot.params['payPeriodUid'];

      this.reportService.payPeriod(payPeriodUid).pipe(
        concatMap(result => {
          return this.http.get(result.url, { responseType: 'blob' })
        }),
        tap(result => {
          this.pdfViewerOnDemand.pdfSrc = result; // pdfSrc can be Blob or Uint8Array
          this.pdfViewerOnDemand.refresh(); // Ask pdf viewer to load/refresh pdf
        }),
        finalize(() => dialogRef.close())
      ).subscribe();
    });
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }
  
  // private refresh() {

  //   let dialogRef = this.waitModalProvider.open('Generating Report...');
  //   dialogRef.afterOpened().subscribe(() => {
  //     this.activatedRoute.params.subscribe(params => {

  //       let payPeriodUid = params['payPeriodUid'];
  //       let paymentMethodUids = this.paymentMethodFilter.value.uids;

  //       combineLatest([
  //         this.payPeriodService.getByUid(payPeriodUid),
  //         this.employeeService.search(null, null, this.pagination, EmployeeService.EmployeeFullView),
  //         this.employeeShiftService.search(null, null, paymentMethodUids, [payPeriodUid], null, [EmployeeShiftStatusKeys.PaymentScheduled, EmployeeShiftStatusKeys.PaymentMade], this.pagination, <EmployeeShiftViewOptions>{ includeEmployee: true, includePosition: true }),
  //         this.employeePayService.search(null, null, [payPeriodUid], paymentMethodUids, [EmployeePayStatusKeys.Scheduled, EmployeePayStatusKeys.Delivered], this.pagination, EmployeePayService.EmployeePayFullView)
  //       ]).subscribe(([payPeriod, employeesPage, employeeShiftsPage, employeePaysPage]) => {
  //         this.title = 'Employee Pay Period Report';

  //         this.startDateTime = payPeriod.startDateTimeUtc;
  //         this.endDateTime = payPeriod.endDateTimeUtc;

  //         // Group shifts and pays by employee
  //         var employeeShiftDictionary = employeeShiftsPage.edges.map(x => x.node).reduce((employeeShiftDictionary, employeeShift) => {
  //           var employeeUid = employeeShift.employeeUid;

  //           var employee = (employeeShiftDictionary[employeeUid] = employeeShiftDictionary[employeeUid] || <EmployeeShift[]>[]);
  //           employee.push(employeeShift);

  //           return employeeShiftDictionary;
  //         }, <{ [employeeUid: string]: EmployeeShift[] }>{});

  //         var employeePayDictionary = employeePaysPage.edges.map(x => x.node).reduce((employeePayDictionary, employeePay) => {
  //           var employeeUid = employeePay.employeeUid;

  //           var employee = (employeePayDictionary[employeeUid] = employeePayDictionary[employeeUid] || <EmployeePay[]>[]);
  //           employee.push(employeePay);

  //           return employeePayDictionary;
  //         }, <{ [employeeUid: string]: EmployeePay[] }>{});

  //         var employees = <EmployeeModel[]>[];

  //         Object.entries(employeePayDictionary).forEach(([employeeUid, employeePays]) => {

  //           var employee = employees.find(x => x.uid == employeeUid);
  //           if (employee == null) {
  //             var entity = employeesPage.edges.map(x => x.node).find(x => x.uid == employeeUid);

  //             employee = <EmployeeModel>{
  //               uid: entity.uid,
  //               firstName: entity.firstName,
  //               lastName: entity.lastName,
  //               displayName: entity.getReportName()
  //             };

  //             employees.push(employee);
  //           }

  //           // Build pay details
  //           employee.payDetails = employeePays.map(x => {
  //             return <PayDetailModel>{
  //               position: x.position.name,
  //               workUnits: this.round(x.workUnits, 2),
  //               workUnitUid: x.workUnitUid,
  //               rate: x.rate,
  //               total: this.round(x.total, 2)
  //             };
  //           }).sort((x, y) => x.position.localeCompare(y.position));

  //           // Build position details from pay details, group by position
  //           var positionPayDictionary = employee.payDetails.reduce((positionPayDictionary, positionPay) => {
  //             var positionUid = positionPay.position;

  //             var position = (positionPayDictionary[positionUid] = positionPayDictionary[positionUid] || <PayDetailModel[]>[]);
  //             position.push(positionPay);

  //             return positionPayDictionary;
  //           }, <{ [position: string]: PayDetailModel[] }>{});

  //           employee.positionDetails = Object.keys(positionPayDictionary).map(x => {
  //             var elements = positionPayDictionary[x];

  //             return <PositionDetailModel>{
  //               position: elements[0].position,
  //               rate: elements[0].rate,
  //               workUnits: elements.reduce((x, y) => x + y.workUnits, 0),
  //               workUnitUid: elements[0].workUnitUid,
  //               total: elements.reduce((x, y) => x + y.total, 0)
  //             };
  //           });

  //           employee.employeeHoursTotal = employee.payDetails.reduce((x, y) => x + y.workUnits, 0);
  //           employee.employeePayTotal = this.round(employee.payDetails.reduce((x, y) => x + y.total, 0), 2);

  //           var employeeShifts = employeeShiftDictionary[employee.uid];
  //           if (employeeShifts) {
  //             // Build shift details
  //             employee.shiftDetails = employeeShifts.map(x => {
  //               return <ShiftDetailsModel>{
  //                 startDateTime: x.clockInDateTimeUtc,
  //                 endDateTime: x.clockOutDateTimeUtc,
  //                 positionUid: x.position.uid,
  //                 position: x.position.name,
  //                 workUnits: getDuration(x.clockInDateTimeUtc, x.clockOutDateTimeUtc).toNumber(),
  //                 workUnitUid: 'b5603ba3-fae1-496c-aa3d-fe8764922691', // Always hours
  //               };
  //             }).sort((x, y) => Math.sign(x.startDateTime.getTime() - y.endDateTime.getTime()));
  //           }

  //           return employee;
  //         });

  //         this.employees.next(employees.sort((x, y) => {
  //           return (x.lastName || '').concat(x.firstName || '').localeCompare((y.lastName || '').concat((x.firstName || '')));
  //         }));

  //         this.reportTotal = this.round(employees.reduce((x, y) => x + y.employeePayTotal, 0), 2)

  //         dialogRef.close();
  //       });
  //     });
  //   });
  // }

  public selectPaymentMethodFilter(filter: UidFilterModel) {

    this.paymentMethodFilter.next(filter);
  }
}
