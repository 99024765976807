import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TextEntryModalData, TextEntryModalResult } from "../text-entry-modal.provider";
import { TextEntryModalComponent } from "../text-entry-modal.component";
import { takeUntil } from "rxjs";
import { LoggingProvider, SystemMessageProvider } from "../../../providers";

@Component({
  selector: 'app-textbox-modal',
  templateUrl: './textbox-modal.component.html',
  styleUrls: ['./textbox-modal.component.scss']
})
export class TextBoxModalComponent extends TextEntryModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) data: TextEntryModalData,
    dialogRef: MatDialogRef<TextBoxModalComponent, TextEntryModalResult>,
    loggingProvider: LoggingProvider,
    systemMessageProvider: SystemMessageProvider
  ) {
    super(data, dialogRef, loggingProvider, systemMessageProvider);

    if (this.data.cardSwipeEvent$) {
      this.data.cardSwipeEvent$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(cardSwipe => this.handleCardSwipe(cardSwipe));
    }
  }

  ngOnDestroy() {

    this.destroyed$.next();
  }
}
