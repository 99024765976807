import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ceiling'
})
export class CeilingPipe implements PipeTransform {

  constructor(
  ) {
  }

  transform(value: number): number {

    return value ? Math.ceil(value) : null;
  }
}
