<div class="editor" *ngIf="context">

  <div style="flex: 1 1 auto; display: flex;">
    <transaction-item-configurator-detail style="flex: 3;" [context]="context" (configurationChanged)="handleConfigurationChanged()"></transaction-item-configurator-detail>
    <div style="height: 100%; width: 1px; background-color: gray;"></div>
    <transaction-item-configurator-mods style="flex: 2;" [context]="context" (configurationChanged)="handleConfigurationChanged()"></transaction-item-configurator-mods>
  </div>

  <div style="display: flex; border-top: 1px solid #000; margin-left: 2px; margin-right: 2px;">

    <span class="total-headings" style="width: 80%; text-align: right;">Total</span>
    <span class="total-headings" style="width: 20%; text-align: right;">{{ eachAmount | async | currency:'USD':'symbol':'1.2-2' }}</span>

  </div>

</div>