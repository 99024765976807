import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DecimalModalProvider } from "core";
import { RegisterDecimalModalComponent } from "./register-decimal-modal.component";

@Injectable()
export class RegisterDecimalModalProvider extends DecimalModalProvider {

  constructor(
    dialog: MatDialog
  ) {
    super(dialog);
  }

  public open(data: DecimalModalData): MatDialogRef<RegisterDecimalModalComponent, number> {

    return this.dialog.open<RegisterDecimalModalComponent, DecimalModalData, number>(RegisterDecimalModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface DecimalModalData {
  title: string,
  initialValue: number
}
