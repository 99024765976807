import { TransactionEventType } from './transaction-event-type';
import { TransactionLine } from './transaction-line';

export class TransactionEvent {

    transactionLine: TransactionLine;
    eventType: TransactionEventType;
    onSuccess: () => any;

    constructor(transactionLine: TransactionLine, eventType: TransactionEventType, onSuccess: () => any) {

        this.transactionLine = transactionLine;
        this.eventType = eventType;
        this.onSuccess = onSuccess || function () { };
    }
}
