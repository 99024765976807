import { Injectable } from '@angular/core';
import { PaginationInput } from 'core';
import { ObservableCacheProvider } from 'core';
import { Department } from '../models/department';
import { DepartmentService } from '../services/department.service';
import { BehaviorSubject, Observable, of } from "rxjs";
import { shareReplay } from 'rxjs/operators';
import { DepartmentStatusKeys } from '../keys';
import { TenantProvider } from 'core';

/**
 * Provider meant to be a cache enabled alternative to calling ProductDepartmentService directly, with additional members for getting ProductDepartment in different states (ie: Active).
 */
@Injectable()
export class DepartmentProvider {

  public currentActive$: Observable<Department[]>;

  private currentActiveSubject = new BehaviorSubject<Department[]>(null);

  constructor(
    private cacheProvider: ObservableCacheProvider,
    private tenantProvider: TenantProvider,
    private departmentService: DepartmentService,
  ) {
    this.currentActive$ = this.currentActiveSubject.asObservable();

    this.departmentService.search(this.tenantProvider.currentUid, [DepartmentStatusKeys.Active], <PaginationInput>{ pageIndex: 0, pageSize: 100000 }, DepartmentService.DepartmentFullView).subscribe(departmentsPage => {

      const departments = departmentsPage.edges.map(x => x.node);
      departments.forEach(department => {
        this.cacheProvider.update(this.getCacheKey(department.uid), () => of(department).pipe(shareReplay(1)));
      });
    });
  }

  public getOneCached$(uid: string): Observable<Department> {

    return this.cacheProvider.getOrAdd(this.getCacheKey(uid), () => this.getOne$(uid))
  }

  public getMany$(uids: string[]): Observable<Department[]> {

    return this.departmentService.getByUids(uids);
  }

  public getOne$(uid: string): Observable<Department> {

    return this.departmentService
      .getByUid(uid, DepartmentService.DepartmentFullView)
      .pipe(shareReplay(1));
  }

  private getCacheKey(uid: string): string {

    return `Department_${uid}`;
  }
}
