<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="last">
    <th mat-header-cell *matHeaderCellDef color="accent">Last</th>
    <td mat-cell *matCellDef="let employee">{{ employee.employee.lastName }}</td>
  </ng-container>

  <ng-container matColumnDef="first">
    <th mat-header-cell *matHeaderCellDef>First</th>
    <td mat-cell *matCellDef="let employee">{{ employee.employee.firstName }}</td>
  </ng-container>

  <ng-container matColumnDef="display">
    <th mat-header-cell *matHeaderCellDef>Display Name</th>
    <td mat-cell *matCellDef="let employee">{{ employee.employee.displayName }}</td>
  </ng-container>

  <ng-container matColumnDef="hasIdentityId">
    <th mat-header-cell *matHeaderCellDef>Linked ID</th>
    <td mat-cell *matCellDef="let employee">{{ employee.employee.identityId == null ? '' : 'Yes' }}</td>
  </ng-container>

  <ng-container matColumnDef="pin">
    <th mat-header-cell *matHeaderCellDef>PIN</th>
    <td mat-cell *matCellDef="let employee">{{ employee.employee.pin }}</td>
  </ng-container>

  <ng-container matColumnDef="paymentMethod">
    <th mat-header-cell *matHeaderCellDef>Payment Method</th>
    <td mat-cell *matCellDef="let employee">{{ employee.employee.paymentMethodUid | paymentMethod | async }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let employee">{{ employee.employee.employeeStatusUid | employeeStatus }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>

</table>
