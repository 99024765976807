import { Component, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, map, concatMap, takeUntil, } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { StoreIntegrationService } from 'pos-store-integration';
import { BlobServerSettings, TenantProvider, getParamsFromFilters, getParamsFromPaginator, isEqualUUID, updateFiltersFromParams, updatePaginatorFromParams } from 'core';
import { ActionBarConfiguration, ActionConfiguration, FilterConfiguration, FilterOptionConfiguration } from 'core';
import { Paginator } from 'core';
import { ConfirmModalProvider } from 'core';
import { SpinnerModalComponent } from 'core';
import { SpinnerModalProvider } from 'core';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { ProductDataSource, ProductModel, ProductLibraryConfig, Department, Category, ProductProvider } from 'downtown-product';
import { ProductStatusKeys, DepartmentStatusKeys } from 'downtown-product';
import { CategoryService } from 'downtown-product';
import { DepartmentService, DepartmentViewOptions } from 'downtown-product';
import { ProductService, ProductViewOptions } from 'downtown-product';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-back-office-products',
  templateUrl: './back-office-products.component.html',
  styleUrls: ['./back-office-products.component.scss']
})
export class BackOfficeProductsComponent implements OnInit {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: ProductDataSource;
  public paginator: Paginator;

  public title: string;
  public columns: Array<string>;

  public canSyncToStore = true;

  private allDepartmentFilterOptions: FilterOptionConfiguration<Department>[];
  private allCategoryFilterOptions: FilterOptionConfiguration<Category>[];
  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private blobServerSettings: BlobServerSettings,
    private tenantProvider: TenantProvider,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private departmentService: DepartmentService,
    private categoryService: CategoryService,
    private productService: ProductService,
    private productProvider: ProductProvider,
    private storeIntegrationService: StoreIntegrationService
  ) {
    this.navigationProvider.setWaypoint('Products');
    this.title = 'Products';

    this.dataSource = new ProductDataSource(this.blobServerSettings, this.productService, this.productProvider, this.tenantProvider, false);
    this.paginator = new Paginator(this.dataSource, 0, 100, 'clockInDateTimeUtc', 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('New', this.new.bind(this)),
        new ActionConfiguration('Edit', () => this.edit(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Enable', () => this.enable(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEnable)),
        new ActionConfiguration('Disable', () => this.disable(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDisable)),
        new ActionConfiguration('Delete', () => this.delete(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDelete)),
        new ActionConfiguration('Store Sync (Do Not Press)', this.syncToStore.bind(this), () => true)
      ],
      [
        new FilterConfiguration('Department', [new FilterOptionConfiguration('All', null)], (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }),
        new FilterConfiguration('Category', [new FilterOptionConfiguration('All', null)], (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }),
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('Active', [ProductStatusKeys.Active]),
            new FilterOptionConfiguration('All', [ProductStatusKeys.Active, ProductStatusKeys.Inactive])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'Active')) }
        )
      ]
    );

    // Build initial columns
    this.columns = [ /*'select',*/ 'name', 'imageUrl', /* 'description', 'displayOrder',*/  'departmentUid', 'categoryUid', 'productStatusUid'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }

    this.productService.getMutated().pipe(
      takeUntil(this.destroyed$)
    ).subscribe(saleableProduct => {
      this.dataSource.merge(saleableProduct);
    });
  }

  ngOnInit() {

    let spinnerModalRef = this.spinnerModalProvider.open();
    spinnerModalRef.afterOpened().subscribe(() => {
      combineLatest([
        this.departmentService.search(this.tenantProvider.currentUid, [DepartmentStatusKeys.Active], null, <DepartmentViewOptions>{}),
        this.categoryService.search(this.tenantProvider.currentUid, null, null, null)
      ]).subscribe(
        ([productDepartmentsPage, productCategoriesPage]) => {
          this.allDepartmentFilterOptions = productDepartmentsPage.edges.map(x => new FilterOptionConfiguration(x.node.name, x.node));
          this.allCategoryFilterOptions = productCategoriesPage.edges.map(x => new FilterOptionConfiguration(x.node.name, x.node));

          const departmentFilter = <FilterConfiguration<Department>>this.actionBarConfiguration.filters.find(x => x.title == 'Department');
          const categoryFilter = <FilterConfiguration<Category>>this.actionBarConfiguration.filters.find(x => x.title == 'Category');
          const statusFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => x.title == 'Status');
          const paginator = this.paginator;

          departmentFilter.options.push(...this.allDepartmentFilterOptions);
          categoryFilter.options.push(...this.allCategoryFilterOptions);

          this.activatedRoute.queryParams.pipe(
            takeUntil(this.destroyed$)
          ).subscribe(params => {
            updateFiltersFromParams([departmentFilter, categoryFilter, statusFilter], params);
            updatePaginatorFromParams(paginator, params);
          });

          combineLatest([
            this.actionBarConfiguration.filters.find(x => x.title == 'Department').selected,
            this.actionBarConfiguration.filters.find(x => x.title == 'Category').selected,
            this.actionBarConfiguration.filters.find(x => x.title == 'Status').selected,
            this.paginator.pageRequest$
          ]).pipe(
            takeUntil(this.destroyed$),
            map(([departmentFilterOption, categoryFilterOption, statusFilterOption, paginationInput]) => {
              let departmentFilterValue = departmentFilterOption?.value ? departmentFilterOption.value.uid : null;
              let categoryFilterValue = categoryFilterOption?.value ? categoryFilterOption.value.uid : null;
              let statusFilterUids = statusFilterOption.value;

              var categoryOptions = this.allCategoryFilterOptions.filter(x => departmentFilterOption.value ? isEqualUUID(departmentFilterOption.value.uid, x.value.departmentUid) : true);
              categoryFilter.options.splice(1, categoryFilter.options.length - 1, ...categoryOptions);

              const params = Object.assign(getParamsFromFilters([departmentFilter, categoryFilter, statusFilter]), getParamsFromPaginator(paginator));
              this.navigationProvider.navigate([], { queryParams: params, queryParamsHandling: 'merge' }).then(_ => this.navigationProvider.updateLastWaypoint());

              if (categoryFilterOption.value && !categoryOptions.some(x => x.value.uid == categoryFilterOption.value.uid)) {
                categoryFilter.reset();
              } else {
                this.dataSource.loadData(
                  this.tenantProvider.currentUid,
                  departmentFilterValue ? [departmentFilterValue] : null,
                  categoryFilterValue ? [categoryFilterValue] : null,
                  statusFilterUids,
                  paginationInput,
                  <ProductViewOptions>{}
                );
              }
            })
          ).subscribe();

          spinnerModalRef.close();
        },
        err => {
          console.log(err);
        }
      );
    });
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }

  public new() {

    this.navigationProvider.navigate(['/backoffice/product']);
  }

  public edit(product: ProductModel) {

    this.navigationProvider.navigate(['/backoffice/product', product.product.uid]);
  }

  public enable(product: ProductModel) {

    let waitDialogRef = this.waitModalProvider.open('Enabling...');

    waitDialogRef.afterOpened().pipe(
      concatMap(() => this.dataSource.enable(product)),
      catchError(() => of(null))
    ).subscribe(() => {
      waitDialogRef.close();
    });
  }

  public disable(product: ProductModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Disable Product', 'Are you certain you want to disable this product?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Disabling...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.disable(product)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public delete(product: ProductModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Delete Product', 'Are you certain you want to delete this product?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Deleting...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.delete(product)),
          catchError(() => of(null)),
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public syncToStore() {

    let waitDialogRef = this.waitModalProvider.open('Syncing to Store...');

    waitDialogRef.afterOpened().subscribe(() => {
      this.storeIntegrationService.syncToStore().subscribe(x => {
        waitDialogRef.close();
      });
    });
  }
}
