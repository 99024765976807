<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="terminalUid">
    <th mat-header-cell *matHeaderCellDef color="accent">Terminal UID</th>
    <td mat-cell *matCellDef="let drawer">{{ drawer.drawer.terminalUid }}</td>
  </ng-container>

  <ng-container matColumnDef="terminalName">
    <th mat-header-cell *matHeaderCellDef color="accent">Terminal</th>
    <td mat-cell *matCellDef="let drawer">{{ drawer.drawer.terminalUid | terminal | async }}</td>
  </ng-container>

  <ng-container matColumnDef="startDateTimeUtc">
    <th mat-header-cell *matHeaderCellDef color="accent">Start</th>
    <td mat-cell *matCellDef="let drawer">{{ drawer.drawer.startDateTimeUtc | date: 'E M-dd-yyyy h:mm a' }}</td>
  </ng-container>

  <ng-container matColumnDef="startAuthUid">
    <th mat-header-cell *matHeaderCellDef color="accent">Start Auth Uid</th>
    <td mat-cell *matCellDef="let drawer">{{ drawer.drawer.startAuthUid }}</td>
  </ng-container>

  <ng-container matColumnDef="startAuth">
    <th mat-header-cell *matHeaderCellDef color="accent">Start Auth</th>
    <td mat-cell *matCellDef="let drawer">{{ (drawer.drawer.startAuthUid | employee | async)?.getDisplayableName() }}</td>
  </ng-container>

  <ng-container matColumnDef="endDateTimeUtc">
    <th mat-header-cell *matHeaderCellDef>End</th>
    <td mat-cell *matCellDef="let drawer">{{ drawer.drawer.endDateTimeUtc | date: 'E M-dd-yyyy h:mm a' }}</td>
  </ng-container>

  <ng-container matColumnDef="endAuthUid">
    <th mat-header-cell *matHeaderCellDef color="accent">End Auth Uid</th>
    <td mat-cell *matCellDef="let drawer">{{ drawer.drawer.endAuthUid }}</td>
  </ng-container>

  <ng-container matColumnDef="endAuth">
    <th mat-header-cell *matHeaderCellDef color="accent">End Auth</th>
    <td mat-cell *matCellDef="let drawer">{{ (drawer.drawer.endAuthUid | employee | async)?.getDisplayableName() }}</td>
  </ng-container>

  <ng-container matColumnDef="seedAmount">
    <th mat-header-cell *matHeaderCellDef>Seed $</th>
    <td mat-cell *matCellDef="let drawer">{{ drawer.drawer.seedAmount | currency:'USD':'symbol':'1.2-2' }}</td>
  </ng-container>

  <ng-container matColumnDef="balance">
    <th mat-header-cell *matHeaderCellDef>Balance $</th>
    <td mat-cell *matCellDef="let drawer" style="text-align: left;">{{ drawer.drawer.balance | currency:'USD':'symbol':'1.2-2' }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let drawer">{{ drawer.drawer.drawerStatusUid | drawerStatus }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let drawer">
      <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="drawer.canEdit" (click)="edit(drawer)">Edit</button>
      <button mat-stroked-button color="warn" style="margin-right: 5px;" click-stop-propagation *ngIf="drawer.canDelete" (click)="delete(drawer)">
        <mat-icon>delete_outline</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>
</table>
