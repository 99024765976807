import { Component, OnInit } from '@angular/core';
import { NavigationProvider, TenantProvider, isEqualUUID } from 'core';
import { CacheService } from 'core';
import { ProductStatusKeys, MenuPlacement, flattenProduct, ProductProvider, Product } from 'downtown-product';
import { PosRuntimeProvider } from 'pos-core';
import { DepartmentService, DepartmentViewOptions } from 'downtown-product';
import { ProductService } from 'downtown-product';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-site-public-menu',
  templateUrl: './site-public-menu.component.html',
  styleUrls: ['./site-public-menu.component.scss']
})
export class SitePublicMenuComponent implements OnInit {

  public categories: Category[];
  public selectedCategory: string;
  public categoryMenuItems: MenuItem[];

  private destroyed$ = new Subject<void>();

  constructor(
    public runtimeProvider: PosRuntimeProvider,
    private navigationProvider: NavigationProvider,
    private cacheService: CacheService,
    private departmentService: DepartmentService,
    private tenantProvider: TenantProvider,
    private productProvider: ProductProvider,
    private activatedRoute: ActivatedRoute
  ) {
    this.navigationProvider.setWaypoint('Menu');
  }

  ngOnInit() {

    this.navigationProvider.updateTitle("Uptown Bar Menu");

    let kitchenDepartmentUid = '46C1731B-DBEA-4C6E-B822-F56B80A58254';

    combineLatest([
      this.cacheService.getOrAdd(
        kitchenDepartmentUid,
        () => this.departmentService.getByUid(kitchenDepartmentUid, <DepartmentViewOptions>{ includeCategories: true }).pipe(shareReplay(1))
      ),
      this.productProvider.search$(this.tenantProvider.currentUid, [kitchenDepartmentUid], null, [ProductStatusKeys.Active], null, ProductService.ProductFullView)
    ]).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(([department, productsPage]) => {
      let categories = department.categories.map(category => {

        // Explode products by portions
        return <Category>{
          uid: category.uid,
          name: category.name,
          description: category.description,
          menuItems: productsPage.edges.map(x => x.node).filter(x => {
            return isEqualUUID(x.productStatusUid, ProductStatusKeys.Active) && isEqualUUID(x.categoryUid, category.uid);
          }).map(product => {
            var product = flattenProduct(product);

            return <MenuItem>{
              name: product.name,
              category: department.categories.find(x => x.uid.toUpperCase() == product.categoryUid.toUpperCase()).name,
              description: product.description,
              imageUid: product.imageUid,
              portions: product.configuration.portions.filter(x => isEqualUUID(x.menuPlacementUid, MenuPlacement.Menu) && isEqualUUID(x.productStatusUid, ProductStatusKeys.Active)).map(portion => {
                return <Portion>{
                  name: portion.name,
                  price: portion.price
                }
              }),
              // inclusionGroups: product.configuration.inclusionGroups?.map(inclusionGroup => {
              //   return <InclusionGroup>{
              //     name: inclusionGroup.name,
              //     options: inclusionGroup.options.map(option => {
              //       return <InclusionGroupOption>{
              //         name: option.alias ? of(option.alias) : this.productProvider.getOneCached$(option.productReference.uid, option.productReference.version).pipe(map(product => product.name))
              //       }
              //     })
              //   }
              // }),
              // inclusions: product.configuration.inclusions?.map(inclusion => {

              //   return this.getAvailableProductReference(inclusion.productReference.uid, inclusion.productReference.version).pipe(
              //     filter(product => !!product),
              //     map(product => {
              //       return <Inclusion>{
              //         name: inclusion.alias ? inclusion.alias : product.name
              //       };
              //     })
              //   );
              // }),
              basePrice: product.basePrice
            };
          }).filter(x => x.portions.length > 0)
        }
      }).filter(x => x.menuItems.length > 0);

      this.categories = categories;

      var initialCategoryUid = this.activatedRoute.snapshot.params['categoryUid'];
      if (!initialCategoryUid) {
        this.navigationProvider.navigate(['menu', this.categories[0].uid]);
      } else {
        this.activatedRoute.params.pipe(
          takeUntil(this.destroyed$)
        ).subscribe(params => {
          let categoryUid = params['categoryUid'];

          if (categoryUid) {
            var category = this.categories.find(x => isEqualUUID(x.uid, categoryUid));
            if (category) {
              this.categoryMenuItems = category.menuItems;
            }
          }
        });
      }
    });
  }

  public ngDestroy() {

    this.destroyed$.next();
  }

  public panelClick(category: Category) {

    this.navigationProvider.navigate(['menu', category.uid]);
  }

  private getAvailableProductReference(productUid: string, version: number): Observable<Product> {

    return this.productProvider.getOneCached$(productUid, version).pipe(
      map(product => {
        return isEqualUUID(product.productStatusUid, ProductStatusKeys.Active) ? product : null;
      })
    )
  }
}

class Category {

  uid: string;
  name: string;
  description: string;
  menuItems: MenuItem[];
}

class MenuItem {

  name: string;
  category: string;
  description: string;
  imageUid: string;
  // inclusions: Observable<Inclusion>[];
  // inclusionGroups: InclusionGroup[];
  portions: Portion[];
  basePrice: number;
  addOns?: AddOn[];
  daysAvailable?: AvailableDay[]
}

class Portion {

  name: string;
  price: number;
}

class Inclusion {

  name: string;
}

class InclusionGroup {

  name: string;
  options: InclusionGroupOption[]
}

class InclusionGroupOption {

  name: Observable<string>;
}



class AddOn {

  name: string;
  price: number;
}

class AvailableDay {

  day: number;
  mealShifts: string[];
}
