import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Employee } from "../../models/employee";
import { Observable } from "rxjs";
import { SecurityPinModalComponent } from "./security-pin-modal.component";

@Injectable()
export class SecurityPinModalProvider {

  constructor(
    private dialog: MatDialog
  ) {
  }

  public open(data: SecurityPinModalData): MatDialogRef<SecurityPinModalComponent, [string, Employee]> {

    return this.dialog.open(SecurityPinModalComponent, {
      width: '40vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface SecurityPinModalData {

  title: string;
  getAuthTokenCallback: (pin: string) => Observable<[string, Employee]>;
}
