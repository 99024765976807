import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Transaction } from "../../models/transaction";
import { Subject, combineLatest, Observable, of } from 'rxjs';
import { TransactionSelectModalData } from "./transaction-select-modal.provider";
import { map, takeUntil, take, skip, share, shareReplay } from "rxjs/operators";
import { CategoryProvider, DepartmentProvider } from "downtown-product";
import { ProductProvider } from "downtown-product";
// import { TerminalProvider } from "../../../providers/terminal.provider";
import { TransactionProvider } from "../../providers/transaction.provider";
import { CurrentDateTimeProvider, Lock, LockResolver, TimeoutProvider, TenantProvider, SettingProvider } from "core";
import { TransactionSettings } from "../../models";

@Component({
  selector: 'app-transaction-select-modal',
  templateUrl: './transaction-select-modal.component.html',
  styleUrls: ['./transaction-select-modal.component.scss']
})
export class TransactionSelectModalComponent {

  public selectableTransactions$: Observable<SelectableTransaction[]>;
  public timeoutPercent: number;

  private timeoutProvider: TimeoutProvider;
  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TransactionSelectModalData,
    private dialogRef: MatDialogRef<TransactionSelectModalComponent>,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider,
    private currentDateTimeProvider: CurrentDateTimeProvider,
    private transactionProvider: TransactionProvider,
    private categoryProvider: CategoryProvider,
    private departmentProvider: DepartmentProvider,
    private productProvider: ProductProvider,
    private lockResolver: LockResolver
  ) {
  }

  ngOnInit() {

    this.selectableTransactions$ = this.transactionProvider.openTransactions$.pipe(
      takeUntil(this.destroyed$),
      map(transactions => {
        return transactions.map(x => this.buildOne(x));
      }),
      shareReplay(1)
    );

    this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('PosSettings', this.tenantProvider.currentUid).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(transactionSettings => {
      this.timeoutProvider = new TimeoutProvider(this.currentDateTimeProvider, this.destroyed$, transactionSettings.transactionAutoClearDuration);
      this.timeoutProvider.percent$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(value => this.timeoutPercent = value);
      this.timeoutProvider.inactive$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(_ => this.close());
    });
  }

  public ngOnDestroy() {

    this.destroyed$.next(null);
  }

  selectTransaction(selectableTransaction: SelectableTransaction) {

    selectableTransaction.lock.pipe(take(1)).subscribe(lock => {
      if (lock == null || !lock.isRemoteDevice) {
        this.dialogRef.close(selectableTransaction.transaction);
      }
    });
  }

  close() {

    this.dialogRef.close();
  }

  buildOne(transaction: Transaction): SelectableTransaction {

    return <SelectableTransaction>{
      transaction: transaction,
      lock: transaction.lockUid == null ? of(null) : this.lockResolver.resolve(transaction.lockUid),
      recentOrderedItems: transaction.items?.map(item => {
        let productUid = item.productUid;

        if (productUid) {
          return this.productProvider.getOneCached$(productUid).pipe(
            takeUntil(this.destroyed$),
            map(product => product?.name || 'Product')
          );
        }

        if (item.categoryUid) {
          return this.categoryProvider.getOneCached$(item.categoryUid).pipe(
            takeUntil(this.destroyed$),
            map(category => {
              return category?.name || 'Category';
            })
          );
        }

        if (item.departmentUid) {
          return this.departmentProvider.getOneCached$(item.departmentUid).pipe(
            takeUntil(this.destroyed$),
            map(department => {
              return department?.name || 'Department';
            })
          );
        }

        return 'Unknown';
      }),
      hasDepartmentItems: transaction.items?.some(x => x.departmentUid == '46c1731b-dbea-4c6e-b822-f56b80a58254')
    };
  }
}

interface SelectableTransaction {

  transaction: Transaction;
  lock: Observable<Lock>;
  hasDepartmentItems: boolean;
  recentOrderedItems: Observable<string>[];
}
