import { findInArrayByUid, isEqualUUID } from "core";
import { ProductConfigurationPreparation, ProductConfigurationPreparationOption, ProductConfigurationProductReference, ProductConfigurationVariation, ProductConfigurationVariationOption, Product } from "../models/product";
import { ConfigurationFuncs } from './configuration.functions';
import { cloneInclusionGroupOptions, clonePreparations, cloneProduct, cloneVariations } from "./clone-product";


export function flattenProduct(sourceProduct: Product): Product {

  const clone = (source: any): any => {
    return JSON.parse(JSON.stringify(source)) as typeof source; // dereference all members, keeping typescript type reference
  };

  const coalesceMissingMembers = (source: any, target: any) => {

    if (source != null && target != null) {
      const sourceClone = clone(source);

      Object.keys(sourceClone).forEach(key => target[key] = target[key] ? target[key] : sourceClone[key]);
    }
  };

  const flattenArray = (sourceArray: { uid: string }[], targetArray: { uid: string }[], onEach: (source: any, target: any) => void = null) => {

    if (sourceArray != null && targetArray != null) {
      targetArray.forEach(target => {
        const source = findInArrayByUid(sourceArray, target.uid);
        coalesceMissingMembers(source, target);

        if (onEach) {
          onEach(source, target);
        }
      });
    }
  };

  const inheritArray = (sourceArray: { uid: string }[], targetArray: { uid: string }[], onEach: (source: any, target: any) => void = null) => {

    if (sourceArray != null && targetArray != null) {
      sourceArray.forEach(source => {
        let target = findInArrayByUid(targetArray, source.uid);
        if (!target) {
          target = clone(source);
          targetArray.push(target);
        }

        coalesceMissingMembers(source, target);

        if (onEach) {
          onEach(source, target);
        }
      });
    }
  };

  const inheritProductReference = (source: ProductConfigurationProductReference, target: ProductConfigurationProductReference) => {

    if (source) {
      if (!ConfigurationFuncs.validProductReference(target) && ConfigurationFuncs.validProductReference(source)) {
        target = target || new ProductConfigurationProductReference();
        target.uid = source.uid;
        target.portionUid = source.portionUid;
        target.version = source.version;
      }
    }
  };

  const inheritPreparations = (source: { preparations?: ProductConfigurationPreparation[] }, target: { preparations?: ProductConfigurationPreparation[] }) => {

    if (source && !target.preparations) {
      target.preparations = target.preparations || (source.preparations ? [] : null);

      inheritArray(source.preparations, target.preparations, (x1: { options?: ProductConfigurationPreparationOption[] }, x2: { options?: ProductConfigurationPreparationOption[] }) => {
        const sourcePreparationOptions = ConfigurationFuncs.preparationOptions.getPreparationOptionsArray(x1);
        const targetPreparationOptions = ConfigurationFuncs.preparationOptions.getPreparationOptionsArray(x2);

        inheritArray(sourcePreparationOptions, targetPreparationOptions);
      });
    }

    target.preparations?.forEach(targetPreparation => {
      const sourcePreparation = findInArrayByUid(source?.preparations, targetPreparation.uid);

      if (sourcePreparation) {
        coalesceMissingMembers(sourcePreparation, targetPreparation);

        targetPreparation.options?.forEach(targetPreparationOption => {
          coalesceMissingMembers(findInArrayByUid(sourcePreparation.options, targetPreparationOption.uid), targetPreparationOption);
        });
      }
    });
  };

  const inheritVariations = (source: { variations?: ProductConfigurationVariation[] }, target: { variations?: ProductConfigurationVariation[] }) => {

    if (source && !target.variations?.length) {
      target.variations = target.variations || (source.variations ? [] : null);

      inheritArray(source.variations, target.variations, (x1: { options?: ProductConfigurationVariationOption[] }, x2: { options?: ProductConfigurationVariationOption[] }) => {
        const sourceVariationOptions = ConfigurationFuncs.variationOptions.getVariationOptionsArray(x1);
        const targetVariationOptions = ConfigurationFuncs.variationOptions.getVariationOptionsArray(x2);

        inheritArray(sourceVariationOptions, targetVariationOptions);
      });
    }

    target.variations?.forEach(targetVariation => {
      const sourceVariation = findInArrayByUid(source.variations, targetVariation.uid);

      if (sourceVariation) {
        coalesceMissingMembers(sourceVariation, targetVariation);

        targetVariation.options?.forEach(targetVariationOption => {
          coalesceMissingMembers(findInArrayByUid(sourceVariation.options, targetVariationOption.uid), targetVariationOption);
        });
      }
    });
  };

  const targetProduct = cloneProduct(sourceProduct);
  if (targetProduct) {
    const productPreparations = targetProduct.configuration.preparations;
    const productVariations = targetProduct.configuration.variations;
    const productInclusionGroups = ConfigurationFuncs.inclusionGroups.getInclusionGroupsArray(targetProduct.configuration);
    const productAddOns = ConfigurationFuncs.addOns.getAddOnsArray(targetProduct.configuration);


    // ConfigurationFuncs.inclusions.getInclusionsArray(sourceProduct.configuration)?.forEach(productInclusion => {

    //   inheritPreparations(targetProduct.configuration, productInclusion); 
    //   inheritVariations(targetProduct.configuration, productInclusion); 

    //   inheritProductReference(productInclusion?.productReference, productInclusion?.productReference);
    // });

    const portions = ConfigurationFuncs.portions.getPortionsArray(targetProduct.configuration) || [];
    portions.forEach(portion => {

      // portion.inclusionGroups = portion.inclusionGroups || cloneInclusionGroups(targetProduct.configuration.inclusionGroups);
      // portion.inclusions = portion.inclusions || cloneInclusions(targetProduct.configuration.inclusions);
      // portion.extras = portion.extras || cloneExtras(targetProduct.configuration.extras);
      // portion.addOns = portion.addOns || cloneAddOns(targetProduct.configuration.addOns);
      // portion.variations = portion.variations || cloneVariations(targetProduct.configuration.variations);
      // portion.preparations = portion.preparations || clonePreparations(targetProduct.configuration.preparations);

      ConfigurationFuncs.inclusionGroups.getInclusionGroupsArray(portion)?.forEach(portionInclusionGroup => {

        const productInclusionGroup = findInArrayByUid(productInclusionGroups, portionInclusionGroup.uid);

        if (productInclusionGroup) {
          portionInclusionGroup.uid = portionInclusionGroup.uid || productInclusionGroup.uid;
          portionInclusionGroup.name = portionInclusionGroup.name || productInclusionGroup.name;
          portionInclusionGroup.maxDistinctOptionsIncluded = portionInclusionGroup.maxDistinctOptionsIncluded || productInclusionGroup.maxDistinctOptionsIncluded;
          portionInclusionGroup.minQuantityForOption = portionInclusionGroup.minQuantityForOption || productInclusionGroup.minQuantityForOption;
          portionInclusionGroup.maxTotalOptionQuantityIncluded = portionInclusionGroup.maxTotalOptionQuantityIncluded || productInclusionGroup.maxTotalOptionQuantityIncluded;
          portionInclusionGroup.allowSubstitution = portionInclusionGroup.allowSubstitution || productInclusionGroup.allowSubstitution;
          portionInclusionGroup.allowAdditional = portionInclusionGroup.allowAdditional || productInclusionGroup.allowAdditional;
          portionInclusionGroup.hideInPrintDetail = portionInclusionGroup.hideInPrintDetail || productInclusionGroup.hideInPrintDetail;
          portionInclusionGroup.hideInUIDetail = portionInclusionGroup.hideInUIDetail || productInclusionGroup.hideInUIDetail;
          portionInclusionGroup.options = portionInclusionGroup.options || cloneInclusionGroupOptions(productInclusionGroup.options);
          portionInclusionGroup.preparations = portionInclusionGroup.preparations || clonePreparations(productInclusionGroup.preparations);
          portionInclusionGroup.variations = portionInclusionGroup.variations || cloneVariations(productInclusionGroup.variations);
        }

        inheritPreparations(targetProduct.configuration, portionInclusionGroup);
        inheritVariations(targetProduct.configuration, portionInclusionGroup);

        const productInclusionGroupOptions = ConfigurationFuncs.inclusionGroupOptions.getInclusionGroupOptionsArray(productInclusionGroup);
        ConfigurationFuncs.inclusionGroupOptions.getInclusionGroupOptionsArray(portionInclusionGroup)?.forEach(portionInclusionGroupOption => {
          if (productInclusionGroupOptions) {
            const productInclusionGroupOption = findInArrayByUid(productInclusionGroupOptions, portionInclusionGroupOption.uid);

            if (productInclusionGroupOption) {
              portionInclusionGroupOption.uid = productInclusionGroupOption.uid || portionInclusionGroupOption.uid;
              portionInclusionGroupOption.productReference = productInclusionGroupOption.productReference || portionInclusionGroupOption.productReference;
              portionInclusionGroupOption.alias = productInclusionGroupOption.alias || portionInclusionGroupOption.alias;
              portionInclusionGroupOption.defaultQuantity = productInclusionGroupOption.defaultQuantity || portionInclusionGroupOption.defaultQuantity;
              portionInclusionGroupOption.maxIncludedQuantity = productInclusionGroupOption.maxIncludedQuantity || portionInclusionGroupOption.maxIncludedQuantity;
              portionInclusionGroupOption.priceOverride = productInclusionGroupOption.priceOverride || portionInclusionGroupOption.priceOverride;
              portionInclusionGroupOption.preparations = productInclusionGroupOption.preparations || clonePreparations(portionInclusionGroupOption.preparations);
              portionInclusionGroupOption.variations = productInclusionGroupOption.variations || cloneVariations(portionInclusionGroupOption.variations);
            }
          }

          inheritPreparations(portionInclusionGroup, portionInclusionGroupOption); // InclusionGroup to InclusionGroupOption
          inheritVariations(portionInclusionGroup, portionInclusionGroupOption); // InclusionGroup to InclusionGroupOption

          inheritPreparations(targetProduct.configuration, portionInclusionGroupOption); // Product to InclusionGroupOption
          inheritVariations(targetProduct.configuration, portionInclusionGroupOption); // Product to InclusionGroupOption
        });
      });

      const portionAddOns = ConfigurationFuncs.addOns.getAddOnsArray(portion);
      if (!portionAddOns) {
        portion.addOns = [];
        inheritArray(productAddOns, portion.addOns);
      } else {
        ConfigurationFuncs.addOns.getAddOnsArray(portion)?.forEach(portionAddOn => {
          const productAddOn = findInArrayByUid(productAddOns, portionAddOn.uid);
          coalesceMissingMembers(productAddOn, portionAddOn);
        });
      }
    });
  }

  return targetProduct;
}

