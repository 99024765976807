import { Directive, Input, Type, ViewContainerRef, EventEmitter, Output } from '@angular/core';
import { Product } from 'downtown-product';
import { EditableTransactionItemConfiguration } from '../models/editable-transaction/editable-transaction';
import { HtmlElementMap } from './html-element-map';
import { ItemConfiguratorContext } from '../components/item-configurator/item-configurator-context';

// NOTE: ItemConfiguratorContext cannot come from index.ts or Ivy will report as a circular dependency

@Directive({
  selector: '[item-configurator-detail]',
})
export class ProductConfiguratorDetailDirective {

  @Input() public parentContext: ItemConfiguratorContext;
  @Input() public product: Product;
  @Input() public itemConfiguration: EditableTransactionItemConfiguration;
  @Input() public isReadOnly: boolean;
  @Output() public configurationChanged = new EventEmitter();

  constructor(
    private container: ViewContainerRef
  ) {
  }

  ngOnInit(): void {

    if (this.product && this.itemConfiguration) {
      let component: Type<any> | null = null;

      HtmlElementMap.filter(x => x.map.indexOf('transaction-item-configurator-detail') > -1).forEach(
        x => {
          if (component === null) component = x.component;
        }
      );

      const componentRef = this.container.createComponent(component);
      componentRef.instance.context = new ItemConfiguratorContext(
        this.parentContext.ownerUid,
        this.parentContext.productProvider,
        this.product,
        this.itemConfiguration,
        this.isReadOnly == undefined ? this.parentContext.isReadOnly : this.isReadOnly
      );
      componentRef.instance.configurationChanged = this.configurationChanged;
    }
  }
}
