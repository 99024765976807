import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { shareReplay } from "rxjs/operators";

@Injectable()
export class ObservableCacheProvider {

  private static _defaultExpirationMs: number = 30 * 60 * 1000;

  private memoryStorage: { [key: string]: Entry<Observable<any>> };
  public enabled = true;

  constructor(
  ) {
    this.memoryStorage = {};

    setInterval(() => {

      let now = new Date().getTime();
      let keys = Object.keys(this.memoryStorage);

      keys.forEach(key => {
        let entry = this.memoryStorage[key];
        if (now > entry.expiration) {
          this.invalidate(key);
        }
      });
    }, 1000);
  };

  clear() {

    Object.keys(this.memoryStorage).forEach(key => this.invalidate(key));
  }

  get<T>(key: string): Observable<T> {

    if (this.enabled) {
      var entry = this.memoryStorage[key];

      if (entry) {
        return entry.value;
      }
    }

    return of(null);
  }

  getOrAdd<T>(key: string, resolver: () => Observable<T>, expirationMs: number = ObservableCacheProvider._defaultExpirationMs): Observable<T> {

    if (this.enabled) {
      let now = new Date().getTime();

      var entry = this.memoryStorage[key];
      if (!entry) {
        entry = <Entry<Observable<T>>>{ value: resolver().pipe(shareReplay(1)) };
        this.memoryStorage[key] = entry
      }

      entry.expiration = now + expirationMs; // Slide the expiration

      return entry.value;
    } else {
      return resolver();
    }
  }

  addOrUpdate<T>(key: string, value: Observable<T>, expirationMs: number = ObservableCacheProvider._defaultExpirationMs): Observable<T> {

    if (this.enabled) {
      let now = new Date().getTime();
      var entry = this.memoryStorage[key];
      if (entry) {
        entry.value = value.pipe(shareReplay(1));
      } else {
        entry = <Entry<Observable<T>>>{ value: value.pipe(shareReplay(1)) };
        this.memoryStorage[key] = entry
      }

      entry.expiration = now + expirationMs; // Slide the expiration
    }

    return value;
  }

  update<T>(key: string, resolver: () => Observable<T>, expirationMs: number = ObservableCacheProvider._defaultExpirationMs): Observable<T> {

    let entry = this.memoryStorage[key];
    if (entry) {
      entry.value = resolver().pipe(shareReplay(1));

      entry.expiration = new Date().getTime() + expirationMs;

      return entry.value;
    }

    return this.getOrAdd(key, resolver, expirationMs);
  }

  invalidate(key: string) {

    this.memoryStorage[key] = null;
    delete this.memoryStorage[key];
  }

  keyExists(key: string): boolean {
    return this.memoryStorage[key] != null;
  }

  intersectKeys(keys: string[]): string[] {
    return keys.filter(x => this.memoryStorage[x] != null);
  }

  missingKeys(keys: string[]): string[] {
    return keys.filter(x => this.memoryStorage[x] == null);
  }
}

class Entry<T> {

  value: T;
  expiration: number;
}
