import { Component, Input, Output, EventEmitter } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { TenantProvider, findInArrayByKey, findInArrayByUid, isCaseInsensitiveEqual, isEqualUUID } from "core";
import { initTransactionItemConfigurationInclusionGroupOption } from "../../../functions/transaction-item-configuration/initialize";
import { EditableTransactionItemConfigurationInclusionGroup, EditableTransactionItemConfigurationInclusionGroupOption } from "../../../models/editable-transaction/editable-transaction";
import { ProductConfigurationInclusionGroup, ProductStatusKeys, ProductSelectModalProvider, DepartmentProvider, ProductConfigurationInclusionGroupOption, ProductConfigurationProductReference } from 'downtown-product';
import { flattenProduct } from "downtown-product";
import { concatMap, first, map } from "rxjs/operators";
import { UUID } from "angular2-uuid";
import { InclusionGroupSelectionModel, PreparationModel, VariationModel, InclusionGroupOptionSelectionModel, VariationOptionModel, PreparationOptionModel } from "../models";
import { ItemConfiguratorContext } from "../../../components";
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: 'transaction-item-configurator-modal-inclusion-group-tab',
  templateUrl: './item-configurator-modal-inclusion-group-tab.component.html',
  styleUrls: ['./item-configurator-modal-inclusion-group-tab.component.scss']
})
export class ItemConfiguratorModalInclusionGroupTabComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public isNew: boolean;
  @Input() public productInclusionGroup: ProductConfigurationInclusionGroup;
  @Output() public configurationChanged = new EventEmitter();

  public inclusionGroupSelection: InclusionGroupSelectionModel;

  constructor(
    private currencyPipe: CurrencyPipe,
    protected tenantProvider: TenantProvider,
    protected departmentProvider: DepartmentProvider,
    protected productSelectModalProvider: ProductSelectModalProvider
  ) {

  }

  ngOnInit(): void {

    forkJoin(
      this.productInclusionGroup.options.map(productOption => {
        // build standard options
        return this.buildOptionSelectionModel(this.productInclusionGroup, productOption, this.isNew, false);
      })
    ).pipe(
      map(optionSelections => optionSelections.filter(x => !!x))
    ).subscribe(optionSelections => {
      let itemInclusionGroup = this.context.getItemPortion().getConfiguringInclusionGroup(this.productInclusionGroup.uid);
      if (itemInclusionGroup && itemInclusionGroup.options) {

        itemInclusionGroup.options.forEach(option => {
          let inclusionGroupSelectionOption = findInArrayByKey(optionSelections, 'optionUid', option.optionUid);
          if (inclusionGroupSelectionOption) {
            inclusionGroupSelectionOption.selectedQuantity = option.quantity;
          } else {
            // build substitution options
            const productOption = new ProductConfigurationInclusionGroupOption();
            productOption.uid = option.optionUid;
            productOption.productReference = <ProductConfigurationProductReference>{
              uid: option.productUid,
              portionUid: option.productPortionUid,
              version: option.productVersion
            };

            this.productInclusionGroup.options.push(productOption);

            this.buildOptionSelectionModel(this.productInclusionGroup, productOption, false, true).subscribe(inclusionGroupSelectionOption => {
              inclusionGroupSelectionOption.display = '(Sub) ' + inclusionGroupSelectionOption.display;
              inclusionGroupSelectionOption.selectedQuantity = option.quantity;

              optionSelections.push(inclusionGroupSelectionOption);
            });
          };
        });
      }

      this.inclusionGroupSelection = <InclusionGroupSelectionModel>{
        inclusionGroupUid: this.productInclusionGroup.uid,
        name: this.productInclusionGroup.name,
        options: optionSelections.filter(x => !!x),
        maxDistinctOptionsIncluded: this.productInclusionGroup.maxDistinctOptionsIncluded,
        minQuantityForOption: this.productInclusionGroup.minQuantityForOption,
        maxTotalOptionQuantityIncluded: this.productInclusionGroup.maxTotalOptionQuantityIncluded,
        allowSubstitution: this.productInclusionGroup.allowSubstitution,
        allowAdditional: this.productInclusionGroup.allowAdditional,
        currentInclusionCount: 0,
        currentInclusionQuantity: 0
      };

      this.updateInclusionGroupMeasures();
    });
  }

  public getInclusionGroupOptionMessaging(option: InclusionGroupOptionSelectionModel): string {

    let message = '';

    if ((!this.productInclusionGroup.maxTotalOptionQuantityIncluded && option.maxIncludedQuantity) || (this.productInclusionGroup.maxTotalOptionQuantityIncluded && option.maxIncludedQuantity)) {
      message += `${option.maxIncludedQuantity} included`;

      if (this.productInclusionGroup.allowAdditional || (this.productInclusionGroup.maxTotalOptionQuantityIncluded && this.productInclusionGroup.allowAdditional)) {
        message += `, ${this.currencyPipe.transform(option.additionalPrice, 'USD', 'symbol', '1.2-2')} each additional`;
      }
    }

    if (!this.productInclusionGroup.maxTotalOptionQuantityIncluded && !option.maxIncludedQuantity) {
      message += `${this.currencyPipe.transform(option.additionalPrice, 'USD', 'symbol', '1.2-2')} each`;
    }

    if (this.inclusionGroupSelection.maxTotalOptionQuantityIncluded && this.inclusionGroupSelection.currentInclusionQuantity && this.inclusionGroupSelection.maxTotalOptionQuantityIncluded && this.inclusionGroupSelection.allowAdditional) {
      message += `${this.currencyPipe.transform(option.additionalPrice, 'USD', 'symbol', '1.2-2')} each additional`;
    }

    return message;
  }

  public optionsHaveRestrictions(options: ProductConfigurationInclusionGroupOption[]): boolean {

    return options.some(x => x.maxIncludedQuantity);
  }

  public incrementInclusionGroupOption(option: InclusionGroupOptionSelectionModel) {

    let itemInclusionGroup = this.context.getItemPortion().getConfiguringInclusionGroup(this.productInclusionGroup.uid);

    let amountToIncrement = 0;
    let isProductInclusionExistingSelection = option.selectedQuantity > 0;
    let isSingleSelect = this.productInclusionGroup.options.length == 1 || this.productInclusionGroup.maxDistinctOptionsIncluded == 1;

    if (isSingleSelect) {
      // Only 1 max is allowed, zero out all other options
      itemInclusionGroup.options.filter(x => !isCaseInsensitiveEqual(x.optionUid, option.optionUid)).forEach(option => {
        itemInclusionGroup.options.splice(itemInclusionGroup.options.indexOf(option), 1);
        findInArrayByKey(this.inclusionGroupSelection.options, 'optionUid', option.optionUid).selectedQuantity = 0;
      })
    }

    let existingSelectionCount = itemInclusionGroup ? itemInclusionGroup.options.filter(x => x.quantity > 0).length : 0;
    let existingQuantityCount = itemInclusionGroup ? itemInclusionGroup.options.map(x => x.quantity).reduce((total, q) => total + q, 0) : 0;

    if (this.productInclusionGroup.allowAdditional) {
      amountToIncrement = isProductInclusionExistingSelection ? 1 : (this.productInclusionGroup.minQuantityForOption ? this.productInclusionGroup.minQuantityForOption : 1);
    } else {
      // Check by number of selections first
      const maxDistinctOptionsIncluded = this.productInclusionGroup.maxDistinctOptionsIncluded || Number.MAX_SAFE_INTEGER;

      if (existingSelectionCount < maxDistinctOptionsIncluded || isProductInclusionExistingSelection) {
        const maxTotalOptionQuantityIncluded = this.productInclusionGroup.maxTotalOptionQuantityIncluded || Number.MAX_SAFE_INTEGER;
        if (existingQuantityCount < maxTotalOptionQuantityIncluded) {
          amountToIncrement = isProductInclusionExistingSelection ? 1 : (this.productInclusionGroup.minQuantityForOption || 1);
          if (amountToIncrement + existingQuantityCount > maxTotalOptionQuantityIncluded || option.selectedQuantity + amountToIncrement > (option.maxIncludedQuantity || Number.MAX_SAFE_INTEGER)) {
            amountToIncrement = 0;
          }
        }
      }
    }

    if (amountToIncrement > 0) {
      if (!itemInclusionGroup) {
        itemInclusionGroup = new EditableTransactionItemConfigurationInclusionGroup();
        itemInclusionGroup.inclusionGroupUid = this.productInclusionGroup.uid;

        this.context.itemConfiguration.portion.inclusionGroups = this.context.itemConfiguration.portion.inclusionGroups || [];
        this.context.itemConfiguration.portion.inclusionGroups.push(itemInclusionGroup);
      }

      var itemInclusionGroupOption = this.getOrAddConfiguringOption(itemInclusionGroup, option);

      itemInclusionGroupOption.quantity += amountToIncrement;
      option.selectedQuantity += amountToIncrement;

      this.updateInclusionGroupMeasures();

      if (this.context.selection != itemInclusionGroupOption) {
        this.context.select(itemInclusionGroupOption);
      }

      this.configurationChanged.emit();
    }
  }

  public decrementInclusionGroupOption(option: InclusionGroupOptionSelectionModel) {

    let itemInclusionGroup = this.context.getItemPortion().getConfiguringInclusionGroup(this.productInclusionGroup.uid);
    var itemInclusionGroupOption = findInArrayByKey(itemInclusionGroup.options, 'optionUid', option.optionUid);

    let amountToDecrement = 0;
    if (option.selectedQuantity > 0) {
      amountToDecrement = 1;

      if (option.selectedQuantity - amountToDecrement < this.productInclusionGroup.minQuantityForOption) {
        amountToDecrement = option.selectedQuantity;
      }
    }

    option.selectedQuantity -= amountToDecrement;

    if (itemInclusionGroupOption) {
      itemInclusionGroupOption.quantity -= amountToDecrement;

      const productOption = this.productInclusionGroup.options.find(x => isEqualUUID(x.uid, itemInclusionGroupOption.optionUid));

      if (itemInclusionGroupOption.quantity <= 0 && !productOption.defaultQuantity) {
        let inclusionGroupOptionIndex = itemInclusionGroup.options.indexOf(itemInclusionGroupOption);
        itemInclusionGroup.options.splice(inclusionGroupOptionIndex, 1);
      }
    }

    this.updateInclusionGroupMeasures();

    if (option.selectedQuantity == 0) {
      this.context.select(null);
    } else if (this.context.selection != itemInclusionGroupOption) {
      this.context.select(itemInclusionGroupOption);
    }

    this.configurationChanged.emit();
  }

  public selectSubstitution() {

    const departmentUid = this.context.product.departmentUid;

    this.departmentProvider.getOneCached$(departmentUid).subscribe(department => {
      this.productSelectModalProvider.open({
        title: `${department.name} - Substitution `,
        departmentUid: department.uid,
        menuPlacement: 'Off Menu'
      }).afterClosed().pipe(
        concatMap(result => {
          if (!result) {
            return of(null);
          }

          var productInclusionGroup = this.productInclusionGroup;

          const productOption = new ProductConfigurationInclusionGroupOption();
          productOption.uid = UUID.UUID();
          productOption.productReference = <ProductConfigurationProductReference>{
            uid: result.productUid,
            portionUid: result.portionUid,
            version: result.productVersion
          };
          productOption.priceOverride = result.eachAmount;
          productOption.variations = productInclusionGroup.variations;
          productOption.preparations = productInclusionGroup.preparations;

          console.log(`Creating substition option with UID ${productOption.uid} for productUid ${result.productUid}`);

          this.productInclusionGroup.options.push(productOption);

          return this.buildOptionSelectionModel(this.productInclusionGroup, productOption, false, true);
        }),
        first()
      ).subscribe(result => {
        if (result) {
          result.display = '(Sub) ' + result.display;

          this.inclusionGroupSelection.options.push(result);

          this.incrementInclusionGroupOption(result);
        }
      })
    });
  }

  private getOrAddConfiguringOption(itemInclusionGroup: EditableTransactionItemConfigurationInclusionGroup, option: InclusionGroupOptionSelectionModel): EditableTransactionItemConfigurationInclusionGroupOption {

    var itemOption = findInArrayByKey(itemInclusionGroup.options, 'optionUid', option.optionUid);
    if (itemOption) {
      return itemOption;
    }

    let productInclusionGroup = this.context.product.configuration.getPortion(this.context.itemConfiguration.portion.portionUid).getInclusionGroup(this.productInclusionGroup.uid);
    let productInclusionGroupOption: ProductConfigurationInclusionGroupOption;

    if (option.isSubstitution) {
      productInclusionGroupOption = new ProductConfigurationInclusionGroupOption();
      productInclusionGroupOption.uid = option.optionUid;
      productInclusionGroupOption.productReference = <ProductConfigurationProductReference>{
        uid: option.productReference.uid,
        portionUid: option.productReference.portionUid,
        version: option.productReference.version
      };
      productInclusionGroupOption.preparations = productInclusionGroup.preparations;
      productInclusionGroupOption.variations = productInclusionGroup.variations;
    } else {
      productInclusionGroupOption = findInArrayByUid(productInclusionGroup.options, option.optionUid);
    }

    var itemOption = initTransactionItemConfigurationInclusionGroupOption(this.context.product, productInclusionGroupOption, false, option.isSubstitution);;
    itemInclusionGroup.options.push(itemOption);

    itemInclusionGroup.options.sort((left, right) => {
      var leftOption = productInclusionGroup.options.find(x => isEqualUUID(x.uid, left.optionUid));
      var rightOption = productInclusionGroup.options.find(x => isEqualUUID(x.uid, right.optionUid));

      var leftIndex = productInclusionGroup.options.indexOf(leftOption);
      var rightIndex = productInclusionGroup.options.indexOf(rightOption);

      return leftIndex - rightIndex; 
    });

    return itemOption;
  }

  private updateInclusionGroupMeasures() {

    this.inclusionGroupSelection.currentInclusionCount = this.inclusionGroupSelection.options?.filter(x => x.selectedQuantity > 0).length;
    this.inclusionGroupSelection.currentInclusionQuantity = this.inclusionGroupSelection.options?.map(x => x.selectedQuantity).reduce((sum, x) => sum += x, 0);
  }

  private buildOptionSelectionModel(
    productInclusionGroup: ProductConfigurationInclusionGroup,
    productOption: ProductConfigurationInclusionGroupOption,
    setDefaults: boolean,
    isSubstitution: boolean
  ): Observable<InclusionGroupOptionSelectionModel> {

    if (productOption.productReference) {
      return this.context.productProvider.getOneCached$(productOption.productReference.uid, productOption.productReference.version).pipe(
        map(optionProduct => {
          optionProduct = flattenProduct(optionProduct);

          let productPortion = optionProduct.configuration.getPortion(productOption.productReference.portionUid);

          if (isCaseInsensitiveEqual(optionProduct.productStatusUid, ProductStatusKeys.Active) && isCaseInsensitiveEqual(productPortion.productStatusUid, ProductStatusKeys.Active)) {

            var inclusionGroupOptionModel = <InclusionGroupOptionSelectionModel>{
              optionUid: productOption.uid,
              productReference: productOption.productReference,
              display: productOption.alias || optionProduct.name,
              selectedQuantity: setDefaults ? productOption.defaultQuantity || 0 : 0,
              // maxIncludedQuantity: !productOption.maxIncludedQuantity || productOption.maxIncludedQuantity == 0 ? productInclusionGroup.maxTotalOptionQuantityIncluded : productOption.maxIncludedQuantity,
              maxIncludedQuantity: productOption.maxIncludedQuantity,
              additionalPrice: productOption.priceOverride || productPortion?.price,
              preparations: productOption.preparations?.map(configurablePreparation => {
                return <PreparationModel>{
                  preparationUid: configurablePreparation.uid,
                  name: configurablePreparation.name,
                  options: configurablePreparation.options?.map(preparationOption => {
                    return <PreparationOptionModel>{
                      optionUid: preparationOption.uid,
                      name: preparationOption.name,
                      additionalPrice: preparationOption.additionalPrice,
                      isDefault: false
                    };
                  })
                };
              }),
              variations: productOption.variations?.map(configurableVariation => {
                return <VariationModel>{
                  variationUid: configurableVariation.uid,
                  name: configurableVariation.name,
                  options: configurableVariation.options?.map(variationOption => {
                    return <VariationOptionModel>{
                      optionUid: variationOption.uid,
                      alias: variationOption.alias,
                      priceOverride: variationOption.priceOverride,
                      isSelected: false
                    };
                  })
                };
              }),
              isSubstitution: isSubstitution
            };

            return inclusionGroupOptionModel;
          }

          return null;
        })
      );
    } else {
      return of(<InclusionGroupOptionSelectionModel>{
        optionUid: productOption.uid,
        display: productOption.alias,
        selectedQuantity: setDefaults ? productOption.defaultQuantity || 0 : 0,
        maxIncludedQuantity: !productOption.maxIncludedQuantity || productOption.maxIncludedQuantity == 0 ? productInclusionGroup.maxTotalOptionQuantityIncluded : productOption.maxIncludedQuantity,
        additionalPrice: productOption.priceOverride,
        preparations: [],
        variations: []
      });
    }
  }
}
