import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpinnerModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { MemberProfileForm } from 'pos-core';
import { MemberService } from 'pos-core';

@Component({
  selector: 'app-administration-member-profile',
  templateUrl: './administration-member-profile.component.html',
  styleUrls: ['./administration-member-profile.component.scss']
})
export class AdministrationMemberProfileComponent extends MemberProfileForm implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    navigationProvider: NavigationProvider,
    spinnerModalProvider: SpinnerModalProvider,
    memberService: MemberService
  ) {
    super(navigationProvider, spinnerModalProvider, memberService);

    this.navigationProvider.setWaypoint('Member Profile');
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      let memberUid = params['memberUid'];

      if (memberUid) {
        let spinnerModalRef = this.spinnerModalProvider.open();
        spinnerModalRef.afterOpened().subscribe(() => {
          this.memberService.getByUid(memberUid).subscribe(member => {
            if (member) {
              this.loadForm(member);

              spinnerModalRef.close();
            } else {
              this.navigationProvider.backOneWaypoint();
            }
          });
        });
      } else {
        this.navigationProvider.backOneWaypoint();
      }
    });
  }
}
