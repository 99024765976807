import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Observable, map, of } from 'rxjs';
import { ItemConfiguratorContext } from "../../item-configurator-context";
import { SettingProvider, TenantProvider, findInArrayByUid, slideInAnimation, slideOutAnimation } from "core";
import { ProductConfigurationVariation } from "downtown-product";
import { EditableTransactionItemConfigurationVariation, TransactionSettings } from "../../../../models";

@Component({
  selector: 'transaction-item-configurator-detail-variation-option',
  templateUrl: './item-configurator-detail-variation-option.component.html',
  styleUrls: ['./item-configurator-detail-variation-option.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfiguratorDetailVariationOptionComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public productVariation: ProductConfigurationVariation;
  @Input() public itemVariation: EditableTransactionItemConfigurationVariation;
  @Output() public configurationChanged = new EventEmitter();

  public transactionSettings$: Observable<TransactionSettings>;

  constructor(
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider
  ) {
    this.transactionSettings$ = this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid);
  }

  ngOnInit() {

  }

  public get display(): Observable<string> {

    const variationOption = findInArrayByUid(this.productVariation.options, this.itemVariation.optionUid);

    if (variationOption?.alias) {
      return of(variationOption.alias);
    }

    return this.context.productProvider.getOneCached$(this.itemVariation.productUid, this.itemVariation.productVersion).pipe(
      map(x => x.name)
    );
  }
}
