import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {

  @Input() initialDate: Date;
  @Input() minimumDateTime: Date;
  @Input() maximumDateTime: Date;
  @Input() selectionMode: string = 'preferred';
  @Output() dateSelected = new EventEmitter<{ month: number, day: number, year: number }>();

  selectedMonth: number;
  selectedYear: number;
  selectedDay: number;
  displayMonth: number;
  displayYear: number;
  days: MeasureModel[];
  calendarTitle: string;

  ngOnInit() {
    var date = this.initialDate ? this.initialDate : new Date();

    this.displayMonth = date.getMonth();
    this.displayYear = date.getFullYear();
    this.selectedMonth = date.getMonth() + 1;
    this.selectedDay = date.getDate();
    this.selectedYear = date.getFullYear();

    this.minimumDateTime = this.minimumDateTime ? new Date(this.minimumDateTime.getFullYear(), this.minimumDateTime.getMonth(), this.minimumDateTime.getDate(), 0, 0) : null;
    this.maximumDateTime = this.maximumDateTime ? new Date(this.maximumDateTime.getFullYear(), this.maximumDateTime.getMonth(), this.maximumDateTime.getDate(), 23, 59) : null;

    this.updateCalendar();
  }

  previousMonth() {

    var previousMonth = this.displayMonth - 1;
    if (previousMonth < 0) {
      previousMonth = 11;
      this.displayYear -= 1;
    }
    this.displayMonth = previousMonth;

    this.updateCalendar();
  }

  nextMonth() {

    var nextMonth = this.displayMonth + 1;
    if (nextMonth > 11) {
      nextMonth = 0;
      this.displayYear += 1;
    }

    this.displayMonth = nextMonth;

    this.updateCalendar();
  }

  selectDay(day: MeasureModel) {

    this.days.forEach(x => x.isSelected = false);
    day.isSelected = true;

    this.selectedMonth = day.month;
    this.selectedDay = day.day;
    this.selectedYear = day.year;
  }

  updateCalendar() {

    var firstOfCurrentMonth = new Date(this.displayYear, this.displayMonth, 1);
    var firstOfPreviousMonth = new Date(new Date(firstOfCurrentMonth).setMonth(firstOfCurrentMonth.getMonth() - 1));
    var firstOfNextMonth = new Date(new Date(firstOfCurrentMonth).setMonth(firstOfCurrentMonth.getMonth() + 1));
    var daysInCurrentMonth = new Date(firstOfCurrentMonth.getFullYear(), firstOfCurrentMonth.getMonth() + 1, 0).getDate();
    var daysInPreviousMonth = new Date(firstOfPreviousMonth.getFullYear(), firstOfPreviousMonth.getMonth() + 1, 0).getDate();
    var firstIsOnDayOfWeek = firstOfCurrentMonth.getDay();

    let days = new Array<MeasureModel>(42);

    var index = 0;
    for (var x = daysInPreviousMonth - firstIsOnDayOfWeek + 1; x <= daysInPreviousMonth; x++) {
      let date = new Date(firstOfPreviousMonth.getFullYear(), firstOfPreviousMonth.getMonth(), x);

      let measure = new MeasureModel();
      measure.date = date;
      measure.month = date.getMonth() + 1;
      measure.day = date.getDate();
      measure.year = date.getFullYear();
      measure.isSelectable = this.selectionMode == 'restricted' ? (this.minimumDateTime == null ? true : date >= this.minimumDateTime) && (this.maximumDateTime == null ? true : date <= this.maximumDateTime) : true;
      measure.isPreferred = this.selectionMode == 'preferred' ? (this.minimumDateTime == null ? true : date >= this.minimumDateTime) && (this.maximumDateTime == null ? true : date <= this.maximumDateTime) : false;

      days[index++] = measure;
    }

    for (x = 0; x < daysInCurrentMonth; x++) {
      let date = new Date(firstOfCurrentMonth.getFullYear(), firstOfCurrentMonth.getMonth(), x + 1);

      let measure = new MeasureModel();
      measure.date = date;
      measure.month = date.getMonth() + 1;
      measure.day = date.getDate();
      measure.year = date.getFullYear();
      measure.isSelectable = this.selectionMode == 'restricted' ? (this.minimumDateTime == null ? true : date >= this.minimumDateTime) && (this.maximumDateTime == null ? true : date <= this.maximumDateTime) : true;
      measure.isPreferred = this.selectionMode == 'preferred' ? (this.minimumDateTime == null ? true : date >= this.minimumDateTime) && (this.maximumDateTime == null ? true : date <= this.maximumDateTime) : false;

      days[index++] = measure;
    }

    for (x = 0; x < 42 - firstIsOnDayOfWeek - daysInCurrentMonth; x++) {
      let date = new Date(firstOfNextMonth.getFullYear(), firstOfNextMonth.getMonth(), x + 1);

      let measure = new MeasureModel();
      measure.date = date;
      measure.month = date.getMonth() + 1;
      measure.day = date.getDate();
      measure.year = date.getFullYear();
      measure.isSelectable = this.selectionMode == 'restricted' ? (this.minimumDateTime == null ? true : date >= this.minimumDateTime) && (this.maximumDateTime == null ? true : date <= this.maximumDateTime) : true;
      measure.isPreferred = this.selectionMode == 'preferred' ? (this.minimumDateTime == null ? true : date >= this.minimumDateTime) && (this.maximumDateTime == null ? true : date <= this.maximumDateTime) : false;

      days[index++] = measure;
    }

    this.days = days;
    this.calendarTitle = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' }).format(firstOfCurrentMonth)

    var currentDayTick = this.days.find(x => x.year == this.selectedYear && x.month == this.selectedMonth && x.day == this.selectedDay);
    if (currentDayTick) {
      this.selectDay(currentDayTick);
    }
  }

  datePressed(value: MeasureModel) {

    if (value.isSelectable) {
      this.selectDay(value);
      this.dateSelected.next({ month: value.month, day: value.day, year: value.year });
    }
  }
}

class MeasureModel {
  day: number;
  month: number;
  year: number;
  date: Date;
  isSelectable: boolean;
  isSelected: boolean;
  isPreferred: boolean;
}
