import { Component, Inject } from '@angular/core';
import { BehaviorSubject, first } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SecurityPinModalData } from './security-pin-modal.provider';
import { Employee } from '../../models/employee';

@Component({
  selector: 'app-security-pin-modal',
  templateUrl: './security-pin-modal.component.html',
  styleUrls: ['./security-pin-modal.component.scss']
})
export class SecurityPinModalComponent {

  securityCode = new BehaviorSubject<string>('');
  errorMessage: string;

  constructor(
    private dialogRef: MatDialogRef<SecurityPinModalComponent, [string, Employee]>,
    @Inject(MAT_DIALOG_DATA) public data: SecurityPinModalData
  ) {

  }

  setSecurityCode(securityCode: string) {

    this.securityCode.next(securityCode);
  }

  setErrorMessage(message: string) {

    this.errorMessage = message;

    setTimeout(() => {
      this.errorMessage = null;
    }, 1250)
  }

  keyPressed(value: string) {

    let securityCodeValue = this.securityCode.value;

    if (value == "Enter") {
      if (this.data.getAuthTokenCallback) {
        this.data.getAuthTokenCallback(securityCodeValue).pipe(first()).subscribe(([authToken, employee]) => {
          if (employee) {
            this.dialogRef.close([authToken, employee]);
          } else {
            this.securityCode.next('');
            this.setErrorMessage("UNAUTHORIZED");
          }
        });
      } else {
        this.cancel();
      }
    } else if (value == "Back") {
      if (securityCodeValue != null && securityCodeValue.length > 0) {
        this.securityCode.next(securityCodeValue.slice(0, securityCodeValue.length - 1));
      } else {
        this.cancel();
      }
    } else if (value == "Clear") {
      this.securityCode.next('');
    } else if (value == "Cancel") {
      this.cancel();
    } else if (securityCodeValue == null || securityCodeValue.length < 6) {
      this.securityCode.next(securityCodeValue + value);
    }
  }

  cancel() {
    this.dialogRef.close([null, null]);
  }
}
