import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { PermissionKeys } from "../keys";
import { Permission } from "../models/permission";

@Injectable()
export class PermissionService {

  private permissions: Permission[];

  constructor() {
  }

  list(): Observable<Permission[]> {

    if (this.permissions) {
      return of(this.permissions);
    } else {
      return of([
        { uid: PermissionKeys.CanUseSelfServiceHR, name: 'Can Use Self Service HR' },
        { uid: PermissionKeys.CanOpenCloseAccounts, name: 'Can Open/Close Accounts' },
        { uid: PermissionKeys.CanStartTerminalDrawer, name: 'Can Start Terminal Drawer' },
        { uid: PermissionKeys.CanEndTerminalDrawer, name: 'Can End Terminal Drawer' },
        { uid: PermissionKeys.CanViewSalesHistory, name: 'Can View Sales History' },
        { uid: PermissionKeys.CanExitApplication, name: 'Can Exit Application' },
        { uid: PermissionKeys.CanChangeTerminalGeneralSettings, name: 'Can Change Terminal General Settings' },
        { uid: PermissionKeys.CanChangeTerminalSetup, name: 'Can Change Terminal Setup' },
        { uid: PermissionKeys.CanChangeDeviceSettings, name: 'Can Change Device Settings' },
        { uid: PermissionKeys.CanReadTerminalMessages, name: 'Can Read Terminal Messages' },
        { uid: PermissionKeys.CanDeleteTerminalMessages, name: 'Can Delete Terminal Messages' },
        { uid: PermissionKeys.CanSeeLoggedInEmployees, name: 'Can See Logged In Employees' },
        { uid: PermissionKeys.CanUseCashRegister, name: 'Can Use Cash Register' },
        { uid: PermissionKeys.CanAuthorizeCardTransactions, name: 'Can Authorize Card Transactions' },
        { uid: PermissionKeys.CanCaptureCardTransactions, name: 'Can Capture Card Transactions' },
        { uid: PermissionKeys.CanVoidCardTransactions, name: 'Can Void Card Transactions' },
        { uid: PermissionKeys.CanRefundCardTransactions, name: 'Can Refund Card Transactions' },
        { uid: PermissionKeys.CanManageEmployees, name: 'Can Manage Employees' },
        { uid: PermissionKeys.SystemAdministrator, name: 'System Administrator' },
      ]);
    }
  }
}

