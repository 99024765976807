import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SpinnerModalProvider } from "core";
import { UUID } from "angular2-uuid";
import { AuthHeaderProvider } from 'core';
import { NavigationProvider } from 'core';
import { TerminalService } from '../../services';
import { Terminal } from '../../models';

export class TerminalForm {

  public form: UntypedFormGroup;
  public canSave = false;

  constructor(
    protected navigationProvider: NavigationProvider,
    protected spinnerModalProvider: SpinnerModalProvider,
    protected terminalService: TerminalService,
    protected authHeaderProvider: AuthHeaderProvider
  ) {
    this.form = new UntypedFormGroup({
      uid: new UntypedFormControl(null),
      name: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl(''),
      type: new UntypedFormControl(''),
      deviceId: new UntypedFormControl(''),
      defaultDrawerSeedAmount: new UntypedFormControl('')
    });

    this.form.valueChanges.subscribe(x => this.updateEnablement());
  }

  public loadForm(terminal: Terminal) {

    if (terminal) {
      this.form.get('uid').setValue(terminal.uid);
      this.form.get('name').setValue(terminal.name);
      this.form.get('description').setValue(terminal.description);
      this.form.get('type').setValue(terminal.description);
      this.form.get('deviceId').setValue(terminal.deviceId);
      this.form.get('defaultDrawerSeedAmount').setValue(terminal.defaultDrawerSeedAmount);
    }

    this.form.markAsPristine();
    this.updateEnablement();
  }

  public save() {

    if (this.form.dirty) {
      let saveDialogRef = this.spinnerModalProvider.open();

      saveDialogRef.afterOpened().subscribe(() => {
        if (this.form.get('uid').value) {
          this.terminalService.update(
            this.form.get('uid').value,
            this.form.get('name').value,
            this.form.get('description').value,
            this.form.get('type').value,
            this.form.get('deviceId').value,
            this.form.get('defaultDrawerSeedAmount').value,
            this.authHeaderProvider
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        } else {
          this.form.get('uid').setValue(UUID.UUID());

          this.terminalService.create(
            this.form.get('uid').value,
            this.form.get('name').value,
            this.form.get('description').value,
            this.form.get('type').value,
            this.form.get('deviceId').value,
            this.form.get('defaultDrawerSeedAmount').value,
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        }
      });
    } else {
      this.navigationProvider.backOneWaypoint();
    }
  }

  public cancel() {
    this.navigationProvider.backOneWaypoint();
  }

  protected updateEnablement() {

    this.canSave = this.form.dirty;
  }
}
