import { Injectable } from "@angular/core";
import { Page } from "core";
import { PaginationInput } from "core";
import { HttpService } from "core";
import { Member } from "../models/member";
import { OltpServiceSettings } from "../oltp-service-settings";
import { StoreModelFactory } from "../providers/store-model-factory";
import { MemberViewOptions, ViewFactory } from "../view-factory";
import { Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";

@Injectable()
export class MemberService {

  public static readonly MemberIndexView = <MemberViewOptions>{};
  public static readonly MemberFullView = <MemberViewOptions>{ identityUser: {} };

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = ViewFactory.buildMemberView(viewOptions);

    var request = {
      query: `query { getByUid(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'getByUid').pipe(
      map(x => StoreModelFactory.assignMember(x))
    );
  }

  getByIdentityId(identityId: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = ViewFactory.buildMemberView(viewOptions);

    var request = {
      query: `query { getByIdentityId(identityId: "${identityId}") ${view} }`
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'getByIdentityId').pipe(
      map(x => StoreModelFactory.assignMember(x))
    );
  }

  upsertByUid(uid: string, firstName: string, lastName: string, displayName: string, mobilePhone: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = ViewFactory.buildMemberView(viewOptions);

    var memberInput = {
      uid: uid,
      // firstName: firstName,
      // lastName: lastName,
      // displayName: displayName,
      // mobilePhone: mobilePhone
    };

    var request = {
      query: `mutation upsertByUid($member:MemberInput!) { upsertByUid(member: $member) ${view} }`,
      variables: { member: memberInput }
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'upsertByUid').pipe(
      map(x => StoreModelFactory.assignMember(x))
    );
  }

  search(statusUids: string[], paginationInput: PaginationInput, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Page<Member>> {

    let view = ViewFactory.buildMemberView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<Member>>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = StoreModelFactory.assignMember(edge.node)))
    );
  }

  enable(uid: string, authorizationId: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = ViewFactory.buildMemberView(viewOptions);

    var request = {
      query: `mutation enableMember { enable(uid: "${uid}", authorizationId: "${authorizationId}") ${view} }`
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'enable').pipe(
      map(x => StoreModelFactory.assignMember(x))
    );
  }

  disable(uid: string, authorizationId: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = ViewFactory.buildMemberView(viewOptions);

    var request = {
      query: `mutation disableMember { disable(uid: "${uid}", authorizationId: "${authorizationId}") ${view} }`
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'disable').pipe(
      map(x => StoreModelFactory.assignMember(x))
    );
  }

  update(uid: string, firstName: string, lastName: string, displayName: string, email: string, authorizationId: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = ViewFactory.buildMemberView(viewOptions);

    var memberInput = {
      uid: uid,
      firstName: firstName,
      lastName: lastName,
      displayName: displayName,
      email: email
    };

    var request = {
      query: `mutation updateMember($member:MemberInput!) { update(member: $member, authorizationId: "${authorizationId}") ${view} }`,
      variables: { member: memberInput }
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'update').pipe(
      map(x => StoreModelFactory.assignMember(x))
    );
  }

  delete(uid: string, authorizationId: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = ViewFactory.buildMemberView(viewOptions);

    var request = {
      query: `mutation deleteMember { delete(uid: "${uid}", authorizationId: "${authorizationId}") ${view} }`
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'delete').pipe(
      map(x => StoreModelFactory.assignMember(x))
    );
  }
}

