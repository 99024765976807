import { Component, Input, OnInit } from '@angular/core';
import { Subject, first } from 'rxjs';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Setting } from '../../models/setting';
import { NavigationProvider } from '../../providers/navigation.provider';
import { SettingProvider } from '../../providers/setting.provider';
import { isCaseInsensitiveEqual } from '../../functions/string';
import { SettingBaseComponent } from './setting-base.component';

@Component({
  selector: 'core-settings',
  templateUrl: './core-settings.component.html',
  styleUrls: ['./core-settings.component.scss']
})
export class CoreSettingsComponent implements OnInit {

  @Input("ownerUids") public ownerUids: string[];

  public title: string;
  public settings: Setting[];
  public currentSetting: Setting;
  public currentComponent: SettingBaseComponent<any, any>;

  private destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private navigationProvider: NavigationProvider,
    private settingProvider: SettingProvider,
  ) {
    this.navigationProvider.updateTitle("Settings");
    this.title = `Settings`;
  }

  ngOnInit() {

    // var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;

    this.settingProvider.search$(this.ownerUids).pipe(
      first()
    ).subscribe(settingsPage => {
      this.settings = settingsPage.edges.map(x => x.node);

      console.log(this.settings);

      this.updateCurrentSetting();
      this.patchSettings();

      if (!this.currentSetting) {
        if (this.settings.length > 0) {
          this.panelClick(this.settings[0]);
        }
      }
    });
  }

  ngOnDestroy() {

    this.destroyed$.next();
  }

  onActivate(event: SettingBaseComponent<any, any>) {

    this.currentComponent = event;

    if (this.settings) {
      this.updateCurrentSetting();
      this.patchSettings();
    }
  }

  onDeactivate(event: SettingBaseComponent<any, any>) {

    if (this.currentComponent && this.settings) {
      this.currentSetting.value = JSON.stringify((<FormGroup>event.form).getRawValue());
    }
  }

  private updateCurrentSetting() {

    const lastUrlSegment = this.router.url.split('?')[0].split('/').pop();
    const setting = this.settings.find(x => isCaseInsensitiveEqual(x.type, lastUrlSegment));
    this.currentSetting = setting;
  }

  private patchSettings() {

    if (this.currentComponent) {
      var setting = this.settings.find(x => isCaseInsensitiveEqual(x.type, this.currentSetting.type));
      if (setting) {

        this.currentComponent.patchForm(JSON.parse(setting.value));
      }
    }
  }

  public panelClick(setting: Setting) {

    this.navigationProvider.navigate(['admin/settings', setting.type.toLocaleLowerCase()]);
  }

  public cancel() {

    this.navigationProvider.backOneWaypoint();
  }

  public save() {

    this.onDeactivate(this.currentComponent);

    this.settingProvider.putMany$(this.settings).subscribe();
  }
}
