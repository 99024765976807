import { UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators, FormControl, FormGroup } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { ProductConfiguration, ProductConfigurationProductReference, ProductConfigurationPreparation, ProductConfigurationPreparationOption, ProductConfigurationVariation, ProductConfigurationVariationOption, ProductConfigurationPortion, ProductConfigurationInclusionGroup, ProductConfigurationInclusionGroupOption, ProductConfigurationAddOn, Product } from '../models/product';
import { ProductInput } from '../models/product-input';
import { ConfigurationFuncs } from './configuration.functions';
import { MenuPlacement, ProductStatusKeys } from '../keys';

export function serializeProductForm(form: UntypedFormGroup): ProductInput {

  return <Product>{
    uid: form.get('uid').value ? form.get('uid').value : UUID.UUID(),
    ownerUid: form.get('ownerUid').value || undefined,
    sku: form.get('sku').value || undefined,
    name: form.get('name').value || undefined,
    abbreviation: null || undefined,
    description: form.get('description').value || undefined,
    imageUid: form.get('imageUid').value || undefined,
    displayOrder: form.get('displayOrder').value || 0,
    departmentUid: form.get('departmentUid').value || undefined,
    categoryUid: form.get('categoryUid').value || undefined,
    basePrice: form.get('basePrice').value || 0,
    productTypeUid: form.get('productTypeUid').value || undefined,
    configuration: serializeProductConfigurationForm(<UntypedFormGroup>form.get('configuration')),
    productStatusUid: form.get('productStatusUid').value || undefined
  };
};

export function serializeProductConfigurationForm(form: UntypedFormGroup): ProductConfiguration {

  if (form) {
    var productConfiguration = <ProductConfiguration>{};

    var portions = (<UntypedFormArray>form.get('portions')).controls;
    if (portions.length > 0) {
      productConfiguration['portions'] = Object.values(portions).map(x => serializeProductPortionForm(<FormGroup>x));
    }

    var inclusionGroups = (<UntypedFormArray>form.get('inclusionGroups')).controls;
    if (inclusionGroups.length > 0) {
      productConfiguration['inclusionGroups'] = Object.values(inclusionGroups).map(x => serializeProductInclusionGroupForm(<UntypedFormGroup>x));
    }

    var addOns = (<UntypedFormArray>form.get('addOns')).controls;
    if (addOns.length > 0) {
      productConfiguration['addOns'] = Object.values(addOns).map(x => serializeProductAddOnForm(<UntypedFormGroup>x));
    }

    var preparations = (<UntypedFormArray>form.get('preparations')).controls;
    if (preparations.length > 0) {
      productConfiguration['preparations'] = Object.values(preparations).map(x => serializeProductPreparationForm(<UntypedFormGroup>x));
    }

    var variations = (<UntypedFormArray>form.get('variations')).controls;
    if (variations.length > 0) {
      productConfiguration['variations'] = Object.values(variations).map(x => serializeProductVariationForm(<UntypedFormGroup>x));
    }

    return <ProductConfiguration>productConfiguration;
  }

  return null;
}

export function serializeProductReferenceForm(form: UntypedFormGroup): ProductConfigurationProductReference {

  if (form && ConfigurationFuncs.validProductReference(form.value)) {
    return <ProductConfigurationProductReference>{
      uid: form.get('uid').value,
      portionUid: form.get('portionUid').value,
      version: form.get('version').value,
    };
  }

  return null;
}

export function serializeProductPreparationForm(form: UntypedFormGroup): ProductConfigurationPreparation {

  if (form) {
    var productConfigurationPreparation = <ProductConfigurationPreparation>{
      uid: form.get('uid').value,
      name: form.get('name').value || undefined,
      // defaultOptionUid: form.get('defaultOptionUid').value,
    };

    var options = (<UntypedFormArray>form.get('options')).controls;
    if (options.length > 0) {
      productConfigurationPreparation['options'] = Object.values(options).map(x => serializeProductPreparationOptionForm(<UntypedFormGroup>x));
    }

    return <ProductConfigurationPreparation>productConfigurationPreparation;
  }

  return null;
}

export function serializeProductPreparationOptionForm(form: UntypedFormGroup): ProductConfigurationPreparationOption {

  if (form) {
    var productConfigurationPreparationOption = {
      uid: form.get('uid').value,
      name: form.get('name').value || undefined,
      // additionalPrice: form.get('additionalPrice').value || undefined
    };

    return <ProductConfigurationPreparationOption>productConfigurationPreparationOption;
  }

  return null;
}

export function serializeProductVariationForm(form: UntypedFormGroup): ProductConfigurationVariation {

  if (form) {
    var productConfigurationVariation = <ProductConfigurationVariation>{
      uid: form.get('uid').value,
      name: form.get('name').value || undefined,
      // defaultOptionUid: form.get('defaultOptionUid').value,
    };

    var options = (<UntypedFormArray>form.get('options')).controls;
    if (options.length > 0) {
      productConfigurationVariation['options'] = Object.values(options).map(x => serializeProductVariationOptionForm(<UntypedFormGroup>x));
    }

    return <ProductConfigurationVariation>productConfigurationVariation;
  }

  return null;
}

export function serializeProductVariationOptionForm(form: UntypedFormGroup): ProductConfigurationVariationOption {

  if (form) {
    var productConfigurationVariationOption = <ProductConfigurationVariationOption>{
      uid: form.get('uid').value,
      alias: form.get('alias').value || undefined,
      // priceOverride: form.get('priceOverride').value,
    };

    var productReferenceForm = <UntypedFormGroup>form.get('productReference');
    if (ConfigurationFuncs.validProductReference(productReferenceForm?.value)) {
      productConfigurationVariationOption.productReference = serializeProductReferenceForm(<UntypedFormGroup>form.get('productReference'));
    }

    return <ProductConfigurationVariationOption>productConfigurationVariationOption;
  }

  return null;
}

export function serializeProductPortionForm(form: UntypedFormGroup): ProductConfigurationPortion {

  if (form) {
    var productConfigurationPortion = <ProductConfigurationPortion>{
      uid: form.get('uid').value,
      name: form.get('name').value || undefined,
      menuPlacementUid: form.get('menuPlacementUid').value || undefined,
      availableAsInclusion: form.get('availableAsInclusion').value || undefined,
      availableAsAddOn: form.get('availableAsAddOn').value || undefined,
      price: form.get('price').value || 0
    };

    var inclusionGroups = (<UntypedFormArray>form.get('inclusionGroups')).controls;
    if (inclusionGroups.length > 0) {
      productConfigurationPortion['inclusionGroups'] = Object.values(inclusionGroups).map(x => serializeProductInclusionGroupForm(<UntypedFormGroup>x));
    }

    var addOns = (<UntypedFormArray>form.get('addOns')).controls;
    if (addOns.length > 0) {
      productConfigurationPortion['addOns'] = Object.values(addOns).map(x => serializeProductAddOnForm(<UntypedFormGroup>x));
    }

    var preparations = (<UntypedFormArray>form.get('preparations')).controls;
    if (preparations.length > 0) {
      productConfigurationPortion['preparations'] = Object.values(preparations).map(x => serializeProductPreparationForm(<UntypedFormGroup>x));
    }

    var variations = (<UntypedFormArray>form.get('variations')).controls;
    if (variations.length > 0) {
      productConfigurationPortion['variations'] = Object.values(variations).map(x => serializeProductVariationForm(<UntypedFormGroup>x));
    }

    return <ProductConfigurationPortion>productConfigurationPortion;
  }

  return null;
}

export function serializeProductInclusionGroupForm(form: UntypedFormGroup): ProductConfigurationInclusionGroup {

  if (form) {
    var productConfigurationInclusionGroup = <ProductConfigurationInclusionGroup>{
      uid: form.get('uid').value,
      name: form.get('name').value || undefined,
      maxDistinctOptionsIncluded: form.get('maxDistinctOptionsIncluded').value || undefined,
      minQuantityForOption: form.get('minQuantityForOption').value || undefined,
      maxTotalOptionQuantityIncluded: form.get('maxTotalOptionQuantityIncluded').value || undefined,
      allowAdditional: form.get('allowAdditional').value || undefined,
    };

    var options = (<UntypedFormArray>form.get('options')).controls;
    if (options.length > 0) {
      productConfigurationInclusionGroup['options'] = Object.values(options).map(x => serializeProductInclusionGroupOptionForm(<UntypedFormGroup>x));
    }

    return <ProductConfigurationInclusionGroup>productConfigurationInclusionGroup;
  }

  return null;
}

export function serializeProductInclusionGroupOptionForm(form: UntypedFormGroup): ProductConfigurationInclusionGroupOption {

  if (form) {
    var productConfigurationInclusionGroupOption = new ProductConfigurationInclusionGroupOption();
    productConfigurationInclusionGroupOption.uid = form.get('uid').value;
    productConfigurationInclusionGroupOption.alias = form.get('alias').value || undefined;
    productConfigurationInclusionGroupOption.defaultQuantity = form.get('defaultQuantity').value || undefined;
    productConfigurationInclusionGroupOption.maxIncludedQuantity = form.get('maxIncludedQuantity').value || undefined;
    // productConfigurationInclusionGroupOption.priceOverride= form.get('priceOverride').value;

    var productReferenceForm = <UntypedFormGroup>form.get('productReference');
    if (ConfigurationFuncs.validProductReference(productReferenceForm?.value)) {
      productConfigurationInclusionGroupOption.productReference = serializeProductReferenceForm(<UntypedFormGroup>form.get('productReference'));
    }

    var preparations = (<UntypedFormArray>form.get('preparations')).controls;
    if (preparations.length > 0) {
      productConfigurationInclusionGroupOption['preparations'] = Object.values(preparations).map(x => serializeProductPreparationForm(<UntypedFormGroup>x));
    }

    var variations = (<UntypedFormArray>form.get('variations')).controls;
    if (variations.length > 0) {
      productConfigurationInclusionGroupOption['variations'] = Object.values(variations).map(x => serializeProductVariationForm(<UntypedFormGroup>x));
    }

    return productConfigurationInclusionGroupOption;
  }

  return null;
}

export function serializeProductAddOnForm(form: UntypedFormGroup): ProductConfigurationAddOn {

  if (form) {
    var productConfigurationAddOn = <ProductConfigurationAddOn>{
      uid: form.get('uid').value,
      alias: form.get('alias').value || undefined,
      // priceOverride?: number;
    };

    var productReferenceForm = <UntypedFormGroup>form.get('productReference');
    if (ConfigurationFuncs.validProductReference(productReferenceForm?.value)) {
      productConfigurationAddOn.productReference = serializeProductReferenceForm(<UntypedFormGroup>form.get('productReference'));
    }

    return <ProductConfigurationAddOn>productConfigurationAddOn;
  }

  return null;
}

export function buildProductConfigurationForm(product: Product) {

  let configuration = product?.configuration;
  // if (configuration) {
  return new UntypedFormGroup({
    portions: new UntypedFormArray(configuration && configuration.portions ? configuration.portions.map(x => buildProductPortionForm(x)) : []),
    inclusionGroups: new UntypedFormArray(configuration && configuration.inclusionGroups ? configuration.inclusionGroups.map(x => buildProductInclusionGroupForm(x)) : []),
    addOns: new UntypedFormArray(configuration && configuration.addOns ? configuration.addOns.map(x => buildProductAddOnForm(x)) : []),
    preparations: new UntypedFormArray(configuration && configuration.preparations ? configuration.preparations.map(x => buildProductPreparationForm(x)) : []),
    variations: new UntypedFormArray(configuration && configuration.variations ? configuration.variations.map(x => buildProductVariationForm(x)) : [])
  });
  // }

  // return null;
};

export function buildProductReferenceForm(productReference: ProductConfigurationProductReference): UntypedFormGroup {

  return new UntypedFormGroup({
    uid: new UntypedFormControl(productReference?.uid, [Validators.required]),
    portionUid: new UntypedFormControl(productReference?.portionUid, [Validators.required]),
    version: new UntypedFormControl(productReference?.version, [Validators.required]),
  });
};

export function buildProductPreparationForm(preparation: ProductConfigurationPreparation): UntypedFormGroup {

  return new UntypedFormGroup({
    uid: new UntypedFormControl(preparation ? preparation.uid : UUID.UUID(), [Validators.required]),
    name: new UntypedFormControl(preparation ? preparation.name : null, [Validators.required]),
    options: new UntypedFormArray(preparation && preparation.options ? preparation.options.map(x => buildProductPreparationOptionForm(x)) : [])
  });
};

export function buildProductPreparationOptionForm(preparationOption: ProductConfigurationPreparationOption): UntypedFormGroup {

  return new UntypedFormGroup({
    uid: new UntypedFormControl(preparationOption ? preparationOption.uid : UUID.UUID(), [Validators.required]),
    name: new UntypedFormControl(preparationOption ? preparationOption.name : '', [Validators.required]),
  });
};

export function buildProductVariationForm(variation: ProductConfigurationVariation): UntypedFormGroup {

  return new UntypedFormGroup({
    uid: new UntypedFormControl(variation ? variation.uid : UUID.UUID(), [Validators.required]),
    name: new UntypedFormControl(variation ? variation.name : null),
    options: new UntypedFormArray(variation && variation.options ? variation.options.map(x => buildProductVariationOptionForm(x)) : [])
  });
};

export function buildProductVariationOptionForm(option: ProductConfigurationVariationOption): UntypedFormGroup {

  return new UntypedFormGroup({
    uid: new UntypedFormControl(option ? option.uid : UUID.UUID(), [Validators.required]),
    productReference: buildProductReferenceForm(option ? option.productReference : null),
    alias: new UntypedFormControl(option ? option.alias : null)
  });
};

export function buildProductPortionForm(portion: ProductConfigurationPortion): UntypedFormGroup {

  return new UntypedFormGroup({
    uid: new FormControl<string>(portion ? portion.uid : UUID.UUID(), [Validators.required]),
    name: new FormControl<string>(portion ? portion.name : '', [Validators.required]),
    menuPlacementUid: new FormControl<string>(portion ? portion.menuPlacementUid : MenuPlacement.Menu),
    availableAsInclusion: new FormControl<boolean>(portion ? portion.availableAsInclusion : false),
    availableAsAddOn: new FormControl<boolean>(portion ? portion.availableAsAddOn : false),
    price: new FormControl<number>(portion ? portion.price : 0, [Validators.required]),
    inclusionGroups: new UntypedFormArray(portion && portion.inclusionGroups ? portion.inclusionGroups.map(x => buildProductInclusionGroupForm(x)) : []),
    addOns: new UntypedFormArray(portion && portion.addOns ? portion.addOns.map(x => buildProductAddOnForm(x)) : []),
    preparations: new UntypedFormArray(portion && portion.preparations ? portion.preparations.map(x => buildProductPreparationForm(x)) : []),
    variations: new UntypedFormArray(portion && portion.variations ? portion.variations.map(x => buildProductVariationForm(x)) : []),
    productStatusUid: new FormControl<string>(portion ? portion.productStatusUid : ProductStatusKeys.Active),
  });
};

export function buildProductInclusionGroupForm(inclusionGroup: ProductConfigurationInclusionGroup): UntypedFormGroup {

  return new UntypedFormGroup({
    uid: new UntypedFormControl(inclusionGroup ? inclusionGroup.uid : UUID.UUID(), [Validators.required]),
    name: new UntypedFormControl(inclusionGroup ? inclusionGroup.name : null),
    options: new UntypedFormArray(inclusionGroup && inclusionGroup.options ? inclusionGroup.options.map(x => buildProductInclusionGroupOptionForm(x)) : []),
    maxDistinctOptionsIncluded: new UntypedFormControl(inclusionGroup ? inclusionGroup.maxDistinctOptionsIncluded : null),
    minQuantityForOption: new UntypedFormControl(inclusionGroup ? inclusionGroup.minQuantityForOption : 1),
    maxTotalOptionQuantityIncluded: new UntypedFormControl(inclusionGroup ? inclusionGroup.maxTotalOptionQuantityIncluded : 0),
    allowAdditional: new UntypedFormControl(inclusionGroup ? inclusionGroup.allowAdditional : false)
  });
};

export function buildProductInclusionGroupOptionForm(option: ProductConfigurationInclusionGroupOption): UntypedFormGroup {

  return new UntypedFormGroup({
    uid: new UntypedFormControl(option ? option.uid : UUID.UUID(), [Validators.required]),
    productReference: buildProductReferenceForm(option ? option.productReference : null),
    alias: new UntypedFormControl(option ? option.alias : null),
    defaultQuantity: new UntypedFormControl(option ? option.defaultQuantity : 0),
    maxIncludedQuantity: new UntypedFormControl(option ? option.maxIncludedQuantity : 1),
    preparations: new UntypedFormArray(option && option.preparations ? option.preparations.map(x => buildProductPreparationForm(x)) : []),
    variations: new UntypedFormArray(option && option.variations ? option.variations.map(x => buildProductVariationForm(x)) : [])
  });
};

export function buildProductAddOnForm(addOn: ProductConfigurationAddOn): UntypedFormGroup {

  return new UntypedFormGroup({
    uid: new UntypedFormControl(addOn ? addOn.uid : UUID.UUID(), [Validators.required]),
    productReference: buildProductReferenceForm(addOn ? addOn.productReference : null),
    alias: new UntypedFormControl(addOn ? addOn.alias : ''),
    priceOverride: new UntypedFormControl(addOn ? addOn.priceOverride : 0),
  });
};
