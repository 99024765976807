export class Setting {

  uid: string;
  ownerUid: string;
  type: string;
  name: string;
  value: string;

  public getParsedValue<T>() {

    return JSON.parse(this.value) as T;
  }
}
