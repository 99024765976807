import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProductAndPortionModalData, ProductAndPortionModalResult } from "./product-and-portion-modal.provider";
import { ProductConfigurationProductReference } from '../../models/product';

@Component({
  selector: 'app-product-and-portion-modal',
  templateUrl: './product-and-portion-modal.component.html',
  styleUrls: ['./product-and-portion-modal.component.scss']
})
export class ProductAndPortionModalComponent {

  public selections: ProductAndPortion[];

  constructor(
    private dialogRef: MatDialogRef<ProductAndPortionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProductAndPortionModalData
  ) {

    this.selections = this.data.products.map(product => {
      return product.configuration.portions.map(portion => {
        return <ProductAndPortion>{
          display: `${product.name} - ${portion.name}`,
          productReference: {
            uid: product.uid,
            portionUid: portion.uid,
            version: product.version
          }
        }
      });
    }).reduce((x, y) => x.concat(y), []);
  }

  select(selection: ProductAndPortion) {

    this.dialogRef.close(<ProductAndPortionModalResult>{ productReference: selection.productReference });
  }
}

class ProductAndPortion {

  display: string;
  productReference: ProductConfigurationProductReference;
}
