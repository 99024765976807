import { animate, state, style, transition, trigger } from '@angular/animations';

export const fadeInOutAnimation = trigger('fadeInOut', [
  transition('* => fadeIn', [
    style({ opacity: 0 }),
    animate("{{ duration }}", style({ opacity: 1 }))
  ], {
    params: {
      duration: 1000
    }
  }),
  transition('* => fadeOut', [
    animate(1000, style({ opacity: 0 })),
  ])
]);
// UNFINISHED https://www.yearofmoo.com/2017/06/new-wave-of-animation-features.html
