import { GraphApiErrorResult } from "../models";

export function isGraphError(error: any) {

    return (error as GraphApiErrorResult)?.errors?.length > 0;
}

export function getGraphApiErrorMessage(error: GraphApiErrorResult) {

    return error.errors.map(error => error.message.indexOf(':') > -1 ? error.message.slice(error.message.indexOf(':') + 2) : error.message).join('\r\n');
}

export function getGraphApiErrorMessageHtml(error: GraphApiErrorResult) {

    return error.errors.map(error => `<p>${error.message.indexOf(':') > -1 ? error.message.slice(error.message.indexOf(':') + 2) : error.message}</p>`).join('');
}
