
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class DebugErrorHandlingProvider extends ErrorHandler {

    constructor(
    ) {
        super();
    }

    override handleError(error: any) {

        console.log(error);
        super.handleError(error);
    }
}
