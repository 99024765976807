import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmModalData } from "./confirm-modal.provider";

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  message: string;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalData
  ) {

  }
}

