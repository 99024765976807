import { Pipe, PipeTransform } from '@angular/core';
import { WorkUnitKeys } from '../keys';

@Pipe({
  name: 'workUnit'
})
export class WorkUnitPipe implements PipeTransform {

  private frequencyMap = new Map<string, string>([
    [WorkUnitKeys.Hourly, 'Per Hour'],
    [WorkUnitKeys.PerOccurence, 'Per Occurence'],
    [WorkUnitKeys.PerDay, 'Per Day'],
    [WorkUnitKeys.PerWeek, 'Per Week'],
    [WorkUnitKeys.PerPayPeriod, 'Per Pay Period'],
    [WorkUnitKeys.PerMonth, 'Per Month'],
    [WorkUnitKeys.PerYear, 'Per Year'],
    ['b5603ba3-fae1-496c-aa3d-fe8764922691', 'Per Hour'],
    ['ab679de4-e499-4b0a-a954-26af4002c396', 'Per Occurence'],
    ['7d10bc55-6ffd-42f5-95d1-fb49d059aa93', 'Per Day'],
    ['91a09fde-bd6d-4a6d-a2d2-9848201cdbeb', 'Per Week'],
    ['0053979d-6825-434a-b7d9-04aee03e751c', 'Per Pay Period'],
    ['99087cec-27c7-4561-b89c-b5f53b39e38b', 'Per Month'],
    ['34eeae91-bc48-4c89-8ee5-f30654d8a0bc', 'Per Year']
  ]);

  private singularMap = new Map<string, string>([
    [WorkUnitKeys.Hourly, 'Hour'],
    [WorkUnitKeys.PerOccurence, 'Occurence'],
    [WorkUnitKeys.PerDay, 'Day'],
    [WorkUnitKeys.PerWeek, 'Week'],
    [WorkUnitKeys.PerPayPeriod, 'Pay Period'],
    [WorkUnitKeys.PerMonth, 'Month'],
    [WorkUnitKeys.PerYear, 'Year'],
    ['b5603ba3-fae1-496c-aa3d-fe8764922691', 'Hour'],
    ['ab679de4-e499-4b0a-a954-26af4002c396', 'Occurence'],
    ['7d10bc55-6ffd-42f5-95d1-fb49d059aa93', 'Day'],
    ['91a09fde-bd6d-4a6d-a2d2-9848201cdbeb', 'Week'],
    ['0053979d-6825-434a-b7d9-04aee03e751c', 'Pay Period'],
    ['99087cec-27c7-4561-b89c-b5f53b39e38b', 'Month'],
    ['34eeae91-bc48-4c89-8ee5-f30654d8a0bc', 'Year']
  ]);


  private pluralMap = new Map<string, string>([
    [WorkUnitKeys.Hourly, 'Hours'],
    [WorkUnitKeys.PerOccurence, 'Occurences'],
    [WorkUnitKeys.PerDay, 'Days'],
    [WorkUnitKeys.PerWeek, 'Weeks'],
    [WorkUnitKeys.PerPayPeriod, 'Pay Periods'],
    [WorkUnitKeys.PerMonth, 'Months'],
    [WorkUnitKeys.PerYear, 'Years'],
    ['b5603ba3-fae1-496c-aa3d-fe8764922691', 'Hours'],
    ['ab679de4-e499-4b0a-a954-26af4002c396', 'Occurences'],
    ['7d10bc55-6ffd-42f5-95d1-fb49d059aa93', 'Days'],
    ['91a09fde-bd6d-4a6d-a2d2-9848201cdbeb', 'Weeks'],
    ['0053979d-6825-434a-b7d9-04aee03e751c', 'Pay Periods'],
    ['99087cec-27c7-4561-b89c-b5f53b39e38b', 'Months'],
    ['34eeae91-bc48-4c89-8ee5-f30654d8a0bc', 'Years']
  ]);

  constructor(
  ) {
  }

  transform(value: any, option: string): string {

    option = option || 'frequency';

    if (option == 'frequency') {
      return this.frequencyMap.get(value);
    } else if (option == 'singular') {
      return this.singularMap.get(value);
    } else if (option == 'plural') {
      return this.pluralMap.get(value);
    }

    return null;
  }
}
