import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IDecimalModalData } from "core";

@Component({
  selector: 'app-store-decimal-modal',
  templateUrl: './store-decimal-modal.component.html',
  styleUrls: ['./store-decimal-modal.component.scss']
})
export class StoreDecimalModalComponent {

  public form: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<StoreDecimalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDecimalModalData
  ) {
    this.form = new UntypedFormGroup({
      value: new UntypedFormControl('', { validators: [Validators.required, Validators.min(0), Validators.max(this.data.initialValue)], updateOn: 'blur' })
    });
  }

  commit() {

    let value = this.form.dirty ? this.form.get('fulfilledQuantity').value : null;
    this.dialogRef.close(value);
  }

  cancel() {

    this.dialogRef.close();
  }
}
