import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SettingBaseComponent } from 'core';
import { TransactionSettings } from '../../models/transaction-settings';

export interface TransactionSettingFormGroupType {

  recentTransactionHourMinute: FormControl<string>;
  transactionAutoClearDuration: FormControl<number>;
  combineDuplicateItems: FormControl<boolean>;
  // configurationAlwaysShowContextMenus: FormControl<boolean>;
};

@Component({
  selector: 'transaction-transaction-setting',
  templateUrl: './transaction-setting.component.html',
  styleUrls: ['./transaction-setting.component.scss']
})
export class TransactionSettingComponent extends SettingBaseComponent<TransactionSettings, TransactionSettingFormGroupType> {

  constructor(
  ) {
    super();

    this.form = new FormGroup({
      recentTransactionHourMinute: new FormControl<string>(null),
      transactionAutoClearDuration: new FormControl<number>(null),
      combineDuplicateItems: new FormControl<boolean>(false),
      // configurationAlwaysShowContextMenus: new FormControl<boolean>(false)
    });
  }

  public override patchForm(value: TransactionSettings): void {

    this.form.patchValue(value);
  }
}
