import { Injectable } from "@angular/core";
import { CurrentDateTimeProvider, SettingProvider } from "core";
import { Observable, Subject } from "rxjs";
import { concatMap, filter, finalize, map, mergeMap, switchMap, takeUntil, tap } from "rxjs/operators";
import { TerminalSettings } from "../models";
import { TerminalProvider } from ".";

@Injectable()
export class TerminalSleepAlertProvider {

  public sleepAlert$: Observable<void>;

  private lastEmittedDateTime: Date;
  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  constructor(
    private currentDateTimeProvider: CurrentDateTimeProvider,
    private terminalProvider: TerminalProvider,
    private settingProvider: SettingProvider
  ) {
    this.initialize();
  }

  ngOnDestroy() {

    this.refresh$.next(null);
    this.destroyed$.next(null);
  }

  private initialize() {

    this.lastEmittedDateTime = this.currentDateTimeProvider.currentDateTime;

    this.sleepAlert$ = this.terminalProvider.active$.pipe(
      takeUntil(this.destroyed$),
      filter(terminal => !!terminal),
      concatMap(terminal => this.settingProvider.getOneByTypeAndOwner$<TerminalSettings>('TerminalSettings', terminal.uid).pipe(
        tap(terminalSettings => {
          console.log(terminalSettings);
        }),
        switchMap(terminalSettings => {
          this.refresh$.next();

          const sleepAlertReloadMs = terminalSettings?.sleepAlertReloadMs || 10000;

          return this.currentDateTimeProvider.currentDateTime$.pipe(
            takeUntil(this.refresh$),
            map(currentDateTime => {
              const intervalTimespan = currentDateTime.getTime() - this.lastEmittedDateTime.getTime();
              this.lastEmittedDateTime = currentDateTime;

              return intervalTimespan > sleepAlertReloadMs;
            }),
            filter(alert => alert),
            map(_ => { return; })
          );
        })
      ))
    )
  };
}
