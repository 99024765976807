import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IdentityUser } from '../models/identity-user';
import { CacheService } from '../services/cache.service';

@Injectable()
export class IdentityUserResolver {

  constructor(
    private cacheService: CacheService
  ) {
  }

  resolve(uid: string) : Observable<IdentityUser> {

    if (uid) {
      var item = this.cacheService.getOrAdd(uid, () => {
        var identityUser = new IdentityUser();
        identityUser.id = uid;
        identityUser.username = uid;
        identityUser.firstName = uid;
        identityUser.lastName = uid;

        return of(identityUser);
      });

      return item;
    }

    return of(null);

  }
}
