<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <div style="display: flex;">
    <div *ngFor="let image of data.images" style="width: 8.3333vw; height: 8.3333vh;">
        <div style="height: 100%; width: 100%; background-color: gray; border-radius: 4px; padding: 4px; display: flex; justify-content: center; align-items: center;" (click)="selectOption(image)">
          <img style="" src="{{ data.imageHostUrl + '/' + image.uid + '?height=65' }}">
        </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <span style="flex: 1 1 auto;"></span>
  <button mat-stroked-button color="warn" [mat-dialog-close]>Cancel</button>
</mat-dialog-actions>
