import { Injectable } from "@angular/core";
import { HttpService } from "core";
import { Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { ProductLibraryConfig } from "../product-library-config";
import { Department } from '../models/department';
import { PaginationInput } from "core";
import { Page } from "core";
import { CategoryService, CategoryViewOptions } from "./category.service";
import { ProductModelFactory } from "../product-model-factory";

@Injectable()
export class DepartmentService {

  public static readonly DepartmentFullView = <DepartmentViewOptions>{ includeCategories: true };
  public static readonly DepartmentIndexView = <DepartmentViewOptions>{};

  private readonly endPoint = 'api/product/department';

  constructor(
    private httpService: HttpService,
    private productLibraryConfig: ProductLibraryConfig,
  ) {
  }

  getByUid(uid: string, viewOptions: DepartmentViewOptions = null, identityToken: string = null): Observable<Department> {

    let view = DepartmentService.buildView(viewOptions || DepartmentService.DepartmentFullView);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Department>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'getByUid', identityToken).pipe(
      map(x => ProductModelFactory.assignDepartment(x))
    );
  }

  getByUids(uids: string[], viewOptions: DepartmentViewOptions = null, identityToken: string = null): Observable<Department[]> {

    let view = DepartmentService.buildView(viewOptions || DepartmentService.DepartmentFullView);

    var request = {
      query: `query { getByUids (uids: ${JSON.stringify(uids)}) ${view} }`
    };

    return this.httpService.graph<Department[]>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'getByUids', identityToken).pipe(
      map(x => x.map(y => ProductModelFactory.assignDepartment(y)))
    );
  }

  search(ownerUid: string, statusUids: string[], paginationInput: PaginationInput, viewOptions: DepartmentViewOptions = null, identityToken: string = null): Observable<Page<Department>> {

    let view = DepartmentService.buildView(viewOptions || DepartmentService.DepartmentFullView);

    var request = {
      query: `query search($pagination:PaginationInput) { search(ownerUid: "${ownerUid}", statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: paginationInput
    };

    return this.httpService.graph<Page<Department>>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'search', identityToken).pipe(
      tap(x => x.edges.forEach(edge => edge.node = ProductModelFactory.assignDepartment(edge.node)))
    );
  }

  create(uid: string, ownerUid: string, name: string, accountSettingsUid: string, isSalesTaxIncluded: boolean, taxAccountSettingUid: string, viewOptions: DepartmentViewOptions = null, identityToken: string = null): Observable<Department> {

    let view = DepartmentService.buildView(viewOptions || DepartmentService.DepartmentFullView);

    var departmentInput = {
      uid: uid,
      ownerUid: ownerUid,
      name: name,
      accountSettingsUid: accountSettingsUid,
      isSalesTaxIncluded: isSalesTaxIncluded,
      taxAccountSettingUid: taxAccountSettingUid
    };

    var request = {
      query: `mutation create($department:DepartmentInput!) { create(department: $department) ${view} }`,
      variables: { department: departmentInput }
    };

    return this.httpService.graph<Department>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'create', identityToken).pipe(
      map(x => ProductModelFactory.assignDepartment(x))
    );
  }

  update(uid: string, name: string, accountSettingsUid: string, isSalesTaxIncluded: boolean, taxAccountSettingUid: string, viewOptions: DepartmentViewOptions = null, identityToken: string = null): Observable<Department> {

    let view = DepartmentService.buildView(viewOptions || DepartmentService.DepartmentFullView);

    var departmentInput = {
      uid: uid,
      name: name,
      accountSettingsUid: accountSettingsUid,
      isSalesTaxIncluded: isSalesTaxIncluded,
      taxAccountSettingUid: taxAccountSettingUid
    };

    var request = {
      query: `mutation update($department:DepartmentInput!) { update(department: $department) ${view} }`,
      variables: { department: departmentInput }
    };

    return this.httpService.graph<Department>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'update', identityToken).pipe(
      map(x => ProductModelFactory.assignDepartment(x))
    );
  }

  enable(uid: string, viewOptions: DepartmentViewOptions = null, identityToken: string = null): Observable<Department> {

    let view = DepartmentService.buildView(viewOptions || DepartmentService.DepartmentFullView);

    var request = {
      query: `mutation enable { enable(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Department>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'enable', identityToken).pipe(
      map(x => ProductModelFactory.assignDepartment(x))
    );
  }

  disable(uid: string, viewOptions: DepartmentViewOptions = null, identityToken: string = null): Observable<Department> {

    let view = DepartmentService.buildView(viewOptions || DepartmentService.DepartmentFullView);

    var request = {
      query: `mutation disable { disable(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Department>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'disable', identityToken).pipe(
      map(x => ProductModelFactory.assignDepartment(x))
    );
  }

  delete(uid: string, viewOptions: DepartmentViewOptions = null, identityToken: string = null): Observable<Department> {

    let view = DepartmentService.buildView(viewOptions || DepartmentService.DepartmentFullView);

    var request = {
      query: `mutation delete { delete(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Department>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'delete', identityToken).pipe(
      map(x => ProductModelFactory.assignDepartment(x))
    );
  }

  public static buildView(viewOptions: DepartmentViewOptions = null, identityToken: string = null) {

    let view = `uid ownerUid name accountSettingUid isSalesTaxIncluded taxAccountSettingUid departmentStatusUid`;

    if (viewOptions.includeCategories) {
      view += ` categories ${CategoryService.buildView(<CategoryViewOptions>{})}`;
    }

    return '{ ' + view + ' }';
  }
}

export interface DepartmentViewOptions {

  includeCategories: boolean;
}
