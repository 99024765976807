import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmployeeShiftsComponent } from './components/employee/shifts/employee-shifts.component';

import { SiteEmployeeDashboardComponent } from './components/employee/dashboard/site-employee-dashboard.component';
import { SiteEmployeeProfileComponent } from './components/employee/profile/site-employee-profile.component';
import { SiteEmployeeShiftComponent } from './components/employee/shift/site-employee-shift.component';
import { SitePublicDartsComponent } from './components/public/darts/site-public-darts.component';
import { SitePublicHomeComponent } from './components/public/home/site-public-home.component';
import { SitePublicMenuComponent } from './components/public/menu/site-public-menu.component';
import { SitePublicProfileComponent } from './components/public/profile/site-public-profile.component';
// import { SiteShellAlertsComponent } from './components/shell/alerts/site-shell-alerts.component';
import { SiteShellComponent } from './components/shell/site-shell.component';

import { BackOfficeDrawerComponent } from './components/back-office/drawer/back-office-drawer.component';
import { BackOfficeDrawersComponent } from './components/back-office/drawers/back-office-drawers.component';
import { BackOfficeEmployeePermissionsComponent } from './components/back-office/employee/permissions/back-office-employee-permissions.component';
import { BackOfficeEmployeePositionsComponent } from './components/back-office/employee/positions/back-office-employee-positions.component';
import { BackOfficeEmployeeShiftsComponent } from './components/back-office/employee/shifts/back-office-employee-shifts.component';
import { BackOfficeEmployeesComponent } from './components/back-office/employees/back-office-employees.component';
import { BackOfficePayPeriodComponent } from './components/back-office/pay-period/back-office-pay-period.component';
import { BackOfficePayPeriodReportComponent } from './components/back-office/pay-period/report/back-office-pay-period-report.component';
import { BackOfficePayPeriodsComponent } from './components/back-office/pay-periods/back-office-pay-periods.component';
import { BackOfficePositionComponent } from './components/back-office/position/back-office-position.component';
import { BackOfficePositionsComponent } from './components/back-office/positions/back-office-positions.component';
import { BackOfficeProductsComponent } from './components/back-office/products/back-office-products.component';
import { BackOfficeShiftsComponent } from './components/back-office/shifts/back-office-shifts.component';
import { BackOfficeTransactionsComponent } from './components/back-office/transactions/back-office-transactions.component';
import { BackOfficeAccountsComponent } from './components/back-office/accounts/back-office-accounts.component';
import { BackOfficeDashboardComponent } from './components/back-office/dashboard/back-office-dashboard.component';
import { BackOfficeEmployeeProfileComponent } from './components/back-office/employee/back-office-employee-profile.component';
import { BackOfficeEmployeePaymentComponent } from './components/back-office/employee/payment/back-office-employee-payment.component';
import { BackOfficeReportsComponent } from './components/back-office/reports/back-office-reports.component';
import { BackOfficeReportsDailySalesChartComponent } from './components/back-office/reports/daily-sales/back-office-reports-daily-sales-chart.component';
import { BackOfficeShiftComponent } from './components/back-office/shift/back-office-shift.component';
import { BackOfficePayPeriodShiftsComponent } from './components/back-office/pay-period/shifts/back-office-pay-period-shifts.component';

import { AdministrationDamComponent } from './components/administration/dam/administration-dam.component';
import { AdministrationDashboardComponent } from './components/administration/dashboard/administration-dashboard.component';
import { AdministrationMembersComponent } from './components/administration/members/administration-members.component';
import { AdministrationMemberProfileComponent } from './components/administration/members/profile/administration-member-profile.component';
import { BackOfficeDepartmentsComponent } from './components/back-office/departments/back-office-departments.component';
import { BackOfficeDepartmentComponent } from './components/back-office/department/back-office-department.component';
import { BackOfficeCategoriesComponent } from './components/back-office/categories/back-office-categories.component';
import { BackOfficeProductCategoryComponent } from './components/back-office/category/back-office-category.component';

import { AuthorizationGuard } from './guards/authorization.guard';
import { AuthorizationCanGuard } from './guards/authorization.can.guard';
import { TopNavigationComponent } from './components/shell/top-navigation/top-navigation.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PosSettingComponent, RegisterComponent } from 'pos-core';
import { LogisticSettingComponent, TransactionSettingComponent } from 'downtown-transaction';
import { SiteSettingsComponent } from './components/administration/site-settings/site-settings.component';
import { TerminalsComponent } from './components/administration/terminals/terminals.component';
import { TerminalComponent } from './components/administration/terminal/terminal.component';

const appRoutes: Routes = [
  {
    path: '',
    children: [
      /* Top level site routes */
      {
        path: '',
        component: SitePublicHomeComponent
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent
      },
      {
        path: 'darts',
        component: SitePublicDartsComponent
      },
      {
        path: 'menu',
        component: SitePublicMenuComponent
      },
      {
        path: 'menu/:categoryUid',
        component: SitePublicMenuComponent
      },

      /* Member routes */
      {
        path: 'member',
        children: [
          {
            path: ':memberUid/profile',
            component: SitePublicProfileComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
        ]
      },

      /* Administration routes */
      {
        path: 'admin',
        children: [
          {
            path: 'dashboard',
            component: AdministrationDashboardComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'dam',
            component: AdministrationDamComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'members',
            component: AdministrationMembersComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'member/:memberUid/profile',
            component: AdministrationMemberProfileComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'settings',
            component: SiteSettingsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard],
            children: [
              {
                path: 'possettings',
                component: PosSettingComponent,
                canActivate: [AuthorizationGuard],
                canLoad: [AuthorizationCanGuard],
              },
              {
                path: 'logisticsettings',
                component: LogisticSettingComponent,
                canActivate: [AuthorizationGuard],
                canLoad: [AuthorizationCanGuard],
              },
              {
                path: 'transactionsettings',
                component: TransactionSettingComponent,
                canActivate: [AuthorizationGuard],
                canLoad: [AuthorizationCanGuard],
              },
            ]
          },
          {
            path: 'terminals',
            component: TerminalsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'terminal/:terminalUid',
            component: TerminalComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
        ]
      },

      /* Employee routes */
      {
        path: 'employee',
        children: [
          {
            path: ':employeeUid/dashboard',
            component: SiteEmployeeDashboardComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: ':employeeUid/shifts',
            component: EmployeeShiftsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'shift',
            component: SiteEmployeeShiftComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'shift/:employeeShiftUid',
            component: SiteEmployeeShiftComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: ':employeeUid/profile',
            component: SiteEmployeeProfileComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
        ]
      },



      /* Backoffice routes */
      {
        path: 'backoffice',
        children: [
          {
            path: 'dashboard',
            component: BackOfficeDashboardComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'positions',
            component: BackOfficePositionsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'position',
            component: BackOfficePositionComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'position/:positionUid',
            component: BackOfficePositionComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'employees',
            component: BackOfficeEmployeesComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'employee/shift',
            component: BackOfficeShiftComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'employee/shift/:employeeShiftUid',
            component: BackOfficeShiftComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'employee/profile',
            component: BackOfficeEmployeeProfileComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'employee/:employeeUid/profile',
            component: BackOfficeEmployeeProfileComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'employee/:employeeUid/positions',
            component: BackOfficeEmployeePositionsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'employee/:employeeUid/permissions',
            component: BackOfficeEmployeePermissionsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'employee/:employeeUid/shifts',
            component: BackOfficeEmployeeShiftsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'employee/:employeeUid/payment',
            component: BackOfficeEmployeePaymentComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'shifts',
            component: BackOfficeShiftsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'shift',
            component: BackOfficeShiftComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'shift/:employeeShiftUid',
            component: BackOfficeShiftComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'payperiods',
            component: BackOfficePayPeriodsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'payperiod/:payPeriodUid',
            component: BackOfficePayPeriodComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'payperiod',
            component: BackOfficePayPeriodComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'payperiod/:payPeriodUid/shifts',
            component: BackOfficePayPeriodShiftsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          }, {
            path: 'payperiod/:payPeriodUid/report',
            component: BackOfficePayPeriodReportComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'payperiods',
            component: BackOfficePayPeriodsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'register',
            component: RegisterComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'register/:transactionUid',
            component: RegisterComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'transactions',
            component: BackOfficeTransactionsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'transaction/:transactionUid',
            component: RegisterComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'drawers',
            component: BackOfficeDrawersComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'drawer/:drawerUid',
            component: BackOfficeDrawerComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'accounts',
            component: BackOfficeAccountsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard]
          },
          {
            path: 'reports',
            component: BackOfficeReportsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard],
            children: [
              {
                path: 'dailysales',
                component: BackOfficeReportsDailySalesChartComponent
              }
            ]
          },
          {
            path: 'departments',
            component: BackOfficeDepartmentsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard],
          },
          {
            path: 'department',
            component: BackOfficeDepartmentComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard],
          },
          {
            path: 'department/:departmentUid',
            component: BackOfficeDepartmentComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard],
          },
          {
            path: 'department/:departmentUid/categories',
            component: BackOfficeCategoriesComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard],
          },
          {
            path: 'department/:departmentUid/category/:categoryUid',
            component: BackOfficeProductCategoryComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard],
          },
          {
            path: 'department/:departmentUid/category',
            component: BackOfficeProductCategoryComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard],
          },
          {
            path: 'products',
            component: BackOfficeProductsComponent,
            canActivate: [AuthorizationGuard],
            canLoad: [AuthorizationCanGuard],
          },
          {
            path: 'product',
            loadChildren: () => import('./product-configuration-routing.module').then((m) => m.ProductConfigurationRoutingModule)
          }
        ]
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { paramsInheritanceStrategy: 'always' })
  ],
  exports: [
    RouterModule
  ],
  providers: [
  ]
})
export class PosSiteRoutingModule {
  static components = [
    SiteShellComponent,
    // SiteShellAlertsComponent,

    SitePublicHomeComponent,
    SitePublicDartsComponent,
    SitePublicMenuComponent,

    UnauthorizedComponent,

    TopNavigationComponent,

    AdministrationDamComponent,
    AdministrationDashboardComponent,
    AdministrationMembersComponent,
    AdministrationMemberProfileComponent,
    TerminalComponent,
    TerminalsComponent,
    SiteSettingsComponent,

    BackOfficeDashboardComponent,
    BackOfficePositionsComponent,
    BackOfficePositionComponent,
    BackOfficeEmployeesComponent,
    BackOfficeEmployeeProfileComponent,
    BackOfficeEmployeePositionsComponent,
    BackOfficeEmployeePermissionsComponent,
    BackOfficeEmployeePaymentComponent,
    BackOfficeEmployeeShiftsComponent,
    BackOfficeShiftsComponent,
    BackOfficeShiftComponent,
    BackOfficePayPeriodsComponent,
    BackOfficePayPeriodComponent,
    BackOfficePayPeriodShiftsComponent,
    BackOfficePayPeriodReportComponent,
    BackOfficeTransactionsComponent,
    BackOfficeDrawerComponent,
    BackOfficeDrawersComponent,
    BackOfficeAccountsComponent,
    BackOfficeReportsComponent,
    BackOfficeDepartmentsComponent,
    BackOfficeDepartmentComponent,
    BackOfficeCategoriesComponent,
    BackOfficeProductCategoryComponent,
    BackOfficeProductsComponent,

    SitePublicProfileComponent,
    SiteEmployeeDashboardComponent,
    EmployeeShiftsComponent,
    SiteEmployeeShiftComponent,
    SiteEmployeeProfileComponent,

    BackOfficeReportsDailySalesChartComponent
  ];
}
