import { Injectable } from "@angular/core";
import { HttpService } from "core";
import { Observable } from 'rxjs';
import { OltpServiceSettings } from '../oltp-service-settings';
import { PosModelFactory } from "../pos-model-factory";
import { map } from "rxjs/operators";
import { ReportRequestResponse } from '../models/report-request-response';

@Injectable()
export class ReportService {

  public static readonly ReportFullView = <ReportViewOptions>{ };

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  payPeriod(payPeriodUid: string, viewOptions: ReportViewOptions = null): Observable<ReportRequestResponse> {

    let view = ReportService.buildView(viewOptions || ReportService.ReportFullView);

    var request = {
      query: `query payPeriod { payPeriod(payPeriodUid: "${payPeriodUid}") ${view} }`
    }

    return this.httpService.graph<ReportRequestResponse>(this.oltpServiceSettings.apiUrl, 'api/report', request, 'payPeriod').pipe(
      map(x => PosModelFactory.assignReportRequestResponse(x))
    );
  }

  public static buildView(viewOptions: ReportViewOptions) {

    let view = `uid url`;

    return '{ ' + view + ' }';
  }
}

export interface ReportViewOptions {

}
