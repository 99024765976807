import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthHeaderProvider } from 'core';
import { SpinnerModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { PositionForm } from 'pos-core';
import { Position } from 'pos-core';
import { PositionService } from 'pos-core';

@Component({
  selector: 'app-back-office-position',
  templateUrl: './back-office-position.component.html',
  styleUrls: ['./back-office-position.component.scss']
})
export class BackOfficePositionComponent extends PositionForm implements OnInit {

  public title: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    navigationProvider: NavigationProvider,
    spinnerModalProvider: SpinnerModalProvider,
    positionService: PositionService,
    authHeaderProvider: AuthHeaderProvider
  ) {
    super(navigationProvider, spinnerModalProvider, positionService, authHeaderProvider);

    this.navigationProvider.setWaypoint('Position');
    this.title = 'Position';
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      let positionUid = params['positionUid'];

      if (positionUid) {
        let spinnerModalRef = this.spinnerModalProvider.open();
        spinnerModalRef.afterOpened().subscribe(() => {
          this.positionService.getByUid(positionUid, this.authHeaderProvider).subscribe(position => {
            if (position) {
              this.loadForm(position);
              spinnerModalRef.close();
            }
          });
        });
      } else {
        this.loadForm(new Position());
      }
    });
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }
}
