<div style="display: flex; height: 100%;">

  <div *ngIf="transactions && transactions.length > 1" style="height: 100%; margin-right: 10px; padding-right: 3px; overflow-y: auto;">

    <div *ngFor="let transaction of transactions">
      <app-pos-button style="border-width: 2px;" [styles]="{ backgroundColor: editableTransaction && transaction.uid.toUpperCase() == editableTransaction.uid.toUpperCase() ? '#b9a246' : '#4d4d00' }" (click)="selectTransaction(transaction)">
        <div>Closed: {{ transaction.closeDateTimeUtc | date: 'h:mm a' }}</div>
        <div>Total: {{ transaction.total | currency:'USD':'symbol':'1.2-2' }}</div>
      </app-pos-button>
    </div>

  </div>

  <div style="flex: 1; height: 100%; display: flex; flex-direction: column;">

    <div style="flex: 1; display: flex;">
      <div style="width: 60%;">
        <transaction-transaction-editor style="width: 100%; height: 100%;" [editableTransaction]="editableTransaction"></transaction-transaction-editor>
      </div>
      <div *ngIf="editableTransaction" style="flex: 1;">
        <div style="display: flex;">
          <span style="flex: 1; text-align: right; margin-right: 2px;">Status&nbsp;:</span>
          <span style="flex: 1; text-align: left; margin-left: 2px;">{{ editableTransaction.transactionStatus }}</span>
        </div>
        <div style="display: flex;">
          <span style="flex: 1; text-align: right; margin-right: 2px;">Open Date/Time&nbsp;:</span>
          <span style="flex: 1; text-align: left; margin-left: 2px;">{{ editableTransaction.openDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}</span>
        </div>
        <div style="display: flex;">
          <span style="flex: 1; text-align: right; margin-right: 2px;">Close Date/Time&nbsp;:</span>
          <span style="flex: 1; text-align: left; margin-left: 2px;">{{ editableTransaction.closeDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}</span>
        </div>
      </div>
    </div>

    <div style="display: flex;" *ngIf="editableTransaction">

      <app-pos-button *ngIf="canPrint" (click)="print()">Print</app-pos-button>
      <app-pos-button *ngIf="canPrint" (click)="department()">Kitchen</app-pos-button>
      <app-pos-button *ngIf="(editableTransaction.transactionStatus | async)?.toUpperCase() == 'Closed'" (click)="reopen()">Reopen</app-pos-button>
      <span style="flex: 1 1 auto;"></span>
      <app-pos-button (click)="close()">Close</app-pos-button>

    </div>

  </div>

</div>