import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, of, combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { flatMap } from 'rxjs/operators';
import { UUID } from 'angular2-uuid';
import { PaginationInput } from 'core';
import { TimeSpan, getDuration } from 'core';
import { DateTimeModalProvider, DateTimeModalData } from 'core';
import { SelectListModalProvider, SelectListModalData } from 'core';
import { SpinnerModalProvider } from 'core';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { EmployeeStatusKeys } from 'pos-core';
import { Employee } from 'pos-core';
import { EmployeeShiftService } from 'pos-core';
import { EmployeeService } from 'pos-core';
import { PositionService } from 'pos-core';
import { Position } from 'pos-core';

@Component({
  selector: 'app-back-office-shift',
  templateUrl: './back-office-shift.component.html',
  styleUrls: ['./back-office-shift.component.scss']
})
export class BackOfficeShiftComponent implements OnInit {

  public selectedEmployee = new BehaviorSubject<Employee>(null);
  public selectedPosition = new BehaviorSubject<Position>(null);
  public clockInDateTime = new BehaviorSubject<Date>(null);
  public clockOutDateTime = new BehaviorSubject<Date>(null);
  public employeePositions: Position[];
  public allEmployees: Employee[];
  public allPositions: Position[];
  public duration = new BehaviorSubject<TimeSpan>(null);

  public canSelectPosition = new BehaviorSubject<boolean>(false);
  public canClockIn = new BehaviorSubject<boolean>(false);
  public canClockOut = new BehaviorSubject<boolean>(false);
  public canSave = new BehaviorSubject<boolean>(false);

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private dateTimeModalProvider: DateTimeModalProvider,
    private selectListModalProvider: SelectListModalProvider,
    private waitModalProvider: WaitModalProvider,
    private employeeShiftService: EmployeeShiftService,
    private employeeService: EmployeeService,
    private positionService: PositionService
  ) {
  }

  ngOnInit() {

    this.navigationProvider.setWaypoint('Employee Profile');

    let spinnerModalRef = this.spinnerModalProvider.open();
    spinnerModalRef.afterOpened().subscribe(() => {
      combineLatest(
        this.employeeService.search(null, [EmployeeStatusKeys.Active], <PaginationInput>{ pageIndex: 0, pageSize: 10000 }),
        this.positionService.search(null, null)
      ).subscribe(([employeesPage, positionsPage]) => {
        this.allEmployees = employeesPage.edges.map(x => x.node);
        this.allPositions = positionsPage.edges.map(x => x.node);

        this.activatedRoute.params.subscribe(params => {
          let employeeShiftUid = params['employeeShiftUid'];

          if (employeeShiftUid) {
            this.employeeShiftService.getByUid(employeeShiftUid).subscribe(employeeShift => {
              if (employeeShift) {
                this.employeeService.getByUid(employeeShift.employeeUid).subscribe(employee => {
                  this.assignEmployee(employee);

                  this.selectedPosition.next(this.allPositions.find(y => y.uid == employeeShift.positionUid));
                  this.clockInDateTime.next(employeeShift.clockInDateTimeUtc);

                  if (employeeShift.clockOutDateTimeUtc) {
                    this.clockOutDateTime.next(employeeShift.clockOutDateTimeUtc);
                  }

                  this.updateEnablement();
                  this.duration.next(getDuration(this.clockInDateTime.value, this.clockOutDateTime.value));
                  spinnerModalRef.close();
                })
              }
            });
          } else {
            this.activatedRoute.queryParams.pipe(
              flatMap(params => {
                return params['employeeUid'] ? this.employeeService.getByUid(params['employeeUid']) : of<Employee>(null);
              })
            ).subscribe(employee => {
              if (employee) {
                this.assignEmployee(employee);
              }

              this.clockInDateTime.next(null);
              this.clockOutDateTime.next(null);

              this.updateEnablement();
              spinnerModalRef.close();
            });
          }
        });
      });

      this.updateEnablement();
    });
  }

  selectEmployee() {

    this.selectListModalProvider.open(<SelectListModalData<Employee>>{
      title: 'Select Employee',
      options: this.allEmployees,
      displayFunc: item => item.getFullName()
    }).afterClosed().subscribe(employee => {
      if (employee) {
        this.assignEmployee(<Employee>employee);
        this.updateEnablement();
      }
    });
  }

  selectPosition() {

    this.selectListModalProvider.open(<SelectListModalData<Position>>{
      title: 'Select Position',
      options: this.employeePositions,
      displayFunc: item => item.name
    }).afterClosed().subscribe(position => {
      if (position) {
        this.selectedPosition.next(<Position>position);

        var defaultDateTime = this.clockInDateTime.value ? this.clockInDateTime.value : new Date();
        var roundedDate = this.round(defaultDateTime, 15 * 60 * 1000);
        this.clockInDateTime.next(roundedDate);

        this.updateEnablement();
      }
    });
  }

  selectClockInDateTime() {

    this.dateTimeModalProvider.open(<DateTimeModalData>{
      initialDate: this.clockInDateTime.value,
      maximumDateTime: this.clockOutDateTime.value,
      selectionMode: 'preferred'
    }).afterClosed().subscribe(value => {
      if (value) {
        this.clockInDateTime.next(value);

        this.updateEnablement();
        this.duration.next(getDuration(this.clockInDateTime.value, this.clockOutDateTime.value));
      }
    });
  }

  selectClockOutDateTime() {

    this.dateTimeModalProvider.open(<DateTimeModalData>{
      initialDate: this.clockOutDateTime.value,
      minimumDateTime: this.clockInDateTime.value,
      selectionMode: 'preferred'
    }).afterClosed().subscribe(value => {
      if (value) {
        this.clockOutDateTime.next(value);

        this.updateEnablement();
        this.duration.next(getDuration(this.clockInDateTime.value, this.clockOutDateTime.value));
      }
    });
  }

  save() {

    let waitModalRef = this.waitModalProvider.open('Saving...');
    waitModalRef.afterOpened().subscribe(() => {
      let clockInDateTime = this.clockInDateTime.value;
      var clockInDateTimeUtc = new Date(clockInDateTime.getFullYear(), clockInDateTime.getMonth(), clockInDateTime.getDate(), clockInDateTime.getHours(), clockInDateTime.getMinutes());

      let clockOutDateTime = this.clockOutDateTime.value;
      var clockOutDateTimeUtc = clockOutDateTime ? new Date(clockOutDateTime.getFullYear(), clockOutDateTime.getMonth(), clockOutDateTime.getDate(), clockOutDateTime.getHours(), clockOutDateTime.getMinutes()) : null;

      this.activatedRoute.params.subscribe(params => {
        let employeeShiftUid = params['employeeShiftUid'];

        if (employeeShiftUid) {
          this.employeeShiftService.getByUid(employeeShiftUid).subscribe(employeeShift => {
            if (employeeShift) {

              this.employeeShiftService.editEmployeeShift(employeeShiftUid, this.selectedEmployee.value.uid, this.selectedPosition.value.uid, clockInDateTimeUtc, clockOutDateTimeUtc).subscribe(employeeShift => {
                waitModalRef.close();
                this.navigationProvider.backOneWaypoint();
              });
            }
          });
        } else {
          const employeeShiftUid = UUID.UUID();

          this.employeeShiftService.clockIn(employeeShiftUid, this.selectedEmployee.value.uid, this.selectedPosition.value.uid, clockInDateTimeUtc).subscribe(employeeShift => {
            if (clockOutDateTimeUtc) {
              this.employeeShiftService.clockOut(employeeShiftUid, clockOutDateTimeUtc).subscribe(employeeShift => {
                waitModalRef.close();
                this.navigationProvider.backOneWaypoint();
              });
            } else {
              waitModalRef.close();
              this.navigationProvider.backOneWaypoint();
            }
          })
        }
      });
    });
  }

  cancel() {

    this.navigationProvider.backOneWaypoint();
  }

  private assignEmployee(employee: Employee) {

    this.selectedEmployee.next(employee);

    this.employeePositions = employee.positions.map(x => this.allPositions.find(y => y.uid == x.uid));
    this.selectedPosition.next(this.employeePositions.length == 1 ? this.employeePositions[0] : null);
  }

  private round(date: Date, durationMs: number): Date {

    let dateMs = date.getTime();

    return new Date(Math.ceil((+dateMs) / (+durationMs)) * (+durationMs));
  }

  private updateEnablement() {

    var selectedEmployee = this.selectedEmployee.value;
    var selectedPosition = this.selectedPosition.value;
    var clockInDateTime = this.clockInDateTime.value;
    var clockOutDateTime = this.clockOutDateTime.value;

    this.canSelectPosition.next(selectedEmployee != null && this.employeePositions != null && this.employeePositions.length > 1);
    this.canClockIn.next(selectedEmployee != null && selectedPosition != null);
    this.canClockOut.next(selectedEmployee && selectedPosition && clockInDateTime != null);
    this.canSave.next(selectedEmployee && selectedPosition && clockInDateTime != null && (clockOutDateTime == null ? true : clockInDateTime < clockOutDateTime));
  }
}
