import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { finalize, map, switchMap } from "rxjs/operators";
import { SystemMessage } from '../models/system-message';
import { SystemMessageInput } from '../models/system-message-input';
import { HttpService } from './http.service';
import { EndpointState, GraphService } from './graph.service';
import { CoreLibraryConfig } from '../core-library-config';

@Injectable()
export class SystemMessageService {

  public static readonly SystemMessageFullView = <SystemMessageViewOptions>{};

  constructor(
    private httpService: HttpService,
    private graphService: GraphService,
    private coreLibraryConfig: CoreLibraryConfig,
  ) {
  }

  getStream$(viewOptions: SystemMessageViewOptions = null, identityToken: string = null): Observable<SystemMessage> {

    let view = SystemMessageService.buildView(viewOptions || SystemMessageService.SystemMessageFullView);

    var endpoint = this.graphService.createEndpoint(this.coreLibraryConfig.socketUrl + 'api/systemmessage', 'systemmessage');

    return endpoint.state.pipe(
      switchMap(state => {
        if (state == EndpointState.Ready) {
          return endpoint.addSubscription<SystemMessage>(`subscription message { message ${view} }`, 'message', identityToken);
        } else {
          return new Observable<SystemMessage>(null);
        }
      }),
      finalize(() => {
        endpoint.close();
      })
    );
  }

  publish(systemMessageInput: SystemMessageInput, viewOptions: SystemMessageViewOptions = null): Observable<SystemMessage> {

    let view = SystemMessageService.buildView(viewOptions || SystemMessageService.SystemMessageFullView);

    var request = {
      query: `mutation publish($systemMessage:SystemMessageInput!) { publish(systemMessage: $systemMessage) ${view} }`,
      variables: { systemMessage: systemMessageInput }
    };

    return this.httpService.graph<SystemMessage>(this.coreLibraryConfig.apiUrl, 'api/systemmessage', request, 'publish').pipe(
      map(x => x)
    );
  }

  public static buildView(viewOptions: SystemMessageViewOptions) {

    let view = `uid type sourceNodeIdentifier targetNodeIdentifier targetResourceUid payload`;

    return '{ ' + view + ' }';
  }
}

export interface SystemMessageViewOptions {

}
