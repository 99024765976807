import { Injectable, Optional } from '@angular/core';
import { Location } from '@angular/common'
import { Title } from '@angular/platform-browser';
import { Router, NavigationExtras } from '@angular/router';
import { isCaseInsensitiveEqual } from '../functions';
import { ErrorHandlingProvider } from './error-handling.provider';
import { LoggingProvider } from '../providers';

@Injectable()
export class NavigationProvider {

  private history = <string[]>[];

  constructor(
    private router: Router,
    private location: Location,
    private title: Title,
    @Optional() private loggingService: LoggingProvider,
    @Optional() private errorHandlingProvider: ErrorHandlingProvider
  ) {

  }

  clear() {

    if (this.loggingService) {
      this.loggingService.log('NavigationProvider:clear');
    }

    this.history = [];
  }

  getTitle(): string {

    return this.title.getTitle();
  }

  updateTitle(title: string) {

    this.title.setTitle(title);
  }

  reload() {

    // this.clear();
    // this.router.navigateByUrl('');

    window.location.reload();
  }

  setWaypoint(title: string = null) {

    let url = this.router.url;

    if (url.startsWith('index.html')) {
      console.log('removing index.html from waypoint');

      url = url.substring('index.html'.length);
    }

    if (this.history.length == 0 || !isCaseInsensitiveEqual(this.history[this.history.length - 1], url)) {
      if (this.loggingService) {
        this.loggingService.log(`NavigationProvider:setWaypoint:${url}`);
      }

      this.history.push(url);
    }

    this.title.setTitle(title ? title : null);
  }

  updateLastWaypoint() {

    const path = this.location.path(false);

    if (this.history.length > 0) {
      this.history[this.history.length - 1] = path;
    }
  }

  navigate(segments: any[], extras?: NavigationExtras): Promise<boolean> {

    return this.router.navigate(segments, extras);
  }

  backOneWaypoint() {

    if (this.history.length > 1) {
      this.history.pop();

      if (this.loggingService) {
        this.loggingService.log(`NavigationProvider:backOneWaypoint:${this.history[this.history.length - 1]}`);
      }

      this.router.navigateByUrl(this.history[this.history.length - 1])
        .then(result => {
          if (!result && this.errorHandlingProvider) {
            this.errorHandlingProvider.handleError('Unable to navigate back one waypoint.');
          }
        })
        .catch(err => this.errorHandlingProvider.handleError(err));
    } else {
      this.clear();
      this.router.navigateByUrl('');
    }
  }
}
