<div class="option" (click)="context.select(addOn)" [ngClass]="{ 'selected': context.selection == addOn }">

  <div *ngIf="addOnProduct && addOn.item?.configuration">
    <ng-template product-configuration-detail [product]="addOnProduct" [itemConfiguration]="addOn.item.configuration.value" [readOnly]="true" (configurationChanged)="handleConfigurationChanged"></ng-template>
    <!-- <app-product-configuration-detail [transactionItem]="addOn.item" [readOnly]="true" (configurationChanged)="handleConfigurationChanged"></app-product-configuration-detail> -->
  </div>
</div>

<!-- Context Menu -->
<div *ngIf="!context.readOnly && context.selection == addOn" [@slideIn] [@slideOut] style="display: flex; overflow: hidden; background-color: white;">
  <div class="left">
    <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
  </div>
  <div class="center">
    <button mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="edit(addOn)" click-stop-propagation>Edit</button>
    <button mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="remove(addOn)" click-stop-propagation>Remove</button>
  </div>
  <div class="right">
    <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
  </div>
</div>
