<div class="jumbotron clearfix" style="padding-left: 30px; padding-right: 30px;">
    <div class="col-md-5">
        <img src="{{ this.runtimeProvider.assetBaseUrl + 'images/branding/Uptown Full - White.png' }}" style="width: 100%;" />
    </div>
    <div class="col-md-7">
        <p class="lead text-center">Uptown Bar Operations Dashboard</p>
        <p>Use this area for managing internal operations.</p>
    </div>
</div>

<div class="clearfix">
    <div class="col-md-7">
        <p>News updates</p>
        <p>No news</p>
        <p>Messages</p>
        <p>No messages</p>
    </div>
    <div class="col-md-5">
    </div>
</div>
