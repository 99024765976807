import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SettingBaseComponent } from 'core';
import { LogisticSettings } from '../../models/logistic-settings';

export interface LogisticSettingFormGroupType {
  dineIn: FormControl<boolean>;
  carryOut: FormControl<boolean>;
  curbsidePickup: FormControl<boolean>;
  delivery: FormControl<boolean>;
  deliveryCharge: FormControl<number>;
};

@Component({
  selector: 'transaction-logistic-setting',
  templateUrl: './logistic-setting.component.html',
  styleUrls: ['./logistic-setting.component.scss']
})
export class LogisticSettingComponent extends SettingBaseComponent<LogisticSettings, LogisticSettingFormGroupType> {

  constructor(
  ) {
    super();

    this.form = new FormGroup<LogisticSettingFormGroupType>({
      dineIn: new FormControl<boolean>(false),
      carryOut: new FormControl<boolean>(false),
      curbsidePickup: new FormControl<boolean>(false),
      delivery: new FormControl<boolean>(false),
      deliveryCharge: new FormControl<number>(null)
    });
  }

  public override patchForm(value: LogisticSettings): void {

    this.form.patchValue(value);
  }
}
