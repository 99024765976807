import { Component, Input, EventEmitter, Output } from '@angular/core';
import { slideInAnimation, slideOutAnimation } from 'core';
import { EditableTransaction, EditableTransactionAdjustment } from '../../../models/editable-transaction/editable-transaction';

@Component({
  selector: 'transaction-transaction-editor-adjustment',
  templateUrl: './transaction-editor-adjustment.component.html',
  styleUrls: ['./transaction-editor-adjustment.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class TransactionEditorAdjustmentComponent  {

  @Input() editableTransaction: EditableTransaction
  @Input() adjustment: EditableTransactionAdjustment;
  @Output() select = new EventEmitter<EditableTransactionAdjustment>();
  @Output() void = new EventEmitter<EditableTransactionAdjustment>();
  @Output() cancel = new EventEmitter<EditableTransactionAdjustment>();
}
