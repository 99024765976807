import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LogisticTypeModel } from "downtown-transaction";
import { LogisticTypeModalComponent } from "./logistic-type-modal.component";

@Injectable()
export class LogisticTypeModalProvider  {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: LogisticTypeModalData): MatDialogRef<LogisticTypeModalComponent, LogisticTypeModel> {

    return this.dialog.open<LogisticTypeModalComponent, LogisticTypeModalData, LogisticTypeModel>(LogisticTypeModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface LogisticTypeModalData {
  title: string,
  options: Array<LogisticTypeModel>,
  displayFunc: (item: any) => { };
}

