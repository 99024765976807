import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WaitModalComponent } from './wait-modal.component';
import { Injectable } from '@angular/core';

@Injectable()
export class WaitModalProvider {

  constructor(
    private dialog: MatDialog
  ) {
  }

  public open(message: string): MatDialogRef<WaitModalComponent> {

    return this.dialog.open(WaitModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: {
        message: message
      }
    });
  }
}

export interface WaitModalData {
  message: string
}
