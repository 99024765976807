import { TransactionLine } from "./transaction-line";
import { BehaviorSubject, combineLatest } from 'rxjs';
import { TransactionEvent } from "./transaction-event";
import { TransactionEventType } from "./transaction-event-type";
import Decimal from "decimal.js";

export class TransactionAdjustmentLine extends TransactionLine {


  static commitConditions = <((x: TransactionAdjustmentLine) => boolean)[]>[
    x => !x.previousValues || x.isChangedFrom(x.previousValues, x.getValues())
  ];

  description: string;
  amountText = new BehaviorSubject<string>(null);
  total = new BehaviorSubject<number>(null);
  isVoid = new BehaviorSubject<boolean>(false);
  isCompensated = new BehaviorSubject<boolean>(false);

  get typeName() { return 'TransactionAdjustmentLine'; }

  constructor(

  ) {
    super();

    combineLatest([this.amountText]).subscribe(x => {
      this.total.next(-(this.getAmount()));
      this.isValid.next(this.getAmount() > 0);
    });
    combineLatest([this.isEditing$]).subscribe(x => {
      let isEditing = this.isEditing;
      if (isEditing) {
        this.previousValues = this.getValues();
      }

      this.canCancel.next(isEditing);
      this.canVoid.next(isEditing && this.uid != null);
      this.canAdjust.next(false);
    });
  }

  private getValues() {

    return {
      ['amountText']: this.amountText.value
    };
  }

  keyPressed(value: string) {

    let keyValue = value.toLowerCase();

    if (keyValue == "00") {
      this.keyPressed("0");
      this.keyPressed("0");
    } else if (keyValue == ".") {
      let eachAmount = this.amountText.value;
      let decimalIndex = eachAmount.indexOf('.');

      let wholeDigits = eachAmount.substr(0, decimalIndex + 2).replace('.', '');
      let decimalDigits = eachAmount.substr(decimalIndex + 2);

      eachAmount = parseInt(wholeDigits + decimalDigits).toString() + ".";
      this.amountText.next(eachAmount);
    } else if (keyValue == "back") {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        // Shift all digits right
        amount = Math.floor(amount * 10) / 100;

        if (amount == 0 && !this.isValid) {
          this.cancel();
        } else {
          this.amountText.next(amount.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        }
      }
    } else if (keyValue == "enter") {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        this.commit();
      }
    } else if (keyValue == "clear") {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        this.clearAmount();
      }
    } else {
      let digit = parseInt(value);
      if (!Number.isNaN(digit)) {
        let textEachAmount = this.amountText.value.concat(keyValue);

        let validAmount = new Decimal(textEachAmount);
        if (!validAmount.isNaN()) {
          let decimalIndex = textEachAmount.indexOf('.');
          if (decimalIndex > 0 && textEachAmount.length - decimalIndex > 3) {
            // Need to shift the decimal point
            textEachAmount = (new Decimal(textEachAmount.slice(0, decimalIndex + 2).replace('.', '')).toString()) + '.' + (textEachAmount.slice(decimalIndex + 2));
          }

          this.amountText.next(textEachAmount);
        }
      }
    }
  }

  cancel() {

    if (this.previousValues) {
      // Revert to previous values
      this.amountText.next(this.previousValues['amountText']);
    }

    this.previousValues = null;
    this.isEditing$.next(false);

    this.onEvent.next(new TransactionEvent(this, TransactionEventType.Cancelled, null));
  }

  adjust() {

  }

  void() {

    if (this.previousValues) {
      // Revert to previous values
      this.amountText.next(this.previousValues['amountText']);
    }

    this.previousValues = null;
    this.isEditing$.next(false);

    this.onEvent.next(new TransactionEvent(this, TransactionEventType.Voided, null));
  }

  commit() {

    // TODO: Ensure new value is not greater than the transaction being adjusted
    // var total = this.total.value;
    // var adjustedTotal = this.saleLine.total.value - new List<TransactionSaleAdjustmentLine>(this.saleLine.adjustments).Sum(x => x.total.value);
    // if (adjustedTotal < 0) {
    //     this.eachAmountText.next(Math.abs(total - adjustedTotal).toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    // }

    if (TransactionAdjustmentLine.commitConditions.every(x => x(this))) {
      this.isEditing$.next(false);
      this.previousValues = null;

      this.onEvent.next(new TransactionEvent(this, TransactionEventType.Committed, null));
    } else {
      // Uncommittable, cancel instead of commit
      this.cancel();
    }
  }

  clearAmount(): TransactionAdjustmentLine {

    this.amountText.next((0).toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));

    return this;
  }

  public getAmount(): number {

    return new Decimal(this.amountText.value).toNumber();
  }
}
