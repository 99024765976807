import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BlobImage } from "../../models/blob-image";
import { SelectImageModalData } from "./select-image-modal.provider";

@Component({
  selector: 'app-select-image-modal',
  templateUrl: './select-image-modal.component.html',
  styleUrls: ['./select-image-modal.component.scss']
})
export class SelectImageModalComponent {

  constructor(
    private dialogRef: MatDialogRef<SelectImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectImageModalData
  ) {
  }

  selectOption(image: BlobImage) {

    this.dialogRef.close(image);
  }
}
