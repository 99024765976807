import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { CheckListModalComponent } from './check-list-modal.component';

@Injectable()
export class CheckListModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open<T>(data: CheckListModalData<T>): MatDialogRef<CheckListModalComponent<T>, boolean> {

    return this.dialog.open<CheckListModalComponent<T>, CheckListModalData<T>, boolean>(CheckListModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface CheckListModalData<T> {

  title: string,
  options: { value: T, checked: boolean, disabled: boolean }[],
  displayFunc: (item: T) => {};
}
