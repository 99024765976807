import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { ExpansionContainerComponent, getDescendentRouteSnapshot } from 'core';
import { ExpansionPanelComponent } from 'core';
import { SpinnerModalProvider } from 'core';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { ConfigurationComponentBase } from './configuration-component-base';
import { ProductContext } from './product-context';
import { ProductStatusKeys, ProductTypeKeys } from '../../keys';
import { ProductAndPortionModalProvider } from '../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { buildProductConfigurationForm, serializeProductForm } from '../../functions/configuration-form-functions';
import { TenantProvider } from 'core';
import { ProductProvider } from '../../providers';
import { ProductService } from '../../services';

@Component({
  selector: 'app-back-office-product-page',
  templateUrl: './back-office-product-page.component.html',
  styleUrls: ['./back-office-product-page.component.scss']
})
export class BackOfficeProductPageComponent extends ConfigurationComponentBase {

  @ViewChild('expansionContainer', { static: true }) expansionContainer: ExpansionContainerComponent;
  @ViewChild('portionsContainer', { static: true }) portionsContainer: ExpansionContainerComponent;
  @ViewChild('inclusionGroupsContainer', { static: true }) inclusionGroupsContainer: ExpansionContainerComponent;
  @ViewChild('addOnsContainer', { static: true }) addOnsContainer: ExpansionContainerComponent;

  @ViewChild('generalPanel', { static: true }) generalPanel: ExpansionPanelComponent;
  @ViewChild('portionsPanel', { static: true }) portionsPanel: ExpansionPanelComponent;
  @ViewChild('inclusionGroupsPanel', { static: true }) inclusionGroupsPanel: ExpansionPanelComponent;
  @ViewChild('addOnsPanel', { static: true }) addOnsPanel: ExpansionPanelComponent;

  public context: ProductContext;
  public portionsFormArray: UntypedFormArray;
  public inclusionGroupsFormArray: UntypedFormArray;
  public addOnsFormArray: UntypedFormArray;
  public version: number;
  public ProductStatusKeys = ProductStatusKeys;

  public canSave = new BehaviorSubject<boolean>(false);
  public canPrint = new BehaviorSubject<boolean>(false);

  private destroyed$ = new Subject();

  constructor(
    private router: Router,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private productService: ProductService,
    activatedRoute: ActivatedRoute,
    tenantProvider: TenantProvider,
    productProvider: ProductProvider,
    productAndPortionModalProvider: ProductAndPortionModalProvider,
  ) {
    super(activatedRoute, tenantProvider, productProvider, productAndPortionModalProvider);

    this.navigationProvider.setWaypoint('Product Configuration');
    this.context = new ProductContext();
  }

  ngOnInit() {

    this.navigationProvider.updateTitle("Product");

    this.activatedRoute.params.subscribe((params: Params) => {

      var form = new UntypedFormGroup({
        uid: new UntypedFormControl(null),
        ownerUid: new UntypedFormControl(this.tenantProvider.currentUid, [Validators.required]),
        sku: new UntypedFormControl(null),
        name: new UntypedFormControl(null, [Validators.required]),
        abbreviation: new UntypedFormControl(null),
        description: new UntypedFormControl(null),
        imageUid: new UntypedFormControl(null),
        displayOrder: new UntypedFormControl(0, [Validators.required]),
        departmentUid: new UntypedFormControl(null, [Validators.required]),
        categoryUid: new UntypedFormControl(null),
        basePrice: new UntypedFormControl(0, [Validators.required]),
        productTypeUid: new UntypedFormControl(ProductTypeKeys.Physical, [Validators.required]),
        configuration: new UntypedFormGroup({}),
        productStatusUid: new UntypedFormControl(null),
      });

      let spinnerModalRef = this.spinnerModalProvider.open();

      let productUid = params['productUid'];

      combineLatest([
        spinnerModalRef.afterOpened(),
        productUid ? this.productProvider.getOneCached$(productUid, null) : of(null),
      ]).subscribe(([_, product]) => {
        if (product) {
          this.version = product.version;

          // TODO: Get full form from static builder file like buildProductForm does
          form.controls['uid'].setValue(product.uid);
          form.controls['ownerUid'].setValue(product.ownerUid);
          form.controls['sku'].setValue(product.sku);
          form.controls['name'].setValue(product.name);
          form.controls['abbreviation'].setValue(product.abbreviation);
          form.controls['description'].setValue(product.description);
          form.controls['imageUid'].setValue(product.imageUid);
          form.controls['displayOrder'].setValue(product.displayOrder);
          form.controls['departmentUid'].setValue(product.departmentUid);
          form.controls['categoryUid'].setValue(product.categoryUid);
          form.controls['basePrice'].setValue(product.basePrice);
          form.controls['productTypeUid'].setValue(product.productTypeUid);
          form.controls['productStatusUid'].setValue(product.productStatusUid);

          var configurationFormGroup = <UntypedFormGroup>buildProductConfigurationForm(product);
          Object.keys(configurationFormGroup.controls).forEach(name => {
            (<UntypedFormGroup>form.get('configuration')).addControl(name, configurationFormGroup.get(name));
          });
        } else {
          var configurationFormGroup = <UntypedFormGroup>buildProductConfigurationForm(product);
          Object.keys(configurationFormGroup.controls).forEach(name => {
            (<UntypedFormGroup>form.get('configuration')).addControl(name, configurationFormGroup.get(name));
          });
        }

        this.context.productForm = form;
        this.portionsFormArray = <UntypedFormArray>this.context.configurationForm.get('portions');
        this.inclusionGroupsFormArray = <UntypedFormArray>this.context.configurationForm.get('inclusionGroups');
        this.addOnsFormArray = <UntypedFormArray>this.context.configurationForm.get('addOns');

        this.setNavTarget();

        this.router.events.pipe(
          takeUntil(this.destroyed$),
          filter(event => event instanceof NavigationEnd)
        ).subscribe(x => {
          setTimeout(() => {
            this.setNavTarget();
          });
        });

        this.updateEnablement();

        spinnerModalRef.close();
      });
    });
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  setNavTarget() {

    var route = getDescendentRouteSnapshot(this.activatedRoute);

    var portionUid = route.paramMap.get('portionUid');
    var inclusionUid = route.paramMap.get('inclusionUid');
    var inclusionGroupUid = route.paramMap.get('inclusionGroupUid');
    var extraUid = route.paramMap.get('extraUid');
    var addOnUid = route.paramMap.get('addOnUid');

    this.expansionContainer.setSelected(null);
    this.portionsContainer.setSelected(null);
    this.inclusionGroupsContainer.setSelected(null);
    this.addOnsContainer.setSelected(null);

    if (portionUid || route.url.some(x => x.path == 'portions')) {
      this.expansionContainer.setExpanded(this.portionsPanel);

      if (route.url.some(x => x.path == 'portions')) {
        this.expansionContainer.setSelected(this.portionsPanel);
      }

      if (portionUid) {
        var portionPanel = this.portionsContainer.panels.find(x => x.value == portionUid)
        if (portionPanel) {
          this.portionsContainer.setSelected(portionPanel);
        }
      }
    } else if (inclusionGroupUid || route.url.some(x => x.path == 'inclusiongroups')) {
      this.expansionContainer.setExpanded(this.inclusionGroupsPanel);

      if (route.url.some(x => x.path == 'inclusiongroups')) {
        this.expansionContainer.setSelected(this.inclusionGroupsPanel);
      }

      if (inclusionGroupUid) {
        var addOnPanel = this.inclusionGroupsContainer.panels.find(x => x.value == inclusionGroupUid)
        if (addOnPanel) {
          this.inclusionGroupsContainer.setSelected(addOnPanel);
        }
      }
    } else if (addOnUid || route.url.some(x => x.path == 'addons')) {
      this.expansionContainer.setExpanded(this.addOnsPanel);

      if (route.url.some(x => x.path == 'addons')) {
        this.expansionContainer.setSelected(this.addOnsPanel);
      }

      if (addOnUid) {
        var addOnPanel = this.addOnsContainer.panels.find(x => x.value == addOnUid)
        if (addOnPanel) {
          this.addOnsContainer.setSelected(addOnPanel);
        }
      }
    } else {
      this.expansionContainer.setExpanded(this.generalPanel);
      this.expansionContainer.setSelected(this.generalPanel);
    }
  }

  panelClick(target: string) {

    this.navigationProvider.navigate(this.getBaseProductNavigationSegments().concat(target));
  }

  portionClick(target: string) {

    this.navigationProvider.navigate(this.getBaseProductNavigationSegments().concat(['portion', target]));
  }

  inclusionClick(target: string) {

    this.navigationProvider.navigate(this.getBaseProductNavigationSegments().concat(['inclusion', target]));
  }

  inclusionGroupClick(target: string) {

    var route = getDescendentRouteSnapshot(this.activatedRoute);

    const routeSegments = this.getBaseProductNavigationSegments().concat(['inclusiongroup', target]);

    if (route.url.some(x => x.path == 'options')) {
      routeSegments.push('options');
    }

    this.navigationProvider.navigate(routeSegments);
  }

  extraClick(target: string) {

    this.navigationProvider.navigate(this.getBaseProductNavigationSegments().concat(['extra', target]));
  }

  addOnClick(target: string) {

    this.navigationProvider.navigate(this.getBaseProductNavigationSegments().concat(['addon', target]));
  }

  onActivate(event: ConfigurationComponentBase) {

    event.context = this.context;
  }

  save() {

    console.log('save');

    if (this.context.productForm.dirty) {
      let waitDialogRef = this.waitModalProvider.open('Saving...');

      waitDialogRef.afterOpened().subscribe(() => {

        var existingProductUid = this.context.productForm.get('uid').value;
        if (!existingProductUid) {
          this.context.productForm.get('uid').setValue(UUID.UUID());
        }

        var productInputModel = serializeProductForm(this.context.productForm);
        var method = existingProductUid ? this.productService.update(productInputModel) : this.productService.create(productInputModel);
        method.subscribe(() => {
          // This should be a merge from the server roundtrip
          if (!this.context.productForm.get('uid')) {
            this.context.productForm.get('uid').setValue(UUID.UUID());
          }

          waitDialogRef.close();
        });
      });
    }
  }

  cancel() {
    this.navigationProvider.backOneWaypoint();
  }

  private updateEnablement() {

    this.canSave.next(this.context.productForm.dirty);
    this.canPrint.next(!this.context.productForm.dirty && this.context.productForm.valid);
  }
}
