import { Component, OnInit } from '@angular/core';
import { NavigationProvider } from 'core';

@Component({
  selector: 'app-back-office-accounts',
  templateUrl: './back-office-accounts.component.html',
  styleUrls: ['./back-office-accounts.component.scss']
})
export class BackOfficeAccountsComponent implements OnInit {


  constructor(
    private navigationProvider: NavigationProvider,
  ) {
    this.navigationProvider.setWaypoint('Back Office Accounts');
  }

  ngOnInit() {
  }
}


