import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ProductConfigurationAddOn } from "downtown-product";
import { AddOnSelectionModel } from "../models";
import { ItemConfiguratorContext } from "../../../components";

@Component({
  selector: 'transaction-item-configurator-modal-add-ons-tab',
  templateUrl: './item-configurator-modal-add-ons-tab.component.html',
  styleUrls: ['./item-configurator-modal-add-ons-tab.component.scss']
})
export class ItemConfiguratorModalAddOnsTabComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public isNew: boolean;
  @Output() public configurationChanged = new EventEmitter();

  public addOnSelection: AddOnSelectionModel;
  public addOns: ProductConfigurationAddOn[];

  constructor(
  ) {
  }

  ngOnInit() {

    this.addOns = this.context.getProductPortion(this.context.itemConfiguration.portion.portionUid).addOns;
  }

  public handleConfigurationChanged() {

    this.configurationChanged.emit();
  }
}
