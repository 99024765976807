import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SelectListModalData } from "./select-list-modal.provider";

@Component({
  selector: 'app-select-list-modal',
  templateUrl: './select-list-modal.component.html',
  styleUrls: ['./select-list-modal.component.scss']
})
export class SelectListModalComponent<T> {

  public options: SelectableOption<T>[];

  constructor(
    private dialogRef: MatDialogRef<SelectListModalComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: SelectListModalData<T>
  ) {
    this.options = data.options.map(x => <SelectableOption<T>>{ display: data.displayFunc(x), item: x, selected: true });
  }

  toggleOption(option: SelectableOption<T>) {

    option.selected = !option.selected;
  }

  selectOption(option: SelectableOption<T>) {

    if (this.data.allowMultiple) {
      this.toggleOption(option);
    } else {
      this.dialogRef.close(option.item);
    }
  }

  close() {

      this.dialogRef.close(this.options.filter(x => x.selected).map(x => x.item));
  }
}

interface SelectableOption<T> {

  display: string;
  selected: boolean;
  item: T
}
