<div *ngIf="form" [formGroup]="form">

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Name</span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="name">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Status</span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <mat-select formControlName="productStatusUid">
        <mat-option *ngFor="let productStatus of productStatuses" [value]="productStatus.uid">{{ productStatus.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Description</span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="description">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Image</span>
    </div>
    <div style="flex: 1; margin-left: 5px;">
      <!-- <img *ngIf="imageUrl" src="{{ imageUrl }}"> -->
      <div style="flex: 1 1 auto;"></div>
      <button mat-flat-button (click)="selectImage()">Select Image</button>
      <button mat-flat-button (click)="removeImage()">Remove Image</button>
    </div>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Display Order</span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="displayOrder">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Department</span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <mat-select formControlName="departmentUid">
        <mat-option *ngFor="let department of departments" [value]="department.uid">{{ department.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Category</span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <mat-select formControlName="categoryUid">
        <mat-option *ngFor="let category of filteredCategories" [value]="category.uid">{{ category.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="form.get('configuration')">

    <div style="display: flex;">
      <div style="flex: 1;">
        <span>Preparations</span>
      </div>
      <div style="flex: 1;">

        <mat-selection-list cdkDropList class="draggable-list" [multiple]="false" (cdkDropListDropped)="dropPreparation($event)">

          <mat-list-option cdkDrag *ngFor="let preparationForm of preparationsFormGroupArray" [value]="preparationForm" class="item" (click)="editPreparation(preparationForm)" click-stop-propagation>
            <div style="display: flex; align-items: center;">
              <div style="width: 25px; margin-right: 5px;">
                <mat-icon>drag_indicator</mat-icon>
              </div>
              <div style="flex: 1;">
                <span>{{ getValueOrUnknown(getResolvedPreparationFormValue(preparationForm.get('uid').value, 'name')) }}</span>
              </div>
              <mat-icon (click)="deletePreparation(preparationForm)">delete</mat-icon>
            </div>
          </mat-list-option>

        </mat-selection-list>

        <button mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-right: 5px;" (click)="newPreparation()">Add</button>
      </div>
    </div>

    <div style="display: flex;">
      <div style="flex: 1;">
        <span>Variations</span>
      </div>
      <div style="flex: 1;">

        <mat-selection-list cdkDropList class="draggable-list" [multiple]="false" (cdkDropListDropped)="dropVariation($event)">

          <mat-list-option cdkDrag *ngFor="let variationFormGroup of variationsFormGroupArray" [value]="variationFormGroup" class="item" (click)="editVariation(variationFormGroup)" click-stop-propagation>
            <div style="display: flex; align-items: center;">
              <div style="width: 25px; margin-right: 5px;">
                <mat-icon>drag_indicator</mat-icon>
              </div>
              <div style="flex: 1;">
                <span>{{ getValueOrUnknown(getResolvedVariationFormValue(variationFormGroup.get('uid').value, 'name')) }}</span>
              </div>
              <mat-icon (click)="deleteVariation(variationFormGroup)">delete</mat-icon>
            </div>
          </mat-list-option>

        </mat-selection-list>

        <button mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-right: 5px;" (click)="newVariation()">Add</button>
      </div>
    </div>

  </div>

</div>
