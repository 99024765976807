<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="dataSource.selection?.isMultipleSelection()" (change)="$event ? dataSource.masterToggle() : null" [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row) : null" [checked]="dataSource.selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="dayOfWeek">
    <th mat-header-cell *matHeaderCellDef color="accent">Day Of Week</th>
    <td mat-cell *matCellDef="let row">
      <div style="text-align: center;">{{ row.item.clockInDateTime | date: 'EE' }}</div>
      <div style="text-align: center;">{{ row.item.clockInDateTime | date: 'M-dd' }}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="employee">
    <th mat-header-cell *matHeaderCellDef color="accent">Employee</th>
    <td mat-cell *matCellDef="let row">{{ row.item.employee.getFullName() }}</td>
  </ng-container>

  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef color="accent">Position</th>
    <td mat-cell *matCellDef="let row">{{ row.item.position.name }}</td>
  </ng-container>

  <ng-container matColumnDef="clockedIn">
    <th mat-header-cell *matHeaderCellDef>Clocked In</th>
    <td mat-cell *matCellDef="let row">{{ row.item.clockInDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}</td>
  </ng-container>

  <ng-container matColumnDef="clockedOut">
    <th mat-header-cell *matHeaderCellDef>Clocked Out</th>
    <td mat-cell *matCellDef="let row">{{ row.item.clockOutDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}</td>
  </ng-container>

  <ng-container matColumnDef="hours">
    <th mat-header-cell *matHeaderCellDef>Hours</th>
    <td mat-cell *matCellDef="let row" style="text-align: left;" [ngStyle]="{ 'color': row.item.duration?.toNumber() > 8 ? 'red' : 'inherit' }">{{ row.item.duration | timeSpan : 'short' }}</td>
  </ng-container>

  <ng-container matColumnDef="transactionsValue">
    <th mat-header-cell *matHeaderCellDef>$</th>
    <td mat-cell *matCellDef="let row" style="text-align: left;">{{ row.item.transactionsValue | currency:'USD':'symbol':'1.2-2' }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let row">
      <span [ngStyle]="{ 'color': row.item.employeeShiftStatusUid.toLocaleUpperCase() === 'BBBABA53-7424-46D0-BB91-991A96AA11A7' ? 'red' : 'inherit' }">{{ row.item.employeeShiftStatusUid | employeeShiftStatus }}</span>
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let employeeShift">
      <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="employeeShift.canEdit" (click)="viewConversations(employeeShift)">
        <mat-icon matBadge="{{ employeeShift.conversationCount }}" matBadgeColor="warn" [matBadgeHidden]="employeeShift.conversationCount == 0">feedback</mat-icon>
      </button>
      <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="employeeShift.canEdit" (click)="edit(employeeShift)">Edit</button>
      <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="employeeShift.canSubmit" (click)="submit(employeeShift)">Submit</button>
      <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="employeeShift.canAccept" (click)="accept(employeeShift)">Accept</button>
      <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="employeeShift.canReject" (click)="reject(employeeShift)">Reject</button>
      <button mat-stroked-button color="warn" style="margin-right: 5px;" click-stop-propagation *ngIf="employeeShift.canDelete" (click)="delete(employeeShift)">
        <mat-icon>delete_outline</mat-icon>
      </button>
    </td>
  </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>

</table>
