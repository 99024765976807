import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentDateTimeProvider, DateRangeFilterInput, TimeoutProvider, EntityEventProvider, ErrorHandlingProvider, isCaseInsensitiveEqual, isEqualUUID, MessageModalData, MessageModalProvider, NavigationProvider, ObservableQueueProvider, PaginationInput, PaymentMethod, PaymentMethodKeys, SettingProvider, slideInAnimation, slideOutAnimation, sortByKey, subtractHourMinuteSecond, SystemMessageProvider, TenantProvider, CardSwipe } from 'core';
import { TransactionViewModalProvider, TransactionSelectModalProvider, Transaction, TransactionEditorBase, EditableTransactionDataHandler, EditableTransaction, EditableTransactionItem, LogisticTypeModel, LogisticSettings, EditableTransactionCharge, ChargeTypeKeys, EditableTransactionPayment, ItemConfiguratorModalData, EditableTransactionTypes, EditableTransactionGuest, EditableTransactionItemAdjustment, EditableTransactionAdjustment, EditableTransactionChargeAdjustment, ItemConfiguratorModalResult, initTransactionItemConfiguration, initTransactionItem, cloneTransactionItemConfiguration, calculateTotal, EditableTransactionItemConfiguration, LogisticTypeKeys, TransactionSettings, GuestServiceModalProvider, TransactionStatusEnum } from 'downtown-transaction';
import { Category, CategorySelectModalProvider, Department, MenuPlacement, Product, ProductProvider, ProductSelectModalProvider, ProductSelectModalResult, ProductStatusKeys, flattenProduct } from 'downtown-product';
import { ItemConfiguratorModalProvider } from 'downtown-transaction';
import { TransactionProvider } from 'downtown-transaction';
import { BehaviorSubject, Observable, Subject, asapScheduler, combineLatest, forkJoin, of } from 'rxjs';
import { map, takeUntil, tap, first, concatMap, filter, finalize, catchError } from 'rxjs/operators';
import { ScheduleProvider, DrawerProvider, REGISTER_HANDLER_PROVIDER_FACTORY, RegisterFeatureProvider } from '../../providers';
import { DrawerService, PriceAdjustmentService } from '../../services';
import { CardEntryModalProvider, CardSwipeMode, LogisticTypeModalData, LogisticTypeModalProvider, PaymentModalData, PaymentModalProvider, PrintDepartmentSlipModalProvider, SelectGuestModalData, SelectGuestModalProvider, TransactionItemPrintModalProvider } from '../../modals';
import { DisburseDebitInput, PrintDocumentOptions, OutputStyle, PosSettings, Schedule, Drawer } from '../../models';
import { PriceAdjustmentStatusKeys } from '../../keys';
import { UUID } from 'angular2-uuid';
import { timeoutEnclosure } from '../../handlers';
import { TextEntryModalProvider, TextEntryModalResult, CaptureTokenEventModel, CardSwipeEventModel, CardSwipeStatusEnum } from 'core';
import { RemoteLoggingProvider, TerminalProvider, TerminalSettings } from 'downmain-terminal';
import { DeviceSettings, DeviceTypeEnum } from 'downmain-device';
import { OltpServiceSettings } from '../../oltp-service-settings';
import Decimal from 'decimal.js';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class RegisterComponent extends TransactionEditorBase implements OnInit {

  static DEPARTMENT_KITCHEN: string = '46C1731B-DBEA-4C6E-B822-F56B80A58254';

  public timeoutPercent: number;
  public lastEntryContext: LastEntryContextEnum = LastEntryContextEnum.None;
  public activeSchedules$: Observable<Schedule[]>;
  public registerFeatureProvider: RegisterFeatureProvider;

  public openTransactions$: Observable<Transaction[]>;
  public recentTransactions$: Observable<Transaction[]>;

  public canPrintCheck = false;
  public canPrintKitchen = false;
  public canPrintReceipt = false;
  public canPrintCardReceipt = false;
  public canAddSaleComp = false;
  public canSaveGuestCheck = false;
  public canTenderCash = false;
  public canTenderCard = false;
  public canCloseCardSale = false;
  public canCloseCashSale = false;
  public canOpenDrawer = false;

  protected terminalUid: string;

  protected observableQueueProvider: ObservableQueueProvider;
  protected destroyed$ = new Subject<void>();
  protected refresh$ = new Subject<void>();

  protected timeoutProvider: TimeoutProvider;

  private hasCashDrawer = false;
  private hasCardTerminal = false;
  private hasReceiptPrinter = false;
  private hasKitchenPrinter = false;
  private activeSchedulesSubject = new BehaviorSubject<Schedule[]>(null);

  constructor(
    private navigationProvider: NavigationProvider,
    // private transactionNotesModal: TransactionNotesModalProvider,
    // private activePriceAdjustmentsProvider: ActivePriceAdjustmentsProvider,
    @Inject(REGISTER_HANDLER_PROVIDER_FACTORY) registerHandlerProviderFactory: () => RegisterFeatureProvider,
    private activatedRoute: ActivatedRoute,
    private currentDateTimeProvider: CurrentDateTimeProvider,
    private oltpServiceSettings: OltpServiceSettings,
    private entityEventProvider: EntityEventProvider,
    private systemMessageProvider: SystemMessageProvider,
    private terminalProvider: TerminalProvider,
    private drawerProvider: DrawerProvider,
    private drawerService: DrawerService,
    private productProvider: ProductProvider,
    private scheduleProvider: ScheduleProvider,
    private priceAdjustmentService: PriceAdjustmentService,
    private textEntryModalProvider: TextEntryModalProvider,
    private categorySelectModalProvider: CategorySelectModalProvider,
    private productSelectModalProvider: ProductSelectModalProvider,
    private guestServiceModalProvider: GuestServiceModalProvider,
    private itemConfiguratorModalProvider: ItemConfiguratorModalProvider,
    private selectGuestModalProvider: SelectGuestModalProvider,
    private transactionSelectModalProvider: TransactionSelectModalProvider,
    private transactionViewModalProvider: TransactionViewModalProvider,
    private transactionItemPrintModalProvider: TransactionItemPrintModalProvider,
    private printDepartmentSlipModalProvider: PrintDepartmentSlipModalProvider,
    private logisticTypeModalProvider: LogisticTypeModalProvider,
    private cardEntryModalProvider: CardEntryModalProvider,
    private paymentModalProvider: PaymentModalProvider,
    private messageModalProvider: MessageModalProvider,
    errorHandlingProvider: ErrorHandlingProvider,
    tenantProvider: TenantProvider,
    settingProvider: SettingProvider,
    transactionProvider: TransactionProvider,
    remoteLoggingProvider: RemoteLoggingProvider,
  ) {
    super(errorHandlingProvider, tenantProvider, settingProvider, transactionProvider, remoteLoggingProvider);

    navigationProvider.setWaypoint('Site Register');

    this.activeSchedules$ = this.activeSchedulesSubject.asObservable();

    this.observableQueueProvider = new ObservableQueueProvider(this.remoteLoggingProvider, message => {
      this.messageModalProvider.open(<MessageModalData>{ title: 'Register Error', message: message });
    });

    this.registerFeatureProvider = registerHandlerProviderFactory();

    this.terminalProvider.active$.pipe(
      takeUntil(this.destroyed$),
      concatMap(terminal => this.settingProvider.getOneByTypeAndOwner$<DeviceSettings>('DeviceSettings', terminal.uid))
    ).subscribe(deviceSettings => {
      this.hasCashDrawer = deviceSettings != null && deviceSettings.settings.some(x => x.deviceType == DeviceTypeEnum.CashDrawer);
      this.hasCardTerminal = deviceSettings != null && deviceSettings.settings.some(x => x.deviceType == DeviceTypeEnum.Msr);

      this.settingProvider.getOneByTypeAndOwner$<TerminalSettings>('TerminalSettings', this.terminalProvider.active.uid).pipe(
        first()
      ).subscribe(terminalSettings => {
        this.hasReceiptPrinter = terminalSettings != null && terminalSettings.defaultReceiptPrinterDeviceUid && deviceSettings.settings.some(x => isEqualUUID(x.uid, terminalSettings.defaultReceiptPrinterDeviceUid));
        this.hasKitchenPrinter = terminalSettings != null && terminalSettings.defaultKitchenPrinterDeviceUid && deviceSettings.settings.some(x => isEqualUUID(x.uid, terminalSettings.defaultKitchenPrinterDeviceUid));
      });
    });

    this.openTransactions$ = this.transactionProvider.openTransactions$.pipe(takeUntil(this.destroyed$));
    this.recentTransactions$ = this.transactionProvider.recentTransactions$.pipe(takeUntil(this.destroyed$));
  }

  ngOnInit() {

    this.remoteLoggingProvider.log(`BackOfficeTransactionComponent::ngOnInit`);

    this.drawerProvider.activeDrawer$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(_ => {
      this.synchronizeFeatureEnabling();
    });

    this.transactionProvider.mutatedStream$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(transaction => {
      if (this.editableTransaction && isEqualUUID(transaction.uid, this.editableTransaction.uid)) {
        this.observableQueueProvider.enqueue(() => {
          const correlationUid = UUID.UUID();

          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction.uid} | transactionMutatedStream$ merging`);

          EditableTransactionDataHandler.mergeTransaction(this.editableTransaction, transaction);
          this.synchronizeFeatureEnabling();

          return of(true);
        })
      }
    });

    // Monitor for inactivity and auto clear transactions
    this.timeoutProvider = new TimeoutProvider(this.currentDateTimeProvider, this.destroyed$);

    this.timeoutProvider.percent$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(value => {
      this.timeoutPercent = this.editableTransaction ? value : 0;
    });

    this.timeoutProvider.inactive$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(_ => {
      if (this.editableTransaction) {
        if (isEqualUUID(this.editableTransaction.lockUid.value, this.terminalProvider.active.uid)) {
          this.observableQueueProvider.enqueue(() => {
            var correlationUid = UUID.UUID();

            return this.unlock$(correlationUid).pipe(
              tap(_ => {
                this.editableTransaction = null;
                this.navigateToTransaction(null);
              }),
              map(_ => true),
              finalize(() => this.publishLogging())
            );
          });
        } else {
          this.navigateToTransaction(null);
        }
      }
    });

    this.terminalProvider.active$.pipe(
      takeUntil(this.destroyed$),
      tap(terminal => {
        this.refresh$.next();

        this.terminalUid = terminal?.uid;

        if (terminal) {
          this.lockUid = terminal.uid;

          this.initializeRouteSubscription();
          this.initializeScheduleSubscription();

          this.synchronizeFeatureEnabling();
        }
      })
    ).pipe(
      // concatMap((localTerminal) => {
      //   return this.drawerProvider.activeDrawer$.pipe(
      //     map(activeDrawer => {
      //       return <[Drawer, Terminal]>[activeDrawer, localTerminal];
      //     })
      //   )
      // }),
      // takeUntil(this.destroyed$)
    ).subscribe();
  }

  ngOnDestroy() {

    this.registerFeatureProvider.destroy();

    let lockTerminalUid = this.editableTransaction ? this.editableTransaction.lockUid.value : null;
    if (lockTerminalUid) {
      this.unlock$(UUID.UUID()).subscribe();
    }

    this.refresh$.next();
    this.destroyed$.next();
  }

  // @HostListener('document:visibilitychange', ['$event'])
  // visibilityChange($event: Event) {

  //   if (document.visibilityState === 'hidden') {
  //     this.ngOnDestroy();
  //   }
  // }

  public navigateHome() {

    this.registerFeatureProvider.navigateHome(this.navigationProvider);
  }

  public navigateToBackOffice() {

    this.registerFeatureProvider.navigateToBackOffice(this.navigationProvider);
  }

  protected navigateToTransaction(transactionUid: string): void {

    this.registerFeatureProvider.navigateToTransaction(this.navigationProvider, transactionUid);
  }

  private initializeRouteSubscription() {

    this.activatedRoute.params.pipe(
      takeUntil(this.refresh$)
    ).subscribe(params => {
      const transactionUid = <string>params['transactionUid'];

      if (transactionUid) {
        if (!this.editableTransaction || !isEqualUUID(this.editableTransaction.uid, transactionUid)) {

          this.observableQueueProvider.enqueue(() => {
            var correlationUid = UUID.UUID();

            return this.loadTransaction$(transactionUid, correlationUid).pipe(
              tap(() => {
                this.synchronizeFeatureEnabling();
              }),
              finalize(() => this.publishLogging())
            );
          });
        }
      } else {
        this.observableQueueProvider.enqueue(() => {
          var correlationUid = UUID.UUID();

          return this.clearTransaction(correlationUid).pipe(
            map(() => true),
            finalize(() => this.publishLogging())
          );
        });
      }
    });
  }

  private initializeScheduleSubscription() {

    // Monitor for schedule changes that involve a price adjustment (ie: happy hour)
    combineLatest([
      this.scheduleProvider.active$,
      // this.activePriceAdjustmentsProvider.activePriceAdjustments$
      this.priceAdjustmentService.search([PriceAdjustmentStatusKeys.Active], null, PriceAdjustmentService.PriceAdjustmentFullView)
    ]).pipe(
      takeUntil(this.refresh$)
    ).subscribe(([activeSchedules, activePriceAdjustmentsPage]) => {
      let activePriceAdjustments = activePriceAdjustmentsPage.edges.map(x => x.node);
      if (activeSchedules != null && activePriceAdjustments != null) {
        const activeSchedulesWithPriceAdjustments = activeSchedules.filter(schedule => activePriceAdjustments?.some(pa => pa.scheduleUids?.some(x => x == schedule.uid)));

        let changed = false;
        let currentActiveSchedules = (this.activeSchedulesSubject.value || []).slice();
        activeSchedulesWithPriceAdjustments.forEach(activeSchedule => {
          if (!currentActiveSchedules.some(x => x.uid == activeSchedule.uid)) {
            currentActiveSchedules.push(activeSchedule);
            changed = true;
          }
        });

        let nextActiveSchedules = currentActiveSchedules.filter(x => activeSchedulesWithPriceAdjustments.some(y => y.uid == x.uid));

        changed = changed || nextActiveSchedules.length != currentActiveSchedules.length;
        if (changed) {
          this.activeSchedulesSubject.next(sortByKey(nextActiveSchedules, 'name'));
        }
      }
    });
  }

  protected stopDateTimeProgress() {

    this.timeoutProvider.stop();
  }

  protected updateTimeoutProvider() {

    var selectedLineItem = EditableTransactionDataHandler.getSelectedLine(this.editableTransaction);
    selectedLineItem ? this.timeoutProvider.stop() : this.timeoutProvider.restart();

    this.synchronizeFeatureEnabling();
  }

  protected publishLogging() {

    this.settingProvider.getOneByTypeAndOwner$<TerminalSettings>('TerminalSettings', this.terminalProvider.active.uid).subscribe(terminalSettings => {
      if (terminalSettings.enableRemoteLogging) {
        this.remoteLoggingProvider.publish();
      }
    });
  }

  protected loadTransaction$(transactionUid: string, correlationUid: string): Observable<boolean> {

    return (this.editableTransaction ? this.commitOrCancelTransactionState$() : of(null)).pipe(
      tap(() => EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null)),
      concatMap(() => this.demandTransaction$(this.editableTransaction)),
      first(),
      concatMap(() => super.getTransaction$(transactionUid)),
      first(),
      concatMap(transaction => {
        this.editableTransaction = transaction;
        this.lastEntryContext = LastEntryContextEnum.None;

        return super.addViewer$(this.editableTransaction, this.terminalUid, false, correlationUid)
      }),
      first(),
      tap(_ => {
        this.synchronizeFeatureEnabling();
        this.updateTimeoutProvider();
      }),
      map(_ => true)
    );
  }

  protected clearTransaction(correlationUid: string): Observable<EditableTransaction> {

    return this.unlock$(correlationUid).pipe(
      tap(() => {
        this.editableTransaction = null;
        this.lastEntryContext = LastEntryContextEnum.None;
        this.synchronizeFeatureEnabling();
      })
    );
  }

  public departmentPressed(value: { department: Department, category: Category }) {

    const correlationUid = UUID.UUID();
    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | departmentPressed`);

    this.observableQueueProvider.enqueue(() => {
      return this.handleDepartmentPressed$(value, correlationUid).pipe(
        tap(() => this.synchronizeFeatureEnabling()),
        finalize(() => this.publishLogging())
      );
    });
  }

  protected handleDepartmentPressed$(value: { department: Department, category: Category }, correlationUid = UUID.UUID()): Observable<boolean> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleDepartmentPressed$`);

    return of(this.timeoutProvider.stop()).pipe(
      concatMap(_ => {
        let selectedLineItem = EditableTransactionDataHandler.getSelectedLine(this.editableTransaction) as EditableTransactionItem;
        if (selectedLineItem && selectedLineItem.uid && selectedLineItem.configuration.value) {
          // Pressed department on saved item already configured
          EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
          selectedLineItem = null;
          this.lastEntryContext = LastEntryContextEnum.None;
        }

        if (selectedLineItem && selectedLineItem instanceof EditableTransactionItem) {

          if (!selectedLineItem.uid && selectedLineItem.isValid && this.lastEntryContext == LastEntryContextEnum.Currency) {
            // Starting next entry

            return this.demandTransaction$(this.editableTransaction, correlationUid).pipe(
              concatMap(transaction => {
                return this.commitItem$(transaction, selectedLineItem, correlationUid).pipe(
                  concatMap(_ => {
                    EditableTransactionDataHandler.setSelectedLine(transaction, null);

                    return this.startNewItem$(value.department, value.category).pipe(first());
                  }),
                  first()
                );
              }),
              first(),
              map(_ => true)
            );
          } else {
            // Existing entry
            let transactionItem = EditableTransactionDataHandler.getOrCreateTransactionItem(this.editableTransaction);
            if (!transactionItem.configuration.value) {
              // Can't change department of configured items
              const existingDepartmentUid = transactionItem.departmentUid.value;
              const existingCategoryUid = transactionItem.categoryUid.value

              const updatedDepartmentUid = value.department?.uid;
              const updatedCategoryUid = value.category?.uid;

              if (isEqualUUID(existingDepartmentUid, updatedDepartmentUid) && isEqualUUID(existingCategoryUid, updatedCategoryUid)) {
                transactionItem.quantity.next(transactionItem.quantity.value + 1);

                this.lastEntryContext = LastEntryContextEnum.Department;
              } else {
                return this.getDepartmentCategoryProductsCount$(value.department, value.category).pipe(
                  concatMap(totalCount => {
                    if (totalCount == 0) {
                      transactionItem.quantity.next(1);
                      transactionItem.departmentUid.next(value.department.uid);
                      transactionItem.categoryUid.next(value.category?.uid);
                    } else {
                      if (transactionItem.isValid) {
                        return this.demandTransaction$(this.editableTransaction, correlationUid).pipe(
                          concatMap(transaction => {
                            return this.commitItem$(transaction, selectedLineItem, correlationUid).pipe(
                              first(),
                              concatMap(_ => {
                                EditableTransactionDataHandler.setSelectedLine(transaction, null);

                                return this.startNewItem$(value.department, value.category, correlationUid);
                              }),
                              first()
                            );
                          }),
                          first(),
                          map(_ => true)
                        );
                      } else {
                        EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
                        EditableTransactionDataHandler.cancelTransactionItem(this.editableTransaction, transactionItem);

                        this.departmentPressed(value);
                      }
                    }

                    this.lastEntryContext = LastEntryContextEnum.Department;

                    return of(true);
                  }),
                  first(),
                );
              }
            }

            return of(true);
          }
        } else {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleDepartmentPressed$ => startNewItem$ observe`);

          return this.startNewItem$(value.department, value.category, correlationUid).pipe(
            tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleDepartmentPressed$ <= startNewItem$ observe`)),
            map(_ => true),
            finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleDepartmentPressed$ => startNewItem$ finalize`))
          );
        }
      }),
      first(),
      tap(_ => this.updateTimeoutProvider())
    )
  }

  public speedValuePressed(value: string) {

    const correlationUid = UUID.UUID();
    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | speedValuePressed`);

    var selection = this.getSelection();

    if (selection instanceof EditableTransactionItem) {
      if (selection && selection.getEachAmount() > 0 && (this.lastEntryContext == LastEntryContextEnum.Currency || selection.uid)) {
        this.numberPressed('clear');
      }
    } else if (selection instanceof EditableTransactionAdjustment) {
      if (selection && selection.getAmount() > 0) {
        this.numberPressed('clear');
      }
    } else if (selection instanceof EditableTransactionItemAdjustment) {
      if (selection && selection.getEachAmount() > 0) {
        this.numberPressed('clear');
      }
    } else if (selection instanceof EditableTransactionCharge) {
      if (selection && selection.getEachAmount() > 0) {
        this.numberPressed('clear');
      }
    } else if (selection instanceof EditableTransactionPayment) {
      if (selection && selection.getAmount() > 0) {
        this.numberPressed('clear');
      }
    }

    for (var i = 0; i < value.length; i++) {
      this.numberPressed(value[i], correlationUid);
    }

    this.updateTimeoutProvider();
  }

  public numberPressed(value: string, correlationUid = UUID.UUID()) {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | numberPressed | ${value}`);

    this.observableQueueProvider.enqueue(() => {
      return this.handleNumberPressed$(value, correlationUid).pipe(
        tap(_ => this.synchronizeFeatureEnabling()),
        tap(_ => this.updateTimeoutProvider()),
        finalize(() => this.publishLogging())
      );
    });
  }

  private handleNumberPressed$(value: string, correlationUid = UUID.UUID()): Observable<boolean> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleNumberPressed$ | ${value}`);

    let buttonValue = value.toLowerCase();

    var selectedLineItem = EditableTransactionDataHandler.getSelectedLine(this.editableTransaction);

    if (selectedLineItem) {
      // this.remoteLoggingProvider.log(`numberPressed:selectedLineItem has value`);

      if (selectedLineItem instanceof EditableTransactionItem) {
        // this.remoteLoggingProvider.log(`numberPressed:selectedLineItem is TransactionLineItem`);

        let transactionLineItem = <EditableTransactionItem>selectedLineItem;

        if (!transactionLineItem.configuration.value) {

          if (buttonValue == "back" && this.lastEntryContext == LastEntryContextEnum.None) {
            this.lastEntryContext = LastEntryContextEnum.Currency;
          }

          // Special handling for sale line items that keep a context of the last entry button used to maintain a user workflow
          if (!transactionLineItem.uid && transactionLineItem.isValid && this.lastEntryContext == LastEntryContextEnum.Department && buttonValue != "enter" && buttonValue != "clear") {
            this.remoteLoggingProvider.log(`numberPressed:user is starting new entry`);

            return this.demandTransaction$(this.editableTransaction).pipe(
              first(),
              concatMap(transactionEditState => {
                return this.commitItem$(transactionEditState, transactionLineItem).pipe(
                  tap(_ => {
                    // User setting up a new line item - current line item already valid and the last context was not currency entry
                    // this.remoteLoggingProvider.log(`numberPressed:setting selectedLineItem to null`);
                    EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);

                    if (transactionLineItem || buttonValue != "enter") {
                      let transactionLineItem = EditableTransactionDataHandler.getOrCreateTransactionItem(transactionEditState)

                      // this.remoteLoggingProvider.log(`numberPressed:setting SelectedLineItem to new transactionLineItem`);
                      selectedLineItem = EditableTransactionDataHandler.setSelectedLine(transactionEditState, transactionLineItem)

                      // this.remoteLoggingProvider.log(`numberPressed:executing keypress for value: ${value}`);

                      let result = EditableTransactionDataHandler.getNumberPadKeyPressedResult(selectedLineItem, value);

                      // this.remoteLoggingProvider.log(`numberPressed:setting LastEntryContextEnum: Currency`);
                      this.lastEntryContext = LastEntryContextEnum.Currency;
                    }
                  })
                );
              }),
              first(),
              map(_ => true)
            );
          } else {
            // this.remoteLoggingProvider.log(`numberPressed:user is still in same entry`);

            this.editableTransaction = EditableTransactionDataHandler.getOrCreateTransaction(this.editableTransaction, this.terminalUid);

            if (this.editableTransaction.transactionStatus.value != TransactionStatusEnum.Closed) {
              // this.remoteLoggingProvider.log(`numberPressed:executing keypress for value: ${value}`);

              let result = EditableTransactionDataHandler.getNumberPadKeyPressedResult(transactionLineItem, value);

              // this.remoteLoggingProvider.log(`numberPressed:result is: ${result}`);

              if (result == 'cancel') {
                // this.remoteLoggingProvider.log(`numberPressed:cancel`);

                EditableTransactionDataHandler.cancelItemChanges(this.editableTransaction, transactionLineItem);
                EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);

                if (this.editableTransaction.uid == null && this.editableTransaction.lineItems.length == 0 && this.editableTransaction.payments.length == 0 && this.editableTransaction.adjustments.length == 0) {
                  // Cancel the transaction
                  EditableTransactionDataHandler.tryCancelTransaction(this.editableTransaction);
                  this.editableTransaction = null;
                }

                return of(true);
              } else if (result == 'commit') {
                // this.remoteLoggingProvider.log(`numberPressed:commit`);

                if (transactionLineItem.isValid) {
                  this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${this.editableTransaction?.uid} | handleNumberPressed$ => demandTransaction$ observe`);

                  return this.demandTransaction$(this.editableTransaction, correlationUid).pipe(
                    concatMap(transaction => {
                      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | handleNumberPressed$ <= demandTransaction$`);

                      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${this.editableTransaction?.uid} | handleNumberPressed$ => commitItem$ observe`);

                      return this.commitItem$(transaction, transactionLineItem, correlationUid)
                    }),
                    tap(_ => {
                      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${this.editableTransaction?.uid} | handleNumberPressed$ <= commitItem$`);

                      EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
                      this.lastEntryContext = LastEntryContextEnum.None;
                    }),
                    finalize(() => {
                      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${this.editableTransaction?.uid} | handleNumberPressed$ => demandTransaction$ finalize`);
                    }),
                    map(_ => true)
                  );
                }
              } else {
                this.lastEntryContext = LastEntryContextEnum.Currency;

                return of(true);
              }
            }
          }
        }
      } else if (selectedLineItem instanceof EditableTransactionItemAdjustment) {
        // this.remoteLoggingProvider.log(`numberPressed:selectedLineItem is EditableTransactionLineItemAdjustment`);

        let result = EditableTransactionDataHandler.getNumberPadKeyPressedResult(selectedLineItem, value);

        // this.remoteLoggingProvider.log(`numberPressed:result is: ${result}`);

        let transactionLineItem = this.editableTransaction.lineItems.find(x => isEqualUUID(x.uid, (<EditableTransactionItemAdjustment>selectedLineItem).transactionLineItemUid));
        let transactionLineItemAdjustmentTotal = transactionLineItem.adjustments.map(x => x.total.value).reduce((sum, total) => sum + total);

        if (result == 'cancel') {
          // this.remoteLoggingProvider.log(`numberPressed:cancel`);

          this.lineItemCancel(selectedLineItem);

          return of(true);
        } else if (result == 'commit') {
          // this.remoteLoggingProvider.log(`numberPressed:commit`);

          if (selectedLineItem.isValid && transactionLineItemAdjustmentTotal <= transactionLineItem.total.value) {
            return this.commitItem$(this.editableTransaction, selectedLineItem).pipe(
              first(),
              tap(_ => {
                EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
                this.lastEntryContext = LastEntryContextEnum.None;
              }),
              map(_ => true)
            );
          }

          this.remoteLoggingProvider.log('Adjustments total more than item amount.');

          return of(true);
        } else {
          this.lastEntryContext = LastEntryContextEnum.Currency;

          if (selectedLineItem.isValid && transactionLineItemAdjustmentTotal > transactionLineItem.total.value) {
            // TODO: Should be converted to form validation?
            selectedLineItem.validationMessage = 'Adjustments total more than item amount. Fix or cancel.';
          } else {
            selectedLineItem.validationMessage = null;
          };

          return of(true);
        }
      } else if (selectedLineItem instanceof EditableTransactionAdjustment) {
        // this.remoteLoggingProvider.log(`numberPressed:selectedLineItem is EditableTransactionAdjustment`);

        let result = EditableTransactionDataHandler.getNumberPadKeyPressedResult(selectedLineItem, value);

        // this.remoteLoggingProvider.log(`numberPressed:result is: ${result}`);

        let transactionAdjustmentTotal = this.editableTransaction.adjustments.map(x => x.total.value).reduce((sum, total) => sum + total, 0);

        if (result == 'cancel') {
          // this.remoteLoggingProvider.log(`numberPressed:cancel`);

          EditableTransactionDataHandler.cancelItemChanges(this.editableTransaction, selectedLineItem);
          EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
        } else if (result == 'commit') {
          this.remoteLoggingProvider.log(`numberPressed:commit`);
          if (selectedLineItem.isValid && transactionAdjustmentTotal <= this.editableTransaction.total.value) {
            return this.demandTransaction$(this.editableTransaction).pipe(
              concatMap(_ => {
                return this.commitItem$(this.editableTransaction, selectedLineItem).pipe(
                  tap(_ => {
                    EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
                    this.lastEntryContext = LastEntryContextEnum.None;
                  })
                );
              }),
              first(),
              map(_ => true)
            );
          }
        } else {
          this.lastEntryContext = LastEntryContextEnum.Currency;

          if (selectedLineItem.isValid && transactionAdjustmentTotal > this.editableTransaction.total.value) {
            // TODO: Should be converted to form validation?
            selectedLineItem.validationMessage = 'Adjustment is for more than amount. Fix or cancel.';
          } else {
            selectedLineItem.validationMessage = null;
          };
        }

        return of(true);
      } else if (selectedLineItem instanceof EditableTransactionCharge) {
        // this.remoteLoggingProvider.log(`numberPressed:selectedLineItem is EditableTransactionCharge`);

        let result = EditableTransactionDataHandler.getNumberPadKeyPressedResult(selectedLineItem, value);

        // this.remoteLoggingProvider.log(`numberPressed:result is: ${result}`);

        this.editableTransaction = EditableTransactionDataHandler.getOrCreateTransaction(this.editableTransaction, this.terminalUid);

        if (result == 'cancel') {
          // this.remoteLoggingProvider.log(`numberPressed:cancel`);

          EditableTransactionDataHandler.cancelItemChanges(this.editableTransaction, selectedLineItem);
          EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
        } else if (result == 'commit') {
          // this.remoteLoggingProvider.log(`numberPressed:commit`);

          if (selectedLineItem.isValid) {
            return this.demandTransaction$(this.editableTransaction).pipe(
              concatMap(_ => {
                return this.commitItem$(this.editableTransaction, selectedLineItem).pipe(
                  tap(_ => {
                    EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
                    this.lastEntryContext = LastEntryContextEnum.None;
                  })
                );
              }),
              first(),
              map(_ => true)
            );
          }
        } else {
          this.lastEntryContext = LastEntryContextEnum.Currency;
        }

        return of(true);
      } else if (selectedLineItem instanceof EditableTransactionChargeAdjustment) {
        // this.remoteLoggingProvider.log(`numberPressed:selectedLineItem is EditableTransactionChargeAdjustment`);

        let result = EditableTransactionDataHandler.getNumberPadKeyPressedResult(selectedLineItem, value);

        // this.remoteLoggingProvider.log(`numberPressed:result is: ${result}`);

        let transactionCharge = this.editableTransaction.charges.find(x => isEqualUUID(x.uid, (<EditableTransactionChargeAdjustment>selectedLineItem).transactionChargeUid));
        let transactionChargeAdjustmentTotal = transactionCharge.adjustments.map(x => x.total.value).reduce((sum, total) => sum + total, 0);

        if (result == 'cancel') {
          // this.remoteLoggingProvider.log(`numberPressed:cancel`);

          this.lineItemCancel(selectedLineItem);

          return of(true);
        } else if (result == 'commit') {
          // this.remoteLoggingProvider.log(`numberPressed:commit`);

          if (selectedLineItem.isValid && transactionChargeAdjustmentTotal <= transactionCharge.total.value) {
            return this.commitItem$(this.editableTransaction, selectedLineItem).pipe(
              first(),
              tap(_ => {
                EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
                this.lastEntryContext = LastEntryContextEnum.None;
              }),
              map(_ => true)
            );
          }

          // this.remoteLoggingProvider.log('Adjustments total more than item amount.');

          return of(true);
        } else {
          this.lastEntryContext = LastEntryContextEnum.Currency;

          if (selectedLineItem.isValid && transactionChargeAdjustmentTotal > transactionCharge.total.value) {
            // TODO: Should be converted to form validation?
            selectedLineItem.validationMessage = 'Adjustments total more than item amount. Fix or cancel.';
          } else {
            selectedLineItem.validationMessage = null;
          };

          return of(true);
        }
      } else if (selectedLineItem instanceof EditableTransactionPayment) {
        // this.remoteLoggingProvider.log(`numberPressed:selectedLineItem is EditableTransactionPayment`);

        let result = EditableTransactionDataHandler.getNumberPadKeyPressedResult(selectedLineItem, value);

        // this.remoteLoggingProvider.log(`numberPressed:result is: ${result}`);

        this.editableTransaction = EditableTransactionDataHandler.getOrCreateTransaction(this.editableTransaction, this.terminalUid);

        if (result == 'cancel') {
          // this.remoteLoggingProvider.log(`numberPressed:cancel`);

          EditableTransactionDataHandler.cancelItemChanges(this.editableTransaction, selectedLineItem);
          EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
        } else if (result == 'commit') {
          // this.remoteLoggingProvider.log(`numberPressed:commit`);

          if (selectedLineItem.isValid) {
            return this.demandTransaction$(this.editableTransaction).pipe(
              concatMap(_ => {
                return this.commitItem$(this.editableTransaction, selectedLineItem).pipe(
                  tap(_ => {
                    EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
                    this.lastEntryContext = LastEntryContextEnum.None;
                  })
                );
              }),
              first(),
              map(_ => true)
            );
          }
        } else {
          this.lastEntryContext = LastEntryContextEnum.Currency;
        }

        return of(true);
      }
    } else {
      // this.remoteLoggingProvider.log(`numberPressed:selectedLineItem is null`);

      this.editableTransaction = EditableTransactionDataHandler.getOrCreateTransaction(this.editableTransaction, this.terminalUid);

      let transactionLineItem = EditableTransactionDataHandler.getOrCreateTransactionItem(this.editableTransaction);

      // this.remoteLoggingProvider.log(`numberPressed:setting SelectedLineItem to new transactionLineItem`);
      let selectedLineItem = EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, transactionLineItem);

      // this.remoteLoggingProvider.log(`numberPressed:executing keypress for value: ${value}`);

      let _ = EditableTransactionDataHandler.getNumberPadKeyPressedResult(selectedLineItem, value);

      // this.remoteLoggingProvider.log(`numberPressed:setting LastEntryContextEnum: Currency`);
      this.lastEntryContext = LastEntryContextEnum.Currency;

      return of(true);
    }

    return of(false);
  }

  private getDepartmentCategoryProductsCount$(department: Department, category: Category, correlationUid = UUID.UUID()): Observable<number> {

    const departmentUids = department ? [department.uid] : [];
    const categoryUids = category ? [category.uid] : [];

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getDepartmentCategoryProductsCount$ => productProvider.search$ observe`);

    return this.productProvider.search$(this.tenantProvider.currentUid, departmentUids, categoryUids, [ProductStatusKeys.Active], null).pipe(
      tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getDepartmentCategoryProductsCount$ <= productProvider.search$`)),
      map(productsPage => productsPage.totalCount),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getDepartmentCategoryProductsCount$ => productProvider.search$ finalize`))
    );
  }

  private getProductSelectModalProviderResult$(department: Department, category: Category) {

    return this.productSelectModalProvider.open({
      departmentUid: department.uid,
      title: department.name
    }).afterClosed();
  }

  private startNewItem$(department: Department, category: Category, correlationUid = UUID.UUID()): Observable<EditableTransactionItem> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => getDepartmentCategoryProductsCount$ observe`);

    return this.getDepartmentCategoryProductsCount$(department, category, correlationUid).pipe(
      concatMap(totalCount => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ <= getDepartmentCategoryProductsCount$`);

        if (totalCount > 0) {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => getProductSelectModalProviderResult$ observe`);

          return this.getProductSelectModalProviderResult$(department, category).pipe(
            concatMap((result: ProductSelectModalResult) => {
              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ <= getProductSelectModalProviderResult$`);

              if (result) {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => productProvider.getOneCached$ observe`);

                return this.productProvider.getOneCached$(result.productUid, result.productVersion).pipe(
                  concatMap(product => {
                    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ <= productProvider.getOneCached$`);

                    const portion = product.configuration.getPortion(result.portionUid);

                    if (portion.isConfigurable()) {
                      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => getItemConfiguratorModalResult$ observe`);

                      return this.getItemConfiguratorModalResult$(product, result.portionUid).pipe(
                        tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ <= getItemConfiguratorModalResult$`)),
                        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => getItemConfiguratorModalResult$ finalize`))
                      );
                    } else {
                      return of(<ItemConfiguratorModalResult>{
                        productUid: product.uid,
                        productVersion: product.version,
                        configuration: initTransactionItemConfiguration(flattenProduct(product), result.portionUid, this.productProvider, true),
                        startAnother: false
                      });
                    }
                  }),
                  first(),
                  finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => productProvider.getOneCached$ finalize`))
                );
              } else {
                return of(<ItemConfiguratorModalResult>{
                  productUid: null,
                  productVersion: null,
                  configuration: null,
                  startAnother: false
                });
              }
            }),
            first(),
            concatMap((result: ItemConfiguratorModalResult) => {
              if (result.productUid != null && !!result.productVersion != null) {
                this.editableTransaction = EditableTransactionDataHandler.getOrCreateTransaction(this.editableTransaction, this.terminalUid);

                let guest: EditableTransactionGuest = null;
                // if (result.guest) {
                //   guest = EditableTransactionDataHandler.getOrCreateGuest(this.editableTransaction, <EditableTransactionGuest>{
                //     uid: result.guest.uid,
                //     name: result.guest.name
                //   });
                // }

                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => commitConfiguredItem$ observe`);

                return this.commitConfiguredItem$(result.productUid, result.productVersion, result.configuration, null).pipe(
                  tap(() => {
                    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ <= commitConfiguredItem$ observe`);

                    this.lastEntryContext = LastEntryContextEnum.None;

                    if (result.startAnother) {
                      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ | Scheduling start another`);

                      asapScheduler.schedule(() => {
                        this.departmentPressed({ department: department, category: category });
                      });
                    }
                  }),
                  finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => commitConfiguredItem$ finalize`))
                );
              }

              if (this.editableTransaction && this.editableTransaction.lineItems.length == 0) {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => commitOrCancelTransactionState$ observe`);

                return this.commitOrCancelTransactionState$().pipe(
                  tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ <= commitOrCancelTransactionState$`)),
                  map(_ => null),
                  finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => commitOrCancelTransactionState$ finalize`))
                );
              }

              return of(null);
            }),
            first(),
            finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => getProductSelectModalProviderResult$ finalize`))
          );
        } else {
          if (!category && department.categories?.length > 0) {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => categorySelectModalProvider.open observe`);

            return this.categorySelectModalProvider.open({
              title: 'Select Category',
              departmentUids: [department.uid]
            }).afterClosed().pipe(
              map(result => {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ <= categorySelectModalProvider.open`);

                if (result) {
                  this.editableTransaction = EditableTransactionDataHandler.getOrCreateTransaction(this.editableTransaction, this.terminalUid);

                  const transactionLineItem = EditableTransactionDataHandler.getOrCreateTransactionItem(this.editableTransaction);
                  transactionLineItem.quantity.next(1);
                  transactionLineItem.departmentUid.next(result.departmentUid);
                  transactionLineItem.categoryUid.next(result.categoryUid);

                  EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, transactionLineItem);
                  this.lastEntryContext = LastEntryContextEnum.Department;

                  return transactionLineItem;
                }

                return null;
              }),
              first(),
              finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => categorySelectModalProvider.open finalize`))
            );
          } else {
            this.editableTransaction = EditableTransactionDataHandler.getOrCreateTransaction(this.editableTransaction, this.terminalUid);

            const transactionLineItem = EditableTransactionDataHandler.getOrCreateTransactionItem(this.editableTransaction);

            if (transactionLineItem.departmentUid.value && isEqualUUID(transactionLineItem.departmentUid.value, department.uid)) {
              transactionLineItem.quantity.next(transactionLineItem.quantity.value + 1);
            } else {
              transactionLineItem.quantity.next(1);
              transactionLineItem.departmentUid.next(department.uid);
              transactionLineItem.categoryUid.next(category?.uid);
            }

            EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, transactionLineItem);
            this.lastEntryContext = LastEntryContextEnum.Department;

            return of(transactionLineItem);
          }
        }
      }),
      first(),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | startNewItem$ => getDepartmentCategoryProductsCount$ finalize`))
    );
  }

  private commitConfiguredItem$(productUid: string, productVersion: number, configuration: EditableTransactionItemConfiguration, guest: EditableTransactionGuest, correlationUid = UUID.UUID()): Observable<EditableTransactionItem> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => productProvider.getOneCached$ observe`);

    return this.productProvider.getOneCached$(productUid, productVersion).pipe(
      first(),
      concatMap(product => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ <= productProvider.getOneCached$ observe`);

        var transactionItem = initTransactionItem(product, null, this.productProvider, true);
        transactionItem.configuration.next(cloneTransactionItemConfiguration(configuration));
        transactionItem.guestUid.next(guest ? guest.uid : null);

        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => calculateTotal observe`);

        return calculateTotal(transactionItem, this.productProvider).pipe(
          tap(x => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ <= calculateTotal`);

            transactionItem.eachAmountText.next(x.toString());
          }),
          map(x => transactionItem),
          finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => calculateTotal finalize`))
        )
      }),
      first(),
      concatMap(transactionItem => {
        transactionItem = EditableTransactionDataHandler.addTransactionItem(this.editableTransaction, transactionItem);

        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => demandTransaction$ observe`);

        return this.demandTransaction$(this.editableTransaction).pipe(
          first(),
          concatMap(editableTransaction => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ <= demandTransaction$`);

            if (guest && !guest.uid) {
              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => commitGuest$ observe`);

              return super.commitGuest$(editableTransaction, guest).pipe(
                tap(guest => {
                  this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ <= commitGuest`);

                  if (guest) {
                    transactionItem.guestUid.next(guest.uid);
                    this.editableTransaction.lastGuestUid = guest.uid;
                  }
                }),
                map(_ => editableTransaction),
                finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => commitGuest$ finalize`))
              )
            }

            return of(editableTransaction)
          }),
          first(),
          concatMap(editableTransaction => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => commitTransactionItem$ observe`);

            return this.commitTransactionItem$(editableTransaction, transactionItem, null).pipe(
              tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ <= commitTransactionItem$`)),
              first(),
              finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => commitTransactionItem$ finalize`))
            );
          }),
          first(),
          finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => demandTransaction$ finalize`))
        )
      }),
      first(),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => productProvider.getOneCached$ finalize`))
    )
  }

  private getItemConfiguratorModalResult$(product: Product, portionUid: string, correlationUid = UUID.UUID()): Observable<ItemConfiguratorModalResult> {

    const openTransaction = this.editableTransaction && isEqualUUID(this.editableTransaction.transactionStatus.value, TransactionStatusEnum.Open) ? this.editableTransaction : null;
    const configuration = initTransactionItemConfiguration(flattenProduct(product), portionUid, this.productProvider, true);

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => itemConfiguratorModalProvider.open observe`);

    return this.itemConfiguratorModalProvider.open(<ItemConfiguratorModalData>{
      ownerUid: this.tenantProvider.currentUid,
      productUid: product.uid,
      productVersion: product.version,
      itemConfiguration: configuration,
      menuPlacementUid: MenuPlacement.Menu,
      guests: openTransaction ? openTransaction.guests : null,
      lastGuestUid: openTransaction ? openTransaction.lastGuestUid : null,
      isNew: true,
      isAddOn: false
    }).afterClosed().pipe(
      map(result => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ <= itemConfiguratorModalProvider.open`);

        return result || <ItemConfiguratorModalResult>{ productUid: null, productVersion: null, configuration: null, startAnother: false };
      }),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | commitConfiguredItem$ => itemConfiguratorModalProvider.open finalize`))
    );
  }

  public getSelection(): EditableTransactionTypes {

    return EditableTransactionDataHandler.getSelectedLine(this.editableTransaction);
  }

  public lineItemSelect(transactionItem: EditableTransactionGuest | EditableTransactionTypes) {

    if (transactionItem instanceof EditableTransactionItem || transactionItem instanceof EditableTransactionItemAdjustment || transactionItem instanceof EditableTransactionCharge || transactionItem instanceof EditableTransactionChargeAdjustment || transactionItem instanceof EditableTransactionPayment || transactionItem instanceof EditableTransactionAdjustment) {
      this.observableQueueProvider.enqueue(() => {
        return this.handleLineItemSelected$(transactionItem).pipe(
          tap(_ => this.synchronizeFeatureEnabling()),
          tap(_ => this.updateTimeoutProvider()),
          finalize(() => this.publishLogging())
        );
      });
    }
  }

  protected handleLineItemSelected$(transactionLineItem: EditableTransactionTypes, correlationUid = UUID.UUID()): Observable<boolean> {

    if (this.editableTransaction.selectedLineItem.value == transactionLineItem) {
      // Deselect
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemSelected$ => commitItem observe`);

      return this.commitItem$(this.editableTransaction, selectedLineItem).pipe(
        tap(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemSelected$ <= commitItem`);

          EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
          this.lastEntryContext = LastEntryContextEnum.None;
        }),
        map(() => true),
        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemSelected$ => commitItem finalize`))
      );
    } else {
      var selectedLineItem = this.editableTransaction.selectedLineItem.value;

      if (transactionLineItem != selectedLineItem) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | executing keypress for value: enter`);

        let result = EditableTransactionDataHandler.getNumberPadKeyPressedResult(selectedLineItem, 'enter');

        if (result == 'commit') {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemSelected$ => commitItem observe`);

          return this.commitItem$(this.editableTransaction, selectedLineItem).pipe(
            tap(() => {
              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemSelected$ <= commitItem`);

              EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, transactionLineItem);
              this.lastEntryContext = LastEntryContextEnum.None;
            }),
            map(() => true),
            finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemSelected$ => commitItem finalize`))
          );
        } else {
          EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, transactionLineItem);
          this.lastEntryContext = LastEntryContextEnum.None;
        }
      }
    }

    return of(true);
  }

  public lineItemEdit(transactionItem: EditableTransactionGuest | EditableTransactionItem | EditableTransactionItemAdjustment | EditableTransactionPayment | EditableTransactionAdjustment) {

    if (transactionItem instanceof EditableTransactionItem) {
      this.observableQueueProvider.enqueue(() => {
        return this.handleLineItemEdit(transactionItem).pipe(
          tap(_ => this.synchronizeFeatureEnabling()),
          tap(_ => this.updateTimeoutProvider()),
          finalize(() => this.publishLogging())
        );
      });
    }
  }

  private handleLineItemEdit(transactionItem: EditableTransactionItem, correlationUid = UUID.UUID()): Observable<boolean> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemEdit$ => itemConfiguratorModalProvider.open observe`);

    return this.itemConfiguratorModalProvider.open(
      <ItemConfiguratorModalData>{
        ownerUid: this.tenantProvider.currentUid,
        productUid: transactionItem.productUid.value,
        productVersion: transactionItem.productVersion.value,
        itemConfiguration: transactionItem.configuration.value,
        menuPlacementUid: MenuPlacement.Menu,
        isNew: false,
        isAddOn: false
      }
    ).afterClosed().pipe(
      concatMap(result => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemEdit$ <= itemConfiguratorModalProvider.open`);

        if (result) {
          transactionItem.productUid.next(result.productUid);
          transactionItem.productVersion.next(result.productVersion);
          // transactionItem.guestUid.next(result.guest ? result.guest.uid : null);
          transactionItem.configuration.next(result.configuration);
          transactionItem.eachAmountText.next(result.eachTotal.toFixed(2));

          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemEdit$ => commitItem$ observe`);

          return this.commitItem$(this.editableTransaction, transactionItem).pipe(
            first(),
            tap(_ => {
              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemEdit$ <= commitItem$`);

              EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
              this.lastEntryContext = LastEntryContextEnum.None;
            }),
            finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemEdit$ => commitItem$ finalize`))
          );
        }

        EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
        this.lastEntryContext = LastEntryContextEnum.None;

        return of(true);
      }),
      first(),
      map(_ => true),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemEdit$ => itemConfiguratorModalProvider.open finalize`))
    );
  }

  public lineItemVoid(transactionItem: EditableTransactionGuest | EditableTransactionTypes) {

    if (transactionItem instanceof EditableTransactionItem || transactionItem instanceof EditableTransactionCharge || transactionItem instanceof EditableTransactionChargeAdjustment || transactionItem instanceof EditableTransactionPayment || transactionItem instanceof EditableTransactionItemAdjustment || transactionItem instanceof EditableTransactionAdjustment) {
      this.observableQueueProvider.enqueue(() => {
        return this.handleLineItemVoid(transactionItem).pipe(
          tap(() => this.synchronizeFeatureEnabling()),
          finalize(() => this.publishLogging())
        );
      });
    }
  }

  private handleLineItemVoid(lineItem: EditableTransactionTypes, correlationUid = UUID.UUID()): Observable<boolean> {

    EditableTransactionDataHandler.cancelItemChanges(this.editableTransaction, lineItem);
    EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);

    if (lineItem instanceof EditableTransactionItem) {
      if (this.editableTransaction.lineItems.length == 1) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransaction$ observe`);

        return super.cancelTransaction$(this.editableTransaction, correlationUid).pipe(
          first(),
          tap(editableTransaction => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransaction$`);

            EditableTransactionDataHandler.tryCancelTransaction(editableTransaction);
            this.editableTransaction = null;
          }),
          map(_ => true),
          finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransaction$ finalize`))
        );
      } else {
        let transactionLineItemAdjustment = EditableTransactionDataHandler.getOrCreateTransactionItemAdjustment(this.editableTransaction, lineItem);
        transactionLineItemAdjustment.description = "Void";
        transactionLineItemAdjustment.isVoid.next(true);
        transactionLineItemAdjustment.quantity.next(lineItem.quantity.value);
        transactionLineItemAdjustment.eachAmountText.next(lineItem.eachAmountText.value);

        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => commitItem$ observe`);

        return this.commitItem$(this.editableTransaction, transactionLineItemAdjustment).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ <= commitItem$`)),
          first(),
          map(_ => true),
          finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => commitItem$ finalize`))
        );
      }
    } else if (lineItem instanceof EditableTransactionItemAdjustment) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransactionItemAdjustment$ observe`);

      return super.cancelTransactionItemAdjustment$(this.editableTransaction, lineItem, correlationUid).pipe(
        tap(transactionItemAdjustment => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ <= cancelTransactionItemAdjustment$`);

          EditableTransactionDataHandler.removeItem(this.editableTransaction, transactionItemAdjustment);
        }),
        map(_ => true),
        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransactionItemAdjustment$ finalize`))
      );
    } else if (lineItem instanceof EditableTransactionCharge) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransactionCharge$ observe`);

      return super.cancelTransactionCharge$(this.editableTransaction, lineItem, correlationUid).pipe(
        tap(transactionCharge => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ <= cancelTransactionCharge$`);

          EditableTransactionDataHandler.removeItem(this.editableTransaction, transactionCharge);
        }),
        map(() => true),
        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransactionCharge$ finalize`))
      );
    } else if (lineItem instanceof EditableTransactionChargeAdjustment) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransactionChargeAdjustment$ observe`);

      return super.cancelTransactionChargeAdjustment$(this.editableTransaction, lineItem, correlationUid).pipe(
        tap(transactionChargeAdjustment => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ <= cancelTransactionChargeAdjustment$`);

          EditableTransactionDataHandler.removeItem(this.editableTransaction, transactionChargeAdjustment);
        }),
        map(_ => true),
        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransactionChargeAdjustment$ finalize`))
      );
    } else if (lineItem instanceof EditableTransactionAdjustment) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransactionAdjustment$ observe`);

      return super.cancelTransactionAdjustment$(this.editableTransaction, lineItem, correlationUid).pipe(
        tap(transactionAdjustment => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ <= cancelTransactionAdjustment$`);

          EditableTransactionDataHandler.removeItem(this.editableTransaction, transactionAdjustment);
        }),
        map(_ => true),
        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransactionAdjustment$ finalize`))
      );
    } else if (lineItem instanceof EditableTransactionPayment) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransactionPayment$ observe`);

      return super.cancelTransactionPayment$(this.editableTransaction, lineItem, correlationUid).pipe(
        concatMap(payment => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ <= cancelTransactionPayment$`);

          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => drawerService.disburseDebit observe`);

          return this.drawerService.disburseDebit(<DisburseDebitInput>{
            drawerUid: this.drawerProvider.activeDrawer.uid,
            debitUid: UUID.UUID(),
            paymentMethodUid: payment.paymentMethodUid.value,
            amount: payment.getAmount(),
            description: null,
            referenceUid: this.editableTransaction.uid,
            metadata: [{ key: 'transactionUid', value: this.editableTransaction.uid }, { key: 'paymentUid', value: payment.uid }]
          }).pipe(
            tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ <= drawerService.disburseDebit`)),
            map(_ => payment),
            finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => drawerService.disburseDebit finalize`))
          );
        }),
        first(),
        tap(payment => EditableTransactionDataHandler.removeItem(this.editableTransaction, payment)),
        map(_ => true),
        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemVoid$ => cancelTransactionPayment$ finalize`))
      );
    }

    return of(false);
  }

  public lineItemComp(lineItem: EditableTransactionItem | EditableTransactionCharge): Observable<boolean> {

    if (lineItem instanceof EditableTransactionItem || lineItem instanceof EditableTransactionCharge) {
      this.observableQueueProvider.enqueue(() => {
        return this.handleLineItemComp(lineItem).pipe(
          tap(() => this.synchronizeFeatureEnabling()),
          finalize(() => this.publishLogging())
        );
      });
    }

    return null;
  }

  private handleLineItemComp(lineItem: EditableTransactionItem | EditableTransactionCharge, correlationUid = UUID.UUID()): Observable<boolean> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemComp$ => demandTransaction$ observe`);

    return this.demandTransaction$(this.editableTransaction).pipe(
      concatMap(() => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemComp$ <= demandTransaction$`);

        return this.commitItem$(this.editableTransaction, this.editableTransaction.selectedLineItem.value);
      }),
      first(),
      tap(() => EditableTransactionDataHandler.cancelItemChanges(this.editableTransaction, this.editableTransaction.selectedLineItem.value)), // Cancel any that are invalid
      tap(() => EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null)), // Set selected line item to null
      tap(() => {
        this.remoteLoggingProvider.log('PosRegisterComponent::lineItemVoided adding adjustment');

        if (lineItem instanceof EditableTransactionItem) {
          let transactionItemAdjustment = EditableTransactionDataHandler.getOrCreateTransactionItemAdjustment(this.editableTransaction, lineItem);
          transactionItemAdjustment.description = "Comp";
          transactionItemAdjustment.quantity.next(lineItem.quantity.value);

          EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, transactionItemAdjustment);
        } else if (lineItem instanceof EditableTransactionCharge) {
          let transactionChargeAdjustment = EditableTransactionDataHandler.getOrCreateTransactionChargeAdjustment(this.editableTransaction, lineItem);
          transactionChargeAdjustment.description = "Comp";
          transactionChargeAdjustment.quantity.next(lineItem.quantity.value);

          EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, transactionChargeAdjustment);
        }
      }),
      map(_ => true),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemComp$ => demandTransaction$ finalize`))
    );
  }

  public lineItemChangeType(transactionItem: EditableTransactionPayment): Observable<boolean> {

    if (transactionItem instanceof EditableTransactionPayment) {
      this.observableQueueProvider.enqueue(() => {
        return this.handleLineItemChangeType(transactionItem).pipe(
          tap(() => this.synchronizeFeatureEnabling()),
          finalize(() => this.publishLogging())
        );
      });
    }

    return null;
  }

  private handleLineItemChangeType(lineItem: EditableTransactionPayment, correlationUid = UUID.UUID()): Observable<boolean> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemChangeType => paymentModalProvider.open observe`);

    return this.paymentModalProvider.open(<PaymentModalData>{
      title: 'Payment',
      amount: lineItem.getAmount(),
      paymentMethodUid: lineItem.paymentMethodUid.value,
      paymentMethods: [
        <PaymentMethod>{
          uid: PaymentMethodKeys.Cash,
          name: 'Cash'
        },
        <PaymentMethod>{
          uid: PaymentMethodKeys.Card,
          name: 'Card'
        }
      ]
    }).afterClosed().pipe(
      concatMap(result => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemChangeType <= paymentModalProvider.open`);

        if (result) {
          if (result.amount != lineItem.getAmount() || !isEqualUUID(result.paymentMethod.uid, lineItem.paymentMethodUid.value)) {
            lineItem.amountText.next(result.amount.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
            lineItem.paymentMethodUid.next(result.paymentMethod.uid);

            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemChangeType => commitItem$ observe`);

            return this.commitItem$(this.editableTransaction, lineItem).pipe(
              tap(_ => {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemChangeType <= commitItem$`);

                EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);
                this.lastEntryContext = LastEntryContextEnum.None;
              }),
              finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemChangeType => commitItem$ finalize`))
            );
          }
        }

        return of(null);
      }),
      first(),
      map(_ => {
        EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);

        return true;
      }),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleLineItemChangeType => paymentModalProvider.open finalize`))
    );
  }

  public lineItemCancel(transactionItem: EditableTransactionGuest | EditableTransactionTypes) {

    if (transactionItem instanceof EditableTransactionItem || transactionItem instanceof EditableTransactionAdjustment || transactionItem instanceof EditableTransactionItemAdjustment || transactionItem instanceof EditableTransactionCharge || transactionItem instanceof EditableTransactionPayment || transactionItem instanceof EditableTransactionChargeAdjustment) {
      this.observableQueueProvider.enqueue(() => {
        this.handleLineItemCancel(transactionItem);
        this.synchronizeFeatureEnabling();
        this.updateTimeoutProvider();

        return of(true).pipe(
          finalize(() => this.publishLogging())
        );
      });
    }
  }

  protected handleLineItemCancel(lineItem: EditableTransactionItem | EditableTransactionAdjustment | EditableTransactionItemAdjustment | EditableTransactionCharge | EditableTransactionChargeAdjustment | EditableTransactionPayment, correlationUid = UUID.UUID()) {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | cancelling lineItemChanges and selectedLineItem to null`);

    EditableTransactionDataHandler.cancelItemChanges(this.editableTransaction, lineItem);
    EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);

    if (this.editableTransaction.uid == null && this.editableTransaction.lineItems.length == 0 && this.editableTransaction.payments.length == 0 && this.editableTransaction.adjustments.length == 0) {
      // Cancel the transaction
      EditableTransactionDataHandler.tryCancelTransaction(this.editableTransaction);
      this.editableTransaction = null;
    }
  }

  public parkCheck(correlationUid = UUID.UUID()) {

    this.observableQueueProvider.enqueue(() => {
      return this.parkCheck$(correlationUid).pipe(
        finalize(() => this.publishLogging())
      )
    });
  }

  protected parkCheck$(correlationUid: string): Observable<boolean> {

    return of(this.timeoutProvider.stop()).pipe(
      concatMap(_ => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ => commitOrCancelTransactionState$ observe`);

        return this.commitOrCancelTransactionState$(correlationUid).pipe(
          first(),
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ <= commitOrCancelTransactionState$`)),
          finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ => commitOrCancelTransactionState$ finalize`))
        );
      }),
      concatMap(editableTransaction => {
        if (editableTransaction.notes.value) {
          return of(editableTransaction);
        } else {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ => getTextEntryModalResult$ observe`);

          return this.getTextEntryModalResult$('Guest Name, Location, or Swipe Card', null, correlationUid).pipe(
            concatMap(textEntryModalResult => {
              if (textEntryModalResult && !isCaseInsensitiveEqual(editableTransaction.notes.value, textEntryModalResult.textEntry)) {
                this.editableTransaction.notes.next(textEntryModalResult.textEntry);

                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ => updateNotes$ observe`);

                return super.updateNotes$(editableTransaction, correlationUid).pipe(
                  first(),
                  tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ <= updateNotes$`)),
                  map(_ => textEntryModalResult),
                  finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ => updateNotes$ finalize`))
                );
              } else {
                return of(textEntryModalResult);
              }
            }),
            concatMap(textEntryModalResult => {
              if (textEntryModalResult?.cardSwipe) {
                editableTransaction.holdCardReference.next(textEntryModalResult.cardSwipe.lastFour);

                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ => updateHoldCardReference$ observe`);

                return super.updateHoldCardReference$(editableTransaction, correlationUid).pipe(
                  first(),
                  tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ <= updateHoldCardReference$`)),
                  finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ => updateHoldCardReference$ finalize`))
                );
              } else {
                return of(editableTransaction);
              }
            }),
            map(_ => editableTransaction),
            finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ => getTextEntryModalResult$ finalize`))
          );
        }
      }),
      concatMap(editableTransaction => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ => unlockTransaction$ observe`);

        return super.removeViewer$(editableTransaction, this.terminalUid, false, correlationUid).pipe(
          first(),
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ <= unlockTransaction$`)),
          finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | parkCheck$ => unlockTransaction$ finalize`))
        );
      }),
      tap(_ => {
        this.editableTransaction = null;
        this.synchronizeFeatureEnabling();

        this.navigateToTransaction(null);
      }),
      map(_ => true)
    );
  }

  private getTextEntryModalResult$(title: string, initialValue?: string, correlationUid = UUID.UUID()): Observable<TextEntryModalResult> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getTextEntryModalResult$ => settingProvider.getOneByTypeAndOwner$ observe`);

    return this.settingProvider.getOneByTypeAndOwner$<DeviceSettings>('DeviceSettings', this.terminalProvider.active.uid).pipe(
      first(),
      concatMap(deviceSettings => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getTextEntryModalResult$ <= settingProvider.getOneByTypeAndOwner$`);

        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getTextEntryModalResult$ => textEntryModalProvider.open observe`);

        return this.textEntryModalProvider.open(
          {
            title: title,
            initialValue: initialValue,
            cardSwipeEvent$: this.systemMessageProvider.systemEvents$.pipe(
              filter(event => isCaseInsensitiveEqual(event.type, 'msr-card-swipe-event')),
              map(event => <CardSwipe>JSON.parse(event.payload)),
              filter(cardSwipeEvent => !!cardSwipeEvent),
            ),
            showDevCardSwipe: this.oltpServiceSettings.showDevFeatures
          },
          deviceSettings.textEntryStyle
        ).afterClosed().pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getTextEntryModalResult$ <= textEntryModalProvider.open`)),
          finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getTextEntryModalResult$ => textEntryModalProvider.open finalize`))
        );
      }),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getTextEntryModalResult$ => settingProvider.getOneByTypeAndOwner$ finalize`))
    );
  }

  public showUpdateNotes(correlationUid = UUID.UUID()): Observable<boolean> {

    if (this.editableTransaction.canEditNotes) {
      this.observableQueueProvider.enqueue(() => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showUpdateNotes => commitOrCancelTransactionState$ observe`);

        return this.commitOrCancelTransactionState$().pipe(
          tap(_ => this.timeoutProvider.stop()),
          concatMap(editableTransaction => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showUpdateNotes <= commitOrCancelTransactionState$`);

            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showUpdateNotes => getTextEntryModalResult$ observe`);

            return this.getTextEntryModalResult$('Guest Name, Location, or Swipe Card', editableTransaction.notes.value).pipe(
              tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showUpdateNotes <= getTextEntryModalResult$`)),
              map(value => <[EditableTransaction, TextEntryModalResult]>[editableTransaction, value]),
              finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showUpdateNotes => getTextEntryModalResult$ finalize`))
            );
          }),
          first(),
          concatMap(([editableTransaction, value]) => {
            if (value) {
              editableTransaction.notes.next(value.textEntry);

              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showUpdateNotes => updateNotes$ observe`);

              return super.updateNotes$(editableTransaction, correlationUid).pipe(
                tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showUpdateNotes <= updateNotes$`)),
                map(_ => true),
                finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showUpdateNotes => updateNotes$ finalize`))
              );
            }

            this.synchronizeFeatureEnabling();

            return of(true);
          }),
          first(),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showUpdateNotes => commitOrCancelTransactionState$ finalize`);
            this.publishLogging();
          }),
          finalize(() => this.publishLogging())
        )
      });
    }

    return of(false);
  }

  protected updateNotes(notes: string, correlationUid = UUID.UUID()): Observable<EditableTransaction> {

    this.editableTransaction.notes.next(notes);

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateNotes => updateNotes$ observe`);

    return super.updateNotes$(this.editableTransaction, correlationUid);
  }

  public updateLogistics(correlationUid = UUID.UUID()) {

    if (this.editableTransaction.canEditLocation) {
      this.observableQueueProvider.enqueue(() => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogistics => commitOrCancelTransactionState$ observe`);

        return this.commitOrCancelTransactionState$().pipe(
          tap(_ => this.timeoutProvider.stop()),
          concatMap(_ => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogistics <= commitOrCancelTransactionState$`);

            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogistics => getLogisticTypes$ observe`);

            return this.getLogisticTypes$().pipe(
              concatMap(logisticTypes => {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogistics <= getLogisticTypes$`);

                return this.logisticTypeModalProvider.open(
                  <LogisticTypeModalData>{
                    title: 'Transaction Logistics',
                    options: logisticTypes,
                    displayFunc: (item: any) => item.display
                  }).afterClosed().pipe(
                    tap(_ => this.timeoutProvider.restart()),
                    concatMap(value => {
                      return value ? this.updateLogisticType(value.uid) : of(true);
                    }),
                    first(),
                    tap(_ => this.synchronizeFeatureEnabling())
                  );

              }),
              first(),
              finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogistics => getLogisticTypes$ finalize`))
            )
          }),
          first(),
          map(_ => true),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogistics => commitOrCancelTransactionState$ finalize`);
            this.publishLogging();
          })
        );
      });
    }
  }

  public updateLogisticType(logisticTypeUid: string, correlationUid = UUID.UUID()): Observable<boolean> {

    this.editableTransaction.logisticTypeUid = logisticTypeUid;

    const removeDeliveryCharges = (charges: EditableTransactionCharge[]): Observable<boolean> => {
      const deliveryCharges = charges?.filter(x => isEqualUUID(x.chargeTypeUid.value, ChargeTypeKeys.DeliveryCharge));

      if (deliveryCharges?.length == 0) {
        return of(true);
      }

      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => forkJoin observe`);

      return forkJoin(deliveryCharges.map(deliveryCharge => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => handleLineItemVoid$ observe`);

        return this.handleLineItemVoid(deliveryCharge).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType <= handleLineItemVoid$`)),
          finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => handleLineItemVoid$ finalize`))
        );
      })).pipe(
        tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType <= forkJoin`)),
        map(() => true),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => forkJoin finalize`);
          this.publishLogging();
        })
      );
    };

    const removeContainerCharges = (charges: EditableTransactionCharge[]): Observable<boolean> => {
      const containerCharges = charges?.filter(x => isEqualUUID(x.chargeTypeUid.value, ChargeTypeKeys.ContainerCharge));

      if (containerCharges?.length == 0) {
        return of(true);
      }

      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => forkJoin observe`);

      return forkJoin(containerCharges.map(containerCharge => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => handleLineItemVoid$ observe`);

        return this.handleLineItemVoid(containerCharge).pipe(
          tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType <= handleLineItemVoid$`)),
          finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => handleLineItemVoid$ finalize`))
        );
      })).pipe(
        tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType <= forkJoin`)),
        map(() => true),
        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => forkJoin finalize`))
      );
    };

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => updateLogisticType$ observe`);

    return super.updateLogisticType$(this.editableTransaction, correlationUid).pipe(
      concatMap(editableTransaction => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType <= updateLogisticType$`);

        switch (editableTransaction.logisticTypeUid) {
          case LogisticTypeKeys.DineIn: {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => forkJoin observe`);

            return forkJoin([
              removeDeliveryCharges(editableTransaction.charges),
              removeContainerCharges(editableTransaction.charges)
            ]).pipe(
              tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType <= forkJoin`)),
              map(() => {
                return true;
              }),
              finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => forkJoin finalize`))
            );
          }
          case LogisticTypeKeys.CarryOut: {
            return removeDeliveryCharges(editableTransaction.charges);
          }
          case LogisticTypeKeys.CurbsidePickup: {
            return removeDeliveryCharges(editableTransaction.charges);
          }
          case LogisticTypeKeys.Delivery: {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => settingProvider.getOneByTypeAndOwner$ observe`);

            return this.settingProvider.getOneByTypeAndOwner$<LogisticSettings>('LogisticSettings', this.tenantProvider.currentUid).pipe(
              first(),
              concatMap(logisticSettings => {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType <= settingProvider.getOneByTypeAndOwner$`);

                return removeContainerCharges(editableTransaction.charges).pipe(
                  concatMap(() => {
                    let transactionChargeLineItem = EditableTransactionDataHandler.getOrCreateTransactionCharge(editableTransaction);
                    transactionChargeLineItem.chargeTypeUid.next(ChargeTypeKeys.DeliveryCharge);
                    transactionChargeLineItem.description = 'Delivery Charge';
                    transactionChargeLineItem.quantity.next(1);
                    transactionChargeLineItem.eachAmountText.next(logisticSettings.deliveryCharge.toString());

                    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => commitItem$ observe`);

                    return this.commitItem$(editableTransaction, transactionChargeLineItem).pipe(
                      tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType <= commitItem$`)),
                      map(() => true),
                      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => commitItem$ finalize`))
                    );
                  }),
                  first()
                )
              }),
              first(),
              finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => settingProvider.getOneByTypeAndOwner$ finalize`))
            )
          }
        }

        return of(true);
      }),
      first(),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | updateLogisticType => updateLogisticType$ finalize`))
    );
  }

  public showScheduleDescription(schedule: Schedule) {

    this.messageModalProvider.open({
      message: schedule.description
    });
  }

  public showGuestServies(correlationUid = UUID.UUID()) {

    this.observableQueueProvider.enqueue(() => {
      this.timeoutProvider.stop();

      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showScheduleDescription => guestServiceModalProvider.open observe`);

      this.guestServiceModalProvider.open({
        departmentUid: null,
        title: 'Guest Services'
      }).afterClosed().pipe(
        tap(_ => this.timeoutProvider.restart())
      ).subscribe(() => {
      });

      return of(true);
    });
  }

  public showOpenTransactions(correlationUid = UUID.UUID()) {

    this.observableQueueProvider.enqueue(() => {
      this.timeoutProvider.stop();

      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showOpenTransactions => transactionSelectModalProvider.open observe`);

      this.transactionSelectModalProvider.open().afterClosed().pipe(
        tap(_ => this.timeoutProvider.restart())
      ).subscribe({
        next: (transaction) => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showOpenTransactions <= transactionSelectModalProvider.open`);

          if (transaction) {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showOpenTransactions | selected ${transaction.uid}`);

            this.navigateToTransaction(transaction.uid);
          }
        },
        complete: () => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showOpenTransactions => transactionSelectModalProvider.open complete`);
          this.publishLogging();
        }
      })

      return of(true);
    });
  }

  public showRecentClosedTransactions(correlationUid = UUID.UUID()) {

    this.observableQueueProvider.enqueue(() => {

      this.timeoutProvider.stop();

      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showOpenTransactions => settingProvider.getOneByTypeAndOwner$ observe`);

      return this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid).pipe(
        concatMap(transactionSetting => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showOpenTransactions <= settingProvider.getOneByTypeAndOwner$`);

          var afterDateTime = subtractHourMinuteSecond(new Date(), transactionSetting.recentTransactionHourMinute);

          var closedDateRangeFilter = <DateRangeFilterInput>{
            afterDateTimeUtc: afterDateTime.toISOString(),
            beforeDateTimeUtc: new Date().toISOString()
          };

          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showOpenTransactions => transactionService.search$ observe`);

          return this.transactionProvider.search$(
            null,
            closedDateRangeFilter,
            [TransactionStatusEnum.Closed],
            <PaginationInput>{ sortField: 'closeDateTimeUtc', sortStrategy: 'desc' }
          ).pipe(
            concatMap(transactionsPage => {
              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showOpenTransactions <= transactionService.search$`);

              return this.transactionViewModalProvider.open({
                transactionUids: transactionsPage.edges.map(x => x.node.uid),
                commands: [
                  {
                    'display': 'Print Receipt',
                    action: (editableTransaction) => {
                      this.observableQueueProvider.enqueue(() =>
                        this.printReceipt$(editableTransaction.uid)
                      );
                    },
                    closeOnAction: false
                  },
                  {
                    'display': 'Print Kitchen Slip',
                    action: (editableTransaction) => {
                      this.observableQueueProvider.enqueue(() =>
                        this.registerFeatureProvider.printDepartmentSlip$(
                          editableTransaction,
                          RegisterComponent.DEPARTMENT_KITCHEN,
                          this.printDepartmentSlipModalProvider,
                          this.transactionItemPrintModalProvider,
                          this.messageModalProvider,
                          this.timeoutProvider,
                          this.systemMessageProvider,
                          this.settingProvider,
                          this.terminalProvider
                        )
                      );
                    },
                    closeOnAction: false
                  },
                  {
                    'display': 'Reopen',
                    action: (editableTransaction) => {
                      this.transactionProvider.reopen$(editableTransaction.uid, this.terminalUid).subscribe(transaction => {
                        this.navigateToTransaction(transaction.uid);
                      });
                    },
                    closeOnAction: true
                  },
                ]
              }).afterClosed();
            }),
            tap(_ => this.timeoutProvider.restart()),
            finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showOpenTransactions => transactionService.search$ finalize`))
          );
        }),
        map(_ => {
          return true;
        }),
        finalize(() => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | showOpenTransactions => settingProvider.getOneByTypeAndOwner$ finalize`);
          this.publishLogging();
        })
      );
    });
  }

  public openCashDrawer() {

    this.observableQueueProvider.enqueue(() => {
      return timeoutEnclosure(this.timeoutProvider, this.registerFeatureProvider.openCashDrawer$(this.systemMessageProvider))
    });
  }

  protected getLogisticTypes$(correlationUid = UUID.UUID()): Observable<LogisticTypeModel[]> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getLogisticTypes => settingProvider.getOneByTypeAndOwner$ observe`);

    return this.settingProvider.getOneByTypeAndOwner$<LogisticSettings>('LogisticSettings', this.tenantProvider.currentUid).pipe(
      map(logisticSettings => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getLogisticTypes <= settingProvider.getOneByTypeAndOwner$`);

        const logisticTypes = <LogisticTypeModel[]>[];

        if (logisticSettings.dineIn) {
          logisticTypes.push(<LogisticTypeModel>{
            uid: LogisticTypeKeys.DineIn,
            display: 'Dine In'
          });
        }
        if (logisticSettings.carryOut) {
          logisticTypes.push(<LogisticTypeModel>{
            uid: LogisticTypeKeys.CarryOut,
            display: 'Carry Out'
          });
        }
        if (logisticSettings.curbsidePickup) {
          logisticTypes.push(<LogisticTypeModel>{
            uid: LogisticTypeKeys.CurbsidePickup,
            display: 'Curbside Pickup'
          });
        }
        if (logisticSettings.delivery) {
          logisticTypes.push(<LogisticTypeModel>{
            uid: LogisticTypeKeys.Delivery,
            display: 'Delivery'
          });
        }

        return logisticTypes;
      }),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | getLogisticTypes => settingProvider.getOneByTypeAndOwner$ finalize`))
    );
  }

  public addTransactionCharge(): Observable<boolean> {

    this.observableQueueProvider.enqueue(() => {
      return this.handleAddTransactionCharge$().pipe(
        tap(() => this.synchronizeFeatureEnabling()),
        tap(_ => this.updateTimeoutProvider()),
        finalize(() => this.publishLogging())
      );
    });

    return null;
  }

  protected handleAddTransactionCharge$(correlationUid = UUID.UUID()): Observable<boolean> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddTransactionCharge$ => demandTransaction$ observe`);

    return this.demandTransaction$(this.editableTransaction).pipe(
      concatMap(() => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddTransactionCharge$ <= demandTransaction$`);

        return this.commitItem$(this.editableTransaction, this.editableTransaction.selectedLineItem.value);
      }),
      first(),
      tap(() => {
        this.remoteLoggingProvider.log('PosRegisterComponent::addTransactionCharge adding transaction charge');

        EditableTransactionDataHandler.cancelItemChanges(this.editableTransaction, this.editableTransaction.selectedLineItem.value);
        EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);

        let transactionChargeLineItem = EditableTransactionDataHandler.getOrCreateTransactionCharge(this.editableTransaction);
        transactionChargeLineItem.chargeTypeUid.next(ChargeTypeKeys.MiscellaneousCharge);
        transactionChargeLineItem.description = 'Miscellaneous Charge';

        EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, transactionChargeLineItem);
      }),
      map(_ => true),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddTransactionCharge$ => demandTransaction$ finalize`))
    );
  }

  public addTransactionAdjustment() {

    this.observableQueueProvider.enqueue(() => {
      return this.handleAddTransactionAdjustment$().pipe(
        tap(() => this.synchronizeFeatureEnabling()),
        tap(_ => this.updateTimeoutProvider()),
        finalize(() => this.publishLogging())
      );
    });
  }

  protected handleAddTransactionAdjustment$(correlationUid = UUID.UUID()): Observable<boolean> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddTransactionAdjustment$ => demandTransaction$ observe`);

    return this.demandTransaction$(this.editableTransaction).pipe(
      concatMap(() => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddTransactionAdjustment$ <= demandTransaction$`);

        return this.commitItem$(this.editableTransaction, this.editableTransaction.selectedLineItem.value);
      }),
      first(),
      tap(() => {
        this.remoteLoggingProvider.log('PosRegisterComponent::addTransactionAdjustment adding transaction adjustment');

        EditableTransactionDataHandler.cancelItemChanges(this.editableTransaction, this.editableTransaction.selectedLineItem.value);
        EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);

        let transactionAdjustmentLineItem = EditableTransactionDataHandler.getOrCreateTransactionAdjustment(this.editableTransaction);
        transactionAdjustmentLineItem.description.next('Comp');

        EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, transactionAdjustmentLineItem);
      }),
      map(_ => true),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddTransactionAdjustment$ => demandTransaction$ finalize`))
    );
  }

  public addCashPayment() {

    this.observableQueueProvider.enqueue(() => {
      return this.handleAddCashPayment$().pipe(
        tap(() => this.synchronizeFeatureEnabling()),
        tap(_ => this.updateTimeoutProvider()),
        finalize(() => this.publishLogging())
      );
    });
  }

  protected handleAddCashPayment$(correlationUid = UUID.UUID()): Observable<boolean> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddCashPayment$ => commitOrCancelTransactionState$ observe`);

    return this.commitOrCancelTransactionState$().pipe(
      tap(() => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddCashPayment$ <= commitOrCancelTransactionState$`);

        if (!isEqualUUID(this.editableTransaction.transactionStatus.value, TransactionStatusEnum.Closed)) {
          EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);

          let paymentLineItem = EditableTransactionDataHandler.getOrCreatePayment(this.editableTransaction);
          paymentLineItem.paymentMethodUid.next(PaymentMethodKeys.Cash);

          EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, paymentLineItem);
        }
      }),
      map(() => true),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddCashPayment$ => commitOrCancelTransactionState$ finalize`))
    );
  }

  public addCardPayment() {

    this.observableQueueProvider.enqueue(() => {
      return this.handleAddCardPayment$().pipe(
        tap(() => this.synchronizeFeatureEnabling()),
        finalize(() => this.publishLogging())
      );
    });
  }

  protected handleAddCardPayment$(correlationUid = UUID.UUID()): Observable<boolean> {

    this.editableTransaction = EditableTransactionDataHandler.getOrCreateTransaction(this.editableTransaction, this.terminalUid);

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddCardPayment$ => demandTransaction$ observe`);

    return this.demandTransaction$(this.editableTransaction).pipe(
      concatMap(editableTransaction => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddCardPayment$ <= demandTransaction$`);

        return this.commitItem$(editableTransaction, editableTransaction.selectedLineItem.value);
      }),
      first(),
      tap(() => {
        EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, null);

        // if (this.settingsProvider.enableCardProcessor.value) {
        //   let dialogRef = this.dialog.open(CardSwipeModalComponent, {
        //     width: '40vw',
        //     disableClose: true,
        //     closeOnNavigation: true,
        //     data: {
        //       title: 'Swipe Card and enter amount',
        //       mode: CardSwipeMode.CaptureToken,
        //       initialValue: 0.00,
        //       allowEdit: true
        //     }
        //   });

        //   dialogRef.afterClosed().subscribe((captureTokenEvent: CaptureTokenEventModel) => {
        //     if (captureTokenEvent) {
        //       let amount = captureTokenEvent.amount;

        //       let notes = `${captureTokenEvent.captureToken.firstName} ${captureTokenEvent.captureToken.middleInitial}`.trim();
        //       transaction.notes = `${notes} ${captureTokenEvent.captureToken.surname}`.trim();

        //       this.processQueueProvider.enqueue([
        //         () => this.transactionService.updateNotes(transaction.uid, transaction.notes)
        //       ]);

        //       let cardTransactionUid = UUID.UUID();

        //       let paymentLineItem = this.getOrCreatePayment(transaction, PaymentMethodKeys.UID_CreditCard, cardTransactionUid);
        //       paymentLineItem.amountText.next(amount.toString());
        //       paymentLineItem.metadata = [...(paymentLineItem.metadata || []), ...[{ key: "drawerUid", value: this.drawerProvider.activeDrawer.uid }]];
        //       paymentLineItem.commit();

        //       // Create a CardTransaction
        //       this.cardTransactionService.create(cardTransactionUid, transaction.uid, paymentLineItem.uid, amount, null, captureTokenEvent.captureToken.firstName, captureTokenEvent.captureToken.middleInitial, captureTokenEvent.captureToken.surname, captureTokenEvent.captureToken.lastFour, captureTokenEvent.captureToken.token, captureTokenEvent.captureToken.encryption, this.stateProvider.authorityEmployee.value.uid).subscribe(cardTransaction => {
        //         if (this.settingsProvider.autoPrintCreditCardReceipt.value) {
        //           this.printCreditCardReceipt();
        //         }

        //         if (cardTransaction && this.settingsProvider.preAuthorizeCardTransactions.value) {
        //           // Authorize with the processor
        //           this.msrService.authorizeTransaction(captureTokenEvent.captureToken.encryption, cardTransaction.amount, cardTransaction.tip, this.settingsProvider.preAuthorizeAdditionalPercent.value).subscribe(authorizeResult => {
        //             if (authorizeResult && authorizeResult.success) {

        //               this.closeSale();

        //               // If the processor authorizes it, create a payment and set the CardTransaction to 'authorized'
        //               this.processQueueProvider.enqueue(() => this.cardTransactionService.authorize(cardTransactionUid, authorizeResult.processorReferenceId, authorizeResult.totalAmount, this.stateProvider.authorityEmployee.value.uid));
        //             } else {
        //               this.processQueueProvider.enqueue(() => this.cardTransactionService.failAuthorize(cardTransactionUid, this.stateProvider.authorityEmployee.value.uid));
        //               this.showMessage('Card Processing Error', authorizeResult.messages.join(', '));
        //             }
        //           });
        //         }
        //       });
        //     }
        //   });
        //   asyncOperationResult.next(true);
        // } else {
        let paymentLineItem = EditableTransactionDataHandler.getOrCreatePayment(this.editableTransaction);
        paymentLineItem.paymentMethodUid.next(PaymentMethodKeys.Card);

        EditableTransactionDataHandler.setSelectedLine(this.editableTransaction, paymentLineItem);
        // }
      }),
      tap(_ => this.updateTimeoutProvider()),
      map(_ => true),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleAddCardPayment$ => demandTransaction$ finalize`))
    );
  }

  public settleTransactionCash() {

    const correlationUid = UUID.UUID();
    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCash`);

    this.observableQueueProvider.enqueue(() => {
      return timeoutEnclosure(
        this.timeoutProvider,
        this.handleSettleTransactionCash$(correlationUid)
      ).pipe(
        finalize(() => this.publishLogging())
      )
    });
  }

  protected handleSettleTransactionCash$(correlationUid = UUID.UUID()): Observable<boolean> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleSettleTransactionCash$`);

    return of(this.timeoutProvider.stop()).pipe(
      concatMap(_ => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleSettleTransactionCash$ => commitOrCancelTransactionState$ observe`);

        return this.commitOrCancelTransactionState$(correlationUid).pipe(
          tap(editableTransaction => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | handleSettleTransactionCash$ <= commitOrCancelTransactionState$`);

            EditableTransactionDataHandler.setSelectedLine(editableTransaction, null);
          }),
          concatMap(editableTransaction => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | handleSettleTransactionCash$ => demandTransaction$ observe`);

            return this.demandTransaction$(editableTransaction, correlationUid).pipe(
              finalize(() => {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleSettleTransactionCash$ => demandTransaction$ finalize`);
              })
            );
          }),
          first(),
          concatMap(editableTransaction => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ <= demandTransaction$`);

            if (editableTransaction) {
              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ | Settling transaction`);

              var amountDue = editableTransaction.totalDue.value;
              if (amountDue > 0) {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ | Amount due ${amountDue} > 0. Creating and committing payment.`);

                let paymentLineItem = EditableTransactionDataHandler.getOrCreatePayment(editableTransaction);
                paymentLineItem.paymentMethodUid.next(PaymentMethodKeys.Cash);
                paymentLineItem.amountText.next(new Decimal(amountDue).toFixed(2));
                paymentLineItem.referenceUid.next(this.drawerProvider.activeDrawer.uid);

                // Commit the payment
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ => commitItem$ observe`);

                return this.commitItem$(editableTransaction, paymentLineItem, correlationUid).pipe(
                  concatMap(() => {
                    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ <= commitItem$`);

                    // close the sale and open the drawer
                    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ => closeTransaction$ observe`);

                    return super.closeTransaction$(editableTransaction, correlationUid).pipe(
                      tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ <= closeTransaction$`)),
                      finalize(() => {
                        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ => closeTransaction$ finalize`);
                      })
                    );
                  }),
                  first(),
                  map(_ => true),
                  finalize(() => {
                    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ => commitItem$ finalize`);
                  })
                );
              } else if (editableTransaction.change.value > 0) {
                // Disburse change
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ | Change due ${editableTransaction.getChange()} > 0`);

                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ => closeTransaction$ observe`);

                return super.closeTransaction$(editableTransaction, correlationUid).pipe(
                  concatMap(editableTransaction => {
                    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ <= closeTransaction$`);

                    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ => drawerService.disburseDebit$ observe`);

                    return this.drawerService.disburseDebit({
                      drawerUid: this.drawerProvider.activeDrawer.uid,
                      debitUid: UUID.UUID(),
                      paymentMethodUid: PaymentMethodKeys.Cash,
                      description: null,
                      referenceUid: editableTransaction.uid,
                      amount: editableTransaction.change.value,
                      metadata: [{ key: 'transactionUid', value: editableTransaction.uid }]
                    }).pipe(
                      tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ <= drawerService.disburseDebit$`)),
                      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ => drawerService.disburseDebit$ finalize`))
                    );
                  }),
                  first(),
                  map(_ => true),
                  finalize(() => {
                    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ => closeTransaction$ finalize`);
                  })
                );
              } else {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction.uid} | handleSettleTransactionCash$ | Total due == 0`);

                return super.closeTransaction$(editableTransaction, correlationUid).pipe(
                  map(_ => true)
                );
              }
            }

            return of(true);
          }),
          first(),
          concatMap(_ => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${this.editableTransaction.uid} | handleSettleTransactionCash$ => handleOpenCashDrawer$ observe`);

            return this.handleOpenCashDrawer$(correlationUid).pipe(
              tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${this.editableTransaction.uid} | handleSettleTransactionCash$ <= handleOpenCashDrawer$`)),
              finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${this.editableTransaction.uid} | handleSettleTransactionCash$ => handleOpenCashDrawer$ finalize`))
            );
          }),
          first(),
          tap(_ => this.synchronizeFeatureEnabling()),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleSettleTransactionCash$ => commitOrCancelTransactionState$ finalize`);
          })
        );
      }),
      first(),
      tap(_ => this.updateTimeoutProvider())
    );
  }

  public handleOpenCashDrawer$(correlationUid: string): Observable<boolean> {

    if (this.drawerProvider.activeDrawer) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleOpenCashDrawer$ => registerFeatureProvider.openCashDrawer$ observe`);

      return this.registerFeatureProvider.openCashDrawer$(this.systemMessageProvider).pipe(
        tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleOpenCashDrawer$ <= registerFeatureProvider.openCashDrawer$`)),
        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | handleOpenCashDrawer$ => registerFeatureProvider.openCashDrawer$ finalize`))
      );
    }

    return of(true);
  }

  public settleTransactionCard(correlationUid = UUID.UUID()): Observable<boolean> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$`);

    if (this.editableTransaction != null) {
      this.observableQueueProvider.enqueue(() => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => commitOrCancelTransactionState$ observe`);

        return this.commitOrCancelTransactionState$().pipe(
          tap(_ => this.timeoutProvider.stop()),
          concatMap(editableTransaction => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= commitOrCancelTransactionState$`);

            var amountDue = editableTransaction.totalDue.value;
            if (amountDue > 0) {
              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => settingProvider.getOneByTypeAndOwner$ observe`);

              return this.settingProvider.getOneByTypeAndOwner$<PosSettings>('PosSettings', this.tenantProvider.currentUid).pipe(
                concatMap(posSettings => {
                  this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= settingProvider.getOneByTypeAndOwner$`);

                  if (posSettings.enableCreditCardProcessor) {
                    this.remoteLoggingProvider.log(`settleOrderCreditCard: cardProcessor enabled`);

                    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => cardEntryModalProvider.open observe`);

                    return this.cardEntryModalProvider.open({
                      title: 'Swipe Card',
                      mode: CardSwipeMode.CaptureToken,
                      initialValue: amountDue,
                      allowEdit: false
                    }).afterClosed().pipe(
                      tap((_: any) => this.timeoutProvider.restart()),
                      tap((captureToken: CaptureTokenEventModel) => {
                        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= cardEntryModalProvider.open`);

                        if (captureToken) {
                          let amount = captureToken.amount;

                          let notes = `${captureToken.captureToken.firstName} ${captureToken.captureToken.middleInitial}`.trim();
                          editableTransaction.notes.next(`${notes} ${captureToken.captureToken.surname}`.trim());

                          //   this.processQueueProvider.add([
                          //     () => this.transactionService.updateNotes(transaction.uid, transaction.notes)
                          //   ]);

                          //   let cardTransactionUid = UUID.UUID();

                          //   let paymentLineItem = this.getOrCreatePayment(transaction, PaymentMethodKeys.UID_CreditCard, cardTransactionUid);
                          //   paymentLineItem.amountText.next(amount.toString());
                          //   paymentLineItem.commit();

                          //   // Create a CardTransaction
                          //   this.cardTransactionService.create(cardTransactionUid, transaction.uid, paymentLineItem.uid, amount, null, captureToken.captureToken.firstName, captureToken.captureToken.middleInitial, captureToken.captureToken.surname, captureToken.captureToken.lastFour, captureToken.captureToken.token, captureToken.captureToken.encryption, this.stateProvider.authorityEmployee.value.uid).subscribe(cardTransaction => {

                          //     if (this.settingsProvider.autoPrintCreditCardReceipt.value) {
                          //       this.printCreditCardReceipt();
                          //     }

                          //     if (cardTransaction && this.settingsProvider.preAuthorizeCardTransactions.value) {
                          //       let percentOverauth = this.settingsProvider.preAuthorizeAdditionalPercent.value;

                          //       // Authorize with the processor
                          //       this.msrService.authorizeTransaction(captureToken.captureToken.encryption, cardTransaction.amount, cardTransaction.tip, percentOverauth).subscribe(authorizeResult => {
                          //         if (authorizeResult && authorizeResult.success) {

                          //           this.closeSale();

                          //           // If the processor authorizes it, create a payment and set the CardTransaction to 'authorized'
                          //           this.processQueueProvider.add(() => this.cardTransactionService.authorize(cardTransactionUid, authorizeResult.processorReferenceId, authorizeResult.totalAmount, this.stateProvider.authorityEmployee.value.uid));
                          //         } else {
                          //           this.processQueueProvider.add(() => this.cardTransactionService.failAuthorize(cardTransactionUid, this.stateProvider.authorityEmployee.value.uid));
                          //           this.showMessage('Card Processing Error', authorizeResult.messages.join(', '));
                          //         }
                          //       });
                          //     } else {
                          //       this.closeSale();
                          //     }
                          //   });
                        } else {
                          this.messageModalProvider.open({ title: 'Card Processing Error', message: 'Unsuccessful card swipe' });
                        }
                      }),
                      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => cardEntryModalProvider.open finalize`))
                    );
                  } else {
                    this.remoteLoggingProvider.log(`settleOrderCard: cardProcessor not enabled`);

                    this.timeoutProvider.stop();

                    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => cardEntryModalProvider.open observe`);

                    return this.cardEntryModalProvider.open({
                      title: 'Swipe Card',
                      mode: CardSwipeMode.CaptureSwipe,
                      initialValue: amountDue,
                      allowEdit: false
                    }).afterClosed().pipe(
                      tap((_: any) => this.timeoutProvider.restart()),
                      concatMap((cardSwipeEvent: CardSwipeEventModel) => {
                        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= cardEntryModalProvider.open`);

                        if (cardSwipeEvent) {
                          if (cardSwipeEvent && (cardSwipeEvent.status == CardSwipeStatusEnum.Skipped || cardSwipeEvent.status == CardSwipeStatusEnum.Success)) {
                            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => commitOrCancelTransactionState$ observe`);

                            return this.commitOrCancelTransactionState$().pipe(
                              concatMap(editableTransaction => {
                                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= commitOrCancelTransactionState$`);

                                var amountDue = this.editableTransaction.totalDue.value;
                                if (amountDue > 0) {
                                  let paymentLineItem = EditableTransactionDataHandler.getOrCreatePayment(editableTransaction);
                                  paymentLineItem.paymentMethodUid.next(PaymentMethodKeys.Card);
                                  paymentLineItem.amountText.next(amountDue.toString());

                                  this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => commitItem$ observe`);

                                  return this.commitItem$(this.editableTransaction, paymentLineItem).pipe(
                                    concatMap(_ => {
                                      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= commitItem$`);

                                      if (!this.editableTransaction.notes.value) {
                                        if (cardSwipeEvent.event.firstName || cardSwipeEvent.event.surname) {
                                          this.editableTransaction.notes.next(`${cardSwipeEvent.event.firstName} ${cardSwipeEvent.event.surname}`);

                                          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => updateNotes$ observe`);

                                          return super.updateNotes$(this.editableTransaction, correlationUid).pipe(
                                            tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= updateNotes$`)),
                                            finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => updateNotes$ finalize`))
                                          );
                                        }
                                      }

                                      return of(this.editableTransaction);
                                    }),
                                    first(),
                                    concatMap(() => {
                                      this.editableTransaction.holdCardReference.next(cardSwipeEvent.event.lastFour);

                                      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => updateHoldCardReference$ observe`);

                                      return super.updateHoldCardReference$(this.editableTransaction, correlationUid).pipe(
                                        tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= updateHoldCardReference$`)),
                                        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => updateHoldCardReference$ finalize`))
                                      );
                                    }),
                                    first(),
                                    concatMap(() => {
                                      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => closeTransaction$ observe`);

                                      return super.closeTransaction$(this.editableTransaction, correlationUid).pipe(
                                        tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= closeTransaction$`)),
                                        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => closeTransaction$ finalize`))
                                      );
                                    }),
                                    first(),
                                    map(_ => true),
                                    finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => commitItem$ finalize`))
                                  );
                                } else {
                                  this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => closeTransaction$ observe`);

                                  return super.closeTransaction$(this.editableTransaction, correlationUid).pipe(
                                    tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= closeTransaction$`)),
                                    map(_ => true),
                                    finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => closeTransaction$ finalize`))
                                  );
                                }
                              }),
                              first(),
                              finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => commitOrCancelTransactionState$ finalize`))
                            ).pipe(
                              concatMap(_ => {
                                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => getOneByTypeAndOwner$ observe`);

                                return this.settingProvider.getOneByTypeAndOwner$<PosSettings>('PosSettings', this.tenantProvider.currentUid).pipe(
                                  tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= getOneByTypeAndOwner$`)),
                                  map(settings => settings.openCashDrawerOnCardPayment),
                                  finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => getOneByTypeAndOwner$ finalize`))
                                )
                              }),
                              first(),
                              concatMap(openCashDrawerOnCardPayment => {
                                if (openCashDrawerOnCardPayment) {
                                  this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => openCashDrawer$ observe`);

                                  return this.registerFeatureProvider.openCashDrawer$(this.systemMessageProvider).pipe(
                                    first(),
                                    tap(success => {
                                      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= openCashDrawer$`);

                                      if (!success) {
                                        // this.showMessage('Device error', 'Unable to kick open cash drawer');
                                      }
                                    }),
                                    finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => openCashDrawer$ finalize`))
                                  );
                                }

                                return of(true);
                              }),
                              first(),
                              tap(_ => this.synchronizeFeatureEnabling())
                            );
                          } else if (!cardSwipeEvent || cardSwipeEvent.status == CardSwipeStatusEnum.Error) {
                            this.messageModalProvider.open({ title: 'Card Processing Error', message: 'Unsuccessful card swipe' });
                          }
                        } else {
                          this.messageModalProvider.open({ title: 'Card Processing Error', message: 'Unsuccessful card swipe' });
                        }

                        return of(true);
                      }),
                      first(),
                      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => cardEntryModalProvider.open finalize`))
                    );
                  }
                }),
                first(),
                finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => settingProvider.getOneByTypeAndOwner$ finalize`))
              )
            } else {
              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => handleSettleTransactionCash$ observe`);

              return this.handleSettleTransactionCash$().pipe(
                concatMap(_ => {
                  this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= handleSettleTransactionCash$`);

                  this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => handleSettleTransactionCash$ observe`);

                  return this.registerFeatureProvider.openCashDrawer$(this.systemMessageProvider).pipe(
                    tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ <= handleSettleTransactionCash$`)),
                    finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => handleSettleTransactionCash$ finalize`))
                  );
                }),
                tap(_ => {
                  this.timeoutProvider.restart();
                  this.synchronizeFeatureEnabling();
                }),
                finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => handleSettleTransactionCash$ finalize`))
              );
            }
          }),
          first(),
          map(_ => true),
          finalize(() => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | settleTransactionCard$ => commitOrCancelTransactionState$ finalize`);
            this.publishLogging()
          })
        );
      });
    }

    return null;
  }

  public printDepartmentSlip(departmentUid: string) {

    this.observableQueueProvider.enqueue(() => this.printDepartmentSlip$(departmentUid));
  }

  public printDepartmentSlip$(departmentUid: string): Observable<boolean> {

    return timeoutEnclosure(
      this.timeoutProvider,
      this.commitOrCancelTransactionState$().pipe(
        concatMap(editableTransaction => {
          return this.registerFeatureProvider.printDepartmentSlip$(
            editableTransaction,
            departmentUid,
            this.printDepartmentSlipModalProvider,
            this.transactionItemPrintModalProvider,
            this.messageModalProvider,
            this.timeoutProvider,
            this.systemMessageProvider,
            this.settingProvider,
            this.terminalProvider
          );
        }),
        first()
      )
    )
  }

  public printCheck() {

    this.observableQueueProvider.enqueue(() => this.printCheck$(this.editableTransaction.uid));
  }

  protected printCheck$(transactionUid: string): Observable<boolean> {

    return timeoutEnclosure(
      this.timeoutProvider,
      this.commitOrCancelTransactionState$().pipe(
        concatMap(_ => {
          return this.registerFeatureProvider.printCheck$(transactionUid)
        }),
        first()
      )
    )
  }

  public printReceipt() {

    this.observableQueueProvider.enqueue(() => this.printReceipt$(this.editableTransaction.uid));
  }

  protected printReceipt$(transactionUid: string): Observable<boolean> {

    return timeoutEnclosure(
      this.timeoutProvider,
      this.registerFeatureProvider.printReceipt$(transactionUid)
    );
  }

  public printCardReceipt() {

    //   let transaction = this.transaction.value;
    //   if (transaction) {
    //     let cardPayments = transaction.payments.filter(x => x.paymentMethodUid.value == PaymentMethodKeys.UID_CreditCard);

    //     cardPayments.forEach(cardPayment => {
    //       if (cardPayment.referenceUid.value) {
    //         this.cardTransactionService.getByUid(cardPayment.referenceUid.value).subscribe(cardTransaction => {

    //           this.deviceService.printCardReceipt(transaction, cardTransaction);
    //         });
    //       }
    //     });
    //   }
  }

  public printItem(transactionItem: EditableTransactionGuest | EditableTransactionItem) {

    this.observableQueueProvider.enqueue(() => {
      return this.commitOrCancelTransactionState$().pipe(
        concatMap(editableTransaction => {
          if (!editableTransaction) {
            return of(true);
          }

          const printOptions = <PrintDocumentOptions>{
            transactionUid: editableTransaction.uid,
            transactionItemUid: transactionItem.uid,
            departmentUid: '46C1731B-DBEA-4C6E-B822-F56B80A58254',
            style: OutputStyle.Detail,
            deviceSetting: null
          };

          var systemMessage = this.systemMessageProvider.buildMessage(UUID.UUID(), 'print-department', printOptions);

          return this.systemMessageProvider.publishOneWithResponse$<boolean>(systemMessage).pipe(
            tap(_ => this.timeoutProvider.restart()),
            map(success => {
              if (success) {
                this.messageModalProvider.open({ message: 'Successfully sent to the printer at this address.' });
              } else {
                this.messageModalProvider.open({ message: 'There was a problem sending and/or getting a response from the printer at this address.' });
              }

              return true;
            })
          );
        }),
        first(),
        map(() => true)
      );
    });
  }

  public updateGuest(transactionItem: EditableTransactionItem) {

    this.selectGuestModalProvider.open(<SelectGuestModalData>{
      title: 'Guest Options',
      guests: this.editableTransaction.guests,
    }).afterClosed().subscribe(result => {
      if (result) {
        let guest: EditableTransactionGuest = null;
        guest = result.guest ? EditableTransactionDataHandler.getOrCreateGuest(this.editableTransaction, <EditableTransactionGuest>{
          uid: result.guest.uid,
          name: result.guest.name
        }) : null;

        (guest && !guest.uid ? super.commitGuest$(this.editableTransaction, guest) : of(guest)).subscribe(guest => {
          if (guest) {
            transactionItem.guestUid.next(guest.uid);
            this.editableTransaction.lastGuestUid = guest.uid;
          } else {
            transactionItem.guestUid.next(null);
            this.editableTransaction.lastGuestUid = null;
          }

          this.commitItem$(this.editableTransaction, transactionItem).pipe(
            first(),
            tap(_ => this.timeoutProvider.restart())
          ).subscribe(x => {
            this.lastEntryContext = LastEntryContextEnum.None;
          });
        });
      }
    })
  }

  protected commitOrCancelTransactionState$(correlationUid = UUID.UUID()): Observable<EditableTransaction> {

    var editableTransaction = this.editableTransaction || EditableTransactionDataHandler.getOrCreateTransaction(this.editableTransaction, this.terminalUid);

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | commitOrCancelTransactionState$`);

    let selectedLineItem = EditableTransactionDataHandler.getSelectedLine(editableTransaction);
    if (selectedLineItem && !selectedLineItem.isValid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | commitOrCancelTransactionState$ | Selected item not valid, cancelling changes`);

      EditableTransactionDataHandler.cancelItemChanges(this.editableTransaction, selectedLineItem);
    }

    if (editableTransaction.lineItems.length == 0) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | commitOrCancelTransactionState$ | transaction has no items, cancelling transaction`);

      EditableTransactionDataHandler.tryCancelTransaction(editableTransaction);
      if (!editableTransaction.uid) {
        this.editableTransaction = null;
      }
    }

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | commitOrCancelTransactionState$ => demandTransaction$ observe`);

    return this.demandTransaction$(this.editableTransaction, correlationUid).pipe(
      concatMap(transaction => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | commitOrCancelTransactionState$ <= demandTransaction$`);

        if (selectedLineItem && selectedLineItem.isDirty) {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | commitOrCancelTransactionState$ => commitItem$ observe`);

          return this.commitItem$(transaction, selectedLineItem, correlationUid).pipe(
            map(() => {
              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | commitOrCancelTransactionState$ <= commitItem$`);

              return transaction;
            }),
            finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | commitOrCancelTransactionState$ => commitItem$ finalize`))
          );
        }

        return of(transaction);
      }),
      first(),
      tap(transaction => EditableTransactionDataHandler.setSelectedLine(transaction, null)),
      finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | commitOrCancelTransactionState$ => demandTransaction$ finalize`))
    );
  }

  protected lock$(correlationUid: string): Observable<EditableTransaction> {

    let lockUid = this.editableTransaction.lockUid.value;

    if (!lockUid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | lock$ => addLock$ observe`);

      return super.addViewer$(this.editableTransaction, this.terminalUid, false, correlationUid).pipe(
        tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | lock$ <= addLock$`)),
        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | lock$ => addLock$ finalize`))
      );
    } else {
      return of(null);
    }
  }

  protected unlock$(correlationUid: string): Observable<EditableTransaction> {

    let lockUid = this.editableTransaction ? this.editableTransaction.lockUid.value : null;

    if (lockUid) {
      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | unlock$ => commitOrCancelTransactionState$ observe`);

      return this.commitOrCancelTransactionState$().pipe(
        concatMap(editableTransaction => {
          this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | unlock$ <= commitOrCancelTransactionState$`);

          if (isEqualUUID(lockUid, this.terminalUid)) {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | unlock$ => unlockTransaction$ observe`);

            return super.removeViewer$(editableTransaction, lockUid, false, correlationUid).pipe(
              tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | unlock$ <= unlockTransaction$`)),
              finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | unlock$ => unlockTransaction$ finalize`))
            )
          } else {
            return of(null);
          }
        }),
        first(),
        finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | unlock$ => commitOrCancelTransactionState$ finalize`))
      );
    }

    return of(null);
  }

  protected demandTransaction$(editableTransaction: EditableTransaction, correlationUid = UUID.UUID()): Observable<EditableTransaction> {

    let result = of(editableTransaction);

    if (editableTransaction != null) {

      this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | demandOpenTransaction$`);

      if (editableTransaction.number.value == null) {
        result = this.demandTransactionNumber$(editableTransaction, correlationUid);
      }

      if (!editableTransaction.uid) {
        result = result.pipe(
          concatMap(editableTransaction => {
            this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | demandOpenTransaction$ => openTransaction$ observe`);

            return super.openTransaction$(editableTransaction, correlationUid).pipe(
              tap(editableTransaction => {
                this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | demandOpenTransaction$ <= openTransaction$`);

                this.navigateToTransaction(editableTransaction?.uid);
              }),
              finalize(() => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | demandOpenTransaction$ => openTransaction$ finalize`))
            );
          })
        );
      }
    }

    return result;
  }

  protected demandTransactionNumber$(editableTransaction: EditableTransaction, correlationUid = UUID.UUID()): Observable<EditableTransaction> {

    this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | demandOpenTransaction$ => terminalProvider.getTransactionNumber observe`);

    return this.terminalProvider.getTransactionNumber().pipe(
      map(number => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | demandOpenTransaction$ <= terminalProvider.getTransactionNumber | ${number}`);

        editableTransaction.number.next(number);

        return editableTransaction;
      }),
      finalize(() => {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${editableTransaction?.uid} | demandOpenTransaction$ => terminalProvider.getTransactionNumber finalize`);
      })
    );
  }

  protected commitItem$(transaction: EditableTransaction, transactionItem: EditableTransactionTypes, correlationUid = UUID.UUID()): Observable<EditableTransactionTypes> {

    const isNew = !(transactionItem?.uid);

    if (transaction && transactionItem?.isDirty) {
      if (transactionItem instanceof EditableTransactionItem) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | item: ${transactionItem?.uid} | commitItem$ => commitTransactionItem$`);

        return super.commitTransactionItem$(transaction, transactionItem, correlationUid).pipe(
          tap(transactionItem => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | item: ${transactionItem?.uid} | commitItem$ <= commitTransactionItem$`))
        );
      } else if (transactionItem instanceof EditableTransactionItemAdjustment) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | adj: ${transactionItem?.uid} | commitItem$ => commitTransactionItemAdjustment$`);

        return super.commitTransactionItemAdjustment$(transaction, transactionItem, correlationUid).pipe(
          tap(transactionItem => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | adj: ${transactionItem?.uid} | commitItem$ <= commitTransactionItemAdjustment$`))
        );
      } else if (transactionItem instanceof EditableTransactionCharge) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | item: ${transactionItem?.uid} | commitItem$ => commitTransactionCharge$`);

        return super.commitTransactionCharge$(transaction, transactionItem, correlationUid).pipe(
          tap(transactionItem => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | item: ${transactionItem?.uid} | commitItem$ <= commitTransactionCharge$`))
        );
      } else if (transactionItem instanceof EditableTransactionChargeAdjustment) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | adj: ${transactionItem?.uid} | commitItem$ => commitTransactionChargeAdjustment$`);

        return super.commitTransactionChargeAdjustment$(transaction, transactionItem, correlationUid).pipe(
          tap(transactionItem => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | adj: ${transactionItem?.uid} | commitItem$ <= commitTransactionChargeAdjustment$`))
        );
      } else if (transactionItem instanceof EditableTransactionAdjustment) {
        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | adj: ${transactionItem?.uid} | commitItem$ => commitTransactionAdjustment$`);

        return super.commitTransactionAdjustment$(transaction, transactionItem, correlationUid).pipe(
          tap(transactionItem => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | adj: ${transactionItem?.uid} | commitItem$ <= commitTransactionAdjustment$`))
        );
      } else if (transactionItem instanceof EditableTransactionPayment) {

        const drawerUid = transactionItem.metadata?.find(x => x.key == 'drawerUid')?.value || this.drawerProvider.activeDrawer.uid;
        const creditUid = transactionItem.metadata?.find(x => x.key == 'creditUid')?.value || UUID.UUID();

        transactionItem.addOrUpdateMetadata('drawerUid', drawerUid);
        transactionItem.addOrUpdateMetadata('creditUid', creditUid);

        this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | pay: ${transactionItem?.uid} | commitItem$ => commitPayment$`);

        return super.commitTransactionPayment$(transaction, transactionItem, correlationUid).pipe(
          tap(transactionItem => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | pay ${transactionItem?.uid} | commitPayment$ => commitItem$`)),
          concatMap(transactionPayment => {
            var drawerCreditInput = {
              drawerUid: drawerUid,
              creditUid: creditUid,
              paymentMethodUid: transactionPayment.paymentMethodUid.value,
              amount: transactionPayment.getAmount(),
              referenceUid: transactionPayment.uid,
              metadata: [{ key: 'transactionUid', value: transaction.uid }, { key: 'paymentUid', value: transactionPayment.uid }]
            };

            let drawerCreditHandler;
            if (isNew) {
              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | pay: ${transactionPayment.uid} | commitItem$ => drawerService.receiveCredit observe`);

              drawerCreditHandler = this.drawerService.receiveCredit(drawerCreditInput).pipe(
                catchError(error => this.errorHandlingProvider.handleError<Drawer>(error)),
                tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | pay ${transactionPayment?.uid} | commitItem$ <= drawerService.receiveCredit`)),
                finalize(() => {
                  this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction.uid} | pay: ${transactionPayment.uid} | commitItem$ => drawerService.receiveCredit finalize`);
                })
              );
            } else {
              this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction.uid} | pay: ${transactionPayment.uid} | commitItem$ -> drawerService.adjustCredit observe`);

              drawerCreditHandler = this.drawerService.adjustCredit(drawerCreditInput).pipe(
                catchError(error => this.errorHandlingProvider.handleError<Drawer>(error)),
                tap(_ => this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction?.uid} | pay ${transactionPayment?.uid} | commitItem$ <= drawerService.adjustCredit`)),
                finalize(() => {
                  this.remoteLoggingProvider.log(`[RegisterComponent] ${correlationUid} | trx: ${transaction.uid} | pay ${transactionPayment?.uid} | commitItem$ -> drawerService.adjustCredit finalize`);
                })
              );
            }

            return drawerCreditHandler.pipe(
              map(_ => transactionPayment)
            );
          }),
          first()
        );
      }
    }

    return of(null);
  }

  protected synchronizeFeatureEnabling() {

    const transaction = this.editableTransaction;
    const transactionItems = transaction ? transaction.lineItems : [];
    const paymentList = transaction ? transaction.payments : [];

    const isSalePresent = transaction != null;
    const isValidSale = isSalePresent && transactionItems.every(li => li.isValid) && !isEqualUUID(transaction.transactionStatus.value, TransactionStatusEnum.Closed);
    const noOpenSale = transaction == null || !isEqualUUID(transaction.transactionStatus.value, TransactionStatusEnum.Open);
    const isClosedSale = isSalePresent && transactionItems.every(li => li.isValid) && isEqualUUID(transaction.transactionStatus.value, TransactionStatusEnum.Closed);
    const hasKitchenDepartment = transactionItems.some(x => x.departmentUid.value && isEqualUUID(x.departmentUid.value, '46C1731B-DBEA-4C6E-B822-F56B80A58254'));
    const isSaleEditable = isValidSale && !isEqualUUID(transaction.transactionStatus.value, TransactionStatusEnum.Closed);
    const isClosedCardSale = isClosedSale && paymentList.some(payment => isEqualUUID(payment.paymentMethodUid.value, PaymentMethodKeys.Card));
    const canPrintCardReceipt = isClosedCardSale && paymentList.some(payment => payment.referenceUid.value != null);
    const activeDrawer = this.drawerProvider.activeDrawer;

    this.canPrintCheck = isValidSale && this.hasReceiptPrinter;
    this.canPrintKitchen = hasKitchenDepartment && this.hasKitchenPrinter;
    this.canPrintReceipt = isClosedSale && this.hasReceiptPrinter;
    this.canPrintCardReceipt = canPrintCardReceipt && this.hasReceiptPrinter;
    this.canAddSaleComp = isSaleEditable;
    this.canSaveGuestCheck = isSaleEditable;

    this.canTenderCash = isSaleEditable && (this.hasCashDrawer && !!activeDrawer);
    this.canTenderCard = isSaleEditable && (this.hasCardTerminal && !!activeDrawer);
    this.canCloseCashSale = isSaleEditable && ((this.hasCashDrawer && !!activeDrawer) || (transaction.totalDue.value == 0 && (transaction.change.value == null || transaction.change.value == 0)));
    this.canCloseCardSale = isSaleEditable && (this.hasCardTerminal && !!activeDrawer);
    this.canOpenDrawer = (this.hasCashDrawer && !!activeDrawer) && noOpenSale;
  }
}

const enum LastEntryContextEnum {

  None = 0,
  Department = 1,
  Currency = 2
}
