import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'splitTitleCase' })
export class SplitTitleCasePipe implements PipeTransform {

    transform(camelCase: string): string {
        let ccSplit = camelCase.split(/(?=[A-Z])/).join(" ")
        return ccSplit;
    }

}