<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <div style="display: flex;">
    <div style="flex: 1;">
      <h2 style="width: 100%; text-align: center;">Location</h2>
      <div *ngFor="let deviceSettingOption of deviceSettingOptions" style="display: flex; width: 452px; justify-content: center">
        <app-pos-button [styles]="{ 'backgroundColor': deviceSettingOption.selected ? '#4d4d00' : '#2f2f2f' }" (click)="selectDeviceSetting(deviceSettingOption)">{{ deviceSettingOption.display }}</app-pos-button>
      </div>

    </div>
    <div style="flex: 1;">
      <h2 style="width: 100%; text-align: center;">Occurrence</h2>
      <div *ngFor="let occurrenceOption of occurrenceOptions" style="display: flex; width: 100%; justify-content: center;">
        <app-pos-button [styles]="{ 'backgroundColor': occurrenceOption.selected ? '#4d4d00' : '#2f2f2f' }" (click)="selectOccurrence(occurrenceOption)">{{ occurrenceOption.display }}</app-pos-button>
      </div>
    </div>
  </div>

  <hr />
</mat-dialog-content>
<mat-dialog-actions>
  <span style="flex: 1 1 auto;"></span>

  <app-timeout-button color="warn" [percent]="timeoutPercent" (click)="cancel()">Cancel</app-timeout-button>
  <app-pos-button [styles]="{ 'backgroundColor': 'green' }" (click)="close()">Print</app-pos-button>

</mat-dialog-actions>