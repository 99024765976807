import { BehaviorSubject } from "rxjs";
import { TransactionEvent } from './transaction-event';
import { TransactionEventType } from "./transaction-event-type";

export abstract class TransactionLine {

  onEvent = new BehaviorSubject<TransactionEvent>(null);

  uid: string;
  abstract get typeName(): string;
  isValid = new BehaviorSubject<boolean>(false);
  canCancel = new BehaviorSubject<boolean>(false);
  canVoid = new BehaviorSubject<boolean>(false);
  canAdjust = new BehaviorSubject<boolean>(false);

  protected isEditing$ = new BehaviorSubject<boolean>(false);
  protected previousValues: { [key: string]: any };

  public get isEditing(): boolean {
    return this.isEditing$.value;
  }

  public makeEditable() {

    this.isEditing$.next(true);

    this.onEvent.next(new TransactionEvent(this, TransactionEventType.Editing, null));
  }

  protected isChangedFrom(left: { [key: string]: any }, right: { [key: string]: any }): boolean {

    if (left && right) {
      let isChanged = false;
      let keys = Object.keys(left);
      let i = -1;
      while (!isChanged && i++ < keys.length) {
        isChanged = left[keys[i]] !== right[keys[i]];
      }

      return isChanged;
    } else {
      return true;
    }
  }

  abstract keyPressed(value: string): void;
  abstract void(): void;
  abstract adjust(): void;
  abstract cancel(): void;
  abstract commit(): void;
}
