import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Observable, combineLatest, map, of } from 'rxjs';
import { ItemConfiguratorContext } from "../../item-configurator-context";
import { SelectListModalData, SelectListModalProvider, SettingProvider, TenantProvider, findInArrayByKey, slideInAnimation, slideOutAnimation } from "core";
import { ProductConfigurationProductReference, ProductConfigurationVariation } from "downtown-product";
import { EditableTransactionItemConfigurationVariation, TransactionSettings } from "../../../../models";
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: 'transaction-item-configurator-detail-variation',
  templateUrl: './item-configurator-detail-variation.component.html',
  styleUrls: ['./item-configurator-detail-variation.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfiguratorDetailVariationComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public item: { variations?: EditableTransactionItemConfigurationVariation[] }
  @Input() public itemDisplay: Observable<string>
  @Input() public productVariation: ProductConfigurationVariation;
  @Input() public itemVariation: EditableTransactionItemConfigurationVariation;
  @Output() public configurationChanged = new EventEmitter();

  public transactionSettings$: Observable<TransactionSettings>;
  public display: string;

  constructor(
    private currencyPipe: CurrencyPipe,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider,
    private selectListModalProvider: SelectListModalProvider
  ) {
    this.transactionSettings$ = this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid);
  }

  ngOnInit() {

    this.display = this.productVariation.name;
  }

  public editVariation() {

    combineLatest(this.productVariation.options.map(variationOption => {
      return this.context.productProvider.getOneCached$(variationOption.productReference.uid, variationOption.productReference.version).pipe(
        map(x => {
          return {
            uid: variationOption.uid,
            display: variationOption.alias ? variationOption.alias : x.name,
            productReference: variationOption.productReference
          };
        })
      );
    })).subscribe(values => {
      values.splice(0, 0, { uid: null, display: 'None', productReference: null });

      this.itemDisplay.subscribe(itemDisplay => {
        this.selectListModalProvider.open(<SelectListModalData<any>>{
          title: `${itemDisplay} - ${this.productVariation.name}`,
          options: values,
          displayFunc: x => x.display
        }).afterClosed().subscribe(value => {
          const selection = value as { uid: string, display: string, productReference: ProductConfigurationProductReference };

          if (selection) {
            this.item.variations = this.item.variations || [];
            let itemVariation = findInArrayByKey(this.item.variations, 'variationUid', this.productVariation.uid);

            if (selection.uid) {
              if (!itemVariation) {
                itemVariation = <EditableTransactionItemConfigurationVariation>{
                  variationUid: this.productVariation.uid,
                };
                this.item.variations.push(itemVariation);
              }

              itemVariation.optionUid = selection.uid;
              itemVariation.productUid = selection.productReference.uid;
              itemVariation.productVersion = selection.productReference.version;
              itemVariation.productPortionUid = selection.productReference.portionUid;
            } else {
              if (itemVariation) {
                this.item.variations.splice(this.item.variations.indexOf(itemVariation), 1);
                if (this.item.variations.length == 0) {
                  this.item.variations == null;
                }
              }
            }

            itemVariation.optionUid = selection.uid;

            this.configurationChanged.emit();
          }
        });
      });
    });
  }
}
