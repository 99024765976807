import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { CardSwipe, CardSwipeEventTypeEnum } from "../../models";
import { Subject, first } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TextEntryModalData, TextEntryModalResult } from "./text-entry-modal.provider";
import { Inject } from "@angular/core";
import { LoggingProvider, SystemMessageProvider } from "../../providers";
import { UUID } from "angular2-uuid";

export abstract class TextEntryModalComponent {

  public form: FormGroup<{ value: FormControl<string> }>;

  protected destroyed$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TextEntryModalData,
    protected dialogRef: MatDialogRef<TextEntryModalComponent, TextEntryModalResult>,
    protected loggingProvider: LoggingProvider,
    protected systemMessageProvider: SystemMessageProvider
  ) {
    this.form = new FormGroup<{ value: FormControl<string> }>({
      value: new FormControl<string>(data.initialValue, [Validators.required])
    });
  }

  public simulateCardSwipe() {

    const cardSwipe = {
      messageType: CardSwipeEventTypeEnum.cardSwiped,
      lastFour: '1111',
      firstName: 'John',
      middleInitial: '',
      surname: 'Doe',
      expirationMonth: new Date().getMonth().toString(),
      expirationYear: new Date().getFullYear().toString()
    };

    this.systemMessageProvider.publishOne(this.systemMessageProvider.buildMessage(UUID.UUID(), 'msr-card-swipe-event', cardSwipe));
  }

  cancel() {

    this.dialogRef.close();
  }

  confirm() {

    const value = this.form.get('value').value;
    if (value && value.length > 0) {
      this.dialogRef.close({
        textEntry: this.form.get('value').value
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  protected handleCardSwipe(cardSwipe: CardSwipe) {

    const firstName = `${cardSwipe.firstName} ${cardSwipe.middleInitial}`.trim();
    const fullName = `${firstName} ${cardSwipe.surname}`.trim();

    this.form.get('value').setValue(fullName);

    this.loggingProvider.log(`TextEntryModalComponent:handleCardSwipe ${fullName}`);

    this.dialogRef.close({
      textEntry: this.form.get('value').value,
      cardSwipe: cardSwipe
    });
  }
}
