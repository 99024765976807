import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { SecurityUser } from 'core';
import { NavigationProvider } from 'core';
import { Employee } from 'pos-core';
import { Member } from 'pos-core';
import { AuthEmployeeProvider } from 'pos-core';
import { AuthMemberProvider } from 'pos-core';
import { IdentityServerConnectionProvider } from 'projects/pos-site/src/app/providers/identity-server-connection.provider';
import { SiteAuthIdentityProvider } from 'projects/pos-site/src/app/providers/site-auth-identity.provider';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent implements OnInit {

  public identityServerAvailable = new Subject<boolean>();
  public authIdentity$: Observable<SecurityUser>;
  public authEmployee$: Observable<Employee>;
  public authMember$: Observable<Member>;
  public username$ = new BehaviorSubject<string>(null);

  public isEmployee = new BehaviorSubject<boolean>(false);
  public isManager = new BehaviorSubject<boolean>(false);
  public isAdmin = new BehaviorSubject<boolean>(false);
  public isMember = new BehaviorSubject<boolean>(false);

  public canOrderOnline: boolean;

  constructor(
    private authIdentityProvider: SiteAuthIdentityProvider,
    private authEmployeeProvider: AuthEmployeeProvider,
    private authMemberProvider: AuthMemberProvider,
    private navigationProvider: NavigationProvider,
    identityServerConnectionProvider: IdentityServerConnectionProvider
  ) {
    this.authIdentity$ = this.authIdentityProvider.userIdentity$;
    this.authMember$ = this.authMemberProvider.authMember$;
    this.authEmployee$ = this.authEmployeeProvider.authEmployee$;
    this.username$ = this.authIdentityProvider.username$;
    this.isEmployee = this.authIdentityProvider.isEmployee;
    this.isManager = this.authIdentityProvider.isManager;
    this.isAdmin = this.authIdentityProvider.isAdmin;
    this.isMember = this.authIdentityProvider.isMember;

    combineLatest([
      identityServerConnectionProvider.connected$,
      this.authEmployeeProvider.authEmployee$
    ]).subscribe(([identityServerConnected, authEmployee]) => {
      this.identityServerAvailable.next(identityServerConnected !== null);
      this.canOrderOnline = authEmployee?.uid.toUpperCase() == '104A5493-7AA6-4957-96D0-8F9B85EDE78E';
    });
  }

  ngOnInit() {

  }

  navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  navigateToMenu() {

    this.navigationProvider.navigate(['/menu']);
  }

  // navigateToIntegratedStore() {

  //   this.navigationProvider.navigateTo(['/store']);
  // }

  navigateToHostedStore() {

    this.navigationProvider.navigate(['/hosted']);
  }

  navigateToLogin() {

    this.authIdentityProvider.navigateToLogin();
  }

  navigateToLogout() {

    this.authIdentityProvider.navigateToLogout();
  }

  navigateToMemberProfile() {

    this.authMemberProvider.authMember$.subscribe(authMember => {
      this.navigationProvider.navigate(['/member', authMember.uid, 'profile']);
    })
  }

  navigateToChangePassword() {

    alert('not implemented');
  }

  navigateToPublic() {

    this.navigationProvider.navigate(['/']);
  }

  navigateToBackOffice() {

    this.navigationProvider.navigate(['/backoffice/dashboard']);
  }

  navigateToPositions() {

    this.navigationProvider.navigate(['/backoffice/positions']);
  }

  navigateToEmployeeDashboard() {

    this.authEmployeeProvider.authEmployee$.subscribe(authEmployee => {
      this.navigationProvider.navigate(['/employee', authEmployee.uid, 'dashboard']);
    })
  }

  navigateToEmployeeShifts() {

    this.authEmployeeProvider.authEmployee$.subscribe(authEmployee => {
      this.navigationProvider.navigate(['/employee', authEmployee.uid, 'shifts']);
    })
  }

  navigateToEmployeeProfile() {

    this.authEmployeeProvider.authEmployee$.subscribe(authEmployee => {
      this.navigationProvider.navigate(['/employee', authEmployee.uid, 'profile']);
    })
  }

  navigateToEmployees() {

    this.navigationProvider.navigate(['/backoffice/employees']);
  }

  navigateToBackOfficeEmployeeShifts() {

    this.navigationProvider.navigate(['/backoffice/shifts']);
  }

  navigateToPayPeriods() {

    this.navigationProvider.navigate(['/backoffice/payperiods']);
  }

  navigateToTransactions() {

    this.navigationProvider.navigate(['/backoffice/transactions']);
  }
  navigateToRegister() {

    this.navigationProvider.navigate(['/backoffice/register']);
  }

  navigateToDrawers() {

    this.navigationProvider.navigate(['/backoffice/drawers']);
  }

  navigateToAccounts() {

    this.navigationProvider.navigate(['/backoffice/accounts']);
  }

  navigateToReports() {

    this.navigationProvider.navigate(['/backoffice/reports']);
  }

  navigateToDepartments() {

    this.navigationProvider.navigate(['/backoffice/departments']);
  }

  navigateToProducts() {

    this.navigationProvider.navigate(['/backoffice/products']);
  }
  
  navigateToAdminDashboard() {

    this.navigationProvider.navigate(['/admin/dashboard']);
  }

  navigateToAdministrationDam() {

    this.navigationProvider.navigate(['/admin/dam']);
  }

  navigateToAdministrationMembers() {

    this.navigationProvider.navigate(['/admin/members']);
  }

  navigateToAdministrationTerminals() {

    this.navigationProvider.navigate(['/admin/terminals']);
  }

  navigateToSystemSettings() {

    this.navigationProvider.navigate(['/admin/settings']);
  }
}
