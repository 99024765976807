import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';

import { AuthModule } from 'auth';
import { CoreModule } from 'core';
import { DowntownProductModule } from 'downtown-product';
import { DowntownTransactionModule } from 'downtown-transaction';

import { AuthClientProvider } from './providers/auth-client.provider';
import { AuthMemberProvider } from './providers/auth-member.provider';

import { IdentityUserService } from './services/identity-user.service';
import { MemberService } from './services/member.service';
import { CardTransactionBatchService } from './services/card-transaction-batch.service';
import { CardTransaction2Service } from './services/card-transaction.service';
import { CartService } from './services/cart.service';
import { ClientService } from './services/client.service';
import { OrderService } from './services/order.service';
import { ShippingService } from './services/shipping.service';
import { StoreFrontService } from './services/store-front.service';
import { TaxService } from './services/tax.service';

import { CardTransactionBatchStatusPipe } from './pipes/card-transaction-batch-status.pipe';
import { CardTransaction2StatusPipe } from './pipes/card-transaction-status.pipe';
import { OrderStatusPipe } from './pipes/order-status.pipe';

import { CartEditorComponent } from './components/cart-editor/cart-editor.component';

import { OrderItemConfigurationEditorComponent } from './components/order-editor/order-item-editor/order-item-configuration-editor/order-item-configuration-editor.component';

import { CartComponent } from './components/cart/cart.component';
import { CartItemComponent } from './components/cart/item/cart-item.component';
import { ProductsComponent } from './components/products/products.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CheckoutLogisticsComponent } from './components/checkout/logistics/checkout-logistics.component';
import { CheckoutIdentityComponent } from './components/checkout/identity/checkout-identity.component';
import { CheckoutShippingComponent } from './components/checkout/shipping/checkout-shipping.component';
import { CheckoutReviewComponent } from './components/checkout/review/checkout-review.component';

import { CardTransactionBatchesTableComponent } from './components/client/card-transaction-batches-table/card-transaction-batches-table.component';
import { CardTransactionsTableComponent } from './components/client/card-transactions-table/card-transactions-table.component';
import { DeparmtmentsTableComponent } from './components/client/departments-table/departments-table.component';
import { OrdersTableComponent } from './components/client/orders-table/orders-table.component';
import { OrderEditorComponent } from './components/order-editor/order-editor.component';
import { OrderItemEditorComponent } from './components/order-editor/order-item-editor/order-item-editor.component';
import { StoreDecimalModalComponent } from './providers/modals/store-decimal-modal/store-decimal-modal.component';
import { StoreDecimalModalProvider } from './providers/modals/store-decimal-modal/store-decimal-modal.provider';
import { UpdateOrderItemFullfilmentModalComponent } from './providers/modals/update-order-item-fullfilment-modal/update-order-item-fullfilment-modal.component';
import { UpdateOrderItemFullfilmentModalProvider } from './providers/modals/update-order-item-fullfilment-modal/update-order-item-fullfilment-modal.provider';

import { StoreCheckoutOrderedComponent } from './components/checkout/ordered/store-checkout-ordered.component';
import { StoreCheckoutPaymentComponent } from './components/checkout/payment/store-checkout-payment.component';
import { OrderFulfillmentEditorComponent } from './components/order-editor/order-fulfillment-editor/order-fulfillment-editor.component';
import { CartProvider } from './providers/cart.provider';
import { StoreFrontProvider } from './providers/store-front.provider';

@NgModule({
  declarations: [
    CardTransaction2StatusPipe,
    CardTransactionBatchStatusPipe,
    OrderStatusPipe,

    CartComponent,
    CartItemComponent,

    ProductsComponent,

    // Checkout module
    CheckoutComponent,
    CheckoutLogisticsComponent,
    CheckoutIdentityComponent,
    CheckoutShippingComponent,
    CheckoutReviewComponent,

    StoreCheckoutOrderedComponent,
    StoreCheckoutPaymentComponent,

    CartEditorComponent,

    OrderEditorComponent,
    OrderItemEditorComponent,
    OrderItemConfigurationEditorComponent,

    OrderFulfillmentEditorComponent,

    StoreDecimalModalComponent,
    UpdateOrderItemFullfilmentModalComponent,

    CardTransactionBatchesTableComponent,
    CardTransactionsTableComponent,
    DeparmtmentsTableComponent,
    OrdersTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatDialogModule,
    MatListModule,
    MatSelectModule,
    MatStepperModule,
    MatRadioModule,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,

    AuthModule,
    CoreModule,
    DowntownProductModule,
    DowntownTransactionModule
  ],
  exports: [
    CardTransaction2StatusPipe,
    CardTransactionBatchStatusPipe,
    OrderStatusPipe,

    CartComponent,
    ProductsComponent,

    CheckoutComponent,

    OrderEditorComponent,

    CardTransactionBatchesTableComponent,
    CardTransactionsTableComponent,
    DeparmtmentsTableComponent,
    OrdersTableComponent
  ],
  providers: [
    CurrencyPipe,
    CardTransaction2StatusPipe,
    CardTransactionBatchStatusPipe,
    OrderStatusPipe,

    CardTransaction2Service,
    CardTransactionBatchService,
    CartService,
    ClientService,
    IdentityUserService,
    MemberService,
    OrderService,
    ShippingService,
    StoreFrontService,
    TaxService,

    AuthMemberProvider,
    AuthClientProvider,
    CartProvider,
    StoreFrontProvider,

    StoreDecimalModalProvider,
    UpdateOrderItemFullfilmentModalProvider
  ]
})
export class StoreCoreModule {
}
