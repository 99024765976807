import { Injectable } from "@angular/core";
import { Page } from "core";
import { PaginationInput } from "core";
import { HttpService } from "core";
import { OltpServiceSettings } from "../oltp-service-settings";
import { Cart } from "../models/cart";
import { StoreModelFactory } from "../providers/store-model-factory";
import { CartViewOptions, CartItemViewOptions, ViewFactory } from "../view-factory";
import { Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { CartItemInput } from "../models/cart-item-input";

@Injectable()
export class CartService {

  public static readonly CartIndexView = <CartViewOptions>{};
  public static readonly CartFullView = <CartViewOptions>{ includeItems: {includeCategory: true, includeDepartment: true } };
  public static readonly CartItemIndexView = <CartItemViewOptions>{};

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: CartViewOptions = CartService.CartFullView): Observable<Cart> {

    let view = ViewFactory.buildCartView(viewOptions);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<Cart>(this.oltpServiceSettings.apiUrl, 'api/oltp/cart', request, 'getByUid').pipe(
      map(x => StoreModelFactory.assignCart(x))
    );
  }

  search(ownerUids: string[], storeFrontUids: string[], identityUids: string[], statusUids: string[], paginationInput: PaginationInput, viewOptions: CartViewOptions = CartService.CartFullView): Observable<Page<Cart>> {

    let view = ViewFactory.buildCartView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(ownerUids: ${JSON.stringify(ownerUids)}, storeFrontUids: ${JSON.stringify(storeFrontUids)}, identityUids: ${JSON.stringify(identityUids)}, statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<Cart>>(this.oltpServiceSettings.apiUrl, 'api/oltp/cart', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = StoreModelFactory.assignCart(edge.node)))
    );
  }

  create(uid: string, storeFrontUid: string, viewOptions: CartViewOptions = CartService.CartFullView): Observable<Cart> {

    let view = ViewFactory.buildCartView(viewOptions);

    var request = {
      query: `mutation create { create(uid: "${uid}", storeFrontUid: "${storeFrontUid}") ${view} }`
    }

    return this.httpService.graph<Cart>(this.oltpServiceSettings.apiUrl, 'api/oltp/cart', request, 'create').pipe(
      map(x => StoreModelFactory.assignCart(x))
    );
  }

  // calculateCartItem(cartItemInput: CartItemInput, viewOptions: CartItemViewOptions = CartService.CartItemIndexView): Observable<CartItem> {

  //   let view = ViewFactory.buildCartItemView(viewOptions);

  //   var request = {
  //     query: `query calculateCartItem($cartItem:CartItemInput!) { calculateCartItem(cartItem: $cartItem) ${view} }`,
  //     variables: { cartItem: cartItemInput }
  //   }

  //   return this.httpService.graph<CartItem>(this.oltpServiceSettings.apiUrl, 'api/oltp/cart', request, 'calculateCartItem').pipe(
  //     map(x => StoreModelFactory.assignCartItem(x))
  //   );
  // }

  updateLogistics(uid: string, logisticTypeUid: string, logisticScheduleUid: string, logisticDateTimeUtc: Date, viewOptions: CartViewOptions = CartService.CartFullView): Observable<Cart> {

    let view = ViewFactory.buildCartView(viewOptions);

    var request = {
      query: `mutation updateLogistics { updateLogistics(uid: "${uid}", logisticTypeUid: "${logisticTypeUid}", logisticScheduleUid: "${logisticScheduleUid}", logisticDateTimeUtc: ${logisticDateTimeUtc ? `'${logisticDateTimeUtc?.toISOString()}'` : null}) ${view} }`
    }

    return this.httpService.graph<Cart>(this.oltpServiceSettings.apiUrl, 'api/oltp/cart', request, 'updateLogistics').pipe(
      map(x => StoreModelFactory.assignCart(x))
    );
  }

  addCartItem(uid: string, cartItemInput: CartItemInput, viewOptions: CartViewOptions = CartService.CartFullView): Observable<Cart> {

    let view = ViewFactory.buildCartView(viewOptions);

    var request = {
      query: `mutation addCartItem($cartItem:CartItemInput!) { addCartItem(uid: "${uid}", cartItem: $cartItem) ${view} }`,
      variables: { cartItem: cartItemInput }
    }

    return this.httpService.graph<Cart>(this.oltpServiceSettings.apiUrl, 'api/oltp/cart', request, 'addCartItem').pipe(
      map(x => StoreModelFactory.assignCart(x))
    );
  }

  updateCartItem(uid: string, cartItemInput: CartItemInput, viewOptions: CartViewOptions = CartService.CartFullView): Observable<Cart> {

    let view = ViewFactory.buildCartView(viewOptions);

    var request = {
      query: `mutation updateCartItem($cartItem:CartItemInput!) { updateCartItem(uid: "${uid}", cartItem: $cartItem) ${view} }`,
      variables: { cartItem: cartItemInput }
    }

    return this.httpService.graph<Cart>(this.oltpServiceSettings.apiUrl, 'api/oltp/cart', request, 'updateCartItem').pipe(
      map(x => StoreModelFactory.assignCart(x))
    );
  }

  removeCartItem(uid: string, cartItemUid: string, viewOptions: CartViewOptions = CartService.CartFullView): Observable<Cart> {

    let view = ViewFactory.buildCartView(viewOptions);

    var request = {
      query: `mutation removeCartItem { removeCartItem(uid: "${uid}", cartItemUid: "${cartItemUid}") ${view} }`
    }

    return this.httpService.graph<Cart>(this.oltpServiceSettings.apiUrl, 'api/oltp/cart', request, 'removeCartItem').pipe(
      map(x => StoreModelFactory.assignCart(x))
    );
  }
}
