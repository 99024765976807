import { Metadata } from "core";

export interface SyncSettings {

    settings: SyncSetting[];
}

export interface SyncSetting {
    uid: string;
    enabled: boolean;
    name: string;
    syncType: SyncTypeEnum;
    intervalMilliseconds: number;
    maxEventCount: number;
    metadata: Metadata[];
}

export enum SyncTypeEnum {

    WebApi = "WebApi",
    Socket = "Socket",
    Graph = "Graph"
}