import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WaitModalData } from "./wait-modal.provider";

@Component({
  selector: 'app-wait-modal',
  templateUrl: './wait-modal.component.html',
  styleUrls: ['./wait-modal.component.scss']
})
export class WaitModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WaitModalData
  ) {

  }

  ngOnInit() {

  }
}
