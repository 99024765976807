import { Injectable } from "@angular/core";
import { HttpService } from "core";
import { Observable } from 'rxjs';
import { Page } from "core";
import { OltpServiceSettings } from '../oltp-service-settings';
import { Member } from '../models/member';
import { IdentityUserService } from '../services/identity-user.service';
import { EmployeeService } from '../services/employee.service';
import { PaginationInput } from "core";
import { PosModelFactory } from "../pos-model-factory";
import { map, tap } from "rxjs/operators";

@Injectable()
export class MemberService {

  public static readonly MemberIndexView = <MemberViewOptions>{};
  public static readonly MemberFullView = <MemberViewOptions>{ identityUser: true, includeEmployee: true };

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = MemberService.buildView(viewOptions);

    var request = {
      query: `query { getByUid(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'getByUid').pipe(
      map(x => PosModelFactory.assignMember(x))
    );
  }

  getByIdentityId(identityId: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView) {

    let view = MemberService.buildView(viewOptions);

    var request = {
      query: `query { getByIdentityId(identityId: "${identityId}") ${view} }`
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'getByIdentityId').pipe(
      map(x => PosModelFactory.assignMember(x))
    );
  }

  search(statusUids: string[], paginationInput: PaginationInput, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Page<Member>> {

    let view = MemberService.buildView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<Member>>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = PosModelFactory.assignMember(edge.node)))
    );
  }

  create(uid: string, firstName: string, lastName: string, displayName: string, email: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = MemberService.buildView(viewOptions);

    var memberInput = {
      uid: uid,
      firstName: firstName,
      lastName: lastName,
      displayName: displayName,
      email: email
    };

    var request = {
      query: `mutation create($employee:EmployeeInput!) { create(employee: $employee) ${view} }`,
      variables: { member: memberInput }
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'create').pipe(
      map(x => PosModelFactory.assignMember(x))
    );
  }

  update(uid: string, firstName: string, lastName: string, displayName: string, email: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = MemberService.buildView(viewOptions);

    var memberInput = {
      uid: uid,
      firstName: firstName,
      lastName: lastName,
      displayName: displayName,
      email: email
    };

    var request = {
      query: `mutation update($member:MemberInput!) { update(member: $member) ${view} }`,
      variables: { member: memberInput }
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'update').pipe(
      map(x => PosModelFactory.assignMember(x))
    );
  }

  enable(uid: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = MemberService.buildView(viewOptions);

    var request = {
      query: `mutation enableMember { enable(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'enable').pipe(
      map(x => PosModelFactory.assignMember(x))
    );
  }

  disable(uid: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = MemberService.buildView(viewOptions);

    var request = {
      query: `mutation disableMember { disable(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'disable').pipe(
      map(x => PosModelFactory.assignMember(x))
    );
  }

  delete(uid: string, viewOptions: MemberViewOptions = MemberService.MemberIndexView): Observable<Member> {

    let view = MemberService.buildView(viewOptions);

    var request = {
      query: `mutation deleteMember { delete(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Member>(this.oltpServiceSettings.apiUrl, 'api/oltp/member', request, 'delete').pipe(
      map(x => PosModelFactory.assignMember(x))
    );
  }

  public static buildView(viewOptions: MemberViewOptions) {

    let view = `uid identityId firstName lastName email displayName isEmployee memberStatusUid`;

    if (viewOptions.identityUser) {
      view += ` identityUser ${IdentityUserService.buildView(IdentityUserService.IdentityUserIndexView)}`;
    }

    if (viewOptions.includeEmployee) {
      view += ` employee ${EmployeeService.buildView(EmployeeService.EmployeeIndexView)}`;
    }

    return '{ ' + view + ' }';
  }
}

export interface MemberViewOptions {

  identityUser: boolean;
  includeEmployee: boolean;
}

