import { UntypedFormGroup } from "@angular/forms";

export class ProductContext {

  productForm : UntypedFormGroup;

  public get configurationForm() : UntypedFormGroup {

    return this.productForm.get('configuration') as UntypedFormGroup;
  }
}
