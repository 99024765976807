<div style="height: 50vh; display: flex; flex-direction: column; color: #fff;">
  <div style="font-size: 42px; line-height: 42px; text-align: center;">
    {{ data.title }}
  </div>
  <div style="">
    <!-- <div style="position: absolute; top: 0px; width: 100%;"> -->
    <form [formGroup]="form">
      <input #value class="" style="font-family: Arial, Helvetica, sans-serif; font-size: 56px; line-height: 56px; color: black; border: none; padding-left: 3px; padding-right: 3px; outline: none; width: 100%; text-align: center; margin-bottom: 5px;" type="text" spellcheck="false" formControlName="value" />
    </form>
    <!-- </div> -->
  </div>
  <div style="flex: 1; position: relative; display: flex; flex-direction: row; color: black;">
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[0])">{{ isShifted ? keys[0].shiftValue : keys[0].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[1])">{{ isShifted ? keys[1].shiftValue : keys[1].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[2])">{{ isShifted ? keys[2].shiftValue : keys[2].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[3])">{{ isShifted ? keys[3].shiftValue : keys[3].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[4])">{{ isShifted ? keys[4].shiftValue : keys[4].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[5])">{{ isShifted ? keys[5].shiftValue : keys[5].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[6])">{{ isShifted ? keys[6].shiftValue : keys[6].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[7])">{{ isShifted ? keys[7].shiftValue : keys[7].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[8])">{{ isShifted ? keys[8].shiftValue : keys[8].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[9])">{{ isShifted ? keys[9].shiftValue : keys[9].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[10])">{{ isShifted ? keys[10].shiftValue : keys[10].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[11])">{{ isShifted ? keys[11].shiftValue : keys[11].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[12])">{{ isShifted ? keys[12].shiftValue : keys[12].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[13])">{{ isShifted ? keys[13].shiftValue : keys[13].value }}</div>
    </div>
  </div>
  <div style="flex: 1; position: relative; display: flex; flex-direction: row; color: black;">
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[14])">{{ isShifted ? keys[14].shiftValue : keys[14].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[15])">{{ isShifted ? keys[15].shiftValue : keys[15].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[16])">{{ isShifted ? keys[16].shiftValue : keys[16].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[17])">{{ isShifted ? keys[17].shiftValue : keys[17].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[18])">{{ isShifted ? keys[18].shiftValue : keys[18].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[19])">{{ isShifted ? keys[19].shiftValue : keys[19].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[20])">{{ isShifted ? keys[20].shiftValue : keys[20].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[21])">{{ isShifted ? keys[21].shiftValue : keys[21].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[22])">{{ isShifted ? keys[22].shiftValue : keys[22].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[23])">{{ isShifted ? keys[23].shiftValue : keys[23].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[24])">{{ isShifted ? keys[24].shiftValue : keys[24].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[25])">{{ isShifted ? keys[25].shiftValue : keys[25].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[26])">{{ isShifted ? keys[26].shiftValue : keys[26].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[27])">{{ isShifted ? keys[27].shiftValue : keys[27].value }}</div>
    </div>
  </div>
  <div style="flex: 1; position: relative; display: flex; flex-direction: row; color: black;">
    <div class="keyboard-button-container" style="flex: 1.5;">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[28])">{{ isShifted ? keys[28].shiftValue : keys[28].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[29])">{{ isShifted ? keys[29].shiftValue : keys[29].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[30])">{{ isShifted ? keys[30].shiftValue : keys[30].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[31])">{{ isShifted ? keys[31].shiftValue : keys[31].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[32])">{{ isShifted ? keys[32].shiftValue : keys[32].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[33])">{{ isShifted ? keys[33].shiftValue : keys[33].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[34])">{{ isShifted ? keys[34].shiftValue : keys[34].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[35])">{{ isShifted ? keys[35].shiftValue : keys[35].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[36])">{{ isShifted ? keys[36].shiftValue : keys[36].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[37])">{{ isShifted ? keys[37].shiftValue : keys[37].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[38])">{{ isShifted ? keys[38].shiftValue : keys[38].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[39])">{{ isShifted ? keys[39].shiftValue : keys[39].value }}</div>
    </div>
    <div class="keyboard-button-container" style="flex: 2;">
      <div class="keyboard-button" style="flex:1; background-color: green;" (click)="buttonPressed.emit(keys[40])">{{ isShifted ? keys[40].shiftValue : keys[40].value }}</div>
    </div>
  </div>
  <div style="flex: 1; position: relative; display: flex; flex-direction: row; color: black;">
    <div class="keyboard-button-container" style="flex: 1.5;">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[41])">{{ isShifted ? keys[41].shiftValue : keys[41].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[42])">{{ isShifted ? keys[42].shiftValue : keys[42].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[43])">{{ isShifted ? keys[43].shiftValue : keys[43].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[44])">{{ isShifted ? keys[44].shiftValue : keys[44].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[45])">{{ isShifted ? keys[45].shiftValue : keys[45].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[46])">{{ isShifted ? keys[46].shiftValue : keys[46].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[47])">{{ isShifted ? keys[47].shiftValue : keys[47].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[48])">{{ isShifted ? keys[48].shiftValue : keys[48].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[49])">{{ isShifted ? keys[49].shiftValue : keys[49].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[50])">{{ isShifted ? keys[50].shiftValue : keys[50].value }}</div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[51])">{{ isShifted ? keys[51].shiftValue : keys[51].value }}</div>
    </div>
    <div class="keyboard-button-container" style="flex: 1.5;">
      <div class="keyboard-button" (click)="buttonPressed.emit(keys[52])">{{ isShifted ? keys[52].shiftValue : keys[52].value }}</div>
    </div>
  </div>
  <div style="flex: 1; display: flex; flex-direction: row; justify-content: space-between; color: black;">
    <div class="keyboard-button-container">
      <div class="keyboard-button" style="width: 150px;">
        <div *ngIf="data.showDevCardSwipe" class="keyboard-button" style="width: 150px; background-color: red;" (click)="buttonPressed.emit(keys[54])">Sim Card Swipe</div>
      </div>
    </div>
    <div class="keyboard-button-container">
      <div class="keyboard-button" style="width: 50vw;" (click)="buttonPressed.emit(keys[53])">{{ isShifted ? keys[53].shiftValue : keys[53].value }}</div>
    </div>
    <div class="keyboard-button-container" style="display: flex; justify-content: right;">
      <div class="keyboard-button" style="width: 150px; background-color: red;" (click)="cancel()">Cancel</div>
    </div>
  </div>
</div>