import { Component, OnInit, ViewChild } from '@angular/core';
import { ObservableCacheProvider, PaginationInput, TenantProvider } from 'core';
import { combineLatest, of } from 'rxjs';
import { NavigationProvider } from 'core';
import { MatSidenav } from '@angular/material/sidenav';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ProductProvider, ProductService } from 'downtown-product';
import { Product } from 'downtown-product';
import { UUID } from 'angular2-uuid';
import { map, switchMap } from 'rxjs/operators';
import { initTransactionItemConfiguration, ItemConfiguratorModalData, ItemConfiguratorModalProvider } from 'downtown-transaction';
import { LoggingProvider } from 'core';
import { CartService, EditableCart, EditableCartDataHandler, EditableCartItem, fromEditableCartItem, initCartItem } from 'store-core';
import { CategoryService } from 'downtown-product';
import { MenuPlacement } from 'downtown-product';
import { AuthEmployeeProvider } from 'pos-core';
import { CartStatusKeys } from 'store-core';

@Component({
  selector: 'app-site-public-store',
  templateUrl: './site-public-store.component.html',
  styleUrls: ['./site-public-store.component.scss']
})
export class SitePublicStoreComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  private pagination = <PaginationInput>{ pageSize: 1000, pageIndex: 0, sortField: 'name', sortStrategy: 'asc' };

  public cart: EditableCart;
  public categories: Category[];
  public products: Product[];
  public form: FormGroup;

  private cartEditDataHandler: EditableCartDataHandler;

  constructor(
    private tenantProvider: TenantProvider,
    private authEmployeeProvider: AuthEmployeeProvider,
    private navigationProvider: NavigationProvider,
    private cartItemConfiguratorModalProvider: ItemConfiguratorModalProvider,
    private categoryService: CategoryService,
    private productProvider: ProductProvider,
    private cartService: CartService
  ) {
    this.form = new FormGroup({});

    this.cartEditDataHandler = new EditableCartDataHandler(new LoggingProvider());
  }

  ngOnInit() {

    this.authEmployeeProvider.authEmployee$.subscribe(authIdentity => {
      if (authIdentity) {
        combineLatest([
          this.cartService.search(null, null, [authIdentity.uid], [CartStatusKeys.Open], this.pagination)
        ]).subscribe(([existingCartPage]) => {
          if (existingCartPage && existingCartPage.edges.length > 0) {
            this.cart = this.cartEditDataHandler.mergeCart(this.cart || new EditableCart(), existingCartPage.edges[0].node);
          } else {
            this.cart = null;
          }
        });
      }
    });

    combineLatest([
      this.categoryService.search(null, null, null, null),
      this.productProvider.search$(UUID.UUID(), null, null, null, this.pagination),
    ]).subscribe(([categoryPage, productPage]) => {
      if (categoryPage != null && productPage != null) {
        var products = productPage.edges.map(x => x.node);

        var explodedProducts = products.map(product => {

          if (product.configuration && product.configuration.portions) {
            let saleablePortions = product.configuration && product.configuration.portions ? product.configuration.portions.filter(y => y.menuPlacementUid.toUpperCase() != MenuPlacement.NoSale.toUpperCase()) : [];

            return saleablePortions.map(y => <SelectableProduct>{
              product: product,
              categoryUid: product.categoryUid,
              title: product.name,
              subtitle: saleablePortions.length > 1 || y.menuPlacementUid.toUpperCase() != MenuPlacement.Menu.toUpperCase() ? y.name : '',
              price: product.basePrice + y.price,
              portionUid: y.uid,
              menuPlacementUid: y.menuPlacementUid
            });
          } else {
            return [<SelectableProduct>{
              product: product,
              categoryUid: product.categoryUid,
              title: product.name,
              subtitle: '',
              price: product.basePrice,
              menuPlacementUid: MenuPlacement.Menu
            }];

          }
        }).reduce((a, x) => { return a.concat(x); }, []);

        this.categories = categoryPage.edges.map(x => x.node).map(category => {
          return <Category>{
            name: category.name,
            products: explodedProducts.filter(x => x.categoryUid.toUpperCase() == category.uid.toUpperCase())
          }
        }).filter(x => x.products.length > 0);


        products.forEach(product => {
          this.addFormControl(product);
        })

        this.products = products;
      }
    });
  }

  private addFormControl(product: Product) {

    this.form.addControl(product.uid, new FormControl(1, [Validators.pattern("^[0-9]*$")]));
  }

  openCart() {

    this.sidenav.open();
  }

  closeCart() {

    this.sidenav.open();
  }

  configureProduct(selectableProduct: SelectableProduct) {

    this.cartItemConfiguratorModalProvider.open(<ItemConfiguratorModalData>{
      ownerUid: null,
      productUid: selectableProduct.product.uid,
      productVersion: selectableProduct.product.version,
      itemConfiguration: initTransactionItemConfiguration(selectableProduct.product, selectableProduct.portionUid, this.productProvider, true),
      menuPlacementUid: MenuPlacement.Menu,
      isNew: true
    }).afterClosed().subscribe(result => {
      if (result) {
        var cartItem = initCartItem(selectableProduct.product, selectableProduct.portionUid, this.productProvider, true)
        cartItem.uid = UUID.UUID();
        // cartItem.cartUid = this.cart ? this.cart.uid : null;
        cartItem.configuration.next(result.configuration);

        this.addToCart(cartItem);
      }
    });
  }

  addToCart(cartItem: EditableCartItem) {

    if (cartItem) {
      let cartUid = this.cart ? this.cart.uid : UUID.UUID();
      this.authEmployeeProvider.authEmployee$.subscribe(authEmployee => {
        (this.cart ? of(this.cart) : this.cartService.create(cartUid, null).pipe(map(x => this.cartEditDataHandler.mergeCart(this.cart || new EditableCart(), x)))).pipe(
          switchMap(editableCart => {
            return this.cartService.addCartItem(editableCart.uid, fromEditableCartItem(cartItem));
          })
        ).subscribe(updatedCart => {
          if (updatedCart) {
            this.cart = this.cartEditDataHandler.mergeCart(this.cart, updatedCart);
          }
        });
      });
    }
  }

  checkOut() {

    this.navigationProvider.navigate(['store', 'checkout', this.cart.uid]);
  }
}

class Category {

  name: string;
  description: string;
  products: SelectableProduct[];
}

interface SelectableProduct {

  product: Product;
  categoryUid: string;
  title: string;
  subtitle: string;
  price: number;
  portionUid: string;
  menuPlacementUid: string;
}
