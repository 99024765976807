import { TerminalStatusEnum, TerminalTypeEnum } from "../keys";

export class Terminal {

    uid: string;
    name: string;
    description: string;
    type: TerminalTypeEnum;
    deviceId: string;
    defaultDrawerSeedAmount: number;
    createDateTimeUtc: Date;
    status: TerminalStatusEnum;
}
