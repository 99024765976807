<div style="text-align: center;">
  <h2 mat-dialog-title>Enter Value</h2>
</div>

<div [formGroup]="form" style="margin-top: 15px;">
  <mat-form-field style="width: 100%;">
    <input matInput numeric-only type="text" formControlName="value" style="font-size: 2em;" placeholder="Value" />
  </mat-form-field>
</div>

<div style="display: flex;">

  <span style="flex: 1 1 auto;"></span>

  <app-scaled-container>
    <button mat-flat-button class="scale-font scale-button" style="background-color: red;" (click)="cancel()">Cancel</button>
  </app-scaled-container>

  <app-scaled-container>
    <button mat-flat-button class="scale-font scale-button" [disabled]="!this.form.dirty || !this.form.valid" (click)="commit()">Update</button>
  </app-scaled-container>

</div>
