import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';

import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';

// import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { GoogleChartsModule } from 'angular-google-charts';
import { CookieService } from 'ngx-cookie-service';

import { CoreModule, IdentityUserResolver, LockResolver, BlobServerSettings } from 'core';
import { DownMainTerminalModule } from 'downmain-terminal';
import { DowntownProductModule, ProductLibraryConfig } from 'downtown-product';
import { DowntownTransactionModule, TransactionServiceSettings } from 'downtown-transaction';

import { IdentityServerSettings } from 'auth';
import { OltpServiceSettings } from './oltp-service-settings';

import { MembersTableComponent } from "./components/admin/members-table/members-table.component";
// import { SystemMessagesTableComponent } from "./components/admin/system-messages-table/system-messages-table.component";
import { DrawerExchangesTableComponent } from "./components/back-office/drawer-exchanges-table/drawer-exchanges-table.component";
import { DrawersTableComponent } from "./components/back-office/drawers-table/drawers-table.component";
import { EmployeePermissionsTableComponent } from "./components/back-office/employee-permissions-table/employee-permissions-table.component";
import { EmployeePositionsTableComponent } from "./components/back-office/employee-positions-table/employee-positions-table.component";
import { EmployeesTableComponent } from "./components/back-office/employees-table/employees-table.component";
import { PayPeriodsTableComponent } from "./components/back-office/pay-periods-table/pay-periods-table.component";
import { PositionsTableComponent } from "./components/back-office/positions-table/positions-table.component";
import { SettingsTableComponent } from './components/back-office/settings-table/settings-table.component';
import { ShiftsTableComponent } from "./components/back-office/shifts-table/shifts-table.component";
import { NumberPadComponent } from "./components/number-pad/number-pad.component";
import { NumberSpeedPadComponent } from "./components/number-speed-pad/number-speed-pad.component";
import { DepartmentCategoryPadComponent } from "./components/department-category-pad/department-category-pad.component";

import { PosSettingComponent } from './components/admin/pos-setting/pos-setting.component';

import { AccountSettingPipe } from "./pipes/account-setting.pipe";
import { DrawerStatusPipe } from "./pipes/drawer-status.pipe";
import { EmployeeShiftStatusPipe } from "./pipes/employee-shift-status.pipe";
import { EmployeeStatusPipe } from "./pipes/employee-status.pipe";
import { EmployeePipe } from "./pipes/employee.pipe";
import { MemberStatusPipe } from "./pipes/member-status.pipe";
import { PasswordPipe } from "./pipes/password.pipe";
import { PayPeriodStatusPipe } from "./pipes/pay-period-status.pipe";
import { PositionStatusPipe } from "./pipes/position-status.pipe";
// import { SystemMessageStatusPipe } from "./pipes/system-message-status.pipe";
// import { SystemMessageTypePipe } from "./pipes/system-message-type.pipe";
import { WorkUnitPipe } from "./pipes/work-unit.pipe";

import { ActivePriceAdjustmentsProvider } from "./providers/active-price-adjustments.provider";
import { ScheduleProvider } from "./providers/schedule.provider";
// import { AlertProvider } from "./providers/alert.provider";
import { AuthMemberProvider } from "./providers/auth-member.provider";
import { DrawerProvider } from "./providers/drawer.provider";
import { EmployeeProvider } from "./providers/employee.provider";
import { PermissionProvider } from "./providers/permission.provider";
// import { SystemMessageProvider } from "./providers/system-message.provider";

import { PosCoreIdentityUserResolver } from './resolvers/pos-core-identity-user-resolver';
import { PosCoreLockResolver } from './resolvers/pos-core-lock-resolver';

import { AccountSettingService } from "./services/account-setting.service";
import { ConversationService } from "./services/conversation.service";
import { DrawerService } from "./services/drawer.service";
import { EmployeePayService } from "./services/employee-pay.service";
import { EmployeeShiftService } from "./services/employee-shift.service";
import { EmployeeService } from "./services/employee.service";
import { IdentityUserService } from "./services/identity-user.service";
import { MemberService } from "./services/member.service";
import { PayPeriodService } from "./services/pay-period.service";
import { PermissionService } from "./services/permission.service";
import { PositionService } from "./services/position.service";
import { PriceAdjustmentService } from "./services/price-adjustment.service";
import { ScheduleService } from "./services/schedule.service";
// import { SystemMessageService } from "./services/system-message.service";
import { ReportService } from './services/report.service';
import { modalComponentTypes, modalProviderTypes } from './modals/modal-types';
import { RegisterComponent } from './components/register/register.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterButtonComponent } from './components/register/register-button/register-button.component';

const componentTypes = [
  DrawersTableComponent,
  DrawerExchangesTableComponent,
  EmployeesTableComponent,
  EmployeePermissionsTableComponent,
  EmployeePositionsTableComponent,
  MembersTableComponent,
  PayPeriodsTableComponent,
  PositionsTableComponent,
  RegisterComponent,
  RegisterButtonComponent,
  SettingsTableComponent,
  // SystemMessagesTableComponent,
  ShiftsTableComponent,
  PosSettingComponent,

  DepartmentCategoryPadComponent,
  NumberSpeedPadComponent,
  NumberPadComponent,
];

const pipeTypes = [
  AccountSettingPipe,
  DrawerStatusPipe,
  EmployeePipe,
  EmployeeStatusPipe,
  EmployeeShiftStatusPipe,
  MemberStatusPipe,
  PasswordPipe,
  PayPeriodStatusPipe,
  PositionStatusPipe,
  // SystemMessageStatusPipe,
  // SystemMessageTypePipe,
  WorkUnitPipe
];

const providerTypes = [
  ScheduleProvider,
  ActivePriceAdjustmentsProvider,
  // AlertProvider,
  AuthMemberProvider,
  DrawerProvider,
  EmployeeProvider,
  PermissionProvider,
  // SystemMessageProvider,
  // TerminalProvider,
];

const resolverTypes = [
  PosCoreIdentityUserResolver,
  PosCoreLockResolver
];

const serviceTypes = [
  AccountSettingService,
  ConversationService,
  DrawerService,
  EmployeePayService,
  EmployeeShiftService,
  EmployeeService,
  IdentityUserService,
  MemberService,
  PayPeriodService,
  PermissionService,
  PositionService,
  PriceAdjustmentService,
  ReportService,
  ScheduleService,
  // SystemMessageService
];

@NgModule({
  declarations: [
    ...pipeTypes,
    ...componentTypes,
    ...modalComponentTypes,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,

    MatTableModule,
    MatIconModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
    MatGridListModule,
    MatMenuModule,
    MatListModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,

    CoreModule,
    DownMainTerminalModule,
    DowntownProductModule,
    DowntownTransactionModule
  ],
  exports: [
    CoreModule,

    ...componentTypes,
    ...pipeTypes,
  ],
  providers: [
    ...serviceTypes,
    ...providerTypes,
    ...resolverTypes,
    ...modalProviderTypes,
    CookieService,
    {
      provide: IdentityUserResolver,
      useClass: PosCoreIdentityUserResolver
    },
    {
      provide: LockResolver,
      useClass: PosCoreLockResolver
    }
  ]
})
export class PosCoreModule {

  static forRoot(
    oltpServiceSettings: OltpServiceSettings,
    productLibraryConfig: ProductLibraryConfig,
    transactionServiceSettings: TransactionServiceSettings,
    identityServerSettings: IdentityServerSettings,
    blobServerSettings: BlobServerSettings
  ): ModuleWithProviders<PosCoreModule> {
    return {
      ngModule: PosCoreModule,
      providers: [
        {
          provide: OltpServiceSettings,
          useValue: oltpServiceSettings
        },
        {
          provide: ProductLibraryConfig,
          useValue: productLibraryConfig
        },
        {
          provide: TransactionServiceSettings,
          useValue: transactionServiceSettings
        },
        {
          provide: IdentityServerSettings,
          useValue: identityServerSettings
        },
        {
          provide: BlobServerSettings,
          useValue: blobServerSettings
        },

        CookieService,

        ...serviceTypes,
        ...providerTypes,
        ...modalProviderTypes,
      ]
    };
  }
}
