export class Employee {

  uid: string;
  firstName: string;
  lastName: string;
  pin: string;
  mobilePhone: string;
  email: string;
  displayName: string;
  identityId: string;
  paymentMethodUid: string;
  employeeStatusUid: string;
  positions: EmployeePosition[];
  permissions: EmployeePermission[];

  getFullName(): string {
    return `${this.firstName ? this.firstName : ''} ${this.lastName ? this.lastName : ''}`.trim();
  }

  getReportName(): string {
    return `${this.lastName ? this.lastName : ''}, ${this.firstName ? this.firstName : ''}`.trim();
  }

  getDisplayableName(): string {
    return this.displayName ? this.displayName : this.getFullName();
  }
}

export class EmployeePosition {
  uid: string;
  payRate: number;
  workUnitUid: string;
  activeStatusUid: string;
}

export class EmployeePermission {
  uid: string;
}
