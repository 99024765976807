import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { UUID } from 'angular2-uuid';
import { AuthIdentityProvider } from 'core';
import { MessageModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { CartStatusKeys } from '../../keys';
import { IdentityInput, AddressInput, CreditCardInput } from '../../models/order-input';
import { CartService } from '../../services/cart.service';
import { OrderService } from '../../services/order.service';
import { ShippingService } from '../../services/shipping.service';
import { TaxService } from '../../services/tax.service';
import { CartProvider } from '../../providers/cart.provider';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { Cart } from '../../models/cart';
import { CheckoutContext } from './check-out-context';
import { StoreFrontProvider } from '../../providers/store-front.provider';

@Component({
  selector: 'store-core-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;

  public cart: Cart;
  public context: CheckoutContext;

  private destroyed$ = new Subject();

  public expiryMonths = new Array<{ id: string, display: string }>();
  public expiryYears = new Array<{ id: string, display: string }>();
  public shippingAmount: number = 0;
  public taxAmount: number = 0;
  public totalAmount: number = 0;
  public tip: number = 0;
  // private cartEditDataHandler: EditableCartDataHandler;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authIdentityProvider: AuthIdentityProvider,
    private navigationProvider: NavigationProvider,
    private messageModalProvider: MessageModalProvider,
    private cartService: CartService,
    private storeFrontProvider: StoreFrontProvider,
    private cartProvider: CartProvider,
    private shippingService: ShippingService,
    private taxService: TaxService,
    private orderService: OrderService
  ) {
    // this.cartEditDataHandler = new EditableCartDataHandler(new LoggingService());

    this.context = new CheckoutContext();
  }

  ngOnInit() {

    this.navigationProvider.updateTitle("Checkout");

    combineLatest([
      this.storeFrontProvider.currentStoreFront$,
      this.authIdentityProvider.userIdentity$
    ]).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(([storeFront, authidentity]) => {
      if (authidentity) {
        this.context.identityForm.get('uid').setValue(authidentity.id);

        // Insert test data
        this.context.identityForm.get('firstName').setValue('Bob');
        this.context.identityForm.get('lastName').setValue('Smith');
        this.context.identityForm.get('email').setValue('robert.smith@uptownbar.com');
        this.context.identityForm.get('phoneNumber').setValue('414-807-1089');
        this.context.shippingForm.get('address').get('country').setValue('United States');
        this.context.shippingForm.get('address').get('address1').setValue('1301 Allermann Dr.');
        this.context.shippingForm.get('address').get('city').setValue('Watertown');
        this.context.shippingForm.get('address').get('state').setValue('WI');
        this.context.shippingForm.get('address').get('postalCode').setValue('53094');
        // this.billingForm.get('address').get('country').setValue('United States');
        // this.billingForm.get('address').get('address1').setValue('416 E. Main St.');
        // this.billingForm.get('address').get('city').setValue('Watertown');
        // this.billingForm.get('address').get('state').setValue('WI');
        // this.billingForm.get('address').get('postalCode').setValue('53094');
        this.context.billingForm.get('creditCard').get('name').setValue('Robert Smith');
        this.context.billingForm.get('creditCard').get('number').setValue('5147504001021728');
        this.context.billingForm.get('creditCard').get('expiryMonth').setValue('03');
        this.context.billingForm.get('creditCard').get('expiryYear').setValue('2022');
        this.context.billingForm.get('creditCard').get('csc').setValue('706');
      }
    });

    this.cartProvider.currentCart$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(cart => {
      if (cart) {
        if (cart.cartStatusUid.toUpperCase() == CartStatusKeys.Open.toUpperCase()) {
          //   this.cart = this.cartEditDataHandler.mergeCart(this.cart || new EditableCart(), cart);

          //   this.logisticsForm.get('logisticTypeUid').setValue(cart.logisticTypeUid.toUpperCase());
          //   this.logisticsForm.get('logisticScheduleUid').setValue(cart.logisticScheduleUid.toUpperCase());
          //   this.logisticsForm.get('logisticDateTimeUtc').setValue(cart.logisticDateTimeUtc);

          //   this.logisticsForm.get('logisticTypeUid').valueChanges.subscribe(value => {
          //     this.updateLogistics();
          //   });
          //   this.logisticsForm.get('logisticScheduleUid').valueChanges.subscribe(value => {
          //     this.updateLogistics();
          //   });

          //   this.fetchShipping(cartUid);
          //   this.fetchTax(cartUid);
          // } else {
          //   this.messageModalProvider.open(<MessageModalData>{ title: 'Invalid or Missing Cart', message: 'This cart is no longer available or not valid for checkout. Please start again.' }).afterClosed().subscribe(() => {
          //     this.navigationProvider.navigate(['storefront', this.activatedRoute.snapshot.params['storeFrontUid']]);
          //   });
        }
      }
    })
  }

  // selectDeliveryDate() {

  // }

  // selectDeliveryTime() {

  // }

  goForward() {

    this.stepper.next();
  }

  goToBilling() {

    var cart = this.cart;
    if (cart) {

    }

    this.stepper.next();
  }

  goToOrderReview() {

    var cart = this.cart;
    if (cart) {

    }
  }

  getCreditCardMask() {

    var cardNumber = this.context.billingForm.get('creditCard').get('number').value as string;
    if (cardNumber?.length >= 4) {
      cardNumber = cardNumber.substr(cardNumber.length - 4, 4);

      return `${this.context.billingForm.get('creditCard').get('name').value} [...${cardNumber}]`;
    }

    return null;
  }

  public setPercentTip(percentage: number) {

    this.tip = Math.round(this.totalAmount * percentage / 100 * 100) / 100;
  }

  private fetchShipping(cartUid: string) {

    this.shippingService.getByCartUid(cartUid).subscribe(shippingAmount => {
      this.shippingAmount = shippingAmount;
      this.calculateTotalAmount();
    });
  }

  private fetchTax(cartUid: string) {

    this.taxService.getByCartUid(cartUid).subscribe(taxAmount => {
      this.taxAmount = taxAmount;
      this.calculateTotalAmount();
    });
  }

  private calculateTotalAmount() {

    this.totalAmount = this.cart.subTotalAmount + this.shippingAmount + this.taxAmount;
  }

  // private updateLogistics() {

  //   let logisticTypeUid = this.logisticsForm.get('logisticTypeUid').value;
  //   let logisticScheduleUid = this.logisticsForm.get('logisticScheduleUid').value;

  //   if (logisticTypeUid != this.cart.logisticTypeUid || logisticScheduleUid != this.cart.logisticScheduleUid) {
  //     this.cartService.updateLogistics(
  //       this.cart.uid,
  //       logisticTypeUid,
  //       logisticScheduleUid,
  //       null
  //     ).subscribe(cart => {
  //       // this.cart = new EditableCartDataHandler(new LoggingService()).mergeCart(this.cart, cart);
  //       this.fetchShipping(this.cart.uid);
  //       this.fetchTax(this.cart.uid);
  //     });
  //   }
  // }

  completeCheckout() {

    const identityForm = <UntypedFormGroup>this.context.identityForm;
    const billingForm = <UntypedFormGroup>this.context.billingForm;
    const shippingAddressForm = <UntypedFormGroup>this.context.shippingForm.get('address');
    const billingAddressForm = <UntypedFormGroup>this.context.billingForm.get('address');
    const creditCardForm = <UntypedFormGroup>this.context.billingForm.get('creditCard');

    let identity = <IdentityInput>{
      uid: identityForm.get('uid').value,
      firstName: identityForm.get('firstName').value,
      lastName: identityForm.get('lastName').value,
      company: identityForm.get('company').value,
      email: identityForm.get('email').value,
      phoneNumber: identityForm.get('phoneNumber').value
    };

    let shippingAddress = <AddressInput>{
      uid: shippingAddressForm.get('uid').value,
      country: shippingAddressForm.get('country').value,
      address1: shippingAddressForm.get('address1').value,
      city: shippingAddressForm.get('city').value,
      state: shippingAddressForm.get('state').value,
      postalCode: shippingAddressForm.get('postalCode').value
    };

    let billingAddress = (<UntypedFormGroup>billingForm).get('isSameAsShipping').value ? shippingAddress : <AddressInput>{
      uid: billingAddressForm.get('uid').value,
      country: billingAddressForm.get('country').value,
      address1: billingAddressForm.get('address1').value,
      city: billingAddressForm.get('city').value,
      state: billingAddressForm.get('state').value,
      postalCode: billingAddressForm.get('postalCode').value
    };

    let creditCard = <CreditCardInput>{
      name: creditCardForm.get('name').value,
      number: creditCardForm.get('number').value,
      expiryMonth: creditCardForm.get('expiryMonth').value,
      expiryYear: creditCardForm.get('expiryYear').value,
      csc: creditCardForm.get('csc').value,
      amount: this.totalAmount,
      tip: !this.tip || this.tip == 0 ? null : this.tip,
    };

    var orderInput = {
      uid: UUID.UUID(),
      cartUid: this.cart.uid,
      initiatorIdentityUid: identity.uid,
      // logisticTypeUid: this.logisticsForm.get('logisticTypeUid').value,
      // logisticScheduleUid: this.logisticsForm.get('logisticScheduleUid').value,
      // logisticDateTimeUtc: this.logisticsForm.get('logisticDateTimeUtc').value,
      // logisticPaymentMethodUid: this.logisticsForm.get('logisticPaymentMethodUid').value,
      shippingAddressUid: shippingAddress.uid,
      shippingPhoneNumber: identityForm.get('phoneNumber').value,
      billingAddressUid: billingAddress.uid,
      billingPhoneNumber: identityForm.get('phoneNumber').value,

      identities: [identity],
      addresses: [shippingAddress, billingAddress],
      creditCards: [creditCard]
    };

    this.authIdentityProvider.userIdentity$.subscribe(authIdentity => {
      this.orderService.createFromCart(orderInput).subscribe(order => {
        if (order) {
          this.stepper.steps.forEach(step => {
            if (step != this.stepper.steps.last) {
              step.editable = false;
            }
          });

          this.goForward();
        }
      });
    });
  }

  private round(date: Date, durationMs: number): Date {

    let dateMs = date.getTime();

    return new Date(Math.ceil((+dateMs) / (+durationMs)) * (+durationMs));
  }
}
