export function assertIsNull(value: any, message: string) {

    if (value != null) {
        throw message;
    }
}

export function assertIsNotNull(value: any, message: string) {

    if (value == null) {
        throw message;
    }
}
