import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { BlobServerSettings, isEqualUUID } from "core";
import { IPagedDataSource } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { ProductStatusKeys } from "../../keys";
import { Product } from "../../models/product";
import { ProductModelFactory } from "../../product-model-factory";
import { ProductService, ProductViewOptions } from "../../services/product.service";
import { TenantProvider } from 'core';
import { ProductProvider } from "../../providers";

@Component({
  selector: 'app-products-table',
  templateUrl: './products-table.component.html',
  styleUrls: ['./products-table.component.scss']
})
export class ProductsTableComponent {

  @Input() public dataSource: ProductDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {

  }
}


export class ProductModel {

  product: Product;

  imageUrl: string;
  canEdit: boolean;
  canEnable: boolean;
  canDisable: boolean;
  canDelete: boolean;
}

export class ProductDataSource extends DataSource<ProductModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<ProductModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<ProductModel[]>([]);
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private blobServerSettings: BlobServerSettings,
    private productService: ProductService,
    private productProvider: ProductProvider,
    private tenantProvider: TenantProvider,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<ProductModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<ProductModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(ownerUid: string, departmentUids: string[], categoryUids: string[], statusUids: string[], paginationInput: PaginationInput, viewOptions: ProductViewOptions) {

    this.loadingSubject.next(true);

    var blobServerBaseUrl = `${this.blobServerSettings.apiUrl}/api/blob/${this.blobServerSettings.apiOwnerUid}`;
    this.productProvider.search$(this.tenantProvider.currentUid, departmentUids, categoryUids, statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        const products = page.edges.map(x => x.node).map(x => {
          let product = <ProductModel>Object.assign(new ProductModel(), {
            product: x
          });

          if (product.product.imageUid) {
            product.imageUrl = product.product.imageUid ? `${blobServerBaseUrl}/${product.product.imageUid}?height=40` : null
          }

          this.evaluateEnablement(product);

          return product;
        });

        return <[PageInfo, ProductModel[]]>[page.pageInfo, products];
      }),
      catchError(() => of(<[PageInfo, ProductModel[]]>[null, []])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  public merge(product: Product) {

    if (product) {
      var match = this.dataSubject.value.find(x => x.product.uid.toLowerCase() == product.uid.toLowerCase());
      if (match) {
        ProductModelFactory.mergeProduct(product, match.product);
        this.evaluateEnablement(match);
        this.evaluateBulkEnablement();
      }
    }
  }

  public enable(product: ProductModel): Observable<ProductModel> {

    return this.productService.enable(product.product.uid).pipe(
      map(x => {
        product.product.productStatusUid = x.productStatusUid;

        return this.evaluateEnablement(product);
      })
    );
  }

  public disable(product: ProductModel): Observable<ProductModel> {

    return this.productService.disable(product.product.uid).pipe(
      map(x => {
        product.product.productStatusUid = x.productStatusUid;

        return this.evaluateEnablement(product);
      })
    );
  }

  public delete(product: ProductModel): Observable<ProductModel> {

    return this.productService.delete(product.product.uid).pipe(
      map(x => {
        product.product.productStatusUid = x.productStatusUid;

        return this.evaluateEnablement(product);
      })
    );
  }

  private evaluateEnablement(product: ProductModel): ProductModel {

    let productStatusUid = product.product.productStatusUid.toUpperCase();

    product.canEdit = !isEqualUUID(productStatusUid, ProductStatusKeys.Deleted);
    product.canDelete = !isEqualUUID(productStatusUid, ProductStatusKeys.Deleted);
    product.canEnable = isEqualUUID(productStatusUid, ProductStatusKeys.Inactive);
    product.canDisable = isEqualUUID(productStatusUid, ProductStatusKeys.Active);

    return product;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEdit = selected.length == 1;
    this._canBulkDelete = selected.length == 1;
  }
}
