import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { IPagedDataSource } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { CategoryService, CategoryViewOptions } from "../../services/category.service";
import { TenantProvider } from 'core';

@Component({
  selector: 'app-categories-table',
  templateUrl: './categories-table.component.html',
  styleUrls: ['./categories-table.component.scss']
})
export class CategoriesTableComponent {

  @Input() public dataSource: CategoryDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }
}

export class CategoryModel {

  uid: string;
  name: string;
  description: string;
  departmentUid: boolean;
  categoryStatusUid: string;

  canEdit: boolean;
  canEnable: boolean;
  canDisable: boolean;
  canDelete: boolean;
}

export class CategoryDataSource extends DataSource<CategoryModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<CategoryModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<CategoryModel[]>([]);
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private categoryService: CategoryService,
    private tenantProvider: TenantProvider,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<CategoryModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<CategoryModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(departmentUid: string, statusUids: string[], paginationInput: PaginationInput, viewOptions: CategoryViewOptions) {

    this.loadingSubject.next(true);

    this.categoryService.search(this.tenantProvider.currentUid, [departmentUid], statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        const categories = page.edges.map(x => x.node).map(x => {
          let categoryModel = Object.assign(new CategoryModel(), {
            uid: x.uid,
            name: x.name,
            description: x.description,
            departmentUid: x.departmentUid,
            categoryStatusUid: x.categoryStatusUid,
          });

          this.evaluateEnablement(categoryModel);

          return categoryModel;
        });

        return <[PageInfo, CategoryModel[]]>[page.pageInfo, categories];
      }),
      catchError(() => of(<[PageInfo, CategoryModel[]]>[null, []])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  public enable(category: CategoryModel): Observable<CategoryModel> {

    return this.categoryService.enable(category.uid).pipe(
      map(x => {
        category.categoryStatusUid = x.categoryStatusUid;

        return this.evaluateEnablement(category);
      })
    );
  }

  public disable(category: CategoryModel): Observable<CategoryModel> {

    return this.categoryService.disable(category.uid).pipe(
      map(x => {
        category.categoryStatusUid = x.categoryStatusUid;

        return this.evaluateEnablement(category);
      })
    );
  }

  public delete(category: CategoryModel): Observable<CategoryModel> {

    return this.categoryService.delete(category.uid).pipe(
      map(x => {
        category.categoryStatusUid = x.categoryStatusUid;

        return this.evaluateEnablement(category);
      })
    );
  }

  private evaluateEnablement(category: CategoryModel): CategoryModel {

    let categoryStatusUid = category.categoryStatusUid.toUpperCase();

    category.canEdit = true;
    category.canDelete = true;

    return category;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEdit = selected.length == 1;
    this._canBulkDelete = selected.length == 1;
  }
}
