import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { findInArrayByUid } from "core";
import { ProductConfigurationProductReference, ProductConfigurationPortion, ProductConfigurationInclusionGroup, ProductConfigurationInclusionGroupOption, ProductConfigurationAddOn, ProductConfigurationPreparation, ProductConfigurationPreparationOption, ProductConfigurationVariation, ProductConfigurationVariationOption } from "../models";

const removePortionScope = (x: any) => {
  const value = Object.assign({}, x);
  delete value.portion;

  return value;
};

export const ConfigurationFuncs = {

  validProductReference(productReference: ProductConfigurationProductReference) {

    return productReference != null && productReference.uid != null && productReference.portionUid != null;
  },

  portions: {

    getPortionsArray(parent: { portions: ProductConfigurationPortion[] }): ProductConfigurationPortion[] {

      return parent?.portions;
    },

    getPortionForm(parent: { portions: ProductConfigurationPortion[] }, portionUid: string): ProductConfigurationPortion {

      return findInArrayByUid(ConfigurationFuncs.portions.getPortionsArray(parent), portionUid.toLowerCase());
    },

    getPortionValue(parent: { portions: ProductConfigurationPortion[] }, portionUid: string, key: string) {

      return ConfigurationFuncs.portions.getPortionForm(parent, portionUid)?.[key as keyof ProductConfigurationPortion];
    }
  },

  inclusionGroups: {

    getInclusionGroupsArray(parent: { inclusionGroups?: ProductConfigurationInclusionGroup[] }): ProductConfigurationInclusionGroup[] {

      return parent?.inclusionGroups;
    },

    getInclusionGroup(parent: { inclusionGroups?: ProductConfigurationInclusionGroup[] }, inclusionGroupUid: string): ProductConfigurationInclusionGroup {

      return findInArrayByUid(ConfigurationFuncs.inclusionGroups.getInclusionGroupsArray(parent), inclusionGroupUid.toLowerCase());
    },

    getInclusionGroupValue(parent: { inclusionGroups?: ProductConfigurationInclusionGroup[] }, inclusionGroupUid: string, key: string) {

      return ConfigurationFuncs.inclusionGroups.getInclusionGroup(parent, inclusionGroupUid)?.[key as keyof ProductConfigurationInclusionGroup];
    }
  },

  inclusionGroupOptions: {

    getInclusionGroupOptionsArray(parent: { options?: ProductConfigurationInclusionGroupOption[] }): ProductConfigurationInclusionGroupOption[] {

      return parent?.options;
    },

    getInclusionGroupOption(parent: { options?: ProductConfigurationInclusionGroupOption[] }, optionUid: string): ProductConfigurationInclusionGroupOption {

      return findInArrayByUid(ConfigurationFuncs.inclusionGroupOptions.getInclusionGroupOptionsArray(parent), optionUid.toLowerCase());
    },

    getInclusionGroupValue(parent: { options?: ProductConfigurationInclusionGroupOption[] }, optionUid: string, key: string) {

      return ConfigurationFuncs.inclusionGroupOptions.getInclusionGroupOption(parent, optionUid)?.[key as keyof ProductConfigurationInclusionGroupOption];
    }
  },

  addOns: {

    getAddOnsArray(parent: { addOns?: ProductConfigurationAddOn[] }): ProductConfigurationAddOn[] {

      return parent?.addOns;
    },

    getAddOn(parent: { addOns?: ProductConfigurationAddOn[] }, addOnUid: string): ProductConfigurationAddOn {

      return findInArrayByUid(ConfigurationFuncs.addOns.getAddOnsArray(parent), addOnUid.toLowerCase());
    },

    getAddOnValue(parent: { addOns?: ProductConfigurationAddOn[] }, addOnUid: string, key: string) {

      return ConfigurationFuncs.addOns.getAddOn(parent, addOnUid)?.[key as keyof ProductConfigurationAddOn];
    }
  },

  preparations: {

    getPreparation(parent: { preparations?: ProductConfigurationPreparation[] }, preparationUid: string): ProductConfigurationPreparation {

      return findInArrayByUid(parent.preparations, preparationUid.toLowerCase());
    },

    getPreparationValue(parent: { preparations?: ProductConfigurationPreparation[] }, preparationUid: string, key: string) {

      return ConfigurationFuncs.preparations.getPreparation(parent, preparationUid)?.[key as keyof ProductConfigurationPreparation];
    }
  },

  preparationOptions: {

    getPreparationOptionsArray(parent: { options?: ProductConfigurationPreparationOption[] }): ProductConfigurationPreparationOption[] {

      return parent?.options;
    },

    getPreparationOption(parent: { options?: ProductConfigurationPreparationOption[] }, optionUid: string): ProductConfigurationPreparationOption {

      return findInArrayByUid(ConfigurationFuncs.preparationOptions.getPreparationOptionsArray(parent), optionUid.toLowerCase());
    },

    getPreparationValue(parent: { options?: ProductConfigurationPreparationOption[] }, optionUid: string, key: string) {

      return ConfigurationFuncs.preparationOptions.getPreparationOption(parent, optionUid)?.[key as keyof ProductConfigurationPreparationOption];
    }
  },

  variations: {

    getVariation(parent: { variations?: ProductConfigurationVariation[] }, variationUid: string): ProductConfigurationVariation {

      return findInArrayByUid(parent.variations, variationUid.toLowerCase());
    },

    getVariationValue(parent: { variations?: ProductConfigurationVariation[] }, variationUid: string, key: string) {

      return ConfigurationFuncs.variations.getVariation(parent, variationUid)?.[key as keyof ProductConfigurationVariation];
    }
  },

  variationOptions: {

    getVariationOptionsArray(parent: { options?: ProductConfigurationVariationOption[] }): ProductConfigurationVariationOption[] {

      return parent?.options;
    },

    getVariationOption(parent: { options?: ProductConfigurationVariationOption[] }, optionUid: string): ProductConfigurationVariationOption {

      return findInArrayByUid(ConfigurationFuncs.variationOptions.getVariationOptionsArray(parent), optionUid.toLowerCase());
    },

    getVariationValue(parent: { options?: ProductConfigurationVariationOption[] }, optionUid: string, key: string) {

      return ConfigurationFuncs.variationOptions.getVariationOption(parent, optionUid)?.[key as keyof ProductConfigurationVariationOption];
    }
  },
};

export const ConfigurationFormFuncs = {

  resolveProductReference(productForm: UntypedFormGroup, scopes: { [scope: string]: string }): ProductConfigurationProductReference {

    if (scopes) {
      if (scopes['portion']) {
         if (scopes['inclusionGroup']) {
          if (scopes['inclusionGroupOption']) {
            var value = ConfigurationFormFuncs.resolveFormValue(productForm, null, 'productReference', scopes);
            return ConfigurationFuncs.validProductReference(value) ? value : ConfigurationFormFuncs.resolveProductReference(productForm, removePortionScope(scopes));
          }
        } else if (scopes['addOn']) {
          var value = ConfigurationFormFuncs.resolveFormValue(productForm, null, 'productReference', scopes);
          return ConfigurationFuncs.validProductReference(value) ? value : ConfigurationFormFuncs.resolveProductReference(productForm, removePortionScope(scopes));
        }
      } else {
        if (scopes['inclusionGroup']) {
          if (scopes['inclusionGroupOption']) {
            return ConfigurationFormFuncs.resolveFormValue(productForm, null, 'productReference', scopes);
          }
        } else if (scopes['addOn']) {
          return ConfigurationFormFuncs.resolveFormValue(productForm, null, 'productReference', scopes);
        }
      }
    }

    return null;
  },

  resolveFormValue(productForm: UntypedFormGroup, form: UntypedFormGroup, key: string, scopes: { [scope: string]: string }): any {

    if (scopes) {
      if (scopes['portion']) {
        var portionForm = ConfigurationFormFuncs.portions.getPortionForm(productForm, scopes['portion']);

        if (scopes['inclusionGroup']) {
          if (scopes['inclusionGroupOption']) {
            var value = ConfigurationFormFuncs.inclusionGroupOptions.getInclusionGroupOptionFormValue(portionForm, scopes['inclusionGroup'], scopes['inclusionGroupOption'], key);
            return value ? value : ConfigurationFormFuncs.resolveFormValue(productForm, form, key, removePortionScope(scopes));
          } else {
            var value = ConfigurationFormFuncs.inclusionGroups.getInclusionGroupFormValue(portionForm, scopes['inclusionGroup'], key);
            return value ? value : ConfigurationFormFuncs.resolveFormValue(productForm, form, key, removePortionScope(scopes));
          }
        } else if (scopes['addOn']) {
          var value = ConfigurationFormFuncs.addOns.getAddOnFormValue(portionForm, scopes['addOn'], key);
          return value ? value : ConfigurationFormFuncs.resolveFormValue(productForm, form, key, removePortionScope(scopes));
        } else if (scopes['preparation']) {
          var value = ConfigurationFormFuncs.preparations.getPreparationFormValue(portionForm, scopes['preparation'], key);
          return value ? value : ConfigurationFormFuncs.resolveFormValue(productForm, form, key, removePortionScope(scopes));
        } else if (scopes['variation']) {
          var value = ConfigurationFormFuncs.variations.getVariationFormValue(portionForm, scopes['variation'], key);
          return value ? value : ConfigurationFormFuncs.resolveFormValue(productForm, form, key, removePortionScope(scopes));
        }
      } else {
        if (scopes['inclusionGroup']) {
          if (scopes['inclusionGroupOption']) {
            return ConfigurationFormFuncs.inclusionGroupOptions.getInclusionGroupOptionFormValue(productForm, scopes['inclusionGroup'], scopes['inclusionGroupOption'], key);
          } else {
            return ConfigurationFormFuncs.inclusionGroups.getInclusionGroupFormValue(productForm, scopes['inclusionGroup'], key);
          }
        } else if (scopes['addOn']) {
          return ConfigurationFormFuncs.addOns.getAddOnFormValue(productForm, scopes['addOn'], key);
        } else if (scopes['preparation']) {
          return ConfigurationFormFuncs.preparations.getPreparationFormValue(productForm, scopes['preparation'], key);
        } else if (scopes['variation']) {
          return ConfigurationFormFuncs.variations.getVariationFormValue(productForm, scopes['variation'], key);
        }
      }
    } else {

      if (form.get(key).value) {
        return form.get(key).value;
      }
    }
  },

  configuration: {

    getTopParent(form: UntypedFormGroup): AbstractControl {

      var parentForm = <AbstractControl>form;
      while (parentForm.parent != null) {
        parentForm = parentForm.parent;
      }

      return parentForm;
    },

    getValueOrUnknown(value: any): any {

      return value == null || value == '' ? 'Unknown' : value;
    }
  },

  portions: {

    getPortionsFormArray(form: UntypedFormGroup): UntypedFormArray {

      return getFormArray(form, 'portions');
    },

    getPortionForm(form: UntypedFormGroup, portionUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.portions.getPortionsFormArray(form), portionUid.toLowerCase());
    },

    getPortionFormControl(form: UntypedFormGroup, portionUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.portions.getPortionForm(form, portionUid), key);
    },

    getPortionFormValue(form: UntypedFormGroup, portionUid: string, key: string) {

      return ConfigurationFormFuncs.portions.getPortionFormControl(form, portionUid, key)?.value;
    }
  },

  inclusionGroups: {

    getLinkableProductInclusionGroupFormsForPortionForm(productForm: UntypedFormGroup, portionForm: UntypedFormGroup): UntypedFormGroup[] {

      const productInclusionGroupsArray = ConfigurationFormFuncs.inclusionGroups.getInclusionGroupsFormArray(productForm);
      if (productInclusionGroupsArray) {
        const portionInclusionGroupsArray = <UntypedFormArray>portionForm.get('inclusionGroups');
        if (portionInclusionGroupsArray) {
          const portionInclusionGroupUids = portionInclusionGroupsArray.controls.map(x => <string>x.get('uid').value);
          return <UntypedFormGroup[]>productInclusionGroupsArray.controls.filter(x => portionInclusionGroupUids.indexOf(<string>x.get('uid').value) == -1);
        }
      }

      return [];
    },

    getInclusionGroupsFormArray(form: UntypedFormGroup): UntypedFormArray {

      return getFormArray(form, 'inclusionGroups');
    },

    getInclusionGroupForm(form: UntypedFormGroup, inclusionGroupUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.inclusionGroups.getInclusionGroupsFormArray(form), inclusionGroupUid.toLowerCase());
    },

    getInclusionGroupFormControl(form: UntypedFormGroup, inclusionGroupUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.inclusionGroups.getInclusionGroupForm(form, inclusionGroupUid), key);
    },

    getInclusionGroupFormValue(form: UntypedFormGroup, inclusionGroupUid: string, key: string) {

      return ConfigurationFormFuncs.inclusionGroups.getInclusionGroupFormControl(form, inclusionGroupUid, key)?.value;
    },
  },

  inclusionGroupOptions: {

    getLinkableProductInclusionGroupOptionFormsForInclusionGroupForm(productForm: UntypedFormGroup, inclusionGroupForm: UntypedFormGroup): UntypedFormGroup[] {

      var portionOptionsArray = <UntypedFormArray>inclusionGroupForm.get('options');

      var inclusionGroupUid = inclusionGroupForm.get('uid').value;

      const productOptionsArray = ConfigurationFormFuncs.inclusionGroupOptions.getInclusionGroupOptionsFormArray(productForm, inclusionGroupUid);
      if (productOptionsArray) {
        const portionOptionUids = portionOptionsArray.controls.map(x => <string>x.get('uid').value);
        return <UntypedFormGroup[]>productOptionsArray.controls.filter(x => portionOptionUids.indexOf(<string>x.get('uid').value) == -1);
      }

      return [];
    },

    resolveProductInclusionGroupOptionFormValue(productForm: UntypedFormGroup, form: UntypedFormGroup, key: string) {

      var inclusionGroupUid = form.parent.parent.get('uid').value;

      if (form.get(key).value) {
        return form.get(key).value;
      } else {
        return ConfigurationFormFuncs.inclusionGroupOptions.getInclusionGroupOptionFormValue(productForm, inclusionGroupUid, form.get('uid').value, key);
      }
    },

    getInclusionGroupOptionsFormArray(form: UntypedFormGroup, inclusionGroupUid: string): UntypedFormArray {

      return getFormArray(ConfigurationFormFuncs.inclusionGroups.getInclusionGroupForm(form, inclusionGroupUid), 'options');
    },

    getInclusionGroupOptionForm(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.inclusionGroupOptions.getInclusionGroupOptionsFormArray(form, inclusionGroupUid), optionUid.toLowerCase());
    },

    getInclusionGroupOptionFormControl(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.inclusionGroupOptions.getInclusionGroupOptionForm(form, inclusionGroupUid, optionUid), key);
    },

    getInclusionGroupOptionFormValue(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, key: string) {

      return ConfigurationFormFuncs.inclusionGroupOptions.getInclusionGroupOptionFormControl(form, inclusionGroupUid, optionUid, key)?.value;
    }
  },

  addOns: {

    getLinkableProductAddOnFormsForPortionForm(productForm: UntypedFormGroup, portionForm: UntypedFormGroup): UntypedFormGroup[] {

      const productExtrasArray = ConfigurationFormFuncs.addOns.getAddOnsFormArray(productForm);
      if (productExtrasArray) {
        const portionExtrasArray = <UntypedFormArray>portionForm.get('addOns');
        if (portionExtrasArray) {
          const portionExtraUids = portionExtrasArray.controls.map(x => <string>x.get('uid').value);
          return <UntypedFormGroup[]>productExtrasArray.controls.filter(x => portionExtraUids.indexOf(<string>x.get('uid').value) == -1);
        }
      }

      return [];
    },

    resolveProductAddOnFormValue(productForm: UntypedFormGroup, form: UntypedFormGroup, key: string) {

      if (form.get(key).value) {
        return form.get(key).value;
      } else {
        return ConfigurationFormFuncs.addOns.getAddOnFormValue(productForm, form.get('uid').value, key);
      }
    },

    getAddOnsFormArray(form: UntypedFormGroup): UntypedFormArray {

      return getFormArray(form, 'addOns');
    },

    getAddOnForm(form: UntypedFormGroup, addOnUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.addOns.getAddOnsFormArray(form), addOnUid.toLowerCase());
    },

    getAddOnFormControl(form: UntypedFormGroup, addOnUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.addOns.getAddOnForm(form, addOnUid), key);
    },

    getAddOnFormValue(form: UntypedFormGroup, addOnUid: string, key: string) {

      return ConfigurationFormFuncs.addOns.getAddOnFormControl(form, addOnUid, key)?.value;
    }
  },

  preparations: {

    getLinkableProductPreparationFormsForPortionForm(productForm: UntypedFormGroup, portionForm: UntypedFormGroup): UntypedFormGroup[] {

      const productPreparationsArray = ConfigurationFormFuncs.preparations.getPreparationsFormArray(productForm);
      if (productPreparationsArray) {
        const portionPreparationsArray = <UntypedFormArray>portionForm.get('preparations');
        if (portionPreparationsArray) {
          const portionPreparationUids = portionPreparationsArray.controls.map(x => <string>x.get('uid').value);
          return <UntypedFormGroup[]>productPreparationsArray.controls.filter(x => portionPreparationUids.indexOf(<string>x.get('uid').value) == -1);
        }
      }

      return [];
    },

    resolvePreparationFormValue(productForm: UntypedFormGroup, form: UntypedFormGroup, key: string, scope: string): any {

      if (form.get(key).value) {
        return form.get(key).value;
      } else {
        if (scope == 'productInclusionGroup') {
          let inclusionGroupUid = form.parent.parent.parent.parent.get('uid').value;
          let optionUid = form.parent.parent.get('uid').value;
          let preparationUid = form.get('uid').value;

          let value = ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationFormValue(productForm, inclusionGroupUid, optionUid, preparationUid, key);
          return value ? value : ConfigurationFormFuncs.preparations.resolvePreparationFormValue(productForm, form, key, 'product');
        } else if (scope == 'portionInclusionGroup') {
          let inclusionGroupUid = form.parent.parent.parent.parent.get('uid').value;
          let optionUid = form.parent.parent.get('uid').value;
          let preparationUid = form.get('uid').value;

          let value = ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationFormValue(productForm, inclusionGroupUid, optionUid, preparationUid, key);
          return value ? value : ConfigurationFormFuncs.preparations.resolvePreparationFormValue(productForm, form, key, 'productInclusionGroup');
        } else {
          return ConfigurationFormFuncs.preparations.getPreparationFormValue(productForm, form.get('uid').value, key);
        }
      }
    },

    getPreparationsFormArray(form: UntypedFormGroup): UntypedFormArray {

      return getFormArray(form, 'preparations');
    },

    getPreparationForm(form: UntypedFormGroup, preparationUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.preparations.getPreparationsFormArray(form), preparationUid.toLowerCase());
    },

    getPreparationFormControl(form: UntypedFormGroup, preparationUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.preparations.getPreparationForm(form, preparationUid), key);
    },

    getPreparationFormValue(form: UntypedFormGroup, preparationUid: string, key: string) {

      return ConfigurationFormFuncs.preparations.getPreparationFormControl(form, preparationUid, key)?.value;
    },

    // Product
    // getPreparationsFormArray(form: FormGroup): FormArray {

    //   return getFormArray(form, 'preparations');
    // },

    // getPreparationForm(form: FormGroup, preparationUid: string): FormGroup {

    //   return findFormInFormArray(ProductFuncs.preparations.getPreparationsFormArray(form), preparationUid.toLowerCase());
    // },

    // getPreparationFormControl(form: FormGroup, preparationUid: string, key: string): FormControl {

    //   return findFormControl(ProductFuncs.preparations.getPreparationForm(form, preparationUid), key);
    // },

    // getPreparationFormValue(form: FormGroup, preparationUid: string, key: string) {

    //   return ProductFuncs.preparations.getPreparationFormControl(form, preparationUid, key)?.value;
    // },

    getPreparationOptionsFormArray(form: UntypedFormGroup, preparationUid: string): UntypedFormArray {

      return getFormArray(ConfigurationFormFuncs.preparations.getPreparationForm(form, preparationUid), 'options');
    },

    getPreparationOptionForm(form: UntypedFormGroup, preparationUid: string, optionUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.preparations.getPreparationOptionsFormArray(form, preparationUid), optionUid.toLowerCase());
    },

    getPreparationOptionFormControl(form: UntypedFormGroup, preparationUid: string, optionUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.preparations.getPreparationOptionForm(form, preparationUid, optionUid), key);
    },

    getPreparationOptionFormValue(form: UntypedFormGroup, preparationUid: string, optionUid: string, key: string) {

      return ConfigurationFormFuncs.preparations.getPreparationOptionFormControl(form, preparationUid, optionUid, key)?.value;
    },

    // ProductInclusionGroupOption
    getProductInclusionGroupOptionPreparationsFormArray(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string): UntypedFormArray {

      return getFormArray(ConfigurationFormFuncs.inclusionGroupOptions.getInclusionGroupOptionForm(form, inclusionGroupUid, optionUid), 'preparations');
    },

    getProductInclusionGroupOptionPreparationForm(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, preparationUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationsFormArray(form, inclusionGroupUid, optionUid), preparationUid.toLowerCase());
    },

    getProductInclusionGroupOptionPreparationFormControl(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, preparationUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationForm(form, inclusionGroupUid, optionUid, preparationUid), key);
    },

    getProductInclusionGroupOptionPreparationFormValue(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, preparationUid: string, key: string) {

      return ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationFormControl(form, inclusionGroupUid, optionUid, preparationUid, key)?.value;
    },

    getProductInclusionGroupOptionPreparationOptionsFormArray(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, preparationUid: string): UntypedFormArray {

      return getFormArray(ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationForm(form, inclusionGroupUid, optionUid, preparationUid), 'options');
    },

    getProductInclusionGroupOptionPreparationOptionForm(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, preparationUid: string, preparationOptionUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationOptionsFormArray(form, inclusionGroupUid, optionUid, preparationUid), preparationOptionUid.toLowerCase());
    },

    getProductInclusionGroupOptionPreparationOptionFormControl(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, preparationUid: string, preparationOptionUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationOptionForm(form, inclusionGroupUid, optionUid, preparationUid, preparationOptionUid), key);
    },

    getProductInclusionGroupOptionPreparationOptionFormValue(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, preparationUid: string, preparationOptionUid: string, key: string) {

      return ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationOptionFormControl(form, inclusionGroupUid, optionUid, preparationUid, preparationOptionUid, key)?.value;
    }
  },

  variations: {

    getLinkableProductVariationFormsForPortionForm(productForm: UntypedFormGroup, portionForm: UntypedFormGroup): UntypedFormGroup[] {

      const productVariationsArray = ConfigurationFormFuncs.variations.getVariationsFormArray(productForm);
      if (productVariationsArray) {
        const portionVariationsArray = <UntypedFormArray>portionForm.get('variations');
        if (portionVariationsArray) {
          const portionVariationUids = portionVariationsArray.controls.map(x => <string>x.get('uid').value);
          return <UntypedFormGroup[]>productVariationsArray.controls.filter(x => portionVariationUids.indexOf(<string>x.get('uid').value) == -1);
        }
      }

      return [];
    },

    resolveVariationFormValue(productForm: UntypedFormGroup, form: UntypedFormGroup, key: string, scope: string): any {

      if (form.get(key).value) {
        return form.get(key).value;
      } else {
        if (scope == 'productInclusionGroup') {
          let inclusionGroupUid = form.parent.parent.parent.parent.get('uid').value;
          let optionUid = form.parent.parent.get('uid').value;
          let variationUid = form.get('uid').value;

          let value = ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationFormValue(productForm, inclusionGroupUid, optionUid, variationUid, key);
          return value ? value : ConfigurationFormFuncs.variations.resolveVariationFormValue(productForm, form, key, 'product');
        } else if (scope == 'portionInclusionGroup') {
          let inclusionGroupUid = form.parent.parent.parent.parent.get('uid').value;
          let optionUid = form.parent.parent.get('uid').value;
          let variationUid = form.get('uid').value;

          let value = ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationFormValue(productForm, inclusionGroupUid, optionUid, variationUid, key);
          return value ? value : ConfigurationFormFuncs.variations.resolveVariationFormValue(productForm, form, key, 'productInclusionGroup');
        } else {
          return ConfigurationFormFuncs.variations.getVariationFormValue(productForm, form.get('uid').value, key);
        }
      }
    },

    getVariationsFormArray(form: UntypedFormGroup): UntypedFormArray {

      return getFormArray(form, 'variations');
    },

    getVariationForm(form: UntypedFormGroup, variationUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.variations.getVariationsFormArray(form), variationUid.toLowerCase());
    },

    getVariationFormControl(form: UntypedFormGroup, variationUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.variations.getVariationForm(form, variationUid), key);
    },

    getVariationFormValue(form: UntypedFormGroup, variationUid: string, key: string) {

      return ConfigurationFormFuncs.variations.getVariationFormControl(form, variationUid, key)?.value;
    },

    // Product
    // getVariationsFormArray(form: FormGroup): FormArray {

    //   return getFormArray(form, 'variations');
    // },

    // getVariationForm(form: FormGroup, variationUid: string): FormGroup {

    //   return findFormInFormArray(ProductFuncs.variations.getVariationsFormArray(form), variationUid.toLowerCase());
    // },

    // getVariationFormControl(form: FormGroup, variationUid: string, key: string): FormControl {

    //   return <FormControl>ProductFuncs.variations.getVariationForm(form, variationUid)?.get(key);
    // },

    // getVariationFormValue(form: FormGroup, variationUid: string, key: string) {

    //   return ProductFuncs.variations.getVariationFormControl(form, variationUid, key)?.value;
    // },

    getVariationOptionsFormArray(form: UntypedFormGroup, variationUid: string): UntypedFormArray {

      return getFormArray(ConfigurationFormFuncs.variations.getVariationForm(form, variationUid), 'options');
    },

    getVariationOptionForm(form: UntypedFormGroup, variationUid: string, optionUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.variations.getVariationOptionsFormArray(form, variationUid), optionUid.toLowerCase());
    },

    getVariationOptionFormControl(form: UntypedFormGroup, variationUid: string, optionUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.variations.getVariationOptionForm(form, variationUid, optionUid), key);
    },

    getVariationOptionFormValue(form: UntypedFormGroup, variationUid: string, optionUid: string, key: string) {

      return ConfigurationFormFuncs.variations.getVariationOptionFormControl(form, variationUid, optionUid, key)?.value;
    },


    // ProductInclusionGroupOption
    getProductInclusionGroupOptionVariationsFormArray(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string): UntypedFormArray {

      return getFormArray(ConfigurationFormFuncs.inclusionGroupOptions.getInclusionGroupOptionForm(form, inclusionGroupUid, optionUid), 'preparations');
    },

    getProductInclusionGroupOptionVariationForm(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, variationUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationsFormArray(form, inclusionGroupUid, optionUid), variationUid.toLowerCase());
    },

    getProductInclusionGroupOptionVariationFormControl(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, variationUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationForm(form, inclusionGroupUid, optionUid, variationUid), key);
    },

    getProductInclusionGroupOptionVariationFormValue(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, variationUid: string, key: string) {

      return ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationFormControl(form, inclusionGroupUid, optionUid, variationUid, key)?.value;
    },

    getProductInclusionGroupOptionVariationOptionsFormArray(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, variationUid: string): UntypedFormArray {

      return getFormArray(ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationForm(form, inclusionGroupUid, optionUid, variationUid), 'options');
    },

    getProductInclusionGroupOptionVariationOptionForm(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, variationUid: string, preparationOptionUid: string): UntypedFormGroup {

      return findFormInFormArray(ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationOptionsFormArray(form, inclusionGroupUid, optionUid, variationUid), preparationOptionUid.toLowerCase());
    },

    getProductInclusionGroupOptionVariationOptionFormControl(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, variationUid: string, variationOptionUid: string, key: string): UntypedFormControl {

      return findFormControl(ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationOptionForm(form, inclusionGroupUid, optionUid, variationUid, variationOptionUid), key);
    },

    getProductInclusionGroupOptionVariationOptionFormValue(form: UntypedFormGroup, inclusionGroupUid: string, optionUid: string, preparationUid: string, variationOptionUid: string, key: string) {

      return ConfigurationFormFuncs.variations.getProductInclusionGroupOptionVariationOptionFormControl(form, inclusionGroupUid, optionUid, preparationUid, variationOptionUid, key)?.value;
    }
  }
}

const getFormArray = function (form: UntypedFormGroup | AbstractControl, name: string): UntypedFormArray {

  return form instanceof UntypedFormGroup ? form.get(name) as UntypedFormArray : null;
}

const findFormInFormArray = function (formArray: UntypedFormArray, uid: string): UntypedFormGroup {

  return formArray?.controls.find(x => (<string>x.get('uid').value).toLowerCase() == uid) as UntypedFormGroup;
}

const findFormControl = function (form: UntypedFormGroup, key: string): UntypedFormControl {

  return form?.get(key) as UntypedFormControl;
}
