import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethodService } from '../services/payment-method.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'paymentMethod'
})
export class PaymentMethodPipe implements PipeTransform {

  constructor(
    private paymentMethodService: PaymentMethodService
  ) {
  }

  transform(paymentMethodUid: string): Observable<string> {

    if (paymentMethodUid) {
      return this.paymentMethodService.list().pipe(
        map(x => {
          let match = x.find(x => x.uid.toUpperCase() == paymentMethodUid.toUpperCase());
          return match ? match.name : null;
        })
      );
    }
    return null;
  }
}
