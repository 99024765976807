import { Component, Output, EventEmitter } from "@angular/core";
import { Category, CategoryProvider, DepartmentProvider } from "downtown-product";
import { Department } from 'downtown-product';
import { forkJoin, of } from "rxjs";

@Component({
  selector: 'app-department-category-pad',
  templateUrl: './department-category-pad.component.html',
  styleUrls: ['./department-category-pad.component.scss']
})
export class DepartmentCategoryPadComponent {

  @Output('onButtonPressed') onButtonPressed = new EventEmitter<{ department: Department, category: Category }>();

  constructor(
    private departmentProvider: DepartmentProvider,
    private categoryProvider: CategoryProvider
  ) {

  }

  buttonPressed(departmentUid: string, categoryUid: string) {

    forkJoin([
      this.departmentProvider.getOneCached$(departmentUid.toLowerCase()),
      categoryUid ? this.categoryProvider.getOneCached$(categoryUid) : of(null)
    ]).subscribe(([department, category]) => {
      if (department) {
        this.onButtonPressed.emit({ department, category });
      }
    });
  }
}
