import { Employee } from "./employee";
import { Position } from "./position";

export class EmployeePay {

  uid: string;
  employeeShiftUid: string;
  employeeUid: string;
  positionUid: string;
  payPeriodUid: string;
  startDateTimeUtc: Date;
  endDateTimeUtc: Date;
  workUnits: number;
  workUnitUid: string
  rate: number;
  total: number;
  paymentMethodUid: string;
  employeePayStatusUid: string;

  employee: Employee;
  position: Position;
}
