import { Pipe, PipeTransform } from '@angular/core';
import { CacheService } from 'core';
import { DepartmentService } from '../services/department.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Pipe({
  name: 'department'
})
export class DepartmentPipe implements PipeTransform {

  constructor(
    private departmentService: DepartmentService,
    private cacheService: CacheService
  ) {
  }

  transform(departmentUid: string): Observable<string> {

    if (departmentUid) {
      return this.cacheService.getOrAdd(
        departmentUid,
        () => this.departmentService.getByUid(departmentUid).pipe(shareReplay(1))
      ).pipe(
        map(department => {
          return department ? department.name : 'Unknown';
        })
      );
    }
    return null;
  }
}
