import { MatTableModule } from "@angular/material/table";

import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { IPagedDataSource, hasEqualUUID, isCaseInsensitiveEqual } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { Terminal } from "../../models";
import { TerminalService, TerminalViewOptions } from "../../services";
import { TerminalStatusEnum } from "../../keys";
import { TerminalModelFactory } from "../../terminal-model-factory";

@Component({
  selector: 'app-terminals-table',
  templateUrl: './terminals-table.component.html',
  styleUrls: ['./terminals-table.component.scss']
})
export class TerminalsTableComponent {

  @Input() public dataSource: TerminalDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }

  edit(drawer: TerminalRowModel) {

  }

  delete(drawer: TerminalRowModel) {

  }
}

export class TerminalRowModel {

  terminal: Terminal;

  canEdit: boolean;
  canAccept: boolean;
  canReject: boolean;
  canLinkToDeviceId: boolean;
  canDelete: boolean;
}

export class TerminalDataSource extends DataSource<TerminalRowModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<TerminalRowModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<TerminalRowModel[]>([]);
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private terminalService: TerminalService,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<TerminalRowModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<TerminalRowModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(statuses: string[], paginationInput: PaginationInput, viewOptions: TerminalViewOptions = null) {

    this.loadingSubject.next(true);

    this.terminalService.search(statuses, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        const terminals = page.edges.map(x => x.node).map(x => {
          let terminalModel = Object.assign(new TerminalRowModel(), {
            terminal: x
          });

          this.evaluateEnablement(terminalModel);

          return terminalModel;
        });

        return <[PageInfo, TerminalRowModel[]]>[page.pageInfo, terminals];
      }),
      catchError(() => of(<[PageInfo, TerminalRowModel[]]>[null, []])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  // public start(drawer: TerminalModel, seedAmount: number): Observable<TerminalModel> {

  //   return this.drawerService.start(drawer.drawer.uid, seedAmount).pipe(
  //     map(x => {
  //       drawer.drawer.drawerStatusUid = x.drawerStatusUid;

  //       return this.evaluateEnablement(drawer);
  //     })
  //   );
  // }

  // public end(drawer: TerminalModel): Observable<TerminalModel> {

  //   return this.drawerService.end(drawer.drawer.uid).pipe(
  //     map(x => {
  //       drawer.drawer.drawerStatusUid = x.drawerStatusUid;

  //       return this.evaluateEnablement(drawer);
  //     })
  //   );
  // }

  public merge(terminal: Terminal) {

    if (terminal) {
      var match = this.dataSubject.value.find(x => hasEqualUUID(x.terminal, terminal));
      if (match) {
        TerminalModelFactory.mergeTerminal(terminal, match.terminal);
        this.evaluateEnablement(match);
        this.evaluateBulkEnablement();
      }
    }
  }

  public update(terminalRow: TerminalRowModel): Observable<TerminalRowModel> {

    const terminal = terminalRow.terminal;

    return this.terminalService.update(
      terminal.uid,
      terminal.name,
      terminal.description,
      terminal.type,
      terminal.deviceId,
      terminal.defaultDrawerSeedAmount
    ).pipe(
      map(x => {
        TerminalModelFactory.mergeTerminal(x, terminal);

        return this.evaluateEnablement(terminalRow);
      })
    );
  }

  public accept(terminalRow: TerminalRowModel): Observable<TerminalRowModel> {

    return this.terminalService.acceptBindTerminal(terminalRow.terminal.uid).pipe(
      map(x => {
        TerminalModelFactory.mergeTerminal(x, terminalRow.terminal);

        return this.evaluateEnablement(terminalRow);
      })
    );
  }

  public reject(terminalRow: TerminalRowModel): Observable<TerminalRowModel> {

    return this.terminalService.rejectBindTerminal(terminalRow.terminal.uid).pipe(
      map(x => {
        TerminalModelFactory.mergeTerminal(x, terminalRow.terminal);

        return this.evaluateEnablement(terminalRow);
      })
    );
  }

  public evaluateEnablement(terminal: TerminalRowModel): TerminalRowModel {

    let status = terminal.terminal.status;

    terminal.canEdit = !isCaseInsensitiveEqual(status, TerminalStatusEnum.Deleted);
    terminal.canLinkToDeviceId = true;//terminal.terminal.deviceId == null || terminal.terminal.deviceId == '';
    terminal.canAccept = isCaseInsensitiveEqual(status, TerminalStatusEnum.BindTerminalRequested);
    terminal.canReject = isCaseInsensitiveEqual(status, TerminalStatusEnum.BindTerminalRequested);
    terminal.canDelete = !isCaseInsensitiveEqual(status, TerminalStatusEnum.Deleted);

    return terminal;
  }

  public evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEdit = selected.length == 1;
    this._canBulkDelete = selected.length == 1;
  }
}
