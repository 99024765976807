<div *ngIf="form" [formGroup]="form" style="margin-left: 10px;">

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Name</span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="name">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Status</span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <mat-select formControlName="productStatusUid">
        <mat-option *ngFor="let productStatus of productStatuses" [value]="productStatus.uid">{{ productStatus.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Price</span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="price" type="number">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Menu Placement *</span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <mat-select formControlName="menuPlacementUid" placeholder="Menu Placement *">
        <mat-option *ngFor="let menuPlacement of menuPlacements" [value]="menuPlacement.uid.toLowerCase()">{{ menuPlacement.display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>This is an Inclusion portion</span>
    </div>
    <div style="flex: 1; margin-left: 5px;">
      <mat-checkbox formControlName="availableAsInclusion"></mat-checkbox>
    </div>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>This is an Add On portion</span>
    </div>
    <div style="flex: 1; margin-left: 5px;">
      <mat-checkbox formControlName="availableAsAddOn"></mat-checkbox>
    </div>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Preparations</span>
    </div>
    <div style="flex: 1;">

      <mat-selection-list cdkDropList class="draggable-list" [multiple]="false" (cdkDropListDropped)="dropPreparation($event)">

        <mat-list-option cdkDrag *ngFor="let preparationForm of preparationsFormGroupArray" [value]="preparationForm" class="item" (click)="editPreparation(preparationForm)" click-stop-propagation>
          <div style="display: flex; align-items: center;">
            <div style="width: 25px; margin-right: 5px;">
              <mat-icon>drag_indicator</mat-icon>
            </div>
            <div style="flex: 1;">
              <span>{{ getValueOrUnknown(getResolvedPreparationFormValue(preparationForm.get('uid').value, 'name')) }}</span>
              <span *ngIf="(scope != 'product' && getProductPreparationForm(preparationForm))">
                <mat-icon>link</mat-icon>
              </span>
            </div>
            <mat-icon (click)="deletePreparation(preparationForm)">delete</mat-icon>
          </div>
        </mat-list-option>

      </mat-selection-list>

      <div style="display: flex;">
        <button mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-right: 5px;" (click)="newPreparation()">Add</button>
        <button *ngIf="scope != 'product'" [disabled]="!canLinkPreparations" mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-left: 5px;" (click)="linkPreparation()">Link Product Preparation</button>
      </div>
    </div>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Variations</span>
    </div>
    <div style="flex: 1;">

      <mat-selection-list cdkDropList class="draggable-list" [multiple]="false" (cdkDropListDropped)="dropVariation($event)">

        <mat-list-option cdkDrag *ngFor="let variationForm of variationsFormGroupArray" [value]="variationForm" class="item" (click)="editVariation(variationForm)" click-stop-propagation>
          <div style="display: flex; align-items: center;">
            <div style="width: 25px; margin-right: 5px;">
              <mat-icon>drag_indicator</mat-icon>
            </div>
            <div style="flex: 1;">
              <span>{{ getValueOrUnknown(getResolvedVariationFormValue(variationForm.get('uid').value, 'name')) }}</span>
              <span *ngIf="scope != 'product' && getProductVariationForm(variationForm)">
                <mat-icon>link</mat-icon>
              </span>
            </div>
            <mat-icon (click)="deleteVariation(variationForm)">delete</mat-icon>
          </div>
        </mat-list-option>

      </mat-selection-list>

      <div style="display: flex;">
        <button mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-right: 5px;" (click)="newVariation()">Add</button>
        <button *ngIf="scope != 'product'" [disabled]="!canLinkVariations" mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-left: 5px;" (click)="linkVariation()">Link Product Variation</button>
      </div>
    </div>
  </div>

</div>
