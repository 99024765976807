import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'password'
})
export class PasswordPipe implements PipeTransform {

  constructor(
  ) {
  }

  transform(password: string): Observable<string> {

    if (password) {
      return of('**********'.substr(0, password.length));
    }
    return null;
  }
}
