import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TransactionNotesModalComponent } from "./transaction-notes-modal.component";

@Injectable()
export class TransactionNotesModalProvider  {

  constructor(
    private dialog: MatDialog
  ) {
  }

  public open(data: TransactionNotesModalData): MatDialogRef<TransactionNotesModalComponent> {

    return this.dialog.open(TransactionNotesModalComponent, {
      minWidth: '60vw',
      width: '60vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface TransactionNotesModalData {
  title: string;
  initialValue: string;
}
