import { Component } from '@angular/core';

@Component({
  selector: 'lib-downtown-dam',
  standalone: true,
  imports: [],
  template: `
    <p>
      downtown-dam works!
    </p>
  `,
  styles: ``
})
export class DowntownDamComponent {

}
