import {
    BlobService,
    CacheService,
    GraphService,
    HttpService,
    PaymentMethodService,
    SettingService,
    SystemMessageService
} from ".";

export const serviceTypes = [
    BlobService,
    CacheService,
    GraphService,
    HttpService,
    PaymentMethodService,
    SettingService,
    SystemMessageService
];
