import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastFour'
})
export class LastFourPipe implements PipeTransform {

  constructor(
  ) {
  }

  transform(value: string): string {

    return value ? `XXXX-XXXX-XXXX-${value}` : null;
  }
}
