import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthWellKnownEndpoints } from '../models/auth.well-known-endpoints';
import { IdentityServerSettings } from './identity-server-settings';

@Injectable()
export class OidcConfigService {

  public onWellKnownEndpointsLoaded = new Subject<AuthWellKnownEndpoints>();;

  constructor(
    private readonly identityServerSettings: IdentityServerSettings,
    private readonly httpClient: HttpClient
  ) {

  }

  load() {

    this.load_using_stsServer(this.identityServerSettings.url);
  }

  load_using_stsServer(stsServer: string) {
    const configurationEndpointUrl = `${stsServer}/.well-known/openid-configuration`;

    this.httpClient
      .get(configurationEndpointUrl)
      .pipe(
        map(response => {
          this.onWellKnownEndpointsLoaded.next(<AuthWellKnownEndpoints>response);
        }),
        catchError(error => {
          console.error(`OidcConfigService 'load_using_stsServer' threw an error on calling ${stsServer}`, error);
          this.onWellKnownEndpointsLoaded.next(null);
          return of(false);
        })
      )
      .subscribe();
  }

  load_using_custom_stsServer(url: string) {
    this.httpClient
      .get(url)
      .pipe(
        map(response => {
          this.onWellKnownEndpointsLoaded.next(<AuthWellKnownEndpoints>response);
        }),
        catchError(error => {
          console.error(`OidcConfigService 'load_using_custom_stsServer' threw an error on calling ${url}`, error);
          this.onWellKnownEndpointsLoaded.next(null);
          return of(false);
        })
      )
      .subscribe();
  }
}

