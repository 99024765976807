import { Injectable } from '@angular/core';
import { sortByKey } from 'core';
import { PriceAdjustmentStatusKeys } from '../keys';
import { PriceAdjustment } from '../models/price-adjustment';
import { CurrentDateTimeProvider } from 'core';
import { PriceAdjustmentService } from '../services/price-adjustment.service';
import { ScheduleService } from '../services/schedule.service';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class ActivePriceAdjustmentsProvider {

  public activePriceAdjustments$: Observable<PriceAdjustment[]>;

  private activePriceAdjustmentsSubject = new BehaviorSubject<PriceAdjustment[]>(null);
  private updating = false;

  constructor(
    private currentDateTimeProvider: CurrentDateTimeProvider,
    private priceAdjustmentService: PriceAdjustmentService,
  ) {
    this.activePriceAdjustments$ = this.activePriceAdjustmentsSubject.asObservable();

    this.getActivePriceAdjustments(new Date());

    this.currentDateTimeProvider.currentDateTime$.subscribe(currentDateTime => {

      if (currentDateTime?.getSeconds() == 0 && !this.updating) {
        this.getActivePriceAdjustments(currentDateTime);
      }
    });
  }

  private getActivePriceAdjustments(currentDateTime: Date) {

    this.priceAdjustmentService.search([PriceAdjustmentStatusKeys.Active], null, ScheduleService.ScheduleFullView).subscribe(priceAdjustmentsPage => {

      const priceAdjustments = priceAdjustmentsPage.edges.map(x => x.node);

      let changed = false;
      let currentActivePriceAdjustments = (this.activePriceAdjustmentsSubject.value || []).slice();
      priceAdjustments.forEach(priceAdjustment => {
        if (!currentActivePriceAdjustments.some(x => x.uid == priceAdjustment.uid)) {
          currentActivePriceAdjustments.push(priceAdjustment);
          changed = true;
        }
      });

      let nextActivePriceAdjustments = currentActivePriceAdjustments.filter(x => priceAdjustments.some(y => y.uid == x.uid));

      changed = changed && nextActivePriceAdjustments.length == currentActivePriceAdjustments.length;
      if (changed) {
        this.activePriceAdjustmentsSubject.next(sortByKey(nextActivePriceAdjustments, 'name'));
      }
    });
  }
}
