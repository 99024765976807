import { Cart } from "../models/cart";
import { Order, OrderItem } from "../models/order";
import { CardTransaction } from "../models/card-transaction";
import { Identity } from "../models/identity";
import { ModelFactory } from "core";
import { CardTransactionBatch } from "../models/card-transaction-batch";
import { IdentityUser } from "../models/identity-user";
import { Member } from "../models/member";
import { Client } from "../models/client";
import { StoreFront, StoreFrontConfiguration, TransactionSettings } from "../models/store-front";

export class StoreModelFactory {

  public static assignCardTransaction(input: CardTransaction): CardTransaction {

    if (input) {
      let output = ModelFactory.assign(input, CardTransaction);

      output.order = input.order ? StoreModelFactory.assignOrder(input.order) : null;

      return output;
    }

    return null;
  }

  public static assignCardTransactionBatch(input: CardTransactionBatch): CardTransactionBatch {

    if (input) {
      let output = ModelFactory.assign(input, CardTransactionBatch);

      return output;
    }

    return null;
  }

  public static assignCart(input: Cart): Cart {

    if (input) {
      let output = ModelFactory.assign(input, Cart);

      // output.items = input.items ? input.items.map(x => StoreModelFactory.assignCartItem(x)) : null;

      return output;
    }

    return null;
  }

  public static assignClient(input: Client): Client {

    if (input) {
      let output = ModelFactory.assign(input, Client);

      return output;
    }

    return null;
  }

  public static assignIdentity(input: Identity): Identity {

    if (input) {
      let output = ModelFactory.assign(input, Identity);

      return output;
    }

    return null;
  }

  public static assignIdentityUser(input: IdentityUser): IdentityUser {

    if (input) {
      return ModelFactory.assign(input, IdentityUser);
    }

    return null;
  }

  public static assignMember(input: Member): Member {

    if (input) {
      var output = ModelFactory.assign(input, Member);
      output.identityUser = input.identityUser ? StoreModelFactory.assignIdentityUser(input.identityUser) : null;

      return output;
    }

    return null;
  }

  public static assignOrder(input: Order): Order {

    if (input) {
      let output = ModelFactory.assign(input, Order);

      output.initiatorIdentity = input.initiatorIdentity ? StoreModelFactory.assignIdentity(input.initiatorIdentity) : null;
      output.items = input.items ? input.items.map(x => StoreModelFactory.assignOrderItem(x)) : null;
      output.cardTransactions = input.cardTransactions ? input.cardTransactions.map(x => StoreModelFactory.assignCardTransaction(x)) : null;

      return output;
    }

    return null;
  }

  public static assignOrderItem(input: OrderItem): OrderItem {

    if (input) {
      let output = ModelFactory.assign(input, OrderItem);

      return output;
    }

    return null;
  }

  public static assignStoreFront(input: StoreFront): StoreFront {

    if (input) {
      let output = ModelFactory.assign(input, StoreFront);
      output.configuration = input.configuration ? StoreModelFactory.assignStoreConfiguration(input.configuration) : output.configuration;

      return output;
    }

    return null;
  }

  public static assignStoreConfiguration(input: StoreFrontConfiguration): StoreFrontConfiguration {

    if (input) {
      var output = ModelFactory.assign(input, StoreFrontConfiguration);
      output.transactions = input.transactions ? StoreModelFactory.assignTransactionSettings(input.transactions) : output.transactions;

      return output;
    }

    return null;
  }

  public static assignTransactionSettings(input: TransactionSettings): TransactionSettings {

    if (input) {
      let output = ModelFactory.assign(input, TransactionSettings);

      return output;
    }

    return null;
  }
}
