import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Observable } from 'rxjs';
import { ItemConfiguratorContext } from "../../item-configurator-context";
import { SettingProvider, TenantProvider, slideInAnimation, slideOutAnimation } from "core";
import { EditableTransactionItemConfigurationInclusionGroup, EditableTransactionItemConfiguration } from "../../../../models/editable-transaction/editable-transaction";
import { ProductConfigurationInclusionGroup, ProductConfigurationPreparation, ProductConfigurationVariation } from "downtown-product";
import { TransactionSettings } from "../../../../models";

@Component({
  selector: 'transaction-item-configurator-detail-inclusion-group',
  templateUrl: './item-configurator-detail-inclusion-group.component.html',
  styleUrls: ['./item-configurator-detail-inclusion-group.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfiguratorDetailInclusionGroupComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public productInclusionGroup: ProductConfigurationInclusionGroup;
  @Input() public itemInclusionGroup: EditableTransactionItemConfigurationInclusionGroup;
  @Output() public configurationChanged = new EventEmitter();

  public transactionSettings$: Observable<TransactionSettings>;
  public productPreparations: ProductConfigurationPreparation[];
  public productVariations: ProductConfigurationVariation[];

  constructor(
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider
  ) {
    this.transactionSettings$ = this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid);
  }

  ngOnInit() {

    const portion = this.context.getProductPortion(this.context.itemConfiguration.portion.portionUid);
    const productInclusionGroup = this.context.getProductInclusionGroup(portion, this.itemInclusionGroup.inclusionGroupUid);

    this.productPreparations = this.context.getProductPreparations(productInclusionGroup);
    this.productVariations = this.context.getProductVariations(productInclusionGroup);
  }

  public get configuration(): EditableTransactionItemConfiguration {

    return this.context.itemConfiguration;
  }

  public getInclusionGroupDisplay(inclusionGroupUid: string): string {

    return this.context.localCache.getOrAdd(`${inclusionGroupUid}_display`, () => {
      return this.context.product.configuration.getPortion(this.configuration.portion.portionUid).getInclusionGroup(inclusionGroupUid)?.name;
    });
  }
}
