import { Injectable } from "@angular/core";

@Injectable()
export class StoreServerSettings {

  apiUrl: string;
  hostFrameUrl: string;
  ownerUid: string;
  storeFrontUid: string;
}
