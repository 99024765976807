<div style="height: 100%; display: flex; flex-direction: column;">

  <div class="header" role="navigation">
    <app-top-navigation></app-top-navigation>
  </div>


  <div style="flex: 1; overflow: hidden;">
    <router-outlet></router-outlet>
  </div>

</div>

<!-- <div style="position: absolute; top: 65px; right: 20px; width: 300px; height: auto; max-height: 80vh;">
  <app-site-shell-alerts></app-site-shell-alerts>
</div> -->
