<div style="border-radius: 4px;" [style.background-color]="(editableTransaction.selectedLineItem | async) == item ? 'gray' : 'transparent'" (click)="select.emit(item)" click-stop-propagation>
  <ng-container *ngIf="item.configuration.value; else departmentCategoryItem">
    <ng-container *ngTemplateOutlet="configuredItem"></ng-container>
  </ng-container>
</div>

<!-- Transaction Item Context Menu -->
<div *ngIf="(editableTransaction.selectedLineItem | async) == item && (item.canEdit || item.canVoid || item.canComp || item.canCancel)" [@slideIn] [@slideOut] style="display: flex; overflow: hidden;">
  <div style="width: 10%; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
  </div>
  <div style="background-color: gray; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 4px;">
    <button *ngIf="item.canEdit" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="edit.emit(item)">Edit</button>
    <button *ngIf="item.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="void.emit(item)">Void</button>
    <button *ngIf="item.canComp" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="comp.emit(item)">Comp</button>
    <button *ngIf="item.canCancel" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="cancel.emit(item)">Cancel</button>
    <!-- <button *ngIf="item.canPrint" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="print.emit(item)">Print</button>
    <button *ngIf="item.canGuest" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="guest.emit(item)">Guest</button> -->
  </div>
  <div style="flex: 1 1 auto; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
  </div>
</div>

<ng-container *ngFor="let itemAdjustment of item.adjustments">
  <transaction-transaction-editor-item-adjustment [editableTransaction]="editableTransaction" [item]="item" [itemAdjustment]="itemAdjustment" (select)="select.emit($event)" (void)="void.emit($event)" (cancel)="cancel.emit($event)"></transaction-transaction-editor-item-adjustment>
</ng-container>

<ng-template #configuredItem>

  <div style="display: flex;">
    <div style="width: 53%;">{{ item.departmentUid.value | department | async }}</div>
    <div style="width: 10%; text-align: right;">{{ item.quantity | async | number:'1.0-2' }}</div>
    <div style="width: 2%; text-align: center;">&#64;</div>
    <div style="width: 15%; text-align: right;">{{ item.eachAmountText | async | currency:'USD':'symbol':'1.2-2' }}</div>
    <div style="width: 20%; text-align: right;">{{ item.total | async | currency:'USD':'symbol':'1.2-2' }}</div>
  </div>

  <div *ngIf="item.configuration" style="margin-left: 10%;">

    <!-- Show detail -->
    <div *ngIf="(editableTransaction.selectedLineItem | async) == item" [@slideIn] [@slideOut] style="overflow-y: hidden;">
      <transaction-item-configuration-detail [product]="product" [itemConfiguration]="item?.configuration?.value" [readOnly]="true"></transaction-item-configuration-detail>
    </div>

    <!-- Show summary -->
    <div *ngIf="(editableTransaction.selectedLineItem | async) != item" [@slideIn] [@slideOut] style="overflow-y: hidden;">
      <transaction-item-configuration-summary [transactionItem]="item"></transaction-item-configuration-summary>
    </div>

  </div>

</ng-template>

<ng-template #departmentCategoryItem>

  <div style="display: flex;">
    <ng-container *ngIf="item.categoryUid.value; else departmentItem">
      <div *ngIf="item.categoryUid.value" style="width: 53%;">{{ item.categoryUid.value | category | async }}</div>
    </ng-container>

    <ng-template #departmentItem>
      <div style="width: 53%;">{{ item.departmentUid.value | department | async }}</div>
    </ng-template>

    <div style="width: 10%; text-align: right;">{{ item.quantity | async | number:'1.0-2' }}</div>
    <div style="width: 2%; text-align: center;">&#64;</div>
    <div style="width: 15%; text-align: right;">{{ item.eachAmountText | async | currency:'USD':'symbol':'1.2-2' }}</div>
    <div style="width: 20%; text-align: right;">{{ item.total | async | currency:'USD':'symbol':'1.2-2' }}</div>
  </div>

</ng-template>