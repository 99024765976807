<div style="display: flex;">
  <div style="flex: 1;">
    <div>Shipping Address</div>
    <div><span>{{ context.identityForm.get('firstName').value }}</span>&nbsp;<span>{{ context.identityForm.get('lastName').value }}</span></div>

    <div>{{ context.shippingForm.get('address').get('address1').value }}</div>
    <div>{{ context.shippingForm.get('address').get('address2').value }}</div>
    <div>
      <span>{{ context.shippingForm.get('address').get('city').value }}</span>,&nbsp;
      <span>{{ context.shippingForm.get('address').get('state').value }}</span>&nbsp;
      <span>{{ context.shippingForm.get('address').get('postalCode').value }}</span>
    </div>
    <div>{{ context.shippingForm.get('address').get('country').value }}</div>
  </div>
  <div style="flex: 1;">
    <div>Billing Address</div>
    <ng-container *ngIf="context.billingForm.get('isSameAsShipping').value">
      <div><span>{{ context.identityForm.get('firstName').value }}</span>&nbsp;<span>{{ context.identityForm.get('lastName').value }}</span></div>
      <div>{{ context.shippingForm.get('address').get('address1').value }}</div>
      <div>{{ context.shippingForm.get('address').get('address2').value }}</div>
      <div>
        <span>{{ context.shippingForm.get('address').get('city').value }}</span>,&nbsp;
        <span>{{ context.shippingForm.get('address').get('state').value }}</span>&nbsp;
        <span>{{ context.shippingForm.get('address').get('postalCode').value }}</span>
      </div>
      <div>{{context. shippingForm.get('address').get('country').value }}</div>
    </ng-container>
    <ng-container *ngIf="!context.billingForm.get('isSameAsShipping').value">
      <div><span>{{ context.identityForm.get('firstName').value }}</span>&nbsp;<span>{{ context.identityForm.get('lastName').value }}</span></div>
      <div>{{ context.billingForm.get('address').get('address1').value }}</div>
      <div>{{ context.billingForm.get('address').get('address2').value }}</div>
      <div>
        <span>{{ context.billingForm.get('address').get('city').value }}</span>,&nbsp;
        <span>{{ context.billingForm.get('address').get('state').value }}</span>&nbsp;
        <span>{{ context.billingForm.get('address').get('postalCode').value }}</span>
      </div>
      <div>{{ context.billingForm.get('address').get('country').value }}</div>
    </ng-container>
  </div>
</div>
