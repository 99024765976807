import { Component, OnInit } from '@angular/core';
import { NavigationProvider } from 'core';
import { PosRuntimeProvider } from 'pos-core';

@Component({
  selector: 'app-site-public-home',
  templateUrl: './site-public-home.component.html',
  styleUrls: ['./site-public-home.component.scss']
})
export class SitePublicHomeComponent implements OnInit {

  public imageUrl: string;

  constructor(
    private navigationProvider: NavigationProvider,
    public runtimeProvider: PosRuntimeProvider
  ) {
    const image = 'Uptown Full - Grey Building White Lettering.svg';

    this.imageUrl = this.runtimeProvider.assetBaseUrl + 'images/branding/' + image;
  }

  ngOnInit() {
    this.navigationProvider.setWaypoint('Home');
  }
}


