import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { SelectListModalComponent } from './select-list-modal.component';

@Injectable()
export class SelectListModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open<T>(data: SelectListModalData<T>): MatDialogRef<SelectListModalComponent<T>, T | T[]> {

    return this.dialog.open<SelectListModalComponent<T>, SelectListModalData<T>, T | T[]>(SelectListModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface SelectListModalData<T> {

  title: string,
  options: T[],
  allowMultiple?: boolean,
  displayFunc: (item: T) => {};
}
