import { Injectable } from '@angular/core';
import { AuthIdentityProvider, hasEqualUUID, Page, PaginationInput } from 'core';
import { ObservableCacheProvider } from 'core';
import { CartStatusKeys } from '../keys';
import { Cart } from '../models/cart';
import { CartService } from '../services/cart.service';
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { shareReplay, take } from 'rxjs/operators';
// import { StoreLibraryConfig } from '../product-library-config';
import { StoreFrontProvider } from './store-front.provider';

/**
 * Provider meant to be a cache enabled alternative to calling CartService directly, with additional members for getting Cart in different states (ie: Active).
 */
@Injectable()
export class CartProvider {

  public currentCart$: Observable<Cart>;
  private currentCartSubject = new BehaviorSubject<Cart>(null);

  constructor(
    private authIdentityProvider: AuthIdentityProvider,
    private cacheProvider: ObservableCacheProvider,
    private cartService: CartService,
    storeFrontProvider: StoreFrontProvider,
  ) {
    this.currentCart$ = this.currentCartSubject.asObservable();

    combineLatest([
      this.authIdentityProvider.userIdentity$,
      storeFrontProvider.currentStoreFront$
    ]).subscribe(([authIdentity, storeFront]) => {
      if (storeFront) {
        this.cartService.search(null, [storeFront.uid], [authIdentity.id], [CartStatusKeys.Open], null).subscribe(cartPage => {

          if (cartPage && cartPage.edges.length > 0) {
            this.currentCartSubject.next(cartPage.edges[0].node);
            //   this.cart = this.cartEditDataHandler.mergeCart(this.cart || new EditableCart(), existingCartPage.edges[0].node);
          } else {
            this.currentCartSubject.next(null);
          }
        });
      } else {
        this.currentCartSubject.next(null);
      }
    });
  }

  public get current(): Cart {

    return this.currentCartSubject.value;
  }

  public set current(value: Cart) {

    if (!hasEqualUUID(this.currentCartSubject.value, value)) {
      this.currentCartSubject.next(value);
    } else {
      this.currentCartSubject.next(value);
    }
  }

  public getOneCached$(uid: string): Observable<Cart> {

    return this.cacheProvider.getOrAdd(this.getCacheKey(uid), () => this.getOne$(uid))
  }

  public getOne$(uid: string, version: number = null): Observable<Cart> {

    return this.cartService.getByUid(uid)
  }

  private getCacheKey(uid: string): string {

    return `${uid}`;
  }
}
