<div style="border-radius: 4px;" [style.background-color]="context.selection == portion ? 'gray' : 'transparent'" (click)="context.select(portion)">
  {{ getPortionDisplay(portion.portionUid) }}
  <span *ngIf="portion.preparations && portion.preparations.length > 0">
    &nbsp;(
    <span *ngFor="let preparation of portion.preparations; let last = last">
      {{ getPortionPreparationOptionDisplay(portion.portionUid, preparation.preparationUid, preparation.optionUid) }}
      <span *ngIf="!last">/</span>
    </span>
    )
  </span>
  <span *ngIf="portion.variations && portion.variations.length > 0">
    &nbsp;+
    <span *ngFor="let variation of portion.variations; let last = last">
      {{ getPortionVariationOptionDisplay(portion.portionUid, variation.variationUid, variation.optionUid) | async }}
      <span *ngIf="!last">/</span>
    </span>
  </span>
</div>

<!-- Context Menu -->
<div *ngIf="context.selection == portion" [@slideIn] [@slideOut] style="display: flex; overflow: hidden; background-color: white;">
  <div style="width: 10%; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
  </div>
  <div style="background-color: gray; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 4px;">
    <ng-container *ngFor="let preparation of context.getPortionPreparations(portion.portionUid)">
      <button mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="editPreparation(portion.portionUid, preparation.uid)" click-stop-propagation>
        {{ getPortionPreparationDisplay(portion.portionUid, preparation.uid) }}
      </button>
    </ng-container>
    <ng-container *ngFor="let variation of context.getPortionVariations(portion.portionUid)">
      <button mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="editVariation(portion.portionUid, variation.uid)" click-stop-propagation>
        {{ getPortionVariationDisplay(portion.portionUid, variation.uid) }}
      </button>
    </ng-container>
  </div>
  <div style="flex: 1 1 auto; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
  </div>
</div>
