import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { MatSelectionList } from '@angular/material/list';
import { map } from 'rxjs/operators';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { SelectListModalProvider, getDescendentRouteSnapshot } from 'core';
import { NavigationProvider } from 'core';
import { ConfigurationComponentBase } from '../../configuration-component-base';
import { ProductContext } from '../../product-context';
import { ProductAndPortionModalProvider } from '../../../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { buildProductAddOnForm } from '../../../../functions/configuration-form-functions';
import { TenantProvider } from 'core';
import { ProductProvider } from '../../../../providers';

@Component({
  selector: 'app-back-office-product-configuration-add-ons',
  templateUrl: './back-office-product-configuration-add-ons.component.html',
  styleUrls: ['./back-office-product-configuration-add-ons.component.scss']
})
export class BackOfficeProductConfigurationAddOnsComponent extends ConfigurationComponentBase {

  @ViewChild('extrasList', { static: true }) extrasList: MatSelectionList;

  public form: UntypedFormGroup;
  public scope: string;
  public canLinkProductAddOns: boolean;

  private _context: ProductContext;
  private destroyed$ = new Subject();

  constructor(
    private selectListModalProvider: SelectListModalProvider,
    private navigationProvider: NavigationProvider,
    activatedRoute: ActivatedRoute,
    tenantProvider: TenantProvider,
    productProvider: ProductProvider,
    productAndPortionModalProvider: ProductAndPortionModalProvider,
  ) {
    super(activatedRoute, tenantProvider, productProvider, productAndPortionModalProvider);

    this.scope = this.getScope(this.activatedRoute);
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  onActivate(event: ConfigurationComponentBase) {

    event.context = this.context;
  }

  @Input()
  public set context(context: ProductContext) {

    this._context = context;

    var route = getDescendentRouteSnapshot(this.activatedRoute);
    var portionUid = route.params['portionUid'];

    let configurationFormGroup = <UntypedFormGroup>this._context.productForm.get('configuration');
    if (portionUid) {
      let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
      if (portionsGroupFormArray) {
        configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid.toLowerCase());
      }
    }

    this.form = configurationFormGroup;
    this.canLinkProductAddOns = this.scope == 'product' ? false : super.getLinkableProductAddOnFormsForPortionForm(this.form).length > 0;
  }

  public get context(): ProductContext {

    return this._context;
  }

  public newAddOn() {

    var route = getDescendentRouteSnapshot(this.activatedRoute);
    var portionUid = route.params['portionUid'];

    let configurationFormGroup = <UntypedFormGroup>this._context.productForm.get('configuration');
    if (portionUid) {
      let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
      if (portionsGroupFormArray) {
        configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid);
      }
    }

    var addOnsFormArray = <UntypedFormArray>configurationFormGroup.get('addOns');

    const formGroup = buildProductAddOnForm(null);
    addOnsFormArray.push(formGroup);
    addOnsFormArray.markAsDirty();

    this.editAddOn(formGroup);
  }

  public editAddOn(formGroup: UntypedFormGroup) {

    var segments = this.getBasePortionNavigationSegments().concat(['addon', formGroup.get('uid').value]);
    // var route = this.getDescendentRouteSnapshot(this.activatedRoute);
    // var portionUid = route.params['portionUid']?.toLowerCase();

    // const routeSegments = ['/backoffice/product', this.activatedRoute.snapshot.params['productUid']];

    // if (portionUid) {
    //   routeSegments.push(...['portion', portionUid])
    // }

    // routeSegments.push(...['addon', formGroup.get('uid').value])

    this.navigationProvider.navigate(segments);
  }

  public linkAddOn() {

    forkJoin(super.getLinkableProductAddOnFormsForPortionForm(this.form).map(x => {
      const productUid = x.get('productUid').value;
      const portionUid = x.get('portionUid').value;
      const alias = x.get('alias').value;

      if (alias) {
        return of(<ProductAddOnSelection>{ display: alias, addOnFormGroup: x });
      } else {
        if (productUid != null && portionUid != null) {
          return this.productProvider.getOneCached$(productUid, null).pipe(
            map(product => {
              if (product && product.configuration) {
                const portion = product.configuration.portions.find(x => x.uid.toUpperCase() == portionUid.toUpperCase());
                if (portion) {
                  return <ProductAddOnSelection>{ display: `${product.name} - ${portion.name}`, addOnFormGroup: x };
                }
              }

              return <ProductAddOnSelection>{ display: 'Unknown', addOnFormGroup: x };
            })
          );
        } else {
          return of(<ProductAddOnSelection>{ display: 'Unassigned', addOnFormGroup: x });
        }
      }
    })).subscribe(x => {
      this.selectListModalProvider.open({
        title: 'Select Product Extra',
        options: x,
        displayFunc: item => item.display
      }).afterClosed().subscribe(result => {
        if (result) {
          var route = getDescendentRouteSnapshot(this.activatedRoute);
          var portionUid = route.params['portionUid'];

          let configurationFormGroup = <UntypedFormGroup>this._context.productForm.get('configuration');
          if (portionUid) {
            let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
            if (portionsGroupFormArray) {
              configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid);
            }
          }

          var addOnsFormArray = <UntypedFormArray>configurationFormGroup.get('addOns');

          const formGroup = buildProductAddOnForm(null);
          formGroup.get('uid').setValue((<ProductAddOnSelection>result).addOnFormGroup.get('uid').value);

          addOnsFormArray.push(formGroup);
          addOnsFormArray.markAsDirty();

          this.editAddOn(formGroup);
        }
      });
    });
  }

  public dropAddOn(event: CdkDragDrop<string[]>) {

    if (event.previousIndex != event.currentIndex) {
      var addOns = <UntypedFormArray>this.form.get('addOns');
      var item = addOns.controls[event.previousIndex];
      addOns.removeAt(event.previousIndex);

      addOns.insert(event.currentIndex, item);
    }
  }

  public deleteAddOn(form: UntypedFormGroup) {

    var addOns = <UntypedFormArray>this.form.get('addOns');
    addOns.removeAt(addOns.controls.indexOf(form));
  }
}

interface ProductAddOnSelection {

  display: string;
  addOnFormGroup: UntypedFormGroup;
}
