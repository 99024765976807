import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { CoreModule } from 'core';

import { componentTypes } from './components/component-types';
import { serviceTypes } from './services/service-types';
import { modalComponentTypes, modalProviderTypes } from './modals/modal-types';
import { providerTypes } from './providers/provider-types';

@NgModule({
  declarations: [
    ...componentTypes,
    ...modalComponentTypes
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatOptionModule,
    MatSelectModule,

    CoreModule
  ],
  exports: [
    ...componentTypes,
  ],
  providers: [
    ...modalProviderTypes,
    ...providerTypes,
    ...serviceTypes,
  ]
})
export class DownMainDeviceModule {

}
