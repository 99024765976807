import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { SpinnerModalComponent } from './spinner-modal.component';

@Injectable()
export class SpinnerModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(): MatDialogRef<SpinnerModalComponent> {

    return this.dialog.open(SpinnerModalComponent, {
      disableClose: true,
      closeOnNavigation: true
    });
  }
}
