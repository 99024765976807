<div style="height: 100%; display: flex; flex-direction: column;">
  <div style="flex: 8 1 0; position: relative; margin: 20px;">
    <app-date-picker [initialDate]="data.initialDate" (dateSelected)="calendarDateSelected($event)"></app-date-picker>
  </div>
  <div style="flex: 1 1 0;">
    <div style="display: flex; margin-left: 20px; margin-right: 20px;">
      <span style="flex: 3 1 auto;"></span>
      <button mat-flat-button color="warn" class="mat-display-2" style="margin-bottom: 0; padding-top: 10px; padding-bottom: 10px;" (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>
