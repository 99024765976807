import { Component } from "@angular/core";

@Component({
  selector: 'app-spinner-modal',
  templateUrl: './spinner-modal.component.html',
  styleUrls: ['./spinner-modal.component.scss']
})
export class SpinnerModalComponent {

  // TODO: Consider : npm install ngx-spinner --save
  constructor(
  ) {
  }
}
