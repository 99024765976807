import { Output, EventEmitter, Component, ElementRef, ViewChild, Inject } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TextEntryModalData, TextEntryModalResult } from "../text-entry-modal.provider";
import { TextEntryModalComponent } from "../text-entry-modal.component";
import { LoggingProvider, SystemMessageProvider } from "../../../providers";

@Component({
  selector: 'app-keyboard-modal',
  templateUrl: './keyboard-modal.component.html',
  styleUrls: ['./keyboard-modal.component.scss']
})
export class KeyboardModalComponent extends TextEntryModalComponent {

  @Output('onButtonPressed') buttonPressed = new EventEmitter<KeyModel>();
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('value', { static: true }) valueEl: ElementRef;

  public isShifted: boolean = true;
  public isCapLocked: boolean = false;
  public keys: Array<KeyModel>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: TextEntryModalData,
    dialogRef: MatDialogRef<KeyboardModalComponent, TextEntryModalResult>,
    loggingProvider: LoggingProvider,
    systemMessageProvider: SystemMessageProvider
  ) {
    super(data, dialogRef, loggingProvider, systemMessageProvider);

    this.keys = [
      <KeyModel>{ value: null, shiftValue: null, ratio: 1, isEnabled: false },
      <KeyModel>{ value: '1', shiftValue: '!', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '2', shiftValue: '@', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '3', shiftValue: '#', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '4', shiftValue: '$', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '5', shiftValue: '%', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '6', shiftValue: '^', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '7', shiftValue: '&', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '8', shiftValue: '*', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '9', shiftValue: '(', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '0', shiftValue: ')', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '-', shiftValue: '_', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '=', shiftValue: '+', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'Back', shiftValue: 'Back', ratio: 1, isEnabled: true },

      <KeyModel>{ value: null, shiftValue: null, ratio: 1.5, isEnabled: false },
      <KeyModel>{ value: 'q', shiftValue: 'Q', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'w', shiftValue: 'W', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'e', shiftValue: 'E', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'r', shiftValue: 'R', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 't', shiftValue: 'T', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'y', shiftValue: 'Y', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'u', shiftValue: 'U', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'i', shiftValue: 'I', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'o', shiftValue: 'O', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'p', shiftValue: 'P', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '[', shiftValue: '{', ratio: 1, isEnabled: true },
      <KeyModel>{ value: ']', shiftValue: '}', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '|', shiftValue: '|', ratio: 1, isEnabled: true }, // TODO: Get backslash to work, causes issues on server side

      <KeyModel>{ value: 'Cap Lock', shiftValue: 'Cap Lock', ratio: 1, isEnabled: false },
      <KeyModel>{ value: 'a', shiftValue: 'A', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 's', shiftValue: 'S', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'd', shiftValue: 'D', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'f', shiftValue: 'F', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'g', shiftValue: 'G', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'h', shiftValue: 'H', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'j', shiftValue: 'J', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'k', shiftValue: 'K', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'l', shiftValue: 'L', ratio: 1, isEnabled: true },
      <KeyModel>{ value: ';', shiftValue: ':', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '\'', shiftValue: '"', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'Enter', shiftValue: 'Enter', ratio: 1.5, isEnabled: true },

      <KeyModel>{ value: 'Shift', shiftValue: 'SHIFT', ratio: 2, isEnabled: true },
      <KeyModel>{ value: 'z', shiftValue: 'Z', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'x', shiftValue: 'X', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'c', shiftValue: 'C', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'v', shiftValue: 'V', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'b', shiftValue: 'B', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'n', shiftValue: 'N', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'm', shiftValue: 'M', ratio: 1, isEnabled: true },
      <KeyModel>{ value: ',', shiftValue: '<', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '.', shiftValue: '>', ratio: 1, isEnabled: true },
      <KeyModel>{ value: '/', shiftValue: '?', ratio: 1, isEnabled: true },
      <KeyModel>{ value: 'Shift', shiftValue: 'SHIFT', ratio: 2, isEnabled: true },

      <KeyModel>{ value: ' ', shiftValue: ' ', ratio: 1, isEnabled: true },

      <KeyModel>{ value: 'devcard', shiftValue: 'devcard', ratio: 1, isEnabled: true }
    ];

    this.buttonPressed.subscribe(x => this.onButtonPressed(x));

    if (this.data.cardSwipeEvent$) {
      this.data.cardSwipeEvent$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(cardSwipe => this.handleCardSwipe(cardSwipe));
    }
  }

  ngOnInit() {

    this.valueEl.nativeElement.focus();
  }

  ngOnDestroy() {

    this.destroyed$.next();
  }

  onButtonPressed(key: KeyModel) {

    let value = this.isShifted ? key.shiftValue : key.value;
    let entry = <string>this.form.get('value').value || '';

    if (value == "Enter") {
      this.confirm();
    } else if (value == "Back") {
      if (entry.length > 0) {
        // this.form.get('value').setValue(entry.substr(0, entry.length - 1));
        var selectionStart = this.valueEl.nativeElement.selectionStart;
        var selectionEnd = this.valueEl.nativeElement.selectionEnd;

        var substring = '';
        if (selectionStart === selectionEnd) {
          if (selectionStart > 1) {
            substring = entry.substring(0, selectionStart - 1);
          }

          if (selectionEnd < entry.length) {
            substring += entry.substring(selectionEnd, entry.length);
          }

          this.form.get('value').setValue(substring);

          this.valueEl.nativeElement.focus();
          this.valueEl.nativeElement.selectionStart = selectionStart - 1 < 0 ? 0 : selectionStart - 1;
          this.valueEl.nativeElement.selectionEnd = this.valueEl.nativeElement.selectionStart;
        } else {
          if (selectionStart > 0) {
            substring = entry.substring(0, selectionStart);
          }

          if (selectionEnd < entry.length) {
            substring += entry.substring(selectionEnd, entry.length);
          }

          this.form.get('value').setValue(substring);

          this.valueEl.nativeElement.focus();
          this.valueEl.nativeElement.selectionStart = selectionStart;
          this.valueEl.nativeElement.selectionEnd = this.valueEl.nativeElement.selectionStart;
        }
      } else {
        this.cancel();
      }

      this.isShifted = this.isCapLocked || false;
    } else if (value == 'Cap Lock') {
      this.isCapLocked = !this.isCapLocked;
      this.isShifted = this.isCapLocked;
    } else if (value == 'Shift' || value == 'SHIFT') {
      this.isCapLocked = false;
      this.isShifted = !this.isShifted;
    } else if (value == "Cancel") {
      this.cancel();
    } else if (value == 'devcard') {
      this.simulateCardSwipe();
    } else if (entry.length < (this.data.maxLength || 50)) {
      var selectionStart = this.valueEl.nativeElement.selectionStart;
      var selectionEnd = this.valueEl.nativeElement.selectionEnd;

      var substring = '';
      if (selectionStart > 0) {
        substring = entry.substring(0, selectionStart);
      }

      substring += value;

      if (selectionEnd < entry.length) {
        substring += entry.substring(selectionEnd, entry.length);
      }

      this.form.get('value').setValue(substring);

      this.valueEl.nativeElement.focus();
      this.valueEl.nativeElement.selectionStart = selectionStart + value.length;
      this.valueEl.nativeElement.selectionEnd = this.valueEl.nativeElement.selectionStart;

      this.isShifted = this.isCapLocked || false;
    }
  }
}

class KeyModel {
  value: string;
  shiftValue: string;
  ratio: number;
  isEnabled: boolean;
}
