import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductAndPortionModalProvider } from '../../../../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { ConfigurationComponentBase } from '../../../configuration-component-base';
import { ProductContext } from '../../../product-context';
import { TenantProvider, getDescendentRouteSnapshot } from 'core';
import { ProductProvider } from '../../../../../providers';

@Component({
  selector: 'app-back-office-product-configuration-inclusion-group-settings',
  templateUrl: './back-office-product-configuration-inclusion-group-settings.component.html',
  styleUrls: ['./back-office-product-configuration-inclusion-group-settings.component.scss']
})
export class BackOfficeProductConfigurationInclusionGroupSettingsComponent extends ConfigurationComponentBase {

  public form: UntypedFormGroup;
  public scope: string;

  private _context: ProductContext;
  private destroyed$ = new Subject();

  constructor(
    private router: Router,
    activatedRoute: ActivatedRoute,
    tenantProvider: TenantProvider,
    productProvider: ProductProvider,
    productAndPortionModalProvider: ProductAndPortionModalProvider
  ) {
    super(activatedRoute, tenantProvider, productProvider, productAndPortionModalProvider);

    this.scope = this.getScope(this.activatedRoute);
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public set context(context: ProductContext) {

    this._context = context;

    this.assignForm();

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      if (x instanceof NavigationEnd) { this.assignForm(); }
    });
  }

  private assignForm() {

    var route = getDescendentRouteSnapshot(this.activatedRoute);
    var portionUid = route.params['portionUid'];

    let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
    if (portionUid) {
      let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
      if (portionsGroupFormArray) {
        configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid.toLowerCase());
      }
    }

    var inclusionGroupUid = route.params['inclusionGroupUid'];
    let inclusionGroupsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('inclusionGroups');
    if (inclusionGroupsGroupFormArray) {
      configurationFormGroup = <UntypedFormGroup>inclusionGroupsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == inclusionGroupUid.toLowerCase());
    }

    this.form = configurationFormGroup;
  }

  public get context(): ProductContext {

    return this._context;
  }
}
