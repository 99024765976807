<div style="max-width: 1024px; margin-left: auto; margin-right: auto;">
  <div *ngIf="order">

    <div style="text-align: center; font-size: 2em; font-weight: bold;">Order</div>

    <div style="display: flex; margin-top: 5px;">

      <div *ngIf="order.initiatorIdentity" style="flex: 1; margin-right: 2px; border: 1px solid white; padding: 2px;">
        <div style="display: flex;">
          <span style="flex: 1; text-align: right;">First Name:</span>
          <span style="flex: 3; margin-left: 10px;">{{ order.initiatorIdentity.firstName }}</span>
        </div>
        <div style="display: flex;">
          <span style="flex: 1; text-align: right;">Last Name:</span>
          <span style="flex: 3; margin-left: 10px;">{{ order.initiatorIdentity.lastName }}</span>
        </div>
        <div style="display: flex;">
          <span style="flex: 1; text-align: right;">Company:</span>
          <span style="flex: 3; margin-left: 10px;">{{ order.initiatorIdentity.company }}</span>
        </div>
        <div style="display: flex;">
          <span style="flex: 1; text-align: right;">Email:</span>
          <span style="flex: 3; margin-left: 10px;">{{ order.initiatorIdentity.email }}</span>
        </div>
        <div style="display: flex;">
          <span style="flex: 1; text-align: right;">Phone:</span>
          <span style="flex: 3; margin-left: 10px;">{{ order.initiatorIdentity.phoneNumber }}</span>
        </div>
      </div>

      <div style="flex: 1; margin-left: 2px; border: 1px solid white; padding: 2px;">
        <div style="display: flex;">
          <span style="flex: 1; text-align: right;">Open Date:</span>
          <span style="flex: 3; margin-left: 10px;">{{ order.openDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}</span>
        </div>
        <div style="display: flex;">
          <span style="flex: 1; text-align: right;">Modified Date:</span>
          <span style="flex: 3; margin-left: 10px;">{{ order.modifiedDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}</span>
        </div>
        <div style="display: flex;">
          <span style="flex: 1; text-align: right;">Close Date:</span>
          <span style="flex: 3; margin-left: 10px;">{{ order.closeDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}</span>
        </div>
        <div style="display: flex;">
          <span style="flex: 1; text-align: right;">Status:</span>
          <span style="flex: 3; margin-left: 10px;">
            {{ order.orderStatusUid | async | orderStatus }}
            <button *ngIf="canAccept" mat-flat-button style="font-size: 10px; line-height: 14px; padding: 4px;" (click)="accept()">Accept</button>
          </span>
        </div>
      </div>

    </div>

    <div style="display: flex; margin-top: 10px;">
      <div style="flex: 1; margin-right: 2px; border: 1px solid white; padding: 2px;">

        <div>Shipping Address</div>
        <div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;">Country:</span>
            <span style="flex: 3; margin-left: 10px;">{{ order.shippingAddress.country }}</span>
          </div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;">Address:</span>
            <span style="flex: 3; margin-left: 10px;">{{ order.shippingAddress.address1 }}</span>
          </div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;"></span>
            <span style="flex: 3; margin-left: 10px;">{{ order.shippingAddress.address2 }}</span>
          </div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;">City:</span>
            <span style="flex: 3; margin-left: 10px;">{{ order.shippingAddress.city }}</span>
          </div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;">State:</span>
            <span style="flex: 3; margin-left: 10px;">{{ order.shippingAddress.state }}</span>
          </div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;">ZIP/Postal Code:</span>
            <span style="flex: 3; margin-left: 10px;">{{ order.shippingAddress.postalCode }}</span>
          </div>
        </div>

      </div>

      <div style="flex: 1; margin-left: 2px; border: 1px solid white; padding: 2px;">
        <div>Billing Address</div>
        <div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;">Country:</span>
            <span style="flex: 3; margin-left: 10px;">{{ order.billingAddress.country }}</span>
          </div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;">Address:</span>
            <span style="flex: 3; margin-left: 10px;">{{ order.billingAddress.address1 }}</span>
          </div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;"></span>
            <span style="flex: 3; margin-left: 10px;">{{ order.billingAddress.address2 }}</span>
          </div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;">City:</span>
            <span style="flex: 3; margin-left: 10px;">{{ order.billingAddress.city }}</span>
          </div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;">State:</span>
            <span style="flex: 3; margin-left: 10px;">{{ order.billingAddress.state }}</span>
          </div>
          <div style="display: flex;">
            <span style="flex: 1; text-align: right;">ZIP/Postal Code:</span>
            <span style="flex: 3; margin-left: 10px;">{{ order.billingAddress.postalCode }}</span>
          </div>
        </div>
      </div>
    </div>

    <app-back-office-store-order-viewer [order]="$any(order)"></app-back-office-store-order-viewer>

  </div>
</div>
