import { Injectable } from "@angular/core";
import { HttpService } from "core";
import { Observable } from 'rxjs';
import { finalize, map, switchMap } from "rxjs/operators";
import { ProductLibraryConfig } from "../product-library-config";
import { Product } from '../models/product';
import { PaginationInput } from "core";
import { Page } from "core";
import { ProductModelFactory } from "../product-model-factory";
import { EndpointState, GraphService } from "core";
import { ProductInput } from "../models/product-input";

@Injectable()
export class ProductService {

  public static readonly ProductIndexView = <ProductViewOptions>{};
  public static readonly ProductFullView = <ProductViewOptions>{ includeConfiguration: true };

  private readonly endPoint = 'api/product/product';

  constructor(
    private httpService: HttpService,
    private graphService: GraphService,
    private productLibraryConfig: ProductLibraryConfig,
  ) {
  }

  getByUid(uid: string, version: number, viewOptions: ProductViewOptions = ProductService.ProductFullView): Observable<Product> {

    let view = ProductService.buildView(viewOptions);

    var request = {
      query: `query { getByUid (uid: "${uid}", version: ${version}) ${view} }`
    };

    return this.httpService.graph<Product>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'getByUid').pipe(
      map(x => ProductModelFactory.assignProduct(x))
    );
  }

  getByUids(uids: string[], viewOptions: ProductViewOptions = ProductService.ProductFullView): Observable<Product[]> {

    let view = ProductService.buildView(viewOptions);

    var request = {
      query: `query { getByUids (uids: ${JSON.stringify(uids)}) ${view} }`
    };

    return this.httpService.graph<Product[]>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'getByUids').pipe(
      map(x => x.map(y => ProductModelFactory.assignProduct(y)))
    );
  }

  getMutated(viewOptions: ProductViewOptions = ProductService.ProductFullView): Observable<Product> {

    let view = ProductService.buildView(viewOptions);

    var mutatedEndpoint = this.graphService.createEndpoint(this.productLibraryConfig.socketUrl + 'api/product/product', 'product');

    return mutatedEndpoint.state.pipe(
      switchMap(state => {
        if (state == EndpointState.Ready) {
          return mutatedEndpoint
            .addSubscription<Product>(`subscription mutated { mutated ${view} }`, 'mutated').pipe(
              map(x => {
                return ProductModelFactory.assignProduct(x);
              })
            );
        } else {
          return new Observable<Product>(null);
        }
      }),
      finalize(() => {
        mutatedEndpoint.close();
      })
    );
  }

  search(ownerUid: string, departmentUids: string[], categoryUids: string[], statusUids: string[], paginationInput: PaginationInput): Observable<Page<string>> {

    var request = {
      query: `query search($pagination:PaginationInput) { search(ownerUid: "${ownerUid}", departmentUids:  ${JSON.stringify(departmentUids)}, categoryUids:  ${JSON.stringify(categoryUids)}, statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<string>>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'search');
  }

  create(productInput: ProductInput, viewOptions: ProductViewOptions = ProductService.ProductFullView): Observable<Product> {

    let view = ProductService.buildView(viewOptions);

    var request = {
      query: `mutation create($product:ProductInput!) { create(product: $product) ${view} }`,
      variables: { product: productInput }
    };

    return this.httpService.graph<Product>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'create').pipe(
      map(x => ProductModelFactory.assignProduct(x))
    );
  }

  update(productInput: ProductInput, viewOptions: ProductViewOptions = ProductService.ProductFullView): Observable<Product> {

    let view = ProductService.buildView(viewOptions);

    var request = {
      query: `mutation update($product:ProductInput!) { update(product: $product) ${view} }`,
      variables: { product: productInput }
    };

    return this.httpService.graph<Product>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'update').pipe(
      map(x => ProductModelFactory.assignProduct(x))
    );
  }

  enable(uid: string, viewOptions: ProductViewOptions = ProductService.ProductFullView): Observable<Product> {

    let view = ProductService.buildView(viewOptions);

    var request = {
      query: `mutation enable { enable(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Product>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'enable').pipe(
      map(x => ProductModelFactory.assignProduct(x))
    );
  }

  disable(uid: string, viewOptions: ProductViewOptions = ProductService.ProductFullView): Observable<Product> {

    let view = ProductService.buildView(viewOptions);

    var request = {
      query: `mutation disable { disable(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Product>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'disable').pipe(
      map(x => ProductModelFactory.assignProduct(x))
    );
  }

  delete(uid: string, viewOptions: ProductViewOptions = ProductService.ProductFullView): Observable<Product> {

    let view = ProductService.buildView(viewOptions);

    var request = {
      query: `mutation delete { delete(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Product>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'delete').pipe(
      map(x => ProductModelFactory.assignProduct(x))
    );
  }

  public static buildView(viewOptions: ProductViewOptions) {

    return ProductService.buildProductView(viewOptions, 0);
  }

  private static buildProductView(viewOptions: ProductViewOptions, depth: number = 0) {

    let view = `uid ownerUid sku name abbreviation description imageUid displayOrder departmentUid categoryUid productTypeUid basePrice productStatusUid version`;

    if (viewOptions.includeConfiguration) {
      view += ` configuration ${ProductService.buildConfigurationView(viewOptions, depth)}`;
    }
    if (viewOptions.includeDepartment) {
      view += ` productDepartment { uid name description }`;
    }
    if (viewOptions.includeCategory) {
      view += ` productCategory { uid name description }`;
    }

    return '{ ' + view + ' }';
  }

  private static buildConfigurationView(viewOptions: ProductViewOptions, depth: number) {

    let view = '';

    view += ` portions ${ProductService.buildConfigurationPortionsView(viewOptions, depth)}`;
    view += ` preparations ${ProductService.buildConfigurationPreparationsView()}`;
    view += ` variations ${ProductService.buildConfigurationVariationsView()}`;
    view += ` inclusionGroups ${ProductService.buildConfigurationInclusionGroupsView()}`;

    if (depth == 0) {
      view += ` addOns ${ProductService.buildConfigurationAddOnsView(viewOptions, depth)}`;
    }

    return '{ ' + view + ' }';
  }

  private static buildConfigurationPortionsView(viewOptions: ProductViewOptions, depth: number) {

    let view = `uid name menuPlacementUid availableAsInclusion availableAsAddOn price productStatusUid`;

    view += ` tabs ${ProductService.buildConfigurationTabsView()}`;
    view += ` preparations ${ProductService.buildConfigurationPreparationsView()}`;
    view += ` variations ${ProductService.buildConfigurationVariationsView()}`;
    view += ` inclusionGroups ${ProductService.buildConfigurationInclusionGroupsView()}`;

    if (depth == 0) {
      view += ` addOns ${ProductService.buildConfigurationAddOnsView(viewOptions, depth)}`;
    }

    return '{ ' + view + ' }';
  }

  private static buildConfigurationTabsView() {

    return '{ name inclusionGroupUids addOnUids }';
  }

  private static buildConfigurationPreparationsView() {

    return '{ uid name allowNone options { uid name additionalPrice hideInPrintDetail hideInUIDetail } }';
  }

  private static buildConfigurationVariationsView() {

    return '{ uid name defaultOptionUid options { uid productReference { uid portionUid version } alias priceOverride } }';
  }

  private static buildConfigurationInclusionGroupsView() {

    let view = `uid name maxDistinctOptionsIncluded minQuantityForOption maxTotalOptionQuantityIncluded allowSubstitution allowAdditional hideInPrintDetail hideInUIDetail`;

    view += ` options ${ProductService.buildConfigurationInclusionGroupOptionsView()}`;
    view += ` preparations ${ProductService.buildConfigurationPreparationsView()}`;
    view += ` variations ${ProductService.buildConfigurationVariationsView()}`;

    return '{ ' + view + ' }';
  }

  private static buildConfigurationInclusionGroupOptionsView() {

    let view = `uid productReference { uid portionUid version } alias defaultQuantity maxIncludedQuantity priceOverride`;

    view += ` preparations ${ProductService.buildConfigurationPreparationsView()}`;
    view += ` variations ${ProductService.buildConfigurationVariationsView()}`;

    return '{ ' + view + ' }';
  }

  private static buildConfigurationAddOnsView(viewOptions: ProductViewOptions, depth: number) {

    let view = `uid productReference { uid portionUid version } alias priceOverride`;

    if (viewOptions.includeConfiguration) {
      let addOnViewOptions = Object.assign({}, viewOptions);

      view += ` product ${ProductService.buildProductView(addOnViewOptions, depth + 1)}`;
    }

    return '{ ' + view + ' }';
  }
}

export interface ProductViewOptions {

  includeConfiguration: boolean;
  includeDepartment: boolean;
  includeCategory: boolean;
}
