import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StoreIntegrationModalComponent } from "./store-integration-modal.component";

@Injectable()
export class StoreIntegrationModalProvider  {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: StoreIntegrationModalData): MatDialogRef<StoreIntegrationModalComponent> {

    return this.dialog.open(StoreIntegrationModalComponent, {
      width: '70%',
      height: '90%',
      minWidth: '70%',
      minHeight: '90%',
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface StoreIntegrationModalData {

  transactionUids: string[];
}
