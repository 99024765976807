import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SpinnerModalProvider } from "core";
import { UUID } from "angular2-uuid";
import { ActivatedRoute } from '@angular/router';
import { AuthHeaderProvider } from 'core';
import { NavigationProvider } from 'core';
import { Category } from '../models/category';
import { CategoryService } from '../services/category.service';
import { TenantProvider } from 'core';

export class CategoryForm {

  public categoryUid: string;
  public form: UntypedFormGroup;
  public canSave = false;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected navigationProvider: NavigationProvider,
    protected spinnerModalProvider: SpinnerModalProvider,
    protected tenantProvider: TenantProvider,
    protected categoryService: CategoryService,
    protected authHeaderProvider: AuthHeaderProvider
  ) {
    this.form = new UntypedFormGroup({
      ownerUid: new UntypedFormControl(this.tenantProvider.currentUid, [Validators.required]),
      name: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl(null),
    });

    this.form.valueChanges.subscribe(x => this.updateEnablement());
  }

  public loadForm(category: Category) {

    if (category) {
      this.categoryUid = category.uid;

      this.form.get('ownerUid').setValue(category.ownerUid);
      this.form.get('name').setValue(category.name);
      this.form.get('description').setValue(category.description);
    }

    this.form.markAsPristine();
    this.updateEnablement();
  }

  public save() {

    if (this.form.dirty) {
      let saveDialogRef = this.spinnerModalProvider.open();

      saveDialogRef.afterOpened().subscribe(() => {
        if (this.categoryUid) {
          this.categoryService.update(
            this.categoryUid,
            this.form.get('name').value,
            this.form.get('description').value,
            this.activatedRoute.snapshot.params['departmentUid'],
            this.authHeaderProvider
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        } else {
          this.categoryUid = UUID.UUID();

          this.categoryService.create(
            this.categoryUid,
            this.form.get('ownerUid').value,
            this.form.get('name').value,
            this.form.get('description').value,
            this.activatedRoute.snapshot.params['departmentUid'],
            this.authHeaderProvider
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        }
      });
    } else {
      this.navigationProvider.backOneWaypoint();
    }
  }

  public cancel() {
    
    this.navigationProvider.backOneWaypoint();
  }

  protected updateEnablement() {

    this.canSave = this.form.dirty && this.form.valid;
  }
}
