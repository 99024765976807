<h2 mat-dialog-title>Preparation Options</h2>
<mat-dialog-content>

  <div [formGroup]="form">

    <div style="display: flex;">
      <div style="flex: 1;">&nbsp;</div>
      <div *ngIf="getProductPreparationForm(form)" style="flex: 1;">Product Setting</div>

      <div *ngIf="scope == 'productInclusionGroup'" style="flex: 1;">Product Inclusion Group<span *ngIf="getProductPreparationForm(form)">&nbsp;Override</span></div>

      <div *ngIf="scope == 'portionInclusionGroup' && getProductInclusionGroupOptionPreparationForm(form)" style="flex: 1;">Product Inclusion Group Option Setting</div>

      <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1;">Portion Inclusion Group<span *ngIf="getProductPreparationForm(form)">&nbsp;Override</span></div>
    </div>

    <div style="display: flex;">
      <div style="flex: 1;">
        <span>Name</span>
      </div>
      <div *ngIf="scope != 'product' && getProductPreparationForm(form)" style="flex: 1; margin-right: 5px;">
        <span *ngIf="getProductPreparationFormValue(form, 'name');else not_assigned">
          {{ getProductPreparationFormValue(form, 'name') }}
        </span>
      </div>
      <div *ngIf="scope == 'portionInclusionGroup' && getProductInclusionGroupOptionPreparationForm(form)" style="flex: 1; margin-right: 5px;">
        <span *ngIf="getProductInclusionGroupPreparationFormValue(form, 'name');else not_assigned">
          {{ getProductInclusionGroupPreparationFormValue(form, 'name') }}
        </span>
      </div>
      <mat-form-field style="flex: 1; margin-left: 5px;">
        <input matInput formControlName="name">
      </mat-form-field>
    </div>

  </div>

  <hr />

  <div style="display: flex;">
    <div style="width: 30px;">&nbsp;</div>

    <div *ngIf="getProductPreparationForm(form)" style="flex: 1;">Product Setting</div>

    <div *ngIf="scope == 'productInclusionGroup'" style="flex: 1;">Product Inclusion Group<span *ngIf="getProductInclusionGroupOptionPreparationForm(form)">&nbsp;Override</span></div>

    <div *ngIf="scope == 'portionInclusionGroup' && getProductInclusionGroupOptionPreparationForm(form)" style="flex: 1;">Product Inclusion Group Option Setting</div>

    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1;">Portion Inclusion Group Option<span *ngIf="getProductPreparationForm(form)">&nbsp;Override</span></div>

    <div style="width: 115px;">&nbsp;</div>
  </div>

  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <div cdkDrag *ngFor="let option of options.controls" [formGroup]="$any(option)" class="item">
      <div style="display: flex; align-items: center;">
        <div style="width: 25px; margin-right: 5px;">
          <mat-icon>drag_indicator</mat-icon>
        </div>
        <div *ngIf="scope != 'product' && getProductPreparationForm(form)" style="flex: 1; margin-right: 5px;">
          <span *ngIf="getProductPreparationOptionFormValue(form, $any(option), 'name');else not_assigned">
            {{ getProductPreparationOptionFormValue(form, $any(option), 'name') }}
          </span>
        </div>
        <div *ngIf="scope == 'portionInclusionGroup' && getProductInclusionGroupOptionPreparationForm(form)" style="flex: 1; margin-right: 5px;">
          <span *ngIf="getProductInclusionGroupPreparationOptionFormValue(form, $any(option), 'name');else not_assigned">
            {{ getProductInclusionGroupPreparationOptionFormValue(form, $any(option), 'name') }}
          </span>
        </div>
        <mat-form-field style="flex: 1;">
          <input matInput formControlName="name">
        </mat-form-field>
        <span style="width: 60px; text-align: center;">
          <span *ngIf="options.controls[0] == option">(Default)</span>
        </span>
        <div style="width: 50px; margin-left: 5px; text-align: center;">
          <mat-icon (click)="delete($any(option))">delete</mat-icon>
        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button color="accent" (click)="addOption()">Add Option</button>
  <span style="flex: 1 1 auto;"></span>
  <button mat-stroked-button color="warn" [mat-dialog-close]>Close</button>
</mat-dialog-actions>

<ng-template #not_assigned>Not Assigned</ng-template>
