import { Component, Input, EventEmitter, Output } from "@angular/core";
import { calculateConfigurationTotal } from "../../functions";
import { Observable, tap } from "rxjs";
import { ItemConfiguratorContext } from "./item-configurator-context";

@Component({
  selector: 'transaction-item-configurator',
  templateUrl: './item-configurator.component.html',
  styleUrls: ['./item-configurator.component.scss']
})
export class ItemConfiguratorComponent {

  @Input() public context: ItemConfiguratorContext;
  @Output() public configurationChanged = new EventEmitter();

  constructor(
  ) {
  }

  getSaleableProductDisplay(): string {

    return this.context.product.name;
  }

  getPortionDisplay(portionUid: string): string {

    let portion = this.context.product && this.context.product.configuration ? this.context.product.configuration.getPortion(portionUid) : null;
    return portion ? portion.name : null;
  }

  public handleConfigurationChanged() {

    this.configurationChanged.emit();
  }

  public get eachAmount(): Observable<number> {

    return calculateConfigurationTotal(this.context.product.uid, this.context.product.version, this.context.itemConfiguration, this.context.productProvider)
  }
}
