import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmployeeShift } from "../../models/employee-shift";
import { ClockInOutModalComponent, ClockInOutModalData } from "./clock-in-out-modal.component";

@Injectable()
export class ClockInOutModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: ClockInOutModalData): MatDialogRef<ClockInOutModalComponent, EmployeeShift> {

    return this.dialog.open(ClockInOutModalComponent, {
      width: '75vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}
