<div class="jumbotron clearfix" style="padding-left: 30px; padding-right: 30px; display: flex;">
    <div style="flex: 5;">
        <img src="{{ this.runtimeProvider.assetBaseUrl + 'images/branding/Uptown Full - White.png' }}" style="width: 100%;" />
    </div>
    <div style="flex: 7;">
        <p class="lead text-center">Employee Dashboard</p>
        <p>Use this area for employee information.</p>
    </div>
</div>

<div style="display: flex;">
    <div style="flex: 7;">
        <p>News updates</p>
        <p>No news</p>
        <p>Messages</p>
        <p>No messages</p>
    </div>
    <div style="flex: 5;">
    </div>
</div>
