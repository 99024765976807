import { Component, EventEmitter, Output, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-clock-picker',
  templateUrl: './clock-picker.component.html',
  styleUrls: ['./clock-picker.component.scss']
})
export class ClockPickerComponent {

  @Input() initialDate: Date;
  @Output() timeSelected = new EventEmitter<{ hour: number, minute: number }>();

  hours: MeasureModel[];
  minutes: MeasureModel[];
  ampms: MeasureModel[];
  calendarTitle: string;

  constructor(
    private domSanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {

    var hourCount = 12;
    var hours = [];
    for (let i = 1; i <= hourCount; i++) {
      let offset = ((360 / hourCount * i) - 90);
      if (offset < 0) {
        offset = 360 + offset;
      }

      let value = <MeasureModel>{
        measure: i.toString(),
        isSelectable: true,
        transform: this.domSanitizer.bypassSecurityTrustStyle('translate(-30px) rotate(' + offset + 'deg) translate(11em) rotate(-' + offset + 'deg)')
      };

      hours.push(value);
    }

    this.hours = hours;

    var minuteCount = 4;
    var minutes = [];
    for (let i = 0; i < minuteCount; i++) {
      let offset = ((360 / minuteCount * i) - 90);
      if (offset < 0) {
        offset = 360 + offset;
      }

      let value = <MeasureModel>{
        measure: (60 / minuteCount * i).toString(),
        isSelectable: true,
        transform: this.domSanitizer.bypassSecurityTrustStyle('translate(-30px) rotate(' + offset + 'deg) translate(6.5em) rotate(-' + offset + 'deg)')
      };

      minutes.push(value);
    }
    this.minutes = minutes;

    this.ampms = [
      <MeasureModel>{ measure: 'AM', isSelectable: true, transform: this.domSanitizer.bypassSecurityTrustStyle('translate(-2em) translate(-30px)') },
      <MeasureModel>{ measure: 'PM', isSelectable: true, transform: this.domSanitizer.bypassSecurityTrustStyle('translate(2em) translate(-30px)') }
    ]

    var date = this.initialDate ? this.round(this.initialDate, 15 * 60 * 1000) : this.round(new Date(), 15 * 60 * 1000);

    var currentHour = date.getHours();
    if (currentHour === 0) {
      this.ampms[0].isSelected = true;
      currentHour = 12;
    } else if (currentHour === 12) {
      this.ampms[1].isSelected = true;
      currentHour = 12;
    } else if (currentHour > 12) {
      this.ampms[1].isSelected = true;
      currentHour -= 12;
    } else {
      this.ampms[0].isSelected = true;
    }

    var currentHourTick = this.hours.find(x => x.measure == currentHour.toString());
    if (currentHourTick) {
      this.selectHour(currentHourTick);
    } else {
      this.selectHour(this.hours[0]);
    }

    var currentMinute = date.getMinutes();
    var currentMinuteTick = this.minutes.find(x => x.measure == currentMinute.toString());
    if (currentMinuteTick) {
      this.selectMinute(currentMinuteTick);
    } else {
      this.selectMinute(this.minutes[0]);
    }
  }

  hourClicked(hour: MeasureModel) {

    this.selectHour(hour);
    this.timeSelected.next({ hour: this.getMilitaryValue(), minute: parseInt(this.minutes.find(x => x.isSelected).measure) });
  }

  minuteClicked(minute: MeasureModel) {

    this.selectMinute(minute);
    this.timeSelected.next({ hour: this.getMilitaryValue(), minute: parseInt(minute.measure) });
  }

  ampmClicked(ampm: MeasureModel) {

    this.selectAmpm(ampm);
    this.timeSelected.next({ hour: this.getMilitaryValue(), minute: parseInt(this.minutes.find(x => x.isSelected).measure) });
  }

  private getMilitaryValue(): number {

    var hour = parseInt(this.hours.find(x => x.isSelected).measure);
    var isPM = this.ampms[1].isSelected;

    if (isPM && hour != 12) {
      hour += 12;
    } else if (!isPM && hour == 12) {
      hour -= 12;
    }

    return hour;
  }

  private selectHour(hour: MeasureModel) {

    this.hours.forEach(x => x.isSelected = false);
    hour.isSelected = true;
  }

  private selectMinute(minute: MeasureModel) {

    this.minutes.forEach(x => x.isSelected = false);
    minute.isSelected = true;
  }

  private selectAmpm(ampm: MeasureModel) {

    this.ampms.forEach(x => x.isSelected = false);
    ampm.isSelected = true;
  }

  private round(date: Date, durationMs: number): Date {

    let dateMs = date.getTime();

    return new Date(Math.ceil((+dateMs) / (+durationMs)) * (+durationMs));
  }
}

class MeasureModel {
  measure: string;
  isSelectable: boolean;
  isSelected: boolean;
  transform: SafeStyle;
}
