import { Component, OnInit } from '@angular/core';
import { NavigationProvider } from 'core';

@Component({
  selector: 'app-administration-dashboard',
  templateUrl: './administration-dashboard.component.html',
  styleUrls: ['./administration-dashboard.component.scss']
})
export class AdministrationDashboardComponent implements OnInit {

  constructor(
    private navigationProvider: NavigationProvider,
  ) {
    this.navigationProvider.setWaypoint();
  }

  ngOnInit() {
    this.navigationProvider.updateTitle("Administration Dashboard");
  }
}


