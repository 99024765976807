import { Component, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'app-number-pad',
    templateUrl: './number-pad.component.html',
    styleUrls: ['./number-pad.component.scss']
})
export class NumberPadComponent {

    @Output('onButtonPressed') buttonPressed = new EventEmitter<string>();

    constructor() {

    }
}
