import { TerminalDeviceModalComponent } from './terminal-device-modal/terminal-device-modal.component';
import { TerminalDeviceModalProvider } from './terminal-device-modal/terminal-device-modal.provider';

export const modalComponentTypes = [
  TerminalDeviceModalComponent,
];

export const modalProviderTypes = [
  TerminalDeviceModalProvider,
];
