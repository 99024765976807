import { Injectable } from "@angular/core";
import { Page } from "core";
import { PaginationInput } from "core";
import { HttpService } from "core";
import { CardTransaction } from "../models/card-transaction";
import { OltpServiceSettings } from "../oltp-service-settings";
import { StoreModelFactory } from "../providers/store-model-factory";
import { CardTransactionViewOptions, ViewFactory } from "../view-factory";
import { Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";

@Injectable()
export class CardTransaction2Service {

  public static readonly CardTransactionIndexView = <CardTransactionViewOptions>{};

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  // getByUid(uid: string): Observable<Cart> {

  //   var request = {
  //     query: 'query { getByUid (uid: \"' + uid + '\") ' + this.getCartFullView() + ' }'
  //   }

  //   return this.httpService.post<Cart>(this.oltpServiceSettings.oltpApiUrl, 'api/oltp/cart', request, 'getByUid', x => this.assign(x, Cart)).pipe(x => x, catchError(x => of(null)));
  // }

  search(ownerUids: string[], storeFrontUids: string[], orderUids: string[], statusUids: string[], paginationInput: PaginationInput, viewOptions: CardTransactionViewOptions = CardTransaction2Service.CardTransactionIndexView): Observable<Page<CardTransaction>> {

    let view = ViewFactory.buildCardTransactionView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(ownerUids: ${JSON.stringify(ownerUids)}, storeFrontUids: ${JSON.stringify(storeFrontUids)}, orderUids: ${JSON.stringify(orderUids)}, statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<CardTransaction>>(this.oltpServiceSettings.apiUrl, 'api/oltp/cardtransaction', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = StoreModelFactory.assignCardTransaction(edge.node)))
    );
  }

  void(uid: string, viewOptions: CardTransactionViewOptions = CardTransaction2Service.CardTransactionIndexView): Observable<CardTransaction> {

    let view = ViewFactory.buildCardTransactionView(viewOptions);

    var request = {
      query: `mutation void { void(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<CardTransaction>(this.oltpServiceSettings.apiUrl, 'api/oltp/cardtransaction', request, 'void').pipe(
      map(x => StoreModelFactory.assignCardTransaction(x))
    );
  }

  capture(uid: string, viewOptions: CardTransactionViewOptions = CardTransaction2Service.CardTransactionIndexView): Observable<CardTransaction> {

    let view = ViewFactory.buildCardTransactionView(viewOptions);

    var request = {
      query: `mutation capture { capture(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<CardTransaction>(this.oltpServiceSettings.apiUrl, 'api/oltp/cardtransaction', request, 'capture').pipe(
      map(x => StoreModelFactory.assignCardTransaction(x))
    );
  }

  refund(uid: string, viewOptions: CardTransactionViewOptions = CardTransaction2Service.CardTransactionIndexView): Observable<CardTransaction> {

    let view = ViewFactory.buildCardTransactionView(viewOptions);

    var request = {
      query: `mutation refund { refund(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<CardTransaction>(this.oltpServiceSettings.apiUrl, 'api/oltp/cardtransaction', request, 'refund').pipe(
      map(x => StoreModelFactory.assignCardTransaction(x))
    );
  }

  reversal(uid: string, viewOptions: CardTransactionViewOptions = CardTransaction2Service.CardTransactionIndexView): Observable<CardTransaction> {

    let view = ViewFactory.buildCardTransactionView(viewOptions);

    var request = {
      query: `mutation reversal { reversal(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<CardTransaction>(this.oltpServiceSettings.apiUrl, 'api/oltp/cardtransaction', request, 'reversal').pipe(
      map(x => StoreModelFactory.assignCardTransaction(x))
    );
  }
}

