<div style="text-align: center;">
  <h2 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h2>
</div>

<div>
  <form style="font-size: 56px; line-height: 56px; color: white; text-align: center;">
      {{ (amountText | async) | currency:'USD':'symbol':'1.2-2' }}
  </form>
</div>

<mat-grid-list cols="3" rowHeight="100px">
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('7')">7</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('8')">8</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('9')">9</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('4')">4</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('5')">5</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('6')">6</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('1')">1</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('2')">2</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('3')">3</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('Back')">Back</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('0')">0</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('00')">00</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('Cancel')">Cancel</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('Clear')">Clear</mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('Enter')">Enter</mat-grid-tile>
</mat-grid-list>
