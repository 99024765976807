import { Component, ElementRef, HostBinding, HostListener, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthConfiguration } from 'auth';
import { HttpErrorInterceptor, TenantProvider } from 'core';
import { OltpServiceSettings } from 'pos-core';
import { PosRuntimeProvider } from 'pos-core';
import { environment } from '../environments/environment';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @HostBinding('style.background-color')
  backgroundColor: string = '';
  @HostBinding('style.color')
  color: string = '';
  @HostBinding('style.line-height')
  lineHeight: string = '';
  @HostBinding('style.font-family')
  fontFamily: string = '';
  @HostBinding('style.font-size')
  fontSize: string = '';
  @HostBinding('style.cursor')
  cursor: string = '';

  constructor(
    private el: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public runtimeProvider: PosRuntimeProvider,
    private titleService: Title,
    @Inject(HTTP_INTERCEPTORS) interceptors: HttpInterceptor[],
    oltpServiceSettings: OltpServiceSettings,
    tenantProvider: TenantProvider,
    authConfiguration: AuthConfiguration
  ) {
    var httpErrorInterceptor = <HttpErrorInterceptor>interceptors.find(x => x instanceof HttpErrorInterceptor);
    if (httpErrorInterceptor) {
      httpErrorInterceptor.addExcludeEndpoint(oltpServiceSettings.apiUrl + 'api/oltp/terminal');
      httpErrorInterceptor.addExcludeEndpoint(oltpServiceSettings.apiUrl + 'api/health/alive');
      httpErrorInterceptor.addExcludeEndpoint(authConfiguration.stsServer + '.well-known/openid-configuration');
      httpErrorInterceptor.addExcludeEndpoint('https://localhost/R1.Boss.AspNet.Identity' + '/.well-known/openid-configuration'); // Needs to be removed once configuration is more static
    }

    this.backgroundColor = "black";
    this.color = "white";
    // this.lineHeight = "1";
    // this.fontFamily = `'Raleway', sans-serif`;
    // this.fontSize = "16px";
    this.cursor = 'default'

    tenantProvider.currentUid = environment.ownerUid;
  }

  ngOnInit() {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((data: any) => {
      if (data) {
        this.titleService.setTitle(`Uptown Bar ${(data ? `- ${data}` : '')}`);
      }
    });
  }

  ngAfterViewInit() {

    this.updatePosButtonCssVariables();
  }
  
  @HostListener('window:resize')
  public onResize() {

    this.updatePosButtonCssVariables();
  }

  private updatePosButtonCssVariables() {

    const nativeElement = this.el.nativeElement;

    const styles = [
      { name: 'app-register-button-height', value: `${nativeElement.offsetHeight / 12}px` },
      { name: 'app-register-button-width', value: `${nativeElement.offsetWidth / 12}px` },
    ];

    styles.forEach(data => {
      nativeElement.style.setProperty(`--${data.name}`, data.value);
    });
  }
}
