import { Injectable } from '@angular/core';
import { Employee } from '../models/employee';
import { Permission } from '../models/permission';
import { of, Observable } from 'rxjs';

@Injectable()
export class PermissionProvider {

  private allPermissionMap = {
    "CanUseSelfServiceHR": "B242DCC2-F78A-4F41-8E48-55117DD2D114",
    "CanStartTerminalDrawer": "3BE238D7-B386-4E86-AB0D-AA432BF1FEA9",
    "CanOpenCloseAccounts": "8B4B186D-DC09-48BB-B587-1BE5642093BE",
    "CanEndTerminalDrawer": "8FF4C010-E5D9-4D0E-BF0A-7EC3457D239C",
    "CanViewSalesHistory": "929C487D-90F4-4EA6-92C8-4DB615AE8366",
    "CanExitApplication": "7CE6FD86-37C8-452A-B48F-CE50955CC96A",
    "CanChangeTerminalGeneralSettings": "71D5F730-CA42-4025-88B9-094D2B9D8F04",
    "CanChangeTerminalSetup": "F352CDBB-5562-4AF2-8CE1-5DBCEA7F1AF5",
    "CanChangeUPOSSettings": "092B5C77-51C6-46EE-AAF9-FEF299C95075",
    "CanReadTerminalMessages": "250897F8-3DA0-4735-9A39-0AD07CF4A577",
    "CanSeeLoggedInEmployees": "3ADB50FA-6557-4D44-BE1F-915F71B37702",
    "CanDeleteTerminalMessages": "02B6F7BE-DDD2-4AEE-8960-E1B1563670D9",
    "CanUseCashRegister": "8629CE43-6458-4CB8-9671-C9F99D31C7B4",
    "CanAuthorizeCardTransactions": "2517D05F-0B11-48AE-AC2D-2E43AB33570C",
    "CanCaptureCardTransactions": "E1894011-149C-4F64-8FFF-390F14EF60A8",
    "CanVoidCardTransactions": "70FAC6D6-3B15-47DB-B71B-7A01F205549D",
    "CanRefundCardTransactions": "2BF9737C-0C0D-406E-BAFA-079D9A5D0EB6",
    "CanManageEmployees": "EFED65A8-369C-4C21-B858-24E34BF2C268",
    "SystemAdministrator": "AE61D8C2-1543-4590-9CD6-F73A2813985C"
  };

  constructor(
  ) {

  }

  public list(): Observable<Permission[]> {

    var keys = Object.keys(this.allPermissionMap);
    var list = keys.map(x => {
      return <Permission>{
        uid: this.allPermissionMap[x as keyof {}],
        name: x
      };
    });

    return of(list);
  }

  public canAccess(employee: Employee, permission: string): boolean {

    if (employee?.permissions) {
      return employee.permissions.map(x => x.uid.toUpperCase()).indexOf(this.allPermissionMap[permission as keyof {}]) > -1;
    }

    return false;
  }

  public canAccessAny(employee: Employee, permissions: string[]): boolean {

    if (employee?.permissions) {
      return permissions.some(permission => {
        return employee.permissions.map(x => x.uid.toUpperCase()).indexOf(this.allPermissionMap[permission as keyof {}]) > -1;
      });
    }

    return false;
  }
}
