import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, tap, shareReplay } from 'rxjs';
import { SettingService, SettingViewOptions } from '../services/setting.service';
import { Setting } from '../models/setting';
import { SettingInput } from '../models/setting-input';
import { isCaseInsensitiveEqual, isEqualUUID } from '../functions/string';
import { PaginationInput } from '../models/pagination-input';
import { Page } from '../models/page';

/**
 * Provider meant to be a cache enabled alternative to calling SettingService directly, with additional members for getting Setting's.
 */
@Injectable()
export class SettingProvider {

  public currentSettings$: Observable<Setting[]>;
  private currentSettingsSubject = new BehaviorSubject<Setting[]>(null);

  constructor(
    private settingService: SettingService
  ) {
    this.currentSettings$ = this.currentSettingsSubject.asObservable();

    this.refresh();
  }

  public refresh() {

    // This needs to be scoped instead of the 'all' list
    this.settingService.list().subscribe(settings => {
      this.currentSettingsSubject.next(settings.filter(x => !!x));
    });
  }

  public getByUid$<T>(uid: string): Observable<T> {

    return this.currentSettings$.pipe(
      filter(setting => setting != null),
      map(settings => settings.find(x => isEqualUUID(x.uid, uid))),
      map(setting => setting ? setting.getParsedValue<T>() : null),
      shareReplay(1)
    );
  }

  public search$(ownerUids: string[], paginationInput: PaginationInput = null): Observable<Page<Setting>> {

    return this.settingService.search(ownerUids, paginationInput || <PaginationInput>{ pageIndex: 0, pageSize: 10000, sortField: "Name", sortStrategy: 'asc' });
  }

  public getByType$<T>(type: string): Observable<T[]> {

    return this.currentSettings$.pipe(
      filter(setting => setting != null),
      map(settings => settings.filter(x => isCaseInsensitiveEqual(x.type, type))),
      map(settings => settings ? settings.map(x => x.getParsedValue<T>()) : null),
      shareReplay(1)
    );
  }

  public getOneByTypeAndOwner$<T>(type: string, ownerUid: string): Observable<T> {

    return this.currentSettings$.pipe(
      filter(settings => settings != null),
      map(settings => {
        return settings.find(x => isCaseInsensitiveEqual(x.type, type) && isCaseInsensitiveEqual(x.ownerUid, ownerUid));
      }),
      map(setting => {
        return setting ? setting.getParsedValue<T>() : null;
      })
    );
  }

  public getByTypes$(types: string[]): Observable<Setting[]> {

    return this.currentSettings$.pipe(
      filter(setting => setting != null),
      map(settings => settings.filter(x => types.find(y => isCaseInsensitiveEqual(x.type, y)))),
      shareReplay(1)
    );
  }

  public putOne$(setting: SettingInput, viewOptions: SettingViewOptions = SettingService.SettingFullView) {

    return this.settingService.updateOne(setting, viewOptions).pipe(
      tap(updatedSetting => {
        var currentSettings = this.currentSettingsSubject.value;
        var currentSetting = currentSettings.find(x => isEqualUUID(x.uid, updatedSetting.uid));
        var currentSettingIndex = currentSettings.indexOf(currentSetting);

        var updatedSettings = [...currentSettings];
        updatedSettings[currentSettingIndex] = updatedSetting;

        this.currentSettingsSubject.next(updatedSettings);
      })
    );
  }

  public putMany$(settings: SettingInput[], viewOptions: SettingViewOptions = SettingService.SettingFullView) {

    return this.settingService.updateMany(settings, viewOptions).pipe(
      tap(updatedSettings => {
        this.currentSettingsSubject.next(updatedSettings);
      })
    );
  }
}
