<div *ngIf="form" [formGroup]="form">

  <div style="display: flex;">
    <div style="flex: 1;">&nbsp;</div>
    <div style="flex: 1;">Product Setting</div>
    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1;">Portion Override</div>
  </div>

  <ng-template #not_assigned>Not Assigned</ng-template>
  <ng-template #none>None</ng-template>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Name</span>
    </div>
    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="getProductInclusionGroupFormValue(form, 'name');else not_assigned">
        {{ getProductInclusionGroupFormValue(form, 'name') }}
      </span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="name">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Max Distinct Options Included</span>
    </div>
    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="getProductInclusionGroupFormValue(form, 'maxDistinctOptionsIncluded');else not_assigned">
        {{ getProductInclusionGroupFormValue(form, 'maxDistinctOptionsIncluded') }}
      </span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="maxDistinctOptionsIncluded">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Min Quantity For Option</span>
    </div>
    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="getProductInclusionGroupFormValue(form, 'minQuantityForOption');else not_assigned">
        {{ getProductInclusionGroupFormValue(form, 'minQuantityForOption') }}
      </span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="minQuantityForOption">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Max Total Option Quantity Included</span>
    </div>
    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="getProductInclusionGroupFormValue(form, 'maxTotalOptionQuantityIncluded');else not_assigned">
        {{ getProductInclusionGroupFormValue(form, 'maxTotalOptionQuantityIncluded') }}
      </span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="maxTotalOptionQuantityIncluded">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Allow Additional</span>
    </div>
    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="getProductInclusionGroupFormValue(form, 'allowAdditional');else not_assigned">
        {{ getProductInclusionGroupFormValue(form, 'allowAdditional') }}
      </span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <mat-select formControlName="allowAdditional">
        <mat-option [value]="true">True</mat-option>
        <mat-option [value]="false">False</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>
