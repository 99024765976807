import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthEmployeeProvider } from 'pos-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HasAccessGuard  {

  constructor(
    private authEmployeeProvider: AuthEmployeeProvider
  ) {
  }

  private hasAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.authEmployeeProvider.authEmployee$.pipe(map(x => x !== null));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.hasAccess(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.hasAccess(childRoute, state);
  }
}
