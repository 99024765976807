import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditableTransactionGuest } from "downtown-transaction";
import { SelectGuestModalComponent } from "./select-guest-modal.component";

@Injectable()
export class SelectGuestModalProvider  {

  constructor(
    protected dialog: MatDialog,
  ) {
  }

  public open(data: SelectGuestModalData): MatDialogRef<SelectGuestModalComponent, SelectGuestModalResult> {

    return this.dialog.open<SelectGuestModalComponent, SelectGuestModalData, SelectGuestModalResult>(SelectGuestModalComponent, {
      width: '30vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface SelectGuestModalData {

  title: string,
  guests: EditableTransactionGuest[],
}

export interface SelectGuestModalResult {

  guest: EditableTransactionGuest,
}
