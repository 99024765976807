import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, asyncScheduler, forkJoin, of } from 'rxjs';
import { concatMap, filter, first, map, observeOn, takeUntil, tap } from 'rxjs/operators';
import { TransactionStatusEnum } from "../keys";
import { DateRangeFilterInput, Edge, EntityEventProvider, ObservableCacheProvider, PaginationInput, SettingProvider, TenantProvider, isCaseInsensitiveEqual, isEqualUUID, subtractHourMinuteSecond } from "core";
import { TransactionService, TransactionViewOptions } from "../services/transaction.service";
import { Transaction, TransactionPage } from "../models/transaction";
import { TransactionSettings } from "../models";

@Injectable()
export class TransactionProvider {

  public openTransactions$: Observable<Transaction[]>;
  public recentTransactions$: Observable<Transaction[]>;

  private _openTransactionsSubject = new BehaviorSubject<Transaction[]>([]);
  private _recentTransactionsSubject = new BehaviorSubject<Transaction[]>([]);
  private _mutatedSubject$ = new Subject<Transaction>();
  private _mutatedStream$: Observable<Transaction>;

  private destroyed$ = new Subject<void>();

  constructor(
    private settingProvider: SettingProvider,
    private cacheProvider: ObservableCacheProvider,
    private tenantProvider: TenantProvider,
    private entityEventProvider: EntityEventProvider,
    private transactionService: TransactionService,
  ) {
    this.openTransactions$ = this._openTransactionsSubject.asObservable();
    this.recentTransactions$ = this._recentTransactionsSubject.asObservable();
    this._mutatedStream$ = this._mutatedSubject$.asObservable();

    this.entityEventProvider.event$.pipe(
      takeUntil(this.destroyed$),
      observeOn(asyncScheduler),
      filter(event => isCaseInsensitiveEqual(event.type, 'TransactionContext')),
      concatMap(event => this.transactionService.getByUid(event.uid)),
    ).subscribe(transaction => {
      console.log(`TransactionProvider:TransactionContext entity event trx: ${transaction.uid}`);

      this._mutatedSubject$.next(transaction);
    });

    this.composeOpenTransactionsStream();
    this.composeRecentTransactionsStream();
  }

  public ngOnDestroy(): void {

    this.destroyed$.next();
  }

  public get openTransactions(): Transaction[] {

    return this._openTransactionsSubject.value;
  }

  public get mutatedStream$(): Observable<Transaction> {

    return this._mutatedStream$;
  }

  public getByUid$(uid: string): Observable<Transaction> {

    return this.transactionService.getByUid(uid);
  }

  public getByUids$(uids: string[]): Observable<Transaction[]> {

    return this.transactionService.getByUids(uids);
  }

  public search$(openedDateRangeFilter: DateRangeFilterInput, closedDateRangeFilter: DateRangeFilterInput, statusUids: string[], paginationInput: PaginationInput, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<TransactionPage> {

    return this.transactionService.search$(openedDateRangeFilter, closedDateRangeFilter, statusUids, paginationInput, viewOptions).pipe(
      concatMap(result => {
        const productIds = result.edges.map(x => <string>x.node);
        const cacheKeys = productIds.map(x => this.getCacheKey(x));
        const missingKeys = this.cacheProvider.missingKeys(cacheKeys);
        const missingUids = missingKeys.map(x => this.getUidFromKey(x));

        return missingUids.length == 0 ? of(result) : this.transactionService.getByUids(missingUids).pipe(
          tap(transactions => {
            transactions.forEach(transaction => {
              this.cacheProvider.addOrUpdate(this.getCacheKey(transaction.uid), of(transaction));
            });
          }),
          map(_ => result)
        );
      }),
      first(),
      concatMap(result => {
        if (result.edges.length == 0) {
          return of(<TransactionPage>{ pageInfo: result.pageInfo, totalCount: result.totalCount, totalValue: 0, edges: [] });
        }

        return forkJoin(
          result.edges.map(x => x.node).map(x => this.cacheProvider.get<Transaction>(this.getCacheKey(<string>x)))
        ).pipe(
          map(values => {
            return <TransactionPage>(<TransactionPage>{
              pageInfo: result.pageInfo,
              totalCount: result.totalCount,
              totalValue: result.totalValue,
              edges: values.map(x => <Edge<Transaction>>{ node: x })
            });
          })
        );
      }),
      first()
    );
  }

  public getOneCached$(uid: string): Observable<Transaction> {

    return this.cacheProvider.getOrAdd(this.getCacheKey(uid), () => this.getOne$(uid))
  }

  public getOne$(uid: string): Observable<Transaction> {

    return this.transactionService.getByUid(uid)
  }

  public getManyCached$(uids: string[]): Observable<Transaction[]> {

    const cacheKeys = uids.map(x => this.getCacheKey(x));
    const missingKeys = this.cacheProvider.missingKeys(cacheKeys);
    const missingUids = missingKeys.map(x => this.getUidFromKey(x));

    return (missingUids.length == 0 ? of<Transaction[]>([]) : this.transactionService.getByUids(missingUids)).pipe(
      tap(transactions => {
        transactions.forEach(transaction => {
          this.cacheProvider.addOrUpdate(this.getCacheKey(transaction.uid), of(transaction));
        });
      }),
      concatMap(_ => {
        return forkJoin(
          uids.map(x => {
            return this.cacheProvider.get<Transaction>(this.getCacheKey(x));
          })
        );
      }),
      first()
    );
  }

  public open$ = this.transactionService.open.bind(this.transactionService);
  public cancel$ = this.transactionService.cancel.bind(this.transactionService);
  public close$ = this.transactionService.close.bind(this.transactionService);
  public reopen$ = this.transactionService.reopen.bind(this.transactionService);  
  public updateNotes$ = this.transactionService.updateNotes.bind(this.transactionService);
  public updateHoldCardReference$ = this.transactionService.updateHoldCardReference.bind(this.transactionService);
  public updateLogisticType$ = this.transactionService.updateLogisticType.bind(this.transactionService);
  public addLock$ = this.transactionService.addLock.bind(this.transactionService);
  public removeLock$ = this.transactionService.removeLock.bind(this.transactionService);
  public addGuest$ = this.transactionService.addGuest.bind(this.transactionService);
  public addItem$ = this.transactionService.addItem.bind(this.transactionService);
  public changeItem$ = this.transactionService.changeItem.bind(this.transactionService);
  public addCharge$ = this.transactionService.addCharge.bind(this.transactionService);
  public updateCharge$ = this.transactionService.updateCharge.bind(this.transactionService);
  public cancelCharge$ = this.transactionService.cancelCharge.bind(this.transactionService);
  public addChargeAdjustment$ = this.transactionService.addChargeAdjustment.bind(this.transactionService);
  public updateChargeAdjustment$ = this.transactionService.updateChargeAdjustment.bind(this.transactionService);
  public cancelChargeAdjustment$ = this.transactionService.cancelChargeAdjustment.bind(this.transactionService);
  public addAdjustment$ = this.transactionService.addAdjustment.bind(this.transactionService);
  public changeAdjustment$ = this.transactionService.changeAdjustment.bind(this.transactionService);
  public cancelAdjustment$ = this.transactionService.cancelAdjustment.bind(this.transactionService);
  public addItemAdjustment$ = this.transactionService.addItemAdjustment.bind(this.transactionService);
  public changeItemAdjustment$ = this.transactionService.changeItemAdjustment.bind(this.transactionService);
  public cancelItemAdjustment$ = this.transactionService.cancelItemAdjustment.bind(this.transactionService);
  public addPayment$ = this.transactionService.addPayment.bind(this.transactionService);
  public changePayment$ = this.transactionService.changePayment.bind(this.transactionService);
  public cancelPayment$ = this.transactionService.cancelPayment.bind(this.transactionService);
  
  private getCacheKey(uid: string, version: number = null): string {

    return `Transaction_${uid}${version != null ? `_${version}` : ''}`;
  }

  private getUidFromKey(key: string): string {

    const segments = key.split('_');
    return segments[1];
  }

  private composeOpenTransactionsStream() {

    let sortStrategy = (a: Transaction, b: Transaction) => {
      return (a?.notes || '').localeCompare((b?.notes || ''));
    };

    // Get initial open transactions
    this.search$(null, null, [TransactionStatusEnum.Open], null, TransactionService.TransactionFullView).subscribe(transactionsPage => {
      this._openTransactionsSubject.next(transactionsPage.edges.map(x => x.node).sort(sortStrategy));
    });

    // Subscribe for transaction mutations
    this.mutatedStream$.subscribe(transaction => {
      let currentTransactions = this._openTransactionsSubject.value;

      let existingTransaction = currentTransactions.find(x => x.uid.toUpperCase() == transaction.uid.toUpperCase());
      if (existingTransaction) {
        if (isCaseInsensitiveEqual(transaction.transactionStatus, TransactionStatusEnum.Open)) {
          Object.assign(existingTransaction, transaction);
        } else {
          console.log(`TransactionProvider:currentTransactions:existingTransaction:TransactionStatusKeys.Closed`);

          currentTransactions.splice(currentTransactions.indexOf(existingTransaction), 1);
        }
      } else {
        if (isCaseInsensitiveEqual(transaction.transactionStatus, TransactionStatusEnum.Open)) {
          currentTransactions.push(transaction);
        }
      }

      this._openTransactionsSubject.next(currentTransactions.filter(x => !!x).sort(sortStrategy));
    });
  }

  private composeRecentTransactionsStream() {

    this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid).subscribe(transactionSetting => {
      if (transactionSetting) {
        var closedDateRangeFilter = this.getCurrentDateRangeFilterInput(subtractHourMinuteSecond(new Date(), transactionSetting.recentTransactionHourMinute));

        this.search$(null, closedDateRangeFilter, [TransactionStatusEnum.Closed], null, TransactionService.TransactionFullView).subscribe(transactionsPage => {
          this._recentTransactionsSubject.next(transactionsPage.edges.map(x => x.node));
        });

        this.mutatedStream$.subscribe(transaction => {
          let transactions = this._recentTransactionsSubject.value;

          let existingTransaction = transactions.find(x => isEqualUUID(x.uid, transaction.uid));
          if (existingTransaction) {
            if (isCaseInsensitiveEqual(transaction.transactionStatus, TransactionStatusEnum.Closed)) {
              Object.assign(existingTransaction, transaction);
            } else {
              transactions.splice(transactions.indexOf(existingTransaction), 1)
            }
          } else {
            if (isCaseInsensitiveEqual(transaction.transactionStatus, TransactionStatusEnum.Closed) && transaction.closeDateTimeUtc > subtractHourMinuteSecond(new Date(), transactionSetting.recentTransactionHourMinute)) {
              transactions.push(transaction);
            }
          }

          this._recentTransactionsSubject.next(transactions);
        });
      }
    });
  }

  private getCurrentDateRangeFilterInput(afterDateTime: Date): DateRangeFilterInput {

    return <DateRangeFilterInput>{
      afterDateTimeUtc: afterDateTime.toISOString(),
      beforeDateTimeUtc: new Date().toISOString()
    };
  }
}
