import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PrintDepartmentSlipModalComponent } from "./print-department-slip-modal.component";
import { TransactionGuest } from "downtown-transaction";

@Injectable()
export class PrintDepartmentSlipModalProvider {

  private readonly isDesktop: boolean;

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: PrintDepartmentSlipModalData): MatDialogRef<PrintDepartmentSlipModalComponent, PrintDepartmentSlipModalResult> {

    return this.dialog.open<PrintDepartmentSlipModalComponent, PrintDepartmentSlipModalData, PrintDepartmentSlipModalResult>(PrintDepartmentSlipModalComponent, {
      width: '30vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface PrintDepartmentSlipModalData {

  title: string,
  guests: TransactionGuest[],
  allowUnassigned: boolean
}

export interface PrintDepartmentSlipModalResult {

  guestUids: string[];
}
