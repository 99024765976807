import { Injectable } from "@angular/core";
import { Page } from "core";
import { PaginationInput } from "core";
import { HttpService } from "core";
import { IdentityUser } from "../models/identity-user";
import { OltpServiceSettings } from "../oltp-service-settings";
import { StoreModelFactory } from "../providers/store-model-factory";
import { IdentityUserViewOptions, ViewFactory } from "../view-factory";
import { Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";

@Injectable()
export class IdentityUserService {

  public static readonly IndexView = <IdentityUserViewOptions>{};

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByIdentityId(identityId: string, viewOptions: IdentityUserViewOptions = IdentityUserService.IndexView): Observable<IdentityUser> {

    let view = ViewFactory.buildIdentityUserView(viewOptions);

    var request = {
      query: `query { identityUser (identityId: "${identityId}") ${view} }`
    }

    return this.httpService.graph<IdentityUser>(this.oltpServiceSettings.apiUrl, 'api/oltp/identityUser', request, 'identityUser').pipe(
      map(x => StoreModelFactory.assignIdentityUser(x))
    );
  }

  search(paginationInput: PaginationInput, viewOptions: IdentityUserViewOptions = IdentityUserService.IndexView): Observable<Page<IdentityUser>> {

    let view = ViewFactory.buildIdentityUserView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<IdentityUser>>(this.oltpServiceSettings.apiUrl, 'api/oltp/identityUser', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = StoreModelFactory.assignIdentityUser(edge.node)))
    );
  }

  addClaim(id: string, claim: string, employeeUid: string, viewOptions: IdentityUserViewOptions = IdentityUserService.IndexView): Observable<IdentityUser> {

    let view = ViewFactory.buildIdentityUserView(viewOptions);

    var request = {
      query: `mutation addClaim { addClaim(id: "${id}", claim: "${claim}", employeeUid: "${employeeUid}") ${view} }`
    };

    return this.httpService.graph<IdentityUser>(this.oltpServiceSettings.apiUrl, 'api/oltp/identityUser', request, 'addClaim').pipe(
      map(x => StoreModelFactory.assignIdentityUser(x))
    );
  }

  removeClaim(id: string, claim: string, employeeUid: string, viewOptions: IdentityUserViewOptions = IdentityUserService.IndexView): Observable<IdentityUser> {

    let view = ViewFactory.buildIdentityUserView(viewOptions);

    var request = {
      query: `mutation removeClaim { removeClaim(id: "${id}", claim: "${claim}", employeeUid: "${employeeUid}") ${view} }`
    };

    return this.httpService.graph<IdentityUser>(this.oltpServiceSettings.apiUrl, 'api/oltp/identityUser', request, 'removeClaim').pipe(
      map(x => StoreModelFactory.assignIdentityUser(x))
    );
  }
}
