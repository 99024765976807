<div [formGroup]="form" style="display: flex; margin-left: 10px;">

  <div style="flex: 1;">

    <div style="display: flex;">
      <button mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-right: 5px;" (click)="newAddOn()">New</button>
      <button *ngIf="scope == 'portion'" [disabled]="!canLinkProductAddOns" mat-button mat-button-stroked style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-left: 5px;" (click)="linkAddOn()">Link Product Add On</button>
    </div>

    <mat-selection-list cdkDropList class="draggable-list" [multiple]="false" (cdkDropListDropped)="dropAddOn($event)">

      <ng-container *ngIf="form?.get('addOns') != null">

        <mat-list-option cdkDrag *ngFor="let addOnForm of $any(form.get('addOns')).controls" [value]="addOnForm" class="item">
          <div style="display: flex; align-items: center;">
            <div style="width: 25px; margin-right: 5px;">
              <mat-icon>drag_indicator</mat-icon>
            </div>
            <div style="flex: 1;" (click)="editAddOn(addOnForm)">

              <ng-container *ngIf="getResolvedAddOnFormValue(addOnForm.get('uid').value, 'alias'); else noAddOnAlias;">
                <span>{{ getResolvedAddOnFormValue(addOnForm.get('uid').value, 'alias') }}</span>
              </ng-container>

              <ng-template #noAddOnAlias>
                <app-product-portion-label [productUid]="getResolvedProductReference('addOn', addOnForm.get('uid').value)?.uid" [portionUid]="getResolvedProductReference('addOn', addOnForm.get('uid').value)?.portionUid" [version]="getResolvedProductReference('addOn', addOnForm.get('uid').value)?.version"></app-product-portion-label>
              </ng-template>

              <mat-icon *ngIf="scope == 'portion' && getProductAddOnForm(addOnForm)">link</mat-icon>
            </div>
            <mat-icon (click)="deleteAddOn($any(addOnForm))">delete</mat-icon>
          </div>
        </mat-list-option>

      </ng-container>

    </mat-selection-list>

  </div>

  <div style="flex: 1; font-family: familyRaleway, sans-serif; font-size: 2em; line-height: 100%; padding: 0 10px;">
    <p>Add Ons are additional products commonly sold with this product, often as sides or smaller portions.</p>
    <p *ngIf="scope == 'product'">Add Ons configured at the product level (Product Add Ons) are shared add ons that can be included or ignored with all portions. Further, each portion has the ability to override the product level configuration.</p>
    <p *ngIf="scope == 'portion'">Add Ons configured at the portion level can be unique to this portion or it can link a product level add on and override any values if necessary.</p>
  </div>

</div>
