<div *ngIf="form" [formGroup]="form">

  <div style="display: flex;">
    <div style="flex: 1;">&nbsp;</div>
    <div style="flex: 1;">Product Setting</div>
    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1;">Portion Override</div>
  </div>

  <ng-template #not_assigned>Not Assigned</ng-template>
  <ng-template #none>None</ng-template>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Product</span>
    </div>
    <div *ngIf="scope != 'productInclusionGroup'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="validProductReference(getProductInclusionGroupOptionFormValue(form, 'productReference'));else not_assigned">
        <!-- <app-product-portion-label [productReference]="getProductInclusionGroupOptionFormValue(form, 'productReference')" [includePrice]="true"></app-product-portion-label> -->
      </span>
    </div>
    <div style="flex: 1; margin-left: 5px;">
      <!-- <app-product-portion-button [productReference]="form.get('productReference').value" [includePrice]="true" (click)="showSelectProductModal(form)"></app-product-portion-button> -->
    </div>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Alias</span>
    </div>
    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="getProductInclusionGroupOptionFormValue(form, 'alias');else not_assigned">
        {{ getProductInclusionGroupOptionFormValue(form, 'alias') }}
      </span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="alias">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Default Quantity</span>
    </div>
    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="getProductInclusionGroupOptionFormValue(form, 'defaultQuantity');else none">
        {{ getProductInclusionGroupOptionFormValue(form, 'defaultQuantity') }}
      </span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="defaultQuantity">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Max Included Quantity</span>
    </div>
    <div *ngIf="scope == 'portionInclusionGroup'" style="flex: 1; margin-right: 5px;">
      <span *ngIf="getProductInclusionGroupOptionFormValue(form, 'maxIncludedQuantity');else none">
        {{ getProductInclusionGroupOptionFormValue(form, 'maxIncludedQuantity') }}
      </span>
    </div>
    <mat-form-field style="flex: 1; margin-left: 5px;">
      <input matInput formControlName="maxIncludedQuantity">
    </mat-form-field>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Preparations</span>
    </div>
    <div style="flex: 1;">

      <mat-selection-list cdkDropList class="draggable-list" [multiple]="false" (cdkDropListDropped)="dropPreparation($event)">

        <mat-list-option cdkDrag *ngFor="let preparationFormGroup of $any(form.get('preparations')).controls" [value]="preparationFormGroup" class="item" (click)="editPreparation(preparationFormGroup)" click-stop-propagation>
          <div style="display: flex; align-items: center;">
            <div style="width: 25px; margin-right: 5px;">
              <mat-icon>drag_indicator</mat-icon>
            </div>
            <div style="flex: 1;">
              <span>{{ getValueOrUnknown(resolvePreparationFormValue(preparationFormGroup, 'name', scope)) }}</span>
              <span *ngIf="scope != 'product' && getProductPreparationForm(preparationFormGroup)">
                <mat-icon>link</mat-icon>
              </span>
            </div>
            <mat-icon (click)="deletePreparation(preparationFormGroup)">delete</mat-icon>
          </div>
        </mat-list-option>

      </mat-selection-list>

      <div style="display: flex;">
        <button mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-right: 5px;" (click)="newPreparation()">Add</button>
        <button *ngIf="scope != 'product'" [disabled]="!canLinkPreparations" mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-left: 5px;" (click)="linkPreparation()">Link Product Inclusion</button>
      </div>
    </div>
  </div>

  <div style="display: flex;">
    <div style="flex: 1;">
      <span>Variations</span>
    </div>
    <div style="flex: 1;">

      <mat-selection-list cdkDropList class="draggable-list" [multiple]="false" (cdkDropListDropped)="dropVariation($event)">

        <mat-list-option cdkDrag *ngFor="let variationFormGroup of $any(form.get('variations')).controls" [value]="variationFormGroup" class="item" (click)="editVariation(variationFormGroup)" click-stop-propagation>
          <div style="display: flex; align-items: center;">
            <div style="width: 25px; margin-right: 5px;">
              <mat-icon>drag_indicator</mat-icon>
            </div>
            <div style="flex: 1;">
              <span>{{ getValueOrUnknown(resolveProductVariationFormValue(variationFormGroup, 'name', scope)) }}</span>
              <span *ngIf="scope != 'product' && getProductVariationForm(variationFormGroup)">
                <mat-icon>link</mat-icon>
              </span>
            </div>
            <mat-icon (click)="deleteVariation(variationFormGroup)">delete</mat-icon>
          </div>
        </mat-list-option>

      </mat-selection-list>

      <div style="display: flex;">
        <button mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-right: 5px;" (click)="newVariation()">Add</button>
        <button *ngIf="scope != 'product'" [disabled]="!canLinkVariations" mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-left: 5px;" (click)="linkVariation()">Link Product Variation</button>
      </div>
    </div>
  </div>

</div>
