import { Injectable } from "@angular/core";
import { HttpService, TenantProvider } from "core";
import { OltpServiceSettings } from "pos-core";
import { StoreServerSettings } from "pos-core";
import { Observable } from "rxjs";

@Injectable()
export class StoreIntegrationService {

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
    private tenantProvider: TenantProvider,
    private storeServiceSettings: StoreServerSettings,
  ) {
  }

  syncToStore(): Observable<boolean> {

    var request = {
      query: `mutation syncAll { syncAll(ownerUid: "${this.tenantProvider.currentUid}", storeFrontUid: "${this.storeServiceSettings.storeFrontUid}") }`
    };

    return this.httpService.graph<boolean>(this.oltpServiceSettings.apiUrl, 'api/oltp/storeintegration', request, 'syncAll')
  }
}
