<ng-container *ngIf="item">
    <div class="heading">
        NOTES
    </div>

    <div *ngFor="let note of item.notes" style="display: flex; justify-content: center;">
        <div style="width: 75%; display: flex;">
            <button mat-flat-button class="note active" (click)="editNote(note)" click-stop-propagation>{{ note }}</button>
            <button mat-flat-button style="flex: 1; margin-left: 4px;" (click)="deleteNote(note)" click-stop-propagation>
                <div style="display: flex; justify-content: center;">
                    <mat-icon>delete_outline</mat-icon>
                </div>
            </button>

        </div>
    </div>

    <div style="display: flex; justify-content: center;">
        <button mat-flat-button class="note" (click)="addNote()" click-stop-propagation>Add Note...</button>
    </div>
</ng-container>