import { UUID } from "angular2-uuid";
import { EditableTransaction, EditableTransactionAdjustment, EditableTransactionCharge, EditableTransactionChargeAdjustment, EditableTransactionGuest, EditableTransactionItem, EditableTransactionItemAdjustment, EditableTransactionItemConfiguration, EditableTransactionItemConfigurationInclusionGroup, EditableTransactionItemConfigurationPortion, EditableTransactionPayment } from "../../models/editable-transaction/editable-transaction";
import { Transaction, TransactionAdjustment, TransactionCharge, TransactionGuest, TransactionItem, TransactionPayment } from "../../models/transaction";
import { TransactionGuestInput, TransactionItemInput, TransactionItemInputConfiguration, TransactionItemInputConfigurationAddOn, TransactionItemInputConfigurationInclusionGroup, TransactionItemInputConfigurationPortion } from "../../models/transaction-input";
import { TransactionChargeAdjustmentInput, TransactionChargeInput, TransactionItemAdjustmentInput } from "../../models";

export function fromEditableTransaction(source: EditableTransaction): Transaction {

  return <Transaction>{
    uid: source.uid || UUID.UUID(),
    number: source.number.value,
    notes: source.notes.value,
    logisticTypeUid: source.logisticTypeUid,
    transactionStatus: source.transactionStatus.value,
    lockUid: source.lockUid.value,
    lastGuestUid: source.lastGuestUid,
    openDateTimeUtc: source.openDateTimeUtc,
    openAuthUid: source.openAuthUid,
    closeDateTimeUtc: source.closeDateTimeUtc,
    closeAuthUid: source.closeAuthUid,
    total: source.total.value,
    totalDue: source.totalDue.value,

    guests: source.guests ? source.guests.map(x => <TransactionGuest>fromEditableTransactionGuest(x)) : null,
    items: source.lineItems ? source.lineItems.map(x => <TransactionItem>fromEditableTransactionItem(x)) : null
  };
}

export function fromEditableTransactionGuest(source: EditableTransactionGuest): TransactionGuestInput {

  return <TransactionGuestInput>{
    uid: source.uid || UUID.UUID(),
    name: source.name
  };
}

export function fromEditableTransactionItem(source: EditableTransactionItem): TransactionItemInput {

  return <TransactionItemInput>{
    uid: source.uid || UUID.UUID(),
    departmentUid: source.departmentUid.value,
    categoryUid: source.categoryUid.value,
    productUid: source.productUid.value,
    productVersion: source.productVersion.value,
    guestUid: source.guestUid.value,
    configuration: source.configuration.value ? fromEditableTransactionItemConfiguration(source.configuration.value) : null,
    quantity: source.quantity.value,
    eachAmount: source.getEachAmount(),
    notes: source.notes
  };
}

export function fromEditableTransactionItemAdjustment(source: EditableTransactionItemAdjustment): TransactionItemAdjustmentInput {

  return <TransactionItemAdjustmentInput>{
    uid: source.uid || UUID.UUID(),
    description: source.description,
    quantity: source.getQuantity(),
    eachAmount: source.getEachAmount()
  };
}

export function fromEditableTransactionCharge(source: EditableTransactionCharge): TransactionChargeInput {

  return <TransactionChargeInput>{
    uid: source.uid || UUID.UUID(),
    chargeTypeUid: source.chargeTypeUid.value,
    description: source.description,
    quantity: source.getQuantity(),
    eachAmount: source.getEachAmount()
  };
}

export function fromEditableTransactionChargeAdjustment(source: EditableTransactionChargeAdjustment): TransactionChargeAdjustmentInput {

  return <TransactionChargeAdjustmentInput>{
    uid: source.uid || UUID.UUID(),
    description: source.description,
    quantity: source.getQuantity(),
    eachAmount: source.getEachAmount()
  };
}

export function fromEditableTransactionAdjustment(source: EditableTransactionAdjustment): TransactionAdjustment {

  return <TransactionAdjustment>{
    uid: source.uid || UUID.UUID(),
    description: source.description.value,
    amount: source.getAmount(),
  };
}

export function fromEditableTransactionPayment(source: EditableTransactionPayment): TransactionPayment {

  return <TransactionPayment>{
    uid: source.uid || UUID.UUID(),
    paymentMethodUid: source.paymentMethodUid.value,
    amount: source.getAmount(),
    referenceUid: source.referenceUid.value,
    metadata: source.metadata
  };
}

export function fromEditableTransactionItemConfiguration(source: EditableTransactionItemConfiguration): TransactionItemInputConfiguration {

  return <TransactionItemInputConfiguration>{
    portion: fromEditableTransactionItemConfigurationPortion(source.portion),
  }
};

export function fromEditableTransactionItemConfigurationPortion(source: EditableTransactionItemConfigurationPortion): TransactionItemInputConfigurationPortion {

  return <TransactionItemInputConfigurationPortion>{
    portionUid: source.portionUid,
    preparations: source.preparations,
    variations: source.variations,
    inclusionGroups: source.inclusionGroups ? source.inclusionGroups.map(x => fromEditableTransactionItemConfigurationInclusionGroup(x)) : null,
    addOns: source.addOns ? source.addOns.map(x => <TransactionItemInputConfigurationAddOn>{
      addOnUid: x.addOnUid,
      item: fromEditableTransactionItem(x.item)
    }) : null,
    notes: source.notes
  };
}

export function fromEditableTransactionItemConfigurationInclusionGroup(source: EditableTransactionItemConfigurationInclusionGroup): TransactionItemInputConfigurationInclusionGroup {

  return <TransactionItemInputConfigurationInclusionGroup>{

    inclusionGroupUid: source.inclusionGroupUid,
    options: source.options
  };
}