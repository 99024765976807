import { Injectable } from "@angular/core";

// THIS NEEDS TO BE CONVERTED TO A PROVIDER
@Injectable()
export class CacheService {

  private memoryStorage: { [key: string]: Entry<any> } = {};
  public enabled = true;

  constructor() {

    setInterval(() => {

      let now = new Date().getTime();
      let keys = Object.keys(this.memoryStorage);

      keys.forEach(key => {
        let entry = this.memoryStorage[key];
        if (now > entry.expiration) {
          this.invalidate(key);
        }
      });
    }, 1000);
  }

  clear() {

    Object.keys(this.memoryStorage).forEach(key => this.invalidate(key));
  }

  getOrAdd<T>(key: string, resolver: () => T, expirationMs: number = 24 * 60 * 60 * 1000): T {

    if (this.enabled) {
      let now = new Date().getTime();

      var entry = this.memoryStorage[key];
      if (!entry) {
        let resolvedValue = resolver();
        entry = <Entry<T>>{ value: resolvedValue };
        this.memoryStorage[key] = entry;
      }

      entry.expiration = now + expirationMs; // Slide the expiration

      return entry.value;
    } else {
      return resolver();
    }
  }

  addOrUpdate<T>(key: string, value: any, expirationMs: number = 24 * 60 * 60 * 1000): T {

    let now = new Date().getTime();

    let entry = <Entry<T>>{
      value: value,
      expiration: now + expirationMs
    };

    this.memoryStorage[key] = entry;

    return value;
  }

  invalidate(key: string) {

    this.memoryStorage[key] = null;
    delete this.memoryStorage[key];
  }
}

class Entry<T> {

  value: T;
  expiration: number;
}
