<div class="option" (click)="context.select(itemInclusionGroupOption)" [ngClass]="{ 'selected': context.selection == itemInclusionGroupOption }">

  <div class="display-line">

    <ng-container *ngIf="productInclusionGroupOption.defaultQuantity > 0 && itemInclusionGroupOption.quantity == 0">
      ** NO {{ context.getProductInclusionGroupOptionDisplay(productInclusionGroupOption, itemInclusionGroupOption.isSubstitution) | async }} **
    </ng-container>

    <ng-container *ngIf="itemInclusionGroupOption.quantity > 0">
      {{ itemInclusionGroupOption.quantity }} - {{ context.getProductInclusionGroupOptionDisplay(productInclusionGroupOption, itemInclusionGroupOption.isSubstitution) | async }}
    </ng-container>

    <span *ngIf="itemInclusionGroupOption.quantity > 0 && context.getAssignedItemPreparations(itemInclusionGroupOption).length > 0">
      &nbsp;(
      <span *ngFor="let itemPreparation of context.getAssignedItemPreparations(itemInclusionGroupOption); let last = last">
        <transaction-item-configurator-detail-preparation-option [context]="context" [productPreparation]="productInclusionGroupOption.getPreparation(itemPreparation.preparationUid)" [itemPreparation]="itemPreparation"></transaction-item-configurator-detail-preparation-option>
        <span *ngIf="!last">/</span>
      </span>
      &nbsp;)
    </span>

    <span *ngIf="itemInclusionGroupOption.variations && itemInclusionGroupOption.variations.length > 0">
      &nbsp;+&nbsp;
      <span *ngFor="let itemVariation of itemInclusionGroupOption.variations; let last = last">
        <transaction-item-configurator-detail-variation-option [context]="context" [productVariation]="productInclusionGroupOption.getVariation(itemVariation.variationUid)" [itemVariation]="itemVariation"></transaction-item-configurator-detail-variation-option>
        <span *ngIf="!last">/</span>
      </span>
    </span>

    <div *ngIf="itemInclusionGroupOption.quantity > 0 && itemInclusionGroupOption?.notes?.length > 0" style="margin-left: 10%;">
      <div *ngFor="let note of itemInclusionGroupOption.notes">
        {{ note }}
      </div>
    </div>

  </div>

</div>