<div class="editor">
  <ng-container *ngIf="cart; else noCart;">
    <ng-container *ngTemplateOutlet="hasCart;"></ng-container>
  </ng-container>

</div>

<ng-template #hasCart>

  <div style="height: 100%; display: flex; flex-direction: column;">

    <div style="display: flex; border-bottom: 1px solid #000; margin-bottom: 10px;">
      <span *ngIf="canEdit" style="flex: 10;"></span>
      <span style="flex: 50;">ITEM</span>
      <span style="flex: 10; text-align: right;">QTY</span>
      <span style="flex: 10; text-align: right;">EACH</span>
      <span style="flex: 10; text-align: right;">TOTAL</span>
      <span *ngIf="canEdit" style="flex: 10;"></span>
    </div>

    <ng-container *ngFor="let cartItem of cart.items">
      <!-- <app-cart-item-editor [cart]="cart" [cartItem]="cartItem" (selectItem)="onSelectItem($event)"></app-cart-item-editor> -->
    </ng-container>

    <div style="display: flex; margin-top: 2px;">
      <!-- <button *ngIf="!canEdit" mat-flat-button style="font-size: 10px; line-height: 14px; padding: 4px;" (click)="addItem()">Add Item</button> -->
      <span style="flex: 1 1 auto;"></span>
    </div>

    <div style="display: flex; margin-top: 10px; border-top: 1px solid #000;">
      <span style="width: 10%; text-align: right;"></span>
      <span style="width: 75%; text-align: right;">Sub Total</span>
      <span style="width: 10%; text-align: right;">{{ cart.subTotalAmount | currency:'USD':'symbol':'1.2-2' }}</span>
      <span style="width: 5%;"></span>
    </div>

  </div>

</ng-template>

<ng-template #noCart>
  <div style="height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <span>No Cart Items</span>
  </div>
</ng-template>
