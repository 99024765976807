import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthHeaderProvider, TenantProvider } from 'core';
import { SpinnerModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { CategoryForm } from 'downtown-product';
import { CategoryService } from 'downtown-product';

@Component({
  selector: 'app-back-office-category',
  templateUrl: './back-office-category.component.html',
  styleUrls: ['./back-office-category.component.scss']
})
export class BackOfficeProductCategoryComponent extends CategoryForm implements OnInit {

  constructor(
    activatedRoute: ActivatedRoute,
    navigationProvider: NavigationProvider,
    spinnerModalProvider: SpinnerModalProvider,
    categoryService: CategoryService,
    tenantProvider: TenantProvider,
    authHeaderProvider: AuthHeaderProvider,
  ) {
    super(activatedRoute, navigationProvider, spinnerModalProvider, tenantProvider, categoryService, authHeaderProvider);

    this.navigationProvider.setWaypoint('Product Category');
  }

  ngOnInit() {

    let categoryUid = this.activatedRoute.snapshot.params['categoryUid'];
    if (categoryUid) {
      let spinnerModalRef = this.spinnerModalProvider.open();
      spinnerModalRef.afterOpened().subscribe(() => {
        this.categoryService.getByUid(categoryUid, this.authHeaderProvider).subscribe(category => {
          if (category) {
            this.loadForm(category);

            spinnerModalRef.close();
          } else {
            this.navigationProvider.backOneWaypoint();
          }
        });
      });
    }
  }
}
