import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DateTimeModalData } from "./date-time-modal.provider";

@Component({
  selector: 'app-date-time-modal',
  templateUrl: './date-time-modal.component.html',
  styleUrls: ['./date-time-modal.component.scss']
})
export class DateTimeModalComponent {

  selectedDate: Date;

  constructor(
    private dialogRef: MatDialogRef<DateTimeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DateTimeModalData
  ) {

  }

  ngOnInit() {

    var date = this.data.initialDate ? this.data.initialDate : this.round(new Date(), 15 * 60 * 1000);

    this.selectedDate = date;
  }

  dateSelected(value: { month: number, day: number, year: number }) {

    this.selectedDate = new Date(value.year, value.month - 1, value.day, this.selectedDate.getHours(), this.selectedDate.getMinutes());
  }

  timeSelected(value: { hour: number, minute: number }) {

    this.selectedDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), value.hour, value.minute);
  }

  commit() {

    this.dialogRef.close(this.selectedDate);
  }

  cancel() {

    this.dialogRef.close();
  }

  private round(date: Date, durationMs: number): Date {

    let dateMs = date.getTime();

    return new Date(Math.ceil((+dateMs) / (+durationMs)) * (+durationMs));
  }
}

class DatePickableModel {
  date: Date;
  isSelectable: boolean;
}
