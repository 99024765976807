import { Observable, concatMap, first, of } from "rxjs";
import { TransactionItemPrintModalData, TransactionItemPrintModalProvider, TransactionItemPrintModalResult } from "../modals";
import { TimeoutProvider, SettingProvider, isCaseInsensitiveEqual } from "core";
import { timeoutEnclosure } from "./timeout-enclosure";
import { TerminalProvider, TerminalSettings } from "downmain-terminal";
import { DeviceSettings } from "downmain-device";

export function getPrintLocationHandler$(
  timeoutProvider: TimeoutProvider,
  settingProvider: SettingProvider,
  terminalProvider: TerminalProvider,
  transactionItemPrintModalProvider: TransactionItemPrintModalProvider
): Observable<TransactionItemPrintModalResult> {
  return timeoutEnclosure(
    timeoutProvider,
    settingProvider.getOneByTypeAndOwner$<DeviceSettings>('DeviceSettings', terminalProvider.active.uid).pipe(
      first(),
      concatMap(terminalSettings => {
        if (terminalSettings) {
          const printerDeviceSettings = terminalSettings.settings.filter(x => isCaseInsensitiveEqual(x.deviceType, 'ReceiptPrinter'));

          return settingProvider.getOneByTypeAndOwner$<TerminalSettings>('TerminalSettings', terminalProvider.active.uid).pipe(
            concatMap(terminalSettings => {
              return transactionItemPrintModalProvider.open(<TransactionItemPrintModalData>{
                title: 'Print Options',
                printerDeviceSettings: printerDeviceSettings,
                defaultPrintLocationDeviceUid: terminalSettings.defaultKitchenPrinterDeviceUid
              }).afterClosed()
            })
          );
        }

        return of(null);
      }),
    )
  );
}
