<ng-container *ngIf="cart && cart.items.length > 0; else emptycart">
  <div>
    Cart Items: {{ cart.itemQuantity }}
  </div>

  <div *ngFor="let cartItem of cart.items" style="flex: 1; min-width: 50%; padding: 3px 0px;">
    <div style="background-color: rgb(89, 74, 36); border: 1px solid rgb(128, 96, 15); border-radius: 3px; padding: 2px;">

      <store-core-cart-item [cartItem]="cartItem"></store-core-cart-item>

    </div>
  </div>

  <div style="display: flex; padding-top: 3px;">
    <div style="width: 38%; text-align: right;"></div>
    <div style="width: 15%; text-align: right; background-color: rgb(89, 74, 36); border-top: 1px solid rgb(128, 96, 15); border-left: 1px solid rgb(128, 96, 15); border-radius: 3px 0px 0px 0px;">Item Totals</div>
    <div style="width: 10%; text-align: right; background-color: rgb(89, 74, 36); border-top: 1px solid rgb(128, 96, 15);">{{ 0 | number:'1.0-2' }}</div>
    <div style="width: 2%; text-align: center; background-color: rgb(89, 74, 36); border-top: 1px solid rgb(128, 96, 15);">&#64;</div>
    <div style="width: 15%; text-align: right; background-color: rgb(89, 74, 36); border-top: 1px solid rgb(128, 96, 15);">{{ 0 | currency:'USD':'symbol':'1.2-2' }}</div>
    <div style="width: 20%; text-align: right; background-color: rgb(89, 74, 36); border-top: 1px solid rgb(128, 96, 15); border-right: 1px solid rgb(128, 96, 15);  border-radius: 0px 3px 0px 0px;">{{ 0 | currency:'USD':'symbol':'1.2-2' }}</div>
  </div>

  <div style="display: flex;">
    <div style="width: 38%; text-align: right;"></div>
    <div style="width: 42%; text-align: right; background-color: rgb(89, 74, 36); border-left: 1px solid rgb(128, 96, 15); border-radius: 0px 0px 0px 3px; padding-top: 20px;">Tax Total</div>
    <div style="width: 20%; text-align: right; background-color: rgb(89, 74, 36); border-right: 1px solid rgb(128, 96, 15);  border-radius: 0px 0px 3px 0px; padding-top: 20px;">{{ 0 | currency:'USD':'symbol':'1.2-2' }}</div>
  </div>

  <div style="display: flex;">
    <div style="width: 38%; text-align: right;"></div>
    <div style="width: 42%; text-align: right; background-color: rgb(89, 74, 36); border-left: 1px solid rgb(128, 96, 15); border-radius: 0px 0px 0px 3px;">Delivery Fee</div>
    <div style="width: 20%; text-align: right; background-color: rgb(89, 74, 36); border-right: 1px solid rgb(128, 96, 15);  border-radius: 0px 0px 3px 0px;">{{ 0 | currency:'USD':'symbol':'1.2-2' }}</div>
  </div>

  <div style="display: flex; padding-bottom: 3px;">
    <div style="width: 38%; text-align: right;"></div>
    <div style="width: 42%; text-align: right; background-color: rgb(89, 74, 36); border-bottom: 1px solid rgb(128, 96, 15); border-left: 1px solid rgb(128, 96, 15); border-radius: 0px 0px 0px 3px; padding-top: 20px;">Order Total</div>
    <div style="width: 20%; text-align: right; background-color: rgb(89, 74, 36); border-bottom: 1px solid rgb(128, 96, 15); border-right: 1px solid rgb(128, 96, 15);  border-radius: 0px 0px 3px 0px; padding-top: 20px;">{{ 0 | currency:'USD':'symbol':'1.2-2' }}</div>
  </div>
</ng-container>

<ng-template #emptycart>
  You have no items in your cart.
</ng-template>

