import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { ProductStatusKeys } from '../../keys';
import { ProductProvider } from '../../providers/product.provider';

@Component({
  selector: 'app-product-portion-button',
  templateUrl: './product-portion-button.component.html',
  styleUrls: ['./product-portion-button.component.scss']
})
export class ProductPortionButtonComponent implements OnInit {

  @Input() public productUid: string;
  @Input() public portionUid: string;
  @Input() public version: number;
  @Input() public includePrice: boolean = false;

  public display$ = new BehaviorSubject<string>('Select...');

  constructor(
    private productProvider: ProductProvider,
    private currencyPipe: CurrencyPipe
  ) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {

    this.getProductAndPortionDisplay();
  }

  private getProductAndPortionDisplay() {

    if (this.productUid != null && this.portionUid != null && this.version != null) {
      this.productProvider.getOneCached$(this.productUid, this.version).pipe(
        map(product => {
          if (product && product.configuration) {
            let portion = product.configuration.portions.find(x => x.uid.toUpperCase() == this.portionUid.toUpperCase());
            if (portion) {
              let display = `${product.name} - ${portion.name} ${this.includePrice ? '@' + this.currencyPipe.transform(portion.price, 'USD', 'symbol', '1.2-2') : ''}`;

              if (product.productStatusUid == ProductStatusKeys.Inactive) {
                display += ' (Inactive)'
              }

              if (product.productStatusUid == ProductStatusKeys.Deleted) {
                display += ' (Deleted)'
              }

              this.display$.next(display);

              return;
            }
          }

          this.display$.next('Unknown');
        })
      ).subscribe();
    } else {
      this.display$.next('Unassigned');
    }
  }
}
