import { Metadata } from "./metadata";

export class SystemMessageInput {

  uid: string;
  type: string;
  sourceNodeIdentifier: string;
  targetNodeIdentifier: string;
  targetResourceUid: string;
  payload: string;
}
