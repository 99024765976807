import { InjectionToken } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { type ItemConfiguratorModalData, type ItemConfiguratorModalResult } from './item-configurator-modal.provider';
import { type ItemConfiguratorModalComponent } from './item-configurator-modal.component';

export let ITEM_CONFIGURATOR_MODAL_PROVIDER = new InjectionToken<IItemConfiguratorModalProvider>('item-configurator-modal-provider');

export interface IItemConfiguratorModalProvider {

  open(data: ItemConfiguratorModalData): MatDialogRef<ItemConfiguratorModalComponent, ItemConfiguratorModalResult>;
}
