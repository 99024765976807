import { Component, OnInit } from '@angular/core';
import { NavigationProvider } from 'core';
import { PosRuntimeProvider } from 'pos-core';

@Component({
  selector: 'app-back-office-dashboard',
  templateUrl: './back-office-dashboard.component.html',
  styleUrls: ['./back-office-dashboard.component.scss']
})
export class BackOfficeDashboardComponent implements OnInit {


  constructor(
    private navigationProvider: NavigationProvider,
    public runtimeProvider: PosRuntimeProvider
  ) {
    this.navigationProvider.setWaypoint('Back Office Dashboard');
  }

  ngOnInit() {
  }
}


