import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '../models/employee';
import { EmployeeProvider } from '../providers/employee.provider';
import { BehaviorSubject, Observable } from 'rxjs';

@Pipe({
  name: 'employee'
})
export class EmployeePipe implements PipeTransform {

  private value$ = new BehaviorSubject<Employee>(null);

  constructor(
    private employeeProvider: EmployeeProvider
  ) {
  }

  transform(uid: string): Observable<Employee> {

    if (uid) {
      this.employeeProvider.getCached$(uid).subscribe(employee => {
        this.value$.next(employee);
      });
    }

    return this.value$;
  }
}
