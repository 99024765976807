import { Component, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, map, concatMap, takeUntil } from 'rxjs/operators';
import { ActionBarConfiguration, ActionConfiguration, FilterConfiguration, FilterOptionConfiguration, getParamsFromFilters, getParamsFromPaginator, isCaseInsensitiveEqual, updateFiltersFromParams, updatePaginatorFromParams } from 'core';
import { Paginator } from 'core';
import { ConfirmModalProvider } from 'core';
import { SpinnerModalComponent } from 'core';
import { SpinnerModalProvider } from 'core';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { PositionDataSource, PositionModel } from 'pos-core';
import { PositionStatusKeys } from 'pos-core';
import { PositionService, PositionViewOptions } from 'pos-core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-back-office-positions',
  templateUrl: './back-office-positions.component.html',
  styleUrls: ['./back-office-positions.component.scss']
})
export class BackOfficePositionsComponent implements OnInit {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: PositionDataSource;
  public paginator: Paginator;

  public title: string;
  public columns: Array<string>;

  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private positionService: PositionService
  ) {
    this.navigationProvider.setWaypoint('Positions');
    this.title = 'Positions';

    this.dataSource = new PositionDataSource(this.positionService, false);
    this.paginator = new Paginator(this.dataSource, 0, 50, null, 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });
0
    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('New', this.new.bind(this)),
        new ActionConfiguration('Edit', () => this.edit(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Delete', () => this.delete(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDelete))
      ],
      [
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('Active', [PositionStatusKeys.Active]),
            new FilterOptionConfiguration('All', [PositionStatusKeys.Active, PositionStatusKeys.Inactive])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'Active')) }
        )
      ]
    );

    // Build initial columns
    this.columns = [ /*'select',*/ 'name', 'description'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    const statusFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => isCaseInsensitiveEqual(x.title, 'Status'));
    const paginator = this.paginator;

    this.activatedRoute.queryParams.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(params => {
      updateFiltersFromParams([statusFilter], params);
      updatePaginatorFromParams(paginator, params);
    });

    combineLatest([
      statusFilter.selected,
      this.paginator.pageRequest$
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([statusFilterOption, paginationInput]) => {
        let statusFilterUids = statusFilterOption.value;

        const params = Object.assign(getParamsFromFilters([statusFilter]), getParamsFromPaginator(paginator));
        this.navigationProvider.navigate([], { queryParams: params, queryParamsHandling: 'merge' }).then(_ => this.navigationProvider.updateLastWaypoint());

        this.dataSource.loadData(statusFilterUids, paginationInput, <PositionViewOptions>{});
      })
    ).subscribe();
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }

  new() {

    this.navigationProvider.navigate(['/backoffice/position']);
  }

  edit(position: PositionModel) {

    this.navigationProvider.navigate(['/backoffice/position', position.uid]);
  }

  public delete(position: PositionModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Delete Position', 'Are you certain you want to delete this position?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Deleting...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.delete(position)),
          catchError(() => of(null)),
        ).subscribe(() => {
          waitDialogRef.close();
          // this.dataSource.loadData(<PositionViewOptions>{});
        });
      }
    })
  }
}
