import { CategorySelectModalComponent } from "./category-select-modal/category-select-modal.component";
import { CategorySelectModalProvider } from "./category-select-modal/category-select-modal.provider";
import { ProductAndPortionModalComponent } from "./product-and-portion-modal/product-and-portion-modal.component";
import { ProductAndPortionModalProvider } from "./product-and-portion-modal/product-and-portion-modal.provider";
import { ProductPreparationModalComponent } from "./product-preparation-modal/product-preparation-modal.component";
import { ProductPreparationModalProvider } from "./product-preparation-modal/product-preparation-modal.provider";
import { ProductSelectModalComponent } from "./product-select-modal/product-select-modal.component";
import { ProductSelectModalProvider } from "./product-select-modal/product-select-modal.provider";
import { ProductVariationModalComponent } from "./product-variation-modal/product-variation-modal.component";
import { ProductVariationModalProvider } from "./product-variation-modal/product-variation-modal.provider";

export const modalComponentTypes = [
  CategorySelectModalComponent,
  ProductAndPortionModalComponent,
  ProductPreparationModalComponent,
  ProductSelectModalComponent,
  ProductVariationModalComponent,
];

export const modalProviderTypes = [
  CategorySelectModalProvider,
  ProductAndPortionModalProvider,
  ProductPreparationModalProvider,
  ProductSelectModalProvider,
  ProductVariationModalProvider,
];
