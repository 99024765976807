import { Injectable } from "@angular/core";
import { LoggingProvider } from "core";
import { RemoteLoggingService } from "../services";

@Injectable()
export class RemoteLoggingProvider extends LoggingProvider {

  constructor(
    private remoteLoggingService: RemoteLoggingService
  ) {
    super();
  }

  publish() {

    try {
      const publishLogs = LoggingProvider._logs.slice(LoggingProvider._lastLogPublishIndex);
      LoggingProvider._lastLogPublishIndex += publishLogs.length;

      this.remoteLoggingService.publish(publishLogs).subscribe();
    } catch (ex) {
      console.log(ex);
    }
  }
}
