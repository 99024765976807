import { ModelFactory } from "core";
import { Category } from "./models/category";
import { Department } from "./models/department";
import {
  Product,
  ProductConfiguration,
  ProductConfigurationAddOn,
  ProductConfigurationPortion,
  ProductConfigurationVariation,
  ProductConfigurationVariationOption,
  ProductConfigurationPreparation,
  ProductConfigurationPreparationOption,
  ProductConfigurationInclusionGroup,
  ProductConfigurationInclusionGroupOption,
  ProductConfigurationTab
} from './models/product';

export class ProductModelFactory {

  public static assignCategory(input: Category): Category {

    if (input) {
      return ModelFactory.assign(input, Category);
    }

    return null;
  }

  public static mergeCategory(source: Category, target: Category): Category {

    target.ownerUid = source.ownerUid === undefined ? target.ownerUid : source.ownerUid;
    target.name = source.name === undefined ? target.name : source.name;
    target.description = source.description === undefined ? target.description : source.description;
    target.departmentUid = source.departmentUid === undefined ? target.departmentUid : source.departmentUid;
    target.selectableByDefault = source.selectableByDefault === undefined ? target.selectableByDefault : source.selectableByDefault;
    target.categoryStatusUid = source.categoryStatusUid === undefined ? target.categoryStatusUid : source.categoryStatusUid;

    return target;
  }

  public static assignDepartment(input: Department): Department {

    if (input) {
      var output = ModelFactory.assign(input, Department)
      output.categories = input.categories ? input.categories.map(x => ProductModelFactory.assignCategory(x)) : output.categories;

      return output;
    }

    return null;
  }

  public static mergeDepartment(source: Department, target: Department): Department {

    target.ownerUid = source.ownerUid === undefined ? target.ownerUid : source.ownerUid;
    target.name = source.name === undefined ? target.name : source.name;
    target.accountSettingUid = source.accountSettingUid === undefined ? target.accountSettingUid : source.accountSettingUid;
    target.isSalesTaxIncluded = source.isSalesTaxIncluded === undefined ? target.isSalesTaxIncluded : source.isSalesTaxIncluded;
    target.taxAccountSettingUid = source.taxAccountSettingUid === undefined ? target.taxAccountSettingUid : source.taxAccountSettingUid;
    target.departmentStatusUid = source.departmentStatusUid === undefined ? target.departmentStatusUid : source.departmentStatusUid;

    target.categories = source.categories === undefined ? target.categories : source.categories.map(x => ProductModelFactory.assignCategory(x));

    return target;
  }

  public static assignProduct(input: Product): Product {

    if (input) {
      var output = ModelFactory.assign(input, Product)
      output.configuration = input.configuration ? ProductModelFactory.assignProductConfiguration(input.configuration) : output.configuration;

      return output;
    }

    return null;
  }

  public static mergeProduct(source: Product, target: Product): Product {

    target.ownerUid = source.ownerUid === undefined ? target.ownerUid : source.ownerUid;
    target.sku = source.sku === undefined ? target.sku : source.sku;
    target.name = source.name === undefined ? target.name : source.name;
    target.abbreviation = source.abbreviation === undefined ? target.abbreviation : source.abbreviation;
    target.description = source.description === undefined ? target.description : source.description;
    target.imageUid = source.imageUid === undefined ? target.imageUid : source.imageUid;
    target.displayOrder = source.displayOrder === undefined ? target.displayOrder : source.displayOrder;
    target.departmentUid = source.departmentUid === undefined ? target.departmentUid : source.departmentUid;
    target.categoryUid = source.categoryUid === undefined ? target.categoryUid : source.categoryUid;
    target.basePrice = source.basePrice === undefined ? target.basePrice : source.basePrice;
    target.productStatusUid = source.productStatusUid === undefined ? target.productStatusUid : source.productStatusUid;
    target.version = source.version === undefined ? target.version : source.version;

    target.configuration = source.configuration === undefined ? target.configuration : ProductModelFactory.assignProductConfiguration(source.configuration);

    return target;
  }

  public static assignProductConfiguration(input: ProductConfiguration): ProductConfiguration {

    if (input) {
      var output = ModelFactory.assign(input, ProductConfiguration);
      output.portions = input.portions ? input.portions.map(x => ProductModelFactory.assignProductConfigurationPortion(x)) : output.portions;
      output.preparations = input.preparations ? input.preparations.map(x => ProductModelFactory.assignProductConfigurationPreparation(x)) : output.preparations;
      output.variations = input.variations ? input.variations.map(x => ProductModelFactory.assignProductConfigurationVariation(x)) : output.variations;
      output.inclusionGroups = input.inclusionGroups ? input.inclusionGroups.map(x => ProductModelFactory.assignProductConfigurationInclusionGroup(x)) : output.inclusionGroups;
      output.addOns = input.addOns ? input.addOns.map(x => ProductModelFactory.assignProductConfigurationAddOn(x)) : output.addOns;

      return output;
    }

    return null;
  }

  public static assignProductConfigurationPortion(input: ProductConfigurationPortion): ProductConfigurationPortion {

    if (input) {
      var output = ModelFactory.assign(input, ProductConfigurationPortion);
      output.tabs = input.tabs?.map(x => ProductModelFactory.assignProductConfigurationTab(x)) || output.tabs;
      output.inclusionGroups = input.inclusionGroups ? input.inclusionGroups.map(x => ProductModelFactory.assignProductConfigurationInclusionGroup(x)) : output.inclusionGroups;

      return output;
    }

    return null;
  }

  public static assignProductConfigurationVariation(input: ProductConfigurationVariation): ProductConfigurationVariation {

    if (input) {
      var output = ModelFactory.assign(input, ProductConfigurationVariation);
      output.options = input.options ? input.options.map(x => ProductModelFactory.assignProductConfigurationVariationOption(x)) : output.options;

      return output;
    }

    return null;
  }

  public static assignProductConfigurationVariationOption(input: ProductConfigurationVariationOption): ProductConfigurationVariationOption {

    return input ? ModelFactory.assign(input, ProductConfigurationVariationOption) : input;
  }

  public static assignProductConfigurationPreparation(input: ProductConfigurationPreparation): ProductConfigurationPreparation {

    if (input) {
      var output = ModelFactory.assign(input, ProductConfigurationPreparation);
      output.options = input.options ? input.options.map(x => ProductModelFactory.assignProductConfigurationPreparationOption(x)) : output.options;

      return output;
    }

    return null;
  }

  public static assignProductConfigurationPreparationOption(input: ProductConfigurationPreparationOption): ProductConfigurationPreparationOption {

    return input ? ModelFactory.assign(input, ProductConfigurationPreparationOption) : input;
  }

  public static assignProductConfigurationTab(input: ProductConfigurationTab): ProductConfigurationTab {

    return input ? ModelFactory.assign(input, ProductConfigurationTab) : input;
  }

  public static assignProductConfigurationInclusionGroup(input: ProductConfigurationInclusionGroup): ProductConfigurationInclusionGroup {

    if (input) {
      var output = ModelFactory.assign(input, ProductConfigurationInclusionGroup);
      output.options = input.options ? input.options.map(x => ProductModelFactory.assignProductConfigurationInclusionGroupOption(x)) : output.options;
      output.preparations = input.preparations ? input.preparations.map(x => ProductModelFactory.assignProductConfigurationPreparation(x)) : output.preparations;
      output.variations = input.variations ? input.variations.map(x => ProductModelFactory.assignProductConfigurationVariation(x)) : output.variations;

      return output;
    }

    return null;
  }

  public static assignProductConfigurationInclusionGroupOption(input: ProductConfigurationInclusionGroupOption): ProductConfigurationInclusionGroupOption {

    if (input) {
      var output = ModelFactory.assign(input, ProductConfigurationInclusionGroupOption);
      output.preparations = input.preparations ? input.preparations.map(x => ProductModelFactory.assignProductConfigurationPreparation(x)) : output.preparations;
      output.variations = input.variations ? input.variations.map(x => ProductModelFactory.assignProductConfigurationVariation(x)) : output.variations;

      return output;
    }

    return null;
  }

  public static assignProductConfigurationAddOn(input: ProductConfigurationAddOn): ProductConfigurationAddOn {

    if (input) {
      var output = ModelFactory.assign(input, ProductConfigurationAddOn)
      output.product = input.product ? ProductModelFactory.assignProduct(input.product) : output.product;

      return output;
    }

    return null;
  }
}
