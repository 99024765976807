<div style="display: flex; flex-direction: column; height: 100%;">

  <app-page-header [title]="title">
  </app-page-header>

  <app-action-bar [actionBarConfiguration]="actionBarConfiguration">
    <div style="width: 100%; text-align: center;">
      Date
    </div>
  </app-action-bar>

  <div style="flex: 1; overflow-y: hidden;">
    <div #chartContainer style="height: 100%;" (window:resize)="resizeChart($event)">
      <div *ngIf="chart.visible" style="height: 100%;">
        <!-- <google-chart
          [title]="chart.title"
          [type]="chart.type"
          [data]="chart.data"
          [columns]="chart.columnNames"
          [options]="chart.options"
          [width]="chart.width"
          [height]="chart.height"
        >
        </google-chart> -->
      </div>
    </div>
  </div>

</div>
