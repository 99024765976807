import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';

import { CurrencyMaskModule } from 'ng2-currency-mask';

import { CoreModule } from 'core';
import { DownMainDeviceModule } from 'downmain-device';
import { DowntownProductModule } from 'downtown-product';

import { ItemConfigurationDetailComponent } from './components';
import { ITEM_CONFIGURATION_DETAIL_PROVIDER } from './components';
import { componentTypes } from './components';

import { ProductConfigurationDetailDirective } from './directives';

import { ItemConfiguratorModalProvider } from './modals';
import { ITEM_CONFIGURATOR_MODAL_PROVIDER } from './modals';
import { modalComponentTypes, modalProviderTypes } from './modals';

import { CardTransactionStatusPipe } from './pipes';
import { LastFourPipe } from './pipes';
import { LogisticTypePipe } from './pipes';
import { TransactionConfigurationSummaryPipe } from './pipes';
import { TransactionLineItemPipe } from './pipes';

import { TransactionProvider } from './providers';

import { TransactionService } from './services';
import { CardTransactionService } from './services';
import { directiveTypes } from './directives/directive-types';

const providerTypes = [
  TransactionProvider
];


const pipes = [
  CardTransactionStatusPipe,
  LastFourPipe,
  LogisticTypePipe,
  TransactionConfigurationSummaryPipe,
  TransactionLineItemPipe
];

const services = [
  CardTransactionService,
  TransactionService
];

@NgModule({
  declarations: [
    ...componentTypes,
    ...directiveTypes,
    ...modalComponentTypes,
    ...pipes,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatTableModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,

    CurrencyMaskModule,

    CoreModule,
    DownMainDeviceModule,
    DowntownProductModule
  ],
  exports: [
    ...componentTypes,
    ...pipes,

    ProductConfigurationDetailDirective
  ],
  providers: [
    ...modalProviderTypes,
    ...pipes,
    ...providerTypes,
    ...services,
    {
      provide: ITEM_CONFIGURATOR_MODAL_PROVIDER, // Token to beat cyclical compilation
      useClass: ItemConfiguratorModalProvider
    },
    {
      provide: ITEM_CONFIGURATION_DETAIL_PROVIDER, // Token to beat cyclical compilation
      useClass: ItemConfigurationDetailComponent
    }
  ]
})
export class DowntownTransactionModule { }
