import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'downtown-product';
import { NavigationProvider } from 'core';
import { Cart } from '../../../models/cart';
import { ProductService } from 'downtown-product';
import { CheckoutContext } from '../check-out-context';

@Component({
  selector: 'store-core-checkout-shipping',
  templateUrl: './checkout-shipping.component.html',
  styleUrls: ['./checkout-shipping.component.scss']
})
export class CheckoutShippingComponent implements OnInit {

  @Input() public context: CheckoutContext;
  public cart: Cart;

  public countries: string[] = [
    "United States",
    "Canada",
    "Mexico"
  ];

  constructor(
    private navigationProvider: NavigationProvider,
    private productService: ProductService,
  ) {
  }

  ngOnInit() {
    this.navigationProvider.updateTitle("Store");

    // this.productService.search(this.pagination).subscribe(productsPage => {
    //   if (productsPage) {
    //     this.products = productsPage.edges.map(x => x.node);
    //   }
    // })
  }

  addToCart(product: Product) {

  }

  checkOut() {

  }
}
