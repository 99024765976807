<div style="max-width: 1024px; margin-left: auto; margin-right: auto; height: 100%;">
  <div>
    <div class="" style="padding-left: 30px; padding-right: 30px;">
      <div class="">
        <p class="lead">** CHECKOUT **</p>
      </div>
      <div class="">
      </div>
    </div>

    <mat-horizontal-stepper [linear]="true" #stepper>

      <mat-step label="Logistics" [stepControl]="context.logisticsForm">

        <store-core-checkout-logistics [context]="context"></store-core-checkout-logistics>

        <mat-divider style="margin-top: 20px; margin-bottom: 20px;"></mat-divider>

        <div style="display: flex;">
          <span style="flex: 1 1 auto;"></span>
          <button [disabled]="!context.logisticsForm.valid" mat-flat-button type="button" color="accent" (click)="goForward()">Continue...</button>
        </div>

      </mat-step>

      <mat-step label="Identity" [stepControl]="context.identityForm">

        <store-core-checkout-identity [context]="context"></store-core-checkout-identity>

        <mat-divider style="margin-top: 20px; margin-bottom: 20px;"></mat-divider>

        <div style="display: flex;">
          <span style="flex: 1 1 auto;"></span>
          <button [disabled]="!context.identityForm.valid" mat-flat-button type="button" color="accent" (click)="goForward()">Continue...</button>
        </div>

      </mat-step>

      <mat-step *ngIf="context.logisticsForm.get('logisticTypeUid').value == '3F46E3F1-EA40-4012-8260-CD22B5CC054C'" label="Shipping" [stepControl]="context.shippingForm">

        <store-core-checkout-shipping [context]="context"> </store-core-checkout-shipping>

        <mat-divider style="margin-top: 20px; margin-bottom: 20px;"></mat-divider>

        <div style="display: flex;">
          <span style="flex: 1 1 auto;"></span>
          <button [disabled]="!context.shippingForm.valid" mat-flat-button type="button" color="accent" (click)="goForward()">Continue...</button>
        </div>

      </mat-step>
      <!--
      <mat-step *ngIf="logisticsForm.get('logisticPaymentMethodUid').value == 'CFDCABDE-FDBE-421D-82C5-3E2ECEAB50CF'" label="Payment" [stepControl]="billingForm">
        <div [formGroup]="billingForm">

          <div style="display: flex;" formGroupName="creditCard">
            <div style="flex: 1;">
              Credit Card
            </div>
            <div style="flex: 3;">
              <div>
                <mat-form-field style="width: 50%;">
                  <input matInput type="text" formControlName="name" placeholder="Name On Card" />
                  <mat-error *ngIf="billingForm.get('creditCard').get('name').hasError('required')">Name On Card is required</mat-error>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field style="width: 50%;">
                  <input matInput type="text" formControlName="number" placeholder="Card Number" />
                  <mat-error *ngIf="billingForm.get('creditCard').get('number').hasError('required')">Card Number is required</mat-error>
                </mat-form-field>
              </div>
              <div style="display: flex;">
                <div style="flex: 1;">
                  <mat-form-field style="display: 100%;">
                    <mat-select formControlName="expiryMonth" placeholder="Expiration Month">
                      <mat-option *ngFor="let expiryMonth of expiryMonths" [value]="expiryMonth.id">{{ expiryMonth.display }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="billingForm.get('creditCard').get('expiryMonth').hasError('required')">Expiration Month is required</mat-error>
                  </mat-form-field>
                </div>
                <div style="flex: 1;">
                  <mat-form-field style="width: 100%; padding-left: 15px; padding-right: 15px;">
                    <mat-select formControlName="expiryYear" placeholder="Expiration Year">
                      <mat-option *ngFor="let expiryYear of expiryYears" [value]="expiryYear.id">{{ expiryYear.display }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="billingForm.get('creditCard').get('expiryYear').hasError('required')">Expiration Year is required</mat-error>
                  </mat-form-field>
                </div>
                <div style="flex: 1;">
                  <mat-form-field style="width: 50%;">
                    <input matInput type="text" formControlName="csc" placeholder="CSC" />
                    <mat-error *ngIf="billingForm.get('creditCard').get('csc').hasError('required')">CSC is required</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div style="display: flex;">
            <div style="flex: 1;">
              <div>Billing Address</div>
            </div>
            <div style="flex: 3;">
              <div *ngIf="logisticsForm.get('logisticTypeUid').value == '3F46E3F1-EA40-4012-8260-CD22B5CC054C'">
                <input type="checkbox" formControlName="isSameAsShipping" />&nbsp;Same as Shipping
              </div>
              <div *ngIf="logisticsForm.get('logisticTypeUid').value != '3F46E3F1-EA40-4012-8260-CD22B5CC054C' || !billingForm.get('isSameAsShipping').value" formGroupName="address">
                <div>
                  <mat-form-field style="width: 100%;">
                    <mat-select formControlName="country" placeholder="Country" autocomplete="country">
                      <mat-option *ngFor="let country of countries" [value]="country">{{ country }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="billingForm.get('address').get('country').hasError('required')">Country is required</mat-error>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field style="width: 100%;">
                    <input matInput type="text" formControlName="address1" placeholder="Address" autocomplete="address" />
                    <mat-error *ngIf="billingForm.get('address').get('address1').hasError('required')">Address is required</mat-error>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field style="width: 100%;">
                    <input matInput type="text" formControlName="address2" placeholder="" autocomplete="address2" />
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field style="width: 100%;">
                    <input matInput type="text" formControlName="city" placeholder="City" autocomplete="city" />
                    <mat-error *ngIf="billingForm.get('address').get('city').hasError('required')">City is required</mat-error>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field style="width: 100%;">
                    <input matInput type="text" formControlName="state" placeholder="State" autocomplete="state" />
                    <mat-error *ngIf="billingForm.get('address').get('state').hasError('required')">State is required</mat-error>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field style="width: 100%;">
                    <input matInput type="text" formControlName="postalCode" placeholder="ZIP/Postal Code" autocomplete="postalCode" />
                    <mat-error *ngIf="billingForm.get('address').get('postalCode').hasError('required')">Postal Code is required</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mat-divider style="margin-top: 20px; margin-bottom: 20px;"></mat-divider>

        <div style="display: flex;">
          <span style="flex: 1 1 auto;"></span>
          <button [disabled]="!billingForm.valid" mat-flat-button type="button" color="accent" (click)="goForward()">Continue...</button>
        </div>

      </mat-step> -->

      <mat-step label="Order Review">

        <store-core-checkout-review [context]="context"></store-core-checkout-review>

        <mat-divider style="margin-top: 2px; margin-bottom: 2px;"></mat-divider>

        <!-- <app-site-public-store-cart [cart]="cart" [canEdit]="false"></app-site-public-store-cart> -->

        <div style="display: flex;">
          <div style="flex: 3;">
            Comments
          </div>

          <span style="flex: 1 1 0;"></span>

          <div *ngIf="cart" style="flex: 2; margin-top: 2px; background-color: #fff; color: black; border-radius: 4px; font-size: calc(14px + (18 - 14) * ((100vh - 480px) / (1080 - 480))); line-height: calc(1em + (1 - 1) * ((100vh - 480px) / (1080 - 480))); padding: 2px;">
            <div style="display: flex;">
              <span>Subtotal</span>
              <span style="flex: 1 1 auto;"></span>
              <span>{{ cart.subTotalAmount | currency:'USD':'symbol':'1.2-2' }}</span>
            </div>
            <div style="display: flex;">
              <span>Shipping</span>
              <span style="flex: 1 1 auto;"></span>
              <span>{{ shippingAmount | currency:'USD':'symbol':'1.2-2' }}</span>
            </div>
            <div style="display: flex;">
              <span>Tax</span>
              <span style="flex: 1 1 auto;"></span>
              <span>{{ taxAmount | currency:'USD':'symbol':'1.2-2' }}</span>
            </div>
            <div style="display: flex;">
              <span>Total</span>
              <span style="flex: 1 1 auto;"></span>
              <span>{{ totalAmount | currency:'USD':'symbol':'1.2-2' }}</span>
            </div>
          </div>
        </div>

        <div *ngIf="context.logisticsForm.get('logisticPaymentMethodUid').value == 'CFDCABDE-FDBE-421D-82C5-3E2ECEAB50CF'" style="margin-top: 2px; background-color: #fff; color: black; border-radius: 4px; font-size: calc(14px + (18 - 14) * ((100vh - 480px) / (1080 - 480))); line-height: calc(1em + (1 - 1) * ((100vh - 480px) / (1080 - 480))); padding: 2px;">

          <div style="display: flex;">
            <div style="flex: 3;">
              Payment
            </div>

            <span style="flex: 1 1 0;"></span>

            <div *ngIf="cart" style="flex: 2; margin-top: 2px; ">
              <div style="display: flex;">
                <span>Credit Card</span>
                <span style="flex: 1 1 auto;"></span>
                <span>{{ getCreditCardMask() }}</span>
              </div>
            </div>

          </div>

          <div *ngIf="context.logisticsForm.get('logisticTypeUid').value == '3F46E3F1-EA40-4012-8260-CD22B5CC054C' && context.logisticsForm.get('logisticPaymentMethodUid').value == 'CFDCABDE-FDBE-421D-82C5-3E2ECEAB50CF'" style="display: flex; margin-top: 10px;">

            <div style="flex: 3; text-align: right;">
              Include a tip?
            </div>

            <span style="flex: 1 1 0;"></span>

            <div style="flex: 2;">
              <!-- <div style="display: flex;" [style.flex]="isReadOnly ? '10' : '10'">
                <button mat-flat-button style="min-width: 40px; width: 2em; font-size: 12px; font-weight: 12px; line-height: 12px; padding: 4px;" (click)="setPercentTip(5)">
                  5%
                </button>
                <button mat-flat-button style="min-width: 40px; width: 2em; font-size: 12px; font-weight: 12px; line-height: 12px; padding: 4px;" (click)="setPercentTip(10)">
                  10%
                </button>
                <button mat-flat-button style="min-width: 40px; width: 2em; font-size: 12px; font-weight: 12px; line-height: 12px; padding: 4px;" (click)="setPercentTip(15)">
                  15%
                </button>
                <button mat-flat-button style="min-width: 40px; width: 2em; font-size: 12px; font-weight: 12px; line-height: 12px; padding: 4px;" (click)="setPercentTip(20)">
                  20%
                </button>
                <span style="flex: 1 1 0;"></span>
                <div style="width: 75px;">
                  <input type="text" style="width: 100%; text-align: center; font-size: 14px; line-height: 14px;" numeric-only value="{{ tip }}" />
                </div>
              </div> -->
            </div>

          </div>
        </div>

        <mat-divider style="margin-top: 2px; margin-bottom: 2px;"></mat-divider>

        <div style="display: flex;">
          <span style="flex: 1 1 auto;"></span>
          <button mat-flat-button type="button" color="accent" (click)="completeCheckout()">Complete Checkout</button>
        </div>
      </mat-step>

      <!-- <mat-step label="Confirmation">

        CONFIRMATION TIME

      </mat-step> -->

    </mat-horizontal-stepper>

  </div>
</div>
