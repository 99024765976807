import { Component, OnInit } from '@angular/core';
import { NavigationProvider } from 'core';
import { PosRuntimeProvider } from 'pos-core';

@Component({
  selector: 'app-site-employee-dashboard',
  templateUrl: './site-employee-dashboard.component.html',
  styleUrls: ['./site-employee-dashboard.component.scss']
})
export class SiteEmployeeDashboardComponent implements OnInit {

  constructor(
    private navigationProvider: NavigationProvider,
    public runtimeProvider: PosRuntimeProvider
  ) {

  }

  ngOnInit() {
    this.navigationProvider.setWaypoint('Employee Dashboard');
  }
}


