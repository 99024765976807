export class AuthWellKnownEndpoints {

    issuer = '';
    jwks_uri = '';
    authorization_endpoint = '';
    token_endpoint = '';
    userinfo_endpoint = '';
    end_session_endpoint = '';
    check_session_iframe = '';
    revocation_endpoint = '';
    introspection_endpoint = '';
}
