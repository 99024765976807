import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CheckListModalData } from "./check-list-modal.provider";

@Component({
  selector: 'app-check-list-modal',
  templateUrl: './check-list-modal.component.html',
  styleUrls: ['./check-list-modal.component.scss']
})
export class CheckListModalComponent<T> {

  constructor(
    private dialogRef: MatDialogRef<CheckListModalComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: CheckListModalData<T>
  ) {
  }

  toggleOption(option: { value: T, checked: boolean, disabled: boolean }) {

    option.checked = !option.checked;
  }

  ok() {
    this.dialogRef.close(true);
  }
}
