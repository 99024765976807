import { Terminal } from "./models/terminal";
import { ModelFactory } from "core";

export class TerminalModelFactory {

  public static assignTerminal(input: Terminal): Terminal {

    if (input) {
      return ModelFactory.assign(input, Terminal);
    }

    return null;
  }

  public static mergeTerminal(source: Terminal, target: Terminal): Terminal {

    target.name = source.name === undefined ? target.name : source.name;
    target.description = source.description === undefined ? target.description : source.description;
    target.type = source.type === undefined ? target.type : source.type;
    target.deviceId = source.deviceId === undefined ? target.deviceId : source.deviceId;
    target.defaultDrawerSeedAmount = source.defaultDrawerSeedAmount === undefined ? target.defaultDrawerSeedAmount : source.defaultDrawerSeedAmount;
    target.createDateTimeUtc = source.createDateTimeUtc === undefined ? target.createDateTimeUtc : source.createDateTimeUtc;
    target.status = source.status === undefined ? target.status : source.status;

    return target;
  }
}
