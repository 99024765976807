
import { Injectable } from '@angular/core';
import { AuthIdentityProvider, EntityEventProvider, isCaseInsensitiveEqual, isEqualUUID } from 'core';
import { Member } from '../models/member';
import { MemberService } from '../services/member.service';
import { BehaviorSubject, concatMap, filter, Observable, Subject, takeUntil } from 'rxjs';
import { PosModelFactory } from '../pos-model-factory';

@Injectable()
export class AuthMemberProvider {

  public authMember$: Observable<Member>;

  private authMemberSubject = new BehaviorSubject<Member>(null);
  private destroyed$ = new Subject();

  constructor(
    protected authIdentityProvider: AuthIdentityProvider,
    protected entityEventProvider: EntityEventProvider,
    protected memberService: MemberService
  ) {
    this.authMember$ = this.authMemberSubject.asObservable();

    entityEventProvider.event$.pipe(
      filter(event => isCaseInsensitiveEqual(event.type, 'MemberContext')),
      filter(event => isEqualUUID(event.uid, this.authMemberSubject.value?.uid)),
      concatMap(event => memberService.getByUid(event.uid)),
      takeUntil(this.destroyed$)
    ).subscribe(member => {
      PosModelFactory.mergeMember(member, this.authMemberSubject.value);
    });

    authIdentityProvider.userIdentity$.subscribe(authIdentity => {
      if (authIdentity) {
        if (authIdentity.roles.some(x => x == 'uptownbar.member')) {
          memberService.getByIdentityId(authIdentity.id).subscribe(member => {
            this.authMemberSubject.next(member);
          });
        }
      } else {
        this.authMemberSubject.next(null);
      }
    });
  }

  ngOnDestroy() {

    this.destroyed$.next(null);
  }
}
