import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";
import { TransactionNotesModalData } from "./transaction-notes-modal.provider";

@Component({
  selector: 'app-transaction-notes-modal',
  templateUrl: './transaction-notes-modal.component.html',
  styleUrls: ['./transaction-notes-modal.component.scss']
})
export class TransactionNotesModalComponent {

  value: string;
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TransactionNotesModalData,
    private dialogRef: MatDialogRef<TransactionNotesModalComponent>
  ) {
    this.form = new UntypedFormGroup({
      value: new UntypedFormControl(data.initialValue, [Validators.required])
    });
  }

  confirm() {

    this.dialogRef.close(this.form.get('value').value);
  }
}
