<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef color="accent">Name</th>
    <td mat-cell *matCellDef="let position">{{ position.name }}</td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef color="accent">Description</th>
    <td mat-cell *matCellDef="let position">{{ position.description }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let position">{{ position.positionStatusUid | positionStatus }}</td>
  </ng-container>

  <!-- <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let position">
      <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="position.canEdit" (click)="edit(position)">Edit</button>
      <button mat-stroked-button color="warn" style="margin-right: 5px;" click-stop-propagation *ngIf="position.canDelete" (click)="delete(position)">
        <mat-icon>delete_outline</mat-icon>
      </button>
    </td>
  </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>

</table>

<!-- <table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="dataSource.selection.isMultipleSelection()" (change)="$event ? dataSource.masterToggle() : null" [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row) : null" [checked]="dataSource.selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef color="accent">Name</th>
    <td mat-cell *matCellDef="let productDepartment">{{ productDepartment.name }}</td>
  </ng-container>

  <ng-container matColumnDef="accountSettingUid">
    <th mat-header-cell *matHeaderCellDef color="accent">Account Setting</th>
    <td mat-cell *matCellDef="let productDepartment">{{ productDepartment.accountSettingUid }}</td>
  </ng-container>

  <ng-container matColumnDef="isSalesTaxIncluded">
    <th mat-header-cell *matHeaderCellDef>Sales Tax Included</th>
    <td mat-cell *matCellDef="let productDepartment">{{ productDepartment.isSalesTaxIncluded }}</td>
  </ng-container>

  <ng-container matColumnDef="taxAccountSettingUid">
    <th mat-header-cell *matHeaderCellDef>taxAccountSettingUid</th>
    <td mat-cell *matCellDef="let productDepartment">{{ productDepartment.taxAccountSettingUid }}</td>
  </ng-container>

  <ng-container matColumnDef="productDepartmentStatusUid">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let productDepartment">{{ productDepartment.productDepartmentStatusUid | productDepartmentStatus }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>
</table> -->
