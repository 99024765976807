import { Component, Input, Output, EventEmitter, Inject } from "@angular/core";
import { map } from 'rxjs/operators';
import { ItemConfiguratorContext } from "../../item-configurator-context";
import { SettingProvider, TenantProvider, slideInAnimation, slideOutAnimation } from "core";
import { cloneTransactionItemConfiguration, cloneTransactionItemConfigurationPortion } from "../../../../functions/transaction-item-configuration/clone";
import { MenuPlacement } from "../../../../keys";
import { EditableTransactionItemConfigurationAddOn, EditableTransactionItemConfiguration } from "../../../../models/editable-transaction/editable-transaction";
import { type ItemConfiguratorModalData } from "../../../../modals/item-configurator-modal/item-configurator-modal.provider";
import { IItemConfiguratorModalProvider, ITEM_CONFIGURATOR_MODAL_PROVIDER } from '../../../../modals/item-configurator-modal/iitem-configurator-modal.provider';
import { Product, flattenProduct } from "downtown-product";
import { TransactionSettings } from "../../../../models";
import { Observable } from "rxjs";

@Component({
  selector: 'transaction-item-configurator-detail-add-on',
  templateUrl: './item-configurator-detail-add-on.component.html',
  styleUrls: ['./item-configurator-detail-add-on.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfiguratorDetailAddOnComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public itemAddOn: EditableTransactionItemConfigurationAddOn;
  @Output() public configurationChanged = new EventEmitter();

  public transactionSettings$: Observable<TransactionSettings>;
  public addOnProduct: Product;
  public handleConfigurationChanged: null;

  constructor(
    @Inject(ITEM_CONFIGURATOR_MODAL_PROVIDER) private productConfiguratorModalProvider: IItemConfiguratorModalProvider,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider,
  ) {
    this.transactionSettings$ = this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid);
  }

  ngOnInit() {

    this.context.productProvider.getOneCached$(this.itemAddOn.item.productUid.value, this.itemAddOn.item.productVersion.value).subscribe(product => {
      this.addOnProduct = flattenProduct(product);
    });
  }

  public get configuration(): EditableTransactionItemConfiguration {

    return this.context.itemConfiguration;
  }

  getAddOnDisplay(addOn: EditableTransactionItemConfigurationAddOn) {

    return this.context.productProvider.getOneCached$(addOn.item.productUid.value, addOn.item.productVersion.value).pipe(
      map(product => `${product.configuration.getPortion(addOn.item.configuration.value.portion.portionUid).name} - ${product.name}`)
    );
  }

  public edit() {

    this.context.productProvider.getOneCached$(this.itemAddOn.item.productUid.value, this.itemAddOn.item.productVersion.value).subscribe(product => {

      if (product.isConfigurable) {
        this.context.select(null);

        this.productConfiguratorModalProvider.open(<ItemConfiguratorModalData>{
          ownerUid: this.context.ownerUid,
          productUid: this.itemAddOn.item.productUid.value,
          productVersion: this.itemAddOn.item.productVersion.value,
          itemConfiguration: this.itemAddOn.item.configuration.value,
          menuPlacementUid: MenuPlacement.Menu,
          guests: null,
          lastGuestUid: null,
          isNew: false,
          isAddOn: true
        }).afterClosed().subscribe(result => {
          if (result) {
            const existingConfiguration = this.itemAddOn.item.configuration.value;
            existingConfiguration.portion = cloneTransactionItemConfigurationPortion(result.configuration.portion);
            
            this.itemAddOn.item.eachAmountText.next(result.eachTotal.toFixed(2));

            this.configurationChanged.emit();
          }
        });
      }
    });
  }

  public remove() {

    const configuringAddOns = this.configuration.portion.addOns;
    const configuringAddOn = configuringAddOns.find(x => x.addOnUid.toUpperCase() == this.itemAddOn.addOnUid.toUpperCase());

    configuringAddOns.splice(configuringAddOns.indexOf(configuringAddOn), 1);

    this.configurationChanged.emit();
  }
}
