<div *ngIf="form" [formGroup]="form">

  <h2>POS Settings</h2>

  <div style="min-width: 500px; max-width: 50vw;">

    <div [formGroup]="form.controls.systemUser">

      <div style="display: flex;">
        <h3 style="flex: 2; text-align: right; padding-right: 50px;">System User</h3>
        <div style="flex: 2;"></div>
      </div>

      <div style="display: flex;">
        <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
          <label>UID</label>
        </div>
        <mat-form-field style="flex: 2;">
          <input matInput type="text" formControlName="uid" />
        </mat-form-field>
      </div>

      <div style="display: flex;">
        <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
          <label>Identity Email</label>
        </div>
        <mat-form-field style="flex: 2;">
          <input matInput type="text" formControlName="identityEmail" />
        </mat-form-field>
      </div>

      <div style="display: flex;">
        <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
          <label>Identity Password</label>
        </div>
        <mat-form-field style="flex: 2;">
          <input matInput type="password" formControlName="identityPassword" />
        </mat-form-field>
      </div>

    </div>

    <hr style="width: 100%;" />

    <div [formGroup]="form.controls.adminUser">

      <div style="display: flex;">
        <h3 style="flex: 2; text-align: right; padding-right: 50px;">Admin User</h3>
        <div style="flex: 2;"></div>
      </div>

      <div style="display: flex;">
        <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
          <label>UID</label>
        </div>
        <mat-form-field style="flex: 2;">
          <input matInput type="text" formControlName="uid" />
        </mat-form-field>
      </div>

      <div style="display: flex;">
        <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
          <label>Identity Email</label>
        </div>
        <mat-form-field style="flex: 2;">
          <input matInput type="text" formControlName="identityEmail" />
        </mat-form-field>
      </div>

      <div style="display: flex;">
        <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
          <label>Identity Password</label>
        </div>
        <mat-form-field style="flex: 2;">
          <input matInput type="password" formControlName="identityPassword" />
        </mat-form-field>
      </div>

    </div>

    <hr style="width: 100%;" />

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <span>Enable Development Features</span>
      </div>
      <div style="flex: 2;">
        <mat-checkbox formControlName="enableDevelopmentFeatures"></mat-checkbox>
      </div>
    </div>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <span>Auto Print Credit Card Receipt</span>
      </div>
      <div style="flex: 2;">
        <mat-checkbox formControlName="autoPrintCreditCardReceipt"></mat-checkbox>
      </div>
    </div>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <label>Pay Period Duration (days)</label>
      </div>
      <mat-form-field style="flex: 2;">
        <input matInput type="number" formControlName="payPeriodDuration_Days" />
      </mat-form-field>
    </div>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <label>Default Time Zone</label>
      </div>
      <mat-form-field style="flex:2;">
        <input matInput type="text" formControlName="defaultTimezone" />
      </mat-form-field>
    </div>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <label>Pre-authorize With Additional Percent</label>
      </div>
      <mat-form-field style="flex: 2;">
        <input matInput type="number" formControlName="preAuthorizeWithAdditionalPercent" />
      </mat-form-field>
    </div>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <label>Financial Day Start (HH:MM)</label>
      </div>
      <mat-form-field style="flex: 2;">
        <input matInput type="text" formControlName="financialDayStartHourMinute" />
      </mat-form-field>
    </div>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <span>Enable Credit Card Processor</span>
      </div>
      <div style="flex: 2;">
        <mat-checkbox formControlName="enableCreditCardProcessor"></mat-checkbox>
      </div>
    </div>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <span>Pre-authorize Credit Cards</span>
      </div>
      <div style="flex: 2;">
        <mat-checkbox formControlName="preAuthorizeCreditCards"></mat-checkbox>
      </div>
    </div>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <span>Open Cash Drawer On Card Payment</span>
      </div>
      <div style="flex: 2;">
        <mat-checkbox formControlName="openCashDrawerOnCardPayment"></mat-checkbox>
      </div>
    </div>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <label>Dialog Auto Close Duration (seconds)</label>
      </div>
      <mat-form-field style="flex: 2;">
        <input matInput type="number" formControlName="dialogAutocloseDuration" />
      </mat-form-field>
    </div>

  </div>

</div>