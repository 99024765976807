export class CardTransaction {

  uid: string;
  saleUid: string;
  paymentUid: string;
  amount: number;
  tip: number;
  firstName: string;
  middleInitial: string;
  surname: string;
  lastFour: string;
  token: string;
  encryption: string;
  processorReferenceId: string;
  authorizedAmount: number;
  cardTransactionStatusUid: string;
  modifiedDateTimeUtc: Date;
}
