import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NavigationProvider } from 'core';
import { ProductAndPortionModalProvider } from '../../../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { ConfigurationComponentBase } from '../../configuration-component-base';
import { buildProductPortionForm } from '../../../../functions/configuration-form-functions';
import { ProductContext } from '../../product-context';
import { ProductStatusKeys } from '../../../../keys';
import { TenantProvider } from 'core';
import { ProductProvider } from '../../../../providers';

@Component({
  selector: 'app-back-office-product-configuration-portions',
  templateUrl: './back-office-product-configuration-portions.component.html',
  styleUrls: ['./back-office-product-configuration-portions.component.scss']
})
export class BackOfficeProductConfigurationPortionsComponent extends ConfigurationComponentBase {

  public form: UntypedFormGroup;
  public ProductStatusKeys = ProductStatusKeys;

  private _context: ProductContext;
  private destroyed$ = new Subject();

  constructor(
    private navigationProvider: NavigationProvider,
    activatedRoute: ActivatedRoute,
    tenantProvider: TenantProvider,
    productProvider: ProductProvider,
    productAndPortionModalProvider: ProductAndPortionModalProvider,
  ) {
    super(activatedRoute, tenantProvider, productProvider, productAndPortionModalProvider);
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  onActivate(event: ConfigurationComponentBase) {

    event.context = this.context;
  }

  @Input()
  public set context(context: ProductContext) {

    this._context = context;
    this.form = <UntypedFormGroup>this._context.productForm.controls['configuration'];
  }

  public get context(): ProductContext {

    return this._context;
  }

  public get portionsFormGroupArray(): UntypedFormGroup[] {

    return (this.form.get('portions') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  public newPortion() {

    var portionsFormArray = <UntypedFormArray>this._context.productForm.controls['configuration'].get('portions');

    const formGroup = buildProductPortionForm(null);
    portionsFormArray.push(formGroup);
    portionsFormArray.markAsDirty();

    formGroup.get('name').setValue('New Portion');

    this.editPortion(formGroup);
  }

  public editPortion(formGroup: UntypedFormGroup) {

    this.navigationProvider.navigate(this.getBaseProductNavigationSegments().concat(['portion', formGroup.get('uid').value]));
  }

  public dropPortion(event: CdkDragDrop<string[]>) {

    if (event.previousIndex != event.currentIndex) {
      var portions = <UntypedFormArray>this.form.get('portions');
      var item = portions.controls[event.previousIndex];
      portions.removeAt(event.previousIndex);

      portions.insert(event.currentIndex, item);
    }
  }

  public deletePortion(form: UntypedFormGroup) {

    var portions = <UntypedFormArray>this.form.get('portions');
    portions.removeAt(portions.controls.indexOf(form));
  }
}
