import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) {
  }

  transform(date: string | Date): string {

    let value: Date;
    if (typeof (value) === 'string') {
      value = new Date(date);
    } else {
      value = <Date>date;
    }

    let hours = value.getHours();
    if (hours == 0) {
      return formatDate(date, 'M-dd-yyyy', this.locale);
    } else {
      return formatDate(date, 'M-dd-yyyy h:mm a', this.locale);
    }
  }
}
