import { Pipe, PipeTransform } from '@angular/core';
import { CategoryStatusKeys } from '../keys';
import { Category } from '../models/category';

@Pipe({
  name: 'categoryStatus'
})
export class CategoryStatusPipe implements PipeTransform {

  private static map = new Map<string, string>([
    [CategoryStatusKeys.Active.toUpperCase(), 'Active'],
    [CategoryStatusKeys.Inactive.toUpperCase(), 'Inactive'],
    [CategoryStatusKeys.Deleted.toUpperCase(), 'Deleted'],
  ]);

  constructor(
  ) {
  }

  transform(value: Category | string): string {

    if (typeof(value) === 'string'){
      return value ? CategoryStatusPipe.map.get(value.toUpperCase()) : null;
    } else {
      return value ? CategoryStatusPipe.map.get(value.categoryStatusUid.toUpperCase()) : null;
    }
  }
}
