import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, Subject } from 'rxjs';
import { UidFilterModel } from "core";
import { TenantProvider } from 'core';
import { Category, DepartmentProvider, MenuPlacement, Product, ProductProvider, ProductStatusKeys } from "downtown-product";
import { GuestServiceModalData, GuestServiceModalResult } from "./guest-service-modal.provider";
import { ItemConfiguratorContext } from "../../components";

@Component({
  selector: 'app-guest-service-modal',
  templateUrl: './guest-service-modal.component.html',
  styleUrls: ['./guest-service-modal.component.scss']
})
export class GuestServiceModalComponent {

  public context: ItemConfiguratorContext;

  public title: string;
  public categories: Category[];
  public displayableProducts: DisplayableProduct[];
  public productFilter = new BehaviorSubject<UidFilterModel>(null);
  public productFilters: UidFilterModel[];

  private allProducts: Product[];
  private explodedProducts: DisplayableProduct[];
  private destroyed$ = new Subject();

  public headingCategories: { category: Category, displayableProducts: DisplayableProduct[] }[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: GuestServiceModalData,
    private dialogRef: MatDialogRef<GuestServiceModalComponent, GuestServiceModalResult>,
    private departmentProvider: DepartmentProvider,
    private productProvider: ProductProvider,
    private tenantProvider: TenantProvider
  ) {
    this.title = this.data.title.toUpperCase();
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public newGuest() {

  }

  public updateDisplay() {

  }

  public selectDisplayableProduct(product: DisplayableProduct) {

    this.dialogRef.close(<GuestServiceModalResult>{
      productUid: product.product.uid,
      productVersion: product.product.version,
      portionUid: product.portionUid,
      eachAmount: product.price
    });
  }

  public showAll() {

  }

  public cancel() {

    this.dialogRef.close();
  }
}

interface DisplayableProduct {

  product: Product;
  categoryUid: string;
  title: string;
  subtitle: string;
  price: number;
  portionUid: string;
  menuPlacementUid: string;
  selectable: boolean;
}
