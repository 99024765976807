export class ActiveStatusKeys {

  public static readonly Active = '6d63dfa6-2619-4ec3-ba8f-56b6371d03cc';
  public static readonly Inactive = '9d8ec8bc-01ee-4626-ad98-2d0fd0951892';
  public static readonly Deleted = 'a0b4d29e-4908-46f8-8047-c46a0564a4a4';

  public static readonly Map = [
    { uid: ActiveStatusKeys.Active, name: 'Active' },
    { uid: ActiveStatusKeys.Inactive, name: 'Inactive' },
    { uid: ActiveStatusKeys.Deleted, name: 'Deleted' },
  ];
}

export class DrawerStatusKeys {

  public static readonly Started = '266D61F5-FA71-4696-BCE8-73300F247231';
  public static readonly Ended = 'F5F00362-70A1-424E-89ED-5857A63BEE23';
  public static readonly Deleted = '9812E5B3-86CC-4C65-962B-AA1D3FF2E20A';
  public static readonly Committed = 'D4658DE1-546F-4EFF-87CB-E4BF90C2DA1B';
}

export class EmployeeStatusKeys {

  public static readonly Active = '27764DFA-A02C-4754-BA84-1A253891CC00';
  public static readonly Inactive = '75FDBC38-3E23-45DC-907C-2023951F3BB7';
  public static readonly Deleted = '77FD5E95-82D6-42D8-B109-BBA556084EA0';
}

export class EmployeePayStatusKeys {

  public static readonly Scheduled = 'CD526DE2-DFEE-4A44-A2EE-83EEB15D9F83';
  public static readonly Delivered = '25BE891E-757D-4A25-ACCA-F8BDBBA77191';
  public static readonly Cancelled = '5D0C25E6-9915-45EE-870B-864E1C4A1708';
}

export class EmployeeShiftStatusKeys {

  public static readonly ClockedIn = 'E158812C-F077-4B18-8665-5553F081532D';
  public static readonly ClockedOut = 'D542D0C0-EE86-4A74-9857-7E7873F13898';
  public static readonly PaymentMade = '3C201E94-8075-4FA0-B31E-06028263B747';
  public static readonly Deleted = '839FCF47-2CCB-471D-9FF4-DCF7278F746E';
  public static readonly PaymentScheduled = 'EE1A9ADC-A1E6-4CB8-9D5A-B2E4BE4DE54F';
  public static readonly PaymentCancelled = 'A84D1152-0A12-4F54-AB50-183CEACFFDCD';
  public static readonly Submitted = '1A3E393D-4F59-4D6F-A8F3-4EBBA1ED087C';
  public static readonly Rejected = 'BBBABA53-7424-46D0-BB91-991A96AA11A7';
  public static readonly Accepted = 'FC55A935-DE95-4B7A-96B2-2D681606604E';
}

export class MemberStatusKeys {

  public static readonly Active = 'A6AF4206-D55E-468B-AAA5-62DFBAEC2AFA';
  public static readonly Inactive = '0323D0E4-81B0-4E5E-8EFF-D8C6B17A6CE1';
  public static readonly Deleted = '4EABCD50-F5E6-4BE1-A063-FB132887E69B';
}

export class PermissionKeys {

  public static readonly CanUseSelfServiceHR = 'B242DCC2-F78A-4F41-8E48-55117DD2D114';
  public static readonly CanOpenCloseAccounts = '8B4B186D-DC09-48BB-B587-1BE5642093BE';
  public static readonly CanStartTerminalDrawer = '3BE238D7-B386-4E86-AB0D-AA432BF1FEA9';
  public static readonly CanEndTerminalDrawer = '8FF4C010-E5D9-4D0E-BF0A-7EC3457D239C';
  public static readonly CanViewSalesHistory = '929C487D-90F4-4EA6-92C8-4DB615AE8366';
  public static readonly CanExitApplication = '7CE6FD86-37C8-452A-B48F-CE50955CC96A';
  public static readonly CanChangeTerminalGeneralSettings = '71D5F730-CA42-4025-88B9-094D2B9D8F04';
  public static readonly CanChangeTerminalSetup = 'F352CDBB-5562-4AF2-8CE1-5DBCEA7F1AF5';
  public static readonly CanChangeDeviceSettings = '092B5C77-51C6-46EE-AAF9-FEF299C95075';
  public static readonly CanReadTerminalMessages = '250897F8-3DA0-4735-9A39-0AD07CF4A577';
  public static readonly CanDeleteTerminalMessages = '02B6F7BE-DDD2-4AEE-8960-E1B1563670D9';
  public static readonly CanSeeLoggedInEmployees = '3ADB50FA-6557-4D44-BE1F-915F71B37702';
  public static readonly CanUseCashRegister = '8629CE43-6458-4CB8-9671-C9F99D31C7B4';
  public static readonly CanAuthorizeCardTransactions = '2517D05F-0B11-48AE-AC2D-2E43AB33570C';
  public static readonly CanCaptureCardTransactions = 'E1894011-149C-4F64-8FFF-390F14EF60A8';
  public static readonly CanVoidCardTransactions = '70FAC6D6-3B15-47DB-B71B-7A01F205549D';
  public static readonly CanRefundCardTransactions = '2BF9737C-0C0D-406E-BAFA-079D9A5D0EB6';
  public static readonly CanManageEmployees = 'EFED65A8-369C-4C21-B858-24E34BF2C268';
  public static readonly SystemAdministrator = 'AE61D8C2-1543-4590-9CD6-F73A2813985C';
}

export class PayPeriodStatusKeys {

  public static readonly Open = '39EDA133-D49D-4952-9866-612274C36746';
  public static readonly InReview = 'ED1DF301-360C-4DB2-ABDD-B2CA7FD39F84';
  public static readonly Committed = '53607185-2D06-45DE-8379-91082D8AD658';
  public static readonly Deleted = '236BFB28-7280-42D4-88DF-51603E17E75E';
}

export class PositionStatusKeys {

  public static readonly Active = 'FBAE4412-F636-42CB-85B8-D7AE93CF21E7';
  public static readonly Inactive = '0865C917-7D39-407B-9EE6-949904826F3D';
  public static readonly Deleted = '689C41D9-4493-41BE-B997-62EC44275E14';
}

export class PriceAdjustmentStatusKeys {

  public static readonly Active = 'E2D407A3-A264-4C1F-97EF-801F460F5AF7';
  public static readonly Inactive = '330665A2-1A15-4FA0-A224-AF0902B54401';
  public static readonly Deleted = 'DB5274B3-8D65-49A8-A627-F71B2E824074';
}

export class ScheduleStatusKeys {

  public static readonly Active = '764EAA8A-842D-46F7-971B-0448DD79D7D3';
  public static readonly Inactive = '8A8829D2-01DB-4045-AE44-36D4B12BB768';
  public static readonly Deleted = '354C9B13-155D-4890-AD97-6788FB18B2C3';
}

export class SystemMessageLifespanUids {

  public static readonly ImmediateOnly = '6E2704DF-1FC6-4A1D-863D-3276ABF8125D';
  public static readonly Limited = 'DFF07B32-CD9C-421F-B3DF-736C7F7B86CA';
  public static readonly UntilAcknowledged = 'DFF07B32-CD9C-421F-B3DF-736C7F7B86CA';
}

export class SystemMessageStatusUids {

  public static readonly Published = 'F572C2C3-50FB-406B-BA70-83ADB9002F02';
  public static readonly Cleared = '0A58406D-0824-40B0-BEDD-72F74C2943BB';
  public static readonly Deleted = '0CB900E7-360D-4AA2-BDB1-91F4CA445947';
}

export class SystemMessageTypeUids {

  public static readonly OrderSubmitted = 'E371D135-CC02-4E35-A8BF-E7E33646AD2D';
  public static readonly KickCashDrawer = 'A835AAA6-7FA5-45E8-BCF0-99A3122E2EC9';
}

export class WorkUnitKeys {

  public static readonly Hourly = 'B5603BA3-FAE1-496C-AA3D-FE8764922691';
  public static readonly PerOccurence = 'AB679DE4-E499-4B0A-A954-26AF4002C396';
  public static readonly PerDay = '7D10BC55-6FFD-42F5-95D1-FB49D059AA93';
  public static readonly PerWeek = '91A09FDE-BD6D-4A6D-A2D2-9848201CDBEB';
  public static readonly PerPayPeriod = '0053979D-6825-434A-B7D9-04AEE03E751C';
  public static readonly PerMonth = '99087CEC-27C7-4561-B89C-B5F53B39E38B';
  public static readonly PerYear = '34EEAE91-BC48-4C89-8EE5-F30654D8A0BC';
}
