export class Address {

  uid: string;
  name: string;
  country: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  latitude: string;
  longitude: string;
}
