<div class="editor">
  <ng-container *ngIf="order; else noOrder;">
    <ng-container *ngTemplateOutlet="hasOrder;"></ng-container>
  </ng-container>

</div>

<ng-template #hasOrder>

  <div style="height: 100%; display: flex; flex-direction: column;">

    <!-- <div *ngIf="order.orderStatusUid.value?.toUpperCase() != 'B4445AF7-0D14-4D92-80AF-32F197A4589A'" style="display: flex; margin-bottom: 6px;">
      <div style="height: 6vh; padding: 2px;">
        <button *ngIf="order.canEditNotes" mat-flat-button color="primary" style="height: 100%; min-width: 8.3333vw;" (click)="updateNotes.next()">{{ (order.notes | async) == null ? 'Enter Name...' : (order.notes | async) }}</button>
      </div>
      <div style="flex: 1 1 auto;"></div>
      <div style="height: 6vh; padding: 2px;">
        <button *ngIf="order.canEditLocation" mat-flat-button color="primary" style="height: 100%; min-width: 8.3333vw;" (click)="updateLocation.next()">{{ order.logisticTypeUid | logisticType }}</button>
      </div>
    </div> -->


    <div style="display: flex; border-bottom: 1px solid #000; margin-bottom: 10px;">
      <span style="width: 60%;">ITEM</span>
      <span style="width: 10%; text-align: right;">ORDERED</span>
      <span style="width: 10%; text-align: right;">FULFILLED</span>
      <span style="width: 10%; text-align: right;">REMAIN</span>
    </div>

    <div style="flex: 1; overflow-y: auto;">

      <!-- Order Items -->
      <ng-container *ngFor="let orderItem of order.items">
        <div style="border-radius: 4px;" [style.background-color]="(order.selection | async) == orderItem ? 'gray' : 'transparent'" (click)="select.emit(orderItem)" click-stop-propagation>
          <ng-container *ngIf="orderItem.configuration.value; else item">
            <ng-container *ngTemplateOutlet="configuredItem"></ng-container>
          </ng-container>
        </div>

        <!-- Order Item Context Menu -->
        <!-- <div *ngIf="(order.selectedLineItem | async) == transactionLineItem && (transactionLineItem.canVoid || transactionLineItem.canComp || transactionLineItem.canCancel)" [@slideIn] [@slideOut] style="display: flex; overflow: hidden;">
          <div style="width: 10%; background-color: gray;">
            <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
          </div>
          <div style="background-color: gray; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 4px;">
            <button *ngIf="transactionLineItem.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemEdit.emit(transactionLineItem)">Edit</button>
            <button *ngIf="transactionLineItem.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemVoid.emit(transactionLineItem)">Void</button>
            <button *ngIf="transactionLineItem.canComp" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemComp.emit(transactionLineItem)">Comp</button>
            <button *ngIf="transactionLineItem.canCancel" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemCancel.emit(transactionLineItem)">Cancel</button>
          </div>
          <div style="flex: 1 1 auto; background-color: gray;">
            <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
          </div>
        </div> -->

        <ng-template #configuredItem>
          <div style="display: flex;">
            <div style="width: 60%;">{{ orderItem.productUid.value | product | async }}</div>
            <div style="width: 10%; text-align: right;">{{ orderItem.orderQuantity | async | number:'1.0-2' }}</div>
            <div style="width: 10%; text-align: right;">{{ orderItem.fulfilledQuantity | async | number:'1.0-2' }}</div>
            <div style="width: 10%; text-align: right;">{{ orderItem.remainingQuantity | async | number:'1.0-2' }}</div>
            <div style="width: 10%; text-align: right;">
              <button *ngIf="!isReadOnly && orderItem.orderQuantity.value > orderItem.fulfilledQuantity.value" mat-flat-button style="font-size: 10px; line-height: 14px; padding: 4px;" (click)="this.fulfillOrderItem.emit(orderItem)">Update</button>
            </div>
          </div>

          <div *ngIf="orderItem.configuration" style="margin-left: 10%;">

            <!-- Show detail -->
            <!-- <div *ngIf="(order.selectedLineItem | async) == transactionLineItem" [@slideIn] [@slideOut]>
              <app-product-configuration-detail [transactionItem]="transactionLineItem" [readOnly]="true"></app-product-configuration-detail>
            </div> -->

            <!-- Show summary -->
            <!-- <div *ngIf="(order.selectedLineItem | async) != transactionLineItem" [@slideIn] [@slideOut]>
              <app-product-configuration-summary [transactionItem]="transactionLineItem"></app-product-configuration-summary>
            </div> -->

          </div>

        </ng-template>

        <ng-template #item>

          <div style="display: flex;">
            <div style="width: 60%;">{{ orderItem.productUid.value | product | async }}</div>
            <div style="width: 10%; text-align: right;">{{ orderItem.orderQuantity | async | number:'1.0-2' }}</div>
            <div style="width: 10%; text-align: right;">{{ orderItem.fulfilledQuantity | async| number:'1.0-2' }}</div>
            <div style="width: 10%; text-align: right;">{{ orderItem.remainingQuantity | async | number:'1.0-2' }}</div>
            <div style="width: 10%; text-align: right;"><button mat-flat-button style="font-size: 10px; line-height: 14px; padding: 4px;" (click)="select.next(orderItem)">Select</button></div>
          </div>

        </ng-template>

      </ng-container>

    </div>

    <div style="display: flex; margin-top: 2px;">
      <span style="flex: 1 1 auto;"></span>
      <button *ngIf="!isReadOnly && order.orderQuantity > order.fulfilledQuantity" mat-flat-button style="font-size: 10px; line-height: 14px; padding: 4px;" (click)="this.fulfillOrder.emit()">Fulfill All</button>
    </div>

    <div style="display: flex; border-top: 1px solid #000;">

      <!-- Order Totals -->
      <div style="width: 53%; text-align: right;">TOTALS</div>
      <div style="width: 10%; text-align: right;">{{ order.orderQuantity | number:'1.0-2' }}</div>
      <div style="width: 2%; text-align: center;"></div>
      <!-- <div style="width: 15%; text-align: right;">{{ order.fulfilledQuantity | number:'1.0-2' }}</div> -->
      <div style="width: 20%; text-align: right;">{{ order.totalRemainingQuantity | number:'1.0-2' }}</div>

    </div>
  </div>

</ng-template>

<ng-template #noOrder>
  <div style="height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <span>No Order</span>
  </div>
</ng-template>
