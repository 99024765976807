import { Pipe, PipeTransform } from '@angular/core';
import { EditableTransactionItem } from '../models/editable-transaction/editable-transaction';
import { ProductProvider } from 'downtown-product';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'transactionLineItem'
})
export class TransactionLineItemPipe implements PipeTransform {

  constructor(
    private productProvider: ProductProvider
  ) {
  }

  transform(transactionItem: EditableTransactionItem): Observable<string> {

    if (transactionItem) {
      return this.productProvider.getOneCached$(transactionItem.productUid.value, transactionItem.productVersion.value).pipe(
        map(saleableProduct => {
          var display = saleableProduct ? saleableProduct.name : null;

          const configuration = transactionItem.configuration.value;
          if (configuration && configuration.portion && configuration.portion.portionUid) {
            const productPortion = saleableProduct.configuration.portions.find(x => x.uid.toUpperCase() == configuration.portion.portionUid.toUpperCase());
            display = `${display}`;
          }

          return display;
        })
      );
    }

    return null;
  }
}
