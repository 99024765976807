import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { slideInAnimation, slideOutAnimation } from 'core';
import { CacheService } from 'core';
import { EditableOrder, EditableOrderItem, EditableOrderItemAdjustment, EditableOrderPayment } from '../../models/editable-order';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-editor',
  templateUrl: './order-editor.component.html',
  styleUrls: ['./order-editor.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class OrderEditorComponent implements OnInit {

  @Input() order: EditableOrder;
  @Input() canEdit: boolean;
  @Output() updateNotes = new EventEmitter();
  @Output() updateLocation = new EventEmitter();
  @Output() selectItem = new EventEmitter<EditableOrderItem | EditableOrderItemAdjustment | EditableOrderPayment>();
  @Output() updateTip = new EventEmitter<EditableOrderPayment>();
  @Output() capturePayment = new EventEmitter<EditableOrderPayment>();
  @Output() refundPayment = new EventEmitter<EditableOrderPayment>();
  @Output() voidPayment = new EventEmitter<EditableOrderPayment>();
  // @Output() lineItemEdit = new EventEmitter<EditableOrderItem>();
  // @Output() lineItemVoid = new EventEmitter<EditableOrderItem | EditableOrderItemAdjustment | EditableOrderPayment>();
  // @Output() lineItemComp = new EventEmitter<EditableOrderItem>();
  // @Output() lineItemCancel = new EventEmitter<EditableOrderItem>();

  constructor(
    private cacheService: CacheService,
    private orderService: OrderService
  ) {
    this.cacheService = new CacheService();
    this.cacheService.enabled = true;
  }

  ngOnInit() {
  }

  public ensureActiveLineViewable() {

  }

  public onSelectItem(item: EditableOrderItem | EditableOrderItemAdjustment | EditableOrderPayment) {

    this.selectItem.next(item);
  }

  // public adjust(transactionLine: TransactionLine) {

  //   transactionLine.adjust();
  // }

  // public cancel(transactionLine: TransactionLine) {

  //   transactionLine.cancel();
  // }

  // public showMessage(message: string) {

  //   this.dialog.open(MessageModalComponent, {
  //     width: '50vw',
  //     disableClose: true,
  //     closeOnNavigation: true,
  //     data: {
  //       message: message
  //     }
  //   });
  // }
}
