export class Schedule {

  uid: string;
  name: string;
  heading: string;
  description: string;
  scheduleStatusUid: string;

  includeTimeBlocks: ScheduleTimeBlock[];
}

export class ScheduleTimeBlock {

  uid: string;
  name: string;
  startDate: Date;
  endDate: Date;
  days: number[];
  startHourMinute: string;
  endHourMinute: string;
  frequency: string;
}
