<mat-grid-list cols="7" rowHeight="fit" style="height: 100%;">
  <mat-grid-tile><button mat-stroked-button tabindex="-1" (click)="previousMonth()">&lt;</button></mat-grid-tile>
  <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px; text-align: center;" colspan="5">
    <span style="flex:1; font-size: 48px; white-space: nowrap;">{{ calendarTitle }}</span>
  </mat-grid-tile>
  <mat-grid-tile><button mat-stroked-button tabindex="-1" (click)="nextMonth()">&gt;</button></mat-grid-tile>

  <mat-grid-tile>Sunday</mat-grid-tile>
  <mat-grid-tile>Monday</mat-grid-tile>
  <mat-grid-tile>Tuesday</mat-grid-tile>
  <mat-grid-tile>Wednesday</mat-grid-tile>
  <mat-grid-tile>Thursday</mat-grid-tile>
  <mat-grid-tile>Friday</mat-grid-tile>
  <mat-grid-tile>Saturday</mat-grid-tile>

  <ng-container *ngFor="let day of days">
    <mat-grid-tile>
      <button [disabled]="!day.isSelectable" [class.selected]="day.isSelected" [class.notpreferred]="!day.isPreferred" (click)="datePressed(day)">
        {{ day.day }}
      </button>
    </mat-grid-tile>
  </ng-container>

</mat-grid-list>
