import { Component, Input, EventEmitter, Output } from "@angular/core";
import { EditableTransactionItemConfigurationAddOn, EditableTransactionItemConfigurationInclusionGroupOption, EditableTransactionItemConfigurationPortion, EditableTransactionItemConfigurationPreparation, EditableTransactionItemConfigurationVariation } from "../../../models";
import { Observable, of } from "rxjs";
import { ItemConfiguratorContext } from "../item-configurator-context";
import { ProductConfigurationPreparation, ProductConfigurationVariation } from "downtown-product";
import { isEqualUUID } from "core";

@Component({
  selector: 'transaction-item-configurator-mods',
  templateUrl: './item-configurator-mods.component.html',
  styleUrls: ['./item-configurator-mods.component.scss']
})
export class ItemConfiguratorModsComponent {

  @Input() public context: ItemConfiguratorContext;
  @Output() public configurationChanged = new EventEmitter();

  constructor(
  ) {
  }

  ngOnInit() {

  }

  public get title(): Observable<string> {

    const selection = this.context?.selection;

    if (selection instanceof EditableTransactionItemConfigurationPortion) {
    } else if (selection instanceof EditableTransactionItemConfigurationInclusionGroupOption) {
      return this.context.getItemInclusionGroupOptionDisplay(<EditableTransactionItemConfigurationInclusionGroupOption>selection);
    } else if (selection instanceof EditableTransactionItemConfigurationAddOn) {
    }

    return of('Unknown');
  }

  public get productPreparations(): ProductConfigurationPreparation[] {

    const portion = this.context.getProductPortion(this.context.itemConfiguration.portion.portionUid);
    const selection = this.context?.selection;

    if (selection instanceof EditableTransactionItemConfigurationPortion) {
    } else if (selection instanceof EditableTransactionItemConfigurationInclusionGroupOption) {
      return portion.inclusionGroups.find(x => x.options.some(y => isEqualUUID(y.uid, selection.optionUid))).getOption(selection.optionUid).preparations;
    } else if (selection instanceof EditableTransactionItemConfigurationAddOn) {
    }

    return null;
  }

  public get productVariations(): ProductConfigurationVariation[] {

    const portion = this.context.getProductPortion(this.context.itemConfiguration.portion.portionUid);
    const selection = this.context?.selection;

    if (selection instanceof EditableTransactionItemConfigurationPortion) {
    } else if (selection instanceof EditableTransactionItemConfigurationInclusionGroupOption) {
      return portion.inclusionGroups.find(x => x.options.some(y => isEqualUUID(y.uid, selection.optionUid))).getOption(selection.optionUid).variations;
    } else if (selection instanceof EditableTransactionItemConfigurationAddOn) {
    }

    return null;
  }

  public getProductPreparation(itemPreparation: EditableTransactionItemConfigurationPreparation): ProductConfigurationPreparation {

    const portion = this.context.getProductPortion(this.context.itemConfiguration.portion.portionUid);
    const selection = this.context?.selection;

    if (selection instanceof EditableTransactionItemConfigurationPortion) {
    } else if (selection instanceof EditableTransactionItemConfigurationInclusionGroupOption) {
      return portion.inclusionGroups.find(x => x.options.some(y => isEqualUUID(y.uid, selection.optionUid))).getOption(selection.optionUid).getPreparation(itemPreparation.preparationUid);
    }

    return null;
  }

  public getItemVariation(productVariationUid: string) {

    const item = this.context.getSelectionIfHasVariations();
    return item ? item.variations?.find(x => isEqualUUID(x.variationUid, productVariationUid)) : null;
  }

  public getItemPreparation(productPreparationUid: string) {

    const item = this.context.getSelectionIfHasPreparations();
    return item ? item.preparations?.find(x => isEqualUUID(x.preparationUid, productPreparationUid)) : null;
  }

  public handleConfigurationChanged() {

    this.configurationChanged.emit();
  }
}
