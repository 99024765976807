import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ProductVariationModalComponent } from './product-variation-modal.component';
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class ProductVariationModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open<T>(data: ProductVariationModalData): MatDialogRef<ProductVariationModalComponent> {

    return this.dialog.open<ProductVariationModalComponent, ProductVariationModalData, T>(ProductVariationModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface ProductVariationModalData {

  scope: string,
  variationForm: UntypedFormGroup,
}
