import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EditableTransaction } from "../../models/editable-transaction/editable-transaction";
import { EditableTransactionDataHandler } from "../../models/editable-transaction/editable-transaction-data-handler";
import { Transaction } from "../../models/transaction";
import { TransactionService } from "../../services/transaction.service";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { TransactionViewCommand, TransactionViewModalData } from "./transaction-view-modal.provider";
import { CurrentDateTimeProvider, TimeoutProvider, SettingProvider, SystemMessageProvider, TenantProvider } from "core";
import { TransactionSettings } from "../../models";

@Component({
  selector: 'app-transaction-view-modal',
  templateUrl: './transaction-view-modal.component.html',
  styleUrls: ['./transaction-view-modal.component.scss']
})
export class TransactionViewModalComponent {

  public transactions: Transaction[]
  public editableTransaction: EditableTransaction;
  public canPrint = new BehaviorSubject<boolean>(false);
  public timeoutPercent: number;

  private timeoutProvider: TimeoutProvider;
  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TransactionViewModalData,
    private dialogRef: MatDialogRef<TransactionViewModalComponent>,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider,
    private currentDateTimeProvider: CurrentDateTimeProvider,
    private systemMessageProvider: SystemMessageProvider,
    private transactionService: TransactionService
  ) {
  }

  ngOnInit(): void {

    this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(transactionSettings => {
      this.timeoutProvider = new TimeoutProvider(this.currentDateTimeProvider, this.destroyed$, transactionSettings.transactionAutoClearDuration);
      this.timeoutProvider.percent$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(value => this.timeoutPercent = value);
      this.timeoutProvider.inactive$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(_ => this.close());
    });

    this.transactionService.getByUids(this.data.transactionUids, TransactionService.TransactionIndexView).subscribe(transactions => {
      this.transactions = transactions;

      if (this.transactions.length > 0) {
        this.selectTransaction(this.transactions[0]);
      }
    });
  }

  ngOnDestroy() {

    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  selectTransaction(transaction: Transaction) {

    this.transactionService.getByUid(transaction.uid, TransactionService.TransactionFullView).subscribe(transaction => {
      this.editableTransaction = EditableTransactionDataHandler.mergeTransaction(new EditableTransaction(), transaction);
      this.timeoutProvider.restart();
    });
  }

  handleCommand(command: TransactionViewCommand) {

    this.timeoutProvider.restart();
    command.action(this.editableTransaction);
    if (command.closeOnAction) {
      this.close();
    }
  }

  close() {

    this.dialogRef.close();
  }
}
