import { Injectable } from "@angular/core";
import { HttpService } from "core";
import { Observable } from 'rxjs';
import { Page } from "core";
import { PayPeriod } from '../models/pay-period';
import { PaginationInput } from 'core';
import { OltpServiceSettings } from '../oltp-service-settings';
import { PosModelFactory } from '../pos-model-factory';
import { map, tap } from "rxjs/operators";

@Injectable()
export class PayPeriodService {

  public static readonly PayPeriodIndexView = <PayPeriodViewOptions>{};

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: PayPeriodViewOptions = PayPeriodService.PayPeriodIndexView): Observable<PayPeriod> {

    let view = PayPeriodService.buildView(viewOptions);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<PayPeriod>(this.oltpServiceSettings.apiUrl, 'api/oltp/payperiod', request, 'getByUid').pipe(
      map(x => PosModelFactory.assignPayPeriod(x))
    );
  }

  search(statusUids: string[], paginationInput: PaginationInput, viewOptions: PayPeriodViewOptions = PayPeriodService.PayPeriodIndexView): Observable<Page<PayPeriod>> {

    let view = PayPeriodService.buildView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<PayPeriod>>(this.oltpServiceSettings.apiUrl, 'api/oltp/payperiod', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = PosModelFactory.assignPayPeriod(edge.node)))
    );
  }

  create(uid: string, startDateTimeUtc: Date, endDateTimeUtc: Date, viewOptions: PayPeriodViewOptions = PayPeriodService.PayPeriodIndexView): Observable<PayPeriod> {

    let view = PayPeriodService.buildView(viewOptions);

    var request = {
      query: `mutation createPayPeriod { create(uid: "${uid}", startDateTimeUtc: "${startDateTimeUtc.toISOString()}", endDateTimeUtc: "${endDateTimeUtc.toISOString()}") ${view} }`
    };

    return this.httpService.graph<PayPeriod>(this.oltpServiceSettings.apiUrl, 'api/oltp/payperiod', request, 'create').pipe(
      map(x => PosModelFactory.assignPayPeriod(x))
    );
  }

  update(uid: string, startDateTimeUtc: Date, endDateTimeUtc: Date, viewOptions: PayPeriodViewOptions = PayPeriodService.PayPeriodIndexView): Observable<PayPeriod> {

    let view = PayPeriodService.buildView(viewOptions);

    var request = {
      query: `mutation updatePayPeriod { update(uid: "${uid}", startDateTimeUtc: "${startDateTimeUtc.toISOString()}", endDateTimeUtc: "${endDateTimeUtc.toISOString()}") ${view} }`
    };

    return this.httpService.graph<PayPeriod>(this.oltpServiceSettings.apiUrl, 'api/oltp/payperiod', request, 'update').pipe(
      map(x => PosModelFactory.assignPayPeriod(x))
    );
  }

  startReview(uid: string, viewOptions: PayPeriodViewOptions = PayPeriodService.PayPeriodIndexView): Observable<PayPeriod> {

    let view = PayPeriodService.buildView(viewOptions);

    var request = {
      query: `mutation startReview { startReview(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<PayPeriod>(this.oltpServiceSettings.apiUrl, 'api/oltp/payperiod', request, 'startReview').pipe(
      map(x => PosModelFactory.assignPayPeriod(x))
    );
  }

  commit(uid: string, viewOptions: PayPeriodViewOptions = PayPeriodService.PayPeriodIndexView): Observable<PayPeriod> {

    let view = PayPeriodService.buildView(viewOptions);

    var request = {
      query: `mutation commitPayPeriod { commit(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<PayPeriod>(this.oltpServiceSettings.apiUrl, 'api/oltp/payperiod', request, 'commit').pipe(
      map(x => PosModelFactory.assignPayPeriod(x))
    );
  }

  rollback(uid: string, viewOptions: PayPeriodViewOptions = PayPeriodService.PayPeriodIndexView): Observable<PayPeriod> {

    let view = PayPeriodService.buildView(viewOptions);

    var request = {
      query: `mutation rollbackPayPeriod { rollback(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<PayPeriod>(this.oltpServiceSettings.apiUrl, 'api/oltp/payperiod', request, 'rollback').pipe(
      map(x => PosModelFactory.assignPayPeriod(x))
    );
  }

  delete(uid: string, viewOptions: PayPeriodViewOptions = PayPeriodService.PayPeriodIndexView): Observable<PayPeriod> {

    let view = PayPeriodService.buildView(viewOptions);

    var request = {
      query: `mutation deletePayPeriod { delete(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<PayPeriod>(this.oltpServiceSettings.apiUrl, 'api/oltp/payperiod', request, 'delete').pipe(
      map(x => PosModelFactory.assignPayPeriod(x))
    );
  }

  public static buildView(viewOptions: PayPeriodViewOptions) {

    let view = `uid startDateTimeUtc endDateTimeUtc totalSales totalPay payPeriodStatusUid`;

    return '{ ' + view + ' }';
  }
}

export interface PayPeriodViewOptions {

}
