import { Pipe, PipeTransform } from '@angular/core';
import { CacheService } from 'core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductService } from '../services/product.service';

@Pipe({
  name: 'productPortion'
})
export class ProductPortionPipe implements PipeTransform {

  constructor(
    private productService: ProductService,
    private cacheService: CacheService
  ) {
  }

  transform(productUid: string, portionUid: string): Observable<string> {

    if (productUid) {
      return this.cacheService.getOrAdd(
        productUid,
        () => this.productService.getByUid(productUid, null, ProductService.ProductFullView)
      ).pipe(
        map(product => {
          let portions = product && product.configuration ? product.configuration.portions : null;

          return portions ? portions.find(x => x.uid.toUpperCase() == portionUid.toUpperCase()).name : null;
        })
      );
    }
    return null;
  }
}
