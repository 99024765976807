import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, interval, Observable, of } from "rxjs";
import { catchError, distinctUntilChanged, filter, shareReplay, switchMap, takeWhile, tap } from "rxjs/operators";
import { DeviceHubService } from '../services/device-hub.service';
import { DeviceInfo } from '../models/device-info';
import { DeviceHubSettings } from "../models";

@Injectable()
export class DeviceHubProvider {

  public online$: Observable<boolean>;
  public status$: Observable<string>;
  public hubDeviceInfos$: Observable<DeviceInfo[]>;

  private endpoint: string;
  private onlineSubject$ = new BehaviorSubject<boolean>(false);
  private statusSubject$ = new BehaviorSubject<string>(null);
  private hubDeviceInfosSubject = new BehaviorSubject<DeviceInfo[]>(null);

  constructor(
    private http: HttpClient,
    private deviceHubSettings: DeviceHubSettings,
    private deviceHubService: DeviceHubService
  ) {
    this.online$ = this.onlineSubject$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));
    this.status$ = this.statusSubject$.asObservable().pipe(distinctUntilChanged());
    this.hubDeviceInfos$ = this.hubDeviceInfosSubject.asObservable().pipe(shareReplay(1));
    this.endpoint = this.deviceHubSettings.apiUrl ? this.deviceHubSettings.apiUrl + 'api/health/alive' : null;

    this.initializeConnection();
  }

  public get online(): boolean {

    return this.onlineSubject$.value;
  }

  public get devices(): DeviceInfo[] {

    return this.hubDeviceInfosSubject.value;
  }

  private initializeConnection() {

    this.statusSubject$.next('Connecting');

    if (this.endpoint) {
      interval(1000).pipe(
        switchMap(() => this.getDeviceHubOnline$()),
        takeWhile(isAlive => !isAlive, true),
        filter(isAlive => isAlive),
        switchMap(() => this.deviceHubService.getHubDevices())
      ).subscribe(hubDevices => {
        this.hubDeviceInfosSubject.next(hubDevices);
        if (!this.onlineSubject$.value) {
          this.onlineSubject$.next(true);
        }

        // const availabilityHeartbeat = this.deviceHubSettings.availabilityHeartbeat || 0;

        // console.log(`[DeviceHubProvider] Made initial heartbeat connection. Checking again in ${availabilityHeartbeat} seconds.`);

        // if (this.deviceHubSettings.availabilityHeartbeat > 0) {
        //   interval(availabilityHeartbeat * 1000).pipe(
        //     switchMap(() => this.getDeviceHubOnline$()),
        //     takeWhile(isAlive => isAlive),
        //     filter(isAlive => isAlive),
        //   ).subscribe({
        //     next: () => {
        //       console.log(`[DeviceHubProvider] Made heartbeat connection. Checking again in ${availabilityHeartbeat} seconds.`);
        //     },
        //     complete: () => {
        //       this.hubDeviceInfosSubject.next(null);
        //       this.onlineSubject$.next(false);

        //       console.log(`[DeviceHubProvider] Lost heartbeat connection. Retrying connection initializion.`);

        //       this.initializeConnection();
        //     }
        //   });
        // } else {
        //   this.onlineSubject$.next(true);
        // }
      });
    }
  }

  private getDeviceHubOnline$(): Observable<boolean> {

    return this.http.get<boolean>(this.endpoint).pipe(
      tap(alive => this.onlineSubject$.next(alive)),
      tap(_ => this.statusSubject$.next('Available')),
      catchError(x => of(false))
    );
  }
}
