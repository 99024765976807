import { CartItem } from './cart-item';

export class Cart {

  uid: string;
  ownerUid: string;
  storeFrontUid: string;
  identityUid: string;
  logisticTypeUid: string;
  logisticScheduleUid: string;
  logisticDateTimeUtc: string;
  itemQuantity: number = 0;
  subTotalAmount: number = 0;
  cartStatusUid: string;

  items = new Array<CartItem>();
}
