import { Component, Input, Output, EventEmitter } from "@angular/core";
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemConfiguratorContext } from "../../item-configurator-context";
import { CurrencyPipe } from "@angular/common";
import { isEqualUUID, slideInAnimation, slideOutAnimation } from "core";
import { SelectListModalData, SelectListModalProvider } from "core";
import { findInArrayByKey, findInArrayByUid } from "core";
import { EditableTransactionItemConfigurationPortion, EditableTransactionItemConfiguration, EditableTransactionItemConfigurationVariation, EditableTransactionItemConfigurationInclusionGroup } from "../../../../models/editable-transaction/editable-transaction";
import { ProductConfigurationPortion, ProductConfigurationPreparationOption } from "downtown-product";

@Component({
  selector: 'transaction-item-configurator-detail-portion',
  templateUrl: './item-configurator-detail-portion.component.html',
  styleUrls: ['./item-configurator-detail-portion.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfiguratorDetailPortionComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public productPortion: ProductConfigurationPortion;
  @Input() public itemPortion: EditableTransactionItemConfigurationPortion;
  @Output() public configurationChanged = new EventEmitter();

  public display: string;

  constructor(
    private currencyPipe: CurrencyPipe,
    private selectListModalProvider: SelectListModalProvider
  ) {
  }

  ngOnInit() {

    this.display = `${this.context.product.name} ${(this.productPortion ? ` - ${this.productPortion.name}` : null)}`;
  }

  public get configuration(): EditableTransactionItemConfiguration {

    return this.context.itemConfiguration;
  }

  public getVisibleInclusionGroups(): EditableTransactionItemConfigurationInclusionGroup[] {

    const productInclusionGroups = this.productPortion?.inclusionGroups || [];
    const visibleProductInclusionGroups = this.context.itemConfiguration?.portion?.inclusionGroups.filter(x => {
      const productInclusionGroup = productInclusionGroups.find(y => isEqualUUID(x.inclusionGroupUid, y.uid));

      return !productInclusionGroup?.hideInUIDetail;
    });

    return visibleProductInclusionGroups;
  }

  public getPortionPreparationDisplay(portionUid: string, preparationUid: string): string {

    return this.context.localCache.getOrAdd(`${this.context.product.uid}_${portionUid}_${preparationUid}`, () => {
      let portion = this.context.product.configuration.getPortion(portionUid);

      let preparation = findInArrayByUid(portion.preparations, preparationUid);

      return preparation.name;
    });
  }

  public getPortionPreparationOptionDisplay(portionUid: string, preparationUid: string, preparationOptionUid: string): string {

    return this.context.localCache.getOrAdd(`${this.context.product.uid}_${portionUid}_${preparationUid}_${preparationOptionUid}`, () => {
      let portion = this.context.product.configuration.getPortion(portionUid);

      let preparation = findInArrayByUid(portion.preparations, preparationUid);
      let preparationOption = findInArrayByUid(preparation.options, preparationOptionUid);

      return preparationOption.name;
    });
  }

  public getPortionVariationDisplay(portionUid: string, variationUid: string): string {

    return this.context.localCache.getOrAdd(`${this.context.product.uid}_${portionUid}_${variationUid}`, () => {
      let portion = this.context.product.configuration.getPortion(portionUid);

      let variation = findInArrayByUid(portion.variations, variationUid);

      return variation.name;
    });
  }

  public getPortionVariationOptionDisplay(portionUid: string, variationUid: string, variationOptionUid: string): Observable<string> {

    return this.context.localCache.getOrAdd(`${this.context.product.uid}_${portionUid}_${variationUid}_${variationOptionUid}`, () => {
      let portion = this.context.product.configuration.getPortion(portionUid);

      let variation = findInArrayByUid(portion.variations, variationUid);
      let variationOption = findInArrayByUid(variation.options, variationOptionUid);

      if (variationOption && variationOption.alias) {
        return of(variationOption.alias);
      }

      return this.context.productProvider.getOneCached$(variationOption.productReference.uid, variationOption.productReference.version).pipe(
        map(x => x.name)
      );
    });
  }

  public editPreparation(portionUid: string, preparationUid: string) {

    let portion = this.context.product.configuration.getPortion(portionUid);
    let preparation = findInArrayByUid(portion.preparations, preparationUid);

    this.selectListModalProvider.open(<SelectListModalData<ProductConfigurationPreparationOption>>{
      title: preparation.name,
      options: preparation.options,
      displayFunc: x => `${x.name} ${(x.additionalPrice ? `+ ${this.currencyPipe.transform(x.additionalPrice, 'USD', 'symbol', '1.2-2')}` : '')}`
    }).afterClosed().subscribe(selection => {
      if (selection) {
        let configuringPortion = this.configuration.portion;
        let preparation = findInArrayByKey(configuringPortion.preparations, 'preparationUid', preparationUid);

        preparation.optionUid = (<ProductConfigurationPreparationOption>selection).uid;

        this.configurationChanged.emit();
      }
    });
  }

  public editVariation(portionUid: string, variationUid: string) {

    let portion = this.context.product.configuration.getPortion(portionUid);
    let variation = findInArrayByUid(portion.variations, variationUid);

    combineLatest(variation.options.map(variationOption => {
      if (variationOption && variationOption.alias) {
        return of({ uid: variationOption.uid, display: variationOption.alias });
      }

      return this.context.productProvider.getOneCached$(variationOption.productReference.uid, variationOption.productReference.version).pipe(
        map(x => { return { uid: variationOption.uid, display: x.name }; })
      );
    })).subscribe(values => {
      values.splice(0, 0, { uid: null, display: 'None' });

      this.selectListModalProvider.open(<SelectListModalData<any>>{
        title: variation.name,
        options: values,
        displayFunc: x => x.display
      }).afterClosed().subscribe(selection => {
        if (selection) {
          let configuringPortion = this.configuration.portion;

          configuringPortion.variations = configuringPortion.variations || [];
          let variation = findInArrayByKey(configuringPortion.variations, 'variationUid', variationUid);

          if (selection.uid) {
            if (!variation) {
              variation = <EditableTransactionItemConfigurationVariation>{
                variationUid: variationUid,
                productUid: variation.productUid,
                productVersion: variation.productVersion,
                productPortionUid: variation.productPortionUid
              };
              configuringPortion.variations.push(variation);
            }

            variation.optionUid = selection.uid;
          } else {
            if (variation) {
              configuringPortion.variations.splice(configuringPortion.variations.indexOf(variation), 1);
              if (configuringPortion.variations.length == 0) {
                configuringPortion.variations == null;
              }
            }
          }

          variation.optionUid = selection.uid;

          this.configurationChanged.emit();
        }
      });
    });
  }

  handleConfigurationChanged() {

    this.configurationChanged.emit();
  }
}
