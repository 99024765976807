import {
    GuestServiceModalComponent,
    GuestServiceModalProvider,
    ItemConfiguratorModalAddOnComponent,
    ItemConfiguratorModalAddOnsTabComponent,
    ItemConfiguratorModalComponent,

    ItemConfiguratorModalInclusionGroupTabComponent,
    ItemConfiguratorModalAddOnTabComponent,
    ItemConfiguratorModalPortionsTabComponent,
    ItemConfiguratorModalProvider,
    TransactionSelectModalComponent,
    TransactionSelectModalProvider,
    TransactionViewModalComponent,
    TransactionViewModalProvider
} from ".";

export const modalComponentTypes = [
    GuestServiceModalComponent,
    ItemConfiguratorModalComponent,
    TransactionSelectModalComponent,
    TransactionViewModalComponent,
    ItemConfiguratorModalPortionsTabComponent,
    ItemConfiguratorModalInclusionGroupTabComponent,
    ItemConfiguratorModalAddOnTabComponent,
    ItemConfiguratorModalAddOnsTabComponent,
    ItemConfiguratorModalAddOnComponent,
];

export const modalProviderTypes = [
    GuestServiceModalProvider,
    TransactionSelectModalProvider,
    TransactionViewModalProvider,
    ItemConfiguratorModalProvider,
];
