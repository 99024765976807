import { PayPeriodStatus } from "./pay-period-status";

export class PayPeriod {

  uid: string;
  startDateTimeUtc: Date;
  endDateTimeUtc: Date;
  totalSales: number;
  totalPay: number;
  payPeriodStatusUid: string;
}
