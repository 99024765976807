import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EditableTransaction } from "downtown-transaction";
import { EditableTransactionDataHandler } from "downtown-transaction";
import { Transaction } from "downtown-transaction";
import { StoreIntegrationModalData } from "./store-integration-modal.provider";
import { TransactionService } from "downtown-transaction";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'app-store-integration-modal',
  templateUrl: './store-integration-modal.component.html',
  styleUrls: ['./store-integration-modal.component.scss']
})
export class StoreIntegrationModalComponent {

  public transactions: Transaction[]
  public editableTransaction: EditableTransaction;
  public canPrint = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StoreIntegrationModalData,
    private dialogRef: MatDialogRef<StoreIntegrationModalComponent>,
    private transactionService: TransactionService
  ) {
  }

  ngOnInit(): void {

    this.transactionService.getByUids(this.data.transactionUids, TransactionService.TransactionIndexView).subscribe(transactions => {
      this.transactions = transactions;

      if (this.transactions.length > 0) {
        this.selectTransaction(this.transactions[0]);
      }
    });
  }

  selectTransaction(transaction: Transaction) {

    this.transactionService.getByUid(transaction.uid, TransactionService.TransactionFullView).subscribe(transaction => {
      this.editableTransaction = EditableTransactionDataHandler.mergeTransaction(new EditableTransaction(), transaction);
    });
  }

  department() {

  }

  print(){

  }

  reopen() {

  }

  close() {

  }
}
