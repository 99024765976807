import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LogisticTypeModel, TransactionSettings } from "downtown-transaction";
import { LogisticTypeModalData } from "./logistic-type-modal.provider";
import { CurrentDateTimeProvider, TimeoutProvider, SettingProvider, TenantProvider } from "core";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: 'app-logistic-type-modal',
  templateUrl: './logistic-type-modal.component.html',
  styleUrls: ['./logistic-type-modal.component.scss']
})
export class LogisticTypeModalComponent {

  public timeoutPercent: number;

  private timeoutProvider: TimeoutProvider;
  private destroyed$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<LogisticTypeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LogisticTypeModalData,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider,
    private currentDateTimeProvider: CurrentDateTimeProvider
  ) {
    this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(transactionSettings => {      
      this.timeoutProvider = new TimeoutProvider(this.currentDateTimeProvider, this.destroyed$, transactionSettings.transactionAutoClearDuration);
      this.timeoutProvider.percent$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(value => this.timeoutPercent = value);
      this.timeoutProvider.inactive$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(_ => this.cancel());
    });
 }

  selectOption(option: LogisticTypeModel) {

    this.dialogRef.close(option);
  }

  cancel() {

    this.dialogRef.close();
  }
}
