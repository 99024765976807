export class TimeSpan {

  private _hours: number;
  private _minutes: number;

  constructor(hours: number, minutes: number) {

    this._hours = hours;
    this._minutes = minutes;
  }

  get hours(): number {
    return this._hours;
  };

  get minutes(): number {
    return this._minutes;
  };

  toNumber(): number {
    return this.hours + ((this.minutes / 60 * 100) / 100);
  }
}

export function getDuration(startDateTime: Date, endDateTime: Date): TimeSpan {

  if ((startDateTime && endDateTime) && (endDateTime > startDateTime)) {
    // Get duration as hours
    var ms = endDateTime.getTime() - startDateTime.getTime();
    // var msDays = Math.floor(ms / 1000 / 60 / 60 / 24);
    var hoursDuraction = Math.floor(ms / 1000 / 60 / 60);
    var minutesDuration = (ms - (hoursDuraction * 60 * 60 * 1000)) / 1000 / 60;

    return new TimeSpan(hoursDuraction, minutesDuration);
  }

  return null;
}
