import { Component, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { first, map, concatMap, takeUntil } from 'rxjs/operators';
import { ActionBarConfiguration, ActionConfiguration, ConfirmModalProvider, ErrorHandlingProvider, FilterConfiguration, FilterOptionConfiguration, SystemMessageProvider, getParamsFromFilters, getParamsFromPaginator, updateFiltersFromParams, updatePaginatorFromParams } from 'core';
import { Paginator } from 'core';
import { SpinnerModalComponent } from 'core';
import { SpinnerModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { DrawerDataSource, DrawerRowModel } from 'pos-core';
import { DrawerStatusKeys } from 'pos-core';
import { DrawerService } from 'pos-core';
import { UUID } from 'angular2-uuid';
import { ActivatedRoute } from '@angular/router';
import { TerminalProvider } from 'downmain-terminal';

@Component({
  selector: 'app-back-office-drawers',
  templateUrl: './back-office-drawers.component.html',
  styleUrls: ['./back-office-drawers.component.scss']
})
export class BackOfficeDrawersComponent implements OnInit {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: DrawerDataSource;
  public paginator: Paginator;

  public title: string;
  public columns: Array<string>;

  public canSyncToStore = true;

  private destroyed$ = new Subject();
  private refresh$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorHandlingProvider: ErrorHandlingProvider,
    private systemMessageProvider: SystemMessageProvider,
    private terminalProvider: TerminalProvider,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private drawerService: DrawerService
  ) {
    this.navigationProvider.setWaypoint('Drawers');
    this.title = 'Drawers';

    this.dataSource = new DrawerDataSource(errorHandlingProvider, this.drawerService, false);
    this.paginator = new Paginator(this.dataSource, 0, 100, '', 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('View', () => this.view(this.dataSource.selection.selected[0]), () => !this.dataSource.selection.isEmpty()),
        new ActionConfiguration('Kick', () => this.kick(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canKick)),
        new ActionConfiguration('End', () => this.end(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canClose)),
        new ActionConfiguration('Delete', () => this.delete(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDelete)),
      ],
      [
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('Open', [DrawerStatusKeys.Started]),
            new FilterOptionConfiguration('All', [DrawerStatusKeys.Started, DrawerStatusKeys.Ended, DrawerStatusKeys.Committed])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'Open')) }
        )
      ]
    );

    // Build initial columns
    this.columns = [ /*'select',*/ 'terminalName', 'startDateTimeUtc', 'startAuth', 'endDateTimeUtc', 'endAuth', 'seedAmount', 'balance', 'status'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    const statusFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => x.title == 'Status');
    const paginator = this.paginator;

    this.activatedRoute.queryParams.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(params => {
      updateFiltersFromParams([statusFilter], params);
      updatePaginatorFromParams(paginator, params);
    });

    combineLatest([
      statusFilter.selected,
      paginator.pageRequest$,
      this.refresh$
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([statusFilterOption, paginationInput]) => {
        let statusFilterUids = statusFilterOption.value;

        const params = Object.assign(getParamsFromFilters([statusFilter]), getParamsFromPaginator(paginator));
        this.navigationProvider.navigate([], { queryParams: params, queryParamsHandling: 'merge' }).then(_ => this.navigationProvider.updateLastWaypoint());

        this.dataSource.loadData(statusFilterUids, paginationInput, DrawerService.DrawerIndexView);
      })
    ).subscribe();

    this.refresh$.next(null);
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }

  public view(drawer: DrawerRowModel) {

    this.navigationProvider.navigate(['/backoffice/drawer', drawer.drawer.uid]);
  }

  public kick(drawer: DrawerRowModel) {

    this.terminalProvider.getOneByUid$(drawer.drawer.terminalUid).pipe(
      first(),
      concatMap(terminal => {
        var systemMessage = this.systemMessageProvider.buildMessage(UUID.UUID(), 'open-drawer', null, null, `${terminal.name} FE`);

        return this.systemMessageProvider.publishOneWithResponse$<boolean>(systemMessage).pipe(
          map(result => {
            if (!result) {
              // this.showMessage('Device error', 'Unable to kick open cash drawer');
            }

            return true;
          })
        );
      })
    ).subscribe();
  }

  public end(drawer: DrawerRowModel) {

    this.confirmModalProvider.open('End Drawer', 'Are you certain you want to end this drawer?  Note that this will make the target terminal unable to perform any cash drawer functions.').afterClosed().subscribe(result => {
      if (result) {
        this.drawerService.end(drawer.drawer.uid).subscribe(x => {
          this.refresh$.next(null);
        });
      }
    });
  }

  public delete(drawer: DrawerRowModel) {

    this.confirmModalProvider.open('Delete Drawer', 'Are you certain you want to delete this drawer?').afterClosed().subscribe(result => {
      if (result) {
        this.drawerService.delete(drawer.drawer.uid).subscribe(x => {
          this.refresh$.next(null);
        });
      }
    });
  }
}

