import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { IPagedDataSource, isCaseInsensitiveEqual } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { PaymentMethodKeys } from "core";
import { CardTransactionStatusKeys, TransactionStatusEnum } from "../../keys";
import { DateRangeFilterInput } from "core";
// import { TerminalProvider } from "../../providers/terminal.provider";
import { TransactionService, TransactionViewOptions } from "../../services/transaction.service";
import { TransactionProvider } from "../../providers";

@Component({
  selector: 'transaction-table',
  templateUrl: './transactions-table.component.html',
  styleUrls: ['./transactions-table.component.scss']
})
export class TransactionsTableComponent {

  @Input() public dataSource: TransactionDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {

  }
}

export class TransactionModel {

  uid: string;
  number: string;
  notes: string;
  holdCardReference: string;
  logisticTypeUid: string;
  transactionStatus: TransactionStatusEnum;
  lockUid: string;
  openDateTimeUtc: Date;
  closeDateTimeUtc: Date;
  isAdjusted: boolean;
  total: number;
  totalTips: number;

  payments: TransactionPaymentModel[];

  canEdit: boolean;
  canReopen: boolean;
}

export class TransactionPaymentModel {
  uid: string;
  paymentMethodUid: string;
  amount: number;
  tip: number;
  lastFour: string;
  status: string;

  canUpdateTip: boolean;
}

export class TransactionDataSource extends DataSource<TransactionModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public totalValue$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<TransactionModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private totalValueSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<TransactionModel[]>([]);
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private transactionProvider: TransactionProvider,
    // private terminalProvider: TerminalProvider,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.totalValue$ = this.totalValueSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<TransactionModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<TransactionModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(openedDateRangeFilter: DateRangeFilterInput, closedDateRangeFilter: DateRangeFilterInput, statusUids: string[], paginationInput: PaginationInput, viewOptions: TransactionViewOptions) {

    this.loadingSubject.next(true);

    this.transactionProvider.search$(openedDateRangeFilter, closedDateRangeFilter, statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);
        this.totalValueSubject.next(page.totalValue);

        const transactions = page.edges.map(x => x.node).map(x => {
          let transactionModel = <TransactionModel>Object.assign(new TransactionModel(), {
            uid: x.uid,
            number: x.number,
            notes: x.notes,
            holdCardReference: x.holdCardReference,
            logisticTypeUid: x.logisticTypeUid,
            transactionStatus: x.transactionStatus,
            lockUid: x.lockUid,
            openDateTimeUtc: x.openDateTimeUtc,
            closeDateTimeUtc: x.closeDateTimeUtc,
            isAdjusted: x.isAdjusted,
            total: x.total,
            totalTips: x.payments ? x.payments.filter(x => x.cardTransaction?.tip > 0).map(x => x.cardTransaction.tip).reduce((x, y) => x + y, 0) : 0,

            payments: x.payments?.map(payment => {
              return <TransactionPaymentModel>Object.assign(new TransactionPaymentModel(), {
                uid: payment.uid,
                amount: payment.amount,
                paymentMethodUid: payment.paymentMethodUid,
                tip: payment.cardTransaction ? payment.cardTransaction.tip : null,
                lastFour: payment.cardTransaction ? payment.cardTransaction.lastFour : null,
                status: payment.cardTransaction ? payment.cardTransaction.cardTransactionStatusUid : null,
                canUpdateTip: isCaseInsensitiveEqual(payment.paymentMethodUid, PaymentMethodKeys.Card) && payment.cardTransaction != null && !isCaseInsensitiveEqual(payment.cardTransaction.cardTransactionStatusUid, CardTransactionStatusKeys.Captured)
              });
            })
          });

          this.evaluateEnablement(transactionModel);

          return transactionModel;
        });

        return <[PageInfo, TransactionModel[]]>[page.pageInfo, transactions];
      }),
      catchError(() => of(<[PageInfo, TransactionModel[]]>[null, []])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  private evaluateEnablement(transaction: TransactionModel): TransactionModel {

    let transactionStatus = transaction.transactionStatus;

    transaction.canEdit = isCaseInsensitiveEqual(transactionStatus, TransactionStatusEnum.Open);
    // && (transaction.lockTerminalUid == null || transaction.lockTerminalUid.toUpperCase() == this.terminalProvider.localTerminal.uid.toUpperCase());
    transaction.canReopen = isCaseInsensitiveEqual(transactionStatus, TransactionStatusEnum.Closed);

    return transaction;
  }

  private evaluateBulkEnablement() {

  }
}
