import { Component, Input, Output, EventEmitter } from "@angular/core";
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemConfigurationDetailContext } from "../item-configuration-detail-context";
import { CurrencyPipe } from "@angular/common";
import { slideInAnimation, slideOutAnimation } from "core";
import { SelectListModalData, SelectListModalProvider } from "core";
import { findInArrayByKey, findInArrayByUid } from "core";
import { EditableTransactionItemConfigurationPortion, EditableTransactionItemConfiguration, EditableTransactionItemConfigurationVariation } from "../../../models/editable-transaction/editable-transaction";
import { ProductConfigurationPreparationOption } from "downtown-product";

@Component({
  selector: 'transaction-item-configuration-detail-portion',
  templateUrl: './item-configuration-detail-portion.component.html',
  styleUrls: ['./item-configuration-detail-portion.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfigurationDetailPortionComponent {

  @Input() public context: ItemConfigurationDetailContext;
  @Input() public portion: EditableTransactionItemConfigurationPortion;
  @Output() public configurationChanged = new EventEmitter();

  constructor(
    private currencyPipe: CurrencyPipe,
    private selectListModalProvider: SelectListModalProvider
  ) {
  }

  public get configuration(): EditableTransactionItemConfiguration {

    return this.context.itemConfiguration;
  }

  getPortionDisplay(portionUid: string): string {

    return this.context.localCache.getOrAdd(`${this.context.configurableProduct.uid}_${portionUid}`, () => {
      let portion = this.context.configurableProduct && this.context.configurableProduct.configuration ? this.context.configurableProduct.configuration.getPortion(portionUid) : null;
      return `${this.context.configurableProduct.name} ${(portion ? ` - ${portion.name}` : null)}`;
    });
  }

  public getPortionPreparationDisplay(portionUid: string, preparationUid: string): string {

    return this.context.localCache.getOrAdd(`${this.context.configurableProduct.uid}_${portionUid}_${preparationUid}`, () => {
      let portion = this.context.configurableProduct.configuration.getPortion(portionUid);

      let preparation = findInArrayByUid(portion.preparations, preparationUid);

      return preparation.name;
    });
  }

  public getPortionPreparationOptionDisplay(portionUid: string, preparationUid: string, preparationOptionUid: string): string {

    return this.context.localCache.getOrAdd(`${this.context.configurableProduct.uid}_${portionUid}_${preparationUid}_${preparationOptionUid}`, () => {
      let portion = this.context.configurableProduct.configuration.getPortion(portionUid);

      let preparation = findInArrayByUid(portion.preparations, preparationUid);
      let preparationOption = findInArrayByUid(preparation.options, preparationOptionUid);

      return preparationOption.name;
    });
  }

  public getPortionVariationDisplay(portionUid: string, variationUid: string): string {

    return this.context.localCache.getOrAdd(`${this.context.configurableProduct.uid}_${portionUid}_${variationUid}`, () => {
      let portion = this.context.configurableProduct.configuration.getPortion(portionUid);

      let variation = findInArrayByUid(portion.variations, variationUid);

      return variation.name;
    });
  }

  public getPortionVariationOptionDisplay(portionUid: string, variationUid: string, variationOptionUid: string): Observable<string> {

    return this.context.localCache.getOrAdd(`${this.context.configurableProduct.uid}_${portionUid}_${variationUid}_${variationOptionUid}`, () => {
      let portion = this.context.configurableProduct.configuration.getPortion(portionUid);

      let variation = findInArrayByUid(portion.variations, variationUid);
      let variationOption = findInArrayByUid(variation.options, variationOptionUid);

      if (variationOption && variationOption.alias) {
        return of(variationOption.alias);
      }

      return this.context.productProvider.getOneCached$(variationOption.productReference.uid, variationOption.productReference.version).pipe(
        map(x => x.name)
      );
    });
  }

  public editPreparation(portionUid: string, preparationUid: string) {

    let portion = this.context.configurableProduct.configuration.getPortion(portionUid);
    let preparation = findInArrayByUid(portion.preparations, preparationUid);

    this.selectListModalProvider.open(<SelectListModalData<ProductConfigurationPreparationOption>>{
      title: preparation.name,
      options: preparation.options,
      displayFunc: x => `${x.name} ${(x.additionalPrice ? `+ ${this.currencyPipe.transform(x.additionalPrice, 'USD', 'symbol', '1.2-2')}` : '')}`
    }).afterClosed().subscribe(selection => {
      if (selection) {
        let configuringPortion = this.configuration.portion;
        let preparation = findInArrayByKey(configuringPortion.preparations, 'preparationUid', preparationUid);

        preparation.optionUid = (<ProductConfigurationPreparationOption>selection).uid;

        this.configurationChanged.emit();
      }
    });
  }

  public editVariation(portionUid: string, variationUid: string) {

    let portion = this.context.configurableProduct.configuration.getPortion(portionUid);
    let variation = findInArrayByUid(portion.variations, variationUid);

    combineLatest(variation.options.map(variationOption => {
      if (variationOption && variationOption.alias) {
        return of({ uid: variationOption.uid, display: variationOption.alias });
      }

      return this.context.productProvider.getOneCached$(variationOption.productReference.uid, variationOption.productReference.version).pipe(
        map(x => { return { uid: variationOption.uid, display: x.name }; })
      );
    })).subscribe(values => {
      values.splice(0, 0, { uid: null, display: 'None' });

      this.selectListModalProvider.open(<SelectListModalData<any>>{
        title: variation.name,
        options: values,
        displayFunc: x => x.display
      }).afterClosed().subscribe(selection => {
        if (selection) {
          let configuringPortion = this.configuration.portion;

          configuringPortion.variations = configuringPortion.variations || [];
          let variation = findInArrayByKey(configuringPortion.variations, 'variationUid', variationUid);

          if (selection.uid) {
            if (!variation) {
              variation = <EditableTransactionItemConfigurationVariation>{
                variationUid: variationUid,
                productUid: variation.productUid,
                productVersion: variation.productVersion,
                productPortionUid: variation.productPortionUid
              };
              configuringPortion.variations.push(variation);
            }

            variation.optionUid = selection.uid;
          } else {
            if (variation) {
              configuringPortion.variations.splice(configuringPortion.variations.indexOf(variation), 1);
              if (configuringPortion.variations.length == 0) {
                configuringPortion.variations == null;
              }
            }
          }

          variation.optionUid = selection.uid;

          this.configurationChanged.emit();
        }
      });
    });
  }
}
