import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from 'rxjs';
import { ItemConfiguratorModalData, ItemConfiguratorModalResult } from "./item-configurator-modal.provider";
import { cloneTransactionItemConfiguration } from "../../functions/transaction-item-configuration/clone";
import { fromEditableTransactionItemConfiguration } from "../../functions/transaction-item-configuration/transaction-input-factory";
import { ProductConfigurationAddOn, ProductConfigurationInclusionGroup, ProductConfigurationTab, ProductProvider } from "downtown-product";
import { flattenProduct } from "downtown-product";
import { calculateConfigurationTotal, configureProductConfigurationWithEditableItemConfiguration } from "../../functions";
import { ItemConfiguratorContext } from "../../components";
import { isEqualUUID } from "core";

@Component({
  selector: 'app-item-configurator-modal',
  templateUrl: './item-configurator-modal.component.html',
  styleUrls: ['./item-configurator-modal.component.scss']
})
export class ItemConfiguratorModalComponent {

  public context: ItemConfiguratorContext;

  public title: string;
  public subtitle: string;
  public selectionTabs: SelectionTab[];
  public selectedTab: SelectionTab;

  private destroyed$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ItemConfiguratorModalData,
    private dialogRef: MatDialogRef<ItemConfiguratorModalComponent>,
    private productProvider: ProductProvider
  ) {

  }

  ngOnInit() {

    this.productProvider.getOneCached$(this.data.productUid, this.data.productVersion).subscribe(product => {

      product = flattenProduct(product);
      product.configuration = configureProductConfigurationWithEditableItemConfiguration(product.configuration, this.data.itemConfiguration);

      this.context = new ItemConfiguratorContext(
        this.data.ownerUid,
        this.productProvider,
        product,
        cloneTransactionItemConfiguration(this.data.itemConfiguration),
        false
      );

      const itemPortion = this.context.getItemPortion();

      const productPortion = product.configuration.getPortion(itemPortion.portionUid);
      const productConfigurationTabs = productPortion.tabs || [];
      const productInclusionGroups = productPortion.inclusionGroups?.filter(x => !x.hideInUIDetail);

      if (productConfigurationTabs.length == 0) {
        const inclusionGroupTabs = productInclusionGroups
          .map(x => {
            return <ProductConfigurationTab>{
              name: x.name,
              inclusionGroupUids: [x.uid]
            }
          });

        productConfigurationTabs.push(...inclusionGroupTabs);

        if (productPortion.addOns?.length > 0) {
          const addOnTab = <ProductConfigurationTab>{
            name: 'Add Ons',
            addOnUids: productPortion.addOns?.map(x => x.uid)
          }

          productConfigurationTabs.push(addOnTab);
        }
      }

      productConfigurationTabs.unshift(<ProductConfigurationTab>{
        name: 'All',
        inclusionGroupUids: productInclusionGroups.map(x => x.uid),
        addOnUids: productPortion.addOns?.map(x => x.uid)
      });

      const selectionTabs = productConfigurationTabs
        .filter(x => !!x)
        .map(productConfigurationTab => {
          return <SelectionTab>{
            display: productConfigurationTab.name,
            inclusionGroupUids: productConfigurationTab.inclusionGroupUids,
            addOnUids: productConfigurationTab.addOnUids
          };
        });

      this.selectionTabs = selectionTabs;
      this.selectedTab = this.selectionTabs[0];

      this.updateDisplay();
    });
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public getAvailableInclusionGroups(): ProductConfigurationInclusionGroup[] {

    const portion = this.context.product.configuration.getPortion(this.context.itemConfiguration.portion.portionUid);

    return portion.inclusionGroups?.filter(x => !x.hideInUIDetail);
  }

  public getProductInclusionGroup(inclusionGroupUid: string): ProductConfigurationInclusionGroup {

    const portion = this.context.product.configuration.getPortion(this.context.itemConfiguration.portion.portionUid);

    return portion.inclusionGroups?.find(x => isEqualUUID(inclusionGroupUid, x.uid));
  }

  public getProductAddOn(addOnUid: string): ProductConfigurationAddOn {

    const portion = this.context.product.configuration.getPortion(this.context.itemConfiguration.portion.portionUid);

    return portion.addOns?.find(x => isEqualUUID(addOnUid, x.uid));
  }

  public selectTab(selectionTab: SelectionTab) {

    this.selectedTab = selectionTab;
  }

  public updateDisplay() {

  }

  public selectOption(startAnother: boolean) {

    let existingConfigurationState = JSON.stringify(fromEditableTransactionItemConfiguration(this.data.itemConfiguration));

    // Check dirty state
    let updatedConfigurationState = JSON.stringify(fromEditableTransactionItemConfiguration(this.context.itemConfiguration));

    if (!this.data.isNew && existingConfigurationState.toUpperCase() == updatedConfigurationState.toUpperCase()) {
      this.dialogRef.close(null)
    } else {
      calculateConfigurationTotal(this.data.productUid, this.data.productVersion, this.context.itemConfiguration, this.productProvider).subscribe(total => {
        this.dialogRef.close(<ItemConfiguratorModalResult>{
          productUid: this.data.productUid,
          productVersion: this.data.productVersion,
          configuration: this.context.itemConfiguration,
          startAnother: startAnother,
          eachTotal: total
        });
      });
    }
  }

  public cancel() {

    this.dialogRef.close(null);
  }
}

interface SelectionTab {

  display: string;
  inclusionGroupUids?: string[];
  addOnUids?: string[];
}