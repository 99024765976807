<h2 mat-dialog-title style="text-align: center;">{{ data.title }}</h2>
<mat-dialog-content>

  <div style="display: flex; justify-content: center;">
    <app-pos-button mat-list-item *ngFor="let option of data.options" (click)="selectOption(option)">{{ data.displayFunc(option) }}</app-pos-button>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <span style="flex: 1 1 auto;"></span>
  <app-timeout-button color="warn" [percent]="timeoutPercent" (click)="cancel()">Cancel</app-timeout-button>
</mat-dialog-actions>
