import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProductAndPortionModalComponent } from "./product-and-portion-modal.component";
import { Product, ProductConfigurationProductReference } from '../../models/product';

@Injectable()
export class ProductAndPortionModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: ProductAndPortionModalData): MatDialogRef<ProductAndPortionModalComponent, ProductAndPortionModalResult> {

    return this.dialog.open<ProductAndPortionModalComponent, ProductAndPortionModalData, ProductAndPortionModalResult>(ProductAndPortionModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface ProductAndPortionModalData {

  title: string,
  products: Product[]
}

export interface ProductAndPortionModalResult {

  productReference: ProductConfigurationProductReference;
}
