import { Injectable } from "@angular/core";

@Injectable()
export class IdentityServerSettings {

  url: string;
  redirectUrl: string;
  clientId: string;
  responseType: string;
  scope: string;
  postLogoutRedirectUrl: string;
  startChecksession: boolean;
  silentRenew: boolean;
  startupRoute: string;
  forbiddenRoute: string;
  unauthorizedRoute: string;
  logConsoleWarningActive: boolean;
  logConsoleDebugActive: boolean;
  maxIdTokenIatOffsetAllowedInSeconds: number;
  availabilityHeartbeat: number;
}
