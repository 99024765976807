import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ObservableCacheProvider } from './observable-cache.provider';
import { isCaseInsensitiveEqual } from '../functions/string';

@Injectable()
export class TenantProvider {

  public currentUid$: Observable<string>;

  private currentUidSubject = new BehaviorSubject<string>(null);

  constructor(
    private cacheProvider: ObservableCacheProvider
  ) {
    this.currentUid$ = this.currentUidSubject.asObservable();

  }

  public get currentUid(): string {

    return this.currentUidSubject.value;
  }

  public set currentUid(value: string) {

    if (!isCaseInsensitiveEqual(this.currentUidSubject.value, value)) {
      this.currentUidSubject.next(value);
    }
  }
}
