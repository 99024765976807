import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { TransactionItemPrintModalComponent } from './transaction-item-print-modal.component';
import { PrintOccurrence } from '../../models';
import { DeviceSetting } from 'downmain-device';

@Injectable()
export class TransactionItemPrintModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open<T>(data: TransactionItemPrintModalData): MatDialogRef<TransactionItemPrintModalComponent, TransactionItemPrintModalResult> {

    return this.dialog.open<TransactionItemPrintModalComponent, TransactionItemPrintModalData, TransactionItemPrintModalResult>(TransactionItemPrintModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface TransactionItemPrintModalData {

  title: string,
  printerDeviceSettings: DeviceSetting[],
  defaultPrintLocationDeviceUid: string
}

export interface TransactionItemPrintModalResult {

  printerDeviceSetting: DeviceSetting,
  printOccurrence: PrintOccurrence
}
