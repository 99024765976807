import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DateModalData } from "./date-modal.provider";

@Component({
  selector: 'app-date-modal',
  templateUrl: './date-modal.component.html',
  styleUrls: ['./date-modal.component.scss']
})
export class DateModalComponent {

  constructor(
    private dialogRef: MatDialogRef<DateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DateModalData
  ) {

  }

  public calendarDateSelected(value: { month: number, day: number, year: number }) {

    var selectedDate = new Date(value.year, value.month - 1, value.day)
    this.dialogRef.close(selectedDate);
  }

  public cancel() {

    this.dialogRef.close();
  }
}
