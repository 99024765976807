import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';

import { StoreIntegrationService } from "./services/store-integration.service";
import { CoreModule } from "core";
import { PosCoreModule } from "pos-core";
import { DowntownProductModule } from "downtown-product";
import { DowntownTransactionModule } from "downtown-transaction";

import { StoreCoreModule } from "store-core";

import { BackOfficeStoreOrderComponent } from './components/back-office/store/order/back-office-store-order.component';
import { BackOfficeStoreOrderViewerComponent } from './components/order-viewer/back-office-store-order-viewer.component';
import { StoreHostComponent } from './components/store-host/store-host.component';
import { SitePublicStoreComponent } from "./components/store/site-public-store.component";

@NgModule({
  declarations: [
    ...PosStoreIntegrationModule.components
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatTableModule,
    MatToolbarModule,
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,

    // PosStoreIntegrationRoutingModule,

    CoreModule,
    PosCoreModule,
    DowntownProductModule,
    DowntownTransactionModule,
    StoreCoreModule
  ],
  providers: [
    StoreIntegrationService
  ],
  exports: [
  ]
})
export class PosStoreIntegrationModule {

  static components = [
    StoreHostComponent,
    BackOfficeStoreOrderComponent,
    SitePublicStoreComponent,

    BackOfficeStoreOrderViewerComponent
  ];

  static forRoot(): ModuleWithProviders<PosStoreIntegrationModule> {
    return {
      ngModule: PosStoreIntegrationModule,
      providers: [
      ]
    }
  };
}
