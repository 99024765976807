<div [formGroup]="form" style="display: flex; margin-left: 10px;">

  <div style="flex: 1;">
    <div style="margin-bottom: 10px;">
      <h2>Options</h2>
    </div>

    <div style="display: flex;">
      <button mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-right: 5px;" (click)="newOption()">New</button>
      <button *ngIf="scope == 'portionInclusionGroup' && getProductInclusionGroupForm(form)" [disabled]="!canLinkProductOptions" mat-button mat-button-stroked style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-left: 5px;" (click)="linkOption()">Link Product Inclusion Group Option</button>
    </div>

    <mat-selection-list cdkDropList class="draggable-list" [multiple]="false" (cdkDropListDropped)="dropOption($event)">

      <ng-container *ngIf="form?.get('options') != null">
        <mat-list-option cdkDrag *ngFor="let optionForm of $any(form.get('options'))?.controls" [value]="optionForm" class="item">
          <div style="display: flex; align-items: center;">
            <div style="width: 25px; margin-right: 5px;">
              <mat-icon>drag_indicator</mat-icon>
            </div>
            <div style="flex: 1;" (click)="editOption(optionForm)">

              <ng-container *ngIf="getResolvedInclusionGroupOptionFormValue(optionForm.get('uid').value, 'alias'); else noOptionAlias;">
                <span>{{ getResolvedInclusionGroupOptionFormValue(optionForm.get('uid').value, 'alias') }}</span>
              </ng-container>

              <ng-template #noOptionAlias>
                <app-product-portion-label [productUid]="getResolvedProductReference('inclusionGroupOption', optionForm.get('uid').value)?.uid" [portionUid]="getResolvedProductReference('inclusionGroupOption', optionForm.get('uid').value)?.portionUid" [version]="getResolvedProductReference('inclusionGroupOption', optionForm.get('uid').value)?.version"></app-product-portion-label>
              </ng-template>

              <mat-icon *ngIf="scope == 'portionInclusionGroup' && getProductInclusionGroupOptionForm(optionForm)">link</mat-icon>
            </div>
            <mat-icon (click)="deleteOption(optionForm)">delete</mat-icon>
          </div>
        </mat-list-option>
      </ng-container>

    </mat-selection-list>

  </div>

</div>
