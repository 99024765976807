import { Injectable } from "@angular/core";
import { HttpService } from "core";
import { OltpServiceSettings } from "../oltp-service-settings";
import { Observable } from 'rxjs';

@Injectable()
export class ShippingService {

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByCartUid(cartUid: string): Observable<number> {

    var request = {
      query: `query { getByCartUid (cartUid: \"${cartUid}") }`
    }

    return this.httpService.graph<number>(this.oltpServiceSettings.apiUrl, 'api/oltp/shipping', request, 'getByCartUid');
  }
}
