import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SpinnerModalProvider } from "core";
import { NavigationProvider } from 'core';
import { Employee } from '../../../models/employee';
import { AuthEmployeeProvider } from '../../../providers/auth-employee.provider';
import { EmployeeService } from '../../../services/employee.service';

export class EmployeeProfileForm {

  public employeeUid: string;
  public form: UntypedFormGroup;
  public canSave = false;
  public canPrint = false;

  constructor(
    protected navigationProvider: NavigationProvider,
    protected spinnerModalProvider: SpinnerModalProvider,
    protected authEmployeeProvider: AuthEmployeeProvider,
    protected employeeService: EmployeeService
  ) {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, [Validators.required]),
      lastName: new UntypedFormControl(null),
      displayName: new UntypedFormControl(null),
      pin: new UntypedFormControl({ value: null, disabled: false }, [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern("^[0-9]*$")]),
      mobilePhone: new UntypedFormControl(null),
      email: new UntypedFormControl(null, { validators: Validators.email }),
      paymentMethodUid: new UntypedFormControl(null, [Validators.required])
    });

    this.form.valueChanges.subscribe(x => this.updateEnablement());
  }

  public loadForm(employee: Employee) {

    if (employee) {
      this.employeeUid = employee.uid;

      this.form.get('firstName').setValue(employee.firstName);
      this.form.get('lastName').setValue(employee.lastName);
      this.form.get('displayName').setValue(employee.displayName);
      this.form.get('pin').setValue(employee.pin);
      this.form.get('mobilePhone').setValue(employee.mobilePhone);
      this.form.get('email').setValue(employee.email);
      this.form.get('paymentMethodUid').setValue(employee.paymentMethodUid.toUpperCase());
    }

    this.form.markAsPristine();
    this.updateEnablement();
  }

  public getRandomPin() {

    let spinnerModalRef = this.spinnerModalProvider.open();
    spinnerModalRef.afterOpened().subscribe(() => {
      this.employeeService.getRandomPin().subscribe(pin => {
        this.form.get('pin').setValue(pin);
        this.form.get('pin').markAsDirty();

        spinnerModalRef.close();
      });
    });
  }

  public cancel() {
    this.navigationProvider.backOneWaypoint();
  }

  protected updateEnablement() {

    this.canSave = this.form.dirty;
    this.canPrint = !this.form.dirty && this.form.valid;
  }
}
