export class Lock {

  uid: string;
  name: string;
  isLocalDevice: boolean;
  isLocalUser: boolean;

  get isRemoteDevice(): boolean {
    return !this.isLocalDevice;
  }

  get isRemoteUser(): boolean {
    return !this.isLocalUser;
  }

  getDisplay(): string {
    return this.name != null && this.name != '' ? this.name : this.uid;
  }
}
