import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpService } from "core";
import { DeviceInfo } from '../models/device-info';
import { DeviceHubSettings } from "../models";

@Injectable()
export class DeviceHubService {

  constructor(
    private httpService: HttpService,
    private deviceHubSettings: DeviceHubSettings,
  ) {
  }

  getHubDevices(): Observable<DeviceInfo[]> {

    var request = {
      query: 'query { getDevices { name type platform } }'
    }

    return this.httpService.graph<DeviceInfo[]>(this.deviceHubSettings.apiUrl, 'deviceinfo', request, 'getDevices').pipe(catchError(x => of(null)));
  }
}
