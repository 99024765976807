import { Injectable } from '@angular/core';

import { OidcSecurityService } from 'auth';


@Injectable()
export class AuthorizationCanGuard  {

  constructor(
    private oidcSecurityService: OidcSecurityService
  ) { }

  canLoad(): boolean {
    // console.log('AuthorizationGuard, canLoad:' + this.oidcSecurityService.moduleSetup);
    return this.oidcSecurityService.moduleSetup;
  }
}
