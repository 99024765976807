import { Component, Input } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageInfo } from '../../models/page';
import { PaginationInput } from '../../models/pagination-input';
import { Params } from '@angular/router';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {

  @Input() public dataSource: IPagedDataSource;
  @Input() public paginator: Paginator;

  constructor() {

  }

  ngOnInit() {

    let selectedPageSizeOption = this.paginator.pageSizeOptions.find(x => x.display == this.paginator.pageSize.toString());
    if (!selectedPageSizeOption) {
      selectedPageSizeOption = { display: this.paginator.pageSize.toString(), value: this.paginator.pageSize };

      this.paginator.pageSizeOptions.splice(0, 0, selectedPageSizeOption)
    }

    this.paginator.selectedPageSizeOption = selectedPageSizeOption;
  }

  public selectPageSize(pageSizeOption: { display: string, value: number }) {

    this.paginator.selectedPageSizeOption = pageSizeOption;
    this.paginator.pageSize = pageSizeOption.value;
  }
}

export interface IPagedDataSource {

  totalCount$: Observable<number>;
  pageInfo$: Observable<PageInfo>;
}

export class Paginator {

  public pageRequest$: Observable<PaginationInput>;
  public destroyed$ = new Subject();

  public pageSizeOptions: { display: string, value: number }[];
  public selectedPageSizeOption: { display: string, value: number };

  private _pageRequestSubject: BehaviorSubject<PaginationInput>;
  private _dataSource: IPagedDataSource;
  private _totalCount: number;
  private _pageIndex: number;
  private _pageSize: number;
  private _sortField: string;
  private _sortStrategy: string;
  private _maxPageIndex: number = 0;

  constructor(
    dataSource: IPagedDataSource,
    pageIndex: number,
    pageSize: number,
    sortField: string,
    sortStrategy: string
  ) {
    this._dataSource = dataSource;
    this._pageIndex = pageIndex;
    this._pageSize = pageSize;
    this._sortField = sortField;
    this._sortStrategy = sortStrategy;

    this.pageSizeOptions = [
      { display: '25', value: 25 },
      { display: '50', value: 50 },
      { display: '100', value: 100 },
      { display: '250', value: 250 },
      { display: '1000', value: 1000 }
    ];
    this.selectedPageSizeOption = this.pageSizeOptions.find(x => x.display == '50');

    this._pageRequestSubject = new BehaviorSubject<PaginationInput>(this.getPaginationInput());
    this.pageRequest$ = this._pageRequestSubject.asObservable();

    this._dataSource.totalCount$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(totalCount => this._totalCount = totalCount);

    this._dataSource.pageInfo$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(pageInfo => {
      if (pageInfo) {
        this._pageIndex = pageInfo.thisPage;
        this._maxPageIndex = pageInfo.lastPage;
      }
    });
  }

  public destroy() {

    this.destroyed$.complete();
  }

  public get totalCount(): number {

    return this._totalCount;
  }

  public get pageIndex(): number {

    return this._pageIndex;
  }

  public set pageIndex(value: number) {

    this._pageIndex = value;
    this.publishRequest();
  }

  public get pageSize(): number {

    return this._pageSize;
  }

  public set pageSize(value: number) {

    var pageSizeOption = this.pageSizeOptions.find(x => x.value == value);
    if (pageSizeOption) {
      this.selectedPageSizeOption = pageSizeOption;

      this._pageIndex = 0;
      this._pageSize = value;
      this.publishRequest();
    }
  }

  public get maxPageIndex(): number {

    return this._maxPageIndex;
  }

  public pageBackward() {

    this._pageIndex--;
    this.publishRequest();
  }

  public pageForward() {

    this._pageIndex++;
    this.publishRequest();
  }

  private publishRequest() {

    this._pageRequestSubject.next(this.getPaginationInput());
  }

  private getPaginationInput(): PaginationInput {

    return <PaginationInput>{ pageSize: this._pageSize, pageIndex: this._pageIndex, sortField: this._sortField, sortStrategy: this._sortStrategy };
  }
}

export function updatePaginatorFromParams(paginator: Paginator, params: Params) {

  if (params['pageIndex']) {
    var pageIndexParam = +params['pageIndex'];
    if (params['pageIndex'] && pageIndexParam != paginator.pageIndex) {
      paginator.pageIndex = pageIndexParam;
    }
  }

  if (params['pageSize']) {
    var pageSizeParam = +params['pageSize'];
    if (params['pageSize'] && pageSizeParam != paginator.pageSize) {
      paginator.pageSize = pageSizeParam;
    }
  }
}

export function getParamsFromPaginator(paginator: Paginator): Params {

  var params: Params = {};

  if (paginator) {
    params['pageIndex'] = paginator.pageIndex;
    params['pageSize'] = paginator.pageSize;
  }

  return params;
}