import { Injectable } from "@angular/core";
import { Page } from "core";
import { PaginationInput } from "core";
import { HttpService } from "core";
import { OltpServiceSettings } from "../oltp-service-settings";
import { StoreFront } from "../models/store-front";
import { StoreModelFactory } from "../providers/store-model-factory";
import { StoreFrontViewOptions, ViewFactory } from "../view-factory";
import { Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { StoreFrontInput } from '../models/store-front-input';

@Injectable()
export class StoreFrontService {

  public static readonly StoreFrontFullView = <StoreFrontViewOptions>{};

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: StoreFrontViewOptions = StoreFrontService.StoreFrontFullView): Observable<StoreFront> {

    let view = ViewFactory.buildStoreFrontView(viewOptions);

    var request = {
      query: `query { getByUid(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<StoreFront>(this.oltpServiceSettings.apiUrl, 'api/oltp/storefront', request, 'getByUid').pipe(
      map(x => StoreModelFactory.assignStoreFront(x))
    );
  }

  getByOwnerUid(ownerUid: string, viewOptions: StoreFrontViewOptions = StoreFrontService.StoreFrontFullView): Observable<StoreFront[]> {

    let view = ViewFactory.buildStoreFrontView(viewOptions);

    var request = {
      query: `query { getByOwnerUid(ownerUid: "${ownerUid}") ${view} }`
    };

    return this.httpService.graph<StoreFront[]>(this.oltpServiceSettings.apiUrl, 'api/oltp/storefront', request, 'getByOwnerUid').pipe(
      map(x => x.map(y => StoreModelFactory.assignStoreFront(y)))
    );
  }

  search(statusUids: string[], paginationInput: PaginationInput, viewOptions: StoreFrontViewOptions = StoreFrontService.StoreFrontFullView): Observable<Page<StoreFront>> {

    let view = ViewFactory.buildStoreFrontView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<StoreFront>>(this.oltpServiceSettings.apiUrl, 'api/oltp/storefront', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = StoreModelFactory.assignStoreFront(edge.node)))
    );
  }

  create(uid: string, storeFrontUid: string, name: string, viewOptions: StoreFrontViewOptions = StoreFrontService.StoreFrontFullView): Observable<StoreFront> {

    let view = ViewFactory.buildStoreFrontView(viewOptions);

    var storeFrontInput = {
      uid: uid,
      storeFrontUid: storeFrontUid,
      name: name,
    };

    var request = {
      query: `mutation create($storeFront:StoreFrontInput!) { create(storeFront: $storeFront) ${view} }`,
      variables: { storeFront: storeFrontInput }
    };

    return this.httpService.graph<StoreFront>(this.oltpServiceSettings.apiUrl, 'api/oltp/storefront', request, 'create').pipe(
      map(x => StoreModelFactory.assignStoreFront(x))
    );
  }

  update(storeFrontInput: StoreFrontInput, viewOptions: StoreFrontViewOptions = StoreFrontService.StoreFrontFullView): Observable<StoreFront> {

    let view = ViewFactory.buildStoreFrontView(viewOptions);

    var request = {
      query: `mutation update($storeFront:StoreFrontInput!) { update(storeFront: $storeFront) ${view} }`,
      variables: { storeFront: storeFrontInput }
    };

    return this.httpService.graph<StoreFront>(this.oltpServiceSettings.apiUrl, 'api/oltp/storefront', request, 'update').pipe(
      map(x => StoreModelFactory.assignStoreFront(x))
    );
  }

  enable(uid: string, authIdentityUid: string, viewOptions: StoreFrontViewOptions = StoreFrontService.StoreFrontFullView): Observable<StoreFront> {

    let view = ViewFactory.buildStoreFrontView(viewOptions);

    var request = {
      query: `mutation enable { enable(uid: "${uid}", authIdentityUid: "${authIdentityUid}") ${view} }`
    };

    return this.httpService.graph<StoreFront>(this.oltpServiceSettings.apiUrl, 'api/oltp/storefront', request, 'enable').pipe(
      map(x => StoreModelFactory.assignStoreFront(x))
    );
  }

  disable(uid: string, authIdentityUid: string, viewOptions: StoreFrontViewOptions = StoreFrontService.StoreFrontFullView): Observable<StoreFront> {

    let view = ViewFactory.buildStoreFrontView(viewOptions);

    var request = {
      query: `mutation disable { disable(uid: "${uid}", authIdentityUid: "${authIdentityUid}") ${view} }`
    };

    return this.httpService.graph<StoreFront>(this.oltpServiceSettings.apiUrl, 'api/oltp/storefront', request, 'disable').pipe(
      map(x => StoreModelFactory.assignStoreFront(x))
    );
  }

  delete(uid: string, authIdentityUid: string, viewOptions: StoreFrontViewOptions = StoreFrontService.StoreFrontFullView): Observable<StoreFront> {

    let view = ViewFactory.buildStoreFrontView(viewOptions);

    var request = {
      query: `mutation delete { delete(uid: "${uid}", authIdentityUid: "${authIdentityUid}") ${view} }`
    };

    return this.httpService.graph<StoreFront>(this.oltpServiceSettings.apiUrl, 'api/oltp/storefront', request, 'delete').pipe(
      map(x => StoreModelFactory.assignStoreFront(x))
    );
  }
}

