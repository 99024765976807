import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatTableModule } from '@angular/material/table';

import { CoreModule } from 'core';

import { componentTypes } from './components/component-types';
import { serviceTypes } from './services/service-types';
import { pipeTypes } from './pipes/pipe-types';
import { providerTypes } from './providers/provider-types';


@NgModule({
    declarations: [
      ...componentTypes,
      ...pipeTypes,
    ],
    imports: [
      CommonModule,
      FormsModule,
      RouterModule,
      ReactiveFormsModule,
      MatTableModule,
  
      CoreModule
    ],
    exports: [
      ...componentTypes,
      ...pipeTypes,
    ],
    providers: [
      ...providerTypes,
      ...serviceTypes,
    ]
  })
export class DownMainTerminalModule {

}
