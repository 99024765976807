import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditableTransactionGuest, EditableTransactionItemConfiguration } from "../../models/editable-transaction/editable-transaction";
import { ItemConfiguratorModalComponent } from "./item-configurator-modal.component";
import { IItemConfiguratorModalProvider } from './iitem-configurator-modal.provider';

@Injectable()
export class ItemConfiguratorModalProvider implements IItemConfiguratorModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: ItemConfiguratorModalData): MatDialogRef<ItemConfiguratorModalComponent, ItemConfiguratorModalResult> {

    return this.dialog.open<ItemConfiguratorModalComponent, ItemConfiguratorModalData, ItemConfiguratorModalResult>(ItemConfiguratorModalComponent, {
      height: '100vh',
      minWidth: '100vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface ItemConfiguratorModalData {

  ownerUid: string;
  productUid: string;
  productVersion: number;
  itemConfiguration: EditableTransactionItemConfiguration;
  menuPlacementUid: string;
  guests?: EditableTransactionGuest[];
  lastGuestUid?: string;
  isNew: boolean;
  isAddOn?: boolean;
}

export interface ItemConfiguratorModalResult {

  productUid: string;
  productVersion: number;
  configuration: EditableTransactionItemConfiguration;
  eachTotal: number;
  startAnother: boolean;
}
