import { Pipe, PipeTransform } from '@angular/core';
import { LogisticTypeKeys } from '../keys';

@Pipe({
  name: 'logisticType'
})
export class LogisticTypePipe implements PipeTransform {

  private map = new Map<string, string>([
    [LogisticTypeKeys.DineIn.toUpperCase(), 'Dine In'],
    [LogisticTypeKeys.CarryOut.toUpperCase(), 'Carry Out'],
    [LogisticTypeKeys.CurbsidePickup.toUpperCase(), 'Curbside'],
    [LogisticTypeKeys.Delivery.toUpperCase(), 'Delivery'],
  ]);

  constructor(
  ) {
  }

  transform(value: string): string {

    if (typeof (value) === 'string') {
      return value ? this.map.get(value.toLocaleUpperCase()) : null;
    } else {
      return null;
    }
  }
}
