<div style="">
  <div style="display: flex; margin-bottom: 1em;">
    <span style="flex: 1 1 auto;"></span>
  </div>

  <div style="flex: 1; overflow-y: auto;">

    <div *ngFor="let category of categories">
      <div style="text-align: center;">
        <span style="font-size: 1.25rem; line-height: 1.25rem; margin-bottom: .5rem; font-weight: bold;">{{ category.name }}</span>
      </div>

      <div style="display: flex; flex-wrap: wrap;">

        <div *ngFor="let product of category.products" style="flex: 1; min-width: 50%; padding: 6px;" (click)="configureProduct(product)">
          <div style="background-color: rgb(89, 74, 36); border: 1px solid rgb(128, 96, 15); border-radius: 2px; padding: 6px;">

            <div style="font-size: 1rem; font-weight: bold;">{{ product.title }}</div>
            <div style="font-size: .875rem;">{{ product.subtitle }}</div>

          </div>
        </div>
      </div>
    </div>

  </div>

</div>
