import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';

import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';

import { IdentityUserResolver } from './resolvers/identity-user-resolver';
import { LockResolver } from './resolvers/lock-resolver';

import { componentTypes } from './components/component-types';
import { directiveTypes } from './directives/directive-types';
import { modalProviderTypes, modalComponentTypes } from './modals/modal-types';
import { pipeTypes } from './pipes/pipe-types';
import { providerTypes } from './providers/provider-types';
import { serviceTypes } from './services/service-types';

@NgModule({
  declarations: [
    ...componentTypes,
    ...directiveTypes,
    ...modalComponentTypes,
    ...pipeTypes
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,

    MatMenuModule,
    MatIconModule,
    MatGridListModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatCheckboxModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    ...componentTypes,
    ...directiveTypes,
    ...pipeTypes,
  ],
  providers: [
    IdentityUserResolver,
    LockResolver,

    HttpErrorInterceptor,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },

    ...directiveTypes,
    ...modalProviderTypes,
    ...providerTypes,
    ...serviceTypes
  ]
})
export class CoreModule { }
