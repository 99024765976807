import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PaymentModalData, PaymentModalResult } from './payment-modal.provider';
import { isEqualUUID, PaymentMethod } from 'core';
import Decimal from 'decimal.js';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent {

  public amountText = '';
  public paymentMethod: PaymentMethod;

  constructor(
    private dialogRef: MatDialogRef<PaymentModalComponent, PaymentModalResult>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentModalData
  ) {
    this.amountText = (data.amount || 0).toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 });
    this.paymentMethod = data.paymentMethods.find(x => isEqualUUID(x.uid, data.paymentMethodUid));
  }

  setAmount(amount: number) {

    this.amountText = amount.toString();
  }

  keyPressed(value: string) {

    let keyValue = value.toLowerCase();
    let amountValue = this.amountText;

    if (keyValue == "00") {
      this.keyPressed("0");
      this.keyPressed("0");
    } else if (keyValue == ".") {
      let decimalIndex = amountValue.indexOf('.');

      let wholeDigits = amountValue.substr(0, decimalIndex + 2).replace('.', '');
      let decimalDigits = amountValue.substr(decimalIndex + 2);

      this.amountText = parseInt(wholeDigits + decimalDigits).toString() + ".";
    } else if (value == "Enter") {
      this.update();
    } else if (value == "Back") {
      let value = new Decimal(amountValue);
      if (value.greaterThanOrEqualTo(0)) {
        // Shift all digits right
        value = value.times(10).floor().dividedBy(100);

        if (value.equals(0)) {
          this.amountText = new Decimal(0).toFixed(2);

          this.keyPressed('clear');
        } else {
          this.amountText = value.toNumber().toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
      }
    } else if (value == "Clear") {
      this.amountText = new Decimal(0).toFixed(2);
    } else if (value == "Cancel") {
      this.cancel();
    } else {
      let digit = new Decimal(value);
      if (!digit.isNaN()) {
        let valueText = amountValue.concat(keyValue);

        let value = new Decimal(valueText);
        if (!value.isNaN()) {
          let decimalIndex = valueText.indexOf('.');
          if (decimalIndex > 0 && valueText.length - decimalIndex > 3) {
            // Need to shift the decimal point
            valueText = (new Decimal(valueText.slice(0, decimalIndex + 2).replace('.', '')).toString()) + '.' + (valueText.slice(decimalIndex + 2));
          }

          this.amountText = valueText;
        }
      }
    }
  }

  update() {

    this.dialogRef.close({
      amount: new Decimal(this.amountText).toNumber(),
      paymentMethod: this.paymentMethod
    });
  }

  cancel() {
    
    this.dialogRef.close(null);
  }
}
