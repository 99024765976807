import { Product, ProductConfigurationPreparation, ProductConfigurationVariation, ProductProvider } from "downtown-product";
import { CacheService } from "core";
import { EditableTransactionItemConfiguration, EditableTransactionItemConfigurationAddOn, EditableTransactionItemConfigurationInclusionGroupOption, EditableTransactionItemConfigurationPortion } from "../../models/editable-transaction/editable-transaction";
import { findInArrayByUid, someInArrayByUid } from "core";

export class ItemConfigurationDetailContext {

  public selection: EditableTransactionItemConfigurationPortion | EditableTransactionItemConfigurationInclusionGroupOption | EditableTransactionItemConfigurationAddOn;

  public localCache: CacheService;

  constructor(
    public ownerUid: string,
    public productProvider: ProductProvider,
    public configurableProduct: Product,
    public itemConfiguration: EditableTransactionItemConfiguration,
    public readOnly: boolean
  ) {
    this.localCache = new CacheService();
  }

  public select(selection: EditableTransactionItemConfigurationPortion | EditableTransactionItemConfigurationInclusionGroupOption | EditableTransactionItemConfigurationAddOn) {

    if (!this.readOnly) {
      if (this.selection == selection || selection == null) {
        this.selection = null;
      } else {
        if (selection instanceof EditableTransactionItemConfigurationPortion) {
          var preparations = this.getPortionPreparations(selection.portionUid);
          var variations = this.getPortionVariations(selection.portionUid);

          this.selection = preparations.length > 0 || variations.length > 0 ? selection : null;
        } else if (selection instanceof EditableTransactionItemConfigurationInclusionGroupOption) {
          var preparations = this.getInclusionGroupOptionPreparations(selection.optionUid);
          var variations = this.getInclusionGroupOptionVariations(selection.optionUid);

          this.selection = preparations.length > 0 || variations.length > 0 ? selection : null;
        } else if (selection instanceof EditableTransactionItemConfigurationAddOn) {
          this.selection = selection;
        }
      }
    }
  }

  public getPortionPreparations(portionUid: string): ProductConfigurationPreparation[] {

    return this.localCache.getOrAdd(`${portionUid}_preparations`, () => {
      let portion = this.configurableProduct.configuration.getPortion(portionUid);

      return portion && portion.preparations ? portion.preparations || [] : [];
    });
  }

  public getPortionVariations(portionUid: string): ProductConfigurationVariation[] {

    let portion = this.configurableProduct.configuration.getPortion(portionUid);

    return portion && portion.variations ? portion.variations || [] : [];
  }

  public getInclusionGroupOptionPreparations(inclusionGroupOptionUid: string): ProductConfigurationPreparation[] {

    let inclusionGroup = this.configurableProduct.configuration.getPortion(this.itemConfiguration.portion.portionUid).inclusionGroups
      .find(x => someInArrayByUid(x.options, inclusionGroupOptionUid));

    let inclusionGroupOption = findInArrayByUid(inclusionGroup?.options, inclusionGroupOptionUid);

    return inclusionGroupOption?.preparations || [];
  }

  public getInclusionGroupOptionVariations(inclusionGroupOptionUid: string): ProductConfigurationVariation[] {

    let inclusionGroup = this.configurableProduct.configuration.getPortion(this.itemConfiguration.portion.portionUid).inclusionGroups
      .find(x => someInArrayByUid(x.options, inclusionGroupOptionUid));

    let inclusionGroupOption = findInArrayByUid(inclusionGroup?.options, inclusionGroupOptionUid);

    return inclusionGroupOption?.variations || [];
  }
}
