import { Injectable } from "@angular/core";
import { HttpService, SettingProvider } from "core";
import { Observable, concatMap } from "rxjs";
import { TerminalSettings } from "../models";
import { TerminalProvider } from "../providers";

@Injectable()
export class RemoteLoggingService {

  constructor(
    private httpService: HttpService,
    private terminalProvider: TerminalProvider,
    private settingProvider: SettingProvider
  ) {
  }

  publish(entries: string[]): Observable<void> {

    return this.settingProvider.getOneByTypeAndOwner$<TerminalSettings>('TerminalSettings', this.terminalProvider.active.uid).pipe(
      concatMap(settingProvider => {
        return this.httpService.post(settingProvider.remoteLoggingHostUrl, 'api/logstorage', entries);
      })
    );
  }
}

//  'https://localhost/downtown.pos.api/'