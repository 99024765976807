import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DecimalModalProvider, IDecimalModalData } from "core";
import { StoreDecimalModalComponent } from "../../../providers/modals/store-decimal-modal/store-decimal-modal.component";

@Injectable()
export class StoreDecimalModalProvider extends DecimalModalProvider {

  constructor(
    dialog: MatDialog
  ) {
    super(dialog);
  }

  public open(data: IDecimalModalData): MatDialogRef<StoreDecimalModalComponent, number> {

    return this.dialog.open<StoreDecimalModalComponent, IDecimalModalData, number>(StoreDecimalModalComponent, {
      width: '40vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}
