import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, of, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, map, concatMap, takeUntil } from 'rxjs/operators';
import { ActionBarConfiguration, ActionConfiguration, FilterConfiguration, FilterOptionConfiguration, MessageModalProvider, getParamsFromFilters, getParamsFromPaginator, isCaseInsensitiveEqual, updateFiltersFromParams, updatePaginatorFromParams } from 'core';
import { Paginator } from 'core';
import { ConfirmModalProvider } from 'core';
import { SpinnerModalComponent } from 'core';
import { SpinnerModalProvider } from 'core';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { EmployeeShiftDataSource, EmployeeShiftModel } from 'pos-core';
import { PaymentMethodKeys } from 'core';
import { EmployeeShiftStatusKeys } from 'pos-core';
import { ConversationMessage } from 'pos-core';
import { AuthEmployeeProvider } from 'pos-core';
import { ClockInOutModalData } from 'pos-core';
import { ClockInOutModalProvider } from 'pos-core';
import { EmployeeShiftService, EmployeeShiftViewOptions } from 'pos-core';
import { PositionService } from 'pos-core';
import { ConversationModalData, ConversationModalProvider } from 'projects/pos-site/src/app/modals/conversation-modal/conversation-modal.provider';

@Component({
  selector: 'app-back-office-shifts',
  templateUrl: './back-office-shifts.component.html',
  styleUrls: ['./back-office-shifts.component.scss']
})
export class BackOfficeShiftsComponent implements OnInit {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: EmployeeShiftDataSource;
  public paginator: Paginator;

  public title: string;
  public columns: Array<string>;

  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private authEmployeeProvider: AuthEmployeeProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private messageModalProvider: MessageModalProvider,
    private conversationModalProvider: ConversationModalProvider,
    private clockInOutModalProvider: ClockInOutModalProvider,
    private positionService: PositionService,
    private employeeShiftService: EmployeeShiftService
  ) {
    this.navigationProvider.setWaypoint('Employee Shifts');
    this.title = 'Employee Shifts';

    this.dataSource = new EmployeeShiftDataSource(this.employeeShiftService, this.messageModalProvider, () => new EmployeeShiftModel(), false);
    this.paginator = new Paginator(this.dataSource, 0, 25, 'clockInDateTimeUtc', 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('New', this.new.bind(this)),
        new ActionConfiguration('Edit', () => this.edit(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Submit', () => this.submit(this.dataSource.selection.selected), () => this.dataSource.selection.selected.some(x => x.canSubmit)),
        new ActionConfiguration('Accept', () => this.accept(this.dataSource.selection.selected), () => this.dataSource.selection.selected.some(x => x.canAccept)),
        new ActionConfiguration('Reject', () => this.reject(this.dataSource.selection.selected), () => this.dataSource.selection.selected.some(x => x.canReject)),
        new ActionConfiguration('Delete', () => this.delete(this.dataSource.selection.selected), () => this.dataSource.selection.selected.some(x => x.canDelete))
      ],
      [
        new FilterConfiguration('Position', [new FilterOptionConfiguration('All', null)], (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }),
        new FilterConfiguration('Days Of The Week',
          [
            new FilterOptionConfiguration('All', null),
            new FilterOptionConfiguration('Sunday', [1]),
            new FilterOptionConfiguration('Monday', [2]),
            new FilterOptionConfiguration('Tuesday', [3]),
            new FilterOptionConfiguration('Wednesday', [4]),
            new FilterOptionConfiguration('Thursday', [5]),
            new FilterOptionConfiguration('Friday', [6]),
            new FilterOptionConfiguration('Saturday', [7]),
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }),
        new FilterConfiguration('Payment Method',
          [
            new FilterOptionConfiguration('All', null),
            new FilterOptionConfiguration('Cash', [PaymentMethodKeys.Cash]),
            new FilterOptionConfiguration('Check', [PaymentMethodKeys.Check])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }
        ),
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('All', [EmployeeShiftStatusKeys.ClockedIn, EmployeeShiftStatusKeys.ClockedOut, EmployeeShiftStatusKeys.PaymentMade, EmployeeShiftStatusKeys.PaymentScheduled, EmployeeShiftStatusKeys.PaymentCancelled, EmployeeShiftStatusKeys.Submitted, EmployeeShiftStatusKeys.Rejected, EmployeeShiftStatusKeys.Accepted]),
            new FilterOptionConfiguration('Unpaid', [EmployeeShiftStatusKeys.ClockedIn, EmployeeShiftStatusKeys.ClockedOut, EmployeeShiftStatusKeys.PaymentScheduled, EmployeeShiftStatusKeys.Accepted, EmployeeShiftStatusKeys.Submitted, EmployeeShiftStatusKeys.Rejected]),
            new FilterOptionConfiguration('Open', [EmployeeShiftStatusKeys.ClockedIn, EmployeeShiftStatusKeys.Rejected]),
            new FilterOptionConfiguration('Submitted', [EmployeeShiftStatusKeys.Accepted, EmployeeShiftStatusKeys.Submitted, EmployeeShiftStatusKeys.Rejected]),
            new FilterOptionConfiguration('Paid', [EmployeeShiftStatusKeys.PaymentMade])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }
        )
      ]
    );

    // Build initial columns
    this.columns = [ /*'select',*/ 'employee', 'position', 'clockedIn', 'clockedOut', 'hours', 'transactionsValue', 'status'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    let spinnerModalRef = this.spinnerModalProvider.open();
    spinnerModalRef.afterOpened().subscribe(() => {
      this.positionService.search(null, null).subscribe(positionsPage => {
        const positionFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => isCaseInsensitiveEqual(x.title, 'Position'));
        const daysOfTheWeekFilter = <FilterConfiguration<number[]>>this.actionBarConfiguration.filters.find(x => isCaseInsensitiveEqual(x.title, 'Days Of The Week'));
        const paymentMethodFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => isCaseInsensitiveEqual(x.title, 'Payment Method'));
        const statusFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => isCaseInsensitiveEqual(x.title, 'Status'));
        const paginator = this.paginator;

        positionFilter.options.push(...positionsPage.edges.map(x => new FilterOptionConfiguration(x.node.name, [x.node.uid])));

        this.activatedRoute.queryParams.pipe(
          takeUntil(this.destroyed$)
        ).subscribe(params => {
          updateFiltersFromParams([positionFilter, daysOfTheWeekFilter, paymentMethodFilter, statusFilter], params);
          updatePaginatorFromParams(paginator, params);
        });

        combineLatest([
          positionFilter.selected,
          daysOfTheWeekFilter.selected,
          paymentMethodFilter.selected,
          statusFilter.selected,
          paginator.pageRequest$
        ]).pipe(takeUntil(this.destroyed$),
          map(([positionFilterOption, daysOfTheWeekFilterOption, paymentMethodFilterOption, statusFilterOption, paginationInput]) => {
            let positionFilterUids = positionFilterOption.value;
            let daysOfTheWeekFilterUids = daysOfTheWeekFilterOption.value;
            let paymentMethodFilterUids = paymentMethodFilterOption.value;
            let statusFilterUids = statusFilterOption.value;

            const viewOptions = <EmployeeShiftViewOptions>{ includeEmployee: true, includePosition: true, includeAnalytics: { includeTransactions: true } };

            const params = Object.assign(getParamsFromFilters([positionFilter, daysOfTheWeekFilter, paymentMethodFilter, statusFilter]), getParamsFromPaginator(paginator));
            this.navigationProvider.navigate([], { queryParams: params, queryParamsHandling: 'merge' }).then(_ => this.navigationProvider.updateLastWaypoint());

            this.dataSource.loadData(null, positionFilterUids, paymentMethodFilterUids, null, daysOfTheWeekFilterUids, statusFilterUids, paginationInput, viewOptions);
          })
        ).subscribe();

        spinnerModalRef.close();
      },
        err => {
          console.log(err);
        }
      );
    });
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }

  public new() {

    this.clockInOutModalProvider.open(<ClockInOutModalData>{
      loadExisting: false,
      canSelectEmployee: true,
      employeeUid: this.activatedRoute.snapshot.params['employeeUid']
    }).afterClosed().subscribe(employeeShift => {
      this.paginator.pageIndex = this.paginator.pageIndex;
    });
  }

  public edit(row: EmployeeShiftModel) {

    this.clockInOutModalProvider.open(<ClockInOutModalData>{
      loadExisting: false,
      canSelectEmployee: true,
      employeeSelectMode: 'name',
      employeeUid: row.item.employeeUid,
      employeeShiftUid: row.item.uid
    }).afterClosed().subscribe(employeeShift => {
      if (employeeShift) {
        this.dataSource.merge(employeeShift);
      }
    });
  }

  public submit(employeeShifts: EmployeeShiftModel[]) {

    let confirmDialogRef = this.confirmModalProvider.open('Submit Shifts', `Are you certain you want to submit ${employeeShifts.length} selected shifts?`);
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Submitting for approval...');

        combineLatest([
          this.authEmployeeProvider.authEmployee$,
          waitDialogRef.afterOpened()
        ]).pipe(
          concatMap(([authEmployee]) => this.dataSource.submit(employeeShifts)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public accept(employeeShifts: EmployeeShiftModel[]) {

    let confirmDialogRef = this.confirmModalProvider.open('Accept Shifts', `Are you certain you want to accept ${employeeShifts.length} selected shifts?`);
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Accepting...');

        combineLatest([
          this.authEmployeeProvider.authEmployee$,
          waitDialogRef.afterOpened()
        ]).pipe(
          concatMap(([authEmployee]) => this.dataSource.accept(employeeShifts)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public reject(employeeShifts: EmployeeShiftModel[]) {

    let confirmDialogRef = this.confirmModalProvider.open('Accept Shifts', `Are you certain you want to accept ${employeeShifts.length} selected shifts?`);
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Rejecting...');

        combineLatest([
          this.authEmployeeProvider.authEmployee$,
          waitDialogRef.afterOpened()
        ]).pipe(
          concatMap(([authEmployee]) => this.dataSource.reject(employeeShifts)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public delete(employeeShifts: EmployeeShiftModel[]) {

    let confirmDialogRef = this.confirmModalProvider.open('Delete Pay Period', 'Are you certain you want to delete this pay period?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Deleting...');

        combineLatest([
          this.authEmployeeProvider.authEmployee$,
          waitDialogRef.afterOpened()
        ]).pipe(
          concatMap(([authEmployee]) => this.dataSource.delete(employeeShifts)),
          catchError(() => of(null))
        ).subscribe(() => {
          waitDialogRef.close();
          this.paginator.pageIndex = this.paginator.pageIndex;
        });
      }
    });
  }

  public viewConversations(row: EmployeeShiftModel) {

    this.conversationModalProvider.open(<ConversationModalData>{
      refAggregateUid: row.item.uid
    }).afterClosed().subscribe((message: ConversationMessage) => {
      if (message) {
        this.employeeShiftService.getByUid(row.item.uid).subscribe(update => {
          row.item.conversationCount = update.conversationCount;
        });
      }
    });
  }
}
