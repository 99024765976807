<div [formGroup]="form" style="display: flex; height: 100%;">
  <div style="flex: 1; margin-left: 10px;">

    <app-expansion-container #expansionContainer style="background-color: rgba(255, 255, 255, 0.06)">

      <app-expansion-panel #generalPanel>
        <app-expansion-panel-header (click)="panelClick('general')">
          <app-expansion-panel-title>
            <span style="font-size: 24px;">Inclusion Group Settings</span>
          </app-expansion-panel-title>
        </app-expansion-panel-header>
      </app-expansion-panel>

      <app-expansion-panel #optionsPanel>

        <app-expansion-panel-header (click)="panelClick('options')">
          <app-expansion-panel-title>
            <span style="font-size: 24px;">Options<span *ngIf="context.productForm">&nbsp;({{ getInclusionGroupOptionsFormArray()?.length }})</span></span>
          </app-expansion-panel-title>
        </app-expansion-panel-header>

        <app-expansion-panel-content>

          <div style="padding-left: 40px;">

            <app-expansion-container #optionsContainer>

              <app-expansion-panel *ngFor="let optionForm of getInclusionGroupOptionsFormArray().controls" [value]="optionForm.get('uid').value">
                <app-expansion-panel-header (click)="optionClick(optionForm.get('uid').value)">
                  <app-expansion-panel-title>
                    <span style="font-size: 18px;">

                      <ng-container *ngIf="getResolvedInclusionGroupOptionFormValue(optionForm.get('uid').value, 'alias'); else noOptionAlias;">
                        <span>{{ getResolvedInclusionGroupOptionFormValue(optionForm.get('uid').value, 'alias') }}</span>
                      </ng-container>

                      <ng-template #noOptionAlias>
                        <app-product-portion-label [productUid]="getResolvedProductReference('inclusionGroupOption', optionForm.get('uid').value)?.uid" [portionUid]="getResolvedProductReference('inclusionGroupOption', optionForm.get('uid').value)?.portionUid" [version]="getResolvedProductReference('inclusionGroupOption', optionForm.get('uid').value)?.version"></app-product-portion-label>
                      </ng-template>

                      <mat-icon *ngIf="scope == 'portionInclusionGroup' && getProductInclusionGroupOptionForm($any(optionForm))">link</mat-icon>
                    </span>
                  </app-expansion-panel-title>
                </app-expansion-panel-header>
              </app-expansion-panel>

            </app-expansion-container>

          </div>

        </app-expansion-panel-content>

      </app-expansion-panel>

    </app-expansion-container>

  </div>

  <div style="flex: 1; height: 100%;">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>

</div>
