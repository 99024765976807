import { getDuration, TimeSpan } from "core";
import { Employee } from "./employee";
import { Position } from "./position";

export class EmployeeShift {

  uid: string;
  employeeUid: string;
  positionUid: string;
  clockInDateTimeUtc: Date;
  clockOutDateTimeUtc: Date;
  employeePayUid: string;
  payPeriodUid: string;
  dayOfWeek: number;
  employeeShiftStatusUid: string;

  employee: Employee;
  position: Position;

  transactionsCount: number;
  departmentCounts: { uid: string, count: number }[];
  transactionsValue: number;
  conversationCount: number;

  public get duration(): TimeSpan {

    return getDuration(this.clockInDateTimeUtc, this.clockOutDateTimeUtc);
  }
}

