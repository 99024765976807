import { AccountSetting } from "./models/account-setting";
// import { SystemMessage } from "./models/system-message";
import { ModelFactory } from "core";
import { getLocaleDate } from "core";
import { Conversation, ConversationMessage } from "./models/conversation";
import { Drawer, DrawerCredit, DrawerDebit } from "./models/drawer";
import { Employee } from "./models/employee";
import { EmployeePay } from "./models/employee-pay";
import { EmployeeShift } from "./models/employee-shift";
import { IdentityUser } from "core";
import { Member } from "./models/member";
import { PayPeriod } from "./models/pay-period";
import { PriceAdjustment } from "./models/price-adjustment";
import { Schedule, ScheduleTimeBlock } from "./models/schedule";
import { UserAccount } from "./models/user-account";
import { Position } from "./models/position";
import { TransactionModelFactory } from "downtown-transaction";
import { ReportRequestResponse } from "./models/report-request-response";
import { Terminal, TerminalModelFactory } from "downmain-terminal";

export class PosModelFactory {

  public static assignAccountSetting(input: AccountSetting): AccountSetting {

    if (input) {
      return ModelFactory.assign(input, AccountSetting);
    }

    return null;
  }

  public static assignConversation(input: Conversation): Conversation {

    if (input) {
      let output = ModelFactory.assign(input, Conversation);

      output.messages = input.messages.map(x => PosModelFactory.assignConversationMessage(x));

      return output;
    }

    return null;
  }

  public static assignConversationMessage(input: ConversationMessage): ConversationMessage {

    if (input) {
      let output = ModelFactory.assign(input, ConversationMessage);

      output.dateTimeUtc = input.dateTimeUtc ? new Date(input.dateTimeUtc) : output.dateTimeUtc;
      output.identityUser = input.identityUser ? PosModelFactory.assignIdentityUser(input.identityUser) : output.identityUser;
      output.employee = input.employee ? PosModelFactory.assignEmployee(input.employee) : output.employee;

      return output;
    }

    return null;
  }

  public static assignDrawer(input: Drawer): Drawer {

    if (input) {
      var output = ModelFactory.assign(input, Drawer);

      output.credits = input.credits ? input.credits.map(credit => PosModelFactory.assignDrawerCredit(credit)) : output.credits;
      output.debits = input.debits ? input.debits.map(debit => PosModelFactory.assignDrawerDebit(debit)) : output.debits;
      output.terminal = input.terminal ? TerminalModelFactory.assignTerminal(input.terminal) : output.terminal;
      output.startEmployee = input.startEmployee ? PosModelFactory.assignEmployee(input.startEmployee) : output.startEmployee;
      output.endEmployee = input.endEmployee ? PosModelFactory.assignEmployee(input.endEmployee) : output.endEmployee;

      return output;
    }

    return null;
  }

  public static mergeDrawer(source: Drawer, target: Drawer): Drawer {

    target.terminalUid = source.terminalUid === undefined ? target.terminalUid : source.terminalUid;
    target.startDateTimeUtc = source.startDateTimeUtc === undefined ? target.startDateTimeUtc : source.startDateTimeUtc;
    target.startAuthUid = source.startAuthUid === undefined ? target.startAuthUid : source.startAuthUid;
    target.endDateTimeUtc = source.endDateTimeUtc === undefined ? target.endDateTimeUtc : source.endDateTimeUtc;
    target.endAuthUid = source.endAuthUid === undefined ? target.endAuthUid : source.endAuthUid;
    target.seedAmount = source.seedAmount === undefined ? target.seedAmount : source.seedAmount;
    target.balance = source.balance === undefined ? target.balance : source.balance;
    target.saleSequence = source.saleSequence === undefined ? target.saleSequence : source.saleSequence;
    target.drawerStatusUid = source.drawerStatusUid === undefined ? target.drawerStatusUid : source.drawerStatusUid;
    target.defaultDrawerSeedAmount = source.defaultDrawerSeedAmount === undefined ? target.defaultDrawerSeedAmount : source.defaultDrawerSeedAmount;

    target.credits = source.credits === undefined ? target.credits : source.credits;
    target.debits = source.debits === undefined ? target.debits : source.debits;

    target.terminal = source.terminal === undefined ? target.terminal : source.terminal;
    target.startEmployee = source.startEmployee === undefined ? target.startEmployee : source.startEmployee;
    target.endEmployee = source.endEmployee === undefined ? target.endEmployee : source.endEmployee;

    return target;
  }

  public static assignDrawerCredit(input: DrawerCredit): DrawerCredit {

    if (input) {
      var output = ModelFactory.assign(input, DrawerCredit);

      output.transaction = input.transaction ? TransactionModelFactory.assignTransaction(input.transaction) : output.transaction;

      return output;
    }

    return null;
  }

  public static assignDrawerDebit(input: DrawerDebit): DrawerDebit {

    if (input) {
      var output = ModelFactory.assign(input, DrawerDebit);

      output.transaction = input.transaction ? TransactionModelFactory.assignTransaction(input.transaction) : output.transaction;

      return output;
    }

    return null;
  }

  public static assignEmployee(input: Employee): Employee {

    if (input) {
      return ModelFactory.assign(input, Employee)
    }

    return null;
  }

  public static mergeEmployee(source: Employee, target: Employee): Employee {

    target.firstName = source.firstName === undefined ? target.firstName : source.firstName;
    target.lastName = source.lastName === undefined ? target.lastName : source.lastName;
    target.pin = source.pin === undefined ? target.pin : source.pin;
    target.mobilePhone = source.mobilePhone === undefined ? target.mobilePhone : source.mobilePhone;
    target.email = source.email === undefined ? target.email : source.email;
    target.displayName = source.displayName === undefined ? target.displayName : source.displayName;
    target.identityId = source.identityId === undefined ? target.identityId : source.identityId;
    target.paymentMethodUid = source.paymentMethodUid === undefined ? target.paymentMethodUid : source.paymentMethodUid;
    target.employeeStatusUid = source.employeeStatusUid === undefined ? target.employeeStatusUid : source.employeeStatusUid;
    target.positions = source.positions === undefined ? target.positions : source.positions;
    target.permissions = source.permissions === undefined ? target.permissions : source.permissions;

    return target;
  }

  public static assignEmployeePay(input: EmployeePay): EmployeePay {

    if (input) {
      var output = ModelFactory.assign(input, EmployeePay)
      output.employee = input.employee ? PosModelFactory.assignEmployee(input.employee) : output.employee;
      output.position = input.position ? PosModelFactory.assignPosition(input.position) : output.position;

      return output;
    }

    return null;
  }

  public static assignEmployeeShift(input: EmployeeShift): EmployeeShift {

    if (input) {
      let output = ModelFactory.assign(input, EmployeeShift);
      output.clockInDateTimeUtc = new Date(input.clockInDateTimeUtc);
      output.clockOutDateTimeUtc = input.clockOutDateTimeUtc ? new Date(input.clockOutDateTimeUtc) : output.clockOutDateTimeUtc;
      output.employee = input.employee ? ModelFactory.assign(input.employee, Employee) : output.employee;

      return output;
    }

    return null;
  }

  public static assignIdentityUser(input: IdentityUser): IdentityUser {

    if (input) {
      return ModelFactory.assign(input, IdentityUser);
    }

    return null;
  }

  public static assignMember(input: Member): Member {

    if (input) {
      let output = ModelFactory.assign(input, Member);
      output.identityUser = input.identityUser ? PosModelFactory.assignIdentityUser(input.identityUser) : output.identityUser;
      output.employee = input.employee ? PosModelFactory.assignEmployee(input.employee) : output.employee;

      return output;
    }

    return null;
  }

  public static mergeMember(source: Member, target: Member): Member {

    target.identityId = source.identityId === undefined ? target.identityId : source.identityId;
    target.firstName = source.firstName === undefined ? target.firstName : source.firstName;
    target.lastName = source.lastName === undefined ? target.lastName : source.lastName;
    target.email = source.email === undefined ? target.email : source.email;
    target.displayName = source.displayName === undefined ? target.displayName : source.displayName;
    target.memberStatusUid = source.memberStatusUid === undefined ? target.memberStatusUid : source.memberStatusUid;

    target.isEmployee = source.isEmployee === undefined ? target.isEmployee : source.isEmployee;
    target.identityUser = source.identityUser === undefined ? target.identityUser : source.identityUser;
    target.employee = source.employee === undefined ? target.employee : source.employee;

    return target;
  }

  public static assignPayPeriod(input: PayPeriod): PayPeriod {

    if (input) {
      let output = ModelFactory.assign(input, PayPeriod);
      output.startDateTimeUtc = new Date(input.startDateTimeUtc);
      output.endDateTimeUtc = input.endDateTimeUtc ? new Date(input.endDateTimeUtc) : output.endDateTimeUtc;

      return output;
    }

    return null;
  }

  public static assignPosition(input: Position): Position {

    if (input) {
      return ModelFactory.assign(input, Position);
    }

    return null;
  }

  public static assignPriceAdjustment(input: PriceAdjustment): PriceAdjustment {

    if (input) {
      return ModelFactory.assign(input, PriceAdjustment);
    }

    return null;
  }

  public static assignReportRequestResponse(input: ReportRequestResponse): ReportRequestResponse {

    if (input) {
      return ModelFactory.assign(input, ReportRequestResponse);
    }

    return null;
  }

  public static assignSchedule(input: Schedule): Schedule {

    if (input) {
      let output = ModelFactory.assign(input, Schedule);

      output.includeTimeBlocks = input.includeTimeBlocks ? input.includeTimeBlocks.map(x => PosModelFactory.assignScheduleTimeBlock(x)) : output.includeTimeBlocks;

      return output;
    }

    return null;
  }

  public static assignScheduleTimeBlock(input: ScheduleTimeBlock): ScheduleTimeBlock {

    if (input) {
      var output = ModelFactory.assign(input, ScheduleTimeBlock)
      // Parse out date part only as local
      output.startDate = input.startDate ? getLocaleDate(input.startDate) : output.startDate;
      output.endDate = input.endDate ? getLocaleDate(input.endDate) : output.endDate;

      return output;
    }

    return null;
  }

  // public static assignSystemMessage(input: SystemMessage): SystemMessage {

  //   if (input) {
  //     let output = ModelFactory.assign(input, SystemMessage);

  //     output.publishedDateTimeUtc = input.publishedDateTimeUtc;
  //     output.expiresDateTimeUtc = input.expiresDateTimeUtc;

  //     return output;
  //   }

  //   return null;
  // }

  public static assignUserAccount(input: UserAccount): UserAccount {

    if (input) {
      return ModelFactory.assign(input, UserAccount);
    }

    return null;
  }
}
