import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MessageModalData } from "./message-modal.provider";
import { CurrentDateTimeProvider, TimeoutProvider } from "../../providers";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent {

  public timeoutPercent: number;

  private timeoutProvider: TimeoutProvider;
  private destroyed$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<MessageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MessageModalData,
    private currentDateTimeProvider: CurrentDateTimeProvider
  ) {

  }

  public ngOnInit() {

    if (this.data.autoCloseTimeout) {
      this.timeoutProvider = new TimeoutProvider(this.currentDateTimeProvider, this.destroyed$, this.data.autoCloseTimeout);
      this.timeoutProvider.percent$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(value => this.timeoutPercent = value);
      this.timeoutProvider.inactive$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(_ => this.close());
    } else {
      this.timeoutPercent = 0;
    }
  }

  public ngOnDestroy() {

    this.destroyed$.next(null);
  }

  public close() {

    this.dialogRef.close();
  }
}
