<div style="height: 100%; width: 100%; display: flex; flex-direction: column;">
  <div class="mat-headline-2" style="flex: 1 0 0; overflow: hidden; text-align: center; margin: 20px;">
    <span>{{ selectedDate | date: 'M-dd-yyyy' }}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{ selectedDate | date: 'h:mm a' }}</span>
  </div>

  <div style="flex: 8 0 0; display: flex;">
    <div style="flex: 1; position: relative; margin: 20px;">
      <app-date-picker [initialDate]="data.initialDate" [minimumDateTime]="data.minimumDateTime" [maximumDateTime]="data.maximumDateTime" [selectionMode]="data.selectionMode" (dateSelected)="dateSelected($event)"></app-date-picker>
    </div>
    <div style="flex: 1; position: relative; margin: 20px;">
      <app-clock-picker [initialDate]="data.initialDate" (timeSelected)="timeSelected($event)"></app-clock-picker>
    </div>
  </div>

  <div style="flex: 1 0 0; overflow: hidden;">
    <div style="display: flex; margin-left: 20px; margin-right: 20px;">
      <span style="flex: 3 1 auto;"></span>
      <app-pos-button color="warn" (click)="cancel()">Cancel</app-pos-button>
      <app-pos-button color="primary" (click)="commit()">OK</app-pos-button>
    </div>
  </div>
</div>
