<div style="display: flex; height: 100%;">

  <div style="height: 100%; width: 16.6666%; padding-right: 10px; padding-top: var(--app-register-button-height); overflow-y: auto; overflow-x: hidden;">

    <app-pos-button [disabled]="true" style="width: 100%" (click)="newGuest()">
      <mat-icon style="font-size: 21px;">person</mat-icon>&nbsp;&nbsp;Guest One
    </app-pos-button>

    <app-pos-button [disabled]="true" style="width: 100%" (click)="newGuest()">
      <mat-icon style="font-size: 21px;">person_add</mat-icon>&nbsp;&nbsp;Add Guest
    </app-pos-button>

    <!-- <app-pos-button style="width: 100%" (click)="selectGuest()">
      <span *ngIf="!selectedGuest">
        <mat-icon style="font-size: 21px;">persons</mat-icon>&nbsp;&nbsp;
      </span>
      <span>{{ selectedGuest ? this.selectedGuest.name : 'Select Guest...' }}</span>
    </app-pos-button> -->

  </div>

  <div style="height: 100%; width: 33.3332%; padding-right: 10px; overflow-y: hidden; overflow-x: hidden;">

    <div style="height: 100%; background-color: white;"></div>
  </div>

  <div style="height: 100%; flex: 1; display: flex; flex-direction: column;">

    <div style="flex: 1; overflow-y: auto;">

    </div>

    <div style="display: flex;">
      <span style="flex: 1 1 auto;"></span>
      <app-pos-button (click)="cancel()" [styles]="{ backgroundColor: 'red' }">Cancel</app-pos-button>
    </div>

  </div>

</div>