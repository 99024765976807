<div style="max-width: 1024px; margin-left: auto; margin-right: auto; height: 100%; display: flex; padding-top: 10px;">
  <div *ngIf="order" style="width: 100%;">
    <div style="background-color: black; padding: 10px; border-radius: 4px; margin-bottom: 10px;">
      <div>
        Order Number: {{ order.number }}
      </div>
      <div>
        Order Date: {{ order.openDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}
      </div>
    </div>

    <div style="display: flex; background-color: black; padding: 10px; border-radius: 4px; margin-bottom: 10px;">
      <div style="flex: 1;">
        <div>Shipping Address</div>
        <ng-container *ngIf="order.shippingAddress">
          <!-- <div>{{ order.shippingAddress.name }}</div> -->
          <div>{{ order.shippingAddress.address1 }}</div>
          <div>{{ order.shippingAddress.city }}</div>
          <div>{{ order.shippingAddress.state }}</div>
          <div>{{ order.shippingAddress.postalCode }}</div>
          <div>{{ order.shippingAddress.country }}</div>
          <!-- <div>{{ order.shippingAddress.phone }}</div> -->
        </ng-container>
      </div>
      <div style="flex: 1;">
        <div>Billing Address</div>
        <ng-container *ngIf="order.billingAddress">
          <!-- <div>{{ order.billingAddress.name }}</div> -->
          <div>{{ order.billingAddress.address1 }}</div>
          <div>{{ order.billingAddress.city }}</div>
          <div>{{ order.billingAddress.state }}</div>
          <div>{{ order.billingAddress.postalCode }}</div>
          <div>{{ order.billingAddress.country }}</div>
          <!-- <div>{{ order.billingAddress.phone }}</div> -->
        </ng-container>
      </div>
    </div>

    <div style="background-color: black; padding: 10px; border-radius: 4px; margin-bottom: 10px;">

      <div style="display: flex; border-bottom: 1px solid white;">
        <span style="flex: 1;">Qty</span>
        <span style="flex: 1;">SKU</span>
        <span style="flex: 4;">Description</span>
        <span style="flex: 1; text-align: right;">Each</span>
        <span style="flex: 1; text-align: right;">Total</span>
      </div>
      <div *ngFor="let orderItem of order.items" style="display: flex; margin-bottom: 5px;">
        <div style="flex: 1;">
          {{ orderItem.orderQuantity }}
        </div>
        <div style="flex: 1;">
          {{ orderItem.product.sku }}
        </div>
        <div style="flex: 4;">
          {{ orderItem.product.name }}
        </div>
        <div style="flex: 1; text-align: right;">
          {{ orderItem.eachAmount | currency:'USD':'symbol':'1.2-2' }}
        </div>
        <div style="flex: 1; text-align: right;">
          {{ orderItem.totalAmount | currency:'USD':'symbol':'1.2-2' }}
        </div>
      </div>
    </div>

    <div style="display: flex;">
      <div style="flex: 4; background-color: black; padding: 10px; border-radius: 4px; margin-bottom: 10px;">
        <div>Item Count: {{ order.orderQuantity }}</div>
        <div>Comments: None</div>
      </div>

      <div style="flex: 2; background-color: black; padding: 10px; border-radius: 4px; margin-bottom: 10px;">
        <div style="display: flex;">
          <span>Items Total</span>
          <span style="flex: 1 1 auto;"></span>
          <span>{{ order.subTotalAmount | currency:'USD':'symbol':'1.2-2' }}</span>
        </div>
        <div style="display: flex;">
          <span>Shipping</span>
          <span style="flex: 1 1 auto;"></span>
          <span>{{ order.shippingAmount | currency:'USD':'symbol':'1.2-2' }}</span>
        </div>
        <div style="display: flex;">
          <span>Tax</span>
          <span style="flex: 1 1 auto;"></span>
          <span>{{ order.taxAmount | currency:'USD':'symbol':'1.2-2' }}</span>
        </div>
        <div style="display: flex;">
          <span>Order Total</span>
          <span style="flex: 1 1 auto;"></span>
          <span>{{ order.totalAmount | currency:'USD':'symbol':'1.2-2' }}</span>
        </div>
      </div>

    </div>
  </div>
</div>
