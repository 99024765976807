<div style="text-align: center;">
  <h2 mat-dialog-title>Order Item Fulfillment Update</h2>
</div>

<div style="display: flex;">
  <span style="flex: 1; text-align: right;">Order Quantity:</span><span style="flex: 1; margin-left: 5px;">{{ data.orderQuantity }}</span>
</div>
<div style="display: flex;">
  <span style="flex: 1; text-align: right;">Current Fulfilled Quantity:</span><span style="flex: 1; margin-left: 5px;">{{ data.fulfilledQuantity }}</span>
</div>

<div [formGroup]="form" style="margin-top: 15px;">
  <mat-form-field style="width: 100%;">
    <input matInput numeric-only type="text" formControlName="fulfilledQuantity" style="font-size: 2em;" placeholder="Updated Fulfilled Quantity" />
  </mat-form-field>
</div>

<div style="display: flex;">

  <app-scaled-container>
    <button mat-flat-button class="scale-font scale-button" (click)="fulfillAll()">Fulfill All</button>
  </app-scaled-container>

  <span style="flex: 1 1 auto;"></span>

  <app-scaled-container>
    <button mat-flat-button class="scale-font scale-button" style="background-color: red;" (click)="cancel()">Cancel</button>
  </app-scaled-container>

  <app-scaled-container>
    <button mat-flat-button class="scale-font scale-button" [disabled]="!this.form.dirty || !this.form.valid" (click)="fulfillAll()">Update</button>
  </app-scaled-container>

</div>
