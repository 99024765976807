export enum TerminalStatusEnum {

  Deleted = 'Deleted',
  Online = 'Online',
  Offline = 'Offline',
  BindTerminalRequested = 'BindTerminalRequested',
  BindTerminalRejected = 'BindTerminalRejected'
}

export enum TerminalTypeEnum {

  Site = 'Site',
  StandaloneTerminal = 'Standalone Terminal',
  RemoteTerminal = 'Remote Terminal'
}
