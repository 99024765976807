import { Injectable } from "@angular/core";
import { UUID } from "angular2-uuid";
import { AuthorizationResult } from "auth";
import { AuthorizationState } from "auth";
import { OidcSecurityService } from "auth";
import { SecurityUser } from "core";
import { AuthIdentityProvider } from "core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SiteAuthIdentityProvider extends AuthIdentityProvider {

  public username$ = new BehaviorSubject<string>(null);

  public isMember = new BehaviorSubject<boolean>(false);
  public isEmployee = new BehaviorSubject<boolean>(false);
  public isManager = new BehaviorSubject<boolean>(false);
  public isAdmin = new BehaviorSubject<boolean>(false);

  public static readonly systemIdentityCookieKey = '.systemIdentityId';
  public static readonly systemIdentityTokenKey = '.systemIdentityToken';

  constructor(
    private oidcSecurityService: OidcSecurityService,
    // private employeeService: EmployeeService,
    // private memberService: MemberService,
    // private authHeaderProvider: AuthHeaderProvider
  ) {
    super();

    this.loadUserIdentity();
  }

  protected loadUserIdentity() {

    if (this.oidcSecurityService.moduleSetup) {
      this.doCallbackLogicIfRequired();
    } else {
      this.oidcSecurityService.onModuleSetup.subscribe(() => {
        this.doCallbackLogicIfRequired();
      });
    }

    this.oidcSecurityService.onCheckSessionChanged.subscribe((checksession: boolean) => {
      console.log('...recieved a check session event');
    });

    this.oidcSecurityService.onAuthorizationResult.subscribe((authorizationResult: AuthorizationResult) => {
      this.onAuthorizationResultComplete(authorizationResult);
    });

    this.oidcSecurityService.getIsAuthorized().subscribe(isAuthorized => {

      console.log(`isAuthorized: ${isAuthorized}`)

      if (isAuthorized) {
        let token = this.oidcSecurityService.getIdToken();
      }
    });

    this.oidcSecurityService.getUserData().subscribe(userData => {

      if (userData && userData.sub) {
        var oidcSecurityUser = <SecurityUser>{
          id: userData.sub,
          email: userData.email,
          emailVerified: userData.email_verified,
          roles: Array.isArray(userData.role) ? <string[]>userData.role : [userData.role]
        };

        // This occurs on a refresh or cache
        localStorage.setItem('authIdentityId', userData.sub);

        this.setUserIdentity(oidcSecurityUser);
        this.username$.next(userData.email);

        let roles = Array.isArray(userData.role) ? <string[]>userData.role : [userData.role];
        if (roles.filter(x => x === 'uptownbar.admin').length > 0) {
          this.isAdmin.next(true);
        }
        if (roles.filter(x => x === 'uptownbar.employee').length > 0) {
          this.isEmployee.next(true);
          this.isManager.next(roles.filter(x => x === 'uptownbar.manager').length > 0);
        }
        if (roles.filter(x => x === 'uptownbar.member').length > 0) {
          this.isMember.next(true);
        }
      } else {
        this.clear();
      }
    });

    this.updateIdentity();
  }

  public setUserIdentityToken(token: string) { }

  public getUserIdentityToken(): string {

    return this.oidcSecurityService.getIdToken()
  }

  protected loadSystemIdentity() {

    const systemIdentityId = localStorage.getItem(SiteAuthIdentityProvider.systemIdentityCookieKey);
    this.setSystemIdentity(systemIdentityId ? <SecurityUser>{ id: systemIdentityId } : null);
  }

  override setSystemIdentity(systemIdentity: SecurityUser) {

    super.setUserIdentity(systemIdentity);

    if (systemIdentity) {
      localStorage.setItem(SiteAuthIdentityProvider.systemIdentityCookieKey, systemIdentity.id);
    } else {
      localStorage.removeItem(SiteAuthIdentityProvider.systemIdentityCookieKey);
    }
  }

  public setSystemIdentityToken(token: string) {

    if (token) {
      localStorage.setItem(SiteAuthIdentityProvider.systemIdentityTokenKey, token);
    } else {
      localStorage.removeItem(SiteAuthIdentityProvider.systemIdentityTokenKey);
    }
  }

  public getSystemIdentityToken(): string {

    return localStorage.getItem(SiteAuthIdentityProvider.systemIdentityTokenKey)
  }

  public setSystemToken(token: string) {

  }

  override clear() {

    super.clear();

    this.username$.next(null);
    this.isEmployee.next(false);
    this.isManager.next(false);
    this.isAdmin.next(false);

    this.updateIdentity();
  }

  navigateToLogin() {

    this.oidcSecurityService.setCustomRequestParameters({ 'ui_locales': 'en-US' });
    this.oidcSecurityService.authorize();
  }

  navigateToLogout() {

    this.oidcSecurityService.setCustomRequestParameters({ 'ui_locales': 'en-US' });
    this.oidcSecurityService.logoff();
  }

  private updateIdentity() {

    let authIdentityId = localStorage.getItem('authIdentityId');
    if (!authIdentityId) {
      authIdentityId = UUID.UUID();
      localStorage.setItem('authIdentityId', authIdentityId);
    }
  }

  private doCallbackLogicIfRequired() {
    if (window.location.hash) {
      this.oidcSecurityService.authorizedImplicitFlowCallback();
    }
  }

  private onAuthorizationResultComplete(authorizationResult: AuthorizationResult) {

    console.log('Auth result received AuthorizationState:' + authorizationResult.authorizationState + ' validationResult:' + authorizationResult.validationResult);

    if (authorizationResult.authorizationState === AuthorizationState.unauthorized) {
      this.setUserIdentity(null);
    }
  }
}
