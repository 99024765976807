import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, finalize, shareReplay, switchMap, takeUntil } from 'rxjs';
import { EntityEvent } from '../models/entity-event';
import { EndpointState, GraphService } from '../services';
import { CoreLibraryConfig } from '../core-library-config';
import { EntityEventProviderStatusEnum } from '../keys';

@Injectable()
export class EntityEventProvider {

  public status$: Observable<EntityEventProviderStatusEnum>;
  public event$: Observable<EntityEvent>;

  private eventSubject$ = new Subject<EntityEvent>();
  private statusSubject$ = new BehaviorSubject<EntityEventProviderStatusEnum>(null);
  private destroyed$ = new Subject();

  constructor(
    private graphService: GraphService,
    private coreLibraryConfig: CoreLibraryConfig,
  ) {
    this.event$ = this.eventSubject$.asObservable();
    this.status$ = this.statusSubject$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

    this.initialize();
  }

  ngOnDestroy() {

    this.destroyed$.next(null);
  }

  private initialize() {

    this.getStream$().pipe(
      takeUntil(this.destroyed$)
    ).subscribe(event => {
      this.eventSubject$.next(event);
    });
  }

  private getStream$(identityToken: string = null): Observable<EntityEvent> {

    const endpoint = this.graphService.createEndpoint(this.coreLibraryConfig.socketUrl + 'api/entityevent', 'events');

    return endpoint.state.pipe(
      switchMap(state => {
        let status: EntityEventProviderStatusEnum;
        switch (state) {
          case EndpointState.Closed:
            status = EntityEventProviderStatusEnum.Closed;
            break;
          case EndpointState.Connecting:
            status = EntityEventProviderStatusEnum.Connecting;
            break;
          case EndpointState.Closing:
            status = EntityEventProviderStatusEnum.Closing;
            break;
          case EndpointState.Ready:
            status = EntityEventProviderStatusEnum.Ready;
            break;
        }

        this.statusSubject$.next(status);

        if (state == EndpointState.Ready) {
          return endpoint.addSubscription<EntityEvent>(`subscription events { events { type uid eventType } }`, 'events', identityToken);
        } else {
          return new Observable<EntityEvent>(null);
        }
      }),
      finalize(() => {
        endpoint.close();
      })
    );
  }
}
