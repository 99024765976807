import { EditableTransactionItem, EditableTransactionItemConfiguration, EditableTransactionItemConfigurationAddOn, EditableTransactionItemConfigurationInclusionGroup, EditableTransactionItemConfigurationInclusionGroupOption, EditableTransactionItemConfigurationPortion, EditableTransactionItemConfigurationPreparation, EditableTransactionItemConfigurationVariation } from "../../models/editable-transaction/editable-transaction";

export function cloneTransactionItem(source: EditableTransactionItem): EditableTransactionItem {

  let target = new EditableTransactionItem();
  target.uid = source.uid;
  target.departmentUid.next(source.departmentUid.value);
  target.productUid.next(source.productUid.value);
  target.productVersion.next(source.productVersion.value);
  target.guestUid.next(source.guestUid.value);
  target.configuration.next(cloneTransactionItemConfiguration(source.configuration.value));
  target.quantity.next(source.quantity.value);
  target.eachAmountText.next(source.eachAmountText.value);

  return target;
}

export function cloneTransactionItemConfiguration(source: EditableTransactionItemConfiguration): EditableTransactionItemConfiguration {

  let target: EditableTransactionItemConfiguration;
  if (source) {
    target = new EditableTransactionItemConfiguration();
    target.portion = cloneTransactionItemConfigurationPortion(source.portion);
  } else {
    return null;
  }

  return target;
}

export function cloneTransactionItemConfigurationPortion(source: EditableTransactionItemConfigurationPortion): EditableTransactionItemConfigurationPortion {

  let target: EditableTransactionItemConfigurationPortion;
  if (source) {
    target = new EditableTransactionItemConfigurationPortion();
    target.portionUid = source.portionUid;
    target.preparations = cloneTransactionItemConfigurationPreparations(source.preparations);
    target.variations = cloneTransactionItemConfigurationVariations(source.variations);
    target.inclusionGroups = cloneTransactionItemConfigurationInclusionGroups(source.inclusionGroups);
    target.addOns = cloneTransactionItemConfigurationAddOns(source.addOns);
    target.notes = cloneTransactionItemConfigurationNotes(source.notes);
  } else {
    target = null;
  }

  return target;
}

export function cloneTransactionItemConfigurationPreparations(source: EditableTransactionItemConfigurationPreparation[]): EditableTransactionItemConfigurationPreparation[] {

  let target: EditableTransactionItemConfigurationPreparation[];
  if (source) {
    target = source.map(x => cloneTransactionItemConfigurationPreparation(x));
  } else {
    target = null;
  }

  return target;
}

export function cloneTransactionItemConfigurationPreparation(source: EditableTransactionItemConfigurationPreparation): EditableTransactionItemConfigurationPreparation {

  let target: EditableTransactionItemConfigurationPreparation;
  if (source) {
    target = new EditableTransactionItemConfigurationPreparation();
    target.preparationUid = source.preparationUid;
    target.optionUid = source.optionUid;
  } else {
    target = null;
  }

  return target;
}

export function cloneTransactionItemConfigurationVariations(source: EditableTransactionItemConfigurationVariation[]): EditableTransactionItemConfigurationVariation[] {

  let target: EditableTransactionItemConfigurationVariation[];
  if (source) {
    target = source.map(x => cloneTransactionItemConfigurationVariation(x));
  } else {
    target = null;
  }

  return target;
}

export function cloneTransactionItemConfigurationVariation(source: EditableTransactionItemConfigurationVariation): EditableTransactionItemConfigurationVariation {

  let target: EditableTransactionItemConfigurationVariation;
  if (source) {
    target = new EditableTransactionItemConfigurationVariation();
    target.variationUid = source.variationUid;
    target.optionUid = source.optionUid;
    target.productUid = source.productUid;
    target.productVersion = source.productVersion;
    target.productPortionUid = source.productPortionUid;
  } else {
    target = null;
  }

  return target;
}

export function cloneTransactionItemConfigurationNotes(source: string[]): string[] {

  return source?.map(x => (' ' + x).slice(1));
}

export function cloneTransactionItemConfigurationInclusionGroups(source: EditableTransactionItemConfigurationInclusionGroup[]): EditableTransactionItemConfigurationInclusionGroup[] {

  let target: EditableTransactionItemConfigurationInclusionGroup[];
  if (source) {
    target = source.map(x => cloneTransactionItemConfigurationInclusionGroup(x));
  } else {
    target = null;
  }

  return target;
}

export function cloneTransactionItemConfigurationInclusionGroup(source: EditableTransactionItemConfigurationInclusionGroup): EditableTransactionItemConfigurationInclusionGroup {

  let target: EditableTransactionItemConfigurationInclusionGroup;
  if (source) {
    target = new EditableTransactionItemConfigurationInclusionGroup();
    target.inclusionGroupUid = source.inclusionGroupUid;
    target.options.push(...cloneTransactionItemConfigurationInclusionGroupOptions(source.options));
  } else {
    target = null;
  }

  return target;
}

export function cloneTransactionItemConfigurationInclusionGroupOptions(source: EditableTransactionItemConfigurationInclusionGroupOption[]): EditableTransactionItemConfigurationInclusionGroupOption[] {

  let target: EditableTransactionItemConfigurationInclusionGroupOption[];
  if (source) {
    target = source.map(x => cloneTransactionItemConfigurationInclusionGroupOption(x));
  } else {
    target = null;
  }

  return target;
}

export function cloneTransactionItemConfigurationInclusionGroupOption(source: EditableTransactionItemConfigurationInclusionGroupOption): EditableTransactionItemConfigurationInclusionGroupOption {

  let target: EditableTransactionItemConfigurationInclusionGroupOption;
  if (source) {
    target = new EditableTransactionItemConfigurationInclusionGroupOption();
    target.optionUid = source.optionUid;
    target.productUid = source.productUid;
    target.productVersion = source.productVersion;
    target.productPortionUid = source.productPortionUid;
    target.quantity = source.quantity;
    target.preparations = cloneTransactionItemConfigurationPreparations(source.preparations);
    target.variations = cloneTransactionItemConfigurationVariations(source.variations);
    target.notes = cloneTransactionItemConfigurationNotes(source.notes);
    target.isSubstitution = source.isSubstitution;
  } else {
    target = null;
  }

  return target;
}

export function cloneTransactionItemConfigurationAddOns(source: EditableTransactionItemConfigurationAddOn[]): EditableTransactionItemConfigurationAddOn[] {

  let target: EditableTransactionItemConfigurationAddOn[];
  if (source) {
    target = source.map(x => cloneTransactionItemConfigurationAddOn(x));
  } else {
    target = null;
  }

  return target;
}

export function cloneTransactionItemConfigurationAddOn(source: EditableTransactionItemConfigurationAddOn): EditableTransactionItemConfigurationAddOn {

  let target: EditableTransactionItemConfigurationAddOn;
  if (source) {
    target = new EditableTransactionItemConfigurationAddOn();
    target.addOnUid = source.addOnUid;
    target.item = cloneTransactionItem(source.item);
  } else {
    target = null;
  }

  return target;
}
