import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TransactionItemPrintModalData, TransactionItemPrintModalResult } from "./transaction-item-print-modal.provider";
import { CurrentDateTimeProvider, TimeoutProvider, SettingProvider, TenantProvider, isCaseInsensitiveEqual } from "core";
import { Subject, takeUntil } from "rxjs";
import { PrintOccurrence } from "../../models";
import { TransactionSettings } from "downtown-transaction";
import { DeviceSetting } from "downmain-device";

@Component({
  selector: 'app-transaction-item-print-modal',
  templateUrl: './transaction-item-print-modal.component.html',
  styleUrls: ['./transaction-item-print-modal.component.scss']
})
export class TransactionItemPrintModalComponent {

  public deviceSettingOptions: DeviceSettingOption[];
  public occurrenceOptions: PrintOccurrenceOption[];
  public timeoutPercent: number;

  private timeoutProvider: TimeoutProvider;
  private destroyed$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<TransactionItemPrintModalComponent, TransactionItemPrintModalResult>,
    @Inject(MAT_DIALOG_DATA) public data: TransactionItemPrintModalData,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider,
    private currentDateTimeProvider: CurrentDateTimeProvider
  ) {
    this.deviceSettingOptions = data.printerDeviceSettings.map(x => <DeviceSettingOption>{ display: x.name, printerDeviceSetting: x, selected: isCaseInsensitiveEqual(x.uid, data.defaultPrintLocationDeviceUid) });

    this.occurrenceOptions = [
      { display: "First Print", selected: true, printOccurrence: PrintOccurrence.First },
      { display: "Re-Print", selected: false, printOccurrence: PrintOccurrence.Reprint }
    ];

    this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(transactionSettings => {      
      this.timeoutProvider = new TimeoutProvider(this.currentDateTimeProvider, this.destroyed$, transactionSettings.transactionAutoClearDuration);
      this.timeoutProvider.percent$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(value => this.timeoutPercent = value);
      this.timeoutProvider.inactive$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(_ => this.cancel());
    });
  }

  public ngOnDestroy() {

    this.destroyed$.next(null);
  }

  selectDeviceSetting(option: DeviceSettingOption) {

    this.deviceSettingOptions.filter(x => x.selected).forEach(x => x.selected = false);
    option.selected = true;
  }

  selectOccurrence(option: PrintOccurrenceOption) {

    this.occurrenceOptions.filter(x => x.selected).forEach(x => x.selected = false);
    option.selected = true;
  }

  cancel() {

    this.dialogRef.close();
  }

  close() {

    this.dialogRef.close(<TransactionItemPrintModalResult>{
      printerDeviceSetting: this.deviceSettingOptions.find(x => x.selected).printerDeviceSetting,
      printOccurrence: this.occurrenceOptions.find(x => x.selected).printOccurrence
    });
  }
}

interface DeviceSettingOption {

  display: string;
  selected: boolean;
  printerDeviceSetting: DeviceSetting
}

interface PrintOccurrenceOption {

  display: string;
  selected: boolean;
  printOccurrence: PrintOccurrence
}
