import { EditableTransaction, EditableTransactionItem, EditableTransactionPayment, EditableTransactionItemAdjustment, EditableTransactionAdjustment, EditableTransactionGuest, EditableTransactionItemConfiguration, EditableTransactionItemConfigurationPortion, EditableTransactionItemConfigurationPreparation, EditableTransactionItemConfigurationVariation, EditableTransactionItemConfigurationInclusionGroup, EditableTransactionItemConfigurationInclusionGroupOption, EditableTransactionItemConfigurationAddOn, EditableTransactionCharge, EditableTransactionChargeAdjustment } from './editable-transaction';
import { Transaction, TransactionItem, TransactionPayment, TransactionAdjustment, TransactionItemAdjustment, TransactionGuest, TransactionItemConfiguration, TransactionItemConfigurationPortion, TransactionItemConfigurationInclusionGroup, TransactionItemConfigurationVariation, TransactionItemConfigurationPreparation, TransactionItemConfigurationInclusionGroupOption, TransactionItemConfigurationAddOn, TransactionCharge, TransactionChargeAdjustment } from "../../models/transaction";
import { BehaviorSubject } from 'rxjs';
import { TransactionStatusEnum, LogisticTypeKeys } from '../../keys';
import { isEqualUUID, hasEqualUUID, isCaseInsensitiveEqual } from 'core';

import Decimal from 'decimal.js';

export type EditableTransactionTypes = EditableTransactionItem | EditableTransactionItemAdjustment | EditableTransactionCharge | EditableTransactionChargeAdjustment | EditableTransactionAdjustment | EditableTransactionPayment;

export class EditableTransactionDataHandler {

  public static getSelectedLine(editableTransaction: EditableTransaction): EditableTransactionTypes {

    return editableTransaction ? editableTransaction.selectedLineItem.value : null;
  }

  public static setSelectedLine(editableTransaction: EditableTransaction, lineItem: EditableTransactionTypes): EditableTransactionTypes {

    if (editableTransaction) {
      let selectedLineItem = editableTransaction.selectedLineItem.value;
      if (lineItem != selectedLineItem) {
        if (selectedLineItem != null) {
          // currentLineItem.commit();
        }

        editableTransaction.selectedLineItem.next(lineItem);
      }

      return editableTransaction.selectedLineItem.value;
    } else {
      return null;
    }
  }

  public static getOrCreateTransaction(editableTransaction: EditableTransaction, terminalUid: string): EditableTransaction {

    if (editableTransaction == null || isCaseInsensitiveEqual(editableTransaction.transactionStatus.value, TransactionStatusEnum.Closed)) {
      editableTransaction = new EditableTransaction();
      editableTransaction.logisticTypeUid = LogisticTypeKeys.DineIn;
      editableTransaction.lockUid.next(terminalUid);
    }

    return editableTransaction;
  }

  public static tryCancelTransaction(editableTransaction: EditableTransaction) {

    if (editableTransaction && !isCaseInsensitiveEqual(editableTransaction.transactionStatus.value, TransactionStatusEnum.Closed)) {
      editableTransaction.transactionStatus.next(TransactionStatusEnum.Cancelled);
    }

    return editableTransaction;
  }

  public static getOrCreateGuest(editableTransaction: EditableTransaction, guest: EditableTransactionGuest): EditableTransactionGuest {

    let existingGuest = guest.uid ? editableTransaction.guests.find(x => hasEqualUUID(x, guest)) : null;
    return existingGuest || editableTransaction.addTransactionGuest(guest);
  }

  public static addGuest(editableTransaction: EditableTransaction, guest: EditableTransactionGuest): EditableTransactionGuest {

    return editableTransaction.addTransactionGuest(guest);
  }

  public static getOrCreateTransactionItem(editableTransaction: EditableTransaction): EditableTransactionItem {

    var selectedLineItem = this.getSelectedLine(editableTransaction) as EditableTransactionItem;

    let transactionItem = selectedLineItem && selectedLineItem.uid ? editableTransaction.lineItems.find(x => hasEqualUUID(x, selectedLineItem)) : selectedLineItem;
    if (transactionItem == null) {
      transactionItem = editableTransaction.addTransactionItem();
    }

    return transactionItem;
  }

  public static addTransactionItem(editableTransaction: EditableTransaction, transactionItem: EditableTransactionItem): EditableTransactionItem {

    return editableTransaction.addTransactionItem(transactionItem);
  }

  public static getOrCreatePayment(editableTransaction: EditableTransaction): EditableTransactionPayment {

    var selection = this.getSelectedLine(editableTransaction) as EditableTransactionPayment;

    let payments = editableTransaction.payments;
    let payment = selection && selection.uid ? payments.find(x => hasEqualUUID(x, selection)) : selection;
    if (payment == null) {
      payment = editableTransaction.addPayment();
    }

    return payment;
  }

  public static getOrCreateTransactionCharge(editableTransaction: EditableTransaction): EditableTransactionCharge {

    var selection = this.getSelectedLine(editableTransaction) as EditableTransactionCharge;

    let charges = editableTransaction.charges;
    let transactionCharge = selection && selection.uid ? charges.find(x => hasEqualUUID(x, selection)) : selection;

    if (transactionCharge == null) {
      transactionCharge = editableTransaction.addTransactionCharge();
    }

    return transactionCharge;
  }

  public static getOrCreateTransactionAdjustment(editableTransaction: EditableTransaction): EditableTransactionAdjustment {

    var selection = this.getSelectedLine(editableTransaction) as EditableTransactionAdjustment;

    let adjustments = editableTransaction.adjustments;
    let transactionAdjustment = selection && selection.uid ? adjustments.find(x => hasEqualUUID(x, selection)) : selection;

    if (transactionAdjustment == null) {
      transactionAdjustment = editableTransaction.addTransactionAdjustment();
    }

    return transactionAdjustment;
  }

  public static getOrCreateTransactionItemAdjustment(editableTransaction: EditableTransaction, transactionItem: EditableTransactionItem): EditableTransactionItemAdjustment {

    var selection = this.getSelectedLine(editableTransaction) as EditableTransactionItemAdjustment;

    let adjustments = transactionItem.adjustments;
    let transactionItemAdjustment = selection && selection.uid ? adjustments.find(x => hasEqualUUID(x, selection)) : selection;

    if (transactionItemAdjustment == null) {
      transactionItemAdjustment = transactionItem.addTransactionItemAdjustment();
    }

    return transactionItemAdjustment;
  }

  public static getOrCreateTransactionChargeAdjustment(editableTransaction: EditableTransaction, transactionCharge: EditableTransactionCharge): EditableTransactionChargeAdjustment {

    var selection = this.getSelectedLine(editableTransaction) as EditableTransactionChargeAdjustment;

    let charges = transactionCharge.adjustments;
    let transactionChargeAdjustment = selection && selection.uid ? charges.find(x => hasEqualUUID(x, selection)) : selection;

    if (transactionChargeAdjustment == null) {
      transactionChargeAdjustment = transactionCharge.addTransactionChargeAdjustment();
    }

    return transactionChargeAdjustment;
  }

  public static cancelItemChanges(editableTransaction: EditableTransaction, item: EditableTransactionTypes) {

    if (item) {
      if (item instanceof EditableTransactionItem) {
        return EditableTransactionDataHandler.cancelTransactionItem(editableTransaction, item);
      } else if (item instanceof EditableTransactionItemAdjustment) {
        return EditableTransactionDataHandler.cancelTransactionItemAdjustment(editableTransaction, item);
      } else if (item instanceof EditableTransactionCharge) {
        return EditableTransactionDataHandler.cancelTransactionCharge(editableTransaction, item);
      } else if (item instanceof EditableTransactionChargeAdjustment) {
        return EditableTransactionDataHandler.cancelTransactionChargeAdjustment(editableTransaction, item);
      } else if (item instanceof EditableTransactionAdjustment) {
        return EditableTransactionDataHandler.cancelTransactionAdjustment(editableTransaction, item);
      } else if (item instanceof EditableTransactionPayment) {
        return EditableTransactionDataHandler.cancelPayment(editableTransaction, item);
      }
    }

    return editableTransaction;
  }

  static cancelTransactionItem(editableTransaction: EditableTransaction, transactionItem: EditableTransactionItem): EditableTransactionItem {

    if (transactionItem.uid) {
      editableTransaction.resetTransactionItem(transactionItem);
    } else {
      editableTransaction.removeTransactionItem(transactionItem);
    }

    return transactionItem;
  }

  static cancelTransactionItemAdjustment(editableTransaction: EditableTransaction, transactionItemAdjustment: EditableTransactionItemAdjustment): EditableTransactionItemAdjustment {

    var transactionItem = editableTransaction.lineItems.find(x => isEqualUUID(x.uid, transactionItemAdjustment.transactionLineItemUid));
    if (transactionItem) {
      if (transactionItemAdjustment.uid) {
        transactionItem.resetTransactionItemAdjustment(transactionItemAdjustment);
      } else {
        transactionItem.removeTransactionItemAdjustment(transactionItemAdjustment);
      }
    }

    return transactionItemAdjustment;
  }

  static cancelTransactionCharge(editableTransaction: EditableTransaction, transactionCharge: EditableTransactionCharge): EditableTransactionCharge {

    if (transactionCharge.uid) {
      editableTransaction.resetTransactionCharge(transactionCharge);
    } else {
      editableTransaction.removeTransactionCharge(transactionCharge);
    }

    return transactionCharge;
  }

  static cancelTransactionChargeAdjustment(editableTransaction: EditableTransaction, transactionChargeAdjustment: EditableTransactionChargeAdjustment): EditableTransactionChargeAdjustment {

    var transactionCharge = editableTransaction.charges.find(x => isEqualUUID(x.uid, transactionChargeAdjustment.transactionChargeUid));
    if (transactionCharge) {
      if (transactionChargeAdjustment.uid) {
        transactionCharge.resetTransactionChargeAdjustment(transactionChargeAdjustment);
      } else {
        transactionCharge.removeTransactionChargeAdjustment(transactionChargeAdjustment);
      }
    }

    return transactionChargeAdjustment;
  }

  static cancelTransactionAdjustment(editableTransaction: EditableTransaction, transactionAdjustment: EditableTransactionAdjustment): EditableTransactionAdjustment {

    if (transactionAdjustment.uid) {
      editableTransaction.resetTransactionAdjustment(transactionAdjustment);
    } else {
      editableTransaction.removeTransactionAdjustment(transactionAdjustment);
    }

    return transactionAdjustment;
  }

  static cancelPayment(editableTransaction: EditableTransaction, payment: EditableTransactionPayment): EditableTransactionPayment {

    if (payment.uid) {
      editableTransaction.resetPayment(payment);
    } else {
      editableTransaction.removePayment(payment);
    }

    return payment;
  }

  public static removeItem(editableTransaction: EditableTransaction, item: EditableTransactionItemAdjustment | EditableTransactionCharge | EditableTransactionChargeAdjustment | EditableTransactionAdjustment | EditableTransactionPayment) {

    if (item) {
      if (item instanceof EditableTransactionItemAdjustment) {
        let transactionItem = editableTransaction.lineItems.find(x => isEqualUUID(x.uid, item.transactionLineItemUid))

        transactionItem.removeTransactionItemAdjustment(item);
      } else if (item instanceof EditableTransactionCharge) {
        editableTransaction.removeTransactionCharge(item);
      } else if (item instanceof EditableTransactionChargeAdjustment) {
        let transactionItem = editableTransaction.charges.find(x => isEqualUUID(x.uid, item.transactionChargeUid))

        transactionItem.removeTransactionChargeAdjustment(item);
      } else if (item instanceof EditableTransactionAdjustment) {
        editableTransaction.removeTransactionAdjustment(item);
      } else if (item instanceof EditableTransactionPayment) {
        editableTransaction.removePayment(item);
      }
    }
  }

  public static mergeTransaction(target: EditableTransaction, source: Transaction): EditableTransaction {

    if (source) {
      target = target || new EditableTransaction();

      target.uid = source.uid;
      target.number.next(source.number);
      target.openDateTimeUtc = source.openDateTimeUtc;
      target.openAuthUid = source.openAuthUid;
      target.closeDateTimeUtc = source.closeDateTimeUtc;
      target.closeAuthUid = source.closeAuthUid;
      target.logisticTypeUid = source.logisticTypeUid;
      target.lockUid.next(source.lockUid);
      target.lastGuestUid = source.lastGuestUid;
      target.notes.next(source.notes);
      target.holdCardReference.next(source.holdCardReference);
      target.total.next(source.total);
      target.transactionStatus.next(source.transactionStatus);

      if (source.guests) {
        source.guests.forEach(guest => {
          const transactionGuest = target.guests.find(x => hasEqualUUID(x, guest)) || target.addTransactionGuest();
          this.mergeTransactionGuest(transactionGuest, guest);
        });
      }

      if (source.items) {
        source.items.forEach(item => {
          const transactionItem = target.lineItems.find(x => hasEqualUUID(x, item)) || target.addTransactionItem();
          this.mergeTransactionItem(transactionItem, item);
        });
      }

      if (source.charges) {
        source.charges.forEach(charge => {
          const transactionCharge = target.charges.find(x => hasEqualUUID(x, charge)) || target.addTransactionCharge();
          this.mergeTransactionCharge(transactionCharge, charge);
        });
      }

      if (source.adjustments) {
        source.adjustments.forEach(adjustment => {
          const adjustmentItem = target.adjustments.find(x => hasEqualUUID(x, adjustment)) || target.addTransactionAdjustment();
          this.mergeTransactionAdjustment(adjustmentItem, adjustment);
        });
      }

      if (source.payments) {
        source.payments.forEach(payment => {
          const paymentItem = target.payments.find(x => hasEqualUUID(x, payment)) || target.addPayment();
          this.mergePayment(paymentItem, payment);
        });
      }
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionGuest(target: EditableTransactionGuest, source: TransactionGuest): EditableTransactionGuest {

    if (source) {
      target = target || new EditableTransactionGuest();

      target.uid = source.uid;
      target.name = source.name;
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionItem(target: EditableTransactionItem, source: TransactionItem): EditableTransactionItem {

    if (source) {
      target = target || new EditableTransactionItem();

      target.uid = source.uid;
      target.departmentUid.next(source.departmentUid);
      target.categoryUid.next(source.categoryUid);
      target.productUid.next(source.productUid);
      target.productVersion.next(source.productVersion);
      target.guestUid.next(source.guestUid);
      target.configuration.next(EditableTransactionDataHandler.mergeTransactionItemConfiguration(target.configuration.value, source.configuration));
      target.quantity.next(source.quantity);
      target.eachAmountText.next(new Decimal(source.eachAmount.toString()).toFixed(2));
      target.notes = source.notes;

      if (source.adjustments) {
        source.adjustments.forEach(adjustment => {
          const transactionItemAdjustment = target.adjustments.find(x => hasEqualUUID(x, adjustment)) || target.addTransactionItemAdjustment();
          EditableTransactionDataHandler.mergeTransactionItemAdjustment(transactionItemAdjustment, adjustment);
        });
      }

      target.takeSnapshot();
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionItemConfiguration(target: EditableTransactionItemConfiguration, source: TransactionItemConfiguration): EditableTransactionItemConfiguration {

    if (source) {
      target = target || new EditableTransactionItemConfiguration();

      target.portion = EditableTransactionDataHandler.mergeTransactionItemConfigurationPortion(target.portion, source.portion);
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionItemConfigurationPortion(target: EditableTransactionItemConfigurationPortion, source: TransactionItemConfigurationPortion): EditableTransactionItemConfigurationPortion {

    if (source) {
      target = target || new EditableTransactionItemConfigurationPortion();

      target.portionUid = source.portionUid;
      target.preparations = EditableTransactionDataHandler.mergeTransactionItemConfigurationPreparations(target.preparations, source.preparations);
      target.variations = EditableTransactionDataHandler.mergeTransactionItemConfigurationVariations(target.variations, source.variations);
      target.inclusionGroups = EditableTransactionDataHandler.mergeTransactionItemConfigurationInclusionGroups(target.inclusionGroups, source.inclusionGroups);
      target.addOns = EditableTransactionDataHandler.mergeTransactionItemConfigurationAddOns(target.addOns, source.addOns);
      target.notes = source.notes;
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionItemConfigurationPreparations(targets: EditableTransactionItemConfigurationPreparation[], sources: TransactionItemConfigurationPreparation[]): EditableTransactionItemConfigurationPreparation[] {

    if (sources) {
      targets = targets || [];

      sources.forEach(source => {
        const target = EditableTransactionDataHandler.getOrCreateTransactionItemConfigurationPreparation(targets, source.preparationUid);
        this.mergeTransactionItemConfigurationPreparation(target, source);
      });
      targets.forEach(target => {
        const source = sources.find(x => isEqualUUID(x.preparationUid, target.preparationUid));
        if (!source) {
          targets.splice(targets.indexOf(target), 1);
        }
      })
    } else {
      targets = null;
    }

    return targets && targets.length > 0 ? targets : null;
  }

  public static getOrCreateTransactionItemConfigurationPreparation(targets: EditableTransactionItemConfigurationPreparation[], preparationUid: string): EditableTransactionItemConfigurationPreparation {

    let target = targets.find(x => isEqualUUID(x.preparationUid, preparationUid));
    if (!target) {
      target = new EditableTransactionItemConfigurationPreparation();
      targets.push(target);
    }

    return target;
  }

  public static mergeTransactionItemConfigurationPreparation(target: EditableTransactionItemConfigurationPreparation, source: TransactionItemConfigurationPreparation): EditableTransactionItemConfigurationPreparation {

    if (source) {
      target = target || new EditableTransactionItemConfigurationPreparation();

      target.preparationUid = source.preparationUid;
      target.optionUid = source.optionUid;
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionItemConfigurationVariations(targets: EditableTransactionItemConfigurationVariation[], sources: TransactionItemConfigurationVariation[]): EditableTransactionItemConfigurationVariation[] {

    if (sources) {
      targets = targets || [];

      sources.forEach(source => {
        const target = EditableTransactionDataHandler.getOrCreateTransactionItemConfigurationVariation(targets, source.variationUid);
        this.mergeTransactionItemConfigurationVariation(target, source);
      });
      targets.forEach(target => {
        const source = sources.find(x => isEqualUUID(x.variationUid, target.variationUid));
        if (!source) {
          targets.splice(targets.indexOf(target), 1);
        }
      })
    } else {
      targets = null;
    }

    return targets && targets.length > 0 ? targets : null;
  }

  public static getOrCreateTransactionItemConfigurationVariation(targets: EditableTransactionItemConfigurationVariation[], variationUid: string): EditableTransactionItemConfigurationVariation {

    let target = targets.find(x => isEqualUUID(x.variationUid, variationUid));
    if (!target) {
      target = new EditableTransactionItemConfigurationVariation();
      targets.push(target);
    }

    return target;
  }

  public static mergeTransactionItemConfigurationVariation(target: EditableTransactionItemConfigurationVariation, source: TransactionItemConfigurationVariation): EditableTransactionItemConfigurationVariation {

    if (source) {
      target = target || new EditableTransactionItemConfigurationVariation();

      target.variationUid = source.variationUid;
      target.optionUid = source.optionUid;
      target.productUid = source.productUid;
      target.productVersion = source.productVersion;
      target.productPortionUid = source.productPortionUid;
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionItemConfigurationInclusionGroups(targets: EditableTransactionItemConfigurationInclusionGroup[], sources: TransactionItemConfigurationInclusionGroup[]): EditableTransactionItemConfigurationInclusionGroup[] {

    if (sources) {
      targets = targets || [];

      sources.forEach(source => {
        const target = EditableTransactionDataHandler.getOrCreateTransactionItemConfigurationInclusionGroup(targets, source.inclusionGroupUid);
        this.mergeTransactionItemConfigurationInclusionGroup(target, source);
      });
      targets.forEach(target => {
        const source = sources.find(x => isEqualUUID(x.inclusionGroupUid, target.inclusionGroupUid));
        if (!source) {
          targets.splice(targets.indexOf(target), 1);
        }
      })
    } else {
      targets = null;
    }

    return targets && targets.length > 0 ? targets : null;
  }

  public static getOrCreateTransactionItemConfigurationInclusionGroup(targets: EditableTransactionItemConfigurationInclusionGroup[], inclusionGroupUid: string): EditableTransactionItemConfigurationInclusionGroup {

    let target = targets.find(x => isEqualUUID(x.inclusionGroupUid, inclusionGroupUid));
    if (!target) {
      target = new EditableTransactionItemConfigurationInclusionGroup();
      targets.push(target);
    }

    return target;
  }

  public static mergeTransactionItemConfigurationInclusionGroup(target: EditableTransactionItemConfigurationInclusionGroup, source: TransactionItemConfigurationInclusionGroup): EditableTransactionItemConfigurationInclusionGroup {

    if (source) {
      target = target || new EditableTransactionItemConfigurationInclusionGroup();

      target.inclusionGroupUid = source.inclusionGroupUid;
      target.options = EditableTransactionDataHandler.mergeTransactionItemConfigurationInclusionGroupOptions(target.options, source.options);
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionItemConfigurationInclusionGroupOptions(targets: EditableTransactionItemConfigurationInclusionGroupOption[], sources: TransactionItemConfigurationInclusionGroupOption[]): EditableTransactionItemConfigurationInclusionGroupOption[] {

    if (sources) {
      targets = targets || [];

      sources.forEach(source => {
        const target = EditableTransactionDataHandler.getOrCreateTransactionItemConfigurationInclusionGroupOption(targets, source.optionUid);
        this.mergeTransactionItemConfigurationInclusionGroupOption(target, source);
      });
      targets.forEach(target => {
        const source = sources.find(x => isEqualUUID(x.optionUid, target.optionUid));
        if (!source) {
          targets.splice(targets.indexOf(target), 1);
        }
      })
    } else {
      targets = null;
    }

    return targets && targets.length > 0 ? targets : null;
  }

  public static getOrCreateTransactionItemConfigurationInclusionGroupOption(targets: EditableTransactionItemConfigurationInclusionGroupOption[], optionUid: string): EditableTransactionItemConfigurationInclusionGroupOption {

    let target = targets.find(x => isEqualUUID(x.optionUid, optionUid));
    if (!target) {
      target = new EditableTransactionItemConfigurationInclusionGroupOption();
      targets.push(target);
    }

    return target;
  }

  public static mergeTransactionItemConfigurationInclusionGroupOption(target: EditableTransactionItemConfigurationInclusionGroupOption, source: TransactionItemConfigurationInclusionGroupOption): EditableTransactionItemConfigurationInclusionGroupOption {

    if (source) {
      target = target || new EditableTransactionItemConfigurationInclusionGroupOption();

      target.optionUid = source.optionUid;
      target.productUid = source.productUid;
      target.productVersion = source.productVersion;
      target.productPortionUid = source.productPortionUid;
      target.quantity = source.quantity;
      target.preparations = EditableTransactionDataHandler.mergeTransactionItemConfigurationPreparations(target.preparations, source.preparations);
      target.variations = EditableTransactionDataHandler.mergeTransactionItemConfigurationVariations(target.variations, source.variations);
      target.notes = source.notes;
      target.isSubstitution = source.isSubstitution;
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionItemConfigurationAddOns(targets: EditableTransactionItemConfigurationAddOn[], sources: TransactionItemConfigurationAddOn[]): EditableTransactionItemConfigurationAddOn[] {

    if (sources) {
      targets = targets || [];

      sources.forEach(source => {
        const target = EditableTransactionDataHandler.getOrCreateTransactionItemConfigurationAddOn(targets, source.addOnUid);
        this.mergeTransactionItemConfigurationAddOn(target, source);
      });
      targets.forEach(target => {
        const source = sources.find(x => isEqualUUID(x.addOnUid, target.addOnUid));
        if (!source) {
          targets.splice(targets.indexOf(target), 1);
        }
      })
    } else {
      targets = null;
    }

    return targets && targets.length > 0 ? targets : null;
  }

  public static getOrCreateTransactionItemConfigurationAddOn(targets: EditableTransactionItemConfigurationAddOn[], addOnUid: string): EditableTransactionItemConfigurationAddOn {

    let target = targets.find(x => isEqualUUID(x.addOnUid, addOnUid));
    if (!target) {
      target = new EditableTransactionItemConfigurationAddOn();
      targets.push(target);
    }

    return target;
  }

  public static mergeTransactionItemConfigurationAddOn(target: EditableTransactionItemConfigurationAddOn, patch: TransactionItemConfigurationAddOn): EditableTransactionItemConfigurationAddOn {

    if (patch) {
      target = target || new EditableTransactionItemConfigurationAddOn();

      target.addOnUid = patch.addOnUid;
      target.item = EditableTransactionDataHandler.mergeTransactionItem(target.item, patch.item);
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionItemAdjustment(target: EditableTransactionItemAdjustment, patch: TransactionItemAdjustment): EditableTransactionItemAdjustment {

    if (patch) {
      target = target || new EditableTransactionItemAdjustment();

      target.uid = patch.uid;
      target.description = patch.description;
      target.quantity.next(patch.quantity);
      target.eachAmountText.next(new Decimal(patch.eachAmount).toFixed(2));
      target.isVoid.next(patch.isVoid);
      target.isCompensated.next(patch.isCompensated);

      target.takeSnapshot();
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionCharge(target: EditableTransactionCharge, patch: TransactionCharge): EditableTransactionCharge {

    if (patch) {
      target = target || new EditableTransactionCharge();

      target.uid = patch.uid;
      target.chargeTypeUid.next(patch.chargeTypeUid);
      target.description = patch.description;
      target.quantity.next(patch.quantity);
      target.eachAmountText.next(new Decimal(patch.eachAmount).toFixed(2));

      if (patch.adjustments) {
        patch.adjustments.forEach(adjustment => {
          const transactionChargeAdjustment = target.adjustments.find(x => hasEqualUUID(x, adjustment)) || target.addTransactionChargeAdjustment();
          EditableTransactionDataHandler.mergeTransactionChargeAdjustment(transactionChargeAdjustment, adjustment);
        });
      }

      target.takeSnapshot();
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionChargeAdjustment(target: EditableTransactionChargeAdjustment, patch: TransactionChargeAdjustment): EditableTransactionChargeAdjustment {

    if (patch) {
      target = target || new EditableTransactionChargeAdjustment();

      target.uid = patch.uid;
      target.description = patch.description;
      target.quantity.next(patch.quantity);
      target.eachAmountText.next(new Decimal(patch.eachAmount).toFixed(2));
      target.isVoid.next(patch.isVoid);
      target.isCompensated.next(patch.isCompensated);

      target.takeSnapshot();
    } else {
      target = null;
    }

    return target;
  }

  public static mergeTransactionAdjustment(target: EditableTransactionAdjustment, patch: TransactionAdjustment): EditableTransactionAdjustment {

    if (patch) {
      target.uid = patch.uid;
      target.description.next(patch.description);
      target.amountText.next(new Decimal(patch.amount).toFixed(2));

      target.takeSnapshot();
    }

    return target;
  }

  public static mergePayment(target: EditableTransactionPayment, patch: TransactionPayment): EditableTransactionPayment {

    if (patch) {
      target.uid = patch.uid;
      target.paymentMethodUid.next(patch.paymentMethodUid);
      target.amountText.next(new Decimal(patch.amount).toFixed(2));
      target.referenceUid.next(patch.referenceUid);
      target.metadata = patch.metadata;

      target.takeSnapshot();
    }

    return target;
  }

  public static getNumberPadKeyPressedResult(item: EditableTransactionTypes, value: string): string {

    let keyValue = value.toLowerCase();

    let subject: BehaviorSubject<string>;

    if (item instanceof EditableTransactionItem) {
      subject = item.eachAmountText;
    } else if (item instanceof EditableTransactionItemAdjustment) {
      subject = item.eachAmountText;
    } else if (item instanceof EditableTransactionCharge) {
      subject = item.eachAmountText;
    } else if (item instanceof EditableTransactionChargeAdjustment) {
      subject = item.eachAmountText;
    } else if (item instanceof EditableTransactionAdjustment) {
      subject = item.amountText;
    } else if (item instanceof EditableTransactionPayment) {
      subject = item.amountText;
    }

    if (subject) {
      if (keyValue == "00") {
        EditableTransactionDataHandler.numberPadKeyPressed(item, "0");
        EditableTransactionDataHandler.numberPadKeyPressed(item, "0");
      } else if (keyValue == ".") {
        let value = subject.value;
        let decimalIndex = value.indexOf('.');

        let wholeDigits = value.substr(0, decimalIndex + 2).replace('.', '');
        let decimalDigits = value.substr(decimalIndex + 2);

        value = parseInt(wholeDigits + decimalDigits).toString() + ".";
        subject.next(value);
      } else if (keyValue == "back") {
        let value = new Decimal(subject.value);
        if (value.equals(0)) {
          return 'cancel';
        } else {
          // Shift all digits right
          value = new Decimal(value.times(10)).floor().dividedBy(100);

          if (value.equals(0) && !item.isValid) {
            subject.next(new Decimal(0).toFixed(2));

            return 'cancel';
          } else {
            subject.next(value.toNumber().toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
          }
        }
      } else if (keyValue == "enter") {
        let value = new Decimal(subject.value);
        if (value.equals(0)) {
          return 'cancel';
        } else {
          return 'commit';
        }
      } else if (keyValue == "clear") {
        let value = new Decimal(subject.value);
        if (value.equals(0)) {
          return 'cancel';
        } else {
          subject.next(new Decimal(0).toFixed(2));
        }
      } else {
        let digit = new Decimal(value);
        if (!digit.isNaN()) {
          let valueText = subject.value.concat(keyValue);

          let value = new Decimal(valueText);
          if (!value.isNaN()) {
            let decimalIndex = valueText.indexOf('.');
            if (decimalIndex > 0 && valueText.length - decimalIndex > 3) {
              // Need to shift the decimal point
              valueText = (new Decimal(valueText.slice(0, decimalIndex + 2).replace('.', '')).toString()) + '.' + (valueText.slice(decimalIndex + 2));
            }

            subject.next(valueText);
          }
        }

        return subject.value;
      }

      return value;
    }

    return null;
  }


  public static numberPadKeyPressed(item: EditableTransactionTypes, value: string): string {

    let keyValue = value.toLowerCase();

    let subject: BehaviorSubject<string>;

    if (item instanceof EditableTransactionItem) {
      subject = item.eachAmountText;
    } else if (item instanceof EditableTransactionItemAdjustment) {
      subject = item.eachAmountText;
    } else if (item instanceof EditableTransactionCharge) {
      subject = item.eachAmountText;
    } else if (item instanceof EditableTransactionChargeAdjustment) {
      subject = item.eachAmountText;
    } else if (item instanceof EditableTransactionAdjustment) {
      subject = item.amountText;
    } else if (item instanceof EditableTransactionPayment) {
      subject = item.amountText;
    }

    if (subject) {
      if (keyValue == "00") {
        EditableTransactionDataHandler.numberPadKeyPressed(item, "0");
        EditableTransactionDataHandler.numberPadKeyPressed(item, "0");
      } else if (keyValue == ".") {
        let value = subject.value;
        let decimalIndex = value.indexOf('.');

        let wholeDigits = value.slice(0, decimalIndex + 2).replace('.', '');
        let decimalDigits = value.slice(decimalIndex + 2);

        value = parseInt(wholeDigits + decimalDigits).toString() + ".";
        subject.next(value);
      } else if (keyValue == "back") {
        let value = new Decimal(subject.value);
        if (value.equals(0)) {
          return 'cancel';
        } else {
          // Shift all digits right
          value = value.times(10).floor().dividedBy(100);

          if (value.equals(0) && !item.isValid) {
            subject.next(new Decimal(0).toFixed(2));

            return 'cancel';
          } else {
            subject.next(value.toNumber().toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
          }
        }
      } else if (keyValue == "enter") {
        let value = new Decimal(subject.value);
        if (value.equals(0)) {
          return 'cancel';
        } else {
          return 'commit';
        }
      } else if (keyValue == "clear") {
        let value = new Decimal(subject.value);
        if (value.equals(0)) {
          return 'cancel';
        } else {
          subject.next(new Decimal(0).toFixed(2));
        }
      } else {
        let digit = new Decimal(value);
        if (!digit.isNaN()) {
          let valueText = subject.value.concat(keyValue);

          let value = new Decimal(valueText);
          if (!value.isNaN()) {
            let decimalIndex = valueText.indexOf('.');
            if (decimalIndex > 0 && valueText.length - decimalIndex > 3) {
              // Need to shift the decimal point
              valueText = (new Decimal(valueText.slice(0, decimalIndex + 2).replace('.', '')).toString()) + '.' + (valueText.slice(decimalIndex + 2));
            }

            subject.next(valueText);
          }
        }

        return subject.value;
      }

      return value;
    }

    return null;
  }
}
