import { Component, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, map, concatMap, takeUntil } from 'rxjs/operators';
import { ActionBarConfiguration, ActionConfiguration, FilterConfiguration, FilterOptionConfiguration, getParamsFromFilters, getParamsFromPaginator, isCaseInsensitiveEqual, updateFiltersFromParams, updatePaginatorFromParams } from 'core';
import { Paginator } from 'core';
import { PaginationInput } from 'core';
import { ConfirmModalProvider } from 'core';
import { SelectListModalProvider, SelectListModalData } from 'core';
import { SpinnerModalComponent } from 'core';
import { SpinnerModalProvider } from 'core';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { EmployeeDataSource, EmployeeModel } from 'pos-core';
import { PaymentMethodKeys } from 'core';
import { EmployeeStatusKeys } from 'pos-core';
import { IdentityUser } from 'core';
import { AuthEmployeeProvider } from 'pos-core';
import { EmployeeService } from 'pos-core';
import { IdentityUserService } from 'pos-core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-back-office-employees',
  templateUrl: './back-office-employees.component.html',
  styleUrls: ['./back-office-employees.component.scss']
})
export class BackOfficeEmployeesComponent implements OnInit {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: EmployeeDataSource;
  public paginator: Paginator;

  public title: string;
  public columns: Array<string>;

  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authEmployeeProvider: AuthEmployeeProvider,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private selectListModalProvider: SelectListModalProvider,
    private employeeService: EmployeeService,
    private identityUserService: IdentityUserService
  ) {
    this.navigationProvider.setWaypoint('Employees');
    this.title = 'Employees';

    this.dataSource = new EmployeeDataSource(this.employeeService, false);
    this.paginator = new Paginator(this.dataSource, 0, 100, '', 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('New', this.new.bind(this)),
        new ActionConfiguration('Profile', () => this.profile(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Positions', () => this.positions(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Permissions', () => this.permissions(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Shifts', () => this.shifts(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Enable', () => this.enable(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEnable)),
        new ActionConfiguration('Disable', () => this.disable(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDisable)),
        new ActionConfiguration('Link Id', () => this.linkIdentity(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canLinkIdentity)),
        new ActionConfiguration('Unlink Id', () => this.unlinkIdentity(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canUnlinkIdentity)),
        new ActionConfiguration('Delete', () => this.delete(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDelete))
      ],
      [
        new FilterConfiguration('Payment Method',
          [
            new FilterOptionConfiguration('All', null),
            new FilterOptionConfiguration('Cash', [PaymentMethodKeys.Cash]),
            new FilterOptionConfiguration('Check', [PaymentMethodKeys.Check])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }
        ),
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('Active', [EmployeeStatusKeys.Active]),
            new FilterOptionConfiguration('All', [EmployeeStatusKeys.Active, EmployeeStatusKeys.Inactive])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'Active')) }
        )
      ]
    );

    // Build initial columns
    this.columns = [ /*'select',*/ 'last', 'first', 'display', 'hasIdentityId', 'pin', 'paymentMethod', 'status'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }

    this.employeeService.getMutated().pipe(
      takeUntil(this.destroyed$)
    ).subscribe(employee => {
      this.dataSource.merge(employee);
    });
  }

  ngOnInit() {

    const paymentMethodFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => isCaseInsensitiveEqual(x.title, 'Payment Method'));
    const statusFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => isCaseInsensitiveEqual(x.title, 'Status'));
    const paginator = this.paginator;

    this.activatedRoute.queryParams.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(params => {
      updateFiltersFromParams([paymentMethodFilter, statusFilter], params);
      updatePaginatorFromParams(paginator, params);
    });

    combineLatest([
      paymentMethodFilter.selected,
      statusFilter.selected,
      paginator.pageRequest$
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([payMethodFilterOption, statusFilterOption, paginationInput]) => {
        let payMethodFilterUids = payMethodFilterOption.value;
        let statusFilterUids = statusFilterOption.value;

        const params = Object.assign(getParamsFromFilters([paymentMethodFilter, statusFilter]), getParamsFromPaginator(paginator));
        this.navigationProvider.navigate([], { queryParams: params, queryParamsHandling: 'merge' }).then(_ => this.navigationProvider.updateLastWaypoint());

        this.dataSource.loadData(payMethodFilterUids, statusFilterUids, paginationInput, EmployeeService.EmployeeIndexView);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }

  public new() {

    this.navigationProvider.navigate(['/backoffice/employee/profile']);
  }

  public profile(employee: EmployeeModel) {

    this.navigationProvider.navigate(['/backoffice/employee', employee.employee.uid, 'profile']);
  }

  public positions(employee: EmployeeModel) {

    this.navigationProvider.navigate(['/backoffice/employee', employee.employee.uid, 'positions']);
  }

  public permissions(employee: EmployeeModel) {

    this.navigationProvider.navigate(['/backoffice/employee', employee.employee.uid, 'permissions']);
  }

  public shifts(employee: EmployeeModel) {

    this.navigationProvider.navigate(['/backoffice/employee', employee.employee.uid, 'shifts']);
  }

  public payment(employee: EmployeeModel) {

    this.navigationProvider.navigate(['/backoffice/employee', employee.employee.uid, 'payment']);
  }

  public enable(employee: EmployeeModel) {

    let waitDialogRef = this.waitModalProvider.open('Enabling...');

    combineLatest([
      this.authEmployeeProvider.authEmployee$,
      waitDialogRef.afterOpened()
    ]).pipe(
      concatMap(([authEmployee]) => this.dataSource.enable(employee, authEmployee)),
      catchError(() => of(null))
    ).subscribe(() => {
      waitDialogRef.close();
    });
  }

  public disable(employee: EmployeeModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Disable Employee', 'Are you certain you want to disable this employee?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Disabling...');

        combineLatest([
          this.authEmployeeProvider.authEmployee$,
          waitDialogRef.afterOpened()
        ]).pipe(
          concatMap(([authEmployee]) => this.dataSource.disable(employee, authEmployee)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public linkIdentity(employee: EmployeeModel) {

    let waitDialogRef = this.waitModalProvider.open('Loading...');
    waitDialogRef.afterOpened().subscribe(() => {
      this.identityUserService.search(<PaginationInput>{ pageSize: 25, pageIndex: 0 }, IdentityUserService.IdentityUserIndexView).subscribe(identityUsersPage => {
        waitDialogRef.close();

        this.selectListModalProvider.open(<SelectListModalData<IdentityUser>>{
          title: 'Select Identity',
          options: identityUsersPage.edges.map(x => x.node),
          displayFunc: identityUser => identityUser.email
        }).afterClosed().subscribe(identityUser => {
          if (identityUser) {
            let waitDialogRef2 = this.waitModalProvider.open('Linking Identity...');

            combineLatest([
              this.authEmployeeProvider.authEmployee$,
              waitDialogRef2.afterOpened()
            ]).pipe(
              concatMap(([authEmployee]) => this.dataSource.updateIdentity(employee, (<IdentityUser>identityUser).id, authEmployee)),
              catchError(() => of(null))
            ).subscribe(() => waitDialogRef2.close());
          }
        });
      });
    });
  }

  public unlinkIdentity(employee: EmployeeModel) {

    this.confirmModalProvider.open('Unlink Employee', 'Are you certain you want to unlink this employee?').afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Unlinking...');

        combineLatest([
          this.authEmployeeProvider.authEmployee$,
          waitDialogRef.afterOpened()
        ]).pipe(
          concatMap(([authEmployee]) => this.dataSource.updateIdentity(employee, null, authEmployee)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public delete(employee: EmployeeModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Delete Employee', 'Are you certain you want to delete this employee?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Deleting...');

        combineLatest([
          this.authEmployeeProvider.authEmployee$,
          waitDialogRef.afterOpened()
        ]).pipe(
          concatMap(([authEmployee]) => this.dataSource.delete(employee, authEmployee)),
          catchError(() => of(null)),
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }
}
