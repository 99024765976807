<div *ngIf="resolvedCartItem">

  <div style="display: flex;">
    <div style="width: 53%;">{{ resolvedCartItem.name }}</div>
    <div style="width: 10%; text-align: right; padding-right: 5px;">{{ resolvedCartItem.quantity | number:'1.0-2' }}</div>
    <div style="width: 2%; text-align: center;">&#64;</div>
    <div style="width: 15%; text-align: right;">{{ resolvedCartItem.eachAmount | currency:'USD':'symbol':'1.2-2' }}</div>
    <div style="width: 20%; text-align: right;">{{ resolvedCartItem.total | currency:'USD':'symbol':'1.2-2' }}</div>
  </div>

  <div *ngIf="resolvedCartItem.configuration" style="display: flex; padding-left: 30px; padding-top: 4px;">

    <div>
      CONFIG
    </div>
    <!-- Show detail -->
    <!-- <div *ngIf="(editableTransaction.selectedLineItem | async) == item" [@slideIn] [@slideOut] style="overflow-y: hidden;">
      <transaction-product-configuration-detail [productConfiguratorContext]="productConfiguratorContext" [readOnly]="true"></transaction-product-configuration-detail>
    </div> -->

    <!-- Show summary -->
    <!-- <div *ngIf="(editableTransaction.selectedLineItem | async) != item" [@slideIn] [@slideOut] style="overflow-y: hidden;">
      <transaction-product-configuration-summary [transactionItem]="item"></transaction-product-configuration-summary>
    </div> -->

  </div>

</div>
