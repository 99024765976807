import { TransactionService } from "downtown-transaction";

export class ViewFactory {

  public static readonly Cart_IndexView = <CartViewOptions>{};
  public static readonly Cart_FullView = <CartViewOptions>{ includeItems: { includeDepartment: true, includeCategory: true } };

  public static buildCardTransactionView(viewOptions: CardTransactionViewOptions): string {

    let view = `uid ownerUid storeFrontUid orderUid processorReferenceId batchId name lastFour amount cardTransactionStatusUid createdDateTimeUtc modifiedDateTimeUtc `;

    if (viewOptions.includeOrder) {
      view += ` order ${ViewFactory.buildOrderView(viewOptions.includeOrder)}`;
    }

    return '{' + view + '}';
  }

  public static buildCardTransactionBatchView(viewOptions: CardTransactionBatchViewOptions): string {

    let view = `uid processorBatchId count amount creditSaleCount creditSaleAmount creditReturnCount creditReturnAmount debitSaleCount debitSaleAmount debitReturnCount debitReturnAmount openDateTimeUtc modifiedDateTimeUtc closeDateTimeUtc cardTransactionBatchStatusUid`;

    return '{' + view + '}';
  }

  public static buildCartView(viewOptions: CartViewOptions, depth: number = 0) {

    let view = `uid ownerUid storeFrontUid identityUid logisticTypeUid logisticScheduleUid logisticDateTimeUtc itemQuantity subTotalAmount cartStatusUid`;

    if (viewOptions.includeItems) {
      view += ` items ${ViewFactory.buildCartItemView(viewOptions.includeItems, depth)}`;
    }

    return '{ ' + view + ' }';
  }

  public static buildCartItemView(viewOptions: CartItemViewOptions, depth: number = 0): string {

    let view = `uid productUid productVersion eachAmount quantity `;

    view += ` configuration ${TransactionService.buildConfigurationView(depth)}`;

    return `{ ${view} }`;
  }

  public static buildClientView(viewOptions: ClientViewOptions): string {

    let view = 'uid name';

    return '{ ' + view + ' }';
  }

  public static buildIdentityUserView(viewOptions: IdentityUserViewOptions) {

    let view = `id username email emailConfirmed phoneNumber phoneNumberConfirmed twoFactorEnabled claims { type value }`;

    return '{ ' + view + ' }';
  }

  public static buildMemberView(viewOptions: MemberViewOptions) {

    let view = `uid identityId firstName lastName email memberStatusUid`;

    if (viewOptions.identityUser) {
      view += ` identityUser ${ViewFactory.buildIdentityUserView(viewOptions.identityUser)}`;
    }

    return '{ ' + view + ' }';
  }

  public static buildOrderView(viewOptions: OrderViewOptions): string {

    let view = `uid ownerUid storeFrontUid number cartUid initiatorIdentityUid logisticTypeUid logisticScheduleUid logisticDateTimeUtc logisticPaymentMethodUid shippingAddressUid shippingPhoneNumber billingAddressUid billingPhoneNumber orderQuantity fulfilledQuantity subTotalAmount shippingAmount taxAmount totalAmount openDateTimeUtc modifiedDateTimeUtc closeDateTimeUtc orderStatusUid`;

    view += ` initiatorIdentity { uid firstName lastName company email addressUid phoneNumber }`;

    if (viewOptions.includeShippingAddress) {
      view += ` shippingAddress { uid country address1 address2 city state postalCode longitude latitude }`;
    }
    if (viewOptions.includeBillingAddress) {
      view += ` billingAddress { uid country address1 address2 city state postalCode longitude latitude }`;
    }
    if (viewOptions.includeItems) {
      view += ` items ${ViewFactory.buildOrderItemView(viewOptions.includeItems)}`;
    }
    if (viewOptions.includeAdjustments) {
      view += ` adjustments ${ViewFactory.buildOrderAdjustmentView()}`;
    }
    if (viewOptions.includePayments) {
      view += ` payments ${ViewFactory.buildOrderPaymentView(viewOptions.includePayments)}`;
    }
    if (viewOptions.includeDisbursements) {
      view += ` disbursements { uid paymentMethodUid amount }`;
    }
    if (viewOptions.incudeCardTransactions) {
      view = view += ` cardTransactions ${ViewFactory.buildCardTransactionView(viewOptions.incudeCardTransactions)}`;
    }

    return '{' + view + '}';
  }

  public static buildOrderItemView(viewOptions: OrderItemViewOptions): string {

    let view = `uid name productUid eachAmount orderQuantity fulfilledQuantity deliveryTypeUid orderItemStatusUid `;

    if (viewOptions.configurationDepth > 0) {
      view += ` configuration ${ViewFactory.buildOrderItemConfigurationView(viewOptions)}`;
    }

    return `{ ${view} }`;
  }

  public static buildOrderItemConfigurationView(viewOptions: OrderItemViewOptions): string {

    let view = '';

    view += ` portion ${ViewFactory.buildOrderItemConfigurationPortionsView(viewOptions)}`;

    return '{ ' + view + ' }';
  }

  public static buildOrderItemConfigurationPortionsView(viewOptions: OrderItemViewOptions): string {

    let view = `portionUid`;

    view += ` variations ${ViewFactory.buildOrderItemConfigurationVariationsView()}`;
    view += ` preparations ${ViewFactory.buildOrderItemConfigurationPreparationsView()}`;
    view += ` inclusionGroups ${ViewFactory.buildOrderItemConfigurationInclusionGroupsView()}`;
    view += ` inclusions ${ViewFactory.buildOrderItemConfigurationInclusionsView()}`;
    view += ` extras ${ViewFactory.buildOrderItemConfigurationExtrasView()}`;
    view += ` addOns ${ViewFactory.buildOrderItemConfigurationAddOnsView(viewOptions)}`;

    return '{ ' + view + ' }';
  }

  public static buildOrderItemConfigurationVariationsView(): string {

    return '{ variationUid optionUid }';
  }

  public static buildOrderItemConfigurationPreparationsView(): string {

    return '{ preparationUid optionUid }';
  }

  public static buildOrderItemConfigurationInclusionGroupsView(): string {

    return `{ inclusionGroupUid options ${ViewFactory.buildOrderItemConfigurationInclusionGroupOptionsView()} }`;
  }

  public static buildOrderItemConfigurationInclusionGroupOptionsView(): string {

    let view = `optionUid quantity`;

    view += ` variations ${ViewFactory.buildOrderItemConfigurationVariationsView()}`;
    view += ` preparations ${ViewFactory.buildOrderItemConfigurationPreparationsView()}`;

    return '{ ' + view + ' }';
  }

  public static buildOrderItemConfigurationInclusionsView(): string {

    let view = `inclusionUid quantity`;

    view += ` variations ${ViewFactory.buildOrderItemConfigurationVariationsView()}`;
    view += ` preparations ${ViewFactory.buildOrderItemConfigurationPreparationsView()}`;

    return '{ ' + view + ' }';
  }

  public static buildOrderItemConfigurationExtrasView(): string {

    let view = `extraUid quantity`;

    view += ` variations ${ViewFactory.buildOrderItemConfigurationVariationsView()}`;
    view += ` preparations ${ViewFactory.buildOrderItemConfigurationPreparationsView()}`;

    return '{ ' + view + ' }';
  }

  public static buildOrderItemConfigurationAddOnsView(viewOptions: OrderItemViewOptions): string {

    let view = `addOnUid`;

    if (viewOptions.configurationDepth > 1) {
      viewOptions = Object.assign(<OrderItemViewOptions>{}, viewOptions);
      viewOptions.configurationDepth = viewOptions.configurationDepth - 1;

      view += ` item ${ViewFactory.buildOrderItemView(viewOptions)}`;
    }

    return '{ ' + view + ' }';
  }

  public static buildOrderItemAdjustmentView(): string {

    return `{ uid description quantity eachAmount isVoid isCompensated }`;
  }

  public static buildOrderAdjustmentView(): string {

    return `{ uid description amount isVoid isCompensated }`;
  }

  public static buildOrderPaymentView(viewOptions: OrderPaymentViewOptions): string {

    let view = `uid paymentMethodUid cardTransactionUid amount tip`;

    if (viewOptions.includeCardTransaction) {
      view += ` cardTransaction { uid ownerUid orderUid processorReferenceId batchId name lastFour amount tip cardTransactionStatusUid createdDateTimeUtc modifiedDateTimeUtc }`;
    }

    return `{ ${view} }`;
  }

  public static buildStoreFrontView(viewOptions: StoreFrontViewOptions): string {

    let view = `uid ownerUid name configuration { logistics { dineIn carryOut curbsidePickup delivery deliveryCharge } transactions { placeholder } }`;

    return '{' + view + '}';
  }
}

export interface CardTransactionViewOptions {

  includeOrder: OrderViewOptions
}

export interface CardTransactionBatchViewOptions {

}

export interface CartViewOptions {

  includeItems?: CartItemViewOptions;
}

export interface CartItemViewOptions {

  includeDepartment: boolean;
  includeCategory: boolean;
}


export interface ClientViewOptions {

}

export interface IdentityUserViewOptions {

}

export interface MemberViewOptions {

  identityUser?: boolean;
}

export interface OrderViewOptions {

  includeShippingAddress?: boolean;
  includeBillingAddress?: boolean;
  includeItems?: OrderItemViewOptions;
  includeAdjustments?: boolean;
  includePayments?: OrderPaymentViewOptions;
  includeDisbursements?: boolean;
  incudeCardTransactions?: CardTransactionViewOptions;
}

export interface OrderItemViewOptions {

  configurationDepth: number
}

export interface OrderPaymentViewOptions {

  includeCardTransaction?: boolean;
}

// export interface ProductViewOptions {

//   includeDepartment: boolean;
//   includeCategory: boolean;
//   configurationDepth: number
// }

export interface StoreFrontViewOptions {

}
