<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <mat-action-list>
    <div *ngFor="let option of options" style="display: flex; align-items: center;">
      <mat-checkbox *ngIf="data.allowMultiple" [checked]="option.selected" (click)="toggleOption(option)"></mat-checkbox>
      <button mat-list-item (click)="selectOption(option)">{{ option.display }}</button>
    </div>
  </mat-action-list>
</mat-dialog-content>
<mat-dialog-actions>
  <span style="flex: 1 1 auto;"></span>
  <button mat-flat-button color="warn" [mat-dialog-close]>Cancel</button>
  <button mat-flat-button *ngIf="data.allowMultiple" (click)="close()">OK</button>
</mat-dialog-actions>
