import { Component, Input } from "@angular/core";
import { EditableTransactionItem } from "../../models/editable-transaction/editable-transaction";

@Component({
  selector: 'transaction-item-configuration-summary',
  templateUrl: './item-configuration-summary.component.html',
  styleUrls: ['./item-configuration-summary.component.scss'],
})
export class ItemConfigurationSummaryComponent {

  @Input() public transactionItem: EditableTransactionItem;

  constructor(
  ) {
  }

  ngOnInit() {
  }
}
