import { Injectable } from "@angular/core";
import { HttpService } from "core";
import { Observable } from 'rxjs';
import { Page } from "core";
import { Position } from "../models/position";
import { OltpServiceSettings } from "../oltp-service-settings";
import { PaginationInput } from "core";
import { map, tap } from "rxjs/operators";
import { PosModelFactory } from '../pos-model-factory';

@Injectable()
export class PositionService {

  public static readonly PositionFullView = <PositionViewOptions>{};

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: PositionViewOptions = null, identityToken: string = null): Observable<Position> {

    let view = PositionService.buildView(viewOptions || PositionService.PositionFullView);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<Position>(this.oltpServiceSettings.apiUrl, 'api/oltp/position', request, 'getByUid', identityToken).pipe(
      map(x => PosModelFactory.assignPosition(x))
    );
  }

  getByUids(uids: string[], viewOptions: PositionViewOptions = null, identityToken: string = null): Observable<Position[]> {

    let view = PositionService.buildView(viewOptions || PositionService.PositionFullView);

    var request = {
      query: `query { getByUids (uids: ${JSON.stringify(uids)}) ${view} }`
    }

    return this.httpService.graph<Position[]>(this.oltpServiceSettings.apiUrl, 'api/oltp/position', request, 'getByUids', identityToken).pipe(
      map(x => x.map(y => PosModelFactory.assignPosition(y)))
    );
  }

  search(statusUids: string[], paginationInput: PaginationInput, viewOptions: PositionViewOptions = null, identityToken: string = null): Observable<Page<Position>> {

    let view = PositionService.buildView(viewOptions || PositionService.PositionFullView);

    var request = {
      query: `query search($pagination:PaginationInput) { search (statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<Position>>(this.oltpServiceSettings.apiUrl, 'api/oltp/position', request, 'search', identityToken).pipe(
      tap(x => x.edges.forEach(edge => edge.node = PosModelFactory.assignPosition(edge.node)))
    );
  }

  create(uid: string, name: string, description: string, viewOptions: PositionViewOptions = null, identityToken: string = null): Observable<Position> {

    let view = PositionService.buildView(viewOptions || PositionService.PositionFullView);

    var positionInput = {
      uid: uid,
      name: name,
      description: description
    };

    var request = {
      query: `mutation create($position:PositionInput!) { create(position: $position) ${view} }`,
      variables: { position: positionInput }
    };

    return this.httpService.graph<Position>(this.oltpServiceSettings.apiUrl, 'api/oltp/position', request, 'create', identityToken).pipe(
      map(x => PosModelFactory.assignPosition(x))
    );
  }

  update(uid: string, name: string, description: string, viewOptions: PositionViewOptions = null, identityToken: string = null): Observable<Position> {

    let view = PositionService.buildView(viewOptions || PositionService.PositionFullView);

    var positionInput = {
      uid: uid,
      name: name,
      description: description
    };

    var request = {
      query: `mutation update($position:PositionInput!) { update(position: $position) ${view} }`,
      variables: { position: positionInput }
    };

    return this.httpService.graph<Position>(this.oltpServiceSettings.apiUrl, 'api/oltp/position', request, 'update', identityToken).pipe(
      map(x => PosModelFactory.assignPosition(x))
    );
  }

  delete(uid: string, viewOptions: PositionViewOptions = null, identityToken: string = null): Observable<Position> {

    let view = PositionService.buildView(viewOptions || PositionService.PositionFullView);

    var request = {
      query: `mutation delete { delete(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Position>(this.oltpServiceSettings.apiUrl, 'api/oltp/position', request, 'delete', identityToken).pipe(
      map(x => PosModelFactory.assignPosition(x))
    );
  }

  public static buildView(viewOptions: PositionViewOptions) {

    let view = `uid name description positionStatusUid`;

    return '{ ' + view + ' }';
  }
}

export interface PositionViewOptions {

}

