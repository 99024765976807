<div class="editor">

  <ng-container *ngIf="order; else noOrder;">
    <ng-container *ngTemplateOutlet="hasOrder;"></ng-container>
  </ng-container>

</div>
<ng-template #hasOrder>

  <div style="display: flex; flex-direction: column;">

    <div *ngIf="order.orderStatusUid.value?.toUpperCase() != 'B4445AF7-0D14-4D92-80AF-32F197A4589A'" style="display: flex; margin-bottom: 6px;">
      <div style="height: 6vh; padding: 2px;">
        <button *ngIf="order.canEditNotes" mat-flat-button color="primary" style="height: 100%; min-width: 8.3333vw;" (click)="updateNotes.next(null)">{{ (order.notes | async) == null ? 'Enter Name...' : (order.notes | async) }}</button>
      </div>
      <div style="flex: 1 1 auto;"></div>
      <div style="height: 6vh; padding: 2px;">
        <!-- <button *ngIf="order.canEditLocation" mat-flat-button color="primary" style="height: 100%; min-width: 8.3333vw;" (click)="updateLocation.next()">{{ order.logisticTypeUid | logisticType }}</button> -->
      </div>
    </div>

    <div style="display: flex; border-bottom: 1px solid #000; margin-bottom: 10px;">
      <span *ngIf="canEdit" style="flex: 10;"></span>
      <span style="flex: 50;">ITEM</span>
      <span style="flex: 10; text-align: right;">QTY</span>
      <span style="flex: 10; text-align: right;">EACH</span>
      <span style="flex: 10; text-align: right;">TOTAL</span>
      <span *ngIf="canEdit" style="flex: 10;"></span>
    </div>

    <ng-container *ngFor="let orderItem of order.items">
      <app-order-item-editor [order]="order" [orderItem]="orderItem" [canEdit]="canEdit" (selectItem)="onSelectItem($event)"></app-order-item-editor>
    </ng-container>

    <div style="display: flex; margin-top: 2px;">
      <!-- <button *ngIf="canEdit" mat-flat-button style="font-size: 10px; line-height: 14px; padding: 4px;" (click)="addItem()">Add Item</button> -->
      <span style="flex: 1 1 auto;"></span>
    </div>

    <div>

      <div *ngIf="order.adjustments?.length > 0 || order.payments?.length > 0" style="display: flex; margin-top: 10px;">
        <span style="flex: 80; text-align: right; border-top: 1px solid #000;">Sub Total</span>
        <span style="flex: 10; text-align: right; border-top: 1px solid #000;">{{ order.subTotalAmount | currency:'USD':'symbol':'1.2-2' }}</span>
        <span *ngIf="canEdit" style="flex: 10; text-align: right; border-top: 1px solid #000;">
          <!-- <button mat-flat-button style="font-size: 10px; line-height: 14px; padding: 4px;" (click)="addAdjustment()">Add Adjustment</button> -->
        </span>
      </div>

      <ng-container *ngFor="let adjustment of order.adjustments">
        <!-- <div style="display: flex; border-radius: 4px; color: red;" [style.background-color]="(order.selectedLineItem | async) == adjustment ? 'gray' : 'transparent'" (click)="lineItemSelect.emit(adjustment)">
          <span style="width: 80%; text-align: right;">{{ adjustment.description | async }} </span>
          <span style="width: 20%; text-align: right;">{{ -(adjustment.total | async) | currency:'USD':'symbol':'1.2-2' }}</span>
        </div> -->

        <!-- <div *ngIf="(order.selectedLineItem | async) == adjustment && (adjustment.canVoid || adjustment.canCancel)" [@slideIn] [@slideOut] style="display: flex; overflow: hidden;">
          <div style="width: 20%; background-color: gray;">
            <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
          </div>
          <div style="background-color: gray; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 4px;">
            <button *ngIf="adjustment.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemVoid.emit(adjustment)">Remove</button>
            <button *ngIf="adjustment.canCancel" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="lineItemCancel.emit(adjustment)">Cancel</button>
          </div>
          <div style="flex: 1 1 auto; background-color: gray;">
            <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
          </div>
        </div> -->

      </ng-container>

      <div style="display: flex;">
        <span style="flex: 80; text-align: right;">Shipping</span>
        <span style="flex: 10; text-align: right;">{{ order.shippingAmount | currency:'USD':'symbol':'1.2-2' }}</span>
        <span *ngIf="canEdit" style="flex: 10;"></span>
      </div>

      <div style="display: flex;">
        <span style="flex: 80; text-align: right;">Tax</span>
        <span style="flex: 10; text-align: right;">{{ order.taxAmount | currency:'USD':'symbol':'1.2-2' }}</span>
        <span *ngIf="canEdit" style="flex: 10;"></span>
      </div>

      <div style="display: flex;">
        <span style="flex: 80; text-align: right;">Total</span>
        <span style="flex: 10; text-align: right;">{{ order.totalAmount | currency:'USD':'symbol':'1.2-2' }}</span>
        <span *ngIf="canEdit" style="flex: 10;"></span>
      </div>

      <div style="border-top: 1px solid #000; margin-top: 15px;">
        <ng-container *ngFor="let paymentItem of order.payments">
          <div style="display: flex; border-radius: 4px;" [style.background-color]="(order.selection | async) == paymentItem ? 'gray' : 'transparent'" (click)="selectItem.emit(paymentItem)">
            <span style="flex: 80;">

              <div *ngIf="paymentItem.cardTransaction">

                <div style="display: flex;">
                  <div style="flex: 1;">
                    <span>{{ paymentItem.cardTransaction.name | async }}</span>&nbsp;[
                    <span>{{ paymentItem.cardTransaction.lastFour | async }}</span>]
                  </div>

                  <div style="flex: 1;">
                    <span>Amount: {{ paymentItem.amount | async | currency:'USD':'symbol':'1.2-2' }}</span>
                  </div>

                  <div style="flex: 1;">
                    <span>{{ paymentItem.cardTransaction.cardTransactionStatusUid | async | cardTransaction2Status }}</span>
                  </div>
                </div>

                <div style="display: flex;">
                  <div style="flex: 1;">
                  </div>

                  <div style="flex: 1;">
                    <span>Tip: {{ paymentItem.tip | async | currency:'USD':'symbol':'1.2-2' }}</span>
                  </div>

                  <div style="flex: 1;">
                  </div>
                </div>
              </div>

            </span>
            <!-- <div style="flex: 10; text-align: right;">{{ paymentItem.paymentMethodUid | paymentMethod | async }}</div> -->
            <div style="flex: 10; text-align: right;">{{ -(paymentItem.amount | async) | currency:'USD':'symbol':'1.2-2' }}</div>
            <div *ngIf="canEdit" style="flex: 10;"></div>
          </div>

          <div *ngIf="(order.selection | async) == paymentItem && (paymentItem.canVoid || paymentItem.canCancel)" [@slideIn] [@slideOut] style="display: flex; overflow: hidden;">
            <div style="width: 20%; background-color: gray;">
              <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
            </div>
            <div *ngIf="paymentItem.cardTransaction" style="background-color: gray; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 4px;">

              <button mat-flat-button [disabled]="!paymentItem.cardTransaction?.canTip" (click)="updateTip.emit(paymentItem)">Update Tip</button>
              <button mat-flat-button [disabled]="!paymentItem.cardTransaction?.canVoid" (click)="voidPayment.emit(paymentItem)">Void</button>
              <!-- <button mat-flat-button [disabled]="!paymentItem.cardTransaction?.canReverse" (click)="reversal(paymentItem)">Reversal</button> -->
              <button mat-flat-button [disabled]="!paymentItem.cardTransaction?.canCapture" (click)="capturePayment.emit(paymentItem)">Capture</button>
              <button mat-flat-button [disabled]="!paymentItem.cardTransaction?.canRefund" (click)="refundPayment.emit(paymentItem)">Refund</button>
            </div>
            <div style="flex: 1 1 auto; background-color: gray;">
              <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
            </div>
          </div>
        </ng-container>
      </div>

      <div style="display: flex; margin-top: 2px;">
        <span style="flex: 1 1 auto;"></span>
        <!-- <button *ngIf="canEdit" mat-flat-button style="font-size: 10px; line-height: 14px; padding: 4px;" (click)="addPayment()">Add Payment</button> -->
      </div>

      <div style="display: flex; border-top: 1px solid #000; margin-top: 2px;">

        <ng-container *ngIf="order.balance != null">
          <div class="total-headings" style="flex: 80; text-align: right;">Balance Due</div>
          <div class="total-headings" style="flex: 10; text-align: right;">{{ order.balance | currency:'USD':'symbol':'1.2-2' }}</div>
          <div *ngIf="canEdit" style="flex: 10;"></div>
        </ng-container>

        <ng-container *ngIf="order.balance < 0">
          <div class="total-headings" style="flex: 80; text-align: right;">Refund Amount</div>
          <div class="total-headings" style="flex: 10; text-align: right;">{{ -order.balance | currency:'USD':'symbol':'1.2-2' }}</div>
          <div *ngIf="canEdit" style="flex: 10;"></div>
        </ng-container>

      </div>

    </div>
  </div>

</ng-template>

<ng-template #noOrder>
  <div style="height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <span>No Order</span>
  </div>
</ng-template>
