import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PrintDepartmentSlipModalData, PrintDepartmentSlipModalResult } from "./print-department-slip-modal.provider";
import { CurrentDateTimeProvider, TimeoutProvider, SettingProvider, TenantProvider } from "core";
import { Subject, takeUntil } from "rxjs";
import { PosSettings } from "../../models";

@Component({
  selector: 'app-print-department-slip-modal',
  templateUrl: './print-department-slip-modal.component.html',
  styleUrls: ['./print-department-slip-modal.component.scss']
})
export class PrintDepartmentSlipModalComponent {

  public options: PrintOption[];
  public allIsSelected = false;

  private timeoutProvider: TimeoutProvider;
  private destroyed$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<PrintDepartmentSlipModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PrintDepartmentSlipModalData,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider,
    private currentDateTimeProvider: CurrentDateTimeProvider
  ) {
    this.options = this.data.guests.filter(x => x).map(x => <PrintOption>{
      uid: x.uid,
      display: x.name,
      selected: false
    });

    if (this.data.allowUnassigned) {
      this.options.push(<PrintOption>{
        uid: null,
        display: 'Unassigned',
        selected: false
      });
    }

    this.toggleAll();

    this.settingProvider.getOneByTypeAndOwner$<PosSettings>('PosSettings', this.tenantProvider.currentUid).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(posSettings => {
      this.timeoutProvider = new TimeoutProvider(this.currentDateTimeProvider, this.destroyed$, posSettings.dialogAutocloseDuration);
      this.timeoutProvider.inactive$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(_ => this.close());
    });
  }

  public ngOnDestroy() {

    this.destroyed$.next(null);
  }

  public toggleAll() {

    this.allIsSelected = !this.allIsSelected;
    this.options.forEach(x => x.selected = this.allIsSelected);
  }

  toggleOption(option: PrintOption) {

    option.selected = !option.selected;
    this.allIsSelected = this.options.filter(x => x.selected).length == this.options.length;
  }

  public print() {

    this.dialogRef.close(<PrintDepartmentSlipModalResult>{
      guestUids: this.allIsSelected ? null : this.options.filter(x => x.selected).map(x => x.uid)
    });
  }

  close() {

    this.dialogRef.close();
  }
}

interface PrintOption {

  uid: string;
  display: string;
  selected: boolean;
}
