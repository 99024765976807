<ng-container *ngIf="itemConfiguration">

  <div *ngIf="itemConfiguration?.portion">
    <transaction-item-configuration-detail-portion [context]="context" [portion]="itemConfiguration.portion" (configurationChanged)="handleConfigurationChanged()"></transaction-item-configuration-detail-portion>
  </div>

  <div *ngFor="let inclusionGroup of getVisibleInclusionGroups()" style="margin-left: 10%;">
    <transaction-item-configuration-detail-inclusion-group [context]="context" [inclusionGroup]="inclusionGroup" (configurationChanged)="handleConfigurationChanged()"></transaction-item-configuration-detail-inclusion-group>
  </div>

  <div *ngIf="itemConfiguration?.portion.addOns?.length > 0">&nbsp;</div>

  <div *ngFor="let addOn of itemConfiguration?.portion.addOns">
    <transaction-item-configuration-detail-add-on [context]="context" [addOn]="addOn" (configurationChanged)="handleConfigurationChanged()"></transaction-item-configuration-detail-add-on>
  </div>

</ng-container>
