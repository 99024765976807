import { Injectable } from '@angular/core';
import { ObservableCacheProvider } from 'core';
import { BehaviorSubject, Observable, of } from "rxjs";
import { shareReplay } from 'rxjs/operators';
import { CategoryStatusKeys } from '../keys';
import { TenantProvider } from 'core';
import { Category } from '../models';
import { CategoryService } from '../services';

/**
 * Provider meant to be a cache enabled alternative to calling ProductDepartmentService directly, with additional members for getting ProductDepartment in different states (ie: Active).
 */
@Injectable()
export class CategoryProvider {

  public currentActive$: Observable<Category[]>;

  private currentActiveSubject = new BehaviorSubject<Category[]>(null);

  constructor(
    private cacheProvider: ObservableCacheProvider,
    private tenantProvider: TenantProvider,
    private categoryService: CategoryService,
  ) {
    this.currentActive$ = this.currentActiveSubject.asObservable();

    this.categoryService.search(this.tenantProvider.currentUid, null, [CategoryStatusKeys.Active], null).subscribe(categoriesPage => {

      const categories = categoriesPage.edges.map(x => x.node);
      categories.forEach(category => {
        this.cacheProvider.update(this.getCacheKey(category.uid), () => of(category).pipe(shareReplay(1)));
      });
    });
  }

  public getOneCached$(uid: string): Observable<Category> {

    return this.cacheProvider.getOrAdd(this.getCacheKey(uid), () => this.getOne$(uid))
  }

  public getOne$(uid: string): Observable<Category> {

    return this.categoryService
      .getByUid(uid)
      .pipe(shareReplay(1));
  }

  private getCacheKey(uid: string): string {

    return `Category_${uid}`;
  }
}
