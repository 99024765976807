export const enum CardSwipeEventTypeEnum {
  cardSwiped,
  drawerClosed
}

export interface CaptureTokenEventModel {

  amount: number;
  captureToken: CaptureTokenEvent;
}

export declare class CaptureTokenEvent {
  token: string;
  encryption: string;
  lastFour: string;
  firstName: string;
  middleInitial: string;
  surname: string;
}


export interface CardSwipeEventModel {

  status: CardSwipeStatusEnum;
  event: CardSwipe;
}

export const enum CardSwipeStatusEnum {

  Success = 'Success',
  Skipped = 'Skipped',
  Error = 'Error',
  Cancelled = 'Cancelled'
}
export class CardSwipe {

  messageType: CardSwipeEventTypeEnum;
  lastFour: string;
  firstName: string;
  middleInitial?: string;
  surname: string;
  expirationMonth: string;
  expirationYear: string;
}
