import { findInArrayByUid, someInArrayByUid } from "core";

export class Product {

  static configurableChecks: ((x: Product) => boolean)[] = [
    (product: Product) => product.configuration.portions && product.configuration.portions.length > 1,
    (product: Product) => product.configuration.portions.some(x => x.variations && x.variations.length > 0),
    (product: Product) => product.configuration.portions.some(x => x.preparations && x.preparations.length > 0),
    (product: Product) => product.configuration.portions.some(x => x.inclusionGroups && x.inclusionGroups.length > 0),
    (product: Product) => product.configuration.portions.some(x => x.addOns && x.addOns.length > 0),
  ];

  uid: string;
  ownerUid: string;
  sku: string;
  name: string;
  abbreviation: string;
  description: string;
  imageUid: string;
  displayOrder: number;
  departmentUid: string;
  categoryUid: string;
  productTypeUid: string;
  basePrice: number;
  productStatusUid: string;
  version: number;

  configuration: ProductConfiguration;

  isConfigurable(): boolean {

    return Product.configurableChecks.some(x => x(this));
  }
}

export class ProductConfiguration {

  portions: ProductConfigurationPortion[];
  inclusionGroups: ProductConfigurationInclusionGroup[];
  addOns: ProductConfigurationAddOn[];
  preparations: ProductConfigurationPreparation[];
  variations: ProductConfigurationVariation[];

  getPortion(portionUid: string): ProductConfigurationPortion {

    return findInArrayByUid(this.portions, portionUid);
  }

  getVariation(variationUid: string): ProductConfigurationVariation {

    return findInArrayByUid(this.variations, variationUid);
  }

  getPreparation(preparationUid: string): ProductConfigurationPreparation {

    return findInArrayByUid(this.preparations, preparationUid);
  }

  getInclusionGroup(inclusionGroupUid: string): ProductConfigurationInclusionGroup {

    return findInArrayByUid(this.inclusionGroups, inclusionGroupUid);
  }

  getInclusionGroupOption(inclusionGroupOptionUid: string) {

    var inclusionGroup = this.inclusionGroups?.find(x => someInArrayByUid(x.options, inclusionGroupOptionUid));
    return findInArrayByUid(inclusionGroup.options, inclusionGroupOptionUid);
  }

  getAddOn(addOnUid: string): ProductConfigurationAddOn {

    return findInArrayByUid(this.addOns, addOnUid);
  }
}

export class ProductConfigurationProductReference {

  uid: string;
  portionUid: string;
  version?: number;
}

export class ProductConfigurationPreparation {

  uid: string;
  name: string;
  allowNone: boolean;
  options: ProductConfigurationPreparationOption[];

  getOption(optionUid: string): ProductConfigurationPreparationOption {

    return findInArrayByUid(this.options, optionUid);
  }
}

export class ProductConfigurationPreparationOption {

  uid: string;
  name: string;
  additionalPrice?: number;
  hideInPrintDetail?: boolean;
  hideInUIDetail?: boolean;
}

export class ProductConfigurationVariation {

  uid: string;
  name: string;
  defaultOptionUid?: string;
  options: ProductConfigurationVariationOption[];

  getOption(optionUid: string): ProductConfigurationVariationOption {

    return findInArrayByUid(this.options, optionUid);
  }
}

export class ProductConfigurationVariationOption {

  uid: string;
  productReference: ProductConfigurationProductReference;
  alias?: string;
  priceOverride?: number;
}

export class ProductConfigurationPortion {

  static configurableChecks: ((x: ProductConfigurationPortion) => boolean)[] = [
    (portion: ProductConfigurationPortion) => (portion.inclusionGroups && portion.inclusionGroups.length > 0),
    (portion: ProductConfigurationPortion) => (portion.addOns && portion.addOns.length > 0)
  ];

  uid: string;
  name: string;
  menuPlacementUid: string;
  availableAsInclusion: boolean;
  availableAsAddOn: boolean;
  tabs?: ProductConfigurationTab[];
  preparations?: ProductConfigurationPreparation[];
  variations?: ProductConfigurationVariation[];
  inclusionGroups?: ProductConfigurationInclusionGroup[];
  addOns?: ProductConfigurationAddOn[];
  price?: number;
  productStatusUid: string;

  isConfigurable(): boolean {

    return ProductConfigurationPortion.configurableChecks.some(x => x(this));
  }

  getVariation(variationUid: string): ProductConfigurationVariation {

    return findInArrayByUid(this.variations, variationUid);
  }

  getPreparation(preparationUid: string): ProductConfigurationPreparation {

    return findInArrayByUid(this.preparations, preparationUid);
  }

  getInclusionGroup(inclusionGroupUid: string): ProductConfigurationInclusionGroup {

    return findInArrayByUid(this.inclusionGroups, inclusionGroupUid);
  }

  getAddOn(addOnUid: string): ProductConfigurationAddOn {

    return findInArrayByUid(this.addOns, addOnUid);
  }
}

export class ProductConfigurationTab {

  name?: string;
  inclusionGroupUids?: string[];
  addOnUids?: string[];
}

export class ProductConfigurationInclusionGroup {

  uid: string;
  name?: string;
  maxDistinctOptionsIncluded?: number;
  minQuantityForOption?: number;
  maxTotalOptionQuantityIncluded?: number;
  allowSubstitution?: boolean;
  allowAdditional?: boolean;
  hideInPrintDetail?: boolean;
  hideInUIDetail?: boolean;
  options: ProductConfigurationInclusionGroupOption[];
  preparations?: ProductConfigurationPreparation[];
  variations?: ProductConfigurationVariation[];

  getOption(optionUid: string): ProductConfigurationInclusionGroupOption {

    return findInArrayByUid(this.options, optionUid);
  }
}

export class ProductConfigurationInclusionGroupOption {

  uid: string;
  productReference: ProductConfigurationProductReference;
  alias?: string;
  defaultQuantity?: number;
  maxIncludedQuantity?: number;
  priceOverride?: number;
  preparations?: ProductConfigurationPreparation[];
  variations?: ProductConfigurationVariation[];

  getVariation(variationUid: string): ProductConfigurationVariation {

    return findInArrayByUid(this.variations, variationUid);
  }

  getPreparation(preparationUid: string): ProductConfigurationPreparation {

    return findInArrayByUid(this.preparations, preparationUid);
  }
}

export class ProductConfigurationAddOn {

  uid: string;
  productReference: ProductConfigurationProductReference;
  alias?: string;
  priceOverride?: number;

  product: Product
}
