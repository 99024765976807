<div [formGroup]="context.identityForm" style="display: flex;">
  <div style="flex: 1;">
    Identity
  </div>
  <div style="flex: 3;">
    <div>
      <mat-form-field style="width: 100%;">
        <input matInput type="text" formControlName="firstName" placeholder="First Name *" autocomplete="firstName" />
        <mat-error *ngIf="context.identityForm.get('firstName').hasError('required')">First Name is required</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <input matInput type="text" formControlName="lastName" placeholder="Last Name *" autocomplete="lastName" />
        <mat-error *ngIf="context.identityForm.get('lastName').hasError('required')">Last Name is required</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <input matInput type="text" formControlName="company" placeholder="Company" autocomplete="company" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 50%;">
        <input matInput type="text" formControlName="email" placeholder="Email *" autocomplete="email" />
        <mat-error *ngIf="context.identityForm.get('email').hasError('required')">Email is required</mat-error>
        <mat-error *ngIf="context.identityForm.get('email').hasError('email')">Email is not valid</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <input matInput type="text" formControlName="phoneNumber" placeholder="Phone Number *" autocomplete="phone" />
        <mat-error *ngIf="context.identityForm.get('email').hasError('required')">Phone is required</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
