import { Injectable } from '@angular/core';
import { CurrentDateTimeProvider, ErrorHandlingProvider, Page, sortByKey } from 'core';
import { getHourMinuteSecondFromString, addDays, addMinutes } from 'core';
import { ScheduleStatusKeys } from '../keys';
import { Drawer } from '../models/drawer';
import { Schedule } from '../models/schedule';
import { ScheduleService } from '../services/schedule.service';
import { BehaviorSubject, Observable, catchError } from "rxjs";
import { TerminalProvider } from 'downmain-terminal';

@Injectable()
export class ScheduleProvider {

  public active$: Observable<Schedule[]>;

  private activeSubject$ = new BehaviorSubject<Schedule[]>(null);
  private updating = false;

  constructor(
    private currentDateTimeProvider: CurrentDateTimeProvider,
    private terminalProvider: TerminalProvider,
    private scheduleService: ScheduleService,
    private errorHandlingProvider: ErrorHandlingProvider
  ) {
    this.active$ = this.activeSubject$.asObservable();

    this.getActiveSchedules(this.currentDateTimeProvider.currentDateTime);

    this.currentDateTimeProvider.currentDateTime$.subscribe(currentDateTime => {

      if (currentDateTime?.getSeconds() == 0 && !this.updating) {
        this.getActiveSchedules(currentDateTime);
      }
    });
  }

  public setActiveSchedules(drawer: Drawer) {

    this.activeSubject$.next(null);
  }

  private getActiveSchedules(currentDateTime: Date) {

    if (this.terminalProvider.active) {
      this.scheduleService.search([ScheduleStatusKeys.Active], null).pipe(
        catchError(error => this.errorHandlingProvider.handleError<Page<Schedule>>(error)),
      ).subscribe(schedulesPage => {
        if (schedulesPage) {
          schedulesPage.edges = [schedulesPage.edges[1]];

          var activeSchedules = schedulesPage.edges.map(x => x.node).filter(schedule => {

            schedule.includeTimeBlocks = [schedule.includeTimeBlocks[0]];

            return schedule.includeTimeBlocks.some(includeTimeBlock => {

              let frequencyDays = 0;
              switch (includeTimeBlock.frequency) {
                case 'daily': {
                  frequencyDays = 1;
                  break;
                }
                case 'weekly': {
                  frequencyDays = 7;
                  break;
                }
                case 'yearly': {
                  frequencyDays = 365;
                  break;
                }
              }

              const [startHour, startMinute] = getHourMinuteSecondFromString(includeTimeBlock.startHourMinute);
              const [endHour, endMinute] = getHourMinuteSecondFromString(includeTimeBlock.endHourMinute);

              // Find start of current iteration
              let iterationStartDate = includeTimeBlock.startDate;
              let iterationEndDate = addDays(iterationStartDate, frequencyDays);
              iterationEndDate = addMinutes(iterationEndDate, - 1);

              while (currentDateTime > iterationEndDate) {
                let intervalDate = new Date(iterationStartDate.getTime());

                // Get start of date component
                iterationStartDate = addDays(intervalDate, frequencyDays);
                iterationEndDate = addDays(iterationStartDate, frequencyDays);
                if (endHour < startHour || (endHour == startHour && endMinute < startMinute)) {
                  // add the overrage
                  iterationEndDate = new Date(new Date(iterationEndDate.getTime()).setHours(endHour));
                  iterationEndDate = new Date(new Date(iterationEndDate.getTime()).setMinutes(endMinute));
                } else {
                  // set to end of previous date
                  iterationEndDate = addMinutes(iterationEndDate, -1);
                }
              }

              // Build block days
              let iterationStartDate2 = new Date(iterationStartDate.getTime());
              let iterationTimeBlockDays = includeTimeBlock.days.map(iterationDay => {
                let baseDate = addDays(iterationStartDate2, iterationDay);

                let startYear = baseDate.getFullYear();
                let startMonth = baseDate.getMonth();
                let startDate = baseDate.getDate();

                let iterationTimeBlockStartDate = new Date(startYear, startMonth, startDate, startHour, startMinute);

                let endYear = baseDate.getFullYear();
                let endMonth = baseDate.getMonth();
                let endDate = baseDate.getDate();

                if (endHour < startHour || (endHour == startHour && endMinute < startMinute)) {
                  endDate += 1;
                }

                let iterationTimeBlockEndDate = new Date(endYear, endMonth, endDate, endHour, endMinute);

                return [iterationTimeBlockStartDate, iterationTimeBlockEndDate];
              });

              return iterationTimeBlockDays.some(x => currentDateTime >= x[0] && currentDateTime < x[1]);
            });
          });

          let changed = false;
          let currentActiveSchedules = (this.activeSubject$.value || []).slice();
          activeSchedules.forEach(activeSchedule => {
            if (!currentActiveSchedules.some(x => x.uid == activeSchedule.uid)) {
              currentActiveSchedules.push(activeSchedule);
              changed = true;
            }
          });

          let nextActiveSchedules = currentActiveSchedules.filter(x => activeSchedules.some(y => y.uid == x.uid));

          changed = changed || nextActiveSchedules.length != currentActiveSchedules.length;
          if (changed) {
            this.activeSubject$.next(sortByKey(nextActiveSchedules, 'name'));
          }
        }
      });
    }
  }
}
