<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <div *ngFor="let selection of selections">
    <mat-action-list>
      <button mat-list-item (click)="select(selection)">{{ selection?.display }}</button>
    </mat-action-list>
  </div>

  <!-- <mat-action-list>
    <button mat-list-item *ngFor="let option of data.saleableProducts" (click)="selectOption(option)">{{ data.displayFunc(option) }}</button>
  </mat-action-list> -->
</mat-dialog-content>
<mat-dialog-actions>
  <span style="flex: 1 1 auto;"></span>
  <button mat-stroked-button color="warn" [mat-dialog-close]>Cancel</button>
</mat-dialog-actions>
