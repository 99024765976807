import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpinnerModalProvider, TenantProvider } from 'core';
import { NavigationProvider } from 'core';
import { DepartmentForm } from 'downtown-product';
import { AccountSetting } from 'pos-core';
import { AccountSettingService } from 'pos-core';
import { DepartmentService } from 'downtown-product';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-back-office-department',
  templateUrl: './back-office-department.component.html',
  styleUrls: ['./back-office-department.component.scss']
})
export class BackOfficeDepartmentComponent extends DepartmentForm implements OnInit {

  public title: string;
  public accountSettings: AccountSetting[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountSettingService: AccountSettingService,
    navigationProvider: NavigationProvider,
    spinnerModalProvider: SpinnerModalProvider,
    tenantProvider: TenantProvider,
    departmentService: DepartmentService,
  ) {
    super(navigationProvider, spinnerModalProvider, tenantProvider, departmentService);

    this.navigationProvider.setWaypoint('Product Department');
    this.title = 'Product Department';
  }

  ngOnInit() {

    combineLatest([
      this.accountSettingService.search(null, null),
      this.activatedRoute.params
    ]).subscribe(([accountSettingsPage, params]) => {
      this.accountSettings = accountSettingsPage.edges.map(x => x.node);

      let departmentUid = params['departmentUid'];
      if (departmentUid) {
        let spinnerModalRef = this.spinnerModalProvider.open();
        spinnerModalRef.afterOpened().subscribe(() => {
          this.departmentService.getByUid(departmentUid).subscribe(department => {
            if (department) {
              this.loadForm(department);

              spinnerModalRef.close();
            } else {
              this.navigationProvider.backOneWaypoint();
            }
          });
        });
      }
    });
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }
}
