export class Identity {

  uid: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  addressUid: string;
  phoneNumber: string;

  getFullName(): string {

    if (this.firstName || this.lastName) {
      return `${this.firstName || ''} ${this.lastName || ''}`.trim();
    }

    return '';
  }

  getDisplay(): string {

    let value = this.getFullName();
    if (value == null || value.length == 0) {
      value = this.email;
    }

    return value;
  }
}
