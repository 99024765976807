import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: 'input[numeric-only]'
})
export class NumericOnlyInputDirective implements OnInit {

  @Input('numeric-only') maxLength: string;

  constructor(
    private elementRef: ElementRef,
    private _renderer: Renderer2
  ) { }

  ngOnInit(): void {

    __ngRendererSetElementAttributeHelper(this._renderer, this.elementRef.nativeElement, 'maxlength', this.maxLength);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    let e = <KeyboardEvent>event;
    const key = event.which || event.keyCode;
    if ([46, 8, 9, 27, 13, 110].indexOf(key) !== -1 ||
      // Allow: Ctrl+A
      (key === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (key === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (key === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (key === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (key >= 35 && key <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (key < 48 || key > 57)) && (key < 96 || key > 105)) {
      e.preventDefault();
    }
  }
}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
    if (name[0] === ":") {
        const match = name.match(/^:([^:]+):(.+)$/);
        return [match[1], match[2]];
    }
    return ["", name];
}

function __ngRendererSetElementAttributeHelper(renderer: AnyDuringRendererMigration, element: AnyDuringRendererMigration, namespaceAndName: AnyDuringRendererMigration, value?: AnyDuringRendererMigration) {
    const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
    if (value != null) {
        renderer.setAttribute(element, name, value, namespace);
    }
    else {
        renderer.removeAttribute(element, name, namespace);
    }
}
