import { TransactionLine } from "./transaction-line";
import { BehaviorSubject, combineLatest } from "rxjs";
import { TransactionEvent } from "./transaction-event";
import { TransactionEventType } from "./transaction-event-type";
import Decimal from "decimal.js";

export class TransactionPaymentLine extends TransactionLine {

  static commitConditions = <((x: TransactionPaymentLine) => boolean)[]>[
    x => !x.previousValues || x.isChangedFrom(x.previousValues, x.getValues())
  ];

  paymentMethodUid = new BehaviorSubject<string>(null);
  referenceUid = new BehaviorSubject<string>(null);
  amountText = new BehaviorSubject<string>(null);
  cardTransaction = new BehaviorSubject<CardTransactionLine>(null);
  canAddTip = new BehaviorSubject<boolean>(true);

  get typeName() { return 'TransactionPaymentLine'; }

  constructor(
  ) {
    super();

    combineLatest([this.isEditing$]).subscribe(x => {
      let isEditing = this.isEditing;
      if (isEditing) {
        this.previousValues = this.getValues();
      }

      this.canCancel.next(isEditing);
      this.canVoid.next(isEditing && this.uid != null);
      this.canAddTip.next(isEditing && this.uid != null && this.cardTransaction.value != null);
      this.canAdjust.next(false);
    });
  }

  private getValues() {

    return {
      ['paymentMethodUid']: this.paymentMethodUid.value,
      ['amountText']: this.amountText.value,
      ['cardTransaction']: this.cardTransaction.value
    };
  }

  keyPressed(value: string) {

    let keyValue = value.toLowerCase();

    if (keyValue == "00") {
      this.keyPressed("0");
      this.keyPressed("0");
    } else if (keyValue == ".") {
      let amount = this.amountText.value;
      let decimalIndex = amount.indexOf('.');

      let wholeDigits = amount.substr(0, decimalIndex + 2).replace('.', '');
      let decimalDigits = amount.substr(decimalIndex + 2);

      amount = parseInt(wholeDigits + decimalDigits).toString() + ".";
      this.amountText.next(amount);
    } else if (keyValue == "back") {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        // Shift all digits right
        amount = Math.floor(amount * 10) / 100;

        if (amount == 0 && !this.isValid) {
          // this.cancel(); // WHY IS THIS COMMENTED OUT ?
        } else {
          this.amountText.next(amount.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        }
      }
    } else if (keyValue == "enter") {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        this.commit();
      }
    } else if (keyValue == "clear") {
      this.clearAmount();
    } else {
      let digit = parseInt(value);
      if (!Number.isNaN(digit)) {
        let textAmount = this.amountText.value.concat(keyValue);

        let validAmount = new Decimal(textAmount);
        if (Number.isNaN(validAmount)) {
          let decimalIndex = textAmount.indexOf('.');
          if (decimalIndex > 0 && textAmount.length - decimalIndex > 3) {
            // Need to shift the decimal point
            textAmount = (new Decimal(textAmount.slice(0, decimalIndex + 2).replace('.', '')).toString()) + '.' + (textAmount.slice(decimalIndex + 2));
          }

          this.amountText.next(textAmount);
        }
      }
    }
  }

  cancel() {

    if (this.previousValues) {
      // Revert to previous values
      this.paymentMethodUid.next(this.previousValues['paymentMethodUid']);
      this.amountText.next(this.previousValues['amountText']);
      this.cardTransaction.next(this.previousValues['cardTransaction']);
    }

    this.previousValues = null;
    this.isEditing$.next(false);

    this.onEvent.next(new TransactionEvent(this, TransactionEventType.Cancelled, null));
  }

  adjust() {

  }

  void() {

    if (this.previousValues) {
      // Revert to previous values
      this.paymentMethodUid.next(this.previousValues['paymentMethodUid']);
      this.amountText.next(this.previousValues['amountText']);
      this.cardTransaction.next(this.previousValues['cardTransaction']);
    }

    this.previousValues = null;
    this.isEditing$.next(false);

    this.onEvent.next(new TransactionEvent(this, TransactionEventType.Voided, null));
  }

  commit() {

    if (TransactionPaymentLine.commitConditions.every(x => x(this))) {
      this.isEditing$.next(false);
      this.previousValues = null;

      this.onEvent.next(new TransactionEvent(this, TransactionEventType.Committed, null));
    } else {
      // Uncommittable, cancel instead of commit
      this.cancel();
    }
  }

  clearAmount() {

    this.amountText.next(new Decimal(0).toFixed(2));
  }

  getAmount(): number {

    return new Decimal(this.amountText.value).toNumber();
  }
}

export class CardTransactionLine {

  uid: string;
  tipText = new BehaviorSubject<string>(null);
  lastFour = new BehaviorSubject<string>(null);
  cardTransactionStatusUid: string;
}
