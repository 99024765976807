import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Observable, combineLatest, map, of } from 'rxjs';
import { ItemConfiguratorContext } from "../../item-configurator-context";
import { SelectListModalData, SettingProvider, TenantProvider, findInArrayByKey, isEqualUUID, slideInAnimation, slideOutAnimation } from "core";
import { ProductConfigurationVariation, ProductConfigurationVariationOption } from "downtown-product";
import { EditableTransactionItemConfigurationVariation, TransactionSettings } from "../../../../models";
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: 'transaction-item-configurator-mods-variation',
  templateUrl: './item-configurator-mods-variation.component.html',
  styleUrls: ['./item-configurator-mods-variation.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfiguratorModsVariationComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public item: { variations?: EditableTransactionItemConfigurationVariation[] }
  @Input() public itemDisplay: Observable<string>
  @Input() public productVariation: ProductConfigurationVariation;
  @Input() public itemVariation: EditableTransactionItemConfigurationVariation;
  @Output() public configurationChanged = new EventEmitter();

  public transactionSettings$: Observable<TransactionSettings>;
  public display: string;
  public availableVariationOptions: ProductConfigurationVariationOption[];

  constructor(
    private currencyPipe: CurrencyPipe,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider
  ) {
    this.transactionSettings$ = this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid);
  }

  ngOnInit() {

    this.display = this.productVariation.name;
    this.availableVariationOptions = this.productVariation.options;
  }

  public getProductVariationOptionDisplay(productVariationOption: ProductConfigurationVariationOption): Observable<string> {

    return this.context.localCache.getOrAdd(`${this.context.product.uid}_${productVariationOption.uid}`, () => {

      return this.context.productProvider.getOneCached$(productVariationOption.productReference.uid, productVariationOption.productReference.version).pipe(
        map(product => {
          let display = productVariationOption.alias || product.name;

          const productPortion = product.configuration?.portions?.find(x => isEqualUUID(x.uid, productVariationOption.productReference.portionUid));
          let additionalPrice = productPortion ? productPortion.price || 0 : 0;

          return `${display} ${(additionalPrice ? `+ ${this.currencyPipe.transform(additionalPrice, 'USD', 'symbol', '1.2-2')}` : '')}`
        })
      );
    });
  }

  public selectOption(productVariationOption: ProductConfigurationVariationOption) {

    if (productVariationOption) {
      if (this.itemVariation && this.productVariation.options.length == 1) {
        if (this.itemVariation) {
          this.item.variations.splice(this.item.variations.indexOf(this.itemVariation), 1);
          this.itemVariation = null;
        }
      } else {
        if (!this.itemVariation) {
          this.itemVariation = <EditableTransactionItemConfigurationVariation>{
            variationUid: this.productVariation.uid,
            productUid: productVariationOption.productReference.uid,
            productVersion: productVariationOption.productReference.version,
            productPortionUid: productVariationOption.productReference.portionUid,
          };

          this.item.variations = this.item.variations || [];
          this.item.variations.push(this.itemVariation);
        }

        this.itemVariation.optionUid = productVariationOption.uid;
      }

      this.configurationChanged.emit();
    }
  }
}
