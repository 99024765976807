export class CardTransactionBatch {

  uid: string;
  processorBatchId: string;
  count: number;
  amount: number;
  creditSaleCount: number;
  creditSaleAmount: number;
  creditReturnCount: number;
  creditReturnAmount: number;
  debitSaleCount: number;
  debitSaleAmount: number;
  debitReturnCount: number;
  debitReturnAmount: number;
  openDateTimeUtc: Date;
  modifiedDateTimeUtc: Date;
  closeDateTimeUtc: Date;
  cardTransactionBatchStatusUid: string;
}
