import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthConfiguration } from './auth.configuration';
import { OidcConfigService } from './services/oidc.security.config.service';
import { OidcSecurityService } from './services/oidc.security.service';
import { OidcSecurityValidation } from './services/oidc.security.validation';
import { OidcSecurityCheckSession } from './services/oidc.security.check-session';
import { OidcSecuritySilentRenew } from './services/oidc.security.silent-renew';
import { OidcSecurityUserService } from './services/oidc.security.user-service';
import { OidcSecurityCommon } from './services/oidc.security.common';
import { TokenHelperService } from './services/oidc-token-helper.service';
import { LoggerService } from './services/oidc.logger.service';
import { IFrameService } from './services/existing-iframe.service';
import { EqualityHelperService } from './services/oidc-equality-helper.service';
import { AuthWellKnownEndpoints } from './models/auth.well-known-endpoints';
import { OidcDataService } from './services/oidc-data.service';
import { StateValidationService } from './services/oidc-security-state-validation.service';
import { OidcSecurityStorage, BrowserStorage } from './services/oidc.security.storage';

@NgModule()
export class AuthModule {

  static forRoot(token: Token = {}): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        OidcConfigService,
        OidcSecurityService,
        OidcSecurityValidation,
        OidcSecurityCheckSession,
        OidcSecuritySilentRenew,
        OidcSecurityUserService,
        OidcSecurityCommon,
        AuthConfiguration,
        TokenHelperService,
        LoggerService,
        IFrameService,
        EqualityHelperService,
        AuthWellKnownEndpoints,
        OidcDataService,
        StateValidationService,
        {
          provide: OidcSecurityStorage,
          useClass: token.storage || BrowserStorage,
        },
      ],
    };
  }
}

export interface Type<T> extends Function {
  new(...args: any[]): T;
}

export interface Token {
  storage?: Type<any>;
}
