import { Metadata } from "core";
import { Transaction } from "downtown-transaction";
import { Employee } from "./employee";
import { Terminal } from "downmain-terminal";

export class Drawer {

  uid: string;
  terminalUid: string;
  startDateTimeUtc: Date;
  startAuthUid: string;
  endDateTimeUtc: Date;
  endAuthUid: string;
  seedAmount: number;
  balance: number;
  saleSequence: number;
  drawerStatusUid: string;
  defaultDrawerSeedAmount: string;
  credits: DrawerCredit[]
  debits: DrawerDebit[]

  terminal: Terminal;
  startEmployee: Employee;
  endEmployee: Employee;
}

export class DrawerCredit {

  creditUid: string;
  paymentMethodUid: string;
  amount: number;
  description: string;
  referenceUid: string;
  metadata: Metadata[];
  dateTimeUtc: Date;

  transaction: Transaction;
}

export class DrawerDebit {

  debitUid: string;
  paymentMethodUid: string;
  amount: number;
  description: string;
  referenceUid: string;
  metadata: Metadata[];
  dateTimeUtc: Date;

  transaction: Transaction;
}
