export class Metrics {

  measures: Measure[];
}

export class Measure {

  name: string;
  value: number;
}
