import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeStatusKeys } from '../keys';
import { Employee } from '../models/employee';

@Pipe({
  name: 'employeeStatus'
})
export class EmployeeStatusPipe implements PipeTransform {

  private employeeStatusMap = new Map<string, string>([
    [EmployeeStatusKeys.Active.toUpperCase(), 'Active'],
    [EmployeeStatusKeys.Inactive.toUpperCase(), 'Inactive'],
    [EmployeeStatusKeys.Deleted.toUpperCase(), 'Deleted']
  ]);

  constructor(
  ) {
  }

  transform(value: Employee | string): string {

    if (typeof(value) === 'string'){
      return value ? this.employeeStatusMap.get(value.toUpperCase()) : null;
    } else {
      return value ? this.employeeStatusMap.get(value.employeeStatusUid.toUpperCase()) : null;
    }
  }
}
