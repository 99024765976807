<div *ngIf="categories" style="height: 100%; display: flex; flex-direction: column;">

  <div style="display: flex;">
    <div style="flex: 1 1 auto; display: flex; justify-content: center; align-items: center;">
      <h2 mat-dialog-title style="font-size: 42px; font-weight: bold; line-height: 42px;">{{ title }}</h2>
    </div>

    <app-pos-button [matMenuTriggerFor]="productFilterMenu" #statusMenuTrigger="matMenuTrigger">
      <div style="font-size: .7em;">Product Filter</div>
      <div style="font-weight: bold;">{{ (productFilter | async)?.name }}</div>
    </app-pos-button>

  </div>

  <div style="flex: 1; overflow-y: auto;">
    <div *ngFor="let headingCategory of headingCategories" style="margin-top: 5px;">
      <div style="font-size: 2em;">{{ headingCategory.category.name }}</div>
      <div style="display: flex; flex-wrap: wrap;">

        <app-pos-button *ngFor="let displayableProduct of headingCategory.displayableProducts" (click)="selectDisplayableProduct(displayableProduct)" [disabled]="!displayableProduct.selectable" [styles]="{ backgroundColor: 'transparent', border: '4px solid #b9a246' }">
          <div>{{ displayableProduct.title }}</div>
          <div>{{ displayableProduct.subtitle }}</div>
        </app-pos-button>

      </div>
    </div>
  </div>

  <div style="display: flex;">
    <span style="flex: 1 1 auto;"></span>
    <app-pos-button (click)="cancel()" [styles]="{ backgroundColor: 'red' }">Cancel</app-pos-button>
  </div>

</div>

<mat-menu #productFilterMenu="matMenu" xPosition="before">
  <button *ngFor="let productFilter of productFilters" mat-menu-item (click)="selectProductFilter(productFilter)" style="height: 100%; width: 100%;">{{ productFilter.name }}</button>
</mat-menu>