import { Component, Input, OnInit } from '@angular/core';
import { slideInAnimation, slideOutAnimation } from 'core';
import { CacheService } from 'core';
import { EditableOrder } from 'store-core';
import { OrderService } from 'store-core';

@Component({
  selector: 'app-back-office-store-order-viewer',
  templateUrl: './back-office-store-order-viewer.component.html',
  styleUrls: ['./back-office-store-order-viewer.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class BackOfficeStoreOrderViewerComponent implements OnInit {

  @Input() order: EditableOrder;

  constructor(
    private cacheService: CacheService,
    private orderService: OrderService
  ) {
  }

  ngOnInit() {
  }
}
