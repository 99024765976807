import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { Permission } from "../../../models/permission";
import { EmployeeService, EmployeeViewOptions } from "../../../services/employee.service";
import { PermissionService } from "../../../services/permission.service";
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";

@Component({
  selector: 'app-employee-permissions-table',
  templateUrl: './employee-permissions-table.component.html',
  styleUrls: ['./employee-permissions-table.component.scss']
})
export class EmployeePermissionsTableComponent {

  @Input() public dataSource: EmployeePermissionDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }
}

export class EmployeePermissionModel {

  permissionUid: string;
  name: string;

  canDelete: boolean;
}

export class EmployeePermissionDataSource extends DataSource<EmployeePermissionModel> {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public selection: SelectionModel<EmployeePermissionModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private dataSubject = new BehaviorSubject<EmployeePermissionModel[]>([]);
  private permissions: Permission[];
  private _canBulkDelete = false;

  constructor(
    private employeeService: EmployeeService,
    private permissionService: PermissionService,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();

    this.selection = new SelectionModel<EmployeePermissionModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<EmployeePermissionModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(employeeUid: string, viewOptions: EmployeeViewOptions) {

    this.loadingSubject.next(true);

    combineLatest([
      this.employeeService.getByUid(employeeUid, viewOptions),
      this.permissionService.list()
    ]).pipe(
      map(([employee, permissions]) => {
        this.permissions = permissions;
        this.totalCountSubject.next(employee.permissions?.length || 0);

        const employeePermissions = employee.permissions.map(x => {
          let employeePermissionModel = Object.assign(new EmployeePermissionModel(), {
            name: this.permissions.find(y => y.uid.toUpperCase() == x.uid.toUpperCase()).name,
            permissionUid: x.uid,
          });

          this.evaluateEnablement(employeePermissionModel);

          return employeePermissionModel;
        });

        return employeePermissions;
      }),
      catchError(() => of(<EmployeePermissionModel[]>[])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(data => {
      this.dataSubject.next(data);
    });
  }

  public add(employeeUid: string, permissionUid: string): Observable<EmployeePermissionModel> {

    return this.employeeService.addPermission(employeeUid, permissionUid).pipe(
      map(employee => {
        var employeePermission = <EmployeePermissionModel>{
          permissionUid: permissionUid,
          name: this.permissions.find(y => y.uid == permissionUid).name,
        };

        var employeePermissions = this.dataSubject.value;
        employeePermissions.push(employeePermission);
        this.dataSubject.next(employeePermissions);

        return this.evaluateEnablement(employeePermission);
      })
    );
  }

  public delete(employeeUid: string, employeePermission: EmployeePermissionModel): Observable<EmployeePermissionModel> {

    return this.employeeService.deletePermission(employeeUid, employeePermission.permissionUid, EmployeeService.EmployeeFullView).pipe(
      map(x => {
        // employeePermission.productDepartmentStatusUid = x.productDepartmentStatusUid;

        var employeePermissions = this.dataSubject.value;
        employeePermissions.splice(employeePermissions.findIndex(x => x.permissionUid.toUpperCase() == employeePermission.permissionUid.toUpperCase()), 1);
        this.dataSubject.next(employeePermissions);

        return this.evaluateEnablement(employeePermission);
      })
    );
  }

  private evaluateEnablement(employeePermission: EmployeePermissionModel): EmployeePermissionModel {

    employeePermission.canDelete = true;

    return employeePermission;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkDelete = selected.length == 1;
  }
}
