import { Product } from "downtown-product";
import { Address } from "./address";
import { CardTransaction } from "./card-transaction";
import { Identity } from "./identity";

export class Order {

  uid: string;
  ownerUid: string;
  storeFrontUid: string;
  number: string;
  identityUid: string;
  cartUid: string;
  orderQuantity: number = 0;
  fulfilledQuantity: number = 0;
  subTotalAmount: number = 0;
  shippingAmount: number = 0;
  taxAmount: number = 0;
  totalAmount: number = 0;
  openDateTimeUtc: Date;
  modifiedDateTimeUtc: Date;
  closeDateTimeUtc: Date;
  orderStatusUid: string;

  initiatorIdentity: Identity;
  shippingAddress: Address;
  billingAddress: Address;
  items = new Array<OrderItem>();
  adjustments: OrderAdjustment[];
  payments: PaymentItem[];
  disbursements: DisbursementItem[];
  cardTransactions = new Array<CardTransaction>();
}

export class OrderItem {

  uid: string;
  name: string;
  productUid: string;
  configuration: OrderItemConfiguration;
  orderQuantity: number;
  eachAmount: number;
  totalAmount: number;
  fulfilledQuantity: number;
  productDeliveryUid: string;
  orderItemStatus: string;

  product: Product;
  adjustments: OrderItemAdjustment[];
}

export class OrderItemAdjustment {

  uid: string;
  description: string;
  quantity: number;
  eachAmount: number;
  isVoid: boolean;
  isCompensated: boolean;

  getTotal(): number {
    return this.quantity * this.eachAmount;
  }
}

export class OrderAdjustment {

  uid: string;
  description: string;
  amount: number;
  isVoid: boolean;
  isCompensated: boolean;

  getTotal(): number {
    return this.amount;
  }
}

export class PaymentItem {

  uid: string;
  paymentMethodUid: string;
  cardTransactionUid: string;
  amount: number;
  tip: number;

  cardTransaction: CardTransaction
}

export class DisbursementItem {

  uid: string;
  drawerUid: string;
  paymentMethodUid: string;
  amount: number;
}

export class OrderItemConfiguration {

  saleableProductUid: string;
  portion: OrderItemConfigurationPortion;

  public getConfiguringPortion?(): OrderItemConfigurationPortion {

    return this.portion;
  }
}

export class OrderItemConfigurationPortion {

  portionUid: string;
  preparations?: OrderItemConfigurationPreparation[];
  variations?: OrderItemConfigurationVariation[];
  inclusionGroups?: OrderItemConfigurationInclusionGroup[];
  inclusions?: OrderItemConfigurationInclusion[];
  extras?: OrderItemConfigurationExtra[];
  addOns?: OrderItemConfigurationAddOn[];

  public getConfiguringInclusionGroup?(inclusionGroupUid: string): OrderItemConfigurationInclusionGroup {

    return this.inclusionGroups ? this.inclusionGroups.find(x => x.inclusionGroupUid.toUpperCase() == inclusionGroupUid.toUpperCase()) : null;
  }

  public getConfiguringInclusion?(inclusionUid: string): OrderItemConfigurationInclusion {

    return this.inclusions ? this.inclusions.find(x => x.inclusionUid.toUpperCase() == inclusionUid.toUpperCase()) : null;
  }

  public getConfiguringExtra?(extraUid: string): OrderItemConfigurationExtra {

    return this.extras ? this.extras.find(x => x.extraUid.toUpperCase() == extraUid.toUpperCase()) : null;
  }
}

export class OrderItemConfigurationPreparation {

  preparationUid: string;
  optionUid: string;
}

export class OrderItemConfigurationVariation {

  variationUid: string;
  optionUid: string;
  productUid: string;
  productVersion: number;
}

export class OrderItemConfigurationInclusionGroup {

  inclusionGroupUid: string;
  options: OrderItemConfigurationInclusionGroupOption[];
}

export class OrderItemConfigurationInclusionGroupOption {

  optionUid: string;
  productUid: string;
  productVersion: number;
  quantity: number;
  preparations?: OrderItemConfigurationPreparation[];
  variations?: OrderItemConfigurationVariation[];
}

export class OrderItemConfigurationInclusion {

  inclusionUid: string;
  productUid: string;
  productVersion: number;
  quantity: number;
  preparations?: OrderItemConfigurationPreparation[];
  variations?: OrderItemConfigurationVariation[];
}

export class OrderItemConfigurationExtra {

  extraUid: string;
  productUid: string;
  productVersion: number;
  quantity: number;
  preparations?: OrderItemConfigurationPreparation[];
  variations?: OrderItemConfigurationVariation[];
}

export class OrderItemConfigurationAddOn {

  addOnUid: string;
  item: OrderItem;
}
