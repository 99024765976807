<mat-grid-list cols="4" rowHeight="8.3333vh">
  <mat-grid-tile [colspan]="4">
    <span class="mat-headline-4">Employee Shift Entry - Clock In and Out</span>
  </mat-grid-tile>
  <mat-grid-tile><span class="mat-h1">Employee</span></mat-grid-tile>
  <mat-grid-tile><span class="mat-h1">Position</span></mat-grid-tile>
  <mat-grid-tile><span class="mat-h1">Clock In Date/Time</span></mat-grid-tile>
  <mat-grid-tile><span class="mat-h1">Clock Out Date/Time</span></mat-grid-tile>

  <mat-grid-tile style="padding: 2px;">
    <app-pos-button [disabled]="!(canSelectEmployee | async)" style="width: 100%;" (click)="selectEmployee()">{{ (selectedEmployee | async) ? (selectedEmployee | async).getDisplayableName() : ( data.employeeSelectMode == 'pin' ? 'Enter PIN...' : 'Select Employee...' ) }}</app-pos-button>
  </mat-grid-tile>
  <mat-grid-tile style="padding: 2px;">
    <app-pos-button style="width: 100%;" [disabled]="!(canSelectPosition | async)" (click)="selectPosition()">{{ (selectedPosition | async) ? (selectedPosition | async).name : 'Select Position...' }}</app-pos-button>
  </mat-grid-tile>
  <mat-grid-tile style="padding: 2px;">
    <app-pos-button style="width: 100%;" [disabled]="!(canClockIn | async)" (click)="selectClockInDateTime()">{{ (clockInDateTime | async) ? (clockInDateTime.value.getTime() == now.getTime() ? 'Now' : (clockInDateTime.value | date: 'M-dd-yyyy h:mm a')) : 'Select Clock In...' }}</app-pos-button>
  </mat-grid-tile>
  <mat-grid-tile style="padding: 2px;">
    <app-pos-button style="width: 100%;" [disabled]="!(canClockOut | async)" (click)="selectClockOutDateTime()">{{ (clockOutDateTime | async) ? ((clockOutDateTime | async) | date: 'M-dd-yyyy h:mm a') : 'Select Clock Out...' }}</app-pos-button>
  </mat-grid-tile>

  <mat-grid-tile></mat-grid-tile>
  <mat-grid-tile>
    <div *ngIf="employeeOpenShift">
      <h1>Previously Clocked In</h1>
      <h1>Edit Clock In or Select Clock Out</h1>
    </div>
  </mat-grid-tile>
  <mat-grid-tile><span class="mat-h1">{{ (duration | async) | timeSpan }}</span></mat-grid-tile>
  <mat-grid-tile></mat-grid-tile>

  <mat-grid-tile [colspan]="2"></mat-grid-tile>
  <mat-grid-tile [colspan]="2">
    <span *ngIf="(duration | async)?.hours > maxHoursWarningThreshold" class="mat-h1">
      <span style="color: var(--app-warn-default-color)">Warning: The shift duration is greater than {{ maxHoursWarningThreshold }} hours.</span>
      <span style="color: var(--app-warn-default-color)">Please make sure your entry is accurate.</span>
    </span>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="2"></mat-grid-tile>

  <mat-grid-tile>
    <app-pos-button color="warn" (click)="cancel()" style="width: 100%;">Cancel</app-pos-button>
  </mat-grid-tile>
  <mat-grid-tile>
    <app-pos-button [disabled]="!(canSave | async)" style="width: 100%;" (click)="save()">{{ saveButtonText }}</app-pos-button>
  </mat-grid-tile>

</mat-grid-list>