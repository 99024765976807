import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from 'rxjs';
import { ItemConfiguratorContext } from "../../item-configurator-context";
import { SettingProvider, TenantProvider, isEqualUUID, slideInAnimation, slideOutAnimation } from "core";
import { ProductConfigurationPreparation, ProductConfigurationPreparationOption } from "downtown-product";
import { EditableTransactionItemConfigurationPreparation, TransactionSettings } from "../../../../models";
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: 'transaction-item-configurator-mods-preparation',
  templateUrl: './item-configurator-mods-preparation.component.html',
  styleUrls: ['./item-configurator-mods-preparation.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfiguratorModsPreparationComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public item: { preparations?: EditableTransactionItemConfigurationPreparation[] }
  @Input() public itemDisplay: Observable<string>
  @Input() public productPreparation: ProductConfigurationPreparation;
  @Input() public itemPreparation: EditableTransactionItemConfigurationPreparation;
  @Output() public configurationChanged = new EventEmitter();

  public transactionSettings$: Observable<TransactionSettings>;
  public display: string;
  public availablePreparationOptions: ProductConfigurationPreparationOption[];

  constructor(
    private currencyPipe: CurrencyPipe,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider
  ) {
    this.transactionSettings$ = this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid);
  }

  ngOnInit() {

    this.display = this.productPreparation.name;
    this.availablePreparationOptions = this.productPreparation.options || [];
  }

  public getProductPreparationOptionDisplay(productPreparationOption: ProductConfigurationPreparationOption): string {

    return this.context.localCache.getOrAdd(`${this.context.product.uid}_${productPreparationOption.uid}`, () => {
      return `${productPreparationOption.name} ${(productPreparationOption.additionalPrice ? `+ ${this.currencyPipe.transform(productPreparationOption.additionalPrice, 'USD', 'symbol', '1.2-2')} each` : '')}`
    });
  }

  public selectOption(productPreparationOption: ProductConfigurationPreparationOption) {

    if (productPreparationOption) {
      if (this.itemPreparation && isEqualUUID(this.itemPreparation.optionUid, productPreparationOption.uid) && this.productPreparation.allowNone) {
        this.itemPreparation.optionUid = null;
      } else {
        if (!this.itemPreparation) {
          this.itemPreparation = <EditableTransactionItemConfigurationPreparation>{
            preparationUid: this.productPreparation.uid,
          };

          this.item.preparations = this.item.preparations || [];
          this.item.preparations.push(this.itemPreparation);
        }

        this.itemPreparation.optionUid = productPreparationOption.uid;
      }

      this.configurationChanged.emit();
    }
  }
}
