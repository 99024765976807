import { Injectable } from '@angular/core';
import { IdentityServerSettings } from 'auth';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BlobServerSettings } from 'core';
import { OltpServiceSettings } from 'pos-core';
import { PosRuntimeProvider } from 'pos-core';

@Injectable()
export class SiteRuntimeProvider extends PosRuntimeProvider {

  public readonly identityServerSettings: IdentityServerSettings;

  constructor(
    oltpServiceSettings: OltpServiceSettings,
    identityServerSettings: IdentityServerSettings,
    blobServerSettings: BlobServerSettings,
    deviceDetectorService: DeviceDetectorService
  ) {
    super(
      deviceDetectorService,
      oltpServiceSettings,
      blobServerSettings
    );

    this.identityServerSettings = identityServerSettings;
  }
}
