<app-page-header>

  <ng-container left>
    <button mat-flat-button style="width: 4.1666vw;" (click)="navigateHome()">
      <mat-icon style="font-weight: bold;">home</mat-icon>
    </button>
    <button mat-flat-button style="width: 4.1666vw; margin-left: 4px;" (click)="navigateBack()">
      <mat-icon style="font-weight: bold;">arrow_back</mat-icon>
    </button>
  </ng-container>

  <ng-container middle>{{title}}</ng-container>

</app-page-header>

<div class="container">
  <form [formGroup]="form" style="margin-top: 20px;">
    <div>
      <mat-form-field>
        <input matInput formControlName="firstName" placeholder="First Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="lastName" placeholder="Last Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="displayName" placeholder="Display Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="pin" placeholder="PIN">
        <button matSuffix mat-flat-button color="primary" (click)="getRandomPin()">Get Random PIN</button>
        <mat-hint>Pin is required and must be six digits</mat-hint>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="mobilePhone" placeholder="Mobile Phone">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="email" placeholder="Email">
        <mat-error *ngIf="form.get('email').hasError('email')">Email is not valid</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <mat-select formControlName="paymentMethodUid" placeholder="Payment Method*">
          <mat-option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod.uid">{{ paymentMethod.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('paymentMethodUid').hasError('required')">Payment Method is required</mat-error>
      </mat-form-field>
    </div>
  </form>

  <div style="text-align: right;">
    <button mat-flat-button color="warn" style="margin-left: 1em;" (click)="cancel()">Cancel</button>
    <button mat-flat-button [disabled]="!this.form.dirty || !this.form.valid" color="accent" style="margin-left: 1em;" (click)="save()">Save</button>
  </div>

</div>
