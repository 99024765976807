<app-page-header>

  <ng-container left>
    <button mat-flat-button style="width: 4.1666vw;" (click)="navigateHome()">
      <mat-icon style="font-weight: bold;">home</mat-icon>
    </button>
    <button mat-flat-button style="width: 4.1666vw; margin-left: 4px;" (click)="navigateBack()">
      <mat-icon style="font-weight: bold;">arrow_back</mat-icon>
    </button>
  </ng-container>

  <ng-container middle>{{title}}</ng-container>

</app-page-header>

<div class="container">
  <form [formGroup]="form" style="margin-top: 20px;">
    <div>
      <mat-form-field>
        <input matInput formControlName="name" placeholder="Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <mat-select formControlName="accountSettingUid" placeholder="Account Setting">
          <mat-option *ngFor="let accountSetting of accountSettings" [value]="accountSetting.uid">{{ accountSetting.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <mat-select formControlName="isSalesTaxIncluded" placeholder="Sales Tax Included">
          <mat-option *ngFor="let option of [{ display: 'True', value: true }, { display: 'False', value: false }]" [value]="option.value">{{ option.display }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div style="text-align: right;">
    <button mat-flat-button color="warn" style="margin-left: 1em;" (click)="cancel()">Cancel</button>
    <button mat-flat-button [disabled]="!this.form.dirty || !this.form.valid" color="accent" style="margin-left: 1em;" (click)="save()">Save</button>
  </div>

</div>