import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';

import { CoreModule } from 'core';

import { ProductPortionButtonComponent } from "./components/product-portion-button/product-portion-button.component";
import { ProductPortionLabelComponent } from "./components/product-portion-label/product-portion-label.component";
import { CategoriesTableComponent } from './components/categories-table/categories-table.component';
import { DepartmentsTableComponent } from './components/departments-table/departments-table.component';
import { ProductsTableComponent } from './components/products-table/products-table.component';

import { BackOfficeProductPageComponent } from "./components/product/back-office-product-page.component";
import { BackOfficeProductConfigurationAddOnComponent } from "./components/product/configuration/add-on/back-office-product-configuration-add-on.component";
import { BackOfficeProductConfigurationAddOnsComponent } from "./components/product/configuration/add-ons/back-office-product-configuration-add-ons.component";
import { BackOfficeProductConfigurationInclusionGroupComponent } from "./components/product/configuration/inclusion-group/back-office-product-configuration-inclusion-group.component";
import { BackOfficeProductConfigurationInclusionGroupOptionComponent } from "./components/product/configuration/inclusion-group/option/back-office-product-configuration-inclusion-group-option.component";
import { BackOfficeProductConfigurationInclusionGroupOptionsComponent } from "./components/product/configuration/inclusion-group/options/back-office-product-configuration-inclusion-group-options.component";
import { BackOfficeProductConfigurationInclusionGroupSettingsComponent } from "./components/product/configuration/inclusion-group/settings/back-office-product-configuration-inclusion-group-settings.component";
import { BackOfficeProductConfigurationInclusionGroupsComponent } from "./components/product/configuration/inclusion-groups/back-office-product-configuration-inclusion-groups.component";
import { BackOfficeProductConfigurationPortionComponent } from "./components/product/configuration/portion/back-office-product-configuration-portion.component";
import { BackOfficeProductConfigurationPortionGeneralComponent } from "./components/product/configuration/portion/general/back-office-product-configuration-portion-general.component";
import { BackOfficeProductConfigurationPortionsComponent } from "./components/product/configuration/portions/back-office-product-configuration-portions.component";
import { BackOfficeProductConfigurationGeneralComponent } from "./components/product/general/back-office-product-configuration-general.component";

import { modalComponentTypes, modalProviderTypes } from './modals/modal-types';

import { CategoryStatusPipe } from "./pipes/category-status.pipe";
import { CategoryPipe } from "./pipes/category.pipe";
import { DepartmentStatusPipe } from "./pipes/department-status.pipe";
import { DepartmentPipe } from "./pipes/department.pipe";
import { ProductPortionPipe } from "./pipes/product-portion.pipe";
import { ProductStatusPipe } from "./pipes/product-status.pipe";
import { ProductPipe } from "./pipes/product.pipe";

import { CategoryProvider } from "./providers/category.provider";
import { DepartmentProvider } from "./providers/department.provider";
import { ProductProvider } from "./providers/product.provider";

import { DepartmentService } from './services/department.service';
import { CategoryService } from './services/category.service';
import { ProductService } from './services/product.service';


const componentTypes = [
  ProductPortionButtonComponent,
  ProductPortionLabelComponent,
  CategoriesTableComponent,
  DepartmentsTableComponent,
  ProductsTableComponent,

  BackOfficeProductPageComponent,
  BackOfficeProductConfigurationGeneralComponent,
  BackOfficeProductConfigurationPortionsComponent,
  BackOfficeProductConfigurationPortionComponent,
  BackOfficeProductConfigurationPortionGeneralComponent,
  BackOfficeProductConfigurationInclusionGroupsComponent,
  BackOfficeProductConfigurationInclusionGroupComponent,
  BackOfficeProductConfigurationInclusionGroupSettingsComponent,
  BackOfficeProductConfigurationInclusionGroupOptionsComponent,
  BackOfficeProductConfigurationInclusionGroupOptionComponent,
  BackOfficeProductConfigurationAddOnsComponent,
  BackOfficeProductConfigurationAddOnComponent,

  ...modalComponentTypes,
];

const pipeTypes = [
  DepartmentStatusPipe,
  CategoryPipe,
  CategoryStatusPipe,
  DepartmentPipe,
  ProductPipe,
  ProductPortionPipe,
  ProductStatusPipe
];

const providerTypes = [
  CategoryProvider,
  DepartmentProvider,
  ProductProvider,
  ...modalProviderTypes
];

const serviceTypes = [
  DepartmentService,
  CategoryService,
  ProductService,
];

@NgModule({
  declarations: [
    ...componentTypes,
    ...pipeTypes,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,

    DragDropModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,

    CoreModule
  ],
  exports: [
    ...componentTypes,
    ...pipeTypes,
  ],
  providers: [
    ...providerTypes,
    ...serviceTypes,
  ]
})
export class DowntownProductModule { }
