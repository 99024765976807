import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { IPagedDataSource } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { PayPeriodStatusKeys } from "../../../keys";
import { PayPeriodService, PayPeriodViewOptions } from "../../../services/pay-period.service";

@Component({
  selector: 'app-pay-periods-table',
  templateUrl: './pay-periods-table.component.html',
  styleUrls: ['./pay-periods-table.component.scss']
})
export class PayPeriodsTableComponent {

  @Input() public dataSource: PayPeriodDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }
}

export class PayPeriodModel {

  uid: string;
  startDateTimeUtc: Date;
  endDateTimeUtc: Date;
  payPeriodStatusUid: string;
  totalSales: number;
  totalPay: number;

  canEdit: boolean;
  canViewShifts: boolean;
  canReview: boolean;
  canCommit: boolean;
  canRollback: boolean;
  canReport: boolean;
  canDelete: boolean;
}

export class PayPeriodDataSource extends DataSource<PayPeriodModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<PayPeriodModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<PayPeriodModel[]>([]);

  private _canBulkEdit = false;
  private _canBulkReview = false;
  private _canBulkEditShifts = false;
  private _canBulkCommit = false;
  private _canBulkRollback = false;
  private _canBulkReport = false;
  private _canBulkDelete = false;

  constructor(
    private payPeriodService: PayPeriodService,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<PayPeriodModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit;
  }

  public get canBulkEditShifts(): boolean {
    return this._canBulkEditShifts;
  }

  public get canBulkReview(): boolean {
    return this._canBulkReview;
  }

  public get canBulkCommit(): boolean {
    return this._canBulkCommit;
  }

  public get canBulkRollback(): boolean {
    return this._canBulkRollback;
  }
  public get canBulkReport(): boolean {
    return this._canBulkReport;
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete;
  }

  connect(collectionViewer: CollectionViewer): Observable<PayPeriodModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(statusUids: string[], paginationInput: PaginationInput, viewOptions: PayPeriodViewOptions) {

    this.loadingSubject.next(true);

    this.payPeriodService.search(statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        const drawers = page.edges.map(x => x.node).map(x => {
          let drawerModel = Object.assign(new PayPeriodModel(), {
            uid: x.uid,
            startDateTimeUtc: x.startDateTimeUtc,
            endDateTimeUtc: x.endDateTimeUtc,
            payPeriodStatusUid: x.payPeriodStatusUid,
            totalSales: x.totalSales,
            totalPay: x.totalPay
          });

          this.evaluateEnablement(drawerModel);

          return drawerModel;
        });

        return <[PageInfo, PayPeriodModel[]]>[page.pageInfo, drawers];
      }),
      catchError(() => of(<[PageInfo, PayPeriodModel[]]>[null, []])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  public review(payPeriod: PayPeriodModel): Observable<PayPeriodModel> {

    return this.payPeriodService.startReview(payPeriod.uid).pipe(
      map(x => {
        payPeriod.payPeriodStatusUid = x.payPeriodStatusUid;

        return this.evaluateEnablement(payPeriod);
      })
    );
  }

  public commit(payPeriod: PayPeriodModel): Observable<PayPeriodModel> {

    return this.payPeriodService.commit(payPeriod.uid).pipe(
      map(x => {
        payPeriod.payPeriodStatusUid = x.payPeriodStatusUid;

        return this.evaluateEnablement(payPeriod);
      })
    );
  }

  public rollback(payPeriod: PayPeriodModel): Observable<PayPeriodModel> {

    return this.payPeriodService.rollback(payPeriod.uid).pipe(
      map(x => {
        payPeriod.payPeriodStatusUid = x.payPeriodStatusUid;

        return this.evaluateEnablement(payPeriod);
      })
    );
  }

  public delete(payPeriod: PayPeriodModel): Observable<PayPeriodModel> {

    return this.payPeriodService.delete(payPeriod.uid).pipe(
      map(x => {
        payPeriod.payPeriodStatusUid = x.payPeriodStatusUid;

        return this.evaluateEnablement(payPeriod);
      })
    );
  }

  private evaluateEnablement(payPeriod: PayPeriodModel): PayPeriodModel {

    let payPeriodStatusUid = payPeriod.payPeriodStatusUid;

    payPeriod.canEdit = payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.Open.toUpperCase();
    payPeriod.canViewShifts = true;
    payPeriod.canReview = payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.Open.toUpperCase();
    payPeriod.canCommit = payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.InReview.toUpperCase();
    payPeriod.canRollback = payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.Committed;
    payPeriod.canReport = payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.Committed.toUpperCase();
    payPeriod.canDelete = payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.Open.toUpperCase();

    return payPeriod;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEdit = selected.length == 1 && selected[0].payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.Open.toUpperCase();
    this._canBulkEditShifts = selected.length == 1;
    this._canBulkReview = selected.length == 1 && selected[0].payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.Open.toUpperCase();
    this._canBulkCommit = selected.length == 1 && selected[0].payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.InReview.toUpperCase();
    this._canBulkRollback = selected.length == 1 && selected[0].payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.Committed.toUpperCase();
    this._canBulkReport = selected.length == 1 && selected[0].payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.Committed.toUpperCase();
    this._canBulkDelete = selected.length == 1 && selected[0].payPeriodStatusUid.toUpperCase() === PayPeriodStatusKeys.Open.toUpperCase();
  }
}
