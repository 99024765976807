import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ProductProvider } from 'downtown-product';
import { first } from 'rxjs';
import { CartItem } from '../../../models/cart-item';

@Component({
  selector: 'store-core-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {

  @Input('cartItem') cartItem: CartItem;

  public resolvedCartItem: ResolvedCartItem;

  constructor(
    private productProvider: ProductProvider
  ) {
  }

  ngOnInit() {

    this.resolveCartItem();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['item']) {
      this.resolveCartItem();
    }
  }

  private resolveCartItem() {

    if (this.cartItem) {
      this.productProvider.getOneCached$(this.cartItem.productUid, this.cartItem.productVersion).pipe(
        first()
      ).subscribe(product => {
        const resolvedCartItem = Object.assign(new ResolvedCartItem(), this.cartItem);
        resolvedCartItem.name = product.name;

        this.resolvedCartItem = resolvedCartItem;
      });
    }
  }
}

class ResolvedCartItem extends CartItem {

}
