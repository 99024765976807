import { Pipe, PipeTransform } from '@angular/core';
import { DepartmentStatusKeys } from '../keys';
import { Department } from '../models/department';

@Pipe({
  name: 'departmentStatus'
})
export class DepartmentStatusPipe implements PipeTransform {

  private map = new Map<string, string>([
    [DepartmentStatusKeys.Active.toUpperCase(), 'Active'],
    [DepartmentStatusKeys.Inactive.toUpperCase(), 'Inactive'],
    [DepartmentStatusKeys.Deleted.toUpperCase(), 'Deleted']
  ]);

  constructor(
  ) {
  }

  transform(value: Department | string): string {

    if (typeof(value) === 'string'){
      return value ? this.map.get(value.toUpperCase()) : null;
    } else {
      return value ? this.map.get(value.departmentStatusUid.toUpperCase()) : null;
    }
  }
}
