<div class="background" [style.background-image]="'url(' + this.runtimeProvider.assetBaseUrl + 'images/bottles1.png' + ')'">
  <!-- <img src="{{ this.runtimeProvider.AssetBaseUrl + 'images/bottles1.png' }}" style="width: 100%; opacity: .3;" /> -->
</div>

<div class="branding">
  <div style="margin-bottom: 5px;">
    <img class="logo" src="{{ imageUrl }}" />
  </div>
  <div class="mat-subheading-1" style="text-align: center;">416 E. Main St., Watertown WI 53094</div>
  <h1 class="mat-display-1" style="color: red; font-weight: bold; text-align: center; margin-bottom: 0px;">TAKEOUT CALL</h1>
  <h1 class="mat-display-1" style="color: red; font-weight: bold; text-align: center; margin-bottom: 20px; user-select: all;">920-245-3663</h1>

  <div style="margin-bottom: 20px;">
    <h1 style="text-align: center;">Hours</h1>
    <div class="mat-body-2" style="text-align: center;">Bar: Everday 10am - 12am or later</div>
    <div class="mat-body-2" style="text-align: center;">Kitchen: Everyday 11am - 8pm or later</div>
  </div>
</div>

<div class="content">

  <div>
    <p style="font-size: 2em; line-height: 1.5em; text-align: center;">Uptown Bar is located on the corner of 5th and Main in downtown Watertown, WI. We are open 7 days a week 365 days a year.</p>
  </div>

  <div>
    <p style="font-size: 2em; line-height: 1.5em; text-align: center">We feature four 50" HDTV's behind the bar and another 4 large TV's around the venue all running in pure 4K HDMI.</p>
    <p style="font-size: 2em; line-height: 1.5em; text-align: center">Our kitchen is open from 11am to 8pm serving bar food favorites, delicious specialities, and a Friday Fish fry.</p>
  </div>

  <div style="text-align: center;">
    <p>Email us at <a href="mailto: feedback@uptownbar.com"></a>feedback&#64;uptownbar.com</p>
  </div>

  <div style="text-align: center;">
    <p>&copy; 2011 - {{ runtimeProvider.currentYear }} - Uptown Of Watertown LLC</p>
  </div>

</div>
