<div class="container" [formGroup]="form">
  <div style="margin-bottom: 10px;">
    <h2>Product Category</h2>
  </div>
  <div>
    <div>
      <mat-form-field>
        <input matInput formControlName="name" placeholder="Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="description" placeholder="Description">
      </mat-form-field>
    </div>
  </div>

  <div style="text-align: right;">
    <button mat-flat-button color="warn" style="margin-left: 1em;" (click)="cancel()">Cancel</button>
    <button mat-flat-button [disabled]="!this.form.dirty || !this.form.valid" color="accent" style="margin-left: 1em;" (click)="save()">Save</button>
  </div>

</div>
