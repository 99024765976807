import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { IPagedDataSource } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { CardTransactionBatchStatusKeys } from "../../../keys";
import { CardTransactionBatch } from "../../../models/card-transaction-batch";
import { CardTransactionBatchService } from "../../../services/card-transaction-batch.service";
import { CardTransactionBatchViewOptions } from "../../../view-factory";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";

@Component({
  selector: 'app-card-transaction-batches-table',
  templateUrl: './card-transaction-batches-table.component.html',
  styleUrls: ['./card-transaction-batches-table.component.scss']
})
export class CardTransactionBatchesTableComponent {

  @Input() public dataSource: CardTransactionBatchDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }
}

export class CardTransactionBatchModel {

  uid: string;
  processorBatchId: string;
  count: number;
  amount: number;
  creditSaleCount: number;
  creditSaleAmount: number;
  creditReturnCount: number;
  creditReturnAmount: number;
  debitSaleCount: number;
  debitSaleAmount: number;
  debitReturnCount: number;
  debitReturnAmount: number;
  openDateTimeUtc: Date;
  modifiedDateTimeUtc: Date;
  closeDateTimeUtc: Date;
  cardTransactionBatchStatusUid: string;

  canClose: boolean;
}

export class CardTransactionBatchDataSource extends DataSource<CardTransactionBatchModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<CardTransactionBatchModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<CardTransactionBatchModel[]>([]);
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private cardTransactionBatchService: CardTransactionBatchService,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<CardTransactionBatchModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<CardTransactionBatchModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(storeFrontUid: string, statusUids: string[], paginationInput: PaginationInput, viewOptions: CardTransactionBatchViewOptions) {

    this.loadingSubject.next(true);

    this.cardTransactionBatchService.search(null, [storeFrontUid], statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        let cardTransactions = page.edges.map(x => x.node).map(order => this.mergeCardTransactionBatchModel(new CardTransactionBatchModel(), order));


        return <[PageInfo, CardTransactionBatchModel[]]>[page.pageInfo, cardTransactions];
      }),
      catchError(() => of(<[PageInfo, CardTransactionBatchModel[]]>[null, []])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  public close(cardTransaction: CardTransactionBatchModel): Observable<CardTransactionBatchModel> {

    return this.cardTransactionBatchService.close(cardTransaction.uid, null).pipe(
      map(x => {
        return this.mergeCardTransactionBatchModel(cardTransaction, x);
      })
    );
  }

  private mergeCardTransactionBatchModel(model: CardTransactionBatchModel, cardTransactionBatch: CardTransactionBatch) {

    model.uid = cardTransactionBatch.uid;
    model.processorBatchId = cardTransactionBatch.processorBatchId;
    model.count = cardTransactionBatch.count;
    model.amount = cardTransactionBatch.amount;
    model.creditSaleCount = cardTransactionBatch.creditSaleCount;
    model.creditSaleAmount = cardTransactionBatch.creditSaleAmount;
    model.creditReturnCount = cardTransactionBatch.creditReturnCount;
    model.creditReturnAmount = cardTransactionBatch.creditReturnAmount;
    model.debitSaleCount = cardTransactionBatch.debitSaleCount;
    model.debitSaleAmount = cardTransactionBatch.debitSaleAmount;
    model.debitReturnCount = cardTransactionBatch.debitReturnCount;
    model.debitReturnAmount = cardTransactionBatch.debitReturnAmount;
    model.openDateTimeUtc = cardTransactionBatch.openDateTimeUtc;
    model.modifiedDateTimeUtc = cardTransactionBatch.modifiedDateTimeUtc;
    model.closeDateTimeUtc = cardTransactionBatch.closeDateTimeUtc;
    model.cardTransactionBatchStatusUid = cardTransactionBatch.cardTransactionBatchStatusUid;

    this.evaluateEnablement(model);

    return model;
  }

  private evaluateEnablement(cardTransactionBatch: CardTransactionBatchModel): CardTransactionBatchModel {

    let cardTransactionBatchStatusUid = cardTransactionBatch.cardTransactionBatchStatusUid.toUpperCase();

    cardTransactionBatch.canClose = cardTransactionBatchStatusUid == CardTransactionBatchStatusKeys.Open.toUpperCase();

    return cardTransactionBatch;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEdit = selected.length == 1;
    this._canBulkDelete = selected.length == 1;
  }
}
