import { Component } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Component({ template: '' })
export abstract class SettingBaseComponent<TModel, TControl extends { [K in keyof TControl]: AbstractControl<any>; }> {

  public form: FormGroup<TControl>;

  protected destroyed$ = new Subject();

  ngOnDestroy() {

    this.destroyed$.next(null);
  }

  public abstract patchForm(value: TModel): void;
}
