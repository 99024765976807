import { Product, ProductProvider } from 'downtown-product';
import { initTransactionItemConfiguration } from 'downtown-transaction';
import { EditableCartItem } from '../models/editable-cart';


export function initCartItem(product: Product, portionUid: string, productProvider: ProductProvider, setDefaults: boolean): EditableCartItem {

  var cartItem = new EditableCartItem();
  cartItem.productUid.next(product?.uid);
  cartItem.productVersion.next(product?.version);
  cartItem.name.next(product?.name);
  cartItem.configuration.next(initTransactionItemConfiguration(product, portionUid, productProvider, setDefaults));

  return cartItem;
}
