import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Employee } from '../models/employee';
import { finalize, map, switchMap, tap } from "rxjs/operators";
import { OltpServiceSettings } from "../oltp-service-settings";
import { HttpService } from "core";
import { PaginationInput } from "core";
import { Page } from "core";
import { PosModelFactory } from "../pos-model-factory";
import { EndpointState, GraphService } from "core";

@Injectable()
export class EmployeeService {

  public static readonly EmployeeIndexView = <EmployeeViewOptions>{};
  public static readonly EmployeeFullView = <EmployeeViewOptions>{ includePositions: true, includePermissions: true };

  constructor(
    private httpService: HttpService,
    private graphService: GraphService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'getByUid', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  getByPin(pin: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `query { getByPin(pin: "${pin}") ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'getByPin', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  getByIdentityId(identityId: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `query { getByIdentityId(identityId: "${identityId}") ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'getByIdentityId', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  getRandomPin(): Observable<string> {

    var request = {
      query: `query { getRandomPin }`
    };

    return this.httpService.graph(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'getRandomPin');
  }

  getMutated(viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var employeeMutatedEndpoint = this.graphService.createEndpoint(this.oltpServiceSettings.socketUrl + 'api/oltp/employee', 'employee');

    return employeeMutatedEndpoint.state.pipe(
      switchMap(state => {
        if (state == EndpointState.Ready) {
          return employeeMutatedEndpoint
            .addSubscription<Employee>(`subscription mutated { mutated ${view} }`, 'mutated', identityToken).pipe(
              map(x => {
                return PosModelFactory.assignEmployee(x);
              })
            );
        } else {
          return new Observable<Employee>(null);
        }
      }),
      finalize(() => {
        employeeMutatedEndpoint.close();
      })
    );
  }

  search(paymentMethodUids: string[], statusUids: string[], paginationInput: PaginationInput, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Page<Employee>> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `query search($pagination:PaginationInput) { search(paymentMethodUids: ${JSON.stringify(paymentMethodUids)}, statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<Employee>>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'search', identityToken).pipe(
      tap(x => x.edges.forEach(edge => edge.node = PosModelFactory.assignEmployee(edge.node)))
    );
  }

  create(uid: string, firstName: string, lastName: string, displayName: string, pin: string, mobilePhone: string, email: string, paymentMethodUid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var employeeInput = {
      uid: uid,
      firstName: firstName,
      lastName: lastName,
      displayName: displayName,
      pin: pin,
      mobilePhone: mobilePhone,
      email: email,
      paymentMethodUid: paymentMethodUid
    };

    var request = {
      query: `mutation createEmployee($employee:EmployeeInput!) { create(employee: $employee) ${view} }`,
      variables: { employee: employeeInput }
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'create', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  update(uid: string, firstName: string, lastName: string, displayName: string, pin: string, mobilePhone: string, email: string, paymentMethodUid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var employeeInput = {
      uid: uid,
      firstName: firstName,
      lastName: lastName,
      displayName: displayName,
      pin: pin,
      mobilePhone: mobilePhone,
      email: email,
      paymentMethodUid: paymentMethodUid
    };

    var request = {
      query: `mutation updateEmployee($employee:EmployeeInput!) { update(employee: $employee) ${view} }`,
      variables: { employee: employeeInput }
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'update', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  enable(uid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `mutation enableEmployee { enable(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'enable', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  disable(uid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `mutation disableEmployee { disable(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'disable', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  updateIdentity(uid: string, identityId: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `mutation linkIdentity { updateIdentity(uid: "${uid}", identityId: ${identityId ? '\"' + identityId + '\"' : null}) ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'updateIdentity', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  updatePaymentMethod(uid: string, paymentMethodUid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `mutation updatePaymentMethod { updatePaymentMethod(uid: "${uid}", paymentMethodUid: "${paymentMethodUid}") ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'updatePaymentMethod', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  delete(uid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `mutation deleteEmployee { delete(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'delete', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  addPosition(uid: string, positionUid: string, payRate: number, workUnitUid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `mutation addPosition { addPosition(uid: "${uid}", positionUid: "${positionUid}", payRate: ${payRate}, workUnitUid: "${workUnitUid}") ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'addPosition', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  updatePosition(uid: string, positionUid: string, payRate: number, workUnitUid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `mutation updatePosition { updatePosition(uid: "${uid}", positionUid: "${positionUid}", payRate: ${payRate}, workUnitUid: "${workUnitUid}") ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'updatePosition', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  deletePosition(uid: string, positionUid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `mutation deletePosition { deletePosition(uid: "${uid}", positionUid: "${positionUid}") ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'deletePosition', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  addPermission(uid: string, permissionUid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `mutation addPermission { addPermission(uid: "${uid}", permissionUid: "${permissionUid}") ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'addPermission', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  deletePermission(uid: string, permissionUid: string, viewOptions: EmployeeViewOptions = null, identityToken: string = null): Observable<Employee> {

    let view = EmployeeService.buildView(viewOptions || EmployeeService.EmployeeFullView);

    var request = {
      query: `mutation deletePermission { deletePermission(uid: "${uid}", permissionUid: "${permissionUid}") ${view} }`
    };

    return this.httpService.graph<Employee>(this.oltpServiceSettings.apiUrl, 'api/oltp/employee', request, 'deletePermission', identityToken).pipe(
      map(x => PosModelFactory.assignEmployee(x))
    );
  }

  public static buildView(viewOptions: EmployeeViewOptions) {

    let view = `uid firstName lastName pin mobilePhone email displayName identityId paymentMethodUid employeeStatusUid`;

    if (viewOptions.includePositions) {
      view += ` positions { uid payRate workUnitUid activeStatusUid }`;
    }

    if (viewOptions.includePermissions) {
      view += ` permissions { uid }`;
    }

    return '{ ' + view + ' }';
  }
}

export interface EmployeeViewOptions {

  includePositions: boolean;
  includePermissions: boolean;
}
