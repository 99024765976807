<div style="height: 100%; display: flex; flex-direction: column;">

  <div style="height: 100%; display: flex;">

    <div>

      <div style="border: 1px solid white; padding: 2px;" (click)="dailySales()">
        <h2>Daily Sales</h2>
      </div>
      <!-- <div style="border: 1px solid white; padding: 2px;" (click)="dailySales()">
        <h2>Daily Sales</h2>
      </div> -->

    </div>

    <div style="flex: 1;">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>
