import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { SpinnerModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { EmployeeProfileForm } from 'pos-core';
import { AuthEmployeeProvider } from 'pos-core';
import { EmployeeService } from 'pos-core';

@Component({
  selector: 'app-site-employee-profile',
  templateUrl: './site-employee-profile.component.html',
  styleUrls: ['./site-employee-profile.component.scss']
})
export class SiteEmployeeProfileComponent extends EmployeeProfileForm implements OnInit {

  public title: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    navigationProvider: NavigationProvider,
    spinnerModalProvider: SpinnerModalProvider,
    authEmployeeProvider: AuthEmployeeProvider,
    employeeService: EmployeeService
  ) {
    super(navigationProvider, spinnerModalProvider, authEmployeeProvider, employeeService);

    this.navigationProvider.setWaypoint('Employee Profile');
    this.title = 'Employee Profile';
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      let employeeUid = params['employeeUid'];

      if (employeeUid) {
        let spinnerModalRef = this.spinnerModalProvider.open();
        spinnerModalRef.afterOpened().subscribe(() => {
          this.employeeService.getByUid(employeeUid).subscribe(employee => {
            if (employee) {
              this.loadForm(employee);

              spinnerModalRef.close();
            } else {
              this.navigationProvider.backOneWaypoint();
            }
          });
        });
      } else {
        this.navigationProvider.backOneWaypoint();
      }
    });
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }
  
  public save() {

    if (this.form.dirty) {
      let saveDialogRef = this.spinnerModalProvider.open();

      saveDialogRef.afterOpened().subscribe(() => {
        if (this.employeeUid) {
          this.employeeService.update(
            this.employeeUid,
            this.form.get('firstName').value,
            this.form.get('lastName').value,
            this.form.get('displayName').value,
            this.form.get('pin').value,
            this.form.get('mobilePhone').value,
            this.form.get('email').value,
            this.form.get('paymentMethodUid').value
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        } else {
          this.employeeUid = UUID.UUID();

          this.employeeService.create(
            this.employeeUid,
            this.form.get('firstName').value,
            this.form.get('lastName').value,
            this.form.get('displayName').value,
            this.form.get('pin').value,
            this.form.get('mobilePhone').value,
            this.form.get('email').value,
            this.form.get('paymentMethodUid').value
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        }
      });
    } else {
      this.navigationProvider.backOneWaypoint();
    }
  }
}
