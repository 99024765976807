<div style="display: flex; flex-direction: column; height: 100%;">

  <app-page-header>

    <ng-container left>
      <button mat-flat-button style="width: 4.1666vw;" (click)="navigateHome()">
        <mat-icon style="font-weight: bold;">home</mat-icon>
      </button>
      <button mat-flat-button style="width: 4.1666vw; margin-left: 4px;" (click)="navigateBack()">
        <mat-icon style="font-weight: bold;">arrow_back</mat-icon>
      </button>
    </ng-container>

    <ng-container middle>{{title}}</ng-container>

    <app-paginator right [dataSource]="dataSource" [paginator]="paginator"></app-paginator>

  </app-page-header>

  <app-action-bar [actionBarConfiguration]="actionBarConfiguration"></app-action-bar>

  <div style="flex: 1; overflow-y: auto;">
    <app-terminals-table [dataSource]="dataSource" [columns]="columns"></app-terminals-table>
  </div>

</div>


<!-- <mat-toolbar color="primary">
  <span>{{ title }}</span>
  <span style="flex: 1 1 auto;"></span>
  <span *ngIf="members | async">{{ startItemIndex }} to {{ endItemIndex }} of {{ totalItemCount }}</span>
</mat-toolbar>

<mat-toolbar color="primary">

  <span style="flex: 1 1 auto;"></span>

  <div matRipple (click)="pageBackward()" class="filter-button" [class.disabled]="this.pageIndex == 0">
    <div style="font-size: 1.5em; font-weight: bold;">
      <mat-icon style="font-size: 1.5em; font-weight: bold;">chevron_left</mat-icon>
    </div>
  </div>
  <div matRipple (click)="pageForward()" class="filter-button" [class.disabled]="this.pageIndex == this.maxPageIndex">
    <div style="font-size: 1.5em; font-weight: bold;">
      <mat-icon style="font-size: 1.5em; font-weight: bold;">chevron_right</mat-icon>
    </div>
  </div>
  <div [matMenuTriggerFor]="statusFilterMenu" #statusMenuTrigger="matMenuTrigger" matRipple class="filter-button">
    <div style="font-size: .7em;">Status</div>
    <div style="font-weight: bold;">{{ (statusFilter | async)?.name }}</div>
  </div>
  <div [matMenuTriggerFor]="pageSizeFilterMenu" #pageSizeMenuTrigger="matMenuTrigger" matRipple class="filter-button">
    <div style="font-size: .7em;">Page Size</div>
    <div style="font-weight: bold;">{{ (pageSizeFilter | async)?.name }}</div>
  </div>
</mat-toolbar>

<div style="position: absolute; top: 138px; left: 5px; right: 5px; bottom: 5px; overflow-y: auto;">

  <table class="themed" style="width: 100%;" mat-table [dataSource]="members">

    <ng-container matColumnDef="member">
      <th mat-header-cell *matHeaderCellDef color="accent">Member</th>
      <td mat-cell *matCellDef="let member">
        <div>{{ member.uid }}</div>
        <div>{{ member.lastName }}</div>
        <div>{{ member.firstName }}</div>
        <div>{{ member.displayName }}</div>
        <div>{{ member.email }}</div>
        <div>{{ member.memberStatusUid | memberStatus }}</div>
        <div>
          <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canEdit" (click)="profile(member)">
            <mat-icon>edit_outline</mat-icon>
          </button>
          <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canEnable" (click)="enable(member)">
            <mat-icon>toggle_on</mat-icon>
          </button>
          <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canDisable" (click)="disable(member)">
            <mat-icon>toggle_off</mat-icon>
          </button>
          <button mat-icon-button color="warn" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canDelete" (click)="delete(member)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="identity">
      <th mat-header-cell *matHeaderCellDef>Identity</th>
      <td mat-cell *matCellDef="let member">
        <div>{{ member.identityId }}</div>
        <div style="display: flex;">
          <div style="flex: 1;">
            <span>Role(s)</span>
            <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canEdit" (click)="addRole(member)">
              <mat-icon>add_circle</mat-icon>
            </button>
          </div>
          <div style="flex: 1;">
            <div *ngFor="let role of member.roles">
              <span>{{ role }}</span>
              <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canEdit" (click)="removeRole(member, role)">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="employee">
      <th mat-header-cell *matHeaderCellDef>Employee</th>
      <td mat-cell *matCellDef="let member">
        <div>{{ member.employeeUid }}</div>
        <div>{{ member.employeeFullName }}</div>
        <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canLinkIdentity" (click)="linkEmployee(member)">
          <mat-icon>link</mat-icon>
        </button>
        <button mat-icon-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="member.canUnlinkIdentity" (click)="unlinkEmployee(member)">
          <mat-icon>link_off</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>

<mat-menu #statusFilterMenu="matMenu" xPosition="before">
  <div>
    <button *ngFor="let statusFilter of statusFilters" mat-menu-item (click)="selectStatusFilter(statusFilter)">{{ statusFilter.name }}</button>
  </div>
</mat-menu>

<mat-menu #pageSizeFilterMenu="matMenu" xPosition="before">
  <div>
    <button *ngFor="let pageSizeFilter of pageSizeFilters" mat-menu-item (click)="selectPageSizeFilter(pageSizeFilter)">{{ pageSizeFilter.name }}</button>
  </div>
</mat-menu> -->
