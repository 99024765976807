import { Injectable } from "@angular/core";
import { HttpService } from "core";
import { Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { ProductLibraryConfig } from "../product-library-config";
import { Category } from '../models/category';
import { PaginationInput } from "core";
import { Page } from "core";
import { ProductModelFactory } from '../product-model-factory';

@Injectable()
export class CategoryService {

  public static readonly CategoryIndexView = <CategoryViewOptions>{};
  public static readonly CategoryFullView = <CategoryViewOptions>{};

  private readonly endPoint = 'api/product/category';

  constructor(
    private httpService: HttpService,
    private productLibraryConfig: ProductLibraryConfig,
  ) {
  }

  getByUid(uid: string, viewOptions: CategoryViewOptions = CategoryService.CategoryFullView): Observable<Category> {

    let view = CategoryService.buildView(viewOptions);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Category>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'getByUid').pipe(
      map(x => ProductModelFactory.assignCategory(x))
    );
  }

  search(ownerUid: string, departmentUids: string[], statusUids: string[], paginationInput: PaginationInput, viewOptions: CategoryViewOptions = CategoryService.CategoryFullView): Observable<Page<Category>> {

    let view = CategoryService.buildView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(ownerUid: "${ownerUid}", departmentUids: ${JSON.stringify(departmentUids)}, statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: paginationInput
    };

    return this.httpService.graph<Page<Category>>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = ProductModelFactory.assignCategory(edge.node)))
    );
  }

  create(uid: string, ownerUid: string, name: string, description: string, departmentUid: string, viewOptions: CategoryViewOptions = CategoryService.CategoryFullView): Observable<Category> {

    let view = CategoryService.buildView(viewOptions);

    var categoryInput = {
      uid: uid,
      ownerUid: ownerUid,
      name: name,
      description: description,
      departmentUid: departmentUid
    };

    var request = {
      query: `mutation create($category:CategoryInput!) { create(category: $category) ${view} }`,
      variables: { category: categoryInput }
    };

    return this.httpService.graph<Category>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'create').pipe(
      map(x => ProductModelFactory.assignCategory(x))
    );
  }

  update(uid: string, name: string, description: string, departmentUid: string, viewOptions: CategoryViewOptions = CategoryService.CategoryFullView): Observable<Category> {

    let view = CategoryService.buildView(viewOptions);

    var productCategoryInput = {
      uid: uid,
      name: name,
      description: description,
      departmentUid: departmentUid
    };

    var request = {
      query: `mutation update($category:CategoryInput!) { update(category: $category) ${view} }`,
      variables: { category: productCategoryInput }
    };

    return this.httpService.graph<Category>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'update').pipe(
      map(x => ProductModelFactory.assignCategory(x))
    );
  }

  enable(uid: string, viewOptions: CategoryViewOptions = CategoryService.CategoryFullView): Observable<Category> {

    let view = CategoryService.buildView(viewOptions);

    var request = {
      query: `mutation enable { enable(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Category>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'enable').pipe(
      map(x => ProductModelFactory.assignCategory(x))
    );
  }

  disable(uid: string, viewOptions: CategoryViewOptions = CategoryService.CategoryFullView): Observable<Category> {

    let view = CategoryService.buildView(viewOptions);

    var request = {
      query: `mutation disable { disable(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Category>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'disable').pipe(
      map(x => ProductModelFactory.assignCategory(x))
    );
  }

  delete(uid: string, viewOptions: CategoryViewOptions = CategoryService.CategoryFullView): Observable<Category> {

    let view = CategoryService.buildView(viewOptions);

    var request = {
      query: `mutation delete { delete(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Category>(this.productLibraryConfig.apiUrl, this.endPoint, request, 'delete').pipe(
      map(x => ProductModelFactory.assignCategory(x))
    );
  }

  static buildView(viewOptions: CategoryViewOptions) {

    let view = `uid ownerUid name description departmentUid selectableByDefault categoryStatusUid`;

    return '{' + view + '}';
  }
}

export interface CategoryViewOptions {

}
