import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationProvider } from 'core';

@Component({
  selector: 'app-back-office-reports',
  templateUrl: './back-office-reports.component.html',
  styleUrls: ['./back-office-reports.component.scss']
})
export class BackOfficeReportsComponent implements OnInit {

  constructor(
    private router: Router,
    private navigationProvider: NavigationProvider,
  ) {

  }

  ngOnInit() {
    this.navigationProvider.setWaypoint('Reports');
  }

  dailySales() {

    this.navigationProvider.navigate(['backoffice', 'reports', 'dailysales']);
  }
}


