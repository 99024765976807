import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GuestServiceModalComponent } from "./guest-service-modal.component";

@Injectable()
export class GuestServiceModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: GuestServiceModalData): MatDialogRef<GuestServiceModalComponent, GuestServiceModalResult> {

    return this.dialog.open<GuestServiceModalComponent, GuestServiceModalData, GuestServiceModalResult>(GuestServiceModalComponent, {
      height: '100vh',
      minWidth: '100vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface GuestServiceModalData {
  title: string;
  departmentUid: string;
  menuPlacement?: string;
}

export interface GuestServiceModalResult {

  productUid: string,
  productVersion: number,
  portionUid: string,
  eachAmount: number
}
