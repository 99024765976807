<div style="height: 50px; text-align: center; margin-bottom: 10px;">
  <h2 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h2>
</div>

<div style="height: 50px;">
  <form style="font-size: 56px; text-align: center;">
    {{ (amountText | async) | currency:'USD':'symbol':'1.2-2' }}
  </form>
</div>

<ng-container *ngIf="data.allowEdit">
  <mat-grid-list cols="3" rowHeight="100px">
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('7')">7</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('8')">8</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('9')">9</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('4')">4</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('5')">5</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('6')">6</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('1')">1</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('2')">2</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('3')">3</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('back')">Back</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('0')">0</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('00')">00</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('cancel')">Cancel</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="keyPressed('clear')">Clear</mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;">
      <button mat-button [disabled]="!canEnter" (click)="keyPressed('Enter')">
        Enter
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</ng-container>

<!-- <div *ngIf="showDevCardSwipe" style="flex: 1; font-size: 28px; text-align: center; color: black;">
  <div style="height: 8.3333vh;">
    <button mat-flat-button style="font-size: 24px; height: 100%; width: 100%;" (click)="keyPressed('devCard')">Simulate Card Swipe</button>
  </div>
</div> -->

<ng-container *ngIf="!data.allowEdit">
  <div style="flex: 1; font-size: 28px; text-align: center; color: black;">
    <div style="display: flex; height: 8.3333vh;">
      <button mat-flat-button style="font-size: 24px; height: 100%; flex: 1; margin-right: 2px;" (click)="keyPressed('skip')">Skip</button>
      <div style="flex: 1;">
        <app-timeout-button [styles]="{ 'background-color': 'red', 'color': 'white', 'font-size': '24px', 'height': '100%', 'margin-left': '2px' }" [percent]="timeoutPercent" (click)="keyPressed('cancel')">Cancel</app-timeout-button>
      </div>

    </div>
  </div>
</ng-container>