import { Product } from "downtown-product";
import { TransactionItemConfiguration } from "downtown-transaction";

export class CartItem {

  uid: string;
  cartUid: string;
  productUid: string;
  productVersion: number;
  name: string;
  configuration: TransactionItemConfiguration;
  quantity: number = 0;
  eachAmount: number = 0;

  product: Product;

  get total(): number {
    return this.quantity * this.eachAmount;
  }
}
