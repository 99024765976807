import { Component } from "@angular/core";
import { combineLatest, of, Subject, forkJoin } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { catchError, map, concatMap, takeUntil } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { ActionBarConfiguration, ActionConfiguration, FilterConfiguration, FilterOptionConfiguration, MessageModalProvider } from "core";
import { Paginator } from "core";
import { ConfirmModalProvider } from "core";
import { SelectListModalProvider, SelectListModalData } from "core";
import { SpinnerModalComponent } from "core";
import { SpinnerModalProvider } from "core";
import { WaitModalProvider } from "core";
import { NavigationProvider } from "core";
import { EmployeeShiftDataSource, EmployeeShiftModel } from "pos-core";
import { PaymentMethodKeys } from "core";
import { EmployeeShiftStatusKeys, PayPeriodStatusKeys } from "pos-core";
import { ConversationMessage } from "pos-core";
import { PayPeriod } from "pos-core";
import { ClockInOutModalData } from "pos-core";
import { ClockInOutModalProvider } from "pos-core";
import { EmployeeShiftService, EmployeeShiftViewOptions } from "pos-core";
import { PayPeriodService } from "pos-core";
import { PositionService } from "pos-core";
import { ConversationModalData, ConversationModalProvider } from "projects/pos-site/src/app/modals/conversation-modal/conversation-modal.provider";

@Component({
  selector: 'app-back-office-pay-period-shifts',
  templateUrl: './back-office-pay-period-shifts.component.html',
  styleUrls: ['./back-office-pay-period-shifts.component.scss']
})
export class BackOfficePayPeriodShiftsComponent {

  public actionBarConfiguration: ActionBarConfiguration;
  public masterDataSource: EmployeeShiftDataSource;
  public shiftDates: { shiftDate: Date, positions: { position: string, dataSource: EmployeeShiftDataSource }[] }[];
  public paginator: Paginator;

  public title: string;
  public columns: Array<string>;
  private payPeriods: PayPeriod[];

  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private messageModalProvider: MessageModalProvider,
    private selectListModalProvider: SelectListModalProvider,
    private conversationModalProvider: ConversationModalProvider,
    private clockInOutModalProvider: ClockInOutModalProvider,
    private positionService: PositionService,
    private payPeriodService: PayPeriodService,
    private employeeShiftService: EmployeeShiftService,
    private datePipe: DatePipe
  ) {
    this.navigationProvider.setWaypoint('Pay Period Employee Shifts');
    this.title = 'Pay Period Employee Shifts';

    this.masterDataSource = new EmployeeShiftDataSource(
      this.employeeShiftService,
      this.messageModalProvider,
      () => { return new PayPeriodEmployeeShiftModel(); },
      true
    );
    const masterDataSourceEvaluateEnablement = this.masterDataSource.evaluateEnablement.bind(this.masterDataSource);
    this.masterDataSource.evaluateEnablement = employeeShift => {

      employeeShift = masterDataSourceEvaluateEnablement(employeeShift);

      (employeeShift as PayPeriodEmployeeShiftModel).canMovePayPeriod = this.payPeriods?.length > 1;

      return employeeShift;
    };

    this.paginator = new Paginator(this.masterDataSource, 0, 250, 'clockInDateTimeUtc', 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.masterDataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.masterDataSource.connect(null).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(employeeShifts => {
      this.loadDataSources(employeeShifts)
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('New', this.new.bind(this)),
        new ActionConfiguration('Edit', () => this.edit(this.masterDataSource.selection.selected[0]), () => this.masterDataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Pay Period', () => this.payPeriod(this.masterDataSource.selection.selected), () => this.masterDataSource.selection.selected.some(x => (x as PayPeriodEmployeeShiftModel).canMovePayPeriod)),
        new ActionConfiguration('Submit', () => this.submit(this.masterDataSource.selection.selected), () => this.masterDataSource.selection.selected.some(x => x.canSubmit)),
        new ActionConfiguration('Accept', () => this.accept(this.masterDataSource.selection.selected), () => this.masterDataSource.selection.selected.some(x => x.canAccept)),
        new ActionConfiguration('Reject', () => this.reject(this.masterDataSource.selection.selected), () => this.masterDataSource.selection.selected.some(x => x.canReject)),
        new ActionConfiguration('Delete', () => this.delete(this.masterDataSource.selection.selected), () => this.masterDataSource.selection.selected.some(x => x.canDelete)),
        new ActionConfiguration('Bulk', () => this.toggleBulk()),
        new ActionConfiguration('Toggle All', () => this.toggleSelection())
      ],
      [
        new FilterConfiguration('Position', [new FilterOptionConfiguration('All', null)], (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }),
        new FilterConfiguration('Days Of The Week',
          [
            new FilterOptionConfiguration('All', null),
            new FilterOptionConfiguration('Sunday', [1]),
            new FilterOptionConfiguration('Monday', [2]),
            new FilterOptionConfiguration('Tuesday', [3]),
            new FilterOptionConfiguration('Wednesday', [4]),
            new FilterOptionConfiguration('Thursday', [5]),
            new FilterOptionConfiguration('Friday', [6]),
            new FilterOptionConfiguration('Saturday', [7]),
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }),
        new FilterConfiguration('Payment Method',
          [
            new FilterOptionConfiguration('All', null),
            new FilterOptionConfiguration('Cash', [PaymentMethodKeys.Cash]),
            new FilterOptionConfiguration('Check', [PaymentMethodKeys.Check])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }
        ),
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('All', [EmployeeShiftStatusKeys.ClockedIn, EmployeeShiftStatusKeys.ClockedOut, EmployeeShiftStatusKeys.PaymentMade, EmployeeShiftStatusKeys.PaymentScheduled, EmployeeShiftStatusKeys.PaymentCancelled, EmployeeShiftStatusKeys.Submitted, EmployeeShiftStatusKeys.Rejected, EmployeeShiftStatusKeys.Accepted]),
            new FilterOptionConfiguration('Unpaid', [EmployeeShiftStatusKeys.ClockedIn, EmployeeShiftStatusKeys.ClockedOut, EmployeeShiftStatusKeys.PaymentScheduled, EmployeeShiftStatusKeys.Accepted, EmployeeShiftStatusKeys.Submitted, EmployeeShiftStatusKeys.Rejected]),
            new FilterOptionConfiguration('Open', [EmployeeShiftStatusKeys.ClockedIn, EmployeeShiftStatusKeys.Rejected]),
            new FilterOptionConfiguration('Submitted', [EmployeeShiftStatusKeys.Accepted, EmployeeShiftStatusKeys.Submitted, EmployeeShiftStatusKeys.Rejected]),
            new FilterOptionConfiguration('Paid', [EmployeeShiftStatusKeys.PaymentMade])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }
        )
      ]
    );

    // Build initial columns
    this.columns = ['employee', 'clockedIn', 'clockedOut', 'hours', 'status'];
  }

  private loadDataSources(rows: EmployeeShiftModel[]) {

    var employeeShiftDictionary = rows.reduce((employeeShiftDictionary, row) => {
      var shiftDate = new Date(row.item.clockInDateTimeUtc.getFullYear(), row.item.clockInDateTimeUtc.getMonth(), row.item.clockInDateTimeUtc.getDate());

      var employee = (employeeShiftDictionary[shiftDate.getTime()] = employeeShiftDictionary[shiftDate.getTime()] || <EmployeeShiftModel[]>[]);
      employee.push(row);

      return employeeShiftDictionary;
    }, <{ [employeeUid: string]: EmployeeShiftModel[] }>{});

    this.shiftDates = Object.entries(employeeShiftDictionary).map(([shiftDate, employeeShifts]) => {

      // Build position details from pay details, group by position
      var positionShiftDictionary = employeeShifts.reduce((positionShiftDictionary, row) => {
        var positionUid = row.item.positionUid;

        var position = (positionShiftDictionary[positionUid] = positionShiftDictionary[positionUid] || <EmployeeShiftModel[]>[]);
        position.push(row);

        return positionShiftDictionary;
      }, <{ [position: string]: EmployeeShiftModel[] }>{});

      var positionDetails = Object.keys(positionShiftDictionary).map(x => {
        var elements = positionShiftDictionary[x];

        let dataSource = new EmployeeShiftDataSource(this.employeeShiftService, this.messageModalProvider, () => { return new PayPeriodEmployeeShiftModel(); }, true);
        dataSource.selection = this.masterDataSource.selection;

        dataSource.loadFrom(elements.sort((x, y) => Math.sign(y.item.clockInDateTimeUtc.getTime() - x.item.clockInDateTimeUtc.getTime())));

        return <{ position: string, dataSource: EmployeeShiftDataSource }>{
          position: elements[0].item.position.name,
          dataSource: dataSource
        };
      }).sort((x, y) => Math.sign(x.position.localeCompare(y.position)));

      return <{ shiftDate: Date, positions: { position: string, dataSource: EmployeeShiftDataSource }[] }>{
        shiftDate: new Date(parseInt(shiftDate)),
        positions: positionDetails
      };
    });
  }

  ngOnInit() {

    let spinnerModalRef = this.spinnerModalProvider.open();
    spinnerModalRef.afterOpened().subscribe(() => {
      let payPeriodUid = this.activatedRoute.snapshot.params['payPeriodUid'];

      forkJoin([
        this.positionService.search(null, null),
        this.payPeriodService.getByUid(payPeriodUid),
        this.payPeriodService.search([PayPeriodStatusKeys.InReview, PayPeriodStatusKeys.Open], null)
      ]).subscribe(([positionsPage, payPeriod, payPeriodsPage]) => {
        this.payPeriods = payPeriodsPage.edges.map(x => x.node);

        this.title += ` [${this.datePipe.transform(payPeriod.startDateTimeUtc, 'M-dd-yyyy')} to ${this.datePipe.transform(payPeriod.endDateTimeUtc, 'M-dd-yyyy')}]`

        let positionFilter = this.actionBarConfiguration.filters.find(x => x.title == 'Position');
        positionFilter.options.push(...positionsPage.edges.map(x => new FilterOptionConfiguration(x.node.name, [x.node.uid])));

        combineLatest([
          this.actionBarConfiguration.filters.find(x => x.title == 'Position').selected,
          this.actionBarConfiguration.filters.find(x => x.title == 'Days Of The Week').selected,
          this.actionBarConfiguration.filters.find(x => x.title == 'Payment Method').selected,
          this.actionBarConfiguration.filters.find(x => x.title == 'Status').selected,
          this.paginator.pageRequest$
        ]).pipe(
          takeUntil(this.destroyed$),
          map(([positionFilterOption, daysOfTheWeekFilterOption, paymentMethodFilterOption, statusFilterOption, paginationInput]) => {
            let positionFilterUids = positionFilterOption.value;
            let daysOfTheWeekFilterUids = daysOfTheWeekFilterOption.value;
            let paymentMethodFilterUids = paymentMethodFilterOption.value;
            let statusFilterUids = statusFilterOption.value;

            const viewOptions = <EmployeeShiftViewOptions>{ includeEmployee: true, includePosition: true };

            this.masterDataSource.loadData(null, positionFilterUids, paymentMethodFilterUids, [payPeriodUid], daysOfTheWeekFilterUids, statusFilterUids, paginationInput, viewOptions);
          })
        ).subscribe();

        spinnerModalRef.close();
      },
        err => {
          console.log(err);
        }
      );
    });
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  isAllSelected() {

    const numSelected = this.masterDataSource.selection.selected.length;
    const numRows = this.masterDataSource.currentPage.length;

    return numSelected == numRows;
  }

  toggleBulk() {

    const selectColumn = this.columns.find(x => x == 'select');
    if (selectColumn) {
      this.columns.splice(0, 1);
    } else {
      this.columns.unshift('select');
    }
  }

  toggleSelection() {

    let dataSources = this.shiftDates.reduce((x1, x2) => x1.concat(x2.positions.map(y => y.dataSource)), <EmployeeShiftDataSource[]>[]);

    if (this.isAllSelected()) {
      dataSources.forEach(dataSource => dataSource.selection.clear());
      // this.masterDataSource.selection.clear()
    } else {
      dataSources.forEach(dataSource => dataSource.selection.select(...dataSource.currentPage));
    }
  }

  public new() {

    this.clockInOutModalProvider.open(<ClockInOutModalData>{
      loadExisting: false,
      canSelectEmployee: true,
      employeeUid: this.activatedRoute.snapshot.params['employeeUid']
    }).afterClosed().subscribe(employeeShift => {
      this.paginator.pageIndex = this.paginator.pageIndex;
    });
  }

  public edit(row: EmployeeShiftModel) {

    this.clockInOutModalProvider.open(<ClockInOutModalData>{
      loadExisting: false,
      canSelectEmployee: true,
      employeeSelectMode: 'name',
      employeeUid: row.item.employeeUid,
      employeeShiftUid: row.item.uid
    }).afterClosed().subscribe(employeeShift => {
      if (employeeShift) {
        this.masterDataSource.merge(employeeShift);
      }
    });
  }

  public payPeriod(rows: EmployeeShiftModel[]) {

    this.selectListModalProvider.open(<SelectListModalData<PayPeriod>>{
      title: 'Select PayPeriod',
      options: [{ uid: null }, ...this.payPeriods],
      displayFunc: (payPeriod) => {
        return payPeriod.uid == null ? 'None' : `${this.datePipe.transform(payPeriod.startDateTimeUtc, 'M-dd-yyyy')} - ${this.datePipe.transform(payPeriod.endDateTimeUtc, 'M-dd-yyyy')}`;
      }
    }).afterClosed().subscribe(value => {
      const movingEmployeeShifts = rows.filter(row => row.item.payPeriodUid != (<PayPeriod>value).uid);

      let waitDialogRef = this.waitModalProvider.open('Updating shift pay period...');

      waitDialogRef.afterOpened().pipe(
        concatMap(() => this.masterDataSource.updatePayPeriod(movingEmployeeShifts, (<PayPeriod>value).uid)),
        catchError(() => of(null))
      ).subscribe(() => waitDialogRef.close());
    });
  }

  public submit(rows: EmployeeShiftModel[]) {

    let shifts = rows.filter(x => this.masterDataSource.submittable.find(y => y.item.uid === x.item.uid));

    let confirmDialogRef = this.confirmModalProvider.open('Submit Shifts', `Are you certain you want to submit ${shifts.length} of ${rows.length} selected shifts?`);
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Submitting for approval...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.masterDataSource.submit(shifts)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public accept(rows: EmployeeShiftModel[]) {

    let shifts = rows.filter(x => this.masterDataSource.acceptable.find(y => y.item.uid === x.item.uid));

    let confirmDialogRef = this.confirmModalProvider.open('Accept Shifts', `Are you certain you want to accept ${shifts.length} of ${rows.length} selected shifts?`);
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Accepting...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.masterDataSource.accept(shifts)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public reject(employeeShifts: EmployeeShiftModel[]) {

    let shifts = employeeShifts.filter(x => this.masterDataSource.rejectable.find(y => y.item.uid === x.item.uid));

    let confirmDialogRef = this.confirmModalProvider.open('Reject Shifts', `Are you certain you want to reject ${shifts.length} of ${employeeShifts.length} selected shifts?`);
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Rejecting...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.masterDataSource.reject(shifts)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public delete(employeeShifts: EmployeeShiftModel[]) {

    let confirmDialogRef = this.confirmModalProvider.open('Delete Shifts', `Are you certain you want to delete ${employeeShifts.length} selected shifts?`);
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Deleting...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.masterDataSource.delete(employeeShifts)),
          catchError(() => of(null))
        ).subscribe(() => {
          waitDialogRef.close();
          this.paginator.pageIndex = this.paginator.pageIndex;
        });
      }
    });
  }

  public viewConversations(row: EmployeeShiftModel) {

    this.conversationModalProvider.open(<ConversationModalData>{
      refAggregateUid: row.item.uid
    }).afterClosed().subscribe((message: ConversationMessage) => {
      if (message) {
        this.employeeShiftService.getByUid(row.item.uid).subscribe(update => {
          row.item.conversationCount = update.conversationCount;
        });
      }
    });
  }
}

class PayPeriodEmployeeShiftModel extends EmployeeShiftModel {

  canMovePayPeriod: boolean;
}
