import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from "downtown-product";
import { PaginationInput } from 'core';
import { NavigationProvider } from 'core';
import { Cart } from '../../../models/cart';
import { CartService } from '../../../services/cart.service';
import { ProductService } from 'downtown-product';

@Component({
  selector: 'app-store-checkout-payment',
  templateUrl: './store-checkout-payment.component.html',
  styleUrls: ['./store-checkout-payment.component.scss']
})
export class StoreCheckoutPaymentComponent implements OnInit {

  public pagination = <PaginationInput>{ pageSize: 25, pageIndex: 0, sortField: 'name', sortStrategy: 'asc' };

  public products: Product[];
  public cart: Cart;

  constructor(
    private navigationProvider: NavigationProvider,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private cartService: CartService
  ) {
  }

  ngOnInit() {
    this.navigationProvider.updateTitle("Store");

    this.productService.search(null, null, null, null, this.pagination).subscribe(productsPage => {
      if (productsPage) {
        // TODO: // changed serach
        // this.products = productsPage.edges.map(x => x.node);
      }
    })
  }

  addToCart(product: Product) {

  }

  checkOut() {

  }
}
