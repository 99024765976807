export class ProductInput {

  uid: string;
  ownerUid: string;
  sku: string;
  name: string;
  abbreviation: string;
  description: string;
  imageUid: string;
  displayOrder: number;
  departmentUid: string;
  categoryUid: string;
  basePrice: number;
  productStatusUid: string;

  configuration: ProductConfigurationInput;
}

export class ProductConfigurationInput {

  portions: ProductConfigurationPortionInput[];
  preparations: ProductConfigurationPreparationInput[];
  variations: ProductConfigurationVariationInput[];
  inclusionGroups: ProductConfigurationInclusionGroupInput[];
  addOns: ProductConfigurationAddOnInput[];
}

export class ProductConfigurationPortionInput {

  uid: string;
  name: string;
  menuPlacementUid: string;
  availableAsInclusion: boolean;
  availableAsAddOn: boolean;
  preparations?: ProductConfigurationPreparationInput[];
  variations?: ProductConfigurationVariationInput[];
  inclusionGroups?: ProductConfigurationInclusionGroupInput[];
  addOns?: ProductConfigurationAddOnInput[];
  price?: number;
}

export class ProductConfigurationPreparationInput {

  uid: string;
  name: string;
  options: ProductConfigurationPreparationOptionInput[];
}

export class ProductConfigurationPreparationOptionInput {

  uid: string;
  name: string;
  additionalPrice?: number;
}

export class ProductConfigurationVariationInput {

  uid: string;
  name: string;
  defaultOptionUid?: string;
  options: ProductConfigurationVariationOptionInput[];
}

export class ProductConfigurationVariationOptionInput {

  uid: string;
  productUid?: string; // IS THIS CORRECT ????  MORE BELOW
  portionUid?: string;
  alias?: string;
  priceOverride?: number;
}

export class ProductConfigurationInclusionGroupInput {

  uid: string;
  name?: string;
  options: ProductConfigurationInclusionGroupOptionInput[];
  maxDistinctOptionsIncluded?: number;
  minQuantityForOption?: number;
  maxTotalOptionQuantityIncluded?: number;
  allowSubstitution?: boolean;
  allowAdditional?: boolean;
}

export class ProductConfigurationInclusionGroupOptionInput {

  uid: string;
  productUid?: string;
  portionUid?: string;
  preparations?: ProductConfigurationPreparationInput[];
  variations?: ProductConfigurationVariationInput[];
  alias?: string;
  defaultQuantity?: number;
  maxIncludedQuantity?: number;
  priceOverride?: number;
}

export class ProductConfigurationAddOnInput {

  uid: string;
  productUid?: string;
  portionUid?: string;
  alias?: string;
  priceOverride?: number;

  product: ProductInput
}
