import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ConfirmModalComponent } from './confirm-modal.component';

@Injectable()
export class ConfirmModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(title: string, message: string): MatDialogRef<ConfirmModalComponent, boolean> {

    return this.dialog.open<ConfirmModalComponent, any, boolean>(ConfirmModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: <ConfirmModalData>{
        title: title,
        message: message
      }
    });
  }
}

export interface ConfirmModalData {
  title: string;
  message: string;
}
