import { Component, Input, EventEmitter, Output } from '@angular/core';
import { slideInAnimation, slideOutAnimation } from 'core';
import { EditableTransaction, EditableTransactionItem, EditableTransactionItemAdjustment } from '../../../models/editable-transaction/editable-transaction';

@Component({
  selector: 'transaction-transaction-editor-item-adjustment',
  templateUrl: './transaction-editor-item-adjustment.component.html',
  styleUrls: ['./transaction-editor-item-adjustment.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class TransactionEditorItemAdjustmentComponent {

  @Input() editableTransaction: EditableTransaction
  @Input() item: EditableTransactionItem;
  @Input() itemAdjustment: EditableTransactionItemAdjustment;
  @Output() select = new EventEmitter<EditableTransactionItemAdjustment>();
  @Output() void = new EventEmitter<EditableTransactionItemAdjustment>();
  @Output() cancel = new EventEmitter<EditableTransactionItemAdjustment>();
}
