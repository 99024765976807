import { EditableOrder, EditableOrderItem, EditableOrderItemAdjustment, EditableOrderPayment, EditableOrderAdjustment, EditableCardTransaction, EditableOrderItemConfiguration, EditableOrderItemConfigurationPortion, EditableOrderItemConfigurationAddOn } from './editable-order';
import { OrderStatusKeys } from '../keys';
import { Order, OrderItem, OrderItemAdjustment, OrderAdjustment, PaymentItem as PaymentItem, OrderItemConfiguration, OrderItemConfigurationPortion, OrderItemConfigurationAddOn } from './order';
import { CardTransaction } from './card-transaction';
import { LoggingProvider } from 'core';
import Decimal from 'decimal.js';

export class EditableOrderDataHandler {

  constructor(
    private loggingProvider: LoggingProvider
  ) {

  }

  getSelection(editableOrder: EditableOrder): EditableOrderItem | EditableOrderItemAdjustment | EditableOrderPayment | EditableOrderAdjustment {

    return editableOrder ? editableOrder.selection.value : null;
  }

  // public getSelectedLineItem(): Observable<OrderLineItem> {

  //   return this.selectedLineItem;
  // }

  public setSelectedItem(editableOrder: EditableOrder, item: EditableOrderItem | EditableOrderItemAdjustment | EditableOrderPayment | EditableOrderAdjustment): EditableOrderItem | EditableOrderItemAdjustment | EditableOrderPayment | EditableOrderAdjustment {

    if (editableOrder) {
      let selection = editableOrder.selection.value;
      if (item != selection) {
        if (selection != null) {
          // currentLineItem.commit();
        }

        this.loggingProvider.log(`setting SelectedLineItem to ${item == null ? 'null' : 'lineItem'}`);

        editableOrder.selection.next(item);
      }

      return editableOrder.selection.value;
    } else {
      return null;
    }
  }

  getOrCreateOrder(editableOrder: EditableOrder): EditableOrder {

    if (editableOrder == null || editableOrder.orderStatusUid.value.toUpperCase() == OrderStatusKeys.Fulfilled.toUpperCase()) {
      this.loggingProvider.log(`creating new transaction`);
      editableOrder = new EditableOrder();
    }


    return editableOrder;
  }

  cancelOrder(editableOrder: EditableOrder) {

    if (editableOrder && editableOrder.orderStatusUid.value.toUpperCase() != OrderStatusKeys.Fulfilled.toUpperCase()) {
      this.loggingProvider.log(`cancelling transaction`);

      editableOrder.orderStatusUid.next(OrderStatusKeys.Cancelled);
    }

    return editableOrder;
  }

  getOrCreateOrderLineItem(editableOrder: EditableOrder): EditableOrderItem {

    var selection = this.getSelection(editableOrder) as EditableOrderItem;

    let lineItems = editableOrder.items;
    let transactionLineItem = selection && selection.uid ? lineItems.find(x => x.uid.toLowerCase() == selection.uid.toLowerCase()) : selection;
    if (transactionLineItem == null) {
      this.loggingProvider.log(`creating new transactionLineItem`);

      transactionLineItem = editableOrder.addOrderItem();
      //     saleLineItem.makeEditable();
      //     this.synchronizeFeatureEnabling();
      //     if (transaction.getCurrentLineItem() == null) {
      //       this.lastEntryContext = LastEntryContextEnum.None;
      //     }

      //     setTimeout(() => this.editor.ensureActiveLineViewable(), 1);

    } else {
      //     if (transaction.getCurrentLineItem() == null) {
      //       this.lastEntryContext = LastEntryContextEnum.None;
      //     }

      //     return saleLineItem;
    }

    return transactionLineItem;
  }

  includeOrderLineItem(editableOrder: EditableOrder, transactionLineItem: EditableOrderItem): EditableOrderItem {

    return editableOrder.addOrderItem(transactionLineItem);
  }

  getOrCreatePaymentLineItem(editableOrder: EditableOrder): EditableOrderPayment {

    var selectedLineItem = this.getSelection(editableOrder) as EditableOrderPayment;

    let payments = editableOrder.payments;
    let paymentLineItem = selectedLineItem && selectedLineItem.uid ? payments.find(x => x.uid.toLowerCase() == selectedLineItem.uid.toLowerCase()) : selectedLineItem;
    if (paymentLineItem == null) {
      this.loggingProvider.log(`creating new paymentLineItem`);

      paymentLineItem = editableOrder.addPaymentLineItem();
      //     saleLineItem.makeEditable();
      //     this.synchronizeFeatureEnabling();
      //     if (transaction.getCurrentLineItem() == null) {
      //       this.lastEntryContext = LastEntryContextEnum.None;
      //     }

      //     setTimeout(() => this.editor.ensureActiveLineViewable(), 1);

    } else {
      //     if (transaction.getCurrentLineItem() == null) {
      //       this.lastEntryContext = LastEntryContextEnum.None;
      //     }

      //     return saleLineItem;
    }

    return paymentLineItem;
  }

  getOrCreateOrderAdjustmentLineItem(editableOrder: EditableOrder): EditableOrderAdjustment {

    var selectedLineItem = this.getSelection(editableOrder) as EditableOrderAdjustment;

    let adjustments = editableOrder.adjustments;
    let transactionAdjustmentLineItem = selectedLineItem && selectedLineItem.uid ? adjustments.find(x => x.uid.toLowerCase() == selectedLineItem.uid.toLowerCase()) : selectedLineItem;

    if (transactionAdjustmentLineItem == null) {
      this.loggingProvider.log(`creating new OrderAdjustmentLineItem`);

      transactionAdjustmentLineItem = editableOrder.addOrderAdjustmentLineItem();
      //     saleLineItem.makeEditable();
      //     this.synchronizeFeatureEnabling();
      //     if (transaction.getCurrentLineItem() == null) {
      //       this.lastEntryContext = LastEntryContextEnum.None;
      //     }

      //     setTimeout(() => this.editor.ensureActiveLineViewable(), 1);

    } else {
      //     if (transaction.getCurrentLineItem() == null) {
      //       this.lastEntryContext = LastEntryContextEnum.None;
      //     }

      //     return saleLineItem;
    }

    return transactionAdjustmentLineItem;
  }

  getOrCreateOrderLineItemAdjustment(editableOrder: EditableOrder, transactionLineItem: EditableOrderItem): EditableOrderItemAdjustment {

    var selectedLineItem = this.getSelection(editableOrder) as EditableOrderItemAdjustment;

    let adjustments = transactionLineItem.adjustments;
    let transactionLineItemAdjustment = selectedLineItem && selectedLineItem.uid ? adjustments.find(x => x.uid.toLowerCase() == selectedLineItem.uid.toLowerCase()) : selectedLineItem;

    if (transactionLineItemAdjustment == null) {
      this.loggingProvider.log(`creating new transactionLineItemAdjustment`);

      transactionLineItemAdjustment = transactionLineItem.addOrderLineItemAdjustment();
      //     saleLineItem.makeEditable();
      //     this.synchronizeFeatureEnabling();
      //     if (transaction.getCurrentLineItem() == null) {
      //       this.lastEntryContext = LastEntryContextEnum.None;
      //     }

      //     setTimeout(() => this.editor.ensureActiveLineViewable(), 1);

    } else {
      //     if (transaction.getCurrentLineItem() == null) {
      //       this.lastEntryContext = LastEntryContextEnum.None;
      //     }

      //     return saleLineItem;
    }

    return transactionLineItemAdjustment;
  }

  cancelLineItemChanges(editableOrder: EditableOrder, lineItem: EditableOrderItem | EditableOrderItemAdjustment | EditableOrderAdjustment | EditableOrderPayment) {

    if (lineItem) {
      if (lineItem instanceof EditableOrderItem) {
        return this.cancelOrderLineItem(editableOrder, lineItem);
      } else if (lineItem instanceof EditableOrderItemAdjustment) {
        return this.cancelOrderLineItemAdjustment(editableOrder, lineItem);
      } else if (lineItem instanceof EditableOrderAdjustment) {
        return this.cancelOrderAdjustmentLineItem(editableOrder, lineItem);
      } else if (lineItem instanceof EditableOrderPayment) {
        return this.cancelPaymentLineItem(editableOrder, lineItem);
      }
    }

    return null;
  }

  private cancelOrderLineItem(editableOrder: EditableOrder, transactionLineItem: EditableOrderItem): EditableOrderItem {

    if (transactionLineItem.uid) {
      editableOrder.resetOrderLineItem(transactionLineItem);
    } else {
      editableOrder.removeOrderLineItem(transactionLineItem);
    }

    return transactionLineItem;
  }

  private cancelOrderLineItemAdjustment(editableOrder: EditableOrder, transactionLineItemAdjustment: EditableOrderItemAdjustment): EditableOrderItemAdjustment {

    var transactionLineItem = editableOrder.items.find(x => x.uid == transactionLineItemAdjustment.transactionLineItemUid);
    if (transactionLineItem) {
      if (transactionLineItemAdjustment.uid) {
        transactionLineItem.resetOrderLineItemAdjustment(transactionLineItemAdjustment);
      } else {
        transactionLineItem.removeOrderLineItemAdjustment(transactionLineItemAdjustment);
      }
    }

    return transactionLineItemAdjustment;
  }

  private cancelOrderAdjustmentLineItem(editableOrder: EditableOrder, transactionAdjustmentLineItem: EditableOrderAdjustment): EditableOrderAdjustment {

    if (transactionAdjustmentLineItem.uid) {
      editableOrder.resetOrderAdjustmentLineItem(transactionAdjustmentLineItem);
    } else {
      editableOrder.removeOrderAdjustmentLineItem(transactionAdjustmentLineItem);
    }

    return transactionAdjustmentLineItem;
  }

  private cancelPaymentLineItem(editableOrder: EditableOrder, paymentLineItem: EditableOrderPayment): EditableOrderPayment {

    if (paymentLineItem.uid) {
      editableOrder.resetPaymentLineItem(paymentLineItem);
    } else {
      editableOrder.removePaymentLineItem(paymentLineItem);
    }

    return paymentLineItem;
  }

  removeLineItem(editableOrder: EditableOrder, lineItem: EditableOrderItemAdjustment | EditableOrderPayment) {

    if (lineItem) {
      if (lineItem instanceof EditableOrderItemAdjustment) {
        let transactionLineItem = editableOrder.items.find(x => x.uid == lineItem.transactionLineItemUid)

        transactionLineItem.removeOrderLineItemAdjustment(lineItem);
      } else if (lineItem instanceof EditableOrderAdjustment) {
        editableOrder.removeOrderAdjustmentLineItem(lineItem);
      } else if (lineItem instanceof EditableOrderPayment) {
        editableOrder.removePaymentLineItem(lineItem);
      }
    }
  }

  mergeOrder(target: EditableOrder, source: Order): EditableOrder {

    target = target || new EditableOrder();

    target.uid = source.uid;
    target.ownerUid = source.ownerUid;
    target.number = source.number;
    target.identityUid = source.identityUid;
    target.cartUid = source.cartUid;
    target.orderQuantity = source.orderQuantity;
    target.fulfilledQuantity = source.fulfilledQuantity;
    target.subTotalAmount = source.subTotalAmount;
    target.shippingAmount = source.shippingAmount;
    target.taxAmount = source.taxAmount;
    target.totalAmount = source.totalAmount;
    target.openDateTimeUtc = source.openDateTimeUtc;
    target.modifiedDateTimeUtc = source.modifiedDateTimeUtc;
    target.closeDateTimeUtc = source.closeDateTimeUtc;
    target.orderStatusUid.next(source.orderStatusUid);

    target.initiatorIdentity = source.initiatorIdentity;
    target.billingAddress = source.billingAddress;
    target.shippingAddress = source.shippingAddress;

    if (source.items) {
      source.items.forEach(item => {
        this.mergeOrderItem(target.items.find(x => x.uid.toLowerCase() == item.uid.toLowerCase()) || target.addOrderItem(), item);
      });
      target.items.forEach(editableItem => {
        var item = source.items.find(x => x.uid.toUpperCase() == editableItem.uid.toUpperCase());
        if (!item) {
          target.items.splice(target.items.indexOf(editableItem), 1);
        }
      });
    }

    if (source.adjustments) {
      source.adjustments.forEach(adjustment => {
        this.mergeOrderAdjustment(target.adjustments.find(x => x.uid.toLowerCase() == adjustment.uid.toLowerCase()) || target.addOrderAdjustmentLineItem(), adjustment);
      });
      target.adjustments.forEach(editableAdjustment => {
        var adjustment = source.adjustments.find(x => x.uid.toUpperCase() == editableAdjustment.uid.toUpperCase());
        if (!adjustment) {
          target.adjustments.splice(target.adjustments.indexOf(editableAdjustment), 1);
        }
      });
    }

    if (source.payments) {
      source.payments.forEach(payment => {
        this.mergePayment(target.payments.find(x => x.uid.toLowerCase() == payment.uid.toLowerCase()) || target.addPaymentLineItem(), payment);
      });
      target.payments.forEach(editablePayment => {
        var payment = source.payments.find(x => x.uid.toUpperCase() == editablePayment.uid.toUpperCase());
        if (!payment) {
          target.payments.splice(target.payments.indexOf(editablePayment), 1);
        }
      });
    }

    return target;
  }

  mergeOrderItem(target: EditableOrderItem, source: OrderItem): EditableOrderItem {

    target = target || new EditableOrderItem();

    target.uid = source.uid;
    target.productUid.next(source.productUid);
    target.configuration.next(source.configuration ? this.mergeOrderItemConfiguration(target.configuration.value, source.configuration) : null);
    target.orderQuantity.next(source.orderQuantity);
    target.eachAmount.next(source.eachAmount);
    target.fulfilledQuantity.next(source.fulfilledQuantity);

    if (source.adjustments) {
      source.adjustments.forEach(adjustment => {
        const transactionLineItemAdjustment = target.adjustments.find(x => x.uid.toLowerCase() == adjustment.uid.toLowerCase()) || target.addOrderLineItemAdjustment();

        this.mergeOrderItemAdjustment(transactionLineItemAdjustment, adjustment);
      });
    }

    target.takeSnapshot();

    return target;
  }

  mergeOrderItemConfiguration(target: EditableOrderItemConfiguration, source: OrderItemConfiguration): EditableOrderItemConfiguration {

    target = target || new EditableOrderItemConfiguration();

    target.portion = this.mergeOrderItemConfigurationPortion(target.portion, source.portion);

    return target;
  }

  mergeOrderItemConfigurationPortion(target: EditableOrderItemConfigurationPortion, source: OrderItemConfigurationPortion): EditableOrderItemConfigurationPortion {

    target = target || new EditableOrderItemConfigurationPortion();

    target.portionUid = source.portionUid;
    target.preparations = source.preparations;
    target.variations = source.variations;
    target.inclusionGroups = source.inclusionGroups;
    target.inclusions = source.inclusions;
    target.extras = source.extras;
    target.addOns = this.mergeTransactionItemConfigurationAddOns(target.addOns, source.addOns);

    return target;
  }

  mergeTransactionItemConfigurationAddOns(targets: EditableOrderItemConfigurationAddOn[], sources: OrderItemConfigurationAddOn[]): EditableOrderItemConfigurationAddOn[] {

    if (sources) {
      targets = targets || [];

      sources.forEach(source => {
        const target = EditableOrderDataHandler.getOrCreateOrderItemConfigurationAddOn(targets, source.addOnUid);
        this.mergeOrderItemConfigurationAddOn(target, source);
      });
      targets.forEach(target => {
        const source = sources.find(x => x.addOnUid.toLowerCase() == target.addOnUid.toLowerCase());
        if (!source) {
          targets.splice(targets.indexOf(target), 1);
        }
      })
    } else {
      targets = null;
    }

    return targets && targets.length > 0 ? targets : null;
  }

  public static getOrCreateOrderItemConfigurationAddOn(targets: EditableOrderItemConfigurationAddOn[], addOnUid: string): EditableOrderItemConfigurationAddOn {

    let target = targets.find(x => x.addOnUid.toLowerCase() == addOnUid.toLowerCase());
    if (!target) {
      target = new EditableOrderItemConfigurationAddOn();
      targets.push(target);
    }

    return target;
  }

  mergeOrderItemConfigurationAddOn(target: EditableOrderItemConfigurationAddOn, source: OrderItemConfigurationAddOn): EditableOrderItemConfigurationAddOn {

    target = target || new EditableOrderItemConfigurationAddOn();

    target.addOnUid = source.addOnUid;
    target.item = source.item ? this.mergeOrderItem(target.item, source.item) : null;

    return target;
  }

  mergeOrderItemAdjustment(transactionLineItemAdjustment: EditableOrderItemAdjustment, adjustment: OrderItemAdjustment) {

    transactionLineItemAdjustment.uid = adjustment.uid;
    transactionLineItemAdjustment.description = adjustment.description;
    transactionLineItemAdjustment.quantity.next(adjustment.quantity);
    transactionLineItemAdjustment.eachAmountText.next(new Decimal(adjustment.eachAmount).toFixed(2));
    transactionLineItemAdjustment.isVoid.next(adjustment.isVoid);
    transactionLineItemAdjustment.isCompensated.next(adjustment.isCompensated);

    transactionLineItemAdjustment.takeSnapshot();
  }

  mergeOrderAdjustment(transactionAdjustmentLineItem: EditableOrderAdjustment, adjustment: OrderAdjustment) {

    transactionAdjustmentLineItem.uid = adjustment.uid;
    transactionAdjustmentLineItem.description.next(adjustment.description);
    transactionAdjustmentLineItem.amountText.next(new Decimal(adjustment.amount).toFixed(2));
    transactionAdjustmentLineItem.isVoid.next(adjustment.isVoid);
    transactionAdjustmentLineItem.isCompensated.next(adjustment.isCompensated);

    transactionAdjustmentLineItem.takeSnapshot();
  }

  mergePayment(editablePaymentLineItem: EditableOrderPayment, payment: PaymentItem) {

    editablePaymentLineItem.uid = payment.uid;
    editablePaymentLineItem.paymentMethodUid.next(payment.paymentMethodUid);
    editablePaymentLineItem.cardTransactionUid.next(payment.cardTransactionUid);
    editablePaymentLineItem.amount.next(payment.amount);
    editablePaymentLineItem.tip.next(payment.tip);

    if (payment.cardTransaction) {
      editablePaymentLineItem.cardTransaction = editablePaymentLineItem.cardTransaction || new EditableCardTransaction();
      this.mergeCardTransaction(editablePaymentLineItem.cardTransaction, payment.cardTransaction);
    }

    editablePaymentLineItem.takeSnapshot();
  }

  mergeCardTransaction(editableCardTransaction: EditableCardTransaction, cardTransaction: CardTransaction) {

    editableCardTransaction.uid = cardTransaction.uid;
    editableCardTransaction.processorReferenceId = cardTransaction.processorReferenceId;
    editableCardTransaction.name.next(cardTransaction.name);
    editableCardTransaction.lastFour.next(cardTransaction.lastFour);
    editableCardTransaction.amount.next(cardTransaction.amount);
    editableCardTransaction.tip.next(cardTransaction.tip || 0);
    editableCardTransaction.cardTransactionStatusUid.next(cardTransaction.cardTransactionStatusUid);
    editableCardTransaction.createdDateTimeUtc.next(cardTransaction.createdDateTimeUtc);
    editableCardTransaction.modifiedDateTimeUtc.next(cardTransaction.modifiedDateTimeUtc);
  }
}
