import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, skip, takeUntil } from "rxjs";

/**
 * Class that provides a heartbeat for recurring background processes
 */
@Injectable()
export class CurrentDateTimeProvider {

  public currentDateTime$: Observable<Date>;

  private currentDateTimeSubject$ = new BehaviorSubject<Date>(this.getCurrentDateTime());
  private clockInterval: NodeJS.Timeout;
  private destroyed$ = new Subject<void>();

  constructor(
  ) {
    this.currentDateTime$ = this.currentDateTimeSubject$.asObservable().pipe(
      skip(1),
      takeUntil(this.destroyed$)
    );

    this.startTimer();
  }

  ngOnDestroy() {

    this.destroyed$.next();
  }

  public get currentDateTime(): Date {

    return this.currentDateTimeSubject$.value;
  }

  public startTimer() {

    if (!this.clockInterval) {
      this.clockInterval = setInterval(() => {
        this.currentDateTimeSubject$.next(this.getCurrentDateTime());
      }, 1000);
    }
  }

  public endTimer() {

    if (this.clockInterval) {
      clearInterval(this.clockInterval);
      this.clockInterval = null;
    };
  }

  private getCurrentDateTime(): Date {

    return new Date();
  }
}
