export class IdentityUser {

  id: string;
  username: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  twoFactorEnabled: boolean;

  claims: Claim[];

  getFullName(): string {
    return `${this.firstName ? this.firstName : ''} ${this.lastName ? this.lastName : ''}`.trim();
  }

  getReportName(): string {
    return `${this.lastName ? this.lastName : ''}, ${this.firstName ? this.firstName : ''}`.trim();
  }

  getDisplay(): string {
    const displayableName = this.displayName != null && this.displayName != '' ? this.displayName : this.getFullName();
    return displayableName != null && displayableName != '' ? displayableName : this.email;
  }
}

export class Claim {

  type: string;
  value: string;
}
