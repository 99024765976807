export class CategoryStatusKeys {

  public static readonly Active = '8FB8FAEC-4CF7-49BE-B007-9DAC5D21D4B3';
  public static readonly Inactive = '51BA0845-3F18-4AC2-8012-6723441D096C';
  public static readonly Deleted = 'E12AB309-F771-40E3-BED5-77C40349BCD1';
}

export class DepartmentStatusKeys {

  public static readonly Active = '95480927-7552-4C7C-9DCC-906B8984DFD2';
  public static readonly Inactive = 'E720414C-6FFF-44AC-8809-35F22DD066DC';
  public static readonly Deleted = 'A8B3F9FD-4422-464C-A2F3-390B9C70A918';
}

export class MenuPlacement {

  public static readonly Menu = 'B8F62863-6F65-4FD7-9F29-BD8B37610E88';
  public static readonly OffMenu = 'EE202D9D-9F44-492F-8A3F-EDC23A2414AD';
  public static readonly NoSale = 'C35072A2-2B8B-4F08-9762-316544646D5F';
}

export class ProductStatusKeys {

  public static readonly Active = 'ca465387-188e-448c-b7df-5765cbf6f125';
  public static readonly Inactive = '88a24634-7f03-48be-bfcf-e584d88f96b2';
  public static readonly Deleted = 'fed4d2bc-fcd3-48ed-96f3-761d639c8482';
}

export class ProductTypeKeys {

  public static readonly Physical = '5CE8B01D-4F1A-4F82-888B-5C33772F970B';
  public static readonly Service = '68EDDF2E-0662-4EB4-A236-4E2A9D5F3CF2';
  public static readonly Virtual = '22428566-88C1-4336-9941-A8EAF88D934F';
}
