import { Injectable } from "@angular/core";
import { take } from "rxjs";
import { environment } from '../../environments/environment';
import { EntityEventProvider } from "core";
import { TerminalProvider, TerminalService } from "downmain-terminal";

@Injectable()
export class SiteTerminalProvider extends TerminalProvider {

  constructor(
    terminalService: TerminalService,
    entityEventProvider: EntityEventProvider
  ) {
    super(terminalService, entityEventProvider);

  }

  protected getActive() {

    this.statusSubject$.next(`Initializing...`);
    this.terminalService.getByUid(environment.terminalUid).pipe(take(1)).subscribe(terminal => {

      if (terminal) {
        this.activeSubject$.next(terminal);

      } else {
        this.activeSubject$.next(null);

        setTimeout(() => {
          this.getActive();
        }, 1000);
      }
    });
  }
}
