<div class="container" [formGroup]="form">
  <div style="margin-bottom: 10px;">
    <h2> <span class="grid-text" style="text-align: left;">Member Profile</span>
      Profile</h2>
  </div>
  <div>
    <div>
      <mat-form-field>
        <input matInput formControlName="firstName" placeholder="First Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="lastName" placeholder="Last Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="displayName" placeholder="Display Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput formControlName="email" placeholder="Email">
        <mat-error *ngIf="form.get('email').hasError('email')">Email is not valid</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div style="text-align: right;">
    <button mat-flat-button color="warn" style="margin-left: 1em;" (click)="cancel()">Cancel</button>
    <button mat-flat-button [disabled]="!this.form.dirty || !this.form.valid" color="accent" style="margin-left: 1em;" (click)="save()">Save</button>
  </div>

</div>
