import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  private excludeEndpoints = <string[]>[];

  constructor(
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      // map((event: HttpEvent<any>) => {
      //   // TODO: header information here
      //   return event;
      // }),
      catchError((error) => {
        // Catch and handle network exceptions here, application errors should be handled in components
        if (error instanceof HttpErrorResponse) {
          if (!this.excludeEndpoints.some(x => x == request.url.toLowerCase())) {
            console.log(error);

            return throwError(error);
          }
        }

        return throwError(error);
      })
    );
  }

  public addExcludeEndpoint(endpointUrl: string) {

    this.excludeEndpoints.push(endpointUrl.toLowerCase());
  }
}
