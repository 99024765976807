import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ProductService } from '../services/product.service';
import { CacheService } from 'core';

@Pipe({
  name: 'product'
})
export class ProductPipe implements PipeTransform {

  constructor(
    private cacheService: CacheService,
    private productService: ProductService
  ) {
  }

  transform(productUid: string): Observable<string> {

    if (productUid) {
      return this.cacheService.getOrAdd(
        productUid,
        () => this.productService.getByUid(productUid, null).pipe(shareReplay(1))
      ).pipe(
        map(product => {
          return product ? product.name : 'Unknown';
        })
      );
    }

    return null;
  }
}
