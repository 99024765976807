import { Component, Input, OnInit } from '@angular/core';
import { Cart } from '../../../models/cart';
import { CartService } from '../../../services/cart.service';
import { CheckoutContext } from '../check-out-context';

@Component({
  selector: 'store-core-checkout-identity',
  templateUrl: './checkout-identity.component.html',
  styleUrls: ['./checkout-identity.component.scss']
})
export class CheckoutIdentityComponent implements OnInit {

  @Input() public context: CheckoutContext;
  public cart: Cart;

  constructor(
    private cartService: CartService,
  ) {
  }

  ngOnInit() {
  }

}
