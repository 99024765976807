import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { AuthHeaderProvider } from 'core';
import { DateModalProvider } from 'core';
import { SpinnerModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { PayPeriodService } from 'pos-core';

@Component({
  selector: 'app-back-office-pay-period',
  templateUrl: './back-office-pay-period.component.html',
  styleUrls: ['./back-office-pay-period.component.scss']
})
export class BackOfficePayPeriodComponent implements OnInit {

  public title: string;
  public form: UntypedFormGroup;
  public canSave = new BehaviorSubject<boolean>(false);

  private payPeriodUid: string;

  constructor(
    private navigationProvider: NavigationProvider,
    private activatedRoute: ActivatedRoute,
    private spinnerModalProvider: SpinnerModalProvider,
    private dateModalProvider: DateModalProvider,
    private payPeriodService: PayPeriodService,
    private authHeaderProvider: AuthHeaderProvider,
    private datePipe: DatePipe
  ) {
    this.navigationProvider.setWaypoint('Pay Period');
    this.title = 'Pay Period';

    this.form = new UntypedFormGroup({
      startDateTimeUtc: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
      endDateTimeUtc: new UntypedFormControl({ value: '', disabled: true }, [Validators.required])
    });
  }

  ngOnInit() {

    this.navigationProvider.setWaypoint('Pay Period');

    this.activatedRoute.params.subscribe(params => {
      let payPeriodUid = params['payPeriodUid'];

      if (payPeriodUid) {
        let spinnerModalRef = this.spinnerModalProvider.open();
        spinnerModalRef.afterOpened().subscribe(() => {

          this.payPeriodService.getByUid(payPeriodUid, this.authHeaderProvider).subscribe(payPeriod => {
            this.payPeriodUid = payPeriod.uid;

            this.form.get('startDateTimeUtc').setValue(this.datePipe.transform(payPeriod.startDateTimeUtc, 'M-dd-yyyy'));
            this.form.get('endDateTimeUtc').setValue(this.datePipe.transform(payPeriod.endDateTimeUtc, 'M-dd-yyyy'));

            this.updateEnablement();

            spinnerModalRef.close();
          });
        });
      } else {
        // Auto calculate
        this.updateEnablement();
      }
    })
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }
  
  valid() {
    return this.form.get('startDateTimeUtc').value != '' && this.form.get('endDateTimeUtc').value != '';
  }

  editStartDate() {

    let dialogRef = this.dateModalProvider.open({
      initialDate: this.form.get('startDateTimeUtc').value ? new Date(this.form.get('startDateTimeUtc').value) : new Date(),
    });
    dialogRef.afterClosed().subscribe((value: Date) => {
      if (value) {
        this.form.get('startDateTimeUtc').setValue(this.datePipe.transform(value, 'M-dd-yyyy'));
        this.form.get('endDateTimeUtc').markAsDirty();

        this.updateEnablement();
      }
    });
  }

  editEndDate() {

    let dialogRef = this.dateModalProvider.open({
      initialDate: this.form.get('endDateTimeUtc').value ? new Date(this.form.get('endDateTimeUtc').value) : new Date(),
    });
    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.form.get('endDateTimeUtc').setValue(this.datePipe.transform(value, 'M-dd-yyyy'));
        this.form.get('endDateTimeUtc').markAsDirty();

        this.updateEnablement();
      }
    });
  }

  save() {

    if (this.form.dirty) {
      let startDateTime = new Date(this.form.get('startDateTimeUtc').value);
      var startDateTimeUtc = new Date(startDateTime.getFullYear(), startDateTime.getMonth(), startDateTime.getDate());

      let endDateTime = new Date(this.form.get('endDateTimeUtc').value);
      var endDateTimeUtc = new Date(endDateTime.getFullYear(), endDateTime.getMonth(), endDateTime.getDate());
      endDateTimeUtc = new Date(new Date(endDateTime).setMilliseconds(endDateTime.getMilliseconds() - 1));

      if (this.payPeriodUid) {
        this.payPeriodService.update(
          this.payPeriodUid,
          startDateTimeUtc,
          endDateTimeUtc,
          this.authHeaderProvider
        ).subscribe(_ => {
          this.navigationProvider.backOneWaypoint();
        });
      } else {
        this.payPeriodUid = UUID.UUID();

        this.payPeriodService.create(
          this.payPeriodUid,
          startDateTimeUtc,
          endDateTimeUtc,
          this.authHeaderProvider
        ).subscribe(_ => {
          this.navigationProvider.backOneWaypoint();
        });
      }
    } else {
      this.navigationProvider.backOneWaypoint();
    }
  }

  cancel() {
    this.navigationProvider.backOneWaypoint();
  }

  private updateEnablement() {

    var startDateTimeUtc = this.form.get('startDateTimeUtc').value;
    var endDateTimeUtc = this.form.get('endDateTimeUtc').value;

    this.canSave.next(startDateTimeUtc != null && endDateTimeUtc != null && startDateTimeUtc < endDateTimeUtc);
  }
}
