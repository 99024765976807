<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="dataSource.selection.isMultipleSelection()" (change)="$event ? dataSource.masterToggle() : null" [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row) : null" [checked]="dataSource.selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="number">
    <th mat-header-cell *matHeaderCellDef color="accent">Trans #</th>
    <td mat-cell *matCellDef="let transaction">
      <div>{{ transaction.number }}<span *ngIf="transaction.isAdjusted" style="color: red;">&nbsp;(ADJ)</span></div>
      <div *ngIf="transaction.notes">{{ transaction.notes }}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="openClose">
    <th mat-header-cell *matHeaderCellDef color="accent">Open/Close</th>
    <td mat-cell *matCellDef="let transaction">
      {{ transaction.openDateTimeUtc | date: 'M-dd-yyyy h:mm a' }}
      <span *ngIf="(transaction.openDateTimeUtc | date: 'h:mm a') !== (transaction.closeDateTimeUtc | date: 'h:mm a')">-<span>{{ transaction.closeDateTimeUtc | date: 'h:mm a' }}</span></span>
    </td>
  </ng-container>

  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef>Trans $</th>
    <td mat-cell *matCellDef="let transaction">{{ transaction.total | currency:'USD':'symbol':'1.2-2' }}</td>
  </ng-container>

  <ng-container matColumnDef="logisticType">
    <th mat-header-cell *matHeaderCellDef>Logistic</th>
    <td mat-cell *matCellDef="let transaction">{{ transaction.logisticTypeUid | logisticType }}</td>
  </ng-container>

  <ng-container matColumnDef="payments">
    <th mat-header-cell *matHeaderCellDef>Payments</th>
    <td mat-cell *matCellDef="let transaction">
      <div *ngIf="transaction.holdCardReference">{{ transaction.holdCardReference }}</div>
      <div *ngFor="let payment of transaction.payments">
        <div>{{ payment.amount | currency:'USD':'symbol':'1.2-2' }}&nbsp;{{ payment.paymentMethodUid | paymentMethod | async }}</div>
        <div>
          {{ payment.lastFour | lastFour }}&nbsp;{{ payment.tip | currency:'USD':'symbol':'1.2-2' }}
          <!-- &nbsp;<button mat-flat-button click-stop-propagation *ngIf="payment.canUpdateTip" (click)="updateTip(transaction, payment)">Tip</button> -->
        </div>
        <div>{{ payment.status | cardTransactionStatus }}</div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let transaction">
      <span>{{ transaction.transactionStatus }}</span>
      <span *ngIf="transaction.lockUid">&nbsp;(Locked &#64; {{ (transaction.lockUid | lockResolver | async )?.getDisplay() }})</span>
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let transaction">

      <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="transaction.canView" (click)="view.emit(transaction)">
        View
      </button>

      <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="transaction.canEdit" (click)="edit.emit(transaction)">
        Edit
      </button>

    </td>
  </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>
</table>
