// import { AlertsModalComponent } from './providers/modals/alerts-modal/alerts-modal.component';
// import { AlertsModalProvider } from './providers/modals/alerts-modal/alerts-modal.provider';
import { AmountFrequencyModalComponent } from "./amount-frequency-modal/amount-frequency-modal.component";
import { AmountFrequencyModalProvider } from "./amount-frequency-modal/amount-frequency-modal.provider";
import { ClockInOutModalComponent } from "./clock-in-out-modal/clock-in-out-modal.component";
import { ClockInOutModalProvider } from "./clock-in-out-modal/clock-in-out-modal.provider";
import { RegisterDecimalModalComponent } from "./register-decimal-modal/register-decimal-modal.component";
import { RegisterDecimalModalProvider } from "./register-decimal-modal/register-decimal-modal.provider";
import { LogisticTypeModalComponent } from "./logistic-type-modal/logistic-type-modal.component";
import { LogisticTypeModalProvider } from "./logistic-type-modal/logistic-type-modal.provider";
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { PaymentModalProvider } from './payment-modal/payment-modal.provider';
import { PrintDepartmentSlipModalComponent } from "./print-department-slip-modal/print-department-slip-modal.component";
import { PrintDepartmentSlipModalProvider } from "./print-department-slip-modal/print-department-slip-modal.provider";
import { SecurityPinModalComponent } from "./security-pin-modal/security-pin-modal.component";
import { SecurityPinModalProvider } from "./security-pin-modal/security-pin-modal.provider";
import { SelectGuestModalComponent } from "./select-guest-modal/select-guest-modal.component";
import { SelectGuestModalProvider } from "./select-guest-modal/select-guest-modal.provider";
import { SelectSystemMessageTypeModalComponent } from "./select-system-message-type-modal/select-system-message-type-modal.component";
import { SelectSystemMessageTypeModalProvider } from "./select-system-message-type-modal/select-system-message-type-modal.provider";
import { StoreIntegrationModalProvider } from './store-integration-modal/store-integration-modal.provider';
import { StoreIntegrationModalComponent } from './store-integration-modal/store-integration-modal.component';
import { TransactionItemPrintModalComponent } from "./transaction-item-print-modal/transaction-item-print-modal.component";
import { TransactionItemPrintModalProvider } from "./transaction-item-print-modal/transaction-item-print-modal.provider";

export const modalComponentTypes = [
    // AlertsModalComponent,
    AmountFrequencyModalComponent,
    ClockInOutModalComponent,
    LogisticTypeModalComponent,
    PaymentModalComponent,
    PrintDepartmentSlipModalComponent,
    RegisterDecimalModalComponent,
    SecurityPinModalComponent,
    StoreIntegrationModalComponent,
    SelectGuestModalComponent,
    SelectSystemMessageTypeModalComponent,
    TransactionItemPrintModalComponent,
];

export const modalProviderTypes = [
    // AlertsModalProvider,
    AmountFrequencyModalProvider,
    ClockInOutModalProvider,
    LogisticTypeModalProvider,
    PaymentModalProvider,
    PrintDepartmentSlipModalProvider,
    RegisterDecimalModalProvider,
    SecurityPinModalProvider,
    StoreIntegrationModalProvider,
    SelectGuestModalProvider,
    SelectSystemMessageTypeModalProvider,
    TransactionItemPrintModalProvider
];
