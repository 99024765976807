import { Injectable } from "@angular/core";

@Injectable()
export class LoggingProvider {

  protected static _logs: any[] = [];
  protected static _errors: any[] = [];
  protected static _warns: any[] = [];
  protected static _debugs: any[] = [];
  protected static _lastLogPublishIndex = 0;

  static {
    (console as any).defaultLog = console.log.bind(console);
    (console as any).log = function () {
      (console as any).defaultLog.apply(console, arguments);

      var logEntry = Array.from(arguments);
      LoggingProvider._logs.push(logEntry[0]);

      if (LoggingProvider._logs.length > 1000) {
        const increment = LoggingProvider._logs.length - 1000;

        LoggingProvider._logs.splice(increment);
        LoggingProvider._lastLogPublishIndex -= increment;
      }
    };

    (console as any).defaultError = console.error.bind(console);
    (console as any).error = function () {
      (console as any).defaultError.apply(console, arguments);
      LoggingProvider._errors.push(Array.from(arguments));
    };

    (console as any).defaultWarn = console.warn.bind(console);
    (console as any).warn = function () {
      (console as any).defaultWarn.apply(console, arguments);
      LoggingProvider._warns.push(Array.from(arguments));
    };

    (console as any).defaultDebug = console.debug.bind(console);
    (console as any).debug = function () {
      (console as any).defaultDebug.apply(console, arguments);
      LoggingProvider._debugs.push(Array.from(arguments));
    };
  };

  log(message: string) {

    console.log(message);
  }

  getLogs(): string {

    return LoggingProvider._logs.filter(x => !!x).map((x: any[]) => x.toString()).join(String.fromCharCode(13));
  }
}
