import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { EmployeeShift } from "../models/employee-shift";
import { OltpServiceSettings } from "../oltp-service-settings";
import { HttpService } from "core";
import { PaginationInput } from "core";
import { Page } from "core";
import { PosModelFactory } from "../pos-model-factory";

@Injectable()
export class EmployeeShiftService {

  public static readonly EmployeeShiftIndexView = <EmployeeShiftViewOptions>{};
  public static readonly EmployeeShiftFullView = <EmployeeShiftViewOptions>{ includeEmployee: true, includePosition: true, includeAnalytics: { includeTransactions: true, includeDepartments: true } };

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: EmployeeShiftViewOptions = null, identityToken: string = null): Observable<EmployeeShift> {

    let view = EmployeeShiftService.buildView(viewOptions || EmployeeShiftService.EmployeeShiftFullView);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<EmployeeShift>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeeShift', request, 'getByUid', identityToken).pipe(
      map(x => PosModelFactory.assignEmployeeShift(x))
    );
  }

  getOpen(employeeUid: string, viewOptions: EmployeeShiftViewOptions = null, identityToken: string = null): Observable<EmployeeShift> {

    let view = EmployeeShiftService.buildView(viewOptions || EmployeeShiftService.EmployeeShiftFullView);

    var request = {
      query: `query { getOpen(employeeUid: "${employeeUid}") ${view} }`
    };

    return this.httpService.graph<EmployeeShift>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeeshift', request, 'getOpen', identityToken).pipe(
      map(x => PosModelFactory.assignEmployeeShift(x))
    );
  }

  search(employeeUids: string[], positionUids: string[], paymentMethodUids: string[], payPeriodUids: string[], daysOfWeek: number[], statusUids: string[], paginationInput: PaginationInput, viewOptions: EmployeeShiftViewOptions = null, identityToken: string = null): Observable<Page<EmployeeShift>> {

    let view = EmployeeShiftService.buildView(viewOptions || EmployeeShiftService.EmployeeShiftFullView);

    var request = {
      query: `query employeeShiftSearch($pagination:PaginationInput) { search(employeeUids: ${JSON.stringify(employeeUids)}, positionUids: ${JSON.stringify(positionUids)}, paymentMethodUids: ${JSON.stringify(paymentMethodUids)}, payPeriodUids: ${JSON.stringify(payPeriodUids)}, statusUids: ${JSON.stringify(statusUids)}, daysOfWeek: ${JSON.stringify(daysOfWeek)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<EmployeeShift>>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeeshift', request, 'search', identityToken).pipe(
      tap(x => x.edges.forEach(edge => edge.node = PosModelFactory.assignEmployeeShift(edge.node)))
    );
  }

  submit(employeeShiftUids: string[], viewOptions: EmployeeShiftViewOptions = null, identityToken: string = null): Observable<EmployeeShift[]> {

    let view = EmployeeShiftService.buildView(viewOptions || EmployeeShiftService.EmployeeShiftFullView);

    var request = {
      query: `mutation submitEmployeeShift { submit(employeeShiftUids: ${JSON.stringify(employeeShiftUids)}) ${view} }`
    }

    return this.httpService.graph<EmployeeShift[]>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeeshift', request, 'submit', identityToken).pipe(
      map(x => x.map(y => PosModelFactory.assignEmployeeShift(y)))
    );
  }

  accept(employeeShiftUids: string[], viewOptions: EmployeeShiftViewOptions = null, identityToken: string = null): Observable<EmployeeShift[]> {

    let view = EmployeeShiftService.buildView(viewOptions || EmployeeShiftService.EmployeeShiftFullView);

    var request = {
      query: `mutation acceptEmployeeShift { accept(employeeShiftUids: ${JSON.stringify(employeeShiftUids)}) ${view} }`
    }

    return this.httpService.graph<EmployeeShift[]>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeeshift', request, 'accept', identityToken).pipe(
      map(x => x.map(y => PosModelFactory.assignEmployeeShift(y)))
    );
  }

  reject(employeeShiftUids: string[], viewOptions: EmployeeShiftViewOptions = null, identityToken: string = null): Observable<EmployeeShift[]> {

    let view = EmployeeShiftService.buildView(viewOptions || EmployeeShiftService.EmployeeShiftFullView);

    var request = {
      query: `mutation rejectEmployeeShift { reject(employeeShiftUids: ${JSON.stringify(employeeShiftUids)}) ${view} }`
    }

    return this.httpService.graph<EmployeeShift[]>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeeshift', request, 'reject', identityToken).pipe(
      map(x => x.map(y => PosModelFactory.assignEmployeeShift(y)))
    );
  }

  delete(employeeShiftUids: string[], viewOptions: EmployeeShiftViewOptions = null, identityToken: string = null): Observable<EmployeeShift[]> {

    let view = EmployeeShiftService.buildView(viewOptions || EmployeeShiftService.EmployeeShiftFullView);

    var request = {
      query: `mutation delete { delete(employeeShiftUids: ${JSON.stringify(employeeShiftUids)}) ${view} }`
    }

    return this.httpService.graph<EmployeeShift[]>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeeshift', request, 'delete', identityToken).pipe(
      map(x => x.map(y => PosModelFactory.assignEmployeeShift(y)))
    );
  }

  updatePayPeriod(employeeShiftUids: string[], payPeriodUid: string, viewOptions: EmployeeShiftViewOptions = null, identityToken: string = null): Observable<EmployeeShift[]> {

    let view = EmployeeShiftService.buildView(viewOptions || EmployeeShiftService.EmployeeShiftFullView);

    var request = {
      query: `mutation updatePayPeriod { updatePayPeriod(employeeShiftUids: ${JSON.stringify(employeeShiftUids)}${payPeriodUid == null ? '' : `, payPeriodUid: "${payPeriodUid}"`}) ${view} }`
    }

    return this.httpService.graph<EmployeeShift[]>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeeshift', request, 'updatePayPeriod', identityToken).pipe(
      map(x => x.map(y => PosModelFactory.assignEmployeeShift(y)))
    );
  }

  clockIn(uid: string, employeeUid: string, positionUid: string, clockInDateTime: Date, viewOptions: EmployeeShiftViewOptions = null, identityToken: string = null): Observable<EmployeeShift> {

    let view = EmployeeShiftService.buildView(viewOptions || EmployeeShiftService.EmployeeShiftFullView);

    var clockInDateTimeUtc = clockInDateTime.toISOString();

    var request = {
      query: `mutation clockIn { clockIn(employeeShiftUid: "${uid}", employeeUid: "${employeeUid}", positionUid: "${positionUid}", clockInDateTimeUtc: "${clockInDateTimeUtc}") ${view} }`
    };

    return this.httpService.graph<EmployeeShift>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeeshift', request, 'clockIn', identityToken).pipe(
      map(x => PosModelFactory.assignEmployeeShift(x))
    );
  }

  clockOut(uid: string, clockOutDateTime: Date, viewOptions: EmployeeShiftViewOptions = null, identityToken: string = null): Observable<EmployeeShift> {

    let view = EmployeeShiftService.buildView(viewOptions || EmployeeShiftService.EmployeeShiftFullView);

    var request = {
      query: `mutation clockOut { clockOut(employeeShiftUid: "${uid}", clockOutDateTimeUtc: "${clockOutDateTime.toISOString()}") ${view} }`
    };

    return this.httpService.graph<EmployeeShift>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeeshift', request, 'clockOut', identityToken).pipe(
      map(x => PosModelFactory.assignEmployeeShift(x))
    );
  }

  editEmployeeShift(uid: string, employeeUid: string, positionUid: string, clockInDateTime: Date, clockOutDateTime: Date, viewOptions: EmployeeShiftViewOptions = null, identityToken: string = null): Observable<EmployeeShift> {

    let view = EmployeeShiftService.buildView(viewOptions || EmployeeShiftService.EmployeeShiftFullView);

    var employeeShiftInput = {
      uid: uid,
      employeeUid: employeeUid,
      positionUid: positionUid,
      clockInDateTimeUtc: clockInDateTime.toISOString(),
      clockOutDateTimeUtc: clockOutDateTime?.toISOString()
    };

    var request = {
      query: `mutation editEmployeeShift($employeeShift:EmployeeShiftInput!) { edit(employeeShift: $employeeShift) ${view} }`,
      variables: { employeeShift: employeeShiftInput }
    };

    return this.httpService.graph<EmployeeShift>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeeshift', request, 'edit', identityToken).pipe(
      map(x => PosModelFactory.assignEmployeeShift(x))
    );
  }

  public static buildView(viewOptions: EmployeeShiftViewOptions) {

    let view = `uid employeeUid positionUid clockInDateTimeUtc clockOutDateTimeUtc employeePayUid payPeriodUid dayOfWeek employeeShiftStatusUid`;

    if (viewOptions.includeEmployee) {
      view += ` employee { firstName lastName }`;
    }

    if (viewOptions.includePosition) {
      view += ` position { name }`;
    }

    if (viewOptions.includeAnalytics) {
      view += ` conversationCount`;

      if (viewOptions.includeAnalytics.includeTransactions) {
        view += ` transactionsCount transactionsValue`;
      }
      if (viewOptions.includeAnalytics.includeDepartments) {
        view += ` departmentCounts { uid count }`;
      }
    }

    return `{ ${view} }`;
  }
}

export interface EmployeeShiftViewOptions {

  includeEmployee?: boolean;
  includePosition?: boolean;
  includeAnalytics?: EmployeeShiftAnalyticsViewOptions;
}

export interface EmployeeShiftAnalyticsViewOptions {

  includeTransactions: boolean;
  includeDepartments: boolean;
}
