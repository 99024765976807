import { Component, Input, EventEmitter, Output } from '@angular/core';
import { slideInAnimation, slideOutAnimation } from 'core';
import { EditableTransaction, EditableTransactionPayment } from '../../../models/editable-transaction/editable-transaction';

@Component({
  selector: 'transaction-transaction-editor-payment',
  templateUrl: './transaction-editor-payment.component.html',
  styleUrls: ['./transaction-editor-payment.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class TransactionEditorPaymentComponent  {

  @Input() editableTransaction: EditableTransaction
  @Input() payment: EditableTransactionPayment;
  @Output() select = new EventEmitter<EditableTransactionPayment>();
  @Output() void = new EventEmitter<EditableTransactionPayment>();
  @Output() changeType = new EventEmitter<EditableTransactionPayment>();
  @Output() cancel = new EventEmitter<EditableTransactionPayment>();
}
