<div style="display: flex; flex-direction: column;">
  <div>
    <app-pos-button (click)="buttonPressed('edeb7b33-529e-414a-9e22-0bf38a40ec75', 'F7D5EF97-BC99-4A75-8FEF-472FB4228D60')" [styles]="{ backgroundColor: '#1f471f' }">Can</app-pos-button>
    <app-pos-button (click)="buttonPressed('edeb7b33-529e-414a-9e22-0bf38a40ec75', '348FC71C-FA29-4359-8E94-2E21608A1D06')" [styles]="{ backgroundColor: '#1f471f' }">Rail</app-pos-button>
    <app-pos-button (click)="buttonPressed('edeb7b33-529e-414a-9e22-0bf38a40ec75', '71B73E76-B8C0-4AFD-B9F0-8B3AD5AEF5F8')" [styles]="{ backgroundColor: '#1f471f' }">Soda</app-pos-button>
  </div>
  <div>
    <app-pos-button (click)="buttonPressed('edeb7b33-529e-414a-9e22-0bf38a40ec75', '1198E1A7-5860-4B48-962D-79E2D4D34279')" [styles]="{ backgroundColor: '#1f471f' }">Tap</app-pos-button>
    <app-pos-button (click)="buttonPressed('edeb7b33-529e-414a-9e22-0bf38a40ec75', '9E46BAC8-3805-4BBF-87BC-749DAC1C7F8B')" [styles]="{ backgroundColor: '#1f471f' }">Call</app-pos-button>
    <app-pos-button (click)="buttonPressed('6F6BD33F-8C14-42FB-B437-1BE40F629041', null)" [styles]="{ backgroundColor: '#1f471f' }">Misc</app-pos-button>
  </div>
  <div>
    <app-pos-button (click)="buttonPressed('edeb7b33-529e-414a-9e22-0bf38a40ec75', '10F36754-0AB1-4822-BDFA-7A244F324CA4')" [styles]="{ backgroundColor: '#1f471f' }">Bottle</app-pos-button>
    <app-pos-button (click)="buttonPressed('edeb7b33-529e-414a-9e22-0bf38a40ec75', '59213568-B78F-4105-8664-B3E782BFC828')" [styles]="{ backgroundColor: '#1f471f' }">Top</app-pos-button>
    <app-pos-button (click)="buttonPressed('46C1731B-DBEA-4C6E-B822-F56B80A58254', null)" [styles]="{ backgroundColor: '#1f471f' }">Kitchen</app-pos-button>
  </div>
  <div>
    <app-pos-button (click)="buttonPressed('edeb7b33-529e-414a-9e22-0bf38a40ec75', '50966256-EAF3-471A-94D1-6AB8C770FB28')" [styles]="{ backgroundColor: '#1f471f' }">Bloody</app-pos-button>
    <app-pos-button (click)="buttonPressed('edeb7b33-529e-414a-9e22-0bf38a40ec75', 'F8EA1545-4EA3-44D7-B305-D2B4907E19AE')" [styles]="{ backgroundColor: '#1f471f' }">Shot</app-pos-button>
    <app-pos-button (click)="buttonPressed('DC9D3B58-E05C-4E45-A88F-A5B87075F970', null)" [styles]="{ backgroundColor: '#1f471f' }">Merchandise</app-pos-button>
  </div>
  <div>
    <app-pos-button [styles]="{ backgroundColor: '#1f471f' }"></app-pos-button>
    <app-pos-button [styles]="{ backgroundColor: '#1f471f' }"></app-pos-button>
    <app-pos-button [styles]="{ backgroundColor: '#1f471f' }"></app-pos-button>
  </div>
</div>