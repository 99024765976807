import { Setting } from '../models/setting';
export class ModelFactory {

  public static assign<T>(instance: T, type: { new(): T; }): T {
    return instance ? Object.assign(new type(), instance) : null;
  }

  public static assignSetting(input: Setting): Setting {

    if (input) {
      return ModelFactory.assign(input, Setting);
    }

    return null;
  }
}
