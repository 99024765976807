import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DecimalModalComponent } from "./decimal-modal.component";

@Injectable()
export abstract class DecimalModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public abstract open(data: IDecimalModalData): MatDialogRef<DecimalModalComponent, number>;
}

export interface IDecimalModalData {
  title: string,
  initialValue: number
}
