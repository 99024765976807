import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatSelectionList } from '@angular/material/list';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { SelectListModalProvider, getDescendentRouteSnapshot } from 'core';
import { NavigationProvider } from 'core';
import { ProductAndPortionModalProvider } from '../../../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { ConfigurationComponentBase } from '../../configuration-component-base';
import { buildProductInclusionGroupForm } from '../../../../functions/configuration-form-functions';
import { ProductContext } from '../../product-context';
import { TenantProvider } from 'core';
import { ProductProvider } from '../../../../providers';

@Component({
  selector: 'app-back-office-product-configuration-inclusion-groups',
  templateUrl: './back-office-product-configuration-inclusion-groups.component.html',
  styleUrls: ['./back-office-product-configuration-inclusion-groups.component.scss']
})
export class BackOfficeProductConfigurationInclusionGroupsComponent extends ConfigurationComponentBase {

  @ViewChild('inclusionGroupsList', { static: true }) inclusionGroupsList: MatSelectionList;
  public form: UntypedFormGroup;
  public scope: string;
  public canLinkProductInclusionGroups: boolean;

  private _context: ProductContext;
  private destroyed$ = new Subject();

  constructor(
    private selectListModalProvider: SelectListModalProvider,
    private navigationProvider: NavigationProvider,
    activatedRoute: ActivatedRoute,
    tenantProvider: TenantProvider,
    productProvider: ProductProvider,
    productAndPortionModalProvider: ProductAndPortionModalProvider,
  ) {
    super(activatedRoute, tenantProvider, productProvider, productAndPortionModalProvider);

    this.scope = this.getScope(this.activatedRoute);
  }

  ngOnInit() {

    setTimeout(() => {
      this.setNavTarget();
    });
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  onActivate(event: ConfigurationComponentBase) {

    event.context = this.context;
  }

  @Input()
  public set context(context: ProductContext) {

    this._context = context;

    var route = getDescendentRouteSnapshot(this.activatedRoute);
    var portionUid = route.params['portionUid'];

    let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
    if (portionUid) {
      let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
      if (portionsGroupFormArray) {
        configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid.toLowerCase());
      }
    }

    this.form = configurationFormGroup;
    this.canLinkProductInclusionGroups = this.scope == 'product' ? false : super.getLinkableProductInclusionGroupFormsForPortionForm(this.form).length > 0;
  }

  setNavTarget() {

    var route = getDescendentRouteSnapshot(this.activatedRoute);
    var portionUid = route.params['portionUid'];

    let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
    if (portionUid) {
      let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
      if (portionsGroupFormArray) {
        configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid.toLowerCase());
      }
    }

    var inclusionGroupUid = route.params['inclusionGroupUid'];
    if (inclusionGroupUid) {
      let inclusionGroupsFormArray = <UntypedFormArray>configurationFormGroup.get('inclusionGroups');

      if (inclusionGroupsFormArray) {
        configurationFormGroup = <UntypedFormGroup>inclusionGroupsFormArray.controls.find(x => x.get('uid').value.toLowerCase() == inclusionGroupUid.toLowerCase());

        this.inclusionGroupsList.selectedOptions.select(this.inclusionGroupsList.options.find(x => x.value === configurationFormGroup));
      }
    }
  }

  public get context(): ProductContext {

    return this._context;
  }

  public newInclusionGroup() {

    var route = getDescendentRouteSnapshot(this.activatedRoute);
    var portionUid = route.params['portionUid'];

    let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
    if (portionUid) {
      let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
      if (portionsGroupFormArray) {
        configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid);
      }
    }

    var inclusionGroupsFormArray = <UntypedFormArray>configurationFormGroup.get('inclusionGroups');

    const formGroup = buildProductInclusionGroupForm(null);
    inclusionGroupsFormArray.push(formGroup);
    inclusionGroupsFormArray.markAsDirty();

    this.editInclusionGroup(formGroup);
    formGroup.get('name').setValue('New Inclusion Group');
  }

  public editInclusionGroup(formGroup: UntypedFormGroup) {

    var segments = this.getBasePortionNavigationSegments().concat(['inclusiongroup', formGroup.get('uid').value]);

    this.navigationProvider.navigate(segments);
  }

  public linkInclusionGroup() {

    const options = super.getLinkableProductInclusionGroupFormsForPortionForm(this.form).map(x => {
      const name = x.get('name').value;

      if (name) {
        return <ProductInclusionGroupSelection>{ display: name, inclusionGroupFormGroup: x };
      } else {
        return <ProductInclusionGroupSelection>{ display: 'Unknown', inclusionGroupFormGroup: x };
      }
    });

    this.selectListModalProvider.open({
      title: 'Select Product Inclusion',
      options: options,
      displayFunc: item => item.display
    }).afterClosed().subscribe(result => {
      if (result) {
        var route = getDescendentRouteSnapshot(this.activatedRoute);
        var portionUid = route.params['portionUid'];

        let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
        if (portionUid) {
          let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
          if (portionsGroupFormArray) {
            configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('id').value.toLowerCase() == portionUid);
          }
        }

        var inclusionGroupsFormArray = <UntypedFormArray>configurationFormGroup.get('inclusionGroups');

        const formGroup = buildProductInclusionGroupForm(null);
        formGroup.get('uid').setValue((<ProductInclusionGroupSelection>result).inclusionGroupFormGroup.get('uid').value);

        inclusionGroupsFormArray.push(formGroup);
        inclusionGroupsFormArray.markAsDirty();

        this.editInclusionGroup(formGroup);
      }
    });
  }

  public dropInclusionGroup(event: CdkDragDrop<string[]>) {

    if (event.previousIndex != event.currentIndex) {
      var inclusionGroups = <UntypedFormArray>this.form.get('inclusionGroups');
      var item = inclusionGroups.controls[event.previousIndex];
      inclusionGroups.removeAt(event.previousIndex);

      inclusionGroups.insert(event.currentIndex, item);
    }
  }

  public deleteInclusionGroup(form: UntypedFormGroup) {

    var inclusionGroups = <UntypedFormArray>this.form.get('inclusionGroups');
    inclusionGroups.removeAt(inclusionGroups.controls.indexOf(form));
  }
}

class ProductInclusionGroupSelection {

  display: string;
  inclusionGroupFormGroup: UntypedFormGroup;
}
