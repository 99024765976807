import { Injectable } from '@angular/core';
import { Drawer } from '../models/drawer';
import { DrawerService } from '../services/drawer.service';
import { BehaviorSubject, concatMap, filter, first, Observable, of, shareReplay, Subject, takeUntil, tap } from "rxjs";
import { EntityEventProvider, isCaseInsensitiveEqual, isEqualUUID } from 'core';
import { PosModelFactory } from '../pos-model-factory';
import { DrawerStatusKeys } from '../keys';
import { Terminal, TerminalProvider } from 'downmain-terminal';

@Injectable()
export class DrawerProvider {

  public activeDrawer$: Observable<Drawer>;

  private activeDrawerSubject = new BehaviorSubject<Drawer>(null);
  private destroyed$ = new Subject<void>();

  constructor(
    private terminalProvider: TerminalProvider,
    private entityEventProvider: EntityEventProvider,
    private drawerService: DrawerService,
  ) {
    this.activeDrawer$ = this.activeDrawerSubject.asObservable().pipe(shareReplay(1));

    this.terminalProvider.active$.pipe(
      takeUntil(this.destroyed$),
      filter(terminal => !!terminal),
      concatMap(terminal => this.getOpenDrawer(terminal)),
      first()
    ).subscribe();

    this.entityEventProvider.event$.pipe(
      takeUntil(this.destroyed$),
      filter(event => isCaseInsensitiveEqual(event.type, 'DrawerContext')),
      concatMap(event => {
        if (this.activeDrawerSubject.value) {
          // have active drawer
          if (isEqualUUID(event.uid, this.activeDrawerSubject.value.uid)) {
            // active drawer was evented, check if ended
            return drawerService.getByUid(event.uid).pipe(
              tap(drawer => {
                if (isEqualUUID(drawer.drawerStatusUid, DrawerStatusKeys.Ended) || isEqualUUID(drawer.drawerStatusUid, DrawerStatusKeys.Deleted)) {
                  this.activeDrawerSubject.next(null);
                } else {
                  PosModelFactory.mergeDrawer(drawer, this.activeDrawerSubject.value);
                }
              })
            );
          }
        } else {
          // no active drawer
          return this.getOpenDrawer(this.terminalProvider.active);
        }

        return of(null);
      })
    ).subscribe();
  }

  public ngOnDestroy(): void {

    this.destroyed$.next();
  }

  public get activeDrawer(): Drawer {

    return this.activeDrawerSubject.value;
  }

  public getOne$(uid: string): Observable<Drawer> {

    return this.drawerService.getByUid(uid).pipe(first())
  }

  private getOpenDrawer(terminal: Terminal): Observable<Drawer> {

    return this.drawerService.getOpen(terminal.uid).pipe(
      tap(drawer => this.activeDrawerSubject.next(drawer))
    );
  }
}
