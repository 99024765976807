import { Category } from "./category";

export class Department {

  uid: string;
  ownerUid: string;
  name: string;
  accountSettingUid: string;
  isSalesTaxIncluded: boolean;
  taxAccountSettingUid: string;
  departmentStatusUid: string;

  categories: Category[];
}
