import { Component } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { DeviceHubProvider } from '../../providers/device-hub.provider';
import { DeviceInfo } from '../../models/device-info';
import { isCaseInsensitiveEqual, MessageModalProvider, SettingBaseComponent, SystemMessageProvider, TextEntryStyleEnum } from 'core';
import { UUID } from 'angular2-uuid';
import { TerminalDeviceModalProvider, TerminalDeviceModalData } from '../../modals/terminal-device-modal/terminal-device-modal.provider';
import { DocumentProvider } from '../../providers/document.provider';
import { PrintDocumentNetworkPayload } from '../../models/print-document-network-payload';
import { DeviceSetting, DeviceSettings, DeviceTypeEnum } from '../../models';

interface DeviceSettingsFormGroupType {
  textEntryStyle: FormControl<string>;
  settings: FormArray<FormGroup<DeviceSettingFormGroupType>>;
};

interface DeviceSettingFormGroupType {

  uid: FormControl<string>;
  enabled: FormControl<boolean>;
  name: FormControl<string>;
  deviceType: FormControl<string>;
  platform: FormControl<string>;
  metadata: FormArray<FormGroup<MetadataFormGroupType>>;
};

interface MetadataFormGroupType {

  key: FormControl<string>;
  value: FormControl<string>;
}

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.scss']
})
export class DeviceSettingsComponent extends SettingBaseComponent<DeviceSettings, DeviceSettingsFormGroupType> {

  public textEntryStyles = [TextEntryStyleEnum.SoftKeyboard, TextEntryStyleEnum.Textbox];
  public deviceInfos: DeviceInfo[];
  public deviceTypes = [DeviceTypeEnum.CashDrawer, DeviceTypeEnum.Msr, DeviceTypeEnum.ReceiptPrinter];

  public availableDevices: DeviceSetting[];
  public selectedDeviceSettingFormGroup: FormGroup<DeviceSettingFormGroupType>;

  constructor(
    private deviceHubProvider: DeviceHubProvider,
    private documentProvider: DocumentProvider,
    private terminalDeviceModalProvider: TerminalDeviceModalProvider,
    private messageModalProvider: MessageModalProvider,
    private systemMessageProvider: SystemMessageProvider
  ) {
    super();

    this.form = new FormGroup({
      textEntryStyle: new FormControl<string>(null),
      settings: new FormArray([])
    });
  }

  ngOnInit() {

    this.deviceHubProvider.hubDeviceInfos$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(hubDeviceInfos => {
      this.deviceInfos = hubDeviceInfos;
    });
  }

  public get devices() {

    if (!this.availableDevices) {
      this.availableDevices = <DeviceSetting[]>this.form.controls.settings.getRawValue();
    }
    return this.availableDevices;
  }

  public override patchForm(value: DeviceSettings): void {

    var settings = value.settings;
    if (settings.length) {
      settings.forEach(setting => {
        const deviceFormGroup = this.createDeviceSettingFormGroup();

        setting.metadata.forEach(_ => {
          const metadataFormGroup = this.createMetadataFormGroup();
          deviceFormGroup.controls.metadata.push(metadataFormGroup);
        });

        this.form.controls.settings.push(deviceFormGroup);
      });
    }

    this.form.patchValue(value);
    this.availableDevices = null;
  }

  public addDevice() {

    this.terminalDeviceModalProvider.open(
      <TerminalDeviceModalData>{
        title: 'Select or define device',
        oposOptions: this.deviceInfos,
        netowrkOptions: [
          {
            name: 'Receipt Printer',
            type: 'ReceiptPrinter',
            platform: 'Network'
          }
        ],
        allowMultiple: false,
        displayFunc: (item) => item.name
      }
    ).afterClosed().subscribe(deviceInfo => {
      if (deviceInfo) {
        var deviceSettingFormGroup = this.createDeviceSettingFormGroup();
        deviceSettingFormGroup.controls.uid.setValue(UUID.UUID());
        deviceSettingFormGroup.controls.enabled.setValue(true);
        deviceSettingFormGroup.controls.name.setValue(deviceInfo.name);
        deviceSettingFormGroup.controls.deviceType.setValue(deviceInfo.type);
        deviceSettingFormGroup.controls.platform.setValue(deviceInfo.platform);

        if (isCaseInsensitiveEqual(deviceInfo.platform, 'OPOS')) {
          deviceSettingFormGroup.controls.metadata.push(new FormGroup<MetadataFormGroupType>({
            key: new FormControl<string>('deviceName'),
            value: new FormControl<string>(deviceInfo.name)
          }));
        } else if (isCaseInsensitiveEqual(deviceInfo.platform, 'Network')) {
          deviceSettingFormGroup.controls.metadata.push(new FormGroup<MetadataFormGroupType>({
            key: new FormControl<string>('ipAddress'),
            value: new FormControl<string>('192.168.1.101')
          }));
          deviceSettingFormGroup.controls.metadata.push(new FormGroup<MetadataFormGroupType>({
            key: new FormControl<string>('port'),
            value: new FormControl<string>('9002')
          }));
        }

        this.form.controls.settings.push(deviceSettingFormGroup);
        this.availableDevices = null;

        this.deviceSettingPanelClick(deviceSettingFormGroup);
      }
    });
  }

  public deleteDevice(deviceInfoFormGroup: FormGroup<DeviceSettingFormGroupType>) {

    this.form.controls.settings.removeAt(this.form.controls.settings.value.findIndex(x => x == deviceInfoFormGroup.value));

    if (this.selectedDeviceSettingFormGroup == deviceInfoFormGroup) {
      this.selectedDeviceSettingFormGroup = null;
    }

    this.availableDevices = null;
  }

  public deviceSettingPanelClick(deviceSettingFormGroup: FormGroup<DeviceSettingFormGroupType>) {

    this.selectedDeviceSettingFormGroup = deviceSettingFormGroup;
  }

  public getMetadataFormGroup(formGroup: DeviceSettingFormGroupType, key: string): FormGroup<MetadataFormGroupType> {

    return formGroup.metadata.controls.find(x => x.controls.key.value == key);
  }

  public printTest(deviceInfoFormGroup: FormGroup<DeviceSettingFormGroupType>) {

    const ipAddressFormGroup = deviceInfoFormGroup.controls.metadata.controls.find(x => isCaseInsensitiveEqual(x.controls.key.value, 'ipAddress'));
    const ipAddressFormControl = ipAddressFormGroup.controls.value

    const portFormGroup = deviceInfoFormGroup.controls.metadata.controls.find(x => isCaseInsensitiveEqual(x.controls.key.value, 'port'));
    const portFormControl = portFormGroup.controls.value

    const payload = <PrintDocumentNetworkPayload>{
      networkAddress: `${ipAddressFormControl.value}:${portFormControl.value}`,
      document: this.documentProvider.buildTestPrintDocument()
    }

    var systemMessage = this.systemMessageProvider.buildMessage(UUID.UUID(), 'print-document-network', payload, null, null, deviceInfoFormGroup.controls.uid.value);

    this.systemMessageProvider.publishOneWithResponse$<boolean>(systemMessage).subscribe(success => {
      if (success) {
        this.messageModalProvider.open({ message: 'Successfully sent to the printer at this address.' });
      } else {
        this.messageModalProvider.open({ message: `There was a problem sending and/or getting a response from the selected printer at this address.` });
      }
    });
  }

  private createDeviceSettingFormGroup(): FormGroup<DeviceSettingFormGroupType> {

    return new FormGroup<DeviceSettingFormGroupType>({
      uid: new FormControl<string>({ value: null, disabled: true }),
      enabled: new FormControl<boolean>(true),
      name: new FormControl<string>(null),
      deviceType: new FormControl<string>({ value: null, disabled: true }),
      platform: new FormControl<string>({ value: null, disabled: true }),
      metadata: new FormArray<FormGroup<MetadataFormGroupType>>([])
    });
  }

  private createMetadataFormGroup(): FormGroup<MetadataFormGroupType> {

    return new FormGroup<MetadataFormGroupType>({
      key: new FormControl<string>(null),
      value: new FormControl<string>(null)
    });
  }
}
