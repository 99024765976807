import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Observable } from 'rxjs';
import { ItemConfiguratorContext } from "../../item-configurator-context";
import { SettingProvider, TenantProvider, slideInAnimation, slideOutAnimation } from "core";
import { EditableTransactionItemConfigurationInclusionGroupOption } from "../../../../models/editable-transaction/editable-transaction";
import { ProductConfigurationInclusionGroupOption, ProductConfigurationPreparation, ProductConfigurationVariation } from "downtown-product";
import { TransactionSettings } from "../../../../models";

@Component({
  selector: 'transaction-item-configurator-detail-inclusion-group-option',
  templateUrl: './item-configurator-detail-inclusion-group-option.component.html',
  styleUrls: ['./item-configurator-detail-inclusion-group-option.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfiguratorDetailInclusionGroupOptionComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public productInclusionGroupOption: ProductConfigurationInclusionGroupOption;
  @Input() public itemInclusionGroupOption: EditableTransactionItemConfigurationInclusionGroupOption;
  @Output() public configurationChanged = new EventEmitter();

  public transactionSettings$: Observable<TransactionSettings>;
  public display: Observable<string>;
  public productPreparations: ProductConfigurationPreparation[];
  public productVariations: ProductConfigurationVariation[];

  constructor(
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider,
  ) {
    this.transactionSettings$ = this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid);
  }

  ngOnInit() {

    this.display = this.context.getItemInclusionGroupOptionDisplay(this.itemInclusionGroupOption);
    this.productPreparations = this.context.getProductPreparations(this.productInclusionGroupOption);
    this.productVariations = this.context.getProductVariations(this.productInclusionGroupOption);
  }
}
