import { Component, ContentChildren, QueryList, SimpleChanges } from '@angular/core';
import { ExpansionPanelComponent } from '../../components/expansion-panel/expansion-panel.component';

@Component({
  selector: 'app-expansion-container',
  templateUrl: './expansion-container.component.html',
  styleUrls: ['./expansion-container.component.scss']
})
export class ExpansionContainerComponent {

  @ContentChildren(ExpansionPanelComponent) childComponents: QueryList<ExpansionPanelComponent>;

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {


  }

  ngAfterViewInit() {

    if (this.childComponents) {
      this.childComponents.forEach((childComponent: ExpansionPanelComponent) => {
      });
    }
  }

  public get panels(): ExpansionPanelComponent[] {

    return this.childComponents.map(x => x);
  }

  public setExpanded(panel: ExpansionPanelComponent) {

    this.childComponents.filter(x => x != panel).forEach(panel => panel.expanded = false);

    if (panel) {
      panel.expanded = true;
    }
  }

  public setSelected(panel: ExpansionPanelComponent) {

    this.childComponents.filter(x => x != panel).forEach(panel => panel.selected = false);

    if (panel) {
      panel.selected = true;
    }
  }
}

