import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DateModalComponent } from './date-modal.component';

@Injectable()
export class DateModalProvider {

  private readonly isDesktop: boolean;

  constructor(
    protected dialog: MatDialog,
    protected deviceDetectorService: DeviceDetectorService
  ) {
    this.isDesktop = deviceDetectorService.isDesktop();
  }

  public open(data: DateModalData): MatDialogRef<DateModalComponent, Date> {

    if (this.isDesktop) {
      return this.dialog.open(DateModalComponent, {
        width: '50%',
        height: '100%',
        minWidth: '50%',
        minHeight: '100%',
        hasBackdrop: true,
        disableClose: true,
        closeOnNavigation: true,
        data: data
      });
    } else {
      return this.dialog.open(DateModalComponent, {
        width: '100%',
        height: '100%',
        minWidth: '100%',
        minHeight: '100%',
        hasBackdrop: true,
        disableClose: true,
        closeOnNavigation: true,
        data: data
      });
    }
  }
}

export interface DateModalData {
  initialDate: Date;
}
