export class IdentityUser {

  id: string;
  username: string;
  email: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  twoFactorEnabled: boolean;

  claims: Claim[];
}

export class Claim {

  type: string;
  value: string;
}
