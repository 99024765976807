import { Component } from '@angular/core';

@Component({
  selector: 'downtown-dam-explorer',
  templateUrl: './dam-explorer.component.html',
  styleUrls: ['./dam-explorer.component.scss']
})
export class DamExplorerComponent {

  constructor(
  ) {

  
    
  }

}
