import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { distinctUntilChanged, filter, shareReplay } from "rxjs/operators";
import { Terminal } from "../models/terminal";
import { TerminalService } from "../services/terminal.service";
import { EntityEventProvider, EntityEventProviderStatusEnum } from "core";

@Injectable()
export abstract class TerminalProvider {

  public active$: Observable<Terminal>;
  public status$: Observable<string>;

  protected activeSubject$ = new BehaviorSubject<Terminal>(null);
  protected statusSubject$ = new BehaviorSubject<string>('Unavailable');

  constructor(
    protected terminalService: TerminalService,
    protected entityEventProvider: EntityEventProvider
  ) {
    this.active$ = this.activeSubject$.asObservable().pipe(
      filter(x => x != null),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.status$ = this.statusSubject$.asObservable().pipe(
      distinctUntilChanged()
    );

    this.active$.subscribe(terminal => this.activeSubject$.next(terminal));

    entityEventProvider.status$.subscribe(entityEventProviderStatus => {
      if (entityEventProviderStatus == EntityEventProviderStatusEnum.Ready) {
        this.getActive();
      } else {
        this.activeSubject$.next(null);
        this.statusSubject$.next('Offline');
      }
    });
  }

  get active(): Terminal {

    return this.activeSubject$.value;
  }

  public getOneByUid$(uid: string): Observable<Terminal> {

    return this.terminalService.getByUid(uid)
  }

  public getOneByDeviceId$(deviceId: string): Observable<Terminal> {

    return this.terminalService.getByDeviceId(deviceId)
  }

  public getTransactionNumber(): Observable<string> {

    var currentDate = new Date();
    var currentDateText = `${currentDate.toLocaleDateString('en-us', { year: "numeric", month: "2-digit", day: "2-digit" })}-${currentDate.toLocaleTimeString('en-us', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}`;
    currentDateText = currentDateText
      .replace(/\//g, '')
      .replace(/:/g, '')
      .replace(/am/ig, '')
      .replace(/pm/ig, '')
      .replace(/ /g, '');

    return of(currentDateText);
  }

  protected abstract getActive(): void;
}
