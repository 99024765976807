import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BlobImage } from "../../models/blob-image";
import { SelectImageModalComponent } from "./select-image-modal.component";

@Injectable()
export class SelectImageModalProvider  {

  private readonly isDesktop: boolean;

  constructor(
    protected dialog: MatDialog,
  ) {
  }

  public open(data: SelectImageModalData): MatDialogRef<SelectImageModalComponent, BlobImage> {

    return this.dialog.open<SelectImageModalComponent, SelectImageModalData, BlobImage>(SelectImageModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface SelectImageModalData {

  title: string;
  imageHostUrl: string;
  images: BlobImage[];
}
