import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SpinnerModalProvider } from "core";
import { UUID } from "angular2-uuid";
import { NavigationProvider } from 'core';
import { Member } from '../../../models/member';
import { MemberService } from '../../../services/member.service';

export class MemberProfileForm {

  public memberUid: string;
  public form: UntypedFormGroup;
  public canSave = false;
  public canPrint = false;

  constructor(
    protected navigationProvider: NavigationProvider,
    protected spinnerModalProvider: SpinnerModalProvider,
    protected memberService: MemberService
  ) {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl(''),
      displayName: new UntypedFormControl(''),
      email: new UntypedFormControl('', { validators: Validators.email, updateOn: 'blur' })
    });

    this.form.valueChanges.subscribe(x => this.updateEnablement());
  }

  public loadForm(member: Member) {

    if (member) {
      this.memberUid = member.uid;

      this.form.get('firstName').setValue(member.firstName);
      this.form.get('lastName').setValue(member.lastName);
      this.form.get('displayName').setValue(member.displayName);
      this.form.get('email').setValue(member.email);
    }

    this.form.markAsPristine();
    this.updateEnablement();
  }

  public save() {

    if (this.form.dirty) {
      let saveDialogRef = this.spinnerModalProvider.open();

      saveDialogRef.afterOpened().subscribe(() => {
        if (this.memberUid) {
          this.memberService.update(
            this.memberUid,
            this.form.get('firstName').value,
            this.form.get('lastName').value,
            this.form.get('displayName').value,
            this.form.get('email').value
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        } else {
          this.memberUid = UUID.UUID();

          this.memberService.create(
            this.memberUid,
            this.form.get('firstName').value,
            this.form.get('lastName').value,
            this.form.get('displayName').value,
            this.form.get('email').value
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        }
      });
    } else {
      this.navigationProvider.backOneWaypoint();
    }
  }

  public cancel() {
    this.navigationProvider.backOneWaypoint();
  }

  protected updateEnablement() {

    this.canSave = this.form.dirty;
    this.canPrint = !this.form.dirty && this.form.valid;
  }
}
