import { Pipe, PipeTransform } from '@angular/core';
import { ProductStatusKeys } from '../keys';
import { Product } from '../models/product';

@Pipe({
  name: 'productStatus'
})
export class ProductStatusPipe implements PipeTransform {

  private static map = new Map<string, string>([
    [ProductStatusKeys.Active.toUpperCase(), 'Active'],
    [ProductStatusKeys.Inactive.toUpperCase(), 'Inactive'],
    [ProductStatusKeys.Deleted.toUpperCase(), 'Deleted'],
  ]);

  constructor(
  ) {
  }

  transform(value: Product | string): string {

    if (typeof(value) === 'string'){
      return value ? ProductStatusPipe.map.get(value.toUpperCase()) : null;
    } else {
      return value ? ProductStatusPipe.map.get(value.productStatusUid.toUpperCase()) : null;
    }
  }
}
