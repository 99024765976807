<div style="display: flex; flex-direction: column; height: 100%;">

  <div style="font-size: 32px; line-height: 32px; margin-bottom: 3px; font-weight: bold; text-align: center;">
    <span>{{ data.title }}</span>
  </div>

  <div style="flex: 1 1 auto; overflow-y: auto;">

    <div style="display: flex; flex-direction: column;">
      <div>
        <button mat-flat-button class="check-button" [ngClass]="{ 'selected': allIsSelected }" style="height: 8vh; width: 4.1667vw; margin-bottom: 4px; margin-right: 4px;" (click)="toggleAll()">&nbsp;</button>
        <span style="font-size: 32px;">All</span>
      </div>

      <div style="flex: 1 1 auto; padding-left: 4.1667vw;">
        <div *ngFor="let option of options">
          <button mat-flat-button class="check-button" [ngClass]="{ 'selected': option.selected }" style="height: 8vh; width: 4.1667vw; margin-bottom: 4px; margin-right: 4px;" (click)="toggleOption(option)">&nbsp;</button>
          <span style="font-size: 32px;">{{ option.display }}</span>
        </div>
      </div>

    </div>

  </div>

  <div style="display: flex;">

    <app-pos-button (click)="close()" [styles]="{ backgroundColor: 'red' }">Cancel</app-pos-button>
    <span style="flex: 1 1 auto;"></span>
    <app-pos-button (click)="print()">Print</app-pos-button>

  </div>

</div>