import { Component, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { concatMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ObservableCacheProvider, PaginationInput, TenantProvider } from 'core';
import { CategoryService, MenuPlacement, Product, ProductProvider, ProductService } from 'downtown-product';
import { ItemConfiguratorModalData, ItemConfiguratorModalProvider } from 'downtown-transaction';
import { CartProvider } from '../../providers/cart.provider';
import { CartService } from '../../services/cart.service';
import { initCartItem } from '../../functions/initialize';
import { EditableCartItem } from '../../models/editable-cart';
import { fromEditableCartItem } from '../../functions/cart-input-factory';
import { ViewFactory } from '../../factories/view-factory';

@Component({
  selector: 'store-core-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  private pagination = <PaginationInput>{ pageSize: 1000, pageIndex: 0, sortField: 'name', sortStrategy: 'asc' };

  public categories: Category[];
  public products: Product[];
  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private itemConfiguratorModalProvider: ItemConfiguratorModalProvider,
    private cacheProvider: ObservableCacheProvider,
    private tenantProvider: TenantProvider,
    private categoryService: CategoryService,
    private productProvider: ProductProvider,
    private cartService: CartService,
    private cartProvider: CartProvider
  ) {
  }

  ngOnInit() {

    const storeFrontUid = this.activatedRoute.snapshot.params['storeFrontUid'];

    combineLatest([
      this.categoryService.search(storeFrontUid, null, null, null),
      this.productProvider.search$(storeFrontUid, null, null, null, this.pagination),
    ]).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(([categoryPage, productPage]) => {
      if (categoryPage != null && productPage != null) {
        var products = productPage.edges.map(x => x.node);

        var explodedProducts = products.map(product => {

          if (product.configuration && product.configuration.portions) {
            let saleablePortions = product.configuration && product.configuration.portions ? product.configuration.portions.filter(y => y.menuPlacementUid.toUpperCase() != MenuPlacement.NoSale.toUpperCase()) : [];

            return saleablePortions.map(y => <SelectableProduct>{
              product: product,
              categoryUid: product.categoryUid,
              title: product.name,
              subtitle: saleablePortions.length > 1 || y.menuPlacementUid.toUpperCase() != MenuPlacement.Menu.toUpperCase() ? y.name : '',
              price: product.basePrice + y.price,
              portionUid: y.uid,
              menuPlacementUid: y.menuPlacementUid
            });
          } else {
            return [<SelectableProduct>{
              product: product,
              categoryUid: product.categoryUid,
              title: product.name,
              subtitle: '',
              price: product.basePrice,
              menuPlacementUid: MenuPlacement.Menu
            }];

          }
        }).reduce((a, x) => { return a.concat(x); }, []);

        this.categories = categoryPage.edges.map(x => x.node).map(category => {
          return <Category>{
            name: category.name,
            products: explodedProducts.filter(x => x.categoryUid.toUpperCase() == category.uid.toUpperCase())
          }
        }).filter(x => x.products.length > 0);

        this.products = products;
      }
    });
  }

  ngOnDestroy() {

    this.destroyed$.next(null);
  }

  configureProduct(selection: SelectableProduct) {

    var cartItem = initCartItem(selection.product, selection.portionUid, this.productProvider, true);

    if (selection.product.isConfigurable()) {

      this.itemConfiguratorModalProvider.open(<ItemConfiguratorModalData>{
        ownerUid: this.activatedRoute.snapshot.params['storeFrontUid'],
        productUid: selection.product.uid,
        productVersion: selection.product.version,
        itemConfiguration: cartItem.configuration.value,
        menuPlacementUid: MenuPlacement.Menu,
        isNew: true,
        isAddOn: false
      }).afterClosed().subscribe(result => {
        if (result) {
          var cartItem = initCartItem(selection.product, selection.portionUid, this.productProvider, true);
          cartItem.configuration.next(result.configuration);

          this.addToCart(cartItem);
        }
      });
    }
  }

  addToCart(cartItem: EditableCartItem) {


    if (cartItem) {
      let cartUid = this.cartProvider.current ? this.cartProvider.current.uid : UUID.UUID();

      const storeFrontUid = <string>this.activatedRoute.snapshot.params['storeFrontUid'];

      (this.cartProvider.current ? of(this.cartProvider.current) : this.cartService.create(cartUid, storeFrontUid, ViewFactory.Cart_FullView)).pipe(
        concatMap(cart => {
          this.cartProvider.current = cart;

          cartItem.uid = cartItem.uid || UUID.UUID();
          cartItem.cartUid = cart.uid;

          return this.cartService.addCartItem(cart.uid, fromEditableCartItem(cartItem));
        })
      ).subscribe(cart => {
        if (cart) {
          // this.cart = this.cartEditDataHandler.mergeCart(this.cart, cart);
          this.cartProvider.current = cart;
        }
      });
    }
  }
}

class Category {

  name: string;
  description: string;
  products: SelectableProduct[];
}

interface SelectableProduct {

  product: Product;
  categoryUid: string;
  title: string;
  subtitle: string;
  price: number;
  portionUid: string;
  menuPlacementUid: string;
}
