import { CheckListModalComponent } from "./check-list-modal/check-list-modal.component";
import { CheckListModalProvider } from "./check-list-modal/check-list-modal.provider";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { ConfirmModalProvider } from "./confirm-modal/confirm-modal.provider";
import { DateModalComponent } from "./date-modal/date-modal.component";
import { DateModalProvider } from "./date-modal/date-modal.provider";
import { DateTimeModalComponent } from "./date-time-modal/date-time-modal.component";
import { DateTimeModalProvider } from "./date-time-modal/date-time-modal.provider";
import { KeyboardModalComponent } from "./text-entry-modal/keyboard-modal/keyboard-modal.component";
import { MessageModalComponent } from "./message-modal/message-modal.component";
import { MessageModalProvider } from "./message-modal/message-modal.provider";
import { TextBoxModalComponent } from "./text-entry-modal/textbox-modal/textbox-modal.component";
import { SelectImageModalComponent } from "./select-image-modal/select-image-modal.component";
import { SelectImageModalProvider } from "./select-image-modal/select-image-modal.provider";
import { SelectListModalComponent } from "./select-list-modal/select-list-modal.component";
import { SelectListModalProvider } from "./select-list-modal/select-list-modal.provider";
import { SpinnerModalComponent } from "./spinner-modal/spinner-modal.component";
import { SpinnerModalProvider } from "./spinner-modal/spinner-modal.provider";
import { WaitModalComponent } from "./wait-modal/wait-modal.component";
import { WaitModalProvider } from "./wait-modal/wait-modal.provider";
import { TextEntryModalProvider } from "./text-entry-modal/text-entry-modal.provider";

export const modalComponentTypes = [
  ConfirmModalComponent,
  CheckListModalComponent,
  DateModalComponent,
  DateTimeModalComponent,
  KeyboardModalComponent,
  MessageModalComponent,
  SelectImageModalComponent,
  SelectListModalComponent,
  SpinnerModalComponent,
  TextBoxModalComponent,
  WaitModalComponent,
];

export const modalProviderTypes = [
  ConfirmModalProvider,
  CheckListModalProvider,
  DateModalProvider,
  DateTimeModalProvider,
  MessageModalProvider,
  SelectImageModalProvider,
  SelectListModalProvider,
  SpinnerModalProvider,
  TextEntryModalProvider,
  WaitModalProvider,
];
