<div style="display: flex; flex-direction: column; height: 100%;">

  <app-page-header>

    <ng-container left>
      <button mat-flat-button style="width: 4.1666vw;" (click)="navigateHome()">
        <mat-icon style="font-weight: bold;">home</mat-icon>
      </button>
      <button mat-flat-button style="width: 4.1666vw; margin-left: 4px;" (click)="navigateBack()">
        <mat-icon style="font-weight: bold;">arrow_back</mat-icon>
      </button>
    </ng-container>

    <ng-container middle>{{title}}</ng-container>

    <app-paginator right [dataSource]="dataSource" [paginator]="paginator"></app-paginator>

  </app-page-header>

  <app-action-bar [actionBarConfiguration]="actionBarConfiguration"></app-action-bar>

  <div style="flex: 1; overflow-y: auto;">
    <app-pay-periods-table [dataSource]="dataSource" [columns]="columns"></app-pay-periods-table>
  </div>

</div>
