import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { Position } from "../../../models/position";
import { EmployeeService, EmployeeViewOptions } from "../../../services/employee.service";
import { PositionService } from "../../../services/position.service";
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";

@Component({
  selector: 'app-employee-positions-table',
  templateUrl: './employee-positions-table.component.html',
  styleUrls: ['./employee-positions-table.component.scss']
})
export class EmployeePositionsTableComponent {

  @Input() public dataSource: EmployeePositionDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }
}

export class EmployeePositionModel {

  positionUid: string;
  name: string;
  payRate: number;
  workUnitUid: string;

  canEdit: boolean;
  canDelete: boolean;
}

export class EmployeePositionDataSource extends DataSource<EmployeePositionModel> {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public selection: SelectionModel<EmployeePositionModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private dataSubject = new BehaviorSubject<EmployeePositionModel[]>([]);
  private positions: Position[];
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private employeeService: EmployeeService,
    private positionService: PositionService,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();

    this.selection = new SelectionModel<EmployeePositionModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<EmployeePositionModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(employeeUid: string, viewOptions: EmployeeViewOptions) {

    this.loadingSubject.next(true);

    combineLatest([
      this.employeeService.getByUid(employeeUid, viewOptions),
      this.positionService.search(null, null)
    ]).pipe(
      map(([employee, positions]) => {
        this.positions = positions.edges.map(x => x.node);
        this.totalCountSubject.next(employee.positions?.length || 0);

        const employeePositions = employee.positions.map(x => {
          let employeePositionModel = Object.assign(new EmployeePositionModel(), {
            name: this.positions.find(y => y.uid == x.uid).name,
            positionUid: x.uid,
            payRate: x.payRate,
            workUnitUid: x.workUnitUid
          });

          this.evaluateEnablement(employeePositionModel);

          return employeePositionModel;
        });

        return employeePositions;
      }),
      catchError(() => of(<EmployeePositionModel[]>[])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(data => {
      this.dataSubject.next(data);
    });
  }

  public add(employeeUid: string, positionUid: string, amount: number, workUnitUid: string): Observable<EmployeePositionModel> {

    return this.employeeService.addPosition(employeeUid, positionUid, amount, workUnitUid).pipe(
      map(employee => {
        var employeePosition = <EmployeePositionModel>{
          positionUid: positionUid,
          name: this.positions.find(y => y.uid == positionUid).name,
          payRate: amount,
          workUnitUid: workUnitUid
        };

        var employeePositions = this.dataSubject.value;
        employeePositions.push(employeePosition);
        this.dataSubject.next(employeePositions);

        return this.evaluateEnablement(employeePosition);
      })
    );
  }

  public edit(employeeUid: string, positionUid: string, amount: number, workUnitUid: string): Observable<EmployeePositionModel> {

    return this.employeeService.updatePosition(employeeUid, positionUid, amount, workUnitUid).pipe(
      map(employee => {
        const employeePosition = this.dataSubject.value.find(x => x.positionUid.toUpperCase() == positionUid.toUpperCase());
        employeePosition.payRate = amount;
        employeePosition.workUnitUid = workUnitUid;

        return this.evaluateEnablement(employeePosition);
      })
    );
  }

  public delete(employeeUid: string, employeePosition: EmployeePositionModel): Observable<EmployeePositionModel> {

    return this.employeeService.deletePosition(employeeUid, employeePosition.positionUid, EmployeeService.EmployeeFullView).pipe(
      map(x => {
        // employeePosition.productDepartmentStatusUid = x.productDepartmentStatusUid;

        var employeePositions = this.dataSubject.value;
        employeePositions.splice(employeePositions.findIndex(x => x.positionUid.toUpperCase() == employeePosition.positionUid.toUpperCase()), 1);
        this.dataSubject.next(employeePositions);

        return this.evaluateEnablement(employeePosition);
      })
    );
  }

  private evaluateEnablement(employeePosition: EmployeePositionModel): EmployeePositionModel {

    // let employeePositionStatusUid = employeePosition.employeePositionStatusUid.toUpperCase();

    employeePosition.canEdit = true;
    employeePosition.canDelete = true;

    return employeePosition;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEdit = selected.length == 1;
    this._canBulkDelete = selected.length == 1;
  }
}
