import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ExpansionContainerComponent, getDescendentRouteSnapshot } from 'core';
import { ExpansionPanelComponent } from 'core';
import { NavigationProvider } from 'core';
import { ProductAndPortionModalProvider } from '../../../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { ConfigurationComponentBase } from '../../configuration-component-base';
import { ProductContext } from '../../product-context';
import { TenantProvider } from 'core';
import { ProductProvider } from '../../../../providers';

@Component({
  selector: 'app-back-office-product-configuration-inclusion-group',
  templateUrl: './back-office-product-configuration-inclusion-group.component.html',
  styleUrls: ['./back-office-product-configuration-inclusion-group.component.scss']
})
export class BackOfficeProductConfigurationInclusionGroupComponent extends ConfigurationComponentBase {

  @ViewChild('expansionContainer', { static: true }) expansionContainer: ExpansionContainerComponent;
  @ViewChild('optionsContainer', { static: true }) optionsContainer: ExpansionContainerComponent;

  @ViewChild('generalPanel', { static: true }) generalPanel: ExpansionPanelComponent;
  @ViewChild('optionsPanel', { static: true }) optionsPanel: ExpansionPanelComponent;

  public form: UntypedFormGroup;
  public scope: string;
  public optionsFormArray: UntypedFormArray;

  private _context: ProductContext;
  private destroyed$ = new Subject();

  constructor(
    private router: Router,
    private navigationProvider: NavigationProvider,
    activatedRoute: ActivatedRoute,
    tenantProvider: TenantProvider,
    productProvider: ProductProvider,
    productAndPortionModalProvider: ProductAndPortionModalProvider,
  ) {
    super(activatedRoute, tenantProvider, productProvider, productAndPortionModalProvider);

    this.scope = this.getScope(this.activatedRoute);
  }

  ngOnInit() {

  }

  public set context(context: ProductContext) {

    this._context = context;

    this.assignForm();

    this.router.events.pipe(
      takeUntil(this.destroyed$),
      filter(event => event instanceof NavigationEnd)
    ).subscribe(x => {
      this.assignForm();
    });
  }

  private assignForm() {

    var route = getDescendentRouteSnapshot(this.activatedRoute);
    var portionUid = route.params['portionUid'];

    let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
    if (portionUid) {
      let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
      if (portionsGroupFormArray) {
        configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid.toLowerCase());
      }
    }

    var inclusionGroupUid = route.params['inclusionGroupUid'];
    let inclusionGroupsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('inclusionGroups');
    if (inclusionGroupsGroupFormArray) {
      configurationFormGroup = <UntypedFormGroup>inclusionGroupsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == inclusionGroupUid.toLowerCase());
    }

    this.form = configurationFormGroup;
    this.optionsFormArray = <UntypedFormArray>this.form.get('options');

    setTimeout(() => {
      this.setNavTarget();
    });
  }

  public get context(): ProductContext {

    return this._context;
  }

  setNavTarget() {

    var route = getDescendentRouteSnapshot(this.activatedRoute);

    var optionUid = route.params['optionUid'];

    this.expansionContainer.setSelected(null);
    this.optionsContainer.setSelected(null);

    if (optionUid || route.url.some(x => x.path == 'options')) {
      this.expansionContainer.setExpanded(this.optionsPanel);

      if (route.url.some(x => x.path == 'options')) {
        this.expansionContainer.setSelected(this.optionsPanel);
      }

      if (optionUid) {
        var optionPanel = this.optionsContainer.panels.find(x => x.value == optionUid)
        if (optionPanel) {
          this.optionsContainer.setSelected(optionPanel);
        }
      }
    } else {
      this.expansionContainer.setExpanded(this.generalPanel);
    }
  }

  private getBaseInclusionGroupNavigationSegments(): string[] {

    var segments = this.getBasePortionNavigationSegments();
    var routeSnapshot = getDescendentRouteSnapshot(this.activatedRoute);

    var inclusionGroupUid = routeSnapshot.params['inclusionGroupUid'];
    if (inclusionGroupUid) {
      segments.push(...['inclusiongroup', inclusionGroupUid]);
    }

    return segments;
  }

  panelClick(target: string) {

    this.navigationProvider.navigate(this.getBaseInclusionGroupNavigationSegments().concat([target]));
  }

  optionClick(target: string) {

    this.navigationProvider.navigate(this.getBaseInclusionGroupNavigationSegments().concat(['option', target]));
  }

  onActivate(event: ConfigurationComponentBase) {

    event.context = this.context;
  }

  public getInclusionGroupOptionsFormArray(): UntypedFormArray {

    var route = getDescendentRouteSnapshot(this.activatedRoute);
    var portionUid = route.params['portionUid']?.toLowerCase();
    var inclusionGroupUid = route.params['inclusionGroupUid'].toLowerCase();

    var configurationFormGroup = this.context.productForm.get('configuration');

    if (portionUid) {
      configurationFormGroup = (<UntypedFormArray>configurationFormGroup.get('portions')).controls.find(x => x.get('uid').value.toLowerCase() == portionUid.toLowerCase())
    }

    var inclusionGroupForm = (<UntypedFormArray>configurationFormGroup.get('inclusionGroups')).controls.find(x => x.get('uid').value.toLowerCase() == inclusionGroupUid.toLowerCase());
    var inclusionGroupOptionsFormArray = <UntypedFormArray>inclusionGroupForm.get('options');

    return inclusionGroupOptionsFormArray;
  }
}

