import { Component, Input, OnInit } from '@angular/core';
import { Cart } from '../../../models/cart';
import { CartService } from '../../../services/cart.service';
import { CheckoutContext } from '../check-out-context';

@Component({
  selector: 'store-core-checkout-logistics',
  templateUrl: './checkout-logistics.component.html',
  styleUrls: ['./checkout-logistics.component.scss']
})
export class CheckoutLogisticsComponent implements OnInit {

  @Input() public context: CheckoutContext;
  public cart: Cart;

  constructor(
    private cartService: CartService,
  ) {
  }

  ngOnInit() {
  }

  selectDeliveryDate() {

  }

  selectDeliveryTime() {

  }

  private updateLogistics() {

    let logisticTypeUid = this.context.logisticsForm.get('logisticTypeUid').value;
    let logisticScheduleUid = this.context.logisticsForm.get('logisticScheduleUid').value;

    if (logisticTypeUid != this.cart.logisticTypeUid || logisticScheduleUid != this.cart.logisticScheduleUid) {
      this.cartService.updateLogistics(
        this.cart.uid,
        logisticTypeUid,
        logisticScheduleUid,
        null
      ).subscribe(cart => {
        // this.cart = new EditableCartDataHandler(new LoggingService()).mergeCart(this.cart, cart);
        // this.fetchShipping(this.cart.uid);
        // this.fetchTax(this.cart.uid);
      });
    }
  }

}
