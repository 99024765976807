import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-expansion-panel-content',
  templateUrl: './expansion-panel-content.component.html',
  styleUrls: ['./expansion-panel-content.component.scss']
})
export class ExpansionPanelContentComponent {

  @Output() click = new EventEmitter<void>();

  ngOnInit() {

  }

  onClick() {

    this.click.emit();
  }
}
