import { Observable } from "rxjs";

import { TimeoutProvider, MessageModalProvider, SettingProvider, SystemMessageProvider, isEqualUUID } from "core";
import { PrintDepartmentSlipModalProvider, RegisterFeatureProvider, TransactionItemPrintModalProvider, notAvailableHandler$ } from "pos-core";
import { EditableTransaction } from "downtown-transaction";

import { navigateToTransactionHandler } from "../handlers";
import { navigateToHomeHandler } from "../handlers";
import { navigateToBackOfficeHandler } from "../handlers";
import { TerminalProvider } from "downmain-terminal";

export class SiteRegisterFeatureProvider extends RegisterFeatureProvider {

    constructor(
        private messageModalProvider: MessageModalProvider
    ) {
        super();
    }

    public navigateHome = navigateToHomeHandler;

    public navigateToBackOffice = navigateToBackOfficeHandler;

    public navigateToTransaction = navigateToTransactionHandler;

    public openCashDrawer$ = <(systemMessageProvider: SystemMessageProvider) => Observable<boolean>>null;

    public printCheck$ = (_: string) => notAvailableHandler$(this.messageModalProvider);

    public printReceipt$ = (_: string) => notAvailableHandler$(this.messageModalProvider);

    public printDepartmentSlip$ = (
        editableTransaction: EditableTransaction,
        departmentUid: string,
        printDepartmentSlipModalProvider: PrintDepartmentSlipModalProvider,
        transactionItemPrintModalProvider: TransactionItemPrintModalProvider,
        messageModalProvider: MessageModalProvider,
        timeoutProvider: TimeoutProvider,
        systemMessageProvider: SystemMessageProvider,
        settingProvider: SettingProvider,
        terminalProvider: TerminalProvider
    ) => notAvailableHandler$(this.messageModalProvider);
}