import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Lock } from '../models/lock';
import { CacheService } from '../services/cache.service';

@Injectable()
export class LockResolver {

  constructor(
    private cacheService: CacheService
  ) {
  }

  resolve(uid: string): Observable<Lock> {

    if (uid) {
      var item = this.cacheService.getOrAdd(uid, () => {
        const lock = new Lock();
        lock.uid = uid;
        lock.name = uid;
        lock.isLocalDevice = false;
        lock.isLocalUser = false;

        return of(lock);
      });

      return item;
    }

    return of(null);
  }
}
