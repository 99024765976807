import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { Conversation, ConversationMessage } from "../models/conversation";
import { OltpServiceSettings } from "../oltp-service-settings";
import { HttpService } from "core";
import { PaginationInput } from "core";
import { Page } from "core";
import { PosModelFactory } from "../pos-model-factory";

@Injectable()
export class ConversationService {

  public static readonly ConversationIndexView = <ConversationViewOptions>{};
  public static readonly ConversationFullView = <ConversationViewOptions>{};

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings
  ) {
  }

  getByUid(uid: string, viewOptions: ConversationViewOptions = ConversationService.ConversationIndexView): Observable<Conversation> {

    let view = ConversationService.buildView(viewOptions);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<Conversation>(this.oltpServiceSettings.apiUrl, 'api/oltp/conversation', request, 'getByUid').pipe(
      map(x => PosModelFactory.assignConversation(x))
    );
  }

  search(refAggregateUids: string[], paginationInput: PaginationInput, viewOptions: ConversationViewOptions = ConversationService.ConversationIndexView): Observable<Page<Conversation>> {

    let view = ConversationService.buildView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(refAggregateUids: ${JSON.stringify(refAggregateUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    }

    return this.httpService.graph<Page<Conversation>>(this.oltpServiceSettings.apiUrl, 'api/oltp/conversation', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = PosModelFactory.assignConversation(edge.node)))
    );
  }

  create(uid: string, refAggregateUid: string, messageUid: string, identityId: string, content: string, viewOptions: ConversationViewOptions = ConversationService.ConversationIndexView): Observable<Conversation> {

    let view = ConversationService.buildView(viewOptions);

    var conversationMessageInput = messageUid ? {
      uid: messageUid,
      identityId: identityId,
      content: content
    } : null;

    var request = {
      query: `mutation createConversation($message:ConversationMessageInput!) { create(uid: "${uid}", refAggregateUid: "${refAggregateUid}", message: $message) ${view} }`,
      variables: { message: conversationMessageInput }
    };

    return this.httpService.graph<Conversation>(this.oltpServiceSettings.apiUrl, 'api/oltp/conversation', request, 'create').pipe(
      map(x => PosModelFactory.assignConversation(x))
    );
  }

  addMessage(uid: string, messageUid: string, identityId: string, content: string, viewOptions: ConversationViewOptions = ConversationService.ConversationIndexView): Observable<ConversationMessage> {

    let view = ConversationService.buildView(viewOptions);

    var conversationMessageInput = {
      uid: messageUid,
      identityId: identityId,
      content: content
    };

    var request = {
      query: `mutation addMessage($message:ConversationMessageInput!) { addMessage(uid: "${uid}", message: $message) ${view} }`,
      variables: { message: conversationMessageInput }
    };

    return this.httpService.graph<ConversationMessage>(this.oltpServiceSettings.apiUrl, 'api/oltp/conversation', request, 'addMessage').pipe(
      map(x => PosModelFactory.assignConversationMessage(x))
    );
  }

  delete(uid: string, viewOptions: ConversationViewOptions = ConversationService.ConversationIndexView): Observable<Conversation> {

    let view = ConversationService.buildView(viewOptions);

    var request = {
      query: `mutation deleteConversation { delete(uid: "${uid}") ${view} }`
    }

    return this.httpService.graph<Conversation>(this.oltpServiceSettings.apiUrl, 'api/oltp/conversation', request, 'delete').pipe(
      map(x => PosModelFactory.assignConversation(x))
    );
  }

  public static buildView(viewOptions: ConversationViewOptions) {

    let view = `uid refAggregateUid conversationStatusUid`;

    if (viewOptions.includeMessages) {
      view += ` messages { uid conversationUid identityId content dateTimeUtc identityUser { id username } employee { uid firstName lastName displayName fullName } }`;
    }

    return '{' + view + '}';
  }
}

export interface ConversationViewOptions {

  includeMessages: boolean;
}
