import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ConsoleLoggingInterceptor implements HttpInterceptor {

  public enabled: boolean = true;

  private excludeEndpoints = <string[]>[];

  constructor(
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      map((httpEvent: HttpEvent<any>) => {
        if (this.enabled && httpEvent instanceof HttpResponse) {
          if (!this.excludeEndpoints.some(x => x == request.url.toLowerCase())) {
            const authorization = request.headers.get('Authorization')?.slice(0, 50);

            console.log(`${request.method} ${request.url} [${authorization}] : ${httpEvent.status}`);
          }
        }

        return httpEvent;
      }),
      catchError((error) => {
        // Catch and handle network exceptions here, application errors should be handled in components
        if (this.enabled && error instanceof HttpErrorResponse) {
          console.log('HTTP ERROR: ' + error.message);
        }

        return throwError(() => new Error(error.message));
      })
    );
  }

  public addExcludeEndpoint(endpointUrl: string) {

    this.excludeEndpoints.push(endpointUrl.toLowerCase());
  }
}
