import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Component, Inject } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProductPreparationModalData } from "./product-preparation-modal.provider";
import { buildProductPreparationOptionForm } from '../../functions/configuration-form-functions';
import { ConfigurationFormFuncs } from '../../functions/configuration.functions';

@Component({
  selector: 'app-product-preparation-modal',
  templateUrl: './product-preparation-modal.component.html',
  styleUrls: ['./product-preparation-modal.component.scss']
})
export class ProductPreparationModalComponent {

  public form: UntypedFormGroup;
  public options: UntypedFormArray;
  public scope: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProductPreparationModalData,
    private dialogRef: MatDialogRef<ProductPreparationModalComponent>
  ) {
    this.form = data.preparationForm;
    this.options = <UntypedFormArray>data.preparationForm.get('options');

    this.scope = data.scope;
  }

  public addOption() {

    const formGroup = buildProductPreparationOptionForm(null);

    this.options.push(formGroup);
    this.options.markAsDirty();
  }

  public drop(event: CdkDragDrop<string[]>) {

    if (event.previousIndex != event.currentIndex) {
      var item = this.options.controls[event.previousIndex];
      this.options.removeAt(event.previousIndex);

      this.options.insert(event.currentIndex, item);
    }
  }

  public delete(formGroup: UntypedFormGroup) {

    var index = this.options.controls.indexOf(formGroup);
    this.options.removeAt(index);
  }

  public getProductPreparationForm(preparationForm: UntypedFormGroup) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(preparationForm);

    return ConfigurationFormFuncs.preparations.getPreparationForm(productForm, preparationForm.get('uid').value);
  }

  public getProductPreparationFormValue(preparationForm: UntypedFormGroup, key: string) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(preparationForm);

    return ConfigurationFormFuncs.preparations.getPreparationFormValue(productForm, preparationForm.get('uid').value, key);
  }

  public getProductPreparationOptionFormValue(preparationForm: UntypedFormGroup, optionForm: UntypedFormGroup, key: string) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(preparationForm);

    return ConfigurationFormFuncs.preparations.getPreparationOptionFormValue(productForm, preparationForm.get('uid').value, optionForm.get('uid').value, key);
  }

  public getProductInclusionGroupOptionPreparationForm(preparationForm: UntypedFormGroup) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(preparationForm);

    var inclusionGroupUid = preparationForm.parent.parent.parent.parent.get('uid').value;
    var optionUid = preparationForm.parent.parent.get('uid').value;
    var preparationUid = preparationForm.get('uid').value;

    return ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationForm(productForm, inclusionGroupUid, optionUid, preparationUid);
  }

  public getProductInclusionGroupPreparationFormValue(preparationForm: UntypedFormGroup, key: string) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(preparationForm);

    var inclusionGroupUid = preparationForm.parent.parent.parent.parent.get('uid').value;
    var optionUid = preparationForm.parent.parent.get('uid').value;
    var preparationUid = preparationForm.get('uid').value;

    return ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationFormValue(productForm, inclusionGroupUid, optionUid, preparationUid, key);
  }

  public getProductInclusionGroupPreparationOptionFormValue(preparationForm: UntypedFormGroup, optionForm: UntypedFormGroup, key: string) {

    var productForm = <UntypedFormGroup>ConfigurationFormFuncs.configuration.getTopParent(preparationForm);

    var inclusionGroupUid = preparationForm.parent.parent.parent.parent.get('uid').value;
    var optionUid = preparationForm.parent.parent.get('uid').value;
    var preparationUid = preparationForm.get('uid').value;
    var preparationOptionUid = optionForm.get('uid').value;

    return ConfigurationFormFuncs.preparations.getProductInclusionGroupOptionPreparationOptionFormValue(productForm, inclusionGroupUid, optionUid, preparationUid, preparationOptionUid, key);
  }
}
