import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Product } from "downtown-product";
import { EditableTransactionItemConfiguration } from '../../models/editable-transaction/editable-transaction';
import { Observable } from "rxjs";
import { calculateConfigurationTotal } from "../../functions";
import { ItemConfiguratorContext } from "../item-configurator/item-configurator-context";

@Component({
  selector: 'transaction-item-configuration-editor',
  templateUrl: './item-configuration-editor.component.html',
  styleUrls: ['./item-configuration-editor.component.scss']
})
export class ItemConfigurationEditorComponent {

  @Input() public context: ItemConfiguratorContext;
  @Output() public configurationChanged = new EventEmitter();

  public product: Product;
  public itemConfiguration: EditableTransactionItemConfiguration;

  constructor(
  ) {
  }

  ngOnInit() {

    if (this.context) {
      this.product = this.context.product;
      this.itemConfiguration = this.context.itemConfiguration;
    }
  }

  public get eachAmount(): Observable<number> {

    return calculateConfigurationTotal(this.context.product.uid, this.context.product.version, this.context.itemConfiguration, this.context.productProvider);
  }

  public handleConfigurationChanged() {

    this.configurationChanged.emit();
  }
}
