import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { GoogleChartsModule } from 'angular-google-charts';
import { PosSiteRoutingModule } from './pos-site-routing.module';

import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

import { AuthModule } from 'auth';
import { CoreLibraryConfig, CoreModule, DebugErrorHandlingProvider, KeyboardModalComponent, MessageModalProvider, SystemMessageConfig, SystemMessageProvider, TextEntryModalComponent } from 'core';
import { CardEntryModalComponent, CardEntryModalProvider, PosCoreModule, REGISTER_HANDLER_PROVIDER_FACTORY } from 'pos-core';
import { DownMainTerminalModule, TerminalProvider } from 'downmain-terminal';
import { DowntownProductModule, ProductLibraryConfig } from 'downtown-product';
import { DowntownTransactionModule, TransactionServiceSettings } from 'downtown-transaction';
import { DowntownDamModule } from 'downtown-dam';
import { PosStoreIntegrationModule } from 'pos-store-integration';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { HasAccessGuard } from './guards/has-access.guard';
import { AuthorizationGuard } from './guards/authorization.guard';
import { AuthorizationCanGuard } from './guards/authorization.can.guard';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { OpenIDImplicitFlowConfiguration } from 'auth';
import { IdentityServerSettings } from 'auth';
import { OidcConfigService } from 'auth';
import { OidcSecurityService } from 'auth';
import { BlobServerSettings } from 'core';
import { ConsoleLoggingInterceptor } from 'core';
import { AuthHeaderProvider } from 'core';
import { AuthIdentityProvider } from 'core';
import { HasAuthorityGuard } from './guards/authority.guard';
import { SiteAuthHeaderProvider } from './providers/site-auth-header-provider';
import { SiteAuthIdentityProvider } from './providers/site-auth-identity.provider';
import { OltpServiceSettings } from 'pos-core';
import { AuthEmployeeProvider } from 'pos-core';
import { PosRuntimeProvider } from 'pos-core';
import { StoreServerSettings } from 'pos-core';
import { ConversationModalComponent } from './modals/conversation-modal/conversation-modal.component';
import { ConversationModalProvider } from './modals/conversation-modal/conversation-modal.provider';
import { IdentityServerConnectionProvider } from './providers/identity-server-connection.provider';
import { TransactionNotesModalComponent } from './modals/transaction-notes-modal/transaction-notes-modal.component';
import { TransactionNotesModalProvider } from './modals/transaction-notes-modal/transaction-notes-modal.provider';
import { SiteRuntimeProvider } from './providers/runtime.provider';
import { SiteAuthEmployeeProvider } from './providers/site-auth-employee.provider';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer-ng17';
import { SiteSystemMessageProvider } from './providers/site-system-message.provider';
import { SiteCardEntryModalProvider } from './modals/site-card-entry-modal/site-card-entry-modal.provider';
import { SiteCardEntryModalComponent } from './modals/site-card-entry-modal/site-card-entry-modal.component';
import { SiteRegisterFeatureProvider } from './providers/site-register-feature-provider';
import { SiteTerminalProvider } from './providers/site-terminal.provider';

export function loadConfig(oidcConfigService: OidcConfigService) {
  console.log('APP_INITIALIZER STARTING');
  return () => oidcConfigService.load();
}

@NgModule({
  declarations: [
    AppComponent,

    ConversationModalComponent,
    TransactionNotesModalComponent,
    SiteCardEntryModalComponent,

    ...PosSiteRoutingModule.components,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,

    PdfJsViewerModule,

    // NgxChartsModule,
    // GoogleChartsModule,

    PosSiteRoutingModule,

    HttpClientModule,
    AuthModule.forRoot(),
    CoreModule,
    DownMainTerminalModule,
    DowntownProductModule,
    DowntownTransactionModule,
    DowntownDamModule,
    PosCoreModule,
    // PosCoreModule.forRoot(
    //   <OltpServiceSettings>environment.oltp,
    //   <productLibraryConfig>environment.product,
    //   <TransactionServiceSettings>environment.transaction,
    //   <IdentityServerSettings>environment.identityServer,
    //   <BlobServerSettings>environment.blob
    // ),
    PosStoreIntegrationModule.forRoot(),
  ],
  providers: [
    CurrencyPipe,
    DatePipe,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConsoleLoggingInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: DebugErrorHandlingProvider
    },
    {
      provide: PosRuntimeProvider,
      useClass: SiteRuntimeProvider
    },
    {
      provide: AuthIdentityProvider,
      useClass: SiteAuthIdentityProvider
    },
    {
      provide: AuthEmployeeProvider,
      useClass: SiteAuthEmployeeProvider
    },
    {
      provide: AuthHeaderProvider,
      useClass: SiteAuthHeaderProvider
    },
    {
      provide: SystemMessageProvider,
      useClass: SiteSystemMessageProvider
    },
    {
      provide: TerminalProvider,
      useClass: SiteTerminalProvider
    },
    {
      provide: CoreLibraryConfig,
      useValue: environment.core
    },
    {
      provide: OltpServiceSettings,
      useValue: environment.oltp
    },
    {
      provide: SystemMessageConfig,
      useValue: environment.systemMessage
    },
    {
      provide: ProductLibraryConfig,
      useValue: environment.product
    },
    {
      provide: TransactionServiceSettings,
      useValue: environment.transaction
    },
    {
      provide: IdentityServerSettings,
      useValue: environment.identityServer
    },
    {
      provide: BlobServerSettings,
      useValue: environment.blob
    },
    {
      provide: StoreServerSettings,
      useValue: <StoreServerSettings>environment.store
    },
    {
      provide: REGISTER_HANDLER_PROVIDER_FACTORY,
      useFactory: (
        messageModalProvider: MessageModalProvider
      ) => {
        return () => {
          return new SiteRegisterFeatureProvider(
            messageModalProvider
          );
        }
      },
      deps: []
    },
    {
      provide: TextEntryModalComponent,
      useClass: KeyboardModalComponent
    },
    {
      provide: CardEntryModalProvider,
      useClass: SiteCardEntryModalProvider
    },
    {
      provide: CardEntryModalComponent,
      useClass: SiteCardEntryModalComponent
    },
    OidcConfigService,
    OidcSecurityService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [OidcConfigService],
      multi: true
    },

    AuthorizationGuard,
    AuthorizationCanGuard,
    // SecureFileService,
    HasAuthorityGuard,
    HasAccessGuard,

    SiteRuntimeProvider,
    SiteAuthIdentityProvider,
    IdentityServerConnectionProvider,

    ConversationModalProvider,
    TransactionNotesModalProvider,
    SiteCardEntryModalProvider
  ],
  bootstrap: [AppComponent]
})
export class PosSiteModule {

  constructor(
    identityServerSettings: IdentityServerSettings,
    oidcSecurityService: OidcSecurityService,
    oidcConfigService: OidcConfigService
  ) {
    // let deviceInfo = this.deviceService.getDeviceInfo();
    // const isMobile = this.deviceService.isMobile();
    // const isTablet = this.deviceService.isTablet();
    // const isDesktopDevice = this.deviceService.isDesktop();

    // console.log(deviceInfo);
    // console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.

    // Wire up auth configuration
    oidcConfigService.onWellKnownEndpointsLoaded.subscribe(wellKnownEndpoints => {

      const openIDImplicitFlowConfiguration = new OpenIDImplicitFlowConfiguration();
      openIDImplicitFlowConfiguration.stsServer = identityServerSettings.url;
      openIDImplicitFlowConfiguration.redirect_url = identityServerSettings.redirectUrl;
      // The Client MUST validate that the aud (audience) Claim contains its client_id value registered at the Issuer
      // identified by the iss (issuer) Claim as an audience.
      // The ID Token MUST be rejected if the ID Token does not list the Client as a valid audience,
      // or if it contains additional audiences not trusted by the Client.
      openIDImplicitFlowConfiguration.client_id = identityServerSettings.clientId;
      openIDImplicitFlowConfiguration.response_type = identityServerSettings.responseType;
      openIDImplicitFlowConfiguration.scope = identityServerSettings.scope;
      openIDImplicitFlowConfiguration.post_logout_redirect_uri = identityServerSettings.postLogoutRedirectUrl;
      openIDImplicitFlowConfiguration.start_checksession = identityServerSettings.startChecksession;

      openIDImplicitFlowConfiguration.silent_renew = identityServerSettings.silentRenew;
      openIDImplicitFlowConfiguration.silent_renew_url = 'http://localhost:4200/silent-renew.html';

      openIDImplicitFlowConfiguration.post_login_route = identityServerSettings.startupRoute;
      // HTTP 403
      openIDImplicitFlowConfiguration.forbidden_route = identityServerSettings.forbiddenRoute;
      // HTTP 401
      openIDImplicitFlowConfiguration.unauthorized_route = identityServerSettings.unauthorizedRoute;
      openIDImplicitFlowConfiguration.log_console_warning_active = identityServerSettings.logConsoleWarningActive;
      openIDImplicitFlowConfiguration.log_console_debug_active = identityServerSettings.logConsoleDebugActive;
      // id_token C8: The iat Claim can be used to reject tokens that were issued too far away from the current time,
      // limiting the amount of time that nonces need to be stored to prevent attacks.The acceptable range is Client specific.
      openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds = identityServerSettings.maxIdTokenIatOffsetAllowedInSeconds;

      // openIDImplicitFlowConfiguration.iss_validation_off = false;

      oidcSecurityService.setupModule(openIDImplicitFlowConfiguration, wellKnownEndpoints);
    });

    console.log('APP STARTING');

  }
}
