import { Order } from "./order";

export class CardTransaction {

  uid: string;
  ownerUid: string;
  storeFrontUid: string;
  orderUid: string;
  processorReferenceId: string;
  batchId : string;
  name: string;
  lastFour: string;
  amount: number;
  tip: number;
  cardTransactionStatusUid: string;
  createdDateTimeUtc: Date;
  modifiedDateTimeUtc: Date;

  order: Order;
}
