<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="dataSource.selection.isMultipleSelection()" (change)="$event ? dataSource.masterToggle() : null" [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row) : null" [checked]="dataSource.selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef color="accent">Name</th>
    <td mat-cell *matCellDef="let category">{{ category.name }}</td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef color="accent">Description</th>
    <td mat-cell *matCellDef="let category">{{ category.description }}</td>
  </ng-container>

  <ng-container matColumnDef="departmentUid">
    <th mat-header-cell *matHeaderCellDef>Product Department</th>
    <td mat-cell *matCellDef="let category">{{ category.departmentUid | department | async }}</td>
  </ng-container>

  <ng-container matColumnDef="categoryStatusUid">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let category">{{ category.categoryStatusUid | categoryStatus }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>

</table>
