import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { IPagedDataSource } from "core";
import { PageInfo } from "core";
import { PositionStatusKeys } from "../../../keys";
import { PositionService, PositionViewOptions } from "../../../services/position.service";
import { PaginationInput } from "core";

@Component({
  selector: 'app-positions-table',
  templateUrl: './positions-table.component.html',
  styleUrls: ['./positions-table.component.scss']
})
export class PositionsTableComponent {

  @Input() public dataSource: PositionDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }
}

export class PositionModel {

  uid: string;
  name: string;
  description: string;
  positionStatusUid: string;

  canEdit: boolean;
  canDelete: boolean;
}

export class PositionDataSource extends DataSource<PositionModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<PositionModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<PositionModel[]>([]);
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private positionService: PositionService,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<PositionModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<PositionModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(statusUids: string[], paginationInput: PaginationInput, viewOptions: PositionViewOptions) {

    this.loadingSubject.next(true);

    this.positionService.search(statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        const positions = page.edges.map(x => x.node).map(x => {
          let positionModel = Object.assign(new PositionModel(), {
            uid: x.uid,
            name: x.name,
            positionStatusUid: x.positionStatusUid
          });

          this.evaluateEnablement(positionModel);

          return positionModel;
        });

        return <[PageInfo, PositionModel[]]>[page.pageInfo, positions];
      }),
      catchError(() => of(<[PageInfo, PositionModel[]]>[null, []])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  // public enable(saleableProduct: PositionModel): Observable<PositionModel> {

  //   return this.positionService.enable(saleableProduct.uid).pipe(
  //     map(x => {
  //       saleableProduct.productDepartmentStatusUid = x.productDepartmentStatusUid;

  //       return this.evaluateEnablement(saleableProduct);
  //     })
  //   );
  // }

  // public disable(saleableProduct: PositionModel): Observable<PositionModel> {

  //   return this.positionService.disable(saleableProduct.uid).pipe(
  //     map(x => {
  //       saleableProduct.productDepartmentStatusUid = x.productDepartmentStatusUid;

  //       return this.evaluateEnablement(saleableProduct);
  //     })
  //   );
  // }

  public delete(position: PositionModel): Observable<PositionModel> {

    return this.positionService.delete(position.uid).pipe(
      map(x => {
        position.positionStatusUid = x.positionStatusUid;

        return this.evaluateEnablement(position);
      })
    );
  }

  private evaluateEnablement(position: PositionModel): PositionModel {

    let positionStatusUid = position.positionStatusUid.toUpperCase();

    position.canEdit = positionStatusUid != PositionStatusKeys.Deleted;
    position.canDelete = positionStatusUid != PositionStatusKeys.Deleted;

    return position;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEdit = selected.length == 1;
    this._canBulkDelete = selected.length == 1;
  }
}
