import { fromEditableTransactionItemConfiguration } from "downtown-transaction";
import { CartItemInput } from "../models/cart-item-input";
import { EditableCartItem } from "../models/editable-cart";

export function fromEditableCartItem(source: EditableCartItem): CartItemInput {

  return <CartItemInput>{
    uid: source.uid,
    cartUid: source.cartUid,
    productUid: source.productUid.value,
    productVersion: source.productVersion.value,
    name: source.name.value,
    configuration: source.configuration.value ? fromEditableTransactionItemConfiguration(source.configuration.value) : null,
    quantity: source.quantity.value
  };
}
