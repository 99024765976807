import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { EmployeePay } from "../models/employee-pay";
import { OltpServiceSettings } from "../oltp-service-settings";
import { HttpService } from "core";
import { PaginationInput } from "core";
import { Page } from "core";
import { PosModelFactory } from "../pos-model-factory";
import { EmployeeService } from "../services/employee.service";
import { PositionService } from "../services/position.service";

@Injectable()
export class EmployeePayService {

  public static readonly EmployeePayIndexView = <EmployeePayViewOptions>{};
  public static readonly EmployeePayFullView = <EmployeePayViewOptions>{ includeEmployee: true, includePosition: true };

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: EmployeePayViewOptions = EmployeePayService.EmployeePayFullView): Observable<EmployeePay> {

    let view = EmployeePayService.buildView(viewOptions);

    var request = {
      query: 'query { getByUid (uid: \"' + uid + '\") ${view} }'
    }

    return this.httpService.graph<EmployeePay>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeePay', request, 'getByUid').pipe(
      map(x => PosModelFactory.assignEmployeePay(x))
    );
  }

  search(employeeUids: string[], positionUids: string[], payPeriodUids: string[], paymentMethodUids: string[], statusUids: string[], paginationInput: PaginationInput, viewOptions: EmployeePayViewOptions = EmployeePayService.EmployeePayFullView): Observable<Page<EmployeePay>> {

    let view = EmployeePayService.buildView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(employeeUids: ${JSON.stringify(employeeUids)}, positionUids: ${JSON.stringify(positionUids)}, payPeriodUids: ${JSON.stringify(payPeriodUids)}, paymentMethodUids: ${JSON.stringify(paymentMethodUids)}, statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: { pagination: paginationInput }
    };

    return this.httpService.graph<Page<EmployeePay>>(this.oltpServiceSettings.apiUrl, 'api/oltp/employeePay', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = PosModelFactory.assignEmployeePay(edge.node)))
    );
  }

  public static buildView(viewOptions: EmployeePayViewOptions) {

    let view = `uid employeeShiftUid employeeUid positionUid payPeriodUid startDateTimeUtc endDateTimeUtc workUnits workUnitUid rate total paymentMethodUid employeePayStatusUid`;

    if (viewOptions.includeEmployee) {
      view += ` employee ${EmployeeService.buildView(EmployeeService.EmployeeFullView)}`;
    }

    if (viewOptions.includePosition) {
      view += ` position ${PositionService.buildView(PositionService.PositionFullView)}`;
    }

    return '{ ' + view + ' }';
  }
}

export interface EmployeePayViewOptions {

  includeEmployee: boolean;
  includePosition: boolean;
}
