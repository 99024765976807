import {
    ActionBarComponent,
    ClockPickerComponent,
    CoreSettingsComponent,
    DatePickerComponent,
    ExpansionContainerComponent,
    ExpansionPanelComponent,
    ExpansionPanelContentComponent,
    ExpansionPanelHeaderComponent,
    ExpansionPanelTitleComponent,
    FileSelectorComponent,
    PageHeaderComponent,
    PaginatorComponent,
    PosButtonComponent,
    ScaledContainerComponent,
    TimeoutButtonComponent
} from ".";

export const componentTypes = [
    ActionBarComponent,
    ExpansionContainerComponent,
    ExpansionPanelComponent,
    ExpansionPanelHeaderComponent,
    ExpansionPanelTitleComponent,
    ExpansionPanelContentComponent,
    PosButtonComponent,
    CoreSettingsComponent,

    ClockPickerComponent,
    DatePickerComponent,
    FileSelectorComponent,
    PageHeaderComponent,
    PaginatorComponent,
    TimeoutButtonComponent,
    ScaledContainerComponent
];
