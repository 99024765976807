import { Pipe, PipeTransform } from '@angular/core';
import { CacheService } from 'core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TerminalService } from '../services/terminal.service';

@Pipe({
  name: 'terminal'
})
export class TerminalPipe implements PipeTransform {

  constructor(
    private terminalService: TerminalService,
    private cacheService: CacheService
  ) {
  }

  transform(terminalUid: string): Observable<string> {

    if (terminalUid) {
      return this.cacheService.getOrAdd(
        terminalUid,
        () => this.terminalService.getByUid(terminalUid).pipe(shareReplay(1))
      ).pipe(
        map(terminal => {
          return terminal ? terminal.name : null;
        })
      );
    }
    return null;
  }
}
