import { Injectable } from "@angular/core";
import { Drawer } from "../models/drawer";
import { UUID } from "angular2-uuid";
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { OltpServiceSettings } from "../oltp-service-settings";
import { DateRangeFilterInput, HttpService } from "core";
import { PaginationInput } from "core";
import { Page } from "core";
import { EmployeeService } from "../services/employee.service";
import { PosModelFactory } from "../pos-model-factory";
import { DrawerCreditInput } from '../models/drawer-credit-input';
import { DisburseDebitInput } from '../models/disburse-debit-input';
import { TransactionService } from "downtown-transaction";

@Injectable()
export class DrawerService {

  public static readonly DrawerIndexView = <DrawerViewOptions>{};
  public static readonly DrawerFullView = <DrawerViewOptions>{ includeEmployee: true, includeCreditDebit: true };

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(drawerUid: string, viewOptions: DrawerViewOptions = DrawerService.DrawerFullView): Observable<Drawer> {

    let view = DrawerService.buildView(viewOptions);

    var request = {
      query: `query { getByUid(uid: "${drawerUid}") ${view} }`
    };

    return this.httpService.graph<Drawer>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'getByUid').pipe(
      map(x => PosModelFactory.assignDrawer(x))
    );
  }

  getByUids(drawerUids: string[], viewOptions: DrawerViewOptions = DrawerService.DrawerFullView): Observable<Drawer[]> {

    let view = DrawerService.buildView(viewOptions);

    var request = {
      query: `query { getByUids (getByUids: ${JSON.stringify(drawerUids)}) { totalCount edges { node ${view} } } }`
    };

    return this.httpService.graph<Drawer[]>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'getByUids').pipe(
      map(x => x.map(y => PosModelFactory.assignDrawer(y)))
    );
  }

  search(startedDateRangeFilter: DateRangeFilterInput, endedDateRangeFilter: DateRangeFilterInput, terminalUids: string[], statusUids: string[], paginationInput: PaginationInput, viewOptions: DrawerViewOptions = DrawerService.DrawerFullView): Observable<Page<Drawer>> {

    let view = DrawerService.buildView(viewOptions);

    var request = {
      query: `query search($startedDateRangeFilter:DateRangeFilterInput, $endedDateRangeFilter:DateRangeFilterInput, $pagination:PaginationInput) { search(startedDateRangeFilter: $startedDateRangeFilter, endedDateRangeFilter: $endedDateRangeFilter, terminalUids: ${JSON.stringify(terminalUids)}, statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: {
        startedDateRangeFilter: startedDateRangeFilter,
        endedDateRangeFilter: endedDateRangeFilter,
        pagination: paginationInput
      }
    };

    return this.httpService.graph<Page<Drawer>>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = PosModelFactory.assignDrawer(edge.node)))
    );
  }

  getOpen(terminalUid: string, viewOptions: DrawerViewOptions = DrawerService.DrawerFullView): Observable<Drawer> {

    let view = DrawerService.buildView(viewOptions);

    var request = {
      query: `query { getOpen(terminalUid: "${terminalUid}") ${view} }`
    };

    return this.httpService.graph<Drawer>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'getOpen').pipe(
      map(x => PosModelFactory.assignDrawer(x))
    );
  }

  start(terminalUid: string, seedAmount: number, viewOptions: DrawerViewOptions = null, identityToken: string = null): Observable<Drawer> {

    let view = DrawerService.buildView(viewOptions || DrawerService.DrawerFullView);

    let drawerUid = UUID.UUID();

    var request = {
      query: `mutation start { start(drawerUid: "${drawerUid}", terminalUid: "${terminalUid}", seedAmount: ${seedAmount}) ${view} }`
    };

    return this.httpService.graph<Drawer>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'start', identityToken).pipe(
      map(x => PosModelFactory.assignDrawer(x))
    );
  }

  end(drawerUid: string, viewOptions: DrawerViewOptions = null, identityToken: string = null): Observable<Drawer> {

    let view = DrawerService.buildView(viewOptions || DrawerService.DrawerFullView);

    var request = {
      query: `mutation end { end(drawerUid: "${drawerUid}") ${view} }`
    };

    return this.httpService.graph<Drawer>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'end', identityToken).pipe(
      map(x => PosModelFactory.assignDrawer(x))
    );
  }

  delete(drawerUid: string, viewOptions: DrawerViewOptions = null, identityToken: string = null): Observable<Drawer> {

    let view = DrawerService.buildView(viewOptions || DrawerService.DrawerFullView);

    var request = {
      query: `mutation delete { delete(drawerUid: "${drawerUid}") ${view} }`
    };

    return this.httpService.graph<Drawer>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'delete', identityToken).pipe(
      map(x => PosModelFactory.assignDrawer(x))
    );
  }

  getNextTransactionNumber(drawerUid: string, identityToken: string = null): Observable<string> {

    var request = {
      query: `mutation getNextTransactionNumber { getNextTransactionNumber(drawerUid: "${drawerUid}") }`
    };

    return this.httpService.graph<string>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'getNextTransactionNumber', identityToken);
  }

  receiveCredit(drawerCreditInput: DrawerCreditInput, viewOptions: DrawerViewOptions = null, identityToken: string = null): Observable<Drawer> {

    let view = DrawerService.buildView(viewOptions || DrawerService.DrawerFullView);

    var request = {
      query: `mutation receiveCredit($drawerCredit:DrawerCreditInput!) { receiveCredit(drawerCredit: $drawerCredit) ${view} }`,
      variables: {
        drawerCredit: drawerCreditInput
      }
    };

    return this.httpService.graph<Drawer>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'receiveCredit', identityToken).pipe(
      map(x => PosModelFactory.assignDrawer(x)),
      catchError(x => of(null))
    );
  }

  adjustCredit(drawerCreditInput: DrawerCreditInput, viewOptions: DrawerViewOptions = null, identityToken: string = null): Observable<Drawer> {

    let view = DrawerService.buildView(viewOptions || DrawerService.DrawerFullView);

    var request = {
      query: `mutation adjustCredit($drawerCredit:DrawerCreditInput!) { adjustCredit(drawerCredit: $drawerCredit) ${view} }`,
      variables: {
        drawerCredit: drawerCreditInput
      }
    };

    return this.httpService.graph<Drawer>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'adjustCredit', identityToken).pipe(
      map(x => PosModelFactory.assignDrawer(x)),
      catchError(x => of(null))
    );
  }

  cancelCredit(drawerUid: string, paymentUid: string, viewOptions: DrawerViewOptions = DrawerService.DrawerFullView): Observable<Drawer> {

    let view = DrawerService.buildView(viewOptions);

    var request = {
      query: `mutation cancelCredit { cancelCredit(drawerUid: "${drawerUid}", creditUid: "${paymentUid}") ${view} }`
    };

    return this.httpService.graph<Drawer>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'cancelCredit').pipe(
      map(x => PosModelFactory.assignDrawer(x))
    );
  }

  disburseDebit(disburseDebitInput: DisburseDebitInput, viewOptions: DrawerViewOptions = null, identityToken: string = null): Observable<Drawer> {

    let view = DrawerService.buildView(viewOptions || DrawerService.DrawerFullView);

    var request = {
      query: `mutation disburseDebit($drawerDebit:DrawerDebitInput!) { disburseDebit(drawerDebit: $drawerDebit) ${view} }`,
      variables: {
        drawerDebit: disburseDebitInput
      }
    };

    return this.httpService.graph<Drawer>(this.oltpServiceSettings.apiUrl, 'api/oltp/drawer', request, 'receiveCredit', identityToken).pipe(
      map(x => PosModelFactory.assignDrawer(x)),
      catchError(x => of(null))
    );
  }

  public static buildView(viewOptions: DrawerViewOptions) {

    let view = `uid terminalUid terminal { uid name } startDateTimeUtc startAuthUid endDateTimeUtc endAuthUid seedAmount balance saleSequence drawerStatusUid`;

    if (viewOptions.includeEmployee) {
      view += ` startEmployee ${EmployeeService.buildView(EmployeeService.EmployeeIndexView)}`;
      view += ` endEmployee ${EmployeeService.buildView(EmployeeService.EmployeeIndexView)}`;
    }

    if (viewOptions.includeCreditDebit) {
      view += ` credits { creditUid paymentMethodUid amount description referenceUid metadata { key value } transaction ${TransactionService.buildView(TransactionService.TransactionIndexView)} dateTimeUtc }`;
      view += ` debits { debitUid paymentMethodUid amount description referenceUid metadata { key value } transaction ${TransactionService.buildView(TransactionService.TransactionIndexView)} dateTimeUtc }`;
    }

    return '{ ' + view + ' }';
  }
}

export interface DrawerViewOptions {

  includeEmployee: boolean
  includeCreditDebit: boolean
}

