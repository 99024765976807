<div class="start-drawer-container" style="width: 100%; height: 100%; display: flex; flex-direction: column; overflow: hidden;">
  <div style="flex: 3;">
  </div>
  <div style="flex: 1; display: flex;">
    <div style="flex: 2">&nbsp;</div>
    <div class="grid-container" style="flex: 2;">
      <button mat-flat-button color="accent" [disabled]="true">{{ (selectedEmployee | async) ? (selectedEmployee | async).getDisplayableName() : 'Select Employee...' }}</button>
    </div>
    <div class="grid-container" style="flex: 2;">
      <button mat-flat-button color="accent" [disabled]="!(canSelectPosition | async)" (click)="selectPosition()">{{ (selectedPosition | async) ? (selectedPosition | async).name : 'Select Position...' }}</button>
    </div>
    <div class="grid-container" style="flex: 2; ">
      <button mat-flat-button color="accent" [disabled]="!(canClockIn | async)" (click)="selectClockInDateTime()">{{ (clockInDateTime | async) ? ((clockInDateTime | async) | date: 'M-dd-yyyy h:mm a') : 'Clock In...' }}</button>
    </div>
    <div class="grid-container" style="flex: 2;">
      <button mat-flat-button color="accent" [disabled]="!(canClockOut | async)" (click)="selectClockOutDateTime()">{{ (clockOutDateTime | async) ? ((clockOutDateTime | async) | date: 'M-dd-yyyy h:mm a') : 'Clock Out...' }}</button>
    </div>
    <div style="flex: 2">&nbsp;</div>
  </div>
  <div style="flex: 1; display: flex;">
    <div style="flex: 2">&nbsp;</div>
    <div class="grid-container" style="flex: 4;">
    </div>
    <div class="grid-container" style="flex: 4;">
      <span class="grid-text">
        {{ (duration | async) | timeSpan }}
      </span>
    </div>
    <div style="flex: 2">&nbsp;</div>
  </div>
  <div style="flex: 1;">
  </div>
  <div style="flex: 1; display: flex;">
    <div style="flex: 2">&nbsp;</div>
    <div style="flex: 4">&nbsp;</div>
    <div class="grid-container" style="flex: 2;">
      <button mat-flat-button color="accent" [disabled]="!(canSave | async)" (click)="save()">Save</button>
    </div>
    <div class="grid-container" style="flex: 2;">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
    </div>
    <div style="flex: 2">&nbsp;</div>
  </div>
  <div style="flex: 4;">
  </div>
</div>
<!-- <div class="start-drawer-container" style="width: 100%; display: flex; flex-direction: column; overflow: hidden;">
  <div style="flex: 1; position: relative;">
  </div>
  <div style="flex: 1; position: relative;">
  </div>
  <div style="flex: 1; position: relative;">
  </div>
  <div style="flex: 1; position: relative;">
    <div style="width: 100%;">
      <div style="display: flex; flex-direction: row; height: 100%; overflow: hidden;">
        <div style="flex: 2">&nbsp;</div>
        <div style="flex: 2;">
          <app-site-button [display]="(selectedEmployee | async) ? (selectedEmployee | async).getDisplayableName() : 'Enter PIN...'" [style]="{ 'background-color' : '#4d4d00' }"></app-site-button>
        </div>
        <div style="flex: 2;">
          <app-site-button [display]="(selectedPosition | async) ? (selectedPosition | async).name : 'Select Position...'" [isDisabled]="!(canSelectPosition | async)" [style]="{ 'background-color' : '#4d4d00' }" (click)="selectPosition()"></app-site-button>
        </div>
        <div style="flex: 2;">
          <app-site-button [display]="(clockInDateTime | async) ? ((clockInDateTime | async) | date: 'M-dd-yyyy h:mm a') : 'Clock In...'" [isDisabled]="!(canClockIn | async)" [style]="{ 'background-color' : '#4d4d00' }" (click)="selectClockInDateTime()"></app-site-button>
        </div>
        <div style="flex: 2;">
          <app-site-button [display]="(clockOutDateTime | async) ? ((clockOutDateTime | async) | date: 'M-dd-yyyy h:mm a') : 'Clock Out...'" [isDisabled]="!(canClockOut | async)" [style]="{ 'background-color' : '#4d4d00' }" (click)="selectClockOutDateTime()"></app-site-button>
        </div>
        <div style="flex: 2">&nbsp;</div>
      </div>
    </div>
  </div>
  <div style="flex: 1; position: relative;">
    <div style="width: 100%;">
      <div style="display: flex; flex-direction: row; height: 100%; overflow: hidden;">
        <div style="flex: 2">&nbsp;</div>
        <div style="flex: 4;">
        </div>
        <div style="flex: 4;">
          <div class="grid-text-container">
            <div class="grid-text">
              {{ (duration | async) | timeSpan }}
            </div>
          </div>
        </div>
        <div style="flex: 2">&nbsp;</div>
      </div>
    </div>
  </div>
  <div style="flex: 1; position: relative;">
  </div>
  <div style="flex: 1; position: relative;">
    <div style="width: 100%;">
      <div style="display: flex; flex-direction: row; height: 100%; overflow: hidden;">
        <div style="flex: 2">&nbsp;</div>
        <div style="flex: 4">&nbsp;</div>
        <div style="flex: 2;">
          <app-site-button [isDisabled]="!(canSave | async)" [display]="'Save'" [style]="{ 'background-color' : '#4d4d00' }" (click)="save()"></app-site-button>
        </div>
        <div style="flex: 2;">
          <app-site-button [display]="'Cancel'" [style]="{ 'background-color' : 'red' }" (click)="cancel()"></app-site-button>
        </div>
        <div style="flex: 2">&nbsp;</div>
      </div>
    </div>
  </div>
  <div style="flex: 4; position: relative;">
  </div>
</div> -->
