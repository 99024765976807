<div>
  <app-pos-button (click)="buttonPressed.emit('7')" [styles]="{ backgroundColor: '#2e466b' }">7</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('8')" [styles]="{ backgroundColor: '#2e466b' }">8</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('9')" [styles]="{ backgroundColor: '#2e466b' }">9</app-pos-button>
</div>
<div>
  <app-pos-button (click)="buttonPressed.emit('4')" [styles]="{ backgroundColor: '#2e466b' }">4</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('5')" [styles]="{ backgroundColor: '#2e466b' }">5</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('6')" [styles]="{ backgroundColor: '#2e466b' }">6</app-pos-button>
</div>
<div>
  <app-pos-button (click)="buttonPressed.emit('1')" [styles]="{ backgroundColor: '#2e466b' }">1</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('2')" [styles]="{ backgroundColor: '#2e466b' }">2</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('3')" [styles]="{ backgroundColor: '#2e466b' }">3</app-pos-button>
</div>
<div>
  <app-pos-button (click)="buttonPressed.emit('.')" [styles]="{ backgroundColor: '#2e466b' }">.</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('0')" [styles]="{ backgroundColor: '#2e466b' }">0</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('00')" [styles]="{ backgroundColor: '#2e466b' }">00</app-pos-button>
</div>
<div>
  <app-pos-button (click)="buttonPressed.emit('Back')" [styles]="{ backgroundColor: '#2e466b' }">Back</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('Clear')" [styles]="{ backgroundColor: '#2e466b' }">Clear</app-pos-button>
  <app-pos-button (click)="buttonPressed.emit('Enter')" [styles]="{ backgroundColor: '#2e466b' }">Enter</app-pos-button>
</div>