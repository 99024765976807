import { Injectable } from '@angular/core';
import { SystemMessage } from '../models/system-message';
import { concatMap, filter, first, map, Observable, of, Subject, takeUntil, tap, timeout } from 'rxjs';
import { SystemMessageService } from '../services/system-message.service';
import { SystemMessageInput } from '../models/system-message-input';
import { isCaseInsensitiveEqual, isEqualUUID } from '../functions/string';
import { SystemMessageConfig } from '../system-message-config';

/**
 * Provider meant to be an alternative to calling SystemMessageService directly, with additional members for subscribing to data mutation events.
 */
@Injectable()
export class SystemMessageProvider {

  public systemEvents$: Observable<SystemMessage>;

  protected initialize$ = new Subject<void>();
  protected destroyed$ = new Subject<void>();

  private systemEventsSubject$ = new Subject<SystemMessage>();
  private _nodeIdentifier: string;

  constructor(
    protected systemMessageConfig: SystemMessageConfig,
    private systemMessageService: SystemMessageService
  ) {
    this.systemEvents$ = this.systemEventsSubject$.asObservable();
  }

  ngOnInit() {

    // this.nodeIdentifier = this.systemMessageConfig.nodeIdentifier;
  }

  ngOnDestroy() {

    this.initialize$.next();
    this.destroyed$.next();
  }

  public get nodeIdentifier(): string {
    return this._nodeIdentifier;
  }

  protected set nodeIdentifier(nodeIdentifier: string) {

    this._nodeIdentifier = nodeIdentifier;

    this.initialize$.next();

    if (this._nodeIdentifier) {
      this.initialize();
    }
  }

  public publishOne(systemMessage: SystemMessageInput) {

    if (isCaseInsensitiveEqual(systemMessage.targetNodeIdentifier, this.nodeIdentifier)) {
      this.systemEventsSubject$.next(systemMessage);
    } else {
      this.systemMessageService.publish(systemMessage).pipe(first()).subscribe();
    }
  }

  public publishOne$(systemMessage: SystemMessageInput): Observable<SystemMessage> {

    if (isCaseInsensitiveEqual(systemMessage.targetNodeIdentifier, this.nodeIdentifier)) {
      return of(systemMessage).pipe(
        tap(_ => this.systemEventsSubject$.next(systemMessage))
      );
    }

    return this.systemMessageService.publish(systemMessage);
  }

  public publishOneWithResponse$<T>(systemMessage: SystemMessageInput): Observable<T> {

    return this.publishOne$(systemMessage).pipe(
      concatMap(systemMessage => {
        return this.systemEvents$.pipe(
          filter(response => isCaseInsensitiveEqual(response.type, 'publish-result')),
          filter(response => isEqualUUID(systemMessage.uid, response.uid)),
          map(response => JSON.parse(response.payload) as T),
          timeout({ first: 15000 }),
          first()
        );
      }),
      first()
    );
  }

  public publishResponse$(systemMessage: SystemMessage, payload: any) {

    var response = this.buildMessage(systemMessage.uid, 'publish-result', payload, systemMessage.targetNodeIdentifier, systemMessage.sourceNodeIdentifier, null);

    this.publishOne(response);
  }

  public buildMessage(uid: string, type: string, payload: any = null, sourceNodeIdentifier: string = null, targetNodeIdentifier: string = null, targetResourceUid: string = null): SystemMessage {

    sourceNodeIdentifier = sourceNodeIdentifier || this.nodeIdentifier;
    targetNodeIdentifier = targetNodeIdentifier || this.nodeIdentifier;

    return {
      uid: uid,
      type: type,
      sourceNodeIdentifier: sourceNodeIdentifier,
      targetNodeIdentifier: targetNodeIdentifier,
      targetResourceUid: targetResourceUid,
      payload: JSON.stringify(payload)
    };
  }

  private initialize() {

    this.systemMessageService.getStream$().pipe(
      takeUntil(this.initialize$),
      filter(systemMessage => isEqualUUID(systemMessage.targetNodeIdentifier, this.nodeIdentifier))
    ).subscribe(systemMessage => {
      console.log(`Received SystemMessage: (${systemMessage.type})`);
      console.log(systemMessage)
      this.systemEventsSubject$.next(systemMessage);
    });
  }
}
