export const environment = {
  production: true,

  ownerUid: '32dbf0a9-943b-4766-9ffb-288861ffaa0d',
  terminalUid: 'e0f7546f-cedb-45f0-803c-fea98cf3421b',

  core: {
    apiUrl: 'https://api.uptownbar.com/',
    socketUrl: 'wss://api.uptownbar.com/',
    availabilityHeartbeat: <number>null,
  },

  oltp: {
    apiUrl: 'https://api.uptownbar.com/',
    socketUrl: 'wss://api.uptownbar.com/',
    ownerUid: '32dbf0a9-943b-4766-9ffb-288861ffaa0d',
    availabilityHeartbeat: <number>null,
    showDevFeatures: false
  },

  systemMessage: {
    nodeIdentifier: ' BE'
  },

  product: {
    apiUrl: 'https://api.uptownbar.com/',
    socketUrl: 'wss://api.uptownbar.com/',
    availabilityHeartbeat: <number>null,
  },

  transaction: {
    apiUrl: 'https://api.uptownbar.com/',
    socketUrl: 'wss://api.uptownbar.com/',
    availabilityHeartbeat: <number>null,
  },

  blob: {
    apiUrl: 'https://blob.uptownbar.com',
    apiOwnerUid: 'a44ff9a1-3247-46fb-a42d-dd3fea588af8',
  },

  store: {
    apiUrl: 'https://storeapi.uptownbar.com',
    hostFrameUrl: 'https://store.uptownbar.com/host',
    ownerUid: '63F77D3E-7F24-41A8-9DC4-2D730BEA1BC0',
    storeFrontUid: '58389FD7-502E-4482-AF36-60D2988432F8',
  },

  identityServer: {
    url: 'https://identity.uptownbar.com',
    redirectUrl: 'https://uptownbar.com',
    clientId: 'uptownbar.site',
    responseType: 'id_token token',
    scope: 'openid email role',
    postLogoutRedirectUrl: 'https://uptownbar.com',
    startChecksession: true,
    silentRenew: false,
    startupRoute: '/',
    forbiddenRoute: '/forbidden',
    unauthorizedRoute: '/unauthorized',
    logConsoleWarningActive: true,
    logConsoleDebugActive: false,
    maxIdTokenIatOffsetAllowedInSeconds: 120,
    availabilityHeartbeat: 1,
  }
};
