import {
    CeilingPipe,
    DateRangePipe,
    IdentityUserResolverPipe,
    LockResolverPipe,
    PaymentMethodPipe,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    SplitTitleCasePipe,
    TimeSpanPipe
} from ".";

export const pipeTypes = [
    SplitTitleCasePipe,
    CeilingPipe,
    DateRangePipe,
    IdentityUserResolverPipe,
    LockResolverPipe,
    PaymentMethodPipe,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    TimeSpanPipe
];
