<div *ngIf="!context.isReadOnly || (context.isReadOnly && itemInclusionGroup.options?.length > 0)">
  {{ getInclusionGroupDisplay(itemInclusionGroup.inclusionGroupUid) }}

  <div *ngIf="itemInclusionGroup.options == null || itemInclusionGroup.options.length == 0" style="margin-left: 10%;">
    <!-- Select... -->
    <div style="color: green;">{{ context.getProductInclusionGroupSelectMessaging(productInclusionGroup) }}</div>
  </div>

  <div *ngFor="let itemInclusionGroupOption of itemInclusionGroup.options" style="margin-left: 10%;">
    <transaction-item-configurator-detail-inclusion-group-option [context]="context" [productInclusionGroupOption]="productInclusionGroup.getOption(itemInclusionGroupOption.optionUid)" [itemInclusionGroupOption]="itemInclusionGroupOption"></transaction-item-configurator-detail-inclusion-group-option>
  </div>
  
</div>