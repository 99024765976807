<div style="border-radius: 4px;" [style.background-color]="(editableTransaction.selectedLineItem | async) == charge ? 'gray' : 'transparent'" (click)="select.emit(charge)">

  <div style="display: flex;">
    <div style="width: 53%;">{{ charge.description }}</div>
    <div style="width: 10%; text-align: right;">{{ charge.quantity | async | number:'1.0-2' }}</div>
    <div style="width: 2%; text-align: center;">&#64;</div>
    <div style="width: 15%; text-align: right;">{{ charge.eachAmountText | async | currency:'USD':'symbol':'1.2-2' }}</div>
    <div style="width: 20%; text-align: right;">{{ charge.total | async | currency:'USD':'symbol':'1.2-2' }}</div>
  </div>

  <div *ngIf="charge.validationMessage" style="text-align: right;" class="validation-message" [@slideIn] [@slideOut]>
    <span>{{ charge.validationMessage }}</span>
  </div>
</div>

<!-- Transaction Charge Line Items Context Menu -->
<div *ngIf="(editableTransaction.selectedLineItem | async) == charge && (charge.canVoid || charge.canCancel)" [@slideIn] [@slideOut] style="display: flex; overflow: hidden;">
  <div style="width: 20%; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
  </div>
  <div style="background-color: gray; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 4px;">
    <button *ngIf="charge.canComp" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="comp.emit(charge)">Comp</button>
    <button *ngIf="charge.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="void.emit(charge)">Void</button>
    <button *ngIf="charge.canCancel" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="cancel.emit(charge)">Cancel</button>
  </div>
  <div style="flex: 1 1 auto; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
  </div>
</div>

<ng-container *ngFor="let chargeAdjustment of charge.adjustments">
  <transaction-transaction-editor-charge-adjustment [editableTransaction]="editableTransaction" [charge]="charge" [chargeAdjustment]="chargeAdjustment" (select)="select.emit($event)" (void)="void.emit($event)" (cancel)="cancel.emit($event)"></transaction-transaction-editor-charge-adjustment>
</ng-container>
