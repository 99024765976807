<div style="display: flex; flex-direction: column; height: 100%;">

  <app-page-header>

    <ng-container left>
      <button mat-flat-button style="width: 4.1666vw;" (click)="navigateHome()">
        <mat-icon style="font-weight: bold;">home</mat-icon>
      </button>
      <button mat-flat-button style="width: 4.1666vw; margin-left: 4px;" (click)="navigateBack()">
        <mat-icon style="font-weight: bold;">arrow_back</mat-icon>
      </button>
    </ng-container>

    <ng-container middle>{{title}}</ng-container>

    <app-paginator right [dataSource]="dataSource" [paginator]="paginator"></app-paginator>

  </app-page-header>

  <app-action-bar [actionBarConfiguration]="actionBarConfiguration">
    <div style="width: 100%; text-align: center;">
      Total Transactions Value: {{ dataSource.totalValue$ | async | currency:'USD':'symbol':'1.2-2' }}
    </div>
  </app-action-bar>

  <div style="flex: 1; overflow-y: auto;">
    <!-- <app-transactions-table [dataSource]="dataSource" [columns]="columns" (view)="view($event)" (edit)="edit($event)" (reopen)="reopen($event)"></app-transactions-table> -->
    <transaction-table [dataSource]="dataSource" [columns]="columns"></transaction-table>
  </div>

</div>
