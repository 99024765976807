import { Component, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, map, concatMap, takeUntil } from 'rxjs/operators';
import { ActionBarConfiguration, ActionConfiguration, FilterConfiguration, FilterOptionConfiguration, getParamsFromFilters, getParamsFromPaginator, updateFiltersFromParams, updatePaginatorFromParams } from 'core';
import { Paginator } from 'core';
import { ConfirmModalProvider } from 'core';
import { SpinnerModalComponent } from 'core';
import { SpinnerModalProvider } from 'core';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { PayPeriodDataSource, PayPeriodModel } from 'pos-core';
import { PayPeriodStatusKeys } from 'pos-core';
import { PayPeriodService } from 'pos-core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-back-office-pay-periods',
  templateUrl: './back-office-pay-periods.component.html',
  styleUrls: ['./back-office-pay-periods.component.scss']
})
export class BackOfficePayPeriodsComponent implements OnInit {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: PayPeriodDataSource;
  public paginator: Paginator;

  public title: string;
  public columns: Array<string>;

  public canSyncToStore = true;

  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private payPeriodService: PayPeriodService
  ) {
    this.navigationProvider.setWaypoint('Pay Periods');
    this.title = 'Pay Periods';

    this.dataSource = new PayPeriodDataSource(this.payPeriodService, false);
    this.paginator = new Paginator(this.dataSource, 0, 25, 'startDateTimeUtc', 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('New', this.new.bind(this)),
        new ActionConfiguration('Edit', () => this.edit(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Shifts', () => this.shifts(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canViewShifts)),
        new ActionConfiguration('Start Review', () => this.review(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canReview)),
        new ActionConfiguration('Commit', () => this.commit(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canCommit)),
        new ActionConfiguration('Rollback', () => this.rollback(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canRollback)),
        new ActionConfiguration('Delete', () => this.delete(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDelete)),
        new ActionConfiguration('Report', () => this.report(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canReport)),
      ],
      [
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('Active', [PayPeriodStatusKeys.Open, PayPeriodStatusKeys.InReview]),
            new FilterOptionConfiguration('All', [PayPeriodStatusKeys.Open, PayPeriodStatusKeys.InReview, PayPeriodStatusKeys.Committed])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }
        )
      ]
    );

    // Build initial columns
    this.columns = [ /*'select',*/ 'startDate', 'endDate', 'totalSales', 'totalPay', 'status'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    const statusFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => x.title == 'Status');
    const paginator = this.paginator;

    this.activatedRoute.queryParams.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(params => {
      updateFiltersFromParams([statusFilter], params);
      updatePaginatorFromParams(paginator, params);
    });

    combineLatest([
      statusFilter.selected,
      paginator.pageRequest$
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([statusFilterOption, paginationInput]) => {
        let statusFilterUids = statusFilterOption.value;

        const params = Object.assign(getParamsFromFilters([statusFilter]), getParamsFromPaginator(paginator));
        this.navigationProvider.navigate([], { queryParams: params, queryParamsHandling: 'merge' }).then(_ => this.navigationProvider.updateLastWaypoint());

        this.dataSource.loadData(statusFilterUids, paginationInput, PayPeriodService.PayPeriodIndexView);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }

  public new() {

    this.navigationProvider.navigate(['/backoffice/payperiod']);
  }

  public edit(payPeriod: PayPeriodModel) {

    this.navigationProvider.navigate(['/backoffice/payperiod', payPeriod.uid]);
  }

  public shifts(payPeriod: PayPeriodModel) {

    this.navigationProvider.navigate(['/backoffice/payperiod', payPeriod.uid, 'shifts']);
  }

  public report(payPeriod: PayPeriodModel) {

    this.navigationProvider.navigate(['/backoffice/payperiod', payPeriod.uid, 'report']);
  }

  public review(payPeriod: PayPeriodModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Review Pay Period', 'Are you certain you want to begin review of this pay period?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Setting pay period in review...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.review(payPeriod)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public commit(payPeriod: PayPeriodModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Review Pay Period', 'Are you certain you want to commit this pay period?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Comitting pay period...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.commit(payPeriod)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  rollback(payPeriod: PayPeriodModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Rollback Period', 'Are you certain you want to roll back this pay period?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Rolling back pay period...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.rollback(payPeriod)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }

  public delete(payPeriod: PayPeriodModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Delete Pay Period', 'Are you certain you want to delete this pay period?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Deleting...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.delete(payPeriod)),
          catchError(() => of(null))
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }
}
