import { Metadata, TextEntryStyleEnum } from "core";

export interface DeviceSettings {

    textEntryStyle: TextEntryStyleEnum;
    settings: DeviceSetting[];
}

export interface DeviceSetting {
    uid: string;
    enabled: boolean;
    name: string;
    deviceType: DeviceTypeEnum;
    platform: string;
    metadata: Metadata[];
}

export enum DeviceTypeEnum {

    ReceiptPrinter = "ReceiptPrinter",
    CashDrawer = "CashDrawer",
    Msr = "Msr"
}