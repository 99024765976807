import { Injectable } from '@angular/core';
import { AuthIdentityProvider } from 'core';
import { Member } from '../models/member';
import { MemberService } from '../services/member.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AuthMemberProvider {

  public authMember$: Observable<Member>;
  private authMemberSubject = new BehaviorSubject<Member>(null);

  constructor(
    authIdentityProvider: AuthIdentityProvider,
    memberService: MemberService
  ) {
    this.authMember$ = this.authMemberSubject.asObservable();

    authIdentityProvider.userIdentity$.subscribe(authIdentity => {
      if (authIdentity) {
        memberService.getByIdentityId(authIdentity.id).subscribe(member => {
          this.authMemberSubject.next(member);
        });
      } else {
        this.authMemberSubject.next(null);
      }
    });
  }
}
