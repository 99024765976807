import { Pipe, PipeTransform } from '@angular/core';
import { TimeSpan } from '../models/time-span';

@Pipe({
  name: 'timeSpan'
})
export class TimeSpanPipe implements PipeTransform {

  constructor(
  ) {
  }

  transform(value: TimeSpan, option: string = null): string {

    if (value) {
      option = option || 'long';

      if (value) {
        switch (option) {
          case 'long':
            return `${value.hours.toFixed(0)} hour${value.hours == 1 ? '' : 's'}, ${value.minutes} minutes`;
          case 'short':
            return value.toNumber().toFixed(2);
        }
      }
    }

    return '-';
  }
}
