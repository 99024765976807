<div style="border-radius: 4px; color: red;" [style.background-color]="(editableTransaction.selectedLineItem | async) == adjustment ? 'gray' : 'transparent'" (click)="select.emit(adjustment)">
  <div style="display: flex;">
    <span style="width: 80%; text-align: right;">{{ adjustment.description | async }} </span>
    <span style="width: 20%; text-align: right;">{{ -(adjustment.total | async) | currency:'USD':'symbol':'1.2-2' }}</span>
  </div>

  <div *ngIf="adjustment.validationMessage" style="text-align: right;" class="validation-message" [@slideIn] [@slideOut]>
    <span>{{ adjustment.validationMessage }}</span>
  </div>
</div>


<!-- Transaction Adjustment Line Items Context Menu -->
<div *ngIf="(editableTransaction.selectedLineItem | async) == adjustment && (adjustment.canVoid || adjustment.canCancel)" [@slideIn] [@slideOut] style="display: flex; overflow: hidden;">
  <div style="width: 20%; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
  </div>
  <div style="background-color: gray; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 4px;">
    <button *ngIf="adjustment.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="void.emit(adjustment)">Remove</button>
    <button *ngIf="adjustment.canCancel" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="cancel.emit(adjustment)">Cancel</button>
  </div>
  <div style="flex: 1 1 auto; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
  </div>
</div>
