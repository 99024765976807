import { Component, OnInit } from '@angular/core';
import { NavigationProvider } from 'core';
import { PosRuntimeProvider } from 'pos-core';

@Component({
  selector: 'app-site-public-darts',
  templateUrl: './site-public-darts.component.html',
  styleUrls: ['./site-public-darts.component.scss']
})
export class SitePublicDartsComponent implements OnInit {

  constructor(
    private navigationProvider: NavigationProvider,
    public runtimeProvider: PosRuntimeProvider
  ) {
  }

  ngOnInit() {
    this.navigationProvider.setWaypoint('Darts');
  }
}


