<div style="height: 100%; display: flex;">

  <div style="flex: 1;"></div>

  <mat-grid-list cols="2" rowHeight="fit" style="flex: 5; height: 100%;">
    <mat-grid-tile colspan="2" style="background-color: transparent;">&nbsp;</mat-grid-tile>
    <ng-container *ngFor="let hour of hours">
      <mat-grid-tile>
        <button [class.selected]="hour.isSelected" (click)="hourClicked(hour)">
          {{ hour.measure }}
        </button>
      </mat-grid-tile>
    </ng-container>
    <mat-grid-tile colspan="2" style="background-color: transparent;">&nbsp;</mat-grid-tile>
  </mat-grid-list>

  <div style="flex: 1;"></div>

  <mat-grid-list cols="2" rowHeight="fit" style="flex: 5;">
    <mat-grid-tile colspan="2" style="background-color: transparent;">&nbsp;</mat-grid-tile>
    <mat-grid-tile colspan="2" style="background-color: transparent;">&nbsp;</mat-grid-tile>
    <mat-grid-tile>
      <button [class.selected]="minutes[0].isSelected" (click)="minuteClicked(minutes[0])">00</button>
    </mat-grid-tile>
    <mat-grid-tile rowspan="2">
      <button [class.selected]="ampms[0].isSelected" (click)="ampmClicked(ampms[0])">AM</button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button [class.selected]="minutes[1].isSelected" (click)="minuteClicked(minutes[1])">15</button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button [class.selected]="minutes[2].isSelected" (click)="minuteClicked(minutes[2])">30</button>
    </mat-grid-tile>
    <mat-grid-tile rowspan="2">
      <button [class.selected]="ampms[1].isSelected" (click)="ampmClicked(ampms[1])">PM</button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button [class.selected]="minutes[3].isSelected" (click)="minuteClicked(minutes[3])">45</button>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" style="background-color: transparent;">&nbsp;</mat-grid-tile>
    <mat-grid-tile colspan="2" style="background-color: transparent;">&nbsp;</mat-grid-tile>
  </mat-grid-list>

  <div style="flex: 1;"></div>

</div>
