import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerModalComponent } from 'core';
import { NavigationProvider } from 'core';
import { OltpServiceSettings } from 'pos-core';
import { TerminalProvider } from 'downmain-terminal';

@Component({
  selector: 'app-site-settings',
  templateUrl: './site-settings.component.html',
  styleUrls: ['./site-settings.component.scss']
})
export class SiteSettingsComponent {

  public title: string;
  public ownerUids: string[];

  private destroyed$ = new Subject();

  constructor(
    private navigationProvider: NavigationProvider,
    private terminalProvider: TerminalProvider,
    private oltpServiceSettings: OltpServiceSettings
  ) {
    this.navigationProvider.setWaypoint('Settings');
    this.title = 'Settings';

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
  }

  ngOnInit() {

    this.terminalProvider.active$.subscribe(terminal => {
      this.ownerUids = terminal ? [this.oltpServiceSettings.ownerUid, terminal.uid] : null;
    })
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }
}
