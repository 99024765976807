export class Category {

  uid: string;
  ownerUid: string;
  name: string;
  description: string;
  departmentUid: string;
  selectableByDefault: boolean;
  categoryStatusUid: string;
}
