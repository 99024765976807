import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthConfiguration, OpenIDImplicitFlowConfiguration } from "auth";
import { OltpServiceSettings } from "pos-core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class IdentityServerConnectionProvider {

  public connected$: Observable<boolean>;
  private connectedSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private oltpServiceSettings: OltpServiceSettings, // Using this heartbeat setting for now until auth settings is less volatile
    private authConfiguration: AuthConfiguration
  ) {
    this.connected$ = this.connectedSubject.asObservable();

    this.getConfigurationSettings(10).subscribe(validSettings => {
      if (validSettings) {
        this.pingIdentityServer();

        if (this.oltpServiceSettings.availabilityHeartbeat) {
          setInterval(() => {
            this.pingIdentityServer();
          }, this.oltpServiceSettings.availabilityHeartbeat * 1000);
        }
      }
    });
  }

  private getConfigurationSettings(maxTries: number): Observable<boolean> {

    return new Observable(subscriber => {
      let validSettings = this.authConfiguration.stsServer ? true : false;
      if (validSettings) {
        subscriber.next(validSettings);
      } else {
        if(maxTries > 0) {
          setTimeout(() => {
            this.getConfigurationSettings(maxTries - 1).subscribe(validSettings => {
              subscriber.next(validSettings);
            });
          }, 1000);
        } else {
          subscriber.next(validSettings);
        }
      }
    });
  }

  private pingIdentityServer() {

    this.http.get<OpenIDImplicitFlowConfiguration>(this.authConfiguration.stsServer + '/.well-known/openid-configuration').pipe(
      map(configuration => configuration ? true : false),
      catchError(() => of(false))
    ).subscribe(isAlive => {
      if (this.connectedSubject.value != isAlive) {
        this.connectedSubject.next(isAlive);
      }
    });
  }
}
