<mat-toolbar color="accent" style="display: flex; align-items: center; padding-left: 16px; padding-right: 16px;">

  <button mat-icon-button class="compact" [matMenuTriggerFor]="smallMenu" [matMenuTriggerData]="{ actions: actionBarConfiguration.actions }">
    <mat-icon>more_vert</mat-icon>
  </button>

  <div class="full">

    <button *ngFor="let action of actionBarConfiguration.actions" mat-flat-button color="primary" class="action" (click)="execute(action)" [disabled]="!(action.enabled == null || action.enabled())">
      <!-- <mat-icon>create</mat-icon> -->
      <span style="font-weight: bold;">{{ action.display }}</span>
    </button>

  </div>

  <div style="flex: 1;">&nbsp;
    <ng-content></ng-content>
  </div>

  <ng-container *ngFor="let filter of actionBarConfiguration.filters">
    <button *ngIf="filter.options?.length > 0" mat-flat-button color="accent" class="filter" [matMenuTriggerFor]="filterMenu" [matMenuTriggerData]="{ filter: filter }" style="height: 100%;">
      <div class="mat-caption">{{ filter.title }}</div>
      <div *ngIf="filter.selected" style="font-weight: bold;">{{ (filter.selected | async)?.display }}</div>
    </button>
  </ng-container>

</mat-toolbar>

<mat-menu #smallMenu="matMenu" xPosition="before">
  <ng-template matMenuContent let-actions="actions">
    <button *ngFor="let action of actions" mat-menu-item (click)="execute(action)">{{ action.display }}</button>
  </ng-template>
</mat-menu>

<mat-menu #filterMenu="matMenu" xPosition="before">
  <ng-template matMenuContent let-filter="filter">
    <button *ngFor="let option of filter.options" mat-menu-item (click)="filterSelect(filter, option)">{{ option.display }}</button>
  </ng-template>
</mat-menu>