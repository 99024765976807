<table class="themed" color="primary" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="dataSource.selection.isMultipleSelection()" (change)="$event ? dataSource.masterToggle() : null" [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row) : null" [checked]="dataSource.selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef color="accent">Name</th>
    <td mat-cell *matCellDef="let product" style="white-space: nowrap; text-overflow: ellipsis;">{{ product.product.name }}</td>
  </ng-container>

  <!--
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef color="accent">Description</th>
    <td mat-cell *matCellDef="let product" style="white-space: nowrap; text-overflow: ellipsis;">{{ product.product.description }}</td>
  </ng-container>-->

  <ng-container matColumnDef="imageUrl">
    <th mat-header-cell *matHeaderCellDef color="accent">Image</th>
    <td mat-cell *matCellDef="let product">
      <img *ngIf="product.imageUrl" src="{{ product.imageUrl }}" />
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="displayOrder">
    <th mat-header-cell *matHeaderCellDef>Display Order</th>
    <td mat-cell *matCellDef="let product">{{ product.product.displayOrder }}</td>
  </ng-container>
-->
  <ng-container matColumnDef="departmentUid">
    <th mat-header-cell *matHeaderCellDef>Department</th>
    <td mat-cell *matCellDef="let product">{{ product.product.departmentUid | department | async }}</td>
  </ng-container>

  <ng-container matColumnDef="categoryUid">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let product">{{ product.product.categoryUid | category | async }}</td>
  </ng-container>

  <ng-container matColumnDef="version">
    <th mat-header-cell *matHeaderCellDef>Version</th>
    <td mat-cell *matCellDef="let product">{{ product.product.version }}</td>
  </ng-container>

  <ng-container matColumnDef="productStatusUid">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let product">{{ product.product.productStatusUid | productStatus }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>

</table>
