import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from 'core';
import { DamExplorerComponent } from './components/dam-explorer/dam-explorer.component';

const componentTypes = [
  DamExplorerComponent
];

// const pipeTypes = [
// ];

// const providerTypes = [
// ];

// const serviceTypes = [
// ];

@NgModule({
  declarations: [
    ...componentTypes,
    // ...pipeTypes,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,

    CoreModule
  ],
  exports: [
    ...componentTypes,
    // ...pipeTypes,
  ],
  providers: [
    // ...providerTypes,
    // ...serviceTypes,
  ]
})
export class DowntownDamModule { }
