import { Component, Input, EventEmitter, Output } from '@angular/core';
import { EditableTransaction, EditableTransactionGuest, EditableTransactionItem, EditableTransactionItemAdjustment, EditableTransactionPayment } from '../../../models/editable-transaction/editable-transaction';

@Component({
  selector: 'transaction-transaction-editor-guest',
  templateUrl: './transaction-editor-guest.component.html',
  styleUrls: ['./transaction-editor-guest.component.scss'],
})
export class TransactionEditorGuestComponent {

  @Input() editableTransaction: EditableTransaction
  @Input() transactionGuest: EditableTransactionGuest;
  @Output() select = new EventEmitter<EditableTransactionGuest | EditableTransactionItem | EditableTransactionItemAdjustment | EditableTransactionPayment>();
  @Output() edit = new EventEmitter<EditableTransactionGuest | EditableTransactionItem>();
  @Output() void = new EventEmitter<EditableTransactionItem | EditableTransactionItemAdjustment | EditableTransactionPayment>();
  @Output() cancel = new EventEmitter<EditableTransactionGuest | EditableTransactionItem | EditableTransactionItemAdjustment>();
  @Output() print = new EventEmitter<EditableTransactionGuest | EditableTransactionItem>();
  @Output() guest = new EventEmitter<EditableTransactionItem>();

  public getGuestItems() {

    return this.editableTransaction.lineItems.filter(x => x.guestUid.value == this.transactionGuest.uid);
  }
}
