import {
    ItemConfigurationDetailComponent,
    ItemConfigurationDetailPortionComponent,
    ItemConfigurationDetailInclusionGroupComponent,
    ItemConfigurationDetailAddOnComponent,
    ItemConfigurationEditorComponent,
    ItemConfigurationSummaryComponent,

    ItemConfiguratorComponent,
    ItemConfiguratorDetailComponent,
    ItemConfiguratorDetailAddOnComponent,
    ItemConfiguratorDetailInclusionGroupComponent,
    ItemConfiguratorDetailInclusionGroupOptionComponent,
    ItemConfiguratorDetailPortionComponent,
    ItemConfiguratorDetailPreparationComponent,
    ItemConfiguratorDetailPreparationOptionComponent,
    ItemConfiguratorDetailVariationComponent,
    ItemConfiguratorDetailVariationOptionComponent,
    ItemConfiguratorModsComponent,
    ItemConfiguratorModsPreparationComponent,
    ItemConfiguratorModsVariationComponent,
    ItemConfiguratorModsNotesComponent,

    LogisticSettingComponent,
    TransactionEditorComponent,
    TransactionEditorGuestComponent,
    TransactionEditorTransactionItemComponent,
    TransactionEditorItemAdjustmentComponent,
    TransactionEditorChargeComponent,
    TransactionEditorChargeAdjustmentComponent,
    TransactionEditorAdjustmentComponent,
    TransactionEditorPaymentComponent,
    TransactionSettingComponent,
    TransactionsTableComponent
} from ".";

export const componentTypes = [
    ItemConfigurationDetailComponent,
    ItemConfigurationDetailPortionComponent,
    ItemConfigurationDetailInclusionGroupComponent,
    ItemConfigurationDetailAddOnComponent,
    ItemConfigurationEditorComponent,
    ItemConfigurationSummaryComponent,

    ItemConfiguratorComponent,
    ItemConfiguratorDetailComponent,
    ItemConfiguratorDetailAddOnComponent,
    ItemConfiguratorDetailInclusionGroupComponent,
    ItemConfiguratorDetailInclusionGroupOptionComponent,
    ItemConfiguratorDetailPortionComponent,
    ItemConfiguratorDetailPreparationComponent,
    ItemConfiguratorDetailPreparationOptionComponent,
    ItemConfiguratorDetailVariationComponent,
    ItemConfiguratorDetailVariationOptionComponent,
    ItemConfiguratorModsComponent,
    ItemConfiguratorModsPreparationComponent,
    ItemConfiguratorModsVariationComponent,
    ItemConfiguratorModsNotesComponent,

    LogisticSettingComponent,
    TransactionEditorComponent,
    TransactionEditorGuestComponent,
    TransactionEditorTransactionItemComponent,
    TransactionEditorItemAdjustmentComponent,
    TransactionEditorChargeComponent,
    TransactionEditorChargeAdjustmentComponent,
    TransactionEditorAdjustmentComponent,
    TransactionEditorPaymentComponent,
    TransactionSettingComponent,
    TransactionsTableComponent,
];
