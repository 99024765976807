<div style="display: flex; color: red;" (click)="select.emit(chargeAdjustment)">
  <div style="width: 10%;"></div>
  <div style="width: 90%; border-radius: 4px;" [style.background-color]="(editableTransaction.selectedLineItem | async) == chargeAdjustment ? 'gray' : 'transparent'">
    <div style="display: flex; width: 100%;">
      <div style="width: 47.7%;">{{ chargeAdjustment.description }}</div>
      <div style="width: 11%; text-align: right;">{{ chargeAdjustment.quantity | async | number:'1.0-2' }}</div>
      <div style="width: 2.2%; text-align: center;">&#64;</div>
      <div style="width: 16.5%; text-align: right;">{{ chargeAdjustment.eachAmountText | async | currency:'USD':'symbol':'1.2-2' }}</div>
      <div style="width: 22%; text-align: right;">-{{ chargeAdjustment.total | async | currency:'USD':'symbol':'1.2-2' }}</div>
    </div>

    <div *ngIf="chargeAdjustment.validationMessage" class="validation-message" [@slideIn] [@slideOut]>
      <span>{{ chargeAdjustment.validationMessage }}</span>
    </div>
  </div>
</div>

<div *ngIf="(editableTransaction.selectedLineItem | async) == chargeAdjustment && (chargeAdjustment.canVoid || chargeAdjustment.canCancel)" [@slideIn] [@slideOut] style="display: flex; overflow: hidden;">
  <div style="width: 20%; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
  </div>
  <div style="background-color: gray; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 4px;">
    <button *ngIf="chargeAdjustment.canVoid" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="void.emit(chargeAdjustment)">Remove</button>
    <button *ngIf="chargeAdjustment.canCancel" mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="cancel.emit(chargeAdjustment)">Cancel</button>
  </div>
  <div style="flex: 1 1 auto; background-color: gray;">
    <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
  </div>
</div>
