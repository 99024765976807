import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { DateTimeModalComponent } from './date-time-modal.component';

@Injectable()
export class DateTimeModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: DateTimeModalData): MatDialogRef<DateTimeModalComponent> {

    return this.dialog.open(DateTimeModalComponent, {
      width: '100%',
      height: '100%',
      minWidth: '100%',
      minHeight: '100%',
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface DateTimeModalData {
  initialDate: Date;
  minimumDateTime: Date;
  maximumDateTime: Date;
  selectionMode: string;
}

