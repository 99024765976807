<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="dateTimeUtc">
    <th mat-header-cell *matHeaderCellDef color="accent">Date/Time</th>
    <td mat-cell *matCellDef="let exchange">{{ exchange.exchange.dateTimeUtc | date: 'M-dd-yyyy h:mm a' }}</td>
  </ng-container>

  <ng-container matColumnDef="referenceUid">
    <th mat-header-cell *matHeaderCellDef>Reference Uid</th>
    <td mat-cell *matCellDef="let exchange">{{ exchange.exchange.referenceUid }}</td>
  </ng-container>

  <ng-container matColumnDef="transaction.number">
    <th mat-header-cell *matHeaderCellDef>Transaction</th>
    <td mat-cell *matCellDef="let exchange">
      <div *ngIf="exchange.exchange?.transaction">
        <span style="text-decoration:underline;" (click)="viewTransaction(exchange.exchange.transaction.uid)">{{ exchange.exchange.transaction.number }}</span>&nbsp;
        <button mat-button style="border: 1px solid gray;">View</button>&nbsp;
        <button mat-button style="border: 1px solid gray;">Edit</button>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let exchange">{{ exchange.exchange.description }}</td>
  </ng-container>

  <ng-container matColumnDef="credit">
    <th mat-header-cell *matHeaderCellDef>Credit</th>
    <td mat-cell *matCellDef="let exchange"><span *ngIf="exchange.creditAmount != null">{{ exchange.creditAmount | currency:'USD':'symbol':'1.2-2' }}&nbsp;{{ exchange.exchange.paymentMethodUid | paymentMethod | async }}</span></td>
  </ng-container>

  <ng-container matColumnDef="debit">
    <th mat-header-cell *matHeaderCellDef>Debit</th>
    <td mat-cell *matCellDef="let exchange"><span *ngIf="exchange.debitAmount != null">{{ exchange.debitAmount | currency:'USD':'symbol':'1.2-2' }}&nbsp;{{ exchange.exchange.paymentMethodUid | paymentMethod | async }}</span></td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let exchange">
      <button mat-stroked-button color="accent" style="margin-right: 5px;" click-stop-propagation *ngIf="exchange.description === 'Tip Out'" (click)="updateTip(exchange)">Update Tip</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>
</table>
