import { Directive, HostListener } from "@angular/core";

// https://stackoverflow.com/questions/35274028/stop-mouse-event-propagation

@Directive({
  selector: "[click-stop-propagation]"
})
export class ClickStopPropagation {

  @HostListener("click", ["$event"])
  public onClick(event: any): void {

    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener("mousedown", ["$event"])
  public onMousedown(event: any): void {

    event.stopPropagation();
    event.preventDefault();
  }
}
