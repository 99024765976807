import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { finalize, map } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthHeaderProvider } from "../providers/auth-header.provider";
import { AuthIdentityProvider } from "../providers/auth-identity.provider";
import { GraphApiError } from "../models";
import { getGraphApiErrorMessage } from "../functions";

export interface IHttpOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable()
export class HttpService {

  public errorMessage$: Observable<string>;

  protected errorMessageSubject = new BehaviorSubject<string>(null);

  constructor(
    private http: HttpClient,
    private authHeaderProvider: AuthHeaderProvider,
    private authIdentityProvider: AuthIdentityProvider
  ) {
    this.errorMessage$ = this.errorMessageSubject.asObservable();
  }

  public graph<T>(url: string, endpoint: string, body: any, dataMember: string, identityToken: string = null): Observable<T> {

    identityToken = identityToken || (this.authIdentityProvider ? this.authIdentityProvider.getIdentityToken() : null);
    const options = this.authIdentityProvider != null && this.authIdentityProvider != null ? this.authHeaderProvider.apply({}, identityToken) : {};

    return this.http.post<GraphApiResult>(url + endpoint, body, options).pipe(
      map((value: GraphApiResult) => this.projectGraphResult<T>(value, dataMember))
    );
  }

  private projectGraphResult<T>(value: GraphApiResult, dataMember: string): T {

    if (value.errors) {
      var message = getGraphApiErrorMessage(value);

      this.errorMessageSubject.next(message);

      throw message;
    }

    return <T>value.data[dataMember];
  }

  public post(url: string, endpoint: string, body: any, identityToken: string = null): Observable<any> {

    identityToken = identityToken || (this.authIdentityProvider ? this.authIdentityProvider.getIdentityToken() : null);
    const options = this.authIdentityProvider != null && this.authIdentityProvider != null ? this.authHeaderProvider.apply({}, identityToken) : {};

    return this.http.post(url + endpoint, body, options);
  }
}

export interface GraphApiResult {
  data: any;
  errors: GraphApiError[];
}
