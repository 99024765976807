import { BehaviorSubject, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { OrderStatusKeys } from '../keys';
import { EditableTransactionItemConfiguration } from 'downtown-transaction';

export class EditableCart {

  public selection = new BehaviorSubject<EditableCartItem>(null);

  public uid: string;
  public storeFrontUid: string;
  public identityUid: string;
  public logisticTypeUid: string;
  public logisticScheduleUid: string;
  public logisticDateTimeUtc: string;
  public scheduledDateTime: Date;
  public itemQuantity: number = 0;
  public subTotalAmount: number = 0;

  public createDateTimeUtc: Date;
  public modifiedDateTimeUtc: Date;
  public cartStatusUid = new BehaviorSubject<string>(OrderStatusKeys.Created);

  public items = <EditableCartItem[]>[];

  public notes = new BehaviorSubject<string>(null);

  public canEditNotes = false;
  public canEditLocation = false;

  private subscriptionMap: { [index: string]: Subscription } = {};

  constructor() {

    this.cartStatusUid.subscribe(() => {
      this.evaluateState();
    });

    this.evaluateState();
  }

  public addCartItem(cartItem?: EditableCartItem): EditableCartItem {

    cartItem = cartItem || new EditableCartItem();
    this.items.push(cartItem);

    return cartItem;
  }

  public resetCartItem(cartLineItem: EditableCartItem) {

    cartLineItem.restoreSnapshot();
  }

  public removeCartItem(cartLineItem: EditableCartItem) {

    let lineItems = this.items;
    let index = lineItems.indexOf(cartLineItem);

    lineItems.splice(index, 1);
  }

  private evaluateState() {

    this.canEditNotes = this.cartStatusUid.value.toUpperCase() == OrderStatusKeys.Created.toUpperCase();
    this.canEditLocation = this.cartStatusUid.value.toUpperCase() == OrderStatusKeys.Created.toUpperCase();
  }
}

export class EditableCartItem {

  public uid?: string;
  public cartUid?: string;
  public name = new BehaviorSubject<string>(null);
  public productUid?= new BehaviorSubject<string>(null);
  public productVersion = new BehaviorSubject<number>(null);
  public configuration = new BehaviorSubject<EditableTransactionItemConfiguration>(null);
  public quantity = new BehaviorSubject<number>(1);
  public eachAmount = new BehaviorSubject<number>(0);
  public totalAmount = new BehaviorSubject<number>(0);

  public isValid: boolean;
  public isDirty: boolean;
  public canEdit = false;
  private snapShot: any;
  public form: UntypedFormGroup;

  constructor(
  ) {
    this.form = new UntypedFormGroup({
      uid: new UntypedFormControl(null, [Validators.pattern("^[0-9]*$")]),
      quantity: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
    });

    this.takeSnapshot();

    this.name.subscribe(() => {
      this.evaluateState();
    });
    this.productUid.subscribe(() => {
      this.evaluateState();
    });
    this.configuration.subscribe(() => {
      this.evaluateState();
    });
    this.eachAmount.subscribe(() => {
      this.calculateTotals();
      this.evaluateState();
    });

    this.calculateTotals();
    this.evaluateState();
  }

  public getEachAmount(): number {

    return this.eachAmount.value;
  }

  public takeSnapshot() {

    this.snapShot = {
      productUid: this.productUid.value,
      name: this.name.value,
      configuration: this.configuration.value,
      eachAmount: this.eachAmount.value
    };

    this.evaluateState();
  }

  public restoreSnapshot() {

    this.productUid.next(this.snapShot.productUid);
    this.name.next(this.snapShot.name);
    this.configuration.next(this.snapShot.configuration);
    this.eachAmount.next(this.snapShot.eachAmount);

    this.evaluateState();
  }

  private calculateTotals() {

    const eachAmount = this.getEachAmount();
    const quantity = this.form.get('quantity').value;

    this.totalAmount.next(eachAmount * quantity);
  }

  private evaluateState() {

    const quantity = this.form.get('quantity').value;
    const eachAmount = this.getEachAmount();

    this.isValid = this.productUid.value != null && quantity > 0 && eachAmount > 0;

    this.isDirty = this.name.value != this.snapShot.name;
    this.isDirty = this.isDirty || this.productUid.value != this.snapShot.productUid;
    this.isDirty = this.isDirty || this.configuration.value != this.snapShot.configuration;
    this.isDirty = this.isDirty || quantity != this.snapShot.orderQuantity;
    this.isDirty = this.isDirty || eachAmount != this.snapShot.eachAmount;

    this.canEdit = this.uid != null;
  }
}

// export class EditableCartItemConfiguration {

//   portion: EditableCartItemConfigurationPortion;

//   public getConfiguringPortion?(): EditableCartItemConfigurationPortion {

//     return this.portion;
//   }
// }

// export class EditableCartItemConfigurationPortion {

//   portionUid: string;
//   preparations?: EditableCartItemConfigurationPreparation[];
//   variations?: EditableCartItemConfigurationVariation[];
//   inclusionGroups?: EditableCartItemConfigurationInclusionGroup[];
//   inclusions?: EditableCartItemConfigurationInclusion[];
//   extras?: EditableCartItemConfigurationExtra[];
//   addOns?: EditableCartItemConfigurationAddOn[];

//   public getConfiguringInclusionGroup?(inclusionGroupUid: string): EditableCartItemConfigurationInclusionGroup {

//     return this.inclusionGroups ? this.inclusionGroups.find(x => x.inclusionGroupUid.toUpperCase() == inclusionGroupUid.toUpperCase()) : null;
//   }

//   public getConfiguringInclusion?(inclusionUid: string): EditableCartItemConfigurationInclusion {

//     return this.inclusions ? this.inclusions.find(x => x.inclusionUid.toUpperCase() == inclusionUid.toUpperCase()) : null;
//   }

//   public getConfiguringExtra?(extraUid: string): EditableCartItemConfigurationExtra {

//     return this.extras ? this.extras.find(x => x.extraUid.toUpperCase() == extraUid.toUpperCase()) : null;
//   }
// }

// export class EditableCartItemConfigurationPreparation {

//   preparationUid: string;
//   optionUid: string;
// }

// export class EditableCartItemConfigurationVariation {

//   variationUid: string;
//   optionUid: string;
//   productUid: string;
//   productVersion: number;
// }

// export class EditableCartItemConfigurationInclusionGroup {

//   inclusionGroupUid: string;
//   options: EditableCartItemConfigurationInclusionGroupOption[];
// }

// export class EditableCartItemConfigurationInclusionGroupOption {

//   optionUid: string;
//   productUid: string;
//   productVersion: number;
//   quantity: number;
//   preparations?: EditableCartItemConfigurationPreparation[];
//   variations?: EditableCartItemConfigurationVariation[];
// }

// export class EditableCartItemConfigurationInclusion {

//   inclusionUid: string;
//   productUid: string;
//   productVersion: number;
//   quantity: number;
//   preparations?: EditableCartItemConfigurationPreparation[];
//   variations?: EditableCartItemConfigurationVariation[];
// }

// export class EditableCartItemConfigurationExtra {

//   extraUid: string;
//   productUid: string;
//   productVersion: number;
//   quantity: number;
//   preparations?: EditableCartItemConfigurationPreparation[];
//   variations?: EditableCartItemConfigurationVariation[];
// }

// export class EditableCartItemConfigurationAddOn {

//   addOnUid: string;
//   item: EditableCartItem;
// }
