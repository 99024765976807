import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, forkJoin, of, skip, takeUntil } from "rxjs";
import { CurrentDateTimeProvider } from './current-date-time.provder';
import { addSeconds } from '../functions';

/**
 * Class that provides a consistent API for managing timeout events
 */
@Injectable()
export class TimeoutProvider {

    static TIMEOUTPROVIDER_DEFAULT_DURATION: InjectionToken<number> = new InjectionToken<number>('TIMEOUTPROVIDER_DEFAULT_DURATION', {
        providedIn: 'root', // optional
        factory: () => 30
    });

    static TIMEOUTPROVIDER_DEFAULT_UNTIL: InjectionToken<Observable<void>> = new InjectionToken<Observable<void>>('TIMEOUTPROVIDER_DEFAULT_UNTIL', {
        providedIn: 'root', // optional
        factory: () => of()
    });

    public percent$: Observable<number>;
    public inactive$: Observable<void>;

    private _lastResetDateTimeUtc: Date;
    private percentSubject = new BehaviorSubject<number>(0);
    private inactiveSubject = new BehaviorSubject<void>(null);
    private destroyed$ = new Subject<void>();

    constructor(
        currentDateTimeProvider: CurrentDateTimeProvider,
        @Inject('TIMEOUTPROVIDER_DEFAULT_DURATION') until$: Observable<void> = null,
        @Inject('TIMEOUTPROVIDER_DEFAULT_UNTIL') duration: number = 30
    ) {
        this.percent$ = this.percentSubject.asObservable().pipe(
            distinctUntilChanged()
        );
        this.inactive$ = this.inactiveSubject.asObservable().pipe(
            skip(1)
        );

        var untilObservables = until$ ? until$ : this.destroyed$;

        currentDateTimeProvider.currentDateTime$.pipe(
            skip(1),
            takeUntil(untilObservables)
        ).subscribe(currentDateTimeUtc => {
            if (duration && this._lastResetDateTimeUtc) {
                var timeoutDateTimeUtc = addSeconds(this._lastResetDateTimeUtc, duration)

                if (timeoutDateTimeUtc < currentDateTimeUtc) {
                    this.inactiveSubject.next();
                    this.stop();
                    this.restart();
                }

                const startTime = this._lastResetDateTimeUtc.getTime();
                const timeoutTime = timeoutDateTimeUtc.getTime();
                const currentTime = currentDateTimeUtc.getTime();

                const percent = (currentTime - startTime) / (timeoutTime - startTime) * 100;

                this.percentSubject.next(percent);
            }
        });

        this.restart();
    }

    ngOnDestroy() {

        this.destroyed$.next(null);
    }

    public restart(withValue?: Date) {

        this._lastResetDateTimeUtc = withValue || new Date();
    }

    public stop() {

        this.percentSubject.next(0);
        this._lastResetDateTimeUtc = null;
    }
}
