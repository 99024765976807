import { Product, ProductConfiguration, ProductConfigurationAddOn, ProductConfigurationInclusionGroup, ProductConfigurationInclusionGroupOption, ProductConfigurationPortion, ProductConfigurationPreparation, ProductConfigurationPreparationOption, ProductConfigurationProductReference, ProductConfigurationTab, ProductConfigurationVariation, ProductConfigurationVariationOption } from "../models";

export function cloneProduct(source: Product): Product {

    if (source) {
        const target = new Product();
        target.uid = source.uid;
        target.name = source.name;
        target.abbreviation = source.abbreviation;
        target.description = source.description;
        target.imageUid = source.imageUid;
        target.displayOrder = source.displayOrder;
        target.departmentUid = source.departmentUid;
        target.categoryUid = source.categoryUid;
        target.basePrice = source.basePrice;
        target.productStatusUid = source.productStatusUid;
        target.version = source.version;

        target.configuration = cloneConfiguration(source.configuration);

        return target;
    }

    return null;
}

export function cloneConfiguration(source: ProductConfiguration): ProductConfiguration {

    if (source) {
        const target = new ProductConfiguration();
        target.portions = source.portions?.map(x => clonePortion(x));
        target.inclusionGroups = source.inclusionGroups?.map(x => cloneInclusionGroup(x));
        target.addOns = source.addOns?.map(x => cloneAddOn(x));
        target.preparations = source.preparations?.map(x => clonePreparation(x));
        target.variations = source.variations?.map(x => cloneVariation(x));

        return target;
    }

    return null;
}

export function clonePortions(source: ProductConfigurationPortion[]): ProductConfigurationPortion[] {

    return source?.map(x => clonePortion(x));
}

export function clonePortion(source: ProductConfigurationPortion): ProductConfigurationPortion {

    if (source) {
        const target = new ProductConfigurationPortion();
        target.uid = source.uid;
        target.name = source.name;
        target.menuPlacementUid = source.menuPlacementUid;
        target.availableAsInclusion = source.availableAsInclusion;
        target.availableAsAddOn = source.availableAsAddOn;
        target.price = source.price;
        target.productStatusUid = source.productStatusUid;
        target.tabs = source.tabs?.map(x => cloneTab(x));
        target.inclusionGroups = source.inclusionGroups?.map(x => cloneInclusionGroup(x));
        target.addOns = source.addOns?.map(x => cloneAddOn(x));
        target.preparations = source.preparations?.map(x => clonePreparation(x));
        target.variations = source.variations?.map(x => cloneVariation(x));

        return target;
    }

    return null;
};

export function cloneTab(source: ProductConfigurationTab): ProductConfigurationTab {

    if (source) {
        const target = new ProductConfigurationTab();
        target.name = source.name;
        target.inclusionGroupUids = source.inclusionGroupUids ? [...source.inclusionGroupUids] : null;
        target.addOnUids = source.addOnUids ? [...source.addOnUids] : null;

        return target;
    }

    return null;
}

export function cloneInclusionGroups(source: ProductConfigurationInclusionGroup[]): ProductConfigurationInclusionGroup[] {

    return source?.map(x => cloneInclusionGroup(x));
}

export function cloneInclusionGroup(source: ProductConfigurationInclusionGroup): ProductConfigurationInclusionGroup {

    if (source) {
        const target = new ProductConfigurationInclusionGroup();
        target.uid = source.uid;
        target.name = source.name;
        target.maxDistinctOptionsIncluded = source.maxDistinctOptionsIncluded;
        target.minQuantityForOption = source.minQuantityForOption;
        target.maxTotalOptionQuantityIncluded = source.maxTotalOptionQuantityIncluded;
        target.allowSubstitution = source.allowSubstitution;
        target.allowAdditional = source.allowAdditional;
        target.hideInPrintDetail = source.hideInPrintDetail;
        target.hideInUIDetail = source.hideInUIDetail;
        target.options = source.options?.map(x => cloneInclusionGroupOption(x));
        target.preparations = source.preparations?.map(x => clonePreparation(x));
        target.variations = source.variations?.map(x => cloneVariation(x));

        return target;
    }

    return null;
};

export function cloneInclusionGroupOptions(source: ProductConfigurationInclusionGroupOption[]): ProductConfigurationInclusionGroupOption[] {

    return source?.map(x => cloneInclusionGroupOption(x));
}

export function cloneInclusionGroupOption(source: ProductConfigurationInclusionGroupOption): ProductConfigurationInclusionGroupOption {

    if (source) {
        const target = new ProductConfigurationInclusionGroupOption();
        target.uid = source.uid;
        target.productReference = cloneProductReference(source.productReference);
        target.alias = source.alias;
        target.defaultQuantity = source.defaultQuantity;
        target.maxIncludedQuantity = source.maxIncludedQuantity;
        target.priceOverride = source.priceOverride;
        target.preparations = source.preparations?.map(x => clonePreparation(x));
        target.variations = source.variations?.map(x => cloneVariation(x));

        return target;
    }

    return null;
};

export function cloneAddOns(source: ProductConfigurationAddOn[]): ProductConfigurationAddOn[] {

    return source?.map(x => cloneAddOn(x));
}

export function cloneAddOn(source: ProductConfigurationAddOn): ProductConfigurationAddOn {

    if (source) {
        const target = new ProductConfigurationAddOn();
        target.uid = source.uid;
        target.productReference = cloneProductReference(source.productReference);
        target.alias = source.alias;
        target.priceOverride = source.priceOverride;

        return target;
    }

    return null;
};

export function clonePreparations(source: ProductConfigurationPreparation[]): ProductConfigurationPreparation[] {

    return source?.map(x => clonePreparation(x));
}

export function clonePreparation(source: ProductConfigurationPreparation): ProductConfigurationPreparation {

    if (source) {
        const target = new ProductConfigurationPreparation();
        target.uid = source.uid;
        target.name = source.name;
        target.allowNone = source.allowNone;
        target.options = source.options?.map(x => clonePreparationOption(x));

        return target;
    }

    return null;
};

export function clonePreparationOptions(source: ProductConfigurationPreparationOption[]): ProductConfigurationPreparationOption[] {

    return source?.map(x => clonePreparationOption(x));
}

export function clonePreparationOption(source: ProductConfigurationPreparationOption): ProductConfigurationPreparationOption {

    if (source) {
        const target = new ProductConfigurationPreparationOption();
        target.uid = source.uid;
        target.name = source.name;
        target.additionalPrice = source.additionalPrice;
        target.hideInPrintDetail = source.hideInPrintDetail;
        target.hideInUIDetail = source.hideInUIDetail;

        return target;
    }

    return null;
};

export function cloneVariations(source: ProductConfigurationVariation[]): ProductConfigurationVariation[] {

    return source?.map(x => cloneVariation(x));
}

export function cloneVariation(source: ProductConfigurationVariation): ProductConfigurationVariation {

    if (source) {
        const target = new ProductConfigurationVariation();
        target.uid = source.uid;
        target.name = source.name;
        target.defaultOptionUid = source.defaultOptionUid;
        target.options = source.options?.map(x => cloneVariationOption(x));

        return target;
    }

    return null;
};

export function cloneVariationOptions(source: ProductConfigurationVariationOption[]): ProductConfigurationVariationOption[] {

    return source?.map(x => cloneVariationOption(x));
}

export function cloneVariationOption(source: ProductConfigurationVariationOption): ProductConfigurationVariationOption {

    if (source) {
        const target = new ProductConfigurationVariationOption();
        target.uid = source.uid;
        target.productReference = cloneProductReference(source.productReference);
        target.alias = source.alias;
        target.priceOverride = source.priceOverride;

        return target;
    }

    return null;
};

export function cloneProductReference(source: ProductConfigurationProductReference): ProductConfigurationProductReference {

    if (source) {
        const target = new ProductConfigurationProductReference();
        target.uid = source.uid;
        target.portionUid = source.portionUid;
        target.version = source.version;

        return target;
    }

    return null;
};
