<div style="display: flex; flex-direction: column; height: 100%;">

  <app-page-header [title]="title">
    <app-paginator [dataSource]="masterDataSource" [paginator]="paginator"></app-paginator>
  </app-page-header>

  <app-action-bar [actionBarConfiguration]="actionBarConfiguration"></app-action-bar>

  <div style="flex: 1; overflow-y: auto;">

    <div *ngFor="let shiftDate of shiftDates" style="display: flex; border-bottom: solid 1px white;">
      <div style="flex: 1;">
        <div style="text-align: center;">{{ shiftDate.shiftDate | date: 'EE' }}</div>
        <div style="text-align: center;">{{ shiftDate.shiftDate | date: 'M-dd' }}</div>
      </div>
      <div style="flex: 11;">
        <div *ngFor="let position of shiftDate.positions" style="display: flex;">
          <div style="flex: 1;">
            <span>{{ position.position }}</span>
          </div>
          <div style="flex: 10;">
            <app-shifts-table [masterDataSource]="masterDataSource" [dataSource]="position.dataSource" [columns]="columns"></app-shifts-table>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
