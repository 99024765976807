import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, concatMap, first } from 'rxjs/operators';
import { LoggingProvider } from './';

export class ObservableQueueProvider {

  public state$: Observable<ProcessQueueState>;

  private stateSubject = new BehaviorSubject<ProcessQueueState>(ProcessQueueState.Idle);
  private timer: NodeJS.Timeout;
  private queue = new Array<() => Observable<boolean>>();

  constructor(
    private loggingService: LoggingProvider,
    private onErrorCallback: (message: string) => void = () => { }
  ) {
    this.state$ = this.stateSubject.asObservable();
  }

  public enqueue(process: () => Observable<boolean> | Array<() => Observable<boolean>>) {

    if (Array.isArray(process)) {
      for (var i = 0; i < process.length; i++) {
        this.queue.push(process[i]);
      }
    } else {
      this.queue.push(process as () => Observable<boolean>);
    }

    this.activateInterval();
  }

  private activateInterval() {

    if (!this.timer) {
      this.timer = setInterval(() => {
        if (this.stateSubject.value === ProcessQueueState.Idle) {
          this.processQueue();
        }
      }, 100);
    }
  }

  private deactivateTimer() {

    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  private processQueue() {

    if (this.queue.length == 0) {
      this.deactivateTimer();
    } else {
      this.stateSubject.next(ProcessQueueState.Working);

      let process = this.queue.length == 0 ? of(true) : this.processOne()
      process.subscribe(result => {
        if (!result){
          this.loggingService.log(`ObservableQueueProvider: process one failed`);
        }

        this.stateSubject.next(ProcessQueueState.Idle);
      });
    }
  }

  private processOne(): Observable<boolean> {

    let queueFunction = this.queue.shift();

    return queueFunction().pipe(
      catchError((error) => {
        if (this.onErrorCallback) {
          let errorMessage = 'Undefined Error.';

          if (error?.message) {
            errorMessage = error.message
          } else {
            errorMessage = errorMessage + ` ${error?.toString()}`;
          }

          this.onErrorCallback(errorMessage);
        }
        return of(false);
      }),
      concatMap(result => {
        if (!result) {
          // Something failed, flush the queue
          this.queue.splice(0, this.queue.length);

          this.loggingService.log(`ObservableQueueProvider::queueFunction:result: ${result}, aborting queue`);
        }

        return this.queue.length == 0 ? of(result) : this.processOne();
      }),
      first()
    )
  }
}

export const enum ProcessQueueState {
  Idle,
  Working
}
