import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeShiftStatusKeys } from '../keys';
import { EmployeeShift } from '../models/employee-shift';

@Pipe({
  name: 'employeeShiftStatus'
})
export class EmployeeShiftStatusPipe implements PipeTransform {

  public static DisplayMap = new Map<string, string>([
    [EmployeeShiftStatusKeys.ClockedIn.toUpperCase(), 'Clocked In'],
    [EmployeeShiftStatusKeys.ClockedOut.toUpperCase(), 'Clocked Out'],
    [EmployeeShiftStatusKeys.PaymentMade.toUpperCase(), 'Payment Made'],
    [EmployeeShiftStatusKeys.Deleted.toUpperCase(), 'Deleted'],
    [EmployeeShiftStatusKeys.PaymentScheduled.toUpperCase(), 'Payment Scheduled'],
    [EmployeeShiftStatusKeys.PaymentCancelled.toUpperCase(), 'Payment Cancelled'],
    [EmployeeShiftStatusKeys.Submitted.toUpperCase(), 'Submitted'],
    [EmployeeShiftStatusKeys.Rejected.toUpperCase(), 'Rejected'],
    [EmployeeShiftStatusKeys.Accepted.toUpperCase(), 'Accepted']
  ]);

  constructor(
  ) {
  }

  transform(value: EmployeeShift | string): string {

    if (typeof (value) === 'string') {
      return value ? EmployeeShiftStatusPipe.DisplayMap.get(value.toUpperCase()) : null;
    } else {
      return value ? EmployeeShiftStatusPipe.DisplayMap.get(value.employeeShiftStatusUid.toUpperCase()) : null;
    }
  }
}
