<app-page-header>

  <ng-container left>
    <button mat-flat-button style="width: 4.1666vw;" (click)="navigateHome()">
      <mat-icon style="font-weight: bold;">home</mat-icon>
    </button>
    <button mat-flat-button style="width: 4.1666vw; margin-left: 4px;" (click)="navigateBack()">
      <mat-icon style="font-weight: bold;">arrow_back</mat-icon>
    </button>
  </ng-container>

  <ng-container middle>{{title}}</ng-container>

</app-page-header>

<div class="container">
  <form [formGroup]="form" style="margin-top: 20px;">
    <mat-form-field>
      <input matInput type="text" formControlName="firstName" placeholder="First Name">
    </mat-form-field>
    <div>
      <mat-form-field>
        <input matInput type="text" formControlName="lastName" placeholder="Last Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput type="text" formControlName="displayName" placeholder="Display Name">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput #postalCode type="text" formControlName="pin" placeholder="PIN">
        <button matSuffix mat-flat-button color="primary" (click)="getRandomPin()">Get Random PIN</button>
        <mat-hint align="end">{{postalCode.value.length}} / 6</mat-hint>
        <mat-hint>Pin is required and must be six digits</mat-hint>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput type="tel" formControlName="mobilePhone" placeholder="Mobile Phone">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput type="email" formControlName="email" placeholder="Email">
        <mat-error *ngIf="form.get('email').hasError('email')">Email is not valid</mat-error>
      </mat-form-field>
    </div>
  </form>

  <div style="text-align: right;">
    <button mat-flat-button color="warn" style="margin-left: 1em;" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="!this.form.dirty || !this.form.valid" style="margin-left: 1em;" (click)="save()">Save</button>
  </div>
</div>