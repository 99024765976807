<div style="text-align: center;">
  <h2 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h2>
</div>

<div>
  <form style="font-size: 56px; line-height: 56px; text-align: center; margin-top: 5px; margin-bottom: 5px;">
    {{ amountText | async | currency:'USD':'symbol':'1.2-2' }}&nbsp;{{ workUnitUid | async | workUnit:'frequency' }}
  </form>
</div>

<div style="display: flex;">
  <div style="flex: 3; margin-right: 2px;">
    <mat-grid-list cols="3" rowHeight="100px">
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('7')">7</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('8')">8</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('9')">9</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('4')">4</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('5')">5</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('6')">6</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('1')">1</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('2')">2</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('3')">3</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('Back')">Back</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('0')">0</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('00')">00</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('Cancel')">Cancel</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('Clear')">Clear</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="numberPadKeyPressed('Enter')">Enter</mat-grid-tile>
    </mat-grid-list>
  </div>
  <div style="flex: 1; margin-left: 2px;">
    <mat-grid-list cols="1" rowHeight="71px">
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="frequencyKeyPressed('b5603ba3-fae1-496c-aa3d-fe8764922691')">Per Hour</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="frequencyKeyPressed('ab679de4-e499-4b0a-a954-26af4002c396')">Per Occurence</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="frequencyKeyPressed('7d10bc55-6ffd-42f5-95d1-fb49d059aa93')">Per Day</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="frequencyKeyPressed('91a09fde-bd6d-4a6d-a2d2-9848201cdbeb')">Per Week</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="frequencyKeyPressed('0053979d-6825-434a-b7d9-04aee03e751c')">Per Pay Period</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="frequencyKeyPressed('99087cec-27c7-4561-b89c-b5f53b39e38b')">Per Month</mat-grid-tile>
      <mat-grid-tile style="background-color: rgb(77,77,77); padding: 2px;" (click)="frequencyKeyPressed('34eeae91-bc48-4c89-8ee5-f30654d8a0bc')">Per Year</mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
