import { Component, Input, EventEmitter, Output } from '@angular/core';
import { slideInAnimation, slideOutAnimation } from 'core';
import { EditableTransaction, EditableTransactionCharge, EditableTransactionChargeAdjustment } from '../../../models/editable-transaction/editable-transaction';

@Component({
  selector: 'transaction-transaction-editor-charge',
  templateUrl: './transaction-editor-charge.component.html',
  styleUrls: ['./transaction-editor-charge.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class TransactionEditorChargeComponent {

  @Input() editableTransaction: EditableTransaction
  @Input() charge: EditableTransactionCharge;
  @Output() select = new EventEmitter<EditableTransactionCharge | EditableTransactionChargeAdjustment>();
  @Output() void = new EventEmitter<EditableTransactionCharge | EditableTransactionChargeAdjustment>();
  @Output() comp = new EventEmitter<EditableTransactionCharge>();
  @Output() cancel = new EventEmitter<EditableTransactionCharge | EditableTransactionChargeAdjustment>();
}
