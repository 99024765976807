
export class PaymentMethodKeys {

  public static readonly Check = '4810FB65-2137-434A-8E79-EF4CBEFB70C4';
  public static readonly Cash = '81FDD7F7-13D2-4E47-B810-FB4E0F80ACD8';
  public static readonly Card = 'B41D2C85-8204-4A05-B492-A1B33C62F1AB';
}


export enum EntityEventProviderStatusEnum {

  Closed = 'Closed',
  Connecting = 'Connecting',
  Closing = 'Closing',
  Ready = 'Ready'
}
