import { Injectable } from '@angular/core';
import { PaginationInput } from 'core';
import { ObservableCacheProvider } from 'core';
import { EmployeeStatusKeys } from '../keys';
import { Employee } from '../models/employee';
import { EmployeeService } from '../services/employee.service';
import { BehaviorSubject, Observable, of } from "rxjs";
import { shareReplay, map } from 'rxjs/operators';

/**
 * Provider meant to be a cache enabled alternative to calling EmployeeService directly, with additional members for getting Employee in different states (ie: Active).
 */
@Injectable()
export class EmployeeProvider {

  public currentActive$: Observable<Employee[]>;

  private currentActiveSubject = new BehaviorSubject<Employee[]>(null);

  constructor(
    private cacheProvider: ObservableCacheProvider,
    private employeeService: EmployeeService,
  ) {
    this.currentActive$ = this.currentActiveSubject.asObservable();

    this.employeeService.search(null, [EmployeeStatusKeys.Active], <PaginationInput>{ pageIndex: 0, pageSize: 100000 }, EmployeeService.EmployeeFullView).subscribe(employeesPage => {

      const employees = employeesPage.edges.map(x => x.node);
      employees.forEach(employee => {
        this.cacheProvider.update(this.getCacheKey(employee.uid), () => of(employee).pipe(shareReplay(1)));
      });
    });
  }

  public getCached$(uid: string): Observable<Employee> {

    return uid ? this.cacheProvider.getOrAdd(this.getCacheKey(uid), () => this.getOne$(uid)) : null;
  }

  public getOne$(uid: string): Observable<Employee> {

    return uid ? this.employeeService
      .getByUid(uid, EmployeeService.EmployeeFullView)
      .pipe(shareReplay(1)) : null;
  }

  private getCacheKey(uid: string): string {

    return `${uid}`;
  }
}
