import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ProductPreparationModalComponent } from './product-preparation-modal.component';
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class ProductPreparationModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open<T>(data: ProductPreparationModalData): MatDialogRef<ProductPreparationModalComponent> {

    return this.dialog.open<ProductPreparationModalComponent, ProductPreparationModalData, T>(ProductPreparationModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface ProductPreparationModalData {

  scope: string,
  preparationForm: UntypedFormGroup,
}
