import { Component } from "@angular/core";
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { ActionBarConfiguration, ActionConfiguration, FilterConfiguration, FilterOptionConfiguration, getParamsFromFilters, getParamsFromPaginator, isCaseInsensitiveEqual, SettingProvider, TenantProvider, updateFiltersFromParams, updatePaginatorFromParams } from "core";
import { Paginator } from "core";
import { getDateTimeForHourMinuteSecond, addMilliseconds, addDays } from "core";
import { DateModalProvider, DateModalData } from "core";
import { SpinnerModalComponent } from "core";
import { SpinnerModalProvider } from "core";
import { NavigationProvider } from "core";
import { TransactionDataSource, TransactionProvider } from "downtown-transaction";
import { TransactionModel } from "downtown-transaction";
import { TransactionStatusEnum } from "downtown-transaction";
import { DateRangeFilterInput } from "core";
import { TransactionViewModalProvider } from "downtown-transaction";
import { PosSettings } from "pos-core";
import { TransactionService, TransactionViewOptions } from "downtown-transaction";
import { ActivatedRoute } from "@angular/router";
import { TerminalProvider } from "downmain-terminal";

@Component({
  selector: 'app-back-office-transactions',
  templateUrl: './back-office-transactions.component.html',
  styleUrls: ['./back-office-transactions.component.scss']
})
export class BackOfficeTransactionsComponent {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: TransactionDataSource;
  public paginator: Paginator;

  public title: string;
  public columns: Array<string>;

  private posSetting: PosSettings;
  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private dateModalProvider: DateModalProvider,
    private transactionViewModalProvider: TransactionViewModalProvider,
    private transactionProvider: TransactionProvider,
    private settingProvider: SettingProvider,
    private terminalProvider: TerminalProvider,
    private tenantProvider: TenantProvider
  ) {
    this.navigationProvider.setWaypoint('Transactions');
    this.title = 'Transactions';

    // this.dataSource = new TransactionDataSource(this.transactionService, this.terminalProvider, false);
    this.dataSource = new TransactionDataSource(this.transactionProvider, false);
    this.paginator = new Paginator(this.dataSource, 0, 100, 'openDateTimeUtc', 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var yesterday = new Date(new Date(today).setDate(today.getDate() - 1));

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('Register', () => this.register(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Reopen', () => this.reopen(this.dataSource.selection.selected[0].uid), () => this.dataSource.selection.selected.some(x => x.canReopen))
      ],
      [
        new FilterConfiguration<{ afterDateTimeUtc: Date, beforeDateTimeUtc: Date }>('Opened Date',
          [
            new FilterOptionConfiguration('Today', { afterDateTimeUtc: today, beforeDateTimeUtc: today }),
            new FilterOptionConfiguration('Yesterday', { afterDateTimeUtc: yesterday, beforeDateTimeUtc: yesterday }),
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'Today')) }
        ),
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('All', null),
            new FilterOptionConfiguration('Open', [TransactionStatusEnum.Open, TransactionStatusEnum.Tab]),
            new FilterOptionConfiguration('Closed', [TransactionStatusEnum.Closed, TransactionStatusEnum.Committed]),
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'All')) }
        )
      ]
    );

    const dateFilter = this.actionBarConfiguration.filters.find(x => isCaseInsensitiveEqual(x.title, 'Opened Date'));
    for (var i = 2; i < 7; i++) {
      var date = new Date(new Date(today).setDate(today.getDate() - i));
      var filterOption = new FilterOptionConfiguration(
        new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric', day: 'numeric' }).format(date),
        { afterDateTimeUtc: date, beforeDateTimeUtc: date }
      );
      dateFilter.options.push(filterOption);
    }

    dateFilter.options.push(new FilterOptionConfiguration("Custom", { afterDateTimeUtc: null, beforeDateTimeUtc: null }));

    // Build initial columns
    this.columns = [ /*'select',*/ 'number', 'openClose', 'total', 'payments', 'status'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    const openedDateFilter = <FilterConfiguration<{ afterDateTimeUtc: Date, beforeDateTimeUtc: Date }>>this.actionBarConfiguration.filters.find(x => x.title == 'Opened Date');
    const statusFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => x.title == 'Status');
    const paginator = this.paginator;

    this.activatedRoute.queryParams.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(params => {
      if (params['openeddate']) {
        const value = params['openeddate'];
        if (!openedDateFilter.options.find(x => isCaseInsensitiveEqual(x.display, value))) {
          var date = new Date(value);

          const filterOption = new FilterOptionConfiguration<{ afterDateTimeUtc: Date, beforeDateTimeUtc: Date }>(
            new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric', day: 'numeric' }).format(date),
            { afterDateTimeUtc: date, beforeDateTimeUtc: date }
          );
          openedDateFilter.options.push(filterOption);
        }
      }

      updateFiltersFromParams([openedDateFilter, statusFilter], params);
      updatePaginatorFromParams(paginator, params);
    });

    combineLatest([
      this.settingProvider.getOneByTypeAndOwner$<PosSettings>('PosSettings', this.tenantProvider.currentUid),
      openedDateFilter.selected,
      statusFilter.selected,
      this.paginator.pageRequest$
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([setting, dateRangeFilterOption, statusFilterOption, paginationInput]) => {
        if (setting) {
          this.posSetting = setting;

          if (dateRangeFilterOption.display == 'Custom') {
            this.selectDateRangeFilter();
          } else {
            // Adjust date range
            var openedAfterDateTime = getDateTimeForHourMinuteSecond(dateRangeFilterOption.value.afterDateTimeUtc, this.posSetting.financialDayStartHourMinute);
            var openedBeforeDateTime = addMilliseconds(getDateTimeForHourMinuteSecond(addDays(dateRangeFilterOption.value.afterDateTimeUtc, 1), this.posSetting.financialDayStartHourMinute), -1);

            var openedDateRangeFilter = <DateRangeFilterInput>{
              afterDateTimeUtc: openedAfterDateTime.toISOString(),
              beforeDateTimeUtc: openedBeforeDateTime.toISOString()
            };

            let statusFilterUids = statusFilterOption.value;

            const params = Object.assign(getParamsFromFilters([openedDateFilter, statusFilter]), getParamsFromPaginator(paginator));
            this.navigationProvider.navigate([], { queryParams: params, queryParamsHandling: 'merge' }).then(_ => this.navigationProvider.updateLastWaypoint());

            this.dataSource.loadData(openedDateRangeFilter, null, statusFilterUids, paginationInput, <TransactionViewOptions>{ includePayments: { includeCardTransactions: true } });
          }
        }
      })
    ).subscribe();

    // This will help merge modifications made to this transaction outside of this instance
    // this.transactionProvider.transactionMutated$.pipe(
    //   takeUntil(this.destroyed$)
    // ).subscribe(transaction => {
    //   let listTransaction = this.transactions.value.find(x => x.uid.toUpperCase() == transaction.uid.toUpperCase());
    //   if (listTransaction && transaction.transactionStatusUid.toUpperCase() != TransactionStatusEnum.Closed.toUpperCase()) {
    //     let transactionModel = this.transactionsList.merge(listTransaction, transaction);
    //     this.evaluateEnablement(transactionModel);
    //   } else {
    //     // Check a refresh if on first page
    //     if (this.pageIndex == 0) {
    //       this.refresh();
    //     }
    //   }
    // });
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }

  private selectDateRangeFilter() {

    let dialogRef = this.dateModalProvider.open(<DateModalData>{
      initialDate: new Date()
    });

    dialogRef.afterClosed().subscribe(date => {
      if (date) {
        var startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        const filter = this.actionBarConfiguration.filters.find(x => isCaseInsensitiveEqual(x.title, 'Opened Date'));
        var filterOption = new FilterOptionConfiguration(new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric', day: 'numeric' }).format(startDate), { afterDateTimeUtc: startDate, beforeDateTimeUtc: startDate });
        filter.options.splice(filter.options.length - 1, 0, filterOption);
        filter.selected.next(filterOption);
      }
    });
  }

  view(transaction: TransactionModel) {

    this.transactionViewModalProvider.open({
      transactionUids: [transaction.uid],
      commands: [
        {
          display: 'Reopen',
          action: (editableTransaction) => {
            this.reopen(editableTransaction.uid);
          },
          closeOnAction: true
        }
      ]
    }).afterClosed().subscribe(() => {
    });
  }

  register(transaction: TransactionModel) {

    this.navigationProvider.navigate(['/backoffice/register/', transaction.uid]);
  }

  // updateTip(transaction: TransactionModel) {

  //   this.router.navigate(['/transaction', transaction.uid, 'cardtransactions']);
  // }

  reopen(transactionUid: string) {

    this.transactionProvider.reopen$(transactionUid, this.terminalProvider.active.uid).subscribe(transaction => {
      this.navigationProvider.navigate(['backoffice', 'transaction', transaction.uid]);
    });
  }
}
