import { Injectable } from '@angular/core';
import { hasEqualUUID } from 'core';
import { ObservableCacheProvider } from 'core';
import { StoreFrontService } from '../services/store-front.service';
import { StoreFront } from '../models/store-front';
import { BehaviorSubject, Observable } from "rxjs";
import { StoreFrontViewOptions } from "../view-factory";
import { StoreFrontInput } from '../models/store-front-input';

/**
 * Provider meant to be a cache enabled alternative to calling StoreFrontService directly, with additional members for getting StoreFront in different states (ie: Active).
 */
@Injectable()
export class StoreFrontProvider {

  public currentStoreFront$: Observable<StoreFront>;
  private currentStoreFrontSubject = new BehaviorSubject<StoreFront>(null);

  constructor(
    private cacheProvider: ObservableCacheProvider,
    private storeFrontService: StoreFrontService,
  ) {
    this.currentStoreFront$ = this.currentStoreFrontSubject.asObservable();
  }

  public set current(value: StoreFront) {

    if (!hasEqualUUID(this.currentStoreFrontSubject.value, value)) {
      this.currentStoreFrontSubject.next(value);
    }
  }

  public getOneCached$(uid: string): Observable<StoreFront> {

    return this.cacheProvider.getOrAdd(this.getCacheKey(uid), () => this.getOne$(uid))
  }

  public getOne$(uid: string): Observable<StoreFront> {

    return this.storeFrontService.getByUid(uid)
  }

  public putOne$(storeFront: StoreFrontInput, viewOptions: StoreFrontViewOptions = StoreFrontService.StoreFrontFullView) {

    return this.storeFrontService.update(storeFront, viewOptions);
  }

  private getCacheKey(uid: string): string {

    return `${uid}`;
  }
}
