import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CategorySelectModalComponent } from "./category-select-modal.component";

@Injectable()
export class CategorySelectModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }

  public open(data: CategorySelectModalData): MatDialogRef<CategorySelectModalComponent, CategorySelectModalResult> {

    return this.dialog.open<CategorySelectModalComponent, CategorySelectModalData, CategorySelectModalResult>(CategorySelectModalComponent, {
      height: '100vh',
      minWidth: '100vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface CategorySelectModalData {
  title: string;
  departmentUids: string[];
}

export interface CategorySelectModalResult {

  departmentUid: string,
  categoryUid: string,
}
