import { Pipe, PipeTransform } from '@angular/core';
import { PayPeriodStatusKeys } from '../keys';
import { PayPeriod } from '../models/pay-period';

@Pipe({
  name: 'payPeriodStatus'
})
export class PayPeriodStatusPipe implements PipeTransform {

  private payPeriodStatusMap = new Map<string, string>([
    [PayPeriodStatusKeys.Open.toUpperCase(), 'Open'],
    [PayPeriodStatusKeys.InReview.toUpperCase(), 'In Review'],
    [PayPeriodStatusKeys.Committed.toUpperCase(), 'Committed'],
    [PayPeriodStatusKeys.Deleted.toUpperCase(), 'Deleted']
  ]);

  constructor(
  ) {
  }

  transform(value: PayPeriod | string): string {

    if (typeof (value) === 'string') {
      return value ? this.payPeriodStatusMap.get(value.toLocaleUpperCase()) : null;
    } else {
      return value ? this.payPeriodStatusMap.get(value.payPeriodStatusUid.toLocaleUpperCase()) : null;
    }
  }
}
