import { Component, Input, Output, EventEmitter } from "@angular/core";
import { initTransactionItemConfiguration } from "../../../functions/transaction-item-configuration/initialize";
import { PortionModel } from "../models";
import { ItemConfiguratorContext } from "../../../components";

@Component({
  selector: 'transaction-item-configurator-modal-portions-tab',
  templateUrl: './item-configurator-modal-portions-tab.component.html',
  styleUrls: ['./item-configurator-modal-portions-tab.component.scss']
})
export class ItemConfiguratorModalPortionsTabComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public menuPlacementUid: string;
  @Output() public configurationChanged = new EventEmitter();

  public portions = <PortionModel[]>[];

  constructor(
  ) {
  }

  ngOnInit() {

    if (this.context.product.configuration && this.context.product.configuration.portions) {
      this.context.product.configuration.portions.filter(x => this.menuPlacementUid ? x.menuPlacementUid == this.menuPlacementUid : true).forEach(portion => {
        let portionModel = <PortionModel>{
          portionUid: portion.uid,
          name: portion.name,
          price: portion.price,
          isSelected: false,
        };

        if (this.context.itemConfiguration.portion && this.context.itemConfiguration.portion.portionUid.toUpperCase() == portionModel.portionUid.toUpperCase()) {
          portionModel.isSelected = true;
        }

        this.portions.push(portionModel);
      });
    }
  }

  public selectPortion(portionUid: string) {

    this.portions.forEach(x => {
      if (x.portionUid.toUpperCase() == portionUid.toUpperCase()) {
        x.isSelected = true;
      } else {
        x.isSelected = false;
      }
    });

    this.context.itemConfiguration = initTransactionItemConfiguration(this.context.product, portionUid, this.context.productProvider, true);

    this.configurationChanged.next(null);
  }
}
