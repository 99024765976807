import { Component, OnInit } from '@angular/core';
import { StoreServerSettings } from 'pos-core';

@Component({
  selector: 'app-store-host',
  templateUrl: './store-host.component.html',
  styleUrls: ['./store-host.component.scss']
})
export class StoreHostComponent implements OnInit {

  public storeHostUrl: string;

  constructor(
    public storeServerSettings: StoreServerSettings,
  ) {
  }

  // Two way iframe communication
  // https://gist.github.com/pbojinov/8965299

  ngOnInit() {

    this.storeHostUrl = `${this.storeServerSettings.hostFrameUrl}/${this.storeServerSettings.storeFrontUid}`;
  }
}
