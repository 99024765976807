<div style="display: flex; height: 100%;">

  <div style="flex: 1; display: flex;">

    <app-expansion-container #expansionContainer style="flex: 1; background-color: rgba(255, 255, 255, 0.06);">

      <app-expansion-panel *ngFor="let category of categories">
        <app-expansion-panel-header (click)="panelClick(category)">
          <app-expansion-panel-title>
            <span style="font-size: 24px;">{{ category.name }}</span>
          </app-expansion-panel-title>
        </app-expansion-panel-header>
      </app-expansion-panel>

    </app-expansion-container>

  </div>

  <div style="flex: 4; padding-left: 5px; height: 100%; overflow-y: auto;">

    <div style="display: flex; flex-wrap: wrap;">

      <ng-container *ngFor="let menuItem of categoryMenuItems">

        <div style="width: 40%; margin-right: 5px; margin-bottom: 5px; padding: 0; background-color: rgb(66,66,66); border-radius: 4px;">

          <div style="display: flex;">

            <div style="flex: 2;">

              <div style="margin-left: 8px; margin-right: 8px;">
                <div style="display: flex;">
                  <span class="mat-title">{{ menuItem.name }}</span>
                  <span style="flex: 1 1 auto;"></span>
                  <span *ngIf="menuItem.basePrice > 0" class="mat-title">{{ menuItem.basePrice | currency:'USD':'symbol':'1.2-2' }}</span>
                </div>
                <div style="margin-top: 0px; margin-bottom: 8px; min-height: 40px;">
                  <span class="mat-body-1" style="opacity: .7;">{{ menuItem.description }}</span>
                </div>
              </div>

              <!-- <div>
                <img *ngIf="menuItem.imageUid" style="max-height: 200px; margin-left: 5px; margin-right: 0; width: 100%;" src="{{ runtimeProvider.imageHostUrl + '/' + menuItem.imageUid + '?height=250' }}">
              </div> -->

            </div>

            <div style="flex: 1;; padding-left: 16px; padding-right: 16px;">
              <div *ngFor="let portion of menuItem.portions" style="margin-left: 8px; margin-right: 8px; margin-bottom: 4px;">
                <div style="display: flex;">
                  <span class="mat-title">{{ portion.name }}</span>
                  <span style="flex: 1 1 auto;"></span>
                  <span class="mat-title">{{ portion.price | currency:'USD':'symbol':'1.2-2' }}</span>
                </div>
                <div style="margin-top: -12px; margin-bottom: 8px;">
                </div>
              </div>
            </div>

          </div>

          <!-- <div *ngIf="menuItem.inclusionGroups?.length > 0 || menuItem.inclusions?.length > 0">Includes</div>

          <div *ngIf="menuItem.inclusionGroups">
            <div *ngFor="let inclusionGroup of menuItem.inclusionGroups">
              <div>{{ inclusionGroup.name }}</div>
              <div>
                <div *ngFor="let option of inclusionGroup.options">
                  <div style="margin-left: 1em;">{{ option.name | async }}</div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="menuItem.inclusions">
            <div *ngFor="let inclusion of menuItem.inclusions">{{ (inclusion | async)?.name }}</div>
          </div> -->

          <div style="margin-left: 0; margin-right: 0; height: 2em;">
            <div *ngIf="menuItem.addOns?.length > 0" style="margin-left: 8px; margin-right: 8px;">
              <span class="mat-subheading-1">Extra Options: </span>
              <div *ngFor="let addOn of menuItem.addOns" style="display: flex; margin-left: 8px; margin-right: 8px;">
                <span class="mat-caption">{{ addOn.name }}</span>
                <span style="flex: 1 1 auto;"></span>
                <span class="mat-caption">{{ addOn.price | currency:'USD':'symbol':'1.2-2' }}</span>
              </div>
            </div>
          </div>

        </div>
        <!-- 
        <mat-card style="width: 40%; margin-right: 5px; margin-bottom: 5px; padding: 0;">

          <div style="display: flex;">

            <div style="flex: 2;">

              <div style="margin-left: 8px; margin-right: 8px;">
                <div style="display: flex;">
                  <span class="mat-title">{{ menuItem.name }}</span>
                  <span style="flex: 1 1 auto;"></span>
                  <span *ngIf="menuItem.basePrice > 0" class="mat-title">{{ menuItem.basePrice | currency:'USD':'symbol':'1.2-2' }}</span>
                </div>
                <div style="margin-top: -12px; margin-bottom: 8px; min-height: 40px;">
                  <span class="mat-body-1" style="opacity: .7;">{{ menuItem.description }}</span>
                </div>
              </div>

              <div style="height: 250px;">
                <img *ngIf="menuItem.imageUid" style="max-height: 200px; margin-left: 0; margin-right: 0; width: 100%;" src="{{ runtimeProvider.imageHostUrl + '/' + menuItem.imageUid + '?height=250' }}">
              </div>

            </div>

            <div style="flex: 1;; padding-left: 16px; padding-right: 16px;">
              <div *ngFor="let portion of menuItem.portions" style="margin-left: 8px; margin-right: 8px; margin-bottom: 4px;">
                <div style="display: flex;">
                  <span class="mat-title">{{ portion.name }}</span>
                  <span style="flex: 1 1 auto;"></span>
                  <span class="mat-title">{{ portion.price | currency:'USD':'symbol':'1.2-2' }}</span>
                </div>
                <div style="margin-top: -12px; margin-bottom: 8px;">
                </div>
              </div>
            </div>

          </div>

          <mat-card-footer style="margin-left: 0; margin-right: 0; height: 2em;">
            <div *ngIf="menuItem.addOns?.length > 0" style="margin-left: 8px; margin-right: 8px;">
              <span class="mat-subheading-1">Extra Options: </span>
              <div *ngFor="let addOn of menuItem.addOns" style="display: flex; margin-left: 8px; margin-right: 8px;">
                <span class="mat-caption">{{ addOn.name }}</span>
                <span style="flex: 1 1 auto;"></span>
                <span class="mat-caption">{{ addOn.price | currency:'USD':'symbol':'1.2-2' }}</span>
              </div>
            </div>
          </mat-card-footer>

        </mat-card> -->

      </ng-container>

    </div>

  </div>

</div>