export function isCaseInsensitiveEqual(left: string, right: string): boolean {

  return left?.toLowerCase() == right?.toLowerCase();
}

export function isEqualUUID(left?: string, right?: string): boolean {

  return left?.toLowerCase() == right?.toLowerCase();
}

export function hasEqualUUID(left?: { uid?: string }, right?: { uid?: string }): boolean {

  return isEqualUUID(left?.uid, right?.uid);
}
