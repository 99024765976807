import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SpinnerModalProvider } from "core";
import { UUID } from "angular2-uuid";
import { AuthHeaderProvider } from 'core';
import { NavigationProvider } from 'core';
import { PositionService } from '../../../services/position.service';
import { Position } from '../../../models/position';

export class PositionForm {

  public positionUid: string;
  public form: UntypedFormGroup;
  public canSave = false;

  constructor(
    protected navigationProvider: NavigationProvider,
    protected spinnerModalProvider: SpinnerModalProvider,
    protected positionService: PositionService,
    protected authHeaderProvider: AuthHeaderProvider
  ) {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl(''),
    });

    this.form.valueChanges.subscribe(x => this.updateEnablement());
  }

  public loadForm(position: Position) {

    if (position) {
      this.positionUid = position.uid;

      this.form.get('name').setValue(position.name);
      this.form.get('description').setValue(position.description);
    }

    this.form.markAsPristine();
    this.updateEnablement();
  }

  public save() {

    if (this.form.dirty) {
      let saveDialogRef = this.spinnerModalProvider.open();

      saveDialogRef.afterOpened().subscribe(() => {
        if (this.positionUid) {
          this.positionService.update(
            this.positionUid,
            this.form.get('name').value,
            this.form.get('description').value,
            this.authHeaderProvider
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        } else {
          this.positionUid = UUID.UUID();

          this.positionService.create(
            this.positionUid,
            this.form.get('name').value,
            this.form.get('description').value,
            this.authHeaderProvider
          ).subscribe(() => {
            saveDialogRef.close();
            this.navigationProvider.backOneWaypoint();
          });
        }
      });
    } else {
      this.navigationProvider.backOneWaypoint();
    }
  }

  public cancel() {
    this.navigationProvider.backOneWaypoint();
  }

  protected updateEnablement() {

    this.canSave = this.form.dirty;
  }
}
