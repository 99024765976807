<div [formGroup]="form" style="display: flex; margin-left: 10px;">

  <div style="flex: 1;">

    <div style="display: flex;">
      <button mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-right: 5px;" (click)="newPortion()">New</button>
    </div>
    <mat-selection-list cdkDropList class="draggable-list" [multiple]="false" (cdkDropListDropped)="dropPortion($event)">

      <ng-container *ngIf="form?.get('portions') != null">

        <mat-list-option cdkDrag *ngFor="let portionForm of portionsFormGroupArray" [value]="portionForm" class="item">
          <div style="display: flex; align-items: center;">
            <div style="width: 25px; margin-right: 5px;">
              <mat-icon>drag_indicator</mat-icon>
            </div>
            <span style="flex: 1;" (click)="editPortion(portionForm)">{{ portionForm.get('name').value }}<span *ngIf="portionForm.get('productStatusUid').value == ProductStatusKeys.Inactive" style="font-size: 12px;">&nbsp;(Inactive)</span></span>
            <mat-icon (click)="deletePortion(portionForm)">delete</mat-icon>
          </div>
        </mat-list-option>

      </ng-container>

    </mat-selection-list>
  </div>

  <div style="flex: 1; font-family: familyRaleway, sans-serif; font-size: 2em; line-height: 100%; padding: 0 10px;">
    <p>Portions provide the available serving size, high level variations, or special pricing of items. Each product for sale or inclusion must have at least one portion.</p>
  </div>

</div>
