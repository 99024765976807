import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectSystemMessageTypeModalComponent } from "./select-system-message-type-modal.component";

@Injectable()
export class SelectSystemMessageTypeModalProvider {

  private readonly isDesktop: boolean;

  constructor(
    protected dialog: MatDialog,
  ) {
  }

  public open(data: SelectSystemMessageTypeModalData): MatDialogRef<SelectSystemMessageTypeModalComponent, SelectSystemMessageTypeModalResult> {

    return this.dialog.open<SelectSystemMessageTypeModalComponent, SelectSystemMessageTypeModalData, SelectSystemMessageTypeModalResult>(SelectSystemMessageTypeModalComponent, {
      width: '30vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface SelectSystemMessageTypeModalData {

}

export interface SelectSystemMessageTypeModalResult {

  systemMessageTypeUid: string,
  terminalUid: string
}
