import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SettingBaseComponent } from 'core';
import { PosSettings } from '../../../models';

export interface PosSettingsGroupFormType {
  systemUser: FormGroup<SystemUserFormGroupType>;
  adminUser: FormGroup<AdminUserFormGroupType>;
  enableDevelopmentFeatures: FormControl<boolean>;
  autoPrintCreditCardReceipt: FormControl<boolean>;
  payPeriodDuration_Days: FormControl<number>;
  defaultTimezone: FormControl<string>;
  preAuthorizeWithAdditionalPercent: FormControl<number>;
  financialDayStartHourMinute: FormControl<string>;
  enableCreditCardProcessor: FormControl<boolean>;
  preAuthorizeCreditCards: FormControl<boolean>;
  openCashDrawerOnCardPayment: FormControl<boolean>;
  dialogAutocloseDuration: FormControl<number>;
};

export interface SystemUserFormGroupType {

  uid: FormControl<string>;
  identityEmail: FormControl<string>;
  identityPassword: FormControl<string>;
};

export interface AdminUserFormGroupType {

  uid: FormControl<string>;
  identityEmail: FormControl<string>;
  identityPassword: FormControl<string>;
};

@Component({
  selector: 'pos-core-pos-setting',
  templateUrl: './pos-setting.component.html',
  styleUrls: ['./pos-setting.component.scss']
})
export class PosSettingComponent extends SettingBaseComponent<PosSettings, PosSettingsGroupFormType> {

  constructor(
  ) {
    super();

    this.form = new FormGroup({
      systemUser: new FormGroup<SystemUserFormGroupType>({
        uid: new FormControl<string>(null),
        identityEmail: new FormControl<string>(null),
        identityPassword: new FormControl<string>(null),
      }),
      adminUser: new FormGroup<AdminUserFormGroupType>({
        uid: new FormControl<string>(null),
        identityEmail: new FormControl<string>(null),
        identityPassword: new FormControl<string>(null),
      }),
      enableDevelopmentFeatures: new FormControl<boolean>(false),
      autoPrintCreditCardReceipt: new FormControl<boolean>(false),
      payPeriodDuration_Days: new FormControl<number>(null),
      defaultTimezone: new FormControl<string>(null),
      preAuthorizeWithAdditionalPercent: new FormControl<number>(null),
      financialDayStartHourMinute: new FormControl<string>(null),
      enableCreditCardProcessor: new FormControl<boolean>(false),
      preAuthorizeCreditCards: new FormControl<boolean>(false),
      openCashDrawerOnCardPayment: new FormControl<boolean>(false),
      dialogAutocloseDuration: new FormControl<number>(null)
    });
  }

  public override patchForm(value: PosSettings): void {

    this.form.patchValue(value);
  }
}
