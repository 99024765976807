import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusKeys } from '../keys';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  private map = new Map<string, string>([
    [OrderStatusKeys.Created.toUpperCase(), 'Created'],
    [OrderStatusKeys.Submitted.toUpperCase(), 'Submitted'],
    [OrderStatusKeys.Reviewing.toUpperCase(), 'Reviewing'],
    [OrderStatusKeys.Accepted.toUpperCase(), 'Accepted'],
    [OrderStatusKeys.Processing.toUpperCase(), 'Processing'],
    [OrderStatusKeys.Fulfilled.toUpperCase(), 'Fulfilled'],
    [OrderStatusKeys.Closed.toUpperCase(), 'Closed'],
    [OrderStatusKeys.Cancelled.toUpperCase(), 'Cancelled'],
    [OrderStatusKeys.Committed.toUpperCase(), 'Committed']
  ]);

  constructor(
  ) {
  }

  transform(value: string): string {

    if (typeof (value) === 'string') {
      return value ? this.map.get(value.toUpperCase()) : null;
    } else {
      return null;
    }
  }
}
