import { Component, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, map, concatMap, takeUntil } from 'rxjs/operators';
import { ActionBarConfiguration, ActionConfiguration, FilterConfiguration, FilterOptionConfiguration, TenantProvider, getParamsFromFilters, getParamsFromPaginator, isCaseInsensitiveEqual, updateFiltersFromParams, updatePaginatorFromParams } from 'core';
import { Paginator } from 'core';
import { ConfirmModalProvider } from 'core';
import { SpinnerModalComponent } from 'core';
import { SpinnerModalProvider } from 'core';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { CategoryDataSource, CategoryModel } from 'downtown-product';
import { CategoryStatusKeys } from 'downtown-product';
import { CategoryService, CategoryViewOptions } from 'downtown-product';

@Component({
  selector: 'app-back-office-categories',
  templateUrl: './back-office-categories.component.html',
  styleUrls: ['./back-office-categories.component.scss']
})
export class BackOfficeCategoriesComponent implements OnInit {

  public actionBarConfiguration: ActionBarConfiguration;
  public dataSource: CategoryDataSource;
  public paginator: Paginator;

  public title: string;
  public columns: Array<string>;

  private destroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private confirmModalProvider: ConfirmModalProvider,
    private categoryService: CategoryService,
    private tenantProvider: TenantProvider,
  ) {
    this.navigationProvider.setWaypoint('Product Categories');
    this.title = 'Product Categories';

    this.dataSource = new CategoryDataSource(this.categoryService, this.tenantProvider, false);
    this.paginator = new Paginator(this.dataSource, 0, 100, '', 'desc');

    var spinnerModalRef: MatDialogRef<SpinnerModalComponent>;
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading) {
        spinnerModalRef = this.spinnerModalProvider.open();
      } else {
        if (spinnerModalRef) {
          spinnerModalRef.close();
        }
      }
    });

    this.actionBarConfiguration = new ActionBarConfiguration(
      [
        new ActionConfiguration('New', this.new.bind(this)),
        new ActionConfiguration('Edit', () => this.edit(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canEdit)),
        new ActionConfiguration('Delete', () => this.delete(this.dataSource.selection.selected[0]), () => this.dataSource.selection.selected.some(x => x.canDelete))
      ],
      [
        new FilterConfiguration('Status',
          [
            new FilterOptionConfiguration('Active', [CategoryStatusKeys.Active]),
            new FilterOptionConfiguration('All', [CategoryStatusKeys.Active, CategoryStatusKeys.Inactive, CategoryStatusKeys.Deleted])
          ],
          (filter) => { filter.selected.next(filter.options.find(x => x.display == 'Active')) }
        )
      ]
    );

    // Build initial columns
    this.columns = [ /*'select',*/ 'name', 'description', 'departmentUid', 'categoryStatusUid'];
    // if (this.runtimeProvider.isDesktop) {
    //   this.displayedColumns.push('actions');
    // } else {
    //   this.columns.unshift('select');
    // }
  }

  ngOnInit() {

    const statusFilter = <FilterConfiguration<string[]>>this.actionBarConfiguration.filters.find(x => isCaseInsensitiveEqual(x.title, 'Status'));
    const paginator = this.paginator;

    this.activatedRoute.queryParams.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(params => {
      updateFiltersFromParams([statusFilter], params);
      updatePaginatorFromParams(paginator, params);
    });

    combineLatest([
      statusFilter.selected,
      this.paginator.pageRequest$,
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([statusFilterOption, paginationInput]) => {
        let statusFilterUids = statusFilterOption.value;

        const params = Object.assign(getParamsFromFilters([statusFilter]), getParamsFromPaginator(paginator));
        this.navigationProvider.navigate([], { queryParams: params, queryParamsHandling: 'merge' }).then(_ => this.navigationProvider.updateLastWaypoint());

        this.dataSource.loadData(this.activatedRoute.snapshot.params['departmentUid'], statusFilterUids, paginationInput, <CategoryViewOptions>{});
      })
    ).subscribe();
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }

  new() {

    this.activatedRoute.params.subscribe(params => {
      this.navigationProvider.navigate(['/backoffice/department', params['departmentUid'], 'category']);
    });
  }

  edit(category: CategoryModel) {

    this.activatedRoute.params.subscribe(params => {
      this.navigationProvider.navigate(['/backoffice/department', params['departmentUid'], 'category', category.uid]);
    });
  }

  public delete(category: CategoryModel) {

    let confirmDialogRef = this.confirmModalProvider.open('Delete Category', 'Are you certain you want to delete this category?');
    confirmDialogRef.afterClosed().subscribe(value => {
      if (value) {
        let waitDialogRef = this.waitModalProvider.open('Deleting...');

        waitDialogRef.afterOpened().pipe(
          concatMap(() => this.dataSource.delete(category)),
          catchError(() => of(null)),
        ).subscribe(() => waitDialogRef.close());
      }
    });
  }
}
