<div class="editor">

  <ng-container *ngIf="editableTransaction; else notransaction;">
    <ng-container *ngTemplateOutlet="hastransaction;"></ng-container>
  </ng-container>

</div>

<ng-template #hastransaction>

  <div style="height: 100%; display: flex; flex-direction: column;">

    <div style="position: relative; height: 8px; width: 100%;">
      <div style="position: absolute; height: 100%; width: 100%; top: 0px; opacity: .4; background-color: black;" [ngStyle]="{ 'left': (-100 + percent) + '%' }"></div>
    </div>

    <div style="display: flex; margin-bottom: 6px;">
      
      <div style="height: 6vh; padding: 2px;">
        <app-pos-button *ngIf="editableTransaction.canEditNotes; else noEditNotes" style="height: 100%; min-width: 8.3333vw;" (click)="updateNotes.next(null)">{{ (editableTransaction.notes | async) == null ? 'Unknown Guest' : (editableTransaction.notes | async) }}</app-pos-button>
        <ng-template #noEditNotes>
          <app-pos-button style="height: 100%; min-width: 8.3333vw;">{{ (editableTransaction.notes | async) == null ? 'Unknown Guest' : (editableTransaction.notes | async) }}</app-pos-button>
        </ng-template>
      </div>

      <div style="flex: 1 1 auto;"></div>

      <div style="height: 6vh; padding: 2px;">
        <app-pos-button *ngIf="editableTransaction.canEditLocation; else noEditLocation" mat-flat-button color="primary" style="height: 100%; min-width: 8.3333vw;" (click)="updateLocation.next(null)">{{ editableTransaction.logisticTypeUid | logisticType }}</app-pos-button>
        <ng-template #noEditLocation>
          <button mat-flat-button color="primary" style="height: 100%; min-width: 8.3333vw;">{{ editableTransaction.logisticTypeUid | logisticType }}</button>
        </ng-template>
      </div>
    </div>

    <div style="display: flex; border-bottom: 1px solid #000; margin-bottom: 10px;">
      <span style="width: 53%;">{{ editableTransaction.transactionStatus | async }}</span>
      <span style="width: 10%; text-align: right;">QTY</span>
      <span style="width: 2%;"></span>
      <span style="width: 15%; text-align: right;">EACH</span>
      <span style="width: 20%; text-align: right;">TOTAL</span>
    </div>

    <div style="flex: 1; overflow-y: auto;">

      <!-- Transaction Guests -->
      <ng-container *ngFor="let transactionGuest of getTransactionGuests()">
        <transaction-transaction-editor-guest [editableTransaction]="editableTransaction" [transactionGuest]="transactionGuest" (select)="select.emit($event)" (edit)="edit.emit($event)" (void)="void.emit($event)" (cancel)="cancel.emit($event)" (print)="print.emit($event)" (guest)="guest.emit($event)"></transaction-transaction-editor-guest>
      </ng-container>

      <!-- Transaction Items -->
      <ng-container *ngFor="let transactionItem of getTransactionItems()">
        <transaction-transaction-editor-item [editableTransaction]="editableTransaction" [item]="transactionItem" (select)="select.emit($event)" (edit)="edit.emit($event)" (void)="void.emit($event)" (comp)="comp.emit($event)" (cancel)="cancel.emit($event)" (print)="print.emit($event)" (guest)="guest.emit($event)"></transaction-transaction-editor-item>
      </ng-container>

      <!-- Charges -->
      <div *ngFor="let charge of editableTransaction.charges" style="margin-top: 10px;">
        <transaction-transaction-editor-charge [editableTransaction]="editableTransaction" [charge]="charge" (select)="select.emit($event)" (void)="void.emit($event)" (comp)="comp.emit($event)" (cancel)="cancel.emit($event)"></transaction-transaction-editor-charge>
      </div>

    </div>

    <div>
      <!-- Transaction Total -->
      <div *ngIf="editableTransaction.adjustments?.length > 0 || editableTransaction.payments?.length > 0" style="display: flex; margin-top: 10px;">
        <span style="width: 50%;"></span>
        <span style="width: 30%; text-align: right; border-top: 1px solid #000;">Sale Total</span>
        <span style="width: 20%; text-align: right; border-top: 1px solid #000;">{{ editableTransaction.total | async | currency:'USD':'symbol':'1.2-2' }}</span>
      </div>

      <!-- Transaction Adjustment Line Items -->
      <ng-container *ngFor="let adjustment of editableTransaction.adjustments">
        <transaction-transaction-editor-adjustment [editableTransaction]="editableTransaction" [adjustment]="adjustment" (select)="select.emit($event)" (void)="void.emit($event)" (cancel)="cancel.emit($event)"></transaction-transaction-editor-adjustment>
      </ng-container>

      <!-- Payment Line Items -->
      <ng-container *ngFor="let payment of editableTransaction.payments">
        <transaction-transaction-editor-payment [editableTransaction]="editableTransaction" [payment]="payment" (select)="select.emit($event)" (void)="void.emit($event)" (changeType)="changeType.emit($event)" (cancel)="cancel.emit($event)"></transaction-transaction-editor-payment>
      </ng-container>

      <!-- Change/Total Due -->
      <div style="display: flex; border-top: 1px solid #000;">

        <ng-container *ngIf="editableTransaction.totalDue.value != null">
          <span class="total-headings" style="width: 80%; text-align: right;">Total Due</span>
          <span class="total-headings" style="width: 20%; text-align: right;">{{ editableTransaction.totalDue | async | currency:'USD':'symbol':'1.2-2' }}</span>
        </ng-container>

        <ng-container *ngIf="editableTransaction.change.value != null">
          <span class="total-headings" style="width: 80%; text-align: right;">Change</span>
          <span class="total-headings" style="width: 20%; text-align: right;">{{ editableTransaction.change | async | currency:'USD':'symbol':'1.2-2' }}</span>
        </ng-container>

      </div>

    </div>
  </div>

</ng-template>

<ng-template #notransaction>
  <div style="height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <span>No Transaction</span>
  </div>
</ng-template>