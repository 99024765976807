export class CardProcessorBatch {

  batchId: string;
  settlementTimeUtc: string;
  settlementTimeLocal: string;
  settlementState: string;
  paymentMethod: string;
  marketType: string;
  product: string;
}
