import { Injectable } from "@angular/core";
import { GraphService, HttpService } from "core";
import { Transaction, TransactionItem, TransactionPayment, TransactionItemAdjustment, TransactionAdjustment, TransactionGuest, TransactionPage, TransactionCharge, TransactionChargeAdjustment, TransactionSearchPage } from "../models/transaction";
import { Observable } from 'rxjs'
import { PaginationInput } from 'core';
import { TransactionServiceSettings } from '../transaction-service-settings';
import { Metrics } from "../models/metrics";
import { TransactionGuestInput, TransactionItemInput } from "../models/transaction-input";
import { map, tap } from "rxjs/operators";
import { DateRangeFilterInput } from 'core';
import { TransactionModelFactory } from '../transaction-model-factory';
import { TransactionPaymentInput } from '../models/transaction-payment-input';
import { TransactionChargeAdjustmentInput, TransactionChargeInput, TransactionItemAdjustmentInput } from "../models";

@Injectable()
export class TransactionService {

  [index: string]: any;

  public static readonly TransactionGuestFullView = <TransactionGuestViewOptions>{};
  public static readonly TransactionItemAdjustmentFullView = <TransactionItemAdjustmentViewOptions>{};
  public static readonly TransactionItemFullView = <TransactionItemViewOptions>{ includeAdjustments: TransactionService.TransactionItemAdjustmentFullView };
  public static readonly TransactionAdjustmentFullView = <TransactionAdjustmentViewOptions>{};
  public static readonly TransactionPaymentFullView = <TransactionPaymentViewOptions>{ includeCardTransactions: true };
  public static readonly TransactionChargeAdjustmentFullView = <TransactionChargeAdjustmentViewOptions>{};
  public static readonly TransactionChargeFullView = <TransactionChargeViewOptions>{ includeAdjustments: TransactionService.TransactionChargeAdjustmentFullView };
  public static readonly TransactionIndexView = <TransactionViewOptions>{};
  public static readonly TransactionFullView = <TransactionViewOptions>{
    includeGuests: {},
    includeItems: TransactionService.TransactionItemFullView,
    includeCharges: TransactionService.TransactionChargeFullView,
    includeAdjustments: TransactionService.TransactionAdjustmentFullView,
    includePayments: TransactionService.TransactionPaymentFullView,
    includeDisbursements: true
  };

  private readonly endPoint = 'api/transaction/transaction';

  constructor(
    private httpService: HttpService,
    private transactionServiceSettings: TransactionServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Transaction>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'getByUid').pipe(
      map(x => TransactionModelFactory.assignTransaction(x))
    );
  }

  getByUids(uids: string[], viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction[]> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `query { getByUids (uids: ${JSON.stringify(uids)}) ${view} }`
    };

    return this.httpService.graph<Transaction[]>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'getByUids').pipe(
      map(x => x.map(y => TransactionModelFactory.assignTransaction(y)))
    );
  }

  search$(openedDateRangeFilter: DateRangeFilterInput, closedDateRangeFilter: DateRangeFilterInput, statusUids: string[], paginationInput: PaginationInput, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<TransactionSearchPage> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `query search($openedDateRangeFilter:DateRangeFilterInput, $closedDateRangeFilter:DateRangeFilterInput, $pagination:PaginationInput) { search(statuses: ${JSON.stringify(statusUids)}, openedDateRangeFilter: $openedDateRangeFilter, closedDateRangeFilter: $closedDateRangeFilter, pagination: $pagination) { totalCount totalValue edges { node } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: {
        openedDateRangeFilter: openedDateRangeFilter,
        closedDateRangeFilter: closedDateRangeFilter,
        pagination: paginationInput
      }
    };

    return this.httpService.graph<TransactionSearchPage>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = edge.node))
    );
  }

  metrics(statusUids: string[], dateRangeFilter: DateRangeFilterInput): Observable<Metrics> {

    var request = {
      query: `query metrics($dateRangeFilter:DateRangeFilterInput) { metrics(statusUids: ${JSON.stringify(statusUids)}, dateRangeFilter: $dateRangeFilter) { measures { name value } } }`,
      variables: { dateRangeFilter: dateRangeFilter }
    };

    return this.httpService.graph<Metrics>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'metrics');
  }

  getByLockUid(lockUid: string, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction[]> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `query { getByLockUid(lockUid: "${lockUid}") ${view} }`
    };

    return this.httpService.graph<Transaction[]>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'getByLockUid').pipe(
      map(x => x.map(y => TransactionModelFactory.assignTransaction(y)))
    );
  }

  addLock(uid: string, lockUid: string, force: boolean = false, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `mutation addLock { addLock(transactionUid: "${uid}", lockUid: "${lockUid}", force: ${force}) ${view} }`
    };

    return this.httpService.graph<Transaction>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'addLock').pipe(
      map(x => TransactionModelFactory.assignTransaction(x))
    );
  }

  removeLock(uid: string, lockUid: string, force: boolean = false, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `mutation removeLock { removeLock(transactionUid: "${uid}", lockUid: "${lockUid}", force: ${force}) ${view} }`
    };

    return this.httpService.graph<Transaction>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'removeLock').pipe(
      map(x => TransactionModelFactory.assignTransaction(x))
    );
  }

  open(uid: string, transactionNumber: string, logisticTypeUid: string, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `mutation open { open(transactionUid: "${uid}", transactionNumber: "${transactionNumber}", logisticTypeUid: "${logisticTypeUid}") ${view} }`
    };

    return this.httpService.graph<Transaction>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'open').pipe(
      map(x => TransactionModelFactory.assignTransaction(x))
    );
  }

  cancel(uid: string, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `mutation cancel { cancel(transactionUid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Transaction>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'cancel').pipe(
      map(x => TransactionModelFactory.assignTransaction(x))
    );
  }

  updateNotes(transactionUid: string, notes: string, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction> {

    let view = TransactionService.buildView(viewOptions);

    // var escapedNotes = notes.replace(/\\\\/g, '\\');
    var escapedNotes = notes;
    var request = {
      query: `mutation updateNotes { updateNotes(transactionUid: "${transactionUid}", notes: ${(escapedNotes ? (`"${escapedNotes}"`) : ('null'))}) ${view} }`
    };

    return this.httpService.graph<Transaction>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'updateNotes').pipe(
      map(x => TransactionModelFactory.assignTransaction(x))
    );
  }

  updateHoldCardReference(transactionUid: string, holdCardReference: string, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `mutation updateHoldCardReference { updateHoldCardReference(transactionUid: "${transactionUid}", holdCardReference: ${(holdCardReference ? (`"${holdCardReference}"`) : ('null'))}) ${view} }`
    };

    return this.httpService.graph<Transaction>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'updateHoldCardReference').pipe(
      map(x => TransactionModelFactory.assignTransaction(x))
    );
  }

  updateLogisticType(transactionUid: string, logisticTypeUid: string, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `mutation updateLogisticType { updateLogisticType(transactionUid: "${transactionUid}", logisticTypeUid: "${logisticTypeUid}") ${view} }`
    };

    return this.httpService.graph<Transaction>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'updateLogisticType').pipe(
      map(x => TransactionModelFactory.assignTransaction(x))
    );
  }

  close(uid: string, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `mutation Close { close(transactionUid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Transaction>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'close').pipe(
      map(x => TransactionModelFactory.assignTransaction(x))
    );
  }

  reopen(uid: string, lockUid: string, viewOptions: TransactionViewOptions = TransactionService.TransactionFullView): Observable<Transaction> {

    let view = TransactionService.buildView(viewOptions);

    var request = {
      query: `mutation Reopen { reopen(transactionUid: "${uid}", lockUid: "${lockUid}") ${view} }`
    };

    return this.httpService.graph<Transaction>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'reopen').pipe(
      map(x => TransactionModelFactory.assignTransaction(x))
    );
  }

  addGuest(transactionUid: string, guestInput: TransactionGuestInput, viewOptions: TransactionGuestViewOptions = TransactionService.TransactionGuestFullView): Observable<TransactionGuest> {

    let view = TransactionService.buildTransactionGuestView(viewOptions);

    var request = {
      query: `mutation addGuest($guest:TransactionGuestInput!) { addGuest(transactionUid: "${transactionUid}", guest: $guest) ${view} }`,
      variables: { guest: guestInput }
    };

    return this.httpService.graph<TransactionGuest>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'addGuest').pipe(
      map(x => TransactionModelFactory.assignTransactionGuest(x))
    );
  }

  addItem(transactionUid: string, itemInput: TransactionItemInput, viewOptions: TransactionItemViewOptions = TransactionService.TransactionFullView): Observable<TransactionItem> {

    let view = TransactionService.buildTransactionItemView(viewOptions);

    var request = {
      query: `mutation addItem($item:TransactionItemInput!) { addItem(transactionUid: "${transactionUid}", item: $item) ${view} }`,
      variables: { item: itemInput }
    };

    return this.httpService.graph<TransactionItem>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'addItem').pipe(
      map(x => TransactionModelFactory.assignTransactionItem(x))
    );
  }

  changeItem(transactionUid: string, itemInput: TransactionItemInput, viewOptions: TransactionItemViewOptions = TransactionService.TransactionFullView): Observable<TransactionItem> {

    let view = TransactionService.buildTransactionItemView(viewOptions);

    var request = {
      query: `mutation changeItem($item:TransactionItemInput!) { changeItem(transactionUid: "${transactionUid}", item: $item) ${view} }`,
      variables: { item: itemInput }
    };

    return this.httpService.graph<TransactionItem>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'changeItem').pipe(
      map(x => TransactionModelFactory.assignTransactionItem(x))
    );
  }

  voidItem(transactionUid: string, itemUid: string, itemAdjustmentUid: string, viewOptions: TransactionItemViewOptions): Observable<TransactionItemAdjustment> {

    let view = TransactionService.buildTransactionItemView(viewOptions);

    var request = {
      query: `mutation voidItem { voidItem(transactionUid: "${transactionUid}", itemUid: "${itemUid}", itemAdjustmentUid: "${itemAdjustmentUid}") ${view} }`
    };

    return this.httpService.graph<TransactionItemAdjustment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'voidItem').pipe(
      map(x => TransactionModelFactory.assignTransactionItemAdjustment(x))
    );
  }

  addItemAdjustment(transactionUid: string, itemUid: string, itemAdjustmentInput: TransactionItemAdjustmentInput, viewOptions: TransactionItemAdjustmentViewOptions = TransactionService.TransactionItemAdjustmentFullView): Observable<TransactionItemAdjustment> {

    let view = TransactionService.buildTransactionItemAdjustmentView(viewOptions);

    var request = {
      query: `mutation addItemAdjustment($itemAdjustment:TransactionItemAdjustmentInput!) { addItemAdjustment(transactionUid: "${transactionUid}", itemUid: "${itemUid}", itemAdjustment: $itemAdjustment) ${view} }`,
      variables: { itemAdjustment: itemAdjustmentInput }
    };

    return this.httpService.graph<TransactionItemAdjustment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'addItemAdjustment').pipe(
      map(x => TransactionModelFactory.assignTransactionItemAdjustment(x))
    );
  }

  changeItemAdjustment(transactionUid: string, transactionItemUid: string, itemAdjustmentInput: TransactionItemAdjustmentInput, viewOptions: TransactionItemAdjustmentViewOptions = TransactionService.TransactionItemAdjustmentFullView): Observable<TransactionItemAdjustment> {

    let view = TransactionService.buildTransactionItemAdjustmentView(viewOptions);

    var request = {
      query: `mutation changeItemAdjustment($itemAdjustment:TransactionItemAdjustmentInput!) { changeItemAdjustment(transactionUid: "${transactionUid}", itemUid: "${transactionItemUid}", itemAdjustment: $itemAdjustment) ${view} }`,
      variables: { itemAdjustment: itemAdjustmentInput }
    };

    return this.httpService.graph<TransactionItemAdjustment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'changeItemAdjustment').pipe(
      map(x => TransactionModelFactory.assignTransactionItemAdjustment(x))
    );
  }

  cancelItemAdjustment(transactionUid: string, itemUid: string, itemAdjustmentUid: string, viewOptions: TransactionItemAdjustmentViewOptions = TransactionService.TransactionItemAdjustmentFullView): Observable<TransactionItemAdjustment> {

    let view = TransactionService.buildTransactionItemAdjustmentView(viewOptions);

    var request = {
      query: `mutation cancelItemAdjustment { cancelItemAdjustment(transactionUid: "${transactionUid}", itemUid: "${itemUid}", itemAdjustmentUid: "${itemAdjustmentUid}") ${view} }`
    };

    return this.httpService.graph<TransactionItemAdjustment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'cancelItemAdjustment').pipe(
      map(x => TransactionModelFactory.assignTransactionItemAdjustment(x))
    );
  }

  addCharge(transactionUid: string, chargeInput: TransactionChargeInput, viewOptions: TransactionChargeViewOptions = TransactionService.TransactionChargeFullView): Observable<TransactionCharge> {

    let view = TransactionService.buildTransactionChargeView(viewOptions);

    var request = {
      query: `mutation addCharge($charge:TransactionChargeInput!) { addCharge(transactionUid: "${transactionUid}", charge: $charge) ${view} }`,
      variables: { charge: chargeInput }
    };

    return this.httpService.graph<TransactionCharge>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'addCharge').pipe(
      map(x => TransactionModelFactory.assignTransactionCharge(x))
    );
  }

  updateCharge(transactionUid: string, chargeInput: TransactionChargeInput, viewOptions: TransactionChargeViewOptions = TransactionService.TransactionItemAdjustmentFullView): Observable<TransactionCharge> {

    let view = TransactionService.buildTransactionChargeView(viewOptions);

    var request = {
      query: `mutation updateCharge($charge:TransactionChargeInput!) { updateCharge(transactionUid: "${transactionUid}", charge: $charge) ${view} }`,
      variables: { charge: chargeInput }
    };

    return this.httpService.graph<TransactionCharge>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'updateCharge').pipe(
      map(x => TransactionModelFactory.assignTransactionCharge(x))
    );
  }

  cancelCharge(transactionUid: string, chargeUid: string, viewOptions: TransactionChargeViewOptions = TransactionService.TransactionChargeFullView): Observable<TransactionCharge> {

    let view = TransactionService.buildTransactionChargeView(viewOptions);

    var request = {
      query: `mutation cancelCharge { cancelCharge(transactionUid: "${transactionUid}", chargeUid: "${chargeUid}") ${view} }`
    };

    return this.httpService.graph<TransactionCharge>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'cancelCharge').pipe(
      map(x => TransactionModelFactory.assignTransactionCharge(x))
    );
  }

  addChargeAdjustment(transactionUid: string, chargeUid: string, chargeAdjustmentInput: TransactionChargeAdjustmentInput, viewOptions: TransactionChargeAdjustmentViewOptions = TransactionService.TransactionChargeAdjustmentFullView): Observable<TransactionChargeAdjustment> {

    let view = TransactionService.buildTransactionChargeAdjustmentView(viewOptions);

    var request = {
      query: `mutation addChargeAdjustment($chargeAdjustment:TransactionChargeAdjustmentInput!) { addChargeAdjustment(transactionUid: "${transactionUid}", chargeUid: "${chargeUid}", chargeAdjustment: $chargeAdjustment) ${view} }`,
      variables: { chargeAdjustment: chargeAdjustmentInput }
    };

    return this.httpService.graph<TransactionChargeAdjustment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'addChargeAdjustment').pipe(
      map(x => TransactionModelFactory.assignTransactionChargeAdjustment(x))
    );
  }

  updateChargeAdjustment(transactionUid: string, chargeUid: string, chargeAdjustmentInput: TransactionChargeAdjustmentInput, viewOptions: TransactionChargeAdjustmentViewOptions = TransactionService.TransactionChargeAdjustmentFullView): Observable<TransactionChargeAdjustment> {

    let view = TransactionService.buildTransactionChargeAdjustmentView(viewOptions);

    var request = {
      query: `mutation updateChargeAdjustment($chargeAdjustment:TransactionChargeAdjustmentInput!) { updateChargeAdjustment(transactionUid: "${transactionUid}", chargeUid: "${chargeUid}", chargeAdjustment: $chargeAdjustment) ${view} }`,
      variables: { chargeAdjustment: chargeAdjustmentInput }
    };

    return this.httpService.graph<TransactionChargeAdjustment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'updateChargeAdjustment').pipe(
      map(x => TransactionModelFactory.assignTransactionChargeAdjustment(x))
    );
  }

  cancelChargeAdjustment(transactionUid: string, chargeUid: string, chargeAdjustmentUid: string, viewOptions: TransactionChargeAdjustmentViewOptions = TransactionService.TransactionChargeAdjustmentFullView): Observable<TransactionChargeAdjustment> {

    let view = TransactionService.buildTransactionChargeAdjustmentView(viewOptions);

    var request = {
      query: `mutation cancelChargeAdjustment { cancelChargeAdjustment(transactionUid: "${transactionUid}", chargeUid: "${chargeUid}", chargeAdjustmentUid: "${chargeAdjustmentUid}") ${view} }`
    };

    return this.httpService.graph<TransactionItemAdjustment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'cancelChargeAdjustment').pipe(
      map(x => TransactionModelFactory.assignTransactionChargeAdjustment(x))
    );
  }

  addAdjustment(transactionUid: string, adjustmentInput: TransactionAdjustment, viewOptions: TransactionAdjustmentViewOptions = TransactionService.TransactionItemAdjustmentFullView): Observable<TransactionAdjustment> {

    let view = TransactionService.buildTransactionAdjustmentView(viewOptions);

    var request = {
      query: `mutation addAdjustment($adjustment:TransactionAdjustmentInput!) { addAdjustment(transactionUid: "${transactionUid}", adjustment: $adjustment) ${view} }`,
      variables: { adjustment: adjustmentInput }
    };

    return this.httpService.graph<TransactionAdjustment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'addAdjustment').pipe(
      map(x => TransactionModelFactory.assignTransactionAdjustment(x))
    );
  }

  changeAdjustment(transactionUid: string, adjustmentInput: TransactionAdjustment, viewOptions: TransactionAdjustmentViewOptions = TransactionService.TransactionAdjustmentFullView): Observable<TransactionAdjustment> {

    let view = TransactionService.buildTransactionAdjustmentView(viewOptions);

    var request = {
      query: `mutation changeAdjustment($adjustment:TransactionAdjustmentInput!) { changeAdjustment(transactionUid: "${transactionUid}", adjustment: $adjustment) ${view} }`,
      variables: { adjustment: adjustmentInput }
    };

    return this.httpService.graph<TransactionAdjustment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'changeAdjustment').pipe(
      map(x => TransactionModelFactory.assignTransactionAdjustment(x))
    );
  }

  cancelAdjustment(transactionUid: string, adjustmentUid: string, viewOptions: TransactionAdjustmentViewOptions = TransactionService.TransactionAdjustmentFullView): Observable<TransactionAdjustment> {

    let view = TransactionService.buildTransactionAdjustmentView(viewOptions);

    var request = {
      query: `mutation cancelAdjustment { cancelAdjustment(transactionUid: "${transactionUid}", adjustmentUid: "${adjustmentUid}") ${view} }`
    };

    return this.httpService.graph<TransactionAdjustment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'cancelAdjustment').pipe(
      map(x => TransactionModelFactory.assignTransactionAdjustment(x))
    );
  }

  addPayment(transactionUid: string, paymentInput: TransactionPaymentInput, viewOptions: TransactionPaymentViewOptions = TransactionService.TransactionPaymentFullView): Observable<TransactionPayment> {

    let view = TransactionService.buildTransactionPaymentView(viewOptions);

    var request = {
      query: `mutation addPayment($payment:TransactionPaymentInput!) { addPayment(transactionUid: "${transactionUid}", payment: $payment) ${view} }`,
      variables: { payment: paymentInput }
    };

    return this.httpService.graph<TransactionPayment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'addPayment').pipe(
      map(x => TransactionModelFactory.assignTransactionPayment(x))
    );
  }

  changePayment(transactionUid: string, paymentInput: TransactionPaymentInput, viewOptions: TransactionPaymentViewOptions = TransactionService.TransactionPaymentFullView): Observable<TransactionPayment> {

    let view = TransactionService.buildTransactionPaymentView(viewOptions);

    var request = {
      query: `mutation changePayment($payment:TransactionPaymentInput!) { changePayment(transactionUid: "${transactionUid}", payment: $payment) ${view} }`,
      variables: { payment: paymentInput }
    };

    return this.httpService.graph<TransactionPayment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'changePayment').pipe(
      map(x => TransactionModelFactory.assignTransactionPayment(x))
    );
  }

  cancelPayment(transactionUid: string, paymentUid: string, viewOptions: TransactionPaymentViewOptions = TransactionService.TransactionPaymentFullView): Observable<TransactionPayment> {

    let view = TransactionService.buildTransactionPaymentView(viewOptions);

    var request = {
      query: `mutation cancelPayment { cancelPayment(transactionUid: "${transactionUid}", paymentUid: "${paymentUid}") ${view} }`
    };

    return this.httpService.graph<TransactionPayment>(this.transactionServiceSettings.apiUrl, this.endPoint, request, 'cancelPayment').pipe(
      map(x => TransactionModelFactory.assignTransactionPayment(x))
    );
  }

  public static buildView(viewOptions: TransactionViewOptions) {

    return TransactionService.buildTransactionView(viewOptions, 0);
  }

  public static buildTransactionView(viewOptions: TransactionViewOptions, depth: number = 0) {

    let view = `uid number notes holdCardReference logisticTypeUid transactionStatus lockUid lastGuestUid openDateTimeUtc openAuthUid closeDateTimeUtc closeAuthUid isAdjusted total totalDue`;

    if (viewOptions.includeGuests) {
      view += ` guests ${TransactionService.buildTransactionGuestView(viewOptions.includeGuests)}`;
    }
    if (viewOptions.includeItems) {
      view += ` items ${TransactionService.buildTransactionItemView(viewOptions.includeItems, depth)}`;
    }
    if (viewOptions.includeAdjustments) {
      view += ` adjustments ${TransactionService.buildTransactionAdjustmentView(viewOptions.includeAdjustments)}`;
    }
    if (viewOptions.includeCharges) {
      view += ` charges ${TransactionService.buildTransactionChargeView(viewOptions.includeCharges)}`;
    }
    if (viewOptions.includePayments) {
      view += ` payments ${TransactionService.buildTransactionPaymentView(viewOptions.includePayments)}`;
    }
    if (viewOptions.includeDisbursements) {
      view += ` disbursements { uid drawerUid paymentMethodUid amount }`;
    }

    return `{ ${view} }`;
  }

  public static buildTransactionGuestView(viewOptions: TransactionGuestViewOptions): string {

    return `{ uid name }`;

  }

  public static buildTransactionItemView(viewOptions: TransactionItemViewOptions, depth: number = 0): string {

    let view = `uid departmentUid categoryUid productUid productVersion guestUid quantity eachAmount notes`;

    view += ` configuration ${TransactionService.buildConfigurationView(depth)}`;

    if (viewOptions?.includeAdjustments) {
      view += ` adjustments ${TransactionService.buildTransactionItemAdjustmentView(viewOptions.includeAdjustments)}`;
    }

    return `{ ${view} }`;
  }

  public static buildConfigurationView(depth: number = 0): string {

    let view = '';

    view += ` portion ${this.buildConfigurationPortionsView(depth)}`;

    return '{ ' + view + ' }';
  }

  public static buildConfigurationPortionsView(depth: number = 0): string {

    let view = `portionUid notes`;

    view += ` variations ${TransactionService.buildConfigurationVariationsView()}`;
    view += ` preparations ${TransactionService.buildConfigurationPreparationsView()}`;
    view += ` inclusionGroups ${TransactionService.buildConfigurationInclusionGroupsView()}`;

    if (depth == 0) {
      view += ` addOns ${TransactionService.buildConfigurationAddOnsView(depth)}`;
    }

    return '{ ' + view + ' }';
  }

  public static buildConfigurationPreparationsView(): string {

    return '{ preparationUid optionUid }';
  }

  public static buildConfigurationVariationsView(): string {

    return '{ variationUid optionUid productUid productVersion productPortionUid }';
  }

  public static buildConfigurationInclusionGroupsView(): string {

    return `{ inclusionGroupUid options ${this.buildConfigurationInclusionGroupOptionsView()} }`;
  }

  public static buildConfigurationInclusionGroupOptionsView(): string {

    let view = `optionUid productUid productVersion productPortionUid quantity isSubstitution notes`;

    view += ` variations ${this.buildConfigurationVariationsView()}`;
    view += ` preparations ${this.buildConfigurationPreparationsView()}`;

    return '{ ' + view + ' }';
  }

  public static buildConfigurationAddOnsView(depth: number = 0): string {

    let view = `addOnUid item ${TransactionService.buildTransactionItemView(null, depth + 1)}`;

    return '{ ' + view + ' }';
  }

  public static buildTransactionItemAdjustmentView(viewOptions: TransactionItemAdjustmentViewOptions): string {

    return `{ uid description quantity eachAmount isVoid isCompensated }`;
  }

  public static buildTransactionChargeView(viewOptions: TransactionChargeViewOptions): string {

    let view = `uid chargeTypeUid description quantity eachAmount`;

    if (viewOptions?.includeAdjustments) {
      view += ` adjustments ${TransactionService.buildTransactionChargeAdjustmentView(viewOptions.includeAdjustments)}`;
    }

    return `{ ${view} }`;
  }

  public static buildTransactionChargeAdjustmentView(viewOptions: TransactionChargeAdjustmentViewOptions): string {

    return `{ uid description quantity eachAmount isVoid isCompensated }`;
  }

  public static buildTransactionAdjustmentView(viewOptions: TransactionAdjustmentViewOptions): string {

    return `{ uid description amount isVoid isCompensated }`;
  }

  public static buildTransactionPaymentView(viewOptions: TransactionPaymentViewOptions): string {

    let view = `uid paymentMethodUid amount referenceUid metadata { key value }`;

    if (viewOptions.includeCardTransactions) {
      view += ` cardTransaction { uid lastFour amount tip cardTransactionStatusUid }`;
    }

    return `{ ${view} }`;
  }
}

export interface TransactionViewOptions {

  includeGuests?: TransactionGuestViewOptions;
  includeItems?: TransactionItemViewOptions;
  includeAdjustments?: TransactionAdjustmentViewOptions;
  includeCharges?: TransactionChargeViewOptions;
  includePayments?: TransactionPaymentViewOptions;
  includeDisbursements?: boolean;
}

export interface TransactionGuestViewOptions {

}

export interface TransactionItemViewOptions {

  includeAdjustments?: TransactionItemAdjustmentViewOptions;
}

export interface TransactionItemAdjustmentViewOptions {

}

export interface TransactionChargeViewOptions {

  includeAdjustments?: TransactionChargeAdjustmentViewOptions;
}

export interface TransactionChargeAdjustmentViewOptions {

}

export interface TransactionAdjustmentViewOptions {

}

export interface TransactionPaymentViewOptions {

  includeCardTransactions?: boolean;
}
