<div class="option" (click)="context.select(itemAddOn)" [ngClass]="{ 'selected': context.selection == itemAddOn }">

  <div class="display-line">
    <div *ngIf="addOnProduct && itemAddOn.item?.configuration">
      <ng-template item-configurator-detail [parentContext]="context" [product]="addOnProduct" [itemConfiguration]="itemAddOn.item.configuration.value" [isReadOnly]="true" (configurationChanged)="handleConfigurationChanged"></ng-template>
    </div>
  </div>

  <!-- Context Menu -->
  <div *ngIf="!context.isReadOnly && (context.selection == itemAddOn)" [@slideIn] [@slideOut] class="context-menu">
    <div class="left">
      <div style="height: 100%; background-color: white; border-top-right-radius: 4px;"></div>
    </div>
    <div class="center">
      <button mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="edit()" click-stop-propagation>Edit</button>
      <button mat-flat-button color="primary" style="margin-left: 2px; margin-right: 2px;" (click)="remove()" click-stop-propagation>Remove</button>
    </div>
    <div class="right">
      <div style="height: 100%; background-color: white; border-top-left-radius: 4px;"></div>
    </div>
  </div>
</div>