import { Injectable } from '@angular/core';
import { SystemMessageConfig, SystemMessageProvider, SystemMessageService } from 'core';
import { TerminalProvider } from 'downmain-terminal';
import { takeUntil } from 'rxjs';

@Injectable()
export class SiteSystemMessageProvider extends SystemMessageProvider {

  constructor(
    private terminalProvider: TerminalProvider,
    systemMessageConfig: SystemMessageConfig,
    systemMessageService: SystemMessageService
  ) {
    super(systemMessageConfig, systemMessageService);

    this.terminalProvider.active$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(terminal => {
      this.nodeIdentifier = terminal ? `Site` : null; // Hard coded pattern for now - consider moving to a Settings entity
    });
  }
}
