<div [formGroup]="form" style="display: flex; margin-left: 10px;">

  <div style="flex: 1;">

    <div style="display: flex;">
      <button mat-button mat-button-flat style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-right: 5px;" (click)="newInclusionGroup()">New</button>
      <button *ngIf="scope == 'portion'" [disabled]="!canLinkProductInclusionGroups" mat-button mat-button-stroked style="flex: 1; border: 1px solid rgba(255, 255, 255, 0.12); margin-left: 5px;" (click)="linkInclusionGroup()">Link Product Inclusion Group</button>
    </div>

    <mat-selection-list cdkDropList class="draggable-list" [multiple]="false" (cdkDropListDropped)="dropInclusionGroup($event)">

      <ng-container *ngIf="form?.get('inclusionGroups') != null">

        <mat-list-option cdkDrag *ngFor="let inclusionGroupForm of $any(form.get('inclusionGroups')).controls" [value]="inclusionGroupForm" class="item">
          <div style="display: flex; align-items: center;">
            <div style="width: 25px; margin-right: 5px;">
              <mat-icon>drag_indicator</mat-icon>
            </div>
            <div style="flex: 1;" (click)="editInclusionGroup(inclusionGroupForm)">
              <span>{{ getResolvedInclusionGroupFormValue(inclusionGroupForm.get('uid').value, 'name') }}</span>
              <mat-icon *ngIf="scope == 'portion' && getProductInclusionGroupForm(inclusionGroupForm)">link</mat-icon>
            </div>
            <mat-icon (click)="deleteInclusionGroup(inclusionGroupForm)">delete</mat-icon>
          </div>
        </mat-list-option>

      </ng-container>

    </mat-selection-list>

  </div>

  <div style="flex: 1; font-family: familyRaleway, sans-serif; font-size: 2em; line-height: 100%; padding: 0 10px;">
    <p>Inclusion Groups are groups of other product portions that can be included with this product with rules such as default options, minimum/maximum number of options in the group, and restricting additional options.</p>
    <p *ngIf="scope == 'product'">Inclusions Groups configured at the product level (Product Inclusions) are shared inclusion groups that can be included or ignored with all portions. Further, each portion has the ability to override the product level configuration.</p>
    <p *ngIf="scope == 'portion'">Inclusions Groups configured at the portion level can be unique to this portion or it can link a product level inclusion group and override any values if necessary.</p>
  </div>

</div>
