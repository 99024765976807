import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from 'rxjs';
import { ItemConfiguratorContext } from "../../item-configurator-context";
import { SelectListModalData, SelectListModalProvider, SettingProvider, TenantProvider, findInArrayByKey, findInArrayByUid, slideInAnimation, slideOutAnimation } from "core";
import { ProductConfigurationPreparation, ProductConfigurationPreparationOption } from "downtown-product";
import { EditableTransactionItemConfigurationPreparation, EditableTransactionItemConfigurationVariation, TransactionSettings } from "../../../../models";
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: 'transaction-item-configurator-detail-preparation',
  templateUrl: './item-configurator-detail-preparation.component.html',
  styleUrls: ['./item-configurator-detail-preparation.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class ItemConfiguratorDetailPreparationComponent {

  @Input() public context: ItemConfiguratorContext;
  @Input() public item: { preparations?: EditableTransactionItemConfigurationPreparation[] }
  @Input() public itemDisplay: Observable<string>
  @Input() public productPreparation: ProductConfigurationPreparation;
  @Input() public itemPreparation: EditableTransactionItemConfigurationPreparation;
  @Output() public configurationChanged = new EventEmitter();

  public transactionSettings$: Observable<TransactionSettings>;
  public preparationDisplay: string;

  constructor(
    private currencyPipe: CurrencyPipe,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider,
    private selectListModalProvider: SelectListModalProvider
  ) {
    this.transactionSettings$ = this.settingProvider.getOneByTypeAndOwner$<TransactionSettings>('TransactionSettings', this.tenantProvider.currentUid);
  }

  ngOnInit() {

    this.preparationDisplay = this.productPreparation.name;
  }

  public editPreparation() {

    const productPreparationOptions = [...this.productPreparation.options || []];
    if (this.productPreparation.allowNone) {
      productPreparationOptions.splice(0, 0, { uid: null, name: 'No Change', hideInPrintDetail: false, hideInUIDetail: false });
    }

    this.itemDisplay.subscribe(itemDisplay => {
      this.selectListModalProvider.open(<SelectListModalData<ProductConfigurationPreparationOption>>{
        title: `${itemDisplay} - ${this.productPreparation.name}`,
        options: productPreparationOptions,
        displayFunc: x => `${x.name} ${(x.additionalPrice ? `+ ${this.currencyPipe.transform(x.additionalPrice, 'USD', 'symbol', '1.2-2')} each` : '')}`
      }).afterClosed().subscribe(selection => {
        if (selection) {
          if (!this.itemPreparation) {
            this.itemPreparation = <EditableTransactionItemConfigurationPreparation>{
              preparationUid: this.productPreparation.uid,
            };

            this.item.preparations = this.item.preparations || [];
            this.item.preparations.push(this.itemPreparation);
          }

          this.itemPreparation.optionUid = (<ProductConfigurationPreparationOption>selection).uid;

          this.configurationChanged.emit();
        }
      });
    });
  }
}
