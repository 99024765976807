
export function findInArrayByUid<T extends { uid: string }>(array: T[], uid: string): T {

  var lowerCaseUid = uid?.toLowerCase();
  return lowerCaseUid ? array?.find(x => x?.uid?.toLowerCase() == lowerCaseUid) as T : null;
}

export function findInArrayByKey<T>(array: T[], key: string, value: string) {

  var lowerCaseValue = value?.toLowerCase();
  return lowerCaseValue ? array?.find(x => x == null ? null : ((x as any)[key] as string)?.toLowerCase() == lowerCaseValue) as T : null;
}

export function someInArrayByUid<T extends { uid: string }>(array: T[], uid: string): boolean {

  var lowerCaseUid = uid?.toLowerCase();
  return lowerCaseUid ? array?.some(x => x == null ? false : x.uid?.toLowerCase() == lowerCaseUid) : false;
}

export function filterFromArrayByKey<T extends { uid: string }>(array: T[], key: string, uid: string): T[] {

  var lowerCaseUid = uid?.toLowerCase();
  return lowerCaseUid ? array?.filter(x => x == null ? false : ((x as any)[key] as string).toLowerCase() == lowerCaseUid) : null;
}

export function sortByKey<T>(array: T[], key: string): T[] {

  return array.sort((a, b) => {
    if (a[key as keyof T] < b[key as keyof T]) {
      return -1;
    }
    if (a[key as keyof T] > b[key as keyof T]) {
      return 1;
    }
    return 0;
  });
}
