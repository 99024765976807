import { AuthIdentityProvider } from "../providers/auth-identity.provider";
import { IHttpOptions } from "../services/http.service";

export abstract class AuthHeaderProvider {

  constructor(
    protected authIdentityProvider: AuthIdentityProvider
  ) {

  }

  abstract apply(options: IHttpOptions, authToken: string): IHttpOptions;
}
