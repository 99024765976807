<div style="display: flex; flex-direction: column; height: 100%;">

  <div style="flex: 1;">

    <div style="display: flex;">

      <app-register-button (click)="navigateHome()">
        <mat-icon style="font-weight: bold;">home</mat-icon>
      </app-register-button>
      <app-register-button (click)="navigateToBackOffice()">Back Office</app-register-button>
      <app-register-button (click)="showOpenTransactions()" [disabled]="(openTransactions$ | async).length == 0">
        Open Transactions&nbsp;({{ (openTransactions$ | async).length }})
      </app-register-button>
      <app-register-button (click)="showRecentClosedTransactions()" [disabled]="(recentTransactions$ | async).length == 0">
        Recent Closed Transactions&nbsp;({{ (recentTransactions$ | async).length }})
      </app-register-button>
      <!-- <app-register-button (click)="showRecentClosedTransactions()" [disabled]="(recentTransactions$ | async).length == 0">
        Tables&nbsp;({{ (recentTransactions$ | async).length }})
      </app-register-button> -->
      <div style="flex: 4;" *ngFor="let activeSchedule of (activeSchedules$ | async)">
        <div *ngIf="(activeSchedules$ | async)?.length > 0" [@slideIn] [@slideOut] style="height: 100%; width: 100%;">
          <app-register-button (click)="showScheduleDescription(activeSchedule)" style="height: 100%; width: 100%;">
            {{ activeSchedule.heading }}
          </app-register-button>
        </div>
      </div>
      <div style="flex: 4;"></div>

    </div>

  </div>

  <div style="flex: 11; display: flex; overflow: hidden;">

    <!-- left side -->
    <div style="flex: 4; height: 100%;">
      <transaction-transaction-editor #editor [editableTransaction]="editableTransaction" [percent]="timeoutPercent" (select)="lineItemSelect($event)" (edit)="lineItemEdit($event)" (void)="lineItemVoid($event)" (comp)="lineItemComp($event)" (changeType)="lineItemChangeType($event)" (cancel)="lineItemCancel($event)" (print)="printItem($event)" (guest)="updateGuest($event)" (updateNotes)="showUpdateNotes()" (updateLocation)="updateLogistics()"></transaction-transaction-editor>
    </div>

    <!-- right side -->
    <div style="flex: 8; display: flex; flex-direction: column; height: 100%;">

      <div style="flex: 1;"></div>

      <div style="flex: 5; display: flex;">
        <div style="flex: 3;">
          <app-department-category-pad (onButtonPressed)="departmentPressed($event)"></app-department-category-pad>
        </div>
        <div style="flex: 2;">
          <app-number-speed-pad (onButtonPressed)="speedValuePressed($event)"></app-number-speed-pad>
        </div>
        <div style="flex: 3;">
          <app-number-pad (onButtonPressed)="numberPressed($event)"></app-number-pad>
        </div>
      </div>

      <div style="flex: 3;"></div>

      <div style="flex: 1; display: flex;">
        <app-register-button [disabled]="!canPrintCheck" (click)="printCheck()">Print Check</app-register-button>
        <app-register-button [disabled]="!canPrintKitchen" (click)="printDepartmentSlip('46C1731B-DBEA-4C6E-B822-F56B80A58254')">Print Kitchen Slip</app-register-button>
        <!-- <app-register-button [disabled]="!canPrintKitchen" (click)="showGuestServies()">Guest Services</app-register-button> -->
        <div style="flex: 1;"></div>
        <div style="flex: 1;"></div>
        <app-register-button [disabled]="!canAddSaleComp" (click)="addTransactionAdjustment()">Add Comp</app-register-button>
        <app-register-button [disabled]="!canTenderCash" (click)="addCashPayment()">Cash Payment</app-register-button>
        <app-register-button [disabled]="!canSaveGuestCheck" (click)="parkCheck()">Park</app-register-button>
        <app-register-button [disabled]="!canCloseCardSale" (click)="settleTransactionCard()">Settle Card</app-register-button>
      </div>

      <div style="flex: 1; display: flex;">
        <app-register-button [disabled]="!canPrintReceipt" (click)="printReceipt()">Print Receipt</app-register-button>
        <app-register-button [disabled]="!canPrintCardReceipt" (click)="printCardReceipt()">Print Card Receipt</app-register-button>
        <div style="flex: 2;"></div>
        <app-register-button [disabled]="!canAddSaleComp" (click)="addTransactionCharge()">Add Charge</app-register-button>
        <app-register-button [disabled]="!canOpenDrawer" (click)="openCashDrawer()">Open Drawer</app-register-button>
        <app-register-button [disabled]="!canTenderCard" (click)="addCardPayment()">Card Payment</app-register-button>
        <app-register-button [disabled]="!canCloseCashSale" (click)="settleTransactionCash()">Settle Cash</app-register-button>
      </div>

    </div>

  </div>

</div>