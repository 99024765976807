<div style="border-radius: 4px;" [style.background-color]="context.selection == itemPortion ? 'gray' : 'transparent'" (click)="context.select(itemPortion)">
  {{ display }}
  <span *ngIf="itemPortion.preparations && itemPortion.preparations.length > 0">
    &nbsp;(
    <span *ngFor="let preparation of itemPortion.preparations; let last = last">
      {{ getPortionPreparationOptionDisplay(itemPortion.portionUid, preparation.preparationUid, preparation.optionUid) }}
      <span *ngIf="!last">/</span>
    </span>
    )
  </span>

  <span *ngIf="itemPortion.variations && itemPortion.variations.length > 0">
    &nbsp;+
    <span *ngFor="let variation of itemPortion.variations; let last = last">
      {{ getPortionVariationOptionDisplay(itemPortion.portionUid, variation.variationUid, variation.optionUid) | async }}
      <span *ngIf="!last">/</span>
    </span>
  </span>
    
  <div *ngIf="itemPortion?.notes?.length > 0" style="margin-left: 10%; margin-bottom: 24px;">
    <div *ngFor="let note of itemPortion.notes">
      {{ note }}
    </div>
  </div>
  
</div>

<div *ngFor="let itemInclusionGroup of getVisibleInclusionGroups()" style="margin-left: 10%;">
  <transaction-item-configurator-detail-inclusion-group [context]="context" [productInclusionGroup]="productPortion.getInclusionGroup(itemInclusionGroup.inclusionGroupUid)" [itemInclusionGroup]="itemInclusionGroup" (configurationChanged)="handleConfigurationChanged()"></transaction-item-configurator-detail-inclusion-group>
</div>

<div *ngIf="context.itemConfiguration?.portion.addOns?.length > 0">&nbsp;</div>

<div *ngFor="let itemAddOn of context.itemConfiguration?.portion.addOns">
  <transaction-item-configurator-detail-add-on [context]="context" [itemAddOn]="itemAddOn" (configurationChanged)="handleConfigurationChanged()"></transaction-item-configurator-detail-add-on>
</div>
