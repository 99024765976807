import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthHeaderProvider } from 'core';
import { SpinnerModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { Terminal, TerminalForm, TerminalService } from 'downmain-terminal';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent extends TerminalForm implements OnInit {

  public title: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    navigationProvider: NavigationProvider,
    spinnerModalProvider: SpinnerModalProvider,
    terminalService: TerminalService,
    authHeaderProvider: AuthHeaderProvider
  ) {
    super(navigationProvider, spinnerModalProvider, terminalService, authHeaderProvider);

    this.navigationProvider.setWaypoint('Terminal');
    this.title = 'Terminal';
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      let terminalUid = params['terminalUid'];

      if (terminalUid) {
        let spinnerModalRef = this.spinnerModalProvider.open();
        spinnerModalRef.afterOpened().subscribe(() => {
          this.terminalService.getByUid(terminalUid, this.authHeaderProvider).subscribe(position => {
            if (position) {
              this.loadForm(position);
              spinnerModalRef.close();
            }
          });
        });
      } else {
        this.loadForm(new Terminal());
      }
    });
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }
}
