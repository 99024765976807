<table class="themed" style="width: 100%;" mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="dataSource.selection.isMultipleSelection()" (change)="$event ? dataSource.masterToggle() : null" [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row) : null" [checked]="dataSource.selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="processorBatchId">
    <th mat-header-cell *matHeaderCellDef color="accent">Batch Id</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.processorBatchId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="count">
    <th mat-header-cell *matHeaderCellDef color="accent">Count</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.count }}
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef color="accent">Amount</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.amount | currency:'USD':'symbol':'1.2-2' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="creditSaleCount">
    <th mat-header-cell *matHeaderCellDef color="accent">Credit Sale #</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.creditSaleCount }}
    </td>
  </ng-container>

  <ng-container matColumnDef="creditSaleAmount">
    <th mat-header-cell *matHeaderCellDef color="accent">Credit Sale $</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.creditSaleAmount | currency:'USD':'symbol':'1.2-2' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="creditReturnCount">
    <th mat-header-cell *matHeaderCellDef color="accent">Credit Return #</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.creditReturnCount }}
    </td>
  </ng-container>

  <ng-container matColumnDef="creditReturnAmount">
    <th mat-header-cell *matHeaderCellDef color="accent">Credit Return $</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.creditReturnAmount | currency:'USD':'symbol':'1.2-2' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="debitSaleCount">
    <th mat-header-cell *matHeaderCellDef color="accent">Debit Sale #</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.debitSaleCount }}
    </td>
  </ng-container>

  <ng-container matColumnDef="debitSaleAmount">
    <th mat-header-cell *matHeaderCellDef color="accent">Debit Sale $</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.debitSaleAmount | currency:'USD':'symbol':'1.2-2' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="debitReturnCount">
    <th mat-header-cell *matHeaderCellDef color="accent">Debit Return #</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.debitReturnCount }}
    </td>
  </ng-container>

  <ng-container matColumnDef="debitReturnAmount">
    <th mat-header-cell *matHeaderCellDef color="accent">Debit Return $</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.debitReturnAmount | currency:'USD':'symbol':'1.2-2' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="openDateTimeUtc">
    <th mat-header-cell *matHeaderCellDef color="accent">Opened</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.openDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="modifiedDateTimeUtc">
    <th mat-header-cell *matHeaderCellDef color="accent">Modified</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.modifiedDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="closeDateTimeUtc">
    <th mat-header-cell *matHeaderCellDef color="accent">Closed</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.closeDateTimeUtc | date: '(E) M-dd-yyyy h:mm a' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="cardTransactionBatchStatusUid">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let cardTransactionBatch">
      {{ cardTransactionBatch.cardTransactionBatchStatusUid | cardTransactionBatchStatus }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="dataSource.selection.toggle(row)" [class]="{ selected: dataSource.selection.isSelected(row) }"></tr>

</table>
