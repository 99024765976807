import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { PaymentMethod } from "../models/payment-method";
import { PaymentMethodKeys } from "../keys";

@Injectable()
export class PaymentMethodService {

  private paymentMethods: PaymentMethod[];

  constructor(
  ) {
  }

  list(): Observable<PaymentMethod[]> {

    return of([
      { uid: PaymentMethodKeys.Cash, name: 'Cash' },
      { uid: PaymentMethodKeys.Card, name: 'Credit Card' },
      { uid: PaymentMethodKeys.Check, name: 'Check' }
    ]);
  }
}

