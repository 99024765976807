import { Pipe, PipeTransform } from '@angular/core';
import { CacheService } from 'core';
import { CategoryService } from '../services/category.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {

  constructor(
    private categoryService: CategoryService,
    private cacheService: CacheService
  ) {
  }

  transform(categoryUid: string): Observable<string> {

    if (categoryUid) {
      return this.cacheService.getOrAdd(
        categoryUid,
        () => this.categoryService.getByUid(categoryUid).pipe(shareReplay(1))
      ).pipe(
        map(category => {
          return category ? category.name : 'Unknown';
        })
      );
    }
    return null;
  }
}
