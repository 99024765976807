import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss']
})
export class FileSelectorComponent {

  @ViewChild('fileInput') fileInput: ElementRef;
  @Input('allowMultiple') allowMultiple: boolean;
  @Output('filesSelected') filesSelected = new EventEmitter();

  constructor(
  ) {

  }

  ngOnInit() {

  }

  onClick(){

    this.fileInput.nativeElement.click();
  }

  selectFile(imgFile: any) {

    if (imgFile.target.files) {
      this.filesSelected.emit(imgFile.target.files);
      this.fileInput.nativeElement.value = "";
    }
  }
}
