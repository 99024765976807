import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from "rxjs/operators";
import { ErrorHandlingProvider, IPagedDataSource, isEqualUUID } from "core";
import { PageInfo } from "core";
import { PaginationInput } from "core";
import { DrawerStatusKeys } from "../../../keys";
import { DrawerService, DrawerViewOptions } from "../../../services/drawer.service";
import { Drawer } from '../../../models/drawer';

@Component({
  selector: 'app-drawers-table',
  templateUrl: './drawers-table.component.html',
  styleUrls: ['./drawers-table.component.scss']
})
export class DrawersTableComponent {

  @Input() public dataSource: DrawerDataSource;
  @Input() public columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }

  edit(drawer: DrawerRowModel) {

  }

  delete(drawer: DrawerRowModel) {

  }
}

export class DrawerRowModel {

  drawer: Drawer;

  canClose: boolean;
  canKick: boolean;
  canDelete: boolean;
}

export class DrawerDataSource extends DataSource<DrawerRowModel> implements IPagedDataSource {

  public loading$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public pageInfo$: Observable<PageInfo>;
  public selection: SelectionModel<DrawerRowModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private dataSubject = new BehaviorSubject<DrawerRowModel[]>([]);
  private _canBulkEdit = false;
  private _canBulkDelete = false;

  constructor(
    private errorHandlingProvider: ErrorHandlingProvider,
    private drawerService: DrawerService,
    multiselect: boolean = false
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
    this.totalCount$ = this.totalCountSubject.asObservable();
    this.pageInfo$ = this.pageInfoSubject.asObservable();

    this.selection = new SelectionModel<DrawerRowModel>(multiselect, [], true);
    if (this.selection.isMultipleSelection()) {
      this.selection.changed.subscribe(() => this.evaluateBulkEnablement());
    }
  }

  public get canBulkEdit(): boolean {
    return this._canBulkEdit
  }

  public get canBulkDelete(): boolean {
    return this._canBulkDelete
  }

  connect(collectionViewer: CollectionViewer): Observable<DrawerRowModel[]> {

    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {

    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSubject.value.length;
    return numSelected == numRows;
  }

  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.dataSubject.value.forEach(row => this.selection.select(row));
  }

  loadData(statusUids: string[], paginationInput: PaginationInput, viewOptions: DrawerViewOptions = null) {

    this.loadingSubject.next(true);

    this.drawerService.search(null, null, null, statusUids, paginationInput, viewOptions).pipe(
      map(page => {
        this.totalCountSubject.next(page.totalCount);

        const drawers = page.edges.map(x => x.node).map(x => {
          let drawerModel = Object.assign(new DrawerRowModel(), {
            drawer: x
          });

          this.evaluateEnablement(drawerModel);

          return drawerModel;
        });

        return <[PageInfo, DrawerRowModel[]]>[page.pageInfo, drawers];
      }),
      catchError(() => of(<[PageInfo, DrawerRowModel[]]>[null, []])),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    ).subscribe(([pageInfo, data]) => {
      this.pageInfoSubject.next(pageInfo);
      this.dataSubject.next(data);
    });
  }

  public start(row: DrawerRowModel, seedAmount: number): Observable<DrawerRowModel> {

    return this.drawerService.start(row.drawer.uid, seedAmount).pipe(
      catchError(error => this.errorHandlingProvider.handleError<Drawer>(error)),
      map(drawer => {
        row.drawer.drawerStatusUid = drawer.drawerStatusUid;

        return this.evaluateEnablement(row);
      })
    );
  }

  public end(row: DrawerRowModel): Observable<DrawerRowModel> {

    return this.drawerService.end(row.drawer.uid).pipe(
      catchError(error => this.errorHandlingProvider.handleError<Drawer>(error)),
      map(drawer => {
        row.drawer.drawerStatusUid = drawer.drawerStatusUid;

        return this.evaluateEnablement(row);
      })
    );
  }

  private evaluateEnablement(drawer: DrawerRowModel): DrawerRowModel {

    let drawerStatusUid = drawer.drawer.drawerStatusUid.toUpperCase();

    drawer.canClose = isEqualUUID(drawerStatusUid, DrawerStatusKeys.Started);
    drawer.canKick = isEqualUUID(drawerStatusUid, DrawerStatusKeys.Started);
    drawer.canDelete = drawer.drawer.balance == drawer.drawer.seedAmount && !isEqualUUID(drawerStatusUid, DrawerStatusKeys.Deleted);

    return drawer;
  }

  private evaluateBulkEnablement() {

    const selected = this.selection.selected;

    this._canBulkEdit = selected.length == 1;
    this._canBulkDelete = selected.length == 1;
  }
}
