import { Pipe, PipeTransform } from '@angular/core';
import { getTransactionLineSummaryText } from '../model-helpers';
import { ProductProvider } from 'downtown-product';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { EditableTransactionItem } from '../models/editable-transaction/editable-transaction';

@Pipe({
  name: 'transactionConfigurationSummary'
})
export class TransactionConfigurationSummaryPipe implements PipeTransform {

  constructor(
    protected productProvider: ProductProvider,
  ) {
  }

  transform(transactionItem: EditableTransactionItem): Observable<string> {

    if (transactionItem) {
      return this.productProvider.getOneCached$(transactionItem.productUid.value, transactionItem.productVersion.value).pipe(
        concatMap(saleableProduct => {
          return getTransactionLineSummaryText(transactionItem.configuration.value, saleableProduct);
        })
      );
    }

    return of<string>(null);
  }
}
