import { Component, OnInit } from '@angular/core';
import { BlobServerSettings, BlobService, NavigationProvider } from 'core';

@Component({
  selector: 'app-administration-dam',
  templateUrl: './administration-dam.component.html',
  styleUrls: ['./administration-dam.component.scss']
})
export class AdministrationDamComponent implements OnInit {

  public title: string;

  constructor(
    private blobServerSettings: BlobServerSettings,
    private blobService: BlobService,
    private navigationProvider: NavigationProvider,
  ) {
    this.navigationProvider.setWaypoint("Digital Asset Management");
    this.title = 'Digital Asset Management';
 }

  ngOnInit() {


    this.blobService;
  }

  public navigateHome() {

    this.navigationProvider.navigate(['/']);
  }

  public navigateBack() {

    this.navigationProvider.backOneWaypoint();
  }
}
