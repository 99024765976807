import { Pipe, PipeTransform } from '@angular/core';
import { CardTransactionBatchStatusKeys } from '../keys';

@Pipe({
  name: 'cardTransactionBatchStatus'
})
export class CardTransactionBatchStatusPipe implements PipeTransform {

  private map = new Map<string, string>([
    [CardTransactionBatchStatusKeys.Open.toUpperCase(), 'Open'],
    [CardTransactionBatchStatusKeys.Closed.toUpperCase(), 'Closed'],
  ]);

  constructor(
  ) {
  }

  transform(value: string): string {

    if (typeof (value) === 'string') {
      return value ? this.map.get(value.toUpperCase()) : null;
    } else {
      return null;
    }
  }
}
