import { EditableCart, EditableCartItem } from './editable-cart';
import { CartStatusKeys } from '../keys';
import { Cart } from './cart';
import { LoggingProvider } from 'core';
import { EditableTransactionDataHandler, EditableTransactionItemConfiguration, EditableTransactionItemConfigurationAddOn, EditableTransactionItemConfigurationInclusionGroup, EditableTransactionItemConfigurationPortion, TransactionItem, TransactionItemConfiguration, TransactionItemConfigurationAddOn, TransactionItemConfigurationPortion } from 'downtown-transaction';
import { CartItem } from './cart-item';

export class EditableCartDataHandler {

  constructor(
    private loggingProvider: LoggingProvider
  ) {

  }

  getSelection(editableCart: EditableCart): EditableCartItem {

    return editableCart ? editableCart.selection.value : null;
  }

  // public getSelectedLineItem(): Observable<OrderLineItem> {

  //   return this.selectedLineItem;
  // }

  public setSelectedLineItem(editableCart: EditableCart, item: EditableCartItem): EditableCartItem {

    if (editableCart) {
      let selection = editableCart.selection.value;
      if (item != selection) {
        if (selection != null) {
          // currentLineItem.commit();
        }

        this.loggingProvider.log(`setting selection to ${item == null ? 'null' : 'lineItem'}`);

        editableCart.selection.next(item);
      }

      return editableCart.selection.value;
    } else {
      return null;
    }
  }

  getOrCreateCart(editableCart: EditableCart): EditableCart {

    if (editableCart == null || editableCart.cartStatusUid.value.toUpperCase() != CartStatusKeys.Open.toUpperCase()) {
      this.loggingProvider.log(`creating new cart`);
      editableCart = new EditableCart();
    }

    return editableCart;
  }

  // Why not just delete the cart ??
  cancelCart(editableCart: EditableCart) {

    if (editableCart && editableCart.cartStatusUid.value.toUpperCase() == CartStatusKeys.Open.toUpperCase()) {
      this.loggingProvider.log(`cancelling cart`);

      editableCart.cartStatusUid.next(CartStatusKeys.Cancelled);
    }

    return editableCart;
  }

  getOrCreateOrderLineItem(editableCart: EditableCart): EditableCartItem {

    var selection = this.getSelection(editableCart) as EditableCartItem;

    let items = editableCart.items;
    let item = selection && selection.uid ? items.find(x => x.uid.toLowerCase() == selection.uid.toLowerCase()) : selection;
    if (item == null) {
      this.loggingProvider.log(`creating new item`);

      item = editableCart.addCartItem();
      //     saleLineItem.makeEditable();
      //     this.synchronizeFeatureEnabling();
      //     if (transaction.getCurrentLineItem() == null) {
      //       this.lastEntryContext = LastEntryContextEnum.None;
      //     }

      //     setTimeout(() => this.editor.ensureActiveLineViewable(), 1);

    } else {
      //     if (transaction.getCurrentLineItem() == null) {
      //       this.lastEntryContext = LastEntryContextEnum.None;
      //     }

      //     return saleLineItem;
    }

    return item;
  }

  cancelItemChanges(editableCart: EditableCart, item: EditableCartItem): EditableCartItem {

    if (item) {
      if (item instanceof EditableCartItem) {
        return this.cancelOrderItem(editableCart, item);
      }
    }

    return null;
  }

  private cancelOrderItem(editableCart: EditableCart, item: EditableCartItem): EditableCartItem {

    if (item.uid) {
      editableCart.resetCartItem(item);
    } else {
      editableCart.removeCartItem(item);
    }

    return item;
  }

  // removeItem(editableCart: EditableCart, item: EditableCartItemAdjustment | EditableOrderPayment) {

  //   if (item) {
  //     if (item instanceof EditableOrderItemAdjustment) {
  //       let transactionLineItem = editableCart.items.find(x => x.uid == item.transactionLineItemUid)

  //       transactionLineItem.removeOrderLineItemAdjustment(item);
  //     } else if (item instanceof EditableOrderAdjustment) {
  //       editableCart.removeOrderAdjustmentLineItem(item);
  //     } else if (item instanceof EditableOrderPayment) {
  //       editableCart.removePaymentLineItem(item);
  //     }
  //   }
  // }

  mergeCart(target: EditableCart, source: Cart): EditableCart {

    target = target || new EditableCart();

    target.uid = source.uid;
    target.storeFrontUid = source.storeFrontUid;
    target.identityUid = source.identityUid;
    target.logisticTypeUid = source.logisticTypeUid;
    target.logisticScheduleUid = source.logisticScheduleUid;
    target.logisticDateTimeUtc = source.logisticDateTimeUtc;
    target.itemQuantity = source.itemQuantity;
    target.subTotalAmount = source.subTotalAmount;

    if (source.items) {
      source.items.forEach(item => {
        this.mergeCartItem(target.items.find(x => x.uid.toLowerCase() == item.uid.toLowerCase()) || target.addCartItem(), item);
      });
      target.items.forEach(editableItem => {
        var item = source.items.find(x => x.uid.toUpperCase() == editableItem.uid.toUpperCase());
        if (!item) {
          target.items.splice(target.items.indexOf(editableItem), 1);
        }
      });
    }

    return target;
  }

  mergeCartItem(target: EditableCartItem, source: CartItem): EditableCartItem {

    target = target || new EditableCartItem();

    target.uid = source.uid;
    // target.cartUid = source.cartUid;
    target.productUid.next(source.productUid);
    target.productVersion.next(source.productVersion);
    // target.name.next(source.name);
    target.configuration.next(source.configuration ? this.mergeCartItemConfiguration(target.configuration.value, source.configuration) : null);
    target.quantity.next(source.quantity);
    target.eachAmount.next(source.eachAmount);

    target.takeSnapshot();

    return target;
  }

  mergeCartItemConfiguration(target: EditableTransactionItemConfiguration, source: TransactionItemConfiguration): EditableTransactionItemConfiguration {

    target = target || new EditableTransactionItemConfiguration();

    target.portion = this.mergeCartItemConfigurationPortion(target.portion, source.portion);

    return target;
  }

  mergeCartItemConfigurationPortion(target: EditableTransactionItemConfigurationPortion, source: TransactionItemConfigurationPortion): EditableTransactionItemConfigurationPortion {

    target = target || new EditableTransactionItemConfigurationPortion();

    target.portionUid = source.portionUid;
    target.preparations = source.preparations;
    target.variations = source.variations;
    target.inclusionGroups = source.inclusionGroups.map(x => {
      const inclusionGroup = new EditableTransactionItemConfigurationInclusionGroup();
      inclusionGroup.inclusionGroupUid = x.inclusionGroupUid;
      inclusionGroup.options = x.options;
      
      return inclusionGroup;
    });
    target.addOns = this.mergeTransactionItemConfigurationAddOns(target.addOns, source.addOns);

    return target;
  }

  mergeTransactionItemConfigurationAddOns(targets: EditableTransactionItemConfigurationAddOn[], sources: TransactionItemConfigurationAddOn[]): EditableTransactionItemConfigurationAddOn[] {

    if (sources) {
      targets = targets || [];

      sources.forEach(source => {
        const target = EditableCartDataHandler.getOrCreateCartItemConfigurationAddOn(targets, source.addOnUid);
        this.mergeCartItemConfigurationAddOn(target, source);
      });
      targets.forEach(target => {
        const source = sources.find(x => x.addOnUid.toLowerCase() == target.addOnUid.toLowerCase());
        if (!source) {
          targets.splice(targets.indexOf(target), 1);
        }
      })
    } else {
      targets = null;
    }

    return targets && targets.length > 0 ? targets : null;
  }

  public static getOrCreateCartItemConfigurationAddOn(targets: EditableTransactionItemConfigurationAddOn[], addOnUid: string): EditableTransactionItemConfigurationAddOn {

    let target = targets.find(x => x.addOnUid.toLowerCase() == addOnUid.toLowerCase());
    if (!target) {
      target = new EditableTransactionItemConfigurationAddOn();
      targets.push(target);
    }

    return target;
  }

  mergeCartItemConfigurationAddOn(target: EditableTransactionItemConfigurationAddOn, source: TransactionItemConfigurationAddOn): EditableTransactionItemConfigurationAddOn {

    target = target || new EditableTransactionItemConfigurationAddOn();

    target.addOnUid = source.addOnUid;
    target.item = source.item ? EditableTransactionDataHandler.mergeTransactionItem(target.item, source.item) : null;

    return target;
  }
}
