import { Product } from 'downtown-product';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditableTransactionItemConfiguration } from "./models/editable-transaction/editable-transaction";

export function getTransactionLineSummaryText(configuration: EditableTransactionItemConfiguration, product: Product): Observable<string> {

  return combineLatest([
    getPortionSummaryText(configuration, product),
  ]).pipe(
    map(results => {
      return results.join('');
    })
  )
}

export function getPortionSummaryText(configuration: EditableTransactionItemConfiguration, product: Product): Observable<string> {

  let portionText = null;

  if (product) {
    let portion = configuration.portion ? product.configuration.getPortion(configuration.portion.portionUid) : null;
    portionText = `${product.name} ${(portion ? ` - ${portion.name}` : null)}`;
  }

  return of(portionText);
}

