import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { OltpServiceSettings } from "../oltp-service-settings";
import { HttpService } from "core";
import { PaginationInput } from "core";
import { Page } from "core";
import { AccountSetting } from "../models/account-setting";
import { PosModelFactory } from "../pos-model-factory";

@Injectable()
export class AccountSettingService {

  public static readonly AccountSettingIndexView = <AccountSettingViewOptions>{};

  constructor(
    private httpService: HttpService,
    private oltpServiceSettings: OltpServiceSettings,
  ) {
  }

  getByUid(uid: string, viewOptions: AccountSettingViewOptions = AccountSettingService.AccountSettingIndexView): Observable<AccountSetting> {

    let view = this.buildView(viewOptions);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<AccountSetting>(this.oltpServiceSettings.apiUrl, 'api/oltp/accountsetting', request, 'getByUid').pipe(
      map(x => PosModelFactory.assignAccountSetting(x))
    );
  }

  search(statusUids: string[], paginationInput: PaginationInput, viewOptions: AccountSettingViewOptions = AccountSettingService.AccountSettingIndexView): Observable<Page<AccountSetting>> {

    let view = this.buildView(viewOptions);

    var request = {
      query: `query productDepartments($pagination:PaginationInput) { search(statusUids: ${JSON.stringify(statusUids)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: paginationInput
    };

    return this.httpService.graph<Page<AccountSetting>>(this.oltpServiceSettings.apiUrl, 'api/oltp/accountsetting', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = PosModelFactory.assignAccountSetting(edge.node)))
    );
  }

  public buildView(viewOptions: AccountSettingViewOptions) {

    let view = `uid name accountTypeUid defaultStartingBalance accountSettingStatusUid`;

    return '{' + view + '}';
  }
}

export interface AccountSettingViewOptions {

}
