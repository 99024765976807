import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WaitModalProvider } from 'core';
import { NavigationProvider } from 'core';
import { ErrorHandlingProvider } from 'core';
import { MemberService } from 'pos-core';

@Component({
  selector: 'app-site-public-profile',
  templateUrl: './site-public-profile.component.html',
  styleUrls: ['./site-public-profile.component.scss']
})
export class SitePublicProfileComponent implements OnInit {

  public memberUid: string;
  public form: UntypedFormGroup;
  public canSave = new BehaviorSubject<boolean>(false);

  constructor(
    private navigationProvider: NavigationProvider,
    private activatedRoute: ActivatedRoute,
    private errorHandlingProvider: ErrorHandlingProvider,
    private waitModalProvider: WaitModalProvider,
    private memberService: MemberService
  ) {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl(''),
      displayName: new UntypedFormControl(''),
      email: new UntypedFormControl('', { validators: Validators.email, updateOn: 'blur' })
    });
  }

  ngOnInit() {

    this.navigationProvider.setWaypoint('Member Profile');

    let dialogRef = this.waitModalProvider.open('Loading...');
    dialogRef.afterOpened().subscribe(() => {
      this.activatedRoute.params.subscribe(params => {
        let memberUid = params['memberUid'];

        if (memberUid) {
          this.memberService.getByUid(memberUid).pipe(
            // catchError(error => this.errorHandlingProvider.handleError<Member>(error, null))
          ).subscribe(member => {
            if (member) {
              this.memberUid = member.uid;

              this.form.get('firstName').setValue(member.firstName);
              this.form.get('lastName').setValue(member.lastName);
              this.form.get('displayName').setValue(member.displayName);
              this.form.get('email').setValue(member.email, Validators.email);
            }

            this.updateEnablement()

            dialogRef.close();
          });
        } else {
          dialogRef.close();
        }
      });
    })
  }

  save() {

    if (this.form.dirty) {
      if (this.memberUid) {

        let dialogRef = this.waitModalProvider.open('Saving...');

        dialogRef.afterOpened().subscribe(() => {
          this.memberService.update(
            this.memberUid,
            this.form.get('firstName').value,
            this.form.get('lastName').value,
            this.form.get('displayName').value,
            this.form.get('email').value
          ).pipe(
            catchError(error => this.errorHandlingProvider.handleError(error, null))
          ).subscribe(member => {
            dialogRef.close();

            if (member) {
              this.navigationProvider.backOneWaypoint();
            }
          });
        });
      }
    } else {
      this.navigationProvider.backOneWaypoint();
    }
  }

  cancel() {
    this.navigationProvider.backOneWaypoint();
  }

  private updateEnablement() {

    this.canSave.next(this.form.dirty);
  }
}
