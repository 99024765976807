import { Component, Inject } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CardEntryModalComponent, CardSwipeMode, ICardEntryModalData, PosSettings } from 'pos-core';
import { CurrentDateTimeProvider, TimeoutProvider, SettingProvider, TenantProvider, CardSwipeEventTypeEnum, CaptureTokenEvent, CaptureTokenEventModel, CardSwipeEventModel, CardSwipeStatusEnum } from 'core';
import Decimal from 'decimal.js';

@Component({
  selector: 'app-site-card-entry-modal',
  templateUrl: './site-card-entry-modal.component.html',
  styleUrls: ['./site-card-entry-modal.component.scss']
})
export class SiteCardEntryModalComponent extends CardEntryModalComponent {

  public amountText = new BehaviorSubject<string>('0.00');
  public captureToken: CaptureTokenEvent;
  public canEnter: boolean;
  public timeoutPercent: number;

  private timeoutProvider: TimeoutProvider;
  private destroyed$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<SiteCardEntryModalComponent, CaptureTokenEventModel | CardSwipeEventModel>,
    @Inject(MAT_DIALOG_DATA) public data: ICardEntryModalData,
    private tenantProvider: TenantProvider,
    private settingProvider: SettingProvider,
    private currentDateTimeProvider: CurrentDateTimeProvider,
  ) {
    super();
  }

  ngOnInit() {

    this.settingProvider.getOneByTypeAndOwner$<PosSettings>('PosSettings', this.tenantProvider.currentUid).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(posSettings => {
      this.timeoutProvider = new TimeoutProvider(this.currentDateTimeProvider, this.destroyed$, posSettings.dialogAutocloseDuration);
      this.timeoutProvider.percent$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(value => this.timeoutPercent = value);
      this.timeoutProvider.inactive$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(_ => this.cancel());
    });

    if (this.data.initialValue) {
      this.amountText.next(this.data.initialValue.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    }

    switch (this.data.mode) {
      // case CardSwipeMode.CaptureSwipe:
      //   // Subscribe to msr device events
      //   this.msrProvider.cardSwipe$.pipe(
      //     takeUntil(this.destroyed$)
      //   ).subscribe(cardSwipe => {
      //     if (cardSwipe) {
      //       this.dialogRef.close({ status: CardSwipeStatusEnum.Success, event: cardSwipe });
      //     }
      //   });
      //   break;
      // case CardSwipeMode.CaptureToken:
      //   this.msrService.captureToken().subscribe(captureToken => {
      //     if (captureToken) {
      //       this.captureToken = captureToken;

      //       if (this.data.allowEdit) {
      //         let name = `${captureToken.firstName} ${captureToken.middleInitial}`.trim();
      //         name = `${name} ${captureToken.surname} (${captureToken.lastFour})`.trim();

      //         this.data.title = name;

      //         this.evaluateEnablement();
      //       } else {
      //         this.dialogRef.close(<CaptureTokenEventModel>{ amount: this.getAmount(), captureToken: this.captureToken });
      //       }
      //     }
      //   })
      //   break;
    }
  }

  ngOnDestroy() {

    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  keyPressed(value: string) {

    let keyValue = value.toLowerCase();

    if (keyValue == '00') {
      this.keyPressed('0');
      this.keyPressed('0');
    } else if (keyValue == '.') {
      let amount = this.amountText.value;
      let decimalIndex = amount.indexOf('.');

      let wholeDigits = amount.substr(0, decimalIndex + 2).replace('.', '');
      let decimalDigits = amount.substr(decimalIndex + 2);

      amount = parseInt(wholeDigits + decimalDigits).toString() + '.';
      this.amountText.next(amount);
    } else if (keyValue == 'back') {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        // Shift all digits right
        amount = Math.floor(amount * 10) / 100;

        this.amountText.next(amount.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
      }
    } else if (keyValue == 'enter') {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        this.dialogRef.close(<CaptureTokenEventModel>{ amount: amount, captureToken: this.captureToken });
      }
    } else if (keyValue == 'clear') {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        this.clearAmount();
      }
    } else if (keyValue == 'skip') {
      this.skip();
    } else if (keyValue == 'cancel') {
      this.cancel();
    } else if (keyValue == 'devcard') {
      switch (this.data.mode) {
        case CardSwipeMode.CaptureSwipe:
          this.dialogRef.close(<CardSwipeEventModel>{
            status: CardSwipeStatusEnum.Success,
            event: {
              messageType: CardSwipeEventTypeEnum.cardSwiped,
              lastFour: '1111',
              firstName: 'John',
              surname: 'Doe',
              expirationMonth: new Date().getMonth().toString(),
              expirationYear: new Date().getFullYear().toString()
            }
          });
          break;
        case CardSwipeMode.CaptureToken:
          this.dialogRef.close(<CaptureTokenEventModel>{ amount: this.getAmount(), captureToken: this.captureToken });
          break;
      }
    } else {
      let digit = new Decimal(value);
      if (!Number.isNaN(digit)) {
        let textEachAmount = this.amountText.value.concat(keyValue);

        let validAmount = new Decimal(textEachAmount);
        if (!validAmount.isNaN()) {
          let decimalIndex = textEachAmount.indexOf('.');
          if (decimalIndex > 0 && textEachAmount.length - decimalIndex > 3) {
            // Need to shift the decimal point
            textEachAmount = (new Decimal(textEachAmount.slice(0, decimalIndex + 2).replace('.', '')).toString()) + '.' + (textEachAmount.slice(decimalIndex + 2));
          }

          this.amountText.next(textEachAmount);
        }
      }
    }

    this.evaluateEnablement();
  }

  getAmount(): number {

    return new Decimal(this.amountText.value).toNumber();
  }

  clearAmount() {

    this.amountText.next((0).toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
  }

  skip() {

    this.dialogRef.close(<CardSwipeEventModel>{ status: CardSwipeStatusEnum.Skipped, event: { firstName: null, surname: null, lastFour: null } });
  }

  cancel() {

    this.dialogRef.close(<CardSwipeEventModel>{ status: CardSwipeStatusEnum.Cancelled });
  }

  private evaluateEnablement() {

    this.canEnter = this.captureToken && this.getAmount() > 0;
  }
}
