import { Component, HostListener, Inject } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DecimalModalData } from "./register-decimal-modal.provider";
import { DecimalModalComponent } from "core";
import Decimal from "decimal.js";

@Component({
  selector: 'app-decimal-modal',
  templateUrl: './register-decimal-modal.component.html',
  styleUrls: ['./register-decimal-modal.component.scss']
})
export class RegisterDecimalModalComponent extends DecimalModalComponent {

  public amountText = new BehaviorSubject<string>('0.00');

  constructor(
    private dialogRef: MatDialogRef<DecimalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DecimalModalData
  ) {
    super();

  }

  ngOnInit() {

    if (this.data.initialValue != null) {
      this.amountText.next(this.data.initialValue.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDownEvent(event: any) {

    let e = <KeyboardEvent>event;
    const key = event.which || event.keyCode;

    if (key == 13) {
      this.keyPressed('enter');
    } else if (key == 8) {
      this.keyPressed('back');
    } else {
      this.keyPressed(event.key);
    }
  }

  keyPressed(value: string) {

    let keyValue = value.toLowerCase();

    if (keyValue == "00") {
      this.keyPressed("0");
      this.keyPressed("0");
    } else if (keyValue == ".") {
      let amount = this.amountText.value;
      let decimalIndex = amount.indexOf('.');

      let wholeDigits = amount.substr(0, decimalIndex + 2).replace('.', '');
      let decimalDigits = amount.substr(decimalIndex + 2);

      amount = parseInt(wholeDigits + decimalDigits).toString() + ".";
      this.amountText.next(amount);
    } else if (keyValue == "back") {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        // Shift all digits right
        amount = Math.floor(amount * 10) / 100;

        this.amountText.next(amount.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
      }
    } else if (keyValue == "enter") {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        this.commit();
      }
    } else if (keyValue == "clear") {
      let amount = this.getAmount();
      if (amount == 0) {
        this.cancel();
      } else {
        this.clearAmount();
      }
    } else if (keyValue == "cancel") {
      this.cancel();
    } else {
      let digit = parseInt(value);
      if (!Number.isNaN(digit)) {
        let textEachAmount = this.amountText.value.concat(keyValue);

        let validAmount = new Decimal(textEachAmount);
        if (!validAmount.isNaN()) {
          let decimalIndex = textEachAmount.indexOf('.');
          if (decimalIndex > 0 && textEachAmount.length - decimalIndex > 3) {
            // Need to shift the decimal point
            textEachAmount = (new Decimal(textEachAmount.slice(0, decimalIndex + 2).replace('.', '')).toString()) + '.' + (textEachAmount.slice(decimalIndex + 2));
          }

          this.amountText.next(textEachAmount);
        }
      }
    }
  }

  getAmount(): number {

    return new Decimal(this.amountText.value).toNumber();
  }

  clearAmount() {

    this.amountText.next((0).toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }));
  }

  commit() {

    this.dialogRef.close(this.getAmount());
  }

  cancel() {

    this.dialogRef.close();
  }
}
