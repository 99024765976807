import { Injectable } from '@angular/core';
// import { DesktopCapturer, IpcRenderer, WebFrame, Clipboard, CrashReporter, nativeImage, Shell, NativeImage } from 'electron';
// let electron = require('electron');


// Check out https://github.com/maximegris/angular-electron


@Injectable()
export class ElectronProvider {

  private _electron: any;

  constructor(
  ) {

  }

  private get electron(): any {

    if (!this._electron) {
      // Because we remapped 'require' in index.html for better 3rd party support in electron apps
      if (window && window['nodeRequire' as any]) {
        this._electron = (window['nodeRequire' as any] as any)('electron');

        return this._electron;
      }
      return null;

    }
    return this._electron;
  }

  get isElectronApp(): boolean {
    return !!window.navigator.userAgent.match(/Electron/);
  }

  public reload() {

    const api = (<any>window).api;

    if (api) {
      api.reload();
    } else {
      throw 'Unable to access Electron API.';
    }
  }

  public toggleDevTools() {

 
    const api = (<any>window).api;

    if (api) {
      api.toggleDevTools();
    } else {
      throw 'Unable to access Electron API.';
    }
 }

  public closeMainWindow() {

    const api = (<any>window).api;

    if (api) {
      api.closeWindow();
    } else {
      throw 'Unable to access Electron API.';
    }
  }

  // get desktopCapturer(): DesktopCapturer {
  //   return this.electron ? this.electron.dedesktopCapturers : null;
  // }

  // get ipcRenderer(): IpcRenderer {
  //   return this.electron ? this.electron.ipcRenderer : null;
  // }

  // get webFrame(): WebFrame {
  //   return this.electron ? this.electron.webFrame : null;
  // }

  // get clipboard(): Clipboard {
  //   return this.electron ? this.electron.clipboard : null;
  // }

  // get crashReporter(): CrashReporter {
  //   return this.electron ? this.electron.crashReporter : null;
  // }

  // get process(): NodeJS.Process {
  //   return this.electron ? this.electron.process : null;
  // }

  // get nativeImage(): NativeImage {
  //   return this.electron ? this.electron.nativeImage : null;
  // }

  // get screen(): Screen {
  //   return this.electron ? this.electron.screen : null;
  // }

  // get shell(): Shell {
  //   return this.electron ? this.electron.shell : null;
  // }
}
