import { Component, ElementRef, ViewChild, Inject } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UUID } from "angular2-uuid";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConversationModalData } from './conversation-modal.provider';
import { PaginationInput } from 'core';
import { GraphEndpoint, GraphService, EndpointState } from 'core';
import { ConversationMessage, PosRuntimeProvider } from 'pos-core';
import { PosModelFactory } from 'pos-core';
import { AuthEmployeeProvider } from 'pos-core';
import { ConversationService } from 'pos-core';

@Component({
  selector: 'app-conversation-modal',
  templateUrl: './conversation-modal.component.html',
  styleUrls: ['./conversation-modal.component.scss']
})
export class ConversationModalComponent {

  @ViewChild('messageContent', { static: true }) messageContentElementRef: ElementRef;

  public form: UntypedFormGroup;
  public loading = new BehaviorSubject<boolean>(true);

  conversationUid: string;
  messages: MessageModel[];
  private messageAddedEndpoint: GraphEndpoint;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConversationModalData,
    private conversationService: ConversationService,
    private graphService: GraphService,
    private posRuntimeProvider: PosRuntimeProvider,
    private authEmployeeProvider: AuthEmployeeProvider
  ) {

    this.form = new UntypedFormGroup({
      messageContent: new UntypedFormControl(''),
    });

    // Subscribe to any new messages
    this.authEmployeeProvider.authEmployee$
    this.messageAddedEndpoint = this.graphService.createEndpoint(this.posRuntimeProvider.oltpSocketUrl + 'api/socket/conversation');
    this.messageAddedEndpoint.state.subscribe(state => {
      if (state == EndpointState.Ready) {
        combineLatest([
          this.authEmployeeProvider.authEmployee$,
          this.messageAddedEndpoint.addSubscription<ConversationMessage>(ConversationService.buildView(ConversationService.ConversationFullView), 'messageAdded')
        ]).subscribe(([authEmployee, message]) => {
          if (message) {
            this.addOrUpdateMessage(this.mapMessage(PosModelFactory.assignConversationMessage(message), authEmployee.uid));
          }
        });
      }
    });

  }

  ngOnInit() {

    this.loading.next(true);

    if (this.data.refAggregateUid) {
      combineLatest([
        this.authEmployeeProvider.authEmployee$,
        this.conversationService.search([this.data.refAggregateUid], <PaginationInput>{ pageSize: 10000, pageIndex: 0 })
      ]).subscribe(([authEmployee, conversationPage]) => {
        if (conversationPage) {
          var conversations = conversationPage.edges.map(x => x.node);
          var conversation = conversations.length == 0 ? null : (this.conversationUid ? conversations.find(x => x.uid == this.conversationUid) : conversations[0]); // Only handling one convo for now

          if (conversation) {
            this.conversationUid = conversation.uid;
            this.messages = conversation.messages.map(m => this.mapMessage(m, authEmployee.uid));
          } else {
            this.messages = [];
          }

          if (this.messageContentElementRef) {
            this.messageContentElementRef.nativeElement.focus();
          }
        }
        this.loading.next(false);
      });
    }
  }

  ngOnDestroy() {

    this.messageAddedEndpoint.close();
  }

  send() {

    var messageContent = this.form.get('messageContent').value;

    if (messageContent && messageContent.trim().length > 0) {
      var messageUid = UUID.UUID();

      if (this.conversationUid) {
        this.authEmployeeProvider.authEmployee$.subscribe(authEmployee => {
          this.conversationService.addMessage(this.conversationUid, messageUid, authEmployee.uid, messageContent).subscribe(message => {
            this.addOrUpdateMessage(this.mapMessage(message, authEmployee.uid));
          });
        });
      } else {
        this.conversationUid = UUID.UUID();

        this.authEmployeeProvider.authEmployee$.subscribe(authEmployee => {
          this.conversationService.create(this.conversationUid, this.data.refAggregateUid, messageUid, authEmployee.uid, messageContent).subscribe(conversation => {

            this.messages = conversation.messages.map(m => this.mapMessage(m, authEmployee.uid));
          });
        });
      }

      this.form.get('messageContent').setValue('');
    }
  }

  private addOrUpdateMessage(message: MessageModel) {

    if (this.messages.find(x => x.uid == message.uid) == null) {
      this.messages.unshift(message);
    }
  }

  private mapMessage(message: ConversationMessage, authIdentityUid: string): MessageModel {

    const displayName = message.employee ? message.employee.getDisplayableName() : (message.identityUser ? message.identityUser.username : message.identityId);

    return <MessageModel>{
      uid: message.uid,
      conversationUid: message.conversationUid,
      identityId: message.identityId,
      displayName: message.employee.getDisplayableName(),
      owner: message.identityId.toUpperCase() == authIdentityUid.toUpperCase() ? 'mine' : 'theirs',
      content: message.content,
      dateTimeUtc: message.dateTimeUtc
    };
  }
}

class MessageModel {
  uid: string;
  conversationUid: string;
  identityId: string;
  displayName: string;
  owner: string;
  content: string;
  dateTimeUtc: Date;
}
