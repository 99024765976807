import { Injectable } from "@angular/core";
import { Terminal } from "../models/terminal";
import { map, tap } from "rxjs/operators";
import { Observable } from 'rxjs';
import { CoreLibraryConfig, HttpService } from 'core';
import { PaginationInput } from "core";
import { Page } from "core";
import { TerminalTypeEnum } from "../keys";
import { TerminalModelFactory } from "../terminal-model-factory";

@Injectable()
export class TerminalService {

  public static readonly TerminalFullView = <TerminalViewOptions>{};
  public static readonly TerminalIndexView = <TerminalViewOptions>{};

  constructor(
    private httpService: HttpService,
    private coreLibraryConfig: CoreLibraryConfig,
  ) {

  }

  getByUid(uid: string, viewOptions: TerminalViewOptions = TerminalService.TerminalFullView): Observable<Terminal> {

    let view = TerminalService.buildView(viewOptions);

    var request = {
      query: `query { getByUid (uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Terminal>(this.coreLibraryConfig.apiUrl, 'api/oltp/terminal', request, 'getByUid').pipe(
      map(x => TerminalModelFactory.assignTerminal(x))
    );
  }

  getByUids(uids: string[], viewOptions: TerminalViewOptions = null, identityToken: string = null): Observable<Terminal[]> {

    let view = TerminalService.buildView(viewOptions || TerminalService.TerminalFullView);

    var request = {
      query: `query { getByUids (uids: ${JSON.stringify(uids)}) ${view} }`
    }

    return this.httpService.graph<Terminal[]>(this.coreLibraryConfig.apiUrl, 'api/oltp/terminal', request, 'getByUids', identityToken).pipe(
      map(x => x.map(y => TerminalModelFactory.assignTerminal(y)))
    );
  }

  getByDeviceId(deviceId: string, viewOptions: TerminalViewOptions = TerminalService.TerminalFullView): Observable<Terminal> {

    let view = TerminalService.buildView(viewOptions);

    var request = {
      query: `query { getByDeviceId (deviceId: "${deviceId}") ${view} }`
    };

    return this.httpService.graph<Terminal>(this.coreLibraryConfig.apiUrl, 'api/oltp/terminal', request, 'getByDeviceId').pipe(
      map(x => TerminalModelFactory.assignTerminal(x))
    );
  }

  search(statuses: string[], paginationInput: PaginationInput, viewOptions: TerminalViewOptions = TerminalService.TerminalFullView): Observable<Page<Terminal>> {

    let view = TerminalService.buildView(viewOptions);

    var request = {
      query: `query search($pagination:PaginationInput) { search(statuses: ${JSON.stringify(statuses)}, pagination: $pagination) { totalCount edges { node ${view} } pageInfo { firstPage previousPage thisPage firstItemIndex lastItemIndex nextPage lastPage } } }`,
      variables: paginationInput
    };

    return this.httpService.graph<Page<Terminal>>(this.coreLibraryConfig.apiUrl, 'api/oltp/terminal', request, 'search').pipe(
      tap(x => x.edges.forEach(edge => edge.node = TerminalModelFactory.assignTerminal(edge.node)))
    );
  }

  getLocal(viewOptions: TerminalViewOptions = TerminalService.TerminalFullView): Observable<Terminal> {

    let view = TerminalService.buildView(viewOptions);

    var request = {
      query: `query { getLocal ${view} }`
    };

    return this.httpService.graph<Terminal>(this.coreLibraryConfig.apiUrl, 'api/oltp/terminal', request, 'getLocal').pipe(
      map(x => TerminalModelFactory.assignTerminal(x))
    );
  }

  public bindTerminalRequest(username: string, password: string, name: string, deviceId: string, viewOptions: TerminalViewOptions = null, identityToken: string = null): Observable<Terminal> {

    let view = TerminalService.buildView(viewOptions || TerminalService.TerminalFullView);

    var request = {
      query: `mutation bindTerminalRequest { bindTerminalRequest(username: "${username}", password: "${password}", name: "${name}", deviceId: "${deviceId}") ${view} }`
    };

    return this.httpService.graph<Terminal>(this.coreLibraryConfig.apiUrl, 'api/oltp/terminal', request, 'bindTerminalRequest', identityToken).pipe(
      map(x => TerminalModelFactory.assignTerminal(x))
    );
  }

  acceptBindTerminal(uid: string, viewOptions: TerminalViewOptions = null, identityToken: string = null): Observable<Terminal> {

    let view = TerminalService.buildView(viewOptions || TerminalService.TerminalFullView);

    var request = {
      query: `mutation acceptBindTerminal { acceptBindTerminal(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Terminal>(this.coreLibraryConfig.apiUrl, 'api/oltp/terminal', request, 'acceptBindTerminal', identityToken).pipe(
      map(x => TerminalModelFactory.assignTerminal(x))
    );
  }

  rejectBindTerminal(uid: string, viewOptions: TerminalViewOptions = null, identityToken: string = null): Observable<Terminal> {

    let view = TerminalService.buildView(viewOptions || TerminalService.TerminalFullView);

    var request = {
      query: `mutation rejectBindTerminal { rejectBindTerminal(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Terminal>(this.coreLibraryConfig.apiUrl, 'api/oltp/terminal', request, 'rejectBindTerminal', identityToken).pipe(
      map(x => TerminalModelFactory.assignTerminal(x))
    );
  }

  create(uid: string, name: string, description: string, type: TerminalTypeEnum, deviceId: string, defaultDrawerSeedAmount: number, viewOptions: TerminalViewOptions = null, identityToken: string = null): Observable<Terminal> {

    let view = TerminalService.buildView(viewOptions || TerminalService.TerminalFullView);

    var terminalInput = {
      uid: uid,
      name: name,
      description: description,
      type: type,
      deviceId: deviceId,
      defaultDrawerSeedAmount: defaultDrawerSeedAmount
    };

    var request = {
      query: `mutation create($terminal:TerminalInput!) { create(terminal: $terminal) ${view} }`,
      variables: { terminal: terminalInput }
    };

    return this.httpService.graph<Terminal>(this.coreLibraryConfig.apiUrl, 'api/oltp/terminal', request, 'create', identityToken).pipe(
      map(x => TerminalModelFactory.assignTerminal(x))
    );
  }

  update(uid: string, name: string, description: string, type: TerminalTypeEnum, deviceId: string, defaultDrawerSeedAmount: number, viewOptions: TerminalViewOptions = null, identityToken: string = null): Observable<Terminal> {

    let view = TerminalService.buildView(viewOptions || TerminalService.TerminalFullView);

    var terminalInput = {
      uid: uid,
      name: name,
      description: description,
      type: type,
      deviceId: deviceId,
      defaultDrawerSeedAmount: defaultDrawerSeedAmount
    };

    var request = {
      query: `mutation update($terminal:TerminalInput!) { update(terminal: $terminal) ${view} }`,
      variables: { terminal: terminalInput }
    };

    return this.httpService.graph<Terminal>(this.coreLibraryConfig.apiUrl, 'api/oltp/terminal', request, 'update', identityToken).pipe(
      map(x => TerminalModelFactory.assignTerminal(x))
    );
  }

  delete(uid: string, viewOptions: TerminalViewOptions = null, identityToken: string = null): Observable<Terminal> {

    let view = TerminalService.buildView(viewOptions || TerminalService.TerminalFullView);

    var request = {
      query: `mutation delete { delete(uid: "${uid}") ${view} }`
    };

    return this.httpService.graph<Terminal>(this.coreLibraryConfig.apiUrl, 'api/oltp/terminal', request, 'delete', identityToken).pipe(
      map(x => TerminalModelFactory.assignTerminal(x))
    );
  }

  static buildView(viewOptions: TerminalViewOptions) {

    let view = `uid name description type deviceId defaultDrawerSeedAmount createDateTimeUtc status`;

    return '{' + view + '}';
  }
}

export interface TerminalViewOptions {

}


