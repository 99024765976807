import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filterFromArrayByKey, findInArrayByUid } from 'core';
import { SpinnerModalProvider } from 'core';
import { ConfigurationComponentBase } from '../configuration-component-base';
import { ProductContext } from '../product-context';
import { Category } from '../../../models/category';
import { Department } from '../../../models/department';
import { ProductStatusKeys } from '../../../keys';
import { ProductService } from '../../../services/product.service';
import { ProductAndPortionModalProvider } from '../../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { DepartmentService } from '../../../services/department.service';
import { CategoryService } from '../../../services/category.service';
import { ProductPreparationModalProvider, ProductPreparationModalData } from '../../../modals/product-preparation-modal/product-preparation-modal.provider';
import { ProductVariationModalData, ProductVariationModalProvider } from '../../../modals/product-variation-modal/product-variation-modal.provider';
import { buildProductPreparationForm, buildProductVariationForm } from '../../../functions/configuration-form-functions';
import { TenantProvider } from 'core';
import { ProductProvider } from '../../../providers';

@Component({
  selector: 'app-back-office-product-configuration-general',
  templateUrl: './back-office-product-configuration-general.component.html',
  styleUrls: ['./back-office-product-configuration-general.component.scss']
})
export class BackOfficeProductConfigurationGeneralComponent extends ConfigurationComponentBase {

  private _context: ProductContext;
  public form: UntypedFormGroup;

  public departments: Department[];
  public categories: Category[];
  public filteredCategories: Category[];
  public productStatuses = [
    {
      uid: ProductStatusKeys.Active,
      name: 'Active'
    },
    {
      uid: ProductStatusKeys.Inactive,
      name: 'Inactive'
    }
  ];

  private destroyed$ = new Subject();

  constructor(
    private spinnerModalProvider: SpinnerModalProvider,
    private departmentService: DepartmentService,
    private categoryService: CategoryService,
    private productPreparationModalProvider: ProductPreparationModalProvider,
    private productVariationModalProvider: ProductVariationModalProvider,
    activatedRoute: ActivatedRoute,
    productService: ProductService,
    tenantProvider: TenantProvider,
    productProvider: ProductProvider,
    productAndPortionModalProvider: ProductAndPortionModalProvider
  ) {
    super(activatedRoute, tenantProvider, productProvider, productAndPortionModalProvider);
  }

  ngOnDestroy() {

    this.destroyed$.next(null);
  }

  public set context(context: ProductContext) {

    this._context = context;
    this.form = context.productForm;

    let spinnerModalRef = this.spinnerModalProvider.open();
    spinnerModalRef.afterOpened().subscribe(() => {

      combineLatest([
        this.departmentService.search(this.tenantProvider.currentUid, null, null),
        this.categoryService.search(this.tenantProvider.currentUid, null, null, null)
      ]).subscribe(([departmentsPage, categoriesPage]) => {
        this.departments = departmentsPage.edges.map(x => x.node);
        this.categories = categoriesPage.edges.map(x => x.node);

        this.form.get('departmentUid').valueChanges.pipe(
          takeUntil(this.destroyed$)
        ).subscribe(value => {
          this.updateCategoryFilter();
        });

        this.updateCategoryFilter();
        spinnerModalRef.close();
      });
    });
  }

  public get preparationsFormGroupArray(): UntypedFormGroup[] {

    return (this.form.get('configuration').get('preparations') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  public get variationsFormGroupArray(): UntypedFormGroup[] {

    return (this.form.get('configuration').get('preparations') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  private updateCategoryFilter() {

    const value = this.form.get('departmentUid').value;

    this.filteredCategories = filterFromArrayByKey(this.categories, 'departmentUid', value);

    if (findInArrayByUid(this.categories, this.form.get('categoryUid').value) == null) {
      this.form.get('categoryUid').setValue(null);
    }
  }

  public get context(): ProductContext {

    return this._context;
  }

  selectImage() {

  }

  removeImage() {

  }

  public newPreparation() {

    const formGroup = buildProductPreparationForm(null);
    const preparations = <UntypedFormArray>this.context.productForm.get('configuration').get('preparations');

    preparations.push(formGroup);
    preparations.markAsDirty();

    this.editPreparation(formGroup);
  }

  public editPreparation(form: UntypedFormGroup) {

    this.productPreparationModalProvider.open(<ProductPreparationModalData>{ scope: this.getScope(this.activatedRoute), preparationForm: form }).afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
      }
    });
  }

  public dropPreparation(event: CdkDragDrop<string[]>) {

    if (event.previousIndex != event.currentIndex) {
      var preparations = <UntypedFormArray>this.context.productForm.get('configuration').get('preparations');
      var item = preparations.controls[event.previousIndex];
      preparations.removeAt(event.previousIndex);

      preparations.insert(event.currentIndex, item);
    }
  }

  public deletePreparation(form: UntypedFormGroup) {

    var preparations = <UntypedFormArray>this.context.productForm.get('configuration').get('preparations');
    preparations.removeAt(preparations.controls.indexOf(form));
  }

  public newVariation() {

    const formGroup = buildProductVariationForm(null);
    const variations = <UntypedFormArray>this.context.productForm.get('configuration').get('variations');

    variations.push(formGroup);
    variations.markAsDirty();

    this.editVariation(formGroup);
  }

  public editVariation(form: UntypedFormGroup) {

    this.productVariationModalProvider.open(<ProductVariationModalData>{ scope: this.getScope(this.activatedRoute), variationForm: form }).afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
      }
    });
  }

  public dropVariation(event: CdkDragDrop<string[]>) {

    if (event.previousIndex != event.currentIndex) {
      var variations = <UntypedFormArray>this.context.productForm.get('configuration').get('variations');
      var item = variations.controls[event.previousIndex];
      variations.removeAt(event.previousIndex);

      variations.insert(event.currentIndex, item);
    }
  }

  public deleteVariation(form: UntypedFormGroup) {

    var variations = <UntypedFormArray>this.context.productForm.get('configuration').get('variations');
    variations.removeAt(variations.controls.indexOf(form));
  }
}
