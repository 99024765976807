import { Component, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { findInArrayByUid, slideInAnimation, slideOutAnimation } from 'core';
import { flattenProduct, Product, ProductConfigurationInclusionGroupOption, ProductConfigurationPreparation, ProductConfigurationPreparationOption, ProductProvider } from 'downtown-product';
import { EditableTransaction, EditableTransactionItem, EditableTransactionItemAdjustment, EditableTransactionItemConfiguration } from '../../../models/editable-transaction/editable-transaction';
import { configureProductWithEditableItem } from '../../../functions';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'transaction-transaction-editor-item',
  templateUrl: './transaction-editor-item.component.html',
  styleUrls: ['./transaction-editor-item.component.scss'],
  animations: [slideInAnimation, slideOutAnimation]
})
export class TransactionEditorTransactionItemComponent {

  public product: Product;

  @Input() editableTransaction: EditableTransaction
  @Input() item: EditableTransactionItem;
  @Output() select = new EventEmitter<EditableTransactionItem | EditableTransactionItemAdjustment>();
  @Output() edit = new EventEmitter<EditableTransactionItem>();
  @Output() void = new EventEmitter<EditableTransactionItem | EditableTransactionItemAdjustment>();
  @Output() comp = new EventEmitter<EditableTransactionItem>();
  @Output() cancel = new EventEmitter<EditableTransactionItem | EditableTransactionItemAdjustment>();
  @Output() print = new EventEmitter<EditableTransactionItem>();
  @Output() guest = new EventEmitter<EditableTransactionItem>();

  private updatedOrdestroyed$ = new Subject();

  constructor(
    private productProvider: ProductProvider,
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['item']) {
      this.updatedOrdestroyed$.next(null);

      this.item?.configuration.pipe(
        takeUntil(this.updatedOrdestroyed$)
      ).subscribe(_ => {
        this.updateProductConfiguration();
      });
    }
  }

  ngOnDestroy() {

    this.updatedOrdestroyed$.next(null);
  }

  private updateProductConfiguration() {

    if (this.item?.configuration?.value) {
      this.productProvider.getOneCached$(this.item.productUid.value, this.item.productVersion.value).subscribe(product => {
        this.product = configureProductWithEditableItem(flattenProduct(product), this.item);
      });
    } else {
      this.product = null;
    }
  }
}
