import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TerminalDeviceModalData } from "./terminal-device-modal.provider";
import { DeviceInfo } from '../../models/device-info';

@Component({
  selector: 'app-terminal-device-modal',
  templateUrl: './terminal-device-modal.component.html',
  styleUrls: ['./terminal-device-modal.component.scss']
})
export class TerminalDeviceModalComponent {

  public oposOptions: SelectableOption<DeviceInfo>[];
  public lanOptions: SelectableOption<DeviceInfo>[];
  public currentView: string = "OPOS";

  constructor(
    private dialogRef: MatDialogRef<TerminalDeviceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TerminalDeviceModalData
  ) {
    this.oposOptions = data.oposOptions.map(x => <SelectableOption<DeviceInfo>>{ display: data.displayFunc(x), item: x, selected: true });
    this.lanOptions = data.netowrkOptions.map(x => <SelectableOption<DeviceInfo>>{ display: data.displayFunc(x), item: x, selected: true });
  }

  setView(view: string) {

    this.currentView = view;
  }

  selectOption(option: SelectableOption<DeviceInfo>) {

    this.oposOptions.filter(x => x != option).forEach(x => x.selected = false);
    option.selected = true;
  }

  close() {

    var selectedOption = this.oposOptions.find(x => x.selected);
    if (!selectedOption) {
      selectedOption = this.lanOptions.find(x => x.selected);
    }

    this.dialogRef.close(selectedOption?.item);
  }
}

interface SelectableOption<T> {

  display: string;
  selected: boolean;
  item: DeviceInfo
}
