<div *ngIf="form" [formGroup]="form">

  <h2>Transaction Settings</h2>

  <div style="display: inline-block; min-width: 500px; max-width: 900px;">

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <label>Recent Transaction Time Span (HH:MM)</label>
      </div>
      <mat-form-field style="flex: 2;">
        <input matInput type="text" formControlName="recentTransactionHourMinute" />
      </mat-form-field>
    </div>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <label>Transaction Auto Clear Duration (seconds)</label>
      </div>
      <mat-form-field style="flex: 2;">
        <input matInput type="number" formControlName="transactionAutoClearDuration" />
      </mat-form-field>
    </div>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <span style="flex: 1;">Combine Duplicate Items</span>
      </div>
      <div style="flex: 2;">
        <mat-checkbox formControlName="combineDuplicateItems"></mat-checkbox>
      </div>
    </div>
<!-- 
    <h2 style="margin-top: 20px;">Configuration Options</h2>

    <div style="display: flex;">
      <div style="flex: 2; text-align: right; padding-right: 50px; padding-top: 15.75px;">
        <span style="flex: 1;">Always show context menus</span>
      </div>
      <div style="flex: 2;">
        <mat-checkbox formControlName="configurationAlwaysShowContextMenus"></mat-checkbox>
      </div>
    </div> -->

  </div>
</div>
