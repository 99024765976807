import { Component, Input, Output, EventEmitter, HostBinding, SimpleChanges, ContentChildren, QueryList, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-register-button',
  templateUrl: './register-button.component.html',
  styleUrls: ['./register-button.component.scss']
})
export class RegisterButtonComponent {

  @Input() disabled: boolean = false;
  @Input() color: string;
  @Input() styles: any = {};
  @Input() class: string;
  @Output() click = new EventEmitter<MouseEvent>();

  @ViewChild('button', { static: true, read: ElementRef }) button: ElementRef<HTMLButtonElement>;

  constructor(
    private renderer: Renderer2
  ) { }

  ngAfterViewInit() {

    if (this.button && this.color) {
      this.renderer.setStyle(this.button.nativeElement, 'background-color', `var(--app-${this.color}-default-color)`);
      this.renderer.setStyle(this.button.nativeElement, 'color', `var(--app-${this.color}-default-contrast-color)`);
    }
  }

  public onClick(event: MouseEvent) {

    event.stopPropagation();
    this.click.emit(event);
  }
}
