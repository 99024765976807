import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { MatSelectionList } from '@angular/material/list';
import { map, takeUntil } from 'rxjs/operators';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { SelectListModalProvider, getDescendentRouteSnapshot } from 'core';
import { NavigationProvider } from 'core';
import { ProductAndPortionModalProvider } from '../../../../../modals/product-and-portion-modal/product-and-portion-modal.provider';
import { buildProductInclusionGroupOptionForm } from '../../../../../functions/configuration-form-functions';
import { ProductContext } from '../../../product-context';
import { ConfigurationComponentBase } from '../../../configuration-component-base';
import { TenantProvider } from 'core';
import { ProductProvider } from '../../../../../providers';

@Component({
  selector: 'app-back-office-product-configuration-inclusion-group-options',
  templateUrl: './back-office-product-configuration-inclusion-group-options.component.html',
  styleUrls: ['./back-office-product-configuration-inclusion-group-options.component.scss']
})
export class BackOfficeProductConfigurationInclusionGroupOptionsComponent extends ConfigurationComponentBase {


  @ViewChild('optionsList', { static: true }) optionsList: MatSelectionList;
  public form: UntypedFormGroup;
  public inclusionGroupForm: UntypedFormGroup;
  public scope: string;
  public canLinkProductOptions: boolean;

  private _context: ProductContext;
  private destroyed$ = new Subject();

  constructor(
    private selectListModalProvider: SelectListModalProvider,
    private navigationProvider: NavigationProvider,
    activatedRoute: ActivatedRoute,
    tenantProvider: TenantProvider,
    productProvider: ProductProvider,
    productAndPortionModalProvider: ProductAndPortionModalProvider,
  ) {
    super(activatedRoute, tenantProvider, productProvider, productAndPortionModalProvider);

    this.scope = this.getScope(this.activatedRoute);
  }

  ngOnDestroy(): void {

    this.destroyed$.next(null);
  }

  onActivate(event: ConfigurationComponentBase) {

    event.context = this.context;
  }

  public set context(context: ProductContext) {

    this._context = context;

    this.activatedRoute.url.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(x => {
      var route = getDescendentRouteSnapshot(this.activatedRoute);
      var portionUid = route.params['portionUid'];

      let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
      if (portionUid) {
        let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
        if (portionsGroupFormArray) {
          configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid.toLowerCase());
        }
      }

      var inclusionGroupUid = route.params['inclusionGroupUid'];
      let inclusionGroupsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('inclusionGroups');
      if (inclusionGroupsGroupFormArray) {
        configurationFormGroup = <UntypedFormGroup>inclusionGroupsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == inclusionGroupUid.toLowerCase());
      }

      this.form = configurationFormGroup;
      this.inclusionGroupForm = <UntypedFormGroup>this.form.parent.parent;
      this.canLinkProductOptions = this.scope == 'product' ? false : super.getLinkableProductInclusionGroupOptionFormsForInclusionGroupForm(this.form).length > 0;

      var n = super.getProductInclusionGroupForm(this.form);
    });
  }

  public get context(): ProductContext {

    return this._context;
  }

  public newOption() {

    var route = getDescendentRouteSnapshot(this.activatedRoute);
    var portionUid = route.params['portionUid'];
    var inclusionGroupUid = route.params['inclusionGroupUid'];

    let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
    if (portionUid) {
      let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
      if (portionsGroupFormArray) {
        configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid);
      }
    }

    var inclusionGroupsFormArray = <UntypedFormArray>configurationFormGroup.get('inclusionGroups');
    configurationFormGroup = <UntypedFormGroup>inclusionGroupsFormArray.controls.find(x => x.get('uid').value.toLowerCase() == inclusionGroupUid);

    var inclusionGroupOptionsFormArray = <UntypedFormArray>configurationFormGroup.get('options');

    const formGroup = buildProductInclusionGroupOptionForm(null);
    inclusionGroupOptionsFormArray.push(formGroup);
    inclusionGroupOptionsFormArray.markAsDirty();

    this.editOption(formGroup);
  }

  private getBaseInclusionGroupNavigationSegments(): string[] {

    var segments = this.getBasePortionNavigationSegments();
    var routeSnapshot = getDescendentRouteSnapshot(this.activatedRoute);

    var inclusionGroupUid = routeSnapshot.params['inclusionGroupUid'];
    if (inclusionGroupUid) {
      segments.push(...['inclusiongroup', inclusionGroupUid]);
    }

    return segments;
  }

  public editOption(formGroup: UntypedFormGroup) {

    this.navigationProvider.navigate(this.getBaseInclusionGroupNavigationSegments().concat(['option', formGroup.get('uid').value]));
  }

  public linkOption() {

    forkJoin(super.getLinkableProductInclusionGroupOptionFormsForInclusionGroupForm(this.form).map(x => {
      const productUid = x.get('productUid').value;
      const portionUid = x.get('portionUid').value;
      const alias = x.get('alias').value;

      if (alias) {
        return of(<ProductInclusionGroupOptionSelection>{ display: alias, optionFormGroup: x });
      } else {
        if (productUid != null && portionUid != null) {
          return this.productProvider.getOneCached$(productUid, null).pipe(
            map(product => {
              if (product && product.configuration) {
                const portion = product.configuration.portions.find(x => x.uid.toUpperCase() == portionUid.toUpperCase());
                if (portion) {
                  return <ProductInclusionGroupOptionSelection>{ display: `${product.name} - ${portion.name}`, optionFormGroup: x };
                }
              }

              return <ProductInclusionGroupOptionSelection>{ display: 'Unknown', optionFormGroup: x };
            })
          );
        } else {
          return of(<ProductInclusionGroupOptionSelection>{ display: 'Unassigned', optionFormGroup: x });
        }
      }
    })).subscribe(x => {
      this.selectListModalProvider.open({
        title: 'Select Product Option',
        options: x,
        displayFunc: item => item.display
      }).afterClosed().subscribe(result => {
        if (result) {
          var route = getDescendentRouteSnapshot(this.activatedRoute);
          var portionUid = route.params['portionUid'];
          var inclusionGroupUid = route.params['inclusionGroupUid'];

          let configurationFormGroup = <UntypedFormGroup>this._context.productForm.controls['configuration'];
          if (portionUid) {
            let portionsGroupFormArray = <UntypedFormArray>configurationFormGroup.get('portions');
            if (portionsGroupFormArray) {
              configurationFormGroup = <UntypedFormGroup>portionsGroupFormArray.controls.find(x => x.get('uid').value.toLowerCase() == portionUid);
            }
          }

          var inclusionGroupsFormArray = <UntypedFormArray>configurationFormGroup.get('inclusionGroups');
          configurationFormGroup = <UntypedFormGroup>inclusionGroupsFormArray.controls.find(x => x.get('uid').value.toLowerCase() == inclusionGroupUid);

          var inclusionGroupOptionsFormArray = <UntypedFormArray>configurationFormGroup.get('options');

          const formGroup = buildProductInclusionGroupOptionForm(null);
          formGroup.get('uid').setValue((<ProductInclusionGroupOptionSelection>result).optionFormGroup.get('uid').value);

          inclusionGroupOptionsFormArray.push(formGroup);
          inclusionGroupOptionsFormArray.markAsDirty();

          this.editOption(formGroup);
        }
      });
    });
  }

  public dropOption(event: CdkDragDrop<string[]>) {

    if (event.previousIndex != event.currentIndex) {
      var options = <UntypedFormArray>this.form.get('options');
      var item = options.controls[event.previousIndex];
      options.removeAt(event.previousIndex);

      options.insert(event.currentIndex, item);
    }
  }

  public deleteOption(form: UntypedFormGroup) {

    var options = <UntypedFormArray>this.form.get('options');
    options.removeAt(options.controls.indexOf(form));
  }
}

class ProductInclusionGroupOptionSelection {

  display: string;
  optionFormGroup: UntypedFormGroup;
}
