import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { MessageModalComponent } from './message-modal.component';

@Injectable()
export class MessageModalProvider {

  constructor(
    protected dialog: MatDialog
  ) {
  }
  
  public open(data: MessageModalData): MatDialogRef<MessageModalComponent> {

    return this.dialog.open(MessageModalComponent, {
      width: '50vw',
      disableClose: true,
      closeOnNavigation: true,
      data: data
    });
  }
}

export interface MessageModalData {
  title?: string;
  message: string;
  autoCloseTimeout?: number;
}
