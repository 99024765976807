import { Component } from "@angular/core";
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { catchError } from "rxjs/operators";
import { SpinnerModalProvider } from "core";
import { WaitModalProvider } from "core";
import { NavigationProvider } from "core";
import { PaymentMethodKeys } from "core";
import { EmployeeService } from "pos-core";
import { ErrorHandlingProvider } from "core";

@Component({
  selector: 'app-back-office-employee-payment',
  templateUrl: './back-office-employee-payment.component.html',
  styleUrls: ['./back-office-employee-payment.component.scss']
})
export class BackOfficeEmployeePaymentComponent {

  public title: string;
  public form: UntypedFormGroup;
  public canSave = new BehaviorSubject<boolean>(false);
  public paymentMethods = <{ uid: string, name: string }[]>[
    { uid: PaymentMethodKeys.Check.toLowerCase(), name: 'Check' },
    { uid: PaymentMethodKeys.Cash.toLowerCase(), name: 'Cash' }
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationProvider: NavigationProvider,
    private errorHandlingProvider: ErrorHandlingProvider,
    private spinnerModalProvider: SpinnerModalProvider,
    private waitModalProvider: WaitModalProvider,
    private employeeService: EmployeeService
  ) {
    this.navigationProvider.setWaypoint('Employee Payment');

    this.title = 'Employee Payment';

    this.form = new UntypedFormGroup({
      paymentMethodUid: new UntypedFormControl(null),
    });
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      let spinnerModalRef = this.spinnerModalProvider.open();
      spinnerModalRef.afterOpened().subscribe(() => {
        this.employeeService.getByUid(params['employeeUid']).subscribe(employee => {
          this.form.get('paymentMethodUid').setValue(employee.paymentMethodUid.toLowerCase());

          spinnerModalRef.close();
        });
      });
    });
  }

  save() {

    if (this.form.dirty) {
      let waitDialogRef = this.waitModalProvider.open('Saving...');

      combineLatest([
        this.activatedRoute.params,
        waitDialogRef.afterOpened()
      ]).subscribe(([params]) => {
        this.employeeService.updatePaymentMethod(
          params['employeeUid'],
          this.form.get('paymentMethodUid').value
        ).pipe(
          catchError(error => this.errorHandlingProvider.handleError(error, null))
        ).subscribe(employee => {
          waitDialogRef.close();

          if (employee) {
            this.navigationProvider.backOneWaypoint();
          }
        });
      });
    } else {
      this.navigationProvider.backOneWaypoint();
    }
  }

  cancel() {
    this.navigationProvider.backOneWaypoint();
  }
}
